type CityTypes = {
  [key: string]: {
    name: string;
    postalCode: string;
  };
};

export const cities: CityTypes = {
  'aaronsburg-pa': {
    name: 'Aaronsburg',
    postalCode: '16820',
  },
  'abbeville-al': {
    name: 'Abbeville',
    postalCode: '36310',
  },
  'abbeville-ga': {
    name: 'Abbeville',
    postalCode: '31001',
  },
  'abbeville-la': {
    name: 'Abbeville',
    postalCode: '70510',
  },
  'abbeville-ms': {
    name: 'Abbeville',
    postalCode: '38601',
  },
  'abbeville-sc': {
    name: 'Abbeville',
    postalCode: '29620',
  },
  'abbot-me': {
    name: 'Abbot',
    postalCode: '04406',
  },
  'abbotsford-wi': {
    name: 'Abbotsford',
    postalCode: '54405',
  },
  'abbott-tx': {
    name: 'Abbott',
    postalCode: '76621',
  },
  'abbottstown-pa': {
    name: 'Abbottstown',
    postalCode: '17301',
  },
  'abbyville-ks': {
    name: 'Abbyville',
    postalCode: '67510',
  },
  'abell-md': {
    name: 'Abell',
    postalCode: '20606',
  },
  'aberdeen-id': {
    name: 'Aberdeen',
    postalCode: '83210',
  },
  'aberdeen-md': {
    name: 'Aberdeen',
    postalCode: '21001',
  },
  'aberdeen-ms': {
    name: 'Aberdeen',
    postalCode: '39730',
  },
  'aberdeen-nc': {
    name: 'Aberdeen',
    postalCode: '28315',
  },
  'aberdeen-oh': {
    name: 'Aberdeen',
    postalCode: '45101',
  },
  'aberdeen-sd': {
    name: 'Aberdeen',
    postalCode: '57401',
  },
  'aberdeen-wa': {
    name: 'Aberdeen',
    postalCode: '98520',
  },
  'aberdeen-proving-ground-md': {
    name: 'Aberdeen Proving Ground',
    postalCode: '21005',
  },
  'abernathy-tx': {
    name: 'Abernathy',
    postalCode: '79311',
  },
  'abie-ne': {
    name: 'Abie',
    postalCode: '68001',
  },
  'abilene-ks': {
    name: 'Abilene',
    postalCode: '67410',
  },
  'abilene-tx': {
    name: 'Abilene',
    postalCode: '79601',
  },
  'abingdon-il': {
    name: 'Abingdon',
    postalCode: '61410',
  },
  'abingdon-md': {
    name: 'Abingdon',
    postalCode: '21009',
  },
  'abingdon-va': {
    name: 'Abingdon',
    postalCode: '24210',
  },
  'abington-ma': {
    name: 'Abington',
    postalCode: '02351',
  },
  'abington-pa': {
    name: 'Abington',
    postalCode: '19001',
  },
  'abiquiu-nm': {
    name: 'Abiquiu',
    postalCode: '87510',
  },
  'abita-springs-la': {
    name: 'Abita Springs',
    postalCode: '70420',
  },
  'abrams-wi': {
    name: 'Abrams',
    postalCode: '54101',
  },
  'absaraka-nd': {
    name: 'Absaraka',
    postalCode: '58002',
  },
  'absarokee-mt': {
    name: 'Absarokee',
    postalCode: '59001',
  },
  'absecon-nj': {
    name: 'Absecon',
    postalCode: '08201',
  },
  'acampo-ca': {
    name: 'Acampo',
    postalCode: '95220',
  },
  'accident-md': {
    name: 'Accident',
    postalCode: '21520',
  },
  'accokeek-md': {
    name: 'Accokeek',
    postalCode: '20607',
  },
  'accomac-va': {
    name: 'Accomac',
    postalCode: '23301',
  },
  'accord-ny': {
    name: 'Accord',
    postalCode: '12404',
  },
  'ackerly-tx': {
    name: 'Ackerly',
    postalCode: '79713',
  },
  'ackerman-ms': {
    name: 'Ackerman',
    postalCode: '39735',
  },
  'ackley-ia': {
    name: 'Ackley',
    postalCode: '50601',
  },
  'ackworth-ia': {
    name: 'Ackworth',
    postalCode: '50001',
  },
  'acme-la': {
    name: 'Acme',
    postalCode: '71316',
  },
  'acme-pa': {
    name: 'Acme',
    postalCode: '15610',
  },
  'acme-wa': {
    name: 'Acme',
    postalCode: '98220',
  },
  'acra-ny': {
    name: 'Acra',
    postalCode: '12405',
  },
  'acton-ca': {
    name: 'Acton',
    postalCode: '93510',
  },
  'acton-ma': {
    name: 'Acton',
    postalCode: '01718',
  },
  'acton-me': {
    name: 'Acton',
    postalCode: '04001',
  },
  'acton-mt': {
    name: 'Acton',
    postalCode: '59002',
  },
  'acushnet-ma': {
    name: 'Acushnet',
    postalCode: '02743',
  },
  'acworth-ga': {
    name: 'Acworth',
    postalCode: '30101',
  },
  'acworth-nh': {
    name: 'Acworth',
    postalCode: '03601',
  },
  'ada-mi': {
    name: 'Ada',
    postalCode: '49301',
  },
  'ada-mn': {
    name: 'Ada',
    postalCode: '56510',
  },
  'ada-oh': {
    name: 'Ada',
    postalCode: '45810',
  },
  'ada-ok': {
    name: 'Ada',
    postalCode: '74820',
  },
  'adah-pa': {
    name: 'Adah',
    postalCode: '15410',
  },
  'adair-ia': {
    name: 'Adair',
    postalCode: '50002',
  },
  'adair-il': {
    name: 'Adair',
    postalCode: '61411',
  },
  'adair-ok': {
    name: 'Adair',
    postalCode: '74330',
  },
  'adairsville-ga': {
    name: 'Adairsville',
    postalCode: '30103',
  },
  'adairville-ky': {
    name: 'Adairville',
    postalCode: '42202',
  },
  'adamant-vt': {
    name: 'Adamant',
    postalCode: '05640',
  },
  'adams-ky': {
    name: 'Adams',
    postalCode: '41201',
  },
  'adams-ma': {
    name: 'Adams',
    postalCode: '01220',
  },
  'adams-mn': {
    name: 'Adams',
    postalCode: '55909',
  },
  'adams-nd': {
    name: 'Adams',
    postalCode: '58210',
  },
  'adams-ne': {
    name: 'Adams',
    postalCode: '68301',
  },
  'adams-ny': {
    name: 'Adams',
    postalCode: '13605',
  },
  'adams-or': {
    name: 'Adams',
    postalCode: '97810',
  },
  'adams-tn': {
    name: 'Adams',
    postalCode: '37010',
  },
  'adams-wi': {
    name: 'Adams',
    postalCode: '53910',
  },
  'adams-center-ny': {
    name: 'Adams Center',
    postalCode: '13606',
  },
  'adams-run-sc': {
    name: 'Adams Run',
    postalCode: '29426',
  },
  'adamsburg-pa': {
    name: 'Adamsburg',
    postalCode: '15611',
  },
  'adamstown-md': {
    name: 'Adamstown',
    postalCode: '21710',
  },
  'adamsville-al': {
    name: 'Adamsville',
    postalCode: '35005',
  },
  'adamsville-oh': {
    name: 'Adamsville',
    postalCode: '43802',
  },
  'adamsville-pa': {
    name: 'Adamsville',
    postalCode: '16110',
  },
  'adamsville-tn': {
    name: 'Adamsville',
    postalCode: '38310',
  },
  'addieville-il': {
    name: 'Addieville',
    postalCode: '62214',
  },
  'addis-la': {
    name: 'Addis',
    postalCode: '70710',
  },
  'addison-al': {
    name: 'Addison',
    postalCode: '35540',
  },
  'addison-il': {
    name: 'Addison',
    postalCode: '60101',
  },
  'addison-me': {
    name: 'Addison',
    postalCode: '04606',
  },
  'addison-mi': {
    name: 'Addison',
    postalCode: '49220',
  },
  'addison-ny': {
    name: 'Addison',
    postalCode: '14801',
  },
  'addison-pa': {
    name: 'Addison',
    postalCode: '15411',
  },
  'addison-tx': {
    name: 'Addison',
    postalCode: '75001',
  },
  'addy-wa': {
    name: 'Addy',
    postalCode: '99101',
  },
  'adel-ga': {
    name: 'Adel',
    postalCode: '31620',
  },
  'adel-ia': {
    name: 'Adel',
    postalCode: '50003',
  },
  'adelanto-ca': {
    name: 'Adelanto',
    postalCode: '92301',
  },
  'adell-wi': {
    name: 'Adell',
    postalCode: '53001',
  },
  'adena-oh': {
    name: 'Adena',
    postalCode: '43901',
  },
  'adger-al': {
    name: 'Adger',
    postalCode: '35006',
  },
  'adin-ca': {
    name: 'Adin',
    postalCode: '96006',
  },
  'adirondack-ny': {
    name: 'Adirondack',
    postalCode: '12808',
  },
  'adjuntas-pr': {
    name: 'Adjuntas',
    postalCode: '00601',
  },
  'adkins-tx': {
    name: 'Adkins',
    postalCode: '78101',
  },
  'admire-ks': {
    name: 'Admire',
    postalCode: '66830',
  },
  'adolph-mn': {
    name: 'Adolph',
    postalCode: '55701',
  },
  'adolphus-ky': {
    name: 'Adolphus',
    postalCode: '42120',
  },
  'adona-ar': {
    name: 'Adona',
    postalCode: '72001',
  },
  'adrian-ga': {
    name: 'Adrian',
    postalCode: '31002',
  },
  'adrian-mi': {
    name: 'Adrian',
    postalCode: '49221',
  },
  'adrian-mn': {
    name: 'Adrian',
    postalCode: '56110',
  },
  'adrian-mo': {
    name: 'Adrian',
    postalCode: '64720',
  },
  'adrian-or': {
    name: 'Adrian',
    postalCode: '97901',
  },
  'adrian-pa': {
    name: 'Adrian',
    postalCode: '16210',
  },
  'adrian-tx': {
    name: 'Adrian',
    postalCode: '79001',
  },
  'adrian-wv': {
    name: 'Adrian',
    postalCode: '26210',
  },
  'advance-mo': {
    name: 'Advance',
    postalCode: '63730',
  },
  'advance-nc': {
    name: 'Advance',
    postalCode: '27006',
  },
  'advent-wv': {
    name: 'Advent',
    postalCode: '25231',
  },
  'afton-ia': {
    name: 'Afton',
    postalCode: '50830',
  },
  'afton-mi': {
    name: 'Afton',
    postalCode: '49705',
  },
  'afton-mn': {
    name: 'Afton',
    postalCode: '55001',
  },
  'afton-ny': {
    name: 'Afton',
    postalCode: '13730',
  },
  'afton-ok': {
    name: 'Afton',
    postalCode: '74331',
  },
  'afton-tn': {
    name: 'Afton',
    postalCode: '37616',
  },
  'afton-tx': {
    name: 'Afton',
    postalCode: '79220',
  },
  'afton-va': {
    name: 'Afton',
    postalCode: '22920',
  },
  'afton-wy': {
    name: 'Afton',
    postalCode: '83110',
  },
  'agana-heights-gu': {
    name: 'Agana Heights',
    postalCode: '96919',
  },
  'agar-sd': {
    name: 'Agar',
    postalCode: '57520',
  },
  'agate-co': {
    name: 'Agate',
    postalCode: '80101',
  },
  'agate-nd': {
    name: 'Agate',
    postalCode: '58310',
  },
  'agawam-ma': {
    name: 'Agawam',
    postalCode: '01001',
  },
  'agency-ia': {
    name: 'Agency',
    postalCode: '52530',
  },
  'agency-mo': {
    name: 'Agency',
    postalCode: '64401',
  },
  'agenda-ks': {
    name: 'Agenda',
    postalCode: '66930',
  },
  'agness-or': {
    name: 'Agness',
    postalCode: '97406',
  },
  'agoura-hills-ca': {
    name: 'Agoura Hills',
    postalCode: '91301',
  },
  'agra-ks': {
    name: 'Agra',
    postalCode: '67621',
  },
  'agra-ok': {
    name: 'Agra',
    postalCode: '74824',
  },
  'agua-dulce-tx': {
    name: 'Agua Dulce',
    postalCode: '78330',
  },
  'aguada-pr': {
    name: 'Aguada',
    postalCode: '00602',
  },
  'aguadilla-pr': {
    name: 'Aguadilla',
    postalCode: '00603',
  },
  'aguanga-ca': {
    name: 'Aguanga',
    postalCode: '92536',
  },
  'aguas-buenas-pr': {
    name: 'Aguas Buenas',
    postalCode: '00703',
  },
  'aguilar-co': {
    name: 'Aguilar',
    postalCode: '81020',
  },
  'aguirre-pr': {
    name: 'Aguirre',
    postalCode: '00704',
  },
  'ahoskie-nc': {
    name: 'Ahoskie',
    postalCode: '27910',
  },
  'ahsahka-id': {
    name: 'Ahsahka',
    postalCode: '83520',
  },
  'ahwahnee-ca': {
    name: 'Ahwahnee',
    postalCode: '93601',
  },
  'aibonito-pr': {
    name: 'Aibonito',
    postalCode: '00705',
  },
  'aiea-hi': {
    name: 'Aiea',
    postalCode: '96701',
  },
  'aiken-sc': {
    name: 'Aiken',
    postalCode: '29801',
  },
  'ailey-ga': {
    name: 'Ailey',
    postalCode: '30410',
  },
  'aimwell-la': {
    name: 'Aimwell',
    postalCode: '71401',
  },
  'ainsworth-ia': {
    name: 'Ainsworth',
    postalCode: '52201',
  },
  'ainsworth-ne': {
    name: 'Ainsworth',
    postalCode: '69210',
  },
  'airville-pa': {
    name: 'Airville',
    postalCode: '17302',
  },
  'airway-heights-wa': {
    name: 'Airway Heights',
    postalCode: '99001',
  },
  'aitkin-mn': {
    name: 'Aitkin',
    postalCode: '56431',
  },
  'ajo-az': {
    name: 'Ajo',
    postalCode: '85321',
  },
  'akeley-mn': {
    name: 'Akeley',
    postalCode: '56433',
  },
  'akers-la': {
    name: 'Akers',
    postalCode: '70421',
  },
  'akron-al': {
    name: 'Akron',
    postalCode: '35441',
  },
  'akron-co': {
    name: 'Akron',
    postalCode: '80720',
  },
  'akron-ia': {
    name: 'Akron',
    postalCode: '51001',
  },
  'akron-in': {
    name: 'Akron',
    postalCode: '46910',
  },
  'akron-mi': {
    name: 'Akron',
    postalCode: '48701',
  },
  'akron-ny': {
    name: 'Akron',
    postalCode: '14001',
  },
  'akron-oh': {
    name: 'Akron',
    postalCode: '44301',
  },
  'akron-pa': {
    name: 'Akron',
    postalCode: '17501',
  },
  'alabaster-al': {
    name: 'Alabaster',
    postalCode: '35007',
  },
  'alachua-fl': {
    name: 'Alachua',
    postalCode: '32615',
  },
  'aladdin-wy': {
    name: 'Aladdin',
    postalCode: '82710',
  },
  'alameda-ca': {
    name: 'Alameda',
    postalCode: '94501',
  },
  'alamo-ca': {
    name: 'Alamo',
    postalCode: '94507',
  },
  'alamo-ga': {
    name: 'Alamo',
    postalCode: '30411',
  },
  'alamo-nd': {
    name: 'Alamo',
    postalCode: '58830',
  },
  'alamo-nv': {
    name: 'Alamo',
    postalCode: '89001',
  },
  'alamo-tn': {
    name: 'Alamo',
    postalCode: '38001',
  },
  'alamo-tx': {
    name: 'Alamo',
    postalCode: '78516',
  },
  'alamogordo-nm': {
    name: 'Alamogordo',
    postalCode: '88310',
  },
  'alamosa-co': {
    name: 'Alamosa',
    postalCode: '81101',
  },
  'alanson-mi': {
    name: 'Alanson',
    postalCode: '49706',
  },
  'alapaha-ga': {
    name: 'Alapaha',
    postalCode: '31622',
  },
  'alba-pa': {
    name: 'Alba',
    postalCode: '16910',
  },
  'alba-tx': {
    name: 'Alba',
    postalCode: '75410',
  },
  'albany-ca': {
    name: 'Albany',
    postalCode: '94706',
  },
  'albany-ga': {
    name: 'Albany',
    postalCode: '31701',
  },
  'albany-il': {
    name: 'Albany',
    postalCode: '61230',
  },
  'albany-in': {
    name: 'Albany',
    postalCode: '47320',
  },
  'albany-ky': {
    name: 'Albany',
    postalCode: '42602',
  },
  'albany-la': {
    name: 'Albany',
    postalCode: '70711',
  },
  'albany-mn': {
    name: 'Albany',
    postalCode: '56307',
  },
  'albany-mo': {
    name: 'Albany',
    postalCode: '64402',
  },
  'albany-ny': {
    name: 'Albany',
    postalCode: '12202',
  },
  'albany-oh': {
    name: 'Albany',
    postalCode: '45710',
  },
  'albany-or': {
    name: 'Albany',
    postalCode: '97321',
  },
  'albany-tx': {
    name: 'Albany',
    postalCode: '76430',
  },
  'albany-vt': {
    name: 'Albany',
    postalCode: '05820',
  },
  'albany-wi': {
    name: 'Albany',
    postalCode: '53502',
  },
  'albemarle-nc': {
    name: 'Albemarle',
    postalCode: '28001',
  },
  'albers-il': {
    name: 'Albers',
    postalCode: '62215',
  },
  'albert-ks': {
    name: 'Albert',
    postalCode: '67511',
  },
  'albert-city-ia': {
    name: 'Albert City',
    postalCode: '50510',
  },
  'albert-lea-mn': {
    name: 'Albert Lea',
    postalCode: '56007',
  },
  'alberta-al': {
    name: 'Alberta',
    postalCode: '36720',
  },
  'alberta-mn': {
    name: 'Alberta',
    postalCode: '56207',
  },
  'alberta-va': {
    name: 'Alberta',
    postalCode: '23821',
  },
  'alberton-mt': {
    name: 'Alberton',
    postalCode: '59820',
  },
  'albertson-nc': {
    name: 'Albertson',
    postalCode: '28508',
  },
  'albertson-ny': {
    name: 'Albertson',
    postalCode: '11507',
  },
  'albertville-al': {
    name: 'Albertville',
    postalCode: '35950',
  },
  'albertville-mn': {
    name: 'Albertville',
    postalCode: '55301',
  },
  'albia-ia': {
    name: 'Albia',
    postalCode: '52531',
  },
  'albin-wy': {
    name: 'Albin',
    postalCode: '82050',
  },
  'albion-ca': {
    name: 'Albion',
    postalCode: '95410',
  },
  'albion-ia': {
    name: 'Albion',
    postalCode: '50005',
  },
  'albion-id': {
    name: 'Albion',
    postalCode: '83311',
  },
  'albion-il': {
    name: 'Albion',
    postalCode: '62806',
  },
  'albion-in': {
    name: 'Albion',
    postalCode: '46701',
  },
  'albion-me': {
    name: 'Albion',
    postalCode: '04910',
  },
  'albion-mi': {
    name: 'Albion',
    postalCode: '49224',
  },
  'albion-ne': {
    name: 'Albion',
    postalCode: '68620',
  },
  'albion-ny': {
    name: 'Albion',
    postalCode: '14411',
  },
  'albion-pa': {
    name: 'Albion',
    postalCode: '16401',
  },
  'alborn-mn': {
    name: 'Alborn',
    postalCode: '55702',
  },
  'albright-wv': {
    name: 'Albright',
    postalCode: '26519',
  },
  'albrightsville-pa': {
    name: 'Albrightsville',
    postalCode: '18210',
  },
  'albuquerque-nm': {
    name: 'Albuquerque',
    postalCode: '87101',
  },
  'alburgh-vt': {
    name: 'Alburgh',
    postalCode: '05440',
  },
  'alburnett-ia': {
    name: 'Alburnett',
    postalCode: '52202',
  },
  'alburtis-pa': {
    name: 'Alburtis',
    postalCode: '18011',
  },
  'alcester-sd': {
    name: 'Alcester',
    postalCode: '57001',
  },
  'alcoa-tn': {
    name: 'Alcoa',
    postalCode: '37701',
  },
  'alcolu-sc': {
    name: 'Alcolu',
    postalCode: '29001',
  },
  'alcova-wy': {
    name: 'Alcova',
    postalCode: '82620',
  },
  'alcove-ny': {
    name: 'Alcove',
    postalCode: '12007',
  },
  'alda-ne': {
    name: 'Alda',
    postalCode: '68810',
  },
  'alden-ia': {
    name: 'Alden',
    postalCode: '50006',
  },
  'alden-ks': {
    name: 'Alden',
    postalCode: '67512',
  },
  'alden-mi': {
    name: 'Alden',
    postalCode: '49612',
  },
  'alden-mn': {
    name: 'Alden',
    postalCode: '56009',
  },
  'alden-ny': {
    name: 'Alden',
    postalCode: '14004',
  },
  'alder-creek-ny': {
    name: 'Alder Creek',
    postalCode: '13301',
  },
  'alderpoint-ca': {
    name: 'Alderpoint',
    postalCode: '95511',
  },
  'alderson-wv': {
    name: 'Alderson',
    postalCode: '24910',
  },
  'aldie-va': {
    name: 'Aldie',
    postalCode: '20105',
  },
  'aldrich-mn': {
    name: 'Aldrich',
    postalCode: '56434',
  },
  'aldrich-mo': {
    name: 'Aldrich',
    postalCode: '65601',
  },
  'aledo-il': {
    name: 'Aledo',
    postalCode: '61231',
  },
  'aledo-tx': {
    name: 'Aledo',
    postalCode: '76008',
  },
  'aleppo-pa': {
    name: 'Aleppo',
    postalCode: '15310',
  },
  'alex-ok': {
    name: 'Alex',
    postalCode: '73002',
  },
  'alexander-ar': {
    name: 'Alexander',
    postalCode: '72002',
  },
  'alexander-ia': {
    name: 'Alexander',
    postalCode: '50420',
  },
  'alexander-il': {
    name: 'Alexander',
    postalCode: '62601',
  },
  'alexander-ks': {
    name: 'Alexander',
    postalCode: '67513',
  },
  'alexander-nc': {
    name: 'Alexander',
    postalCode: '28701',
  },
  'alexander-nd': {
    name: 'Alexander',
    postalCode: '58831',
  },
  'alexander-ny': {
    name: 'Alexander',
    postalCode: '14005',
  },
  'alexander-city-al': {
    name: 'Alexander City',
    postalCode: '35010',
  },
  'alexandria-al': {
    name: 'Alexandria',
    postalCode: '36250',
  },
  'alexandria-in': {
    name: 'Alexandria',
    postalCode: '46001',
  },
  'alexandria-ky': {
    name: 'Alexandria',
    postalCode: '41001',
  },
  'alexandria-la': {
    name: 'Alexandria',
    postalCode: '71301',
  },
  'alexandria-mn': {
    name: 'Alexandria',
    postalCode: '56308',
  },
  'alexandria-mo': {
    name: 'Alexandria',
    postalCode: '63430',
  },
  'alexandria-ne': {
    name: 'Alexandria',
    postalCode: '68303',
  },
  'alexandria-oh': {
    name: 'Alexandria',
    postalCode: '43001',
  },
  'alexandria-pa': {
    name: 'Alexandria',
    postalCode: '16611',
  },
  'alexandria-sd': {
    name: 'Alexandria',
    postalCode: '57311',
  },
  'alexandria-tn': {
    name: 'Alexandria',
    postalCode: '37012',
  },
  'alexandria-va': {
    name: 'Alexandria',
    postalCode: '22301',
  },
  'alexandria-bay-ny': {
    name: 'Alexandria Bay',
    postalCode: '13607',
  },
  'alexis-il': {
    name: 'Alexis',
    postalCode: '61412',
  },
  'alexis-nc': {
    name: 'Alexis',
    postalCode: '28006',
  },
  'alford-fl': {
    name: 'Alford',
    postalCode: '32420',
  },
  'alfred-me': {
    name: 'Alfred',
    postalCode: '04002',
  },
  'alfred-ny': {
    name: 'Alfred',
    postalCode: '14802',
  },
  'alfred-station-ny': {
    name: 'Alfred Station',
    postalCode: '14803',
  },
  'alger-mi': {
    name: 'Alger',
    postalCode: '48610',
  },
  'alger-oh': {
    name: 'Alger',
    postalCode: '45812',
  },
  'algodones-nm': {
    name: 'Algodones',
    postalCode: '87001',
  },
  'algoma-wi': {
    name: 'Algoma',
    postalCode: '54201',
  },
  'algona-ia': {
    name: 'Algona',
    postalCode: '50511',
  },
  'algonac-mi': {
    name: 'Algonac',
    postalCode: '48001',
  },
  'algonquin-il': {
    name: 'Algonquin',
    postalCode: '60102',
  },
  'alhambra-ca': {
    name: 'Alhambra',
    postalCode: '91801',
  },
  'alhambra-il': {
    name: 'Alhambra',
    postalCode: '62001',
  },
  'alice-tx': {
    name: 'Alice',
    postalCode: '78332',
  },
  'aliceville-al': {
    name: 'Aliceville',
    postalCode: '35442',
  },
  'alicia-ar': {
    name: 'Alicia',
    postalCode: '72410',
  },
  'aline-ok': {
    name: 'Aline',
    postalCode: '73716',
  },
  'aliquippa-pa': {
    name: 'Aliquippa',
    postalCode: '15001',
  },
  'aliso-viejo-ca': {
    name: 'Aliso Viejo',
    postalCode: '92656',
  },
  'alkol-wv': {
    name: 'Alkol',
    postalCode: '25501',
  },
  'allardt-tn': {
    name: 'Allardt',
    postalCode: '38504',
  },
  'alledonia-oh': {
    name: 'Alledonia',
    postalCode: '43902',
  },
  'allegan-mi': {
    name: 'Allegan',
    postalCode: '49010',
  },
  'allegany-ny': {
    name: 'Allegany',
    postalCode: '14706',
  },
  'alleghany-ca': {
    name: 'Alleghany',
    postalCode: '95910',
  },
  'alleman-ia': {
    name: 'Alleman',
    postalCode: '50007',
  },
  'allen-ks': {
    name: 'Allen',
    postalCode: '66833',
  },
  'allen-ky': {
    name: 'Allen',
    postalCode: '41601',
  },
  'allen-mi': {
    name: 'Allen',
    postalCode: '49227',
  },
  'allen-ne': {
    name: 'Allen',
    postalCode: '68710',
  },
  'allen-ok': {
    name: 'Allen',
    postalCode: '74825',
  },
  'allen-sd': {
    name: 'Allen',
    postalCode: '57714',
  },
  'allen-tx': {
    name: 'Allen',
    postalCode: '75002',
  },
  'allen-park-mi': {
    name: 'Allen Park',
    postalCode: '48101',
  },
  'allendale-il': {
    name: 'Allendale',
    postalCode: '62410',
  },
  'allendale-mi': {
    name: 'Allendale',
    postalCode: '49401',
  },
  'allendale-nj': {
    name: 'Allendale',
    postalCode: '07401',
  },
  'allendale-sc': {
    name: 'Allendale',
    postalCode: '29810',
  },
  'allenhurst-ga': {
    name: 'Allenhurst',
    postalCode: '31301',
  },
  'allenhurst-nj': {
    name: 'Allenhurst',
    postalCode: '07711',
  },
  'allenspark-co': {
    name: 'Allenspark',
    postalCode: '80510',
  },
  'allensville-ky': {
    name: 'Allensville',
    postalCode: '42204',
  },
  'allensville-pa': {
    name: 'Allensville',
    postalCode: '17002',
  },
  'allenton-mi': {
    name: 'Allenton',
    postalCode: '48002',
  },
  'allenton-wi': {
    name: 'Allenton',
    postalCode: '53002',
  },
  'allentown-nj': {
    name: 'Allentown',
    postalCode: '08501',
  },
  'allentown-pa': {
    name: 'Allentown',
    postalCode: '18101',
  },
  'allenwood-pa': {
    name: 'Allenwood',
    postalCode: '17810',
  },
  'allerton-ia': {
    name: 'Allerton',
    postalCode: '50008',
  },
  'allerton-il': {
    name: 'Allerton',
    postalCode: '61810',
  },
  'alleyton-tx': {
    name: 'Alleyton',
    postalCode: '78935',
  },
  'alliance-ne': {
    name: 'Alliance',
    postalCode: '69301',
  },
  'alliance-oh': {
    name: 'Alliance',
    postalCode: '44601',
  },
  'alligator-ms': {
    name: 'Alligator',
    postalCode: '38720',
  },
  'allison-ia': {
    name: 'Allison',
    postalCode: '50602',
  },
  'allison-pa': {
    name: 'Allison',
    postalCode: '15413',
  },
  'allison-park-pa': {
    name: 'Allison Park',
    postalCode: '15101',
  },
  'allons-tn': {
    name: 'Allons',
    postalCode: '38541',
  },
  'allouez-mi': {
    name: 'Allouez',
    postalCode: '49805',
  },
  'allport-pa': {
    name: 'Allport',
    postalCode: '16821',
  },
  'allred-tn': {
    name: 'Allred',
    postalCode: '38542',
  },
  'allston-ma': {
    name: 'Allston',
    postalCode: '02134',
  },
  'allyn-wa': {
    name: 'Allyn',
    postalCode: '98524',
  },
  'alma-ar': {
    name: 'Alma',
    postalCode: '72921',
  },
  'alma-ga': {
    name: 'Alma',
    postalCode: '31510',
  },
  'alma-il': {
    name: 'Alma',
    postalCode: '62807',
  },
  'alma-ks': {
    name: 'Alma',
    postalCode: '66401',
  },
  'alma-mi': {
    name: 'Alma',
    postalCode: '48801',
  },
  'alma-mo': {
    name: 'Alma',
    postalCode: '64001',
  },
  'alma-ne': {
    name: 'Alma',
    postalCode: '68920',
  },
  'alma-ny': {
    name: 'Alma',
    postalCode: '14708',
  },
  'alma-wi': {
    name: 'Alma',
    postalCode: '54610',
  },
  'alma-wv': {
    name: 'Alma',
    postalCode: '26320',
  },
  'alma-center-wi': {
    name: 'Alma Center',
    postalCode: '54611',
  },
  'almena-ks': {
    name: 'Almena',
    postalCode: '67622',
  },
  'almena-wi': {
    name: 'Almena',
    postalCode: '54805',
  },
  'almira-wa': {
    name: 'Almira',
    postalCode: '99103',
  },
  'almo-ky': {
    name: 'Almo',
    postalCode: '42020',
  },
  'almond-nc': {
    name: 'Almond',
    postalCode: '28702',
  },
  'almond-ny': {
    name: 'Almond',
    postalCode: '14804',
  },
  'almond-wi': {
    name: 'Almond',
    postalCode: '54909',
  },
  'almont-co': {
    name: 'Almont',
    postalCode: '81210',
  },
  'almont-mi': {
    name: 'Almont',
    postalCode: '48003',
  },
  'almont-nd': {
    name: 'Almont',
    postalCode: '58520',
  },
  'almyra-ar': {
    name: 'Almyra',
    postalCode: '72003',
  },
  'alna-me': {
    name: 'Alna',
    postalCode: '04535',
  },
  'alpena-ar': {
    name: 'Alpena',
    postalCode: '72611',
  },
  'alpena-mi': {
    name: 'Alpena',
    postalCode: '49707',
  },
  'alpena-sd': {
    name: 'Alpena',
    postalCode: '57312',
  },
  'alpha-il': {
    name: 'Alpha',
    postalCode: '61413',
  },
  'alpha-ky': {
    name: 'Alpha',
    postalCode: '42603',
  },
  'alpha-mn': {
    name: 'Alpha',
    postalCode: '56111',
  },
  'alpharetta-ga': {
    name: 'Alpharetta',
    postalCode: '30004',
  },
  'alpine-al': {
    name: 'Alpine',
    postalCode: '35014',
  },
  'alpine-ar': {
    name: 'Alpine',
    postalCode: '71920',
  },
  'alpine-az': {
    name: 'Alpine',
    postalCode: '85920',
  },
  'alpine-ca': {
    name: 'Alpine',
    postalCode: '91901',
  },
  'alpine-ny': {
    name: 'Alpine',
    postalCode: '14805',
  },
  'alpine-tn': {
    name: 'Alpine',
    postalCode: '38543',
  },
  'alpine-tx': {
    name: 'Alpine',
    postalCode: '79830',
  },
  'alpine-ut': {
    name: 'Alpine',
    postalCode: '84004',
  },
  'alpine-wy': {
    name: 'Alpine',
    postalCode: '83128',
  },
  'alplaus-ny': {
    name: 'Alplaus',
    postalCode: '12008',
  },
  'alsea-or': {
    name: 'Alsea',
    postalCode: '97324',
  },
  'alsen-nd': {
    name: 'Alsen',
    postalCode: '58311',
  },
  'alsip-il': {
    name: 'Alsip',
    postalCode: '60803',
  },
  'alstead-nh': {
    name: 'Alstead',
    postalCode: '03602',
  },
  'alta-ia': {
    name: 'Alta',
    postalCode: '51002',
  },
  'alta-wy': {
    name: 'Alta',
    postalCode: '83414',
  },
  'alta-vista-ia': {
    name: 'Alta Vista',
    postalCode: '50603',
  },
  'alta-vista-ks': {
    name: 'Alta Vista',
    postalCode: '66834',
  },
  'altadena-ca': {
    name: 'Altadena',
    postalCode: '91001',
  },
  'altamont-il': {
    name: 'Altamont',
    postalCode: '62411',
  },
  'altamont-ks': {
    name: 'Altamont',
    postalCode: '67330',
  },
  'altamont-mo': {
    name: 'Altamont',
    postalCode: '64620',
  },
  'altamont-ny': {
    name: 'Altamont',
    postalCode: '12009',
  },
  'altamont-tn': {
    name: 'Altamont',
    postalCode: '37301',
  },
  'altamont-ut': {
    name: 'Altamont',
    postalCode: '84001',
  },
  'altamonte-springs-fl': {
    name: 'Altamonte Springs',
    postalCode: '32701',
  },
  'altavista-va': {
    name: 'Altavista',
    postalCode: '24517',
  },
  'altenburg-mo': {
    name: 'Altenburg',
    postalCode: '63732',
  },
  'altha-fl': {
    name: 'Altha',
    postalCode: '32421',
  },
  'altheimer-ar': {
    name: 'Altheimer',
    postalCode: '72004',
  },
  'altmar-ny': {
    name: 'Altmar',
    postalCode: '13302',
  },
  'alto-ga': {
    name: 'Alto',
    postalCode: '30510',
  },
  'alto-mi': {
    name: 'Alto',
    postalCode: '49302',
  },
  'alto-nm': {
    name: 'Alto',
    postalCode: '88312',
  },
  'alto-tx': {
    name: 'Alto',
    postalCode: '75925',
  },
  'alto-pass-il': {
    name: 'Alto Pass',
    postalCode: '62905',
  },
  'alton-ia': {
    name: 'Alton',
    postalCode: '51003',
  },
  'alton-il': {
    name: 'Alton',
    postalCode: '62002',
  },
  'alton-ks': {
    name: 'Alton',
    postalCode: '67623',
  },
  'alton-mo': {
    name: 'Alton',
    postalCode: '65606',
  },
  'alton-nh': {
    name: 'Alton',
    postalCode: '03809',
  },
  'alton-va': {
    name: 'Alton',
    postalCode: '24520',
  },
  'alton-bay-nh': {
    name: 'Alton Bay',
    postalCode: '03810',
  },
  'altona-il': {
    name: 'Altona',
    postalCode: '61414',
  },
  'altona-ny': {
    name: 'Altona',
    postalCode: '12910',
  },
  'altonah-ut': {
    name: 'Altonah',
    postalCode: '84002',
  },
  'altoona-al': {
    name: 'Altoona',
    postalCode: '35952',
  },
  'altoona-fl': {
    name: 'Altoona',
    postalCode: '32702',
  },
  'altoona-ia': {
    name: 'Altoona',
    postalCode: '50009',
  },
  'altoona-ks': {
    name: 'Altoona',
    postalCode: '66710',
  },
  'altoona-pa': {
    name: 'Altoona',
    postalCode: '16601',
  },
  'altoona-wi': {
    name: 'Altoona',
    postalCode: '54720',
  },
  'altura-mn': {
    name: 'Altura',
    postalCode: '55910',
  },
  'alturas-ca': {
    name: 'Alturas',
    postalCode: '96101',
  },
  'altus-ar': {
    name: 'Altus',
    postalCode: '72821',
  },
  'altus-ok': {
    name: 'Altus',
    postalCode: '73521',
  },
  'altus-afb-ok': {
    name: 'Altus AFB',
    postalCode: '73523',
  },
  'alum-bank-pa': {
    name: 'Alum Bank',
    postalCode: '15521',
  },
  'alum-bridge-wv': {
    name: 'Alum Bridge',
    postalCode: '26321',
  },
  'alum-creek-wv': {
    name: 'Alum Creek',
    postalCode: '25003',
  },
  'alva-fl': {
    name: 'Alva',
    postalCode: '33920',
  },
  'alva-ok': {
    name: 'Alva',
    postalCode: '73717',
  },
  'alva-wy': {
    name: 'Alva',
    postalCode: '82711',
  },
  'alvada-oh': {
    name: 'Alvada',
    postalCode: '44802',
  },
  'alvarado-mn': {
    name: 'Alvarado',
    postalCode: '56710',
  },
  'alvarado-tx': {
    name: 'Alvarado',
    postalCode: '76009',
  },
  'alvaton-ky': {
    name: 'Alvaton',
    postalCode: '42122',
  },
  'alverton-pa': {
    name: 'Alverton',
    postalCode: '15612',
  },
  'alvin-il': {
    name: 'Alvin',
    postalCode: '61811',
  },
  'alvin-tx': {
    name: 'Alvin',
    postalCode: '77511',
  },
  'alvo-ne': {
    name: 'Alvo',
    postalCode: '68304',
  },
  'alvord-ia': {
    name: 'Alvord',
    postalCode: '51230',
  },
  'alvord-tx': {
    name: 'Alvord',
    postalCode: '76225',
  },
  'alvordton-oh': {
    name: 'Alvordton',
    postalCode: '43501',
  },
  'alzada-mt': {
    name: 'Alzada',
    postalCode: '59311',
  },
  'ama-la': {
    name: 'Ama',
    postalCode: '70031',
  },
  'amado-az': {
    name: 'Amado',
    postalCode: '85645',
  },
  'amagon-ar': {
    name: 'Amagon',
    postalCode: '72005',
  },
  'amana-ia': {
    name: 'Amana',
    postalCode: '52203',
  },
  'amanda-oh': {
    name: 'Amanda',
    postalCode: '43102',
  },
  'amanda-park-wa': {
    name: 'Amanda Park',
    postalCode: '98526',
  },
  'amargosa-valley-nv': {
    name: 'Amargosa Valley',
    postalCode: '89020',
  },
  'amarillo-tx': {
    name: 'Amarillo',
    postalCode: '79101',
  },
  'amawalk-ny': {
    name: 'Amawalk',
    postalCode: '10501',
  },
  'amazonia-mo': {
    name: 'Amazonia',
    postalCode: '64421',
  },
  'amber-ok': {
    name: 'Amber',
    postalCode: '73004',
  },
  'amberg-wi': {
    name: 'Amberg',
    postalCode: '54102',
  },
  'ambia-in': {
    name: 'Ambia',
    postalCode: '47917',
  },
  'ambler-pa': {
    name: 'Ambler',
    postalCode: '19002',
  },
  'amboy-il': {
    name: 'Amboy',
    postalCode: '61310',
  },
  'amboy-in': {
    name: 'Amboy',
    postalCode: '46911',
  },
  'amboy-mn': {
    name: 'Amboy',
    postalCode: '56010',
  },
  'amboy-wa': {
    name: 'Amboy',
    postalCode: '98601',
  },
  'ambridge-pa': {
    name: 'Ambridge',
    postalCode: '15003',
  },
  'ambrose-ga': {
    name: 'Ambrose',
    postalCode: '31512',
  },
  'ambrose-nd': {
    name: 'Ambrose',
    postalCode: '58833',
  },
  'amelia-ne': {
    name: 'Amelia',
    postalCode: '68711',
  },
  'amelia-oh': {
    name: 'Amelia',
    postalCode: '45102',
  },
  'amelia-court-house-va': {
    name: 'Amelia Court House',
    postalCode: '23002',
  },
  'amenia-nd': {
    name: 'Amenia',
    postalCode: '58004',
  },
  'amenia-ny': {
    name: 'Amenia',
    postalCode: '12501',
  },
  'american-canyon-ca': {
    name: 'American Canyon',
    postalCode: '94503',
  },
  'american-falls-id': {
    name: 'American Falls',
    postalCode: '83211',
  },
  'american-fork-ut': {
    name: 'American Fork',
    postalCode: '84003',
  },
  'americus-ga': {
    name: 'Americus',
    postalCode: '31709',
  },
  'americus-ks': {
    name: 'Americus',
    postalCode: '66835',
  },
  'amery-wi': {
    name: 'Amery',
    postalCode: '54001',
  },
  'ames-ia': {
    name: 'Ames',
    postalCode: '50010',
  },
  'ames-ne': {
    name: 'Ames',
    postalCode: '68621',
  },
  'ames-ok': {
    name: 'Ames',
    postalCode: '73718',
  },
  'amesbury-ma': {
    name: 'Amesbury',
    postalCode: '01913',
  },
  'amesville-oh': {
    name: 'Amesville',
    postalCode: '45711',
  },
  'amherst-co': {
    name: 'Amherst',
    postalCode: '80721',
  },
  'amherst-ma': {
    name: 'Amherst',
    postalCode: '01002',
  },
  'amherst-ne': {
    name: 'Amherst',
    postalCode: '68812',
  },
  'amherst-nh': {
    name: 'Amherst',
    postalCode: '03031',
  },
  'amherst-oh': {
    name: 'Amherst',
    postalCode: '44001',
  },
  'amherst-sd': {
    name: 'Amherst',
    postalCode: '57421',
  },
  'amherst-tx': {
    name: 'Amherst',
    postalCode: '79312',
  },
  'amherst-va': {
    name: 'Amherst',
    postalCode: '24521',
  },
  'amherst-wi': {
    name: 'Amherst',
    postalCode: '54406',
  },
  'amherst-junction-wi': {
    name: 'Amherst Junction',
    postalCode: '54407',
  },
  'amherstdale-wv': {
    name: 'Amherstdale',
    postalCode: '25607',
  },
  'amidon-nd': {
    name: 'Amidon',
    postalCode: '58620',
  },
  'amigo-wv': {
    name: 'Amigo',
    postalCode: '25811',
  },
  'amissville-va': {
    name: 'Amissville',
    postalCode: '20106',
  },
  'amistad-nm': {
    name: 'Amistad',
    postalCode: '88410',
  },
  'amite-la': {
    name: 'Amite',
    postalCode: '70422',
  },
  'amity-ar': {
    name: 'Amity',
    postalCode: '71921',
  },
  'amity-mo': {
    name: 'Amity',
    postalCode: '64422',
  },
  'amity-or': {
    name: 'Amity',
    postalCode: '97101',
  },
  'amity-pa': {
    name: 'Amity',
    postalCode: '15311',
  },
  'amityville-ny': {
    name: 'Amityville',
    postalCode: '11701',
  },
  'amma-wv': {
    name: 'Amma',
    postalCode: '25005',
  },
  'amoret-mo': {
    name: 'Amoret',
    postalCode: '64722',
  },
  'amorita-ok': {
    name: 'Amorita',
    postalCode: '73719',
  },
  'amory-ms': {
    name: 'Amory',
    postalCode: '38821',
  },
  'amsterdam-mo': {
    name: 'Amsterdam',
    postalCode: '64723',
  },
  'amsterdam-ny': {
    name: 'Amsterdam',
    postalCode: '12010',
  },
  'amsterdam-oh': {
    name: 'Amsterdam',
    postalCode: '43903',
  },
  'amston-ct': {
    name: 'Amston',
    postalCode: '06231',
  },
  'anabel-mo': {
    name: 'Anabel',
    postalCode: '63431',
  },
  'anacoco-la': {
    name: 'Anacoco',
    postalCode: '71403',
  },
  'anaconda-mt': {
    name: 'Anaconda',
    postalCode: '59711',
  },
  'anacortes-wa': {
    name: 'Anacortes',
    postalCode: '98221',
  },
  'anadarko-ok': {
    name: 'Anadarko',
    postalCode: '73005',
  },
  'anaheim-ca': {
    name: 'Anaheim',
    postalCode: '92801',
  },
  'anahuac-tx': {
    name: 'Anahuac',
    postalCode: '77514',
  },
  'anamoose-nd': {
    name: 'Anamoose',
    postalCode: '58710',
  },
  'anamosa-ia': {
    name: 'Anamosa',
    postalCode: '52205',
  },
  'anasco-pr': {
    name: 'Anasco',
    postalCode: '00610',
  },
  'anatone-wa': {
    name: 'Anatone',
    postalCode: '99401',
  },
  'anawalt-wv': {
    name: 'Anawalt',
    postalCode: '24808',
  },
  'anchor-il': {
    name: 'Anchor',
    postalCode: '61720',
  },
  'anchor-point-ak': {
    name: 'Anchor Point',
    postalCode: '99556',
  },
  'anchorage-ak': {
    name: 'Anchorage',
    postalCode: '99501',
  },
  'ancona-il': {
    name: 'Ancona',
    postalCode: '61311',
  },
  'ancram-ny': {
    name: 'Ancram',
    postalCode: '12502',
  },
  'ancramdale-ny': {
    name: 'Ancramdale',
    postalCode: '12503',
  },
  'andale-ks': {
    name: 'Andale',
    postalCode: '67001',
  },
  'andalusia-al': {
    name: 'Andalusia',
    postalCode: '36420',
  },
  'andalusia-il': {
    name: 'Andalusia',
    postalCode: '61232',
  },
  'anderson-al': {
    name: 'Anderson',
    postalCode: '35610',
  },
  'anderson-ca': {
    name: 'Anderson',
    postalCode: '96007',
  },
  'anderson-in': {
    name: 'Anderson',
    postalCode: '46011',
  },
  'anderson-mo': {
    name: 'Anderson',
    postalCode: '64831',
  },
  'anderson-sc': {
    name: 'Anderson',
    postalCode: '29621',
  },
  'anderson-tx': {
    name: 'Anderson',
    postalCode: '77830',
  },
  'anderson-island-wa': {
    name: 'Anderson Island',
    postalCode: '98303',
  },
  'andersonville-ga': {
    name: 'Andersonville',
    postalCode: '31711',
  },
  'andersonville-tn': {
    name: 'Andersonville',
    postalCode: '37705',
  },
  'andes-ny': {
    name: 'Andes',
    postalCode: '13731',
  },
  'andover-ct': {
    name: 'Andover',
    postalCode: '06232',
  },
  'andover-ks': {
    name: 'Andover',
    postalCode: '67002',
  },
  'andover-ma': {
    name: 'Andover',
    postalCode: '01810',
  },
  'andover-me': {
    name: 'Andover',
    postalCode: '04216',
  },
  'andover-mn': {
    name: 'Andover',
    postalCode: '55304',
  },
  'andover-nh': {
    name: 'Andover',
    postalCode: '03216',
  },
  'andover-nj': {
    name: 'Andover',
    postalCode: '07821',
  },
  'andover-ny': {
    name: 'Andover',
    postalCode: '14806',
  },
  'andover-oh': {
    name: 'Andover',
    postalCode: '44003',
  },
  'andover-sd': {
    name: 'Andover',
    postalCode: '57422',
  },
  'andreas-pa': {
    name: 'Andreas',
    postalCode: '18211',
  },
  'andrews-in': {
    name: 'Andrews',
    postalCode: '46702',
  },
  'andrews-nc': {
    name: 'Andrews',
    postalCode: '28901',
  },
  'andrews-sc': {
    name: 'Andrews',
    postalCode: '29510',
  },
  'andrews-tx': {
    name: 'Andrews',
    postalCode: '79714',
  },
  'andrews-air-force-base-md': {
    name: 'Andrews Air Force Base',
    postalCode: '20762',
  },
  'aneta-nd': {
    name: 'Aneta',
    postalCode: '58212',
  },
  'angela-mt': {
    name: 'Angela',
    postalCode: '59312',
  },
  'angelica-ny': {
    name: 'Angelica',
    postalCode: '14709',
  },
  'angels-camp-ca': {
    name: 'Angels Camp',
    postalCode: '95222',
  },
  'angelus-oaks-ca': {
    name: 'Angelus Oaks',
    postalCode: '92305',
  },
  'angie-la': {
    name: 'Angie',
    postalCode: '70426',
  },
  'angier-nc': {
    name: 'Angier',
    postalCode: '27501',
  },
  'angleton-tx': {
    name: 'Angleton',
    postalCode: '77515',
  },
  'angola-in': {
    name: 'Angola',
    postalCode: '46703',
  },
  'angola-ny': {
    name: 'Angola',
    postalCode: '14006',
  },
  'angora-mn': {
    name: 'Angora',
    postalCode: '55703',
  },
  'angora-ne': {
    name: 'Angora',
    postalCode: '69331',
  },
  'anguilla-ms': {
    name: 'Anguilla',
    postalCode: '38721',
  },
  'angwin-ca': {
    name: 'Angwin',
    postalCode: '94508',
  },
  'animas-nm': {
    name: 'Animas',
    postalCode: '88020',
  },
  'anita-ia': {
    name: 'Anita',
    postalCode: '50020',
  },
  'anita-pa': {
    name: 'Anita',
    postalCode: '15711',
  },
  'aniwa-wi': {
    name: 'Aniwa',
    postalCode: '54408',
  },
  'ankeny-ia': {
    name: 'Ankeny',
    postalCode: '50021',
  },
  'ann-arbor-mi': {
    name: 'Ann Arbor',
    postalCode: '48103',
  },
  'anna-il': {
    name: 'Anna',
    postalCode: '62906',
  },
  'anna-oh': {
    name: 'Anna',
    postalCode: '45302',
  },
  'anna-tx': {
    name: 'Anna',
    postalCode: '75409',
  },
  'annada-mo': {
    name: 'Annada',
    postalCode: '63330',
  },
  'annandale-mn': {
    name: 'Annandale',
    postalCode: '55302',
  },
  'annandale-nj': {
    name: 'Annandale',
    postalCode: '08801',
  },
  'annandale-va': {
    name: 'Annandale',
    postalCode: '22003',
  },
  'annapolis-ca': {
    name: 'Annapolis',
    postalCode: '95412',
  },
  'annapolis-il': {
    name: 'Annapolis',
    postalCode: '62413',
  },
  'annapolis-md': {
    name: 'Annapolis',
    postalCode: '21401',
  },
  'annapolis-mo': {
    name: 'Annapolis',
    postalCode: '63620',
  },
  'annapolis-junction-md': {
    name: 'Annapolis Junction',
    postalCode: '20701',
  },
  'annawan-il': {
    name: 'Annawan',
    postalCode: '61234',
  },
  'annemanie-al': {
    name: 'Annemanie',
    postalCode: '36721',
  },
  'anniston-al': {
    name: 'Anniston',
    postalCode: '36201',
  },
  'annona-tx': {
    name: 'Annona',
    postalCode: '75550',
  },
  'annville-ky': {
    name: 'Annville',
    postalCode: '40402',
  },
  'annville-pa': {
    name: 'Annville',
    postalCode: '17003',
  },
  'anoka-mn': {
    name: 'Anoka',
    postalCode: '55303',
  },
  'anselmo-ne': {
    name: 'Anselmo',
    postalCode: '68813',
  },
  'ansley-ne': {
    name: 'Ansley',
    postalCode: '68814',
  },
  'anson-me': {
    name: 'Anson',
    postalCode: '04911',
  },
  'anson-tx': {
    name: 'Anson',
    postalCode: '79501',
  },
  'ansonia-ct': {
    name: 'Ansonia',
    postalCode: '06401',
  },
  'ansonia-oh': {
    name: 'Ansonia',
    postalCode: '45303',
  },
  'ansted-wv': {
    name: 'Ansted',
    postalCode: '25812',
  },
  'antelope-ca': {
    name: 'Antelope',
    postalCode: '95843',
  },
  'antelope-mt': {
    name: 'Antelope',
    postalCode: '59211',
  },
  'anthon-ia': {
    name: 'Anthon',
    postalCode: '51004',
  },
  'anthony-fl': {
    name: 'Anthony',
    postalCode: '32617',
  },
  'anthony-ks': {
    name: 'Anthony',
    postalCode: '67003',
  },
  'anthony-nm': {
    name: 'Anthony',
    postalCode: '88021',
  },
  'anthony-tx': {
    name: 'Anthony',
    postalCode: '79821',
  },
  'antigo-wi': {
    name: 'Antigo',
    postalCode: '54409',
  },
  'antimony-ut': {
    name: 'Antimony',
    postalCode: '84712',
  },
  'antioch-ca': {
    name: 'Antioch',
    postalCode: '94509',
  },
  'antioch-il': {
    name: 'Antioch',
    postalCode: '60002',
  },
  'antioch-tn': {
    name: 'Antioch',
    postalCode: '37013',
  },
  'antler-nd': {
    name: 'Antler',
    postalCode: '58711',
  },
  'antlers-ok': {
    name: 'Antlers',
    postalCode: '74523',
  },
  'anton-co': {
    name: 'Anton',
    postalCode: '80801',
  },
  'anton-tx': {
    name: 'Anton',
    postalCode: '79313',
  },
  'anton-chico-nm': {
    name: 'Anton Chico',
    postalCode: '87711',
  },
  'antonito-co': {
    name: 'Antonito',
    postalCode: '81120',
  },
  'antrim-nh': {
    name: 'Antrim',
    postalCode: '03440',
  },
  'antwerp-ny': {
    name: 'Antwerp',
    postalCode: '13608',
  },
  'antwerp-oh': {
    name: 'Antwerp',
    postalCode: '45813',
  },
  'anza-ca': {
    name: 'Anza',
    postalCode: '92539',
  },
  'apache-ok': {
    name: 'Apache',
    postalCode: '73006',
  },
  'apache-junction-az': {
    name: 'Apache Junction',
    postalCode: '85119',
  },
  'apalachicola-fl': {
    name: 'Apalachicola',
    postalCode: '32320',
  },
  'apalachin-ny': {
    name: 'Apalachin',
    postalCode: '13732',
  },
  'apex-nc': {
    name: 'Apex',
    postalCode: '27502',
  },
  'apison-tn': {
    name: 'Apison',
    postalCode: '37302',
  },
  'aplington-ia': {
    name: 'Aplington',
    postalCode: '50604',
  },
  'apollo-pa': {
    name: 'Apollo',
    postalCode: '15613',
  },
  'apollo-beach-fl': {
    name: 'Apollo Beach',
    postalCode: '33572',
  },
  'apopka-fl': {
    name: 'Apopka',
    postalCode: '32703',
  },
  'appalachia-va': {
    name: 'Appalachia',
    postalCode: '24216',
  },
  'apple-creek-oh': {
    name: 'Apple Creek',
    postalCode: '44606',
  },
  'apple-grove-wv': {
    name: 'Apple Grove',
    postalCode: '25502',
  },
  'apple-river-il': {
    name: 'Apple River',
    postalCode: '61001',
  },
  'apple-springs-tx': {
    name: 'Apple Springs',
    postalCode: '75926',
  },
  'apple-valley-ca': {
    name: 'Apple Valley',
    postalCode: '92307',
  },
  'applegate-ca': {
    name: 'Applegate',
    postalCode: '95703',
  },
  'applegate-mi': {
    name: 'Applegate',
    postalCode: '48401',
  },
  'appleton-mn': {
    name: 'Appleton',
    postalCode: '56208',
  },
  'appleton-ny': {
    name: 'Appleton',
    postalCode: '14008',
  },
  'appleton-wa': {
    name: 'Appleton',
    postalCode: '98602',
  },
  'appleton-wi': {
    name: 'Appleton',
    postalCode: '54911',
  },
  'appleton-city-mo': {
    name: 'Appleton City',
    postalCode: '64724',
  },
  'appling-ga': {
    name: 'Appling',
    postalCode: '30802',
  },
  'appomattox-va': {
    name: 'Appomattox',
    postalCode: '24522',
  },
  'aptos-ca': {
    name: 'Aptos',
    postalCode: '95003',
  },
  'aquasco-md': {
    name: 'Aquasco',
    postalCode: '20608',
  },
  'aquilla-tx': {
    name: 'Aquilla',
    postalCode: '76622',
  },
  'arab-al': {
    name: 'Arab',
    postalCode: '35016',
  },
  'arabi-ga': {
    name: 'Arabi',
    postalCode: '31712',
  },
  'arabi-la': {
    name: 'Arabi',
    postalCode: '70032',
  },
  'aragon-ga': {
    name: 'Aragon',
    postalCode: '30104',
  },
  'aragon-nm': {
    name: 'Aragon',
    postalCode: '87820',
  },
  'aransas-pass-tx': {
    name: 'Aransas Pass',
    postalCode: '78336',
  },
  'arapaho-ok': {
    name: 'Arapaho',
    postalCode: '73620',
  },
  'arapahoe-co': {
    name: 'Arapahoe',
    postalCode: '80802',
  },
  'arapahoe-nc': {
    name: 'Arapahoe',
    postalCode: '28510',
  },
  'arapahoe-ne': {
    name: 'Arapahoe',
    postalCode: '68922',
  },
  'arapahoe-wy': {
    name: 'Arapahoe',
    postalCode: '82510',
  },
  'ararat-nc': {
    name: 'Ararat',
    postalCode: '27007',
  },
  'ararat-va': {
    name: 'Ararat',
    postalCode: '24053',
  },
  'arbela-mo': {
    name: 'Arbela',
    postalCode: '63432',
  },
  'arbon-id': {
    name: 'Arbon',
    postalCode: '83212',
  },
  'arbovale-wv': {
    name: 'Arbovale',
    postalCode: '24915',
  },
  'arbuckle-ca': {
    name: 'Arbuckle',
    postalCode: '95912',
  },
  'arbyrd-mo': {
    name: 'Arbyrd',
    postalCode: '63821',
  },
  'arcade-ny': {
    name: 'Arcade',
    postalCode: '14009',
  },
  'arcadia-ca': {
    name: 'Arcadia',
    postalCode: '91006',
  },
  'arcadia-fl': {
    name: 'Arcadia',
    postalCode: '34266',
  },
  'arcadia-ia': {
    name: 'Arcadia',
    postalCode: '51430',
  },
  'arcadia-in': {
    name: 'Arcadia',
    postalCode: '46030',
  },
  'arcadia-ks': {
    name: 'Arcadia',
    postalCode: '66711',
  },
  'arcadia-la': {
    name: 'Arcadia',
    postalCode: '71001',
  },
  'arcadia-mi': {
    name: 'Arcadia',
    postalCode: '49613',
  },
  'arcadia-mo': {
    name: 'Arcadia',
    postalCode: '63621',
  },
  'arcadia-ne': {
    name: 'Arcadia',
    postalCode: '68815',
  },
  'arcadia-oh': {
    name: 'Arcadia',
    postalCode: '44804',
  },
  'arcadia-ok': {
    name: 'Arcadia',
    postalCode: '73007',
  },
  'arcadia-wi': {
    name: 'Arcadia',
    postalCode: '54612',
  },
  'arcanum-oh': {
    name: 'Arcanum',
    postalCode: '45304',
  },
  'arcata-ca': {
    name: 'Arcata',
    postalCode: '95521',
  },
  'arch-cape-or': {
    name: 'Arch Cape',
    postalCode: '97102',
  },
  'archbald-pa': {
    name: 'Archbald',
    postalCode: '18403',
  },
  'archbold-oh': {
    name: 'Archbold',
    postalCode: '43502',
  },
  'archer-fl': {
    name: 'Archer',
    postalCode: '32618',
  },
  'archer-ia': {
    name: 'Archer',
    postalCode: '51231',
  },
  'archer-ne': {
    name: 'Archer',
    postalCode: '68816',
  },
  'archie-mo': {
    name: 'Archie',
    postalCode: '64725',
  },
  'arco-id': {
    name: 'Arco',
    postalCode: '83213',
  },
  'arco-mn': {
    name: 'Arco',
    postalCode: '56113',
  },
  'arcola-il': {
    name: 'Arcola',
    postalCode: '61910',
  },
  'arcola-mo': {
    name: 'Arcola',
    postalCode: '65603',
  },
  'arcola-va': {
    name: 'Arcola',
    postalCode: '20107',
  },
  'ardara-pa': {
    name: 'Ardara',
    postalCode: '15615',
  },
  'arden-nc': {
    name: 'Arden',
    postalCode: '28704',
  },
  'ardmore-al': {
    name: 'Ardmore',
    postalCode: '35739',
  },
  'ardmore-ok': {
    name: 'Ardmore',
    postalCode: '73401',
  },
  'ardmore-pa': {
    name: 'Ardmore',
    postalCode: '19003',
  },
  'ardmore-tn': {
    name: 'Ardmore',
    postalCode: '38449',
  },
  'ardsley-ny': {
    name: 'Ardsley',
    postalCode: '10502',
  },
  'arecibo-pr': {
    name: 'Arecibo',
    postalCode: '00612',
  },
  'aredale-ia': {
    name: 'Aredale',
    postalCode: '50605',
  },
  'arena-wi': {
    name: 'Arena',
    postalCode: '53503',
  },
  'arenas-valley-nm': {
    name: 'Arenas Valley',
    postalCode: '88022',
  },
  'arenzville-il': {
    name: 'Arenzville',
    postalCode: '62611',
  },
  'argenta-il': {
    name: 'Argenta',
    postalCode: '62501',
  },
  'argillite-ky': {
    name: 'Argillite',
    postalCode: '41121',
  },
  'argonia-ks': {
    name: 'Argonia',
    postalCode: '67004',
  },
  'argonne-wi': {
    name: 'Argonne',
    postalCode: '54511',
  },
  'argos-in': {
    name: 'Argos',
    postalCode: '46501',
  },
  'argusville-nd': {
    name: 'Argusville',
    postalCode: '58005',
  },
  'argyle-ia': {
    name: 'Argyle',
    postalCode: '52619',
  },
  'argyle-mn': {
    name: 'Argyle',
    postalCode: '56713',
  },
  'argyle-mo': {
    name: 'Argyle',
    postalCode: '65001',
  },
  'argyle-ny': {
    name: 'Argyle',
    postalCode: '12809',
  },
  'argyle-tx': {
    name: 'Argyle',
    postalCode: '76226',
  },
  'argyle-wi': {
    name: 'Argyle',
    postalCode: '53504',
  },
  'ariel-wa': {
    name: 'Ariel',
    postalCode: '98603',
  },
  'arimo-id': {
    name: 'Arimo',
    postalCode: '83214',
  },
  'arion-ia': {
    name: 'Arion',
    postalCode: '51520',
  },
  'ariton-al': {
    name: 'Ariton',
    postalCode: '36311',
  },
  'arizona-city-az': {
    name: 'Arizona City',
    postalCode: '85223',
  },
  'arjay-ky': {
    name: 'Arjay',
    postalCode: '40902',
  },
  'arkadelphia-ar': {
    name: 'Arkadelphia',
    postalCode: '71923',
  },
  'arkansas-city-ks': {
    name: 'Arkansas City',
    postalCode: '67005',
  },
  'arkansaw-wi': {
    name: 'Arkansaw',
    postalCode: '54721',
  },
  'arkdale-wi': {
    name: 'Arkdale',
    postalCode: '54613',
  },
  'arkoma-ok': {
    name: 'Arkoma',
    postalCode: '74901',
  },
  'arkport-ny': {
    name: 'Arkport',
    postalCode: '14807',
  },
  'arkville-ny': {
    name: 'Arkville',
    postalCode: '12406',
  },
  'arlee-mt': {
    name: 'Arlee',
    postalCode: '59821',
  },
  'arley-al': {
    name: 'Arley',
    postalCode: '35541',
  },
  'arlington-al': {
    name: 'Arlington',
    postalCode: '36722',
  },
  'arlington-az': {
    name: 'Arlington',
    postalCode: '85322',
  },
  'arlington-co': {
    name: 'Arlington',
    postalCode: '81021',
  },
  'arlington-ga': {
    name: 'Arlington',
    postalCode: '39813',
  },
  'arlington-ia': {
    name: 'Arlington',
    postalCode: '50606',
  },
  'arlington-il': {
    name: 'Arlington',
    postalCode: '61312',
  },
  'arlington-in': {
    name: 'Arlington',
    postalCode: '46104',
  },
  'arlington-ks': {
    name: 'Arlington',
    postalCode: '67514',
  },
  'arlington-ky': {
    name: 'Arlington',
    postalCode: '42021',
  },
  'arlington-ma': {
    name: 'Arlington',
    postalCode: '02474',
  },
  'arlington-mn': {
    name: 'Arlington',
    postalCode: '55307',
  },
  'arlington-ne': {
    name: 'Arlington',
    postalCode: '68002',
  },
  'arlington-oh': {
    name: 'Arlington',
    postalCode: '45814',
  },
  'arlington-or': {
    name: 'Arlington',
    postalCode: '97812',
  },
  'arlington-sd': {
    name: 'Arlington',
    postalCode: '57212',
  },
  'arlington-tn': {
    name: 'Arlington',
    postalCode: '38002',
  },
  'arlington-tx': {
    name: 'Arlington',
    postalCode: '76001',
  },
  'arlington-va': {
    name: 'Arlington',
    postalCode: '22201',
  },
  'arlington-vt': {
    name: 'Arlington',
    postalCode: '05250',
  },
  'arlington-wa': {
    name: 'Arlington',
    postalCode: '98223',
  },
  'arlington-wi': {
    name: 'Arlington',
    postalCode: '53911',
  },
  'arlington-heights-il': {
    name: 'Arlington Heights',
    postalCode: '60004',
  },
  'arma-ks': {
    name: 'Arma',
    postalCode: '66712',
  },
  'armada-mi': {
    name: 'Armada',
    postalCode: '48005',
  },
  'armagh-pa': {
    name: 'Armagh',
    postalCode: '15920',
  },
  'armington-il': {
    name: 'Armington',
    postalCode: '61721',
  },
  'arminto-wy': {
    name: 'Arminto',
    postalCode: '82630',
  },
  'armona-ca': {
    name: 'Armona',
    postalCode: '93202',
  },
  'armonk-ny': {
    name: 'Armonk',
    postalCode: '10504',
  },
  'armour-sd': {
    name: 'Armour',
    postalCode: '57313',
  },
  'armstrong-ia': {
    name: 'Armstrong',
    postalCode: '50514',
  },
  'armstrong-il': {
    name: 'Armstrong',
    postalCode: '61812',
  },
  'armstrong-mo': {
    name: 'Armstrong',
    postalCode: '65230',
  },
  'armstrong-creek-wi': {
    name: 'Armstrong Creek',
    postalCode: '54103',
  },
  'armuchee-ga': {
    name: 'Armuchee',
    postalCode: '30105',
  },
  'arnaudville-la': {
    name: 'Arnaudville',
    postalCode: '70512',
  },
  'arnegard-nd': {
    name: 'Arnegard',
    postalCode: '58835',
  },
  'arnett-ok': {
    name: 'Arnett',
    postalCode: '73832',
  },
  'arnett-wv': {
    name: 'Arnett',
    postalCode: '25007',
  },
  'arnold-ca': {
    name: 'Arnold',
    postalCode: '95223',
  },
  'arnold-ks': {
    name: 'Arnold',
    postalCode: '67515',
  },
  'arnold-md': {
    name: 'Arnold',
    postalCode: '21012',
  },
  'arnold-mo': {
    name: 'Arnold',
    postalCode: '63010',
  },
  'arnold-ne': {
    name: 'Arnold',
    postalCode: '69120',
  },
  'arnold-afb-tn': {
    name: 'Arnold AFB',
    postalCode: '37389',
  },
  'arnolds-park-ia': {
    name: 'Arnolds Park',
    postalCode: '51331',
  },
  'arnoldsburg-wv': {
    name: 'Arnoldsburg',
    postalCode: '25234',
  },
  'arnoldsville-ga': {
    name: 'Arnoldsville',
    postalCode: '30619',
  },
  'aroda-va': {
    name: 'Aroda',
    postalCode: '22709',
  },
  'aromas-ca': {
    name: 'Aromas',
    postalCode: '95004',
  },
  'arp-tx': {
    name: 'Arp',
    postalCode: '75750',
  },
  'arpin-wi': {
    name: 'Arpin',
    postalCode: '54410',
  },
  'arrey-nm': {
    name: 'Arrey',
    postalCode: '87930',
  },
  'arriba-co': {
    name: 'Arriba',
    postalCode: '80804',
  },
  'arrington-tn': {
    name: 'Arrington',
    postalCode: '37014',
  },
  'arrington-va': {
    name: 'Arrington',
    postalCode: '22922',
  },
  'arrowsmith-il': {
    name: 'Arrowsmith',
    postalCode: '61722',
  },
  'arroyo-pr': {
    name: 'Arroyo',
    postalCode: '00714',
  },
  'arroyo-grande-ca': {
    name: 'Arroyo Grande',
    postalCode: '93420',
  },
  'art-tx': {
    name: 'Art',
    postalCode: '76820',
  },
  'artemas-pa': {
    name: 'Artemas',
    postalCode: '17211',
  },
  'artemus-ky': {
    name: 'Artemus',
    postalCode: '40903',
  },
  'artesia-ca': {
    name: 'Artesia',
    postalCode: '90701',
  },
  'artesia-nm': {
    name: 'Artesia',
    postalCode: '88210',
  },
  'artesian-sd': {
    name: 'Artesian',
    postalCode: '57314',
  },
  'arthur-ia': {
    name: 'Arthur',
    postalCode: '51431',
  },
  'arthur-il': {
    name: 'Arthur',
    postalCode: '61911',
  },
  'arthur-nd': {
    name: 'Arthur',
    postalCode: '58006',
  },
  'arthur-ne': {
    name: 'Arthur',
    postalCode: '69121',
  },
  'arthur-city-tx': {
    name: 'Arthur City',
    postalCode: '75411',
  },
  'artie-wv': {
    name: 'Artie',
    postalCode: '25008',
  },
  'arvada-co': {
    name: 'Arvada',
    postalCode: '80002',
  },
  'arvada-wy': {
    name: 'Arvada',
    postalCode: '82831',
  },
  'arverne-ny': {
    name: 'Arverne',
    postalCode: '11692',
  },
  'arvilla-nd': {
    name: 'Arvilla',
    postalCode: '58214',
  },
  'arvin-ca': {
    name: 'Arvin',
    postalCode: '93203',
  },
  'arvonia-va': {
    name: 'Arvonia',
    postalCode: '23004',
  },
  'ary-ky': {
    name: 'Ary',
    postalCode: '41712',
  },
  'asbury-mo': {
    name: 'Asbury',
    postalCode: '64832',
  },
  'asbury-nj': {
    name: 'Asbury',
    postalCode: '08802',
  },
  'asbury-wv': {
    name: 'Asbury',
    postalCode: '24916',
  },
  'asbury-park-nj': {
    name: 'Asbury Park',
    postalCode: '07712',
  },
  'ash-nc': {
    name: 'Ash',
    postalCode: '28420',
  },
  'ash-flat-ar': {
    name: 'Ash Flat',
    postalCode: '72513',
  },
  'ash-grove-mo': {
    name: 'Ash Grove',
    postalCode: '65604',
  },
  'ashaway-ri': {
    name: 'Ashaway',
    postalCode: '02804',
  },
  'ashburn-ga': {
    name: 'Ashburn',
    postalCode: '31714',
  },
  'ashburn-mo': {
    name: 'Ashburn',
    postalCode: '63433',
  },
  'ashburn-va': {
    name: 'Ashburn',
    postalCode: '20147',
  },
  'ashburnham-ma': {
    name: 'Ashburnham',
    postalCode: '01430',
  },
  'ashby-ma': {
    name: 'Ashby',
    postalCode: '01431',
  },
  'ashby-mn': {
    name: 'Ashby',
    postalCode: '56309',
  },
  'ashby-ne': {
    name: 'Ashby',
    postalCode: '69333',
  },
  'ashcamp-ky': {
    name: 'Ashcamp',
    postalCode: '41512',
  },
  'ashdown-ar': {
    name: 'Ashdown',
    postalCode: '71822',
  },
  'asheboro-nc': {
    name: 'Asheboro',
    postalCode: '27203',
  },
  'asher-ok': {
    name: 'Asher',
    postalCode: '74826',
  },
  'asherton-tx': {
    name: 'Asherton',
    postalCode: '78827',
  },
  'asheville-nc': {
    name: 'Asheville',
    postalCode: '28801',
  },
  'ashfield-ma': {
    name: 'Ashfield',
    postalCode: '01330',
  },
  'ashford-al': {
    name: 'Ashford',
    postalCode: '36312',
  },
  'ashford-ct': {
    name: 'Ashford',
    postalCode: '06278',
  },
  'ashford-wa': {
    name: 'Ashford',
    postalCode: '98304',
  },
  'ashford-wv': {
    name: 'Ashford',
    postalCode: '25009',
  },
  'ashkum-il': {
    name: 'Ashkum',
    postalCode: '60911',
  },
  'ashland-al': {
    name: 'Ashland',
    postalCode: '36251',
  },
  'ashland-il': {
    name: 'Ashland',
    postalCode: '62612',
  },
  'ashland-ks': {
    name: 'Ashland',
    postalCode: '67831',
  },
  'ashland-ky': {
    name: 'Ashland',
    postalCode: '41101',
  },
  'ashland-la': {
    name: 'Ashland',
    postalCode: '71002',
  },
  'ashland-ma': {
    name: 'Ashland',
    postalCode: '01721',
  },
  'ashland-me': {
    name: 'Ashland',
    postalCode: '04732',
  },
  'ashland-mo': {
    name: 'Ashland',
    postalCode: '65010',
  },
  'ashland-ms': {
    name: 'Ashland',
    postalCode: '38603',
  },
  'ashland-mt': {
    name: 'Ashland',
    postalCode: '59003',
  },
  'ashland-ne': {
    name: 'Ashland',
    postalCode: '68003',
  },
  'ashland-nh': {
    name: 'Ashland',
    postalCode: '03217',
  },
  'ashland-ny': {
    name: 'Ashland',
    postalCode: '12407',
  },
  'ashland-oh': {
    name: 'Ashland',
    postalCode: '44805',
  },
  'ashland-or': {
    name: 'Ashland',
    postalCode: '97520',
  },
  'ashland-pa': {
    name: 'Ashland',
    postalCode: '17921',
  },
  'ashland-va': {
    name: 'Ashland',
    postalCode: '23005',
  },
  'ashland-wi': {
    name: 'Ashland',
    postalCode: '54806',
  },
  'ashland-city-tn': {
    name: 'Ashland City',
    postalCode: '37015',
  },
  'ashley-il': {
    name: 'Ashley',
    postalCode: '62808',
  },
  'ashley-in': {
    name: 'Ashley',
    postalCode: '46705',
  },
  'ashley-mi': {
    name: 'Ashley',
    postalCode: '48806',
  },
  'ashley-nd': {
    name: 'Ashley',
    postalCode: '58413',
  },
  'ashley-oh': {
    name: 'Ashley',
    postalCode: '43003',
  },
  'ashley-falls-ma': {
    name: 'Ashley Falls',
    postalCode: '01222',
  },
  'ashmore-il': {
    name: 'Ashmore',
    postalCode: '61912',
  },
  'ashtabula-oh': {
    name: 'Ashtabula',
    postalCode: '44004',
  },
  'ashton-ia': {
    name: 'Ashton',
    postalCode: '51232',
  },
  'ashton-id': {
    name: 'Ashton',
    postalCode: '83420',
  },
  'ashton-il': {
    name: 'Ashton',
    postalCode: '61006',
  },
  'ashton-md': {
    name: 'Ashton',
    postalCode: '20861',
  },
  'ashton-ne': {
    name: 'Ashton',
    postalCode: '68817',
  },
  'ashton-sd': {
    name: 'Ashton',
    postalCode: '57424',
  },
  'ashton-wv': {
    name: 'Ashton',
    postalCode: '25503',
  },
  'ashuelot-nh': {
    name: 'Ashuelot',
    postalCode: '03441',
  },
  'ashville-al': {
    name: 'Ashville',
    postalCode: '35953',
  },
  'ashville-ny': {
    name: 'Ashville',
    postalCode: '14710',
  },
  'ashville-oh': {
    name: 'Ashville',
    postalCode: '43103',
  },
  'ashville-pa': {
    name: 'Ashville',
    postalCode: '16613',
  },
  'ashwood-or': {
    name: 'Ashwood',
    postalCode: '97711',
  },
  'askov-mn': {
    name: 'Askov',
    postalCode: '55704',
  },
  'asotin-wa': {
    name: 'Asotin',
    postalCode: '99402',
  },
  'aspen-co': {
    name: 'Aspen',
    postalCode: '81611',
  },
  'aspermont-tx': {
    name: 'Aspermont',
    postalCode: '79502',
  },
  'aspers-pa': {
    name: 'Aspers',
    postalCode: '17304',
  },
  'assaria-ks': {
    name: 'Assaria',
    postalCode: '67416',
  },
  'assawoman-va': {
    name: 'Assawoman',
    postalCode: '23302',
  },
  'assonet-ma': {
    name: 'Assonet',
    postalCode: '02702',
  },
  'assumption-il': {
    name: 'Assumption',
    postalCode: '62510',
  },
  'astatula-fl': {
    name: 'Astatula',
    postalCode: '34705',
  },
  'aston-pa': {
    name: 'Aston',
    postalCode: '19014',
  },
  'astor-fl': {
    name: 'Astor',
    postalCode: '32102',
  },
  'astoria-il': {
    name: 'Astoria',
    postalCode: '61501',
  },
  'astoria-ny': {
    name: 'Astoria',
    postalCode: '11102',
  },
  'astoria-or': {
    name: 'Astoria',
    postalCode: '97103',
  },
  'astoria-sd': {
    name: 'Astoria',
    postalCode: '57213',
  },
  'atalissa-ia': {
    name: 'Atalissa',
    postalCode: '52720',
  },
  'atascadero-ca': {
    name: 'Atascadero',
    postalCode: '93422',
  },
  'atascosa-tx': {
    name: 'Atascosa',
    postalCode: '78002',
  },
  'atchison-ks': {
    name: 'Atchison',
    postalCode: '66002',
  },
  'atco-nj': {
    name: 'Atco',
    postalCode: '08004',
  },
  'atglen-pa': {
    name: 'Atglen',
    postalCode: '19310',
  },
  'athelstane-wi': {
    name: 'Athelstane',
    postalCode: '54104',
  },
  'athena-or': {
    name: 'Athena',
    postalCode: '97813',
  },
  'athens-al': {
    name: 'Athens',
    postalCode: '35611',
  },
  'athens-ga': {
    name: 'Athens',
    postalCode: '30601',
  },
  'athens-il': {
    name: 'Athens',
    postalCode: '62613',
  },
  'athens-la': {
    name: 'Athens',
    postalCode: '71003',
  },
  'athens-me': {
    name: 'Athens',
    postalCode: '04912',
  },
  'athens-mi': {
    name: 'Athens',
    postalCode: '49011',
  },
  'athens-ny': {
    name: 'Athens',
    postalCode: '12015',
  },
  'athens-oh': {
    name: 'Athens',
    postalCode: '45701',
  },
  'athens-pa': {
    name: 'Athens',
    postalCode: '18810',
  },
  'athens-tn': {
    name: 'Athens',
    postalCode: '37303',
  },
  'athens-tx': {
    name: 'Athens',
    postalCode: '75751',
  },
  'athens-wi': {
    name: 'Athens',
    postalCode: '54411',
  },
  'athens-wv': {
    name: 'Athens',
    postalCode: '24712',
  },
  'atherton-ca': {
    name: 'Atherton',
    postalCode: '94027',
  },
  'athol-id': {
    name: 'Athol',
    postalCode: '83801',
  },
  'athol-ks': {
    name: 'Athol',
    postalCode: '66932',
  },
  'athol-ky': {
    name: 'Athol',
    postalCode: '41307',
  },
  'athol-ma': {
    name: 'Athol',
    postalCode: '01331',
  },
  'athol-ny': {
    name: 'Athol',
    postalCode: '12810',
  },
  'atkins-ar': {
    name: 'Atkins',
    postalCode: '72823',
  },
  'atkins-ia': {
    name: 'Atkins',
    postalCode: '52206',
  },
  'atkins-va': {
    name: 'Atkins',
    postalCode: '24311',
  },
  'atkinson-il': {
    name: 'Atkinson',
    postalCode: '61235',
  },
  'atkinson-nc': {
    name: 'Atkinson',
    postalCode: '28421',
  },
  'atkinson-ne': {
    name: 'Atkinson',
    postalCode: '68713',
  },
  'atkinson-nh': {
    name: 'Atkinson',
    postalCode: '03811',
  },
  'atlanta-ga': {
    name: 'Atlanta',
    postalCode: '30303',
  },
  'atlanta-il': {
    name: 'Atlanta',
    postalCode: '61723',
  },
  'atlanta-in': {
    name: 'Atlanta',
    postalCode: '46031',
  },
  'atlanta-ks': {
    name: 'Atlanta',
    postalCode: '67008',
  },
  'atlanta-la': {
    name: 'Atlanta',
    postalCode: '71404',
  },
  'atlanta-mi': {
    name: 'Atlanta',
    postalCode: '49709',
  },
  'atlanta-mo': {
    name: 'Atlanta',
    postalCode: '63530',
  },
  'atlanta-ny': {
    name: 'Atlanta',
    postalCode: '14808',
  },
  'atlanta-tx': {
    name: 'Atlanta',
    postalCode: '75551',
  },
  'atlantic-ia': {
    name: 'Atlantic',
    postalCode: '50022',
  },
  'atlantic-nc': {
    name: 'Atlantic',
    postalCode: '28511',
  },
  'atlantic-pa': {
    name: 'Atlantic',
    postalCode: '16111',
  },
  'atlantic-va': {
    name: 'Atlantic',
    postalCode: '23303',
  },
  'atlantic-beach-fl': {
    name: 'Atlantic Beach',
    postalCode: '32233',
  },
  'atlantic-beach-nc': {
    name: 'Atlantic Beach',
    postalCode: '28512',
  },
  'atlantic-beach-ny': {
    name: 'Atlantic Beach',
    postalCode: '11509',
  },
  'atlantic-city-nj': {
    name: 'Atlantic City',
    postalCode: '08401',
  },
  'atlantic-highlands-nj': {
    name: 'Atlantic Highlands',
    postalCode: '07716',
  },
  'atlantic-mine-mi': {
    name: 'Atlantic Mine',
    postalCode: '49905',
  },
  'atmore-al': {
    name: 'Atmore',
    postalCode: '36502',
  },
  'atoka-ok': {
    name: 'Atoka',
    postalCode: '74525',
  },
  'atoka-tn': {
    name: 'Atoka',
    postalCode: '38004',
  },
  'atomic-city-id': {
    name: 'Atomic City',
    postalCode: '83215',
  },
  'attalla-al': {
    name: 'Attalla',
    postalCode: '35954',
  },
  'attapulgus-ga': {
    name: 'Attapulgus',
    postalCode: '39815',
  },
  'attica-in': {
    name: 'Attica',
    postalCode: '47918',
  },
  'attica-ks': {
    name: 'Attica',
    postalCode: '67009',
  },
  'attica-mi': {
    name: 'Attica',
    postalCode: '48412',
  },
  'attica-ny': {
    name: 'Attica',
    postalCode: '14011',
  },
  'attica-oh': {
    name: 'Attica',
    postalCode: '44807',
  },
  'attleboro-ma': {
    name: 'Attleboro',
    postalCode: '02703',
  },
  'attleboro-falls-ma': {
    name: 'Attleboro Falls',
    postalCode: '02763',
  },
  'atwater-ca': {
    name: 'Atwater',
    postalCode: '95301',
  },
  'atwater-mn': {
    name: 'Atwater',
    postalCode: '56209',
  },
  'atwater-oh': {
    name: 'Atwater',
    postalCode: '44201',
  },
  'atwood-co': {
    name: 'Atwood',
    postalCode: '80722',
  },
  'atwood-il': {
    name: 'Atwood',
    postalCode: '61913',
  },
  'atwood-ks': {
    name: 'Atwood',
    postalCode: '67730',
  },
  'atwood-ok': {
    name: 'Atwood',
    postalCode: '74827',
  },
  'atwood-tn': {
    name: 'Atwood',
    postalCode: '38220',
  },
  'au-gres-mi': {
    name: 'Au Gres',
    postalCode: '48703',
  },
  'au-sable-forks-ny': {
    name: 'Au Sable Forks',
    postalCode: '12912',
  },
  'au-train-mi': {
    name: 'Au Train',
    postalCode: '49806',
  },
  'auberry-ca': {
    name: 'Auberry',
    postalCode: '93602',
  },
  'aubrey-tx': {
    name: 'Aubrey',
    postalCode: '76227',
  },
  'auburn-al': {
    name: 'Auburn',
    postalCode: '36830',
  },
  'auburn-ca': {
    name: 'Auburn',
    postalCode: '95602',
  },
  'auburn-ga': {
    name: 'Auburn',
    postalCode: '30011',
  },
  'auburn-ia': {
    name: 'Auburn',
    postalCode: '51433',
  },
  'auburn-il': {
    name: 'Auburn',
    postalCode: '62615',
  },
  'auburn-in': {
    name: 'Auburn',
    postalCode: '46706',
  },
  'auburn-ks': {
    name: 'Auburn',
    postalCode: '66402',
  },
  'auburn-ky': {
    name: 'Auburn',
    postalCode: '42206',
  },
  'auburn-ma': {
    name: 'Auburn',
    postalCode: '01501',
  },
  'auburn-me': {
    name: 'Auburn',
    postalCode: '04210',
  },
  'auburn-mi': {
    name: 'Auburn',
    postalCode: '48611',
  },
  'auburn-ne': {
    name: 'Auburn',
    postalCode: '68305',
  },
  'auburn-nh': {
    name: 'Auburn',
    postalCode: '03032',
  },
  'auburn-ny': {
    name: 'Auburn',
    postalCode: '13021',
  },
  'auburn-pa': {
    name: 'Auburn',
    postalCode: '17922',
  },
  'auburn-wa': {
    name: 'Auburn',
    postalCode: '98001',
  },
  'auburn-wv': {
    name: 'Auburn',
    postalCode: '26325',
  },
  'auburn-wy': {
    name: 'Auburn',
    postalCode: '83111',
  },
  'auburn-hills-mi': {
    name: 'Auburn Hills',
    postalCode: '48326',
  },
  'auburndale-fl': {
    name: 'Auburndale',
    postalCode: '33823',
  },
  'auburndale-ma': {
    name: 'Auburndale',
    postalCode: '02466',
  },
  'auburndale-wi': {
    name: 'Auburndale',
    postalCode: '54412',
  },
  'auburntown-tn': {
    name: 'Auburntown',
    postalCode: '37016',
  },
  'audubon-ia': {
    name: 'Audubon',
    postalCode: '50025',
  },
  'audubon-mn': {
    name: 'Audubon',
    postalCode: '56511',
  },
  'audubon-nj': {
    name: 'Audubon',
    postalCode: '08106',
  },
  'augusta-ar': {
    name: 'Augusta',
    postalCode: '72006',
  },
  'augusta-ga': {
    name: 'Augusta',
    postalCode: '30901',
  },
  'augusta-il': {
    name: 'Augusta',
    postalCode: '62311',
  },
  'augusta-ks': {
    name: 'Augusta',
    postalCode: '67010',
  },
  'augusta-ky': {
    name: 'Augusta',
    postalCode: '41002',
  },
  'augusta-me': {
    name: 'Augusta',
    postalCode: '04330',
  },
  'augusta-mi': {
    name: 'Augusta',
    postalCode: '49012',
  },
  'augusta-mo': {
    name: 'Augusta',
    postalCode: '63332',
  },
  'augusta-nj': {
    name: 'Augusta',
    postalCode: '07822',
  },
  'augusta-wi': {
    name: 'Augusta',
    postalCode: '54722',
  },
  'augusta-wv': {
    name: 'Augusta',
    postalCode: '26704',
  },
  'aulander-nc': {
    name: 'Aulander',
    postalCode: '27805',
  },
  'ault-co': {
    name: 'Ault',
    postalCode: '80610',
  },
  'aultman-pa': {
    name: 'Aultman',
    postalCode: '15713',
  },
  'aumsville-or': {
    name: 'Aumsville',
    postalCode: '97325',
  },
  'aurelia-ia': {
    name: 'Aurelia',
    postalCode: '51005',
  },
  'auriesville-ny': {
    name: 'Auriesville',
    postalCode: '12016',
  },
  'aurora-co': {
    name: 'Aurora',
    postalCode: '80010',
  },
  'aurora-ia': {
    name: 'Aurora',
    postalCode: '50607',
  },
  'aurora-il': {
    name: 'Aurora',
    postalCode: '60502',
  },
  'aurora-in': {
    name: 'Aurora',
    postalCode: '47001',
  },
  'aurora-ks': {
    name: 'Aurora',
    postalCode: '67417',
  },
  'aurora-me': {
    name: 'Aurora',
    postalCode: '04408',
  },
  'aurora-mn': {
    name: 'Aurora',
    postalCode: '55705',
  },
  'aurora-mo': {
    name: 'Aurora',
    postalCode: '65605',
  },
  'aurora-nc': {
    name: 'Aurora',
    postalCode: '27806',
  },
  'aurora-ne': {
    name: 'Aurora',
    postalCode: '68818',
  },
  'aurora-ny': {
    name: 'Aurora',
    postalCode: '13026',
  },
  'aurora-oh': {
    name: 'Aurora',
    postalCode: '44202',
  },
  'aurora-or': {
    name: 'Aurora',
    postalCode: '97002',
  },
  'aurora-sd': {
    name: 'Aurora',
    postalCode: '57002',
  },
  'aurora-wv': {
    name: 'Aurora',
    postalCode: '26705',
  },
  'austell-ga': {
    name: 'Austell',
    postalCode: '30106',
  },
  'austerlitz-ny': {
    name: 'Austerlitz',
    postalCode: '12017',
  },
  'austin-ar': {
    name: 'Austin',
    postalCode: '72007',
  },
  'austin-co': {
    name: 'Austin',
    postalCode: '81410',
  },
  'austin-in': {
    name: 'Austin',
    postalCode: '47102',
  },
  'austin-ky': {
    name: 'Austin',
    postalCode: '42123',
  },
  'austin-mn': {
    name: 'Austin',
    postalCode: '55912',
  },
  'austin-nv': {
    name: 'Austin',
    postalCode: '89310',
  },
  'austin-pa': {
    name: 'Austin',
    postalCode: '16720',
  },
  'austin-tx': {
    name: 'Austin',
    postalCode: '78701',
  },
  'austinburg-oh': {
    name: 'Austinburg',
    postalCode: '44010',
  },
  'austinville-ia': {
    name: 'Austinville',
    postalCode: '50608',
  },
  'austinville-va': {
    name: 'Austinville',
    postalCode: '24312',
  },
  'autaugaville-al': {
    name: 'Autaugaville',
    postalCode: '36003',
  },
  'autryville-nc': {
    name: 'Autryville',
    postalCode: '28318',
  },
  'auxier-ky': {
    name: 'Auxier',
    postalCode: '41602',
  },
  'auxvasse-mo': {
    name: 'Auxvasse',
    postalCode: '65231',
  },
  'ava-il': {
    name: 'Ava',
    postalCode: '62907',
  },
  'ava-mo': {
    name: 'Ava',
    postalCode: '65608',
  },
  'ava-ny': {
    name: 'Ava',
    postalCode: '13303',
  },
  'avalon-nj': {
    name: 'Avalon',
    postalCode: '08202',
  },
  'avalon-wi': {
    name: 'Avalon',
    postalCode: '53505',
  },
  'avella-pa': {
    name: 'Avella',
    postalCode: '15312',
  },
  'avenal-ca': {
    name: 'Avenal',
    postalCode: '93204',
  },
  'avenel-nj': {
    name: 'Avenel',
    postalCode: '07001',
  },
  'avenue-md': {
    name: 'Avenue',
    postalCode: '20609',
  },
  'avera-ga': {
    name: 'Avera',
    postalCode: '30803',
  },
  'averill-park-ny': {
    name: 'Averill Park',
    postalCode: '12018',
  },
  'avery-id': {
    name: 'Avery',
    postalCode: '83802',
  },
  'avery-tx': {
    name: 'Avery',
    postalCode: '75554',
  },
  'avilla-in': {
    name: 'Avilla',
    postalCode: '46710',
  },
  'avinger-tx': {
    name: 'Avinger',
    postalCode: '75630',
  },
  'aviston-il': {
    name: 'Aviston',
    postalCode: '62216',
  },
  'avoca-ia': {
    name: 'Avoca',
    postalCode: '51521',
  },
  'avoca-mi': {
    name: 'Avoca',
    postalCode: '48006',
  },
  'avoca-mn': {
    name: 'Avoca',
    postalCode: '56114',
  },
  'avoca-ne': {
    name: 'Avoca',
    postalCode: '68307',
  },
  'avoca-ny': {
    name: 'Avoca',
    postalCode: '14809',
  },
  'avoca-tx': {
    name: 'Avoca',
    postalCode: '79503',
  },
  'avoca-wi': {
    name: 'Avoca',
    postalCode: '53506',
  },
  'avon-ct': {
    name: 'Avon',
    postalCode: '06001',
  },
  'avon-il': {
    name: 'Avon',
    postalCode: '61415',
  },
  'avon-in': {
    name: 'Avon',
    postalCode: '46123',
  },
  'avon-ma': {
    name: 'Avon',
    postalCode: '02322',
  },
  'avon-mn': {
    name: 'Avon',
    postalCode: '56310',
  },
  'avon-ny': {
    name: 'Avon',
    postalCode: '14414',
  },
  'avon-oh': {
    name: 'Avon',
    postalCode: '44011',
  },
  'avon-sd': {
    name: 'Avon',
    postalCode: '57315',
  },
  'avon-by-the-sea-nj': {
    name: 'Avon By The Sea',
    postalCode: '07717',
  },
  'avon-lake-oh': {
    name: 'Avon Lake',
    postalCode: '44012',
  },
  'avon-park-fl': {
    name: 'Avon Park',
    postalCode: '33825',
  },
  'avondale-az': {
    name: 'Avondale',
    postalCode: '85323',
  },
  'avondale-co': {
    name: 'Avondale',
    postalCode: '81022',
  },
  'avondale-pa': {
    name: 'Avondale',
    postalCode: '19311',
  },
  'avondale-estates-ga': {
    name: 'Avondale Estates',
    postalCode: '30002',
  },
  'avonmore-pa': {
    name: 'Avonmore',
    postalCode: '15618',
  },
  'awendaw-sc': {
    name: 'Awendaw',
    postalCode: '29429',
  },
  'axis-al': {
    name: 'Axis',
    postalCode: '36505',
  },
  'axson-ga': {
    name: 'Axson',
    postalCode: '31624',
  },
  'axtell-ks': {
    name: 'Axtell',
    postalCode: '66403',
  },
  'axtell-ne': {
    name: 'Axtell',
    postalCode: '68924',
  },
  'axtell-tx': {
    name: 'Axtell',
    postalCode: '76624',
  },
  'axtell-ut': {
    name: 'Axtell',
    postalCode: '84621',
  },
  'axton-va': {
    name: 'Axton',
    postalCode: '24054',
  },
  'ayden-nc': {
    name: 'Ayden',
    postalCode: '28513',
  },
  'aydlett-nc': {
    name: 'Aydlett',
    postalCode: '27916',
  },
  'ayer-ma': {
    name: 'Ayer',
    postalCode: '01432',
  },
  'aylett-va': {
    name: 'Aylett',
    postalCode: '23009',
  },
  'aynor-sc': {
    name: 'Aynor',
    postalCode: '29511',
  },
  'ayr-nd': {
    name: 'Ayr',
    postalCode: '58007',
  },
  'ayr-ne': {
    name: 'Ayr',
    postalCode: '68925',
  },
  'ayrshire-ia': {
    name: 'Ayrshire',
    postalCode: '50515',
  },
  'azalea-or': {
    name: 'Azalea',
    postalCode: '97410',
  },
  'azle-tx': {
    name: 'Azle',
    postalCode: '76020',
  },
  'aztec-nm': {
    name: 'Aztec',
    postalCode: '87410',
  },
  'azusa-ca': {
    name: 'Azusa',
    postalCode: '91702',
  },
  'babbitt-mn': {
    name: 'Babbitt',
    postalCode: '55706',
  },
  'babson-park-fl': {
    name: 'Babson Park',
    postalCode: '33827',
  },
  'babylon-ny': {
    name: 'Babylon',
    postalCode: '11702',
  },
  'backus-mn': {
    name: 'Backus',
    postalCode: '56435',
  },
  'bacliff-tx': {
    name: 'Bacliff',
    postalCode: '77518',
  },
  'baconton-ga': {
    name: 'Baconton',
    postalCode: '31716',
  },
  'bad-axe-mi': {
    name: 'Bad Axe',
    postalCode: '48413',
  },
  'baden-pa': {
    name: 'Baden',
    postalCode: '15005',
  },
  'badger-ca': {
    name: 'Badger',
    postalCode: '93603',
  },
  'badger-ia': {
    name: 'Badger',
    postalCode: '50516',
  },
  'badger-mn': {
    name: 'Badger',
    postalCode: '56714',
  },
  'bagdad-az': {
    name: 'Bagdad',
    postalCode: '86321',
  },
  'bagdad-ky': {
    name: 'Bagdad',
    postalCode: '40003',
  },
  'baggs-wy': {
    name: 'Baggs',
    postalCode: '82321',
  },
  'bagley-ia': {
    name: 'Bagley',
    postalCode: '50026',
  },
  'bagley-mn': {
    name: 'Bagley',
    postalCode: '56621',
  },
  'bagley-wi': {
    name: 'Bagley',
    postalCode: '53801',
  },
  'bagwell-tx': {
    name: 'Bagwell',
    postalCode: '75412',
  },
  'bahama-nc': {
    name: 'Bahama',
    postalCode: '27503',
  },
  'bailey-co': {
    name: 'Bailey',
    postalCode: '80421',
  },
  'bailey-mi': {
    name: 'Bailey',
    postalCode: '49303',
  },
  'bailey-ms': {
    name: 'Bailey',
    postalCode: '39320',
  },
  'bailey-nc': {
    name: 'Bailey',
    postalCode: '27807',
  },
  'bailey-island-me': {
    name: 'Bailey Island',
    postalCode: '04003',
  },
  'baileys-harbor-wi': {
    name: 'Baileys Harbor',
    postalCode: '54202',
  },
  'baileyton-al': {
    name: 'Baileyton',
    postalCode: '35019',
  },
  'baileyville-il': {
    name: 'Baileyville',
    postalCode: '61007',
  },
  'baileyville-ks': {
    name: 'Baileyville',
    postalCode: '66404',
  },
  'baileyville-me': {
    name: 'Baileyville',
    postalCode: '04694',
  },
  'bainbridge-ga': {
    name: 'Bainbridge',
    postalCode: '39817',
  },
  'bainbridge-in': {
    name: 'Bainbridge',
    postalCode: '46105',
  },
  'bainbridge-ny': {
    name: 'Bainbridge',
    postalCode: '13733',
  },
  'bainbridge-oh': {
    name: 'Bainbridge',
    postalCode: '45612',
  },
  'bainbridge-pa': {
    name: 'Bainbridge',
    postalCode: '17502',
  },
  'bainbridge-island-wa': {
    name: 'Bainbridge Island',
    postalCode: '98110',
  },
  'bainville-mt': {
    name: 'Bainville',
    postalCode: '59212',
  },
  'baird-tx': {
    name: 'Baird',
    postalCode: '79504',
  },
  'baisden-wv': {
    name: 'Baisden',
    postalCode: '25608',
  },
  'bajadero-pr': {
    name: 'Bajadero',
    postalCode: '00616',
  },
  'baker-ca': {
    name: 'Baker',
    postalCode: '92309',
  },
  'baker-fl': {
    name: 'Baker',
    postalCode: '32531',
  },
  'baker-la': {
    name: 'Baker',
    postalCode: '70714',
  },
  'baker-mt': {
    name: 'Baker',
    postalCode: '59313',
  },
  'baker-nv': {
    name: 'Baker',
    postalCode: '89311',
  },
  'baker-wv': {
    name: 'Baker',
    postalCode: '26801',
  },
  'baker-city-or': {
    name: 'Baker City',
    postalCode: '97814',
  },
  'bakersfield-ca': {
    name: 'Bakersfield',
    postalCode: '93301',
  },
  'bakersfield-mo': {
    name: 'Bakersfield',
    postalCode: '65609',
  },
  'bakersfield-vt': {
    name: 'Bakersfield',
    postalCode: '05441',
  },
  'bakerstown-pa': {
    name: 'Bakerstown',
    postalCode: '15007',
  },
  'bakersville-nc': {
    name: 'Bakersville',
    postalCode: '28705',
  },
  'bakewell-tn': {
    name: 'Bakewell',
    postalCode: '37304',
  },
  'bala-cynwyd-pa': {
    name: 'Bala Cynwyd',
    postalCode: '19004',
  },
  'balaton-mn': {
    name: 'Balaton',
    postalCode: '56115',
  },
  'balch-springs-tx': {
    name: 'Balch Springs',
    postalCode: '75180',
  },
  'bald-knob-ar': {
    name: 'Bald Knob',
    postalCode: '72010',
  },
  'baldwin-ga': {
    name: 'Baldwin',
    postalCode: '30511',
  },
  'baldwin-ia': {
    name: 'Baldwin',
    postalCode: '52207',
  },
  'baldwin-il': {
    name: 'Baldwin',
    postalCode: '62217',
  },
  'baldwin-la': {
    name: 'Baldwin',
    postalCode: '70514',
  },
  'baldwin-md': {
    name: 'Baldwin',
    postalCode: '21013',
  },
  'baldwin-mi': {
    name: 'Baldwin',
    postalCode: '49304',
  },
  'baldwin-nd': {
    name: 'Baldwin',
    postalCode: '58521',
  },
  'baldwin-ny': {
    name: 'Baldwin',
    postalCode: '11510',
  },
  'baldwin-wi': {
    name: 'Baldwin',
    postalCode: '54002',
  },
  'baldwin-city-ks': {
    name: 'Baldwin City',
    postalCode: '66006',
  },
  'baldwin-park-ca': {
    name: 'Baldwin Park',
    postalCode: '91706',
  },
  'baldwin-place-ny': {
    name: 'Baldwin Place',
    postalCode: '10505',
  },
  'baldwinsville-ny': {
    name: 'Baldwinsville',
    postalCode: '13027',
  },
  'baldwinville-ma': {
    name: 'Baldwinville',
    postalCode: '01436',
  },
  'baldwyn-ms': {
    name: 'Baldwyn',
    postalCode: '38824',
  },
  'balfour-nd': {
    name: 'Balfour',
    postalCode: '58712',
  },
  'balko-ok': {
    name: 'Balko',
    postalCode: '73931',
  },
  'ball-la': {
    name: 'Ball',
    postalCode: '71405',
  },
  'ball-ground-ga': {
    name: 'Ball Ground',
    postalCode: '30107',
  },
  'ballantine-mt': {
    name: 'Ballantine',
    postalCode: '59006',
  },
  'ballard-wv': {
    name: 'Ballard',
    postalCode: '24918',
  },
  'ballico-ca': {
    name: 'Ballico',
    postalCode: '95303',
  },
  'ballinger-tx': {
    name: 'Ballinger',
    postalCode: '76821',
  },
  'ballston-lake-ny': {
    name: 'Ballston Lake',
    postalCode: '12019',
  },
  'ballston-spa-ny': {
    name: 'Ballston Spa',
    postalCode: '12020',
  },
  'ballwin-mo': {
    name: 'Ballwin',
    postalCode: '63011',
  },
  'bally-pa': {
    name: 'Bally',
    postalCode: '19503',
  },
  'balmorhea-tx': {
    name: 'Balmorhea',
    postalCode: '79718',
  },
  'balsam-grove-nc': {
    name: 'Balsam Grove',
    postalCode: '28708',
  },
  'balsam-lake-wi': {
    name: 'Balsam Lake',
    postalCode: '54810',
  },
  'balta-nd': {
    name: 'Balta',
    postalCode: '58313',
  },
  'baltic-ct': {
    name: 'Baltic',
    postalCode: '06330',
  },
  'baltic-oh': {
    name: 'Baltic',
    postalCode: '43804',
  },
  'baltic-sd': {
    name: 'Baltic',
    postalCode: '57003',
  },
  'baltimore-md': {
    name: 'Baltimore',
    postalCode: '21201',
  },
  'baltimore-oh': {
    name: 'Baltimore',
    postalCode: '43105',
  },
  'bamberg-sc': {
    name: 'Bamberg',
    postalCode: '29003',
  },
  'banco-va': {
    name: 'Banco',
    postalCode: '22711',
  },
  'bancroft-ia': {
    name: 'Bancroft',
    postalCode: '50517',
  },
  'bancroft-id': {
    name: 'Bancroft',
    postalCode: '83217',
  },
  'bancroft-mi': {
    name: 'Bancroft',
    postalCode: '48414',
  },
  'bancroft-ne': {
    name: 'Bancroft',
    postalCode: '68004',
  },
  'bancroft-wi': {
    name: 'Bancroft',
    postalCode: '54921',
  },
  'bandera-tx': {
    name: 'Bandera',
    postalCode: '78003',
  },
  'bandon-or': {
    name: 'Bandon',
    postalCode: '97411',
  },
  'bandy-va': {
    name: 'Bandy',
    postalCode: '24602',
  },
  'bangor-ca': {
    name: 'Bangor',
    postalCode: '95914',
  },
  'bangor-me': {
    name: 'Bangor',
    postalCode: '04401',
  },
  'bangor-mi': {
    name: 'Bangor',
    postalCode: '49013',
  },
  'bangor-pa': {
    name: 'Bangor',
    postalCode: '18013',
  },
  'bangor-wi': {
    name: 'Bangor',
    postalCode: '54614',
  },
  'bangs-tx': {
    name: 'Bangs',
    postalCode: '76823',
  },
  'banks-al': {
    name: 'Banks',
    postalCode: '36005',
  },
  'banks-ar': {
    name: 'Banks',
    postalCode: '71631',
  },
  'banks-id': {
    name: 'Banks',
    postalCode: '83602',
  },
  'banks-or': {
    name: 'Banks',
    postalCode: '97106',
  },
  'bankston-al': {
    name: 'Bankston',
    postalCode: '35542',
  },
  'banner-ky': {
    name: 'Banner',
    postalCode: '41603',
  },
  'banner-ms': {
    name: 'Banner',
    postalCode: '38913',
  },
  'banner-wy': {
    name: 'Banner',
    postalCode: '82832',
  },
  'banner-elk-nc': {
    name: 'Banner Elk',
    postalCode: '28604',
  },
  'banning-ca': {
    name: 'Banning',
    postalCode: '92220',
  },
  'bannister-mi': {
    name: 'Bannister',
    postalCode: '48807',
  },
  'bantam-ct': {
    name: 'Bantam',
    postalCode: '06750',
  },
  'bantry-nd': {
    name: 'Bantry',
    postalCode: '58713',
  },
  'bar-harbor-me': {
    name: 'Bar Harbor',
    postalCode: '04609',
  },
  'baraboo-wi': {
    name: 'Baraboo',
    postalCode: '53913',
  },
  'baraga-mi': {
    name: 'Baraga',
    postalCode: '49908',
  },
  'barataria-la': {
    name: 'Barataria',
    postalCode: '70036',
  },
  'barbeau-mi': {
    name: 'Barbeau',
    postalCode: '49710',
  },
  'barberton-oh': {
    name: 'Barberton',
    postalCode: '44203',
  },
  'barboursville-va': {
    name: 'Barboursville',
    postalCode: '22923',
  },
  'barboursville-wv': {
    name: 'Barboursville',
    postalCode: '25504',
  },
  'barbourville-ky': {
    name: 'Barbourville',
    postalCode: '40906',
  },
  'barceloneta-pr': {
    name: 'Barceloneta',
    postalCode: '00617',
  },
  'barclay-md': {
    name: 'Barclay',
    postalCode: '21607',
  },
  'barco-nc': {
    name: 'Barco',
    postalCode: '27917',
  },
  'bard-nm': {
    name: 'Bard',
    postalCode: '88411',
  },
  'bardstown-ky': {
    name: 'Bardstown',
    postalCode: '40004',
  },
  'bardwell-ky': {
    name: 'Bardwell',
    postalCode: '42023',
  },
  'bargersville-in': {
    name: 'Bargersville',
    postalCode: '46106',
  },
  'barhamsville-va': {
    name: 'Barhamsville',
    postalCode: '23011',
  },
  'baring-mo': {
    name: 'Baring',
    postalCode: '63531',
  },
  'bark-river-mi': {
    name: 'Bark River',
    postalCode: '49807',
  },
  'barker-ny': {
    name: 'Barker',
    postalCode: '14012',
  },
  'barkhamsted-ct': {
    name: 'Barkhamsted',
    postalCode: '06063',
  },
  'barksdale-tx': {
    name: 'Barksdale',
    postalCode: '78828',
  },
  'barksdale-afb-la': {
    name: 'Barksdale AFB',
    postalCode: '71110',
  },
  'barling-ar': {
    name: 'Barling',
    postalCode: '72923',
  },
  'barlow-ky': {
    name: 'Barlow',
    postalCode: '42024',
  },
  'barnard-ks': {
    name: 'Barnard',
    postalCode: '67418',
  },
  'barnard-mo': {
    name: 'Barnard',
    postalCode: '64423',
  },
  'barnardsville-nc': {
    name: 'Barnardsville',
    postalCode: '28709',
  },
  'barnegat-nj': {
    name: 'Barnegat',
    postalCode: '08005',
  },
  'barnes-ks': {
    name: 'Barnes',
    postalCode: '66933',
  },
  'barnes-city-ia': {
    name: 'Barnes City',
    postalCode: '50027',
  },
  'barneston-ne': {
    name: 'Barneston',
    postalCode: '68309',
  },
  'barnesville-ga': {
    name: 'Barnesville',
    postalCode: '30204',
  },
  'barnesville-md': {
    name: 'Barnesville',
    postalCode: '20838',
  },
  'barnesville-mn': {
    name: 'Barnesville',
    postalCode: '56514',
  },
  'barnesville-oh': {
    name: 'Barnesville',
    postalCode: '43713',
  },
  'barnesville-pa': {
    name: 'Barnesville',
    postalCode: '18214',
  },
  'barnet-vt': {
    name: 'Barnet',
    postalCode: '05821',
  },
  'barnett-mo': {
    name: 'Barnett',
    postalCode: '65011',
  },
  'barneveld-ny': {
    name: 'Barneveld',
    postalCode: '13304',
  },
  'barneveld-wi': {
    name: 'Barneveld',
    postalCode: '53507',
  },
  'barney-ga': {
    name: 'Barney',
    postalCode: '31625',
  },
  'barney-nd': {
    name: 'Barney',
    postalCode: '58008',
  },
  'barnhart-mo': {
    name: 'Barnhart',
    postalCode: '63012',
  },
  'barnhill-il': {
    name: 'Barnhill',
    postalCode: '62809',
  },
  'barnsdall-ok': {
    name: 'Barnsdall',
    postalCode: '74002',
  },
  'barnstable-ma': {
    name: 'Barnstable',
    postalCode: '02630',
  },
  'barnstead-nh': {
    name: 'Barnstead',
    postalCode: '03218',
  },
  'barnum-ia': {
    name: 'Barnum',
    postalCode: '50518',
  },
  'barnum-mn': {
    name: 'Barnum',
    postalCode: '55707',
  },
  'barnwell-sc': {
    name: 'Barnwell',
    postalCode: '29812',
  },
  'baroda-mi': {
    name: 'Baroda',
    postalCode: '49101',
  },
  'barranquitas-pr': {
    name: 'Barranquitas',
    postalCode: '00794',
  },
  'barre-ma': {
    name: 'Barre',
    postalCode: '01005',
  },
  'barre-vt': {
    name: 'Barre',
    postalCode: '05641',
  },
  'barren-springs-va': {
    name: 'Barren Springs',
    postalCode: '24313',
  },
  'barrett-mn': {
    name: 'Barrett',
    postalCode: '56311',
  },
  'barrigada-gu': {
    name: 'Barrigada',
    postalCode: '96913',
  },
  'barrington-il': {
    name: 'Barrington',
    postalCode: '60010',
  },
  'barrington-nh': {
    name: 'Barrington',
    postalCode: '03825',
  },
  'barrington-nj': {
    name: 'Barrington',
    postalCode: '08007',
  },
  'barrington-ri': {
    name: 'Barrington',
    postalCode: '02806',
  },
  'barron-wi': {
    name: 'Barron',
    postalCode: '54812',
  },
  'barronett-wi': {
    name: 'Barronett',
    postalCode: '54813',
  },
  'barry-il': {
    name: 'Barry',
    postalCode: '62312',
  },
  'barry-tx': {
    name: 'Barry',
    postalCode: '75102',
  },
  'barryton-mi': {
    name: 'Barryton',
    postalCode: '49305',
  },
  'barrytown-ny': {
    name: 'Barrytown',
    postalCode: '12507',
  },
  'barryville-ny': {
    name: 'Barryville',
    postalCode: '12719',
  },
  'barstow-ca': {
    name: 'Barstow',
    postalCode: '92311',
  },
  'bartelso-il': {
    name: 'Bartelso',
    postalCode: '62218',
  },
  'bartlesville-ok': {
    name: 'Bartlesville',
    postalCode: '74003',
  },
  'bartlett-il': {
    name: 'Bartlett',
    postalCode: '60103',
  },
  'bartlett-ks': {
    name: 'Bartlett',
    postalCode: '67332',
  },
  'bartlett-ne': {
    name: 'Bartlett',
    postalCode: '68622',
  },
  'bartlett-nh': {
    name: 'Bartlett',
    postalCode: '03812',
  },
  'bartlett-tx': {
    name: 'Bartlett',
    postalCode: '76511',
  },
  'bartley-ne': {
    name: 'Bartley',
    postalCode: '69020',
  },
  'barto-pa': {
    name: 'Barto',
    postalCode: '19504',
  },
  'barton-md': {
    name: 'Barton',
    postalCode: '21521',
  },
  'barton-ny': {
    name: 'Barton',
    postalCode: '13734',
  },
  'barton-vt': {
    name: 'Barton',
    postalCode: '05822',
  },
  'barton-city-mi': {
    name: 'Barton City',
    postalCode: '48705',
  },
  'bartonsville-pa': {
    name: 'Bartonsville',
    postalCode: '18321',
  },
  'bartow-fl': {
    name: 'Bartow',
    postalCode: '33830',
  },
  'bartow-ga': {
    name: 'Bartow',
    postalCode: '30413',
  },
  'bartow-wv': {
    name: 'Bartow',
    postalCode: '24920',
  },
  'basalt-co': {
    name: 'Basalt',
    postalCode: '81621',
  },
  'basco-il': {
    name: 'Basco',
    postalCode: '62313',
  },
  'bascom-fl': {
    name: 'Bascom',
    postalCode: '32423',
  },
  'basehor-ks': {
    name: 'Basehor',
    postalCode: '66007',
  },
  'basile-la': {
    name: 'Basile',
    postalCode: '70515',
  },
  'basin-wy': {
    name: 'Basin',
    postalCode: '82410',
  },
  'baskerville-va': {
    name: 'Baskerville',
    postalCode: '23915',
  },
  'baskin-la': {
    name: 'Baskin',
    postalCode: '71219',
  },
  'basking-ridge-nj': {
    name: 'Basking Ridge',
    postalCode: '07920',
  },
  'basom-ny': {
    name: 'Basom',
    postalCode: '14013',
  },
  'bass-harbor-me': {
    name: 'Bass Harbor',
    postalCode: '04653',
  },
  'bass-lake-ca': {
    name: 'Bass Lake',
    postalCode: '93604',
  },
  'bassett-ne': {
    name: 'Bassett',
    postalCode: '68714',
  },
  'bassett-va': {
    name: 'Bassett',
    postalCode: '24055',
  },
  'bassfield-ms': {
    name: 'Bassfield',
    postalCode: '39421',
  },
  'bastian-va': {
    name: 'Bastian',
    postalCode: '24314',
  },
  'bastrop-la': {
    name: 'Bastrop',
    postalCode: '71220',
  },
  'bastrop-tx': {
    name: 'Bastrop',
    postalCode: '78602',
  },
  'basye-va': {
    name: 'Basye',
    postalCode: '22810',
  },
  'batavia-ia': {
    name: 'Batavia',
    postalCode: '52533',
  },
  'batavia-il': {
    name: 'Batavia',
    postalCode: '60510',
  },
  'batavia-ny': {
    name: 'Batavia',
    postalCode: '14020',
  },
  'batavia-oh': {
    name: 'Batavia',
    postalCode: '45103',
  },
  'batchelor-la': {
    name: 'Batchelor',
    postalCode: '70715',
  },
  'batchtown-il': {
    name: 'Batchtown',
    postalCode: '62006',
  },
  'bates-or': {
    name: 'Bates',
    postalCode: '97817',
  },
  'bates-city-mo': {
    name: 'Bates City',
    postalCode: '64011',
  },
  'batesburg-sc': {
    name: 'Batesburg',
    postalCode: '29006',
  },
  'batesland-sd': {
    name: 'Batesland',
    postalCode: '57716',
  },
  'batesville-ar': {
    name: 'Batesville',
    postalCode: '72501',
  },
  'batesville-in': {
    name: 'Batesville',
    postalCode: '47006',
  },
  'batesville-ms': {
    name: 'Batesville',
    postalCode: '38606',
  },
  'batesville-tx': {
    name: 'Batesville',
    postalCode: '78829',
  },
  'bath-il': {
    name: 'Bath',
    postalCode: '62617',
  },
  'bath-in': {
    name: 'Bath',
    postalCode: '47010',
  },
  'bath-me': {
    name: 'Bath',
    postalCode: '04530',
  },
  'bath-mi': {
    name: 'Bath',
    postalCode: '48808',
  },
  'bath-nc': {
    name: 'Bath',
    postalCode: '27808',
  },
  'bath-nh': {
    name: 'Bath',
    postalCode: '03740',
  },
  'bath-ny': {
    name: 'Bath',
    postalCode: '14810',
  },
  'bath-pa': {
    name: 'Bath',
    postalCode: '18014',
  },
  'bath-sd': {
    name: 'Bath',
    postalCode: '57427',
  },
  'bath-springs-tn': {
    name: 'Bath Springs',
    postalCode: '38311',
  },
  'bathgate-nd': {
    name: 'Bathgate',
    postalCode: '58216',
  },
  'baton-rouge-la': {
    name: 'Baton Rouge',
    postalCode: '70801',
  },
  'batson-tx': {
    name: 'Batson',
    postalCode: '77519',
  },
  'battle-creek-ia': {
    name: 'Battle Creek',
    postalCode: '51006',
  },
  'battle-creek-mi': {
    name: 'Battle Creek',
    postalCode: '49014',
  },
  'battle-creek-ne': {
    name: 'Battle Creek',
    postalCode: '68715',
  },
  'battle-ground-in': {
    name: 'Battle Ground',
    postalCode: '47920',
  },
  'battle-ground-wa': {
    name: 'Battle Ground',
    postalCode: '98604',
  },
  'battle-lake-mn': {
    name: 'Battle Lake',
    postalCode: '56515',
  },
  'battle-mountain-nv': {
    name: 'Battle Mountain',
    postalCode: '89820',
  },
  'battleboro-nc': {
    name: 'Battleboro',
    postalCode: '27809',
  },
  'battletown-ky': {
    name: 'Battletown',
    postalCode: '40104',
  },
  'baudette-mn': {
    name: 'Baudette',
    postalCode: '56623',
  },
  'bauxite-ar': {
    name: 'Bauxite',
    postalCode: '72011',
  },
  'baxley-ga': {
    name: 'Baxley',
    postalCode: '31513',
  },
  'baxter-ia': {
    name: 'Baxter',
    postalCode: '50028',
  },
  'baxter-ky': {
    name: 'Baxter',
    postalCode: '40806',
  },
  'baxter-mn': {
    name: 'Baxter',
    postalCode: '56425',
  },
  'baxter-tn': {
    name: 'Baxter',
    postalCode: '38544',
  },
  'baxter-wv': {
    name: 'Baxter',
    postalCode: '26560',
  },
  'baxter-springs-ks': {
    name: 'Baxter Springs',
    postalCode: '66713',
  },
  'bay-ar': {
    name: 'Bay',
    postalCode: '72411',
  },
  'bay-city-mi': {
    name: 'Bay City',
    postalCode: '48706',
  },
  'bay-city-or': {
    name: 'Bay City',
    postalCode: '97107',
  },
  'bay-city-tx': {
    name: 'Bay City',
    postalCode: '77414',
  },
  'bay-city-wi': {
    name: 'Bay City',
    postalCode: '54723',
  },
  'bay-minette-al': {
    name: 'Bay Minette',
    postalCode: '36507',
  },
  'bay-port-mi': {
    name: 'Bay Port',
    postalCode: '48720',
  },
  'bay-saint-louis-ms': {
    name: 'Bay Saint Louis',
    postalCode: '39520',
  },
  'bay-shore-ny': {
    name: 'Bay Shore',
    postalCode: '11706',
  },
  'bay-springs-ms': {
    name: 'Bay Springs',
    postalCode: '39422',
  },
  'bay-village-oh': {
    name: 'Bay Village',
    postalCode: '44140',
  },
  'bayamon-pr': {
    name: 'Bayamon',
    postalCode: '00956',
  },
  'bayard-ia': {
    name: 'Bayard',
    postalCode: '50029',
  },
  'bayard-ne': {
    name: 'Bayard',
    postalCode: '69334',
  },
  'bayard-nm': {
    name: 'Bayard',
    postalCode: '88023',
  },
  'bayboro-nc': {
    name: 'Bayboro',
    postalCode: '28515',
  },
  'bayfield-co': {
    name: 'Bayfield',
    postalCode: '81122',
  },
  'bayfield-wi': {
    name: 'Bayfield',
    postalCode: '54814',
  },
  'baylis-il': {
    name: 'Baylis',
    postalCode: '62314',
  },
  'bayonne-nj': {
    name: 'Bayonne',
    postalCode: '07002',
  },
  'bayou-la-batre-al': {
    name: 'Bayou La Batre',
    postalCode: '36509',
  },
  'bayport-mn': {
    name: 'Bayport',
    postalCode: '55003',
  },
  'bayport-ny': {
    name: 'Bayport',
    postalCode: '11705',
  },
  'bays-ky': {
    name: 'Bays',
    postalCode: '41310',
  },
  'bayside-ca': {
    name: 'Bayside',
    postalCode: '95524',
  },
  'bayside-ny': {
    name: 'Bayside',
    postalCode: '11359',
  },
  'bayside-tx': {
    name: 'Bayside',
    postalCode: '78340',
  },
  'baytown-tx': {
    name: 'Baytown',
    postalCode: '77520',
  },
  'bayview-id': {
    name: 'Bayview',
    postalCode: '83803',
  },
  'bayville-nj': {
    name: 'Bayville',
    postalCode: '08721',
  },
  'bayville-ny': {
    name: 'Bayville',
    postalCode: '11709',
  },
  'bazine-ks': {
    name: 'Bazine',
    postalCode: '67516',
  },
  'beach-nd': {
    name: 'Beach',
    postalCode: '58621',
  },
  'beach-city-oh': {
    name: 'Beach City',
    postalCode: '44608',
  },
  'beach-haven-nj': {
    name: 'Beach Haven',
    postalCode: '08008',
  },
  'beach-lake-pa': {
    name: 'Beach Lake',
    postalCode: '18405',
  },
  'beachwood-nj': {
    name: 'Beachwood',
    postalCode: '08722',
  },
  'beachwood-oh': {
    name: 'Beachwood',
    postalCode: '44122',
  },
  'beacon-ia': {
    name: 'Beacon',
    postalCode: '52534',
  },
  'beacon-ny': {
    name: 'Beacon',
    postalCode: '12508',
  },
  'beacon-falls-ct': {
    name: 'Beacon Falls',
    postalCode: '06403',
  },
  'beale-afb-ca': {
    name: 'Beale AFB',
    postalCode: '95903',
  },
  'bealeton-va': {
    name: 'Bealeton',
    postalCode: '22712',
  },
  'beallsville-md': {
    name: 'Beallsville',
    postalCode: '20839',
  },
  'beallsville-oh': {
    name: 'Beallsville',
    postalCode: '43716',
  },
  'beaman-ia': {
    name: 'Beaman',
    postalCode: '50609',
  },
  'bean-station-tn': {
    name: 'Bean Station',
    postalCode: '37708',
  },
  'bear-de': {
    name: 'Bear',
    postalCode: '19701',
  },
  'bear-branch-ky': {
    name: 'Bear Branch',
    postalCode: '41714',
  },
  'bear-creek-al': {
    name: 'Bear Creek',
    postalCode: '35543',
  },
  'bear-creek-nc': {
    name: 'Bear Creek',
    postalCode: '27207',
  },
  'bear-creek-wi': {
    name: 'Bear Creek',
    postalCode: '54922',
  },
  'bear-lake-mi': {
    name: 'Bear Lake',
    postalCode: '49614',
  },
  'bear-lake-pa': {
    name: 'Bear Lake',
    postalCode: '16402',
  },
  'bear-mountain-ny': {
    name: 'Bear Mountain',
    postalCode: '10911',
  },
  'bearcreek-mt': {
    name: 'Bearcreek',
    postalCode: '59007',
  },
  'bearden-ar': {
    name: 'Bearden',
    postalCode: '71720',
  },
  'beardsley-mn': {
    name: 'Beardsley',
    postalCode: '56211',
  },
  'beardstown-il': {
    name: 'Beardstown',
    postalCode: '62618',
  },
  'bearsville-ny': {
    name: 'Bearsville',
    postalCode: '12409',
  },
  'beasley-tx': {
    name: 'Beasley',
    postalCode: '77417',
  },
  'beason-il': {
    name: 'Beason',
    postalCode: '62512',
  },
  'beatrice-al': {
    name: 'Beatrice',
    postalCode: '36425',
  },
  'beatrice-ne': {
    name: 'Beatrice',
    postalCode: '68310',
  },
  'beattie-ks': {
    name: 'Beattie',
    postalCode: '66406',
  },
  'beatty-or': {
    name: 'Beatty',
    postalCode: '97621',
  },
  'beattyville-ky': {
    name: 'Beattyville',
    postalCode: '41311',
  },
  'beaufort-mo': {
    name: 'Beaufort',
    postalCode: '63013',
  },
  'beaufort-nc': {
    name: 'Beaufort',
    postalCode: '28516',
  },
  'beaufort-sc': {
    name: 'Beaufort',
    postalCode: '29902',
  },
  'beaumont-ca': {
    name: 'Beaumont',
    postalCode: '92223',
  },
  'beaumont-ky': {
    name: 'Beaumont',
    postalCode: '42124',
  },
  'beaumont-ms': {
    name: 'Beaumont',
    postalCode: '39423',
  },
  'beaumont-tx': {
    name: 'Beaumont',
    postalCode: '77701',
  },
  'beaumont-va': {
    name: 'Beaumont',
    postalCode: '23014',
  },
  'beauty-ky': {
    name: 'Beauty',
    postalCode: '41203',
  },
  'beaver-ky': {
    name: 'Beaver',
    postalCode: '41604',
  },
  'beaver-oh': {
    name: 'Beaver',
    postalCode: '45613',
  },
  'beaver-ok': {
    name: 'Beaver',
    postalCode: '73932',
  },
  'beaver-or': {
    name: 'Beaver',
    postalCode: '97108',
  },
  'beaver-pa': {
    name: 'Beaver',
    postalCode: '15009',
  },
  'beaver-ut': {
    name: 'Beaver',
    postalCode: '84713',
  },
  'beaver-wa': {
    name: 'Beaver',
    postalCode: '98305',
  },
  'beaver-wv': {
    name: 'Beaver',
    postalCode: '25813',
  },
  'beaver-city-ne': {
    name: 'Beaver City',
    postalCode: '68926',
  },
  'beaver-creek-mn': {
    name: 'Beaver Creek',
    postalCode: '56116',
  },
  'beaver-crossing-ne': {
    name: 'Beaver Crossing',
    postalCode: '68313',
  },
  'beaver-dam-ky': {
    name: 'Beaver Dam',
    postalCode: '42320',
  },
  'beaver-dam-wi': {
    name: 'Beaver Dam',
    postalCode: '53916',
  },
  'beaver-dams-ny': {
    name: 'Beaver Dams',
    postalCode: '14812',
  },
  'beaver-falls-pa': {
    name: 'Beaver Falls',
    postalCode: '15010',
  },
  'beaver-island-mi': {
    name: 'Beaver Island',
    postalCode: '49782',
  },
  'beaver-meadows-pa': {
    name: 'Beaver Meadows',
    postalCode: '18216',
  },
  'beaver-springs-pa': {
    name: 'Beaver Springs',
    postalCode: '17812',
  },
  'beavercreek-or': {
    name: 'Beavercreek',
    postalCode: '97004',
  },
  'beaverdam-oh': {
    name: 'Beaverdam',
    postalCode: '45808',
  },
  'beaverdam-va': {
    name: 'Beaverdam',
    postalCode: '23015',
  },
  'beaverton-al': {
    name: 'Beaverton',
    postalCode: '35544',
  },
  'beaverton-mi': {
    name: 'Beaverton',
    postalCode: '48612',
  },
  'beaverton-or': {
    name: 'Beaverton',
    postalCode: '97003',
  },
  'beavertown-pa': {
    name: 'Beavertown',
    postalCode: '17813',
  },
  'beaverville-il': {
    name: 'Beaverville',
    postalCode: '60912',
  },
  'beccaria-pa': {
    name: 'Beccaria',
    postalCode: '16616',
  },
  'bechtelsville-pa': {
    name: 'Bechtelsville',
    postalCode: '19505',
  },
  'becker-mn': {
    name: 'Becker',
    postalCode: '55308',
  },
  'becket-ma': {
    name: 'Becket',
    postalCode: '01223',
  },
  'beckley-wv': {
    name: 'Beckley',
    postalCode: '25801',
  },
  'beckville-tx': {
    name: 'Beckville',
    postalCode: '75631',
  },
  'beckwourth-ca': {
    name: 'Beckwourth',
    postalCode: '96129',
  },
  'bedford-ia': {
    name: 'Bedford',
    postalCode: '50833',
  },
  'bedford-in': {
    name: 'Bedford',
    postalCode: '47421',
  },
  'bedford-ky': {
    name: 'Bedford',
    postalCode: '40006',
  },
  'bedford-ma': {
    name: 'Bedford',
    postalCode: '01730',
  },
  'bedford-nh': {
    name: 'Bedford',
    postalCode: '03110',
  },
  'bedford-ny': {
    name: 'Bedford',
    postalCode: '10506',
  },
  'bedford-oh': {
    name: 'Bedford',
    postalCode: '44146',
  },
  'bedford-pa': {
    name: 'Bedford',
    postalCode: '15522',
  },
  'bedford-tx': {
    name: 'Bedford',
    postalCode: '76021',
  },
  'bedford-va': {
    name: 'Bedford',
    postalCode: '24523',
  },
  'bedford-wy': {
    name: 'Bedford',
    postalCode: '83112',
  },
  'bedford-hills-ny': {
    name: 'Bedford Hills',
    postalCode: '10507',
  },
  'bedias-tx': {
    name: 'Bedias',
    postalCode: '77831',
  },
  'bedminster-nj': {
    name: 'Bedminster',
    postalCode: '07921',
  },
  'bedrock-co': {
    name: 'Bedrock',
    postalCode: '81411',
  },
  'bee-ne': {
    name: 'Bee',
    postalCode: '68314',
  },
  'bee-va': {
    name: 'Bee',
    postalCode: '24217',
  },
  'bee-branch-ar': {
    name: 'Bee Branch',
    postalCode: '72013',
  },
  'bee-spring-ky': {
    name: 'Bee Spring',
    postalCode: '42207',
  },
  'beebe-ar': {
    name: 'Beebe',
    postalCode: '72012',
  },
  'beech-bluff-tn': {
    name: 'Beech Bluff',
    postalCode: '38313',
  },
  'beech-creek-ky': {
    name: 'Beech Creek',
    postalCode: '42321',
  },
  'beech-creek-pa': {
    name: 'Beech Creek',
    postalCode: '16822',
  },
  'beech-grove-ar': {
    name: 'Beech Grove',
    postalCode: '72412',
  },
  'beech-grove-in': {
    name: 'Beech Grove',
    postalCode: '46107',
  },
  'beech-island-sc': {
    name: 'Beech Island',
    postalCode: '29842',
  },
  'beecher-il': {
    name: 'Beecher',
    postalCode: '60401',
  },
  'beecher-city-il': {
    name: 'Beecher City',
    postalCode: '62414',
  },
  'beecher-falls-vt': {
    name: 'Beecher Falls',
    postalCode: '05902',
  },
  'beechgrove-tn': {
    name: 'Beechgrove',
    postalCode: '37018',
  },
  'beechmont-ky': {
    name: 'Beechmont',
    postalCode: '42323',
  },
  'beeler-ks': {
    name: 'Beeler',
    postalCode: '67518',
  },
  'beemer-ne': {
    name: 'Beemer',
    postalCode: '68716',
  },
  'beersheba-springs-tn': {
    name: 'Beersheba Springs',
    postalCode: '37305',
  },
  'beeson-wv': {
    name: 'Beeson',
    postalCode: '24714',
  },
  'beeville-tx': {
    name: 'Beeville',
    postalCode: '78102',
  },
  'beggs-ok': {
    name: 'Beggs',
    postalCode: '74421',
  },
  'bejou-mn': {
    name: 'Bejou',
    postalCode: '56516',
  },
  'bel-air-md': {
    name: 'Bel Air',
    postalCode: '21014',
  },
  'bel-alton-md': {
    name: 'Bel Alton',
    postalCode: '20611',
  },
  'belcamp-md': {
    name: 'Belcamp',
    postalCode: '21017',
  },
  'belcher-ky': {
    name: 'Belcher',
    postalCode: '41513',
  },
  'belcher-la': {
    name: 'Belcher',
    postalCode: '71004',
  },
  'belchertown-ma': {
    name: 'Belchertown',
    postalCode: '01007',
  },
  'belcourt-nd': {
    name: 'Belcourt',
    postalCode: '58316',
  },
  'belden-ms': {
    name: 'Belden',
    postalCode: '38826',
  },
  'belden-ne': {
    name: 'Belden',
    postalCode: '68717',
  },
  'beldenville-wi': {
    name: 'Beldenville',
    postalCode: '54003',
  },
  'belding-mi': {
    name: 'Belding',
    postalCode: '48809',
  },
  'belen-nm': {
    name: 'Belen',
    postalCode: '87002',
  },
  'belews-creek-nc': {
    name: 'Belews Creek',
    postalCode: '27009',
  },
  'belfair-wa': {
    name: 'Belfair',
    postalCode: '98528',
  },
  'belfast-me': {
    name: 'Belfast',
    postalCode: '04915',
  },
  'belfast-ny': {
    name: 'Belfast',
    postalCode: '14711',
  },
  'belfast-tn': {
    name: 'Belfast',
    postalCode: '37019',
  },
  'belfield-nd': {
    name: 'Belfield',
    postalCode: '58622',
  },
  'belford-nj': {
    name: 'Belford',
    postalCode: '07718',
  },
  'belfry-ky': {
    name: 'Belfry',
    postalCode: '41514',
  },
  'belfry-mt': {
    name: 'Belfry',
    postalCode: '59008',
  },
  'belgium-wi': {
    name: 'Belgium',
    postalCode: '53004',
  },
  'belgrade-me': {
    name: 'Belgrade',
    postalCode: '04917',
  },
  'belgrade-mn': {
    name: 'Belgrade',
    postalCode: '56312',
  },
  'belgrade-mo': {
    name: 'Belgrade',
    postalCode: '63622',
  },
  'belgrade-mt': {
    name: 'Belgrade',
    postalCode: '59714',
  },
  'belgrade-ne': {
    name: 'Belgrade',
    postalCode: '68623',
  },
  'belgrade-lakes-me': {
    name: 'Belgrade Lakes',
    postalCode: '04918',
  },
  'belhaven-nc': {
    name: 'Belhaven',
    postalCode: '27810',
  },
  'belington-wv': {
    name: 'Belington',
    postalCode: '26250',
  },
  'belknap-il': {
    name: 'Belknap',
    postalCode: '62908',
  },
  'bell-fl': {
    name: 'Bell',
    postalCode: '32619',
  },
  'bell-buckle-tn': {
    name: 'Bell Buckle',
    postalCode: '37020',
  },
  'bell-city-la': {
    name: 'Bell City',
    postalCode: '70630',
  },
  'bell-city-mo': {
    name: 'Bell City',
    postalCode: '63735',
  },
  'bell-gardens-ca': {
    name: 'Bell Gardens',
    postalCode: '90201',
  },
  'bella-vista-ar': {
    name: 'Bella Vista',
    postalCode: '72714',
  },
  'bella-vista-ca': {
    name: 'Bella Vista',
    postalCode: '96008',
  },
  'bellaire-mi': {
    name: 'Bellaire',
    postalCode: '49615',
  },
  'bellaire-oh': {
    name: 'Bellaire',
    postalCode: '43906',
  },
  'bellaire-tx': {
    name: 'Bellaire',
    postalCode: '77401',
  },
  'bellbrook-oh': {
    name: 'Bellbrook',
    postalCode: '45305',
  },
  'belle-mo': {
    name: 'Belle',
    postalCode: '65013',
  },
  'belle-wv': {
    name: 'Belle',
    postalCode: '25015',
  },
  'belle-center-oh': {
    name: 'Belle Center',
    postalCode: '43310',
  },
  'belle-chasse-la': {
    name: 'Belle Chasse',
    postalCode: '70037',
  },
  'belle-fourche-sd': {
    name: 'Belle Fourche',
    postalCode: '57717',
  },
  'belle-glade-fl': {
    name: 'Belle Glade',
    postalCode: '33430',
  },
  'belle-haven-va': {
    name: 'Belle Haven',
    postalCode: '23306',
  },
  'belle-mead-nj': {
    name: 'Belle Mead',
    postalCode: '08502',
  },
  'belle-plaine-ia': {
    name: 'Belle Plaine',
    postalCode: '52208',
  },
  'belle-plaine-ks': {
    name: 'Belle Plaine',
    postalCode: '67013',
  },
  'belle-plaine-mn': {
    name: 'Belle Plaine',
    postalCode: '56011',
  },
  'belle-rive-il': {
    name: 'Belle Rive',
    postalCode: '62810',
  },
  'belle-rose-la': {
    name: 'Belle Rose',
    postalCode: '70341',
  },
  'belle-vernon-pa': {
    name: 'Belle Vernon',
    postalCode: '15012',
  },
  'belleair-beach-fl': {
    name: 'Belleair Beach',
    postalCode: '33786',
  },
  'bellefontaine-ms': {
    name: 'Bellefontaine',
    postalCode: '39737',
  },
  'bellefontaine-oh': {
    name: 'Bellefontaine',
    postalCode: '43311',
  },
  'bellefonte-pa': {
    name: 'Bellefonte',
    postalCode: '16823',
  },
  'bellemont-az': {
    name: 'Bellemont',
    postalCode: '86015',
  },
  'bellerose-ny': {
    name: 'Bellerose',
    postalCode: '11426',
  },
  'belleview-fl': {
    name: 'Belleview',
    postalCode: '34420',
  },
  'belleview-mo': {
    name: 'Belleview',
    postalCode: '63623',
  },
  'belleville-ar': {
    name: 'Belleville',
    postalCode: '72824',
  },
  'belleville-il': {
    name: 'Belleville',
    postalCode: '62220',
  },
  'belleville-ks': {
    name: 'Belleville',
    postalCode: '66935',
  },
  'belleville-mi': {
    name: 'Belleville',
    postalCode: '48111',
  },
  'belleville-nj': {
    name: 'Belleville',
    postalCode: '07109',
  },
  'belleville-pa': {
    name: 'Belleville',
    postalCode: '17004',
  },
  'belleville-wi': {
    name: 'Belleville',
    postalCode: '53508',
  },
  'belleville-wv': {
    name: 'Belleville',
    postalCode: '26133',
  },
  'bellevue-ia': {
    name: 'Bellevue',
    postalCode: '52031',
  },
  'bellevue-id': {
    name: 'Bellevue',
    postalCode: '83313',
  },
  'bellevue-ky': {
    name: 'Bellevue',
    postalCode: '41073',
  },
  'bellevue-mi': {
    name: 'Bellevue',
    postalCode: '49021',
  },
  'bellevue-ne': {
    name: 'Bellevue',
    postalCode: '68005',
  },
  'bellevue-oh': {
    name: 'Bellevue',
    postalCode: '44811',
  },
  'bellevue-tx': {
    name: 'Bellevue',
    postalCode: '76228',
  },
  'bellevue-wa': {
    name: 'Bellevue',
    postalCode: '98004',
  },
  'bellflower-ca': {
    name: 'Bellflower',
    postalCode: '90706',
  },
  'bellflower-il': {
    name: 'Bellflower',
    postalCode: '61724',
  },
  'bellflower-mo': {
    name: 'Bellflower',
    postalCode: '63333',
  },
  'bellingham-ma': {
    name: 'Bellingham',
    postalCode: '02019',
  },
  'bellingham-mn': {
    name: 'Bellingham',
    postalCode: '56212',
  },
  'bellingham-wa': {
    name: 'Bellingham',
    postalCode: '98225',
  },
  'bellmawr-nj': {
    name: 'Bellmawr',
    postalCode: '08031',
  },
  'bellmore-ny': {
    name: 'Bellmore',
    postalCode: '11710',
  },
  'bellona-ny': {
    name: 'Bellona',
    postalCode: '14415',
  },
  'bellows-falls-vt': {
    name: 'Bellows Falls',
    postalCode: '05101',
  },
  'bellport-ny': {
    name: 'Bellport',
    postalCode: '11713',
  },
  'bells-tn': {
    name: 'Bells',
    postalCode: '38006',
  },
  'bells-tx': {
    name: 'Bells',
    postalCode: '75414',
  },
  'bellville-oh': {
    name: 'Bellville',
    postalCode: '44813',
  },
  'bellville-tx': {
    name: 'Bellville',
    postalCode: '77418',
  },
  'bellvue-co': {
    name: 'Bellvue',
    postalCode: '80512',
  },
  'bellwood-il': {
    name: 'Bellwood',
    postalCode: '60104',
  },
  'bellwood-ne': {
    name: 'Bellwood',
    postalCode: '68624',
  },
  'bellwood-pa': {
    name: 'Bellwood',
    postalCode: '16617',
  },
  'belmar-nj': {
    name: 'Belmar',
    postalCode: '07719',
  },
  'belmond-ia': {
    name: 'Belmond',
    postalCode: '50421',
  },
  'belmont-ca': {
    name: 'Belmont',
    postalCode: '94002',
  },
  'belmont-la': {
    name: 'Belmont',
    postalCode: '71406',
  },
  'belmont-ma': {
    name: 'Belmont',
    postalCode: '02478',
  },
  'belmont-mi': {
    name: 'Belmont',
    postalCode: '49306',
  },
  'belmont-ms': {
    name: 'Belmont',
    postalCode: '38827',
  },
  'belmont-nc': {
    name: 'Belmont',
    postalCode: '28012',
  },
  'belmont-nh': {
    name: 'Belmont',
    postalCode: '03220',
  },
  'belmont-ny': {
    name: 'Belmont',
    postalCode: '14813',
  },
  'belmont-oh': {
    name: 'Belmont',
    postalCode: '43718',
  },
  'belmont-vt': {
    name: 'Belmont',
    postalCode: '05730',
  },
  'belmont-wa': {
    name: 'Belmont',
    postalCode: '99104',
  },
  'belmont-wi': {
    name: 'Belmont',
    postalCode: '53510',
  },
  'belmont-wv': {
    name: 'Belmont',
    postalCode: '26134',
  },
  'beloit-ks': {
    name: 'Beloit',
    postalCode: '67420',
  },
  'beloit-oh': {
    name: 'Beloit',
    postalCode: '44609',
  },
  'beloit-wi': {
    name: 'Beloit',
    postalCode: '53511',
  },
  'belpre-ks': {
    name: 'Belpre',
    postalCode: '67519',
  },
  'belpre-oh': {
    name: 'Belpre',
    postalCode: '45714',
  },
  'belt-mt': {
    name: 'Belt',
    postalCode: '59412',
  },
  'belton-ky': {
    name: 'Belton',
    postalCode: '42324',
  },
  'belton-mo': {
    name: 'Belton',
    postalCode: '64012',
  },
  'belton-sc': {
    name: 'Belton',
    postalCode: '29627',
  },
  'belton-tx': {
    name: 'Belton',
    postalCode: '76513',
  },
  'beltrami-mn': {
    name: 'Beltrami',
    postalCode: '56517',
  },
  'beltsville-md': {
    name: 'Beltsville',
    postalCode: '20705',
  },
  'belva-wv': {
    name: 'Belva',
    postalCode: '26656',
  },
  'belvedere-tiburon-ca': {
    name: 'Belvedere Tiburon',
    postalCode: '94920',
  },
  'belvidere-il': {
    name: 'Belvidere',
    postalCode: '61008',
  },
  'belvidere-nc': {
    name: 'Belvidere',
    postalCode: '27919',
  },
  'belvidere-ne': {
    name: 'Belvidere',
    postalCode: '68315',
  },
  'belvidere-nj': {
    name: 'Belvidere',
    postalCode: '07823',
  },
  'belvidere-sd': {
    name: 'Belvidere',
    postalCode: '57521',
  },
  'belvidere-tn': {
    name: 'Belvidere',
    postalCode: '37306',
  },
  'belvidere-center-vt': {
    name: 'Belvidere Center',
    postalCode: '05442',
  },
  'belview-mn': {
    name: 'Belview',
    postalCode: '56214',
  },
  'belvue-ks': {
    name: 'Belvue',
    postalCode: '66407',
  },
  'belzoni-ms': {
    name: 'Belzoni',
    postalCode: '39038',
  },
  'bement-il': {
    name: 'Bement',
    postalCode: '61813',
  },
  'bemidji-mn': {
    name: 'Bemidji',
    postalCode: '56601',
  },
  'bemus-point-ny': {
    name: 'Bemus Point',
    postalCode: '14712',
  },
  'ben-franklin-tx': {
    name: 'Ben Franklin',
    postalCode: '75415',
  },
  'ben-lomond-ca': {
    name: 'Ben Lomond',
    postalCode: '95005',
  },
  'ben-wheeler-tx': {
    name: 'Ben Wheeler',
    postalCode: '75754',
  },
  'bena-mn': {
    name: 'Bena',
    postalCode: '56626',
  },
  'bend-or': {
    name: 'Bend',
    postalCode: '97701',
  },
  'bendena-ks': {
    name: 'Bendena',
    postalCode: '66008',
  },
  'benedict-ks': {
    name: 'Benedict',
    postalCode: '66714',
  },
  'benedict-nd': {
    name: 'Benedict',
    postalCode: '58716',
  },
  'benedict-ne': {
    name: 'Benedict',
    postalCode: '68316',
  },
  'benedicta-me': {
    name: 'Benedicta',
    postalCode: '04733',
  },
  'benezett-pa': {
    name: 'Benezett',
    postalCode: '15821',
  },
  'benge-wa': {
    name: 'Benge',
    postalCode: '99105',
  },
  'benicia-ca': {
    name: 'Benicia',
    postalCode: '94510',
  },
  'benjamin-tx': {
    name: 'Benjamin',
    postalCode: '79505',
  },
  'benkelman-ne': {
    name: 'Benkelman',
    postalCode: '69021',
  },
  'benld-il': {
    name: 'Benld',
    postalCode: '62009',
  },
  'bennet-ne': {
    name: 'Bennet',
    postalCode: '68317',
  },
  'bennett-co': {
    name: 'Bennett',
    postalCode: '80102',
  },
  'bennett-ia': {
    name: 'Bennett',
    postalCode: '52721',
  },
  'bennett-nc': {
    name: 'Bennett',
    postalCode: '27208',
  },
  'bennettsville-sc': {
    name: 'Bennettsville',
    postalCode: '29512',
  },
  'bennington-in': {
    name: 'Bennington',
    postalCode: '47011',
  },
  'bennington-ks': {
    name: 'Bennington',
    postalCode: '67422',
  },
  'bennington-ne': {
    name: 'Bennington',
    postalCode: '68007',
  },
  'bennington-nh': {
    name: 'Bennington',
    postalCode: '03442',
  },
  'bennington-ok': {
    name: 'Bennington',
    postalCode: '74723',
  },
  'bennington-vt': {
    name: 'Bennington',
    postalCode: '05201',
  },
  'benoit-ms': {
    name: 'Benoit',
    postalCode: '38725',
  },
  'bensalem-pa': {
    name: 'Bensalem',
    postalCode: '19020',
  },
  'bensenville-il': {
    name: 'Bensenville',
    postalCode: '60106',
  },
  'benson-az': {
    name: 'Benson',
    postalCode: '85602',
  },
  'benson-il': {
    name: 'Benson',
    postalCode: '61516',
  },
  'benson-mn': {
    name: 'Benson',
    postalCode: '56215',
  },
  'benson-nc': {
    name: 'Benson',
    postalCode: '27504',
  },
  'bent-nm': {
    name: 'Bent',
    postalCode: '88314',
  },
  'bent-mountain-va': {
    name: 'Bent Mountain',
    postalCode: '24059',
  },
  'bentley-la': {
    name: 'Bentley',
    postalCode: '71407',
  },
  'bentley-mi': {
    name: 'Bentley',
    postalCode: '48613',
  },
  'bentleyville-pa': {
    name: 'Bentleyville',
    postalCode: '15314',
  },
  'benton-ar': {
    name: 'Benton',
    postalCode: '72015',
  },
  'benton-ca': {
    name: 'Benton',
    postalCode: '93512',
  },
  'benton-ia': {
    name: 'Benton',
    postalCode: '50835',
  },
  'benton-il': {
    name: 'Benton',
    postalCode: '62812',
  },
  'benton-ks': {
    name: 'Benton',
    postalCode: '67017',
  },
  'benton-ky': {
    name: 'Benton',
    postalCode: '42025',
  },
  'benton-la': {
    name: 'Benton',
    postalCode: '71006',
  },
  'benton-mo': {
    name: 'Benton',
    postalCode: '63736',
  },
  'benton-ms': {
    name: 'Benton',
    postalCode: '39039',
  },
  'benton-pa': {
    name: 'Benton',
    postalCode: '17814',
  },
  'benton-tn': {
    name: 'Benton',
    postalCode: '37307',
  },
  'benton-wi': {
    name: 'Benton',
    postalCode: '53803',
  },
  'benton-city-mo': {
    name: 'Benton City',
    postalCode: '65232',
  },
  'benton-city-wa': {
    name: 'Benton City',
    postalCode: '99320',
  },
  'benton-harbor-mi': {
    name: 'Benton Harbor',
    postalCode: '49022',
  },
  'bentonia-ms': {
    name: 'Bentonia',
    postalCode: '39040',
  },
  'bentonville-ar': {
    name: 'Bentonville',
    postalCode: '72712',
  },
  'bentonville-va': {
    name: 'Bentonville',
    postalCode: '22610',
  },
  'benwood-wv': {
    name: 'Benwood',
    postalCode: '26031',
  },
  'benzonia-mi': {
    name: 'Benzonia',
    postalCode: '49616',
  },
  'berea-ky': {
    name: 'Berea',
    postalCode: '40403',
  },
  'berea-oh': {
    name: 'Berea',
    postalCode: '44017',
  },
  'berea-wv': {
    name: 'Berea',
    postalCode: '26327',
  },
  'beresford-sd': {
    name: 'Beresford',
    postalCode: '57004',
  },
  'bergen-ny': {
    name: 'Bergen',
    postalCode: '14416',
  },
  'bergenfield-nj': {
    name: 'Bergenfield',
    postalCode: '07621',
  },
  'berger-mo': {
    name: 'Berger',
    postalCode: '63014',
  },
  'bergheim-tx': {
    name: 'Bergheim',
    postalCode: '78004',
  },
  'bergholz-oh': {
    name: 'Bergholz',
    postalCode: '43908',
  },
  'bergland-mi': {
    name: 'Bergland',
    postalCode: '49910',
  },
  'bergoo-wv': {
    name: 'Bergoo',
    postalCode: '26298',
  },
  'bergton-va': {
    name: 'Bergton',
    postalCode: '22811',
  },
  'berkeley-ca': {
    name: 'Berkeley',
    postalCode: '94702',
  },
  'berkeley-il': {
    name: 'Berkeley',
    postalCode: '60163',
  },
  'berkeley-heights-nj': {
    name: 'Berkeley Heights',
    postalCode: '07922',
  },
  'berkeley-springs-wv': {
    name: 'Berkeley Springs',
    postalCode: '25411',
  },
  'berkey-oh': {
    name: 'Berkey',
    postalCode: '43504',
  },
  'berkley-ma': {
    name: 'Berkley',
    postalCode: '02779',
  },
  'berkley-mi': {
    name: 'Berkley',
    postalCode: '48072',
  },
  'berkshire-ma': {
    name: 'Berkshire',
    postalCode: '01224',
  },
  'berkshire-ny': {
    name: 'Berkshire',
    postalCode: '13736',
  },
  'berlin-ct': {
    name: 'Berlin',
    postalCode: '06037',
  },
  'berlin-ma': {
    name: 'Berlin',
    postalCode: '01503',
  },
  'berlin-md': {
    name: 'Berlin',
    postalCode: '21811',
  },
  'berlin-nd': {
    name: 'Berlin',
    postalCode: '58415',
  },
  'berlin-nh': {
    name: 'Berlin',
    postalCode: '03570',
  },
  'berlin-nj': {
    name: 'Berlin',
    postalCode: '08009',
  },
  'berlin-ny': {
    name: 'Berlin',
    postalCode: '12022',
  },
  'berlin-pa': {
    name: 'Berlin',
    postalCode: '15530',
  },
  'berlin-wi': {
    name: 'Berlin',
    postalCode: '54923',
  },
  'berlin-center-oh': {
    name: 'Berlin Center',
    postalCode: '44401',
  },
  'berlin-heights-oh': {
    name: 'Berlin Heights',
    postalCode: '44814',
  },
  'bern-id': {
    name: 'Bern',
    postalCode: '83220',
  },
  'bern-ks': {
    name: 'Bern',
    postalCode: '66408',
  },
  'bernalillo-nm': {
    name: 'Bernalillo',
    postalCode: '87004',
  },
  'bernard-ia': {
    name: 'Bernard',
    postalCode: '52032',
  },
  'bernard-me': {
    name: 'Bernard',
    postalCode: '04612',
  },
  'bernardston-ma': {
    name: 'Bernardston',
    postalCode: '01337',
  },
  'bernardsville-nj': {
    name: 'Bernardsville',
    postalCode: '07924',
  },
  'berne-in': {
    name: 'Berne',
    postalCode: '46711',
  },
  'berne-ny': {
    name: 'Berne',
    postalCode: '12023',
  },
  'bernhards-bay-ny': {
    name: 'Bernhards Bay',
    postalCode: '13028',
  },
  'bernice-la': {
    name: 'Bernice',
    postalCode: '71222',
  },
  'bernie-mo': {
    name: 'Bernie',
    postalCode: '63822',
  },
  'bernville-pa': {
    name: 'Bernville',
    postalCode: '19506',
  },
  'berrien-center-mi': {
    name: 'Berrien Center',
    postalCode: '49102',
  },
  'berrien-springs-mi': {
    name: 'Berrien Springs',
    postalCode: '49103',
  },
  'berry-al': {
    name: 'Berry',
    postalCode: '35546',
  },
  'berry-ky': {
    name: 'Berry',
    postalCode: '41003',
  },
  'berry-creek-ca': {
    name: 'Berry Creek',
    postalCode: '95916',
  },
  'berryton-ks': {
    name: 'Berryton',
    postalCode: '66409',
  },
  'berryville-ar': {
    name: 'Berryville',
    postalCode: '72616',
  },
  'berryville-va': {
    name: 'Berryville',
    postalCode: '22611',
  },
  'bertha-mn': {
    name: 'Bertha',
    postalCode: '56437',
  },
  'berthold-nd': {
    name: 'Berthold',
    postalCode: '58718',
  },
  'berthoud-co': {
    name: 'Berthoud',
    postalCode: '80513',
  },
  'bertram-tx': {
    name: 'Bertram',
    postalCode: '78605',
  },
  'bertrand-mo': {
    name: 'Bertrand',
    postalCode: '63823',
  },
  'bertrand-ne': {
    name: 'Bertrand',
    postalCode: '68927',
  },
  'berwick-il': {
    name: 'Berwick',
    postalCode: '61417',
  },
  'berwick-la': {
    name: 'Berwick',
    postalCode: '70342',
  },
  'berwick-me': {
    name: 'Berwick',
    postalCode: '03901',
  },
  'berwick-pa': {
    name: 'Berwick',
    postalCode: '18603',
  },
  'berwind-wv': {
    name: 'Berwind',
    postalCode: '24815',
  },
  'berwyn-il': {
    name: 'Berwyn',
    postalCode: '60402',
  },
  'berwyn-pa': {
    name: 'Berwyn',
    postalCode: '19312',
  },
  'beryl-ut': {
    name: 'Beryl',
    postalCode: '84714',
  },
  'bessemer-al': {
    name: 'Bessemer',
    postalCode: '35020',
  },
  'bessemer-mi': {
    name: 'Bessemer',
    postalCode: '49911',
  },
  'bessemer-pa': {
    name: 'Bessemer',
    postalCode: '16112',
  },
  'bessemer-city-nc': {
    name: 'Bessemer City',
    postalCode: '28016',
  },
  'bessie-ok': {
    name: 'Bessie',
    postalCode: '73622',
  },
  'bethalto-il': {
    name: 'Bethalto',
    postalCode: '62010',
  },
  'bethany-ct': {
    name: 'Bethany',
    postalCode: '06524',
  },
  'bethany-il': {
    name: 'Bethany',
    postalCode: '61914',
  },
  'bethany-la': {
    name: 'Bethany',
    postalCode: '71007',
  },
  'bethany-mo': {
    name: 'Bethany',
    postalCode: '64424',
  },
  'bethany-ok': {
    name: 'Bethany',
    postalCode: '73008',
  },
  'bethany-wv': {
    name: 'Bethany',
    postalCode: '26032',
  },
  'bethany-beach-de': {
    name: 'Bethany Beach',
    postalCode: '19930',
  },
  'bethel-ct': {
    name: 'Bethel',
    postalCode: '06801',
  },
  'bethel-de': {
    name: 'Bethel',
    postalCode: '19931',
  },
  'bethel-me': {
    name: 'Bethel',
    postalCode: '04217',
  },
  'bethel-mn': {
    name: 'Bethel',
    postalCode: '55005',
  },
  'bethel-mo': {
    name: 'Bethel',
    postalCode: '63434',
  },
  'bethel-nc': {
    name: 'Bethel',
    postalCode: '27812',
  },
  'bethel-ny': {
    name: 'Bethel',
    postalCode: '12720',
  },
  'bethel-oh': {
    name: 'Bethel',
    postalCode: '45106',
  },
  'bethel-ok': {
    name: 'Bethel',
    postalCode: '74724',
  },
  'bethel-pa': {
    name: 'Bethel',
    postalCode: '19507',
  },
  'bethel-vt': {
    name: 'Bethel',
    postalCode: '05032',
  },
  'bethel-park-pa': {
    name: 'Bethel Park',
    postalCode: '15102',
  },
  'bethel-springs-tn': {
    name: 'Bethel Springs',
    postalCode: '38315',
  },
  'bethelridge-ky': {
    name: 'Bethelridge',
    postalCode: '42516',
  },
  'bethesda-md': {
    name: 'Bethesda',
    postalCode: '20814',
  },
  'bethesda-oh': {
    name: 'Bethesda',
    postalCode: '43719',
  },
  'bethlehem-ct': {
    name: 'Bethlehem',
    postalCode: '06751',
  },
  'bethlehem-ga': {
    name: 'Bethlehem',
    postalCode: '30620',
  },
  'bethlehem-in': {
    name: 'Bethlehem',
    postalCode: '47104',
  },
  'bethlehem-ky': {
    name: 'Bethlehem',
    postalCode: '40007',
  },
  'bethlehem-nh': {
    name: 'Bethlehem',
    postalCode: '03574',
  },
  'bethlehem-pa': {
    name: 'Bethlehem',
    postalCode: '18015',
  },
  'bethpage-ny': {
    name: 'Bethpage',
    postalCode: '11714',
  },
  'bethpage-tn': {
    name: 'Bethpage',
    postalCode: '37022',
  },
  'bethune-co': {
    name: 'Bethune',
    postalCode: '80805',
  },
  'bethune-sc': {
    name: 'Bethune',
    postalCode: '29009',
  },
  'betsy-layne-ky': {
    name: 'Betsy Layne',
    postalCode: '41605',
  },
  'bettendorf-ia': {
    name: 'Bettendorf',
    postalCode: '52722',
  },
  'betterton-md': {
    name: 'Betterton',
    postalCode: '21610',
  },
  'beulah-co': {
    name: 'Beulah',
    postalCode: '81023',
  },
  'beulah-mi': {
    name: 'Beulah',
    postalCode: '49617',
  },
  'beulah-mo': {
    name: 'Beulah',
    postalCode: '65436',
  },
  'beulah-ms': {
    name: 'Beulah',
    postalCode: '38726',
  },
  'beulah-nd': {
    name: 'Beulah',
    postalCode: '58523',
  },
  'beulah-wy': {
    name: 'Beulah',
    postalCode: '82712',
  },
  'beulaville-nc': {
    name: 'Beulaville',
    postalCode: '28518',
  },
  'beverly-ks': {
    name: 'Beverly',
    postalCode: '67423',
  },
  'beverly-ky': {
    name: 'Beverly',
    postalCode: '40913',
  },
  'beverly-ma': {
    name: 'Beverly',
    postalCode: '01915',
  },
  'beverly-nj': {
    name: 'Beverly',
    postalCode: '08010',
  },
  'beverly-oh': {
    name: 'Beverly',
    postalCode: '45715',
  },
  'beverly-wa': {
    name: 'Beverly',
    postalCode: '99321',
  },
  'beverly-wv': {
    name: 'Beverly',
    postalCode: '26253',
  },
  'beverly-hills-ca': {
    name: 'Beverly Hills',
    postalCode: '90210',
  },
  'beverly-hills-fl': {
    name: 'Beverly Hills',
    postalCode: '34465',
  },
  'bevier-mo': {
    name: 'Bevier',
    postalCode: '63532',
  },
  'bevington-ia': {
    name: 'Bevington',
    postalCode: '50033',
  },
  'bevinsville-ky': {
    name: 'Bevinsville',
    postalCode: '41606',
  },
  'bexar-ar': {
    name: 'Bexar',
    postalCode: '72515',
  },
  'bickleton-wa': {
    name: 'Bickleton',
    postalCode: '99322',
  },
  'bickmore-wv': {
    name: 'Bickmore',
    postalCode: '25019',
  },
  'bicknell-in': {
    name: 'Bicknell',
    postalCode: '47512',
  },
  'biddeford-me': {
    name: 'Biddeford',
    postalCode: '04005',
  },
  'biddle-mt': {
    name: 'Biddle',
    postalCode: '59314',
  },
  'bidwell-oh': {
    name: 'Bidwell',
    postalCode: '45614',
  },
  'bienville-la': {
    name: 'Bienville',
    postalCode: '71008',
  },
  'big-arm-mt': {
    name: 'Big Arm',
    postalCode: '59910',
  },
  'big-bar-ca': {
    name: 'Big Bar',
    postalCode: '96010',
  },
  'big-bear-city-ca': {
    name: 'Big Bear City',
    postalCode: '92314',
  },
  'big-bear-lake-ca': {
    name: 'Big Bear Lake',
    postalCode: '92315',
  },
  'big-bend-wi': {
    name: 'Big Bend',
    postalCode: '53103',
  },
  'big-bend-wv': {
    name: 'Big Bend',
    postalCode: '26136',
  },
  'big-cabin-ok': {
    name: 'Big Cabin',
    postalCode: '74332',
  },
  'big-clifty-ky': {
    name: 'Big Clifty',
    postalCode: '42712',
  },
  'big-cove-tannery-pa': {
    name: 'Big Cove Tannery',
    postalCode: '17212',
  },
  'big-creek-ky': {
    name: 'Big Creek',
    postalCode: '40914',
  },
  'big-creek-ms': {
    name: 'Big Creek',
    postalCode: '38914',
  },
  'big-creek-wv': {
    name: 'Big Creek',
    postalCode: '25505',
  },
  'big-falls-mn': {
    name: 'Big Falls',
    postalCode: '56627',
  },
  'big-flat-ar': {
    name: 'Big Flat',
    postalCode: '72617',
  },
  'big-flats-ny': {
    name: 'Big Flats',
    postalCode: '14814',
  },
  'big-indian-ny': {
    name: 'Big Indian',
    postalCode: '12410',
  },
  'big-island-va': {
    name: 'Big Island',
    postalCode: '24526',
  },
  'big-lake-mn': {
    name: 'Big Lake',
    postalCode: '55309',
  },
  'big-lake-tx': {
    name: 'Big Lake',
    postalCode: '76932',
  },
  'big-laurel-ky': {
    name: 'Big Laurel',
    postalCode: '40808',
  },
  'big-pine-ca': {
    name: 'Big Pine',
    postalCode: '93513',
  },
  'big-pine-key-fl': {
    name: 'Big Pine Key',
    postalCode: '33043',
  },
  'big-piney-wy': {
    name: 'Big Piney',
    postalCode: '83113',
  },
  'big-pool-md': {
    name: 'Big Pool',
    postalCode: '21711',
  },
  'big-prairie-oh': {
    name: 'Big Prairie',
    postalCode: '44611',
  },
  'big-rapids-mi': {
    name: 'Big Rapids',
    postalCode: '49307',
  },
  'big-rock-il': {
    name: 'Big Rock',
    postalCode: '60511',
  },
  'big-rock-tn': {
    name: 'Big Rock',
    postalCode: '37023',
  },
  'big-rock-va': {
    name: 'Big Rock',
    postalCode: '24603',
  },
  'big-run-wv': {
    name: 'Big Run',
    postalCode: '26561',
  },
  'big-sandy-mt': {
    name: 'Big Sandy',
    postalCode: '59520',
  },
  'big-sandy-tn': {
    name: 'Big Sandy',
    postalCode: '38221',
  },
  'big-sandy-tx': {
    name: 'Big Sandy',
    postalCode: '75755',
  },
  'big-spring-tx': {
    name: 'Big Spring',
    postalCode: '79720',
  },
  'big-springs-ne': {
    name: 'Big Springs',
    postalCode: '69122',
  },
  'big-springs-wv': {
    name: 'Big Springs',
    postalCode: '26137',
  },
  'big-stone-city-sd': {
    name: 'Big Stone City',
    postalCode: '57216',
  },
  'big-stone-gap-va': {
    name: 'Big Stone Gap',
    postalCode: '24219',
  },
  'big-sur-ca': {
    name: 'Big Sur',
    postalCode: '93920',
  },
  'big-timber-mt': {
    name: 'Big Timber',
    postalCode: '59011',
  },
  'big-wells-tx': {
    name: 'Big Wells',
    postalCode: '78830',
  },
  'bigelow-ar': {
    name: 'Bigelow',
    postalCode: '72016',
  },
  'bigelow-mn': {
    name: 'Bigelow',
    postalCode: '56117',
  },
  'bigfoot-tx': {
    name: 'Bigfoot',
    postalCode: '78005',
  },
  'bigfork-mn': {
    name: 'Bigfork',
    postalCode: '56628',
  },
  'bigfork-mt': {
    name: 'Bigfork',
    postalCode: '59911',
  },
  'biggers-ar': {
    name: 'Biggers',
    postalCode: '72413',
  },
  'biggs-ca': {
    name: 'Biggs',
    postalCode: '95917',
  },
  'biggsville-il': {
    name: 'Biggsville',
    postalCode: '61418',
  },
  'bighorn-mt': {
    name: 'Bighorn',
    postalCode: '59010',
  },
  'biglerville-pa': {
    name: 'Biglerville',
    postalCode: '17307',
  },
  'billerica-ma': {
    name: 'Billerica',
    postalCode: '01821',
  },
  'billings-mo': {
    name: 'Billings',
    postalCode: '65610',
  },
  'billings-mt': {
    name: 'Billings',
    postalCode: '59101',
  },
  'billings-ok': {
    name: 'Billings',
    postalCode: '74630',
  },
  'billingsley-al': {
    name: 'Billingsley',
    postalCode: '36006',
  },
  'biloxi-ms': {
    name: 'Biloxi',
    postalCode: '39530',
  },
  'bim-wv': {
    name: 'Bim',
    postalCode: '25021',
  },
  'bimble-ky': {
    name: 'Bimble',
    postalCode: '40915',
  },
  'binford-nd': {
    name: 'Binford',
    postalCode: '58416',
  },
  'bingen-wa': {
    name: 'Bingen',
    postalCode: '98605',
  },
  'binger-ok': {
    name: 'Binger',
    postalCode: '73009',
  },
  'bingham-il': {
    name: 'Bingham',
    postalCode: '62011',
  },
  'bingham-me': {
    name: 'Bingham',
    postalCode: '04920',
  },
  'bingham-ne': {
    name: 'Bingham',
    postalCode: '69335',
  },
  'bingham-canyon-ut': {
    name: 'Bingham Canyon',
    postalCode: '84006',
  },
  'bingham-lake-mn': {
    name: 'Bingham Lake',
    postalCode: '56118',
  },
  'binghamton-ny': {
    name: 'Binghamton',
    postalCode: '13901',
  },
  'birch-harbor-me': {
    name: 'Birch Harbor',
    postalCode: '04613',
  },
  'birch-river-wv': {
    name: 'Birch River',
    postalCode: '26610',
  },
  'birch-run-mi': {
    name: 'Birch Run',
    postalCode: '48415',
  },
  'birch-tree-mo': {
    name: 'Birch Tree',
    postalCode: '65438',
  },
  'birchleaf-va': {
    name: 'Birchleaf',
    postalCode: '24220',
  },
  'birchwood-tn': {
    name: 'Birchwood',
    postalCode: '37308',
  },
  'birchwood-wi': {
    name: 'Birchwood',
    postalCode: '54817',
  },
  'bird-city-ks': {
    name: 'Bird City',
    postalCode: '67731',
  },
  'bird-in-hand-pa': {
    name: 'Bird In Hand',
    postalCode: '17505',
  },
  'bird-island-mn': {
    name: 'Bird Island',
    postalCode: '55310',
  },
  'birds-landing-ca': {
    name: 'Birds Landing',
    postalCode: '94512',
  },
  'birdsboro-pa': {
    name: 'Birdsboro',
    postalCode: '19508',
  },
  'birdseye-in': {
    name: 'Birdseye',
    postalCode: '47513',
  },
  'birdsnest-va': {
    name: 'Birdsnest',
    postalCode: '23307',
  },
  'birmingham-al': {
    name: 'Birmingham',
    postalCode: '35203',
  },
  'birmingham-ia': {
    name: 'Birmingham',
    postalCode: '52535',
  },
  'birmingham-mi': {
    name: 'Birmingham',
    postalCode: '48009',
  },
  'birnamwood-wi': {
    name: 'Birnamwood',
    postalCode: '54414',
  },
  'birney-mt': {
    name: 'Birney',
    postalCode: '59012',
  },
  'bisbee-az': {
    name: 'Bisbee',
    postalCode: '85603',
  },
  'bisbee-nd': {
    name: 'Bisbee',
    postalCode: '58317',
  },
  'biscoe-ar': {
    name: 'Biscoe',
    postalCode: '72017',
  },
  'biscoe-nc': {
    name: 'Biscoe',
    postalCode: '27209',
  },
  'bishop-ca': {
    name: 'Bishop',
    postalCode: '93514',
  },
  'bishop-ga': {
    name: 'Bishop',
    postalCode: '30621',
  },
  'bishop-tx': {
    name: 'Bishop',
    postalCode: '78343',
  },
  'bishopville-md': {
    name: 'Bishopville',
    postalCode: '21813',
  },
  'bishopville-sc': {
    name: 'Bishopville',
    postalCode: '29010',
  },
  'bismarck-ar': {
    name: 'Bismarck',
    postalCode: '71929',
  },
  'bismarck-il': {
    name: 'Bismarck',
    postalCode: '61814',
  },
  'bismarck-mo': {
    name: 'Bismarck',
    postalCode: '63624',
  },
  'bismarck-nd': {
    name: 'Bismarck',
    postalCode: '58501',
  },
  'bison-ks': {
    name: 'Bison',
    postalCode: '67520',
  },
  'bison-ok': {
    name: 'Bison',
    postalCode: '73720',
  },
  'bison-sd': {
    name: 'Bison',
    postalCode: '57620',
  },
  'bitely-mi': {
    name: 'Bitely',
    postalCode: '49309',
  },
  'bittinger-md': {
    name: 'Bittinger',
    postalCode: '21522',
  },
  'bivalve-md': {
    name: 'Bivalve',
    postalCode: '21814',
  },
  'bivins-tx': {
    name: 'Bivins',
    postalCode: '75555',
  },
  'bixby-mo': {
    name: 'Bixby',
    postalCode: '65439',
  },
  'bixby-ok': {
    name: 'Bixby',
    postalCode: '74008',
  },
  'blachly-or': {
    name: 'Blachly',
    postalCode: '97412',
  },
  'black-al': {
    name: 'Black',
    postalCode: '36314',
  },
  'black-mo': {
    name: 'Black',
    postalCode: '63625',
  },
  'black-canyon-city-az': {
    name: 'Black Canyon City',
    postalCode: '85324',
  },
  'black-creek-ny': {
    name: 'Black Creek',
    postalCode: '14714',
  },
  'black-creek-wi': {
    name: 'Black Creek',
    postalCode: '54106',
  },
  'black-diamond-wa': {
    name: 'Black Diamond',
    postalCode: '98010',
  },
  'black-eagle-mt': {
    name: 'Black Eagle',
    postalCode: '59414',
  },
  'black-earth-wi': {
    name: 'Black Earth',
    postalCode: '53515',
  },
  'black-hawk-co': {
    name: 'Black Hawk',
    postalCode: '80422',
  },
  'black-hawk-sd': {
    name: 'Black Hawk',
    postalCode: '57718',
  },
  'black-mountain-nc': {
    name: 'Black Mountain',
    postalCode: '28711',
  },
  'black-oak-ar': {
    name: 'Black Oak',
    postalCode: '72414',
  },
  'black-river-mi': {
    name: 'Black River',
    postalCode: '48721',
  },
  'black-river-ny': {
    name: 'Black River',
    postalCode: '13612',
  },
  'black-river-falls-wi': {
    name: 'Black River Falls',
    postalCode: '54615',
  },
  'black-rock-ar': {
    name: 'Black Rock',
    postalCode: '72415',
  },
  'blackburn-mo': {
    name: 'Blackburn',
    postalCode: '65321',
  },
  'blackduck-mn': {
    name: 'Blackduck',
    postalCode: '56630',
  },
  'blackey-ky': {
    name: 'Blackey',
    postalCode: '41804',
  },
  'blackfoot-id': {
    name: 'Blackfoot',
    postalCode: '83221',
  },
  'blackford-ky': {
    name: 'Blackford',
    postalCode: '42403',
  },
  'blacklick-oh': {
    name: 'Blacklick',
    postalCode: '43004',
  },
  'blacksburg-sc': {
    name: 'Blacksburg',
    postalCode: '29702',
  },
  'blacksburg-va': {
    name: 'Blacksburg',
    postalCode: '24060',
  },
  'blackshear-ga': {
    name: 'Blackshear',
    postalCode: '31516',
  },
  'blackstock-sc': {
    name: 'Blackstock',
    postalCode: '29014',
  },
  'blackstone-il': {
    name: 'Blackstone',
    postalCode: '61313',
  },
  'blackstone-ma': {
    name: 'Blackstone',
    postalCode: '01504',
  },
  'blackstone-va': {
    name: 'Blackstone',
    postalCode: '23824',
  },
  'blacksville-wv': {
    name: 'Blacksville',
    postalCode: '26521',
  },
  'blackville-sc': {
    name: 'Blackville',
    postalCode: '29817',
  },
  'blackwater-mo': {
    name: 'Blackwater',
    postalCode: '65322',
  },
  'blackwater-va': {
    name: 'Blackwater',
    postalCode: '24221',
  },
  'blackwell-mo': {
    name: 'Blackwell',
    postalCode: '63626',
  },
  'blackwell-ok': {
    name: 'Blackwell',
    postalCode: '74631',
  },
  'blackwell-tx': {
    name: 'Blackwell',
    postalCode: '79506',
  },
  'blackwood-nj': {
    name: 'Blackwood',
    postalCode: '08012',
  },
  'bladen-ne': {
    name: 'Bladen',
    postalCode: '68928',
  },
  'bladenboro-nc': {
    name: 'Bladenboro',
    postalCode: '28320',
  },
  'bladensburg-md': {
    name: 'Bladensburg',
    postalCode: '20710',
  },
  'blain-pa': {
    name: 'Blain',
    postalCode: '17006',
  },
  'blaine-ky': {
    name: 'Blaine',
    postalCode: '41124',
  },
  'blaine-tn': {
    name: 'Blaine',
    postalCode: '37709',
  },
  'blaine-wa': {
    name: 'Blaine',
    postalCode: '98230',
  },
  'blair-ne': {
    name: 'Blair',
    postalCode: '68008',
  },
  'blair-ok': {
    name: 'Blair',
    postalCode: '73526',
  },
  'blair-sc': {
    name: 'Blair',
    postalCode: '29015',
  },
  'blair-wi': {
    name: 'Blair',
    postalCode: '54616',
  },
  'blairs-va': {
    name: 'Blairs',
    postalCode: '24527',
  },
  'blairs-mills-pa': {
    name: 'Blairs Mills',
    postalCode: '17213',
  },
  'blairsburg-ia': {
    name: 'Blairsburg',
    postalCode: '50034',
  },
  'blairsden-graeagle-ca': {
    name: 'Blairsden Graeagle',
    postalCode: '96103',
  },
  'blairstown-ia': {
    name: 'Blairstown',
    postalCode: '52209',
  },
  'blairstown-mo': {
    name: 'Blairstown',
    postalCode: '64726',
  },
  'blairstown-nj': {
    name: 'Blairstown',
    postalCode: '07825',
  },
  'blairsville-ga': {
    name: 'Blairsville',
    postalCode: '30512',
  },
  'blairsville-pa': {
    name: 'Blairsville',
    postalCode: '15717',
  },
  'blakely-ga': {
    name: 'Blakely',
    postalCode: '39823',
  },
  'blakely-island-wa': {
    name: 'Blakely Island',
    postalCode: '98222',
  },
  'blakesburg-ia': {
    name: 'Blakesburg',
    postalCode: '52536',
  },
  'blakeslee-pa': {
    name: 'Blakeslee',
    postalCode: '18610',
  },
  'blanca-co': {
    name: 'Blanca',
    postalCode: '81123',
  },
  'blanch-nc': {
    name: 'Blanch',
    postalCode: '27212',
  },
  'blanchard-ia': {
    name: 'Blanchard',
    postalCode: '51630',
  },
  'blanchard-id': {
    name: 'Blanchard',
    postalCode: '83804',
  },
  'blanchard-mi': {
    name: 'Blanchard',
    postalCode: '49310',
  },
  'blanchard-nd': {
    name: 'Blanchard',
    postalCode: '58009',
  },
  'blanchard-ok': {
    name: 'Blanchard',
    postalCode: '73010',
  },
  'blanchardville-wi': {
    name: 'Blanchardville',
    postalCode: '53516',
  },
  'blanchester-oh': {
    name: 'Blanchester',
    postalCode: '45107',
  },
  'blanco-nm': {
    name: 'Blanco',
    postalCode: '87412',
  },
  'blanco-ok': {
    name: 'Blanco',
    postalCode: '74528',
  },
  'blanco-tx': {
    name: 'Blanco',
    postalCode: '78606',
  },
  'bland-mo': {
    name: 'Bland',
    postalCode: '65014',
  },
  'bland-va': {
    name: 'Bland',
    postalCode: '24315',
  },
  'blandford-ma': {
    name: 'Blandford',
    postalCode: '01008',
  },
  'blanding-ut': {
    name: 'Blanding',
    postalCode: '84511',
  },
  'blandinsville-il': {
    name: 'Blandinsville',
    postalCode: '61420',
  },
  'blandon-pa': {
    name: 'Blandon',
    postalCode: '19510',
  },
  'blanket-tx': {
    name: 'Blanket',
    postalCode: '76432',
  },
  'blauvelt-ny': {
    name: 'Blauvelt',
    postalCode: '10913',
  },
  'bledsoe-ky': {
    name: 'Bledsoe',
    postalCode: '40810',
  },
  'bleiblerville-tx': {
    name: 'Bleiblerville',
    postalCode: '78931',
  },
  'blencoe-ia': {
    name: 'Blencoe',
    postalCode: '51523',
  },
  'blenheim-sc': {
    name: 'Blenheim',
    postalCode: '29516',
  },
  'blevins-ar': {
    name: 'Blevins',
    postalCode: '71825',
  },
  'bliss-id': {
    name: 'Bliss',
    postalCode: '83314',
  },
  'bliss-ny': {
    name: 'Bliss',
    postalCode: '14024',
  },
  'blissfield-mi': {
    name: 'Blissfield',
    postalCode: '49228',
  },
  'blocker-ok': {
    name: 'Blocker',
    postalCode: '74529',
  },
  'blocksburg-ca': {
    name: 'Blocksburg',
    postalCode: '95514',
  },
  'blockton-ia': {
    name: 'Blockton',
    postalCode: '50836',
  },
  'blodgett-or': {
    name: 'Blodgett',
    postalCode: '97326',
  },
  'blomkest-mn': {
    name: 'Blomkest',
    postalCode: '56216',
  },
  'bloomburg-tx': {
    name: 'Bloomburg',
    postalCode: '75556',
  },
  'bloomdale-oh': {
    name: 'Bloomdale',
    postalCode: '44817',
  },
  'bloomer-wi': {
    name: 'Bloomer',
    postalCode: '54724',
  },
  'bloomery-wv': {
    name: 'Bloomery',
    postalCode: '26817',
  },
  'bloomfield-ct': {
    name: 'Bloomfield',
    postalCode: '06002',
  },
  'bloomfield-ia': {
    name: 'Bloomfield',
    postalCode: '52537',
  },
  'bloomfield-in': {
    name: 'Bloomfield',
    postalCode: '47424',
  },
  'bloomfield-ky': {
    name: 'Bloomfield',
    postalCode: '40008',
  },
  'bloomfield-mo': {
    name: 'Bloomfield',
    postalCode: '63825',
  },
  'bloomfield-mt': {
    name: 'Bloomfield',
    postalCode: '59315',
  },
  'bloomfield-ne': {
    name: 'Bloomfield',
    postalCode: '68718',
  },
  'bloomfield-nj': {
    name: 'Bloomfield',
    postalCode: '07003',
  },
  'bloomfield-nm': {
    name: 'Bloomfield',
    postalCode: '87413',
  },
  'bloomfield-ny': {
    name: 'Bloomfield',
    postalCode: '14469',
  },
  'bloomfield-hills-mi': {
    name: 'Bloomfield Hills',
    postalCode: '48301',
  },
  'blooming-grove-tx': {
    name: 'Blooming Grove',
    postalCode: '76626',
  },
  'blooming-prairie-mn': {
    name: 'Blooming Prairie',
    postalCode: '55917',
  },
  'bloomingburg-ny': {
    name: 'Bloomingburg',
    postalCode: '12721',
  },
  'bloomingburg-oh': {
    name: 'Bloomingburg',
    postalCode: '43106',
  },
  'bloomingdale-ga': {
    name: 'Bloomingdale',
    postalCode: '31302',
  },
  'bloomingdale-il': {
    name: 'Bloomingdale',
    postalCode: '60108',
  },
  'bloomingdale-in': {
    name: 'Bloomingdale',
    postalCode: '47832',
  },
  'bloomingdale-mi': {
    name: 'Bloomingdale',
    postalCode: '49026',
  },
  'bloomingdale-nj': {
    name: 'Bloomingdale',
    postalCode: '07403',
  },
  'bloomingdale-ny': {
    name: 'Bloomingdale',
    postalCode: '12913',
  },
  'bloomingdale-oh': {
    name: 'Bloomingdale',
    postalCode: '43910',
  },
  'bloomingrose-wv': {
    name: 'Bloomingrose',
    postalCode: '25024',
  },
  'bloomington-ca': {
    name: 'Bloomington',
    postalCode: '92316',
  },
  'bloomington-il': {
    name: 'Bloomington',
    postalCode: '61701',
  },
  'bloomington-in': {
    name: 'Bloomington',
    postalCode: '47401',
  },
  'bloomington-md': {
    name: 'Bloomington',
    postalCode: '21523',
  },
  'bloomington-ne': {
    name: 'Bloomington',
    postalCode: '68929',
  },
  'bloomington-ny': {
    name: 'Bloomington',
    postalCode: '12411',
  },
  'bloomington-wi': {
    name: 'Bloomington',
    postalCode: '53804',
  },
  'bloomington-springs-tn': {
    name: 'Bloomington Springs',
    postalCode: '38545',
  },
  'bloomsburg-pa': {
    name: 'Bloomsburg',
    postalCode: '17815',
  },
  'bloomsbury-nj': {
    name: 'Bloomsbury',
    postalCode: '08804',
  },
  'bloomsdale-mo': {
    name: 'Bloomsdale',
    postalCode: '63627',
  },
  'bloomville-ny': {
    name: 'Bloomville',
    postalCode: '13739',
  },
  'bloomville-oh': {
    name: 'Bloomville',
    postalCode: '44818',
  },
  'blossburg-pa': {
    name: 'Blossburg',
    postalCode: '16912',
  },
  'blossom-tx': {
    name: 'Blossom',
    postalCode: '75416',
  },
  'blossvale-ny': {
    name: 'Blossvale',
    postalCode: '13308',
  },
  'blount-wv': {
    name: 'Blount',
    postalCode: '25025',
  },
  'blounts-creek-nc': {
    name: 'Blounts Creek',
    postalCode: '27814',
  },
  'blountstown-fl': {
    name: 'Blountstown',
    postalCode: '32424',
  },
  'blountsville-al': {
    name: 'Blountsville',
    postalCode: '35031',
  },
  'blountville-tn': {
    name: 'Blountville',
    postalCode: '37617',
  },
  'blowing-rock-nc': {
    name: 'Blowing Rock',
    postalCode: '28605',
  },
  'bloxom-va': {
    name: 'Bloxom',
    postalCode: '23308',
  },
  'blue-az': {
    name: 'Blue',
    postalCode: '85922',
  },
  'blue-bell-pa': {
    name: 'Blue Bell',
    postalCode: '19422',
  },
  'blue-creek-oh': {
    name: 'Blue Creek',
    postalCode: '45616',
  },
  'blue-earth-mn': {
    name: 'Blue Earth',
    postalCode: '56013',
  },
  'blue-eye-mo': {
    name: 'Blue Eye',
    postalCode: '65611',
  },
  'blue-grass-ia': {
    name: 'Blue Grass',
    postalCode: '52726',
  },
  'blue-grass-va': {
    name: 'Blue Grass',
    postalCode: '24413',
  },
  'blue-hill-me': {
    name: 'Blue Hill',
    postalCode: '04614',
  },
  'blue-hill-ne': {
    name: 'Blue Hill',
    postalCode: '68930',
  },
  'blue-island-il': {
    name: 'Blue Island',
    postalCode: '60406',
  },
  'blue-lake-ca': {
    name: 'Blue Lake',
    postalCode: '95525',
  },
  'blue-mound-il': {
    name: 'Blue Mound',
    postalCode: '62513',
  },
  'blue-mound-ks': {
    name: 'Blue Mound',
    postalCode: '66010',
  },
  'blue-mounds-wi': {
    name: 'Blue Mounds',
    postalCode: '53517',
  },
  'blue-mountain-ms': {
    name: 'Blue Mountain',
    postalCode: '38610',
  },
  'blue-mountain-lake-ny': {
    name: 'Blue Mountain Lake',
    postalCode: '12812',
  },
  'blue-point-ny': {
    name: 'Blue Point',
    postalCode: '11715',
  },
  'blue-rapids-ks': {
    name: 'Blue Rapids',
    postalCode: '66411',
  },
  'blue-ridge-ga': {
    name: 'Blue Ridge',
    postalCode: '30513',
  },
  'blue-ridge-tx': {
    name: 'Blue Ridge',
    postalCode: '75424',
  },
  'blue-ridge-va': {
    name: 'Blue Ridge',
    postalCode: '24064',
  },
  'blue-ridge-summit-pa': {
    name: 'Blue Ridge Summit',
    postalCode: '17214',
  },
  'blue-river-ky': {
    name: 'Blue River',
    postalCode: '41607',
  },
  'blue-river-or': {
    name: 'Blue River',
    postalCode: '97413',
  },
  'blue-river-wi': {
    name: 'Blue River',
    postalCode: '53518',
  },
  'blue-rock-oh': {
    name: 'Blue Rock',
    postalCode: '43720',
  },
  'blue-springs-mo': {
    name: 'Blue Springs',
    postalCode: '64014',
  },
  'blue-springs-ms': {
    name: 'Blue Springs',
    postalCode: '38828',
  },
  'blue-springs-ne': {
    name: 'Blue Springs',
    postalCode: '68318',
  },
  'bluebell-ut': {
    name: 'Bluebell',
    postalCode: '84007',
  },
  'bluefield-va': {
    name: 'Bluefield',
    postalCode: '24605',
  },
  'bluefield-wv': {
    name: 'Bluefield',
    postalCode: '24701',
  },
  'bluejacket-ok': {
    name: 'Bluejacket',
    postalCode: '74333',
  },
  'bluemont-va': {
    name: 'Bluemont',
    postalCode: '20135',
  },
  'bluewater-nm': {
    name: 'Bluewater',
    postalCode: '87005',
  },
  'bluff-city-ar': {
    name: 'Bluff City',
    postalCode: '71722',
  },
  'bluff-city-ks': {
    name: 'Bluff City',
    postalCode: '67018',
  },
  'bluff-city-tn': {
    name: 'Bluff City',
    postalCode: '37618',
  },
  'bluff-dale-tx': {
    name: 'Bluff Dale',
    postalCode: '76433',
  },
  'bluffs-il': {
    name: 'Bluffs',
    postalCode: '62621',
  },
  'bluffton-ar': {
    name: 'Bluffton',
    postalCode: '72827',
  },
  'bluffton-ga': {
    name: 'Bluffton',
    postalCode: '39824',
  },
  'bluffton-in': {
    name: 'Bluffton',
    postalCode: '46714',
  },
  'bluffton-mn': {
    name: 'Bluffton',
    postalCode: '56518',
  },
  'bluffton-oh': {
    name: 'Bluffton',
    postalCode: '45817',
  },
  'bluffton-sc': {
    name: 'Bluffton',
    postalCode: '29910',
  },
  'bluffton-tx': {
    name: 'Bluffton',
    postalCode: '78607',
  },
  'bluford-il': {
    name: 'Bluford',
    postalCode: '62814',
  },
  'blum-tx': {
    name: 'Blum',
    postalCode: '76627',
  },
  'blunt-sd': {
    name: 'Blunt',
    postalCode: '57522',
  },
  'blythe-ca': {
    name: 'Blythe',
    postalCode: '92225',
  },
  'blythe-ga': {
    name: 'Blythe',
    postalCode: '30805',
  },
  'blythedale-mo': {
    name: 'Blythedale',
    postalCode: '64426',
  },
  'blytheville-ar': {
    name: 'Blytheville',
    postalCode: '72315',
  },
  'blythewood-sc': {
    name: 'Blythewood',
    postalCode: '29016',
  },
  'boalsburg-pa': {
    name: 'Boalsburg',
    postalCode: '16827',
  },
  'boardman-or': {
    name: 'Boardman',
    postalCode: '97818',
  },
  'boaz-al': {
    name: 'Boaz',
    postalCode: '35956',
  },
  'boaz-ky': {
    name: 'Boaz',
    postalCode: '42027',
  },
  'bob-white-wv': {
    name: 'Bob White',
    postalCode: '25028',
  },
  'boca-raton-fl': {
    name: 'Boca Raton',
    postalCode: '33428',
  },
  'bode-ia': {
    name: 'Bode',
    postalCode: '50519',
  },
  'bodega-ca': {
    name: 'Bodega',
    postalCode: '94922',
  },
  'bodega-bay-ca': {
    name: 'Bodega Bay',
    postalCode: '94923',
  },
  'bodfish-ca': {
    name: 'Bodfish',
    postalCode: '93205',
  },
  'boelus-ne': {
    name: 'Boelus',
    postalCode: '68820',
  },
  'boerne-tx': {
    name: 'Boerne',
    postalCode: '78006',
  },
  'bogalusa-la': {
    name: 'Bogalusa',
    postalCode: '70427',
  },
  'bogard-mo': {
    name: 'Bogard',
    postalCode: '64622',
  },
  'bogart-ga': {
    name: 'Bogart',
    postalCode: '30622',
  },
  'bogata-tx': {
    name: 'Bogata',
    postalCode: '75417',
  },
  'boggstown-in': {
    name: 'Boggstown',
    postalCode: '46110',
  },
  'bogota-nj': {
    name: 'Bogota',
    postalCode: '07603',
  },
  'bogue-ks': {
    name: 'Bogue',
    postalCode: '67625',
  },
  'bogue-chitto-ms': {
    name: 'Bogue Chitto',
    postalCode: '39629',
  },
  'bohannon-va': {
    name: 'Bohannon',
    postalCode: '23021',
  },
  'bohemia-ny': {
    name: 'Bohemia',
    postalCode: '11716',
  },
  'boiceville-ny': {
    name: 'Boiceville',
    postalCode: '12412',
  },
  'boiling-springs-pa': {
    name: 'Boiling Springs',
    postalCode: '17007',
  },
  'boiling-springs-sc': {
    name: 'Boiling Springs',
    postalCode: '29316',
  },
  'bois-d-arc-mo': {
    name: 'Bois D Arc',
    postalCode: '65612',
  },
  'boise-id': {
    name: 'Boise',
    postalCode: '83702',
  },
  'boise-city-ok': {
    name: 'Boise City',
    postalCode: '73933',
  },
  'bokchito-ok': {
    name: 'Bokchito',
    postalCode: '74726',
  },
  'bokeelia-fl': {
    name: 'Bokeelia',
    postalCode: '33922',
  },
  'bokoshe-ok': {
    name: 'Bokoshe',
    postalCode: '74930',
  },
  'bolckow-mo': {
    name: 'Bolckow',
    postalCode: '64427',
  },
  'boles-ar': {
    name: 'Boles',
    postalCode: '72926',
  },
  'boles-il': {
    name: 'Boles',
    postalCode: '62909',
  },
  'boley-ok': {
    name: 'Boley',
    postalCode: '74829',
  },
  'boligee-al': {
    name: 'Boligee',
    postalCode: '35443',
  },
  'bolinas-ca': {
    name: 'Bolinas',
    postalCode: '94924',
  },
  'boling-tx': {
    name: 'Boling',
    postalCode: '77420',
  },
  'bolingbrook-il': {
    name: 'Bolingbrook',
    postalCode: '60440',
  },
  'bolivar-mo': {
    name: 'Bolivar',
    postalCode: '65613',
  },
  'bolivar-ny': {
    name: 'Bolivar',
    postalCode: '14715',
  },
  'bolivar-oh': {
    name: 'Bolivar',
    postalCode: '44612',
  },
  'bolivar-pa': {
    name: 'Bolivar',
    postalCode: '15923',
  },
  'bolivar-tn': {
    name: 'Bolivar',
    postalCode: '38008',
  },
  'bolivia-nc': {
    name: 'Bolivia',
    postalCode: '28422',
  },
  'bolt-wv': {
    name: 'Bolt',
    postalCode: '25817',
  },
  'bolton-ct': {
    name: 'Bolton',
    postalCode: '06043',
  },
  'bolton-ma': {
    name: 'Bolton',
    postalCode: '01740',
  },
  'bolton-ms': {
    name: 'Bolton',
    postalCode: '39041',
  },
  'bolton-nc': {
    name: 'Bolton',
    postalCode: '28423',
  },
  'bolton-landing-ny': {
    name: 'Bolton Landing',
    postalCode: '12814',
  },
  'bombay-ny': {
    name: 'Bombay',
    postalCode: '12914',
  },
  'bomont-wv': {
    name: 'Bomont',
    postalCode: '25030',
  },
  'bomoseen-vt': {
    name: 'Bomoseen',
    postalCode: '05732',
  },
  'bon-aqua-tn': {
    name: 'Bon Aqua',
    postalCode: '37025',
  },
  'bon-secour-al': {
    name: 'Bon Secour',
    postalCode: '36511',
  },
  'bon-wier-tx': {
    name: 'Bon Wier',
    postalCode: '75928',
  },
  'bonaire-ga': {
    name: 'Bonaire',
    postalCode: '31005',
  },
  'bonanza-or': {
    name: 'Bonanza',
    postalCode: '97623',
  },
  'bonanza-ut': {
    name: 'Bonanza',
    postalCode: '84008',
  },
  'bonaparte-ia': {
    name: 'Bonaparte',
    postalCode: '52620',
  },
  'boncarbo-co': {
    name: 'Boncarbo',
    postalCode: '81024',
  },
  'bond-co': {
    name: 'Bond',
    postalCode: '80423',
  },
  'bonduel-wi': {
    name: 'Bonduel',
    postalCode: '54107',
  },
  'bondurant-ia': {
    name: 'Bondurant',
    postalCode: '50035',
  },
  'bondurant-wy': {
    name: 'Bondurant',
    postalCode: '82922',
  },
  'bondville-vt': {
    name: 'Bondville',
    postalCode: '05340',
  },
  'bone-gap-il': {
    name: 'Bone Gap',
    postalCode: '62815',
  },
  'bonesteel-sd': {
    name: 'Bonesteel',
    postalCode: '57317',
  },
  'bonfield-il': {
    name: 'Bonfield',
    postalCode: '60913',
  },
  'bonham-tx': {
    name: 'Bonham',
    postalCode: '75418',
  },
  'bonifay-fl': {
    name: 'Bonifay',
    postalCode: '32425',
  },
  'bonita-ca': {
    name: 'Bonita',
    postalCode: '91902',
  },
  'bonita-la': {
    name: 'Bonita',
    postalCode: '71223',
  },
  'bonita-springs-fl': {
    name: 'Bonita Springs',
    postalCode: '34134',
  },
  'bonne-terre-mo': {
    name: 'Bonne Terre',
    postalCode: '63628',
  },
  'bonneau-sc': {
    name: 'Bonneau',
    postalCode: '29431',
  },
  'bonner-mt': {
    name: 'Bonner',
    postalCode: '59823',
  },
  'bonner-springs-ks': {
    name: 'Bonner Springs',
    postalCode: '66012',
  },
  'bonnerdale-ar': {
    name: 'Bonnerdale',
    postalCode: '71933',
  },
  'bonners-ferry-id': {
    name: 'Bonners Ferry',
    postalCode: '83805',
  },
  'bonney-lake-wa': {
    name: 'Bonney Lake',
    postalCode: '98391',
  },
  'bonnie-il': {
    name: 'Bonnie',
    postalCode: '62816',
  },
  'bonnieville-ky': {
    name: 'Bonnieville',
    postalCode: '42713',
  },
  'bonnots-mill-mo': {
    name: 'Bonnots Mill',
    postalCode: '65016',
  },
  'bonnyman-ky': {
    name: 'Bonnyman',
    postalCode: '41719',
  },
  'bono-ar': {
    name: 'Bono',
    postalCode: '72416',
  },
  'bonsall-ca': {
    name: 'Bonsall',
    postalCode: '92003',
  },
  'booker-tx': {
    name: 'Booker',
    postalCode: '79005',
  },
  'boomer-nc': {
    name: 'Boomer',
    postalCode: '28606',
  },
  'boon-mi': {
    name: 'Boon',
    postalCode: '49618',
  },
  'boone-co': {
    name: 'Boone',
    postalCode: '81025',
  },
  'boone-ia': {
    name: 'Boone',
    postalCode: '50036',
  },
  'boone-nc': {
    name: 'Boone',
    postalCode: '28607',
  },
  'boones-mill-va': {
    name: 'Boones Mill',
    postalCode: '24065',
  },
  'booneville-ar': {
    name: 'Booneville',
    postalCode: '72927',
  },
  'booneville-ia': {
    name: 'Booneville',
    postalCode: '50038',
  },
  'booneville-ky': {
    name: 'Booneville',
    postalCode: '41314',
  },
  'booneville-ms': {
    name: 'Booneville',
    postalCode: '38829',
  },
  'boons-camp-ky': {
    name: 'Boons Camp',
    postalCode: '41204',
  },
  'boonsboro-md': {
    name: 'Boonsboro',
    postalCode: '21713',
  },
  'boonton-nj': {
    name: 'Boonton',
    postalCode: '07005',
  },
  'boonville-ca': {
    name: 'Boonville',
    postalCode: '95415',
  },
  'boonville-in': {
    name: 'Boonville',
    postalCode: '47601',
  },
  'boonville-mo': {
    name: 'Boonville',
    postalCode: '65233',
  },
  'boonville-nc': {
    name: 'Boonville',
    postalCode: '27011',
  },
  'boonville-ny': {
    name: 'Boonville',
    postalCode: '13309',
  },
  'booth-al': {
    name: 'Booth',
    postalCode: '36008',
  },
  'boothbay-me': {
    name: 'Boothbay',
    postalCode: '04537',
  },
  'boothbay-harbor-me': {
    name: 'Boothbay Harbor',
    postalCode: '04538',
  },
  'boqueron-pr': {
    name: 'Boqueron',
    postalCode: '00622',
  },
  'borden-in': {
    name: 'Borden',
    postalCode: '47106',
  },
  'bordentown-nj': {
    name: 'Bordentown',
    postalCode: '08505',
  },
  'borger-tx': {
    name: 'Borger',
    postalCode: '79007',
  },
  'boring-or': {
    name: 'Boring',
    postalCode: '97009',
  },
  'boron-ca': {
    name: 'Boron',
    postalCode: '93516',
  },
  'borrego-springs-ca': {
    name: 'Borrego Springs',
    postalCode: '92004',
  },
  'borup-mn': {
    name: 'Borup',
    postalCode: '56519',
  },
  'boscobel-wi': {
    name: 'Boscobel',
    postalCode: '53805',
  },
  'bosler-wy': {
    name: 'Bosler',
    postalCode: '82051',
  },
  'bosque-nm': {
    name: 'Bosque',
    postalCode: '87006',
  },
  'bosque-farms-nm': {
    name: 'Bosque Farms',
    postalCode: '87068',
  },
  'boss-mo': {
    name: 'Boss',
    postalCode: '65440',
  },
  'bossier-city-la': {
    name: 'Bossier City',
    postalCode: '71111',
  },
  'bostic-nc': {
    name: 'Bostic',
    postalCode: '28018',
  },
  'boston-ga': {
    name: 'Boston',
    postalCode: '31626',
  },
  'boston-ky': {
    name: 'Boston',
    postalCode: '40107',
  },
  'boston-ma': {
    name: 'Boston',
    postalCode: '02108',
  },
  'boston-ny': {
    name: 'Boston',
    postalCode: '14025',
  },
  'boston-va': {
    name: 'Boston',
    postalCode: '22713',
  },
  'boswell-in': {
    name: 'Boswell',
    postalCode: '47921',
  },
  'boswell-ok': {
    name: 'Boswell',
    postalCode: '74727',
  },
  'boswell-pa': {
    name: 'Boswell',
    postalCode: '15531',
  },
  'bosworth-mo': {
    name: 'Bosworth',
    postalCode: '64623',
  },
  'bothell-wa': {
    name: 'Bothell',
    postalCode: '98011',
  },
  'botkins-oh': {
    name: 'Botkins',
    postalCode: '45306',
  },
  'bottineau-nd': {
    name: 'Bottineau',
    postalCode: '58318',
  },
  'bouckville-ny': {
    name: 'Bouckville',
    postalCode: '13310',
  },
  'boulder-co': {
    name: 'Boulder',
    postalCode: '80301',
  },
  'boulder-mt': {
    name: 'Boulder',
    postalCode: '59632',
  },
  'boulder-wy': {
    name: 'Boulder',
    postalCode: '82923',
  },
  'boulder-city-nv': {
    name: 'Boulder City',
    postalCode: '89005',
  },
  'boulder-creek-ca': {
    name: 'Boulder Creek',
    postalCode: '95006',
  },
  'boulder-junction-wi': {
    name: 'Boulder Junction',
    postalCode: '54512',
  },
  'boulevard-ca': {
    name: 'Boulevard',
    postalCode: '91905',
  },
  'bound-brook-nj': {
    name: 'Bound Brook',
    postalCode: '08805',
  },
  'bountiful-ut': {
    name: 'Bountiful',
    postalCode: '84010',
  },
  'bourbon-in': {
    name: 'Bourbon',
    postalCode: '46504',
  },
  'bourbon-mo': {
    name: 'Bourbon',
    postalCode: '65441',
  },
  'bourbonnais-il': {
    name: 'Bourbonnais',
    postalCode: '60914',
  },
  'bourg-la': {
    name: 'Bourg',
    postalCode: '70343',
  },
  'bouton-ia': {
    name: 'Bouton',
    postalCode: '50039',
  },
  'boutte-la': {
    name: 'Boutte',
    postalCode: '70039',
  },
  'bovey-mn': {
    name: 'Bovey',
    postalCode: '55709',
  },
  'bovina-tx': {
    name: 'Bovina',
    postalCode: '79009',
  },
  'bovina-center-ny': {
    name: 'Bovina Center',
    postalCode: '13740',
  },
  'bow-nh': {
    name: 'Bow',
    postalCode: '03304',
  },
  'bow-wa': {
    name: 'Bow',
    postalCode: '98232',
  },
  'bowbells-nd': {
    name: 'Bowbells',
    postalCode: '58721',
  },
  'bowden-wv': {
    name: 'Bowden',
    postalCode: '26254',
  },
  'bowdle-sd': {
    name: 'Bowdle',
    postalCode: '57428',
  },
  'bowdoin-me': {
    name: 'Bowdoin',
    postalCode: '04287',
  },
  'bowdoinham-me': {
    name: 'Bowdoinham',
    postalCode: '04008',
  },
  'bowdon-ga': {
    name: 'Bowdon',
    postalCode: '30108',
  },
  'bowdon-nd': {
    name: 'Bowdon',
    postalCode: '58418',
  },
  'bowen-il': {
    name: 'Bowen',
    postalCode: '62316',
  },
  'bowerston-oh': {
    name: 'Bowerston',
    postalCode: '44695',
  },
  'bowersville-ga': {
    name: 'Bowersville',
    postalCode: '30516',
  },
  'bowie-md': {
    name: 'Bowie',
    postalCode: '20715',
  },
  'bowie-tx': {
    name: 'Bowie',
    postalCode: '76230',
  },
  'bowler-wi': {
    name: 'Bowler',
    postalCode: '54416',
  },
  'bowling-green-fl': {
    name: 'Bowling Green',
    postalCode: '33834',
  },
  'bowling-green-in': {
    name: 'Bowling Green',
    postalCode: '47833',
  },
  'bowling-green-ky': {
    name: 'Bowling Green',
    postalCode: '42101',
  },
  'bowling-green-mo': {
    name: 'Bowling Green',
    postalCode: '63334',
  },
  'bowling-green-oh': {
    name: 'Bowling Green',
    postalCode: '43402',
  },
  'bowling-green-va': {
    name: 'Bowling Green',
    postalCode: '22427',
  },
  'bowlus-mn': {
    name: 'Bowlus',
    postalCode: '56314',
  },
  'bowman-ga': {
    name: 'Bowman',
    postalCode: '30624',
  },
  'bowman-nd': {
    name: 'Bowman',
    postalCode: '58623',
  },
  'bowman-sc': {
    name: 'Bowman',
    postalCode: '29018',
  },
  'bowmansville-ny': {
    name: 'Bowmansville',
    postalCode: '14026',
  },
  'box-elder-mt': {
    name: 'Box Elder',
    postalCode: '59521',
  },
  'box-elder-sd': {
    name: 'Box Elder',
    postalCode: '57719',
  },
  'box-springs-ga': {
    name: 'Box Springs',
    postalCode: '31801',
  },
  'boxborough-ma': {
    name: 'Boxborough',
    postalCode: '01719',
  },
  'boxford-ma': {
    name: 'Boxford',
    postalCode: '01921',
  },
  'boyce-la': {
    name: 'Boyce',
    postalCode: '71409',
  },
  'boyce-va': {
    name: 'Boyce',
    postalCode: '22620',
  },
  'boyceville-wi': {
    name: 'Boyceville',
    postalCode: '54725',
  },
  'boyd-mn': {
    name: 'Boyd',
    postalCode: '56218',
  },
  'boyd-tx': {
    name: 'Boyd',
    postalCode: '76023',
  },
  'boyd-wi': {
    name: 'Boyd',
    postalCode: '54726',
  },
  'boyden-ia': {
    name: 'Boyden',
    postalCode: '51234',
  },
  'boyds-md': {
    name: 'Boyds',
    postalCode: '20841',
  },
  'boyds-wa': {
    name: 'Boyds',
    postalCode: '99107',
  },
  'boydton-va': {
    name: 'Boydton',
    postalCode: '23917',
  },
  'boyers-pa': {
    name: 'Boyers',
    postalCode: '16020',
  },
  'boyertown-pa': {
    name: 'Boyertown',
    postalCode: '19512',
  },
  'boyes-mt': {
    name: 'Boyes',
    postalCode: '59316',
  },
  'boykins-va': {
    name: 'Boykins',
    postalCode: '23827',
  },
  'boyle-ms': {
    name: 'Boyle',
    postalCode: '38730',
  },
  'boylston-ma': {
    name: 'Boylston',
    postalCode: '01505',
  },
  'boyne-city-mi': {
    name: 'Boyne City',
    postalCode: '49712',
  },
  'boyne-falls-mi': {
    name: 'Boyne Falls',
    postalCode: '49713',
  },
  'boynton-ok': {
    name: 'Boynton',
    postalCode: '74422',
  },
  'boynton-beach-fl': {
    name: 'Boynton Beach',
    postalCode: '33426',
  },
  'bozeman-mt': {
    name: 'Bozeman',
    postalCode: '59715',
  },
  'bozman-md': {
    name: 'Bozman',
    postalCode: '21612',
  },
  'bozrah-ct': {
    name: 'Bozrah',
    postalCode: '06334',
  },
  'braceville-il': {
    name: 'Braceville',
    postalCode: '60407',
  },
  'bracey-va': {
    name: 'Bracey',
    postalCode: '23919',
  },
  'brackenridge-pa': {
    name: 'Brackenridge',
    postalCode: '15014',
  },
  'brackettville-tx': {
    name: 'Brackettville',
    postalCode: '78832',
  },
  'brackney-pa': {
    name: 'Brackney',
    postalCode: '18812',
  },
  'braddock-nd': {
    name: 'Braddock',
    postalCode: '58524',
  },
  'braddock-pa': {
    name: 'Braddock',
    postalCode: '15104',
  },
  'braddyville-ia': {
    name: 'Braddyville',
    postalCode: '51631',
  },
  'bradenton-fl': {
    name: 'Bradenton',
    postalCode: '34201',
  },
  'bradenton-beach-fl': {
    name: 'Bradenton Beach',
    postalCode: '34217',
  },
  'bradenville-pa': {
    name: 'Bradenville',
    postalCode: '15620',
  },
  'bradford-ar': {
    name: 'Bradford',
    postalCode: '72020',
  },
  'bradford-il': {
    name: 'Bradford',
    postalCode: '61421',
  },
  'bradford-me': {
    name: 'Bradford',
    postalCode: '04410',
  },
  'bradford-nh': {
    name: 'Bradford',
    postalCode: '03221',
  },
  'bradford-ny': {
    name: 'Bradford',
    postalCode: '14815',
  },
  'bradford-oh': {
    name: 'Bradford',
    postalCode: '45308',
  },
  'bradford-pa': {
    name: 'Bradford',
    postalCode: '16701',
  },
  'bradford-ri': {
    name: 'Bradford',
    postalCode: '02808',
  },
  'bradford-tn': {
    name: 'Bradford',
    postalCode: '38316',
  },
  'bradford-vt': {
    name: 'Bradford',
    postalCode: '05033',
  },
  'bradfordsville-ky': {
    name: 'Bradfordsville',
    postalCode: '40009',
  },
  'bradfordwoods-pa': {
    name: 'Bradfordwoods',
    postalCode: '15015',
  },
  'bradgate-ia': {
    name: 'Bradgate',
    postalCode: '50520',
  },
  'bradley-ar': {
    name: 'Bradley',
    postalCode: '71826',
  },
  'bradley-ca': {
    name: 'Bradley',
    postalCode: '93426',
  },
  'bradley-il': {
    name: 'Bradley',
    postalCode: '60915',
  },
  'bradley-me': {
    name: 'Bradley',
    postalCode: '04411',
  },
  'bradley-ok': {
    name: 'Bradley',
    postalCode: '73011',
  },
  'bradley-sc': {
    name: 'Bradley',
    postalCode: '29819',
  },
  'bradley-sd': {
    name: 'Bradley',
    postalCode: '57217',
  },
  'bradley-beach-nj': {
    name: 'Bradley Beach',
    postalCode: '07720',
  },
  'bradleyville-mo': {
    name: 'Bradleyville',
    postalCode: '65614',
  },
  'bradner-oh': {
    name: 'Bradner',
    postalCode: '43406',
  },
  'bradshaw-ne': {
    name: 'Bradshaw',
    postalCode: '68319',
  },
  'brady-mt': {
    name: 'Brady',
    postalCode: '59416',
  },
  'brady-ne': {
    name: 'Brady',
    postalCode: '69123',
  },
  'brady-tx': {
    name: 'Brady',
    postalCode: '76825',
  },
  'bradyville-tn': {
    name: 'Bradyville',
    postalCode: '37026',
  },
  'bragg-city-mo': {
    name: 'Bragg City',
    postalCode: '63827',
  },
  'braggs-ok': {
    name: 'Braggs',
    postalCode: '74423',
  },
  'braham-mn': {
    name: 'Braham',
    postalCode: '55006',
  },
  'braidwood-il': {
    name: 'Braidwood',
    postalCode: '60408',
  },
  'brainard-ne': {
    name: 'Brainard',
    postalCode: '68626',
  },
  'brainard-ny': {
    name: 'Brainard',
    postalCode: '12024',
  },
  'brainerd-mn': {
    name: 'Brainerd',
    postalCode: '56401',
  },
  'braintree-ma': {
    name: 'Braintree',
    postalCode: '02184',
  },
  'braithwaite-la': {
    name: 'Braithwaite',
    postalCode: '70040',
  },
  'braman-ok': {
    name: 'Braman',
    postalCode: '74632',
  },
  'bramwell-wv': {
    name: 'Bramwell',
    postalCode: '24715',
  },
  'branch-ar': {
    name: 'Branch',
    postalCode: '72928',
  },
  'branch-la': {
    name: 'Branch',
    postalCode: '70516',
  },
  'branch-mi': {
    name: 'Branch',
    postalCode: '49402',
  },
  'branchland-wv': {
    name: 'Branchland',
    postalCode: '25506',
  },
  'branchport-ny': {
    name: 'Branchport',
    postalCode: '14418',
  },
  'branchton-pa': {
    name: 'Branchton',
    postalCode: '16021',
  },
  'branchville-in': {
    name: 'Branchville',
    postalCode: '47514',
  },
  'branchville-nj': {
    name: 'Branchville',
    postalCode: '07826',
  },
  'branchville-sc': {
    name: 'Branchville',
    postalCode: '29432',
  },
  'branchville-va': {
    name: 'Branchville',
    postalCode: '23828',
  },
  'brandenburg-ky': {
    name: 'Brandenburg',
    postalCode: '40108',
  },
  'brandon-fl': {
    name: 'Brandon',
    postalCode: '33510',
  },
  'brandon-ia': {
    name: 'Brandon',
    postalCode: '52210',
  },
  'brandon-mn': {
    name: 'Brandon',
    postalCode: '56315',
  },
  'brandon-ms': {
    name: 'Brandon',
    postalCode: '39042',
  },
  'brandon-sd': {
    name: 'Brandon',
    postalCode: '57005',
  },
  'brandon-vt': {
    name: 'Brandon',
    postalCode: '05733',
  },
  'brandon-wi': {
    name: 'Brandon',
    postalCode: '53919',
  },
  'brandt-sd': {
    name: 'Brandt',
    postalCode: '57218',
  },
  'brandy-camp-pa': {
    name: 'Brandy Camp',
    postalCode: '15822',
  },
  'brandy-station-va': {
    name: 'Brandy Station',
    postalCode: '22714',
  },
  'brandywine-md': {
    name: 'Brandywine',
    postalCode: '20613',
  },
  'brandywine-wv': {
    name: 'Brandywine',
    postalCode: '26802',
  },
  'branford-ct': {
    name: 'Branford',
    postalCode: '06405',
  },
  'branford-fl': {
    name: 'Branford',
    postalCode: '32008',
  },
  'branscomb-ca': {
    name: 'Branscomb',
    postalCode: '95417',
  },
  'branson-co': {
    name: 'Branson',
    postalCode: '81027',
  },
  'branson-mo': {
    name: 'Branson',
    postalCode: '65616',
  },
  'brant-mi': {
    name: 'Brant',
    postalCode: '48614',
  },
  'brant-lake-ny': {
    name: 'Brant Lake',
    postalCode: '12815',
  },
  'brantley-al': {
    name: 'Brantley',
    postalCode: '36009',
  },
  'brantwood-wi': {
    name: 'Brantwood',
    postalCode: '54513',
  },
  'braselton-ga': {
    name: 'Braselton',
    postalCode: '30517',
  },
  'brashear-mo': {
    name: 'Brashear',
    postalCode: '63533',
  },
  'brashear-tx': {
    name: 'Brashear',
    postalCode: '75420',
  },
  'brasher-falls-ny': {
    name: 'Brasher Falls',
    postalCode: '13613',
  },
  'brasstown-nc': {
    name: 'Brasstown',
    postalCode: '28902',
  },
  'brattleboro-vt': {
    name: 'Brattleboro',
    postalCode: '05301',
  },
  'brave-pa': {
    name: 'Brave',
    postalCode: '15316',
  },
  'brawley-ca': {
    name: 'Brawley',
    postalCode: '92227',
  },
  'braxton-ms': {
    name: 'Braxton',
    postalCode: '39044',
  },
  'braymer-mo': {
    name: 'Braymer',
    postalCode: '64624',
  },
  'brayton-ia': {
    name: 'Brayton',
    postalCode: '50042',
  },
  'brazeau-mo': {
    name: 'Brazeau',
    postalCode: '63737',
  },
  'brazil-in': {
    name: 'Brazil',
    postalCode: '47834',
  },
  'brazoria-tx': {
    name: 'Brazoria',
    postalCode: '77422',
  },
  'brea-ca': {
    name: 'Brea',
    postalCode: '92821',
  },
  'breaux-bridge-la': {
    name: 'Breaux Bridge',
    postalCode: '70517',
  },
  'breckenridge-co': {
    name: 'Breckenridge',
    postalCode: '80424',
  },
  'breckenridge-mi': {
    name: 'Breckenridge',
    postalCode: '48615',
  },
  'breckenridge-mn': {
    name: 'Breckenridge',
    postalCode: '56520',
  },
  'breckenridge-mo': {
    name: 'Breckenridge',
    postalCode: '64625',
  },
  'breckenridge-tx': {
    name: 'Breckenridge',
    postalCode: '76424',
  },
  'brecksville-oh': {
    name: 'Brecksville',
    postalCode: '44141',
  },
  'breda-ia': {
    name: 'Breda',
    postalCode: '51436',
  },
  'breeden-wv': {
    name: 'Breeden',
    postalCode: '25666',
  },
  'breeding-ky': {
    name: 'Breeding',
    postalCode: '42715',
  },
  'breese-il': {
    name: 'Breese',
    postalCode: '62230',
  },
  'breesport-ny': {
    name: 'Breesport',
    postalCode: '14816',
  },
  'breezewood-pa': {
    name: 'Breezewood',
    postalCode: '15533',
  },
  'breezy-point-ny': {
    name: 'Breezy Point',
    postalCode: '11697',
  },
  'breinigsville-pa': {
    name: 'Breinigsville',
    postalCode: '18031',
  },
  'bremen-al': {
    name: 'Bremen',
    postalCode: '35033',
  },
  'bremen-ga': {
    name: 'Bremen',
    postalCode: '30110',
  },
  'bremen-in': {
    name: 'Bremen',
    postalCode: '46506',
  },
  'bremen-ks': {
    name: 'Bremen',
    postalCode: '66412',
  },
  'bremen-ky': {
    name: 'Bremen',
    postalCode: '42325',
  },
  'bremen-me': {
    name: 'Bremen',
    postalCode: '04551',
  },
  'bremen-nd': {
    name: 'Bremen',
    postalCode: '58319',
  },
  'bremen-oh': {
    name: 'Bremen',
    postalCode: '43107',
  },
  'bremerton-wa': {
    name: 'Bremerton',
    postalCode: '98310',
  },
  'bremo-bluff-va': {
    name: 'Bremo Bluff',
    postalCode: '23022',
  },
  'bremond-tx': {
    name: 'Bremond',
    postalCode: '76629',
  },
  'brenham-tx': {
    name: 'Brenham',
    postalCode: '77833',
  },
  'brent-al': {
    name: 'Brent',
    postalCode: '35034',
  },
  'brenton-wv': {
    name: 'Brenton',
    postalCode: '24818',
  },
  'brentwood-ca': {
    name: 'Brentwood',
    postalCode: '94513',
  },
  'brentwood-md': {
    name: 'Brentwood',
    postalCode: '20722',
  },
  'brentwood-ny': {
    name: 'Brentwood',
    postalCode: '11717',
  },
  'brentwood-tn': {
    name: 'Brentwood',
    postalCode: '37027',
  },
  'brethren-mi': {
    name: 'Brethren',
    postalCode: '49619',
  },
  'bretz-wv': {
    name: 'Bretz',
    postalCode: '26524',
  },
  'brevard-nc': {
    name: 'Brevard',
    postalCode: '28712',
  },
  'brewer-me': {
    name: 'Brewer',
    postalCode: '04412',
  },
  'brewerton-ny': {
    name: 'Brewerton',
    postalCode: '13029',
  },
  'brewster-ks': {
    name: 'Brewster',
    postalCode: '67732',
  },
  'brewster-ma': {
    name: 'Brewster',
    postalCode: '02631',
  },
  'brewster-mn': {
    name: 'Brewster',
    postalCode: '56119',
  },
  'brewster-ne': {
    name: 'Brewster',
    postalCode: '68821',
  },
  'brewster-ny': {
    name: 'Brewster',
    postalCode: '10509',
  },
  'brewster-oh': {
    name: 'Brewster',
    postalCode: '44613',
  },
  'brewster-wa': {
    name: 'Brewster',
    postalCode: '98812',
  },
  'brewton-al': {
    name: 'Brewton',
    postalCode: '36426',
  },
  'briar-mo': {
    name: 'Briar',
    postalCode: '63931',
  },
  'briarcliff-manor-ny': {
    name: 'Briarcliff Manor',
    postalCode: '10510',
  },
  'bricelyn-mn': {
    name: 'Bricelyn',
    postalCode: '56014',
  },
  'briceville-tn': {
    name: 'Briceville',
    postalCode: '37710',
  },
  'brick-nj': {
    name: 'Brick',
    postalCode: '08723',
  },
  'brickeys-ar': {
    name: 'Brickeys',
    postalCode: '72320',
  },
  'bridge-city-tx': {
    name: 'Bridge City',
    postalCode: '77611',
  },
  'bridgeport-al': {
    name: 'Bridgeport',
    postalCode: '35740',
  },
  'bridgeport-ca': {
    name: 'Bridgeport',
    postalCode: '93517',
  },
  'bridgeport-ct': {
    name: 'Bridgeport',
    postalCode: '06604',
  },
  'bridgeport-il': {
    name: 'Bridgeport',
    postalCode: '62417',
  },
  'bridgeport-mi': {
    name: 'Bridgeport',
    postalCode: '48722',
  },
  'bridgeport-ne': {
    name: 'Bridgeport',
    postalCode: '69336',
  },
  'bridgeport-nj': {
    name: 'Bridgeport',
    postalCode: '08014',
  },
  'bridgeport-ny': {
    name: 'Bridgeport',
    postalCode: '13030',
  },
  'bridgeport-oh': {
    name: 'Bridgeport',
    postalCode: '43912',
  },
  'bridgeport-or': {
    name: 'Bridgeport',
    postalCode: '97819',
  },
  'bridgeport-pa': {
    name: 'Bridgeport',
    postalCode: '19405',
  },
  'bridgeport-tx': {
    name: 'Bridgeport',
    postalCode: '76426',
  },
  'bridgeport-wa': {
    name: 'Bridgeport',
    postalCode: '98813',
  },
  'bridgeport-wv': {
    name: 'Bridgeport',
    postalCode: '26330',
  },
  'bridger-mt': {
    name: 'Bridger',
    postalCode: '59014',
  },
  'bridgeton-mo': {
    name: 'Bridgeton',
    postalCode: '63044',
  },
  'bridgeton-nj': {
    name: 'Bridgeton',
    postalCode: '08302',
  },
  'bridgeview-il': {
    name: 'Bridgeview',
    postalCode: '60455',
  },
  'bridgeville-ca': {
    name: 'Bridgeville',
    postalCode: '95526',
  },
  'bridgeville-de': {
    name: 'Bridgeville',
    postalCode: '19933',
  },
  'bridgeville-pa': {
    name: 'Bridgeville',
    postalCode: '15017',
  },
  'bridgewater-ct': {
    name: 'Bridgewater',
    postalCode: '06752',
  },
  'bridgewater-ia': {
    name: 'Bridgewater',
    postalCode: '50837',
  },
  'bridgewater-ma': {
    name: 'Bridgewater',
    postalCode: '02324',
  },
  'bridgewater-me': {
    name: 'Bridgewater',
    postalCode: '04735',
  },
  'bridgewater-nj': {
    name: 'Bridgewater',
    postalCode: '08807',
  },
  'bridgewater-sd': {
    name: 'Bridgewater',
    postalCode: '57319',
  },
  'bridgewater-va': {
    name: 'Bridgewater',
    postalCode: '22812',
  },
  'bridgewater-vt': {
    name: 'Bridgewater',
    postalCode: '05034',
  },
  'bridgewater-corners-vt': {
    name: 'Bridgewater Corners',
    postalCode: '05035',
  },
  'bridgman-mi': {
    name: 'Bridgman',
    postalCode: '49106',
  },
  'bridgton-me': {
    name: 'Bridgton',
    postalCode: '04009',
  },
  'bridport-vt': {
    name: 'Bridport',
    postalCode: '05734',
  },
  'brielle-nj': {
    name: 'Brielle',
    postalCode: '08730',
  },
  'brier-hill-ny': {
    name: 'Brier Hill',
    postalCode: '13614',
  },
  'brierfield-al': {
    name: 'Brierfield',
    postalCode: '35035',
  },
  'brigantine-nj': {
    name: 'Brigantine',
    postalCode: '08203',
  },
  'briggs-tx': {
    name: 'Briggs',
    postalCode: '78608',
  },
  'briggsdale-co': {
    name: 'Briggsdale',
    postalCode: '80611',
  },
  'briggsville-ar': {
    name: 'Briggsville',
    postalCode: '72828',
  },
  'briggsville-wi': {
    name: 'Briggsville',
    postalCode: '53920',
  },
  'brigham-city-ut': {
    name: 'Brigham City',
    postalCode: '84302',
  },
  'brighton-co': {
    name: 'Brighton',
    postalCode: '80601',
  },
  'brighton-ia': {
    name: 'Brighton',
    postalCode: '52540',
  },
  'brighton-il': {
    name: 'Brighton',
    postalCode: '62012',
  },
  'brighton-ma': {
    name: 'Brighton',
    postalCode: '02135',
  },
  'brighton-mi': {
    name: 'Brighton',
    postalCode: '48114',
  },
  'brighton-mo': {
    name: 'Brighton',
    postalCode: '65617',
  },
  'brighton-tn': {
    name: 'Brighton',
    postalCode: '38011',
  },
  'brightwaters-ny': {
    name: 'Brightwaters',
    postalCode: '11718',
  },
  'brightwood-or': {
    name: 'Brightwood',
    postalCode: '97011',
  },
  'brightwood-va': {
    name: 'Brightwood',
    postalCode: '22715',
  },
  'brilliant-al': {
    name: 'Brilliant',
    postalCode: '35548',
  },
  'brilliant-oh': {
    name: 'Brilliant',
    postalCode: '43913',
  },
  'brillion-wi': {
    name: 'Brillion',
    postalCode: '54110',
  },
  'brimfield-il': {
    name: 'Brimfield',
    postalCode: '61517',
  },
  'brimfield-ma': {
    name: 'Brimfield',
    postalCode: '01010',
  },
  'brimley-mi': {
    name: 'Brimley',
    postalCode: '49715',
  },
  'brimson-mn': {
    name: 'Brimson',
    postalCode: '55602',
  },
  'bringhurst-in': {
    name: 'Bringhurst',
    postalCode: '46913',
  },
  'brinkhaven-oh': {
    name: 'Brinkhaven',
    postalCode: '43006',
  },
  'brinkley-ar': {
    name: 'Brinkley',
    postalCode: '72021',
  },
  'brinklow-md': {
    name: 'Brinklow',
    postalCode: '20862',
  },
  'brinktown-mo': {
    name: 'Brinktown',
    postalCode: '65443',
  },
  'brinnon-wa': {
    name: 'Brinnon',
    postalCode: '98320',
  },
  'brinson-ga': {
    name: 'Brinson',
    postalCode: '39825',
  },
  'brisbane-ca': {
    name: 'Brisbane',
    postalCode: '94005',
  },
  'briscoe-tx': {
    name: 'Briscoe',
    postalCode: '79011',
  },
  'bristol-ct': {
    name: 'Bristol',
    postalCode: '06010',
  },
  'bristol-fl': {
    name: 'Bristol',
    postalCode: '32321',
  },
  'bristol-ga': {
    name: 'Bristol',
    postalCode: '31518',
  },
  'bristol-il': {
    name: 'Bristol',
    postalCode: '60512',
  },
  'bristol-in': {
    name: 'Bristol',
    postalCode: '46507',
  },
  'bristol-me': {
    name: 'Bristol',
    postalCode: '04539',
  },
  'bristol-nh': {
    name: 'Bristol',
    postalCode: '03222',
  },
  'bristol-pa': {
    name: 'Bristol',
    postalCode: '19007',
  },
  'bristol-ri': {
    name: 'Bristol',
    postalCode: '02809',
  },
  'bristol-sd': {
    name: 'Bristol',
    postalCode: '57219',
  },
  'bristol-tn': {
    name: 'Bristol',
    postalCode: '37620',
  },
  'bristol-va': {
    name: 'Bristol',
    postalCode: '24201',
  },
  'bristol-vt': {
    name: 'Bristol',
    postalCode: '05443',
  },
  'bristol-wi': {
    name: 'Bristol',
    postalCode: '53104',
  },
  'bristolville-oh': {
    name: 'Bristolville',
    postalCode: '44402',
  },
  'bristow-ia': {
    name: 'Bristow',
    postalCode: '50611',
  },
  'bristow-in': {
    name: 'Bristow',
    postalCode: '47515',
  },
  'bristow-ne': {
    name: 'Bristow',
    postalCode: '68719',
  },
  'bristow-ok': {
    name: 'Bristow',
    postalCode: '74010',
  },
  'bristow-va': {
    name: 'Bristow',
    postalCode: '20136',
  },
  'britt-ia': {
    name: 'Britt',
    postalCode: '50423',
  },
  'britt-mn': {
    name: 'Britt',
    postalCode: '55710',
  },
  'britton-mi': {
    name: 'Britton',
    postalCode: '49229',
  },
  'britton-sd': {
    name: 'Britton',
    postalCode: '57430',
  },
  'brixey-mo': {
    name: 'Brixey',
    postalCode: '65618',
  },
  'broad-brook-ct': {
    name: 'Broad Brook',
    postalCode: '06016',
  },
  'broad-run-va': {
    name: 'Broad Run',
    postalCode: '20137',
  },
  'broad-top-pa': {
    name: 'Broad Top',
    postalCode: '16621',
  },
  'broadalbin-ny': {
    name: 'Broadalbin',
    postalCode: '12025',
  },
  'broadbent-or': {
    name: 'Broadbent',
    postalCode: '97414',
  },
  'broaddus-tx': {
    name: 'Broaddus',
    postalCode: '75929',
  },
  'broadford-va': {
    name: 'Broadford',
    postalCode: '24316',
  },
  'broadlands-il': {
    name: 'Broadlands',
    postalCode: '61816',
  },
  'broadus-mt': {
    name: 'Broadus',
    postalCode: '59317',
  },
  'broadview-il': {
    name: 'Broadview',
    postalCode: '60155',
  },
  'broadview-mt': {
    name: 'Broadview',
    postalCode: '59015',
  },
  'broadview-nm': {
    name: 'Broadview',
    postalCode: '88112',
  },
  'broadview-heights-oh': {
    name: 'Broadview Heights',
    postalCode: '44147',
  },
  'broadwater-ne': {
    name: 'Broadwater',
    postalCode: '69125',
  },
  'broadway-nc': {
    name: 'Broadway',
    postalCode: '27505',
  },
  'broadway-va': {
    name: 'Broadway',
    postalCode: '22815',
  },
  'brock-ne': {
    name: 'Brock',
    postalCode: '68320',
  },
  'brocket-nd': {
    name: 'Brocket',
    postalCode: '58321',
  },
  'brockport-ny': {
    name: 'Brockport',
    postalCode: '14420',
  },
  'brockport-pa': {
    name: 'Brockport',
    postalCode: '15823',
  },
  'brockton-ma': {
    name: 'Brockton',
    postalCode: '02301',
  },
  'brockton-mt': {
    name: 'Brockton',
    postalCode: '59213',
  },
  'brockton-pa': {
    name: 'Brockton',
    postalCode: '17925',
  },
  'brockway-mt': {
    name: 'Brockway',
    postalCode: '59214',
  },
  'brockway-pa': {
    name: 'Brockway',
    postalCode: '15824',
  },
  'brockwell-ar': {
    name: 'Brockwell',
    postalCode: '72517',
  },
  'brocton-il': {
    name: 'Brocton',
    postalCode: '61917',
  },
  'brocton-ny': {
    name: 'Brocton',
    postalCode: '14716',
  },
  'brodhead-ky': {
    name: 'Brodhead',
    postalCode: '40409',
  },
  'brodhead-wi': {
    name: 'Brodhead',
    postalCode: '53520',
  },
  'brodheadsville-pa': {
    name: 'Brodheadsville',
    postalCode: '18322',
  },
  'brodnax-va': {
    name: 'Brodnax',
    postalCode: '23920',
  },
  'brogan-or': {
    name: 'Brogan',
    postalCode: '97903',
  },
  'brogue-pa': {
    name: 'Brogue',
    postalCode: '17309',
  },
  'brohard-wv': {
    name: 'Brohard',
    postalCode: '26138',
  },
  'broken-arrow-ok': {
    name: 'Broken Arrow',
    postalCode: '74011',
  },
  'broken-bow-ne': {
    name: 'Broken Bow',
    postalCode: '68822',
  },
  'broken-bow-ok': {
    name: 'Broken Bow',
    postalCode: '74728',
  },
  'bronaugh-mo': {
    name: 'Bronaugh',
    postalCode: '64728',
  },
  'bronson-fl': {
    name: 'Bronson',
    postalCode: '32621',
  },
  'bronson-ia': {
    name: 'Bronson',
    postalCode: '51007',
  },
  'bronson-ks': {
    name: 'Bronson',
    postalCode: '66716',
  },
  'bronson-mi': {
    name: 'Bronson',
    postalCode: '49028',
  },
  'bronson-tx': {
    name: 'Bronson',
    postalCode: '75930',
  },
  'bronston-ky': {
    name: 'Bronston',
    postalCode: '42518',
  },
  'bronte-tx': {
    name: 'Bronte',
    postalCode: '76933',
  },
  'bronwood-ga': {
    name: 'Bronwood',
    postalCode: '39826',
  },
  'bronx-ny': {
    name: 'Bronx',
    postalCode: '10451',
  },
  'bronxville-ny': {
    name: 'Bronxville',
    postalCode: '10708',
  },
  'brook-in': {
    name: 'Brook',
    postalCode: '47922',
  },
  'brook-park-mn': {
    name: 'Brook Park',
    postalCode: '55007',
  },
  'brookeland-tx': {
    name: 'Brookeland',
    postalCode: '75931',
  },
  'brooker-fl': {
    name: 'Brooker',
    postalCode: '32622',
  },
  'brookesmith-tx': {
    name: 'Brookesmith',
    postalCode: '76827',
  },
  'brookeville-md': {
    name: 'Brookeville',
    postalCode: '20833',
  },
  'brookfield-ct': {
    name: 'Brookfield',
    postalCode: '06804',
  },
  'brookfield-il': {
    name: 'Brookfield',
    postalCode: '60513',
  },
  'brookfield-ma': {
    name: 'Brookfield',
    postalCode: '01506',
  },
  'brookfield-mo': {
    name: 'Brookfield',
    postalCode: '64628',
  },
  'brookfield-ny': {
    name: 'Brookfield',
    postalCode: '13314',
  },
  'brookfield-oh': {
    name: 'Brookfield',
    postalCode: '44403',
  },
  'brookfield-vt': {
    name: 'Brookfield',
    postalCode: '05036',
  },
  'brookfield-wi': {
    name: 'Brookfield',
    postalCode: '53005',
  },
  'brookhaven-ms': {
    name: 'Brookhaven',
    postalCode: '39601',
  },
  'brookhaven-ny': {
    name: 'Brookhaven',
    postalCode: '11719',
  },
  'brookhaven-pa': {
    name: 'Brookhaven',
    postalCode: '19015',
  },
  'brookings-or': {
    name: 'Brookings',
    postalCode: '97415',
  },
  'brookings-sd': {
    name: 'Brookings',
    postalCode: '57006',
  },
  'brookland-ar': {
    name: 'Brookland',
    postalCode: '72417',
  },
  'brooklet-ga': {
    name: 'Brooklet',
    postalCode: '30415',
  },
  'brooklin-me': {
    name: 'Brooklin',
    postalCode: '04616',
  },
  'brookline-ma': {
    name: 'Brookline',
    postalCode: '02445',
  },
  'brookline-mo': {
    name: 'Brookline',
    postalCode: '65619',
  },
  'brookline-nh': {
    name: 'Brookline',
    postalCode: '03033',
  },
  'brooklyn-ct': {
    name: 'Brooklyn',
    postalCode: '06234',
  },
  'brooklyn-ia': {
    name: 'Brooklyn',
    postalCode: '52211',
  },
  'brooklyn-md': {
    name: 'Brooklyn',
    postalCode: '21225',
  },
  'brooklyn-mi': {
    name: 'Brooklyn',
    postalCode: '49230',
  },
  'brooklyn-ms': {
    name: 'Brooklyn',
    postalCode: '39425',
  },
  'brooklyn-ny': {
    name: 'Brooklyn',
    postalCode: '11201',
  },
  'brooklyn-wi': {
    name: 'Brooklyn',
    postalCode: '53521',
  },
  'brookneal-va': {
    name: 'Brookneal',
    postalCode: '24528',
  },
  'brookpark-oh': {
    name: 'Brookpark',
    postalCode: '44142',
  },
  'brookport-il': {
    name: 'Brookport',
    postalCode: '62910',
  },
  'brooks-ca': {
    name: 'Brooks',
    postalCode: '95606',
  },
  'brooks-ga': {
    name: 'Brooks',
    postalCode: '30205',
  },
  'brooks-ky': {
    name: 'Brooks',
    postalCode: '40109',
  },
  'brooks-me': {
    name: 'Brooks',
    postalCode: '04921',
  },
  'brooks-mn': {
    name: 'Brooks',
    postalCode: '56715',
  },
  'brookshire-tx': {
    name: 'Brookshire',
    postalCode: '77423',
  },
  'brookston-in': {
    name: 'Brookston',
    postalCode: '47923',
  },
  'brookston-mn': {
    name: 'Brookston',
    postalCode: '55711',
  },
  'brookston-tx': {
    name: 'Brookston',
    postalCode: '75421',
  },
  'brooksville-fl': {
    name: 'Brooksville',
    postalCode: '34601',
  },
  'brooksville-ky': {
    name: 'Brooksville',
    postalCode: '41004',
  },
  'brooksville-me': {
    name: 'Brooksville',
    postalCode: '04617',
  },
  'brooksville-ms': {
    name: 'Brooksville',
    postalCode: '39739',
  },
  'brookton-me': {
    name: 'Brookton',
    postalCode: '04413',
  },
  'brooktondale-ny': {
    name: 'Brooktondale',
    postalCode: '14817',
  },
  'brookville-in': {
    name: 'Brookville',
    postalCode: '47012',
  },
  'brookville-ks': {
    name: 'Brookville',
    postalCode: '67425',
  },
  'brookville-oh': {
    name: 'Brookville',
    postalCode: '45309',
  },
  'brookville-pa': {
    name: 'Brookville',
    postalCode: '15825',
  },
  'brookwood-al': {
    name: 'Brookwood',
    postalCode: '35444',
  },
  'broomall-pa': {
    name: 'Broomall',
    postalCode: '19008',
  },
  'broomes-island-md': {
    name: 'Broomes Island',
    postalCode: '20615',
  },
  'broomfield-co': {
    name: 'Broomfield',
    postalCode: '80020',
  },
  'brooten-mn': {
    name: 'Brooten',
    postalCode: '56316',
  },
  'broseley-mo': {
    name: 'Broseley',
    postalCode: '63932',
  },
  'brothers-or': {
    name: 'Brothers',
    postalCode: '97712',
  },
  'broughton-il': {
    name: 'Broughton',
    postalCode: '62817',
  },
  'broussard-la': {
    name: 'Broussard',
    postalCode: '70518',
  },
  'browder-ky': {
    name: 'Browder',
    postalCode: '42326',
  },
  'browerville-mn': {
    name: 'Browerville',
    postalCode: '56438',
  },
  'brown-city-mi': {
    name: 'Brown City',
    postalCode: '48416',
  },
  'brownell-ks': {
    name: 'Brownell',
    postalCode: '67521',
  },
  'brownfield-me': {
    name: 'Brownfield',
    postalCode: '04010',
  },
  'brownfield-tx': {
    name: 'Brownfield',
    postalCode: '79316',
  },
  'browning-il': {
    name: 'Browning',
    postalCode: '62624',
  },
  'browning-mo': {
    name: 'Browning',
    postalCode: '64630',
  },
  'browning-mt': {
    name: 'Browning',
    postalCode: '59417',
  },
  'browns-il': {
    name: 'Browns',
    postalCode: '62818',
  },
  'browns-mills-nj': {
    name: 'Browns Mills',
    postalCode: '08015',
  },
  'browns-summit-nc': {
    name: 'Browns Summit',
    postalCode: '27214',
  },
  'browns-valley-ca': {
    name: 'Browns Valley',
    postalCode: '95918',
  },
  'browns-valley-mn': {
    name: 'Browns Valley',
    postalCode: '56219',
  },
  'brownsboro-al': {
    name: 'Brownsboro',
    postalCode: '35741',
  },
  'brownsboro-tx': {
    name: 'Brownsboro',
    postalCode: '75756',
  },
  'brownsburg-in': {
    name: 'Brownsburg',
    postalCode: '46112',
  },
  'brownsdale-mn': {
    name: 'Brownsdale',
    postalCode: '55918',
  },
  'brownstown-il': {
    name: 'Brownstown',
    postalCode: '62418',
  },
  'brownstown-in': {
    name: 'Brownstown',
    postalCode: '47220',
  },
  'brownsville-ca': {
    name: 'Brownsville',
    postalCode: '95919',
  },
  'brownsville-in': {
    name: 'Brownsville',
    postalCode: '47325',
  },
  'brownsville-ky': {
    name: 'Brownsville',
    postalCode: '42210',
  },
  'brownsville-mn': {
    name: 'Brownsville',
    postalCode: '55919',
  },
  'brownsville-or': {
    name: 'Brownsville',
    postalCode: '97327',
  },
  'brownsville-pa': {
    name: 'Brownsville',
    postalCode: '15417',
  },
  'brownsville-tn': {
    name: 'Brownsville',
    postalCode: '38012',
  },
  'brownsville-tx': {
    name: 'Brownsville',
    postalCode: '78520',
  },
  'brownsville-vt': {
    name: 'Brownsville',
    postalCode: '05037',
  },
  'brownsville-wi': {
    name: 'Brownsville',
    postalCode: '53006',
  },
  'brownton-mn': {
    name: 'Brownton',
    postalCode: '55312',
  },
  'browntown-wi': {
    name: 'Browntown',
    postalCode: '53522',
  },
  'brownville-me': {
    name: 'Brownville',
    postalCode: '04414',
  },
  'brownville-ne': {
    name: 'Brownville',
    postalCode: '68321',
  },
  'brownwood-tx': {
    name: 'Brownwood',
    postalCode: '76801',
  },
  'broxton-ga': {
    name: 'Broxton',
    postalCode: '31519',
  },
  'bruce-ms': {
    name: 'Bruce',
    postalCode: '38915',
  },
  'bruce-sd': {
    name: 'Bruce',
    postalCode: '57220',
  },
  'bruce-wi': {
    name: 'Bruce',
    postalCode: '54819',
  },
  'bruce-crossing-mi': {
    name: 'Bruce Crossing',
    postalCode: '49912',
  },
  'bruceton-tn': {
    name: 'Bruceton',
    postalCode: '38317',
  },
  'bruceton-mills-wv': {
    name: 'Bruceton Mills',
    postalCode: '26525',
  },
  'bruceville-in': {
    name: 'Bruceville',
    postalCode: '47516',
  },
  'bruceville-tx': {
    name: 'Bruceville',
    postalCode: '76630',
  },
  'bruington-va': {
    name: 'Bruington',
    postalCode: '23023',
  },
  'brule-ne': {
    name: 'Brule',
    postalCode: '69127',
  },
  'brule-wi': {
    name: 'Brule',
    postalCode: '54820',
  },
  'brumley-mo': {
    name: 'Brumley',
    postalCode: '65017',
  },
  'brundidge-al': {
    name: 'Brundidge',
    postalCode: '36010',
  },
  'bruneau-id': {
    name: 'Bruneau',
    postalCode: '83604',
  },
  'bruner-mo': {
    name: 'Bruner',
    postalCode: '65620',
  },
  'bruni-tx': {
    name: 'Bruni',
    postalCode: '78344',
  },
  'bruning-ne': {
    name: 'Bruning',
    postalCode: '68322',
  },
  'bruno-mn': {
    name: 'Bruno',
    postalCode: '55712',
  },
  'bruno-ne': {
    name: 'Bruno',
    postalCode: '68014',
  },
  'brunson-sc': {
    name: 'Brunson',
    postalCode: '29911',
  },
  'brunswick-ga': {
    name: 'Brunswick',
    postalCode: '31520',
  },
  'brunswick-md': {
    name: 'Brunswick',
    postalCode: '21716',
  },
  'brunswick-me': {
    name: 'Brunswick',
    postalCode: '04011',
  },
  'brunswick-mo': {
    name: 'Brunswick',
    postalCode: '65236',
  },
  'brunswick-ne': {
    name: 'Brunswick',
    postalCode: '68720',
  },
  'brunswick-oh': {
    name: 'Brunswick',
    postalCode: '44212',
  },
  'brusett-mt': {
    name: 'Brusett',
    postalCode: '59318',
  },
  'brush-co': {
    name: 'Brush',
    postalCode: '80723',
  },
  'brush-creek-tn': {
    name: 'Brush Creek',
    postalCode: '38547',
  },
  'brush-prairie-wa': {
    name: 'Brush Prairie',
    postalCode: '98606',
  },
  'brushton-ny': {
    name: 'Brushton',
    postalCode: '12916',
  },
  'brusly-la': {
    name: 'Brusly',
    postalCode: '70719',
  },
  'brussels-il': {
    name: 'Brussels',
    postalCode: '62013',
  },
  'brussels-wi': {
    name: 'Brussels',
    postalCode: '54204',
  },
  'brutus-mi': {
    name: 'Brutus',
    postalCode: '49716',
  },
  'bryan-oh': {
    name: 'Bryan',
    postalCode: '43506',
  },
  'bryan-tx': {
    name: 'Bryan',
    postalCode: '77801',
  },
  'bryans-road-md': {
    name: 'Bryans Road',
    postalCode: '20616',
  },
  'bryant-al': {
    name: 'Bryant',
    postalCode: '35958',
  },
  'bryant-ar': {
    name: 'Bryant',
    postalCode: '72022',
  },
  'bryant-ia': {
    name: 'Bryant',
    postalCode: '52727',
  },
  'bryant-in': {
    name: 'Bryant',
    postalCode: '47326',
  },
  'bryant-sd': {
    name: 'Bryant',
    postalCode: '57221',
  },
  'bryant-wi': {
    name: 'Bryant',
    postalCode: '54418',
  },
  'bryant-pond-me': {
    name: 'Bryant Pond',
    postalCode: '04219',
  },
  'bryantown-md': {
    name: 'Bryantown',
    postalCode: '20617',
  },
  'bryants-store-ky': {
    name: 'Bryants Store',
    postalCode: '40921',
  },
  'bryce-canyon-ut': {
    name: 'Bryce Canyon',
    postalCode: '84717',
  },
  'bryceville-fl': {
    name: 'Bryceville',
    postalCode: '32009',
  },
  'bryn-mawr-ca': {
    name: 'Bryn Mawr',
    postalCode: '92318',
  },
  'bryn-mawr-pa': {
    name: 'Bryn Mawr',
    postalCode: '19010',
  },
  'bryson-tx': {
    name: 'Bryson',
    postalCode: '76427',
  },
  'bryson-city-nc': {
    name: 'Bryson City',
    postalCode: '28713',
  },
  'buchanan-ga': {
    name: 'Buchanan',
    postalCode: '30113',
  },
  'buchanan-mi': {
    name: 'Buchanan',
    postalCode: '49107',
  },
  'buchanan-nd': {
    name: 'Buchanan',
    postalCode: '58420',
  },
  'buchanan-ny': {
    name: 'Buchanan',
    postalCode: '10511',
  },
  'buchanan-tn': {
    name: 'Buchanan',
    postalCode: '38222',
  },
  'buchanan-va': {
    name: 'Buchanan',
    postalCode: '24066',
  },
  'buchanan-dam-tx': {
    name: 'Buchanan Dam',
    postalCode: '78609',
  },
  'buckatunna-ms': {
    name: 'Buckatunna',
    postalCode: '39322',
  },
  'buckeye-az': {
    name: 'Buckeye',
    postalCode: '85326',
  },
  'buckeye-wv': {
    name: 'Buckeye',
    postalCode: '24924',
  },
  'buckfield-me': {
    name: 'Buckfield',
    postalCode: '04220',
  },
  'buckhannon-wv': {
    name: 'Buckhannon',
    postalCode: '26201',
  },
  'buckhead-ga': {
    name: 'Buckhead',
    postalCode: '30625',
  },
  'buckholts-tx': {
    name: 'Buckholts',
    postalCode: '76518',
  },
  'buckhorn-ky': {
    name: 'Buckhorn',
    postalCode: '41721',
  },
  'buckhorn-nm': {
    name: 'Buckhorn',
    postalCode: '88025',
  },
  'buckingham-ia': {
    name: 'Buckingham',
    postalCode: '50612',
  },
  'buckingham-il': {
    name: 'Buckingham',
    postalCode: '60917',
  },
  'buckingham-va': {
    name: 'Buckingham',
    postalCode: '23921',
  },
  'buckland-ma': {
    name: 'Buckland',
    postalCode: '01338',
  },
  'buckland-oh': {
    name: 'Buckland',
    postalCode: '45819',
  },
  'buckley-il': {
    name: 'Buckley',
    postalCode: '60918',
  },
  'buckley-mi': {
    name: 'Buckley',
    postalCode: '49620',
  },
  'buckley-wa': {
    name: 'Buckley',
    postalCode: '98321',
  },
  'bucklin-ks': {
    name: 'Bucklin',
    postalCode: '67834',
  },
  'bucklin-mo': {
    name: 'Bucklin',
    postalCode: '64631',
  },
  'buckner-ar': {
    name: 'Buckner',
    postalCode: '71827',
  },
  'buckner-il': {
    name: 'Buckner',
    postalCode: '62819',
  },
  'buckner-ky': {
    name: 'Buckner',
    postalCode: '40010',
  },
  'buckner-mo': {
    name: 'Buckner',
    postalCode: '64016',
  },
  'buckskin-in': {
    name: 'Buckskin',
    postalCode: '47647',
  },
  'bucksport-me': {
    name: 'Bucksport',
    postalCode: '04416',
  },
  'bucyrus-ks': {
    name: 'Bucyrus',
    postalCode: '66013',
  },
  'bucyrus-mo': {
    name: 'Bucyrus',
    postalCode: '65444',
  },
  'bucyrus-oh': {
    name: 'Bucyrus',
    postalCode: '44820',
  },
  'buda-il': {
    name: 'Buda',
    postalCode: '61314',
  },
  'buda-tx': {
    name: 'Buda',
    postalCode: '78610',
  },
  'budd-lake-nj': {
    name: 'Budd Lake',
    postalCode: '07828',
  },
  'bude-ms': {
    name: 'Bude',
    postalCode: '39630',
  },
  'buellton-ca': {
    name: 'Buellton',
    postalCode: '93427',
  },
  'buena-nj': {
    name: 'Buena',
    postalCode: '08310',
  },
  'buena-park-ca': {
    name: 'Buena Park',
    postalCode: '90620',
  },
  'buena-vista-co': {
    name: 'Buena Vista',
    postalCode: '81211',
  },
  'buena-vista-ga': {
    name: 'Buena Vista',
    postalCode: '31803',
  },
  'buena-vista-pa': {
    name: 'Buena Vista',
    postalCode: '15018',
  },
  'buena-vista-tn': {
    name: 'Buena Vista',
    postalCode: '38318',
  },
  'buena-vista-va': {
    name: 'Buena Vista',
    postalCode: '24416',
  },
  'buffalo-il': {
    name: 'Buffalo',
    postalCode: '62515',
  },
  'buffalo-ks': {
    name: 'Buffalo',
    postalCode: '66717',
  },
  'buffalo-ky': {
    name: 'Buffalo',
    postalCode: '42716',
  },
  'buffalo-mn': {
    name: 'Buffalo',
    postalCode: '55313',
  },
  'buffalo-mo': {
    name: 'Buffalo',
    postalCode: '65622',
  },
  'buffalo-mt': {
    name: 'Buffalo',
    postalCode: '59418',
  },
  'buffalo-nd': {
    name: 'Buffalo',
    postalCode: '58011',
  },
  'buffalo-ny': {
    name: 'Buffalo',
    postalCode: '14201',
  },
  'buffalo-ok': {
    name: 'Buffalo',
    postalCode: '73834',
  },
  'buffalo-sc': {
    name: 'Buffalo',
    postalCode: '29321',
  },
  'buffalo-sd': {
    name: 'Buffalo',
    postalCode: '57720',
  },
  'buffalo-tx': {
    name: 'Buffalo',
    postalCode: '75831',
  },
  'buffalo-wv': {
    name: 'Buffalo',
    postalCode: '25033',
  },
  'buffalo-wy': {
    name: 'Buffalo',
    postalCode: '82834',
  },
  'buffalo-center-ia': {
    name: 'Buffalo Center',
    postalCode: '50424',
  },
  'buffalo-gap-sd': {
    name: 'Buffalo Gap',
    postalCode: '57722',
  },
  'buffalo-gap-tx': {
    name: 'Buffalo Gap',
    postalCode: '79508',
  },
  'buffalo-grove-il': {
    name: 'Buffalo Grove',
    postalCode: '60089',
  },
  'buffalo-junction-va': {
    name: 'Buffalo Junction',
    postalCode: '24529',
  },
  'buffalo-lake-mn': {
    name: 'Buffalo Lake',
    postalCode: '55314',
  },
  'buffalo-mills-pa': {
    name: 'Buffalo Mills',
    postalCode: '15534',
  },
  'buffalo-valley-tn': {
    name: 'Buffalo Valley',
    postalCode: '38548',
  },
  'buford-ga': {
    name: 'Buford',
    postalCode: '30518',
  },
  'buford-wy': {
    name: 'Buford',
    postalCode: '82052',
  },
  'buhl-al': {
    name: 'Buhl',
    postalCode: '35446',
  },
  'buhl-id': {
    name: 'Buhl',
    postalCode: '83316',
  },
  'buhler-ks': {
    name: 'Buhler',
    postalCode: '67522',
  },
  'bula-tx': {
    name: 'Bula',
    postalCode: '79320',
  },
  'bulan-ky': {
    name: 'Bulan',
    postalCode: '41722',
  },
  'bulger-pa': {
    name: 'Bulger',
    postalCode: '15019',
  },
  'bull-shoals-ar': {
    name: 'Bull Shoals',
    postalCode: '72619',
  },
  'bullard-tx': {
    name: 'Bullard',
    postalCode: '75757',
  },
  'bullhead-city-az': {
    name: 'Bullhead City',
    postalCode: '86429',
  },
  'bullock-nc': {
    name: 'Bullock',
    postalCode: '27507',
  },
  'bulls-gap-tn': {
    name: 'Bulls Gap',
    postalCode: '37711',
  },
  'bulpitt-il': {
    name: 'Bulpitt',
    postalCode: '62517',
  },
  'bulverde-tx': {
    name: 'Bulverde',
    postalCode: '78163',
  },
  'bumpass-va': {
    name: 'Bumpass',
    postalCode: '23024',
  },
  'bumpus-mills-tn': {
    name: 'Bumpus Mills',
    postalCode: '37028',
  },
  'buna-tx': {
    name: 'Buna',
    postalCode: '77612',
  },
  'bunceton-mo': {
    name: 'Bunceton',
    postalCode: '65237',
  },
  'bunch-ok': {
    name: 'Bunch',
    postalCode: '74931',
  },
  'buncombe-il': {
    name: 'Buncombe',
    postalCode: '62912',
  },
  'bunker-mo': {
    name: 'Bunker',
    postalCode: '63629',
  },
  'bunker-hill-il': {
    name: 'Bunker Hill',
    postalCode: '62014',
  },
  'bunker-hill-in': {
    name: 'Bunker Hill',
    postalCode: '46914',
  },
  'bunker-hill-ks': {
    name: 'Bunker Hill',
    postalCode: '67626',
  },
  'bunker-hill-wv': {
    name: 'Bunker Hill',
    postalCode: '25413',
  },
  'bunkie-la': {
    name: 'Bunkie',
    postalCode: '71322',
  },
  'bunn-nc': {
    name: 'Bunn',
    postalCode: '27508',
  },
  'bunnell-fl': {
    name: 'Bunnell',
    postalCode: '32110',
  },
  'bunnlevel-nc': {
    name: 'Bunnlevel',
    postalCode: '28323',
  },
  'buras-la': {
    name: 'Buras',
    postalCode: '70041',
  },
  'burbank-ca': {
    name: 'Burbank',
    postalCode: '91501',
  },
  'burbank-il': {
    name: 'Burbank',
    postalCode: '60459',
  },
  'burbank-oh': {
    name: 'Burbank',
    postalCode: '44214',
  },
  'burbank-ok': {
    name: 'Burbank',
    postalCode: '74633',
  },
  'burbank-sd': {
    name: 'Burbank',
    postalCode: '57010',
  },
  'burbank-wa': {
    name: 'Burbank',
    postalCode: '99323',
  },
  'burchard-ne': {
    name: 'Burchard',
    postalCode: '68323',
  },
  'burden-ks': {
    name: 'Burden',
    postalCode: '67019',
  },
  'burdett-ks': {
    name: 'Burdett',
    postalCode: '67523',
  },
  'burdett-ny': {
    name: 'Burdett',
    postalCode: '14818',
  },
  'burdick-ks': {
    name: 'Burdick',
    postalCode: '66838',
  },
  'burdine-ky': {
    name: 'Burdine',
    postalCode: '41517',
  },
  'burfordville-mo': {
    name: 'Burfordville',
    postalCode: '63739',
  },
  'burgaw-nc': {
    name: 'Burgaw',
    postalCode: '28425',
  },
  'burgess-va': {
    name: 'Burgess',
    postalCode: '22432',
  },
  'burgettstown-pa': {
    name: 'Burgettstown',
    postalCode: '15021',
  },
  'burghill-oh': {
    name: 'Burghill',
    postalCode: '44404',
  },
  'burgoon-oh': {
    name: 'Burgoon',
    postalCode: '43407',
  },
  'burkburnett-tx': {
    name: 'Burkburnett',
    postalCode: '76354',
  },
  'burke-ny': {
    name: 'Burke',
    postalCode: '12917',
  },
  'burke-sd': {
    name: 'Burke',
    postalCode: '57523',
  },
  'burke-va': {
    name: 'Burke',
    postalCode: '22015',
  },
  'burkesville-ky': {
    name: 'Burkesville',
    postalCode: '42717',
  },
  'burket-in': {
    name: 'Burket',
    postalCode: '46508',
  },
  'burkett-tx': {
    name: 'Burkett',
    postalCode: '76828',
  },
  'burkeville-tx': {
    name: 'Burkeville',
    postalCode: '75932',
  },
  'burkeville-va': {
    name: 'Burkeville',
    postalCode: '23922',
  },
  'burkittsville-md': {
    name: 'Burkittsville',
    postalCode: '21718',
  },
  'burleson-tx': {
    name: 'Burleson',
    postalCode: '76028',
  },
  'burley-id': {
    name: 'Burley',
    postalCode: '83318',
  },
  'burlingame-ca': {
    name: 'Burlingame',
    postalCode: '94010',
  },
  'burlingame-ks': {
    name: 'Burlingame',
    postalCode: '66413',
  },
  'burlington-co': {
    name: 'Burlington',
    postalCode: '80807',
  },
  'burlington-ct': {
    name: 'Burlington',
    postalCode: '06013',
  },
  'burlington-ia': {
    name: 'Burlington',
    postalCode: '52601',
  },
  'burlington-in': {
    name: 'Burlington',
    postalCode: '46915',
  },
  'burlington-ks': {
    name: 'Burlington',
    postalCode: '66839',
  },
  'burlington-ky': {
    name: 'Burlington',
    postalCode: '41005',
  },
  'burlington-ma': {
    name: 'Burlington',
    postalCode: '01803',
  },
  'burlington-me': {
    name: 'Burlington',
    postalCode: '04417',
  },
  'burlington-mi': {
    name: 'Burlington',
    postalCode: '49029',
  },
  'burlington-nc': {
    name: 'Burlington',
    postalCode: '27215',
  },
  'burlington-nd': {
    name: 'Burlington',
    postalCode: '58722',
  },
  'burlington-nj': {
    name: 'Burlington',
    postalCode: '08016',
  },
  'burlington-ok': {
    name: 'Burlington',
    postalCode: '73722',
  },
  'burlington-tx': {
    name: 'Burlington',
    postalCode: '76519',
  },
  'burlington-vt': {
    name: 'Burlington',
    postalCode: '05401',
  },
  'burlington-wa': {
    name: 'Burlington',
    postalCode: '98233',
  },
  'burlington-wi': {
    name: 'Burlington',
    postalCode: '53105',
  },
  'burlington-wv': {
    name: 'Burlington',
    postalCode: '26710',
  },
  'burlington-wy': {
    name: 'Burlington',
    postalCode: '82411',
  },
  'burlington-flats-ny': {
    name: 'Burlington Flats',
    postalCode: '13315',
  },
  'burlington-junction-mo': {
    name: 'Burlington Junction',
    postalCode: '64428',
  },
  'burlison-tn': {
    name: 'Burlison',
    postalCode: '38015',
  },
  'burna-ky': {
    name: 'Burna',
    postalCode: '42028',
  },
  'burnet-tx': {
    name: 'Burnet',
    postalCode: '78611',
  },
  'burnett-wi': {
    name: 'Burnett',
    postalCode: '53922',
  },
  'burnettsville-in': {
    name: 'Burnettsville',
    postalCode: '47926',
  },
  'burney-ca': {
    name: 'Burney',
    postalCode: '96013',
  },
  'burneyville-ok': {
    name: 'Burneyville',
    postalCode: '73430',
  },
  'burnham-me': {
    name: 'Burnham',
    postalCode: '04922',
  },
  'burnham-pa': {
    name: 'Burnham',
    postalCode: '17009',
  },
  'burns-ks': {
    name: 'Burns',
    postalCode: '66840',
  },
  'burns-or': {
    name: 'Burns',
    postalCode: '97720',
  },
  'burns-tn': {
    name: 'Burns',
    postalCode: '37029',
  },
  'burns-wy': {
    name: 'Burns',
    postalCode: '82053',
  },
  'burnside-ky': {
    name: 'Burnside',
    postalCode: '42519',
  },
  'burnsville-mn': {
    name: 'Burnsville',
    postalCode: '55306',
  },
  'burnsville-ms': {
    name: 'Burnsville',
    postalCode: '38833',
  },
  'burnsville-nc': {
    name: 'Burnsville',
    postalCode: '28714',
  },
  'burnsville-wv': {
    name: 'Burnsville',
    postalCode: '26335',
  },
  'burnt-cabins-pa': {
    name: 'Burnt Cabins',
    postalCode: '17215',
  },
  'burnt-hills-ny': {
    name: 'Burnt Hills',
    postalCode: '12027',
  },
  'burnt-prairie-il': {
    name: 'Burnt Prairie',
    postalCode: '62820',
  },
  'burnt-ranch-ca': {
    name: 'Burnt Ranch',
    postalCode: '95527',
  },
  'burr-ne': {
    name: 'Burr',
    postalCode: '68324',
  },
  'burr-hill-va': {
    name: 'Burr Hill',
    postalCode: '22433',
  },
  'burr-oak-ks': {
    name: 'Burr Oak',
    postalCode: '66936',
  },
  'burr-oak-mi': {
    name: 'Burr Oak',
    postalCode: '49030',
  },
  'burrton-ks': {
    name: 'Burrton',
    postalCode: '67020',
  },
  'burt-ia': {
    name: 'Burt',
    postalCode: '50522',
  },
  'burt-mi': {
    name: 'Burt',
    postalCode: '48417',
  },
  'burt-ny': {
    name: 'Burt',
    postalCode: '14028',
  },
  'burton-mi': {
    name: 'Burton',
    postalCode: '48509',
  },
  'burton-oh': {
    name: 'Burton',
    postalCode: '44021',
  },
  'burton-tx': {
    name: 'Burton',
    postalCode: '77835',
  },
  'burton-wv': {
    name: 'Burton',
    postalCode: '26562',
  },
  'burtonsville-md': {
    name: 'Burtonsville',
    postalCode: '20866',
  },
  'burtrum-mn': {
    name: 'Burtrum',
    postalCode: '56318',
  },
  'burwell-ne': {
    name: 'Burwell',
    postalCode: '68823',
  },
  'busby-mt': {
    name: 'Busby',
    postalCode: '59016',
  },
  'bush-la': {
    name: 'Bush',
    postalCode: '70431',
  },
  'bushkill-pa': {
    name: 'Bushkill',
    postalCode: '18324',
  },
  'bushnell-fl': {
    name: 'Bushnell',
    postalCode: '33513',
  },
  'bushnell-il': {
    name: 'Bushnell',
    postalCode: '61422',
  },
  'bushnell-ne': {
    name: 'Bushnell',
    postalCode: '69128',
  },
  'bushton-ks': {
    name: 'Bushton',
    postalCode: '67427',
  },
  'bushwood-md': {
    name: 'Bushwood',
    postalCode: '20618',
  },
  'buskirk-ny': {
    name: 'Buskirk',
    postalCode: '12028',
  },
  'bussey-ia': {
    name: 'Bussey',
    postalCode: '50044',
  },
  'bustins-island-me': {
    name: 'Bustins Island',
    postalCode: '04013',
  },
  'busy-ky': {
    name: 'Busy',
    postalCode: '41723',
  },
  'butler-al': {
    name: 'Butler',
    postalCode: '36904',
  },
  'butler-ga': {
    name: 'Butler',
    postalCode: '31006',
  },
  'butler-il': {
    name: 'Butler',
    postalCode: '62015',
  },
  'butler-in': {
    name: 'Butler',
    postalCode: '46721',
  },
  'butler-ky': {
    name: 'Butler',
    postalCode: '41006',
  },
  'butler-mo': {
    name: 'Butler',
    postalCode: '64730',
  },
  'butler-nj': {
    name: 'Butler',
    postalCode: '07405',
  },
  'butler-oh': {
    name: 'Butler',
    postalCode: '44822',
  },
  'butler-ok': {
    name: 'Butler',
    postalCode: '73625',
  },
  'butler-pa': {
    name: 'Butler',
    postalCode: '16001',
  },
  'butler-tn': {
    name: 'Butler',
    postalCode: '37640',
  },
  'butler-wi': {
    name: 'Butler',
    postalCode: '53007',
  },
  'butlerville-in': {
    name: 'Butlerville',
    postalCode: '47223',
  },
  'butner-nc': {
    name: 'Butner',
    postalCode: '27509',
  },
  'butte-mt': {
    name: 'Butte',
    postalCode: '59701',
  },
  'butte-nd': {
    name: 'Butte',
    postalCode: '58723',
  },
  'butte-ne': {
    name: 'Butte',
    postalCode: '68722',
  },
  'butte-city-ca': {
    name: 'Butte City',
    postalCode: '95920',
  },
  'butte-falls-or': {
    name: 'Butte Falls',
    postalCode: '97522',
  },
  'butterfield-mn': {
    name: 'Butterfield',
    postalCode: '56120',
  },
  'butternut-wi': {
    name: 'Butternut',
    postalCode: '54514',
  },
  'buttonwillow-ca': {
    name: 'Buttonwillow',
    postalCode: '93206',
  },
  'buxton-me': {
    name: 'Buxton',
    postalCode: '04093',
  },
  'buxton-nd': {
    name: 'Buxton',
    postalCode: '58218',
  },
  'buxton-or': {
    name: 'Buxton',
    postalCode: '97109',
  },
  'buzzards-bay-ma': {
    name: 'Buzzards Bay',
    postalCode: '02532',
  },
  'byars-ok': {
    name: 'Byars',
    postalCode: '74831',
  },
  'bybee-tn': {
    name: 'Bybee',
    postalCode: '37713',
  },
  'byers-co': {
    name: 'Byers',
    postalCode: '80103',
  },
  'byers-ks': {
    name: 'Byers',
    postalCode: '67021',
  },
  'byers-tx': {
    name: 'Byers',
    postalCode: '76357',
  },
  'byesville-oh': {
    name: 'Byesville',
    postalCode: '43723',
  },
  'byfield-ma': {
    name: 'Byfield',
    postalCode: '01922',
  },
  'byhalia-ms': {
    name: 'Byhalia',
    postalCode: '38611',
  },
  'bynum-mt': {
    name: 'Bynum',
    postalCode: '59419',
  },
  'bynum-tx': {
    name: 'Bynum',
    postalCode: '76631',
  },
  'bypro-ky': {
    name: 'Bypro',
    postalCode: '41612',
  },
  'byram-ms': {
    name: 'Byram',
    postalCode: '39272',
  },
  'byrdstown-tn': {
    name: 'Byrdstown',
    postalCode: '38549',
  },
  'byrnedale-pa': {
    name: 'Byrnedale',
    postalCode: '15827',
  },
  'byromville-ga': {
    name: 'Byromville',
    postalCode: '31007',
  },
  'byron-ca': {
    name: 'Byron',
    postalCode: '94514',
  },
  'byron-ga': {
    name: 'Byron',
    postalCode: '31008',
  },
  'byron-il': {
    name: 'Byron',
    postalCode: '61010',
  },
  'byron-mi': {
    name: 'Byron',
    postalCode: '48418',
  },
  'byron-mn': {
    name: 'Byron',
    postalCode: '55920',
  },
  'byron-ne': {
    name: 'Byron',
    postalCode: '68325',
  },
  'byron-ny': {
    name: 'Byron',
    postalCode: '14422',
  },
  'byron-center-mi': {
    name: 'Byron Center',
    postalCode: '49315',
  },
  'caballo-nm': {
    name: 'Caballo',
    postalCode: '87931',
  },
  'cabazon-ca': {
    name: 'Cabazon',
    postalCode: '92230',
  },
  'cabery-il': {
    name: 'Cabery',
    postalCode: '60919',
  },
  'cabin-creek-wv': {
    name: 'Cabin Creek',
    postalCode: '25035',
  },
  'cabin-john-md': {
    name: 'Cabin John',
    postalCode: '20818',
  },
  'cabins-wv': {
    name: 'Cabins',
    postalCode: '26855',
  },
  'cable-oh': {
    name: 'Cable',
    postalCode: '43009',
  },
  'cable-wi': {
    name: 'Cable',
    postalCode: '54821',
  },
  'cabo-rojo-pr': {
    name: 'Cabo Rojo',
    postalCode: '00623',
  },
  'cabool-mo': {
    name: 'Cabool',
    postalCode: '65689',
  },
  'cabot-ar': {
    name: 'Cabot',
    postalCode: '72023',
  },
  'cabot-pa': {
    name: 'Cabot',
    postalCode: '16023',
  },
  'cabot-vt': {
    name: 'Cabot',
    postalCode: '05647',
  },
  'cache-ok': {
    name: 'Cache',
    postalCode: '73527',
  },
  'cache-junction-ut': {
    name: 'Cache Junction',
    postalCode: '84304',
  },
  'cactus-tx': {
    name: 'Cactus',
    postalCode: '79013',
  },
  'caddo-ok': {
    name: 'Caddo',
    postalCode: '74729',
  },
  'caddo-tx': {
    name: 'Caddo',
    postalCode: '76429',
  },
  'caddo-gap-ar': {
    name: 'Caddo Gap',
    postalCode: '71935',
  },
  'caddo-mills-tx': {
    name: 'Caddo Mills',
    postalCode: '75135',
  },
  'cades-sc': {
    name: 'Cades',
    postalCode: '29518',
  },
  'cadet-mo': {
    name: 'Cadet',
    postalCode: '63630',
  },
  'cadillac-mi': {
    name: 'Cadillac',
    postalCode: '49601',
  },
  'cadiz-ky': {
    name: 'Cadiz',
    postalCode: '42211',
  },
  'cadiz-oh': {
    name: 'Cadiz',
    postalCode: '43907',
  },
  'cadott-wi': {
    name: 'Cadott',
    postalCode: '54727',
  },
  'cadwell-ga': {
    name: 'Cadwell',
    postalCode: '31009',
  },
  'cadyville-ny': {
    name: 'Cadyville',
    postalCode: '12918',
  },
  'caguas-pr': {
    name: 'Caguas',
    postalCode: '00725',
  },
  'cahone-co': {
    name: 'Cahone',
    postalCode: '81320',
  },
  'cainsville-mo': {
    name: 'Cainsville',
    postalCode: '64632',
  },
  'cairnbrook-pa': {
    name: 'Cairnbrook',
    postalCode: '15924',
  },
  'cairo-ga': {
    name: 'Cairo',
    postalCode: '39827',
  },
  'cairo-il': {
    name: 'Cairo',
    postalCode: '62914',
  },
  'cairo-mo': {
    name: 'Cairo',
    postalCode: '65239',
  },
  'cairo-ne': {
    name: 'Cairo',
    postalCode: '68824',
  },
  'cairo-ny': {
    name: 'Cairo',
    postalCode: '12413',
  },
  'cairo-wv': {
    name: 'Cairo',
    postalCode: '26337',
  },
  'cal-nev-ari-nv': {
    name: 'Cal Nev Ari',
    postalCode: '89039',
  },
  'calabasas-ca': {
    name: 'Calabasas',
    postalCode: '91302',
  },
  'calabash-nc': {
    name: 'Calabash',
    postalCode: '28467',
  },
  'calais-me': {
    name: 'Calais',
    postalCode: '04619',
  },
  'calais-vt': {
    name: 'Calais',
    postalCode: '05648',
  },
  'calamus-ia': {
    name: 'Calamus',
    postalCode: '52729',
  },
  'calcium-ny': {
    name: 'Calcium',
    postalCode: '13616',
  },
  'calder-id': {
    name: 'Calder',
    postalCode: '83808',
  },
  'caldwell-id': {
    name: 'Caldwell',
    postalCode: '83605',
  },
  'caldwell-ks': {
    name: 'Caldwell',
    postalCode: '67022',
  },
  'caldwell-nj': {
    name: 'Caldwell',
    postalCode: '07006',
  },
  'caldwell-oh': {
    name: 'Caldwell',
    postalCode: '43724',
  },
  'caldwell-tx': {
    name: 'Caldwell',
    postalCode: '77836',
  },
  'caldwell-wv': {
    name: 'Caldwell',
    postalCode: '24925',
  },
  'caledonia-il': {
    name: 'Caledonia',
    postalCode: '61011',
  },
  'caledonia-mi': {
    name: 'Caledonia',
    postalCode: '49316',
  },
  'caledonia-mn': {
    name: 'Caledonia',
    postalCode: '55921',
  },
  'caledonia-mo': {
    name: 'Caledonia',
    postalCode: '63631',
  },
  'caledonia-ms': {
    name: 'Caledonia',
    postalCode: '39740',
  },
  'caledonia-nd': {
    name: 'Caledonia',
    postalCode: '58219',
  },
  'caledonia-ny': {
    name: 'Caledonia',
    postalCode: '14423',
  },
  'caledonia-oh': {
    name: 'Caledonia',
    postalCode: '43314',
  },
  'caledonia-wi': {
    name: 'Caledonia',
    postalCode: '53108',
  },
  'calera-al': {
    name: 'Calera',
    postalCode: '35040',
  },
  'calera-ok': {
    name: 'Calera',
    postalCode: '74730',
  },
  'calexico-ca': {
    name: 'Calexico',
    postalCode: '92231',
  },
  'calhan-co': {
    name: 'Calhan',
    postalCode: '80808',
  },
  'calhoun-ga': {
    name: 'Calhoun',
    postalCode: '30701',
  },
  'calhoun-il': {
    name: 'Calhoun',
    postalCode: '62419',
  },
  'calhoun-ky': {
    name: 'Calhoun',
    postalCode: '42327',
  },
  'calhoun-la': {
    name: 'Calhoun',
    postalCode: '71225',
  },
  'calhoun-mo': {
    name: 'Calhoun',
    postalCode: '65323',
  },
  'calhoun-tn': {
    name: 'Calhoun',
    postalCode: '37309',
  },
  'calhoun-city-ms': {
    name: 'Calhoun City',
    postalCode: '38916',
  },
  'calhoun-falls-sc': {
    name: 'Calhoun Falls',
    postalCode: '29628',
  },
  'calico-rock-ar': {
    name: 'Calico Rock',
    postalCode: '72519',
  },
  'caliente-ca': {
    name: 'Caliente',
    postalCode: '93518',
  },
  'caliente-nv': {
    name: 'Caliente',
    postalCode: '89008',
  },
  'califon-nj': {
    name: 'Califon',
    postalCode: '07830',
  },
  'california-ky': {
    name: 'California',
    postalCode: '41007',
  },
  'california-md': {
    name: 'California',
    postalCode: '20619',
  },
  'california-mo': {
    name: 'California',
    postalCode: '65018',
  },
  'california-pa': {
    name: 'California',
    postalCode: '15419',
  },
  'california-city-ca': {
    name: 'California City',
    postalCode: '93505',
  },
  'california-hot-springs-ca': {
    name: 'California Hot Springs',
    postalCode: '93207',
  },
  'calimesa-ca': {
    name: 'Calimesa',
    postalCode: '92320',
  },
  'calipatria-ca': {
    name: 'Calipatria',
    postalCode: '92233',
  },
  'calistoga-ca': {
    name: 'Calistoga',
    postalCode: '94515',
  },
  'call-tx': {
    name: 'Call',
    postalCode: '75933',
  },
  'callahan-ca': {
    name: 'Callahan',
    postalCode: '96014',
  },
  'callahan-fl': {
    name: 'Callahan',
    postalCode: '32011',
  },
  'callands-va': {
    name: 'Callands',
    postalCode: '24530',
  },
  'callao-mo': {
    name: 'Callao',
    postalCode: '63534',
  },
  'callao-va': {
    name: 'Callao',
    postalCode: '22435',
  },
  'callaway-md': {
    name: 'Callaway',
    postalCode: '20620',
  },
  'callaway-mn': {
    name: 'Callaway',
    postalCode: '56521',
  },
  'callaway-ne': {
    name: 'Callaway',
    postalCode: '68825',
  },
  'callaway-va': {
    name: 'Callaway',
    postalCode: '24067',
  },
  'callender-ia': {
    name: 'Callender',
    postalCode: '50523',
  },
  'callicoon-ny': {
    name: 'Callicoon',
    postalCode: '12723',
  },
  'calliham-tx': {
    name: 'Calliham',
    postalCode: '78007',
  },
  'calmar-ia': {
    name: 'Calmar',
    postalCode: '52132',
  },
  'calpine-ca': {
    name: 'Calpine',
    postalCode: '96124',
  },
  'calumet-mi': {
    name: 'Calumet',
    postalCode: '49913',
  },
  'calumet-ok': {
    name: 'Calumet',
    postalCode: '73014',
  },
  'calumet-city-il': {
    name: 'Calumet City',
    postalCode: '60409',
  },
  'calvert-tx': {
    name: 'Calvert',
    postalCode: '77837',
  },
  'calvert-city-ky': {
    name: 'Calvert City',
    postalCode: '42029',
  },
  'calverton-ny': {
    name: 'Calverton',
    postalCode: '11933',
  },
  'calvin-ky': {
    name: 'Calvin',
    postalCode: '40813',
  },
  'calvin-nd': {
    name: 'Calvin',
    postalCode: '58323',
  },
  'calvin-ok': {
    name: 'Calvin',
    postalCode: '74531',
  },
  'calvin-pa': {
    name: 'Calvin',
    postalCode: '16622',
  },
  'calvin-wv': {
    name: 'Calvin',
    postalCode: '26660',
  },
  'camanche-ia': {
    name: 'Camanche',
    postalCode: '52730',
  },
  'camano-island-wa': {
    name: 'Camano Island',
    postalCode: '98282',
  },
  'camargo-il': {
    name: 'Camargo',
    postalCode: '61919',
  },
  'camargo-ok': {
    name: 'Camargo',
    postalCode: '73835',
  },
  'camarillo-ca': {
    name: 'Camarillo',
    postalCode: '93010',
  },
  'camas-wa': {
    name: 'Camas',
    postalCode: '98607',
  },
  'camas-valley-or': {
    name: 'Camas Valley',
    postalCode: '97416',
  },
  'cambria-ca': {
    name: 'Cambria',
    postalCode: '93428',
  },
  'cambria-wi': {
    name: 'Cambria',
    postalCode: '53923',
  },
  'cambria-heights-ny': {
    name: 'Cambria Heights',
    postalCode: '11411',
  },
  'cambridge-ia': {
    name: 'Cambridge',
    postalCode: '50046',
  },
  'cambridge-id': {
    name: 'Cambridge',
    postalCode: '83610',
  },
  'cambridge-il': {
    name: 'Cambridge',
    postalCode: '61238',
  },
  'cambridge-ks': {
    name: 'Cambridge',
    postalCode: '67023',
  },
  'cambridge-ma': {
    name: 'Cambridge',
    postalCode: '02138',
  },
  'cambridge-md': {
    name: 'Cambridge',
    postalCode: '21613',
  },
  'cambridge-me': {
    name: 'Cambridge',
    postalCode: '04923',
  },
  'cambridge-mn': {
    name: 'Cambridge',
    postalCode: '55008',
  },
  'cambridge-ne': {
    name: 'Cambridge',
    postalCode: '69022',
  },
  'cambridge-ny': {
    name: 'Cambridge',
    postalCode: '12816',
  },
  'cambridge-oh': {
    name: 'Cambridge',
    postalCode: '43725',
  },
  'cambridge-vt': {
    name: 'Cambridge',
    postalCode: '05444',
  },
  'cambridge-wi': {
    name: 'Cambridge',
    postalCode: '53523',
  },
  'cambridge-city-in': {
    name: 'Cambridge City',
    postalCode: '47327',
  },
  'cambridge-springs-pa': {
    name: 'Cambridge Springs',
    postalCode: '16403',
  },
  'cambridgeport-vt': {
    name: 'Cambridgeport',
    postalCode: '05141',
  },
  'camby-in': {
    name: 'Camby',
    postalCode: '46113',
  },
  'camden-al': {
    name: 'Camden',
    postalCode: '36726',
  },
  'camden-ar': {
    name: 'Camden',
    postalCode: '71701',
  },
  'camden-il': {
    name: 'Camden',
    postalCode: '62319',
  },
  'camden-in': {
    name: 'Camden',
    postalCode: '46917',
  },
  'camden-me': {
    name: 'Camden',
    postalCode: '04843',
  },
  'camden-mi': {
    name: 'Camden',
    postalCode: '49232',
  },
  'camden-mo': {
    name: 'Camden',
    postalCode: '64017',
  },
  'camden-ms': {
    name: 'Camden',
    postalCode: '39045',
  },
  'camden-nc': {
    name: 'Camden',
    postalCode: '27921',
  },
  'camden-nj': {
    name: 'Camden',
    postalCode: '08102',
  },
  'camden-ny': {
    name: 'Camden',
    postalCode: '13316',
  },
  'camden-oh': {
    name: 'Camden',
    postalCode: '45311',
  },
  'camden-sc': {
    name: 'Camden',
    postalCode: '29020',
  },
  'camden-tn': {
    name: 'Camden',
    postalCode: '38320',
  },
  'camden-wv': {
    name: 'Camden',
    postalCode: '26338',
  },
  'camden-on-gauley-wv': {
    name: 'Camden On Gauley',
    postalCode: '26208',
  },
  'camden-point-mo': {
    name: 'Camden Point',
    postalCode: '64018',
  },
  'camden-wyoming-de': {
    name: 'Camden Wyoming',
    postalCode: '19934',
  },
  'camdenton-mo': {
    name: 'Camdenton',
    postalCode: '65020',
  },
  'cameron-il': {
    name: 'Cameron',
    postalCode: '61423',
  },
  'cameron-la': {
    name: 'Cameron',
    postalCode: '70631',
  },
  'cameron-mo': {
    name: 'Cameron',
    postalCode: '64429',
  },
  'cameron-mt': {
    name: 'Cameron',
    postalCode: '59720',
  },
  'cameron-nc': {
    name: 'Cameron',
    postalCode: '28326',
  },
  'cameron-ny': {
    name: 'Cameron',
    postalCode: '14819',
  },
  'cameron-ok': {
    name: 'Cameron',
    postalCode: '74932',
  },
  'cameron-sc': {
    name: 'Cameron',
    postalCode: '29030',
  },
  'cameron-tx': {
    name: 'Cameron',
    postalCode: '76520',
  },
  'cameron-wi': {
    name: 'Cameron',
    postalCode: '54822',
  },
  'cameron-wv': {
    name: 'Cameron',
    postalCode: '26033',
  },
  'cameron-mills-ny': {
    name: 'Cameron Mills',
    postalCode: '14820',
  },
  'camilla-ga': {
    name: 'Camilla',
    postalCode: '31730',
  },
  'camillus-ny': {
    name: 'Camillus',
    postalCode: '13031',
  },
  'camino-ca': {
    name: 'Camino',
    postalCode: '95709',
  },
  'cammal-pa': {
    name: 'Cammal',
    postalCode: '17723',
  },
  'camp-ar': {
    name: 'Camp',
    postalCode: '72520',
  },
  'camp-creek-wv': {
    name: 'Camp Creek',
    postalCode: '25820',
  },
  'camp-crook-sd': {
    name: 'Camp Crook',
    postalCode: '57724',
  },
  'camp-dennison-oh': {
    name: 'Camp Dennison',
    postalCode: '45111',
  },
  'camp-douglas-wi': {
    name: 'Camp Douglas',
    postalCode: '54618',
  },
  'camp-h-m-smith-hi': {
    name: 'Camp H M Smith',
    postalCode: '96861',
  },
  'camp-hill-al': {
    name: 'Camp Hill',
    postalCode: '36850',
  },
  'camp-hill-pa': {
    name: 'Camp Hill',
    postalCode: '17011',
  },
  'camp-lejeune-nc': {
    name: 'Camp Lejeune',
    postalCode: '28547',
  },
  'camp-point-il': {
    name: 'Camp Point',
    postalCode: '62320',
  },
  'camp-sherman-or': {
    name: 'Camp Sherman',
    postalCode: '97730',
  },
  'camp-verde-az': {
    name: 'Camp Verde',
    postalCode: '86322',
  },
  'camp-wood-tx': {
    name: 'Camp Wood',
    postalCode: '78833',
  },
  'campbell-al': {
    name: 'Campbell',
    postalCode: '36727',
  },
  'campbell-ca': {
    name: 'Campbell',
    postalCode: '95008',
  },
  'campbell-mn': {
    name: 'Campbell',
    postalCode: '56522',
  },
  'campbell-mo': {
    name: 'Campbell',
    postalCode: '63933',
  },
  'campbell-ne': {
    name: 'Campbell',
    postalCode: '68932',
  },
  'campbell-ny': {
    name: 'Campbell',
    postalCode: '14821',
  },
  'campbell-oh': {
    name: 'Campbell',
    postalCode: '44405',
  },
  'campbell-tx': {
    name: 'Campbell',
    postalCode: '75422',
  },
  'campbell-hall-ny': {
    name: 'Campbell Hall',
    postalCode: '10916',
  },
  'campbell-hill-il': {
    name: 'Campbell Hill',
    postalCode: '62916',
  },
  'campbellsburg-in': {
    name: 'Campbellsburg',
    postalCode: '47108',
  },
  'campbellsburg-ky': {
    name: 'Campbellsburg',
    postalCode: '40011',
  },
  'campbellsport-wi': {
    name: 'Campbellsport',
    postalCode: '53010',
  },
  'campbellsville-ky': {
    name: 'Campbellsville',
    postalCode: '42718',
  },
  'campbellton-fl': {
    name: 'Campbellton',
    postalCode: '32426',
  },
  'campbellton-tx': {
    name: 'Campbellton',
    postalCode: '78008',
  },
  'campo-ca': {
    name: 'Campo',
    postalCode: '91906',
  },
  'campo-co': {
    name: 'Campo',
    postalCode: '81029',
  },
  'campobello-sc': {
    name: 'Campobello',
    postalCode: '29322',
  },
  'campti-la': {
    name: 'Campti',
    postalCode: '71411',
  },
  'campton-ky': {
    name: 'Campton',
    postalCode: '41301',
  },
  'campton-nh': {
    name: 'Campton',
    postalCode: '03223',
  },
  'camptonville-ca': {
    name: 'Camptonville',
    postalCode: '95922',
  },
  'camuy-pr': {
    name: 'Camuy',
    postalCode: '00627',
  },
  'cana-va': {
    name: 'Cana',
    postalCode: '24317',
  },
  'canaan-ct': {
    name: 'Canaan',
    postalCode: '06018',
  },
  'canaan-in': {
    name: 'Canaan',
    postalCode: '47224',
  },
  'canaan-me': {
    name: 'Canaan',
    postalCode: '04924',
  },
  'canaan-nh': {
    name: 'Canaan',
    postalCode: '03741',
  },
  'canaan-ny': {
    name: 'Canaan',
    postalCode: '12029',
  },
  'canaan-vt': {
    name: 'Canaan',
    postalCode: '05903',
  },
  'canada-ky': {
    name: 'Canada',
    postalCode: '41519',
  },
  'canadensis-pa': {
    name: 'Canadensis',
    postalCode: '18325',
  },
  'canadian-ok': {
    name: 'Canadian',
    postalCode: '74425',
  },
  'canadian-tx': {
    name: 'Canadian',
    postalCode: '79014',
  },
  'canajoharie-ny': {
    name: 'Canajoharie',
    postalCode: '13317',
  },
  'canal-fulton-oh': {
    name: 'Canal Fulton',
    postalCode: '44614',
  },
  'canal-point-fl': {
    name: 'Canal Point',
    postalCode: '33438',
  },
  'canal-winchester-oh': {
    name: 'Canal Winchester',
    postalCode: '43110',
  },
  'canandaigua-ny': {
    name: 'Canandaigua',
    postalCode: '14424',
  },
  'canaseraga-ny': {
    name: 'Canaseraga',
    postalCode: '14822',
  },
  'canastota-ny': {
    name: 'Canastota',
    postalCode: '13032',
  },
  'canby-ca': {
    name: 'Canby',
    postalCode: '96015',
  },
  'canby-mn': {
    name: 'Canby',
    postalCode: '56220',
  },
  'canby-or': {
    name: 'Canby',
    postalCode: '97013',
  },
  'candia-nh': {
    name: 'Candia',
    postalCode: '03034',
  },
  'candler-nc': {
    name: 'Candler',
    postalCode: '28715',
  },
  'cando-nd': {
    name: 'Cando',
    postalCode: '58324',
  },
  'candor-nc': {
    name: 'Candor',
    postalCode: '27229',
  },
  'candor-ny': {
    name: 'Candor',
    postalCode: '13743',
  },
  'caneadea-ny': {
    name: 'Caneadea',
    postalCode: '14717',
  },
  'canehill-ar': {
    name: 'Canehill',
    postalCode: '72717',
  },
  'caney-ks': {
    name: 'Caney',
    postalCode: '67333',
  },
  'caney-ok': {
    name: 'Caney',
    postalCode: '74533',
  },
  'caneyville-ky': {
    name: 'Caneyville',
    postalCode: '42721',
  },
  'canfield-oh': {
    name: 'Canfield',
    postalCode: '44406',
  },
  'canisteo-ny': {
    name: 'Canisteo',
    postalCode: '14823',
  },
  'canistota-sd': {
    name: 'Canistota',
    postalCode: '57012',
  },
  'canmer-ky': {
    name: 'Canmer',
    postalCode: '42722',
  },
  'cannel-city-ky': {
    name: 'Cannel City',
    postalCode: '41408',
  },
  'cannelburg-in': {
    name: 'Cannelburg',
    postalCode: '47519',
  },
  'cannelton-in': {
    name: 'Cannelton',
    postalCode: '47520',
  },
  'cannon-ky': {
    name: 'Cannon',
    postalCode: '40923',
  },
  'cannon-ball-nd': {
    name: 'Cannon Ball',
    postalCode: '58528',
  },
  'cannon-falls-mn': {
    name: 'Cannon Falls',
    postalCode: '55009',
  },
  'canoga-park-ca': {
    name: 'Canoga Park',
    postalCode: '91303',
  },
  'canon-ga': {
    name: 'Canon',
    postalCode: '30520',
  },
  'canon-city-co': {
    name: 'Canon City',
    postalCode: '81212',
  },
  'canones-nm': {
    name: 'Canones',
    postalCode: '87516',
  },
  'canonsburg-pa': {
    name: 'Canonsburg',
    postalCode: '15317',
  },
  'canova-sd': {
    name: 'Canova',
    postalCode: '57321',
  },
  'canovanas-pr': {
    name: 'Canovanas',
    postalCode: '00729',
  },
  'canterbury-ct': {
    name: 'Canterbury',
    postalCode: '06331',
  },
  'canterbury-nh': {
    name: 'Canterbury',
    postalCode: '03224',
  },
  'canton-ct': {
    name: 'Canton',
    postalCode: '06019',
  },
  'canton-ga': {
    name: 'Canton',
    postalCode: '30114',
  },
  'canton-il': {
    name: 'Canton',
    postalCode: '61520',
  },
  'canton-ks': {
    name: 'Canton',
    postalCode: '67428',
  },
  'canton-ma': {
    name: 'Canton',
    postalCode: '02021',
  },
  'canton-me': {
    name: 'Canton',
    postalCode: '04221',
  },
  'canton-mi': {
    name: 'Canton',
    postalCode: '48187',
  },
  'canton-mn': {
    name: 'Canton',
    postalCode: '55922',
  },
  'canton-mo': {
    name: 'Canton',
    postalCode: '63435',
  },
  'canton-ms': {
    name: 'Canton',
    postalCode: '39046',
  },
  'canton-nc': {
    name: 'Canton',
    postalCode: '28716',
  },
  'canton-ny': {
    name: 'Canton',
    postalCode: '13617',
  },
  'canton-oh': {
    name: 'Canton',
    postalCode: '44702',
  },
  'canton-ok': {
    name: 'Canton',
    postalCode: '73724',
  },
  'canton-pa': {
    name: 'Canton',
    postalCode: '17724',
  },
  'canton-sd': {
    name: 'Canton',
    postalCode: '57013',
  },
  'canton-tx': {
    name: 'Canton',
    postalCode: '75103',
  },
  'cantonment-fl': {
    name: 'Cantonment',
    postalCode: '32533',
  },
  'cantrall-il': {
    name: 'Cantrall',
    postalCode: '62625',
  },
  'cantril-ia': {
    name: 'Cantril',
    postalCode: '52542',
  },
  'cantua-creek-ca': {
    name: 'Cantua Creek',
    postalCode: '93608',
  },
  'canute-ok': {
    name: 'Canute',
    postalCode: '73626',
  },
  'canutillo-tx': {
    name: 'Canutillo',
    postalCode: '79835',
  },
  'canvas-wv': {
    name: 'Canvas',
    postalCode: '26662',
  },
  'canyon-mn': {
    name: 'Canyon',
    postalCode: '55717',
  },
  'canyon-tx': {
    name: 'Canyon',
    postalCode: '79015',
  },
  'canyon-city-or': {
    name: 'Canyon City',
    postalCode: '97820',
  },
  'canyon-country-ca': {
    name: 'Canyon Country',
    postalCode: '91351',
  },
  'canyon-creek-mt': {
    name: 'Canyon Creek',
    postalCode: '59633',
  },
  'canyon-dam-ca': {
    name: 'Canyon Dam',
    postalCode: '95923',
  },
  'canyon-lake-tx': {
    name: 'Canyon Lake',
    postalCode: '78133',
  },
  'canyonville-or': {
    name: 'Canyonville',
    postalCode: '97417',
  },
  'capac-mi': {
    name: 'Capac',
    postalCode: '48014',
  },
  'capay-ca': {
    name: 'Capay',
    postalCode: '95607',
  },
  'cape-canaveral-fl': {
    name: 'Cape Canaveral',
    postalCode: '32920',
  },
  'cape-charles-va': {
    name: 'Cape Charles',
    postalCode: '23310',
  },
  'cape-coral-fl': {
    name: 'Cape Coral',
    postalCode: '33904',
  },
  'cape-elizabeth-me': {
    name: 'Cape Elizabeth',
    postalCode: '04107',
  },
  'cape-fair-mo': {
    name: 'Cape Fair',
    postalCode: '65624',
  },
  'cape-girardeau-mo': {
    name: 'Cape Girardeau',
    postalCode: '63701',
  },
  'cape-may-nj': {
    name: 'Cape May',
    postalCode: '08204',
  },
  'cape-may-court-house-nj': {
    name: 'Cape May Court House',
    postalCode: '08210',
  },
  'cape-neddick-me': {
    name: 'Cape Neddick',
    postalCode: '03902',
  },
  'cape-vincent-ny': {
    name: 'Cape Vincent',
    postalCode: '13618',
  },
  'capistrano-beach-ca': {
    name: 'Capistrano Beach',
    postalCode: '92624',
  },
  'capitan-nm': {
    name: 'Capitan',
    postalCode: '88316',
  },
  'capitol-mt': {
    name: 'Capitol',
    postalCode: '59319',
  },
  'capitol-heights-md': {
    name: 'Capitol Heights',
    postalCode: '20743',
  },
  'capitola-ca': {
    name: 'Capitola',
    postalCode: '95010',
  },
  'capon-bridge-wv': {
    name: 'Capon Bridge',
    postalCode: '26711',
  },
  'caprock-nm': {
    name: 'Caprock',
    postalCode: '88213',
  },
  'capron-il': {
    name: 'Capron',
    postalCode: '61012',
  },
  'capron-va': {
    name: 'Capron',
    postalCode: '23829',
  },
  'captain-cook-hi': {
    name: 'Captain Cook',
    postalCode: '96704',
  },
  'capulin-nm': {
    name: 'Capulin',
    postalCode: '88414',
  },
  'caputa-sd': {
    name: 'Caputa',
    postalCode: '57725',
  },
  'caratunk-me': {
    name: 'Caratunk',
    postalCode: '04925',
  },
  'caraway-ar': {
    name: 'Caraway',
    postalCode: '72419',
  },
  'carbon-ia': {
    name: 'Carbon',
    postalCode: '50839',
  },
  'carbon-in': {
    name: 'Carbon',
    postalCode: '47837',
  },
  'carbon-tx': {
    name: 'Carbon',
    postalCode: '76435',
  },
  'carbon-cliff-il': {
    name: 'Carbon Cliff',
    postalCode: '61239',
  },
  'carbon-hill-al': {
    name: 'Carbon Hill',
    postalCode: '35549',
  },
  'carbonado-wa': {
    name: 'Carbonado',
    postalCode: '98323',
  },
  'carbondale-co': {
    name: 'Carbondale',
    postalCode: '81623',
  },
  'carbondale-il': {
    name: 'Carbondale',
    postalCode: '62901',
  },
  'carbondale-ks': {
    name: 'Carbondale',
    postalCode: '66414',
  },
  'carbondale-pa': {
    name: 'Carbondale',
    postalCode: '18407',
  },
  'cardiff-by-the-sea-ca': {
    name: 'Cardiff By The Sea',
    postalCode: '92007',
  },
  'cardinal-va': {
    name: 'Cardinal',
    postalCode: '23025',
  },
  'cardington-oh': {
    name: 'Cardington',
    postalCode: '43315',
  },
  'cardwell-mo': {
    name: 'Cardwell',
    postalCode: '63829',
  },
  'cardwell-mt': {
    name: 'Cardwell',
    postalCode: '59721',
  },
  'carencro-la': {
    name: 'Carencro',
    postalCode: '70520',
  },
  'caret-va': {
    name: 'Caret',
    postalCode: '22436',
  },
  'carey-id': {
    name: 'Carey',
    postalCode: '83320',
  },
  'carey-oh': {
    name: 'Carey',
    postalCode: '43316',
  },
  'careywood-id': {
    name: 'Careywood',
    postalCode: '83809',
  },
  'caribou-me': {
    name: 'Caribou',
    postalCode: '04736',
  },
  'carl-junction-mo': {
    name: 'Carl Junction',
    postalCode: '64834',
  },
  'carle-place-ny': {
    name: 'Carle Place',
    postalCode: '11514',
  },
  'carleton-mi': {
    name: 'Carleton',
    postalCode: '48117',
  },
  'carleton-ne': {
    name: 'Carleton',
    postalCode: '68326',
  },
  'carlin-nv': {
    name: 'Carlin',
    postalCode: '89822',
  },
  'carlinville-il': {
    name: 'Carlinville',
    postalCode: '62626',
  },
  'carlisle-ar': {
    name: 'Carlisle',
    postalCode: '72024',
  },
  'carlisle-ia': {
    name: 'Carlisle',
    postalCode: '50047',
  },
  'carlisle-in': {
    name: 'Carlisle',
    postalCode: '47838',
  },
  'carlisle-ky': {
    name: 'Carlisle',
    postalCode: '40311',
  },
  'carlisle-ma': {
    name: 'Carlisle',
    postalCode: '01741',
  },
  'carlisle-ny': {
    name: 'Carlisle',
    postalCode: '12031',
  },
  'carlisle-pa': {
    name: 'Carlisle',
    postalCode: '17013',
  },
  'carlisle-sc': {
    name: 'Carlisle',
    postalCode: '29031',
  },
  'carlock-il': {
    name: 'Carlock',
    postalCode: '61725',
  },
  'carlos-mn': {
    name: 'Carlos',
    postalCode: '56319',
  },
  'carlotta-ca': {
    name: 'Carlotta',
    postalCode: '95528',
  },
  'carlsbad-ca': {
    name: 'Carlsbad',
    postalCode: '92008',
  },
  'carlsbad-nm': {
    name: 'Carlsbad',
    postalCode: '88220',
  },
  'carlsbad-tx': {
    name: 'Carlsbad',
    postalCode: '76934',
  },
  'carlstadt-nj': {
    name: 'Carlstadt',
    postalCode: '07072',
  },
  'carlton-al': {
    name: 'Carlton',
    postalCode: '36515',
  },
  'carlton-ga': {
    name: 'Carlton',
    postalCode: '30627',
  },
  'carlton-mn': {
    name: 'Carlton',
    postalCode: '55718',
  },
  'carlton-or': {
    name: 'Carlton',
    postalCode: '97111',
  },
  'carlton-pa': {
    name: 'Carlton',
    postalCode: '16311',
  },
  'carlton-tx': {
    name: 'Carlton',
    postalCode: '76436',
  },
  'carlton-wa': {
    name: 'Carlton',
    postalCode: '98814',
  },
  'carlyle-il': {
    name: 'Carlyle',
    postalCode: '62231',
  },
  'carman-il': {
    name: 'Carman',
    postalCode: '61425',
  },
  'carmel-ca': {
    name: 'Carmel',
    postalCode: '93923',
  },
  'carmel-in': {
    name: 'Carmel',
    postalCode: '46032',
  },
  'carmel-me': {
    name: 'Carmel',
    postalCode: '04419',
  },
  'carmel-ny': {
    name: 'Carmel',
    postalCode: '10512',
  },
  'carmel-valley-ca': {
    name: 'Carmel Valley',
    postalCode: '93924',
  },
  'carmen-id': {
    name: 'Carmen',
    postalCode: '83462',
  },
  'carmen-ok': {
    name: 'Carmen',
    postalCode: '73726',
  },
  'carmi-il': {
    name: 'Carmi',
    postalCode: '62821',
  },
  'carmichael-ca': {
    name: 'Carmichael',
    postalCode: '95608',
  },
  'carmichaels-pa': {
    name: 'Carmichaels',
    postalCode: '15320',
  },
  'carmine-tx': {
    name: 'Carmine',
    postalCode: '78932',
  },
  'carnation-wa': {
    name: 'Carnation',
    postalCode: '98014',
  },
  'carnegie-ok': {
    name: 'Carnegie',
    postalCode: '73015',
  },
  'carnegie-pa': {
    name: 'Carnegie',
    postalCode: '15106',
  },
  'carnesville-ga': {
    name: 'Carnesville',
    postalCode: '30521',
  },
  'carney-mi': {
    name: 'Carney',
    postalCode: '49812',
  },
  'carney-ok': {
    name: 'Carney',
    postalCode: '74832',
  },
  'caro-mi': {
    name: 'Caro',
    postalCode: '48723',
  },
  'caroga-lake-ny': {
    name: 'Caroga Lake',
    postalCode: '12032',
  },
  'carol-stream-il': {
    name: 'Carol Stream',
    postalCode: '60188',
  },
  'carolina-pr': {
    name: 'Carolina',
    postalCode: '00979',
  },
  'carolina-ri': {
    name: 'Carolina',
    postalCode: '02812',
  },
  'carolina-beach-nc': {
    name: 'Carolina Beach',
    postalCode: '28428',
  },
  'caroline-wi': {
    name: 'Caroline',
    postalCode: '54928',
  },
  'carp-lake-mi': {
    name: 'Carp Lake',
    postalCode: '49718',
  },
  'carpenter-ia': {
    name: 'Carpenter',
    postalCode: '50426',
  },
  'carpenter-sd': {
    name: 'Carpenter',
    postalCode: '57322',
  },
  'carpenter-wy': {
    name: 'Carpenter',
    postalCode: '82054',
  },
  'carpentersville-il': {
    name: 'Carpentersville',
    postalCode: '60110',
  },
  'carpinteria-ca': {
    name: 'Carpinteria',
    postalCode: '93013',
  },
  'carpio-nd': {
    name: 'Carpio',
    postalCode: '58725',
  },
  'carr-co': {
    name: 'Carr',
    postalCode: '80612',
  },
  'carrabelle-fl': {
    name: 'Carrabelle',
    postalCode: '32322',
  },
  'carrboro-nc': {
    name: 'Carrboro',
    postalCode: '27510',
  },
  'carrier-ok': {
    name: 'Carrier',
    postalCode: '73727',
  },
  'carrier-mills-il': {
    name: 'Carrier Mills',
    postalCode: '62917',
  },
  'carriere-ms': {
    name: 'Carriere',
    postalCode: '39426',
  },
  'carrington-nd': {
    name: 'Carrington',
    postalCode: '58421',
  },
  'carrizo-springs-tx': {
    name: 'Carrizo Springs',
    postalCode: '78834',
  },
  'carrizozo-nm': {
    name: 'Carrizozo',
    postalCode: '88301',
  },
  'carroll-ia': {
    name: 'Carroll',
    postalCode: '51401',
  },
  'carroll-ne': {
    name: 'Carroll',
    postalCode: '68723',
  },
  'carroll-oh': {
    name: 'Carroll',
    postalCode: '43112',
  },
  'carrolls-wa': {
    name: 'Carrolls',
    postalCode: '98609',
  },
  'carrollton-al': {
    name: 'Carrollton',
    postalCode: '35447',
  },
  'carrollton-ga': {
    name: 'Carrollton',
    postalCode: '30116',
  },
  'carrollton-il': {
    name: 'Carrollton',
    postalCode: '62016',
  },
  'carrollton-ky': {
    name: 'Carrollton',
    postalCode: '41008',
  },
  'carrollton-mo': {
    name: 'Carrollton',
    postalCode: '64633',
  },
  'carrollton-ms': {
    name: 'Carrollton',
    postalCode: '38917',
  },
  'carrollton-oh': {
    name: 'Carrollton',
    postalCode: '44615',
  },
  'carrollton-tx': {
    name: 'Carrollton',
    postalCode: '75006',
  },
  'carrollton-va': {
    name: 'Carrollton',
    postalCode: '23314',
  },
  'carrolltown-pa': {
    name: 'Carrolltown',
    postalCode: '15722',
  },
  'carrsville-va': {
    name: 'Carrsville',
    postalCode: '23315',
  },
  'carson-ca': {
    name: 'Carson',
    postalCode: '90745',
  },
  'carson-ia': {
    name: 'Carson',
    postalCode: '51525',
  },
  'carson-ms': {
    name: 'Carson',
    postalCode: '39427',
  },
  'carson-nd': {
    name: 'Carson',
    postalCode: '58529',
  },
  'carson-va': {
    name: 'Carson',
    postalCode: '23830',
  },
  'carson-wa': {
    name: 'Carson',
    postalCode: '98610',
  },
  'carson-city-mi': {
    name: 'Carson City',
    postalCode: '48811',
  },
  'carson-city-nv': {
    name: 'Carson City',
    postalCode: '89701',
  },
  'carsonville-mi': {
    name: 'Carsonville',
    postalCode: '48419',
  },
  'carter-mt': {
    name: 'Carter',
    postalCode: '59420',
  },
  'carter-ok': {
    name: 'Carter',
    postalCode: '73627',
  },
  'carter-lake-ia': {
    name: 'Carter Lake',
    postalCode: '51510',
  },
  'carteret-nj': {
    name: 'Carteret',
    postalCode: '07008',
  },
  'cartersville-ga': {
    name: 'Cartersville',
    postalCode: '30120',
  },
  'cartersville-va': {
    name: 'Cartersville',
    postalCode: '23027',
  },
  'carterville-il': {
    name: 'Carterville',
    postalCode: '62918',
  },
  'carterville-mo': {
    name: 'Carterville',
    postalCode: '64835',
  },
  'carthage-ar': {
    name: 'Carthage',
    postalCode: '71725',
  },
  'carthage-il': {
    name: 'Carthage',
    postalCode: '62321',
  },
  'carthage-in': {
    name: 'Carthage',
    postalCode: '46115',
  },
  'carthage-mo': {
    name: 'Carthage',
    postalCode: '64836',
  },
  'carthage-ms': {
    name: 'Carthage',
    postalCode: '39051',
  },
  'carthage-nc': {
    name: 'Carthage',
    postalCode: '28327',
  },
  'carthage-ny': {
    name: 'Carthage',
    postalCode: '13619',
  },
  'carthage-sd': {
    name: 'Carthage',
    postalCode: '57323',
  },
  'carthage-tn': {
    name: 'Carthage',
    postalCode: '37030',
  },
  'carthage-tx': {
    name: 'Carthage',
    postalCode: '75633',
  },
  'cartwright-nd': {
    name: 'Cartwright',
    postalCode: '58838',
  },
  'cartwright-ok': {
    name: 'Cartwright',
    postalCode: '74731',
  },
  'caruthers-ca': {
    name: 'Caruthers',
    postalCode: '93609',
  },
  'caruthersville-mo': {
    name: 'Caruthersville',
    postalCode: '63830',
  },
  'carver-ma': {
    name: 'Carver',
    postalCode: '02330',
  },
  'carver-mn': {
    name: 'Carver',
    postalCode: '55315',
  },
  'carversville-pa': {
    name: 'Carversville',
    postalCode: '18913',
  },
  'carville-la': {
    name: 'Carville',
    postalCode: '70721',
  },
  'cary-il': {
    name: 'Cary',
    postalCode: '60013',
  },
  'cary-ms': {
    name: 'Cary',
    postalCode: '39054',
  },
  'cary-nc': {
    name: 'Cary',
    postalCode: '27511',
  },
  'caryville-fl': {
    name: 'Caryville',
    postalCode: '32427',
  },
  'caryville-tn': {
    name: 'Caryville',
    postalCode: '37714',
  },
  'casa-ar': {
    name: 'Casa',
    postalCode: '72025',
  },
  'casa-grande-az': {
    name: 'Casa Grande',
    postalCode: '85122',
  },
  'casar-nc': {
    name: 'Casar',
    postalCode: '28020',
  },
  'cascade-co': {
    name: 'Cascade',
    postalCode: '80809',
  },
  'cascade-ia': {
    name: 'Cascade',
    postalCode: '52033',
  },
  'cascade-id': {
    name: 'Cascade',
    postalCode: '83611',
  },
  'cascade-md': {
    name: 'Cascade',
    postalCode: '21719',
  },
  'cascade-mt': {
    name: 'Cascade',
    postalCode: '59421',
  },
  'cascade-va': {
    name: 'Cascade',
    postalCode: '24069',
  },
  'cascade-wi': {
    name: 'Cascade',
    postalCode: '53011',
  },
  'cascade-locks-or': {
    name: 'Cascade Locks',
    postalCode: '97014',
  },
  'cascadia-or': {
    name: 'Cascadia',
    postalCode: '97329',
  },
  'cascilla-ms': {
    name: 'Cascilla',
    postalCode: '38920',
  },
  'casco-me': {
    name: 'Casco',
    postalCode: '04015',
  },
  'casco-mi': {
    name: 'Casco',
    postalCode: '48064',
  },
  'casco-wi': {
    name: 'Casco',
    postalCode: '54205',
  },
  'caseville-mi': {
    name: 'Caseville',
    postalCode: '48725',
  },
  'casey-ia': {
    name: 'Casey',
    postalCode: '50048',
  },
  'casey-il': {
    name: 'Casey',
    postalCode: '62420',
  },
  'caseyville-il': {
    name: 'Caseyville',
    postalCode: '62232',
  },
  'cash-ar': {
    name: 'Cash',
    postalCode: '72421',
  },
  'cashiers-nc': {
    name: 'Cashiers',
    postalCode: '28717',
  },
  'cashion-ok': {
    name: 'Cashion',
    postalCode: '73016',
  },
  'cashmere-wa': {
    name: 'Cashmere',
    postalCode: '98815',
  },
  'cashton-wi': {
    name: 'Cashton',
    postalCode: '54619',
  },
  'casnovia-mi': {
    name: 'Casnovia',
    postalCode: '49318',
  },
  'caspar-ca': {
    name: 'Caspar',
    postalCode: '95420',
  },
  'casper-wy': {
    name: 'Casper',
    postalCode: '82601',
  },
  'cass-wv': {
    name: 'Cass',
    postalCode: '24927',
  },
  'cass-city-mi': {
    name: 'Cass City',
    postalCode: '48726',
  },
  'cass-lake-mn': {
    name: 'Cass Lake',
    postalCode: '56633',
  },
  'cassadaga-ny': {
    name: 'Cassadaga',
    postalCode: '14718',
  },
  'cassatt-sc': {
    name: 'Cassatt',
    postalCode: '29032',
  },
  'casscoe-ar': {
    name: 'Casscoe',
    postalCode: '72026',
  },
  'cassel-ca': {
    name: 'Cassel',
    postalCode: '96016',
  },
  'casselberry-fl': {
    name: 'Casselberry',
    postalCode: '32707',
  },
  'casselton-nd': {
    name: 'Casselton',
    postalCode: '58012',
  },
  'cassoday-ks': {
    name: 'Cassoday',
    postalCode: '66842',
  },
  'cassopolis-mi': {
    name: 'Cassopolis',
    postalCode: '49031',
  },
  'casstown-oh': {
    name: 'Casstown',
    postalCode: '45312',
  },
  'cassville-mo': {
    name: 'Cassville',
    postalCode: '65625',
  },
  'cassville-ny': {
    name: 'Cassville',
    postalCode: '13318',
  },
  'cassville-pa': {
    name: 'Cassville',
    postalCode: '16623',
  },
  'cassville-wi': {
    name: 'Cassville',
    postalCode: '53806',
  },
  'castaic-ca': {
    name: 'Castaic',
    postalCode: '91384',
  },
  'castalia-ia': {
    name: 'Castalia',
    postalCode: '52133',
  },
  'castalia-nc': {
    name: 'Castalia',
    postalCode: '27816',
  },
  'castalia-oh': {
    name: 'Castalia',
    postalCode: '44824',
  },
  'castalian-springs-tn': {
    name: 'Castalian Springs',
    postalCode: '37031',
  },
  'castana-ia': {
    name: 'Castana',
    postalCode: '51010',
  },
  'castell-tx': {
    name: 'Castell',
    postalCode: '76831',
  },
  'castella-ca': {
    name: 'Castella',
    postalCode: '96017',
  },
  'castile-ny': {
    name: 'Castile',
    postalCode: '14427',
  },
  'castine-me': {
    name: 'Castine',
    postalCode: '04421',
  },
  'castle-ok': {
    name: 'Castle',
    postalCode: '74833',
  },
  'castle-creek-ny': {
    name: 'Castle Creek',
    postalCode: '13744',
  },
  'castle-hayne-nc': {
    name: 'Castle Hayne',
    postalCode: '28429',
  },
  'castle-rock-co': {
    name: 'Castle Rock',
    postalCode: '80104',
  },
  'castle-rock-wa': {
    name: 'Castle Rock',
    postalCode: '98611',
  },
  'castleberry-al': {
    name: 'Castleberry',
    postalCode: '36432',
  },
  'castleford-id': {
    name: 'Castleford',
    postalCode: '83321',
  },
  'castleton-va': {
    name: 'Castleton',
    postalCode: '22716',
  },
  'castleton-vt': {
    name: 'Castleton',
    postalCode: '05735',
  },
  'castleton-on-hudson-ny': {
    name: 'Castleton On Hudson',
    postalCode: '12033',
  },
  'castlewood-sd': {
    name: 'Castlewood',
    postalCode: '57223',
  },
  'castlewood-va': {
    name: 'Castlewood',
    postalCode: '24224',
  },
  'castor-la': {
    name: 'Castor',
    postalCode: '71016',
  },
  'castorland-ny': {
    name: 'Castorland',
    postalCode: '13620',
  },
  'castro-valley-ca': {
    name: 'Castro Valley',
    postalCode: '94546',
  },
  'castroville-ca': {
    name: 'Castroville',
    postalCode: '95012',
  },
  'castroville-tx': {
    name: 'Castroville',
    postalCode: '78009',
  },
  'cat-spring-tx': {
    name: 'Cat Spring',
    postalCode: '78933',
  },
  'cataldo-id': {
    name: 'Cataldo',
    postalCode: '83810',
  },
  'catano-pr': {
    name: 'Catano',
    postalCode: '00962',
  },
  'catasauqua-pa': {
    name: 'Catasauqua',
    postalCode: '18032',
  },
  'cataula-ga': {
    name: 'Cataula',
    postalCode: '31804',
  },
  'catawba-nc': {
    name: 'Catawba',
    postalCode: '28609',
  },
  'catawba-sc': {
    name: 'Catawba',
    postalCode: '29704',
  },
  'catawba-va': {
    name: 'Catawba',
    postalCode: '24070',
  },
  'catawba-wi': {
    name: 'Catawba',
    postalCode: '54515',
  },
  'catawissa-mo': {
    name: 'Catawissa',
    postalCode: '63015',
  },
  'catawissa-pa': {
    name: 'Catawissa',
    postalCode: '17820',
  },
  'catharpin-va': {
    name: 'Catharpin',
    postalCode: '20143',
  },
  'cathay-nd': {
    name: 'Cathay',
    postalCode: '58422',
  },
  'cathedral-city-ca': {
    name: 'Cathedral City',
    postalCode: '92234',
  },
  'catherine-al': {
    name: 'Catherine',
    postalCode: '36728',
  },
  'catheys-valley-ca': {
    name: 'Catheys Valley',
    postalCode: '95306',
  },
  'cathlamet-wa': {
    name: 'Cathlamet',
    postalCode: '98612',
  },
  'catlett-va': {
    name: 'Catlett',
    postalCode: '20119',
  },
  'catlettsburg-ky': {
    name: 'Catlettsburg',
    postalCode: '41129',
  },
  'catlin-il': {
    name: 'Catlin',
    postalCode: '61817',
  },
  'cato-ny': {
    name: 'Cato',
    postalCode: '13033',
  },
  'catonsville-md': {
    name: 'Catonsville',
    postalCode: '21228',
  },
  'catoosa-ok': {
    name: 'Catoosa',
    postalCode: '74015',
  },
  'catron-mo': {
    name: 'Catron',
    postalCode: '63833',
  },
  'catskill-ny': {
    name: 'Catskill',
    postalCode: '12414',
  },
  'cattaraugus-ny': {
    name: 'Cattaraugus',
    postalCode: '14719',
  },
  'caulfield-mo': {
    name: 'Caulfield',
    postalCode: '65626',
  },
  'causey-nm': {
    name: 'Causey',
    postalCode: '88113',
  },
  'cavalier-nd': {
    name: 'Cavalier',
    postalCode: '58220',
  },
  'cave-city-ar': {
    name: 'Cave City',
    postalCode: '72521',
  },
  'cave-city-ky': {
    name: 'Cave City',
    postalCode: '42127',
  },
  'cave-creek-az': {
    name: 'Cave Creek',
    postalCode: '85331',
  },
  'cave-in-rock-il': {
    name: 'Cave In Rock',
    postalCode: '62919',
  },
  'cave-junction-or': {
    name: 'Cave Junction',
    postalCode: '97523',
  },
  'cave-spring-ga': {
    name: 'Cave Spring',
    postalCode: '30124',
  },
  'cave-springs-ar': {
    name: 'Cave Springs',
    postalCode: '72718',
  },
  'cavendish-vt': {
    name: 'Cavendish',
    postalCode: '05142',
  },
  'cavour-sd': {
    name: 'Cavour',
    postalCode: '57324',
  },
  'cawker-city-ks': {
    name: 'Cawker City',
    postalCode: '67430',
  },
  'cawood-ky': {
    name: 'Cawood',
    postalCode: '40815',
  },
  'cayce-sc': {
    name: 'Cayce',
    postalCode: '29033',
  },
  'cayey-pr': {
    name: 'Cayey',
    postalCode: '00736',
  },
  'cayucos-ca': {
    name: 'Cayucos',
    postalCode: '93430',
  },
  'cayuga-in': {
    name: 'Cayuga',
    postalCode: '47928',
  },
  'cayuga-nd': {
    name: 'Cayuga',
    postalCode: '58013',
  },
  'cayuga-ny': {
    name: 'Cayuga',
    postalCode: '13034',
  },
  'cayuta-ny': {
    name: 'Cayuta',
    postalCode: '14824',
  },
  'cazadero-ca': {
    name: 'Cazadero',
    postalCode: '95421',
  },
  'cazenovia-ny': {
    name: 'Cazenovia',
    postalCode: '13035',
  },
  'cazenovia-wi': {
    name: 'Cazenovia',
    postalCode: '53924',
  },
  'cecil-al': {
    name: 'Cecil',
    postalCode: '36013',
  },
  'cecil-ar': {
    name: 'Cecil',
    postalCode: '72930',
  },
  'cecil-oh': {
    name: 'Cecil',
    postalCode: '45821',
  },
  'cecil-pa': {
    name: 'Cecil',
    postalCode: '15321',
  },
  'cecil-wi': {
    name: 'Cecil',
    postalCode: '54111',
  },
  'cecilia-ky': {
    name: 'Cecilia',
    postalCode: '42724',
  },
  'cedar-ia': {
    name: 'Cedar',
    postalCode: '52543',
  },
  'cedar-ks': {
    name: 'Cedar',
    postalCode: '67628',
  },
  'cedar-mi': {
    name: 'Cedar',
    postalCode: '49621',
  },
  'cedar-mn': {
    name: 'Cedar',
    postalCode: '55011',
  },
  'cedar-bluff-al': {
    name: 'Cedar Bluff',
    postalCode: '35959',
  },
  'cedar-bluff-va': {
    name: 'Cedar Bluff',
    postalCode: '24609',
  },
  'cedar-bluffs-ne': {
    name: 'Cedar Bluffs',
    postalCode: '68015',
  },
  'cedar-city-ut': {
    name: 'Cedar City',
    postalCode: '84720',
  },
  'cedar-creek-tx': {
    name: 'Cedar Creek',
    postalCode: '78612',
  },
  'cedar-crest-nm': {
    name: 'Cedar Crest',
    postalCode: '87008',
  },
  'cedar-falls-ia': {
    name: 'Cedar Falls',
    postalCode: '50613',
  },
  'cedar-grove-in': {
    name: 'Cedar Grove',
    postalCode: '47016',
  },
  'cedar-grove-nc': {
    name: 'Cedar Grove',
    postalCode: '27231',
  },
  'cedar-grove-nj': {
    name: 'Cedar Grove',
    postalCode: '07009',
  },
  'cedar-grove-tn': {
    name: 'Cedar Grove',
    postalCode: '38321',
  },
  'cedar-grove-wi': {
    name: 'Cedar Grove',
    postalCode: '53013',
  },
  'cedar-grove-wv': {
    name: 'Cedar Grove',
    postalCode: '25039',
  },
  'cedar-hill-mo': {
    name: 'Cedar Hill',
    postalCode: '63016',
  },
  'cedar-hill-tn': {
    name: 'Cedar Hill',
    postalCode: '37032',
  },
  'cedar-hill-tx': {
    name: 'Cedar Hill',
    postalCode: '75104',
  },
  'cedar-island-nc': {
    name: 'Cedar Island',
    postalCode: '28520',
  },
  'cedar-key-fl': {
    name: 'Cedar Key',
    postalCode: '32625',
  },
  'cedar-knolls-nj': {
    name: 'Cedar Knolls',
    postalCode: '07927',
  },
  'cedar-lake-in': {
    name: 'Cedar Lake',
    postalCode: '46303',
  },
  'cedar-mountain-nc': {
    name: 'Cedar Mountain',
    postalCode: '28718',
  },
  'cedar-park-tx': {
    name: 'Cedar Park',
    postalCode: '78613',
  },
  'cedar-point-ks': {
    name: 'Cedar Point',
    postalCode: '66843',
  },
  'cedar-rapids-ia': {
    name: 'Cedar Rapids',
    postalCode: '52401',
  },
  'cedar-rapids-ne': {
    name: 'Cedar Rapids',
    postalCode: '68627',
  },
  'cedar-run-pa': {
    name: 'Cedar Run',
    postalCode: '17727',
  },
  'cedar-springs-mi': {
    name: 'Cedar Springs',
    postalCode: '49319',
  },
  'cedar-vale-ks': {
    name: 'Cedar Vale',
    postalCode: '67024',
  },
  'cedar-valley-ut': {
    name: 'Cedar Valley',
    postalCode: '84013',
  },
  'cedarbluff-ms': {
    name: 'Cedarbluff',
    postalCode: '39741',
  },
  'cedarburg-wi': {
    name: 'Cedarburg',
    postalCode: '53012',
  },
  'cedarcreek-mo': {
    name: 'Cedarcreek',
    postalCode: '65627',
  },
  'cedaredge-co': {
    name: 'Cedaredge',
    postalCode: '81413',
  },
  'cedarhurst-ny': {
    name: 'Cedarhurst',
    postalCode: '11516',
  },
  'cedartown-ga': {
    name: 'Cedartown',
    postalCode: '30125',
  },
  'cedarvale-nm': {
    name: 'Cedarvale',
    postalCode: '87009',
  },
  'cedarville-ar': {
    name: 'Cedarville',
    postalCode: '72932',
  },
  'cedarville-ca': {
    name: 'Cedarville',
    postalCode: '96104',
  },
  'cedarville-mi': {
    name: 'Cedarville',
    postalCode: '49719',
  },
  'cedarville-nj': {
    name: 'Cedarville',
    postalCode: '08311',
  },
  'cedarville-oh': {
    name: 'Cedarville',
    postalCode: '45314',
  },
  'cedarville-wv': {
    name: 'Cedarville',
    postalCode: '26611',
  },
  'cee-vee-tx': {
    name: 'Cee Vee',
    postalCode: '79223',
  },
  'ceiba-pr': {
    name: 'Ceiba',
    postalCode: '00735',
  },
  'celeste-tx': {
    name: 'Celeste',
    postalCode: '75423',
  },
  'celestine-in': {
    name: 'Celestine',
    postalCode: '47521',
  },
  'celina-oh': {
    name: 'Celina',
    postalCode: '45822',
  },
  'celina-tn': {
    name: 'Celina',
    postalCode: '38551',
  },
  'celina-tx': {
    name: 'Celina',
    postalCode: '75009',
  },
  'cement-ok': {
    name: 'Cement',
    postalCode: '73017',
  },
  'cement-city-mi': {
    name: 'Cement City',
    postalCode: '49233',
  },
  'center-co': {
    name: 'Center',
    postalCode: '81125',
  },
  'center-ky': {
    name: 'Center',
    postalCode: '42214',
  },
  'center-mo': {
    name: 'Center',
    postalCode: '63436',
  },
  'center-nd': {
    name: 'Center',
    postalCode: '58530',
  },
  'center-ne': {
    name: 'Center',
    postalCode: '68724',
  },
  'center-tx': {
    name: 'Center',
    postalCode: '75935',
  },
  'center-barnstead-nh': {
    name: 'Center Barnstead',
    postalCode: '03225',
  },
  'center-city-mn': {
    name: 'Center City',
    postalCode: '55012',
  },
  'center-conway-nh': {
    name: 'Center Conway',
    postalCode: '03813',
  },
  'center-cross-va': {
    name: 'Center Cross',
    postalCode: '22437',
  },
  'center-harbor-nh': {
    name: 'Center Harbor',
    postalCode: '03226',
  },
  'center-hill-fl': {
    name: 'Center Hill',
    postalCode: '33514',
  },
  'center-junction-ia': {
    name: 'Center Junction',
    postalCode: '52212',
  },
  'center-line-mi': {
    name: 'Center Line',
    postalCode: '48015',
  },
  'center-moriches-ny': {
    name: 'Center Moriches',
    postalCode: '11934',
  },
  'center-ossipee-nh': {
    name: 'Center Ossipee',
    postalCode: '03814',
  },
  'center-point-ia': {
    name: 'Center Point',
    postalCode: '52213',
  },
  'center-point-la': {
    name: 'Center Point',
    postalCode: '71323',
  },
  'center-point-tx': {
    name: 'Center Point',
    postalCode: '78010',
  },
  'center-point-wv': {
    name: 'Center Point',
    postalCode: '26339',
  },
  'center-ridge-ar': {
    name: 'Center Ridge',
    postalCode: '72027',
  },
  'center-rutland-vt': {
    name: 'Center Rutland',
    postalCode: '05736',
  },
  'center-sandwich-nh': {
    name: 'Center Sandwich',
    postalCode: '03227',
  },
  'center-tuftonboro-nh': {
    name: 'Center Tuftonboro',
    postalCode: '03816',
  },
  'center-valley-pa': {
    name: 'Center Valley',
    postalCode: '18034',
  },
  'centerbrook-ct': {
    name: 'Centerbrook',
    postalCode: '06409',
  },
  'centerburg-oh': {
    name: 'Centerburg',
    postalCode: '43011',
  },
  'centereach-ny': {
    name: 'Centereach',
    postalCode: '11720',
  },
  'centerpoint-in': {
    name: 'Centerpoint',
    postalCode: '47840',
  },
  'centerport-ny': {
    name: 'Centerport',
    postalCode: '11721',
  },
  'centerton-ar': {
    name: 'Centerton',
    postalCode: '72719',
  },
  'centertown-ky': {
    name: 'Centertown',
    postalCode: '42328',
  },
  'centertown-mo': {
    name: 'Centertown',
    postalCode: '65023',
  },
  'centerview-mo': {
    name: 'Centerview',
    postalCode: '64019',
  },
  'centerville-ga': {
    name: 'Centerville',
    postalCode: '31028',
  },
  'centerville-ia': {
    name: 'Centerville',
    postalCode: '52544',
  },
  'centerville-in': {
    name: 'Centerville',
    postalCode: '47330',
  },
  'centerville-ks': {
    name: 'Centerville',
    postalCode: '66014',
  },
  'centerville-ma': {
    name: 'Centerville',
    postalCode: '02632',
  },
  'centerville-mo': {
    name: 'Centerville',
    postalCode: '63633',
  },
  'centerville-pa': {
    name: 'Centerville',
    postalCode: '16404',
  },
  'centerville-sd': {
    name: 'Centerville',
    postalCode: '57014',
  },
  'centerville-tn': {
    name: 'Centerville',
    postalCode: '37033',
  },
  'centerville-tx': {
    name: 'Centerville',
    postalCode: '75833',
  },
  'centerville-ut': {
    name: 'Centerville',
    postalCode: '84014',
  },
  'centerville-wa': {
    name: 'Centerville',
    postalCode: '98613',
  },
  'centrahoma-ok': {
    name: 'Centrahoma',
    postalCode: '74534',
  },
  'central-in': {
    name: 'Central',
    postalCode: '47110',
  },
  'central-sc': {
    name: 'Central',
    postalCode: '29630',
  },
  'central-ut': {
    name: 'Central',
    postalCode: '84722',
  },
  'central-bridge-ny': {
    name: 'Central Bridge',
    postalCode: '12035',
  },
  'central-city-ia': {
    name: 'Central City',
    postalCode: '52214',
  },
  'central-city-ky': {
    name: 'Central City',
    postalCode: '42330',
  },
  'central-city-ne': {
    name: 'Central City',
    postalCode: '68826',
  },
  'central-city-pa': {
    name: 'Central City',
    postalCode: '15926',
  },
  'central-falls-ri': {
    name: 'Central Falls',
    postalCode: '02863',
  },
  'central-islip-ny': {
    name: 'Central Islip',
    postalCode: '11722',
  },
  'central-lake-mi': {
    name: 'Central Lake',
    postalCode: '49622',
  },
  'central-point-or': {
    name: 'Central Point',
    postalCode: '97502',
  },
  'central-square-ny': {
    name: 'Central Square',
    postalCode: '13036',
  },
  'central-valley-ny': {
    name: 'Central Valley',
    postalCode: '10917',
  },
  'centralia-il': {
    name: 'Centralia',
    postalCode: '62801',
  },
  'centralia-ks': {
    name: 'Centralia',
    postalCode: '66415',
  },
  'centralia-mo': {
    name: 'Centralia',
    postalCode: '65240',
  },
  'centralia-tx': {
    name: 'Centralia',
    postalCode: '75834',
  },
  'centralia-wa': {
    name: 'Centralia',
    postalCode: '98531',
  },
  'centre-al': {
    name: 'Centre',
    postalCode: '35960',
  },
  'centre-hall-pa': {
    name: 'Centre Hall',
    postalCode: '16828',
  },
  'centreville-al': {
    name: 'Centreville',
    postalCode: '35042',
  },
  'centreville-md': {
    name: 'Centreville',
    postalCode: '21617',
  },
  'centreville-mi': {
    name: 'Centreville',
    postalCode: '49032',
  },
  'centreville-ms': {
    name: 'Centreville',
    postalCode: '39631',
  },
  'centreville-va': {
    name: 'Centreville',
    postalCode: '20120',
  },
  'centuria-wi': {
    name: 'Centuria',
    postalCode: '54824',
  },
  'century-fl': {
    name: 'Century',
    postalCode: '32535',
  },
  'ceres-ca': {
    name: 'Ceres',
    postalCode: '95307',
  },
  'ceres-ny': {
    name: 'Ceres',
    postalCode: '14721',
  },
  'ceres-va': {
    name: 'Ceres',
    postalCode: '24318',
  },
  'ceresco-mi': {
    name: 'Ceresco',
    postalCode: '49033',
  },
  'ceresco-ne': {
    name: 'Ceresco',
    postalCode: '68017',
  },
  'cerrillos-nm': {
    name: 'Cerrillos',
    postalCode: '87010',
  },
  'cerritos-ca': {
    name: 'Cerritos',
    postalCode: '90703',
  },
  'cerro-gordo-il': {
    name: 'Cerro Gordo',
    postalCode: '61818',
  },
  'cerro-gordo-nc': {
    name: 'Cerro Gordo',
    postalCode: '28430',
  },
  'cerulean-ky': {
    name: 'Cerulean',
    postalCode: '42215',
  },
  'ceylon-mn': {
    name: 'Ceylon',
    postalCode: '56121',
  },
  'chacon-nm': {
    name: 'Chacon',
    postalCode: '87713',
  },
  'chadbourn-nc': {
    name: 'Chadbourn',
    postalCode: '28431',
  },
  'chadds-ford-pa': {
    name: 'Chadds Ford',
    postalCode: '19317',
  },
  'chadron-ne': {
    name: 'Chadron',
    postalCode: '69337',
  },
  'chadwick-il': {
    name: 'Chadwick',
    postalCode: '61014',
  },
  'chadwick-mo': {
    name: 'Chadwick',
    postalCode: '65629',
  },
  'chadwicks-ny': {
    name: 'Chadwicks',
    postalCode: '13319',
  },
  'chaffee-mo': {
    name: 'Chaffee',
    postalCode: '63740',
  },
  'chaffee-ny': {
    name: 'Chaffee',
    postalCode: '14030',
  },
  'chagrin-falls-oh': {
    name: 'Chagrin Falls',
    postalCode: '44022',
  },
  'chalfont-pa': {
    name: 'Chalfont',
    postalCode: '18914',
  },
  'challenge-ca': {
    name: 'Challenge',
    postalCode: '95925',
  },
  'challis-id': {
    name: 'Challis',
    postalCode: '83226',
  },
  'chalmers-in': {
    name: 'Chalmers',
    postalCode: '47929',
  },
  'chalmette-la': {
    name: 'Chalmette',
    postalCode: '70043',
  },
  'chama-nm': {
    name: 'Chama',
    postalCode: '87520',
  },
  'chamberlain-me': {
    name: 'Chamberlain',
    postalCode: '04541',
  },
  'chamberlain-sd': {
    name: 'Chamberlain',
    postalCode: '57325',
  },
  'chambers-ne': {
    name: 'Chambers',
    postalCode: '68725',
  },
  'chambersburg-il': {
    name: 'Chambersburg',
    postalCode: '62323',
  },
  'chambersburg-pa': {
    name: 'Chambersburg',
    postalCode: '17201',
  },
  'chamisal-nm': {
    name: 'Chamisal',
    postalCode: '87521',
  },
  'chamois-mo': {
    name: 'Chamois',
    postalCode: '65024',
  },
  'champaign-il': {
    name: 'Champaign',
    postalCode: '61820',
  },
  'champion-mi': {
    name: 'Champion',
    postalCode: '49814',
  },
  'champion-ne': {
    name: 'Champion',
    postalCode: '69023',
  },
  'champion-pa': {
    name: 'Champion',
    postalCode: '15622',
  },
  'champlain-ny': {
    name: 'Champlain',
    postalCode: '12919',
  },
  'champlain-va': {
    name: 'Champlain',
    postalCode: '22438',
  },
  'champlin-mn': {
    name: 'Champlin',
    postalCode: '55316',
  },
  'chana-il': {
    name: 'Chana',
    postalCode: '61015',
  },
  'chancellor-al': {
    name: 'Chancellor',
    postalCode: '36316',
  },
  'chancellor-sd': {
    name: 'Chancellor',
    postalCode: '57015',
  },
  'chandler-az': {
    name: 'Chandler',
    postalCode: '85224',
  },
  'chandler-in': {
    name: 'Chandler',
    postalCode: '47610',
  },
  'chandler-mn': {
    name: 'Chandler',
    postalCode: '56122',
  },
  'chandler-ok': {
    name: 'Chandler',
    postalCode: '74834',
  },
  'chandler-tx': {
    name: 'Chandler',
    postalCode: '75758',
  },
  'chandler-heights-az': {
    name: 'Chandler Heights',
    postalCode: '85227',
  },
  'chandlersville-oh': {
    name: 'Chandlersville',
    postalCode: '43727',
  },
  'chandlerville-il': {
    name: 'Chandlerville',
    postalCode: '62627',
  },
  'changewater-nj': {
    name: 'Changewater',
    postalCode: '07831',
  },
  'chanhassen-mn': {
    name: 'Chanhassen',
    postalCode: '55317',
  },
  'channahon-il': {
    name: 'Channahon',
    postalCode: '60410',
  },
  'channelview-tx': {
    name: 'Channelview',
    postalCode: '77530',
  },
  'channing-mi': {
    name: 'Channing',
    postalCode: '49815',
  },
  'channing-tx': {
    name: 'Channing',
    postalCode: '79018',
  },
  'chantilly-va': {
    name: 'Chantilly',
    postalCode: '20151',
  },
  'chanute-ks': {
    name: 'Chanute',
    postalCode: '66720',
  },
  'chaparral-nm': {
    name: 'Chaparral',
    postalCode: '88081',
  },
  'chapel-hill-nc': {
    name: 'Chapel Hill',
    postalCode: '27514',
  },
  'chapel-hill-tn': {
    name: 'Chapel Hill',
    postalCode: '37034',
  },
  'chapin-ia': {
    name: 'Chapin',
    postalCode: '50427',
  },
  'chapin-il': {
    name: 'Chapin',
    postalCode: '62628',
  },
  'chapin-sc': {
    name: 'Chapin',
    postalCode: '29036',
  },
  'chaplin-ct': {
    name: 'Chaplin',
    postalCode: '06235',
  },
  'chaplin-ky': {
    name: 'Chaplin',
    postalCode: '40012',
  },
  'chapman-ks': {
    name: 'Chapman',
    postalCode: '67431',
  },
  'chapman-ne': {
    name: 'Chapman',
    postalCode: '68827',
  },
  'chapmansboro-tn': {
    name: 'Chapmansboro',
    postalCode: '37035',
  },
  'chapmanville-wv': {
    name: 'Chapmanville',
    postalCode: '25508',
  },
  'chappaqua-ny': {
    name: 'Chappaqua',
    postalCode: '10514',
  },
  'chappell-ky': {
    name: 'Chappell',
    postalCode: '40816',
  },
  'chappell-ne': {
    name: 'Chappell',
    postalCode: '69129',
  },
  'chappell-hill-tx': {
    name: 'Chappell Hill',
    postalCode: '77426',
  },
  'chappells-sc': {
    name: 'Chappells',
    postalCode: '29037',
  },
  'chaptico-md': {
    name: 'Chaptico',
    postalCode: '20621',
  },
  'chardon-oh': {
    name: 'Chardon',
    postalCode: '44024',
  },
  'chariton-ia': {
    name: 'Chariton',
    postalCode: '50049',
  },
  'charlemont-ma': {
    name: 'Charlemont',
    postalCode: '01339',
  },
  'charleroi-pa': {
    name: 'Charleroi',
    postalCode: '15022',
  },
  'charles-city-ia': {
    name: 'Charles City',
    postalCode: '50616',
  },
  'charles-city-va': {
    name: 'Charles City',
    postalCode: '23030',
  },
  'charles-town-wv': {
    name: 'Charles Town',
    postalCode: '25414',
  },
  'charleston-ar': {
    name: 'Charleston',
    postalCode: '72933',
  },
  'charleston-il': {
    name: 'Charleston',
    postalCode: '61920',
  },
  'charleston-me': {
    name: 'Charleston',
    postalCode: '04422',
  },
  'charleston-mo': {
    name: 'Charleston',
    postalCode: '63834',
  },
  'charleston-ms': {
    name: 'Charleston',
    postalCode: '38921',
  },
  'charleston-sc': {
    name: 'Charleston',
    postalCode: '29401',
  },
  'charleston-tn': {
    name: 'Charleston',
    postalCode: '37310',
  },
  'charleston-wv': {
    name: 'Charleston',
    postalCode: '25301',
  },
  'charleston-afb-sc': {
    name: 'Charleston AFB',
    postalCode: '29404',
  },
  'charlestown-in': {
    name: 'Charlestown',
    postalCode: '47111',
  },
  'charlestown-ma': {
    name: 'Charlestown',
    postalCode: '02129',
  },
  'charlestown-nh': {
    name: 'Charlestown',
    postalCode: '03603',
  },
  'charlestown-ri': {
    name: 'Charlestown',
    postalCode: '02813',
  },
  'charlevoix-mi': {
    name: 'Charlevoix',
    postalCode: '49720',
  },
  'charlo-mt': {
    name: 'Charlo',
    postalCode: '59824',
  },
  'charlotte-ar': {
    name: 'Charlotte',
    postalCode: '72522',
  },
  'charlotte-ia': {
    name: 'Charlotte',
    postalCode: '52731',
  },
  'charlotte-mi': {
    name: 'Charlotte',
    postalCode: '48813',
  },
  'charlotte-nc': {
    name: 'Charlotte',
    postalCode: '28202',
  },
  'charlotte-tn': {
    name: 'Charlotte',
    postalCode: '37036',
  },
  'charlotte-tx': {
    name: 'Charlotte',
    postalCode: '78011',
  },
  'charlotte-vt': {
    name: 'Charlotte',
    postalCode: '05445',
  },
  'charlotte-court-house-va': {
    name: 'Charlotte Court House',
    postalCode: '23923',
  },
  'charlotte-hall-md': {
    name: 'Charlotte Hall',
    postalCode: '20622',
  },
  'charlottesville-in': {
    name: 'Charlottesville',
    postalCode: '46117',
  },
  'charlottesville-va': {
    name: 'Charlottesville',
    postalCode: '22901',
  },
  'charlotteville-ny': {
    name: 'Charlotteville',
    postalCode: '12036',
  },
  'charlton-ma': {
    name: 'Charlton',
    postalCode: '01507',
  },
  'charmco-wv': {
    name: 'Charmco',
    postalCode: '25958',
  },
  'charter-oak-ia': {
    name: 'Charter Oak',
    postalCode: '51439',
  },
  'chase-ks': {
    name: 'Chase',
    postalCode: '67524',
  },
  'chase-mi': {
    name: 'Chase',
    postalCode: '49623',
  },
  'chase-city-va': {
    name: 'Chase City',
    postalCode: '23924',
  },
  'chase-mills-ny': {
    name: 'Chase Mills',
    postalCode: '13621',
  },
  'chaseburg-wi': {
    name: 'Chaseburg',
    postalCode: '54621',
  },
  'chaseley-nd': {
    name: 'Chaseley',
    postalCode: '58423',
  },
  'chaska-mn': {
    name: 'Chaska',
    postalCode: '55318',
  },
  'chassell-mi': {
    name: 'Chassell',
    postalCode: '49916',
  },
  'chateaugay-ny': {
    name: 'Chateaugay',
    postalCode: '12920',
  },
  'chatfield-mn': {
    name: 'Chatfield',
    postalCode: '55923',
  },
  'chatfield-tx': {
    name: 'Chatfield',
    postalCode: '75105',
  },
  'chatham-il': {
    name: 'Chatham',
    postalCode: '62629',
  },
  'chatham-la': {
    name: 'Chatham',
    postalCode: '71226',
  },
  'chatham-ma': {
    name: 'Chatham',
    postalCode: '02633',
  },
  'chatham-mi': {
    name: 'Chatham',
    postalCode: '49816',
  },
  'chatham-ms': {
    name: 'Chatham',
    postalCode: '38731',
  },
  'chatham-nj': {
    name: 'Chatham',
    postalCode: '07928',
  },
  'chatham-ny': {
    name: 'Chatham',
    postalCode: '12037',
  },
  'chatham-va': {
    name: 'Chatham',
    postalCode: '24531',
  },
  'chatom-al': {
    name: 'Chatom',
    postalCode: '36518',
  },
  'chatsworth-ca': {
    name: 'Chatsworth',
    postalCode: '91311',
  },
  'chatsworth-ga': {
    name: 'Chatsworth',
    postalCode: '30705',
  },
  'chatsworth-ia': {
    name: 'Chatsworth',
    postalCode: '51011',
  },
  'chatsworth-il': {
    name: 'Chatsworth',
    postalCode: '60921',
  },
  'chatsworth-nj': {
    name: 'Chatsworth',
    postalCode: '08019',
  },
  'chattahoochee-fl': {
    name: 'Chattahoochee',
    postalCode: '32324',
  },
  'chattanooga-ok': {
    name: 'Chattanooga',
    postalCode: '73528',
  },
  'chattanooga-tn': {
    name: 'Chattanooga',
    postalCode: '37402',
  },
  'chattaroy-wa': {
    name: 'Chattaroy',
    postalCode: '99003',
  },
  'chaumont-ny': {
    name: 'Chaumont',
    postalCode: '13622',
  },
  'chauncey-ga': {
    name: 'Chauncey',
    postalCode: '31011',
  },
  'chauncey-wv': {
    name: 'Chauncey',
    postalCode: '25612',
  },
  'chauvin-la': {
    name: 'Chauvin',
    postalCode: '70344',
  },
  'chavies-ky': {
    name: 'Chavies',
    postalCode: '41727',
  },
  'chazy-ny': {
    name: 'Chazy',
    postalCode: '12921',
  },
  'chebanse-il': {
    name: 'Chebanse',
    postalCode: '60922',
  },
  'chebeague-island-me': {
    name: 'Chebeague Island',
    postalCode: '04017',
  },
  'cheboygan-mi': {
    name: 'Cheboygan',
    postalCode: '49721',
  },
  'check-va': {
    name: 'Check',
    postalCode: '24072',
  },
  'checotah-ok': {
    name: 'Checotah',
    postalCode: '74426',
  },
  'chehalis-wa': {
    name: 'Chehalis',
    postalCode: '98532',
  },
  'chelan-wa': {
    name: 'Chelan',
    postalCode: '98816',
  },
  'chelmsford-ma': {
    name: 'Chelmsford',
    postalCode: '01824',
  },
  'chelsea-al': {
    name: 'Chelsea',
    postalCode: '35043',
  },
  'chelsea-ia': {
    name: 'Chelsea',
    postalCode: '52215',
  },
  'chelsea-ma': {
    name: 'Chelsea',
    postalCode: '02150',
  },
  'chelsea-mi': {
    name: 'Chelsea',
    postalCode: '48118',
  },
  'chelsea-ok': {
    name: 'Chelsea',
    postalCode: '74016',
  },
  'chelsea-vt': {
    name: 'Chelsea',
    postalCode: '05038',
  },
  'cheltenham-md': {
    name: 'Cheltenham',
    postalCode: '20623',
  },
  'cheltenham-pa': {
    name: 'Cheltenham',
    postalCode: '19012',
  },
  'chemult-or': {
    name: 'Chemult',
    postalCode: '97731',
  },
  'chemung-ny': {
    name: 'Chemung',
    postalCode: '14825',
  },
  'chenango-forks-ny': {
    name: 'Chenango Forks',
    postalCode: '13746',
  },
  'cheney-ks': {
    name: 'Cheney',
    postalCode: '67025',
  },
  'cheney-wa': {
    name: 'Cheney',
    postalCode: '99004',
  },
  'cheneyville-la': {
    name: 'Cheneyville',
    postalCode: '71325',
  },
  'chenoa-il': {
    name: 'Chenoa',
    postalCode: '61726',
  },
  'chepachet-ri': {
    name: 'Chepachet',
    postalCode: '02814',
  },
  'cheraw-sc': {
    name: 'Cheraw',
    postalCode: '29520',
  },
  'cherokee-al': {
    name: 'Cherokee',
    postalCode: '35616',
  },
  'cherokee-ia': {
    name: 'Cherokee',
    postalCode: '51012',
  },
  'cherokee-ks': {
    name: 'Cherokee',
    postalCode: '66724',
  },
  'cherokee-nc': {
    name: 'Cherokee',
    postalCode: '28719',
  },
  'cherokee-ok': {
    name: 'Cherokee',
    postalCode: '73728',
  },
  'cherokee-tx': {
    name: 'Cherokee',
    postalCode: '76832',
  },
  'cherokee-village-ar': {
    name: 'Cherokee Village',
    postalCode: '72529',
  },
  'cherry-creek-ny': {
    name: 'Cherry Creek',
    postalCode: '14723',
  },
  'cherry-creek-sd': {
    name: 'Cherry Creek',
    postalCode: '57622',
  },
  'cherry-hill-nj': {
    name: 'Cherry Hill',
    postalCode: '08002',
  },
  'cherry-log-ga': {
    name: 'Cherry Log',
    postalCode: '30522',
  },
  'cherry-point-nc': {
    name: 'Cherry Point',
    postalCode: '28533',
  },
  'cherry-tree-pa': {
    name: 'Cherry Tree',
    postalCode: '15724',
  },
  'cherry-valley-ar': {
    name: 'Cherry Valley',
    postalCode: '72324',
  },
  'cherry-valley-il': {
    name: 'Cherry Valley',
    postalCode: '61016',
  },
  'cherry-valley-ma': {
    name: 'Cherry Valley',
    postalCode: '01611',
  },
  'cherry-valley-ny': {
    name: 'Cherry Valley',
    postalCode: '13320',
  },
  'cherryfield-me': {
    name: 'Cherryfield',
    postalCode: '04622',
  },
  'cherryvale-ks': {
    name: 'Cherryvale',
    postalCode: '67335',
  },
  'cherryville-mo': {
    name: 'Cherryville',
    postalCode: '65446',
  },
  'cherryville-nc': {
    name: 'Cherryville',
    postalCode: '28021',
  },
  'cherryville-pa': {
    name: 'Cherryville',
    postalCode: '18035',
  },
  'chesaning-mi': {
    name: 'Chesaning',
    postalCode: '48616',
  },
  'chesapeake-oh': {
    name: 'Chesapeake',
    postalCode: '45619',
  },
  'chesapeake-va': {
    name: 'Chesapeake',
    postalCode: '23320',
  },
  'chesapeake-beach-md': {
    name: 'Chesapeake Beach',
    postalCode: '20732',
  },
  'chesapeake-city-md': {
    name: 'Chesapeake City',
    postalCode: '21915',
  },
  'cheshire-ct': {
    name: 'Cheshire',
    postalCode: '06410',
  },
  'cheshire-ma': {
    name: 'Cheshire',
    postalCode: '01225',
  },
  'cheshire-oh': {
    name: 'Cheshire',
    postalCode: '45620',
  },
  'cheshire-or': {
    name: 'Cheshire',
    postalCode: '97419',
  },
  'chesnee-sc': {
    name: 'Chesnee',
    postalCode: '29323',
  },
  'chester-ar': {
    name: 'Chester',
    postalCode: '72934',
  },
  'chester-ca': {
    name: 'Chester',
    postalCode: '96020',
  },
  'chester-ct': {
    name: 'Chester',
    postalCode: '06412',
  },
  'chester-ga': {
    name: 'Chester',
    postalCode: '31012',
  },
  'chester-ia': {
    name: 'Chester',
    postalCode: '52134',
  },
  'chester-il': {
    name: 'Chester',
    postalCode: '62233',
  },
  'chester-ma': {
    name: 'Chester',
    postalCode: '01011',
  },
  'chester-md': {
    name: 'Chester',
    postalCode: '21619',
  },
  'chester-mt': {
    name: 'Chester',
    postalCode: '59522',
  },
  'chester-ne': {
    name: 'Chester',
    postalCode: '68327',
  },
  'chester-nh': {
    name: 'Chester',
    postalCode: '03036',
  },
  'chester-nj': {
    name: 'Chester',
    postalCode: '07930',
  },
  'chester-ny': {
    name: 'Chester',
    postalCode: '10918',
  },
  'chester-ok': {
    name: 'Chester',
    postalCode: '73838',
  },
  'chester-pa': {
    name: 'Chester',
    postalCode: '19013',
  },
  'chester-sc': {
    name: 'Chester',
    postalCode: '29706',
  },
  'chester-sd': {
    name: 'Chester',
    postalCode: '57016',
  },
  'chester-tx': {
    name: 'Chester',
    postalCode: '75936',
  },
  'chester-ut': {
    name: 'Chester',
    postalCode: '84623',
  },
  'chester-va': {
    name: 'Chester',
    postalCode: '23831',
  },
  'chester-vt': {
    name: 'Chester',
    postalCode: '05143',
  },
  'chester-wv': {
    name: 'Chester',
    postalCode: '26034',
  },
  'chester-gap-va': {
    name: 'Chester Gap',
    postalCode: '22623',
  },
  'chester-springs-pa': {
    name: 'Chester Springs',
    postalCode: '19425',
  },
  'chesterfield-il': {
    name: 'Chesterfield',
    postalCode: '62630',
  },
  'chesterfield-ma': {
    name: 'Chesterfield',
    postalCode: '01012',
  },
  'chesterfield-mo': {
    name: 'Chesterfield',
    postalCode: '63005',
  },
  'chesterfield-nh': {
    name: 'Chesterfield',
    postalCode: '03443',
  },
  'chesterfield-nj': {
    name: 'Chesterfield',
    postalCode: '08515',
  },
  'chesterfield-sc': {
    name: 'Chesterfield',
    postalCode: '29709',
  },
  'chesterfield-va': {
    name: 'Chesterfield',
    postalCode: '23832',
  },
  'chesterhill-oh': {
    name: 'Chesterhill',
    postalCode: '43728',
  },
  'chesterland-oh': {
    name: 'Chesterland',
    postalCode: '44026',
  },
  'chesterton-in': {
    name: 'Chesterton',
    postalCode: '46304',
  },
  'chestertown-md': {
    name: 'Chestertown',
    postalCode: '21620',
  },
  'chestertown-ny': {
    name: 'Chestertown',
    postalCode: '12817',
  },
  'chestnut-il': {
    name: 'Chestnut',
    postalCode: '62518',
  },
  'chestnut-hill-ma': {
    name: 'Chestnut Hill',
    postalCode: '02467',
  },
  'chestnut-mound-tn': {
    name: 'Chestnut Mound',
    postalCode: '38552',
  },
  'chestnutridge-mo': {
    name: 'Chestnutridge',
    postalCode: '65630',
  },
  'cheswick-pa': {
    name: 'Cheswick',
    postalCode: '15024',
  },
  'chetek-wi': {
    name: 'Chetek',
    postalCode: '54728',
  },
  'chetopa-ks': {
    name: 'Chetopa',
    postalCode: '67336',
  },
  'chevy-chase-md': {
    name: 'Chevy Chase',
    postalCode: '20815',
  },
  'chewelah-wa': {
    name: 'Chewelah',
    postalCode: '99109',
  },
  'cheyenne-ok': {
    name: 'Cheyenne',
    postalCode: '73628',
  },
  'cheyenne-wy': {
    name: 'Cheyenne',
    postalCode: '82001',
  },
  'cheyenne-wells-co': {
    name: 'Cheyenne Wells',
    postalCode: '80810',
  },
  'cheyney-pa': {
    name: 'Cheyney',
    postalCode: '19319',
  },
  'chicago-il': {
    name: 'Chicago',
    postalCode: '60601',
  },
  'chicago-heights-il': {
    name: 'Chicago Heights',
    postalCode: '60411',
  },
  'chicago-ridge-il': {
    name: 'Chicago Ridge',
    postalCode: '60415',
  },
  'chichester-nh': {
    name: 'Chichester',
    postalCode: '03258',
  },
  'chichester-ny': {
    name: 'Chichester',
    postalCode: '12416',
  },
  'chickamauga-ga': {
    name: 'Chickamauga',
    postalCode: '30707',
  },
  'chickasha-ok': {
    name: 'Chickasha',
    postalCode: '73018',
  },
  'chico-ca': {
    name: 'Chico',
    postalCode: '95926',
  },
  'chico-tx': {
    name: 'Chico',
    postalCode: '76431',
  },
  'chicopee-ma': {
    name: 'Chicopee',
    postalCode: '01013',
  },
  'chicora-pa': {
    name: 'Chicora',
    postalCode: '16025',
  },
  'chidester-ar': {
    name: 'Chidester',
    postalCode: '71726',
  },
  'chiefland-fl': {
    name: 'Chiefland',
    postalCode: '32626',
  },
  'chilcoot-ca': {
    name: 'Chilcoot',
    postalCode: '96105',
  },
  'childersburg-al': {
    name: 'Childersburg',
    postalCode: '35044',
  },
  'childress-tx': {
    name: 'Childress',
    postalCode: '79201',
  },
  'childwold-ny': {
    name: 'Childwold',
    postalCode: '12922',
  },
  'chilhowee-mo': {
    name: 'Chilhowee',
    postalCode: '64733',
  },
  'chilhowie-va': {
    name: 'Chilhowie',
    postalCode: '24319',
  },
  'chili-wi': {
    name: 'Chili',
    postalCode: '54420',
  },
  'chillicothe-ia': {
    name: 'Chillicothe',
    postalCode: '52548',
  },
  'chillicothe-il': {
    name: 'Chillicothe',
    postalCode: '61523',
  },
  'chillicothe-mo': {
    name: 'Chillicothe',
    postalCode: '64601',
  },
  'chillicothe-oh': {
    name: 'Chillicothe',
    postalCode: '45601',
  },
  'chillicothe-tx': {
    name: 'Chillicothe',
    postalCode: '79225',
  },
  'chilmark-ma': {
    name: 'Chilmark',
    postalCode: '02535',
  },
  'chiloquin-or': {
    name: 'Chiloquin',
    postalCode: '97624',
  },
  'chilton-tx': {
    name: 'Chilton',
    postalCode: '76632',
  },
  'chilton-wi': {
    name: 'Chilton',
    postalCode: '53014',
  },
  'chimacum-wa': {
    name: 'Chimacum',
    postalCode: '98325',
  },
  'chimayo-nm': {
    name: 'Chimayo',
    postalCode: '87522',
  },
  'chimney-rock-co': {
    name: 'Chimney Rock',
    postalCode: '81127',
  },
  'china-grove-nc': {
    name: 'China Grove',
    postalCode: '28023',
  },
  'china-spring-tx': {
    name: 'China Spring',
    postalCode: '76633',
  },
  'chincoteague-island-va': {
    name: 'Chincoteague Island',
    postalCode: '23336',
  },
  'chinle-az': {
    name: 'Chinle',
    postalCode: '86503',
  },
  'chino-ca': {
    name: 'Chino',
    postalCode: '91708',
  },
  'chino-hills-ca': {
    name: 'Chino Hills',
    postalCode: '91709',
  },
  'chino-valley-az': {
    name: 'Chino Valley',
    postalCode: '86323',
  },
  'chinook-mt': {
    name: 'Chinook',
    postalCode: '59523',
  },
  'chinquapin-nc': {
    name: 'Chinquapin',
    postalCode: '28521',
  },
  'chipley-fl': {
    name: 'Chipley',
    postalCode: '32428',
  },
  'chippewa-falls-wi': {
    name: 'Chippewa Falls',
    postalCode: '54729',
  },
  'chippewa-lake-oh': {
    name: 'Chippewa Lake',
    postalCode: '44215',
  },
  'chireno-tx': {
    name: 'Chireno',
    postalCode: '75937',
  },
  'chisago-city-mn': {
    name: 'Chisago City',
    postalCode: '55013',
  },
  'chisholm-mn': {
    name: 'Chisholm',
    postalCode: '55719',
  },
  'chittenango-ny': {
    name: 'Chittenango',
    postalCode: '13037',
  },
  'chittenden-vt': {
    name: 'Chittenden',
    postalCode: '05737',
  },
  'chloe-wv': {
    name: 'Chloe',
    postalCode: '25235',
  },
  'chocorua-nh': {
    name: 'Chocorua',
    postalCode: '03817',
  },
  'chocowinity-nc': {
    name: 'Chocowinity',
    postalCode: '27817',
  },
  'choctaw-ok': {
    name: 'Choctaw',
    postalCode: '73020',
  },
  'chokio-mn': {
    name: 'Chokio',
    postalCode: '56221',
  },
  'choteau-mt': {
    name: 'Choteau',
    postalCode: '59422',
  },
  'choudrant-la': {
    name: 'Choudrant',
    postalCode: '71227',
  },
  'chouteau-ok': {
    name: 'Chouteau',
    postalCode: '74337',
  },
  'chowchilla-ca': {
    name: 'Chowchilla',
    postalCode: '93610',
  },
  'chrisman-il': {
    name: 'Chrisman',
    postalCode: '61924',
  },
  'chrisney-in': {
    name: 'Chrisney',
    postalCode: '47611',
  },
  'christiana-pa': {
    name: 'Christiana',
    postalCode: '17509',
  },
  'christiana-tn': {
    name: 'Christiana',
    postalCode: '37037',
  },
  'christiansburg-va': {
    name: 'Christiansburg',
    postalCode: '24073',
  },
  'christiansted-vi': {
    name: 'Christiansted',
    postalCode: '00820',
  },
  'christine-nd': {
    name: 'Christine',
    postalCode: '58015',
  },
  'christine-tx': {
    name: 'Christine',
    postalCode: '78012',
  },
  'christmas-fl': {
    name: 'Christmas',
    postalCode: '32709',
  },
  'christopher-il': {
    name: 'Christopher',
    postalCode: '62822',
  },
  'christoval-tx': {
    name: 'Christoval',
    postalCode: '76935',
  },
  'chualar-ca': {
    name: 'Chualar',
    postalCode: '93925',
  },
  'chuckey-tn': {
    name: 'Chuckey',
    postalCode: '37641',
  },
  'chugiak-ak': {
    name: 'Chugiak',
    postalCode: '99567',
  },
  'chugwater-wy': {
    name: 'Chugwater',
    postalCode: '82210',
  },
  'chula-ga': {
    name: 'Chula',
    postalCode: '31733',
  },
  'chula-mo': {
    name: 'Chula',
    postalCode: '64635',
  },
  'chula-vista-ca': {
    name: 'Chula Vista',
    postalCode: '91910',
  },
  'chunchula-al': {
    name: 'Chunchula',
    postalCode: '36521',
  },
  'chunky-ms': {
    name: 'Chunky',
    postalCode: '39323',
  },
  'church-creek-md': {
    name: 'Church Creek',
    postalCode: '21622',
  },
  'church-hill-md': {
    name: 'Church Hill',
    postalCode: '21623',
  },
  'church-hill-tn': {
    name: 'Church Hill',
    postalCode: '37642',
  },
  'church-point-la': {
    name: 'Church Point',
    postalCode: '70525',
  },
  'church-road-va': {
    name: 'Church Road',
    postalCode: '23833',
  },
  'church-view-va': {
    name: 'Church View',
    postalCode: '23032',
  },
  'churchs-ferry-nd': {
    name: 'Churchs Ferry',
    postalCode: '58325',
  },
  'churchton-md': {
    name: 'Churchton',
    postalCode: '20733',
  },
  'churchville-md': {
    name: 'Churchville',
    postalCode: '21028',
  },
  'churchville-ny': {
    name: 'Churchville',
    postalCode: '14428',
  },
  'churchville-va': {
    name: 'Churchville',
    postalCode: '24421',
  },
  'churdan-ia': {
    name: 'Churdan',
    postalCode: '50050',
  },
  'churubusco-in': {
    name: 'Churubusco',
    postalCode: '46723',
  },
  'churubusco-ny': {
    name: 'Churubusco',
    postalCode: '12923',
  },
  'ciales-pr': {
    name: 'Ciales',
    postalCode: '00638',
  },
  'cibola-az': {
    name: 'Cibola',
    postalCode: '85328',
  },
  'cibolo-tx': {
    name: 'Cibolo',
    postalCode: '78108',
  },
  'cicero-il': {
    name: 'Cicero',
    postalCode: '60804',
  },
  'cicero-in': {
    name: 'Cicero',
    postalCode: '46034',
  },
  'cicero-ny': {
    name: 'Cicero',
    postalCode: '13039',
  },
  'cidra-pr': {
    name: 'Cidra',
    postalCode: '00739',
  },
  'cima-ca': {
    name: 'Cima',
    postalCode: '92323',
  },
  'cimarron-co': {
    name: 'Cimarron',
    postalCode: '81220',
  },
  'cimarron-ks': {
    name: 'Cimarron',
    postalCode: '67835',
  },
  'cimarron-nm': {
    name: 'Cimarron',
    postalCode: '87714',
  },
  'cincinnati-ia': {
    name: 'Cincinnati',
    postalCode: '52549',
  },
  'cincinnati-oh': {
    name: 'Cincinnati',
    postalCode: '45202',
  },
  'cincinnatus-ny': {
    name: 'Cincinnatus',
    postalCode: '13040',
  },
  'cinebar-wa': {
    name: 'Cinebar',
    postalCode: '98533',
  },
  'circle-mt': {
    name: 'Circle',
    postalCode: '59215',
  },
  'circle-pines-mn': {
    name: 'Circle Pines',
    postalCode: '55014',
  },
  'circleville-ks': {
    name: 'Circleville',
    postalCode: '66416',
  },
  'circleville-ny': {
    name: 'Circleville',
    postalCode: '10919',
  },
  'circleville-oh': {
    name: 'Circleville',
    postalCode: '43113',
  },
  'circleville-wv': {
    name: 'Circleville',
    postalCode: '26804',
  },
  'cisco-ga': {
    name: 'Cisco',
    postalCode: '30708',
  },
  'cisco-il': {
    name: 'Cisco',
    postalCode: '61830',
  },
  'cisco-tx': {
    name: 'Cisco',
    postalCode: '76437',
  },
  'cisco-ut': {
    name: 'Cisco',
    postalCode: '84515',
  },
  'cisne-il': {
    name: 'Cisne',
    postalCode: '62823',
  },
  'cissna-park-il': {
    name: 'Cissna Park',
    postalCode: '60924',
  },
  'citra-fl': {
    name: 'Citra',
    postalCode: '32113',
  },
  'citronelle-al': {
    name: 'Citronelle',
    postalCode: '36522',
  },
  'citrus-heights-ca': {
    name: 'Citrus Heights',
    postalCode: '95610',
  },
  'clackamas-or': {
    name: 'Clackamas',
    postalCode: '97015',
  },
  'claflin-ks': {
    name: 'Claflin',
    postalCode: '67525',
  },
  'claire-city-sd': {
    name: 'Claire City',
    postalCode: '57224',
  },
  'clairfield-tn': {
    name: 'Clairfield',
    postalCode: '37715',
  },
  'clairton-pa': {
    name: 'Clairton',
    postalCode: '15025',
  },
  'clallam-bay-wa': {
    name: 'Clallam Bay',
    postalCode: '98326',
  },
  'clam-gulch-ak': {
    name: 'Clam Gulch',
    postalCode: '99568',
  },
  'clam-lake-wi': {
    name: 'Clam Lake',
    postalCode: '54517',
  },
  'clancy-mt': {
    name: 'Clancy',
    postalCode: '59634',
  },
  'clanton-al': {
    name: 'Clanton',
    postalCode: '35045',
  },
  'clara-city-mn': {
    name: 'Clara City',
    postalCode: '56222',
  },
  'clare-ia': {
    name: 'Clare',
    postalCode: '50524',
  },
  'clare-il': {
    name: 'Clare',
    postalCode: '60111',
  },
  'clare-mi': {
    name: 'Clare',
    postalCode: '48617',
  },
  'claremont-ca': {
    name: 'Claremont',
    postalCode: '91711',
  },
  'claremont-il': {
    name: 'Claremont',
    postalCode: '62421',
  },
  'claremont-mn': {
    name: 'Claremont',
    postalCode: '55924',
  },
  'claremont-nc': {
    name: 'Claremont',
    postalCode: '28610',
  },
  'claremont-nh': {
    name: 'Claremont',
    postalCode: '03743',
  },
  'claremont-sd': {
    name: 'Claremont',
    postalCode: '57432',
  },
  'claremore-ok': {
    name: 'Claremore',
    postalCode: '74017',
  },
  'clarence-ia': {
    name: 'Clarence',
    postalCode: '52216',
  },
  'clarence-mo': {
    name: 'Clarence',
    postalCode: '63437',
  },
  'clarence-ny': {
    name: 'Clarence',
    postalCode: '14031',
  },
  'clarence-pa': {
    name: 'Clarence',
    postalCode: '16829',
  },
  'clarence-center-ny': {
    name: 'Clarence Center',
    postalCode: '14032',
  },
  'clarendon-ar': {
    name: 'Clarendon',
    postalCode: '72029',
  },
  'clarendon-nc': {
    name: 'Clarendon',
    postalCode: '28432',
  },
  'clarendon-pa': {
    name: 'Clarendon',
    postalCode: '16313',
  },
  'clarendon-tx': {
    name: 'Clarendon',
    postalCode: '79226',
  },
  'clarendon-hills-il': {
    name: 'Clarendon Hills',
    postalCode: '60514',
  },
  'claridge-pa': {
    name: 'Claridge',
    postalCode: '15623',
  },
  'clarinda-ia': {
    name: 'Clarinda',
    postalCode: '51632',
  },
  'clarington-oh': {
    name: 'Clarington',
    postalCode: '43915',
  },
  'clarington-pa': {
    name: 'Clarington',
    postalCode: '15828',
  },
  'clarion-ia': {
    name: 'Clarion',
    postalCode: '50525',
  },
  'clarion-pa': {
    name: 'Clarion',
    postalCode: '16214',
  },
  'clarissa-mn': {
    name: 'Clarissa',
    postalCode: '56440',
  },
  'clark-co': {
    name: 'Clark',
    postalCode: '80428',
  },
  'clark-mo': {
    name: 'Clark',
    postalCode: '65243',
  },
  'clark-nj': {
    name: 'Clark',
    postalCode: '07066',
  },
  'clark-sd': {
    name: 'Clark',
    postalCode: '57225',
  },
  'clark-fork-id': {
    name: 'Clark Fork',
    postalCode: '83811',
  },
  'clarkdale-az': {
    name: 'Clarkdale',
    postalCode: '86324',
  },
  'clarkesville-ga': {
    name: 'Clarkesville',
    postalCode: '30523',
  },
  'clarkfield-mn': {
    name: 'Clarkfield',
    postalCode: '56223',
  },
  'clarkia-id': {
    name: 'Clarkia',
    postalCode: '83812',
  },
  'clarklake-mi': {
    name: 'Clarklake',
    postalCode: '49234',
  },
  'clarkrange-tn': {
    name: 'Clarkrange',
    postalCode: '38553',
  },
  'clarkridge-ar': {
    name: 'Clarkridge',
    postalCode: '72623',
  },
  'clarks-ne': {
    name: 'Clarks',
    postalCode: '68628',
  },
  'clarks-grove-mn': {
    name: 'Clarks Grove',
    postalCode: '56016',
  },
  'clarks-hill-in': {
    name: 'Clarks Hill',
    postalCode: '47930',
  },
  'clarks-hill-sc': {
    name: 'Clarks Hill',
    postalCode: '29821',
  },
  'clarks-mills-pa': {
    name: 'Clarks Mills',
    postalCode: '16114',
  },
  'clarks-summit-pa': {
    name: 'Clarks Summit',
    postalCode: '18411',
  },
  'clarksboro-nj': {
    name: 'Clarksboro',
    postalCode: '08020',
  },
  'clarksburg-ca': {
    name: 'Clarksburg',
    postalCode: '95612',
  },
  'clarksburg-md': {
    name: 'Clarksburg',
    postalCode: '20871',
  },
  'clarksburg-mo': {
    name: 'Clarksburg',
    postalCode: '65025',
  },
  'clarksburg-oh': {
    name: 'Clarksburg',
    postalCode: '43115',
  },
  'clarksburg-pa': {
    name: 'Clarksburg',
    postalCode: '15725',
  },
  'clarksburg-wv': {
    name: 'Clarksburg',
    postalCode: '26301',
  },
  'clarksdale-mo': {
    name: 'Clarksdale',
    postalCode: '64430',
  },
  'clarksdale-ms': {
    name: 'Clarksdale',
    postalCode: '38614',
  },
  'clarkson-ky': {
    name: 'Clarkson',
    postalCode: '42726',
  },
  'clarkson-ne': {
    name: 'Clarkson',
    postalCode: '68629',
  },
  'clarkston-ga': {
    name: 'Clarkston',
    postalCode: '30021',
  },
  'clarkston-mi': {
    name: 'Clarkston',
    postalCode: '48346',
  },
  'clarkston-wa': {
    name: 'Clarkston',
    postalCode: '99403',
  },
  'clarksville-ar': {
    name: 'Clarksville',
    postalCode: '72830',
  },
  'clarksville-fl': {
    name: 'Clarksville',
    postalCode: '32430',
  },
  'clarksville-ia': {
    name: 'Clarksville',
    postalCode: '50619',
  },
  'clarksville-in': {
    name: 'Clarksville',
    postalCode: '47129',
  },
  'clarksville-md': {
    name: 'Clarksville',
    postalCode: '21029',
  },
  'clarksville-mi': {
    name: 'Clarksville',
    postalCode: '48815',
  },
  'clarksville-mo': {
    name: 'Clarksville',
    postalCode: '63336',
  },
  'clarksville-ny': {
    name: 'Clarksville',
    postalCode: '12041',
  },
  'clarksville-oh': {
    name: 'Clarksville',
    postalCode: '45113',
  },
  'clarksville-pa': {
    name: 'Clarksville',
    postalCode: '15322',
  },
  'clarksville-tn': {
    name: 'Clarksville',
    postalCode: '37040',
  },
  'clarksville-tx': {
    name: 'Clarksville',
    postalCode: '75426',
  },
  'clarksville-va': {
    name: 'Clarksville',
    postalCode: '23927',
  },
  'clarkton-mo': {
    name: 'Clarkton',
    postalCode: '63837',
  },
  'clarkton-nc': {
    name: 'Clarkton',
    postalCode: '28433',
  },
  'claryville-ny': {
    name: 'Claryville',
    postalCode: '12725',
  },
  'clatonia-ne': {
    name: 'Clatonia',
    postalCode: '68328',
  },
  'clatskanie-or': {
    name: 'Clatskanie',
    postalCode: '97016',
  },
  'claude-tx': {
    name: 'Claude',
    postalCode: '79019',
  },
  'claudville-va': {
    name: 'Claudville',
    postalCode: '24076',
  },
  'claverack-ny': {
    name: 'Claverack',
    postalCode: '12513',
  },
  'clawson-mi': {
    name: 'Clawson',
    postalCode: '48017',
  },
  'claxton-ga': {
    name: 'Claxton',
    postalCode: '30417',
  },
  'clay-ky': {
    name: 'Clay',
    postalCode: '42404',
  },
  'clay-ny': {
    name: 'Clay',
    postalCode: '13041',
  },
  'clay-wv': {
    name: 'Clay',
    postalCode: '25043',
  },
  'clay-center-ks': {
    name: 'Clay Center',
    postalCode: '67432',
  },
  'clay-center-ne': {
    name: 'Clay Center',
    postalCode: '68933',
  },
  'clay-city-il': {
    name: 'Clay City',
    postalCode: '62824',
  },
  'clay-city-in': {
    name: 'Clay City',
    postalCode: '47841',
  },
  'clay-city-ky': {
    name: 'Clay City',
    postalCode: '40312',
  },
  'clayhole-ky': {
    name: 'Clayhole',
    postalCode: '41317',
  },
  'claymont-de': {
    name: 'Claymont',
    postalCode: '19703',
  },
  'claypool-in': {
    name: 'Claypool',
    postalCode: '46510',
  },
  'claysburg-pa': {
    name: 'Claysburg',
    postalCode: '16625',
  },
  'claysville-pa': {
    name: 'Claysville',
    postalCode: '15323',
  },
  'clayton-al': {
    name: 'Clayton',
    postalCode: '36016',
  },
  'clayton-ca': {
    name: 'Clayton',
    postalCode: '94517',
  },
  'clayton-de': {
    name: 'Clayton',
    postalCode: '19938',
  },
  'clayton-ga': {
    name: 'Clayton',
    postalCode: '30525',
  },
  'clayton-id': {
    name: 'Clayton',
    postalCode: '83227',
  },
  'clayton-il': {
    name: 'Clayton',
    postalCode: '62324',
  },
  'clayton-in': {
    name: 'Clayton',
    postalCode: '46118',
  },
  'clayton-ks': {
    name: 'Clayton',
    postalCode: '67629',
  },
  'clayton-la': {
    name: 'Clayton',
    postalCode: '71326',
  },
  'clayton-mi': {
    name: 'Clayton',
    postalCode: '49235',
  },
  'clayton-nc': {
    name: 'Clayton',
    postalCode: '27520',
  },
  'clayton-nj': {
    name: 'Clayton',
    postalCode: '08312',
  },
  'clayton-nm': {
    name: 'Clayton',
    postalCode: '88415',
  },
  'clayton-ny': {
    name: 'Clayton',
    postalCode: '13624',
  },
  'clayton-oh': {
    name: 'Clayton',
    postalCode: '45315',
  },
  'clayton-ok': {
    name: 'Clayton',
    postalCode: '74536',
  },
  'clayton-wa': {
    name: 'Clayton',
    postalCode: '99110',
  },
  'clayton-wi': {
    name: 'Clayton',
    postalCode: '54004',
  },
  'clayville-ny': {
    name: 'Clayville',
    postalCode: '13322',
  },
  'clayville-ri': {
    name: 'Clayville',
    postalCode: '02815',
  },
  'cle-elum-wa': {
    name: 'Cle Elum',
    postalCode: '98922',
  },
  'clear-ak': {
    name: 'Clear',
    postalCode: '99704',
  },
  'clear-brook-va': {
    name: 'Clear Brook',
    postalCode: '22624',
  },
  'clear-creek-wv': {
    name: 'Clear Creek',
    postalCode: '25044',
  },
  'clear-fork-wv': {
    name: 'Clear Fork',
    postalCode: '24822',
  },
  'clear-lake-ia': {
    name: 'Clear Lake',
    postalCode: '50428',
  },
  'clear-lake-mn': {
    name: 'Clear Lake',
    postalCode: '55319',
  },
  'clear-lake-sd': {
    name: 'Clear Lake',
    postalCode: '57226',
  },
  'clear-lake-wi': {
    name: 'Clear Lake',
    postalCode: '54005',
  },
  'clear-spring-md': {
    name: 'Clear Spring',
    postalCode: '21722',
  },
  'clearbrook-mn': {
    name: 'Clearbrook',
    postalCode: '56634',
  },
  'clearfield-ia': {
    name: 'Clearfield',
    postalCode: '50840',
  },
  'clearfield-ky': {
    name: 'Clearfield',
    postalCode: '40313',
  },
  'clearfield-pa': {
    name: 'Clearfield',
    postalCode: '16830',
  },
  'clearfield-ut': {
    name: 'Clearfield',
    postalCode: '84015',
  },
  'clearlake-ca': {
    name: 'Clearlake',
    postalCode: '95422',
  },
  'clearlake-oaks-ca': {
    name: 'Clearlake Oaks',
    postalCode: '95423',
  },
  'clearmont-mo': {
    name: 'Clearmont',
    postalCode: '64431',
  },
  'clearmont-wy': {
    name: 'Clearmont',
    postalCode: '82835',
  },
  'clearville-pa': {
    name: 'Clearville',
    postalCode: '15535',
  },
  'clearwater-fl': {
    name: 'Clearwater',
    postalCode: '33755',
  },
  'clearwater-ks': {
    name: 'Clearwater',
    postalCode: '67026',
  },
  'clearwater-mn': {
    name: 'Clearwater',
    postalCode: '55320',
  },
  'clearwater-ne': {
    name: 'Clearwater',
    postalCode: '68726',
  },
  'clearwater-beach-fl': {
    name: 'Clearwater Beach',
    postalCode: '33767',
  },
  'cleburne-tx': {
    name: 'Cleburne',
    postalCode: '76031',
  },
  'cleghorn-ia': {
    name: 'Cleghorn',
    postalCode: '51014',
  },
  'clementon-nj': {
    name: 'Clementon',
    postalCode: '08021',
  },
  'clements-md': {
    name: 'Clements',
    postalCode: '20624',
  },
  'clements-mn': {
    name: 'Clements',
    postalCode: '56224',
  },
  'clemmons-nc': {
    name: 'Clemmons',
    postalCode: '27012',
  },
  'clemons-ia': {
    name: 'Clemons',
    postalCode: '50051',
  },
  'clemons-ny': {
    name: 'Clemons',
    postalCode: '12819',
  },
  'clemson-sc': {
    name: 'Clemson',
    postalCode: '29631',
  },
  'clendenin-wv': {
    name: 'Clendenin',
    postalCode: '25045',
  },
  'cleo-springs-ok': {
    name: 'Cleo Springs',
    postalCode: '73729',
  },
  'clermont-fl': {
    name: 'Clermont',
    postalCode: '34711',
  },
  'clermont-ga': {
    name: 'Clermont',
    postalCode: '30527',
  },
  'clermont-ia': {
    name: 'Clermont',
    postalCode: '52135',
  },
  'cleveland-al': {
    name: 'Cleveland',
    postalCode: '35049',
  },
  'cleveland-ar': {
    name: 'Cleveland',
    postalCode: '72030',
  },
  'cleveland-ga': {
    name: 'Cleveland',
    postalCode: '30528',
  },
  'cleveland-mn': {
    name: 'Cleveland',
    postalCode: '56017',
  },
  'cleveland-mo': {
    name: 'Cleveland',
    postalCode: '64734',
  },
  'cleveland-ms': {
    name: 'Cleveland',
    postalCode: '38732',
  },
  'cleveland-nc': {
    name: 'Cleveland',
    postalCode: '27013',
  },
  'cleveland-nd': {
    name: 'Cleveland',
    postalCode: '58424',
  },
  'cleveland-nm': {
    name: 'Cleveland',
    postalCode: '87715',
  },
  'cleveland-ny': {
    name: 'Cleveland',
    postalCode: '13042',
  },
  'cleveland-oh': {
    name: 'Cleveland',
    postalCode: '44102',
  },
  'cleveland-ok': {
    name: 'Cleveland',
    postalCode: '74020',
  },
  'cleveland-sc': {
    name: 'Cleveland',
    postalCode: '29635',
  },
  'cleveland-tn': {
    name: 'Cleveland',
    postalCode: '37311',
  },
  'cleveland-tx': {
    name: 'Cleveland',
    postalCode: '77327',
  },
  'cleveland-va': {
    name: 'Cleveland',
    postalCode: '24225',
  },
  'cleveland-wi': {
    name: 'Cleveland',
    postalCode: '53015',
  },
  'cleveland-wv': {
    name: 'Cleveland',
    postalCode: '26215',
  },
  'clever-mo': {
    name: 'Clever',
    postalCode: '65631',
  },
  'cleves-oh': {
    name: 'Cleves',
    postalCode: '45002',
  },
  'clewiston-fl': {
    name: 'Clewiston',
    postalCode: '33440',
  },
  'clifford-mi': {
    name: 'Clifford',
    postalCode: '48727',
  },
  'clifford-nd': {
    name: 'Clifford',
    postalCode: '58016',
  },
  'cliffside-park-nj': {
    name: 'Cliffside Park',
    postalCode: '07010',
  },
  'cliffwood-nj': {
    name: 'Cliffwood',
    postalCode: '07721',
  },
  'clifton-az': {
    name: 'Clifton',
    postalCode: '85533',
  },
  'clifton-co': {
    name: 'Clifton',
    postalCode: '81520',
  },
  'clifton-id': {
    name: 'Clifton',
    postalCode: '83228',
  },
  'clifton-il': {
    name: 'Clifton',
    postalCode: '60927',
  },
  'clifton-ks': {
    name: 'Clifton',
    postalCode: '66937',
  },
  'clifton-nj': {
    name: 'Clifton',
    postalCode: '07011',
  },
  'clifton-tn': {
    name: 'Clifton',
    postalCode: '38425',
  },
  'clifton-tx': {
    name: 'Clifton',
    postalCode: '76634',
  },
  'clifton-va': {
    name: 'Clifton',
    postalCode: '20124',
  },
  'clifton-forge-va': {
    name: 'Clifton Forge',
    postalCode: '24422',
  },
  'clifton-heights-pa': {
    name: 'Clifton Heights',
    postalCode: '19018',
  },
  'clifton-hill-mo': {
    name: 'Clifton Hill',
    postalCode: '65244',
  },
  'clifton-park-ny': {
    name: 'Clifton Park',
    postalCode: '12065',
  },
  'clifton-springs-ny': {
    name: 'Clifton Springs',
    postalCode: '14432',
  },
  'climax-ga': {
    name: 'Climax',
    postalCode: '39834',
  },
  'climax-mi': {
    name: 'Climax',
    postalCode: '49034',
  },
  'climax-mn': {
    name: 'Climax',
    postalCode: '56523',
  },
  'climax-nc': {
    name: 'Climax',
    postalCode: '27233',
  },
  'climax-ny': {
    name: 'Climax',
    postalCode: '12042',
  },
  'climax-springs-mo': {
    name: 'Climax Springs',
    postalCode: '65324',
  },
  'climbing-hill-ia': {
    name: 'Climbing Hill',
    postalCode: '51015',
  },
  'clinchco-va': {
    name: 'Clinchco',
    postalCode: '24226',
  },
  'clint-tx': {
    name: 'Clint',
    postalCode: '79836',
  },
  'clinton-ar': {
    name: 'Clinton',
    postalCode: '72031',
  },
  'clinton-ct': {
    name: 'Clinton',
    postalCode: '06413',
  },
  'clinton-ia': {
    name: 'Clinton',
    postalCode: '52732',
  },
  'clinton-il': {
    name: 'Clinton',
    postalCode: '61727',
  },
  'clinton-in': {
    name: 'Clinton',
    postalCode: '47842',
  },
  'clinton-ky': {
    name: 'Clinton',
    postalCode: '42031',
  },
  'clinton-la': {
    name: 'Clinton',
    postalCode: '70722',
  },
  'clinton-ma': {
    name: 'Clinton',
    postalCode: '01510',
  },
  'clinton-md': {
    name: 'Clinton',
    postalCode: '20735',
  },
  'clinton-me': {
    name: 'Clinton',
    postalCode: '04927',
  },
  'clinton-mi': {
    name: 'Clinton',
    postalCode: '49236',
  },
  'clinton-mn': {
    name: 'Clinton',
    postalCode: '56225',
  },
  'clinton-mo': {
    name: 'Clinton',
    postalCode: '64735',
  },
  'clinton-ms': {
    name: 'Clinton',
    postalCode: '39056',
  },
  'clinton-mt': {
    name: 'Clinton',
    postalCode: '59825',
  },
  'clinton-nc': {
    name: 'Clinton',
    postalCode: '28328',
  },
  'clinton-nj': {
    name: 'Clinton',
    postalCode: '08809',
  },
  'clinton-ny': {
    name: 'Clinton',
    postalCode: '13323',
  },
  'clinton-oh': {
    name: 'Clinton',
    postalCode: '44216',
  },
  'clinton-ok': {
    name: 'Clinton',
    postalCode: '73601',
  },
  'clinton-pa': {
    name: 'Clinton',
    postalCode: '15026',
  },
  'clinton-sc': {
    name: 'Clinton',
    postalCode: '29325',
  },
  'clinton-tn': {
    name: 'Clinton',
    postalCode: '37716',
  },
  'clinton-wa': {
    name: 'Clinton',
    postalCode: '98236',
  },
  'clinton-wi': {
    name: 'Clinton',
    postalCode: '53525',
  },
  'clinton-corners-ny': {
    name: 'Clinton Corners',
    postalCode: '12514',
  },
  'clinton-township-mi': {
    name: 'Clinton Township',
    postalCode: '48035',
  },
  'clintondale-ny': {
    name: 'Clintondale',
    postalCode: '12515',
  },
  'clintonville-wi': {
    name: 'Clintonville',
    postalCode: '54929',
  },
  'clintwood-va': {
    name: 'Clintwood',
    postalCode: '24228',
  },
  'clio-al': {
    name: 'Clio',
    postalCode: '36017',
  },
  'clio-ia': {
    name: 'Clio',
    postalCode: '50052',
  },
  'clio-mi': {
    name: 'Clio',
    postalCode: '48420',
  },
  'clio-sc': {
    name: 'Clio',
    postalCode: '29525',
  },
  'clipper-mills-ca': {
    name: 'Clipper Mills',
    postalCode: '95930',
  },
  'clitherall-mn': {
    name: 'Clitherall',
    postalCode: '56524',
  },
  'clive-ia': {
    name: 'Clive',
    postalCode: '50325',
  },
  'clontarf-mn': {
    name: 'Clontarf',
    postalCode: '56226',
  },
  'clopton-al': {
    name: 'Clopton',
    postalCode: '36317',
  },
  'cloquet-mn': {
    name: 'Cloquet',
    postalCode: '55720',
  },
  'closplint-ky': {
    name: 'Closplint',
    postalCode: '40927',
  },
  'closter-nj': {
    name: 'Closter',
    postalCode: '07624',
  },
  'clothier-wv': {
    name: 'Clothier',
    postalCode: '25047',
  },
  'cloudcroft-nm': {
    name: 'Cloudcroft',
    postalCode: '88317',
  },
  'cloutierville-la': {
    name: 'Cloutierville',
    postalCode: '71416',
  },
  'clover-sc': {
    name: 'Clover',
    postalCode: '29710',
  },
  'clover-va': {
    name: 'Clover',
    postalCode: '24534',
  },
  'cloverdale-ca': {
    name: 'Cloverdale',
    postalCode: '95425',
  },
  'cloverdale-in': {
    name: 'Cloverdale',
    postalCode: '46120',
  },
  'cloverdale-oh': {
    name: 'Cloverdale',
    postalCode: '45827',
  },
  'cloverdale-or': {
    name: 'Cloverdale',
    postalCode: '97112',
  },
  'cloverdale-va': {
    name: 'Cloverdale',
    postalCode: '24077',
  },
  'cloverport-ky': {
    name: 'Cloverport',
    postalCode: '40111',
  },
  'clovis-ca': {
    name: 'Clovis',
    postalCode: '93611',
  },
  'clovis-nm': {
    name: 'Clovis',
    postalCode: '88101',
  },
  'clubb-mo': {
    name: 'Clubb',
    postalCode: '63934',
  },
  'clute-tx': {
    name: 'Clute',
    postalCode: '77531',
  },
  'clutier-ia': {
    name: 'Clutier',
    postalCode: '52217',
  },
  'clyde-ks': {
    name: 'Clyde',
    postalCode: '66938',
  },
  'clyde-mo': {
    name: 'Clyde',
    postalCode: '64432',
  },
  'clyde-nc': {
    name: 'Clyde',
    postalCode: '28721',
  },
  'clyde-ny': {
    name: 'Clyde',
    postalCode: '14433',
  },
  'clyde-oh': {
    name: 'Clyde',
    postalCode: '43410',
  },
  'clyde-tx': {
    name: 'Clyde',
    postalCode: '79510',
  },
  'clymer-ny': {
    name: 'Clymer',
    postalCode: '14724',
  },
  'clymer-pa': {
    name: 'Clymer',
    postalCode: '15728',
  },
  'clyo-ga': {
    name: 'Clyo',
    postalCode: '31303',
  },
  'coachella-ca': {
    name: 'Coachella',
    postalCode: '92236',
  },
  'coahoma-ms': {
    name: 'Coahoma',
    postalCode: '38617',
  },
  'coahoma-tx': {
    name: 'Coahoma',
    postalCode: '79511',
  },
  'coal-center-pa': {
    name: 'Coal Center',
    postalCode: '15423',
  },
  'coal-city-il': {
    name: 'Coal City',
    postalCode: '60416',
  },
  'coal-city-in': {
    name: 'Coal City',
    postalCode: '47427',
  },
  'coal-city-wv': {
    name: 'Coal City',
    postalCode: '25823',
  },
  'coal-hill-ar': {
    name: 'Coal Hill',
    postalCode: '72832',
  },
  'coal-mountain-wv': {
    name: 'Coal Mountain',
    postalCode: '24823',
  },
  'coal-run-oh': {
    name: 'Coal Run',
    postalCode: '45721',
  },
  'coal-township-pa': {
    name: 'Coal Township',
    postalCode: '17866',
  },
  'coal-valley-il': {
    name: 'Coal Valley',
    postalCode: '61240',
  },
  'coaldale-pa': {
    name: 'Coaldale',
    postalCode: '18218',
  },
  'coalgate-ok': {
    name: 'Coalgate',
    postalCode: '74538',
  },
  'coalgood-ky': {
    name: 'Coalgood',
    postalCode: '40818',
  },
  'coalinga-ca': {
    name: 'Coalinga',
    postalCode: '93210',
  },
  'coalmont-co': {
    name: 'Coalmont',
    postalCode: '80430',
  },
  'coalmont-tn': {
    name: 'Coalmont',
    postalCode: '37313',
  },
  'coalport-pa': {
    name: 'Coalport',
    postalCode: '16627',
  },
  'coalton-wv': {
    name: 'Coalton',
    postalCode: '26257',
  },
  'coalville-ut': {
    name: 'Coalville',
    postalCode: '84017',
  },
  'coamo-pr': {
    name: 'Coamo',
    postalCode: '00769',
  },
  'coarsegold-ca': {
    name: 'Coarsegold',
    postalCode: '93614',
  },
  'coatesville-in': {
    name: 'Coatesville',
    postalCode: '46121',
  },
  'coatesville-pa': {
    name: 'Coatesville',
    postalCode: '19320',
  },
  'coats-ks': {
    name: 'Coats',
    postalCode: '67028',
  },
  'coats-nc': {
    name: 'Coats',
    postalCode: '27521',
  },
  'coatsburg-il': {
    name: 'Coatsburg',
    postalCode: '62325',
  },
  'coatsville-mo': {
    name: 'Coatsville',
    postalCode: '63535',
  },
  'cobalt-id': {
    name: 'Cobalt',
    postalCode: '83229',
  },
  'cobb-ga': {
    name: 'Cobb',
    postalCode: '31735',
  },
  'cobb-wi': {
    name: 'Cobb',
    postalCode: '53526',
  },
  'cobbs-creek-va': {
    name: 'Cobbs Creek',
    postalCode: '23035',
  },
  'cobbtown-ga': {
    name: 'Cobbtown',
    postalCode: '30420',
  },
  'cobden-il': {
    name: 'Cobden',
    postalCode: '62920',
  },
  'cobleskill-ny': {
    name: 'Cobleskill',
    postalCode: '12043',
  },
  'coburn-pa': {
    name: 'Coburn',
    postalCode: '16832',
  },
  'cochecton-ny': {
    name: 'Cochecton',
    postalCode: '12726',
  },
  'cochise-az': {
    name: 'Cochise',
    postalCode: '85606',
  },
  'cochiti-lake-nm': {
    name: 'Cochiti Lake',
    postalCode: '87083',
  },
  'cochran-ga': {
    name: 'Cochran',
    postalCode: '31014',
  },
  'cochrane-wi': {
    name: 'Cochrane',
    postalCode: '54622',
  },
  'cochranton-pa': {
    name: 'Cochranton',
    postalCode: '16314',
  },
  'cochranville-pa': {
    name: 'Cochranville',
    postalCode: '19330',
  },
  'cockeysville-md': {
    name: 'Cockeysville',
    postalCode: '21030',
  },
  'cocoa-fl': {
    name: 'Cocoa',
    postalCode: '32922',
  },
  'cocoa-beach-fl': {
    name: 'Cocoa Beach',
    postalCode: '32931',
  },
  'cocolalla-id': {
    name: 'Cocolalla',
    postalCode: '83813',
  },
  'cocolamus-pa': {
    name: 'Cocolamus',
    postalCode: '17014',
  },
  'coden-al': {
    name: 'Coden',
    postalCode: '36523',
  },
  'cody-ne': {
    name: 'Cody',
    postalCode: '69211',
  },
  'cody-wy': {
    name: 'Cody',
    postalCode: '82414',
  },
  'coeburn-va': {
    name: 'Coeburn',
    postalCode: '24230',
  },
  'coeur-d-alene-id': {
    name: 'Coeur D Alene',
    postalCode: '83814',
  },
  'coeymans-hollow-ny': {
    name: 'Coeymans Hollow',
    postalCode: '12046',
  },
  'coffee-creek-mt': {
    name: 'Coffee Creek',
    postalCode: '59424',
  },
  'coffee-springs-al': {
    name: 'Coffee Springs',
    postalCode: '36318',
  },
  'coffeen-il': {
    name: 'Coffeen',
    postalCode: '62017',
  },
  'coffeeville-al': {
    name: 'Coffeeville',
    postalCode: '36524',
  },
  'coffeeville-ms': {
    name: 'Coffeeville',
    postalCode: '38922',
  },
  'coffey-mo': {
    name: 'Coffey',
    postalCode: '64636',
  },
  'coffeyville-ks': {
    name: 'Coffeyville',
    postalCode: '67337',
  },
  'cofield-nc': {
    name: 'Cofield',
    postalCode: '27922',
  },
  'cogan-station-pa': {
    name: 'Cogan Station',
    postalCode: '17728',
  },
  'coggon-ia': {
    name: 'Coggon',
    postalCode: '52218',
  },
  'cogswell-nd': {
    name: 'Cogswell',
    postalCode: '58017',
  },
  'cohagen-mt': {
    name: 'Cohagen',
    postalCode: '59322',
  },
  'cohasset-ma': {
    name: 'Cohasset',
    postalCode: '02025',
  },
  'cohasset-mn': {
    name: 'Cohasset',
    postalCode: '55721',
  },
  'cohocton-ny': {
    name: 'Cohocton',
    postalCode: '14826',
  },
  'cohoes-ny': {
    name: 'Cohoes',
    postalCode: '12047',
  },
  'cohutta-ga': {
    name: 'Cohutta',
    postalCode: '30710',
  },
  'coila-ms': {
    name: 'Coila',
    postalCode: '38923',
  },
  'coin-ia': {
    name: 'Coin',
    postalCode: '51636',
  },
  'coinjock-nc': {
    name: 'Coinjock',
    postalCode: '27923',
  },
  'cokato-mn': {
    name: 'Cokato',
    postalCode: '55321',
  },
  'coker-al': {
    name: 'Coker',
    postalCode: '35452',
  },
  'cokeville-wy': {
    name: 'Cokeville',
    postalCode: '83114',
  },
  'colbert-ga': {
    name: 'Colbert',
    postalCode: '30628',
  },
  'colbert-ok': {
    name: 'Colbert',
    postalCode: '74733',
  },
  'colbert-wa': {
    name: 'Colbert',
    postalCode: '99005',
  },
  'colby-ks': {
    name: 'Colby',
    postalCode: '67701',
  },
  'colby-wi': {
    name: 'Colby',
    postalCode: '54421',
  },
  'colchester-ct': {
    name: 'Colchester',
    postalCode: '06415',
  },
  'colchester-il': {
    name: 'Colchester',
    postalCode: '62326',
  },
  'colchester-vt': {
    name: 'Colchester',
    postalCode: '05446',
  },
  'colcord-ok': {
    name: 'Colcord',
    postalCode: '74338',
  },
  'colcord-wv': {
    name: 'Colcord',
    postalCode: '25048',
  },
  'cold-brook-ny': {
    name: 'Cold Brook',
    postalCode: '13324',
  },
  'cold-spring-mn': {
    name: 'Cold Spring',
    postalCode: '56320',
  },
  'cold-spring-ny': {
    name: 'Cold Spring',
    postalCode: '10516',
  },
  'cold-spring-harbor-ny': {
    name: 'Cold Spring Harbor',
    postalCode: '11724',
  },
  'colden-ny': {
    name: 'Colden',
    postalCode: '14033',
  },
  'coldiron-ky': {
    name: 'Coldiron',
    postalCode: '40819',
  },
  'coldspring-tx': {
    name: 'Coldspring',
    postalCode: '77331',
  },
  'coldwater-ks': {
    name: 'Coldwater',
    postalCode: '67029',
  },
  'coldwater-mi': {
    name: 'Coldwater',
    postalCode: '49036',
  },
  'coldwater-ms': {
    name: 'Coldwater',
    postalCode: '38618',
  },
  'coldwater-oh': {
    name: 'Coldwater',
    postalCode: '45828',
  },
  'cole-camp-mo': {
    name: 'Cole Camp',
    postalCode: '65325',
  },
  'colebrook-ct': {
    name: 'Colebrook',
    postalCode: '06021',
  },
  'colebrook-nh': {
    name: 'Colebrook',
    postalCode: '03576',
  },
  'coleharbor-nd': {
    name: 'Coleharbor',
    postalCode: '58531',
  },
  'coleman-ga': {
    name: 'Coleman',
    postalCode: '39836',
  },
  'coleman-mi': {
    name: 'Coleman',
    postalCode: '48618',
  },
  'coleman-ok': {
    name: 'Coleman',
    postalCode: '73432',
  },
  'coleman-tx': {
    name: 'Coleman',
    postalCode: '76834',
  },
  'coleman-wi': {
    name: 'Coleman',
    postalCode: '54112',
  },
  'coleman-falls-va': {
    name: 'Coleman Falls',
    postalCode: '24536',
  },
  'colerain-nc': {
    name: 'Colerain',
    postalCode: '27924',
  },
  'coleridge-ne': {
    name: 'Coleridge',
    postalCode: '68727',
  },
  'colesburg-ia': {
    name: 'Colesburg',
    postalCode: '52035',
  },
  'coleville-ca': {
    name: 'Coleville',
    postalCode: '96107',
  },
  'colfax-ca': {
    name: 'Colfax',
    postalCode: '95713',
  },
  'colfax-ia': {
    name: 'Colfax',
    postalCode: '50054',
  },
  'colfax-il': {
    name: 'Colfax',
    postalCode: '61728',
  },
  'colfax-in': {
    name: 'Colfax',
    postalCode: '46035',
  },
  'colfax-la': {
    name: 'Colfax',
    postalCode: '71417',
  },
  'colfax-nc': {
    name: 'Colfax',
    postalCode: '27235',
  },
  'colfax-nd': {
    name: 'Colfax',
    postalCode: '58018',
  },
  'colfax-wa': {
    name: 'Colfax',
    postalCode: '99111',
  },
  'colfax-wi': {
    name: 'Colfax',
    postalCode: '54730',
  },
  'colgate-wi': {
    name: 'Colgate',
    postalCode: '53017',
  },
  'collbran-co': {
    name: 'Collbran',
    postalCode: '81624',
  },
  'college-corner-oh': {
    name: 'College Corner',
    postalCode: '45003',
  },
  'college-grove-tn': {
    name: 'College Grove',
    postalCode: '37046',
  },
  'college-park-md': {
    name: 'College Park',
    postalCode: '20740',
  },
  'college-place-wa': {
    name: 'College Place',
    postalCode: '99324',
  },
  'college-point-ny': {
    name: 'College Point',
    postalCode: '11356',
  },
  'college-station-tx': {
    name: 'College Station',
    postalCode: '77840',
  },
  'collegeville-pa': {
    name: 'Collegeville',
    postalCode: '19426',
  },
  'collettsville-nc': {
    name: 'Collettsville',
    postalCode: '28611',
  },
  'colleyville-tx': {
    name: 'Colleyville',
    postalCode: '76034',
  },
  'colliers-wv': {
    name: 'Colliers',
    postalCode: '26035',
  },
  'collierville-tn': {
    name: 'Collierville',
    postalCode: '38017',
  },
  'collingswood-nj': {
    name: 'Collingswood',
    postalCode: '08108',
  },
  'collins-ga': {
    name: 'Collins',
    postalCode: '30421',
  },
  'collins-ia': {
    name: 'Collins',
    postalCode: '50055',
  },
  'collins-mo': {
    name: 'Collins',
    postalCode: '64738',
  },
  'collins-ms': {
    name: 'Collins',
    postalCode: '39428',
  },
  'collins-ny': {
    name: 'Collins',
    postalCode: '14034',
  },
  'collins-oh': {
    name: 'Collins',
    postalCode: '44826',
  },
  'collinston-la': {
    name: 'Collinston',
    postalCode: '71229',
  },
  'collinston-ut': {
    name: 'Collinston',
    postalCode: '84306',
  },
  'collinsville-al': {
    name: 'Collinsville',
    postalCode: '35961',
  },
  'collinsville-il': {
    name: 'Collinsville',
    postalCode: '62234',
  },
  'collinsville-ms': {
    name: 'Collinsville',
    postalCode: '39325',
  },
  'collinsville-ok': {
    name: 'Collinsville',
    postalCode: '74021',
  },
  'collinsville-tx': {
    name: 'Collinsville',
    postalCode: '76233',
  },
  'collinsville-va': {
    name: 'Collinsville',
    postalCode: '24078',
  },
  'collinwood-tn': {
    name: 'Collinwood',
    postalCode: '38450',
  },
  'collison-il': {
    name: 'Collison',
    postalCode: '61831',
  },
  'collyer-ks': {
    name: 'Collyer',
    postalCode: '67631',
  },
  'colman-sd': {
    name: 'Colman',
    postalCode: '57017',
  },
  'colmar-pa': {
    name: 'Colmar',
    postalCode: '18915',
  },
  'colmesneil-tx': {
    name: 'Colmesneil',
    postalCode: '75938',
  },
  'colo-ia': {
    name: 'Colo',
    postalCode: '50056',
  },
  'cologne-mn': {
    name: 'Cologne',
    postalCode: '55322',
  },
  'coloma-mi': {
    name: 'Coloma',
    postalCode: '49038',
  },
  'coloma-wi': {
    name: 'Coloma',
    postalCode: '54930',
  },
  'colome-sd': {
    name: 'Colome',
    postalCode: '57528',
  },
  'colon-mi': {
    name: 'Colon',
    postalCode: '49040',
  },
  'colon-ne': {
    name: 'Colon',
    postalCode: '68018',
  },
  'colona-il': {
    name: 'Colona',
    postalCode: '61241',
  },
  'colonia-nj': {
    name: 'Colonia',
    postalCode: '07067',
  },
  'colonial-beach-va': {
    name: 'Colonial Beach',
    postalCode: '22443',
  },
  'colonial-heights-va': {
    name: 'Colonial Heights',
    postalCode: '23834',
  },
  'colony-ks': {
    name: 'Colony',
    postalCode: '66015',
  },
  'colony-ok': {
    name: 'Colony',
    postalCode: '73021',
  },
  'colora-md': {
    name: 'Colora',
    postalCode: '21917',
  },
  'colorado-city-tx': {
    name: 'Colorado City',
    postalCode: '79512',
  },
  'colorado-springs-co': {
    name: 'Colorado Springs',
    postalCode: '80902',
  },
  'colquitt-ga': {
    name: 'Colquitt',
    postalCode: '39837',
  },
  'colrain-ma': {
    name: 'Colrain',
    postalCode: '01340',
  },
  'colt-ar': {
    name: 'Colt',
    postalCode: '72326',
  },
  'colton-ca': {
    name: 'Colton',
    postalCode: '92324',
  },
  'colton-ny': {
    name: 'Colton',
    postalCode: '13625',
  },
  'colton-or': {
    name: 'Colton',
    postalCode: '97017',
  },
  'colton-sd': {
    name: 'Colton',
    postalCode: '57018',
  },
  'colton-wa': {
    name: 'Colton',
    postalCode: '99113',
  },
  'coltons-point-md': {
    name: 'Coltons Point',
    postalCode: '20626',
  },
  'colts-neck-nj': {
    name: 'Colts Neck',
    postalCode: '07722',
  },
  'columbia-al': {
    name: 'Columbia',
    postalCode: '36319',
  },
  'columbia-ca': {
    name: 'Columbia',
    postalCode: '95310',
  },
  'columbia-ct': {
    name: 'Columbia',
    postalCode: '06237',
  },
  'columbia-ia': {
    name: 'Columbia',
    postalCode: '50057',
  },
  'columbia-il': {
    name: 'Columbia',
    postalCode: '62236',
  },
  'columbia-ky': {
    name: 'Columbia',
    postalCode: '42728',
  },
  'columbia-la': {
    name: 'Columbia',
    postalCode: '71418',
  },
  'columbia-md': {
    name: 'Columbia',
    postalCode: '21044',
  },
  'columbia-mo': {
    name: 'Columbia',
    postalCode: '65201',
  },
  'columbia-ms': {
    name: 'Columbia',
    postalCode: '39429',
  },
  'columbia-nc': {
    name: 'Columbia',
    postalCode: '27925',
  },
  'columbia-nj': {
    name: 'Columbia',
    postalCode: '07832',
  },
  'columbia-pa': {
    name: 'Columbia',
    postalCode: '17512',
  },
  'columbia-sc': {
    name: 'Columbia',
    postalCode: '29201',
  },
  'columbia-sd': {
    name: 'Columbia',
    postalCode: '57433',
  },
  'columbia-tn': {
    name: 'Columbia',
    postalCode: '38401',
  },
  'columbia-va': {
    name: 'Columbia',
    postalCode: '23038',
  },
  'columbia-city-in': {
    name: 'Columbia City',
    postalCode: '46725',
  },
  'columbia-city-or': {
    name: 'Columbia City',
    postalCode: '97018',
  },
  'columbia-cross-roads-pa': {
    name: 'Columbia Cross Roads',
    postalCode: '16914',
  },
  'columbia-falls-me': {
    name: 'Columbia Falls',
    postalCode: '04623',
  },
  'columbia-falls-mt': {
    name: 'Columbia Falls',
    postalCode: '59912',
  },
  'columbia-station-oh': {
    name: 'Columbia Station',
    postalCode: '44028',
  },
  'columbiana-al': {
    name: 'Columbiana',
    postalCode: '35051',
  },
  'columbiana-oh': {
    name: 'Columbiana',
    postalCode: '44408',
  },
  'columbiaville-mi': {
    name: 'Columbiaville',
    postalCode: '48421',
  },
  'columbus-ar': {
    name: 'Columbus',
    postalCode: '71831',
  },
  'columbus-ga': {
    name: 'Columbus',
    postalCode: '31901',
  },
  'columbus-in': {
    name: 'Columbus',
    postalCode: '47201',
  },
  'columbus-ks': {
    name: 'Columbus',
    postalCode: '66725',
  },
  'columbus-ky': {
    name: 'Columbus',
    postalCode: '42032',
  },
  'columbus-mi': {
    name: 'Columbus',
    postalCode: '48063',
  },
  'columbus-ms': {
    name: 'Columbus',
    postalCode: '39701',
  },
  'columbus-mt': {
    name: 'Columbus',
    postalCode: '59019',
  },
  'columbus-nc': {
    name: 'Columbus',
    postalCode: '28722',
  },
  'columbus-nd': {
    name: 'Columbus',
    postalCode: '58727',
  },
  'columbus-ne': {
    name: 'Columbus',
    postalCode: '68601',
  },
  'columbus-nj': {
    name: 'Columbus',
    postalCode: '08022',
  },
  'columbus-oh': {
    name: 'Columbus',
    postalCode: '43085',
  },
  'columbus-pa': {
    name: 'Columbus',
    postalCode: '16405',
  },
  'columbus-tx': {
    name: 'Columbus',
    postalCode: '78934',
  },
  'columbus-wi': {
    name: 'Columbus',
    postalCode: '53925',
  },
  'columbus-city-ia': {
    name: 'Columbus City',
    postalCode: '52737',
  },
  'columbus-grove-oh': {
    name: 'Columbus Grove',
    postalCode: '45830',
  },
  'columbus-junction-ia': {
    name: 'Columbus Junction',
    postalCode: '52738',
  },
  'colusa-ca': {
    name: 'Colusa',
    postalCode: '95932',
  },
  'colver-pa': {
    name: 'Colver',
    postalCode: '15927',
  },
  'colville-wa': {
    name: 'Colville',
    postalCode: '99114',
  },
  'colwell-ia': {
    name: 'Colwell',
    postalCode: '50620',
  },
  'colwich-ks': {
    name: 'Colwich',
    postalCode: '67030',
  },
  'comanche-ok': {
    name: 'Comanche',
    postalCode: '73529',
  },
  'comanche-tx': {
    name: 'Comanche',
    postalCode: '76442',
  },
  'combined-locks-wi': {
    name: 'Combined Locks',
    postalCode: '54113',
  },
  'combs-ar': {
    name: 'Combs',
    postalCode: '72721',
  },
  'combs-ky': {
    name: 'Combs',
    postalCode: '41729',
  },
  'comer-ga': {
    name: 'Comer',
    postalCode: '30629',
  },
  'comerio-pr': {
    name: 'Comerio',
    postalCode: '00782',
  },
  'comfort-tx': {
    name: 'Comfort',
    postalCode: '78013',
  },
  'comfort-wv': {
    name: 'Comfort',
    postalCode: '25049',
  },
  'comfrey-mn': {
    name: 'Comfrey',
    postalCode: '56019',
  },
  'comins-mi': {
    name: 'Comins',
    postalCode: '48619',
  },
  'commack-ny': {
    name: 'Commack',
    postalCode: '11725',
  },
  'commerce-ga': {
    name: 'Commerce',
    postalCode: '30529',
  },
  'commerce-ok': {
    name: 'Commerce',
    postalCode: '74339',
  },
  'commerce-tx': {
    name: 'Commerce',
    postalCode: '75428',
  },
  'commerce-city-co': {
    name: 'Commerce City',
    postalCode: '80022',
  },
  'commerce-township-mi': {
    name: 'Commerce Township',
    postalCode: '48382',
  },
  'commercial-point-oh': {
    name: 'Commercial Point',
    postalCode: '43116',
  },
  'commiskey-in': {
    name: 'Commiskey',
    postalCode: '47227',
  },
  'commodore-pa': {
    name: 'Commodore',
    postalCode: '15729',
  },
  'como-ms': {
    name: 'Como',
    postalCode: '38619',
  },
  'como-nc': {
    name: 'Como',
    postalCode: '27818',
  },
  'como-tx': {
    name: 'Como',
    postalCode: '75431',
  },
  'comptche-ca': {
    name: 'Comptche',
    postalCode: '95427',
  },
  'compton-ar': {
    name: 'Compton',
    postalCode: '72624',
  },
  'compton-ca': {
    name: 'Compton',
    postalCode: '90220',
  },
  'compton-il': {
    name: 'Compton',
    postalCode: '61318',
  },
  'comstock-ne': {
    name: 'Comstock',
    postalCode: '68828',
  },
  'comstock-ny': {
    name: 'Comstock',
    postalCode: '12821',
  },
  'comstock-tx': {
    name: 'Comstock',
    postalCode: '78837',
  },
  'comstock-wi': {
    name: 'Comstock',
    postalCode: '54826',
  },
  'comstock-park-mi': {
    name: 'Comstock Park',
    postalCode: '49321',
  },
  'concan-tx': {
    name: 'Concan',
    postalCode: '78838',
  },
  'concepcion-tx': {
    name: 'Concepcion',
    postalCode: '78349',
  },
  'conception-mo': {
    name: 'Conception',
    postalCode: '64433',
  },
  'conception-junction-mo': {
    name: 'Conception Junction',
    postalCode: '64434',
  },
  'concho-az': {
    name: 'Concho',
    postalCode: '85924',
  },
  'concord-ar': {
    name: 'Concord',
    postalCode: '72523',
  },
  'concord-ca': {
    name: 'Concord',
    postalCode: '94518',
  },
  'concord-ga': {
    name: 'Concord',
    postalCode: '30206',
  },
  'concord-il': {
    name: 'Concord',
    postalCode: '62631',
  },
  'concord-ma': {
    name: 'Concord',
    postalCode: '01742',
  },
  'concord-mi': {
    name: 'Concord',
    postalCode: '49237',
  },
  'concord-nc': {
    name: 'Concord',
    postalCode: '28025',
  },
  'concord-ne': {
    name: 'Concord',
    postalCode: '68728',
  },
  'concord-nh': {
    name: 'Concord',
    postalCode: '03301',
  },
  'concord-pa': {
    name: 'Concord',
    postalCode: '17217',
  },
  'concord-va': {
    name: 'Concord',
    postalCode: '24538',
  },
  'concord-vt': {
    name: 'Concord',
    postalCode: '05824',
  },
  'concordia-ks': {
    name: 'Concordia',
    postalCode: '66901',
  },
  'concordia-mo': {
    name: 'Concordia',
    postalCode: '64020',
  },
  'concrete-wa': {
    name: 'Concrete',
    postalCode: '98237',
  },
  'conda-id': {
    name: 'Conda',
    postalCode: '83230',
  },
  'conde-sd': {
    name: 'Conde',
    postalCode: '57434',
  },
  'condon-mt': {
    name: 'Condon',
    postalCode: '59826',
  },
  'condon-or': {
    name: 'Condon',
    postalCode: '97823',
  },
  'conehatta-ms': {
    name: 'Conehatta',
    postalCode: '39057',
  },
  'conestoga-pa': {
    name: 'Conestoga',
    postalCode: '17516',
  },
  'conesus-ny': {
    name: 'Conesus',
    postalCode: '14435',
  },
  'conesville-ia': {
    name: 'Conesville',
    postalCode: '52739',
  },
  'conesville-oh': {
    name: 'Conesville',
    postalCode: '43811',
  },
  'conewango-valley-ny': {
    name: 'Conewango Valley',
    postalCode: '14726',
  },
  'confluence-pa': {
    name: 'Confluence',
    postalCode: '15424',
  },
  'congers-ny': {
    name: 'Congers',
    postalCode: '10920',
  },
  'congerville-il': {
    name: 'Congerville',
    postalCode: '61729',
  },
  'conifer-co': {
    name: 'Conifer',
    postalCode: '80433',
  },
  'conklin-mi': {
    name: 'Conklin',
    postalCode: '49403',
  },
  'conklin-ny': {
    name: 'Conklin',
    postalCode: '13748',
  },
  'conley-ga': {
    name: 'Conley',
    postalCode: '30288',
  },
  'conneaut-oh': {
    name: 'Conneaut',
    postalCode: '44030',
  },
  'conneaut-lake-pa': {
    name: 'Conneaut Lake',
    postalCode: '16316',
  },
  'conneautville-pa': {
    name: 'Conneautville',
    postalCode: '16406',
  },
  'connell-wa': {
    name: 'Connell',
    postalCode: '99326',
  },
  'connellsville-pa': {
    name: 'Connellsville',
    postalCode: '15425',
  },
  'connelly-springs-nc': {
    name: 'Connelly Springs',
    postalCode: '28612',
  },
  'conner-mt': {
    name: 'Conner',
    postalCode: '59827',
  },
  'connersville-in': {
    name: 'Connersville',
    postalCode: '47331',
  },
  'conover-nc': {
    name: 'Conover',
    postalCode: '28613',
  },
  'conover-oh': {
    name: 'Conover',
    postalCode: '45317',
  },
  'conover-wi': {
    name: 'Conover',
    postalCode: '54519',
  },
  'conowingo-md': {
    name: 'Conowingo',
    postalCode: '21918',
  },
  'conrad-ia': {
    name: 'Conrad',
    postalCode: '50621',
  },
  'conrad-mt': {
    name: 'Conrad',
    postalCode: '59425',
  },
  'conrath-wi': {
    name: 'Conrath',
    postalCode: '54731',
  },
  'conroe-tx': {
    name: 'Conroe',
    postalCode: '77301',
  },
  'conshohocken-pa': {
    name: 'Conshohocken',
    postalCode: '19428',
  },
  'constable-ny': {
    name: 'Constable',
    postalCode: '12926',
  },
  'constableville-ny': {
    name: 'Constableville',
    postalCode: '13325',
  },
  'constantia-ny': {
    name: 'Constantia',
    postalCode: '13044',
  },
  'constantine-mi': {
    name: 'Constantine',
    postalCode: '49042',
  },
  'continental-oh': {
    name: 'Continental',
    postalCode: '45831',
  },
  'continental-divide-nm': {
    name: 'Continental Divide',
    postalCode: '87312',
  },
  'contoocook-nh': {
    name: 'Contoocook',
    postalCode: '03229',
  },
  'convent-la': {
    name: 'Convent',
    postalCode: '70723',
  },
  'converse-in': {
    name: 'Converse',
    postalCode: '46919',
  },
  'converse-la': {
    name: 'Converse',
    postalCode: '71419',
  },
  'converse-tx': {
    name: 'Converse',
    postalCode: '78109',
  },
  'convoy-oh': {
    name: 'Convoy',
    postalCode: '45832',
  },
  'conway-ar': {
    name: 'Conway',
    postalCode: '72032',
  },
  'conway-ma': {
    name: 'Conway',
    postalCode: '01341',
  },
  'conway-mo': {
    name: 'Conway',
    postalCode: '65632',
  },
  'conway-nc': {
    name: 'Conway',
    postalCode: '27820',
  },
  'conway-nh': {
    name: 'Conway',
    postalCode: '03818',
  },
  'conway-pa': {
    name: 'Conway',
    postalCode: '15027',
  },
  'conway-sc': {
    name: 'Conway',
    postalCode: '29526',
  },
  'conway-springs-ks': {
    name: 'Conway Springs',
    postalCode: '67031',
  },
  'conyers-ga': {
    name: 'Conyers',
    postalCode: '30012',
  },
  'cook-mn': {
    name: 'Cook',
    postalCode: '55723',
  },
  'cook-ne': {
    name: 'Cook',
    postalCode: '68329',
  },
  'cook-sta-mo': {
    name: 'Cook Sta',
    postalCode: '65449',
  },
  'cookeville-tn': {
    name: 'Cookeville',
    postalCode: '38501',
  },
  'cooks-mi': {
    name: 'Cooks',
    postalCode: '49817',
  },
  'cooksburg-pa': {
    name: 'Cooksburg',
    postalCode: '16217',
  },
  'cookson-ok': {
    name: 'Cookson',
    postalCode: '74427',
  },
  'cookstown-nj': {
    name: 'Cookstown',
    postalCode: '08511',
  },
  'cooksville-il': {
    name: 'Cooksville',
    postalCode: '61730',
  },
  'cooksville-md': {
    name: 'Cooksville',
    postalCode: '21723',
  },
  'cookville-tx': {
    name: 'Cookville',
    postalCode: '75558',
  },
  'cool-ca': {
    name: 'Cool',
    postalCode: '95614',
  },
  'cool-ridge-wv': {
    name: 'Cool Ridge',
    postalCode: '25825',
  },
  'coolidge-az': {
    name: 'Coolidge',
    postalCode: '85128',
  },
  'coolidge-ga': {
    name: 'Coolidge',
    postalCode: '31738',
  },
  'coolidge-tx': {
    name: 'Coolidge',
    postalCode: '76635',
  },
  'coolin-id': {
    name: 'Coolin',
    postalCode: '83821',
  },
  'coolspring-pa': {
    name: 'Coolspring',
    postalCode: '15730',
  },
  'coolville-oh': {
    name: 'Coolville',
    postalCode: '45723',
  },
  'coon-rapids-ia': {
    name: 'Coon Rapids',
    postalCode: '50058',
  },
  'coon-valley-wi': {
    name: 'Coon Valley',
    postalCode: '54623',
  },
  'cooper-ia': {
    name: 'Cooper',
    postalCode: '50059',
  },
  'cooper-tx': {
    name: 'Cooper',
    postalCode: '75432',
  },
  'cooper-landing-ak': {
    name: 'Cooper Landing',
    postalCode: '99572',
  },
  'coopersburg-pa': {
    name: 'Coopersburg',
    postalCode: '18036',
  },
  'cooperstown-nd': {
    name: 'Cooperstown',
    postalCode: '58425',
  },
  'cooperstown-ny': {
    name: 'Cooperstown',
    postalCode: '13326',
  },
  'cooperstown-pa': {
    name: 'Cooperstown',
    postalCode: '16317',
  },
  'coopersville-mi': {
    name: 'Coopersville',
    postalCode: '49404',
  },
  'coos-bay-or': {
    name: 'Coos Bay',
    postalCode: '97420',
  },
  'coosada-al': {
    name: 'Coosada',
    postalCode: '36020',
  },
  'coosawhatchie-sc': {
    name: 'Coosawhatchie',
    postalCode: '29912',
  },
  'copake-ny': {
    name: 'Copake',
    postalCode: '12516',
  },
  'copake-falls-ny': {
    name: 'Copake Falls',
    postalCode: '12517',
  },
  'copalis-beach-wa': {
    name: 'Copalis Beach',
    postalCode: '98535',
  },
  'copalis-crossing-wa': {
    name: 'Copalis Crossing',
    postalCode: '98536',
  },
  'copan-ok': {
    name: 'Copan',
    postalCode: '74022',
  },
  'cope-co': {
    name: 'Cope',
    postalCode: '80812',
  },
  'cope-sc': {
    name: 'Cope',
    postalCode: '29038',
  },
  'copeland-ks': {
    name: 'Copeland',
    postalCode: '67837',
  },
  'copemish-mi': {
    name: 'Copemish',
    postalCode: '49625',
  },
  'copen-wv': {
    name: 'Copen',
    postalCode: '26615',
  },
  'copenhagen-ny': {
    name: 'Copenhagen',
    postalCode: '13626',
  },
  'copiague-ny': {
    name: 'Copiague',
    postalCode: '11726',
  },
  'coplay-pa': {
    name: 'Coplay',
    postalCode: '18037',
  },
  'coppell-tx': {
    name: 'Coppell',
    postalCode: '75019',
  },
  'copper-center-ak': {
    name: 'Copper Center',
    postalCode: '99573',
  },
  'copper-hill-va': {
    name: 'Copper Hill',
    postalCode: '24079',
  },
  'copperas-cove-tx': {
    name: 'Copperas Cove',
    postalCode: '76522',
  },
  'copperhill-tn': {
    name: 'Copperhill',
    postalCode: '37317',
  },
  'copperopolis-ca': {
    name: 'Copperopolis',
    postalCode: '95228',
  },
  'coquille-or': {
    name: 'Coquille',
    postalCode: '97423',
  },
  'cora-wv': {
    name: 'Cora',
    postalCode: '25614',
  },
  'cora-wy': {
    name: 'Cora',
    postalCode: '82925',
  },
  'coral-mi': {
    name: 'Coral',
    postalCode: '49322',
  },
  'coral-springs-fl': {
    name: 'Coral Springs',
    postalCode: '33065',
  },
  'coralville-ia': {
    name: 'Coralville',
    postalCode: '52241',
  },
  'coram-ny': {
    name: 'Coram',
    postalCode: '11727',
  },
  'coraopolis-pa': {
    name: 'Coraopolis',
    postalCode: '15108',
  },
  'corapeake-nc': {
    name: 'Corapeake',
    postalCode: '27926',
  },
  'corbett-or': {
    name: 'Corbett',
    postalCode: '97019',
  },
  'corbin-ky': {
    name: 'Corbin',
    postalCode: '40701',
  },
  'corcoran-ca': {
    name: 'Corcoran',
    postalCode: '93212',
  },
  'cord-ar': {
    name: 'Cord',
    postalCode: '72524',
  },
  'cordele-ga': {
    name: 'Cordele',
    postalCode: '31015',
  },
  'cordell-ok': {
    name: 'Cordell',
    postalCode: '73632',
  },
  'corder-mo': {
    name: 'Corder',
    postalCode: '64021',
  },
  'cordesville-sc': {
    name: 'Cordesville',
    postalCode: '29434',
  },
  'cordova-al': {
    name: 'Cordova',
    postalCode: '35550',
  },
  'cordova-il': {
    name: 'Cordova',
    postalCode: '61242',
  },
  'cordova-md': {
    name: 'Cordova',
    postalCode: '21625',
  },
  'cordova-sc': {
    name: 'Cordova',
    postalCode: '29039',
  },
  'cordova-tn': {
    name: 'Cordova',
    postalCode: '38016',
  },
  'corea-me': {
    name: 'Corea',
    postalCode: '04624',
  },
  'corfu-ny': {
    name: 'Corfu',
    postalCode: '14036',
  },
  'corinna-me': {
    name: 'Corinna',
    postalCode: '04928',
  },
  'corinne-ut': {
    name: 'Corinne',
    postalCode: '84307',
  },
  'corinth-ky': {
    name: 'Corinth',
    postalCode: '41010',
  },
  'corinth-me': {
    name: 'Corinth',
    postalCode: '04427',
  },
  'corinth-ms': {
    name: 'Corinth',
    postalCode: '38834',
  },
  'corinth-ny': {
    name: 'Corinth',
    postalCode: '12822',
  },
  'corinth-vt': {
    name: 'Corinth',
    postalCode: '05039',
  },
  'corn-ok': {
    name: 'Corn',
    postalCode: '73024',
  },
  'cornelia-ga': {
    name: 'Cornelia',
    postalCode: '30531',
  },
  'cornelius-nc': {
    name: 'Cornelius',
    postalCode: '28031',
  },
  'cornelius-or': {
    name: 'Cornelius',
    postalCode: '97113',
  },
  'cornell-il': {
    name: 'Cornell',
    postalCode: '61319',
  },
  'cornell-mi': {
    name: 'Cornell',
    postalCode: '49818',
  },
  'cornell-wi': {
    name: 'Cornell',
    postalCode: '54732',
  },
  'cornersville-tn': {
    name: 'Cornersville',
    postalCode: '37047',
  },
  'cornettsville-ky': {
    name: 'Cornettsville',
    postalCode: '41731',
  },
  'corning-ar': {
    name: 'Corning',
    postalCode: '72422',
  },
  'corning-ca': {
    name: 'Corning',
    postalCode: '96021',
  },
  'corning-ia': {
    name: 'Corning',
    postalCode: '50841',
  },
  'corning-ks': {
    name: 'Corning',
    postalCode: '66417',
  },
  'corning-ny': {
    name: 'Corning',
    postalCode: '14830',
  },
  'corning-oh': {
    name: 'Corning',
    postalCode: '43730',
  },
  'cornish-me': {
    name: 'Cornish',
    postalCode: '04020',
  },
  'cornish-nh': {
    name: 'Cornish',
    postalCode: '03745',
  },
  'cornish-ut': {
    name: 'Cornish',
    postalCode: '84308',
  },
  'cornucopia-wi': {
    name: 'Cornucopia',
    postalCode: '54827',
  },
  'cornville-az': {
    name: 'Cornville',
    postalCode: '86325',
  },
  'cornwall-ny': {
    name: 'Cornwall',
    postalCode: '12518',
  },
  'cornwall-bridge-ct': {
    name: 'Cornwall Bridge',
    postalCode: '06754',
  },
  'cornwall-on-hudson-ny': {
    name: 'Cornwall On Hudson',
    postalCode: '12520',
  },
  'cornwallville-ny': {
    name: 'Cornwallville',
    postalCode: '12418',
  },
  'corolla-nc': {
    name: 'Corolla',
    postalCode: '27927',
  },
  'corona-ca': {
    name: 'Corona',
    postalCode: '92878',
  },
  'corona-nm': {
    name: 'Corona',
    postalCode: '88318',
  },
  'corona-ny': {
    name: 'Corona',
    postalCode: '11368',
  },
  'corona-sd': {
    name: 'Corona',
    postalCode: '57227',
  },
  'corona-del-mar-ca': {
    name: 'Corona Del Mar',
    postalCode: '92625',
  },
  'coronado-ca': {
    name: 'Coronado',
    postalCode: '92118',
  },
  'corozal-pr': {
    name: 'Corozal',
    postalCode: '00783',
  },
  'corpus-christi-tx': {
    name: 'Corpus Christi',
    postalCode: '78401',
  },
  'corral-id': {
    name: 'Corral',
    postalCode: '83322',
  },
  'corrales-nm': {
    name: 'Corrales',
    postalCode: '87048',
  },
  'correctionville-ia': {
    name: 'Correctionville',
    postalCode: '51016',
  },
  'correll-mn': {
    name: 'Correll',
    postalCode: '56227',
  },
  'corrigan-tx': {
    name: 'Corrigan',
    postalCode: '75939',
  },
  'corry-pa': {
    name: 'Corry',
    postalCode: '16407',
  },
  'corryton-tn': {
    name: 'Corryton',
    postalCode: '37721',
  },
  'corsica-pa': {
    name: 'Corsica',
    postalCode: '15829',
  },
  'corsica-sd': {
    name: 'Corsica',
    postalCode: '57328',
  },
  'corsicana-tx': {
    name: 'Corsicana',
    postalCode: '75109',
  },
  'corte-madera-ca': {
    name: 'Corte Madera',
    postalCode: '94925',
  },
  'cortez-co': {
    name: 'Cortez',
    postalCode: '81321',
  },
  'cortez-fl': {
    name: 'Cortez',
    postalCode: '34215',
  },
  'cortland-il': {
    name: 'Cortland',
    postalCode: '60112',
  },
  'cortland-ne': {
    name: 'Cortland',
    postalCode: '68331',
  },
  'cortland-ny': {
    name: 'Cortland',
    postalCode: '13045',
  },
  'cortland-oh': {
    name: 'Cortland',
    postalCode: '44410',
  },
  'cortlandt-manor-ny': {
    name: 'Cortlandt Manor',
    postalCode: '10567',
  },
  'corunna-in': {
    name: 'Corunna',
    postalCode: '46730',
  },
  'corunna-mi': {
    name: 'Corunna',
    postalCode: '48817',
  },
  'corvallis-mt': {
    name: 'Corvallis',
    postalCode: '59828',
  },
  'corvallis-or': {
    name: 'Corvallis',
    postalCode: '97330',
  },
  'corwith-ia': {
    name: 'Corwith',
    postalCode: '50430',
  },
  'cory-in': {
    name: 'Cory',
    postalCode: '47846',
  },
  'corydon-ia': {
    name: 'Corydon',
    postalCode: '50060',
  },
  'corydon-in': {
    name: 'Corydon',
    postalCode: '47112',
  },
  'corydon-ky': {
    name: 'Corydon',
    postalCode: '42406',
  },
  'cos-cob-ct': {
    name: 'Cos Cob',
    postalCode: '06807',
  },
  'cosby-mo': {
    name: 'Cosby',
    postalCode: '64436',
  },
  'cosby-tn': {
    name: 'Cosby',
    postalCode: '37722',
  },
  'coshocton-oh': {
    name: 'Coshocton',
    postalCode: '43812',
  },
  'cosmopolis-wa': {
    name: 'Cosmopolis',
    postalCode: '98537',
  },
  'cosmos-mn': {
    name: 'Cosmos',
    postalCode: '56228',
  },
  'cossayuna-ny': {
    name: 'Cossayuna',
    postalCode: '12823',
  },
  'cost-tx': {
    name: 'Cost',
    postalCode: '78614',
  },
  'costa-wv': {
    name: 'Costa',
    postalCode: '25051',
  },
  'costa-mesa-ca': {
    name: 'Costa Mesa',
    postalCode: '92626',
  },
  'cotati-ca': {
    name: 'Cotati',
    postalCode: '94931',
  },
  'coto-laurel-pr': {
    name: 'Coto Laurel',
    postalCode: '00780',
  },
  'cotopaxi-co': {
    name: 'Cotopaxi',
    postalCode: '81223',
  },
  'cottage-grove-mn': {
    name: 'Cottage Grove',
    postalCode: '55016',
  },
  'cottage-grove-or': {
    name: 'Cottage Grove',
    postalCode: '97424',
  },
  'cottage-grove-tn': {
    name: 'Cottage Grove',
    postalCode: '38224',
  },
  'cottage-grove-wi': {
    name: 'Cottage Grove',
    postalCode: '53527',
  },
  'cottage-hills-il': {
    name: 'Cottage Hills',
    postalCode: '62018',
  },
  'cottageville-sc': {
    name: 'Cottageville',
    postalCode: '29435',
  },
  'cottageville-wv': {
    name: 'Cottageville',
    postalCode: '25239',
  },
  'cottekill-ny': {
    name: 'Cottekill',
    postalCode: '12419',
  },
  'cotter-ar': {
    name: 'Cotter',
    postalCode: '72626',
  },
  'cotton-mn': {
    name: 'Cotton',
    postalCode: '55724',
  },
  'cotton-plant-ar': {
    name: 'Cotton Plant',
    postalCode: '72036',
  },
  'cotton-valley-la': {
    name: 'Cotton Valley',
    postalCode: '71018',
  },
  'cottondale-al': {
    name: 'Cottondale',
    postalCode: '35453',
  },
  'cottondale-fl': {
    name: 'Cottondale',
    postalCode: '32431',
  },
  'cottonport-la': {
    name: 'Cottonport',
    postalCode: '71327',
  },
  'cottontown-tn': {
    name: 'Cottontown',
    postalCode: '37048',
  },
  'cottonwood-al': {
    name: 'Cottonwood',
    postalCode: '36320',
  },
  'cottonwood-az': {
    name: 'Cottonwood',
    postalCode: '86326',
  },
  'cottonwood-ca': {
    name: 'Cottonwood',
    postalCode: '96022',
  },
  'cottonwood-id': {
    name: 'Cottonwood',
    postalCode: '83522',
  },
  'cottonwood-mn': {
    name: 'Cottonwood',
    postalCode: '56229',
  },
  'cottonwood-falls-ks': {
    name: 'Cottonwood Falls',
    postalCode: '66845',
  },
  'cotuit-ma': {
    name: 'Cotuit',
    postalCode: '02635',
  },
  'cotulla-tx': {
    name: 'Cotulla',
    postalCode: '78014',
  },
  'couch-mo': {
    name: 'Couch',
    postalCode: '65690',
  },
  'couderay-wi': {
    name: 'Couderay',
    postalCode: '54828',
  },
  'coudersport-pa': {
    name: 'Coudersport',
    postalCode: '16915',
  },
  'cougar-wa': {
    name: 'Cougar',
    postalCode: '98616',
  },
  'coulee-city-wa': {
    name: 'Coulee City',
    postalCode: '99115',
  },
  'coulee-dam-wa': {
    name: 'Coulee Dam',
    postalCode: '99116',
  },
  'coulterville-ca': {
    name: 'Coulterville',
    postalCode: '95311',
  },
  'coulterville-il': {
    name: 'Coulterville',
    postalCode: '62237',
  },
  'counce-tn': {
    name: 'Counce',
    postalCode: '38326',
  },
  'council-id': {
    name: 'Council',
    postalCode: '83612',
  },
  'council-nc': {
    name: 'Council',
    postalCode: '28434',
  },
  'council-bluffs-ia': {
    name: 'Council Bluffs',
    postalCode: '51501',
  },
  'council-grove-ks': {
    name: 'Council Grove',
    postalCode: '66846',
  },
  'council-hill-ok': {
    name: 'Council Hill',
    postalCode: '74428',
  },
  'country-club-hills-il': {
    name: 'Country Club Hills',
    postalCode: '60478',
  },
  'coupeville-wa': {
    name: 'Coupeville',
    postalCode: '98239',
  },
  'coupland-tx': {
    name: 'Coupland',
    postalCode: '78615',
  },
  'coupon-pa': {
    name: 'Coupon',
    postalCode: '16629',
  },
  'courtenay-nd': {
    name: 'Courtenay',
    postalCode: '58426',
  },
  'courtland-al': {
    name: 'Courtland',
    postalCode: '35618',
  },
  'courtland-ca': {
    name: 'Courtland',
    postalCode: '95615',
  },
  'courtland-ks': {
    name: 'Courtland',
    postalCode: '66939',
  },
  'courtland-mn': {
    name: 'Courtland',
    postalCode: '56021',
  },
  'courtland-ms': {
    name: 'Courtland',
    postalCode: '38620',
  },
  'courtland-va': {
    name: 'Courtland',
    postalCode: '23837',
  },
  'coushatta-la': {
    name: 'Coushatta',
    postalCode: '71019',
  },
  'cove-ar': {
    name: 'Cove',
    postalCode: '71937',
  },
  'cove-or': {
    name: 'Cove',
    postalCode: '97824',
  },
  'cove-city-nc': {
    name: 'Cove City',
    postalCode: '28523',
  },
  'covelo-ca': {
    name: 'Covelo',
    postalCode: '95428',
  },
  'coventry-ct': {
    name: 'Coventry',
    postalCode: '06238',
  },
  'coventry-ri': {
    name: 'Coventry',
    postalCode: '02816',
  },
  'covert-mi': {
    name: 'Covert',
    postalCode: '49043',
  },
  'covesville-va': {
    name: 'Covesville',
    postalCode: '22931',
  },
  'covina-ca': {
    name: 'Covina',
    postalCode: '91722',
  },
  'covington-ga': {
    name: 'Covington',
    postalCode: '30014',
  },
  'covington-in': {
    name: 'Covington',
    postalCode: '47932',
  },
  'covington-ky': {
    name: 'Covington',
    postalCode: '41011',
  },
  'covington-la': {
    name: 'Covington',
    postalCode: '70433',
  },
  'covington-mi': {
    name: 'Covington',
    postalCode: '49919',
  },
  'covington-oh': {
    name: 'Covington',
    postalCode: '45318',
  },
  'covington-ok': {
    name: 'Covington',
    postalCode: '73730',
  },
  'covington-pa': {
    name: 'Covington',
    postalCode: '16917',
  },
  'covington-tn': {
    name: 'Covington',
    postalCode: '38019',
  },
  'covington-tx': {
    name: 'Covington',
    postalCode: '76636',
  },
  'covington-va': {
    name: 'Covington',
    postalCode: '24426',
  },
  'cowan-tn': {
    name: 'Cowan',
    postalCode: '37318',
  },
  'cowanesque-pa': {
    name: 'Cowanesque',
    postalCode: '16918',
  },
  'cowansville-pa': {
    name: 'Cowansville',
    postalCode: '16218',
  },
  'coward-sc': {
    name: 'Coward',
    postalCode: '29530',
  },
  'cowarts-al': {
    name: 'Cowarts',
    postalCode: '36321',
  },
  'cowden-il': {
    name: 'Cowden',
    postalCode: '62422',
  },
  'cowen-wv': {
    name: 'Cowen',
    postalCode: '26206',
  },
  'coweta-ok': {
    name: 'Coweta',
    postalCode: '74429',
  },
  'cowgill-mo': {
    name: 'Cowgill',
    postalCode: '64637',
  },
  'cowiche-wa': {
    name: 'Cowiche',
    postalCode: '98923',
  },
  'cowlesville-ny': {
    name: 'Cowlesville',
    postalCode: '14037',
  },
  'cowpens-sc': {
    name: 'Cowpens',
    postalCode: '29330',
  },
  'coxs-creek-ky': {
    name: 'Coxs Creek',
    postalCode: '40013',
  },
  'coxs-mills-wv': {
    name: 'Coxs Mills',
    postalCode: '26342',
  },
  'coxsackie-ny': {
    name: 'Coxsackie',
    postalCode: '12051',
  },
  'coy-al': {
    name: 'Coy',
    postalCode: '36435',
  },
  'coyle-ok': {
    name: 'Coyle',
    postalCode: '73027',
  },
  'coyote-nm': {
    name: 'Coyote',
    postalCode: '87012',
  },
  'coyote-springs-nv': {
    name: 'Coyote Springs',
    postalCode: '89067',
  },
  'cozad-ne': {
    name: 'Cozad',
    postalCode: '69130',
  },
  'crab-orchard-ky': {
    name: 'Crab Orchard',
    postalCode: '40419',
  },
  'crab-orchard-ne': {
    name: 'Crab Orchard',
    postalCode: '68332',
  },
  'crab-orchard-tn': {
    name: 'Crab Orchard',
    postalCode: '37723',
  },
  'crab-orchard-wv': {
    name: 'Crab Orchard',
    postalCode: '25827',
  },
  'craftsbury-vt': {
    name: 'Craftsbury',
    postalCode: '05826',
  },
  'craftsbury-common-vt': {
    name: 'Craftsbury Common',
    postalCode: '05827',
  },
  'cragford-al': {
    name: 'Cragford',
    postalCode: '36255',
  },
  'craig-co': {
    name: 'Craig',
    postalCode: '81625',
  },
  'craig-mo': {
    name: 'Craig',
    postalCode: '64437',
  },
  'craig-ne': {
    name: 'Craig',
    postalCode: '68019',
  },
  'craigmont-id': {
    name: 'Craigmont',
    postalCode: '83523',
  },
  'craigsville-va': {
    name: 'Craigsville',
    postalCode: '24430',
  },
  'craigsville-wv': {
    name: 'Craigsville',
    postalCode: '26205',
  },
  'craigville-in': {
    name: 'Craigville',
    postalCode: '46731',
  },
  'cramerton-nc': {
    name: 'Cramerton',
    postalCode: '28032',
  },
  'cranberry-pa': {
    name: 'Cranberry',
    postalCode: '16319',
  },
  'cranberry-township-pa': {
    name: 'Cranberry Township',
    postalCode: '16066',
  },
  'cranbury-nj': {
    name: 'Cranbury',
    postalCode: '08512',
  },
  'crandall-ga': {
    name: 'Crandall',
    postalCode: '30711',
  },
  'crandall-in': {
    name: 'Crandall',
    postalCode: '47114',
  },
  'crandall-tx': {
    name: 'Crandall',
    postalCode: '75114',
  },
  'crandon-wi': {
    name: 'Crandon',
    postalCode: '54520',
  },
  'crane-in': {
    name: 'Crane',
    postalCode: '47522',
  },
  'crane-mo': {
    name: 'Crane',
    postalCode: '65633',
  },
  'crane-tx': {
    name: 'Crane',
    postalCode: '79731',
  },
  'crane-hill-al': {
    name: 'Crane Hill',
    postalCode: '35053',
  },
  'crane-lake-mn': {
    name: 'Crane Lake',
    postalCode: '55725',
  },
  'cranesville-pa': {
    name: 'Cranesville',
    postalCode: '16410',
  },
  'cranfills-gap-tx': {
    name: 'Cranfills Gap',
    postalCode: '76637',
  },
  'cranford-nj': {
    name: 'Cranford',
    postalCode: '07016',
  },
  'cranks-ky': {
    name: 'Cranks',
    postalCode: '40820',
  },
  'cranston-ri': {
    name: 'Cranston',
    postalCode: '02910',
  },
  'crapo-md': {
    name: 'Crapo',
    postalCode: '21626',
  },
  'crary-nd': {
    name: 'Crary',
    postalCode: '58327',
  },
  'craryville-ny': {
    name: 'Craryville',
    postalCode: '12521',
  },
  'crawford-co': {
    name: 'Crawford',
    postalCode: '81415',
  },
  'crawford-ga': {
    name: 'Crawford',
    postalCode: '30630',
  },
  'crawford-ms': {
    name: 'Crawford',
    postalCode: '39743',
  },
  'crawford-ne': {
    name: 'Crawford',
    postalCode: '69339',
  },
  'crawford-ok': {
    name: 'Crawford',
    postalCode: '73638',
  },
  'crawford-tn': {
    name: 'Crawford',
    postalCode: '38554',
  },
  'crawford-tx': {
    name: 'Crawford',
    postalCode: '76638',
  },
  'crawford-wv': {
    name: 'Crawford',
    postalCode: '26343',
  },
  'crawfordsville-ar': {
    name: 'Crawfordsville',
    postalCode: '72327',
  },
  'crawfordsville-ia': {
    name: 'Crawfordsville',
    postalCode: '52621',
  },
  'crawfordsville-in': {
    name: 'Crawfordsville',
    postalCode: '47933',
  },
  'crawfordville-fl': {
    name: 'Crawfordville',
    postalCode: '32327',
  },
  'crawfordville-ga': {
    name: 'Crawfordville',
    postalCode: '30631',
  },
  'crawley-wv': {
    name: 'Crawley',
    postalCode: '24931',
  },
  'creal-springs-il': {
    name: 'Creal Springs',
    postalCode: '62922',
  },
  'cream-ridge-nj': {
    name: 'Cream Ridge',
    postalCode: '08514',
  },
  'creede-co': {
    name: 'Creede',
    postalCode: '81130',
  },
  'creedmoor-nc': {
    name: 'Creedmoor',
    postalCode: '27522',
  },
  'creekside-pa': {
    name: 'Creekside',
    postalCode: '15732',
  },
  'creighton-mo': {
    name: 'Creighton',
    postalCode: '64739',
  },
  'creighton-ne': {
    name: 'Creighton',
    postalCode: '68729',
  },
  'creighton-pa': {
    name: 'Creighton',
    postalCode: '15030',
  },
  'crenshaw-ms': {
    name: 'Crenshaw',
    postalCode: '38621',
  },
  'creola-al': {
    name: 'Creola',
    postalCode: '36525',
  },
  'creola-oh': {
    name: 'Creola',
    postalCode: '45622',
  },
  'creole-la': {
    name: 'Creole',
    postalCode: '70632',
  },
  'cresbard-sd': {
    name: 'Cresbard',
    postalCode: '57435',
  },
  'crescent-ia': {
    name: 'Crescent',
    postalCode: '51526',
  },
  'crescent-ok': {
    name: 'Crescent',
    postalCode: '73028',
  },
  'crescent-or': {
    name: 'Crescent',
    postalCode: '97733',
  },
  'crescent-pa': {
    name: 'Crescent',
    postalCode: '15046',
  },
  'crescent-city-ca': {
    name: 'Crescent City',
    postalCode: '95531',
  },
  'crescent-city-fl': {
    name: 'Crescent City',
    postalCode: '32112',
  },
  'crescent-city-il': {
    name: 'Crescent City',
    postalCode: '60928',
  },
  'crescent-mills-ca': {
    name: 'Crescent Mills',
    postalCode: '95934',
  },
  'crescent-valley-nv': {
    name: 'Crescent Valley',
    postalCode: '89821',
  },
  'cresco-ia': {
    name: 'Cresco',
    postalCode: '52136',
  },
  'cresco-pa': {
    name: 'Cresco',
    postalCode: '18326',
  },
  'cresskill-nj': {
    name: 'Cresskill',
    postalCode: '07626',
  },
  'cresson-pa': {
    name: 'Cresson',
    postalCode: '16630',
  },
  'cresson-tx': {
    name: 'Cresson',
    postalCode: '76035',
  },
  'cressona-pa': {
    name: 'Cressona',
    postalCode: '17929',
  },
  'crest-hill-il': {
    name: 'Crest Hill',
    postalCode: '60403',
  },
  'crested-butte-co': {
    name: 'Crested Butte',
    postalCode: '81224',
  },
  'crestline-oh': {
    name: 'Crestline',
    postalCode: '44827',
  },
  'creston-ca': {
    name: 'Creston',
    postalCode: '93432',
  },
  'creston-ia': {
    name: 'Creston',
    postalCode: '50801',
  },
  'creston-nc': {
    name: 'Creston',
    postalCode: '28615',
  },
  'creston-ne': {
    name: 'Creston',
    postalCode: '68631',
  },
  'creston-oh': {
    name: 'Creston',
    postalCode: '44217',
  },
  'creston-wa': {
    name: 'Creston',
    postalCode: '99117',
  },
  'creston-wv': {
    name: 'Creston',
    postalCode: '26141',
  },
  'crestview-fl': {
    name: 'Crestview',
    postalCode: '32536',
  },
  'crestwood-il': {
    name: 'Crestwood',
    postalCode: '60418',
  },
  'crestwood-ky': {
    name: 'Crestwood',
    postalCode: '40014',
  },
  'creswell-nc': {
    name: 'Creswell',
    postalCode: '27928',
  },
  'creswell-or': {
    name: 'Creswell',
    postalCode: '97426',
  },
  'crete-il': {
    name: 'Crete',
    postalCode: '60417',
  },
  'crete-ne': {
    name: 'Crete',
    postalCode: '68333',
  },
  'creve-coeur-il': {
    name: 'Creve Coeur',
    postalCode: '61610',
  },
  'crewe-va': {
    name: 'Crewe',
    postalCode: '23930',
  },
  'criders-va': {
    name: 'Criders',
    postalCode: '22820',
  },
  'crimora-va': {
    name: 'Crimora',
    postalCode: '24431',
  },
  'cripple-creek-co': {
    name: 'Cripple Creek',
    postalCode: '80813',
  },
  'cripple-creek-va': {
    name: 'Cripple Creek',
    postalCode: '24322',
  },
  'crisfield-md': {
    name: 'Crisfield',
    postalCode: '21817',
  },
  'crittenden-ky': {
    name: 'Crittenden',
    postalCode: '41030',
  },
  'critz-va': {
    name: 'Critz',
    postalCode: '24082',
  },
  'crivitz-wi': {
    name: 'Crivitz',
    postalCode: '54114',
  },
  'crocheron-md': {
    name: 'Crocheron',
    postalCode: '21627',
  },
  'crocker-mo': {
    name: 'Crocker',
    postalCode: '65452',
  },
  'crockett-ca': {
    name: 'Crockett',
    postalCode: '94525',
  },
  'crockett-tx': {
    name: 'Crockett',
    postalCode: '75835',
  },
  'crockett-va': {
    name: 'Crockett',
    postalCode: '24323',
  },
  'crocketts-bluff-ar': {
    name: 'Crocketts Bluff',
    postalCode: '72038',
  },
  'crofton-ky': {
    name: 'Crofton',
    postalCode: '42217',
  },
  'crofton-md': {
    name: 'Crofton',
    postalCode: '21114',
  },
  'crofton-ne': {
    name: 'Crofton',
    postalCode: '68730',
  },
  'croghan-ny': {
    name: 'Croghan',
    postalCode: '13327',
  },
  'cromwell-ct': {
    name: 'Cromwell',
    postalCode: '06416',
  },
  'cromwell-ia': {
    name: 'Cromwell',
    postalCode: '50842',
  },
  'cromwell-in': {
    name: 'Cromwell',
    postalCode: '46732',
  },
  'cromwell-ky': {
    name: 'Cromwell',
    postalCode: '42333',
  },
  'cromwell-mn': {
    name: 'Cromwell',
    postalCode: '55726',
  },
  'crook-co': {
    name: 'Crook',
    postalCode: '80726',
  },
  'crooks-sd': {
    name: 'Crooks',
    postalCode: '57020',
  },
  'crookston-mn': {
    name: 'Crookston',
    postalCode: '56716',
  },
  'crookston-ne': {
    name: 'Crookston',
    postalCode: '69212',
  },
  'crooksville-oh': {
    name: 'Crooksville',
    postalCode: '43731',
  },
  'cropsey-il': {
    name: 'Cropsey',
    postalCode: '61731',
  },
  'cropseyville-ny': {
    name: 'Cropseyville',
    postalCode: '12052',
  },
  'cropwell-al': {
    name: 'Cropwell',
    postalCode: '35054',
  },
  'crosby-mn': {
    name: 'Crosby',
    postalCode: '56441',
  },
  'crosby-ms': {
    name: 'Crosby',
    postalCode: '39633',
  },
  'crosby-nd': {
    name: 'Crosby',
    postalCode: '58730',
  },
  'crosby-tx': {
    name: 'Crosby',
    postalCode: '77532',
  },
  'crosbyton-tx': {
    name: 'Crosbyton',
    postalCode: '79322',
  },
  'cross-sc': {
    name: 'Cross',
    postalCode: '29436',
  },
  'cross-city-fl': {
    name: 'Cross City',
    postalCode: '32628',
  },
  'cross-fork-pa': {
    name: 'Cross Fork',
    postalCode: '17729',
  },
  'cross-hill-sc': {
    name: 'Cross Hill',
    postalCode: '29332',
  },
  'cross-junction-va': {
    name: 'Cross Junction',
    postalCode: '22625',
  },
  'cross-plains-in': {
    name: 'Cross Plains',
    postalCode: '47017',
  },
  'cross-plains-tn': {
    name: 'Cross Plains',
    postalCode: '37049',
  },
  'cross-plains-tx': {
    name: 'Cross Plains',
    postalCode: '76443',
  },
  'cross-plains-wi': {
    name: 'Cross Plains',
    postalCode: '53528',
  },
  'cross-river-ny': {
    name: 'Cross River',
    postalCode: '10518',
  },
  'cross-timbers-mo': {
    name: 'Cross Timbers',
    postalCode: '65634',
  },
  'crossett-ar': {
    name: 'Crossett',
    postalCode: '71635',
  },
  'crosslake-mn': {
    name: 'Crosslake',
    postalCode: '56442',
  },
  'crossroads-nm': {
    name: 'Crossroads',
    postalCode: '88114',
  },
  'crossville-al': {
    name: 'Crossville',
    postalCode: '35962',
  },
  'crossville-il': {
    name: 'Crossville',
    postalCode: '62827',
  },
  'crossville-tn': {
    name: 'Crossville',
    postalCode: '38555',
  },
  'croswell-mi': {
    name: 'Croswell',
    postalCode: '48422',
  },
  'crothersville-in': {
    name: 'Crothersville',
    postalCode: '47229',
  },
  'croton-oh': {
    name: 'Croton',
    postalCode: '43013',
  },
  'croton-on-hudson-ny': {
    name: 'Croton On Hudson',
    postalCode: '10520',
  },
  'crouse-nc': {
    name: 'Crouse',
    postalCode: '28033',
  },
  'crow-agency-mt': {
    name: 'Crow Agency',
    postalCode: '59022',
  },
  'crowell-tx': {
    name: 'Crowell',
    postalCode: '79227',
  },
  'crowheart-wy': {
    name: 'Crowheart',
    postalCode: '82512',
  },
  'crowley-la': {
    name: 'Crowley',
    postalCode: '70526',
  },
  'crowley-tx': {
    name: 'Crowley',
    postalCode: '76036',
  },
  'crown-city-oh': {
    name: 'Crown City',
    postalCode: '45623',
  },
  'crown-point-in': {
    name: 'Crown Point',
    postalCode: '46307',
  },
  'crown-point-ny': {
    name: 'Crown Point',
    postalCode: '12928',
  },
  'crownsville-md': {
    name: 'Crownsville',
    postalCode: '21032',
  },
  'crows-landing-ca': {
    name: 'Crows Landing',
    postalCode: '95313',
  },
  'croydon-pa': {
    name: 'Croydon',
    postalCode: '19021',
  },
  'croydon-ut': {
    name: 'Croydon',
    postalCode: '84018',
  },
  'crozet-va': {
    name: 'Crozet',
    postalCode: '22932',
  },
  'crozier-va': {
    name: 'Crozier',
    postalCode: '23039',
  },
  'cruger-ms': {
    name: 'Cruger',
    postalCode: '38924',
  },
  'crum-wv': {
    name: 'Crum',
    postalCode: '25669',
  },
  'crum-lynne-pa': {
    name: 'Crum Lynne',
    postalCode: '19022',
  },
  'crump-tn': {
    name: 'Crump',
    postalCode: '38327',
  },
  'crumpler-nc': {
    name: 'Crumpler',
    postalCode: '28617',
  },
  'crystal-mi': {
    name: 'Crystal',
    postalCode: '48818',
  },
  'crystal-nd': {
    name: 'Crystal',
    postalCode: '58222',
  },
  'crystal-city-mo': {
    name: 'Crystal City',
    postalCode: '63019',
  },
  'crystal-city-tx': {
    name: 'Crystal City',
    postalCode: '78839',
  },
  'crystal-falls-mi': {
    name: 'Crystal Falls',
    postalCode: '49920',
  },
  'crystal-hill-va': {
    name: 'Crystal Hill',
    postalCode: '24539',
  },
  'crystal-lake-il': {
    name: 'Crystal Lake',
    postalCode: '60012',
  },
  'crystal-river-fl': {
    name: 'Crystal River',
    postalCode: '34428',
  },
  'crystal-spring-pa': {
    name: 'Crystal Spring',
    postalCode: '15536',
  },
  'crystal-springs-ms': {
    name: 'Crystal Springs',
    postalCode: '39059',
  },
  'cub-run-ky': {
    name: 'Cub Run',
    postalCode: '42729',
  },
  'cuba-al': {
    name: 'Cuba',
    postalCode: '36907',
  },
  'cuba-il': {
    name: 'Cuba',
    postalCode: '61427',
  },
  'cuba-ks': {
    name: 'Cuba',
    postalCode: '66940',
  },
  'cuba-mo': {
    name: 'Cuba',
    postalCode: '65453',
  },
  'cuba-nm': {
    name: 'Cuba',
    postalCode: '87013',
  },
  'cuba-ny': {
    name: 'Cuba',
    postalCode: '14727',
  },
  'cuba-city-wi': {
    name: 'Cuba City',
    postalCode: '53807',
  },
  'cubero-nm': {
    name: 'Cubero',
    postalCode: '87014',
  },
  'cudahy-wi': {
    name: 'Cudahy',
    postalCode: '53110',
  },
  'cuddebackville-ny': {
    name: 'Cuddebackville',
    postalCode: '12729',
  },
  'cuddy-pa': {
    name: 'Cuddy',
    postalCode: '15031',
  },
  'cuero-tx': {
    name: 'Cuero',
    postalCode: '77954',
  },
  'cuervo-nm': {
    name: 'Cuervo',
    postalCode: '88417',
  },
  'culbertson-mt': {
    name: 'Culbertson',
    postalCode: '59218',
  },
  'culbertson-ne': {
    name: 'Culbertson',
    postalCode: '69024',
  },
  'culdesac-id': {
    name: 'Culdesac',
    postalCode: '83524',
  },
  'cullen-va': {
    name: 'Cullen',
    postalCode: '23934',
  },
  'culleoka-tn': {
    name: 'Culleoka',
    postalCode: '38451',
  },
  'cullman-al': {
    name: 'Cullman',
    postalCode: '35055',
  },
  'culloden-ga': {
    name: 'Culloden',
    postalCode: '31016',
  },
  'culloden-wv': {
    name: 'Culloden',
    postalCode: '25510',
  },
  'cullom-il': {
    name: 'Cullom',
    postalCode: '60929',
  },
  'cullowhee-nc': {
    name: 'Cullowhee',
    postalCode: '28723',
  },
  'culp-creek-or': {
    name: 'Culp Creek',
    postalCode: '97427',
  },
  'culpeper-va': {
    name: 'Culpeper',
    postalCode: '22701',
  },
  'culver-in': {
    name: 'Culver',
    postalCode: '46511',
  },
  'culver-or': {
    name: 'Culver',
    postalCode: '97734',
  },
  'culver-city-ca': {
    name: 'Culver City',
    postalCode: '90230',
  },
  'cumberland-ia': {
    name: 'Cumberland',
    postalCode: '50843',
  },
  'cumberland-ky': {
    name: 'Cumberland',
    postalCode: '40823',
  },
  'cumberland-md': {
    name: 'Cumberland',
    postalCode: '21502',
  },
  'cumberland-oh': {
    name: 'Cumberland',
    postalCode: '43732',
  },
  'cumberland-ri': {
    name: 'Cumberland',
    postalCode: '02864',
  },
  'cumberland-va': {
    name: 'Cumberland',
    postalCode: '23040',
  },
  'cumberland-wi': {
    name: 'Cumberland',
    postalCode: '54829',
  },
  'cumberland-center-me': {
    name: 'Cumberland Center',
    postalCode: '04021',
  },
  'cumberland-city-tn': {
    name: 'Cumberland City',
    postalCode: '37050',
  },
  'cumberland-foreside-me': {
    name: 'Cumberland Foreside',
    postalCode: '04110',
  },
  'cumberland-furnace-tn': {
    name: 'Cumberland Furnace',
    postalCode: '37051',
  },
  'cumberland-gap-tn': {
    name: 'Cumberland Gap',
    postalCode: '37724',
  },
  'cumby-tx': {
    name: 'Cumby',
    postalCode: '75433',
  },
  'cumming-ga': {
    name: 'Cumming',
    postalCode: '30028',
  },
  'cumming-ia': {
    name: 'Cumming',
    postalCode: '50061',
  },
  'cummings-ks': {
    name: 'Cummings',
    postalCode: '66016',
  },
  'cummings-nd': {
    name: 'Cummings',
    postalCode: '58223',
  },
  'cummington-ma': {
    name: 'Cummington',
    postalCode: '01026',
  },
  'cunningham-ks': {
    name: 'Cunningham',
    postalCode: '67035',
  },
  'cunningham-ky': {
    name: 'Cunningham',
    postalCode: '42035',
  },
  'cunningham-tn': {
    name: 'Cunningham',
    postalCode: '37052',
  },
  'cupertino-ca': {
    name: 'Cupertino',
    postalCode: '95014',
  },
  'curlew-ia': {
    name: 'Curlew',
    postalCode: '50527',
  },
  'curlew-wa': {
    name: 'Curlew',
    postalCode: '99118',
  },
  'curran-mi': {
    name: 'Curran',
    postalCode: '48728',
  },
  'currie-mn': {
    name: 'Currie',
    postalCode: '56123',
  },
  'currie-nc': {
    name: 'Currie',
    postalCode: '28435',
  },
  'currituck-nc': {
    name: 'Currituck',
    postalCode: '27929',
  },
  'curryville-mo': {
    name: 'Curryville',
    postalCode: '63339',
  },
  'curtice-oh': {
    name: 'Curtice',
    postalCode: '43412',
  },
  'curtis-mi': {
    name: 'Curtis',
    postalCode: '49820',
  },
  'curtis-ne': {
    name: 'Curtis',
    postalCode: '69025',
  },
  'curtis-wa': {
    name: 'Curtis',
    postalCode: '98538',
  },
  'curtis-bay-md': {
    name: 'Curtis Bay',
    postalCode: '21226',
  },
  'curtiss-wi': {
    name: 'Curtiss',
    postalCode: '54422',
  },
  'curwensville-pa': {
    name: 'Curwensville',
    postalCode: '16833',
  },
  'cushing-ia': {
    name: 'Cushing',
    postalCode: '51018',
  },
  'cushing-me': {
    name: 'Cushing',
    postalCode: '04563',
  },
  'cushing-mn': {
    name: 'Cushing',
    postalCode: '56443',
  },
  'cushing-ok': {
    name: 'Cushing',
    postalCode: '74023',
  },
  'cushing-tx': {
    name: 'Cushing',
    postalCode: '75760',
  },
  'cushing-wi': {
    name: 'Cushing',
    postalCode: '54006',
  },
  'cusick-wa': {
    name: 'Cusick',
    postalCode: '99119',
  },
  'cusseta-al': {
    name: 'Cusseta',
    postalCode: '36852',
  },
  'cusseta-ga': {
    name: 'Cusseta',
    postalCode: '31805',
  },
  'custar-oh': {
    name: 'Custar',
    postalCode: '43511',
  },
  'custer-ky': {
    name: 'Custer',
    postalCode: '40115',
  },
  'custer-mi': {
    name: 'Custer',
    postalCode: '49405',
  },
  'custer-mt': {
    name: 'Custer',
    postalCode: '59024',
  },
  'custer-sd': {
    name: 'Custer',
    postalCode: '57730',
  },
  'custer-wa': {
    name: 'Custer',
    postalCode: '98240',
  },
  'custer-wi': {
    name: 'Custer',
    postalCode: '54423',
  },
  'custer-city-ok': {
    name: 'Custer City',
    postalCode: '73639',
  },
  'cut-bank-mt': {
    name: 'Cut Bank',
    postalCode: '59427',
  },
  'cut-off-la': {
    name: 'Cut Off',
    postalCode: '70345',
  },
  'cutchogue-ny': {
    name: 'Cutchogue',
    postalCode: '11935',
  },
  'cuthbert-ga': {
    name: 'Cuthbert',
    postalCode: '39840',
  },
  'cutler-ca': {
    name: 'Cutler',
    postalCode: '93615',
  },
  'cutler-il': {
    name: 'Cutler',
    postalCode: '62238',
  },
  'cutler-in': {
    name: 'Cutler',
    postalCode: '46920',
  },
  'cutler-me': {
    name: 'Cutler',
    postalCode: '04626',
  },
  'cutler-oh': {
    name: 'Cutler',
    postalCode: '45724',
  },
  'cuttingsville-vt': {
    name: 'Cuttingsville',
    postalCode: '05738',
  },
  'cuyahoga-falls-oh': {
    name: 'Cuyahoga Falls',
    postalCode: '44221',
  },
  'cyclone-pa': {
    name: 'Cyclone',
    postalCode: '16726',
  },
  'cyclone-wv': {
    name: 'Cyclone',
    postalCode: '24827',
  },
  'cygnet-oh': {
    name: 'Cygnet',
    postalCode: '43413',
  },
  'cylinder-ia': {
    name: 'Cylinder',
    postalCode: '50528',
  },
  'cynthiana-in': {
    name: 'Cynthiana',
    postalCode: '47612',
  },
  'cynthiana-ky': {
    name: 'Cynthiana',
    postalCode: '41031',
  },
  'cypress-ca': {
    name: 'Cypress',
    postalCode: '90630',
  },
  'cypress-il': {
    name: 'Cypress',
    postalCode: '62923',
  },
  'cypress-tx': {
    name: 'Cypress',
    postalCode: '77429',
  },
  'cypress-inn-tn': {
    name: 'Cypress Inn',
    postalCode: '38452',
  },
  'cyril-ok': {
    name: 'Cyril',
    postalCode: '73029',
  },
  'cyrus-mn': {
    name: 'Cyrus',
    postalCode: '56323',
  },
  'd-hanis-tx': {
    name: 'D Hanis',
    postalCode: '78850',
  },
  'dacoma-ok': {
    name: 'Dacoma',
    postalCode: '73731',
  },
  'dacono-co': {
    name: 'Dacono',
    postalCode: '80514',
  },
  'dacula-ga': {
    name: 'Dacula',
    postalCode: '30019',
  },
  'dade-city-fl': {
    name: 'Dade City',
    postalCode: '33523',
  },
  'dadeville-al': {
    name: 'Dadeville',
    postalCode: '36853',
  },
  'dadeville-mo': {
    name: 'Dadeville',
    postalCode: '65635',
  },
  'dafter-mi': {
    name: 'Dafter',
    postalCode: '49724',
  },
  'daggett-ca': {
    name: 'Daggett',
    postalCode: '92327',
  },
  'daggett-mi': {
    name: 'Daggett',
    postalCode: '49821',
  },
  'dagmar-mt': {
    name: 'Dagmar',
    postalCode: '59219',
  },
  'dagsboro-de': {
    name: 'Dagsboro',
    postalCode: '19939',
  },
  'dahinda-il': {
    name: 'Dahinda',
    postalCode: '61428',
  },
  'dahlen-nd': {
    name: 'Dahlen',
    postalCode: '58224',
  },
  'dahlgren-il': {
    name: 'Dahlgren',
    postalCode: '62828',
  },
  'dahlgren-va': {
    name: 'Dahlgren',
    postalCode: '22448',
  },
  'dahlonega-ga': {
    name: 'Dahlonega',
    postalCode: '30533',
  },
  'daingerfield-tx': {
    name: 'Daingerfield',
    postalCode: '75638',
  },
  'dairy-or': {
    name: 'Dairy',
    postalCode: '97625',
  },
  'daisy-mo': {
    name: 'Daisy',
    postalCode: '63743',
  },
  'daisy-ok': {
    name: 'Daisy',
    postalCode: '74540',
  },
  'daisytown-pa': {
    name: 'Daisytown',
    postalCode: '15427',
  },
  'dakota-il': {
    name: 'Dakota',
    postalCode: '61018',
  },
  'dakota-mn': {
    name: 'Dakota',
    postalCode: '55925',
  },
  'dakota-city-ia': {
    name: 'Dakota City',
    postalCode: '50529',
  },
  'dakota-city-ne': {
    name: 'Dakota City',
    postalCode: '68731',
  },
  'dalbo-mn': {
    name: 'Dalbo',
    postalCode: '55017',
  },
  'dale-in': {
    name: 'Dale',
    postalCode: '47523',
  },
  'dale-ny': {
    name: 'Dale',
    postalCode: '14039',
  },
  'dale-tx': {
    name: 'Dale',
    postalCode: '78616',
  },
  'daleville-al': {
    name: 'Daleville',
    postalCode: '36322',
  },
  'daleville-in': {
    name: 'Daleville',
    postalCode: '47334',
  },
  'daleville-ms': {
    name: 'Daleville',
    postalCode: '39326',
  },
  'daleville-va': {
    name: 'Daleville',
    postalCode: '24083',
  },
  'dalhart-tx': {
    name: 'Dalhart',
    postalCode: '79022',
  },
  'dallas-ga': {
    name: 'Dallas',
    postalCode: '30132',
  },
  'dallas-nc': {
    name: 'Dallas',
    postalCode: '28034',
  },
  'dallas-or': {
    name: 'Dallas',
    postalCode: '97338',
  },
  'dallas-pa': {
    name: 'Dallas',
    postalCode: '18612',
  },
  'dallas-sd': {
    name: 'Dallas',
    postalCode: '57529',
  },
  'dallas-tx': {
    name: 'Dallas',
    postalCode: '75201',
  },
  'dallas-wi': {
    name: 'Dallas',
    postalCode: '54733',
  },
  'dallas-wv': {
    name: 'Dallas',
    postalCode: '26036',
  },
  'dallas-center-ia': {
    name: 'Dallas Center',
    postalCode: '50063',
  },
  'dallas-city-il': {
    name: 'Dallas City',
    postalCode: '62330',
  },
  'dallastown-pa': {
    name: 'Dallastown',
    postalCode: '17313',
  },
  'dalmatia-pa': {
    name: 'Dalmatia',
    postalCode: '17017',
  },
  'dalton-ga': {
    name: 'Dalton',
    postalCode: '30720',
  },
  'dalton-ma': {
    name: 'Dalton',
    postalCode: '01226',
  },
  'dalton-mn': {
    name: 'Dalton',
    postalCode: '56324',
  },
  'dalton-mo': {
    name: 'Dalton',
    postalCode: '65246',
  },
  'dalton-ne': {
    name: 'Dalton',
    postalCode: '69131',
  },
  'dalton-ny': {
    name: 'Dalton',
    postalCode: '14836',
  },
  'dalton-oh': {
    name: 'Dalton',
    postalCode: '44618',
  },
  'dalton-pa': {
    name: 'Dalton',
    postalCode: '18414',
  },
  'dalton-wi': {
    name: 'Dalton',
    postalCode: '53926',
  },
  'dalton-city-il': {
    name: 'Dalton City',
    postalCode: '61925',
  },
  'daly-city-ca': {
    name: 'Daly City',
    postalCode: '94014',
  },
  'dalzell-il': {
    name: 'Dalzell',
    postalCode: '61320',
  },
  'dalzell-sc': {
    name: 'Dalzell',
    postalCode: '29040',
  },
  'damar-ks': {
    name: 'Damar',
    postalCode: '67632',
  },
  'damariscotta-me': {
    name: 'Damariscotta',
    postalCode: '04543',
  },
  'damascus-ar': {
    name: 'Damascus',
    postalCode: '72039',
  },
  'damascus-ga': {
    name: 'Damascus',
    postalCode: '39841',
  },
  'damascus-md': {
    name: 'Damascus',
    postalCode: '20872',
  },
  'damascus-or': {
    name: 'Damascus',
    postalCode: '97089',
  },
  'damascus-pa': {
    name: 'Damascus',
    postalCode: '18415',
  },
  'damascus-va': {
    name: 'Damascus',
    postalCode: '24236',
  },
  'dameron-md': {
    name: 'Dameron',
    postalCode: '20628',
  },
  'dammeron-valley-ut': {
    name: 'Dammeron Valley',
    postalCode: '84783',
  },
  'damon-tx': {
    name: 'Damon',
    postalCode: '77430',
  },
  'dana-ia': {
    name: 'Dana',
    postalCode: '50064',
  },
  'dana-il': {
    name: 'Dana',
    postalCode: '61321',
  },
  'dana-in': {
    name: 'Dana',
    postalCode: '47847',
  },
  'dana-ky': {
    name: 'Dana',
    postalCode: '41615',
  },
  'dana-point-ca': {
    name: 'Dana Point',
    postalCode: '92629',
  },
  'danbury-ct': {
    name: 'Danbury',
    postalCode: '06810',
  },
  'danbury-ia': {
    name: 'Danbury',
    postalCode: '51019',
  },
  'danbury-nc': {
    name: 'Danbury',
    postalCode: '27016',
  },
  'danbury-ne': {
    name: 'Danbury',
    postalCode: '69026',
  },
  'danbury-nh': {
    name: 'Danbury',
    postalCode: '03230',
  },
  'danbury-tx': {
    name: 'Danbury',
    postalCode: '77534',
  },
  'danbury-wi': {
    name: 'Danbury',
    postalCode: '54830',
  },
  'danby-vt': {
    name: 'Danby',
    postalCode: '05739',
  },
  'dandridge-tn': {
    name: 'Dandridge',
    postalCode: '37725',
  },
  'dane-wi': {
    name: 'Dane',
    postalCode: '53529',
  },
  'danese-wv': {
    name: 'Danese',
    postalCode: '25831',
  },
  'danforth-il': {
    name: 'Danforth',
    postalCode: '60930',
  },
  'danforth-me': {
    name: 'Danforth',
    postalCode: '04424',
  },
  'dania-fl': {
    name: 'Dania',
    postalCode: '33004',
  },
  'daniel-wy': {
    name: 'Daniel',
    postalCode: '83115',
  },
  'daniels-wv': {
    name: 'Daniels',
    postalCode: '25832',
  },
  'danielson-ct': {
    name: 'Danielson',
    postalCode: '06239',
  },
  'danielsville-ga': {
    name: 'Danielsville',
    postalCode: '30633',
  },
  'danielsville-pa': {
    name: 'Danielsville',
    postalCode: '18038',
  },
  'dannebrog-ne': {
    name: 'Dannebrog',
    postalCode: '68831',
  },
  'dansville-mi': {
    name: 'Dansville',
    postalCode: '48819',
  },
  'dansville-ny': {
    name: 'Dansville',
    postalCode: '14437',
  },
  'dante-sd': {
    name: 'Dante',
    postalCode: '57329',
  },
  'dante-va': {
    name: 'Dante',
    postalCode: '24237',
  },
  'danube-mn': {
    name: 'Danube',
    postalCode: '56230',
  },
  'danvers-il': {
    name: 'Danvers',
    postalCode: '61732',
  },
  'danvers-ma': {
    name: 'Danvers',
    postalCode: '01923',
  },
  'danvers-mn': {
    name: 'Danvers',
    postalCode: '56231',
  },
  'danville-al': {
    name: 'Danville',
    postalCode: '35619',
  },
  'danville-ar': {
    name: 'Danville',
    postalCode: '72833',
  },
  'danville-ca': {
    name: 'Danville',
    postalCode: '94506',
  },
  'danville-ga': {
    name: 'Danville',
    postalCode: '31017',
  },
  'danville-ia': {
    name: 'Danville',
    postalCode: '52623',
  },
  'danville-il': {
    name: 'Danville',
    postalCode: '61832',
  },
  'danville-in': {
    name: 'Danville',
    postalCode: '46122',
  },
  'danville-ks': {
    name: 'Danville',
    postalCode: '67036',
  },
  'danville-ky': {
    name: 'Danville',
    postalCode: '40422',
  },
  'danville-nh': {
    name: 'Danville',
    postalCode: '03819',
  },
  'danville-oh': {
    name: 'Danville',
    postalCode: '43014',
  },
  'danville-pa': {
    name: 'Danville',
    postalCode: '17821',
  },
  'danville-va': {
    name: 'Danville',
    postalCode: '24540',
  },
  'danville-vt': {
    name: 'Danville',
    postalCode: '05828',
  },
  'danville-wa': {
    name: 'Danville',
    postalCode: '99121',
  },
  'danville-wv': {
    name: 'Danville',
    postalCode: '25053',
  },
  'daphne-al': {
    name: 'Daphne',
    postalCode: '36526',
  },
  'darby-mt': {
    name: 'Darby',
    postalCode: '59829',
  },
  'darby-pa': {
    name: 'Darby',
    postalCode: '19023',
  },
  'dardanelle-ar': {
    name: 'Dardanelle',
    postalCode: '72834',
  },
  'darden-tn': {
    name: 'Darden',
    postalCode: '38328',
  },
  'darien-ct': {
    name: 'Darien',
    postalCode: '06820',
  },
  'darien-ga': {
    name: 'Darien',
    postalCode: '31305',
  },
  'darien-il': {
    name: 'Darien',
    postalCode: '60561',
  },
  'darien-wi': {
    name: 'Darien',
    postalCode: '53114',
  },
  'darien-center-ny': {
    name: 'Darien Center',
    postalCode: '14040',
  },
  'darlington-in': {
    name: 'Darlington',
    postalCode: '47940',
  },
  'darlington-md': {
    name: 'Darlington',
    postalCode: '21034',
  },
  'darlington-mo': {
    name: 'Darlington',
    postalCode: '64438',
  },
  'darlington-pa': {
    name: 'Darlington',
    postalCode: '16115',
  },
  'darlington-sc': {
    name: 'Darlington',
    postalCode: '29532',
  },
  'darlington-wi': {
    name: 'Darlington',
    postalCode: '53530',
  },
  'darrington-wa': {
    name: 'Darrington',
    postalCode: '98241',
  },
  'darrow-la': {
    name: 'Darrow',
    postalCode: '70725',
  },
  'darwin-mn': {
    name: 'Darwin',
    postalCode: '55324',
  },
  'dassel-mn': {
    name: 'Dassel',
    postalCode: '55325',
  },
  'dateland-az': {
    name: 'Dateland',
    postalCode: '85333',
  },
  'datil-nm': {
    name: 'Datil',
    postalCode: '87821',
  },
  'daufuskie-island-sc': {
    name: 'Daufuskie Island',
    postalCode: '29915',
  },
  'dauphin-pa': {
    name: 'Dauphin',
    postalCode: '17018',
  },
  'dauphin-island-al': {
    name: 'Dauphin Island',
    postalCode: '36528',
  },
  'davenport-ca': {
    name: 'Davenport',
    postalCode: '95017',
  },
  'davenport-fl': {
    name: 'Davenport',
    postalCode: '33837',
  },
  'davenport-ia': {
    name: 'Davenport',
    postalCode: '52801',
  },
  'davenport-nd': {
    name: 'Davenport',
    postalCode: '58021',
  },
  'davenport-ne': {
    name: 'Davenport',
    postalCode: '68335',
  },
  'davenport-ny': {
    name: 'Davenport',
    postalCode: '13750',
  },
  'davenport-va': {
    name: 'Davenport',
    postalCode: '24239',
  },
  'davenport-wa': {
    name: 'Davenport',
    postalCode: '99122',
  },
  'davenport-center-ny': {
    name: 'Davenport Center',
    postalCode: '13751',
  },
  'davey-ne': {
    name: 'Davey',
    postalCode: '68336',
  },
  'david-ky': {
    name: 'David',
    postalCode: '41616',
  },
  'david-city-ne': {
    name: 'David City',
    postalCode: '68632',
  },
  'davidson-nc': {
    name: 'Davidson',
    postalCode: '28036',
  },
  'davidson-ok': {
    name: 'Davidson',
    postalCode: '73530',
  },
  'davidsonville-md': {
    name: 'Davidsonville',
    postalCode: '21035',
  },
  'davidsville-pa': {
    name: 'Davidsville',
    postalCode: '15928',
  },
  'davin-wv': {
    name: 'Davin',
    postalCode: '25617',
  },
  'davis-ca': {
    name: 'Davis',
    postalCode: '95616',
  },
  'davis-il': {
    name: 'Davis',
    postalCode: '61019',
  },
  'davis-nc': {
    name: 'Davis',
    postalCode: '28524',
  },
  'davis-ok': {
    name: 'Davis',
    postalCode: '73030',
  },
  'davis-sd': {
    name: 'Davis',
    postalCode: '57021',
  },
  'davis-wv': {
    name: 'Davis',
    postalCode: '26260',
  },
  'davis-city-ia': {
    name: 'Davis City',
    postalCode: '50065',
  },
  'davis-creek-ca': {
    name: 'Davis Creek',
    postalCode: '96108',
  },
  'davis-junction-il': {
    name: 'Davis Junction',
    postalCode: '61020',
  },
  'davis-wharf-va': {
    name: 'Davis Wharf',
    postalCode: '23345',
  },
  'davisboro-ga': {
    name: 'Davisboro',
    postalCode: '31018',
  },
  'davisburg-mi': {
    name: 'Davisburg',
    postalCode: '48350',
  },
  'davison-mi': {
    name: 'Davison',
    postalCode: '48423',
  },
  'daviston-al': {
    name: 'Daviston',
    postalCode: '36256',
  },
  'davisville-mo': {
    name: 'Davisville',
    postalCode: '65456',
  },
  'davisville-wv': {
    name: 'Davisville',
    postalCode: '26142',
  },
  'davy-wv': {
    name: 'Davy',
    postalCode: '24828',
  },
  'dawn-mo': {
    name: 'Dawn',
    postalCode: '64638',
  },
  'dawson-al': {
    name: 'Dawson',
    postalCode: '35963',
  },
  'dawson-ga': {
    name: 'Dawson',
    postalCode: '39842',
  },
  'dawson-ia': {
    name: 'Dawson',
    postalCode: '50066',
  },
  'dawson-il': {
    name: 'Dawson',
    postalCode: '62520',
  },
  'dawson-mn': {
    name: 'Dawson',
    postalCode: '56232',
  },
  'dawson-nd': {
    name: 'Dawson',
    postalCode: '58428',
  },
  'dawson-ne': {
    name: 'Dawson',
    postalCode: '68337',
  },
  'dawson-pa': {
    name: 'Dawson',
    postalCode: '15428',
  },
  'dawson-tx': {
    name: 'Dawson',
    postalCode: '76639',
  },
  'dawson-springs-ky': {
    name: 'Dawson Springs',
    postalCode: '42408',
  },
  'dawsonville-ga': {
    name: 'Dawsonville',
    postalCode: '30534',
  },
  'daykin-ne': {
    name: 'Daykin',
    postalCode: '68338',
  },
  'days-creek-or': {
    name: 'Days Creek',
    postalCode: '97429',
  },
  'dayton-ia': {
    name: 'Dayton',
    postalCode: '50530',
  },
  'dayton-id': {
    name: 'Dayton',
    postalCode: '83232',
  },
  'dayton-ky': {
    name: 'Dayton',
    postalCode: '41074',
  },
  'dayton-md': {
    name: 'Dayton',
    postalCode: '21036',
  },
  'dayton-mn': {
    name: 'Dayton',
    postalCode: '55327',
  },
  'dayton-mt': {
    name: 'Dayton',
    postalCode: '59914',
  },
  'dayton-nj': {
    name: 'Dayton',
    postalCode: '08810',
  },
  'dayton-nv': {
    name: 'Dayton',
    postalCode: '89403',
  },
  'dayton-ny': {
    name: 'Dayton',
    postalCode: '14041',
  },
  'dayton-oh': {
    name: 'Dayton',
    postalCode: '45400',
  },
  'dayton-or': {
    name: 'Dayton',
    postalCode: '97114',
  },
  'dayton-pa': {
    name: 'Dayton',
    postalCode: '16222',
  },
  'dayton-tn': {
    name: 'Dayton',
    postalCode: '37321',
  },
  'dayton-tx': {
    name: 'Dayton',
    postalCode: '77535',
  },
  'dayton-va': {
    name: 'Dayton',
    postalCode: '22821',
  },
  'dayton-wa': {
    name: 'Dayton',
    postalCode: '99328',
  },
  'dayton-wy': {
    name: 'Dayton',
    postalCode: '82836',
  },
  'daytona-beach-fl': {
    name: 'Daytona Beach',
    postalCode: '32114',
  },
  'dayville-ct': {
    name: 'Dayville',
    postalCode: '06241',
  },
  'dayville-or': {
    name: 'Dayville',
    postalCode: '97825',
  },
  'dazey-nd': {
    name: 'Dazey',
    postalCode: '58429',
  },
  'de-beque-co': {
    name: 'De Beque',
    postalCode: '81630',
  },
  'de-berry-tx': {
    name: 'De Berry',
    postalCode: '75639',
  },
  'de-graff-oh': {
    name: 'De Graff',
    postalCode: '43318',
  },
  'de-kalb-mo': {
    name: 'De Kalb',
    postalCode: '64440',
  },
  'de-kalb-ms': {
    name: 'De Kalb',
    postalCode: '39328',
  },
  'de-kalb-tx': {
    name: 'De Kalb',
    postalCode: '75559',
  },
  'de-kalb-junction-ny': {
    name: 'De Kalb Junction',
    postalCode: '13630',
  },
  'de-land-il': {
    name: 'De Land',
    postalCode: '61839',
  },
  'de-leon-tx': {
    name: 'De Leon',
    postalCode: '76444',
  },
  'de-leon-springs-fl': {
    name: 'De Leon Springs',
    postalCode: '32130',
  },
  'de-mossville-ky': {
    name: 'De Mossville',
    postalCode: '41033',
  },
  'de-pere-wi': {
    name: 'De Pere',
    postalCode: '54115',
  },
  'de-peyster-ny': {
    name: 'De Peyster',
    postalCode: '13633',
  },
  'de-queen-ar': {
    name: 'De Queen',
    postalCode: '71832',
  },
  'de-ruyter-ny': {
    name: 'De Ruyter',
    postalCode: '13052',
  },
  'de-smet-sd': {
    name: 'De Smet',
    postalCode: '57231',
  },
  'de-soto-ga': {
    name: 'De Soto',
    postalCode: '31743',
  },
  'de-soto-ia': {
    name: 'De Soto',
    postalCode: '50069',
  },
  'de-soto-il': {
    name: 'De Soto',
    postalCode: '62924',
  },
  'de-soto-ks': {
    name: 'De Soto',
    postalCode: '66018',
  },
  'de-soto-mo': {
    name: 'De Soto',
    postalCode: '63020',
  },
  'de-soto-wi': {
    name: 'De Soto',
    postalCode: '54624',
  },
  'de-tour-village-mi': {
    name: 'De Tour Village',
    postalCode: '49725',
  },
  'de-valls-bluff-ar': {
    name: 'De Valls Bluff',
    postalCode: '72041',
  },
  'de-witt-ar': {
    name: 'De Witt',
    postalCode: '72042',
  },
  'de-witt-ia': {
    name: 'De Witt',
    postalCode: '52742',
  },
  'de-witt-mo': {
    name: 'De Witt',
    postalCode: '64639',
  },
  'de-witt-ne': {
    name: 'De Witt',
    postalCode: '68341',
  },
  'de-young-pa': {
    name: 'De Young',
    postalCode: '16728',
  },
  'deadwood-or': {
    name: 'Deadwood',
    postalCode: '97430',
  },
  'deadwood-sd': {
    name: 'Deadwood',
    postalCode: '57732',
  },
  'deal-nj': {
    name: 'Deal',
    postalCode: '07723',
  },
  'deal-island-md': {
    name: 'Deal Island',
    postalCode: '21821',
  },
  'deale-md': {
    name: 'Deale',
    postalCode: '20751',
  },
  'deane-ky': {
    name: 'Deane',
    postalCode: '41812',
  },
  'deansboro-ny': {
    name: 'Deansboro',
    postalCode: '13328',
  },
  'dearborn-mi': {
    name: 'Dearborn',
    postalCode: '48120',
  },
  'dearborn-mo': {
    name: 'Dearborn',
    postalCode: '64439',
  },
  'dearborn-heights-mi': {
    name: 'Dearborn Heights',
    postalCode: '48125',
  },
  'dearing-ga': {
    name: 'Dearing',
    postalCode: '30808',
  },
  'deary-id': {
    name: 'Deary',
    postalCode: '83823',
  },
  'death-valley-ca': {
    name: 'Death Valley',
    postalCode: '92328',
  },
  'deatsville-al': {
    name: 'Deatsville',
    postalCode: '36022',
  },
  'deaver-wy': {
    name: 'Deaver',
    postalCode: '82421',
  },
  'debary-fl': {
    name: 'Debary',
    postalCode: '32713',
  },
  'debord-ky': {
    name: 'Debord',
    postalCode: '41214',
  },
  'decatur-al': {
    name: 'Decatur',
    postalCode: '35601',
  },
  'decatur-ar': {
    name: 'Decatur',
    postalCode: '72722',
  },
  'decatur-ga': {
    name: 'Decatur',
    postalCode: '30030',
  },
  'decatur-ia': {
    name: 'Decatur',
    postalCode: '50067',
  },
  'decatur-il': {
    name: 'Decatur',
    postalCode: '62521',
  },
  'decatur-in': {
    name: 'Decatur',
    postalCode: '46733',
  },
  'decatur-mi': {
    name: 'Decatur',
    postalCode: '49045',
  },
  'decatur-ms': {
    name: 'Decatur',
    postalCode: '39327',
  },
  'decatur-ne': {
    name: 'Decatur',
    postalCode: '68020',
  },
  'decatur-tn': {
    name: 'Decatur',
    postalCode: '37322',
  },
  'decatur-tx': {
    name: 'Decatur',
    postalCode: '76234',
  },
  'decaturville-tn': {
    name: 'Decaturville',
    postalCode: '38329',
  },
  'decherd-tn': {
    name: 'Decherd',
    postalCode: '37324',
  },
  'decker-in': {
    name: 'Decker',
    postalCode: '47524',
  },
  'decker-mi': {
    name: 'Decker',
    postalCode: '48426',
  },
  'decker-mt': {
    name: 'Decker',
    postalCode: '59025',
  },
  'deckerville-mi': {
    name: 'Deckerville',
    postalCode: '48427',
  },
  'declo-id': {
    name: 'Declo',
    postalCode: '83323',
  },
  'decorah-ia': {
    name: 'Decorah',
    postalCode: '52101',
  },
  'dedham-ia': {
    name: 'Dedham',
    postalCode: '51440',
  },
  'dedham-ma': {
    name: 'Dedham',
    postalCode: '02026',
  },
  'deep-gap-nc': {
    name: 'Deep Gap',
    postalCode: '28618',
  },
  'deep-river-ct': {
    name: 'Deep River',
    postalCode: '06417',
  },
  'deep-river-ia': {
    name: 'Deep River',
    postalCode: '52222',
  },
  'deep-run-nc': {
    name: 'Deep Run',
    postalCode: '28525',
  },
  'deepwater-mo': {
    name: 'Deepwater',
    postalCode: '64740',
  },
  'deer-ar': {
    name: 'Deer',
    postalCode: '72628',
  },
  'deer-creek-il': {
    name: 'Deer Creek',
    postalCode: '61733',
  },
  'deer-creek-mn': {
    name: 'Deer Creek',
    postalCode: '56527',
  },
  'deer-creek-ok': {
    name: 'Deer Creek',
    postalCode: '74636',
  },
  'deer-grove-il': {
    name: 'Deer Grove',
    postalCode: '61243',
  },
  'deer-island-or': {
    name: 'Deer Island',
    postalCode: '97054',
  },
  'deer-isle-me': {
    name: 'Deer Isle',
    postalCode: '04627',
  },
  'deer-lodge-mt': {
    name: 'Deer Lodge',
    postalCode: '59722',
  },
  'deer-lodge-tn': {
    name: 'Deer Lodge',
    postalCode: '37726',
  },
  'deer-park-al': {
    name: 'Deer Park',
    postalCode: '36529',
  },
  'deer-park-ca': {
    name: 'Deer Park',
    postalCode: '94576',
  },
  'deer-park-ny': {
    name: 'Deer Park',
    postalCode: '11729',
  },
  'deer-park-tx': {
    name: 'Deer Park',
    postalCode: '77536',
  },
  'deer-park-wa': {
    name: 'Deer Park',
    postalCode: '99006',
  },
  'deer-park-wi': {
    name: 'Deer Park',
    postalCode: '54007',
  },
  'deer-river-mn': {
    name: 'Deer River',
    postalCode: '56636',
  },
  'deer-trail-co': {
    name: 'Deer Trail',
    postalCode: '80105',
  },
  'deerbrook-wi': {
    name: 'Deerbrook',
    postalCode: '54424',
  },
  'deerfield-il': {
    name: 'Deerfield',
    postalCode: '60015',
  },
  'deerfield-ks': {
    name: 'Deerfield',
    postalCode: '67838',
  },
  'deerfield-ma': {
    name: 'Deerfield',
    postalCode: '01342',
  },
  'deerfield-mi': {
    name: 'Deerfield',
    postalCode: '49238',
  },
  'deerfield-mo': {
    name: 'Deerfield',
    postalCode: '64741',
  },
  'deerfield-nh': {
    name: 'Deerfield',
    postalCode: '03037',
  },
  'deerfield-oh': {
    name: 'Deerfield',
    postalCode: '44411',
  },
  'deerfield-va': {
    name: 'Deerfield',
    postalCode: '24432',
  },
  'deerfield-wi': {
    name: 'Deerfield',
    postalCode: '53531',
  },
  'deerfield-beach-fl': {
    name: 'Deerfield Beach',
    postalCode: '33441',
  },
  'deering-nd': {
    name: 'Deering',
    postalCode: '58731',
  },
  'deerton-mi': {
    name: 'Deerton',
    postalCode: '49822',
  },
  'deerwood-mn': {
    name: 'Deerwood',
    postalCode: '56444',
  },
  'deeth-nv': {
    name: 'Deeth',
    postalCode: '89823',
  },
  'defiance-ia': {
    name: 'Defiance',
    postalCode: '51527',
  },
  'defiance-mo': {
    name: 'Defiance',
    postalCode: '63341',
  },
  'defiance-oh': {
    name: 'Defiance',
    postalCode: '43512',
  },
  'deford-mi': {
    name: 'Deford',
    postalCode: '48729',
  },
  'deforest-wi': {
    name: 'Deforest',
    postalCode: '53532',
  },
  'defuniak-springs-fl': {
    name: 'Defuniak Springs',
    postalCode: '32433',
  },
  'dekalb-il': {
    name: 'Dekalb',
    postalCode: '60115',
  },
  'del-mar-ca': {
    name: 'Del Mar',
    postalCode: '92014',
  },
  'del-norte-co': {
    name: 'Del Norte',
    postalCode: '81132',
  },
  'del-rey-ca': {
    name: 'Del Rey',
    postalCode: '93616',
  },
  'del-rio-tn': {
    name: 'Del Rio',
    postalCode: '37727',
  },
  'del-rio-tx': {
    name: 'Del Rio',
    postalCode: '78840',
  },
  'del-valle-tx': {
    name: 'Del Valle',
    postalCode: '78617',
  },
  'delafield-wi': {
    name: 'Delafield',
    postalCode: '53018',
  },
  'delancey-ny': {
    name: 'Delancey',
    postalCode: '13752',
  },
  'deland-fl': {
    name: 'Deland',
    postalCode: '32720',
  },
  'delano-ca': {
    name: 'Delano',
    postalCode: '93215',
  },
  'delano-mn': {
    name: 'Delano',
    postalCode: '55328',
  },
  'delano-tn': {
    name: 'Delano',
    postalCode: '37325',
  },
  'delanson-ny': {
    name: 'Delanson',
    postalCode: '12053',
  },
  'delaplaine-ar': {
    name: 'Delaplaine',
    postalCode: '72425',
  },
  'delaplane-va': {
    name: 'Delaplane',
    postalCode: '20144',
  },
  'delavan-il': {
    name: 'Delavan',
    postalCode: '61734',
  },
  'delavan-mn': {
    name: 'Delavan',
    postalCode: '56023',
  },
  'delavan-wi': {
    name: 'Delavan',
    postalCode: '53115',
  },
  'delaware-ar': {
    name: 'Delaware',
    postalCode: '72835',
  },
  'delaware-ia': {
    name: 'Delaware',
    postalCode: '52036',
  },
  'delaware-oh': {
    name: 'Delaware',
    postalCode: '43015',
  },
  'delaware-ok': {
    name: 'Delaware',
    postalCode: '74027',
  },
  'delbarton-wv': {
    name: 'Delbarton',
    postalCode: '25670',
  },
  'delcambre-la': {
    name: 'Delcambre',
    postalCode: '70528',
  },
  'delco-nc': {
    name: 'Delco',
    postalCode: '28436',
  },
  'delevan-ny': {
    name: 'Delevan',
    postalCode: '14042',
  },
  'delhi-ca': {
    name: 'Delhi',
    postalCode: '95315',
  },
  'delhi-ia': {
    name: 'Delhi',
    postalCode: '52223',
  },
  'delhi-la': {
    name: 'Delhi',
    postalCode: '71232',
  },
  'delhi-ny': {
    name: 'Delhi',
    postalCode: '13753',
  },
  'delia-ks': {
    name: 'Delia',
    postalCode: '66418',
  },
  'delight-ar': {
    name: 'Delight',
    postalCode: '71940',
  },
  'dell-rapids-sd': {
    name: 'Dell Rapids',
    postalCode: '57022',
  },
  'dellrose-tn': {
    name: 'Dellrose',
    postalCode: '38453',
  },
  'dellroy-oh': {
    name: 'Dellroy',
    postalCode: '44620',
  },
  'delmar-de': {
    name: 'Delmar',
    postalCode: '19940',
  },
  'delmar-ia': {
    name: 'Delmar',
    postalCode: '52037',
  },
  'delmar-md': {
    name: 'Delmar',
    postalCode: '21875',
  },
  'delmar-ny': {
    name: 'Delmar',
    postalCode: '12054',
  },
  'delmita-tx': {
    name: 'Delmita',
    postalCode: '78536',
  },
  'delmont-nj': {
    name: 'Delmont',
    postalCode: '08314',
  },
  'delmont-pa': {
    name: 'Delmont',
    postalCode: '15626',
  },
  'delmont-sd': {
    name: 'Delmont',
    postalCode: '57330',
  },
  'deloit-ia': {
    name: 'Deloit',
    postalCode: '51441',
  },
  'delphi-in': {
    name: 'Delphi',
    postalCode: '46923',
  },
  'delphia-ky': {
    name: 'Delphia',
    postalCode: '41735',
  },
  'delphos-ks': {
    name: 'Delphos',
    postalCode: '67436',
  },
  'delphos-oh': {
    name: 'Delphos',
    postalCode: '45833',
  },
  'delray-wv': {
    name: 'Delray',
    postalCode: '26714',
  },
  'delray-beach-fl': {
    name: 'Delray Beach',
    postalCode: '33444',
  },
  'delta-al': {
    name: 'Delta',
    postalCode: '36258',
  },
  'delta-co': {
    name: 'Delta',
    postalCode: '81416',
  },
  'delta-ia': {
    name: 'Delta',
    postalCode: '52550',
  },
  'delta-oh': {
    name: 'Delta',
    postalCode: '43515',
  },
  'delta-pa': {
    name: 'Delta',
    postalCode: '17314',
  },
  'delta-ut': {
    name: 'Delta',
    postalCode: '84624',
  },
  'delta-junction-ak': {
    name: 'Delta Junction',
    postalCode: '99737',
  },
  'deltaville-va': {
    name: 'Deltaville',
    postalCode: '23043',
  },
  'delton-mi': {
    name: 'Delton',
    postalCode: '49046',
  },
  'deltona-fl': {
    name: 'Deltona',
    postalCode: '32725',
  },
  'dema-ky': {
    name: 'Dema',
    postalCode: '41859',
  },
  'demarest-nj': {
    name: 'Demarest',
    postalCode: '07627',
  },
  'deming-nm': {
    name: 'Deming',
    postalCode: '88030',
  },
  'deming-wa': {
    name: 'Deming',
    postalCode: '98244',
  },
  'demopolis-al': {
    name: 'Demopolis',
    postalCode: '36732',
  },
  'demorest-ga': {
    name: 'Demorest',
    postalCode: '30535',
  },
  'demotte-in': {
    name: 'Demotte',
    postalCode: '46310',
  },
  'denair-ca': {
    name: 'Denair',
    postalCode: '95316',
  },
  'dendron-va': {
    name: 'Dendron',
    postalCode: '23839',
  },
  'denham-springs-la': {
    name: 'Denham Springs',
    postalCode: '70706',
  },
  'denhoff-nd': {
    name: 'Denhoff',
    postalCode: '58430',
  },
  'denison-ia': {
    name: 'Denison',
    postalCode: '51442',
  },
  'denison-ks': {
    name: 'Denison',
    postalCode: '66419',
  },
  'denison-tx': {
    name: 'Denison',
    postalCode: '75020',
  },
  'denmark-me': {
    name: 'Denmark',
    postalCode: '04022',
  },
  'denmark-sc': {
    name: 'Denmark',
    postalCode: '29042',
  },
  'denmark-tn': {
    name: 'Denmark',
    postalCode: '38391',
  },
  'denmark-wi': {
    name: 'Denmark',
    postalCode: '54208',
  },
  'dennard-ar': {
    name: 'Dennard',
    postalCode: '72629',
  },
  'dennis-ks': {
    name: 'Dennis',
    postalCode: '67341',
  },
  'dennis-ma': {
    name: 'Dennis',
    postalCode: '02638',
  },
  'dennis-ms': {
    name: 'Dennis',
    postalCode: '38838',
  },
  'dennis-port-ma': {
    name: 'Dennis Port',
    postalCode: '02639',
  },
  'dennison-il': {
    name: 'Dennison',
    postalCode: '62423',
  },
  'dennison-mn': {
    name: 'Dennison',
    postalCode: '55018',
  },
  'dennison-oh': {
    name: 'Dennison',
    postalCode: '44621',
  },
  'denniston-ky': {
    name: 'Denniston',
    postalCode: '40316',
  },
  'dennysville-me': {
    name: 'Dennysville',
    postalCode: '04628',
  },
  'dent-mn': {
    name: 'Dent',
    postalCode: '56528',
  },
  'denton-ga': {
    name: 'Denton',
    postalCode: '31532',
  },
  'denton-ks': {
    name: 'Denton',
    postalCode: '66017',
  },
  'denton-ky': {
    name: 'Denton',
    postalCode: '41132',
  },
  'denton-md': {
    name: 'Denton',
    postalCode: '21629',
  },
  'denton-mt': {
    name: 'Denton',
    postalCode: '59430',
  },
  'denton-nc': {
    name: 'Denton',
    postalCode: '27239',
  },
  'denton-ne': {
    name: 'Denton',
    postalCode: '68339',
  },
  'denton-tx': {
    name: 'Denton',
    postalCode: '76201',
  },
  'denver-co': {
    name: 'Denver',
    postalCode: '80202',
  },
  'denver-ia': {
    name: 'Denver',
    postalCode: '50622',
  },
  'denver-in': {
    name: 'Denver',
    postalCode: '46926',
  },
  'denver-mo': {
    name: 'Denver',
    postalCode: '64441',
  },
  'denver-nc': {
    name: 'Denver',
    postalCode: '28037',
  },
  'denver-ny': {
    name: 'Denver',
    postalCode: '12421',
  },
  'denver-pa': {
    name: 'Denver',
    postalCode: '17517',
  },
  'denver-city-tx': {
    name: 'Denver City',
    postalCode: '79323',
  },
  'denville-nj': {
    name: 'Denville',
    postalCode: '07834',
  },
  'depauw-in': {
    name: 'Depauw',
    postalCode: '47115',
  },
  'depew-ny': {
    name: 'Depew',
    postalCode: '14043',
  },
  'depew-ok': {
    name: 'Depew',
    postalCode: '74028',
  },
  'depoe-bay-or': {
    name: 'Depoe Bay',
    postalCode: '97341',
  },
  'deport-tx': {
    name: 'Deport',
    postalCode: '75435',
  },
  'deposit-ny': {
    name: 'Deposit',
    postalCode: '13754',
  },
  'deputy-in': {
    name: 'Deputy',
    postalCode: '47230',
  },
  'dequincy-la': {
    name: 'Dequincy',
    postalCode: '70633',
  },
  'derby-ct': {
    name: 'Derby',
    postalCode: '06418',
  },
  'derby-ia': {
    name: 'Derby',
    postalCode: '50068',
  },
  'derby-in': {
    name: 'Derby',
    postalCode: '47525',
  },
  'derby-ks': {
    name: 'Derby',
    postalCode: '67037',
  },
  'derby-ny': {
    name: 'Derby',
    postalCode: '14047',
  },
  'derby-vt': {
    name: 'Derby',
    postalCode: '05829',
  },
  'derby-line-vt': {
    name: 'Derby Line',
    postalCode: '05830',
  },
  'deridder-la': {
    name: 'Deridder',
    postalCode: '70634',
  },
  'dermott-ar': {
    name: 'Dermott',
    postalCode: '71638',
  },
  'derrick-city-pa': {
    name: 'Derrick City',
    postalCode: '16727',
  },
  'derry-nh': {
    name: 'Derry',
    postalCode: '03038',
  },
  'derry-nm': {
    name: 'Derry',
    postalCode: '87933',
  },
  'derry-pa': {
    name: 'Derry',
    postalCode: '15627',
  },
  'derwood-md': {
    name: 'Derwood',
    postalCode: '20855',
  },
  'des-allemands-la': {
    name: 'Des Allemands',
    postalCode: '70030',
  },
  'des-arc-ar': {
    name: 'Des Arc',
    postalCode: '72040',
  },
  'des-arc-mo': {
    name: 'Des Arc',
    postalCode: '63636',
  },
  'des-lacs-nd': {
    name: 'Des Lacs',
    postalCode: '58733',
  },
  'des-moines-ia': {
    name: 'Des Moines',
    postalCode: '50307',
  },
  'des-moines-nm': {
    name: 'Des Moines',
    postalCode: '88418',
  },
  'des-plaines-il': {
    name: 'Des Plaines',
    postalCode: '60016',
  },
  'descanso-ca': {
    name: 'Descanso',
    postalCode: '91916',
  },
  'desdemona-tx': {
    name: 'Desdemona',
    postalCode: '76445',
  },
  'desert-hot-springs-ca': {
    name: 'Desert Hot Springs',
    postalCode: '92240',
  },
  'desha-ar': {
    name: 'Desha',
    postalCode: '72527',
  },
  'deshler-ne': {
    name: 'Deshler',
    postalCode: '68340',
  },
  'deshler-oh': {
    name: 'Deshler',
    postalCode: '43516',
  },
  'desmet-id': {
    name: 'Desmet',
    postalCode: '83824',
  },
  'desoto-tx': {
    name: 'Desoto',
    postalCode: '75115',
  },
  'destin-fl': {
    name: 'Destin',
    postalCode: '32541',
  },
  'destrehan-la': {
    name: 'Destrehan',
    postalCode: '70047',
  },
  'detroit-al': {
    name: 'Detroit',
    postalCode: '35552',
  },
  'detroit-me': {
    name: 'Detroit',
    postalCode: '04929',
  },
  'detroit-mi': {
    name: 'Detroit',
    postalCode: '48201',
  },
  'detroit-tx': {
    name: 'Detroit',
    postalCode: '75436',
  },
  'detroit-lakes-mn': {
    name: 'Detroit Lakes',
    postalCode: '56501',
  },
  'devens-ma': {
    name: 'Devens',
    postalCode: '01434',
  },
  'devers-tx': {
    name: 'Devers',
    postalCode: '77538',
  },
  'deville-la': {
    name: 'Deville',
    postalCode: '71328',
  },
  'devils-elbow-mo': {
    name: 'Devils Elbow',
    postalCode: '65457',
  },
  'devils-lake-nd': {
    name: 'Devils Lake',
    postalCode: '58301',
  },
  'devils-tower-wy': {
    name: 'Devils Tower',
    postalCode: '82714',
  },
  'devine-tx': {
    name: 'Devine',
    postalCode: '78016',
  },
  'devol-ok': {
    name: 'Devol',
    postalCode: '73531',
  },
  'devon-pa': {
    name: 'Devon',
    postalCode: '19333',
  },
  'deweese-ne': {
    name: 'Deweese',
    postalCode: '68934',
  },
  'dewey-az': {
    name: 'Dewey',
    postalCode: '86327',
  },
  'dewey-il': {
    name: 'Dewey',
    postalCode: '61840',
  },
  'dewey-ok': {
    name: 'Dewey',
    postalCode: '74029',
  },
  'deweyville-ut': {
    name: 'Deweyville',
    postalCode: '84309',
  },
  'dewitt-il': {
    name: 'Dewitt',
    postalCode: '61735',
  },
  'dewitt-mi': {
    name: 'Dewitt',
    postalCode: '48820',
  },
  'dewitt-va': {
    name: 'Dewitt',
    postalCode: '23840',
  },
  'dewittville-ny': {
    name: 'Dewittville',
    postalCode: '14728',
  },
  'dewy-rose-ga': {
    name: 'Dewy Rose',
    postalCode: '30634',
  },
  'dexter-ga': {
    name: 'Dexter',
    postalCode: '31019',
  },
  'dexter-ia': {
    name: 'Dexter',
    postalCode: '50070',
  },
  'dexter-ks': {
    name: 'Dexter',
    postalCode: '67038',
  },
  'dexter-ky': {
    name: 'Dexter',
    postalCode: '42036',
  },
  'dexter-me': {
    name: 'Dexter',
    postalCode: '04930',
  },
  'dexter-mi': {
    name: 'Dexter',
    postalCode: '48130',
  },
  'dexter-mn': {
    name: 'Dexter',
    postalCode: '55926',
  },
  'dexter-mo': {
    name: 'Dexter',
    postalCode: '63841',
  },
  'dexter-nm': {
    name: 'Dexter',
    postalCode: '88230',
  },
  'dexter-ny': {
    name: 'Dexter',
    postalCode: '13634',
  },
  'dexter-or': {
    name: 'Dexter',
    postalCode: '97431',
  },
  'dexter-city-oh': {
    name: 'Dexter City',
    postalCode: '45727',
  },
  'diagonal-ia': {
    name: 'Diagonal',
    postalCode: '50845',
  },
  'diamond-mo': {
    name: 'Diamond',
    postalCode: '64840',
  },
  'diamond-oh': {
    name: 'Diamond',
    postalCode: '44412',
  },
  'diamond-or': {
    name: 'Diamond',
    postalCode: '97722',
  },
  'diamond-bar-ca': {
    name: 'Diamond Bar',
    postalCode: '91765',
  },
  'diamond-point-ny': {
    name: 'Diamond Point',
    postalCode: '12824',
  },
  'diamond-springs-ca': {
    name: 'Diamond Springs',
    postalCode: '95619',
  },
  'diamondhead-ms': {
    name: 'Diamondhead',
    postalCode: '39525',
  },
  'diana-tx': {
    name: 'Diana',
    postalCode: '75640',
  },
  'diana-wv': {
    name: 'Diana',
    postalCode: '26217',
  },
  'diberville-ms': {
    name: 'Diberville',
    postalCode: '39540',
  },
  'diboll-tx': {
    name: 'Diboll',
    postalCode: '75941',
  },
  'dickens-ia': {
    name: 'Dickens',
    postalCode: '51333',
  },
  'dickens-ne': {
    name: 'Dickens',
    postalCode: '69132',
  },
  'dickens-tx': {
    name: 'Dickens',
    postalCode: '79229',
  },
  'dickerson-md': {
    name: 'Dickerson',
    postalCode: '20842',
  },
  'dickey-nd': {
    name: 'Dickey',
    postalCode: '58431',
  },
  'dickinson-al': {
    name: 'Dickinson',
    postalCode: '36436',
  },
  'dickinson-nd': {
    name: 'Dickinson',
    postalCode: '58601',
  },
  'dickinson-tx': {
    name: 'Dickinson',
    postalCode: '77539',
  },
  'dickinson-center-ny': {
    name: 'Dickinson Center',
    postalCode: '12930',
  },
  'dickson-tn': {
    name: 'Dickson',
    postalCode: '37055',
  },
  'dierks-ar': {
    name: 'Dierks',
    postalCode: '71833',
  },
  'dieterich-il': {
    name: 'Dieterich',
    postalCode: '62424',
  },
  'dietrich-id': {
    name: 'Dietrich',
    postalCode: '83324',
  },
  'diggs-va': {
    name: 'Diggs',
    postalCode: '23045',
  },
  'dighton-ks': {
    name: 'Dighton',
    postalCode: '67839',
  },
  'dighton-ma': {
    name: 'Dighton',
    postalCode: '02715',
  },
  'dike-ia': {
    name: 'Dike',
    postalCode: '50624',
  },
  'dike-tx': {
    name: 'Dike',
    postalCode: '75437',
  },
  'dill-city-ok': {
    name: 'Dill City',
    postalCode: '73641',
  },
  'dillard-ga': {
    name: 'Dillard',
    postalCode: '30537',
  },
  'dille-wv': {
    name: 'Dille',
    postalCode: '26617',
  },
  'diller-ne': {
    name: 'Diller',
    postalCode: '68342',
  },
  'dilley-tx': {
    name: 'Dilley',
    postalCode: '78017',
  },
  'dilliner-pa': {
    name: 'Dilliner',
    postalCode: '15327',
  },
  'dillon-co': {
    name: 'Dillon',
    postalCode: '80435',
  },
  'dillon-mt': {
    name: 'Dillon',
    postalCode: '59725',
  },
  'dillon-sc': {
    name: 'Dillon',
    postalCode: '29536',
  },
  'dillonvale-oh': {
    name: 'Dillonvale',
    postalCode: '43917',
  },
  'dillsboro-in': {
    name: 'Dillsboro',
    postalCode: '47018',
  },
  'dillsburg-pa': {
    name: 'Dillsburg',
    postalCode: '17019',
  },
  'dillwyn-va': {
    name: 'Dillwyn',
    postalCode: '23936',
  },
  'dilworth-mn': {
    name: 'Dilworth',
    postalCode: '56529',
  },
  'dime-box-tx': {
    name: 'Dime Box',
    postalCode: '77853',
  },
  'dimmitt-tx': {
    name: 'Dimmitt',
    postalCode: '79027',
  },
  'dimock-sd': {
    name: 'Dimock',
    postalCode: '57331',
  },
  'dimondale-mi': {
    name: 'Dimondale',
    postalCode: '48821',
  },
  'dingess-wv': {
    name: 'Dingess',
    postalCode: '25671',
  },
  'dingmans-ferry-pa': {
    name: 'Dingmans Ferry',
    postalCode: '18328',
  },
  'dinosaur-co': {
    name: 'Dinosaur',
    postalCode: '81610',
  },
  'dinuba-ca': {
    name: 'Dinuba',
    postalCode: '93618',
  },
  'dinwiddie-va': {
    name: 'Dinwiddie',
    postalCode: '23841',
  },
  'discovery-bay-ca': {
    name: 'Discovery Bay',
    postalCode: '94505',
  },
  'disputanta-va': {
    name: 'Disputanta',
    postalCode: '23842',
  },
  'district-heights-md': {
    name: 'District Heights',
    postalCode: '20747',
  },
  'dittmer-mo': {
    name: 'Dittmer',
    postalCode: '63023',
  },
  'divernon-il': {
    name: 'Divernon',
    postalCode: '62530',
  },
  'divide-co': {
    name: 'Divide',
    postalCode: '80814',
  },
  'divide-mt': {
    name: 'Divide',
    postalCode: '59727',
  },
  'dix-il': {
    name: 'Dix',
    postalCode: '62830',
  },
  'dix-ne': {
    name: 'Dix',
    postalCode: '69133',
  },
  'dixfield-me': {
    name: 'Dixfield',
    postalCode: '04224',
  },
  'dixie-ga': {
    name: 'Dixie',
    postalCode: '31629',
  },
  'dixie-wa': {
    name: 'Dixie',
    postalCode: '99329',
  },
  'dixie-wv': {
    name: 'Dixie',
    postalCode: '25059',
  },
  'dixmont-me': {
    name: 'Dixmont',
    postalCode: '04932',
  },
  'dixon-ca': {
    name: 'Dixon',
    postalCode: '95620',
  },
  'dixon-ia': {
    name: 'Dixon',
    postalCode: '52745',
  },
  'dixon-il': {
    name: 'Dixon',
    postalCode: '61021',
  },
  'dixon-ky': {
    name: 'Dixon',
    postalCode: '42409',
  },
  'dixon-mo': {
    name: 'Dixon',
    postalCode: '65459',
  },
  'dixon-mt': {
    name: 'Dixon',
    postalCode: '59831',
  },
  'dixon-ne': {
    name: 'Dixon',
    postalCode: '68732',
  },
  'dixon-wy': {
    name: 'Dixon',
    postalCode: '82323',
  },
  'dixon-springs-tn': {
    name: 'Dixon Springs',
    postalCode: '37057',
  },
  'dixons-mills-al': {
    name: 'Dixons Mills',
    postalCode: '36736',
  },
  'dobbins-ca': {
    name: 'Dobbins',
    postalCode: '95935',
  },
  'dobbs-ferry-ny': {
    name: 'Dobbs Ferry',
    postalCode: '10522',
  },
  'dobson-nc': {
    name: 'Dobson',
    postalCode: '27017',
  },
  'dodd-city-tx': {
    name: 'Dodd City',
    postalCode: '75438',
  },
  'doddridge-ar': {
    name: 'Doddridge',
    postalCode: '71834',
  },
  'doddsville-ms': {
    name: 'Doddsville',
    postalCode: '38736',
  },
  'dodge-nd': {
    name: 'Dodge',
    postalCode: '58625',
  },
  'dodge-ne': {
    name: 'Dodge',
    postalCode: '68633',
  },
  'dodge-wi': {
    name: 'Dodge',
    postalCode: '54625',
  },
  'dodge-center-mn': {
    name: 'Dodge Center',
    postalCode: '55927',
  },
  'dodge-city-ks': {
    name: 'Dodge City',
    postalCode: '67801',
  },
  'dodgeville-mi': {
    name: 'Dodgeville',
    postalCode: '49921',
  },
  'dodgeville-wi': {
    name: 'Dodgeville',
    postalCode: '53533',
  },
  'dodson-la': {
    name: 'Dodson',
    postalCode: '71422',
  },
  'dodson-mt': {
    name: 'Dodson',
    postalCode: '59524',
  },
  'dodson-tx': {
    name: 'Dodson',
    postalCode: '79230',
  },
  'doe-hill-va': {
    name: 'Doe Hill',
    postalCode: '24433',
  },
  'doe-run-mo': {
    name: 'Doe Run',
    postalCode: '63637',
  },
  'doerun-ga': {
    name: 'Doerun',
    postalCode: '31744',
  },
  'dola-oh': {
    name: 'Dola',
    postalCode: '45835',
  },
  'dolan-springs-az': {
    name: 'Dolan Springs',
    postalCode: '86441',
  },
  'doland-sd': {
    name: 'Doland',
    postalCode: '57436',
  },
  'dolgeville-ny': {
    name: 'Dolgeville',
    postalCode: '13329',
  },
  'dolliver-ia': {
    name: 'Dolliver',
    postalCode: '50531',
  },
  'dolomite-al': {
    name: 'Dolomite',
    postalCode: '35061',
  },
  'dolores-co': {
    name: 'Dolores',
    postalCode: '81323',
  },
  'dolph-ar': {
    name: 'Dolph',
    postalCode: '72528',
  },
  'dolphin-va': {
    name: 'Dolphin',
    postalCode: '23843',
  },
  'dolton-il': {
    name: 'Dolton',
    postalCode: '60419',
  },
  'donahue-ia': {
    name: 'Donahue',
    postalCode: '52746',
  },
  'donald-or': {
    name: 'Donald',
    postalCode: '97020',
  },
  'donalds-sc': {
    name: 'Donalds',
    postalCode: '29638',
  },
  'donaldson-ar': {
    name: 'Donaldson',
    postalCode: '71941',
  },
  'donaldsonville-la': {
    name: 'Donaldsonville',
    postalCode: '70346',
  },
  'donalsonville-ga': {
    name: 'Donalsonville',
    postalCode: '39845',
  },
  'donegal-pa': {
    name: 'Donegal',
    postalCode: '15628',
  },
  'dongola-il': {
    name: 'Dongola',
    postalCode: '62926',
  },
  'donie-tx': {
    name: 'Donie',
    postalCode: '75838',
  },
  'doniphan-mo': {
    name: 'Doniphan',
    postalCode: '63935',
  },
  'doniphan-ne': {
    name: 'Doniphan',
    postalCode: '68832',
  },
  'donna-tx': {
    name: 'Donna',
    postalCode: '78537',
  },
  'donnellson-ia': {
    name: 'Donnellson',
    postalCode: '52625',
  },
  'donnellson-il': {
    name: 'Donnellson',
    postalCode: '62019',
  },
  'donnelly-id': {
    name: 'Donnelly',
    postalCode: '83615',
  },
  'donnelly-mn': {
    name: 'Donnelly',
    postalCode: '56235',
  },
  'donner-la': {
    name: 'Donner',
    postalCode: '70352',
  },
  'donnybrook-nd': {
    name: 'Donnybrook',
    postalCode: '58734',
  },
  'donora-pa': {
    name: 'Donora',
    postalCode: '15033',
  },
  'donovan-il': {
    name: 'Donovan',
    postalCode: '60931',
  },
  'doole-tx': {
    name: 'Doole',
    postalCode: '76836',
  },
  'doon-ia': {
    name: 'Doon',
    postalCode: '51235',
  },
  'dora-al': {
    name: 'Dora',
    postalCode: '35062',
  },
  'dora-mo': {
    name: 'Dora',
    postalCode: '65637',
  },
  'dorado-pr': {
    name: 'Dorado',
    postalCode: '00646',
  },
  'dorchester-ia': {
    name: 'Dorchester',
    postalCode: '52140',
  },
  'dorchester-ma': {
    name: 'Dorchester',
    postalCode: '02121',
  },
  'dorchester-ne': {
    name: 'Dorchester',
    postalCode: '68343',
  },
  'dorchester-sc': {
    name: 'Dorchester',
    postalCode: '29437',
  },
  'dorchester-wi': {
    name: 'Dorchester',
    postalCode: '54425',
  },
  'dorchester-center-ma': {
    name: 'Dorchester Center',
    postalCode: '02124',
  },
  'dorena-or': {
    name: 'Dorena',
    postalCode: '97434',
  },
  'dormansville-ny': {
    name: 'Dormansville',
    postalCode: '12055',
  },
  'dornsife-pa': {
    name: 'Dornsife',
    postalCode: '17823',
  },
  'dorothy-nj': {
    name: 'Dorothy',
    postalCode: '08317',
  },
  'dorothy-wv': {
    name: 'Dorothy',
    postalCode: '25060',
  },
  'dorr-mi': {
    name: 'Dorr',
    postalCode: '49323',
  },
  'dorrance-ks': {
    name: 'Dorrance',
    postalCode: '67634',
  },
  'dorris-ca': {
    name: 'Dorris',
    postalCode: '96023',
  },
  'dorset-oh': {
    name: 'Dorset',
    postalCode: '44032',
  },
  'dorset-vt': {
    name: 'Dorset',
    postalCode: '05251',
  },
  'dorsey-il': {
    name: 'Dorsey',
    postalCode: '62021',
  },
  'dos-palos-ca': {
    name: 'Dos Palos',
    postalCode: '93620',
  },
  'dos-rios-ca': {
    name: 'Dos Rios',
    postalCode: '95429',
  },
  'doss-tx': {
    name: 'Doss',
    postalCode: '78618',
  },
  'doswell-va': {
    name: 'Doswell',
    postalCode: '23047',
  },
  'dothan-al': {
    name: 'Dothan',
    postalCode: '36301',
  },
  'double-springs-al': {
    name: 'Double Springs',
    postalCode: '35553',
  },
  'douds-ia': {
    name: 'Douds',
    postalCode: '52551',
  },
  'dougherty-ia': {
    name: 'Dougherty',
    postalCode: '50433',
  },
  'douglas-ak': {
    name: 'Douglas',
    postalCode: '99824',
  },
  'douglas-az': {
    name: 'Douglas',
    postalCode: '85607',
  },
  'douglas-ga': {
    name: 'Douglas',
    postalCode: '31533',
  },
  'douglas-ma': {
    name: 'Douglas',
    postalCode: '01516',
  },
  'douglas-nd': {
    name: 'Douglas',
    postalCode: '58735',
  },
  'douglas-ne': {
    name: 'Douglas',
    postalCode: '68344',
  },
  'douglas-ok': {
    name: 'Douglas',
    postalCode: '73733',
  },
  'douglas-wy': {
    name: 'Douglas',
    postalCode: '82633',
  },
  'douglas-city-ca': {
    name: 'Douglas City',
    postalCode: '96024',
  },
  'douglass-ks': {
    name: 'Douglass',
    postalCode: '67039',
  },
  'douglass-tx': {
    name: 'Douglass',
    postalCode: '75943',
  },
  'douglassville-pa': {
    name: 'Douglassville',
    postalCode: '19518',
  },
  'douglassville-tx': {
    name: 'Douglassville',
    postalCode: '75560',
  },
  'douglasville-ga': {
    name: 'Douglasville',
    postalCode: '30134',
  },
  'dousman-wi': {
    name: 'Dousman',
    postalCode: '53118',
  },
  'dove-creek-co': {
    name: 'Dove Creek',
    postalCode: '81324',
  },
  'dover-ar': {
    name: 'Dover',
    postalCode: '72837',
  },
  'dover-de': {
    name: 'Dover',
    postalCode: '19901',
  },
  'dover-fl': {
    name: 'Dover',
    postalCode: '33527',
  },
  'dover-ky': {
    name: 'Dover',
    postalCode: '41034',
  },
  'dover-ma': {
    name: 'Dover',
    postalCode: '02030',
  },
  'dover-mn': {
    name: 'Dover',
    postalCode: '55929',
  },
  'dover-mo': {
    name: 'Dover',
    postalCode: '64022',
  },
  'dover-nc': {
    name: 'Dover',
    postalCode: '28526',
  },
  'dover-nh': {
    name: 'Dover',
    postalCode: '03820',
  },
  'dover-nj': {
    name: 'Dover',
    postalCode: '07801',
  },
  'dover-oh': {
    name: 'Dover',
    postalCode: '44622',
  },
  'dover-ok': {
    name: 'Dover',
    postalCode: '73734',
  },
  'dover-pa': {
    name: 'Dover',
    postalCode: '17315',
  },
  'dover-tn': {
    name: 'Dover',
    postalCode: '37058',
  },
  'dover-afb-de': {
    name: 'Dover AFB',
    postalCode: '19902',
  },
  'dover-foxcroft-me': {
    name: 'Dover Foxcroft',
    postalCode: '04426',
  },
  'dover-plains-ny': {
    name: 'Dover Plains',
    postalCode: '12522',
  },
  'dovray-mn': {
    name: 'Dovray',
    postalCode: '56125',
  },
  'dow-il': {
    name: 'Dow',
    postalCode: '62022',
  },
  'dow-city-ia': {
    name: 'Dow City',
    postalCode: '51528',
  },
  'dowagiac-mi': {
    name: 'Dowagiac',
    postalCode: '49047',
  },
  'dowelltown-tn': {
    name: 'Dowelltown',
    postalCode: '37059',
  },
  'dowling-mi': {
    name: 'Dowling',
    postalCode: '49050',
  },
  'downers-grove-il': {
    name: 'Downers Grove',
    postalCode: '60515',
  },
  'downey-ca': {
    name: 'Downey',
    postalCode: '90240',
  },
  'downey-id': {
    name: 'Downey',
    postalCode: '83234',
  },
  'downing-mo': {
    name: 'Downing',
    postalCode: '63536',
  },
  'downing-wi': {
    name: 'Downing',
    postalCode: '54734',
  },
  'downingtown-pa': {
    name: 'Downingtown',
    postalCode: '19335',
  },
  'downs-il': {
    name: 'Downs',
    postalCode: '61736',
  },
  'downs-ks': {
    name: 'Downs',
    postalCode: '67437',
  },
  'downsville-la': {
    name: 'Downsville',
    postalCode: '71234',
  },
  'downsville-ny': {
    name: 'Downsville',
    postalCode: '13755',
  },
  'dows-ia': {
    name: 'Dows',
    postalCode: '50071',
  },
  'doyle-ca': {
    name: 'Doyle',
    postalCode: '96109',
  },
  'doyle-tn': {
    name: 'Doyle',
    postalCode: '38559',
  },
  'doylesburg-pa': {
    name: 'Doylesburg',
    postalCode: '17219',
  },
  'doylestown-oh': {
    name: 'Doylestown',
    postalCode: '44230',
  },
  'doylestown-pa': {
    name: 'Doylestown',
    postalCode: '18901',
  },
  'doyline-la': {
    name: 'Doyline',
    postalCode: '71023',
  },
  'dozier-al': {
    name: 'Dozier',
    postalCode: '36028',
  },
  'dracut-ma': {
    name: 'Dracut',
    postalCode: '01826',
  },
  'drain-or': {
    name: 'Drain',
    postalCode: '97435',
  },
  'drake-co': {
    name: 'Drake',
    postalCode: '80515',
  },
  'drake-nd': {
    name: 'Drake',
    postalCode: '58736',
  },
  'drakes-branch-va': {
    name: 'Drakes Branch',
    postalCode: '23937',
  },
  'drakesboro-ky': {
    name: 'Drakesboro',
    postalCode: '42337',
  },
  'drakesville-ia': {
    name: 'Drakesville',
    postalCode: '52552',
  },
  'draper-sd': {
    name: 'Draper',
    postalCode: '57531',
  },
  'draper-ut': {
    name: 'Draper',
    postalCode: '84020',
  },
  'draper-va': {
    name: 'Draper',
    postalCode: '24324',
  },
  'drasco-ar': {
    name: 'Drasco',
    postalCode: '72530',
  },
  'dravosburg-pa': {
    name: 'Dravosburg',
    postalCode: '15034',
  },
  'drayden-md': {
    name: 'Drayden',
    postalCode: '20630',
  },
  'drayton-nd': {
    name: 'Drayton',
    postalCode: '58225',
  },
  'dresden-ks': {
    name: 'Dresden',
    postalCode: '67635',
  },
  'dresden-me': {
    name: 'Dresden',
    postalCode: '04342',
  },
  'dresden-ny': {
    name: 'Dresden',
    postalCode: '14441',
  },
  'dresden-oh': {
    name: 'Dresden',
    postalCode: '43821',
  },
  'dresden-tn': {
    name: 'Dresden',
    postalCode: '38225',
  },
  'dresher-pa': {
    name: 'Dresher',
    postalCode: '19025',
  },
  'dresser-wi': {
    name: 'Dresser',
    postalCode: '54009',
  },
  'drew-ms': {
    name: 'Drew',
    postalCode: '38737',
  },
  'drewryville-va': {
    name: 'Drewryville',
    postalCode: '23844',
  },
  'drewsey-or': {
    name: 'Drewsey',
    postalCode: '97904',
  },
  'drexel-mo': {
    name: 'Drexel',
    postalCode: '64742',
  },
  'drexel-hill-pa': {
    name: 'Drexel Hill',
    postalCode: '19026',
  },
  'drift-ky': {
    name: 'Drift',
    postalCode: '41619',
  },
  'driftwood-pa': {
    name: 'Driftwood',
    postalCode: '15832',
  },
  'driftwood-tx': {
    name: 'Driftwood',
    postalCode: '78619',
  },
  'driggs-id': {
    name: 'Driggs',
    postalCode: '83422',
  },
  'dripping-springs-tx': {
    name: 'Dripping Springs',
    postalCode: '78620',
  },
  'driscoll-nd': {
    name: 'Driscoll',
    postalCode: '58532',
  },
  'drummond-mt': {
    name: 'Drummond',
    postalCode: '59832',
  },
  'drummond-ok': {
    name: 'Drummond',
    postalCode: '73735',
  },
  'drummond-wi': {
    name: 'Drummond',
    postalCode: '54832',
  },
  'drummond-island-mi': {
    name: 'Drummond Island',
    postalCode: '49726',
  },
  'drummonds-tn': {
    name: 'Drummonds',
    postalCode: '38023',
  },
  'drumore-pa': {
    name: 'Drumore',
    postalCode: '17518',
  },
  'drumright-ok': {
    name: 'Drumright',
    postalCode: '74030',
  },
  'drums-pa': {
    name: 'Drums',
    postalCode: '18222',
  },
  'drury-ma': {
    name: 'Drury',
    postalCode: '01343',
  },
  'drury-mo': {
    name: 'Drury',
    postalCode: '65638',
  },
  'dry-branch-ga': {
    name: 'Dry Branch',
    postalCode: '31020',
  },
  'dry-creek-la': {
    name: 'Dry Creek',
    postalCode: '70637',
  },
  'dry-creek-wv': {
    name: 'Dry Creek',
    postalCode: '25062',
  },
  'dry-fork-va': {
    name: 'Dry Fork',
    postalCode: '24549',
  },
  'dry-prong-la': {
    name: 'Dry Prong',
    postalCode: '71423',
  },
  'dry-ridge-ky': {
    name: 'Dry Ridge',
    postalCode: '41035',
  },
  'dry-run-pa': {
    name: 'Dry Run',
    postalCode: '17220',
  },
  'dryden-mi': {
    name: 'Dryden',
    postalCode: '48428',
  },
  'dryden-ny': {
    name: 'Dryden',
    postalCode: '13053',
  },
  'dryden-tx': {
    name: 'Dryden',
    postalCode: '78851',
  },
  'dryden-va': {
    name: 'Dryden',
    postalCode: '24243',
  },
  'dryfork-wv': {
    name: 'Dryfork',
    postalCode: '26263',
  },
  'du-bois-il': {
    name: 'Du Bois',
    postalCode: '62831',
  },
  'du-bois-ne': {
    name: 'Du Bois',
    postalCode: '68345',
  },
  'du-bois-pa': {
    name: 'Du Bois',
    postalCode: '15801',
  },
  'du-pont-ga': {
    name: 'Du Pont',
    postalCode: '31630',
  },
  'du-quoin-il': {
    name: 'Du Quoin',
    postalCode: '62832',
  },
  'duanesburg-ny': {
    name: 'Duanesburg',
    postalCode: '12056',
  },
  'duarte-ca': {
    name: 'Duarte',
    postalCode: '91008',
  },
  'dubach-la': {
    name: 'Dubach',
    postalCode: '71235',
  },
  'dubberly-la': {
    name: 'Dubberly',
    postalCode: '71024',
  },
  'dublin-ca': {
    name: 'Dublin',
    postalCode: '94568',
  },
  'dublin-ga': {
    name: 'Dublin',
    postalCode: '31021',
  },
  'dublin-nh': {
    name: 'Dublin',
    postalCode: '03444',
  },
  'dublin-oh': {
    name: 'Dublin',
    postalCode: '43016',
  },
  'dublin-pa': {
    name: 'Dublin',
    postalCode: '18917',
  },
  'dublin-tx': {
    name: 'Dublin',
    postalCode: '76446',
  },
  'dublin-va': {
    name: 'Dublin',
    postalCode: '24084',
  },
  'dubois-id': {
    name: 'Dubois',
    postalCode: '83423',
  },
  'dubois-in': {
    name: 'Dubois',
    postalCode: '47527',
  },
  'dubois-wy': {
    name: 'Dubois',
    postalCode: '82513',
  },
  'dubre-ky': {
    name: 'Dubre',
    postalCode: '42731',
  },
  'dubuque-ia': {
    name: 'Dubuque',
    postalCode: '52001',
  },
  'duchesne-ut': {
    name: 'Duchesne',
    postalCode: '84021',
  },
  'duck-wv': {
    name: 'Duck',
    postalCode: '25063',
  },
  'duck-creek-village-ut': {
    name: 'Duck Creek Village',
    postalCode: '84762',
  },
  'duck-hill-ms': {
    name: 'Duck Hill',
    postalCode: '38925',
  },
  'duck-river-tn': {
    name: 'Duck River',
    postalCode: '38454',
  },
  'duckwater-nv': {
    name: 'Duckwater',
    postalCode: '89314',
  },
  'dudley-ga': {
    name: 'Dudley',
    postalCode: '31022',
  },
  'dudley-ma': {
    name: 'Dudley',
    postalCode: '01571',
  },
  'dudley-mo': {
    name: 'Dudley',
    postalCode: '63936',
  },
  'dudley-nc': {
    name: 'Dudley',
    postalCode: '28333',
  },
  'due-west-sc': {
    name: 'Due West',
    postalCode: '29639',
  },
  'duff-tn': {
    name: 'Duff',
    postalCode: '37729',
  },
  'duffield-va': {
    name: 'Duffield',
    postalCode: '24244',
  },
  'dufur-or': {
    name: 'Dufur',
    postalCode: '97021',
  },
  'dugger-in': {
    name: 'Dugger',
    postalCode: '47848',
  },
  'dugspur-va': {
    name: 'Dugspur',
    postalCode: '24325',
  },
  'dugway-ut': {
    name: 'Dugway',
    postalCode: '84022',
  },
  'duke-mo': {
    name: 'Duke',
    postalCode: '65461',
  },
  'duke-ok': {
    name: 'Duke',
    postalCode: '73532',
  },
  'duke-center-pa': {
    name: 'Duke Center',
    postalCode: '16729',
  },
  'dukedom-tn': {
    name: 'Dukedom',
    postalCode: '38226',
  },
  'dulac-la': {
    name: 'Dulac',
    postalCode: '70353',
  },
  'dulce-nm': {
    name: 'Dulce',
    postalCode: '87528',
  },
  'dulles-va': {
    name: 'Dulles',
    postalCode: '20189',
  },
  'duluth-ga': {
    name: 'Duluth',
    postalCode: '30096',
  },
  'duluth-mn': {
    name: 'Duluth',
    postalCode: '55802',
  },
  'dulzura-ca': {
    name: 'Dulzura',
    postalCode: '91917',
  },
  'dumas-ar': {
    name: 'Dumas',
    postalCode: '71639',
  },
  'dumas-ms': {
    name: 'Dumas',
    postalCode: '38625',
  },
  'dumas-tx': {
    name: 'Dumas',
    postalCode: '79029',
  },
  'dumfries-va': {
    name: 'Dumfries',
    postalCode: '22025',
  },
  'dumont-ia': {
    name: 'Dumont',
    postalCode: '50625',
  },
  'dumont-mn': {
    name: 'Dumont',
    postalCode: '56236',
  },
  'dumont-nj': {
    name: 'Dumont',
    postalCode: '07628',
  },
  'dunbar-ne': {
    name: 'Dunbar',
    postalCode: '68346',
  },
  'dunbar-pa': {
    name: 'Dunbar',
    postalCode: '15431',
  },
  'dunbar-wi': {
    name: 'Dunbar',
    postalCode: '54119',
  },
  'dunbar-wv': {
    name: 'Dunbar',
    postalCode: '25064',
  },
  'dunbarton-nh': {
    name: 'Dunbarton',
    postalCode: '03046',
  },
  'duncan-az': {
    name: 'Duncan',
    postalCode: '85534',
  },
  'duncan-ms': {
    name: 'Duncan',
    postalCode: '38740',
  },
  'duncan-ok': {
    name: 'Duncan',
    postalCode: '73533',
  },
  'duncan-sc': {
    name: 'Duncan',
    postalCode: '29334',
  },
  'duncan-falls-oh': {
    name: 'Duncan Falls',
    postalCode: '43734',
  },
  'duncannon-pa': {
    name: 'Duncannon',
    postalCode: '17020',
  },
  'duncansville-pa': {
    name: 'Duncansville',
    postalCode: '16635',
  },
  'duncanville-al': {
    name: 'Duncanville',
    postalCode: '35456',
  },
  'duncanville-tx': {
    name: 'Duncanville',
    postalCode: '75116',
  },
  'duncombe-ia': {
    name: 'Duncombe',
    postalCode: '50532',
  },
  'dundalk-md': {
    name: 'Dundalk',
    postalCode: '21222',
  },
  'dundas-il': {
    name: 'Dundas',
    postalCode: '62425',
  },
  'dundas-mn': {
    name: 'Dundas',
    postalCode: '55019',
  },
  'dundas-va': {
    name: 'Dundas',
    postalCode: '23938',
  },
  'dundee-fl': {
    name: 'Dundee',
    postalCode: '33838',
  },
  'dundee-ia': {
    name: 'Dundee',
    postalCode: '52038',
  },
  'dundee-il': {
    name: 'Dundee',
    postalCode: '60118',
  },
  'dundee-ky': {
    name: 'Dundee',
    postalCode: '42338',
  },
  'dundee-mi': {
    name: 'Dundee',
    postalCode: '48131',
  },
  'dundee-ms': {
    name: 'Dundee',
    postalCode: '38626',
  },
  'dundee-ny': {
    name: 'Dundee',
    postalCode: '14837',
  },
  'dundee-oh': {
    name: 'Dundee',
    postalCode: '44624',
  },
  'dundee-or': {
    name: 'Dundee',
    postalCode: '97115',
  },
  'dunedin-fl': {
    name: 'Dunedin',
    postalCode: '34698',
  },
  'dunellen-nj': {
    name: 'Dunellen',
    postalCode: '08812',
  },
  'dungannon-va': {
    name: 'Dungannon',
    postalCode: '24245',
  },
  'dunkerton-ia': {
    name: 'Dunkerton',
    postalCode: '50626',
  },
  'dunkirk-in': {
    name: 'Dunkirk',
    postalCode: '47336',
  },
  'dunkirk-md': {
    name: 'Dunkirk',
    postalCode: '20754',
  },
  'dunkirk-ny': {
    name: 'Dunkirk',
    postalCode: '14048',
  },
  'dunkirk-oh': {
    name: 'Dunkirk',
    postalCode: '45836',
  },
  'dunlap-ca': {
    name: 'Dunlap',
    postalCode: '93621',
  },
  'dunlap-ia': {
    name: 'Dunlap',
    postalCode: '51529',
  },
  'dunlap-il': {
    name: 'Dunlap',
    postalCode: '61525',
  },
  'dunlap-tn': {
    name: 'Dunlap',
    postalCode: '37327',
  },
  'dunlow-wv': {
    name: 'Dunlow',
    postalCode: '25511',
  },
  'dunmor-ky': {
    name: 'Dunmor',
    postalCode: '42339',
  },
  'dunmore-wv': {
    name: 'Dunmore',
    postalCode: '24934',
  },
  'dunn-nc': {
    name: 'Dunn',
    postalCode: '28334',
  },
  'dunn-center-nd': {
    name: 'Dunn Center',
    postalCode: '58626',
  },
  'dunn-loring-va': {
    name: 'Dunn Loring',
    postalCode: '22027',
  },
  'dunnegan-mo': {
    name: 'Dunnegan',
    postalCode: '65640',
  },
  'dunnell-mn': {
    name: 'Dunnell',
    postalCode: '56127',
  },
  'dunnellon-fl': {
    name: 'Dunnellon',
    postalCode: '34431',
  },
  'dunning-ne': {
    name: 'Dunning',
    postalCode: '68833',
  },
  'dunnsville-va': {
    name: 'Dunnsville',
    postalCode: '22454',
  },
  'dunnville-ky': {
    name: 'Dunnville',
    postalCode: '42528',
  },
  'dunseith-nd': {
    name: 'Dunseith',
    postalCode: '58329',
  },
  'dunsmuir-ca': {
    name: 'Dunsmuir',
    postalCode: '96025',
  },
  'dunstable-ma': {
    name: 'Dunstable',
    postalCode: '01827',
  },
  'dupo-il': {
    name: 'Dupo',
    postalCode: '62239',
  },
  'dupont-in': {
    name: 'Dupont',
    postalCode: '47231',
  },
  'dupont-wa': {
    name: 'Dupont',
    postalCode: '98327',
  },
  'dupree-sd': {
    name: 'Dupree',
    postalCode: '57623',
  },
  'duquesne-pa': {
    name: 'Duquesne',
    postalCode: '15110',
  },
  'durand-il': {
    name: 'Durand',
    postalCode: '61024',
  },
  'durand-mi': {
    name: 'Durand',
    postalCode: '48429',
  },
  'durand-wi': {
    name: 'Durand',
    postalCode: '54736',
  },
  'durango-co': {
    name: 'Durango',
    postalCode: '81301',
  },
  'durango-ia': {
    name: 'Durango',
    postalCode: '52039',
  },
  'durant-ia': {
    name: 'Durant',
    postalCode: '52747',
  },
  'durant-ms': {
    name: 'Durant',
    postalCode: '39063',
  },
  'durant-ok': {
    name: 'Durant',
    postalCode: '74701',
  },
  'durbin-wv': {
    name: 'Durbin',
    postalCode: '26264',
  },
  'durham-ca': {
    name: 'Durham',
    postalCode: '95938',
  },
  'durham-ct': {
    name: 'Durham',
    postalCode: '06422',
  },
  'durham-ks': {
    name: 'Durham',
    postalCode: '67438',
  },
  'durham-me': {
    name: 'Durham',
    postalCode: '04222',
  },
  'durham-mo': {
    name: 'Durham',
    postalCode: '63438',
  },
  'durham-nc': {
    name: 'Durham',
    postalCode: '27701',
  },
  'durham-nh': {
    name: 'Durham',
    postalCode: '03824',
  },
  'durham-ny': {
    name: 'Durham',
    postalCode: '12422',
  },
  'durham-ok': {
    name: 'Durham',
    postalCode: '73642',
  },
  'durhamville-ny': {
    name: 'Durhamville',
    postalCode: '13054',
  },
  'duryea-pa': {
    name: 'Duryea',
    postalCode: '18642',
  },
  'dushore-pa': {
    name: 'Dushore',
    postalCode: '18614',
  },
  'duson-la': {
    name: 'Duson',
    postalCode: '70529',
  },
  'dustin-ok': {
    name: 'Dustin',
    postalCode: '74839',
  },
  'dutch-john-ut': {
    name: 'Dutch John',
    postalCode: '84023',
  },
  'dutton-al': {
    name: 'Dutton',
    postalCode: '35744',
  },
  'dutton-mt': {
    name: 'Dutton',
    postalCode: '59433',
  },
  'dutton-va': {
    name: 'Dutton',
    postalCode: '23050',
  },
  'duvall-wa': {
    name: 'Duvall',
    postalCode: '98019',
  },
  'duxbury-ma': {
    name: 'Duxbury',
    postalCode: '02332',
  },
  'dwale-ky': {
    name: 'Dwale',
    postalCode: '41621',
  },
  'dwight-il': {
    name: 'Dwight',
    postalCode: '60420',
  },
  'dwight-ks': {
    name: 'Dwight',
    postalCode: '66849',
  },
  'dwight-ne': {
    name: 'Dwight',
    postalCode: '68635',
  },
  'dyer-in': {
    name: 'Dyer',
    postalCode: '46311',
  },
  'dyer-nv': {
    name: 'Dyer',
    postalCode: '89010',
  },
  'dyer-tn': {
    name: 'Dyer',
    postalCode: '38330',
  },
  'dyersburg-tn': {
    name: 'Dyersburg',
    postalCode: '38024',
  },
  'dyersville-ia': {
    name: 'Dyersville',
    postalCode: '52040',
  },
  'dyess-ar': {
    name: 'Dyess',
    postalCode: '72330',
  },
  'dyess-afb-tx': {
    name: 'Dyess AFB',
    postalCode: '79607',
  },
  'dyke-va': {
    name: 'Dyke',
    postalCode: '22935',
  },
  'dysart-ia': {
    name: 'Dysart',
    postalCode: '52224',
  },
  'dysart-pa': {
    name: 'Dysart',
    postalCode: '16636',
  },
  'eads-co': {
    name: 'Eads',
    postalCode: '81036',
  },
  'eads-tn': {
    name: 'Eads',
    postalCode: '38028',
  },
  'eagar-az': {
    name: 'Eagar',
    postalCode: '85925',
  },
  'eagle-id': {
    name: 'Eagle',
    postalCode: '83616',
  },
  'eagle-mi': {
    name: 'Eagle',
    postalCode: '48822',
  },
  'eagle-ne': {
    name: 'Eagle',
    postalCode: '68347',
  },
  'eagle-wi': {
    name: 'Eagle',
    postalCode: '53119',
  },
  'eagle-bay-ny': {
    name: 'Eagle Bay',
    postalCode: '13331',
  },
  'eagle-bend-mn': {
    name: 'Eagle Bend',
    postalCode: '56446',
  },
  'eagle-bridge-ny': {
    name: 'Eagle Bridge',
    postalCode: '12057',
  },
  'eagle-butte-sd': {
    name: 'Eagle Butte',
    postalCode: '57625',
  },
  'eagle-creek-or': {
    name: 'Eagle Creek',
    postalCode: '97022',
  },
  'eagle-grove-ia': {
    name: 'Eagle Grove',
    postalCode: '50533',
  },
  'eagle-lake-fl': {
    name: 'Eagle Lake',
    postalCode: '33839',
  },
  'eagle-lake-me': {
    name: 'Eagle Lake',
    postalCode: '04739',
  },
  'eagle-lake-mn': {
    name: 'Eagle Lake',
    postalCode: '56024',
  },
  'eagle-lake-tx': {
    name: 'Eagle Lake',
    postalCode: '77434',
  },
  'eagle-mountain-ut': {
    name: 'Eagle Mountain',
    postalCode: '84005',
  },
  'eagle-nest-nm': {
    name: 'Eagle Nest',
    postalCode: '87718',
  },
  'eagle-pass-tx': {
    name: 'Eagle Pass',
    postalCode: '78852',
  },
  'eagle-point-or': {
    name: 'Eagle Point',
    postalCode: '97524',
  },
  'eagle-river-ak': {
    name: 'Eagle River',
    postalCode: '99577',
  },
  'eagle-river-wi': {
    name: 'Eagle River',
    postalCode: '54521',
  },
  'eagle-rock-mo': {
    name: 'Eagle Rock',
    postalCode: '65641',
  },
  'eagle-rock-va': {
    name: 'Eagle Rock',
    postalCode: '24085',
  },
  'eagle-springs-nc': {
    name: 'Eagle Springs',
    postalCode: '27242',
  },
  'eagletown-ok': {
    name: 'Eagletown',
    postalCode: '74734',
  },
  'eagleville-mo': {
    name: 'Eagleville',
    postalCode: '64442',
  },
  'eagleville-tn': {
    name: 'Eagleville',
    postalCode: '37060',
  },
  'earl-park-in': {
    name: 'Earl Park',
    postalCode: '47942',
  },
  'earle-ar': {
    name: 'Earle',
    postalCode: '72331',
  },
  'earleton-fl': {
    name: 'Earleton',
    postalCode: '32631',
  },
  'earleville-md': {
    name: 'Earleville',
    postalCode: '21919',
  },
  'earlham-ia': {
    name: 'Earlham',
    postalCode: '50072',
  },
  'earlimart-ca': {
    name: 'Earlimart',
    postalCode: '93219',
  },
  'earling-ia': {
    name: 'Earling',
    postalCode: '51530',
  },
  'earlington-ky': {
    name: 'Earlington',
    postalCode: '42410',
  },
  'earlsboro-ok': {
    name: 'Earlsboro',
    postalCode: '74840',
  },
  'earlton-ny': {
    name: 'Earlton',
    postalCode: '12058',
  },
  'earlville-ia': {
    name: 'Earlville',
    postalCode: '52041',
  },
  'earlville-il': {
    name: 'Earlville',
    postalCode: '60518',
  },
  'earlville-ny': {
    name: 'Earlville',
    postalCode: '13332',
  },
  'early-ia': {
    name: 'Early',
    postalCode: '50535',
  },
  'early-tx': {
    name: 'Early',
    postalCode: '76802',
  },
  'early-branch-sc': {
    name: 'Early Branch',
    postalCode: '29916',
  },
  'earlysville-va': {
    name: 'Earlysville',
    postalCode: '22936',
  },
  'earp-ca': {
    name: 'Earp',
    postalCode: '92242',
  },
  'earth-tx': {
    name: 'Earth',
    postalCode: '79031',
  },
  'earth-city-mo': {
    name: 'Earth City',
    postalCode: '63045',
  },
  'easley-sc': {
    name: 'Easley',
    postalCode: '29640',
  },
  'east-alton-il': {
    name: 'East Alton',
    postalCode: '62024',
  },
  'east-amherst-ny': {
    name: 'East Amherst',
    postalCode: '14051',
  },
  'east-andover-me': {
    name: 'East Andover',
    postalCode: '04226',
  },
  'east-arlington-vt': {
    name: 'East Arlington',
    postalCode: '05252',
  },
  'east-aurora-ny': {
    name: 'East Aurora',
    postalCode: '14052',
  },
  'east-baldwin-me': {
    name: 'East Baldwin',
    postalCode: '04024',
  },
  'east-bank-wv': {
    name: 'East Bank',
    postalCode: '25067',
  },
  'east-barre-vt': {
    name: 'East Barre',
    postalCode: '05649',
  },
  'east-bend-nc': {
    name: 'East Bend',
    postalCode: '27018',
  },
  'east-berkshire-vt': {
    name: 'East Berkshire',
    postalCode: '05447',
  },
  'east-berlin-ct': {
    name: 'East Berlin',
    postalCode: '06023',
  },
  'east-berlin-pa': {
    name: 'East Berlin',
    postalCode: '17316',
  },
  'east-bernard-tx': {
    name: 'East Bernard',
    postalCode: '77435',
  },
  'east-berne-ny': {
    name: 'East Berne',
    postalCode: '12059',
  },
  'east-bernstadt-ky': {
    name: 'East Bernstadt',
    postalCode: '40729',
  },
  'east-bethany-ny': {
    name: 'East Bethany',
    postalCode: '14054',
  },
  'east-boothbay-me': {
    name: 'East Boothbay',
    postalCode: '04544',
  },
  'east-boston-ma': {
    name: 'East Boston',
    postalCode: '02128',
  },
  'east-brady-pa': {
    name: 'East Brady',
    postalCode: '16028',
  },
  'east-branch-ny': {
    name: 'East Branch',
    postalCode: '13756',
  },
  'east-bridgewater-ma': {
    name: 'East Bridgewater',
    postalCode: '02333',
  },
  'east-brookfield-ma': {
    name: 'East Brookfield',
    postalCode: '01515',
  },
  'east-brunswick-nj': {
    name: 'East Brunswick',
    postalCode: '08816',
  },
  'east-burke-vt': {
    name: 'East Burke',
    postalCode: '05832',
  },
  'east-calais-vt': {
    name: 'East Calais',
    postalCode: '05650',
  },
  'east-canaan-ct': {
    name: 'East Canaan',
    postalCode: '06024',
  },
  'east-canton-oh': {
    name: 'East Canton',
    postalCode: '44730',
  },
  'east-carbon-ut': {
    name: 'East Carbon',
    postalCode: '84520',
  },
  'east-carondelet-il': {
    name: 'East Carondelet',
    postalCode: '62240',
  },
  'east-charleston-vt': {
    name: 'East Charleston',
    postalCode: '05833',
  },
  'east-chatham-ny': {
    name: 'East Chatham',
    postalCode: '12060',
  },
  'east-chicago-in': {
    name: 'East Chicago',
    postalCode: '46312',
  },
  'east-china-mi': {
    name: 'East China',
    postalCode: '48054',
  },
  'east-concord-ny': {
    name: 'East Concord',
    postalCode: '14055',
  },
  'east-corinth-vt': {
    name: 'East Corinth',
    postalCode: '05040',
  },
  'east-dorset-vt': {
    name: 'East Dorset',
    postalCode: '05253',
  },
  'east-dover-vt': {
    name: 'East Dover',
    postalCode: '05341',
  },
  'east-dublin-ga': {
    name: 'East Dublin',
    postalCode: '31027',
  },
  'east-dubuque-il': {
    name: 'East Dubuque',
    postalCode: '61025',
  },
  'east-durham-ny': {
    name: 'East Durham',
    postalCode: '12423',
  },
  'east-earl-pa': {
    name: 'East Earl',
    postalCode: '17519',
  },
  'east-elmhurst-ny': {
    name: 'East Elmhurst',
    postalCode: '11369',
  },
  'east-fairfield-vt': {
    name: 'East Fairfield',
    postalCode: '05448',
  },
  'east-falmouth-ma': {
    name: 'East Falmouth',
    postalCode: '02536',
  },
  'east-flat-rock-nc': {
    name: 'East Flat Rock',
    postalCode: '28726',
  },
  'east-freedom-pa': {
    name: 'East Freedom',
    postalCode: '16637',
  },
  'east-freetown-ma': {
    name: 'East Freetown',
    postalCode: '02717',
  },
  'east-galesburg-il': {
    name: 'East Galesburg',
    postalCode: '61430',
  },
  'east-granby-ct': {
    name: 'East Granby',
    postalCode: '06026',
  },
  'east-grand-forks-mn': {
    name: 'East Grand Forks',
    postalCode: '56721',
  },
  'east-greenbush-ny': {
    name: 'East Greenbush',
    postalCode: '12061',
  },
  'east-greenville-pa': {
    name: 'East Greenville',
    postalCode: '18041',
  },
  'east-greenwich-ri': {
    name: 'East Greenwich',
    postalCode: '02818',
  },
  'east-haddam-ct': {
    name: 'East Haddam',
    postalCode: '06423',
  },
  'east-hampstead-nh': {
    name: 'East Hampstead',
    postalCode: '03826',
  },
  'east-hampton-ct': {
    name: 'East Hampton',
    postalCode: '06424',
  },
  'east-hampton-ny': {
    name: 'East Hampton',
    postalCode: '11937',
  },
  'east-hanover-nj': {
    name: 'East Hanover',
    postalCode: '07936',
  },
  'east-hardwick-vt': {
    name: 'East Hardwick',
    postalCode: '05836',
  },
  'east-hartford-ct': {
    name: 'East Hartford',
    postalCode: '06108',
  },
  'east-hartland-ct': {
    name: 'East Hartland',
    postalCode: '06027',
  },
  'east-haven-ct': {
    name: 'East Haven',
    postalCode: '06512',
  },
  'east-haven-vt': {
    name: 'East Haven',
    postalCode: '05837',
  },
  'east-helena-mt': {
    name: 'East Helena',
    postalCode: '59635',
  },
  'east-islip-ny': {
    name: 'East Islip',
    postalCode: '11730',
  },
  'east-jewett-ny': {
    name: 'East Jewett',
    postalCode: '12424',
  },
  'east-jordan-mi': {
    name: 'East Jordan',
    postalCode: '49727',
  },
  'east-killingly-ct': {
    name: 'East Killingly',
    postalCode: '06243',
  },
  'east-kingston-nh': {
    name: 'East Kingston',
    postalCode: '03827',
  },
  'east-lansing-mi': {
    name: 'East Lansing',
    postalCode: '48823',
  },
  'east-leroy-mi': {
    name: 'East Leroy',
    postalCode: '49051',
  },
  'east-liberty-oh': {
    name: 'East Liberty',
    postalCode: '43319',
  },
  'east-liverpool-oh': {
    name: 'East Liverpool',
    postalCode: '43920',
  },
  'east-longmeadow-ma': {
    name: 'East Longmeadow',
    postalCode: '01028',
  },
  'east-lyme-ct': {
    name: 'East Lyme',
    postalCode: '06333',
  },
  'east-lynn-wv': {
    name: 'East Lynn',
    postalCode: '25512',
  },
  'east-machias-me': {
    name: 'East Machias',
    postalCode: '04630',
  },
  'east-mansfield-ma': {
    name: 'East Mansfield',
    postalCode: '02031',
  },
  'east-marion-ny': {
    name: 'East Marion',
    postalCode: '11939',
  },
  'east-mc-keesport-pa': {
    name: 'East Mc Keesport',
    postalCode: '15035',
  },
  'east-meadow-ny': {
    name: 'East Meadow',
    postalCode: '11554',
  },
  'east-meredith-ny': {
    name: 'East Meredith',
    postalCode: '13757',
  },
  'east-millinocket-me': {
    name: 'East Millinocket',
    postalCode: '04430',
  },
  'east-millsboro-pa': {
    name: 'East Millsboro',
    postalCode: '15433',
  },
  'east-moline-il': {
    name: 'East Moline',
    postalCode: '61244',
  },
  'east-montpelier-vt': {
    name: 'East Montpelier',
    postalCode: '05651',
  },
  'east-moriches-ny': {
    name: 'East Moriches',
    postalCode: '11940',
  },
  'east-nassau-ny': {
    name: 'East Nassau',
    postalCode: '12062',
  },
  'east-new-market-md': {
    name: 'East New Market',
    postalCode: '21631',
  },
  'east-northport-ny': {
    name: 'East Northport',
    postalCode: '11731',
  },
  'east-norwich-ny': {
    name: 'East Norwich',
    postalCode: '11732',
  },
  'east-orange-nj': {
    name: 'East Orange',
    postalCode: '07017',
  },
  'east-otto-ny': {
    name: 'East Otto',
    postalCode: '14729',
  },
  'east-palatka-fl': {
    name: 'East Palatka',
    postalCode: '32131',
  },
  'east-palestine-oh': {
    name: 'East Palestine',
    postalCode: '44413',
  },
  'east-peoria-il': {
    name: 'East Peoria',
    postalCode: '61611',
  },
  'east-petersburg-pa': {
    name: 'East Petersburg',
    postalCode: '17520',
  },
  'east-pittsburgh-pa': {
    name: 'East Pittsburgh',
    postalCode: '15112',
  },
  'east-point-ky': {
    name: 'East Point',
    postalCode: '41216',
  },
  'east-prairie-mo': {
    name: 'East Prairie',
    postalCode: '63845',
  },
  'east-providence-ri': {
    name: 'East Providence',
    postalCode: '02914',
  },
  'east-quogue-ny': {
    name: 'East Quogue',
    postalCode: '11942',
  },
  'east-randolph-vt': {
    name: 'East Randolph',
    postalCode: '05041',
  },
  'east-rochester-ny': {
    name: 'East Rochester',
    postalCode: '14445',
  },
  'east-rochester-oh': {
    name: 'East Rochester',
    postalCode: '44625',
  },
  'east-rockaway-ny': {
    name: 'East Rockaway',
    postalCode: '11518',
  },
  'east-rutherford-nj': {
    name: 'East Rutherford',
    postalCode: '07073',
  },
  'east-ryegate-vt': {
    name: 'East Ryegate',
    postalCode: '05042',
  },
  'east-saint-louis-il': {
    name: 'East Saint Louis',
    postalCode: '62201',
  },
  'east-sandwich-ma': {
    name: 'East Sandwich',
    postalCode: '02537',
  },
  'east-schodack-ny': {
    name: 'East Schodack',
    postalCode: '12063',
  },
  'east-setauket-ny': {
    name: 'East Setauket',
    postalCode: '11733',
  },
  'east-smithfield-pa': {
    name: 'East Smithfield',
    postalCode: '18817',
  },
  'east-sparta-oh': {
    name: 'East Sparta',
    postalCode: '44626',
  },
  'east-springfield-ny': {
    name: 'East Springfield',
    postalCode: '13333',
  },
  'east-springfield-pa': {
    name: 'East Springfield',
    postalCode: '16411',
  },
  'east-stroudsburg-pa': {
    name: 'East Stroudsburg',
    postalCode: '18301',
  },
  'east-syracuse-ny': {
    name: 'East Syracuse',
    postalCode: '13057',
  },
  'east-taunton-ma': {
    name: 'East Taunton',
    postalCode: '02718',
  },
  'east-tawas-mi': {
    name: 'East Tawas',
    postalCode: '48730',
  },
  'east-thetford-vt': {
    name: 'East Thetford',
    postalCode: '05043',
  },
  'east-troy-wi': {
    name: 'East Troy',
    postalCode: '53120',
  },
  'east-wakefield-nh': {
    name: 'East Wakefield',
    postalCode: '03830',
  },
  'east-wallingford-vt': {
    name: 'East Wallingford',
    postalCode: '05742',
  },
  'east-walpole-ma': {
    name: 'East Walpole',
    postalCode: '02032',
  },
  'east-wareham-ma': {
    name: 'East Wareham',
    postalCode: '02538',
  },
  'east-waterboro-me': {
    name: 'East Waterboro',
    postalCode: '04030',
  },
  'east-waterford-pa': {
    name: 'East Waterford',
    postalCode: '17021',
  },
  'east-wenatchee-wa': {
    name: 'East Wenatchee',
    postalCode: '98802',
  },
  'east-weymouth-ma': {
    name: 'East Weymouth',
    postalCode: '02189',
  },
  'east-windsor-ct': {
    name: 'East Windsor',
    postalCode: '06088',
  },
  'east-worcester-ny': {
    name: 'East Worcester',
    postalCode: '12064',
  },
  'eastaboga-al': {
    name: 'Eastaboga',
    postalCode: '36260',
  },
  'eastanollee-ga': {
    name: 'Eastanollee',
    postalCode: '30538',
  },
  'eastchester-ny': {
    name: 'Eastchester',
    postalCode: '10709',
  },
  'eastern-ky': {
    name: 'Eastern',
    postalCode: '41622',
  },
  'eastford-ct': {
    name: 'Eastford',
    postalCode: '06242',
  },
  'eastham-ma': {
    name: 'Eastham',
    postalCode: '02642',
  },
  'easthampton-ma': {
    name: 'Easthampton',
    postalCode: '01027',
  },
  'eastlake-oh': {
    name: 'Eastlake',
    postalCode: '44095',
  },
  'eastland-tx': {
    name: 'Eastland',
    postalCode: '76448',
  },
  'eastman-ga': {
    name: 'Eastman',
    postalCode: '31023',
  },
  'eastman-wi': {
    name: 'Eastman',
    postalCode: '54626',
  },
  'easton-ct': {
    name: 'Easton',
    postalCode: '06612',
  },
  'easton-il': {
    name: 'Easton',
    postalCode: '62633',
  },
  'easton-ks': {
    name: 'Easton',
    postalCode: '66020',
  },
  'easton-md': {
    name: 'Easton',
    postalCode: '21601',
  },
  'easton-me': {
    name: 'Easton',
    postalCode: '04740',
  },
  'easton-mn': {
    name: 'Easton',
    postalCode: '56025',
  },
  'easton-mo': {
    name: 'Easton',
    postalCode: '64443',
  },
  'easton-pa': {
    name: 'Easton',
    postalCode: '18040',
  },
  'eastover-sc': {
    name: 'Eastover',
    postalCode: '29044',
  },
  'eastpoint-fl': {
    name: 'Eastpoint',
    postalCode: '32328',
  },
  'eastpointe-mi': {
    name: 'Eastpointe',
    postalCode: '48021',
  },
  'eastport-me': {
    name: 'Eastport',
    postalCode: '04631',
  },
  'eastport-ny': {
    name: 'Eastport',
    postalCode: '11941',
  },
  'eastsound-wa': {
    name: 'Eastsound',
    postalCode: '98245',
  },
  'eastview-ky': {
    name: 'Eastview',
    postalCode: '42732',
  },
  'eaton-co': {
    name: 'Eaton',
    postalCode: '80615',
  },
  'eaton-in': {
    name: 'Eaton',
    postalCode: '47338',
  },
  'eaton-ny': {
    name: 'Eaton',
    postalCode: '13334',
  },
  'eaton-oh': {
    name: 'Eaton',
    postalCode: '45320',
  },
  'eaton-rapids-mi': {
    name: 'Eaton Rapids',
    postalCode: '48827',
  },
  'eatonton-ga': {
    name: 'Eatonton',
    postalCode: '31024',
  },
  'eatontown-nj': {
    name: 'Eatontown',
    postalCode: '07724',
  },
  'eatonville-wa': {
    name: 'Eatonville',
    postalCode: '98328',
  },
  'eau-claire-mi': {
    name: 'Eau Claire',
    postalCode: '49111',
  },
  'eau-claire-wi': {
    name: 'Eau Claire',
    postalCode: '54701',
  },
  'eau-galle-wi': {
    name: 'Eau Galle',
    postalCode: '54737',
  },
  'ebensburg-pa': {
    name: 'Ebensburg',
    postalCode: '15931',
  },
  'ebony-va': {
    name: 'Ebony',
    postalCode: '23845',
  },
  'ebro-fl': {
    name: 'Ebro',
    postalCode: '32437',
  },
  'echo-mn': {
    name: 'Echo',
    postalCode: '56237',
  },
  'echo-or': {
    name: 'Echo',
    postalCode: '97826',
  },
  'echola-al': {
    name: 'Echola',
    postalCode: '35457',
  },
  'eckerman-mi': {
    name: 'Eckerman',
    postalCode: '49728',
  },
  'eckert-co': {
    name: 'Eckert',
    postalCode: '81418',
  },
  'eckerty-in': {
    name: 'Eckerty',
    postalCode: '47116',
  },
  'eckley-co': {
    name: 'Eckley',
    postalCode: '80727',
  },
  'eckman-wv': {
    name: 'Eckman',
    postalCode: '24829',
  },
  'eclectic-al': {
    name: 'Eclectic',
    postalCode: '36024',
  },
  'ecleto-tx': {
    name: 'Ecleto',
    postalCode: '78111',
  },
  'economy-in': {
    name: 'Economy',
    postalCode: '47339',
  },
  'ecorse-mi': {
    name: 'Ecorse',
    postalCode: '48229',
  },
  'ecru-ms': {
    name: 'Ecru',
    postalCode: '38841',
  },
  'ector-tx': {
    name: 'Ector',
    postalCode: '75439',
  },
  'edcouch-tx': {
    name: 'Edcouch',
    postalCode: '78538',
  },
  'eddington-me': {
    name: 'Eddington',
    postalCode: '04428',
  },
  'eddy-tx': {
    name: 'Eddy',
    postalCode: '76524',
  },
  'eddyville-ia': {
    name: 'Eddyville',
    postalCode: '52553',
  },
  'eddyville-il': {
    name: 'Eddyville',
    postalCode: '62928',
  },
  'eddyville-ky': {
    name: 'Eddyville',
    postalCode: '42038',
  },
  'eddyville-ne': {
    name: 'Eddyville',
    postalCode: '68834',
  },
  'eddyville-or': {
    name: 'Eddyville',
    postalCode: '97343',
  },
  'edelstein-il': {
    name: 'Edelstein',
    postalCode: '61526',
  },
  'eden-az': {
    name: 'Eden',
    postalCode: '85535',
  },
  'eden-id': {
    name: 'Eden',
    postalCode: '83325',
  },
  'eden-md': {
    name: 'Eden',
    postalCode: '21822',
  },
  'eden-nc': {
    name: 'Eden',
    postalCode: '27288',
  },
  'eden-ny': {
    name: 'Eden',
    postalCode: '14057',
  },
  'eden-sd': {
    name: 'Eden',
    postalCode: '57232',
  },
  'eden-tx': {
    name: 'Eden',
    postalCode: '76837',
  },
  'eden-ut': {
    name: 'Eden',
    postalCode: '84310',
  },
  'eden-vt': {
    name: 'Eden',
    postalCode: '05652',
  },
  'eden-wi': {
    name: 'Eden',
    postalCode: '53019',
  },
  'eden-mills-vt': {
    name: 'Eden Mills',
    postalCode: '05653',
  },
  'eden-prairie-mn': {
    name: 'Eden Prairie',
    postalCode: '55344',
  },
  'eden-valley-mn': {
    name: 'Eden Valley',
    postalCode: '55329',
  },
  'edenton-nc': {
    name: 'Edenton',
    postalCode: '27932',
  },
  'edgar-mt': {
    name: 'Edgar',
    postalCode: '59026',
  },
  'edgar-ne': {
    name: 'Edgar',
    postalCode: '68935',
  },
  'edgar-wi': {
    name: 'Edgar',
    postalCode: '54426',
  },
  'edgar-springs-mo': {
    name: 'Edgar Springs',
    postalCode: '65462',
  },
  'edgard-la': {
    name: 'Edgard',
    postalCode: '70049',
  },
  'edgarton-wv': {
    name: 'Edgarton',
    postalCode: '25672',
  },
  'edgartown-ma': {
    name: 'Edgartown',
    postalCode: '02539',
  },
  'edgecomb-me': {
    name: 'Edgecomb',
    postalCode: '04556',
  },
  'edgefield-sc': {
    name: 'Edgefield',
    postalCode: '29824',
  },
  'edgeley-nd': {
    name: 'Edgeley',
    postalCode: '58433',
  },
  'edgemont-ar': {
    name: 'Edgemont',
    postalCode: '72044',
  },
  'edgemont-sd': {
    name: 'Edgemont',
    postalCode: '57735',
  },
  'edgemoor-sc': {
    name: 'Edgemoor',
    postalCode: '29712',
  },
  'edgerton-ks': {
    name: 'Edgerton',
    postalCode: '66021',
  },
  'edgerton-mn': {
    name: 'Edgerton',
    postalCode: '56128',
  },
  'edgerton-mo': {
    name: 'Edgerton',
    postalCode: '64444',
  },
  'edgerton-oh': {
    name: 'Edgerton',
    postalCode: '43517',
  },
  'edgerton-wi': {
    name: 'Edgerton',
    postalCode: '53534',
  },
  'edgewater-fl': {
    name: 'Edgewater',
    postalCode: '32132',
  },
  'edgewater-md': {
    name: 'Edgewater',
    postalCode: '21037',
  },
  'edgewater-nj': {
    name: 'Edgewater',
    postalCode: '07020',
  },
  'edgewood-ia': {
    name: 'Edgewood',
    postalCode: '52042',
  },
  'edgewood-il': {
    name: 'Edgewood',
    postalCode: '62426',
  },
  'edgewood-md': {
    name: 'Edgewood',
    postalCode: '21040',
  },
  'edgewood-nm': {
    name: 'Edgewood',
    postalCode: '87015',
  },
  'edgewood-tx': {
    name: 'Edgewood',
    postalCode: '75117',
  },
  'edina-mo': {
    name: 'Edina',
    postalCode: '63537',
  },
  'edinboro-pa': {
    name: 'Edinboro',
    postalCode: '16412',
  },
  'edinburg-il': {
    name: 'Edinburg',
    postalCode: '62531',
  },
  'edinburg-nd': {
    name: 'Edinburg',
    postalCode: '58227',
  },
  'edinburg-pa': {
    name: 'Edinburg',
    postalCode: '16116',
  },
  'edinburg-tx': {
    name: 'Edinburg',
    postalCode: '78539',
  },
  'edinburg-va': {
    name: 'Edinburg',
    postalCode: '22824',
  },
  'edinburgh-in': {
    name: 'Edinburgh',
    postalCode: '46124',
  },
  'edison-ga': {
    name: 'Edison',
    postalCode: '39846',
  },
  'edison-ne': {
    name: 'Edison',
    postalCode: '68936',
  },
  'edison-nj': {
    name: 'Edison',
    postalCode: '08817',
  },
  'edison-oh': {
    name: 'Edison',
    postalCode: '43320',
  },
  'edisto-island-sc': {
    name: 'Edisto Island',
    postalCode: '29438',
  },
  'edmeston-ny': {
    name: 'Edmeston',
    postalCode: '13335',
  },
  'edmond-ok': {
    name: 'Edmond',
    postalCode: '73003',
  },
  'edmond-wv': {
    name: 'Edmond',
    postalCode: '25837',
  },
  'edmonds-wa': {
    name: 'Edmonds',
    postalCode: '98020',
  },
  'edmonton-ky': {
    name: 'Edmonton',
    postalCode: '42129',
  },
  'edmore-mi': {
    name: 'Edmore',
    postalCode: '48829',
  },
  'edmore-nd': {
    name: 'Edmore',
    postalCode: '58330',
  },
  'edna-ks': {
    name: 'Edna',
    postalCode: '67342',
  },
  'edna-tx': {
    name: 'Edna',
    postalCode: '77957',
  },
  'edon-oh': {
    name: 'Edon',
    postalCode: '43518',
  },
  'edson-ks': {
    name: 'Edson',
    postalCode: '67733',
  },
  'edwall-wa': {
    name: 'Edwall',
    postalCode: '99008',
  },
  'edward-nc': {
    name: 'Edward',
    postalCode: '27821',
  },
  'edwards-ca': {
    name: 'Edwards',
    postalCode: '93523',
  },
  'edwards-co': {
    name: 'Edwards',
    postalCode: '81632',
  },
  'edwards-il': {
    name: 'Edwards',
    postalCode: '61528',
  },
  'edwards-mo': {
    name: 'Edwards',
    postalCode: '65326',
  },
  'edwards-ms': {
    name: 'Edwards',
    postalCode: '39066',
  },
  'edwards-ny': {
    name: 'Edwards',
    postalCode: '13635',
  },
  'edwardsburg-mi': {
    name: 'Edwardsburg',
    postalCode: '49112',
  },
  'edwardsport-in': {
    name: 'Edwardsport',
    postalCode: '47528',
  },
  'edwardsville-il': {
    name: 'Edwardsville',
    postalCode: '62025',
  },
  'effie-la': {
    name: 'Effie',
    postalCode: '71331',
  },
  'effie-mn': {
    name: 'Effie',
    postalCode: '56639',
  },
  'effingham-il': {
    name: 'Effingham',
    postalCode: '62401',
  },
  'effingham-ks': {
    name: 'Effingham',
    postalCode: '66023',
  },
  'effingham-nh': {
    name: 'Effingham',
    postalCode: '03882',
  },
  'effingham-sc': {
    name: 'Effingham',
    postalCode: '29541',
  },
  'effort-pa': {
    name: 'Effort',
    postalCode: '18330',
  },
  'efland-nc': {
    name: 'Efland',
    postalCode: '27243',
  },
  'egan-la': {
    name: 'Egan',
    postalCode: '70531',
  },
  'egan-sd': {
    name: 'Egan',
    postalCode: '57024',
  },
  'egeland-nd': {
    name: 'Egeland',
    postalCode: '58331',
  },
  'egg-harbor-wi': {
    name: 'Egg Harbor',
    postalCode: '54209',
  },
  'egg-harbor-city-nj': {
    name: 'Egg Harbor City',
    postalCode: '08215',
  },
  'egg-harbor-township-nj': {
    name: 'Egg Harbor Township',
    postalCode: '08234',
  },
  'eggleston-va': {
    name: 'Eggleston',
    postalCode: '24086',
  },
  'eglin-afb-fl': {
    name: 'Eglin AFB',
    postalCode: '32542',
  },
  'eglon-wv': {
    name: 'Eglon',
    postalCode: '26716',
  },
  'egnar-co': {
    name: 'Egnar',
    postalCode: '81325',
  },
  'ehrhardt-sc': {
    name: 'Ehrhardt',
    postalCode: '29081',
  },
  'eidson-tn': {
    name: 'Eidson',
    postalCode: '37731',
  },
  'eielson-afb-ak': {
    name: 'Eielson AFB',
    postalCode: '99702',
  },
  'eight-mile-al': {
    name: 'Eight Mile',
    postalCode: '36613',
  },
  'eighty-eight-ky': {
    name: 'Eighty Eight',
    postalCode: '42130',
  },
  'eighty-four-pa': {
    name: 'Eighty Four',
    postalCode: '15330',
  },
  'eitzen-mn': {
    name: 'Eitzen',
    postalCode: '55931',
  },
  'ekalaka-mt': {
    name: 'Ekalaka',
    postalCode: '59324',
  },
  'ekron-ky': {
    name: 'Ekron',
    postalCode: '40117',
  },
  'el-cajon-ca': {
    name: 'El Cajon',
    postalCode: '92019',
  },
  'el-campo-tx': {
    name: 'El Campo',
    postalCode: '77437',
  },
  'el-centro-ca': {
    name: 'El Centro',
    postalCode: '92243',
  },
  'el-cerrito-ca': {
    name: 'El Cerrito',
    postalCode: '94530',
  },
  'el-dorado-ar': {
    name: 'El Dorado',
    postalCode: '71730',
  },
  'el-dorado-ca': {
    name: 'El Dorado',
    postalCode: '95623',
  },
  'el-dorado-ks': {
    name: 'El Dorado',
    postalCode: '67042',
  },
  'el-dorado-hills-ca': {
    name: 'El Dorado Hills',
    postalCode: '95762',
  },
  'el-dorado-springs-mo': {
    name: 'El Dorado Springs',
    postalCode: '64744',
  },
  'el-mirage-az': {
    name: 'El Mirage',
    postalCode: '85335',
  },
  'el-monte-ca': {
    name: 'El Monte',
    postalCode: '91731',
  },
  'el-nido-ca': {
    name: 'El Nido',
    postalCode: '95317',
  },
  'el-paso-ar': {
    name: 'El Paso',
    postalCode: '72045',
  },
  'el-paso-il': {
    name: 'El Paso',
    postalCode: '61738',
  },
  'el-paso-tx': {
    name: 'El Paso',
    postalCode: '79901',
  },
  'el-prado-nm': {
    name: 'El Prado',
    postalCode: '87529',
  },
  'el-reno-ok': {
    name: 'El Reno',
    postalCode: '73036',
  },
  'el-rito-nm': {
    name: 'El Rito',
    postalCode: '87530',
  },
  'el-segundo-ca': {
    name: 'El Segundo',
    postalCode: '90245',
  },
  'el-sobrante-ca': {
    name: 'El Sobrante',
    postalCode: '94803',
  },
  'elaine-ar': {
    name: 'Elaine',
    postalCode: '72333',
  },
  'eland-wi': {
    name: 'Eland',
    postalCode: '54427',
  },
  'elba-al': {
    name: 'Elba',
    postalCode: '36323',
  },
  'elba-ne': {
    name: 'Elba',
    postalCode: '68835',
  },
  'elba-ny': {
    name: 'Elba',
    postalCode: '14058',
  },
  'elbe-wa': {
    name: 'Elbe',
    postalCode: '98330',
  },
  'elberfeld-in': {
    name: 'Elberfeld',
    postalCode: '47613',
  },
  'elberon-ia': {
    name: 'Elberon',
    postalCode: '52225',
  },
  'elberon-va': {
    name: 'Elberon',
    postalCode: '23846',
  },
  'elbert-co': {
    name: 'Elbert',
    postalCode: '80106',
  },
  'elbert-wv': {
    name: 'Elbert',
    postalCode: '24830',
  },
  'elberta-al': {
    name: 'Elberta',
    postalCode: '36530',
  },
  'elberton-ga': {
    name: 'Elberton',
    postalCode: '30635',
  },
  'elbow-lake-mn': {
    name: 'Elbow Lake',
    postalCode: '56531',
  },
  'elbridge-ny': {
    name: 'Elbridge',
    postalCode: '13060',
  },
  'elbridge-tn': {
    name: 'Elbridge',
    postalCode: '38227',
  },
  'elburn-il': {
    name: 'Elburn',
    postalCode: '60119',
  },
  'elcho-wi': {
    name: 'Elcho',
    postalCode: '54428',
  },
  'eldon-ia': {
    name: 'Eldon',
    postalCode: '52554',
  },
  'eldon-mo': {
    name: 'Eldon',
    postalCode: '65026',
  },
  'eldora-ia': {
    name: 'Eldora',
    postalCode: '50627',
  },
  'eldorado-il': {
    name: 'Eldorado',
    postalCode: '62930',
  },
  'eldorado-oh': {
    name: 'Eldorado',
    postalCode: '45321',
  },
  'eldorado-ok': {
    name: 'Eldorado',
    postalCode: '73537',
  },
  'eldorado-tx': {
    name: 'Eldorado',
    postalCode: '76936',
  },
  'eldorado-wi': {
    name: 'Eldorado',
    postalCode: '54932',
  },
  'eldred-il': {
    name: 'Eldred',
    postalCode: '62027',
  },
  'eldred-ny': {
    name: 'Eldred',
    postalCode: '12732',
  },
  'eldred-pa': {
    name: 'Eldred',
    postalCode: '16731',
  },
  'eldridge-al': {
    name: 'Eldridge',
    postalCode: '35554',
  },
  'eldridge-ia': {
    name: 'Eldridge',
    postalCode: '52748',
  },
  'eldridge-mo': {
    name: 'Eldridge',
    postalCode: '65463',
  },
  'electra-tx': {
    name: 'Electra',
    postalCode: '76360',
  },
  'electric-city-wa': {
    name: 'Electric City',
    postalCode: '99123',
  },
  'eleva-wi': {
    name: 'Eleva',
    postalCode: '54738',
  },
  'elfrida-az': {
    name: 'Elfrida',
    postalCode: '85610',
  },
  'elgin-az': {
    name: 'Elgin',
    postalCode: '85611',
  },
  'elgin-ia': {
    name: 'Elgin',
    postalCode: '52141',
  },
  'elgin-il': {
    name: 'Elgin',
    postalCode: '60120',
  },
  'elgin-mn': {
    name: 'Elgin',
    postalCode: '55932',
  },
  'elgin-nd': {
    name: 'Elgin',
    postalCode: '58533',
  },
  'elgin-ne': {
    name: 'Elgin',
    postalCode: '68636',
  },
  'elgin-ok': {
    name: 'Elgin',
    postalCode: '73538',
  },
  'elgin-or': {
    name: 'Elgin',
    postalCode: '97827',
  },
  'elgin-sc': {
    name: 'Elgin',
    postalCode: '29045',
  },
  'elgin-tx': {
    name: 'Elgin',
    postalCode: '78621',
  },
  'elida-nm': {
    name: 'Elida',
    postalCode: '88116',
  },
  'eliot-me': {
    name: 'Eliot',
    postalCode: '03903',
  },
  'elizabeth-ar': {
    name: 'Elizabeth',
    postalCode: '72531',
  },
  'elizabeth-co': {
    name: 'Elizabeth',
    postalCode: '80107',
  },
  'elizabeth-il': {
    name: 'Elizabeth',
    postalCode: '61028',
  },
  'elizabeth-in': {
    name: 'Elizabeth',
    postalCode: '47117',
  },
  'elizabeth-mn': {
    name: 'Elizabeth',
    postalCode: '56533',
  },
  'elizabeth-nj': {
    name: 'Elizabeth',
    postalCode: '07201',
  },
  'elizabeth-pa': {
    name: 'Elizabeth',
    postalCode: '15037',
  },
  'elizabeth-wv': {
    name: 'Elizabeth',
    postalCode: '26143',
  },
  'elizabeth-city-nc': {
    name: 'Elizabeth City',
    postalCode: '27909',
  },
  'elizabethport-nj': {
    name: 'Elizabethport',
    postalCode: '07206',
  },
  'elizabethton-tn': {
    name: 'Elizabethton',
    postalCode: '37643',
  },
  'elizabethtown-il': {
    name: 'Elizabethtown',
    postalCode: '62931',
  },
  'elizabethtown-in': {
    name: 'Elizabethtown',
    postalCode: '47232',
  },
  'elizabethtown-ky': {
    name: 'Elizabethtown',
    postalCode: '42701',
  },
  'elizabethtown-nc': {
    name: 'Elizabethtown',
    postalCode: '28337',
  },
  'elizabethtown-ny': {
    name: 'Elizabethtown',
    postalCode: '12932',
  },
  'elizabethtown-pa': {
    name: 'Elizabethtown',
    postalCode: '17022',
  },
  'elizabethville-pa': {
    name: 'Elizabethville',
    postalCode: '17023',
  },
  'elizaville-ny': {
    name: 'Elizaville',
    postalCode: '12523',
  },
  'elk-ca': {
    name: 'Elk',
    postalCode: '95432',
  },
  'elk-wa': {
    name: 'Elk',
    postalCode: '99009',
  },
  'elk-city-id': {
    name: 'Elk City',
    postalCode: '83525',
  },
  'elk-city-ks': {
    name: 'Elk City',
    postalCode: '67344',
  },
  'elk-city-ok': {
    name: 'Elk City',
    postalCode: '73644',
  },
  'elk-creek-ca': {
    name: 'Elk Creek',
    postalCode: '95939',
  },
  'elk-creek-mo': {
    name: 'Elk Creek',
    postalCode: '65464',
  },
  'elk-creek-ne': {
    name: 'Elk Creek',
    postalCode: '68348',
  },
  'elk-creek-va': {
    name: 'Elk Creek',
    postalCode: '24326',
  },
  'elk-falls-ks': {
    name: 'Elk Falls',
    postalCode: '67345',
  },
  'elk-garden-wv': {
    name: 'Elk Garden',
    postalCode: '26717',
  },
  'elk-grove-ca': {
    name: 'Elk Grove',
    postalCode: '95624',
  },
  'elk-grove-village-il': {
    name: 'Elk Grove Village',
    postalCode: '60007',
  },
  'elk-horn-ia': {
    name: 'Elk Horn',
    postalCode: '51531',
  },
  'elk-horn-ky': {
    name: 'Elk Horn',
    postalCode: '42733',
  },
  'elk-mound-wi': {
    name: 'Elk Mound',
    postalCode: '54739',
  },
  'elk-park-nc': {
    name: 'Elk Park',
    postalCode: '28622',
  },
  'elk-point-sd': {
    name: 'Elk Point',
    postalCode: '57025',
  },
  'elk-rapids-mi': {
    name: 'Elk Rapids',
    postalCode: '49629',
  },
  'elk-river-mn': {
    name: 'Elk River',
    postalCode: '55330',
  },
  'elka-park-ny': {
    name: 'Elka Park',
    postalCode: '12427',
  },
  'elkader-ia': {
    name: 'Elkader',
    postalCode: '52043',
  },
  'elkfork-ky': {
    name: 'Elkfork',
    postalCode: '41421',
  },
  'elkhart-ia': {
    name: 'Elkhart',
    postalCode: '50073',
  },
  'elkhart-il': {
    name: 'Elkhart',
    postalCode: '62634',
  },
  'elkhart-in': {
    name: 'Elkhart',
    postalCode: '46514',
  },
  'elkhart-ks': {
    name: 'Elkhart',
    postalCode: '67950',
  },
  'elkhart-tx': {
    name: 'Elkhart',
    postalCode: '75839',
  },
  'elkhart-lake-wi': {
    name: 'Elkhart Lake',
    postalCode: '53020',
  },
  'elkhorn-ne': {
    name: 'Elkhorn',
    postalCode: '68022',
  },
  'elkhorn-wi': {
    name: 'Elkhorn',
    postalCode: '53121',
  },
  'elkhorn-wv': {
    name: 'Elkhorn',
    postalCode: '24831',
  },
  'elkhorn-city-ky': {
    name: 'Elkhorn City',
    postalCode: '41522',
  },
  'elkin-nc': {
    name: 'Elkin',
    postalCode: '28621',
  },
  'elkins-ar': {
    name: 'Elkins',
    postalCode: '72727',
  },
  'elkins-nh': {
    name: 'Elkins',
    postalCode: '03233',
  },
  'elkins-wv': {
    name: 'Elkins',
    postalCode: '26241',
  },
  'elkins-park-pa': {
    name: 'Elkins Park',
    postalCode: '19027',
  },
  'elkland-mo': {
    name: 'Elkland',
    postalCode: '65644',
  },
  'elkland-pa': {
    name: 'Elkland',
    postalCode: '16920',
  },
  'elkmont-al': {
    name: 'Elkmont',
    postalCode: '35620',
  },
  'elko-ga': {
    name: 'Elko',
    postalCode: '31025',
  },
  'elko-nv': {
    name: 'Elko',
    postalCode: '89801',
  },
  'elko-new-market-mn': {
    name: 'Elko New Market',
    postalCode: '55020',
  },
  'elkport-ia': {
    name: 'Elkport',
    postalCode: '52044',
  },
  'elkridge-md': {
    name: 'Elkridge',
    postalCode: '21075',
  },
  'elkton-fl': {
    name: 'Elkton',
    postalCode: '32033',
  },
  'elkton-ky': {
    name: 'Elkton',
    postalCode: '42220',
  },
  'elkton-md': {
    name: 'Elkton',
    postalCode: '21921',
  },
  'elkton-mi': {
    name: 'Elkton',
    postalCode: '48731',
  },
  'elkton-mn': {
    name: 'Elkton',
    postalCode: '55933',
  },
  'elkton-or': {
    name: 'Elkton',
    postalCode: '97436',
  },
  'elkton-sd': {
    name: 'Elkton',
    postalCode: '57026',
  },
  'elkton-va': {
    name: 'Elkton',
    postalCode: '22827',
  },
  'elkview-wv': {
    name: 'Elkview',
    postalCode: '25071',
  },
  'elkville-il': {
    name: 'Elkville',
    postalCode: '62932',
  },
  'elkwood-va': {
    name: 'Elkwood',
    postalCode: '22718',
  },
  'ellabell-ga': {
    name: 'Ellabell',
    postalCode: '31308',
  },
  'ellamore-wv': {
    name: 'Ellamore',
    postalCode: '26267',
  },
  'ellaville-ga': {
    name: 'Ellaville',
    postalCode: '31806',
  },
  'ellenboro-nc': {
    name: 'Ellenboro',
    postalCode: '28040',
  },
  'ellenboro-wv': {
    name: 'Ellenboro',
    postalCode: '26346',
  },
  'ellenburg-center-ny': {
    name: 'Ellenburg Center',
    postalCode: '12934',
  },
  'ellenburg-depot-ny': {
    name: 'Ellenburg Depot',
    postalCode: '12935',
  },
  'ellendale-de': {
    name: 'Ellendale',
    postalCode: '19941',
  },
  'ellendale-mn': {
    name: 'Ellendale',
    postalCode: '56026',
  },
  'ellendale-nd': {
    name: 'Ellendale',
    postalCode: '58436',
  },
  'ellensburg-wa': {
    name: 'Ellensburg',
    postalCode: '98926',
  },
  'ellenton-fl': {
    name: 'Ellenton',
    postalCode: '34222',
  },
  'ellenville-ny': {
    name: 'Ellenville',
    postalCode: '12428',
  },
  'ellenwood-ga': {
    name: 'Ellenwood',
    postalCode: '30294',
  },
  'ellerbe-nc': {
    name: 'Ellerbe',
    postalCode: '28338',
  },
  'ellerslie-ga': {
    name: 'Ellerslie',
    postalCode: '31807',
  },
  'ellery-il': {
    name: 'Ellery',
    postalCode: '62833',
  },
  'ellettsville-in': {
    name: 'Ellettsville',
    postalCode: '47429',
  },
  'ellicott-city-md': {
    name: 'Ellicott City',
    postalCode: '21042',
  },
  'ellicottville-ny': {
    name: 'Ellicottville',
    postalCode: '14731',
  },
  'ellijay-ga': {
    name: 'Ellijay',
    postalCode: '30536',
  },
  'ellinger-tx': {
    name: 'Ellinger',
    postalCode: '78938',
  },
  'ellington-ct': {
    name: 'Ellington',
    postalCode: '06029',
  },
  'ellington-mo': {
    name: 'Ellington',
    postalCode: '63638',
  },
  'ellinwood-ks': {
    name: 'Ellinwood',
    postalCode: '67526',
  },
  'elliott-ia': {
    name: 'Elliott',
    postalCode: '51532',
  },
  'elliottsburg-pa': {
    name: 'Elliottsburg',
    postalCode: '17024',
  },
  'ellis-id': {
    name: 'Ellis',
    postalCode: '83235',
  },
  'ellis-ks': {
    name: 'Ellis',
    postalCode: '67637',
  },
  'ellis-grove-il': {
    name: 'Ellis Grove',
    postalCode: '62241',
  },
  'ellisburg-ny': {
    name: 'Ellisburg',
    postalCode: '13636',
  },
  'ellison-bay-wi': {
    name: 'Ellison Bay',
    postalCode: '54210',
  },
  'elliston-va': {
    name: 'Elliston',
    postalCode: '24087',
  },
  'ellisville-il': {
    name: 'Ellisville',
    postalCode: '61431',
  },
  'ellisville-ms': {
    name: 'Ellisville',
    postalCode: '39437',
  },
  'elloree-sc': {
    name: 'Elloree',
    postalCode: '29047',
  },
  'ellsinore-mo': {
    name: 'Ellsinore',
    postalCode: '63937',
  },
  'ellston-ia': {
    name: 'Ellston',
    postalCode: '50074',
  },
  'ellsworth-ia': {
    name: 'Ellsworth',
    postalCode: '50075',
  },
  'ellsworth-il': {
    name: 'Ellsworth',
    postalCode: '61737',
  },
  'ellsworth-ks': {
    name: 'Ellsworth',
    postalCode: '67439',
  },
  'ellsworth-me': {
    name: 'Ellsworth',
    postalCode: '04605',
  },
  'ellsworth-mi': {
    name: 'Ellsworth',
    postalCode: '49729',
  },
  'ellsworth-mn': {
    name: 'Ellsworth',
    postalCode: '56129',
  },
  'ellsworth-ne': {
    name: 'Ellsworth',
    postalCode: '69340',
  },
  'ellsworth-wi': {
    name: 'Ellsworth',
    postalCode: '54011',
  },
  'ellsworth-afb-sd': {
    name: 'Ellsworth AFB',
    postalCode: '57706',
  },
  'ellwood-city-pa': {
    name: 'Ellwood City',
    postalCode: '16117',
  },
  'elm-city-nc': {
    name: 'Elm City',
    postalCode: '27822',
  },
  'elm-creek-ne': {
    name: 'Elm Creek',
    postalCode: '68836',
  },
  'elm-grove-la': {
    name: 'Elm Grove',
    postalCode: '71051',
  },
  'elm-grove-wi': {
    name: 'Elm Grove',
    postalCode: '53122',
  },
  'elm-mott-tx': {
    name: 'Elm Mott',
    postalCode: '76640',
  },
  'elma-ia': {
    name: 'Elma',
    postalCode: '50628',
  },
  'elma-ny': {
    name: 'Elma',
    postalCode: '14059',
  },
  'elma-wa': {
    name: 'Elma',
    postalCode: '98541',
  },
  'elmdale-ks': {
    name: 'Elmdale',
    postalCode: '66850',
  },
  'elmendorf-tx': {
    name: 'Elmendorf',
    postalCode: '78112',
  },
  'elmer-la': {
    name: 'Elmer',
    postalCode: '71424',
  },
  'elmer-mo': {
    name: 'Elmer',
    postalCode: '63538',
  },
  'elmer-nj': {
    name: 'Elmer',
    postalCode: '08318',
  },
  'elmer-ok': {
    name: 'Elmer',
    postalCode: '73539',
  },
  'elmhurst-il': {
    name: 'Elmhurst',
    postalCode: '60126',
  },
  'elmhurst-ny': {
    name: 'Elmhurst',
    postalCode: '11373',
  },
  'elmira-mi': {
    name: 'Elmira',
    postalCode: '49730',
  },
  'elmira-ny': {
    name: 'Elmira',
    postalCode: '14901',
  },
  'elmira-or': {
    name: 'Elmira',
    postalCode: '97437',
  },
  'elmo-mo': {
    name: 'Elmo',
    postalCode: '64445',
  },
  'elmo-mt': {
    name: 'Elmo',
    postalCode: '59915',
  },
  'elmo-ut': {
    name: 'Elmo',
    postalCode: '84521',
  },
  'elmont-ny': {
    name: 'Elmont',
    postalCode: '11003',
  },
  'elmore-al': {
    name: 'Elmore',
    postalCode: '36025',
  },
  'elmore-mn': {
    name: 'Elmore',
    postalCode: '56027',
  },
  'elmore-oh': {
    name: 'Elmore',
    postalCode: '43416',
  },
  'elmore-city-ok': {
    name: 'Elmore City',
    postalCode: '73433',
  },
  'elmsford-ny': {
    name: 'Elmsford',
    postalCode: '10523',
  },
  'elmwood-il': {
    name: 'Elmwood',
    postalCode: '61529',
  },
  'elmwood-ne': {
    name: 'Elmwood',
    postalCode: '68349',
  },
  'elmwood-tn': {
    name: 'Elmwood',
    postalCode: '38560',
  },
  'elmwood-wi': {
    name: 'Elmwood',
    postalCode: '54740',
  },
  'elmwood-park-il': {
    name: 'Elmwood Park',
    postalCode: '60707',
  },
  'elmwood-park-nj': {
    name: 'Elmwood Park',
    postalCode: '07407',
  },
  'elnora-in': {
    name: 'Elnora',
    postalCode: '47529',
  },
  'elon-nc': {
    name: 'Elon',
    postalCode: '27244',
  },
  'elora-tn': {
    name: 'Elora',
    postalCode: '37328',
  },
  'eloy-az': {
    name: 'Eloy',
    postalCode: '85131',
  },
  'elrod-al': {
    name: 'Elrod',
    postalCode: '35458',
  },
  'elroy-wi': {
    name: 'Elroy',
    postalCode: '53929',
  },
  'elsah-il': {
    name: 'Elsah',
    postalCode: '62028',
  },
  'elsberry-mo': {
    name: 'Elsberry',
    postalCode: '63343',
  },
  'elsie-mi': {
    name: 'Elsie',
    postalCode: '48831',
  },
  'elsie-ne': {
    name: 'Elsie',
    postalCode: '69134',
  },
  'elsinore-ut': {
    name: 'Elsinore',
    postalCode: '84724',
  },
  'elsmere-ne': {
    name: 'Elsmere',
    postalCode: '69135',
  },
  'elsmore-ks': {
    name: 'Elsmore',
    postalCode: '66732',
  },
  'elton-la': {
    name: 'Elton',
    postalCode: '70532',
  },
  'elton-wi': {
    name: 'Elton',
    postalCode: '54430',
  },
  'elton-wv': {
    name: 'Elton',
    postalCode: '25965',
  },
  'eltopia-wa': {
    name: 'Eltopia',
    postalCode: '99330',
  },
  'elverson-pa': {
    name: 'Elverson',
    postalCode: '19520',
  },
  'elverta-ca': {
    name: 'Elverta',
    postalCode: '95626',
  },
  'elwell-mi': {
    name: 'Elwell',
    postalCode: '48832',
  },
  'elwood-il': {
    name: 'Elwood',
    postalCode: '60421',
  },
  'elwood-in': {
    name: 'Elwood',
    postalCode: '46036',
  },
  'elwood-ne': {
    name: 'Elwood',
    postalCode: '68937',
  },
  'ely-ia': {
    name: 'Ely',
    postalCode: '52227',
  },
  'ely-mn': {
    name: 'Ely',
    postalCode: '55731',
  },
  'ely-nv': {
    name: 'Ely',
    postalCode: '89301',
  },
  'elyria-ne': {
    name: 'Elyria',
    postalCode: '68837',
  },
  'elyria-oh': {
    name: 'Elyria',
    postalCode: '44035',
  },
  'elysburg-pa': {
    name: 'Elysburg',
    postalCode: '17824',
  },
  'elysian-mn': {
    name: 'Elysian',
    postalCode: '56028',
  },
  'embarrass-mn': {
    name: 'Embarrass',
    postalCode: '55732',
  },
  'embudo-nm': {
    name: 'Embudo',
    postalCode: '87531',
  },
  'emden-il': {
    name: 'Emden',
    postalCode: '62635',
  },
  'emden-mo': {
    name: 'Emden',
    postalCode: '63439',
  },
  'emelle-al': {
    name: 'Emelle',
    postalCode: '35459',
  },
  'emerado-nd': {
    name: 'Emerado',
    postalCode: '58228',
  },
  'emerald-isle-nc': {
    name: 'Emerald Isle',
    postalCode: '28594',
  },
  'emerson-ar': {
    name: 'Emerson',
    postalCode: '71740',
  },
  'emerson-ga': {
    name: 'Emerson',
    postalCode: '30137',
  },
  'emerson-ia': {
    name: 'Emerson',
    postalCode: '51533',
  },
  'emerson-ky': {
    name: 'Emerson',
    postalCode: '41135',
  },
  'emerson-ne': {
    name: 'Emerson',
    postalCode: '68733',
  },
  'emerson-nj': {
    name: 'Emerson',
    postalCode: '07630',
  },
  'emery-sd': {
    name: 'Emery',
    postalCode: '57332',
  },
  'emeryville-ca': {
    name: 'Emeryville',
    postalCode: '94608',
  },
  'emigrant-mt': {
    name: 'Emigrant',
    postalCode: '59027',
  },
  'emily-mn': {
    name: 'Emily',
    postalCode: '56447',
  },
  'eminence-ky': {
    name: 'Eminence',
    postalCode: '40019',
  },
  'eminence-mo': {
    name: 'Eminence',
    postalCode: '65466',
  },
  'emington-il': {
    name: 'Emington',
    postalCode: '60934',
  },
  'emlenton-pa': {
    name: 'Emlenton',
    postalCode: '16373',
  },
  'emmalena-ky': {
    name: 'Emmalena',
    postalCode: '41740',
  },
  'emmaus-pa': {
    name: 'Emmaus',
    postalCode: '18049',
  },
  'emmet-ar': {
    name: 'Emmet',
    postalCode: '71835',
  },
  'emmet-ne': {
    name: 'Emmet',
    postalCode: '68734',
  },
  'emmetsburg-ia': {
    name: 'Emmetsburg',
    postalCode: '50536',
  },
  'emmett-id': {
    name: 'Emmett',
    postalCode: '83617',
  },
  'emmett-ks': {
    name: 'Emmett',
    postalCode: '66422',
  },
  'emmett-mi': {
    name: 'Emmett',
    postalCode: '48022',
  },
  'emmitsburg-md': {
    name: 'Emmitsburg',
    postalCode: '21727',
  },
  'emmons-mn': {
    name: 'Emmons',
    postalCode: '56029',
  },
  'emory-tx': {
    name: 'Emory',
    postalCode: '75440',
  },
  'empire-al': {
    name: 'Empire',
    postalCode: '35063',
  },
  'empire-mi': {
    name: 'Empire',
    postalCode: '49630',
  },
  'emporia-ks': {
    name: 'Emporia',
    postalCode: '66801',
  },
  'emporia-va': {
    name: 'Emporia',
    postalCode: '23847',
  },
  'emporium-pa': {
    name: 'Emporium',
    postalCode: '15834',
  },
  'encampment-wy': {
    name: 'Encampment',
    postalCode: '82325',
  },
  'encinitas-ca': {
    name: 'Encinitas',
    postalCode: '92024',
  },
  'encino-ca': {
    name: 'Encino',
    postalCode: '91316',
  },
  'encino-nm': {
    name: 'Encino',
    postalCode: '88321',
  },
  'encino-tx': {
    name: 'Encino',
    postalCode: '78353',
  },
  'endeavor-wi': {
    name: 'Endeavor',
    postalCode: '53930',
  },
  'enderlin-nd': {
    name: 'Enderlin',
    postalCode: '58027',
  },
  'enders-ne': {
    name: 'Enders',
    postalCode: '69027',
  },
  'endicott-ne': {
    name: 'Endicott',
    postalCode: '68350',
  },
  'endicott-ny': {
    name: 'Endicott',
    postalCode: '13760',
  },
  'endicott-wa': {
    name: 'Endicott',
    postalCode: '99125',
  },
  'energy-il': {
    name: 'Energy',
    postalCode: '62933',
  },
  'enfield-ct': {
    name: 'Enfield',
    postalCode: '06082',
  },
  'enfield-il': {
    name: 'Enfield',
    postalCode: '62835',
  },
  'enfield-nc': {
    name: 'Enfield',
    postalCode: '27823',
  },
  'enfield-nh': {
    name: 'Enfield',
    postalCode: '03748',
  },
  'engadine-mi': {
    name: 'Engadine',
    postalCode: '49827',
  },
  'engelhard-nc': {
    name: 'Engelhard',
    postalCode: '27824',
  },
  'england-ar': {
    name: 'England',
    postalCode: '72046',
  },
  'englewood-co': {
    name: 'Englewood',
    postalCode: '80110',
  },
  'englewood-fl': {
    name: 'Englewood',
    postalCode: '34223',
  },
  'englewood-nj': {
    name: 'Englewood',
    postalCode: '07631',
  },
  'englewood-oh': {
    name: 'Englewood',
    postalCode: '45322',
  },
  'englewood-tn': {
    name: 'Englewood',
    postalCode: '37329',
  },
  'englewood-cliffs-nj': {
    name: 'Englewood Cliffs',
    postalCode: '07632',
  },
  'english-in': {
    name: 'English',
    postalCode: '47118',
  },
  'englishtown-nj': {
    name: 'Englishtown',
    postalCode: '07726',
  },
  'enid-ms': {
    name: 'Enid',
    postalCode: '38927',
  },
  'enid-ok': {
    name: 'Enid',
    postalCode: '73701',
  },
  'enigma-ga': {
    name: 'Enigma',
    postalCode: '31749',
  },
  'ennice-nc': {
    name: 'Ennice',
    postalCode: '28623',
  },
  'enning-sd': {
    name: 'Enning',
    postalCode: '57737',
  },
  'ennis-mt': {
    name: 'Ennis',
    postalCode: '59729',
  },
  'ennis-tx': {
    name: 'Ennis',
    postalCode: '75119',
  },
  'enochs-tx': {
    name: 'Enochs',
    postalCode: '79324',
  },
  'enola-ar': {
    name: 'Enola',
    postalCode: '72047',
  },
  'enola-pa': {
    name: 'Enola',
    postalCode: '17025',
  },
  'enon-oh': {
    name: 'Enon',
    postalCode: '45323',
  },
  'enon-valley-pa': {
    name: 'Enon Valley',
    postalCode: '16120',
  },
  'enoree-sc': {
    name: 'Enoree',
    postalCode: '29335',
  },
  'enosburg-falls-vt': {
    name: 'Enosburg Falls',
    postalCode: '05450',
  },
  'ensenada-pr': {
    name: 'Ensenada',
    postalCode: '00647',
  },
  'ensign-ks': {
    name: 'Ensign',
    postalCode: '67841',
  },
  'enterprise-al': {
    name: 'Enterprise',
    postalCode: '36330',
  },
  'enterprise-ks': {
    name: 'Enterprise',
    postalCode: '67441',
  },
  'enterprise-la': {
    name: 'Enterprise',
    postalCode: '71425',
  },
  'enterprise-ms': {
    name: 'Enterprise',
    postalCode: '39330',
  },
  'enterprise-or': {
    name: 'Enterprise',
    postalCode: '97828',
  },
  'enterprise-wv': {
    name: 'Enterprise',
    postalCode: '26568',
  },
  'entiat-wa': {
    name: 'Entiat',
    postalCode: '98822',
  },
  'entriken-pa': {
    name: 'Entriken',
    postalCode: '16638',
  },
  'enumclaw-wa': {
    name: 'Enumclaw',
    postalCode: '98022',
  },
  'enville-tn': {
    name: 'Enville',
    postalCode: '38332',
  },
  'eola-tx': {
    name: 'Eola',
    postalCode: '76937',
  },
  'eolia-ky': {
    name: 'Eolia',
    postalCode: '40826',
  },
  'eolia-mo': {
    name: 'Eolia',
    postalCode: '63344',
  },
  'epes-al': {
    name: 'Epes',
    postalCode: '35460',
  },
  'ephraim-ut': {
    name: 'Ephraim',
    postalCode: '84627',
  },
  'ephrata-pa': {
    name: 'Ephrata',
    postalCode: '17522',
  },
  'ephrata-wa': {
    name: 'Ephrata',
    postalCode: '98823',
  },
  'epping-nd': {
    name: 'Epping',
    postalCode: '58843',
  },
  'epping-nh': {
    name: 'Epping',
    postalCode: '03042',
  },
  'epps-la': {
    name: 'Epps',
    postalCode: '71237',
  },
  'epsom-nh': {
    name: 'Epsom',
    postalCode: '03234',
  },
  'epworth-ga': {
    name: 'Epworth',
    postalCode: '30541',
  },
  'epworth-ia': {
    name: 'Epworth',
    postalCode: '52045',
  },
  'equality-al': {
    name: 'Equality',
    postalCode: '36026',
  },
  'equality-il': {
    name: 'Equality',
    postalCode: '62934',
  },
  'equinunk-pa': {
    name: 'Equinunk',
    postalCode: '18417',
  },
  'era-tx': {
    name: 'Era',
    postalCode: '76238',
  },
  'erath-la': {
    name: 'Erath',
    postalCode: '70533',
  },
  'erbacon-wv': {
    name: 'Erbacon',
    postalCode: '26203',
  },
  'erhard-mn': {
    name: 'Erhard',
    postalCode: '56534',
  },
  'erick-ok': {
    name: 'Erick',
    postalCode: '73645',
  },
  'ericson-ne': {
    name: 'Ericson',
    postalCode: '68637',
  },
  'erie-co': {
    name: 'Erie',
    postalCode: '80516',
  },
  'erie-il': {
    name: 'Erie',
    postalCode: '61250',
  },
  'erie-ks': {
    name: 'Erie',
    postalCode: '66733',
  },
  'erie-mi': {
    name: 'Erie',
    postalCode: '48133',
  },
  'erie-nd': {
    name: 'Erie',
    postalCode: '58029',
  },
  'erie-pa': {
    name: 'Erie',
    postalCode: '16501',
  },
  'erieville-ny': {
    name: 'Erieville',
    postalCode: '13061',
  },
  'erin-ny': {
    name: 'Erin',
    postalCode: '14838',
  },
  'erin-tn': {
    name: 'Erin',
    postalCode: '37061',
  },
  'erlanger-ky': {
    name: 'Erlanger',
    postalCode: '41018',
  },
  'ermine-ky': {
    name: 'Ermine',
    postalCode: '41815',
  },
  'ernul-nc': {
    name: 'Ernul',
    postalCode: '28527',
  },
  'eros-la': {
    name: 'Eros',
    postalCode: '71238',
  },
  'errol-nh': {
    name: 'Errol',
    postalCode: '03579',
  },
  'erskine-mn': {
    name: 'Erskine',
    postalCode: '56535',
  },
  'erving-ma': {
    name: 'Erving',
    postalCode: '01344',
  },
  'erwin-nc': {
    name: 'Erwin',
    postalCode: '28339',
  },
  'erwin-sd': {
    name: 'Erwin',
    postalCode: '57233',
  },
  'erwin-tn': {
    name: 'Erwin',
    postalCode: '37650',
  },
  'erwinna-pa': {
    name: 'Erwinna',
    postalCode: '18920',
  },
  'erwinville-la': {
    name: 'Erwinville',
    postalCode: '70729',
  },
  'esbon-ks': {
    name: 'Esbon',
    postalCode: '66941',
  },
  'escalon-ca': {
    name: 'Escalon',
    postalCode: '95320',
  },
  'escanaba-mi': {
    name: 'Escanaba',
    postalCode: '49829',
  },
  'escondido-ca': {
    name: 'Escondido',
    postalCode: '92025',
  },
  'eskdale-wv': {
    name: 'Eskdale',
    postalCode: '25075',
  },
  'esko-mn': {
    name: 'Esko',
    postalCode: '55733',
  },
  'eskridge-ks': {
    name: 'Eskridge',
    postalCode: '66423',
  },
  'esmond-il': {
    name: 'Esmond',
    postalCode: '60129',
  },
  'esmond-nd': {
    name: 'Esmond',
    postalCode: '58332',
  },
  'esmont-va': {
    name: 'Esmont',
    postalCode: '22937',
  },
  'espanola-nm': {
    name: 'Espanola',
    postalCode: '87532',
  },
  'esparto-ca': {
    name: 'Esparto',
    postalCode: '95627',
  },
  'esperance-ny': {
    name: 'Esperance',
    postalCode: '12066',
  },
  'essex-ca': {
    name: 'Essex',
    postalCode: '92332',
  },
  'essex-ct': {
    name: 'Essex',
    postalCode: '06426',
  },
  'essex-ia': {
    name: 'Essex',
    postalCode: '51638',
  },
  'essex-il': {
    name: 'Essex',
    postalCode: '60935',
  },
  'essex-ma': {
    name: 'Essex',
    postalCode: '01929',
  },
  'essex-md': {
    name: 'Essex',
    postalCode: '21221',
  },
  'essex-mo': {
    name: 'Essex',
    postalCode: '63846',
  },
  'essex-mt': {
    name: 'Essex',
    postalCode: '59916',
  },
  'essex-ny': {
    name: 'Essex',
    postalCode: '12936',
  },
  'essex-fells-nj': {
    name: 'Essex Fells',
    postalCode: '07021',
  },
  'essex-junction-vt': {
    name: 'Essex Junction',
    postalCode: '05452',
  },
  'essexville-mi': {
    name: 'Essexville',
    postalCode: '48732',
  },
  'essie-ky': {
    name: 'Essie',
    postalCode: '40827',
  },
  'essington-pa': {
    name: 'Essington',
    postalCode: '19029',
  },
  'estacada-or': {
    name: 'Estacada',
    postalCode: '97023',
  },
  'estancia-nm': {
    name: 'Estancia',
    postalCode: '87016',
  },
  'estell-manor-nj': {
    name: 'Estell Manor',
    postalCode: '08319',
  },
  'estelline-sd': {
    name: 'Estelline',
    postalCode: '57234',
  },
  'estero-fl': {
    name: 'Estero',
    postalCode: '33928',
  },
  'estes-park-co': {
    name: 'Estes Park',
    postalCode: '80517',
  },
  'estherville-ia': {
    name: 'Estherville',
    postalCode: '51334',
  },
  'estill-sc': {
    name: 'Estill',
    postalCode: '29918',
  },
  'estill-springs-tn': {
    name: 'Estill Springs',
    postalCode: '37330',
  },
  'estillfork-al': {
    name: 'Estillfork',
    postalCode: '35745',
  },
  'ethan-sd': {
    name: 'Ethan',
    postalCode: '57334',
  },
  'ethel-ar': {
    name: 'Ethel',
    postalCode: '72048',
  },
  'ethel-la': {
    name: 'Ethel',
    postalCode: '70730',
  },
  'ethel-mo': {
    name: 'Ethel',
    postalCode: '63539',
  },
  'ethel-ms': {
    name: 'Ethel',
    postalCode: '39067',
  },
  'ethel-wa': {
    name: 'Ethel',
    postalCode: '98542',
  },
  'ethelsville-al': {
    name: 'Ethelsville',
    postalCode: '35461',
  },
  'ethridge-tn': {
    name: 'Ethridge',
    postalCode: '38456',
  },
  'etlan-va': {
    name: 'Etlan',
    postalCode: '22719',
  },
  'etna-ca': {
    name: 'Etna',
    postalCode: '96027',
  },
  'etna-me': {
    name: 'Etna',
    postalCode: '04434',
  },
  'etna-nh': {
    name: 'Etna',
    postalCode: '03750',
  },
  'etna-wy': {
    name: 'Etna',
    postalCode: '83118',
  },
  'etna-green-in': {
    name: 'Etna Green',
    postalCode: '46524',
  },
  'etoile-ky': {
    name: 'Etoile',
    postalCode: '42131',
  },
  'etoile-tx': {
    name: 'Etoile',
    postalCode: '75944',
  },
  'etowah-ar': {
    name: 'Etowah',
    postalCode: '72428',
  },
  'etowah-nc': {
    name: 'Etowah',
    postalCode: '28729',
  },
  'etowah-tn': {
    name: 'Etowah',
    postalCode: '37331',
  },
  'etta-ms': {
    name: 'Etta',
    postalCode: '38627',
  },
  'etters-pa': {
    name: 'Etters',
    postalCode: '17319',
  },
  'ettrick-wi': {
    name: 'Ettrick',
    postalCode: '54627',
  },
  'eubank-ky': {
    name: 'Eubank',
    postalCode: '42567',
  },
  'eucha-ok': {
    name: 'Eucha',
    postalCode: '74342',
  },
  'euclid-mn': {
    name: 'Euclid',
    postalCode: '56722',
  },
  'euclid-oh': {
    name: 'Euclid',
    postalCode: '44117',
  },
  'eudora-ar': {
    name: 'Eudora',
    postalCode: '71640',
  },
  'eudora-ks': {
    name: 'Eudora',
    postalCode: '66025',
  },
  'eufaula-al': {
    name: 'Eufaula',
    postalCode: '36027',
  },
  'eufaula-ok': {
    name: 'Eufaula',
    postalCode: '74432',
  },
  'eugene-mo': {
    name: 'Eugene',
    postalCode: '65032',
  },
  'eugene-or': {
    name: 'Eugene',
    postalCode: '97401',
  },
  'euless-tx': {
    name: 'Euless',
    postalCode: '76039',
  },
  'eunice-la': {
    name: 'Eunice',
    postalCode: '70535',
  },
  'eunice-mo': {
    name: 'Eunice',
    postalCode: '65468',
  },
  'eupora-ms': {
    name: 'Eupora',
    postalCode: '39744',
  },
  'eure-nc': {
    name: 'Eure',
    postalCode: '27935',
  },
  'eureka-ca': {
    name: 'Eureka',
    postalCode: '95501',
  },
  'eureka-il': {
    name: 'Eureka',
    postalCode: '61530',
  },
  'eureka-ks': {
    name: 'Eureka',
    postalCode: '67045',
  },
  'eureka-mo': {
    name: 'Eureka',
    postalCode: '63025',
  },
  'eureka-mt': {
    name: 'Eureka',
    postalCode: '59917',
  },
  'eureka-nv': {
    name: 'Eureka',
    postalCode: '89316',
  },
  'eureka-sd': {
    name: 'Eureka',
    postalCode: '57437',
  },
  'eureka-springs-ar': {
    name: 'Eureka Springs',
    postalCode: '72631',
  },
  'eustace-tx': {
    name: 'Eustace',
    postalCode: '75124',
  },
  'eustis-fl': {
    name: 'Eustis',
    postalCode: '32726',
  },
  'eustis-me': {
    name: 'Eustis',
    postalCode: '04936',
  },
  'eustis-ne': {
    name: 'Eustis',
    postalCode: '69028',
  },
  'eutaw-al': {
    name: 'Eutaw',
    postalCode: '35462',
  },
  'eutawville-sc': {
    name: 'Eutawville',
    postalCode: '29048',
  },
  'eva-al': {
    name: 'Eva',
    postalCode: '35621',
  },
  'eva-tn': {
    name: 'Eva',
    postalCode: '38333',
  },
  'evangeline-la': {
    name: 'Evangeline',
    postalCode: '70537',
  },
  'evans-co': {
    name: 'Evans',
    postalCode: '80620',
  },
  'evans-ga': {
    name: 'Evans',
    postalCode: '30809',
  },
  'evans-la': {
    name: 'Evans',
    postalCode: '70639',
  },
  'evans-wa': {
    name: 'Evans',
    postalCode: '99126',
  },
  'evans-wv': {
    name: 'Evans',
    postalCode: '25241',
  },
  'evans-city-pa': {
    name: 'Evans City',
    postalCode: '16033',
  },
  'evans-mills-ny': {
    name: 'Evans Mills',
    postalCode: '13637',
  },
  'evansdale-ia': {
    name: 'Evansdale',
    postalCode: '50707',
  },
  'evanston-il': {
    name: 'Evanston',
    postalCode: '60201',
  },
  'evanston-in': {
    name: 'Evanston',
    postalCode: '47531',
  },
  'evanston-wy': {
    name: 'Evanston',
    postalCode: '82930',
  },
  'evansville-ar': {
    name: 'Evansville',
    postalCode: '72729',
  },
  'evansville-il': {
    name: 'Evansville',
    postalCode: '62242',
  },
  'evansville-in': {
    name: 'Evansville',
    postalCode: '47708',
  },
  'evansville-mn': {
    name: 'Evansville',
    postalCode: '56326',
  },
  'evansville-wi': {
    name: 'Evansville',
    postalCode: '53536',
  },
  'evansville-wy': {
    name: 'Evansville',
    postalCode: '82636',
  },
  'evant-tx': {
    name: 'Evant',
    postalCode: '76525',
  },
  'evart-mi': {
    name: 'Evart',
    postalCode: '49631',
  },
  'evarts-ky': {
    name: 'Evarts',
    postalCode: '40828',
  },
  'eveleth-mn': {
    name: 'Eveleth',
    postalCode: '55734',
  },
  'evening-shade-ar': {
    name: 'Evening Shade',
    postalCode: '72532',
  },
  'evensville-tn': {
    name: 'Evensville',
    postalCode: '37332',
  },
  'everest-ks': {
    name: 'Everest',
    postalCode: '66424',
  },
  'everett-ma': {
    name: 'Everett',
    postalCode: '02149',
  },
  'everett-pa': {
    name: 'Everett',
    postalCode: '15537',
  },
  'everett-wa': {
    name: 'Everett',
    postalCode: '98201',
  },
  'evergreen-al': {
    name: 'Evergreen',
    postalCode: '36401',
  },
  'evergreen-co': {
    name: 'Evergreen',
    postalCode: '80439',
  },
  'evergreen-la': {
    name: 'Evergreen',
    postalCode: '71333',
  },
  'evergreen-nc': {
    name: 'Evergreen',
    postalCode: '28438',
  },
  'evergreen-park-il': {
    name: 'Evergreen Park',
    postalCode: '60805',
  },
  'everly-ia': {
    name: 'Everly',
    postalCode: '51338',
  },
  'everson-wa': {
    name: 'Everson',
    postalCode: '98247',
  },
  'everton-ar': {
    name: 'Everton',
    postalCode: '72633',
  },
  'everton-mo': {
    name: 'Everton',
    postalCode: '65646',
  },
  'evington-va': {
    name: 'Evington',
    postalCode: '24550',
  },
  'ewa-beach-hi': {
    name: 'Ewa Beach',
    postalCode: '96706',
  },
  'ewell-md': {
    name: 'Ewell',
    postalCode: '21824',
  },
  'ewen-mi': {
    name: 'Ewen',
    postalCode: '49925',
  },
  'ewing-il': {
    name: 'Ewing',
    postalCode: '62836',
  },
  'ewing-ky': {
    name: 'Ewing',
    postalCode: '41039',
  },
  'ewing-mo': {
    name: 'Ewing',
    postalCode: '63440',
  },
  'ewing-ne': {
    name: 'Ewing',
    postalCode: '68735',
  },
  'ewing-va': {
    name: 'Ewing',
    postalCode: '24248',
  },
  'excello-mo': {
    name: 'Excello',
    postalCode: '65247',
  },
  'excelsior-mn': {
    name: 'Excelsior',
    postalCode: '55331',
  },
  'excelsior-springs-mo': {
    name: 'Excelsior Springs',
    postalCode: '64024',
  },
  'exchange-wv': {
    name: 'Exchange',
    postalCode: '26619',
  },
  'exeland-wi': {
    name: 'Exeland',
    postalCode: '54835',
  },
  'exeter-ca': {
    name: 'Exeter',
    postalCode: '93221',
  },
  'exeter-me': {
    name: 'Exeter',
    postalCode: '04435',
  },
  'exeter-mo': {
    name: 'Exeter',
    postalCode: '65647',
  },
  'exeter-ne': {
    name: 'Exeter',
    postalCode: '68351',
  },
  'exeter-nh': {
    name: 'Exeter',
    postalCode: '03833',
  },
  'exeter-ri': {
    name: 'Exeter',
    postalCode: '02822',
  },
  'exira-ia': {
    name: 'Exira',
    postalCode: '50076',
  },
  'exline-ia': {
    name: 'Exline',
    postalCode: '52555',
  },
  'exmore-va': {
    name: 'Exmore',
    postalCode: '23350',
  },
  'export-pa': {
    name: 'Export',
    postalCode: '15632',
  },
  'exton-pa': {
    name: 'Exton',
    postalCode: '19341',
  },
  'eyota-mn': {
    name: 'Eyota',
    postalCode: '55934',
  },
  'ezel-ky': {
    name: 'Ezel',
    postalCode: '41425',
  },
  'faber-va': {
    name: 'Faber',
    postalCode: '22938',
  },
  'fabius-ny': {
    name: 'Fabius',
    postalCode: '13063',
  },
  'fackler-al': {
    name: 'Fackler',
    postalCode: '35746',
  },
  'factoryville-pa': {
    name: 'Factoryville',
    postalCode: '18419',
  },
  'fair-bluff-nc': {
    name: 'Fair Bluff',
    postalCode: '28439',
  },
  'fair-grove-mo': {
    name: 'Fair Grove',
    postalCode: '65648',
  },
  'fair-haven-mi': {
    name: 'Fair Haven',
    postalCode: '48023',
  },
  'fair-haven-nj': {
    name: 'Fair Haven',
    postalCode: '07704',
  },
  'fair-haven-vt': {
    name: 'Fair Haven',
    postalCode: '05743',
  },
  'fair-lawn-nj': {
    name: 'Fair Lawn',
    postalCode: '07410',
  },
  'fair-oaks-ca': {
    name: 'Fair Oaks',
    postalCode: '95628',
  },
  'fair-oaks-in': {
    name: 'Fair Oaks',
    postalCode: '47943',
  },
  'fair-play-mo': {
    name: 'Fair Play',
    postalCode: '65649',
  },
  'fair-play-sc': {
    name: 'Fair Play',
    postalCode: '29643',
  },
  'fairbank-ia': {
    name: 'Fairbank',
    postalCode: '50629',
  },
  'fairbanks-ak': {
    name: 'Fairbanks',
    postalCode: '99701',
  },
  'fairbanks-in': {
    name: 'Fairbanks',
    postalCode: '47849',
  },
  'fairborn-oh': {
    name: 'Fairborn',
    postalCode: '45324',
  },
  'fairburn-ga': {
    name: 'Fairburn',
    postalCode: '30213',
  },
  'fairburn-sd': {
    name: 'Fairburn',
    postalCode: '57738',
  },
  'fairbury-il': {
    name: 'Fairbury',
    postalCode: '61739',
  },
  'fairbury-ne': {
    name: 'Fairbury',
    postalCode: '68352',
  },
  'fairchance-pa': {
    name: 'Fairchance',
    postalCode: '15436',
  },
  'fairchild-wi': {
    name: 'Fairchild',
    postalCode: '54741',
  },
  'fairchild-air-force-base-wa': {
    name: 'Fairchild Air Force Base',
    postalCode: '99011',
  },
  'fairdale-ky': {
    name: 'Fairdale',
    postalCode: '40118',
  },
  'fairdale-nd': {
    name: 'Fairdale',
    postalCode: '58229',
  },
  'fairdale-wv': {
    name: 'Fairdale',
    postalCode: '25839',
  },
  'fairdealing-mo': {
    name: 'Fairdealing',
    postalCode: '63939',
  },
  'fairfax-ca': {
    name: 'Fairfax',
    postalCode: '94930',
  },
  'fairfax-ia': {
    name: 'Fairfax',
    postalCode: '52228',
  },
  'fairfax-mn': {
    name: 'Fairfax',
    postalCode: '55332',
  },
  'fairfax-mo': {
    name: 'Fairfax',
    postalCode: '64446',
  },
  'fairfax-ok': {
    name: 'Fairfax',
    postalCode: '74637',
  },
  'fairfax-sc': {
    name: 'Fairfax',
    postalCode: '29827',
  },
  'fairfax-sd': {
    name: 'Fairfax',
    postalCode: '57335',
  },
  'fairfax-va': {
    name: 'Fairfax',
    postalCode: '22030',
  },
  'fairfax-vt': {
    name: 'Fairfax',
    postalCode: '05454',
  },
  'fairfax-station-va': {
    name: 'Fairfax Station',
    postalCode: '22039',
  },
  'fairfield-al': {
    name: 'Fairfield',
    postalCode: '35064',
  },
  'fairfield-ca': {
    name: 'Fairfield',
    postalCode: '94533',
  },
  'fairfield-ct': {
    name: 'Fairfield',
    postalCode: '06824',
  },
  'fairfield-ia': {
    name: 'Fairfield',
    postalCode: '52556',
  },
  'fairfield-id': {
    name: 'Fairfield',
    postalCode: '83327',
  },
  'fairfield-il': {
    name: 'Fairfield',
    postalCode: '62837',
  },
  'fairfield-me': {
    name: 'Fairfield',
    postalCode: '04937',
  },
  'fairfield-mt': {
    name: 'Fairfield',
    postalCode: '59436',
  },
  'fairfield-nc': {
    name: 'Fairfield',
    postalCode: '27826',
  },
  'fairfield-nd': {
    name: 'Fairfield',
    postalCode: '58627',
  },
  'fairfield-ne': {
    name: 'Fairfield',
    postalCode: '68938',
  },
  'fairfield-nj': {
    name: 'Fairfield',
    postalCode: '07004',
  },
  'fairfield-oh': {
    name: 'Fairfield',
    postalCode: '45014',
  },
  'fairfield-pa': {
    name: 'Fairfield',
    postalCode: '17320',
  },
  'fairfield-tx': {
    name: 'Fairfield',
    postalCode: '75840',
  },
  'fairfield-va': {
    name: 'Fairfield',
    postalCode: '24435',
  },
  'fairfield-vt': {
    name: 'Fairfield',
    postalCode: '05455',
  },
  'fairfield-wa': {
    name: 'Fairfield',
    postalCode: '99012',
  },
  'fairfield-bay-ar': {
    name: 'Fairfield Bay',
    postalCode: '72088',
  },
  'fairgrove-mi': {
    name: 'Fairgrove',
    postalCode: '48733',
  },
  'fairhaven-ma': {
    name: 'Fairhaven',
    postalCode: '02719',
  },
  'fairhope-al': {
    name: 'Fairhope',
    postalCode: '36532',
  },
  'fairhope-pa': {
    name: 'Fairhope',
    postalCode: '15538',
  },
  'fairland-in': {
    name: 'Fairland',
    postalCode: '46126',
  },
  'fairland-ok': {
    name: 'Fairland',
    postalCode: '74343',
  },
  'fairlee-vt': {
    name: 'Fairlee',
    postalCode: '05045',
  },
  'fairless-hills-pa': {
    name: 'Fairless Hills',
    postalCode: '19030',
  },
  'fairmont-mn': {
    name: 'Fairmont',
    postalCode: '56031',
  },
  'fairmont-nc': {
    name: 'Fairmont',
    postalCode: '28340',
  },
  'fairmont-ne': {
    name: 'Fairmont',
    postalCode: '68354',
  },
  'fairmont-ok': {
    name: 'Fairmont',
    postalCode: '73736',
  },
  'fairmont-wv': {
    name: 'Fairmont',
    postalCode: '26554',
  },
  'fairmount-ga': {
    name: 'Fairmount',
    postalCode: '30139',
  },
  'fairmount-il': {
    name: 'Fairmount',
    postalCode: '61841',
  },
  'fairmount-in': {
    name: 'Fairmount',
    postalCode: '46928',
  },
  'fairmount-nd': {
    name: 'Fairmount',
    postalCode: '58030',
  },
  'fairmount-city-pa': {
    name: 'Fairmount City',
    postalCode: '16224',
  },
  'fairplay-co': {
    name: 'Fairplay',
    postalCode: '80440',
  },
  'fairplay-md': {
    name: 'Fairplay',
    postalCode: '21733',
  },
  'fairport-ny': {
    name: 'Fairport',
    postalCode: '14450',
  },
  'fairview-il': {
    name: 'Fairview',
    postalCode: '61432',
  },
  'fairview-ks': {
    name: 'Fairview',
    postalCode: '66425',
  },
  'fairview-mi': {
    name: 'Fairview',
    postalCode: '48621',
  },
  'fairview-mo': {
    name: 'Fairview',
    postalCode: '64842',
  },
  'fairview-mt': {
    name: 'Fairview',
    postalCode: '59221',
  },
  'fairview-nc': {
    name: 'Fairview',
    postalCode: '28730',
  },
  'fairview-nj': {
    name: 'Fairview',
    postalCode: '07022',
  },
  'fairview-ok': {
    name: 'Fairview',
    postalCode: '73737',
  },
  'fairview-or': {
    name: 'Fairview',
    postalCode: '97024',
  },
  'fairview-pa': {
    name: 'Fairview',
    postalCode: '16415',
  },
  'fairview-sd': {
    name: 'Fairview',
    postalCode: '57027',
  },
  'fairview-tn': {
    name: 'Fairview',
    postalCode: '37062',
  },
  'fairview-ut': {
    name: 'Fairview',
    postalCode: '84629',
  },
  'fairview-wv': {
    name: 'Fairview',
    postalCode: '26570',
  },
  'fairview-heights-il': {
    name: 'Fairview Heights',
    postalCode: '62208',
  },
  'faison-nc': {
    name: 'Faison',
    postalCode: '28341',
  },
  'faith-sd': {
    name: 'Faith',
    postalCode: '57626',
  },
  'fajardo-pr': {
    name: 'Fajardo',
    postalCode: '00738',
  },
  'falcon-mo': {
    name: 'Falcon',
    postalCode: '65470',
  },
  'falconer-ny': {
    name: 'Falconer',
    postalCode: '14733',
  },
  'falfurrias-tx': {
    name: 'Falfurrias',
    postalCode: '78355',
  },
  'falkner-ms': {
    name: 'Falkner',
    postalCode: '38629',
  },
  'falkville-al': {
    name: 'Falkville',
    postalCode: '35622',
  },
  'fall-branch-tn': {
    name: 'Fall Branch',
    postalCode: '37656',
  },
  'fall-city-wa': {
    name: 'Fall City',
    postalCode: '98024',
  },
  'fall-creek-or': {
    name: 'Fall Creek',
    postalCode: '97438',
  },
  'fall-creek-wi': {
    name: 'Fall Creek',
    postalCode: '54742',
  },
  'fall-river-ks': {
    name: 'Fall River',
    postalCode: '67047',
  },
  'fall-river-ma': {
    name: 'Fall River',
    postalCode: '02720',
  },
  'fall-river-wi': {
    name: 'Fall River',
    postalCode: '53932',
  },
  'fall-river-mills-ca': {
    name: 'Fall River Mills',
    postalCode: '96028',
  },
  'fallbrook-ca': {
    name: 'Fallbrook',
    postalCode: '92028',
  },
  'fallentimber-pa': {
    name: 'Fallentimber',
    postalCode: '16639',
  },
  'falling-rock-wv': {
    name: 'Falling Rock',
    postalCode: '25079',
  },
  'falling-waters-wv': {
    name: 'Falling Waters',
    postalCode: '25419',
  },
  'fallon-mt': {
    name: 'Fallon',
    postalCode: '59326',
  },
  'fallon-nv': {
    name: 'Fallon',
    postalCode: '89406',
  },
  'falls-pa': {
    name: 'Falls',
    postalCode: '18615',
  },
  'falls-church-va': {
    name: 'Falls Church',
    postalCode: '22041',
  },
  'falls-city-ne': {
    name: 'Falls City',
    postalCode: '68355',
  },
  'falls-city-or': {
    name: 'Falls City',
    postalCode: '97344',
  },
  'falls-city-tx': {
    name: 'Falls City',
    postalCode: '78113',
  },
  'falls-creek-pa': {
    name: 'Falls Creek',
    postalCode: '15840',
  },
  'falls-mills-va': {
    name: 'Falls Mills',
    postalCode: '24613',
  },
  'falls-of-rough-ky': {
    name: 'Falls Of Rough',
    postalCode: '40119',
  },
  'falls-village-ct': {
    name: 'Falls Village',
    postalCode: '06031',
  },
  'fallsburg-ny': {
    name: 'Fallsburg',
    postalCode: '12733',
  },
  'fallston-md': {
    name: 'Fallston',
    postalCode: '21047',
  },
  'falmouth-in': {
    name: 'Falmouth',
    postalCode: '46127',
  },
  'falmouth-ky': {
    name: 'Falmouth',
    postalCode: '41040',
  },
  'falmouth-ma': {
    name: 'Falmouth',
    postalCode: '02540',
  },
  'falmouth-me': {
    name: 'Falmouth',
    postalCode: '04105',
  },
  'falmouth-mi': {
    name: 'Falmouth',
    postalCode: '49632',
  },
  'falun-ks': {
    name: 'Falun',
    postalCode: '67442',
  },
  'fancy-farm-ky': {
    name: 'Fancy Farm',
    postalCode: '42039',
  },
  'fancy-gap-va': {
    name: 'Fancy Gap',
    postalCode: '24328',
  },
  'fannettsburg-pa': {
    name: 'Fannettsburg',
    postalCode: '17221',
  },
  'fanwood-nj': {
    name: 'Fanwood',
    postalCode: '07023',
  },
  'far-hills-nj': {
    name: 'Far Hills',
    postalCode: '07931',
  },
  'far-rockaway-ny': {
    name: 'Far Rockaway',
    postalCode: '11691',
  },
  'farber-mo': {
    name: 'Farber',
    postalCode: '63345',
  },
  'fargo-ga': {
    name: 'Fargo',
    postalCode: '31631',
  },
  'fargo-nd': {
    name: 'Fargo',
    postalCode: '58102',
  },
  'fargo-ok': {
    name: 'Fargo',
    postalCode: '73840',
  },
  'faribault-mn': {
    name: 'Faribault',
    postalCode: '55021',
  },
  'farina-il': {
    name: 'Farina',
    postalCode: '62838',
  },
  'farley-ia': {
    name: 'Farley',
    postalCode: '52046',
  },
  'farlington-ks': {
    name: 'Farlington',
    postalCode: '66734',
  },
  'farmdale-oh': {
    name: 'Farmdale',
    postalCode: '44417',
  },
  'farmer-city-il': {
    name: 'Farmer City',
    postalCode: '61842',
  },
  'farmersburg-ia': {
    name: 'Farmersburg',
    postalCode: '52047',
  },
  'farmersburg-in': {
    name: 'Farmersburg',
    postalCode: '47850',
  },
  'farmersville-ca': {
    name: 'Farmersville',
    postalCode: '93223',
  },
  'farmersville-il': {
    name: 'Farmersville',
    postalCode: '62533',
  },
  'farmersville-oh': {
    name: 'Farmersville',
    postalCode: '45325',
  },
  'farmersville-tx': {
    name: 'Farmersville',
    postalCode: '75442',
  },
  'farmersville-station-ny': {
    name: 'Farmersville Station',
    postalCode: '14060',
  },
  'farmerville-la': {
    name: 'Farmerville',
    postalCode: '71241',
  },
  'farmingdale-me': {
    name: 'Farmingdale',
    postalCode: '04344',
  },
  'farmingdale-nj': {
    name: 'Farmingdale',
    postalCode: '07727',
  },
  'farmingdale-ny': {
    name: 'Farmingdale',
    postalCode: '11735',
  },
  'farmington-ar': {
    name: 'Farmington',
    postalCode: '72730',
  },
  'farmington-ca': {
    name: 'Farmington',
    postalCode: '95230',
  },
  'farmington-ct': {
    name: 'Farmington',
    postalCode: '06032',
  },
  'farmington-ia': {
    name: 'Farmington',
    postalCode: '52626',
  },
  'farmington-il': {
    name: 'Farmington',
    postalCode: '61531',
  },
  'farmington-ky': {
    name: 'Farmington',
    postalCode: '42040',
  },
  'farmington-me': {
    name: 'Farmington',
    postalCode: '04938',
  },
  'farmington-mi': {
    name: 'Farmington',
    postalCode: '48331',
  },
  'farmington-mn': {
    name: 'Farmington',
    postalCode: '55024',
  },
  'farmington-mo': {
    name: 'Farmington',
    postalCode: '63640',
  },
  'farmington-nh': {
    name: 'Farmington',
    postalCode: '03835',
  },
  'farmington-nm': {
    name: 'Farmington',
    postalCode: '87401',
  },
  'farmington-ny': {
    name: 'Farmington',
    postalCode: '14425',
  },
  'farmington-pa': {
    name: 'Farmington',
    postalCode: '15437',
  },
  'farmington-ut': {
    name: 'Farmington',
    postalCode: '84025',
  },
  'farmington-wa': {
    name: 'Farmington',
    postalCode: '99128',
  },
  'farmington-wv': {
    name: 'Farmington',
    postalCode: '26571',
  },
  'farmington-falls-me': {
    name: 'Farmington Falls',
    postalCode: '04940',
  },
  'farmingville-ny': {
    name: 'Farmingville',
    postalCode: '11738',
  },
  'farmland-in': {
    name: 'Farmland',
    postalCode: '47340',
  },
  'farmville-nc': {
    name: 'Farmville',
    postalCode: '27828',
  },
  'farmville-va': {
    name: 'Farmville',
    postalCode: '23901',
  },
  'farnam-ne': {
    name: 'Farnam',
    postalCode: '69029',
  },
  'farner-tn': {
    name: 'Farner',
    postalCode: '37333',
  },
  'farnham-va': {
    name: 'Farnham',
    postalCode: '22460',
  },
  'farnhamville-ia': {
    name: 'Farnhamville',
    postalCode: '50538',
  },
  'farragut-ia': {
    name: 'Farragut',
    postalCode: '51639',
  },
  'farragut-tn': {
    name: 'Farragut',
    postalCode: '37934',
  },
  'farrell-pa': {
    name: 'Farrell',
    postalCode: '16121',
  },
  'farson-wy': {
    name: 'Farson',
    postalCode: '82932',
  },
  'farwell-mi': {
    name: 'Farwell',
    postalCode: '48622',
  },
  'farwell-mn': {
    name: 'Farwell',
    postalCode: '56327',
  },
  'farwell-ne': {
    name: 'Farwell',
    postalCode: '68838',
  },
  'farwell-tx': {
    name: 'Farwell',
    postalCode: '79325',
  },
  'faucett-mo': {
    name: 'Faucett',
    postalCode: '64448',
  },
  'faulkner-md': {
    name: 'Faulkner',
    postalCode: '20632',
  },
  'faulkton-sd': {
    name: 'Faulkton',
    postalCode: '57438',
  },
  'faunsdale-al': {
    name: 'Faunsdale',
    postalCode: '36738',
  },
  'fawn-grove-pa': {
    name: 'Fawn Grove',
    postalCode: '17321',
  },
  'faxon-ok': {
    name: 'Faxon',
    postalCode: '73540',
  },
  'fay-ok': {
    name: 'Fay',
    postalCode: '73646',
  },
  'fayette-al': {
    name: 'Fayette',
    postalCode: '35555',
  },
  'fayette-ia': {
    name: 'Fayette',
    postalCode: '52142',
  },
  'fayette-mo': {
    name: 'Fayette',
    postalCode: '65248',
  },
  'fayette-ms': {
    name: 'Fayette',
    postalCode: '39069',
  },
  'fayette-oh': {
    name: 'Fayette',
    postalCode: '43521',
  },
  'fayette-ut': {
    name: 'Fayette',
    postalCode: '84630',
  },
  'fayette-city-pa': {
    name: 'Fayette City',
    postalCode: '15438',
  },
  'fayetteville-ar': {
    name: 'Fayetteville',
    postalCode: '72701',
  },
  'fayetteville-ga': {
    name: 'Fayetteville',
    postalCode: '30214',
  },
  'fayetteville-nc': {
    name: 'Fayetteville',
    postalCode: '28301',
  },
  'fayetteville-ny': {
    name: 'Fayetteville',
    postalCode: '13066',
  },
  'fayetteville-oh': {
    name: 'Fayetteville',
    postalCode: '45118',
  },
  'fayetteville-pa': {
    name: 'Fayetteville',
    postalCode: '17222',
  },
  'fayetteville-tn': {
    name: 'Fayetteville',
    postalCode: '37334',
  },
  'fayetteville-tx': {
    name: 'Fayetteville',
    postalCode: '78940',
  },
  'fayetteville-wv': {
    name: 'Fayetteville',
    postalCode: '25840',
  },
  'fayville-ma': {
    name: 'Fayville',
    postalCode: '01745',
  },
  'faywood-nm': {
    name: 'Faywood',
    postalCode: '88034',
  },
  'fe-warren-afb-wy': {
    name: 'Fe Warren AFB',
    postalCode: '82005',
  },
  'feasterville-trevose-pa': {
    name: 'Feasterville Trevose',
    postalCode: '19053',
  },
  'federal-dam-mn': {
    name: 'Federal Dam',
    postalCode: '56641',
  },
  'federal-way-wa': {
    name: 'Federal Way',
    postalCode: '98003',
  },
  'federalsburg-md': {
    name: 'Federalsburg',
    postalCode: '21632',
  },
  'fedora-sd': {
    name: 'Fedora',
    postalCode: '57337',
  },
  'fedscreek-ky': {
    name: 'Fedscreek',
    postalCode: '41524',
  },
  'feeding-hills-ma': {
    name: 'Feeding Hills',
    postalCode: '01030',
  },
  'felch-mi': {
    name: 'Felch',
    postalCode: '49831',
  },
  'felicity-oh': {
    name: 'Felicity',
    postalCode: '45120',
  },
  'fellows-ca': {
    name: 'Fellows',
    postalCode: '93224',
  },
  'fellsmere-fl': {
    name: 'Fellsmere',
    postalCode: '32948',
  },
  'felt-id': {
    name: 'Felt',
    postalCode: '83424',
  },
  'felt-ok': {
    name: 'Felt',
    postalCode: '73937',
  },
  'felton-ca': {
    name: 'Felton',
    postalCode: '95018',
  },
  'felton-de': {
    name: 'Felton',
    postalCode: '19943',
  },
  'felton-mn': {
    name: 'Felton',
    postalCode: '56536',
  },
  'felton-pa': {
    name: 'Felton',
    postalCode: '17322',
  },
  'felts-mills-ny': {
    name: 'Felts Mills',
    postalCode: '13638',
  },
  'fence-wi': {
    name: 'Fence',
    postalCode: '54120',
  },
  'fence-lake-nm': {
    name: 'Fence Lake',
    postalCode: '87315',
  },
  'fenelton-pa': {
    name: 'Fenelton',
    postalCode: '16034',
  },
  'fennimore-wi': {
    name: 'Fennimore',
    postalCode: '53809',
  },
  'fennville-mi': {
    name: 'Fennville',
    postalCode: '49408',
  },
  'fenton-ia': {
    name: 'Fenton',
    postalCode: '50539',
  },
  'fenton-il': {
    name: 'Fenton',
    postalCode: '61251',
  },
  'fenton-mi': {
    name: 'Fenton',
    postalCode: '48430',
  },
  'fenton-mo': {
    name: 'Fenton',
    postalCode: '63026',
  },
  'fenwick-mi': {
    name: 'Fenwick',
    postalCode: '48834',
  },
  'fenwick-wv': {
    name: 'Fenwick',
    postalCode: '26202',
  },
  'fenwick-island-de': {
    name: 'Fenwick Island',
    postalCode: '19944',
  },
  'ferdinand-id': {
    name: 'Ferdinand',
    postalCode: '83526',
  },
  'ferdinand-in': {
    name: 'Ferdinand',
    postalCode: '47532',
  },
  'fergus-falls-mn': {
    name: 'Fergus Falls',
    postalCode: '56537',
  },
  'ferguson-ky': {
    name: 'Ferguson',
    postalCode: '42533',
  },
  'ferguson-nc': {
    name: 'Ferguson',
    postalCode: '28624',
  },
  'fernandina-beach-fl': {
    name: 'Fernandina Beach',
    postalCode: '32034',
  },
  'ferndale-ca': {
    name: 'Ferndale',
    postalCode: '95536',
  },
  'ferndale-mi': {
    name: 'Ferndale',
    postalCode: '48220',
  },
  'ferndale-ny': {
    name: 'Ferndale',
    postalCode: '12734',
  },
  'ferndale-wa': {
    name: 'Ferndale',
    postalCode: '98248',
  },
  'fernley-nv': {
    name: 'Fernley',
    postalCode: '89408',
  },
  'fernwood-id': {
    name: 'Fernwood',
    postalCode: '83830',
  },
  'ferriday-la': {
    name: 'Ferriday',
    postalCode: '71334',
  },
  'ferris-tx': {
    name: 'Ferris',
    postalCode: '75125',
  },
  'ferrisburgh-vt': {
    name: 'Ferrisburgh',
    postalCode: '05456',
  },
  'ferrum-va': {
    name: 'Ferrum',
    postalCode: '24088',
  },
  'ferryville-wi': {
    name: 'Ferryville',
    postalCode: '54628',
  },
  'fertile-ia': {
    name: 'Fertile',
    postalCode: '50434',
  },
  'fertile-mn': {
    name: 'Fertile',
    postalCode: '56540',
  },
  'fessenden-nd': {
    name: 'Fessenden',
    postalCode: '58438',
  },
  'festus-mo': {
    name: 'Festus',
    postalCode: '63028',
  },
  'feura-bush-ny': {
    name: 'Feura Bush',
    postalCode: '12067',
  },
  'fiddletown-ca': {
    name: 'Fiddletown',
    postalCode: '95629',
  },
  'fieldale-va': {
    name: 'Fieldale',
    postalCode: '24089',
  },
  'fielding-ut': {
    name: 'Fielding',
    postalCode: '84311',
  },
  'fieldon-il': {
    name: 'Fieldon',
    postalCode: '62031',
  },
  'fields-or': {
    name: 'Fields',
    postalCode: '97710',
  },
  'fieldton-tx': {
    name: 'Fieldton',
    postalCode: '79326',
  },
  'fife-wa': {
    name: 'Fife',
    postalCode: '98424',
  },
  'fife-lake-mi': {
    name: 'Fife Lake',
    postalCode: '49633',
  },
  'fifield-wi': {
    name: 'Fifield',
    postalCode: '54524',
  },
  'fifty-lakes-mn': {
    name: 'Fifty Lakes',
    postalCode: '56448',
  },
  'fifty-six-ar': {
    name: 'Fifty Six',
    postalCode: '72533',
  },
  'filer-id': {
    name: 'Filer',
    postalCode: '83328',
  },
  'filion-mi': {
    name: 'Filion',
    postalCode: '48432',
  },
  'filley-ne': {
    name: 'Filley',
    postalCode: '68357',
  },
  'fillmore-ca': {
    name: 'Fillmore',
    postalCode: '93015',
  },
  'fillmore-il': {
    name: 'Fillmore',
    postalCode: '62032',
  },
  'fillmore-in': {
    name: 'Fillmore',
    postalCode: '46128',
  },
  'fillmore-mo': {
    name: 'Fillmore',
    postalCode: '64449',
  },
  'fillmore-ny': {
    name: 'Fillmore',
    postalCode: '14735',
  },
  'fillmore-ut': {
    name: 'Fillmore',
    postalCode: '84631',
  },
  'fincastle-va': {
    name: 'Fincastle',
    postalCode: '24090',
  },
  'finchville-ky': {
    name: 'Finchville',
    postalCode: '40022',
  },
  'findlay-il': {
    name: 'Findlay',
    postalCode: '62534',
  },
  'findlay-oh': {
    name: 'Findlay',
    postalCode: '45840',
  },
  'findley-lake-ny': {
    name: 'Findley Lake',
    postalCode: '14736',
  },
  'fine-ny': {
    name: 'Fine',
    postalCode: '13639',
  },
  'fingal-nd': {
    name: 'Fingal',
    postalCode: '58031',
  },
  'finger-tn': {
    name: 'Finger',
    postalCode: '38334',
  },
  'finksburg-md': {
    name: 'Finksburg',
    postalCode: '21048',
  },
  'finland-mn': {
    name: 'Finland',
    postalCode: '55603',
  },
  'finlayson-mn': {
    name: 'Finlayson',
    postalCode: '55735',
  },
  'finley-nd': {
    name: 'Finley',
    postalCode: '58230',
  },
  'finley-ok': {
    name: 'Finley',
    postalCode: '74543',
  },
  'finley-tn': {
    name: 'Finley',
    postalCode: '38030',
  },
  'finleyville-pa': {
    name: 'Finleyville',
    postalCode: '15332',
  },
  'firebaugh-ca': {
    name: 'Firebaugh',
    postalCode: '93622',
  },
  'firth-id': {
    name: 'Firth',
    postalCode: '83236',
  },
  'firth-ne': {
    name: 'Firth',
    postalCode: '68358',
  },
  'fischer-tx': {
    name: 'Fischer',
    postalCode: '78623',
  },
  'fish-camp-ca': {
    name: 'Fish Camp',
    postalCode: '93623',
  },
  'fish-creek-wi': {
    name: 'Fish Creek',
    postalCode: '54212',
  },
  'fish-haven-id': {
    name: 'Fish Haven',
    postalCode: '83287',
  },
  'fisher-ar': {
    name: 'Fisher',
    postalCode: '72429',
  },
  'fisher-il': {
    name: 'Fisher',
    postalCode: '61843',
  },
  'fisher-mn': {
    name: 'Fisher',
    postalCode: '56723',
  },
  'fisher-wv': {
    name: 'Fisher',
    postalCode: '26818',
  },
  'fishers-in': {
    name: 'Fishers',
    postalCode: '46037',
  },
  'fishersville-va': {
    name: 'Fishersville',
    postalCode: '22939',
  },
  'fishertown-pa': {
    name: 'Fishertown',
    postalCode: '15539',
  },
  'fisherville-ky': {
    name: 'Fisherville',
    postalCode: '40023',
  },
  'fishing-creek-md': {
    name: 'Fishing Creek',
    postalCode: '21634',
  },
  'fishkill-ny': {
    name: 'Fishkill',
    postalCode: '12524',
  },
  'fishtail-mt': {
    name: 'Fishtail',
    postalCode: '59028',
  },
  'fisk-mo': {
    name: 'Fisk',
    postalCode: '63940',
  },
  'fiskdale-ma': {
    name: 'Fiskdale',
    postalCode: '01518',
  },
  'fitchburg-ma': {
    name: 'Fitchburg',
    postalCode: '01420',
  },
  'fithian-il': {
    name: 'Fithian',
    postalCode: '61844',
  },
  'fitzgerald-ga': {
    name: 'Fitzgerald',
    postalCode: '31750',
  },
  'fitzhugh-ok': {
    name: 'Fitzhugh',
    postalCode: '74843',
  },
  'fitzpatrick-al': {
    name: 'Fitzpatrick',
    postalCode: '36029',
  },
  'fitzwilliam-nh': {
    name: 'Fitzwilliam',
    postalCode: '03447',
  },
  'five-points-al': {
    name: 'Five Points',
    postalCode: '36855',
  },
  'five-points-tn': {
    name: 'Five Points',
    postalCode: '38457',
  },
  'flag-pond-tn': {
    name: 'Flag Pond',
    postalCode: '37657',
  },
  'flagler-co': {
    name: 'Flagler',
    postalCode: '80815',
  },
  'flagler-beach-fl': {
    name: 'Flagler Beach',
    postalCode: '32136',
  },
  'flagstaff-az': {
    name: 'Flagstaff',
    postalCode: '86001',
  },
  'flanagan-il': {
    name: 'Flanagan',
    postalCode: '61740',
  },
  'flanders-nj': {
    name: 'Flanders',
    postalCode: '07836',
  },
  'flandreau-sd': {
    name: 'Flandreau',
    postalCode: '57028',
  },
  'flasher-nd': {
    name: 'Flasher',
    postalCode: '58535',
  },
  'flat-lick-ky': {
    name: 'Flat Lick',
    postalCode: '40935',
  },
  'flat-rock-al': {
    name: 'Flat Rock',
    postalCode: '35966',
  },
  'flat-rock-il': {
    name: 'Flat Rock',
    postalCode: '62427',
  },
  'flat-rock-in': {
    name: 'Flat Rock',
    postalCode: '47234',
  },
  'flat-rock-mi': {
    name: 'Flat Rock',
    postalCode: '48134',
  },
  'flat-rock-nc': {
    name: 'Flat Rock',
    postalCode: '28731',
  },
  'flat-top-wv': {
    name: 'Flat Top',
    postalCode: '25841',
  },
  'flatgap-ky': {
    name: 'Flatgap',
    postalCode: '41219',
  },
  'flatonia-tx': {
    name: 'Flatonia',
    postalCode: '78941',
  },
  'flatwoods-ky': {
    name: 'Flatwoods',
    postalCode: '41139',
  },
  'flatwoods-la': {
    name: 'Flatwoods',
    postalCode: '71427',
  },
  'flatwoods-wv': {
    name: 'Flatwoods',
    postalCode: '26621',
  },
  'flaxton-nd': {
    name: 'Flaxton',
    postalCode: '58737',
  },
  'flaxville-mt': {
    name: 'Flaxville',
    postalCode: '59222',
  },
  'fleetwood-nc': {
    name: 'Fleetwood',
    postalCode: '28626',
  },
  'fleetwood-pa': {
    name: 'Fleetwood',
    postalCode: '19522',
  },
  'fleischmanns-ny': {
    name: 'Fleischmanns',
    postalCode: '12430',
  },
  'fleming-co': {
    name: 'Fleming',
    postalCode: '80728',
  },
  'fleming-ga': {
    name: 'Fleming',
    postalCode: '31309',
  },
  'fleming-oh': {
    name: 'Fleming',
    postalCode: '45729',
  },
  'fleming-island-fl': {
    name: 'Fleming Island',
    postalCode: '32003',
  },
  'flemingsburg-ky': {
    name: 'Flemingsburg',
    postalCode: '41041',
  },
  'flemington-mo': {
    name: 'Flemington',
    postalCode: '65650',
  },
  'flemington-nj': {
    name: 'Flemington',
    postalCode: '08822',
  },
  'flemington-wv': {
    name: 'Flemington',
    postalCode: '26347',
  },
  'fletcher-mo': {
    name: 'Fletcher',
    postalCode: '63030',
  },
  'fletcher-nc': {
    name: 'Fletcher',
    postalCode: '28732',
  },
  'fletcher-oh': {
    name: 'Fletcher',
    postalCode: '45326',
  },
  'fletcher-ok': {
    name: 'Fletcher',
    postalCode: '73541',
  },
  'flint-mi': {
    name: 'Flint',
    postalCode: '48502',
  },
  'flint-tx': {
    name: 'Flint',
    postalCode: '75762',
  },
  'flint-hill-va': {
    name: 'Flint Hill',
    postalCode: '22627',
  },
  'flinton-pa': {
    name: 'Flinton',
    postalCode: '16640',
  },
  'flintstone-ga': {
    name: 'Flintstone',
    postalCode: '30725',
  },
  'flintstone-md': {
    name: 'Flintstone',
    postalCode: '21530',
  },
  'flintville-tn': {
    name: 'Flintville',
    postalCode: '37335',
  },
  'flippin-ar': {
    name: 'Flippin',
    postalCode: '72634',
  },
  'flomaton-al': {
    name: 'Flomaton',
    postalCode: '36441',
  },
  'flomot-tx': {
    name: 'Flomot',
    postalCode: '79234',
  },
  'floodwood-mn': {
    name: 'Floodwood',
    postalCode: '55736',
  },
  'flora-il': {
    name: 'Flora',
    postalCode: '62839',
  },
  'flora-in': {
    name: 'Flora',
    postalCode: '46929',
  },
  'flora-ms': {
    name: 'Flora',
    postalCode: '39071',
  },
  'flora-vista-nm': {
    name: 'Flora Vista',
    postalCode: '87415',
  },
  'florahome-fl': {
    name: 'Florahome',
    postalCode: '32140',
  },
  'floral-ar': {
    name: 'Floral',
    postalCode: '72534',
  },
  'floral-city-fl': {
    name: 'Floral City',
    postalCode: '34436',
  },
  'floral-park-ny': {
    name: 'Floral Park',
    postalCode: '11001',
  },
  'florala-al': {
    name: 'Florala',
    postalCode: '36442',
  },
  'florence-al': {
    name: 'Florence',
    postalCode: '35630',
  },
  'florence-az': {
    name: 'Florence',
    postalCode: '85132',
  },
  'florence-co': {
    name: 'Florence',
    postalCode: '81226',
  },
  'florence-in': {
    name: 'Florence',
    postalCode: '47020',
  },
  'florence-ks': {
    name: 'Florence',
    postalCode: '66851',
  },
  'florence-ky': {
    name: 'Florence',
    postalCode: '41042',
  },
  'florence-ma': {
    name: 'Florence',
    postalCode: '01062',
  },
  'florence-mo': {
    name: 'Florence',
    postalCode: '65329',
  },
  'florence-ms': {
    name: 'Florence',
    postalCode: '39073',
  },
  'florence-mt': {
    name: 'Florence',
    postalCode: '59833',
  },
  'florence-nj': {
    name: 'Florence',
    postalCode: '08518',
  },
  'florence-or': {
    name: 'Florence',
    postalCode: '97439',
  },
  'florence-sc': {
    name: 'Florence',
    postalCode: '29501',
  },
  'florence-sd': {
    name: 'Florence',
    postalCode: '57235',
  },
  'florence-tx': {
    name: 'Florence',
    postalCode: '76527',
  },
  'florence-vt': {
    name: 'Florence',
    postalCode: '05744',
  },
  'florence-wi': {
    name: 'Florence',
    postalCode: '54121',
  },
  'floresville-tx': {
    name: 'Floresville',
    postalCode: '78114',
  },
  'florham-park-nj': {
    name: 'Florham Park',
    postalCode: '07932',
  },
  'florida-ny': {
    name: 'Florida',
    postalCode: '10921',
  },
  'florida-pr': {
    name: 'Florida',
    postalCode: '00650',
  },
  'florien-la': {
    name: 'Florien',
    postalCode: '71429',
  },
  'floris-ia': {
    name: 'Floris',
    postalCode: '52560',
  },
  'florissant-co': {
    name: 'Florissant',
    postalCode: '80816',
  },
  'florissant-mo': {
    name: 'Florissant',
    postalCode: '63031',
  },
  'flossmoor-il': {
    name: 'Flossmoor',
    postalCode: '60422',
  },
  'flournoy-ca': {
    name: 'Flournoy',
    postalCode: '96029',
  },
  'flourtown-pa': {
    name: 'Flourtown',
    postalCode: '19031',
  },
  'flovilla-ga': {
    name: 'Flovilla',
    postalCode: '30216',
  },
  'flower-mound-tx': {
    name: 'Flower Mound',
    postalCode: '75022',
  },
  'floweree-mt': {
    name: 'Floweree',
    postalCode: '59440',
  },
  'flowery-branch-ga': {
    name: 'Flowery Branch',
    postalCode: '30542',
  },
  'flowood-ms': {
    name: 'Flowood',
    postalCode: '39232',
  },
  'floyd-ia': {
    name: 'Floyd',
    postalCode: '50435',
  },
  'floyd-nm': {
    name: 'Floyd',
    postalCode: '88118',
  },
  'floyd-va': {
    name: 'Floyd',
    postalCode: '24091',
  },
  'floydada-tx': {
    name: 'Floydada',
    postalCode: '79235',
  },
  'floyds-knobs-in': {
    name: 'Floyds Knobs',
    postalCode: '47119',
  },
  'fluker-la': {
    name: 'Fluker',
    postalCode: '70436',
  },
  'flushing-mi': {
    name: 'Flushing',
    postalCode: '48433',
  },
  'flushing-ny': {
    name: 'Flushing',
    postalCode: '11351',
  },
  'flushing-oh': {
    name: 'Flushing',
    postalCode: '43977',
  },
  'fluvanna-tx': {
    name: 'Fluvanna',
    postalCode: '79517',
  },
  'fly-creek-ny': {
    name: 'Fly Creek',
    postalCode: '13337',
  },
  'fogelsville-pa': {
    name: 'Fogelsville',
    postalCode: '18051',
  },
  'folcroft-pa': {
    name: 'Folcroft',
    postalCode: '19032',
  },
  'foley-al': {
    name: 'Foley',
    postalCode: '36535',
  },
  'foley-mn': {
    name: 'Foley',
    postalCode: '56329',
  },
  'foley-mo': {
    name: 'Foley',
    postalCode: '63347',
  },
  'folkston-ga': {
    name: 'Folkston',
    postalCode: '31537',
  },
  'follansbee-wv': {
    name: 'Follansbee',
    postalCode: '26037',
  },
  'follett-tx': {
    name: 'Follett',
    postalCode: '79034',
  },
  'folsom-ca': {
    name: 'Folsom',
    postalCode: '95630',
  },
  'folsom-la': {
    name: 'Folsom',
    postalCode: '70437',
  },
  'folsom-nm': {
    name: 'Folsom',
    postalCode: '88419',
  },
  'folsom-pa': {
    name: 'Folsom',
    postalCode: '19033',
  },
  'folsom-wv': {
    name: 'Folsom',
    postalCode: '26348',
  },
  'fombell-pa': {
    name: 'Fombell',
    postalCode: '16123',
  },
  'fond-du-lac-wi': {
    name: 'Fond Du Lac',
    postalCode: '54935',
  },
  'fonda-ia': {
    name: 'Fonda',
    postalCode: '50540',
  },
  'fonda-ny': {
    name: 'Fonda',
    postalCode: '12068',
  },
  'fontana-ca': {
    name: 'Fontana',
    postalCode: '92335',
  },
  'fontana-ks': {
    name: 'Fontana',
    postalCode: '66026',
  },
  'fontana-wi': {
    name: 'Fontana',
    postalCode: '53125',
  },
  'fontana-dam-nc': {
    name: 'Fontana Dam',
    postalCode: '28733',
  },
  'fontanelle-ia': {
    name: 'Fontanelle',
    postalCode: '50846',
  },
  'foosland-il': {
    name: 'Foosland',
    postalCode: '61845',
  },
  'foothill-ranch-ca': {
    name: 'Foothill Ranch',
    postalCode: '92610',
  },
  'forbes-mn': {
    name: 'Forbes',
    postalCode: '55738',
  },
  'forbes-nd': {
    name: 'Forbes',
    postalCode: '58439',
  },
  'forbestown-ca': {
    name: 'Forbestown',
    postalCode: '95941',
  },
  'ford-ks': {
    name: 'Ford',
    postalCode: '67842',
  },
  'ford-va': {
    name: 'Ford',
    postalCode: '23850',
  },
  'ford-wa': {
    name: 'Ford',
    postalCode: '99013',
  },
  'ford-city-pa': {
    name: 'Ford City',
    postalCode: '16226',
  },
  'ford-cliff-pa': {
    name: 'Ford Cliff',
    postalCode: '16228',
  },
  'fordland-mo': {
    name: 'Fordland',
    postalCode: '65652',
  },
  'fordoche-la': {
    name: 'Fordoche',
    postalCode: '70732',
  },
  'fords-nj': {
    name: 'Fords',
    postalCode: '08863',
  },
  'fordsville-ky': {
    name: 'Fordsville',
    postalCode: '42343',
  },
  'fordville-nd': {
    name: 'Fordville',
    postalCode: '58231',
  },
  'fordyce-ar': {
    name: 'Fordyce',
    postalCode: '71742',
  },
  'fordyce-ne': {
    name: 'Fordyce',
    postalCode: '68736',
  },
  'foreman-ar': {
    name: 'Foreman',
    postalCode: '71836',
  },
  'forest-in': {
    name: 'Forest',
    postalCode: '46039',
  },
  'forest-ms': {
    name: 'Forest',
    postalCode: '39074',
  },
  'forest-oh': {
    name: 'Forest',
    postalCode: '45843',
  },
  'forest-va': {
    name: 'Forest',
    postalCode: '24551',
  },
  'forest-city-ia': {
    name: 'Forest City',
    postalCode: '50436',
  },
  'forest-city-il': {
    name: 'Forest City',
    postalCode: '61532',
  },
  'forest-city-mo': {
    name: 'Forest City',
    postalCode: '64451',
  },
  'forest-city-nc': {
    name: 'Forest City',
    postalCode: '28043',
  },
  'forest-city-pa': {
    name: 'Forest City',
    postalCode: '18421',
  },
  'forest-falls-ca': {
    name: 'Forest Falls',
    postalCode: '92339',
  },
  'forest-grove-mt': {
    name: 'Forest Grove',
    postalCode: '59441',
  },
  'forest-grove-or': {
    name: 'Forest Grove',
    postalCode: '97116',
  },
  'forest-hill-la': {
    name: 'Forest Hill',
    postalCode: '71430',
  },
  'forest-hill-md': {
    name: 'Forest Hill',
    postalCode: '21050',
  },
  'forest-hill-wv': {
    name: 'Forest Hill',
    postalCode: '24935',
  },
  'forest-hills-ky': {
    name: 'Forest Hills',
    postalCode: '41527',
  },
  'forest-hills-ny': {
    name: 'Forest Hills',
    postalCode: '11375',
  },
  'forest-home-al': {
    name: 'Forest Home',
    postalCode: '36030',
  },
  'forest-junction-wi': {
    name: 'Forest Junction',
    postalCode: '54123',
  },
  'forest-lake-mn': {
    name: 'Forest Lake',
    postalCode: '55025',
  },
  'forest-park-ga': {
    name: 'Forest Park',
    postalCode: '30297',
  },
  'forest-park-il': {
    name: 'Forest Park',
    postalCode: '60130',
  },
  'forest-ranch-ca': {
    name: 'Forest Ranch',
    postalCode: '95942',
  },
  'forest-river-nd': {
    name: 'Forest River',
    postalCode: '58233',
  },
  'forestburg-tx': {
    name: 'Forestburg',
    postalCode: '76239',
  },
  'forestburgh-ny': {
    name: 'Forestburgh',
    postalCode: '12777',
  },
  'forestdale-ma': {
    name: 'Forestdale',
    postalCode: '02644',
  },
  'foresthill-ca': {
    name: 'Foresthill',
    postalCode: '95631',
  },
  'foreston-mn': {
    name: 'Foreston',
    postalCode: '56330',
  },
  'forestport-ny': {
    name: 'Forestport',
    postalCode: '13338',
  },
  'forestville-ca': {
    name: 'Forestville',
    postalCode: '95436',
  },
  'forestville-ny': {
    name: 'Forestville',
    postalCode: '14062',
  },
  'forestville-wi': {
    name: 'Forestville',
    postalCode: '54213',
  },
  'forgan-ok': {
    name: 'Forgan',
    postalCode: '73938',
  },
  'foristell-mo': {
    name: 'Foristell',
    postalCode: '63348',
  },
  'fork-md': {
    name: 'Fork',
    postalCode: '21051',
  },
  'fork-sc': {
    name: 'Fork',
    postalCode: '29543',
  },
  'fork-union-va': {
    name: 'Fork Union',
    postalCode: '23055',
  },
  'forked-river-nj': {
    name: 'Forked River',
    postalCode: '08731',
  },
  'forkland-al': {
    name: 'Forkland',
    postalCode: '36740',
  },
  'forks-wa': {
    name: 'Forks',
    postalCode: '98331',
  },
  'forks-of-salmon-ca': {
    name: 'Forks Of Salmon',
    postalCode: '96031',
  },
  'forksville-pa': {
    name: 'Forksville',
    postalCode: '18616',
  },
  'forman-nd': {
    name: 'Forman',
    postalCode: '58032',
  },
  'formoso-ks': {
    name: 'Formoso',
    postalCode: '66942',
  },
  'forney-tx': {
    name: 'Forney',
    postalCode: '75126',
  },
  'forrest-il': {
    name: 'Forrest',
    postalCode: '61741',
  },
  'forrest-city-ar': {
    name: 'Forrest City',
    postalCode: '72335',
  },
  'forreston-il': {
    name: 'Forreston',
    postalCode: '61030',
  },
  'forreston-tx': {
    name: 'Forreston',
    postalCode: '76041',
  },
  'forsyth-ga': {
    name: 'Forsyth',
    postalCode: '31029',
  },
  'forsyth-il': {
    name: 'Forsyth',
    postalCode: '62535',
  },
  'forsyth-mo': {
    name: 'Forsyth',
    postalCode: '65653',
  },
  'forsyth-mt': {
    name: 'Forsyth',
    postalCode: '59327',
  },
  'fort-ann-ny': {
    name: 'Fort Ann',
    postalCode: '12827',
  },
  'fort-ashby-wv': {
    name: 'Fort Ashby',
    postalCode: '26719',
  },
  'fort-atkinson-ia': {
    name: 'Fort Atkinson',
    postalCode: '52144',
  },
  'fort-atkinson-wi': {
    name: 'Fort Atkinson',
    postalCode: '53538',
  },
  'fort-belvoir-va': {
    name: 'Fort Belvoir',
    postalCode: '22060',
  },
  'fort-benning-ga': {
    name: 'Fort Benning',
    postalCode: '31905',
  },
  'fort-benton-mt': {
    name: 'Fort Benton',
    postalCode: '59442',
  },
  'fort-bidwell-ca': {
    name: 'Fort Bidwell',
    postalCode: '96112',
  },
  'fort-blackmore-va': {
    name: 'Fort Blackmore',
    postalCode: '24250',
  },
  'fort-bliss-tx': {
    name: 'Fort Bliss',
    postalCode: '79916',
  },
  'fort-bragg-ca': {
    name: 'Fort Bragg',
    postalCode: '95437',
  },
  'fort-bragg-nc': {
    name: 'Fort Bragg',
    postalCode: '28307',
  },
  'fort-branch-in': {
    name: 'Fort Branch',
    postalCode: '47648',
  },
  'fort-bridger-wy': {
    name: 'Fort Bridger',
    postalCode: '82933',
  },
  'fort-buchanan-pr': {
    name: 'Fort Buchanan',
    postalCode: '00934',
  },
  'fort-calhoun-ne': {
    name: 'Fort Calhoun',
    postalCode: '68023',
  },
  'fort-campbell-ky': {
    name: 'Fort Campbell',
    postalCode: '42223',
  },
  'fort-cobb-ok': {
    name: 'Fort Cobb',
    postalCode: '73038',
  },
  'fort-collins-co': {
    name: 'Fort Collins',
    postalCode: '80521',
  },
  'fort-covington-ny': {
    name: 'Fort Covington',
    postalCode: '12937',
  },
  'fort-davis-tx': {
    name: 'Fort Davis',
    postalCode: '79734',
  },
  'fort-defiance-va': {
    name: 'Fort Defiance',
    postalCode: '24437',
  },
  'fort-deposit-al': {
    name: 'Fort Deposit',
    postalCode: '36032',
  },
  'fort-dodge-ia': {
    name: 'Fort Dodge',
    postalCode: '50501',
  },
  'fort-dodge-ks': {
    name: 'Fort Dodge',
    postalCode: '67843',
  },
  'fort-drum-ny': {
    name: 'Fort Drum',
    postalCode: '13602',
  },
  'fort-duchesne-ut': {
    name: 'Fort Duchesne',
    postalCode: '84026',
  },
  'fort-edward-ny': {
    name: 'Fort Edward',
    postalCode: '12828',
  },
  'fort-eustis-va': {
    name: 'Fort Eustis',
    postalCode: '23604',
  },
  'fort-fairfield-me': {
    name: 'Fort Fairfield',
    postalCode: '04742',
  },
  'fort-gaines-ga': {
    name: 'Fort Gaines',
    postalCode: '39851',
  },
  'fort-garland-co': {
    name: 'Fort Garland',
    postalCode: '81133',
  },
  'fort-gay-wv': {
    name: 'Fort Gay',
    postalCode: '25514',
  },
  'fort-george-g-meade-md': {
    name: 'Fort George G Meade',
    postalCode: '20755',
  },
  'fort-gibson-ok': {
    name: 'Fort Gibson',
    postalCode: '74434',
  },
  'fort-gratiot-mi': {
    name: 'Fort Gratiot',
    postalCode: '48059',
  },
  'fort-hancock-tx': {
    name: 'Fort Hancock',
    postalCode: '79839',
  },
  'fort-hill-pa': {
    name: 'Fort Hill',
    postalCode: '15540',
  },
  'fort-hood-tx': {
    name: 'Fort Hood',
    postalCode: '76544',
  },
  'fort-huachuca-az': {
    name: 'Fort Huachuca',
    postalCode: '85613',
  },
  'fort-irwin-ca': {
    name: 'Fort Irwin',
    postalCode: '92310',
  },
  'fort-jennings-oh': {
    name: 'Fort Jennings',
    postalCode: '45844',
  },
  'fort-johnson-ny': {
    name: 'Fort Johnson',
    postalCode: '12070',
  },
  'fort-jones-ca': {
    name: 'Fort Jones',
    postalCode: '96032',
  },
  'fort-kent-me': {
    name: 'Fort Kent',
    postalCode: '04743',
  },
  'fort-knox-ky': {
    name: 'Fort Knox',
    postalCode: '40121',
  },
  'fort-laramie-wy': {
    name: 'Fort Laramie',
    postalCode: '82212',
  },
  'fort-lauderdale-fl': {
    name: 'Fort Lauderdale',
    postalCode: '33301',
  },
  'fort-lawn-sc': {
    name: 'Fort Lawn',
    postalCode: '29714',
  },
  'fort-leavenworth-ks': {
    name: 'Fort Leavenworth',
    postalCode: '66027',
  },
  'fort-lee-nj': {
    name: 'Fort Lee',
    postalCode: '07024',
  },
  'fort-lee-va': {
    name: 'Fort Lee',
    postalCode: '23801',
  },
  'fort-leonard-wood-mo': {
    name: 'Fort Leonard Wood',
    postalCode: '65473',
  },
  'fort-littleton-pa': {
    name: 'Fort Littleton',
    postalCode: '17223',
  },
  'fort-loramie-oh': {
    name: 'Fort Loramie',
    postalCode: '45845',
  },
  'fort-loudon-pa': {
    name: 'Fort Loudon',
    postalCode: '17224',
  },
  'fort-lupton-co': {
    name: 'Fort Lupton',
    postalCode: '80621',
  },
  'fort-madison-ia': {
    name: 'Fort Madison',
    postalCode: '52627',
  },
  'fort-mc-coy-fl': {
    name: 'Fort Mc Coy',
    postalCode: '32134',
  },
  'fort-mc-kavett-tx': {
    name: 'Fort Mc Kavett',
    postalCode: '76841',
  },
  'fort-mcdowell-az': {
    name: 'Fort Mcdowell',
    postalCode: '85264',
  },
  'fort-meade-fl': {
    name: 'Fort Meade',
    postalCode: '33841',
  },
  'fort-mill-sc': {
    name: 'Fort Mill',
    postalCode: '29707',
  },
  'fort-mitchell-al': {
    name: 'Fort Mitchell',
    postalCode: '36856',
  },
  'fort-mohave-az': {
    name: 'Fort Mohave',
    postalCode: '86426',
  },
  'fort-monmouth-nj': {
    name: 'Fort Monmouth',
    postalCode: '07703',
  },
  'fort-monroe-va': {
    name: 'Fort Monroe',
    postalCode: '23651',
  },
  'fort-morgan-co': {
    name: 'Fort Morgan',
    postalCode: '80701',
  },
  'fort-myer-va': {
    name: 'Fort Myer',
    postalCode: '22211',
  },
  'fort-myers-fl': {
    name: 'Fort Myers',
    postalCode: '33900',
  },
  'fort-myers-beach-fl': {
    name: 'Fort Myers Beach',
    postalCode: '33931',
  },
  'fort-necessity-la': {
    name: 'Fort Necessity',
    postalCode: '71243',
  },
  'fort-oglethorpe-ga': {
    name: 'Fort Oglethorpe',
    postalCode: '30742',
  },
  'fort-payne-al': {
    name: 'Fort Payne',
    postalCode: '35967',
  },
  'fort-peck-mt': {
    name: 'Fort Peck',
    postalCode: '59223',
  },
  'fort-pierce-fl': {
    name: 'Fort Pierce',
    postalCode: '34945',
  },
  'fort-pierre-sd': {
    name: 'Fort Pierre',
    postalCode: '57532',
  },
  'fort-plain-ny': {
    name: 'Fort Plain',
    postalCode: '13339',
  },
  'fort-polk-la': {
    name: 'Fort Polk',
    postalCode: '71459',
  },
  'fort-ransom-nd': {
    name: 'Fort Ransom',
    postalCode: '58033',
  },
  'fort-recovery-oh': {
    name: 'Fort Recovery',
    postalCode: '45846',
  },
  'fort-riley-ks': {
    name: 'Fort Riley',
    postalCode: '66442',
  },
  'fort-ripley-mn': {
    name: 'Fort Ripley',
    postalCode: '56449',
  },
  'fort-rucker-al': {
    name: 'Fort Rucker',
    postalCode: '36362',
  },
  'fort-scott-ks': {
    name: 'Fort Scott',
    postalCode: '66701',
  },
  'fort-shafter-hi': {
    name: 'Fort Shafter',
    postalCode: '96858',
  },
  'fort-shaw-mt': {
    name: 'Fort Shaw',
    postalCode: '59443',
  },
  'fort-sheridan-il': {
    name: 'Fort Sheridan',
    postalCode: '60037',
  },
  'fort-sill-ok': {
    name: 'Fort Sill',
    postalCode: '73503',
  },
  'fort-smith-ar': {
    name: 'Fort Smith',
    postalCode: '72901',
  },
  'fort-stewart-ga': {
    name: 'Fort Stewart',
    postalCode: '31314',
  },
  'fort-stockton-tx': {
    name: 'Fort Stockton',
    postalCode: '79735',
  },
  'fort-sumner-nm': {
    name: 'Fort Sumner',
    postalCode: '88119',
  },
  'fort-supply-ok': {
    name: 'Fort Supply',
    postalCode: '73841',
  },
  'fort-thomas-ky': {
    name: 'Fort Thomas',
    postalCode: '41075',
  },
  'fort-thompson-sd': {
    name: 'Fort Thompson',
    postalCode: '57339',
  },
  'fort-towson-ok': {
    name: 'Fort Towson',
    postalCode: '74735',
  },
  'fort-valley-ga': {
    name: 'Fort Valley',
    postalCode: '31030',
  },
  'fort-valley-va': {
    name: 'Fort Valley',
    postalCode: '22652',
  },
  'fort-wainwright-ak': {
    name: 'Fort Wainwright',
    postalCode: '99703',
  },
  'fort-walton-beach-fl': {
    name: 'Fort Walton Beach',
    postalCode: '32547',
  },
  'fort-washakie-wy': {
    name: 'Fort Washakie',
    postalCode: '82514',
  },
  'fort-washington-md': {
    name: 'Fort Washington',
    postalCode: '20744',
  },
  'fort-washington-pa': {
    name: 'Fort Washington',
    postalCode: '19034',
  },
  'fort-wayne-in': {
    name: 'Fort Wayne',
    postalCode: '46802',
  },
  'fort-white-fl': {
    name: 'Fort White',
    postalCode: '32038',
  },
  'fort-worth-tx': {
    name: 'Fort Worth',
    postalCode: '76102',
  },
  'fort-yates-nd': {
    name: 'Fort Yates',
    postalCode: '58538',
  },
  'fortson-ga': {
    name: 'Fortson',
    postalCode: '31808',
  },
  'fortuna-ca': {
    name: 'Fortuna',
    postalCode: '95540',
  },
  'fortuna-mo': {
    name: 'Fortuna',
    postalCode: '65034',
  },
  'fortuna-nd': {
    name: 'Fortuna',
    postalCode: '58844',
  },
  'fortville-in': {
    name: 'Fortville',
    postalCode: '46040',
  },
  'foss-ok': {
    name: 'Foss',
    postalCode: '73647',
  },
  'fossil-or': {
    name: 'Fossil',
    postalCode: '97830',
  },
  'fosston-mn': {
    name: 'Fosston',
    postalCode: '56542',
  },
  'foster-ky': {
    name: 'Foster',
    postalCode: '41043',
  },
  'foster-mo': {
    name: 'Foster',
    postalCode: '64745',
  },
  'foster-ok': {
    name: 'Foster',
    postalCode: '73434',
  },
  'foster-or': {
    name: 'Foster',
    postalCode: '97345',
  },
  'foster-ri': {
    name: 'Foster',
    postalCode: '02825',
  },
  'foster-va': {
    name: 'Foster',
    postalCode: '23056',
  },
  'foster-wv': {
    name: 'Foster',
    postalCode: '25081',
  },
  'foster-city-mi': {
    name: 'Foster City',
    postalCode: '49834',
  },
  'fosters-al': {
    name: 'Fosters',
    postalCode: '35463',
  },
  'fostoria-mi': {
    name: 'Fostoria',
    postalCode: '48435',
  },
  'fostoria-oh': {
    name: 'Fostoria',
    postalCode: '44830',
  },
  'fouke-ar': {
    name: 'Fouke',
    postalCode: '71837',
  },
  'fountain-co': {
    name: 'Fountain',
    postalCode: '80817',
  },
  'fountain-fl': {
    name: 'Fountain',
    postalCode: '32438',
  },
  'fountain-mi': {
    name: 'Fountain',
    postalCode: '49410',
  },
  'fountain-mn': {
    name: 'Fountain',
    postalCode: '55935',
  },
  'fountain-nc': {
    name: 'Fountain',
    postalCode: '27829',
  },
  'fountain-city-in': {
    name: 'Fountain City',
    postalCode: '47341',
  },
  'fountain-city-wi': {
    name: 'Fountain City',
    postalCode: '54629',
  },
  'fountain-hill-ar': {
    name: 'Fountain Hill',
    postalCode: '71642',
  },
  'fountain-hills-az': {
    name: 'Fountain Hills',
    postalCode: '85268',
  },
  'fountain-inn-sc': {
    name: 'Fountain Inn',
    postalCode: '29644',
  },
  'fountain-run-ky': {
    name: 'Fountain Run',
    postalCode: '42133',
  },
  'fountain-valley-ca': {
    name: 'Fountain Valley',
    postalCode: '92708',
  },
  'fountaintown-in': {
    name: 'Fountaintown',
    postalCode: '46130',
  },
  'fountainville-pa': {
    name: 'Fountainville',
    postalCode: '18923',
  },
  'four-corners-wy': {
    name: 'Four Corners',
    postalCode: '82715',
  },
  'four-oaks-nc': {
    name: 'Four Oaks',
    postalCode: '27524',
  },
  'fourmile-ky': {
    name: 'Fourmile',
    postalCode: '40939',
  },
  'fowler-ca': {
    name: 'Fowler',
    postalCode: '93625',
  },
  'fowler-co': {
    name: 'Fowler',
    postalCode: '81039',
  },
  'fowler-il': {
    name: 'Fowler',
    postalCode: '62338',
  },
  'fowler-in': {
    name: 'Fowler',
    postalCode: '47944',
  },
  'fowler-ks': {
    name: 'Fowler',
    postalCode: '67844',
  },
  'fowler-mi': {
    name: 'Fowler',
    postalCode: '48835',
  },
  'fowler-oh': {
    name: 'Fowler',
    postalCode: '44418',
  },
  'fowlerton-in': {
    name: 'Fowlerton',
    postalCode: '46930',
  },
  'fowlerton-tx': {
    name: 'Fowlerton',
    postalCode: '78021',
  },
  'fowlerville-mi': {
    name: 'Fowlerville',
    postalCode: '48836',
  },
  'fox-ar': {
    name: 'Fox',
    postalCode: '72051',
  },
  'fox-island-wa': {
    name: 'Fox Island',
    postalCode: '98333',
  },
  'fox-lake-il': {
    name: 'Fox Lake',
    postalCode: '60020',
  },
  'fox-lake-wi': {
    name: 'Fox Lake',
    postalCode: '53933',
  },
  'fox-river-grove-il': {
    name: 'Fox River Grove',
    postalCode: '60021',
  },
  'fox-valley-il': {
    name: 'Fox Valley',
    postalCode: '60599',
  },
  'foxboro-ma': {
    name: 'Foxboro',
    postalCode: '02035',
  },
  'foxboro-wi': {
    name: 'Foxboro',
    postalCode: '54836',
  },
  'foxhome-mn': {
    name: 'Foxhome',
    postalCode: '56543',
  },
  'foxworth-ms': {
    name: 'Foxworth',
    postalCode: '39483',
  },
  'frackville-pa': {
    name: 'Frackville',
    postalCode: '17931',
  },
  'frakes-ky': {
    name: 'Frakes',
    postalCode: '40940',
  },
  'frametown-wv': {
    name: 'Frametown',
    postalCode: '26623',
  },
  'framingham-ma': {
    name: 'Framingham',
    postalCode: '01701',
  },
  'francestown-nh': {
    name: 'Francestown',
    postalCode: '03043',
  },
  'francesville-in': {
    name: 'Francesville',
    postalCode: '47946',
  },
  'francisco-in': {
    name: 'Francisco',
    postalCode: '47649',
  },
  'franconia-nh': {
    name: 'Franconia',
    postalCode: '03580',
  },
  'frankenmuth-mi': {
    name: 'Frankenmuth',
    postalCode: '48734',
  },
  'frankewing-tn': {
    name: 'Frankewing',
    postalCode: '38459',
  },
  'frankford-de': {
    name: 'Frankford',
    postalCode: '19945',
  },
  'frankford-mo': {
    name: 'Frankford',
    postalCode: '63441',
  },
  'frankford-wv': {
    name: 'Frankford',
    postalCode: '24938',
  },
  'frankfort-il': {
    name: 'Frankfort',
    postalCode: '60423',
  },
  'frankfort-in': {
    name: 'Frankfort',
    postalCode: '46041',
  },
  'frankfort-ks': {
    name: 'Frankfort',
    postalCode: '66427',
  },
  'frankfort-ky': {
    name: 'Frankfort',
    postalCode: '40601',
  },
  'frankfort-me': {
    name: 'Frankfort',
    postalCode: '04438',
  },
  'frankfort-mi': {
    name: 'Frankfort',
    postalCode: '49635',
  },
  'frankfort-ny': {
    name: 'Frankfort',
    postalCode: '13340',
  },
  'frankfort-oh': {
    name: 'Frankfort',
    postalCode: '45628',
  },
  'frankfort-sd': {
    name: 'Frankfort',
    postalCode: '57440',
  },
  'franklin-al': {
    name: 'Franklin',
    postalCode: '36444',
  },
  'franklin-ar': {
    name: 'Franklin',
    postalCode: '72536',
  },
  'franklin-ga': {
    name: 'Franklin',
    postalCode: '30217',
  },
  'franklin-id': {
    name: 'Franklin',
    postalCode: '83237',
  },
  'franklin-il': {
    name: 'Franklin',
    postalCode: '62638',
  },
  'franklin-in': {
    name: 'Franklin',
    postalCode: '46131',
  },
  'franklin-ks': {
    name: 'Franklin',
    postalCode: '66735',
  },
  'franklin-ky': {
    name: 'Franklin',
    postalCode: '42134',
  },
  'franklin-la': {
    name: 'Franklin',
    postalCode: '70538',
  },
  'franklin-ma': {
    name: 'Franklin',
    postalCode: '02038',
  },
  'franklin-me': {
    name: 'Franklin',
    postalCode: '04634',
  },
  'franklin-mi': {
    name: 'Franklin',
    postalCode: '48025',
  },
  'franklin-mn': {
    name: 'Franklin',
    postalCode: '55333',
  },
  'franklin-mo': {
    name: 'Franklin',
    postalCode: '65250',
  },
  'franklin-nc': {
    name: 'Franklin',
    postalCode: '28734',
  },
  'franklin-ne': {
    name: 'Franklin',
    postalCode: '68939',
  },
  'franklin-nh': {
    name: 'Franklin',
    postalCode: '03235',
  },
  'franklin-nj': {
    name: 'Franklin',
    postalCode: '07416',
  },
  'franklin-ny': {
    name: 'Franklin',
    postalCode: '13775',
  },
  'franklin-oh': {
    name: 'Franklin',
    postalCode: '45005',
  },
  'franklin-pa': {
    name: 'Franklin',
    postalCode: '16323',
  },
  'franklin-tn': {
    name: 'Franklin',
    postalCode: '37064',
  },
  'franklin-tx': {
    name: 'Franklin',
    postalCode: '77856',
  },
  'franklin-va': {
    name: 'Franklin',
    postalCode: '23851',
  },
  'franklin-vt': {
    name: 'Franklin',
    postalCode: '05457',
  },
  'franklin-wi': {
    name: 'Franklin',
    postalCode: '53132',
  },
  'franklin-wv': {
    name: 'Franklin',
    postalCode: '26807',
  },
  'franklin-furnace-oh': {
    name: 'Franklin Furnace',
    postalCode: '45629',
  },
  'franklin-grove-il': {
    name: 'Franklin Grove',
    postalCode: '61031',
  },
  'franklin-lakes-nj': {
    name: 'Franklin Lakes',
    postalCode: '07417',
  },
  'franklin-park-il': {
    name: 'Franklin Park',
    postalCode: '60131',
  },
  'franklin-park-nj': {
    name: 'Franklin Park',
    postalCode: '08823',
  },
  'franklin-square-ny': {
    name: 'Franklin Square',
    postalCode: '11010',
  },
  'franklinton-la': {
    name: 'Franklinton',
    postalCode: '70438',
  },
  'franklinton-nc': {
    name: 'Franklinton',
    postalCode: '27525',
  },
  'franklinville-nc': {
    name: 'Franklinville',
    postalCode: '27248',
  },
  'franklinville-nj': {
    name: 'Franklinville',
    postalCode: '08322',
  },
  'franklinville-ny': {
    name: 'Franklinville',
    postalCode: '14737',
  },
  'frankston-tx': {
    name: 'Frankston',
    postalCode: '75763',
  },
  'franksville-wi': {
    name: 'Franksville',
    postalCode: '53126',
  },
  'frankton-in': {
    name: 'Frankton',
    postalCode: '46044',
  },
  'franktown-co': {
    name: 'Franktown',
    postalCode: '80116',
  },
  'franktown-va': {
    name: 'Franktown',
    postalCode: '23354',
  },
  'frankville-al': {
    name: 'Frankville',
    postalCode: '36538',
  },
  'fraser-mi': {
    name: 'Fraser',
    postalCode: '48026',
  },
  'frazee-mn': {
    name: 'Frazee',
    postalCode: '56544',
  },
  'frazer-mt': {
    name: 'Frazer',
    postalCode: '59225',
  },
  'frazeysburg-oh': {
    name: 'Frazeysburg',
    postalCode: '43822',
  },
  'frazier-park-ca': {
    name: 'Frazier Park',
    postalCode: '93225',
  },
  'fraziers-bottom-wv': {
    name: 'Fraziers Bottom',
    postalCode: '25082',
  },
  'fred-tx': {
    name: 'Fred',
    postalCode: '77616',
  },
  'frederic-mi': {
    name: 'Frederic',
    postalCode: '49733',
  },
  'frederic-wi': {
    name: 'Frederic',
    postalCode: '54837',
  },
  'frederica-de': {
    name: 'Frederica',
    postalCode: '19946',
  },
  'frederick-co': {
    name: 'Frederick',
    postalCode: '80530',
  },
  'frederick-il': {
    name: 'Frederick',
    postalCode: '62639',
  },
  'frederick-md': {
    name: 'Frederick',
    postalCode: '21701',
  },
  'frederick-ok': {
    name: 'Frederick',
    postalCode: '73542',
  },
  'frederick-pa': {
    name: 'Frederick',
    postalCode: '19435',
  },
  'frederick-sd': {
    name: 'Frederick',
    postalCode: '57441',
  },
  'fredericksburg-ia': {
    name: 'Fredericksburg',
    postalCode: '50630',
  },
  'fredericksburg-in': {
    name: 'Fredericksburg',
    postalCode: '47120',
  },
  'fredericksburg-oh': {
    name: 'Fredericksburg',
    postalCode: '44627',
  },
  'fredericksburg-pa': {
    name: 'Fredericksburg',
    postalCode: '17026',
  },
  'fredericksburg-tx': {
    name: 'Fredericksburg',
    postalCode: '78624',
  },
  'fredericksburg-va': {
    name: 'Fredericksburg',
    postalCode: '22401',
  },
  'fredericktown-mo': {
    name: 'Fredericktown',
    postalCode: '63645',
  },
  'fredericktown-oh': {
    name: 'Fredericktown',
    postalCode: '43019',
  },
  'fredericktown-pa': {
    name: 'Fredericktown',
    postalCode: '15333',
  },
  'frederiksted-vi': {
    name: 'Frederiksted',
    postalCode: '00840',
  },
  'fredonia-az': {
    name: 'Fredonia',
    postalCode: '86022',
  },
  'fredonia-ks': {
    name: 'Fredonia',
    postalCode: '66736',
  },
  'fredonia-ky': {
    name: 'Fredonia',
    postalCode: '42411',
  },
  'fredonia-nd': {
    name: 'Fredonia',
    postalCode: '58440',
  },
  'fredonia-ny': {
    name: 'Fredonia',
    postalCode: '14063',
  },
  'fredonia-pa': {
    name: 'Fredonia',
    postalCode: '16124',
  },
  'fredonia-tx': {
    name: 'Fredonia',
    postalCode: '76842',
  },
  'fredonia-wi': {
    name: 'Fredonia',
    postalCode: '53021',
  },
  'free-soil-mi': {
    name: 'Free Soil',
    postalCode: '49411',
  },
  'free-union-va': {
    name: 'Free Union',
    postalCode: '22940',
  },
  'freeburg-il': {
    name: 'Freeburg',
    postalCode: '62243',
  },
  'freeburg-mo': {
    name: 'Freeburg',
    postalCode: '65035',
  },
  'freeburg-pa': {
    name: 'Freeburg',
    postalCode: '17827',
  },
  'freeburn-ky': {
    name: 'Freeburn',
    postalCode: '41528',
  },
  'freedom-ca': {
    name: 'Freedom',
    postalCode: '95019',
  },
  'freedom-in': {
    name: 'Freedom',
    postalCode: '47431',
  },
  'freedom-me': {
    name: 'Freedom',
    postalCode: '04941',
  },
  'freedom-nh': {
    name: 'Freedom',
    postalCode: '03836',
  },
  'freedom-ny': {
    name: 'Freedom',
    postalCode: '14065',
  },
  'freedom-ok': {
    name: 'Freedom',
    postalCode: '73842',
  },
  'freedom-pa': {
    name: 'Freedom',
    postalCode: '15042',
  },
  'freedom-wy': {
    name: 'Freedom',
    postalCode: '83120',
  },
  'freehold-nj': {
    name: 'Freehold',
    postalCode: '07728',
  },
  'freehold-ny': {
    name: 'Freehold',
    postalCode: '12431',
  },
  'freeland-md': {
    name: 'Freeland',
    postalCode: '21053',
  },
  'freeland-mi': {
    name: 'Freeland',
    postalCode: '48623',
  },
  'freeland-pa': {
    name: 'Freeland',
    postalCode: '18224',
  },
  'freeland-wa': {
    name: 'Freeland',
    postalCode: '98249',
  },
  'freeman-mo': {
    name: 'Freeman',
    postalCode: '64746',
  },
  'freeman-sd': {
    name: 'Freeman',
    postalCode: '57029',
  },
  'freeman-va': {
    name: 'Freeman',
    postalCode: '23856',
  },
  'freeman-wv': {
    name: 'Freeman',
    postalCode: '24724',
  },
  'freeport-fl': {
    name: 'Freeport',
    postalCode: '32439',
  },
  'freeport-il': {
    name: 'Freeport',
    postalCode: '61032',
  },
  'freeport-ks': {
    name: 'Freeport',
    postalCode: '67049',
  },
  'freeport-me': {
    name: 'Freeport',
    postalCode: '04032',
  },
  'freeport-mi': {
    name: 'Freeport',
    postalCode: '49325',
  },
  'freeport-mn': {
    name: 'Freeport',
    postalCode: '56331',
  },
  'freeport-ny': {
    name: 'Freeport',
    postalCode: '11520',
  },
  'freeport-oh': {
    name: 'Freeport',
    postalCode: '43973',
  },
  'freeport-pa': {
    name: 'Freeport',
    postalCode: '16229',
  },
  'freeport-tx': {
    name: 'Freeport',
    postalCode: '77541',
  },
  'freer-tx': {
    name: 'Freer',
    postalCode: '78357',
  },
  'freetown-in': {
    name: 'Freetown',
    postalCode: '47235',
  },
  'freeville-ny': {
    name: 'Freeville',
    postalCode: '13068',
  },
  'fremont-ca': {
    name: 'Fremont',
    postalCode: '94536',
  },
  'fremont-ia': {
    name: 'Fremont',
    postalCode: '52561',
  },
  'fremont-in': {
    name: 'Fremont',
    postalCode: '46737',
  },
  'fremont-mi': {
    name: 'Fremont',
    postalCode: '49412',
  },
  'fremont-mo': {
    name: 'Fremont',
    postalCode: '63941',
  },
  'fremont-nc': {
    name: 'Fremont',
    postalCode: '27830',
  },
  'fremont-ne': {
    name: 'Fremont',
    postalCode: '68025',
  },
  'fremont-nh': {
    name: 'Fremont',
    postalCode: '03044',
  },
  'fremont-oh': {
    name: 'Fremont',
    postalCode: '43420',
  },
  'fremont-wi': {
    name: 'Fremont',
    postalCode: '54940',
  },
  'fremont-center-ny': {
    name: 'Fremont Center',
    postalCode: '12736',
  },
  'french-camp-ca': {
    name: 'French Camp',
    postalCode: '95231',
  },
  'french-camp-ms': {
    name: 'French Camp',
    postalCode: '39745',
  },
  'french-creek-wv': {
    name: 'French Creek',
    postalCode: '26218',
  },
  'french-gulch-ca': {
    name: 'French Gulch',
    postalCode: '96033',
  },
  'french-lick-in': {
    name: 'French Lick',
    postalCode: '47432',
  },
  'french-settlement-la': {
    name: 'French Settlement',
    postalCode: '70733',
  },
  'french-village-mo': {
    name: 'French Village',
    postalCode: '63036',
  },
  'frenchburg-ky': {
    name: 'Frenchburg',
    postalCode: '40322',
  },
  'frenchglen-or': {
    name: 'Frenchglen',
    postalCode: '97736',
  },
  'frenchmans-bayou-ar': {
    name: 'Frenchmans Bayou',
    postalCode: '72338',
  },
  'frenchtown-mt': {
    name: 'Frenchtown',
    postalCode: '59834',
  },
  'frenchtown-nj': {
    name: 'Frenchtown',
    postalCode: '08825',
  },
  'frenchville-me': {
    name: 'Frenchville',
    postalCode: '04745',
  },
  'frenchville-pa': {
    name: 'Frenchville',
    postalCode: '16836',
  },
  'fresh-meadows-ny': {
    name: 'Fresh Meadows',
    postalCode: '11365',
  },
  'fresno-ca': {
    name: 'Fresno',
    postalCode: '93650',
  },
  'fresno-oh': {
    name: 'Fresno',
    postalCode: '43824',
  },
  'fresno-tx': {
    name: 'Fresno',
    postalCode: '77545',
  },
  'frewsburg-ny': {
    name: 'Frewsburg',
    postalCode: '14738',
  },
  'friant-ca': {
    name: 'Friant',
    postalCode: '93626',
  },
  'friday-harbor-wa': {
    name: 'Friday Harbor',
    postalCode: '98250',
  },
  'friedens-pa': {
    name: 'Friedens',
    postalCode: '15541',
  },
  'friedheim-mo': {
    name: 'Friedheim',
    postalCode: '63747',
  },
  'friend-ne': {
    name: 'Friend',
    postalCode: '68359',
  },
  'friendly-wv': {
    name: 'Friendly',
    postalCode: '26146',
  },
  'friendship-md': {
    name: 'Friendship',
    postalCode: '20758',
  },
  'friendship-me': {
    name: 'Friendship',
    postalCode: '04547',
  },
  'friendship-ny': {
    name: 'Friendship',
    postalCode: '14739',
  },
  'friendship-tn': {
    name: 'Friendship',
    postalCode: '38034',
  },
  'friendship-wi': {
    name: 'Friendship',
    postalCode: '53934',
  },
  'friendsville-md': {
    name: 'Friendsville',
    postalCode: '21531',
  },
  'friendsville-pa': {
    name: 'Friendsville',
    postalCode: '18818',
  },
  'friendsville-tn': {
    name: 'Friendsville',
    postalCode: '37737',
  },
  'friendswood-tx': {
    name: 'Friendswood',
    postalCode: '77546',
  },
  'frierson-la': {
    name: 'Frierson',
    postalCode: '71027',
  },
  'fries-va': {
    name: 'Fries',
    postalCode: '24330',
  },
  'friona-tx': {
    name: 'Friona',
    postalCode: '79035',
  },
  'frisco-tx': {
    name: 'Frisco',
    postalCode: '75033',
  },
  'frisco-city-al': {
    name: 'Frisco City',
    postalCode: '36445',
  },
  'fritch-tx': {
    name: 'Fritch',
    postalCode: '79036',
  },
  'frohna-mo': {
    name: 'Frohna',
    postalCode: '63748',
  },
  'froid-mt': {
    name: 'Froid',
    postalCode: '59226',
  },
  'fromberg-mt': {
    name: 'Fromberg',
    postalCode: '59029',
  },
  'front-royal-va': {
    name: 'Front Royal',
    postalCode: '22630',
  },
  'frontenac-ks': {
    name: 'Frontenac',
    postalCode: '66763',
  },
  'frontenac-mn': {
    name: 'Frontenac',
    postalCode: '55026',
  },
  'frost-mn': {
    name: 'Frost',
    postalCode: '56033',
  },
  'frost-tx': {
    name: 'Frost',
    postalCode: '76641',
  },
  'frostburg-md': {
    name: 'Frostburg',
    postalCode: '21532',
  },
  'frostburg-pa': {
    name: 'Frostburg',
    postalCode: '15740',
  },
  'frostproof-fl': {
    name: 'Frostproof',
    postalCode: '33843',
  },
  'fruita-co': {
    name: 'Fruita',
    postalCode: '81521',
  },
  'fruitdale-al': {
    name: 'Fruitdale',
    postalCode: '36539',
  },
  'fruithurst-al': {
    name: 'Fruithurst',
    postalCode: '36262',
  },
  'fruitland-ia': {
    name: 'Fruitland',
    postalCode: '52749',
  },
  'fruitland-id': {
    name: 'Fruitland',
    postalCode: '83619',
  },
  'fruitland-md': {
    name: 'Fruitland',
    postalCode: '21826',
  },
  'fruitland-nm': {
    name: 'Fruitland',
    postalCode: '87416',
  },
  'fruitland-ut': {
    name: 'Fruitland',
    postalCode: '84027',
  },
  'fruitland-wa': {
    name: 'Fruitland',
    postalCode: '99129',
  },
  'fruitland-park-fl': {
    name: 'Fruitland Park',
    postalCode: '34731',
  },
  'fruitport-mi': {
    name: 'Fruitport',
    postalCode: '49415',
  },
  'fruitvale-tx': {
    name: 'Fruitvale',
    postalCode: '75127',
  },
  'fryburg-pa': {
    name: 'Fryburg',
    postalCode: '16326',
  },
  'fryeburg-me': {
    name: 'Fryeburg',
    postalCode: '04037',
  },
  'ft-mitchell-ky': {
    name: 'Ft Mitchell',
    postalCode: '41017',
  },
  'fulda-mn': {
    name: 'Fulda',
    postalCode: '56131',
  },
  'fulks-run-va': {
    name: 'Fulks Run',
    postalCode: '22830',
  },
  'fullerton-ca': {
    name: 'Fullerton',
    postalCode: '92831',
  },
  'fullerton-nd': {
    name: 'Fullerton',
    postalCode: '58441',
  },
  'fullerton-ne': {
    name: 'Fullerton',
    postalCode: '68638',
  },
  'fulshear-tx': {
    name: 'Fulshear',
    postalCode: '77441',
  },
  'fulton-ar': {
    name: 'Fulton',
    postalCode: '71838',
  },
  'fulton-ca': {
    name: 'Fulton',
    postalCode: '95439',
  },
  'fulton-il': {
    name: 'Fulton',
    postalCode: '61252',
  },
  'fulton-ks': {
    name: 'Fulton',
    postalCode: '66738',
  },
  'fulton-ky': {
    name: 'Fulton',
    postalCode: '42041',
  },
  'fulton-md': {
    name: 'Fulton',
    postalCode: '20759',
  },
  'fulton-mi': {
    name: 'Fulton',
    postalCode: '49052',
  },
  'fulton-mo': {
    name: 'Fulton',
    postalCode: '65251',
  },
  'fulton-ms': {
    name: 'Fulton',
    postalCode: '38843',
  },
  'fulton-ny': {
    name: 'Fulton',
    postalCode: '13069',
  },
  'fulton-oh': {
    name: 'Fulton',
    postalCode: '43321',
  },
  'fulton-sd': {
    name: 'Fulton',
    postalCode: '57340',
  },
  'fultondale-al': {
    name: 'Fultondale',
    postalCode: '35068',
  },
  'fultonham-ny': {
    name: 'Fultonham',
    postalCode: '12071',
  },
  'fultonville-ny': {
    name: 'Fultonville',
    postalCode: '12072',
  },
  'fults-il': {
    name: 'Fults',
    postalCode: '62244',
  },
  'funk-ne': {
    name: 'Funk',
    postalCode: '68940',
  },
  'fuquay-varina-nc': {
    name: 'Fuquay Varina',
    postalCode: '27526',
  },
  'furlong-pa': {
    name: 'Furlong',
    postalCode: '18925',
  },
  'fyffe-al': {
    name: 'Fyffe',
    postalCode: '35971',
  },
  'gaastra-mi': {
    name: 'Gaastra',
    postalCode: '49927',
  },
  'gable-sc': {
    name: 'Gable',
    postalCode: '29051',
  },
  'gackle-nd': {
    name: 'Gackle',
    postalCode: '58442',
  },
  'gadsden-al': {
    name: 'Gadsden',
    postalCode: '35901',
  },
  'gadsden-sc': {
    name: 'Gadsden',
    postalCode: '29052',
  },
  'gadsden-tn': {
    name: 'Gadsden',
    postalCode: '38337',
  },
  'gaffney-sc': {
    name: 'Gaffney',
    postalCode: '29340',
  },
  'gage-ok': {
    name: 'Gage',
    postalCode: '73843',
  },
  'gagetown-mi': {
    name: 'Gagetown',
    postalCode: '48735',
  },
  'gail-tx': {
    name: 'Gail',
    postalCode: '79738',
  },
  'gaines-mi': {
    name: 'Gaines',
    postalCode: '48436',
  },
  'gaines-pa': {
    name: 'Gaines',
    postalCode: '16921',
  },
  'gainesboro-tn': {
    name: 'Gainesboro',
    postalCode: '38562',
  },
  'gainestown-al': {
    name: 'Gainestown',
    postalCode: '36540',
  },
  'gainesville-al': {
    name: 'Gainesville',
    postalCode: '35464',
  },
  'gainesville-fl': {
    name: 'Gainesville',
    postalCode: '32601',
  },
  'gainesville-ga': {
    name: 'Gainesville',
    postalCode: '30501',
  },
  'gainesville-mo': {
    name: 'Gainesville',
    postalCode: '65655',
  },
  'gainesville-ny': {
    name: 'Gainesville',
    postalCode: '14066',
  },
  'gainesville-tx': {
    name: 'Gainesville',
    postalCode: '76240',
  },
  'gainesville-va': {
    name: 'Gainesville',
    postalCode: '20155',
  },
  'gaithersburg-md': {
    name: 'Gaithersburg',
    postalCode: '20877',
  },
  'gakona-ak': {
    name: 'Gakona',
    postalCode: '99586',
  },
  'galata-mt': {
    name: 'Galata',
    postalCode: '59444',
  },
  'galatia-il': {
    name: 'Galatia',
    postalCode: '62935',
  },
  'galax-va': {
    name: 'Galax',
    postalCode: '24333',
  },
  'galena-il': {
    name: 'Galena',
    postalCode: '61036',
  },
  'galena-ks': {
    name: 'Galena',
    postalCode: '66739',
  },
  'galena-md': {
    name: 'Galena',
    postalCode: '21635',
  },
  'galena-mo': {
    name: 'Galena',
    postalCode: '65656',
  },
  'galena-oh': {
    name: 'Galena',
    postalCode: '43021',
  },
  'galena-park-tx': {
    name: 'Galena Park',
    postalCode: '77547',
  },
  'gales-creek-or': {
    name: 'Gales Creek',
    postalCode: '97117',
  },
  'gales-ferry-ct': {
    name: 'Gales Ferry',
    postalCode: '06335',
  },
  'galesburg-il': {
    name: 'Galesburg',
    postalCode: '61401',
  },
  'galesburg-ks': {
    name: 'Galesburg',
    postalCode: '66740',
  },
  'galesburg-mi': {
    name: 'Galesburg',
    postalCode: '49053',
  },
  'galesburg-nd': {
    name: 'Galesburg',
    postalCode: '58035',
  },
  'galesville-wi': {
    name: 'Galesville',
    postalCode: '54630',
  },
  'galeton-co': {
    name: 'Galeton',
    postalCode: '80622',
  },
  'galeton-pa': {
    name: 'Galeton',
    postalCode: '16922',
  },
  'galien-mi': {
    name: 'Galien',
    postalCode: '49113',
  },
  'galion-oh': {
    name: 'Galion',
    postalCode: '44833',
  },
  'galivants-ferry-sc': {
    name: 'Galivants Ferry',
    postalCode: '29544',
  },
  'gallagher-wv': {
    name: 'Gallagher',
    postalCode: '25083',
  },
  'gallant-al': {
    name: 'Gallant',
    postalCode: '35972',
  },
  'gallatin-mo': {
    name: 'Gallatin',
    postalCode: '64640',
  },
  'gallatin-tn': {
    name: 'Gallatin',
    postalCode: '37066',
  },
  'gallatin-gateway-mt': {
    name: 'Gallatin Gateway',
    postalCode: '59730',
  },
  'galliano-la': {
    name: 'Galliano',
    postalCode: '70354',
  },
  'gallina-nm': {
    name: 'Gallina',
    postalCode: '87017',
  },
  'gallion-al': {
    name: 'Gallion',
    postalCode: '36742',
  },
  'gallipolis-oh': {
    name: 'Gallipolis',
    postalCode: '45631',
  },
  'gallipolis-ferry-wv': {
    name: 'Gallipolis Ferry',
    postalCode: '25515',
  },
  'gallitzin-pa': {
    name: 'Gallitzin',
    postalCode: '16641',
  },
  'galloway-oh': {
    name: 'Galloway',
    postalCode: '43119',
  },
  'gallup-nm': {
    name: 'Gallup',
    postalCode: '87301',
  },
  'galt-ca': {
    name: 'Galt',
    postalCode: '95632',
  },
  'galt-ia': {
    name: 'Galt',
    postalCode: '50101',
  },
  'galt-mo': {
    name: 'Galt',
    postalCode: '64641',
  },
  'galva-ia': {
    name: 'Galva',
    postalCode: '51020',
  },
  'galva-il': {
    name: 'Galva',
    postalCode: '61434',
  },
  'galva-ks': {
    name: 'Galva',
    postalCode: '67443',
  },
  'galveston-in': {
    name: 'Galveston',
    postalCode: '46932',
  },
  'galveston-tx': {
    name: 'Galveston',
    postalCode: '77550',
  },
  'galway-ny': {
    name: 'Galway',
    postalCode: '12074',
  },
  'gamaliel-ar': {
    name: 'Gamaliel',
    postalCode: '72537',
  },
  'gamaliel-ky': {
    name: 'Gamaliel',
    postalCode: '42140',
  },
  'gambier-oh': {
    name: 'Gambier',
    postalCode: '43022',
  },
  'gambrills-md': {
    name: 'Gambrills',
    postalCode: '21054',
  },
  'gamerco-nm': {
    name: 'Gamerco',
    postalCode: '87317',
  },
  'ganado-az': {
    name: 'Ganado',
    postalCode: '86505',
  },
  'ganado-tx': {
    name: 'Ganado',
    postalCode: '77962',
  },
  'gandeeville-wv': {
    name: 'Gandeeville',
    postalCode: '25243',
  },
  'gann-valley-sd': {
    name: 'Gann Valley',
    postalCode: '57341',
  },
  'gans-ok': {
    name: 'Gans',
    postalCode: '74936',
  },
  'gansevoort-ny': {
    name: 'Gansevoort',
    postalCode: '12831',
  },
  'gap-pa': {
    name: 'Gap',
    postalCode: '17527',
  },
  'gap-mills-wv': {
    name: 'Gap Mills',
    postalCode: '24941',
  },
  'garards-fort-pa': {
    name: 'Garards Fort',
    postalCode: '15334',
  },
  'garber-ia': {
    name: 'Garber',
    postalCode: '52048',
  },
  'garber-ok': {
    name: 'Garber',
    postalCode: '73738',
  },
  'garberville-ca': {
    name: 'Garberville',
    postalCode: '95542',
  },
  'garden-mi': {
    name: 'Garden',
    postalCode: '49835',
  },
  'garden-city-id': {
    name: 'Garden City',
    postalCode: '83714',
  },
  'garden-city-ks': {
    name: 'Garden City',
    postalCode: '67846',
  },
  'garden-city-mi': {
    name: 'Garden City',
    postalCode: '48135',
  },
  'garden-city-mn': {
    name: 'Garden City',
    postalCode: '56034',
  },
  'garden-city-mo': {
    name: 'Garden City',
    postalCode: '64747',
  },
  'garden-city-ny': {
    name: 'Garden City',
    postalCode: '11530',
  },
  'garden-city-sd': {
    name: 'Garden City',
    postalCode: '57236',
  },
  'garden-city-tx': {
    name: 'Garden City',
    postalCode: '79739',
  },
  'garden-city-ut': {
    name: 'Garden City',
    postalCode: '84028',
  },
  'garden-grove-ca': {
    name: 'Garden Grove',
    postalCode: '92840',
  },
  'garden-grove-ia': {
    name: 'Garden Grove',
    postalCode: '50103',
  },
  'garden-plain-ks': {
    name: 'Garden Plain',
    postalCode: '67050',
  },
  'garden-prairie-il': {
    name: 'Garden Prairie',
    postalCode: '61038',
  },
  'garden-valley-ca': {
    name: 'Garden Valley',
    postalCode: '95633',
  },
  'garden-valley-id': {
    name: 'Garden Valley',
    postalCode: '83622',
  },
  'gardena-ca': {
    name: 'Gardena',
    postalCode: '90247',
  },
  'gardendale-al': {
    name: 'Gardendale',
    postalCode: '35071',
  },
  'gardendale-tx': {
    name: 'Gardendale',
    postalCode: '79758',
  },
  'gardiner-me': {
    name: 'Gardiner',
    postalCode: '04345',
  },
  'gardiner-mt': {
    name: 'Gardiner',
    postalCode: '59030',
  },
  'gardiner-ny': {
    name: 'Gardiner',
    postalCode: '12525',
  },
  'gardiner-or': {
    name: 'Gardiner',
    postalCode: '97441',
  },
  'gardner-co': {
    name: 'Gardner',
    postalCode: '81040',
  },
  'gardner-il': {
    name: 'Gardner',
    postalCode: '60424',
  },
  'gardner-ks': {
    name: 'Gardner',
    postalCode: '66030',
  },
  'gardner-ma': {
    name: 'Gardner',
    postalCode: '01440',
  },
  'gardner-nd': {
    name: 'Gardner',
    postalCode: '58036',
  },
  'gardners-pa': {
    name: 'Gardners',
    postalCode: '17324',
  },
  'gardnerville-nv': {
    name: 'Gardnerville',
    postalCode: '89410',
  },
  'garfield-ar': {
    name: 'Garfield',
    postalCode: '72732',
  },
  'garfield-ga': {
    name: 'Garfield',
    postalCode: '30425',
  },
  'garfield-ks': {
    name: 'Garfield',
    postalCode: '67529',
  },
  'garfield-ky': {
    name: 'Garfield',
    postalCode: '40140',
  },
  'garfield-mn': {
    name: 'Garfield',
    postalCode: '56332',
  },
  'garfield-nj': {
    name: 'Garfield',
    postalCode: '07026',
  },
  'garfield-nm': {
    name: 'Garfield',
    postalCode: '87936',
  },
  'garfield-wa': {
    name: 'Garfield',
    postalCode: '99130',
  },
  'garita-nm': {
    name: 'Garita',
    postalCode: '88421',
  },
  'garland-ks': {
    name: 'Garland',
    postalCode: '66741',
  },
  'garland-me': {
    name: 'Garland',
    postalCode: '04939',
  },
  'garland-nc': {
    name: 'Garland',
    postalCode: '28441',
  },
  'garland-ne': {
    name: 'Garland',
    postalCode: '68360',
  },
  'garland-tx': {
    name: 'Garland',
    postalCode: '75040',
  },
  'garland-ut': {
    name: 'Garland',
    postalCode: '84312',
  },
  'garland-city-ar': {
    name: 'Garland City',
    postalCode: '71839',
  },
  'garnavillo-ia': {
    name: 'Garnavillo',
    postalCode: '52049',
  },
  'garner-ia': {
    name: 'Garner',
    postalCode: '50438',
  },
  'garner-ky': {
    name: 'Garner',
    postalCode: '41817',
  },
  'garner-nc': {
    name: 'Garner',
    postalCode: '27529',
  },
  'garnerville-ny': {
    name: 'Garnerville',
    postalCode: '10923',
  },
  'garnet-valley-pa': {
    name: 'Garnet Valley',
    postalCode: '19060',
  },
  'garnett-ks': {
    name: 'Garnett',
    postalCode: '66032',
  },
  'garnett-sc': {
    name: 'Garnett',
    postalCode: '29922',
  },
  'garrattsville-ny': {
    name: 'Garrattsville',
    postalCode: '13342',
  },
  'garretson-sd': {
    name: 'Garretson',
    postalCode: '57030',
  },
  'garrett-in': {
    name: 'Garrett',
    postalCode: '46738',
  },
  'garrett-ky': {
    name: 'Garrett',
    postalCode: '41630',
  },
  'garrett-pa': {
    name: 'Garrett',
    postalCode: '15542',
  },
  'garrett-wy': {
    name: 'Garrett',
    postalCode: '82058',
  },
  'garrettsville-oh': {
    name: 'Garrettsville',
    postalCode: '44231',
  },
  'garrison-ia': {
    name: 'Garrison',
    postalCode: '52229',
  },
  'garrison-ky': {
    name: 'Garrison',
    postalCode: '41141',
  },
  'garrison-mn': {
    name: 'Garrison',
    postalCode: '56450',
  },
  'garrison-mo': {
    name: 'Garrison',
    postalCode: '65657',
  },
  'garrison-mt': {
    name: 'Garrison',
    postalCode: '59731',
  },
  'garrison-nd': {
    name: 'Garrison',
    postalCode: '58540',
  },
  'garrison-ny': {
    name: 'Garrison',
    postalCode: '10524',
  },
  'garrison-tx': {
    name: 'Garrison',
    postalCode: '75946',
  },
  'garrison-ut': {
    name: 'Garrison',
    postalCode: '84728',
  },
  'garrochales-pr': {
    name: 'Garrochales',
    postalCode: '00652',
  },
  'garryowen-mt': {
    name: 'Garryowen',
    postalCode: '59031',
  },
  'garvin-mn': {
    name: 'Garvin',
    postalCode: '56132',
  },
  'garvin-ok': {
    name: 'Garvin',
    postalCode: '74736',
  },
  'garwin-ia': {
    name: 'Garwin',
    postalCode: '50632',
  },
  'garwood-nj': {
    name: 'Garwood',
    postalCode: '07027',
  },
  'garwood-tx': {
    name: 'Garwood',
    postalCode: '77442',
  },
  'gary-in': {
    name: 'Gary',
    postalCode: '46402',
  },
  'gary-mn': {
    name: 'Gary',
    postalCode: '56545',
  },
  'gary-sd': {
    name: 'Gary',
    postalCode: '57237',
  },
  'gary-tx': {
    name: 'Gary',
    postalCode: '75643',
  },
  'garysburg-nc': {
    name: 'Garysburg',
    postalCode: '27831',
  },
  'garyville-la': {
    name: 'Garyville',
    postalCode: '70051',
  },
  'gas-city-in': {
    name: 'Gas City',
    postalCode: '46933',
  },
  'gasburg-va': {
    name: 'Gasburg',
    postalCode: '23857',
  },
  'gasport-ny': {
    name: 'Gasport',
    postalCode: '14067',
  },
  'gasquet-ca': {
    name: 'Gasquet',
    postalCode: '95543',
  },
  'gassaway-wv': {
    name: 'Gassaway',
    postalCode: '26624',
  },
  'gassville-ar': {
    name: 'Gassville',
    postalCode: '72635',
  },
  'gaston-in': {
    name: 'Gaston',
    postalCode: '47342',
  },
  'gaston-nc': {
    name: 'Gaston',
    postalCode: '27832',
  },
  'gaston-or': {
    name: 'Gaston',
    postalCode: '97119',
  },
  'gaston-sc': {
    name: 'Gaston',
    postalCode: '29053',
  },
  'gastonia-nc': {
    name: 'Gastonia',
    postalCode: '28052',
  },
  'gate-ok': {
    name: 'Gate',
    postalCode: '73844',
  },
  'gate-city-va': {
    name: 'Gate City',
    postalCode: '24251',
  },
  'gates-nc': {
    name: 'Gates',
    postalCode: '27937',
  },
  'gates-or': {
    name: 'Gates',
    postalCode: '97346',
  },
  'gates-tn': {
    name: 'Gates',
    postalCode: '38037',
  },
  'gates-mills-oh': {
    name: 'Gates Mills',
    postalCode: '44040',
  },
  'gatesville-nc': {
    name: 'Gatesville',
    postalCode: '27938',
  },
  'gatesville-tx': {
    name: 'Gatesville',
    postalCode: '76528',
  },
  'gateway-co': {
    name: 'Gateway',
    postalCode: '81522',
  },
  'gatewood-mo': {
    name: 'Gatewood',
    postalCode: '63942',
  },
  'gatlinburg-tn': {
    name: 'Gatlinburg',
    postalCode: '37738',
  },
  'gattman-ms': {
    name: 'Gattman',
    postalCode: '38844',
  },
  'gatzke-mn': {
    name: 'Gatzke',
    postalCode: '56724',
  },
  'gauley-bridge-wv': {
    name: 'Gauley Bridge',
    postalCode: '25085',
  },
  'gautier-ms': {
    name: 'Gautier',
    postalCode: '39553',
  },
  'gay-ga': {
    name: 'Gay',
    postalCode: '30218',
  },
  'gay-wv': {
    name: 'Gay',
    postalCode: '25244',
  },
  'gaylesville-al': {
    name: 'Gaylesville',
    postalCode: '35973',
  },
  'gaylord-ks': {
    name: 'Gaylord',
    postalCode: '67638',
  },
  'gaylord-mi': {
    name: 'Gaylord',
    postalCode: '49735',
  },
  'gaylord-mn': {
    name: 'Gaylord',
    postalCode: '55334',
  },
  'gaylordsville-ct': {
    name: 'Gaylordsville',
    postalCode: '06755',
  },
  'gays-il': {
    name: 'Gays',
    postalCode: '61928',
  },
  'gays-creek-ky': {
    name: 'Gays Creek',
    postalCode: '41745',
  },
  'gays-mills-wi': {
    name: 'Gays Mills',
    postalCode: '54631',
  },
  'gayville-sd': {
    name: 'Gayville',
    postalCode: '57031',
  },
  'gazelle-ca': {
    name: 'Gazelle',
    postalCode: '96034',
  },
  'geary-ok': {
    name: 'Geary',
    postalCode: '73040',
  },
  'geddes-sd': {
    name: 'Geddes',
    postalCode: '57342',
  },
  'geff-il': {
    name: 'Geff',
    postalCode: '62842',
  },
  'geismar-la': {
    name: 'Geismar',
    postalCode: '70734',
  },
  'gem-ks': {
    name: 'Gem',
    postalCode: '67734',
  },
  'genesee-id': {
    name: 'Genesee',
    postalCode: '83832',
  },
  'genesee-pa': {
    name: 'Genesee',
    postalCode: '16923',
  },
  'geneseo-il': {
    name: 'Geneseo',
    postalCode: '61254',
  },
  'geneseo-ks': {
    name: 'Geneseo',
    postalCode: '67444',
  },
  'geneseo-ny': {
    name: 'Geneseo',
    postalCode: '14454',
  },
  'geneva-al': {
    name: 'Geneva',
    postalCode: '36340',
  },
  'geneva-fl': {
    name: 'Geneva',
    postalCode: '32732',
  },
  'geneva-ia': {
    name: 'Geneva',
    postalCode: '50633',
  },
  'geneva-id': {
    name: 'Geneva',
    postalCode: '83238',
  },
  'geneva-il': {
    name: 'Geneva',
    postalCode: '60134',
  },
  'geneva-in': {
    name: 'Geneva',
    postalCode: '46740',
  },
  'geneva-ne': {
    name: 'Geneva',
    postalCode: '68361',
  },
  'geneva-ny': {
    name: 'Geneva',
    postalCode: '14456',
  },
  'geneva-oh': {
    name: 'Geneva',
    postalCode: '44041',
  },
  'genoa-co': {
    name: 'Genoa',
    postalCode: '80818',
  },
  'genoa-il': {
    name: 'Genoa',
    postalCode: '60135',
  },
  'genoa-ne': {
    name: 'Genoa',
    postalCode: '68640',
  },
  'genoa-ny': {
    name: 'Genoa',
    postalCode: '13071',
  },
  'genoa-oh': {
    name: 'Genoa',
    postalCode: '43430',
  },
  'genoa-wi': {
    name: 'Genoa',
    postalCode: '54632',
  },
  'genoa-wv': {
    name: 'Genoa',
    postalCode: '25517',
  },
  'genoa-city-wi': {
    name: 'Genoa City',
    postalCode: '53128',
  },
  'gentry-ar': {
    name: 'Gentry',
    postalCode: '72734',
  },
  'gentry-mo': {
    name: 'Gentry',
    postalCode: '64453',
  },
  'gentryville-in': {
    name: 'Gentryville',
    postalCode: '47537',
  },
  'george-ia': {
    name: 'George',
    postalCode: '51237',
  },
  'george-west-tx': {
    name: 'George West',
    postalCode: '78022',
  },
  'georgetown-ca': {
    name: 'Georgetown',
    postalCode: '95634',
  },
  'georgetown-de': {
    name: 'Georgetown',
    postalCode: '19947',
  },
  'georgetown-fl': {
    name: 'Georgetown',
    postalCode: '32139',
  },
  'georgetown-ga': {
    name: 'Georgetown',
    postalCode: '39854',
  },
  'georgetown-il': {
    name: 'Georgetown',
    postalCode: '61846',
  },
  'georgetown-in': {
    name: 'Georgetown',
    postalCode: '47122',
  },
  'georgetown-ky': {
    name: 'Georgetown',
    postalCode: '40324',
  },
  'georgetown-la': {
    name: 'Georgetown',
    postalCode: '71432',
  },
  'georgetown-ma': {
    name: 'Georgetown',
    postalCode: '01833',
  },
  'georgetown-me': {
    name: 'Georgetown',
    postalCode: '04548',
  },
  'georgetown-mn': {
    name: 'Georgetown',
    postalCode: '56546',
  },
  'georgetown-ms': {
    name: 'Georgetown',
    postalCode: '39078',
  },
  'georgetown-ny': {
    name: 'Georgetown',
    postalCode: '13072',
  },
  'georgetown-oh': {
    name: 'Georgetown',
    postalCode: '45121',
  },
  'georgetown-pa': {
    name: 'Georgetown',
    postalCode: '15043',
  },
  'georgetown-sc': {
    name: 'Georgetown',
    postalCode: '29440',
  },
  'georgetown-tn': {
    name: 'Georgetown',
    postalCode: '37336',
  },
  'georgetown-tx': {
    name: 'Georgetown',
    postalCode: '78626',
  },
  'georgiana-al': {
    name: 'Georgiana',
    postalCode: '36033',
  },
  'gepp-ar': {
    name: 'Gepp',
    postalCode: '72538',
  },
  'gerald-mo': {
    name: 'Gerald',
    postalCode: '63037',
  },
  'geraldine-al': {
    name: 'Geraldine',
    postalCode: '35974',
  },
  'geraldine-mt': {
    name: 'Geraldine',
    postalCode: '59446',
  },
  'gerber-ca': {
    name: 'Gerber',
    postalCode: '96035',
  },
  'gering-ne': {
    name: 'Gering',
    postalCode: '69341',
  },
  'gerlaw-il': {
    name: 'Gerlaw',
    postalCode: '61435',
  },
  'german-valley-il': {
    name: 'German Valley',
    postalCode: '61039',
  },
  'germansville-pa': {
    name: 'Germansville',
    postalCode: '18053',
  },
  'germanton-nc': {
    name: 'Germanton',
    postalCode: '27019',
  },
  'germantown-il': {
    name: 'Germantown',
    postalCode: '62245',
  },
  'germantown-ky': {
    name: 'Germantown',
    postalCode: '41044',
  },
  'germantown-md': {
    name: 'Germantown',
    postalCode: '20874',
  },
  'germantown-ny': {
    name: 'Germantown',
    postalCode: '12526',
  },
  'germantown-oh': {
    name: 'Germantown',
    postalCode: '45327',
  },
  'germantown-tn': {
    name: 'Germantown',
    postalCode: '38138',
  },
  'germantown-wi': {
    name: 'Germantown',
    postalCode: '53022',
  },
  'germfask-mi': {
    name: 'Germfask',
    postalCode: '49836',
  },
  'geronimo-ok': {
    name: 'Geronimo',
    postalCode: '73543',
  },
  'gerrardstown-wv': {
    name: 'Gerrardstown',
    postalCode: '25420',
  },
  'gerry-ny': {
    name: 'Gerry',
    postalCode: '14740',
  },
  'gerton-nc': {
    name: 'Gerton',
    postalCode: '28735',
  },
  'gervais-or': {
    name: 'Gervais',
    postalCode: '97026',
  },
  'gettysburg-pa': {
    name: 'Gettysburg',
    postalCode: '17325',
  },
  'gettysburg-sd': {
    name: 'Gettysburg',
    postalCode: '57442',
  },
  'getzville-ny': {
    name: 'Getzville',
    postalCode: '14068',
  },
  'geuda-springs-ks': {
    name: 'Geuda Springs',
    postalCode: '67051',
  },
  'geyser-mt': {
    name: 'Geyser',
    postalCode: '59447',
  },
  'geyserville-ca': {
    name: 'Geyserville',
    postalCode: '95441',
  },
  'gheens-la': {
    name: 'Gheens',
    postalCode: '70355',
  },
  'ghent-ky': {
    name: 'Ghent',
    postalCode: '41045',
  },
  'ghent-mn': {
    name: 'Ghent',
    postalCode: '56239',
  },
  'ghent-ny': {
    name: 'Ghent',
    postalCode: '12075',
  },
  'ghent-wv': {
    name: 'Ghent',
    postalCode: '25843',
  },
  'gibbon-mn': {
    name: 'Gibbon',
    postalCode: '55335',
  },
  'gibbon-ne': {
    name: 'Gibbon',
    postalCode: '68840',
  },
  'gibbon-glade-pa': {
    name: 'Gibbon Glade',
    postalCode: '15440',
  },
  'gibbonsville-id': {
    name: 'Gibbonsville',
    postalCode: '83463',
  },
  'gibbs-mo': {
    name: 'Gibbs',
    postalCode: '63540',
  },
  'gibbsboro-nj': {
    name: 'Gibbsboro',
    postalCode: '08026',
  },
  'gibbstown-nj': {
    name: 'Gibbstown',
    postalCode: '08027',
  },
  'gibsland-la': {
    name: 'Gibsland',
    postalCode: '71028',
  },
  'gibson-ga': {
    name: 'Gibson',
    postalCode: '30810',
  },
  'gibson-ia': {
    name: 'Gibson',
    postalCode: '50104',
  },
  'gibson-la': {
    name: 'Gibson',
    postalCode: '70356',
  },
  'gibson-nc': {
    name: 'Gibson',
    postalCode: '28343',
  },
  'gibson-city-il': {
    name: 'Gibson City',
    postalCode: '60936',
  },
  'gibsonburg-oh': {
    name: 'Gibsonburg',
    postalCode: '43431',
  },
  'gibsonia-pa': {
    name: 'Gibsonia',
    postalCode: '15044',
  },
  'gibsonton-fl': {
    name: 'Gibsonton',
    postalCode: '33534',
  },
  'gibsonville-nc': {
    name: 'Gibsonville',
    postalCode: '27249',
  },
  'giddings-tx': {
    name: 'Giddings',
    postalCode: '78942',
  },
  'gideon-mo': {
    name: 'Gideon',
    postalCode: '63848',
  },
  'gifford-ia': {
    name: 'Gifford',
    postalCode: '50259',
  },
  'gifford-il': {
    name: 'Gifford',
    postalCode: '61847',
  },
  'gifford-pa': {
    name: 'Gifford',
    postalCode: '16732',
  },
  'gifford-wa': {
    name: 'Gifford',
    postalCode: '99131',
  },
  'gig-harbor-wa': {
    name: 'Gig Harbor',
    postalCode: '98329',
  },
  'gila-bend-az': {
    name: 'Gila Bend',
    postalCode: '85337',
  },
  'gilbert-az': {
    name: 'Gilbert',
    postalCode: '85233',
  },
  'gilbert-ia': {
    name: 'Gilbert',
    postalCode: '50105',
  },
  'gilbert-la': {
    name: 'Gilbert',
    postalCode: '71336',
  },
  'gilbert-mn': {
    name: 'Gilbert',
    postalCode: '55741',
  },
  'gilbert-pa': {
    name: 'Gilbert',
    postalCode: '18331',
  },
  'gilbert-sc': {
    name: 'Gilbert',
    postalCode: '29054',
  },
  'gilbert-wv': {
    name: 'Gilbert',
    postalCode: '25621',
  },
  'gilberton-pa': {
    name: 'Gilberton',
    postalCode: '17934',
  },
  'gilbertown-al': {
    name: 'Gilbertown',
    postalCode: '36908',
  },
  'gilberts-il': {
    name: 'Gilberts',
    postalCode: '60136',
  },
  'gilbertsville-ky': {
    name: 'Gilbertsville',
    postalCode: '42044',
  },
  'gilbertsville-ny': {
    name: 'Gilbertsville',
    postalCode: '13776',
  },
  'gilbertsville-pa': {
    name: 'Gilbertsville',
    postalCode: '19525',
  },
  'gilbertville-ma': {
    name: 'Gilbertville',
    postalCode: '01031',
  },
  'gilboa-ny': {
    name: 'Gilboa',
    postalCode: '12076',
  },
  'gilby-nd': {
    name: 'Gilby',
    postalCode: '58235',
  },
  'gilchrist-or': {
    name: 'Gilchrist',
    postalCode: '97737',
  },
  'gildford-mt': {
    name: 'Gildford',
    postalCode: '59525',
  },
  'gile-wi': {
    name: 'Gile',
    postalCode: '54525',
  },
  'gilford-nh': {
    name: 'Gilford',
    postalCode: '03249',
  },
  'gill-co': {
    name: 'Gill',
    postalCode: '80624',
  },
  'gill-ma': {
    name: 'Gill',
    postalCode: '01354',
  },
  'gillespie-il': {
    name: 'Gillespie',
    postalCode: '62033',
  },
  'gillett-ar': {
    name: 'Gillett',
    postalCode: '72055',
  },
  'gillett-pa': {
    name: 'Gillett',
    postalCode: '16925',
  },
  'gillett-tx': {
    name: 'Gillett',
    postalCode: '78116',
  },
  'gillett-wi': {
    name: 'Gillett',
    postalCode: '54124',
  },
  'gillette-nj': {
    name: 'Gillette',
    postalCode: '07933',
  },
  'gillette-wy': {
    name: 'Gillette',
    postalCode: '82716',
  },
  'gillham-ar': {
    name: 'Gillham',
    postalCode: '71841',
  },
  'gilliam-la': {
    name: 'Gilliam',
    postalCode: '71029',
  },
  'gilliam-mo': {
    name: 'Gilliam',
    postalCode: '65330',
  },
  'gillsville-ga': {
    name: 'Gillsville',
    postalCode: '30543',
  },
  'gilman-ct': {
    name: 'Gilman',
    postalCode: '06336',
  },
  'gilman-ia': {
    name: 'Gilman',
    postalCode: '50106',
  },
  'gilman-il': {
    name: 'Gilman',
    postalCode: '60938',
  },
  'gilman-vt': {
    name: 'Gilman',
    postalCode: '05904',
  },
  'gilman-wi': {
    name: 'Gilman',
    postalCode: '54433',
  },
  'gilman-city-mo': {
    name: 'Gilman City',
    postalCode: '64642',
  },
  'gilmanton-nh': {
    name: 'Gilmanton',
    postalCode: '03237',
  },
  'gilmanton-iron-works-nh': {
    name: 'Gilmanton Iron Works',
    postalCode: '03837',
  },
  'gilmer-tx': {
    name: 'Gilmer',
    postalCode: '75644',
  },
  'gilmore-city-ia': {
    name: 'Gilmore City',
    postalCode: '50541',
  },
  'gilroy-ca': {
    name: 'Gilroy',
    postalCode: '95020',
  },
  'gilson-il': {
    name: 'Gilson',
    postalCode: '61436',
  },
  'gilsum-nh': {
    name: 'Gilsum',
    postalCode: '03448',
  },
  'giltner-ne': {
    name: 'Giltner',
    postalCode: '68841',
  },
  'gipsy-mo': {
    name: 'Gipsy',
    postalCode: '63750',
  },
  'girard-ga': {
    name: 'Girard',
    postalCode: '30426',
  },
  'girard-il': {
    name: 'Girard',
    postalCode: '62640',
  },
  'girard-ks': {
    name: 'Girard',
    postalCode: '66743',
  },
  'girard-oh': {
    name: 'Girard',
    postalCode: '44420',
  },
  'girard-pa': {
    name: 'Girard',
    postalCode: '16417',
  },
  'girard-tx': {
    name: 'Girard',
    postalCode: '79518',
  },
  'girardville-pa': {
    name: 'Girardville',
    postalCode: '17935',
  },
  'girdler-ky': {
    name: 'Girdler',
    postalCode: '40943',
  },
  'girdletree-md': {
    name: 'Girdletree',
    postalCode: '21829',
  },
  'girvin-tx': {
    name: 'Girvin',
    postalCode: '79740',
  },
  'given-wv': {
    name: 'Given',
    postalCode: '25245',
  },
  'gladbrook-ia': {
    name: 'Gladbrook',
    postalCode: '50635',
  },
  'glade-ks': {
    name: 'Glade',
    postalCode: '67639',
  },
  'glade-hill-va': {
    name: 'Glade Hill',
    postalCode: '24092',
  },
  'glade-park-co': {
    name: 'Glade Park',
    postalCode: '81523',
  },
  'glade-spring-va': {
    name: 'Glade Spring',
    postalCode: '24340',
  },
  'glade-valley-nc': {
    name: 'Glade Valley',
    postalCode: '28627',
  },
  'gladewater-tx': {
    name: 'Gladewater',
    postalCode: '75647',
  },
  'gladstone-il': {
    name: 'Gladstone',
    postalCode: '61437',
  },
  'gladstone-mi': {
    name: 'Gladstone',
    postalCode: '49837',
  },
  'gladstone-nd': {
    name: 'Gladstone',
    postalCode: '58630',
  },
  'gladstone-nj': {
    name: 'Gladstone',
    postalCode: '07934',
  },
  'gladstone-nm': {
    name: 'Gladstone',
    postalCode: '88422',
  },
  'gladstone-or': {
    name: 'Gladstone',
    postalCode: '97027',
  },
  'gladstone-va': {
    name: 'Gladstone',
    postalCode: '24553',
  },
  'gladwin-mi': {
    name: 'Gladwin',
    postalCode: '48624',
  },
  'gladwyne-pa': {
    name: 'Gladwyne',
    postalCode: '19035',
  },
  'glady-wv': {
    name: 'Glady',
    postalCode: '26268',
  },
  'gladys-va': {
    name: 'Gladys',
    postalCode: '24554',
  },
  'glasco-ks': {
    name: 'Glasco',
    postalCode: '67445',
  },
  'glasford-il': {
    name: 'Glasford',
    postalCode: '61533',
  },
  'glasgow-ky': {
    name: 'Glasgow',
    postalCode: '42141',
  },
  'glasgow-mo': {
    name: 'Glasgow',
    postalCode: '65254',
  },
  'glasgow-mt': {
    name: 'Glasgow',
    postalCode: '59230',
  },
  'glasgow-va': {
    name: 'Glasgow',
    postalCode: '24555',
  },
  'glassboro-nj': {
    name: 'Glassboro',
    postalCode: '08028',
  },
  'glassport-pa': {
    name: 'Glassport',
    postalCode: '15045',
  },
  'glasston-nd': {
    name: 'Glasston',
    postalCode: '58236',
  },
  'glastonbury-ct': {
    name: 'Glastonbury',
    postalCode: '06033',
  },
  'gleason-tn': {
    name: 'Gleason',
    postalCode: '38229',
  },
  'gleason-wi': {
    name: 'Gleason',
    postalCode: '54435',
  },
  'glen-ms': {
    name: 'Glen',
    postalCode: '38846',
  },
  'glen-mt': {
    name: 'Glen',
    postalCode: '59732',
  },
  'glen-nh': {
    name: 'Glen',
    postalCode: '03838',
  },
  'glen-wv': {
    name: 'Glen',
    postalCode: '25088',
  },
  'glen-allan-ms': {
    name: 'Glen Allan',
    postalCode: '38744',
  },
  'glen-allen-va': {
    name: 'Glen Allen',
    postalCode: '23059',
  },
  'glen-arbor-mi': {
    name: 'Glen Arbor',
    postalCode: '49636',
  },
  'glen-arm-md': {
    name: 'Glen Arm',
    postalCode: '21057',
  },
  'glen-aubrey-ny': {
    name: 'Glen Aubrey',
    postalCode: '13777',
  },
  'glen-burnie-md': {
    name: 'Glen Burnie',
    postalCode: '21060',
  },
  'glen-campbell-pa': {
    name: 'Glen Campbell',
    postalCode: '15742',
  },
  'glen-carbon-il': {
    name: 'Glen Carbon',
    postalCode: '62034',
  },
  'glen-cove-ny': {
    name: 'Glen Cove',
    postalCode: '11542',
  },
  'glen-dale-wv': {
    name: 'Glen Dale',
    postalCode: '26038',
  },
  'glen-daniel-wv': {
    name: 'Glen Daniel',
    postalCode: '25844',
  },
  'glen-easton-wv': {
    name: 'Glen Easton',
    postalCode: '26039',
  },
  'glen-echo-md': {
    name: 'Glen Echo',
    postalCode: '20812',
  },
  'glen-elder-ks': {
    name: 'Glen Elder',
    postalCode: '67446',
  },
  'glen-ellen-ca': {
    name: 'Glen Ellen',
    postalCode: '95442',
  },
  'glen-ellyn-il': {
    name: 'Glen Ellyn',
    postalCode: '60137',
  },
  'glen-flora-wi': {
    name: 'Glen Flora',
    postalCode: '54526',
  },
  'glen-fork-wv': {
    name: 'Glen Fork',
    postalCode: '25845',
  },
  'glen-gardner-nj': {
    name: 'Glen Gardner',
    postalCode: '08826',
  },
  'glen-haven-wi': {
    name: 'Glen Haven',
    postalCode: '53810',
  },
  'glen-head-ny': {
    name: 'Glen Head',
    postalCode: '11545',
  },
  'glen-hope-pa': {
    name: 'Glen Hope',
    postalCode: '16645',
  },
  'glen-jean-wv': {
    name: 'Glen Jean',
    postalCode: '25846',
  },
  'glen-lyn-va': {
    name: 'Glen Lyn',
    postalCode: '24093',
  },
  'glen-lyon-pa': {
    name: 'Glen Lyon',
    postalCode: '18617',
  },
  'glen-mills-pa': {
    name: 'Glen Mills',
    postalCode: '19342',
  },
  'glen-oaks-ny': {
    name: 'Glen Oaks',
    postalCode: '11004',
  },
  'glen-richey-pa': {
    name: 'Glen Richey',
    postalCode: '16837',
  },
  'glen-ridge-nj': {
    name: 'Glen Ridge',
    postalCode: '07028',
  },
  'glen-rock-nj': {
    name: 'Glen Rock',
    postalCode: '07452',
  },
  'glen-rock-pa': {
    name: 'Glen Rock',
    postalCode: '17327',
  },
  'glen-rogers-wv': {
    name: 'Glen Rogers',
    postalCode: '25848',
  },
  'glen-rose-tx': {
    name: 'Glen Rose',
    postalCode: '76043',
  },
  'glen-saint-mary-fl': {
    name: 'Glen Saint Mary',
    postalCode: '32040',
  },
  'glen-spey-ny': {
    name: 'Glen Spey',
    postalCode: '12737',
  },
  'glen-ullin-nd': {
    name: 'Glen Ullin',
    postalCode: '58631',
  },
  'glen-white-wv': {
    name: 'Glen White',
    postalCode: '25849',
  },
  'glen-wild-ny': {
    name: 'Glen Wild',
    postalCode: '12738',
  },
  'glenallen-mo': {
    name: 'Glenallen',
    postalCode: '63751',
  },
  'glenarm-il': {
    name: 'Glenarm',
    postalCode: '62536',
  },
  'glenbeulah-wi': {
    name: 'Glenbeulah',
    postalCode: '53023',
  },
  'glenbrook-nv': {
    name: 'Glenbrook',
    postalCode: '89413',
  },
  'glenburn-nd': {
    name: 'Glenburn',
    postalCode: '58740',
  },
  'glencoe-ar': {
    name: 'Glencoe',
    postalCode: '72539',
  },
  'glencoe-ca': {
    name: 'Glencoe',
    postalCode: '95232',
  },
  'glencoe-il': {
    name: 'Glencoe',
    postalCode: '60022',
  },
  'glencoe-ky': {
    name: 'Glencoe',
    postalCode: '41046',
  },
  'glencoe-mn': {
    name: 'Glencoe',
    postalCode: '55336',
  },
  'glencoe-nm': {
    name: 'Glencoe',
    postalCode: '88324',
  },
  'glencoe-ok': {
    name: 'Glencoe',
    postalCode: '74032',
  },
  'glencross-sd': {
    name: 'Glencross',
    postalCode: '57630',
  },
  'glendale-az': {
    name: 'Glendale',
    postalCode: '85301',
  },
  'glendale-ca': {
    name: 'Glendale',
    postalCode: '91201',
  },
  'glendale-ky': {
    name: 'Glendale',
    postalCode: '42740',
  },
  'glendale-or': {
    name: 'Glendale',
    postalCode: '97442',
  },
  'glendale-heights-il': {
    name: 'Glendale Heights',
    postalCode: '60139',
  },
  'glendale-springs-nc': {
    name: 'Glendale Springs',
    postalCode: '28629',
  },
  'glendive-mt': {
    name: 'Glendive',
    postalCode: '59330',
  },
  'glendo-wy': {
    name: 'Glendo',
    postalCode: '82213',
  },
  'glendora-ca': {
    name: 'Glendora',
    postalCode: '91740',
  },
  'glendora-ms': {
    name: 'Glendora',
    postalCode: '38928',
  },
  'glendora-nj': {
    name: 'Glendora',
    postalCode: '08029',
  },
  'glenelg-md': {
    name: 'Glenelg',
    postalCode: '21737',
  },
  'glenfield-nd': {
    name: 'Glenfield',
    postalCode: '58443',
  },
  'glenfield-ny': {
    name: 'Glenfield',
    postalCode: '13343',
  },
  'glenford-ny': {
    name: 'Glenford',
    postalCode: '12433',
  },
  'glenford-oh': {
    name: 'Glenford',
    postalCode: '43739',
  },
  'glengary-wv': {
    name: 'Glengary',
    postalCode: '25421',
  },
  'glenham-sd': {
    name: 'Glenham',
    postalCode: '57631',
  },
  'glenhaven-ca': {
    name: 'Glenhaven',
    postalCode: '95443',
  },
  'glenmont-ny': {
    name: 'Glenmont',
    postalCode: '12077',
  },
  'glenmont-oh': {
    name: 'Glenmont',
    postalCode: '44628',
  },
  'glenmoore-pa': {
    name: 'Glenmoore',
    postalCode: '19343',
  },
  'glenmora-la': {
    name: 'Glenmora',
    postalCode: '71433',
  },
  'glenn-ca': {
    name: 'Glenn',
    postalCode: '95943',
  },
  'glenn-dale-md': {
    name: 'Glenn Dale',
    postalCode: '20769',
  },
  'glennallen-ak': {
    name: 'Glennallen',
    postalCode: '99588',
  },
  'glennie-mi': {
    name: 'Glennie',
    postalCode: '48737',
  },
  'glenns-ferry-id': {
    name: 'Glenns Ferry',
    postalCode: '83623',
  },
  'glennville-ca': {
    name: 'Glennville',
    postalCode: '93226',
  },
  'glennville-ga': {
    name: 'Glennville',
    postalCode: '30427',
  },
  'glenolden-pa': {
    name: 'Glenolden',
    postalCode: '19036',
  },
  'glenoma-wa': {
    name: 'Glenoma',
    postalCode: '98336',
  },
  'glenpool-ok': {
    name: 'Glenpool',
    postalCode: '74033',
  },
  'glenrock-wy': {
    name: 'Glenrock',
    postalCode: '82637',
  },
  'glens-falls-ny': {
    name: 'Glens Falls',
    postalCode: '12801',
  },
  'glens-fork-ky': {
    name: 'Glens Fork',
    postalCode: '42741',
  },
  'glenshaw-pa': {
    name: 'Glenshaw',
    postalCode: '15116',
  },
  'glenside-pa': {
    name: 'Glenside',
    postalCode: '19038',
  },
  'glentana-mt': {
    name: 'Glentana',
    postalCode: '59240',
  },
  'glenview-il': {
    name: 'Glenview',
    postalCode: '60025',
  },
  'glenvil-ne': {
    name: 'Glenvil',
    postalCode: '68941',
  },
  'glenville-mn': {
    name: 'Glenville',
    postalCode: '56036',
  },
  'glenville-nc': {
    name: 'Glenville',
    postalCode: '28736',
  },
  'glenville-pa': {
    name: 'Glenville',
    postalCode: '17329',
  },
  'glenville-wv': {
    name: 'Glenville',
    postalCode: '26351',
  },
  'glenwood-al': {
    name: 'Glenwood',
    postalCode: '36034',
  },
  'glenwood-ar': {
    name: 'Glenwood',
    postalCode: '71943',
  },
  'glenwood-ga': {
    name: 'Glenwood',
    postalCode: '30428',
  },
  'glenwood-ia': {
    name: 'Glenwood',
    postalCode: '51534',
  },
  'glenwood-il': {
    name: 'Glenwood',
    postalCode: '60425',
  },
  'glenwood-in': {
    name: 'Glenwood',
    postalCode: '46133',
  },
  'glenwood-md': {
    name: 'Glenwood',
    postalCode: '21738',
  },
  'glenwood-mn': {
    name: 'Glenwood',
    postalCode: '56334',
  },
  'glenwood-mo': {
    name: 'Glenwood',
    postalCode: '63541',
  },
  'glenwood-nj': {
    name: 'Glenwood',
    postalCode: '07418',
  },
  'glenwood-nm': {
    name: 'Glenwood',
    postalCode: '88039',
  },
  'glenwood-ny': {
    name: 'Glenwood',
    postalCode: '14069',
  },
  'glenwood-wa': {
    name: 'Glenwood',
    postalCode: '98619',
  },
  'glenwood-wv': {
    name: 'Glenwood',
    postalCode: '25520',
  },
  'glenwood-city-wi': {
    name: 'Glenwood City',
    postalCode: '54013',
  },
  'glenwood-springs-co': {
    name: 'Glenwood Springs',
    postalCode: '81601',
  },
  'glidden-ia': {
    name: 'Glidden',
    postalCode: '51443',
  },
  'glidden-tx': {
    name: 'Glidden',
    postalCode: '78943',
  },
  'glidden-wi': {
    name: 'Glidden',
    postalCode: '54527',
  },
  'glide-or': {
    name: 'Glide',
    postalCode: '97443',
  },
  'globe-az': {
    name: 'Globe',
    postalCode: '85501',
  },
  'glorieta-nm': {
    name: 'Glorieta',
    postalCode: '87535',
  },
  'gloster-la': {
    name: 'Gloster',
    postalCode: '71030',
  },
  'gloster-ms': {
    name: 'Gloster',
    postalCode: '39638',
  },
  'gloucester-ma': {
    name: 'Gloucester',
    postalCode: '01930',
  },
  'gloucester-nc': {
    name: 'Gloucester',
    postalCode: '28528',
  },
  'gloucester-va': {
    name: 'Gloucester',
    postalCode: '23061',
  },
  'gloucester-city-nj': {
    name: 'Gloucester City',
    postalCode: '08030',
  },
  'gloucester-point-va': {
    name: 'Gloucester Point',
    postalCode: '23062',
  },
  'glouster-oh': {
    name: 'Glouster',
    postalCode: '45732',
  },
  'glover-vt': {
    name: 'Glover',
    postalCode: '05839',
  },
  'gloversville-ny': {
    name: 'Gloversville',
    postalCode: '12078',
  },
  'gloverville-sc': {
    name: 'Gloverville',
    postalCode: '29828',
  },
  'glyndon-mn': {
    name: 'Glyndon',
    postalCode: '56547',
  },
  'glynn-la': {
    name: 'Glynn',
    postalCode: '70736',
  },
  'gnadenhutten-oh': {
    name: 'Gnadenhutten',
    postalCode: '44629',
  },
  'gobler-mo': {
    name: 'Gobler',
    postalCode: '63849',
  },
  'gobles-mi': {
    name: 'Gobles',
    postalCode: '49055',
  },
  'goddard-ks': {
    name: 'Goddard',
    postalCode: '67052',
  },
  'godfrey-il': {
    name: 'Godfrey',
    postalCode: '62035',
  },
  'godley-tx': {
    name: 'Godley',
    postalCode: '76044',
  },
  'godwin-nc': {
    name: 'Godwin',
    postalCode: '28344',
  },
  'goetzville-mi': {
    name: 'Goetzville',
    postalCode: '49736',
  },
  'goff-ks': {
    name: 'Goff',
    postalCode: '66428',
  },
  'goffstown-nh': {
    name: 'Goffstown',
    postalCode: '03045',
  },
  'golconda-il': {
    name: 'Golconda',
    postalCode: '62938',
  },
  'golconda-nv': {
    name: 'Golconda',
    postalCode: '89414',
  },
  'gold-bar-wa': {
    name: 'Gold Bar',
    postalCode: '98251',
  },
  'gold-beach-or': {
    name: 'Gold Beach',
    postalCode: '97444',
  },
  'gold-canyon-az': {
    name: 'Gold Canyon',
    postalCode: '85118',
  },
  'gold-creek-mt': {
    name: 'Gold Creek',
    postalCode: '59733',
  },
  'gold-hill-nc': {
    name: 'Gold Hill',
    postalCode: '28071',
  },
  'gold-hill-or': {
    name: 'Gold Hill',
    postalCode: '97525',
  },
  'golden-co': {
    name: 'Golden',
    postalCode: '80401',
  },
  'golden-il': {
    name: 'Golden',
    postalCode: '62339',
  },
  'golden-mo': {
    name: 'Golden',
    postalCode: '65658',
  },
  'golden-ms': {
    name: 'Golden',
    postalCode: '38847',
  },
  'golden-city-mo': {
    name: 'Golden City',
    postalCode: '64748',
  },
  'golden-eagle-il': {
    name: 'Golden Eagle',
    postalCode: '62036',
  },
  'golden-gate-il': {
    name: 'Golden Gate',
    postalCode: '62843',
  },
  'golden-meadow-la': {
    name: 'Golden Meadow',
    postalCode: '70357',
  },
  'golden-valley-az': {
    name: 'Golden Valley',
    postalCode: '86413',
  },
  'golden-valley-nd': {
    name: 'Golden Valley',
    postalCode: '58541',
  },
  'goldendale-wa': {
    name: 'Goldendale',
    postalCode: '98620',
  },
  'goldens-bridge-ny': {
    name: 'Goldens Bridge',
    postalCode: '10526',
  },
  'goldfield-ia': {
    name: 'Goldfield',
    postalCode: '50542',
  },
  'goldfield-nv': {
    name: 'Goldfield',
    postalCode: '89013',
  },
  'goldonna-la': {
    name: 'Goldonna',
    postalCode: '71031',
  },
  'goldsboro-md': {
    name: 'Goldsboro',
    postalCode: '21636',
  },
  'goldsboro-nc': {
    name: 'Goldsboro',
    postalCode: '27530',
  },
  'goldsboro-tx': {
    name: 'Goldsboro',
    postalCode: '79519',
  },
  'goldsmith-tx': {
    name: 'Goldsmith',
    postalCode: '79741',
  },
  'goldston-nc': {
    name: 'Goldston',
    postalCode: '27252',
  },
  'goldthwaite-tx': {
    name: 'Goldthwaite',
    postalCode: '76844',
  },
  'goldvein-va': {
    name: 'Goldvein',
    postalCode: '22720',
  },
  'goleta-ca': {
    name: 'Goleta',
    postalCode: '93117',
  },
  'goliad-tx': {
    name: 'Goliad',
    postalCode: '77963',
  },
  'goltry-ok': {
    name: 'Goltry',
    postalCode: '73739',
  },
  'golva-nd': {
    name: 'Golva',
    postalCode: '58632',
  },
  'gomer-oh': {
    name: 'Gomer',
    postalCode: '45809',
  },
  'gonvick-mn': {
    name: 'Gonvick',
    postalCode: '56644',
  },
  'gonzales-ca': {
    name: 'Gonzales',
    postalCode: '93926',
  },
  'gonzales-la': {
    name: 'Gonzales',
    postalCode: '70737',
  },
  'gonzales-tx': {
    name: 'Gonzales',
    postalCode: '78629',
  },
  'goochland-va': {
    name: 'Goochland',
    postalCode: '23063',
  },
  'good-hope-ga': {
    name: 'Good Hope',
    postalCode: '30641',
  },
  'good-hope-il': {
    name: 'Good Hope',
    postalCode: '61438',
  },
  'good-thunder-mn': {
    name: 'Good Thunder',
    postalCode: '56037',
  },
  'goode-va': {
    name: 'Goode',
    postalCode: '24556',
  },
  'goodell-ia': {
    name: 'Goodell',
    postalCode: '50439',
  },
  'goodells-mi': {
    name: 'Goodells',
    postalCode: '48027',
  },
  'goodfellow-afb-tx': {
    name: 'Goodfellow AFB',
    postalCode: '76908',
  },
  'goodfield-il': {
    name: 'Goodfield',
    postalCode: '61742',
  },
  'goodhue-mn': {
    name: 'Goodhue',
    postalCode: '55027',
  },
  'gooding-id': {
    name: 'Gooding',
    postalCode: '83330',
  },
  'goodland-in': {
    name: 'Goodland',
    postalCode: '47948',
  },
  'goodland-ks': {
    name: 'Goodland',
    postalCode: '67735',
  },
  'goodland-mn': {
    name: 'Goodland',
    postalCode: '55742',
  },
  'goodlettsville-tn': {
    name: 'Goodlettsville',
    postalCode: '37072',
  },
  'goodman-mo': {
    name: 'Goodman',
    postalCode: '64843',
  },
  'goodman-ms': {
    name: 'Goodman',
    postalCode: '39079',
  },
  'goodman-wi': {
    name: 'Goodman',
    postalCode: '54125',
  },
  'goodrich-mi': {
    name: 'Goodrich',
    postalCode: '48438',
  },
  'goodrich-nd': {
    name: 'Goodrich',
    postalCode: '58444',
  },
  'goodrich-tx': {
    name: 'Goodrich',
    postalCode: '77335',
  },
  'goodridge-mn': {
    name: 'Goodridge',
    postalCode: '56725',
  },
  'goodspring-tn': {
    name: 'Goodspring',
    postalCode: '38460',
  },
  'goodview-va': {
    name: 'Goodview',
    postalCode: '24095',
  },
  'goodwater-al': {
    name: 'Goodwater',
    postalCode: '35072',
  },
  'goodwell-ok': {
    name: 'Goodwell',
    postalCode: '73939',
  },
  'goodwin-sd': {
    name: 'Goodwin',
    postalCode: '57238',
  },
  'goodyear-az': {
    name: 'Goodyear',
    postalCode: '85338',
  },
  'goose-creek-sc': {
    name: 'Goose Creek',
    postalCode: '29445',
  },
  'goose-lake-ia': {
    name: 'Goose Lake',
    postalCode: '52750',
  },
  'gordo-al': {
    name: 'Gordo',
    postalCode: '35466',
  },
  'gordon-al': {
    name: 'Gordon',
    postalCode: '36343',
  },
  'gordon-ga': {
    name: 'Gordon',
    postalCode: '31031',
  },
  'gordon-ky': {
    name: 'Gordon',
    postalCode: '41819',
  },
  'gordon-ne': {
    name: 'Gordon',
    postalCode: '69343',
  },
  'gordon-tx': {
    name: 'Gordon',
    postalCode: '76453',
  },
  'gordon-wi': {
    name: 'Gordon',
    postalCode: '54838',
  },
  'gordon-wv': {
    name: 'Gordon',
    postalCode: '25093',
  },
  'gordonsville-tn': {
    name: 'Gordonsville',
    postalCode: '38563',
  },
  'gordonsville-va': {
    name: 'Gordonsville',
    postalCode: '22942',
  },
  'gordonville-pa': {
    name: 'Gordonville',
    postalCode: '17529',
  },
  'gordonville-tx': {
    name: 'Gordonville',
    postalCode: '76245',
  },
  'gore-ok': {
    name: 'Gore',
    postalCode: '74435',
  },
  'gore-va': {
    name: 'Gore',
    postalCode: '22637',
  },
  'gore-springs-ms': {
    name: 'Gore Springs',
    postalCode: '38929',
  },
  'goree-tx': {
    name: 'Goree',
    postalCode: '76363',
  },
  'goreville-il': {
    name: 'Goreville',
    postalCode: '62939',
  },
  'gorham-il': {
    name: 'Gorham',
    postalCode: '62940',
  },
  'gorham-ks': {
    name: 'Gorham',
    postalCode: '67640',
  },
  'gorham-me': {
    name: 'Gorham',
    postalCode: '04038',
  },
  'gorham-nh': {
    name: 'Gorham',
    postalCode: '03581',
  },
  'gorin-mo': {
    name: 'Gorin',
    postalCode: '63543',
  },
  'gorman-tx': {
    name: 'Gorman',
    postalCode: '76454',
  },
  'gormania-wv': {
    name: 'Gormania',
    postalCode: '26720',
  },
  'goshen-al': {
    name: 'Goshen',
    postalCode: '36035',
  },
  'goshen-ct': {
    name: 'Goshen',
    postalCode: '06756',
  },
  'goshen-in': {
    name: 'Goshen',
    postalCode: '46526',
  },
  'goshen-ky': {
    name: 'Goshen',
    postalCode: '40026',
  },
  'goshen-ma': {
    name: 'Goshen',
    postalCode: '01032',
  },
  'goshen-nh': {
    name: 'Goshen',
    postalCode: '03752',
  },
  'goshen-ny': {
    name: 'Goshen',
    postalCode: '10924',
  },
  'goshen-oh': {
    name: 'Goshen',
    postalCode: '45122',
  },
  'goshen-va': {
    name: 'Goshen',
    postalCode: '24439',
  },
  'gosport-in': {
    name: 'Gosport',
    postalCode: '47433',
  },
  'gotebo-ok': {
    name: 'Gotebo',
    postalCode: '73041',
  },
  'gotha-fl': {
    name: 'Gotha',
    postalCode: '34734',
  },
  'gothenburg-ne': {
    name: 'Gothenburg',
    postalCode: '69138',
  },
  'gould-ar': {
    name: 'Gould',
    postalCode: '71643',
  },
  'gould-ok': {
    name: 'Gould',
    postalCode: '73544',
  },
  'gould-city-mi': {
    name: 'Gould City',
    postalCode: '49838',
  },
  'gouldbusk-tx': {
    name: 'Gouldbusk',
    postalCode: '76845',
  },
  'gouldsboro-me': {
    name: 'Gouldsboro',
    postalCode: '04607',
  },
  'gouldsboro-pa': {
    name: 'Gouldsboro',
    postalCode: '18424',
  },
  'gouverneur-ny': {
    name: 'Gouverneur',
    postalCode: '13642',
  },
  'gove-ks': {
    name: 'Gove',
    postalCode: '67736',
  },
  'government-camp-or': {
    name: 'Government Camp',
    postalCode: '97028',
  },
  'gowanda-ny': {
    name: 'Gowanda',
    postalCode: '14070',
  },
  'gowen-mi': {
    name: 'Gowen',
    postalCode: '49326',
  },
  'gower-mo': {
    name: 'Gower',
    postalCode: '64454',
  },
  'gowrie-ia': {
    name: 'Gowrie',
    postalCode: '50543',
  },
  'grabill-in': {
    name: 'Grabill',
    postalCode: '46741',
  },
  'grace-id': {
    name: 'Grace',
    postalCode: '83241',
  },
  'grace-city-nd': {
    name: 'Grace City',
    postalCode: '58445',
  },
  'gracemont-ok': {
    name: 'Gracemont',
    postalCode: '73042',
  },
  'graceville-fl': {
    name: 'Graceville',
    postalCode: '32440',
  },
  'graceville-mn': {
    name: 'Graceville',
    postalCode: '56240',
  },
  'gracey-ky': {
    name: 'Gracey',
    postalCode: '42232',
  },
  'grady-al': {
    name: 'Grady',
    postalCode: '36036',
  },
  'grady-ar': {
    name: 'Grady',
    postalCode: '71644',
  },
  'grady-nm': {
    name: 'Grady',
    postalCode: '88120',
  },
  'gradyville-ky': {
    name: 'Gradyville',
    postalCode: '42742',
  },
  'graettinger-ia': {
    name: 'Graettinger',
    postalCode: '51342',
  },
  'graff-mo': {
    name: 'Graff',
    postalCode: '65660',
  },
  'graford-tx': {
    name: 'Graford',
    postalCode: '76449',
  },
  'grafton-ia': {
    name: 'Grafton',
    postalCode: '50440',
  },
  'grafton-il': {
    name: 'Grafton',
    postalCode: '62037',
  },
  'grafton-ma': {
    name: 'Grafton',
    postalCode: '01519',
  },
  'grafton-nd': {
    name: 'Grafton',
    postalCode: '58237',
  },
  'grafton-ne': {
    name: 'Grafton',
    postalCode: '68365',
  },
  'grafton-nh': {
    name: 'Grafton',
    postalCode: '03240',
  },
  'grafton-oh': {
    name: 'Grafton',
    postalCode: '44044',
  },
  'grafton-vt': {
    name: 'Grafton',
    postalCode: '05146',
  },
  'grafton-wi': {
    name: 'Grafton',
    postalCode: '53024',
  },
  'grafton-wv': {
    name: 'Grafton',
    postalCode: '26354',
  },
  'graham-al': {
    name: 'Graham',
    postalCode: '36263',
  },
  'graham-ky': {
    name: 'Graham',
    postalCode: '42344',
  },
  'graham-mo': {
    name: 'Graham',
    postalCode: '64455',
  },
  'graham-nc': {
    name: 'Graham',
    postalCode: '27253',
  },
  'graham-ok': {
    name: 'Graham',
    postalCode: '73437',
  },
  'graham-tx': {
    name: 'Graham',
    postalCode: '76450',
  },
  'graham-wa': {
    name: 'Graham',
    postalCode: '98338',
  },
  'grahamsville-ny': {
    name: 'Grahamsville',
    postalCode: '12740',
  },
  'grain-valley-mo': {
    name: 'Grain Valley',
    postalCode: '64029',
  },
  'grainfield-ks': {
    name: 'Grainfield',
    postalCode: '67737',
  },
  'grambling-la': {
    name: 'Grambling',
    postalCode: '71245',
  },
  'gramercy-la': {
    name: 'Gramercy',
    postalCode: '70052',
  },
  'grammer-in': {
    name: 'Grammer',
    postalCode: '47236',
  },
  'grampian-pa': {
    name: 'Grampian',
    postalCode: '16838',
  },
  'granada-co': {
    name: 'Granada',
    postalCode: '81041',
  },
  'granada-mn': {
    name: 'Granada',
    postalCode: '56039',
  },
  'granada-hills-ca': {
    name: 'Granada Hills',
    postalCode: '91344',
  },
  'granbury-tx': {
    name: 'Granbury',
    postalCode: '76048',
  },
  'granby-co': {
    name: 'Granby',
    postalCode: '80446',
  },
  'granby-ct': {
    name: 'Granby',
    postalCode: '06035',
  },
  'granby-ma': {
    name: 'Granby',
    postalCode: '01033',
  },
  'granby-mo': {
    name: 'Granby',
    postalCode: '64844',
  },
  'grand-bay-al': {
    name: 'Grand Bay',
    postalCode: '36541',
  },
  'grand-blanc-mi': {
    name: 'Grand Blanc',
    postalCode: '48439',
  },
  'grand-cane-la': {
    name: 'Grand Cane',
    postalCode: '71032',
  },
  'grand-chain-il': {
    name: 'Grand Chain',
    postalCode: '62941',
  },
  'grand-chenier-la': {
    name: 'Grand Chenier',
    postalCode: '70643',
  },
  'grand-coulee-wa': {
    name: 'Grand Coulee',
    postalCode: '99133',
  },
  'grand-forks-nd': {
    name: 'Grand Forks',
    postalCode: '58201',
  },
  'grand-forks-afb-nd': {
    name: 'Grand Forks AFB',
    postalCode: '58204',
  },
  'grand-gorge-ny': {
    name: 'Grand Gorge',
    postalCode: '12434',
  },
  'grand-haven-mi': {
    name: 'Grand Haven',
    postalCode: '49417',
  },
  'grand-island-fl': {
    name: 'Grand Island',
    postalCode: '32735',
  },
  'grand-island-ne': {
    name: 'Grand Island',
    postalCode: '68801',
  },
  'grand-island-ny': {
    name: 'Grand Island',
    postalCode: '14072',
  },
  'grand-isle-la': {
    name: 'Grand Isle',
    postalCode: '70358',
  },
  'grand-isle-me': {
    name: 'Grand Isle',
    postalCode: '04746',
  },
  'grand-isle-vt': {
    name: 'Grand Isle',
    postalCode: '05458',
  },
  'grand-junction-co': {
    name: 'Grand Junction',
    postalCode: '81501',
  },
  'grand-junction-ia': {
    name: 'Grand Junction',
    postalCode: '50107',
  },
  'grand-junction-mi': {
    name: 'Grand Junction',
    postalCode: '49056',
  },
  'grand-junction-tn': {
    name: 'Grand Junction',
    postalCode: '38039',
  },
  'grand-lake-co': {
    name: 'Grand Lake',
    postalCode: '80447',
  },
  'grand-ledge-mi': {
    name: 'Grand Ledge',
    postalCode: '48837',
  },
  'grand-marais-mn': {
    name: 'Grand Marais',
    postalCode: '55604',
  },
  'grand-marsh-wi': {
    name: 'Grand Marsh',
    postalCode: '53936',
  },
  'grand-meadow-mn': {
    name: 'Grand Meadow',
    postalCode: '55936',
  },
  'grand-mound-ia': {
    name: 'Grand Mound',
    postalCode: '52751',
  },
  'grand-portage-mn': {
    name: 'Grand Portage',
    postalCode: '55605',
  },
  'grand-prairie-tx': {
    name: 'Grand Prairie',
    postalCode: '75050',
  },
  'grand-rapids-mi': {
    name: 'Grand Rapids',
    postalCode: '49503',
  },
  'grand-rapids-mn': {
    name: 'Grand Rapids',
    postalCode: '55744',
  },
  'grand-rapids-oh': {
    name: 'Grand Rapids',
    postalCode: '43522',
  },
  'grand-ridge-fl': {
    name: 'Grand Ridge',
    postalCode: '32442',
  },
  'grand-ridge-il': {
    name: 'Grand Ridge',
    postalCode: '61325',
  },
  'grand-river-ia': {
    name: 'Grand River',
    postalCode: '50108',
  },
  'grand-rivers-ky': {
    name: 'Grand Rivers',
    postalCode: '42045',
  },
  'grand-ronde-or': {
    name: 'Grand Ronde',
    postalCode: '97347',
  },
  'grand-saline-tx': {
    name: 'Grand Saline',
    postalCode: '75140',
  },
  'grand-terrace-ca': {
    name: 'Grand Terrace',
    postalCode: '92313',
  },
  'grand-tower-il': {
    name: 'Grand Tower',
    postalCode: '62942',
  },
  'grand-valley-pa': {
    name: 'Grand Valley',
    postalCode: '16420',
  },
  'grand-view-id': {
    name: 'Grand View',
    postalCode: '83624',
  },
  'grand-view-wi': {
    name: 'Grand View',
    postalCode: '54839',
  },
  'grandfield-ok': {
    name: 'Grandfield',
    postalCode: '73546',
  },
  'grandin-mo': {
    name: 'Grandin',
    postalCode: '63943',
  },
  'grandin-nd': {
    name: 'Grandin',
    postalCode: '58038',
  },
  'grandview-in': {
    name: 'Grandview',
    postalCode: '47615',
  },
  'grandview-mo': {
    name: 'Grandview',
    postalCode: '64030',
  },
  'grandview-tn': {
    name: 'Grandview',
    postalCode: '37337',
  },
  'grandview-tx': {
    name: 'Grandview',
    postalCode: '76050',
  },
  'grandview-wa': {
    name: 'Grandview',
    postalCode: '98930',
  },
  'grandville-mi': {
    name: 'Grandville',
    postalCode: '49418',
  },
  'grandy-nc': {
    name: 'Grandy',
    postalCode: '27939',
  },
  'granger-ia': {
    name: 'Granger',
    postalCode: '50109',
  },
  'granger-in': {
    name: 'Granger',
    postalCode: '46530',
  },
  'granger-mo': {
    name: 'Granger',
    postalCode: '63442',
  },
  'granger-tx': {
    name: 'Granger',
    postalCode: '76530',
  },
  'granger-wa': {
    name: 'Granger',
    postalCode: '98932',
  },
  'grangeville-id': {
    name: 'Grangeville',
    postalCode: '83530',
  },
  'granite-co': {
    name: 'Granite',
    postalCode: '81228',
  },
  'granite-ok': {
    name: 'Granite',
    postalCode: '73547',
  },
  'granite-bay-ca': {
    name: 'Granite Bay',
    postalCode: '95746',
  },
  'granite-city-il': {
    name: 'Granite City',
    postalCode: '62040',
  },
  'granite-falls-mn': {
    name: 'Granite Falls',
    postalCode: '56241',
  },
  'granite-falls-nc': {
    name: 'Granite Falls',
    postalCode: '28630',
  },
  'granite-falls-wa': {
    name: 'Granite Falls',
    postalCode: '98252',
  },
  'granite-springs-ny': {
    name: 'Granite Springs',
    postalCode: '10527',
  },
  'graniteville-sc': {
    name: 'Graniteville',
    postalCode: '29829',
  },
  'graniteville-vt': {
    name: 'Graniteville',
    postalCode: '05654',
  },
  'grannis-ar': {
    name: 'Grannis',
    postalCode: '71944',
  },
  'grant-al': {
    name: 'Grant',
    postalCode: '35747',
  },
  'grant-fl': {
    name: 'Grant',
    postalCode: '32949',
  },
  'grant-mi': {
    name: 'Grant',
    postalCode: '49327',
  },
  'grant-ne': {
    name: 'Grant',
    postalCode: '69140',
  },
  'grant-ok': {
    name: 'Grant',
    postalCode: '74738',
  },
  'grant-city-mo': {
    name: 'Grant City',
    postalCode: '64456',
  },
  'grant-park-il': {
    name: 'Grant Park',
    postalCode: '60940',
  },
  'grantham-nh': {
    name: 'Grantham',
    postalCode: '03753',
  },
  'granton-wi': {
    name: 'Granton',
    postalCode: '54436',
  },
  'grants-nm': {
    name: 'Grants',
    postalCode: '87020',
  },
  'grants-pass-or': {
    name: 'Grants Pass',
    postalCode: '97526',
  },
  'grantsboro-nc': {
    name: 'Grantsboro',
    postalCode: '28529',
  },
  'grantsburg-il': {
    name: 'Grantsburg',
    postalCode: '62943',
  },
  'grantsburg-in': {
    name: 'Grantsburg',
    postalCode: '47123',
  },
  'grantsburg-wi': {
    name: 'Grantsburg',
    postalCode: '54840',
  },
  'grantsville-md': {
    name: 'Grantsville',
    postalCode: '21536',
  },
  'grantsville-ut': {
    name: 'Grantsville',
    postalCode: '84029',
  },
  'grantsville-wv': {
    name: 'Grantsville',
    postalCode: '26147',
  },
  'grantville-ga': {
    name: 'Grantville',
    postalCode: '30220',
  },
  'grantville-ks': {
    name: 'Grantville',
    postalCode: '66429',
  },
  'grantville-pa': {
    name: 'Grantville',
    postalCode: '17028',
  },
  'granville-ia': {
    name: 'Granville',
    postalCode: '51022',
  },
  'granville-il': {
    name: 'Granville',
    postalCode: '61326',
  },
  'granville-ma': {
    name: 'Granville',
    postalCode: '01034',
  },
  'granville-nd': {
    name: 'Granville',
    postalCode: '58741',
  },
  'granville-ny': {
    name: 'Granville',
    postalCode: '12832',
  },
  'granville-oh': {
    name: 'Granville',
    postalCode: '43023',
  },
  'granville-pa': {
    name: 'Granville',
    postalCode: '17029',
  },
  'granville-tn': {
    name: 'Granville',
    postalCode: '38564',
  },
  'granville-vt': {
    name: 'Granville',
    postalCode: '05747',
  },
  'granville-summit-pa': {
    name: 'Granville Summit',
    postalCode: '16926',
  },
  'grapeland-tx': {
    name: 'Grapeland',
    postalCode: '75844',
  },
  'grapeview-wa': {
    name: 'Grapeview',
    postalCode: '98546',
  },
  'grapeville-pa': {
    name: 'Grapeville',
    postalCode: '15634',
  },
  'grapevine-ar': {
    name: 'Grapevine',
    postalCode: '72057',
  },
  'grapevine-tx': {
    name: 'Grapevine',
    postalCode: '76051',
  },
  'grasonville-md': {
    name: 'Grasonville',
    postalCode: '21638',
  },
  'grass-lake-mi': {
    name: 'Grass Lake',
    postalCode: '49240',
  },
  'grass-range-mt': {
    name: 'Grass Range',
    postalCode: '59032',
  },
  'grass-valley-ca': {
    name: 'Grass Valley',
    postalCode: '95945',
  },
  'grass-valley-or': {
    name: 'Grass Valley',
    postalCode: '97029',
  },
  'grassflat-pa': {
    name: 'Grassflat',
    postalCode: '16839',
  },
  'grasston-mn': {
    name: 'Grasston',
    postalCode: '55030',
  },
  'grassy-butte-nd': {
    name: 'Grassy Butte',
    postalCode: '58634',
  },
  'grassy-creek-nc': {
    name: 'Grassy Creek',
    postalCode: '28631',
  },
  'grassy-meadows-wv': {
    name: 'Grassy Meadows',
    postalCode: '24943',
  },
  'gratiot-wi': {
    name: 'Gratiot',
    postalCode: '53541',
  },
  'graton-ca': {
    name: 'Graton',
    postalCode: '95444',
  },
  'gratz-pa': {
    name: 'Gratz',
    postalCode: '17030',
  },
  'gravel-switch-ky': {
    name: 'Gravel Switch',
    postalCode: '40328',
  },
  'gravelly-ar': {
    name: 'Gravelly',
    postalCode: '72838',
  },
  'graves-mill-va': {
    name: 'Graves Mill',
    postalCode: '22721',
  },
  'gravette-ar': {
    name: 'Gravette',
    postalCode: '72736',
  },
  'gravity-ia': {
    name: 'Gravity',
    postalCode: '50848',
  },
  'gravois-mills-mo': {
    name: 'Gravois Mills',
    postalCode: '65037',
  },
  'grawn-mi': {
    name: 'Grawn',
    postalCode: '49637',
  },
  'gray-ga': {
    name: 'Gray',
    postalCode: '31032',
  },
  'gray-ky': {
    name: 'Gray',
    postalCode: '40734',
  },
  'gray-la': {
    name: 'Gray',
    postalCode: '70359',
  },
  'gray-me': {
    name: 'Gray',
    postalCode: '04039',
  },
  'gray-court-sc': {
    name: 'Gray Court',
    postalCode: '29645',
  },
  'gray-summit-mo': {
    name: 'Gray Summit',
    postalCode: '63039',
  },
  'grayland-wa': {
    name: 'Grayland',
    postalCode: '98547',
  },
  'grayling-mi': {
    name: 'Grayling',
    postalCode: '49738',
  },
  'graymont-il': {
    name: 'Graymont',
    postalCode: '61743',
  },
  'grays-river-wa': {
    name: 'Grays River',
    postalCode: '98621',
  },
  'grayslake-il': {
    name: 'Grayslake',
    postalCode: '60030',
  },
  'grayson-ga': {
    name: 'Grayson',
    postalCode: '30017',
  },
  'grayson-ky': {
    name: 'Grayson',
    postalCode: '41143',
  },
  'grayson-la': {
    name: 'Grayson',
    postalCode: '71435',
  },
  'graysville-al': {
    name: 'Graysville',
    postalCode: '35073',
  },
  'graysville-oh': {
    name: 'Graysville',
    postalCode: '45734',
  },
  'graysville-pa': {
    name: 'Graysville',
    postalCode: '15337',
  },
  'graysville-tn': {
    name: 'Graysville',
    postalCode: '37338',
  },
  'graytown-oh': {
    name: 'Graytown',
    postalCode: '43432',
  },
  'grayville-il': {
    name: 'Grayville',
    postalCode: '62844',
  },
  'great-barrington-ma': {
    name: 'Great Barrington',
    postalCode: '01230',
  },
  'great-bend-ks': {
    name: 'Great Bend',
    postalCode: '67530',
  },
  'great-bend-pa': {
    name: 'Great Bend',
    postalCode: '18821',
  },
  'great-cacapon-wv': {
    name: 'Great Cacapon',
    postalCode: '25422',
  },
  'great-falls-mt': {
    name: 'Great Falls',
    postalCode: '59401',
  },
  'great-falls-sc': {
    name: 'Great Falls',
    postalCode: '29055',
  },
  'great-falls-va': {
    name: 'Great Falls',
    postalCode: '22066',
  },
  'great-lakes-il': {
    name: 'Great Lakes',
    postalCode: '60088',
  },
  'great-meadows-nj': {
    name: 'Great Meadows',
    postalCode: '07838',
  },
  'great-mills-md': {
    name: 'Great Mills',
    postalCode: '20634',
  },
  'great-neck-ny': {
    name: 'Great Neck',
    postalCode: '11020',
  },
  'great-valley-ny': {
    name: 'Great Valley',
    postalCode: '14741',
  },
  'greeley-co': {
    name: 'Greeley',
    postalCode: '80631',
  },
  'greeley-ia': {
    name: 'Greeley',
    postalCode: '52050',
  },
  'greeley-ks': {
    name: 'Greeley',
    postalCode: '66033',
  },
  'greeley-ne': {
    name: 'Greeley',
    postalCode: '68842',
  },
  'greeley-pa': {
    name: 'Greeley',
    postalCode: '18425',
  },
  'greeleyville-sc': {
    name: 'Greeleyville',
    postalCode: '29056',
  },
  'green-ks': {
    name: 'Green',
    postalCode: '67447',
  },
  'green-bank-wv': {
    name: 'Green Bank',
    postalCode: '24944',
  },
  'green-bay-va': {
    name: 'Green Bay',
    postalCode: '23942',
  },
  'green-bay-wi': {
    name: 'Green Bay',
    postalCode: '54301',
  },
  'green-castle-mo': {
    name: 'Green Castle',
    postalCode: '63544',
  },
  'green-city-mo': {
    name: 'Green City',
    postalCode: '63545',
  },
  'green-cove-springs-fl': {
    name: 'Green Cove Springs',
    postalCode: '32043',
  },
  'green-forest-ar': {
    name: 'Green Forest',
    postalCode: '72638',
  },
  'green-isle-mn': {
    name: 'Green Isle',
    postalCode: '55338',
  },
  'green-lake-wi': {
    name: 'Green Lake',
    postalCode: '54941',
  },
  'green-lane-pa': {
    name: 'Green Lane',
    postalCode: '18054',
  },
  'green-mountain-nc': {
    name: 'Green Mountain',
    postalCode: '28740',
  },
  'green-pond-sc': {
    name: 'Green Pond',
    postalCode: '29446',
  },
  'green-ridge-mo': {
    name: 'Green Ridge',
    postalCode: '65332',
  },
  'green-river-ut': {
    name: 'Green River',
    postalCode: '84525',
  },
  'green-river-wy': {
    name: 'Green River',
    postalCode: '82935',
  },
  'green-road-ky': {
    name: 'Green Road',
    postalCode: '40946',
  },
  'green-sea-sc': {
    name: 'Green Sea',
    postalCode: '29545',
  },
  'green-spring-wv': {
    name: 'Green Spring',
    postalCode: '26722',
  },
  'green-springs-oh': {
    name: 'Green Springs',
    postalCode: '44836',
  },
  'green-valley-az': {
    name: 'Green Valley',
    postalCode: '85614',
  },
  'green-valley-il': {
    name: 'Green Valley',
    postalCode: '61534',
  },
  'green-valley-wi': {
    name: 'Green Valley',
    postalCode: '54127',
  },
  'green-village-nj': {
    name: 'Green Village',
    postalCode: '07935',
  },
  'greenacres-wa': {
    name: 'Greenacres',
    postalCode: '99016',
  },
  'greenback-tn': {
    name: 'Greenback',
    postalCode: '37742',
  },
  'greenbackville-va': {
    name: 'Greenbackville',
    postalCode: '23356',
  },
  'greenbank-wa': {
    name: 'Greenbank',
    postalCode: '98253',
  },
  'greenbelt-md': {
    name: 'Greenbelt',
    postalCode: '20770',
  },
  'greenbrae-ca': {
    name: 'Greenbrae',
    postalCode: '94904',
  },
  'greenbrier-ar': {
    name: 'Greenbrier',
    postalCode: '72058',
  },
  'greenbrier-tn': {
    name: 'Greenbrier',
    postalCode: '37073',
  },
  'greenbush-me': {
    name: 'Greenbush',
    postalCode: '04418',
  },
  'greenbush-mi': {
    name: 'Greenbush',
    postalCode: '48738',
  },
  'greenbush-mn': {
    name: 'Greenbush',
    postalCode: '56726',
  },
  'greenbush-va': {
    name: 'Greenbush',
    postalCode: '23357',
  },
  'greencastle-in': {
    name: 'Greencastle',
    postalCode: '46135',
  },
  'greencastle-pa': {
    name: 'Greencastle',
    postalCode: '17225',
  },
  'greencreek-id': {
    name: 'Greencreek',
    postalCode: '83533',
  },
  'greendale-wi': {
    name: 'Greendale',
    postalCode: '53129',
  },
  'greene-ia': {
    name: 'Greene',
    postalCode: '50636',
  },
  'greene-me': {
    name: 'Greene',
    postalCode: '04236',
  },
  'greene-ny': {
    name: 'Greene',
    postalCode: '13778',
  },
  'greene-ri': {
    name: 'Greene',
    postalCode: '02827',
  },
  'greeneville-tn': {
    name: 'Greeneville',
    postalCode: '37743',
  },
  'greenfield-ca': {
    name: 'Greenfield',
    postalCode: '93927',
  },
  'greenfield-ia': {
    name: 'Greenfield',
    postalCode: '50849',
  },
  'greenfield-il': {
    name: 'Greenfield',
    postalCode: '62044',
  },
  'greenfield-in': {
    name: 'Greenfield',
    postalCode: '46140',
  },
  'greenfield-ma': {
    name: 'Greenfield',
    postalCode: '01301',
  },
  'greenfield-mo': {
    name: 'Greenfield',
    postalCode: '65661',
  },
  'greenfield-nh': {
    name: 'Greenfield',
    postalCode: '03047',
  },
  'greenfield-oh': {
    name: 'Greenfield',
    postalCode: '45123',
  },
  'greenfield-ok': {
    name: 'Greenfield',
    postalCode: '73043',
  },
  'greenfield-tn': {
    name: 'Greenfield',
    postalCode: '38230',
  },
  'greenfield-center-ny': {
    name: 'Greenfield Center',
    postalCode: '12833',
  },
  'greenfield-park-ny': {
    name: 'Greenfield Park',
    postalCode: '12435',
  },
  'greenland-nh': {
    name: 'Greenland',
    postalCode: '03840',
  },
  'greenlawn-ny': {
    name: 'Greenlawn',
    postalCode: '11740',
  },
  'greenleaf-id': {
    name: 'Greenleaf',
    postalCode: '83626',
  },
  'greenleaf-ks': {
    name: 'Greenleaf',
    postalCode: '66943',
  },
  'greenleaf-wi': {
    name: 'Greenleaf',
    postalCode: '54126',
  },
  'greenport-ny': {
    name: 'Greenport',
    postalCode: '11944',
  },
  'greens-fork-in': {
    name: 'Greens Fork',
    postalCode: '47345',
  },
  'greensboro-al': {
    name: 'Greensboro',
    postalCode: '36744',
  },
  'greensboro-ga': {
    name: 'Greensboro',
    postalCode: '30642',
  },
  'greensboro-md': {
    name: 'Greensboro',
    postalCode: '21639',
  },
  'greensboro-nc': {
    name: 'Greensboro',
    postalCode: '27395',
  },
  'greensboro-pa': {
    name: 'Greensboro',
    postalCode: '15338',
  },
  'greensboro-vt': {
    name: 'Greensboro',
    postalCode: '05841',
  },
  'greensboro-bend-vt': {
    name: 'Greensboro Bend',
    postalCode: '05842',
  },
  'greensburg-in': {
    name: 'Greensburg',
    postalCode: '47240',
  },
  'greensburg-ks': {
    name: 'Greensburg',
    postalCode: '67054',
  },
  'greensburg-ky': {
    name: 'Greensburg',
    postalCode: '42743',
  },
  'greensburg-la': {
    name: 'Greensburg',
    postalCode: '70441',
  },
  'greensburg-pa': {
    name: 'Greensburg',
    postalCode: '15601',
  },
  'greentop-mo': {
    name: 'Greentop',
    postalCode: '63546',
  },
  'greentown-in': {
    name: 'Greentown',
    postalCode: '46936',
  },
  'greentown-pa': {
    name: 'Greentown',
    postalCode: '18426',
  },
  'greenup-il': {
    name: 'Greenup',
    postalCode: '62428',
  },
  'greenup-ky': {
    name: 'Greenup',
    postalCode: '41144',
  },
  'greenvale-ny': {
    name: 'Greenvale',
    postalCode: '11548',
  },
  'greenview-il': {
    name: 'Greenview',
    postalCode: '62642',
  },
  'greenville-al': {
    name: 'Greenville',
    postalCode: '36037',
  },
  'greenville-ca': {
    name: 'Greenville',
    postalCode: '95947',
  },
  'greenville-fl': {
    name: 'Greenville',
    postalCode: '32331',
  },
  'greenville-ga': {
    name: 'Greenville',
    postalCode: '30222',
  },
  'greenville-ia': {
    name: 'Greenville',
    postalCode: '51343',
  },
  'greenville-il': {
    name: 'Greenville',
    postalCode: '62246',
  },
  'greenville-in': {
    name: 'Greenville',
    postalCode: '47124',
  },
  'greenville-ky': {
    name: 'Greenville',
    postalCode: '42345',
  },
  'greenville-me': {
    name: 'Greenville',
    postalCode: '04441',
  },
  'greenville-mi': {
    name: 'Greenville',
    postalCode: '48838',
  },
  'greenville-mo': {
    name: 'Greenville',
    postalCode: '63944',
  },
  'greenville-ms': {
    name: 'Greenville',
    postalCode: '38701',
  },
  'greenville-nc': {
    name: 'Greenville',
    postalCode: '27834',
  },
  'greenville-nh': {
    name: 'Greenville',
    postalCode: '03048',
  },
  'greenville-ny': {
    name: 'Greenville',
    postalCode: '12083',
  },
  'greenville-oh': {
    name: 'Greenville',
    postalCode: '45331',
  },
  'greenville-pa': {
    name: 'Greenville',
    postalCode: '16125',
  },
  'greenville-ri': {
    name: 'Greenville',
    postalCode: '02828',
  },
  'greenville-sc': {
    name: 'Greenville',
    postalCode: '29601',
  },
  'greenville-tx': {
    name: 'Greenville',
    postalCode: '75401',
  },
  'greenville-ut': {
    name: 'Greenville',
    postalCode: '84731',
  },
  'greenville-va': {
    name: 'Greenville',
    postalCode: '24440',
  },
  'greenville-wi': {
    name: 'Greenville',
    postalCode: '54942',
  },
  'greenville-wv': {
    name: 'Greenville',
    postalCode: '24945',
  },
  'greenville-junction-me': {
    name: 'Greenville Junction',
    postalCode: '04442',
  },
  'greenway-ar': {
    name: 'Greenway',
    postalCode: '72430',
  },
  'greenway-va': {
    name: 'Greenway',
    postalCode: '22067',
  },
  'greenwell-springs-la': {
    name: 'Greenwell Springs',
    postalCode: '70739',
  },
  'greenwich-ct': {
    name: 'Greenwich',
    postalCode: '06830',
  },
  'greenwich-ks': {
    name: 'Greenwich',
    postalCode: '67055',
  },
  'greenwich-nj': {
    name: 'Greenwich',
    postalCode: '08323',
  },
  'greenwich-ny': {
    name: 'Greenwich',
    postalCode: '12834',
  },
  'greenwich-oh': {
    name: 'Greenwich',
    postalCode: '44837',
  },
  'greenwich-ut': {
    name: 'Greenwich',
    postalCode: '84732',
  },
  'greenwood-ar': {
    name: 'Greenwood',
    postalCode: '72936',
  },
  'greenwood-ca': {
    name: 'Greenwood',
    postalCode: '95635',
  },
  'greenwood-de': {
    name: 'Greenwood',
    postalCode: '19950',
  },
  'greenwood-fl': {
    name: 'Greenwood',
    postalCode: '32443',
  },
  'greenwood-in': {
    name: 'Greenwood',
    postalCode: '46142',
  },
  'greenwood-la': {
    name: 'Greenwood',
    postalCode: '71033',
  },
  'greenwood-me': {
    name: 'Greenwood',
    postalCode: '04255',
  },
  'greenwood-mo': {
    name: 'Greenwood',
    postalCode: '64034',
  },
  'greenwood-ms': {
    name: 'Greenwood',
    postalCode: '38930',
  },
  'greenwood-ne': {
    name: 'Greenwood',
    postalCode: '68366',
  },
  'greenwood-ny': {
    name: 'Greenwood',
    postalCode: '14839',
  },
  'greenwood-sc': {
    name: 'Greenwood',
    postalCode: '29646',
  },
  'greenwood-va': {
    name: 'Greenwood',
    postalCode: '22943',
  },
  'greenwood-wi': {
    name: 'Greenwood',
    postalCode: '54437',
  },
  'greenwood-lake-ny': {
    name: 'Greenwood Lake',
    postalCode: '10925',
  },
  'greenwood-springs-ms': {
    name: 'Greenwood Springs',
    postalCode: '38848',
  },
  'greer-az': {
    name: 'Greer',
    postalCode: '85927',
  },
  'greer-sc': {
    name: 'Greer',
    postalCode: '29650',
  },
  'gregory-mi': {
    name: 'Gregory',
    postalCode: '48137',
  },
  'gregory-sd': {
    name: 'Gregory',
    postalCode: '57533',
  },
  'greig-ny': {
    name: 'Greig',
    postalCode: '13345',
  },
  'grenada-ca': {
    name: 'Grenada',
    postalCode: '96038',
  },
  'grenada-ms': {
    name: 'Grenada',
    postalCode: '38901',
  },
  'grenloch-nj': {
    name: 'Grenloch',
    postalCode: '08032',
  },
  'grenola-ks': {
    name: 'Grenola',
    postalCode: '67346',
  },
  'grenora-nd': {
    name: 'Grenora',
    postalCode: '58845',
  },
  'grenville-nm': {
    name: 'Grenville',
    postalCode: '88424',
  },
  'grenville-sd': {
    name: 'Grenville',
    postalCode: '57239',
  },
  'gresham-ne': {
    name: 'Gresham',
    postalCode: '68367',
  },
  'gresham-or': {
    name: 'Gresham',
    postalCode: '97030',
  },
  'gresham-sc': {
    name: 'Gresham',
    postalCode: '29546',
  },
  'gresham-wi': {
    name: 'Gresham',
    postalCode: '54128',
  },
  'grethel-ky': {
    name: 'Grethel',
    postalCode: '41631',
  },
  'gretna-fl': {
    name: 'Gretna',
    postalCode: '32332',
  },
  'gretna-la': {
    name: 'Gretna',
    postalCode: '70053',
  },
  'gretna-ne': {
    name: 'Gretna',
    postalCode: '68028',
  },
  'gretna-va': {
    name: 'Gretna',
    postalCode: '24557',
  },
  'grey-eagle-mn': {
    name: 'Grey Eagle',
    postalCode: '56336',
  },
  'greybull-wy': {
    name: 'Greybull',
    postalCode: '82426',
  },
  'greycliff-mt': {
    name: 'Greycliff',
    postalCode: '59033',
  },
  'gridley-ca': {
    name: 'Gridley',
    postalCode: '95948',
  },
  'gridley-il': {
    name: 'Gridley',
    postalCode: '61744',
  },
  'gridley-ks': {
    name: 'Gridley',
    postalCode: '66852',
  },
  'griffin-ga': {
    name: 'Griffin',
    postalCode: '30223',
  },
  'griffin-in': {
    name: 'Griffin',
    postalCode: '47616',
  },
  'griffith-in': {
    name: 'Griffith',
    postalCode: '46319',
  },
  'griffithsville-wv': {
    name: 'Griffithsville',
    postalCode: '25521',
  },
  'griffithville-ar': {
    name: 'Griffithville',
    postalCode: '72060',
  },
  'grifton-nc': {
    name: 'Grifton',
    postalCode: '28530',
  },
  'griggsville-il': {
    name: 'Griggsville',
    postalCode: '62340',
  },
  'grimes-ia': {
    name: 'Grimes',
    postalCode: '50111',
  },
  'grimesland-nc': {
    name: 'Grimesland',
    postalCode: '27837',
  },
  'grimsley-tn': {
    name: 'Grimsley',
    postalCode: '38565',
  },
  'grindstone-pa': {
    name: 'Grindstone',
    postalCode: '15442',
  },
  'grinnell-ia': {
    name: 'Grinnell',
    postalCode: '50112',
  },
  'grinnell-ks': {
    name: 'Grinnell',
    postalCode: '67738',
  },
  'grissom-arb-in': {
    name: 'Grissom Arb',
    postalCode: '46971',
  },
  'griswold-ia': {
    name: 'Griswold',
    postalCode: '51535',
  },
  'grizzly-flats-ca': {
    name: 'Grizzly Flats',
    postalCode: '95636',
  },
  'groesbeck-tx': {
    name: 'Groesbeck',
    postalCode: '76642',
  },
  'groom-tx': {
    name: 'Groom',
    postalCode: '79039',
  },
  'grosse-ile-mi': {
    name: 'Grosse Ile',
    postalCode: '48138',
  },
  'grosse-pointe-mi': {
    name: 'Grosse Pointe',
    postalCode: '48230',
  },
  'grosse-tete-la': {
    name: 'Grosse Tete',
    postalCode: '70740',
  },
  'groton-ct': {
    name: 'Groton',
    postalCode: '06340',
  },
  'groton-ma': {
    name: 'Groton',
    postalCode: '01450',
  },
  'groton-ny': {
    name: 'Groton',
    postalCode: '13073',
  },
  'groton-sd': {
    name: 'Groton',
    postalCode: '57445',
  },
  'groton-vt': {
    name: 'Groton',
    postalCode: '05046',
  },
  'grottoes-va': {
    name: 'Grottoes',
    postalCode: '24441',
  },
  'grouse-creek-ut': {
    name: 'Grouse Creek',
    postalCode: '84313',
  },
  'grove-ok': {
    name: 'Grove',
    postalCode: '74344',
  },
  'grove-city-mn': {
    name: 'Grove City',
    postalCode: '56243',
  },
  'grove-city-oh': {
    name: 'Grove City',
    postalCode: '43123',
  },
  'grove-city-pa': {
    name: 'Grove City',
    postalCode: '16127',
  },
  'grove-hill-al': {
    name: 'Grove Hill',
    postalCode: '36451',
  },
  'groveland-ca': {
    name: 'Groveland',
    postalCode: '95321',
  },
  'groveland-fl': {
    name: 'Groveland',
    postalCode: '34736',
  },
  'groveland-il': {
    name: 'Groveland',
    postalCode: '61535',
  },
  'groveland-ma': {
    name: 'Groveland',
    postalCode: '01834',
  },
  'groveland-ny': {
    name: 'Groveland',
    postalCode: '14462',
  },
  'groveoak-al': {
    name: 'Groveoak',
    postalCode: '35975',
  },
  'groveport-oh': {
    name: 'Groveport',
    postalCode: '43125',
  },
  'grover-co': {
    name: 'Grover',
    postalCode: '80729',
  },
  'grover-nc': {
    name: 'Grover',
    postalCode: '28073',
  },
  'grover-wy': {
    name: 'Grover',
    postalCode: '83122',
  },
  'grover-beach-ca': {
    name: 'Grover Beach',
    postalCode: '93433',
  },
  'grover-hill-oh': {
    name: 'Grover Hill',
    postalCode: '45849',
  },
  'grovertown-in': {
    name: 'Grovertown',
    postalCode: '46531',
  },
  'groves-tx': {
    name: 'Groves',
    postalCode: '77619',
  },
  'grovespring-mo': {
    name: 'Grovespring',
    postalCode: '65662',
  },
  'groveton-nh': {
    name: 'Groveton',
    postalCode: '03582',
  },
  'groveton-tx': {
    name: 'Groveton',
    postalCode: '75845',
  },
  'grovetown-ga': {
    name: 'Grovetown',
    postalCode: '30813',
  },
  'grubville-mo': {
    name: 'Grubville',
    postalCode: '63041',
  },
  'gruetli-laager-tn': {
    name: 'Gruetli Laager',
    postalCode: '37339',
  },
  'grundy-va': {
    name: 'Grundy',
    postalCode: '24614',
  },
  'grundy-center-ia': {
    name: 'Grundy Center',
    postalCode: '50638',
  },
  'gruver-tx': {
    name: 'Gruver',
    postalCode: '79040',
  },
  'grygla-mn': {
    name: 'Grygla',
    postalCode: '56727',
  },
  'guadalupe-ca': {
    name: 'Guadalupe',
    postalCode: '93434',
  },
  'guadalupita-nm': {
    name: 'Guadalupita',
    postalCode: '87722',
  },
  'gualala-ca': {
    name: 'Gualala',
    postalCode: '95445',
  },
  'guanica-pr': {
    name: 'Guanica',
    postalCode: '00653',
  },
  'guayama-pr': {
    name: 'Guayama',
    postalCode: '00784',
  },
  'guayanilla-pr': {
    name: 'Guayanilla',
    postalCode: '00656',
  },
  'guaynabo-pr': {
    name: 'Guaynabo',
    postalCode: '00965',
  },
  'guerneville-ca': {
    name: 'Guerneville',
    postalCode: '95446',
  },
  'guernsey-ia': {
    name: 'Guernsey',
    postalCode: '52221',
  },
  'guernsey-wy': {
    name: 'Guernsey',
    postalCode: '82214',
  },
  'guerra-tx': {
    name: 'Guerra',
    postalCode: '78360',
  },
  'gueydan-la': {
    name: 'Gueydan',
    postalCode: '70542',
  },
  'guffey-co': {
    name: 'Guffey',
    postalCode: '80820',
  },
  'guide-rock-ne': {
    name: 'Guide Rock',
    postalCode: '68942',
  },
  'guild-tn': {
    name: 'Guild',
    postalCode: '37340',
  },
  'guilderland-ny': {
    name: 'Guilderland',
    postalCode: '12084',
  },
  'guilderland-center-ny': {
    name: 'Guilderland Center',
    postalCode: '12085',
  },
  'guildhall-vt': {
    name: 'Guildhall',
    postalCode: '05905',
  },
  'guilford-ct': {
    name: 'Guilford',
    postalCode: '06437',
  },
  'guilford-in': {
    name: 'Guilford',
    postalCode: '47022',
  },
  'guilford-me': {
    name: 'Guilford',
    postalCode: '04443',
  },
  'guilford-mo': {
    name: 'Guilford',
    postalCode: '64457',
  },
  'guilford-ny': {
    name: 'Guilford',
    postalCode: '13780',
  },
  'guin-al': {
    name: 'Guin',
    postalCode: '35563',
  },
  'guinda-ca': {
    name: 'Guinda',
    postalCode: '95637',
  },
  'guion-ar': {
    name: 'Guion',
    postalCode: '72540',
  },
  'gulf-breeze-fl': {
    name: 'Gulf Breeze',
    postalCode: '32561',
  },
  'gulf-shores-al': {
    name: 'Gulf Shores',
    postalCode: '36542',
  },
  'gulfport-ms': {
    name: 'Gulfport',
    postalCode: '39501',
  },
  'gulliver-mi': {
    name: 'Gulliver',
    postalCode: '49840',
  },
  'gully-mn': {
    name: 'Gully',
    postalCode: '56646',
  },
  'gulston-ky': {
    name: 'Gulston',
    postalCode: '40830',
  },
  'gum-spring-va': {
    name: 'Gum Spring',
    postalCode: '23065',
  },
  'gunlock-ky': {
    name: 'Gunlock',
    postalCode: '41632',
  },
  'gunlock-ut': {
    name: 'Gunlock',
    postalCode: '84733',
  },
  'gunnison-co': {
    name: 'Gunnison',
    postalCode: '81230',
  },
  'gunnison-ms': {
    name: 'Gunnison',
    postalCode: '38746',
  },
  'gunpowder-md': {
    name: 'Gunpowder',
    postalCode: '21010',
  },
  'gunter-tx': {
    name: 'Gunter',
    postalCode: '75058',
  },
  'guntersville-al': {
    name: 'Guntersville',
    postalCode: '35976',
  },
  'guntown-ms': {
    name: 'Guntown',
    postalCode: '38849',
  },
  'gurabo-pr': {
    name: 'Gurabo',
    postalCode: '00778',
  },
  'gurdon-ar': {
    name: 'Gurdon',
    postalCode: '71743',
  },
  'gurley-al': {
    name: 'Gurley',
    postalCode: '35748',
  },
  'gurley-ne': {
    name: 'Gurley',
    postalCode: '69141',
  },
  'gurnee-il': {
    name: 'Gurnee',
    postalCode: '60031',
  },
  'gustine-ca': {
    name: 'Gustine',
    postalCode: '95322',
  },
  'gustine-tx': {
    name: 'Gustine',
    postalCode: '76455',
  },
  'guston-ky': {
    name: 'Guston',
    postalCode: '40142',
  },
  'guthrie-ky': {
    name: 'Guthrie',
    postalCode: '42234',
  },
  'guthrie-ok': {
    name: 'Guthrie',
    postalCode: '73044',
  },
  'guthrie-center-ia': {
    name: 'Guthrie Center',
    postalCode: '50115',
  },
  'guttenberg-ia': {
    name: 'Guttenberg',
    postalCode: '52052',
  },
  'guy-ar': {
    name: 'Guy',
    postalCode: '72061',
  },
  'guy-tx': {
    name: 'Guy',
    postalCode: '77444',
  },
  'guymon-ok': {
    name: 'Guymon',
    postalCode: '73942',
  },
  'guys-tn': {
    name: 'Guys',
    postalCode: '38339',
  },
  'guys-mills-pa': {
    name: 'Guys Mills',
    postalCode: '16327',
  },
  'guysville-oh': {
    name: 'Guysville',
    postalCode: '45735',
  },
  'guyton-ga': {
    name: 'Guyton',
    postalCode: '31312',
  },
  'gwinn-mi': {
    name: 'Gwinn',
    postalCode: '49841',
  },
  'gwinner-nd': {
    name: 'Gwinner',
    postalCode: '58040',
  },
  'gwynedd-pa': {
    name: 'Gwynedd',
    postalCode: '19436',
  },
  'gwynn-oak-md': {
    name: 'Gwynn Oak',
    postalCode: '21207',
  },
  'gypsum-co': {
    name: 'Gypsum',
    postalCode: '81637',
  },
  'gypsum-ks': {
    name: 'Gypsum',
    postalCode: '67448',
  },
  'hacienda-heights-ca': {
    name: 'Hacienda Heights',
    postalCode: '91745',
  },
  'hackberry-az': {
    name: 'Hackberry',
    postalCode: '86411',
  },
  'hackberry-la': {
    name: 'Hackberry',
    postalCode: '70645',
  },
  'hackensack-mn': {
    name: 'Hackensack',
    postalCode: '56452',
  },
  'hackensack-nj': {
    name: 'Hackensack',
    postalCode: '07601',
  },
  'hacker-valley-wv': {
    name: 'Hacker Valley',
    postalCode: '26222',
  },
  'hackett-ar': {
    name: 'Hackett',
    postalCode: '72937',
  },
  'hackettstown-nj': {
    name: 'Hackettstown',
    postalCode: '07840',
  },
  'hackleburg-al': {
    name: 'Hackleburg',
    postalCode: '35564',
  },
  'hacksneck-va': {
    name: 'Hacksneck',
    postalCode: '23358',
  },
  'haddam-ct': {
    name: 'Haddam',
    postalCode: '06438',
  },
  'haddam-ks': {
    name: 'Haddam',
    postalCode: '66944',
  },
  'haddock-ga': {
    name: 'Haddock',
    postalCode: '31033',
  },
  'haddon-heights-nj': {
    name: 'Haddon Heights',
    postalCode: '08035',
  },
  'haddonfield-nj': {
    name: 'Haddonfield',
    postalCode: '08033',
  },
  'hadley-ma': {
    name: 'Hadley',
    postalCode: '01035',
  },
  'hadley-ny': {
    name: 'Hadley',
    postalCode: '12835',
  },
  'hadley-pa': {
    name: 'Hadley',
    postalCode: '16130',
  },
  'hagaman-ny': {
    name: 'Hagaman',
    postalCode: '12086',
  },
  'hagarville-ar': {
    name: 'Hagarville',
    postalCode: '72839',
  },
  'hagatna-gu': {
    name: 'Hagatna',
    postalCode: '96910',
  },
  'hager-city-wi': {
    name: 'Hager City',
    postalCode: '54014',
  },
  'hagerhill-ky': {
    name: 'Hagerhill',
    postalCode: '41222',
  },
  'hagerman-id': {
    name: 'Hagerman',
    postalCode: '83332',
  },
  'hagerman-nm': {
    name: 'Hagerman',
    postalCode: '88232',
  },
  'hagerstown-in': {
    name: 'Hagerstown',
    postalCode: '47346',
  },
  'hagerstown-md': {
    name: 'Hagerstown',
    postalCode: '21740',
  },
  'hague-nd': {
    name: 'Hague',
    postalCode: '58542',
  },
  'hague-ny': {
    name: 'Hague',
    postalCode: '12836',
  },
  'hague-va': {
    name: 'Hague',
    postalCode: '22469',
  },
  'hahira-ga': {
    name: 'Hahira',
    postalCode: '31632',
  },
  'hahnville-la': {
    name: 'Hahnville',
    postalCode: '70057',
  },
  'haigler-ne': {
    name: 'Haigler',
    postalCode: '69030',
  },
  'haiku-hi': {
    name: 'Haiku',
    postalCode: '96708',
  },
  'hailey-id': {
    name: 'Hailey',
    postalCode: '83333',
  },
  'haines-ak': {
    name: 'Haines',
    postalCode: '99827',
  },
  'haines-or': {
    name: 'Haines',
    postalCode: '97833',
  },
  'haines-city-fl': {
    name: 'Haines City',
    postalCode: '33844',
  },
  'hainesport-nj': {
    name: 'Hainesport',
    postalCode: '08036',
  },
  'hale-mi': {
    name: 'Hale',
    postalCode: '48739',
  },
  'hale-mo': {
    name: 'Hale',
    postalCode: '64643',
  },
  'hale-center-tx': {
    name: 'Hale Center',
    postalCode: '79041',
  },
  'haledon-nj': {
    name: 'Haledon',
    postalCode: '07508',
  },
  'haleiwa-hi': {
    name: 'Haleiwa',
    postalCode: '96712',
  },
  'hales-corners-wi': {
    name: 'Hales Corners',
    postalCode: '53130',
  },
  'halethorpe-md': {
    name: 'Halethorpe',
    postalCode: '21227',
  },
  'haleyville-al': {
    name: 'Haleyville',
    postalCode: '35565',
  },
  'half-moon-bay-ca': {
    name: 'Half Moon Bay',
    postalCode: '94019',
  },
  'half-way-mo': {
    name: 'Half Way',
    postalCode: '65663',
  },
  'halfway-or': {
    name: 'Halfway',
    postalCode: '97834',
  },
  'halifax-ma': {
    name: 'Halifax',
    postalCode: '02338',
  },
  'halifax-nc': {
    name: 'Halifax',
    postalCode: '27839',
  },
  'halifax-pa': {
    name: 'Halifax',
    postalCode: '17032',
  },
  'halifax-va': {
    name: 'Halifax',
    postalCode: '24558',
  },
  'hall-mt': {
    name: 'Hall',
    postalCode: '59837',
  },
  'hall-summit-la': {
    name: 'Hall Summit',
    postalCode: '71034',
  },
  'hallam-ne': {
    name: 'Hallam',
    postalCode: '68368',
  },
  'hallandale-fl': {
    name: 'Hallandale',
    postalCode: '33009',
  },
  'hallettsville-tx': {
    name: 'Hallettsville',
    postalCode: '77964',
  },
  'halliday-nd': {
    name: 'Halliday',
    postalCode: '58636',
  },
  'hallie-ky': {
    name: 'Hallie',
    postalCode: '41821',
  },
  'hallieford-va': {
    name: 'Hallieford',
    postalCode: '23068',
  },
  'hallock-mn': {
    name: 'Hallock',
    postalCode: '56728',
  },
  'hallowell-me': {
    name: 'Hallowell',
    postalCode: '04347',
  },
  'halls-tn': {
    name: 'Halls',
    postalCode: '38040',
  },
  'hallsboro-nc': {
    name: 'Hallsboro',
    postalCode: '28442',
  },
  'hallstead-pa': {
    name: 'Hallstead',
    postalCode: '18822',
  },
  'hallsville-mo': {
    name: 'Hallsville',
    postalCode: '65255',
  },
  'hallsville-tx': {
    name: 'Hallsville',
    postalCode: '75650',
  },
  'hallwood-va': {
    name: 'Hallwood',
    postalCode: '23359',
  },
  'halma-mn': {
    name: 'Halma',
    postalCode: '56729',
  },
  'halsey-ne': {
    name: 'Halsey',
    postalCode: '69142',
  },
  'halsey-or': {
    name: 'Halsey',
    postalCode: '97348',
  },
  'halstad-mn': {
    name: 'Halstad',
    postalCode: '56548',
  },
  'halstead-ks': {
    name: 'Halstead',
    postalCode: '67056',
  },
  'haltom-city-tx': {
    name: 'Haltom City',
    postalCode: '76117',
  },
  'hambleton-wv': {
    name: 'Hambleton',
    postalCode: '26269',
  },
  'hamburg-ar': {
    name: 'Hamburg',
    postalCode: '71646',
  },
  'hamburg-ia': {
    name: 'Hamburg',
    postalCode: '51640',
  },
  'hamburg-il': {
    name: 'Hamburg',
    postalCode: '62045',
  },
  'hamburg-mn': {
    name: 'Hamburg',
    postalCode: '55339',
  },
  'hamburg-nj': {
    name: 'Hamburg',
    postalCode: '07419',
  },
  'hamburg-ny': {
    name: 'Hamburg',
    postalCode: '14075',
  },
  'hamburg-pa': {
    name: 'Hamburg',
    postalCode: '19526',
  },
  'hamden-ct': {
    name: 'Hamden',
    postalCode: '06514',
  },
  'hamden-ny': {
    name: 'Hamden',
    postalCode: '13782',
  },
  'hamden-oh': {
    name: 'Hamden',
    postalCode: '45634',
  },
  'hamel-mn': {
    name: 'Hamel',
    postalCode: '55340',
  },
  'hamer-id': {
    name: 'Hamer',
    postalCode: '83425',
  },
  'hamer-sc': {
    name: 'Hamer',
    postalCode: '29547',
  },
  'hamersville-oh': {
    name: 'Hamersville',
    postalCode: '45130',
  },
  'hamill-sd': {
    name: 'Hamill',
    postalCode: '57534',
  },
  'hamilton-al': {
    name: 'Hamilton',
    postalCode: '35570',
  },
  'hamilton-co': {
    name: 'Hamilton',
    postalCode: '81638',
  },
  'hamilton-ga': {
    name: 'Hamilton',
    postalCode: '31811',
  },
  'hamilton-ia': {
    name: 'Hamilton',
    postalCode: '50116',
  },
  'hamilton-il': {
    name: 'Hamilton',
    postalCode: '62341',
  },
  'hamilton-in': {
    name: 'Hamilton',
    postalCode: '46742',
  },
  'hamilton-ks': {
    name: 'Hamilton',
    postalCode: '66853',
  },
  'hamilton-mi': {
    name: 'Hamilton',
    postalCode: '49419',
  },
  'hamilton-mo': {
    name: 'Hamilton',
    postalCode: '64644',
  },
  'hamilton-ms': {
    name: 'Hamilton',
    postalCode: '39746',
  },
  'hamilton-mt': {
    name: 'Hamilton',
    postalCode: '59840',
  },
  'hamilton-nc': {
    name: 'Hamilton',
    postalCode: '27840',
  },
  'hamilton-nd': {
    name: 'Hamilton',
    postalCode: '58238',
  },
  'hamilton-ny': {
    name: 'Hamilton',
    postalCode: '13346',
  },
  'hamilton-oh': {
    name: 'Hamilton',
    postalCode: '45011',
  },
  'hamilton-pa': {
    name: 'Hamilton',
    postalCode: '15744',
  },
  'hamilton-tx': {
    name: 'Hamilton',
    postalCode: '76531',
  },
  'hamilton-va': {
    name: 'Hamilton',
    postalCode: '20158',
  },
  'hamler-oh': {
    name: 'Hamler',
    postalCode: '43524',
  },
  'hamlet-in': {
    name: 'Hamlet',
    postalCode: '46532',
  },
  'hamlet-nc': {
    name: 'Hamlet',
    postalCode: '28345',
  },
  'hamlin-ia': {
    name: 'Hamlin',
    postalCode: '50117',
  },
  'hamlin-ny': {
    name: 'Hamlin',
    postalCode: '14464',
  },
  'hamlin-tx': {
    name: 'Hamlin',
    postalCode: '79520',
  },
  'hamlin-wv': {
    name: 'Hamlin',
    postalCode: '25523',
  },
  'hammett-id': {
    name: 'Hammett',
    postalCode: '83627',
  },
  'hammon-ok': {
    name: 'Hammon',
    postalCode: '73650',
  },
  'hammond-il': {
    name: 'Hammond',
    postalCode: '61929',
  },
  'hammond-in': {
    name: 'Hammond',
    postalCode: '46320',
  },
  'hammond-la': {
    name: 'Hammond',
    postalCode: '70401',
  },
  'hammond-mt': {
    name: 'Hammond',
    postalCode: '59332',
  },
  'hammond-ny': {
    name: 'Hammond',
    postalCode: '13646',
  },
  'hammond-or': {
    name: 'Hammond',
    postalCode: '97121',
  },
  'hammond-wi': {
    name: 'Hammond',
    postalCode: '54015',
  },
  'hammondsport-ny': {
    name: 'Hammondsport',
    postalCode: '14840',
  },
  'hammondsville-oh': {
    name: 'Hammondsville',
    postalCode: '43930',
  },
  'hammonton-nj': {
    name: 'Hammonton',
    postalCode: '08037',
  },
  'hampden-ma': {
    name: 'Hampden',
    postalCode: '01036',
  },
  'hampden-me': {
    name: 'Hampden',
    postalCode: '04444',
  },
  'hampden-nd': {
    name: 'Hampden',
    postalCode: '58338',
  },
  'hampshire-il': {
    name: 'Hampshire',
    postalCode: '60140',
  },
  'hampshire-tn': {
    name: 'Hampshire',
    postalCode: '38461',
  },
  'hampstead-md': {
    name: 'Hampstead',
    postalCode: '21074',
  },
  'hampstead-nc': {
    name: 'Hampstead',
    postalCode: '28443',
  },
  'hampstead-nh': {
    name: 'Hampstead',
    postalCode: '03841',
  },
  'hampton-ar': {
    name: 'Hampton',
    postalCode: '71744',
  },
  'hampton-ct': {
    name: 'Hampton',
    postalCode: '06247',
  },
  'hampton-fl': {
    name: 'Hampton',
    postalCode: '32044',
  },
  'hampton-ga': {
    name: 'Hampton',
    postalCode: '30228',
  },
  'hampton-ia': {
    name: 'Hampton',
    postalCode: '50441',
  },
  'hampton-il': {
    name: 'Hampton',
    postalCode: '61256',
  },
  'hampton-ky': {
    name: 'Hampton',
    postalCode: '42047',
  },
  'hampton-mn': {
    name: 'Hampton',
    postalCode: '55031',
  },
  'hampton-ne': {
    name: 'Hampton',
    postalCode: '68843',
  },
  'hampton-nh': {
    name: 'Hampton',
    postalCode: '03842',
  },
  'hampton-nj': {
    name: 'Hampton',
    postalCode: '08827',
  },
  'hampton-ny': {
    name: 'Hampton',
    postalCode: '12837',
  },
  'hampton-sc': {
    name: 'Hampton',
    postalCode: '29924',
  },
  'hampton-tn': {
    name: 'Hampton',
    postalCode: '37658',
  },
  'hampton-va': {
    name: 'Hampton',
    postalCode: '23661',
  },
  'hampton-bays-ny': {
    name: 'Hampton Bays',
    postalCode: '11946',
  },
  'hampton-falls-nh': {
    name: 'Hampton Falls',
    postalCode: '03844',
  },
  'hamptonville-nc': {
    name: 'Hamptonville',
    postalCode: '27020',
  },
  'hamshire-tx': {
    name: 'Hamshire',
    postalCode: '77622',
  },
  'hamtramck-mi': {
    name: 'Hamtramck',
    postalCode: '48212',
  },
  'hana-hi': {
    name: 'Hana',
    postalCode: '96713',
  },
  'hanahan-sc': {
    name: 'Hanahan',
    postalCode: '29410',
  },
  'hanceville-al': {
    name: 'Hanceville',
    postalCode: '35077',
  },
  'hancock-ia': {
    name: 'Hancock',
    postalCode: '51536',
  },
  'hancock-md': {
    name: 'Hancock',
    postalCode: '21750',
  },
  'hancock-me': {
    name: 'Hancock',
    postalCode: '04640',
  },
  'hancock-mi': {
    name: 'Hancock',
    postalCode: '49930',
  },
  'hancock-mn': {
    name: 'Hancock',
    postalCode: '56244',
  },
  'hancock-nh': {
    name: 'Hancock',
    postalCode: '03449',
  },
  'hancock-ny': {
    name: 'Hancock',
    postalCode: '13783',
  },
  'hancock-vt': {
    name: 'Hancock',
    postalCode: '05748',
  },
  'hancock-wi': {
    name: 'Hancock',
    postalCode: '54943',
  },
  'hanford-ca': {
    name: 'Hanford',
    postalCode: '93230',
  },
  'hankamer-tx': {
    name: 'Hankamer',
    postalCode: '77560',
  },
  'hankins-ny': {
    name: 'Hankins',
    postalCode: '12741',
  },
  'hankinson-nd': {
    name: 'Hankinson',
    postalCode: '58041',
  },
  'hanley-falls-mn': {
    name: 'Hanley Falls',
    postalCode: '56245',
  },
  'hanlontown-ia': {
    name: 'Hanlontown',
    postalCode: '50444',
  },
  'hanna-in': {
    name: 'Hanna',
    postalCode: '46340',
  },
  'hanna-ok': {
    name: 'Hanna',
    postalCode: '74845',
  },
  'hanna-ut': {
    name: 'Hanna',
    postalCode: '84031',
  },
  'hanna-wy': {
    name: 'Hanna',
    postalCode: '82327',
  },
  'hanna-city-il': {
    name: 'Hanna City',
    postalCode: '61536',
  },
  'hannacroix-ny': {
    name: 'Hannacroix',
    postalCode: '12087',
  },
  'hannaford-nd': {
    name: 'Hannaford',
    postalCode: '58448',
  },
  'hannah-nd': {
    name: 'Hannah',
    postalCode: '58239',
  },
  'hannibal-mo': {
    name: 'Hannibal',
    postalCode: '63401',
  },
  'hannibal-ny': {
    name: 'Hannibal',
    postalCode: '13074',
  },
  'hanover-il': {
    name: 'Hanover',
    postalCode: '61041',
  },
  'hanover-in': {
    name: 'Hanover',
    postalCode: '47243',
  },
  'hanover-ks': {
    name: 'Hanover',
    postalCode: '66945',
  },
  'hanover-ma': {
    name: 'Hanover',
    postalCode: '02339',
  },
  'hanover-md': {
    name: 'Hanover',
    postalCode: '21076',
  },
  'hanover-me': {
    name: 'Hanover',
    postalCode: '04237',
  },
  'hanover-mi': {
    name: 'Hanover',
    postalCode: '49241',
  },
  'hanover-mn': {
    name: 'Hanover',
    postalCode: '55341',
  },
  'hanover-nh': {
    name: 'Hanover',
    postalCode: '03755',
  },
  'hanover-nm': {
    name: 'Hanover',
    postalCode: '88041',
  },
  'hanover-pa': {
    name: 'Hanover',
    postalCode: '17331',
  },
  'hanover-va': {
    name: 'Hanover',
    postalCode: '23069',
  },
  'hanover-wv': {
    name: 'Hanover',
    postalCode: '24839',
  },
  'hanover-park-il': {
    name: 'Hanover Park',
    postalCode: '60133',
  },
  'hanoverton-oh': {
    name: 'Hanoverton',
    postalCode: '44423',
  },
  'hansboro-nd': {
    name: 'Hansboro',
    postalCode: '58339',
  },
  'hanscom-afb-ma': {
    name: 'Hanscom AFB',
    postalCode: '01731',
  },
  'hansen-id': {
    name: 'Hansen',
    postalCode: '83334',
  },
  'hansford-wv': {
    name: 'Hansford',
    postalCode: '25103',
  },
  'hanska-mn': {
    name: 'Hanska',
    postalCode: '56041',
  },
  'hanson-ky': {
    name: 'Hanson',
    postalCode: '42413',
  },
  'hanson-ma': {
    name: 'Hanson',
    postalCode: '02341',
  },
  'hanston-ks': {
    name: 'Hanston',
    postalCode: '67849',
  },
  'hansville-wa': {
    name: 'Hansville',
    postalCode: '98340',
  },
  'happy-ky': {
    name: 'Happy',
    postalCode: '41746',
  },
  'happy-tx': {
    name: 'Happy',
    postalCode: '79042',
  },
  'happy-camp-ca': {
    name: 'Happy Camp',
    postalCode: '96039',
  },
  'happy-jack-az': {
    name: 'Happy Jack',
    postalCode: '86024',
  },
  'happy-valley-or': {
    name: 'Happy Valley',
    postalCode: '97086',
  },
  'harbeson-de': {
    name: 'Harbeson',
    postalCode: '19951',
  },
  'harbinger-nc': {
    name: 'Harbinger',
    postalCode: '27941',
  },
  'harbor-beach-mi': {
    name: 'Harbor Beach',
    postalCode: '48441',
  },
  'harbor-city-ca': {
    name: 'Harbor City',
    postalCode: '90710',
  },
  'harbor-springs-mi': {
    name: 'Harbor Springs',
    postalCode: '49740',
  },
  'harborcreek-pa': {
    name: 'Harborcreek',
    postalCode: '16421',
  },
  'harborside-me': {
    name: 'Harborside',
    postalCode: '04642',
  },
  'harcourt-ia': {
    name: 'Harcourt',
    postalCode: '50544',
  },
  'hardaway-al': {
    name: 'Hardaway',
    postalCode: '36039',
  },
  'hardeeville-sc': {
    name: 'Hardeeville',
    postalCode: '29927',
  },
  'hardenville-mo': {
    name: 'Hardenville',
    postalCode: '65666',
  },
  'hardesty-ok': {
    name: 'Hardesty',
    postalCode: '73944',
  },
  'hardin-il': {
    name: 'Hardin',
    postalCode: '62047',
  },
  'hardin-ky': {
    name: 'Hardin',
    postalCode: '42048',
  },
  'hardin-mo': {
    name: 'Hardin',
    postalCode: '64035',
  },
  'hardin-mt': {
    name: 'Hardin',
    postalCode: '59034',
  },
  'hardinsburg-in': {
    name: 'Hardinsburg',
    postalCode: '47125',
  },
  'hardinsburg-ky': {
    name: 'Hardinsburg',
    postalCode: '40143',
  },
  'hardtner-ks': {
    name: 'Hardtner',
    postalCode: '67057',
  },
  'hardwick-mn': {
    name: 'Hardwick',
    postalCode: '56134',
  },
  'hardwick-vt': {
    name: 'Hardwick',
    postalCode: '05843',
  },
  'hardy-ar': {
    name: 'Hardy',
    postalCode: '72542',
  },
  'hardy-ia': {
    name: 'Hardy',
    postalCode: '50545',
  },
  'hardy-ky': {
    name: 'Hardy',
    postalCode: '41531',
  },
  'hardy-ne': {
    name: 'Hardy',
    postalCode: '68943',
  },
  'hardy-va': {
    name: 'Hardy',
    postalCode: '24101',
  },
  'hardyville-ky': {
    name: 'Hardyville',
    postalCode: '42746',
  },
  'hardyville-va': {
    name: 'Hardyville',
    postalCode: '23070',
  },
  'harford-pa': {
    name: 'Harford',
    postalCode: '18823',
  },
  'harker-heights-tx': {
    name: 'Harker Heights',
    postalCode: '76548',
  },
  'harkers-island-nc': {
    name: 'Harkers Island',
    postalCode: '28531',
  },
  'harlan-ia': {
    name: 'Harlan',
    postalCode: '51537',
  },
  'harlan-in': {
    name: 'Harlan',
    postalCode: '46743',
  },
  'harlan-ky': {
    name: 'Harlan',
    postalCode: '40831',
  },
  'harlem-ga': {
    name: 'Harlem',
    postalCode: '30814',
  },
  'harlem-mt': {
    name: 'Harlem',
    postalCode: '59526',
  },
  'harlem-springs-oh': {
    name: 'Harlem Springs',
    postalCode: '44631',
  },
  'harleton-tx': {
    name: 'Harleton',
    postalCode: '75651',
  },
  'harleysville-pa': {
    name: 'Harleysville',
    postalCode: '19438',
  },
  'harleyville-sc': {
    name: 'Harleyville',
    postalCode: '29448',
  },
  'harlingen-tx': {
    name: 'Harlingen',
    postalCode: '78550',
  },
  'harman-wv': {
    name: 'Harman',
    postalCode: '26270',
  },
  'harmans-md': {
    name: 'Harmans',
    postalCode: '21077',
  },
  'harmon-il': {
    name: 'Harmon',
    postalCode: '61042',
  },
  'harmony-me': {
    name: 'Harmony',
    postalCode: '04942',
  },
  'harmony-mn': {
    name: 'Harmony',
    postalCode: '55939',
  },
  'harmony-nc': {
    name: 'Harmony',
    postalCode: '28634',
  },
  'harmony-pa': {
    name: 'Harmony',
    postalCode: '16037',
  },
  'harned-ky': {
    name: 'Harned',
    postalCode: '40144',
  },
  'harold-ky': {
    name: 'Harold',
    postalCode: '41635',
  },
  'harper-ia': {
    name: 'Harper',
    postalCode: '52231',
  },
  'harper-ks': {
    name: 'Harper',
    postalCode: '67058',
  },
  'harper-or': {
    name: 'Harper',
    postalCode: '97906',
  },
  'harper-tx': {
    name: 'Harper',
    postalCode: '78631',
  },
  'harper-woods-mi': {
    name: 'Harper Woods',
    postalCode: '48225',
  },
  'harpers-ferry-ia': {
    name: 'Harpers Ferry',
    postalCode: '52146',
  },
  'harpers-ferry-wv': {
    name: 'Harpers Ferry',
    postalCode: '25425',
  },
  'harpersfield-ny': {
    name: 'Harpersfield',
    postalCode: '13786',
  },
  'harpersville-al': {
    name: 'Harpersville',
    postalCode: '35078',
  },
  'harpster-oh': {
    name: 'Harpster',
    postalCode: '43323',
  },
  'harpswell-me': {
    name: 'Harpswell',
    postalCode: '04079',
  },
  'harpursville-ny': {
    name: 'Harpursville',
    postalCode: '13787',
  },
  'harrah-ok': {
    name: 'Harrah',
    postalCode: '73045',
  },
  'harrah-wa': {
    name: 'Harrah',
    postalCode: '98933',
  },
  'harrells-nc': {
    name: 'Harrells',
    postalCode: '28444',
  },
  'harrellsville-nc': {
    name: 'Harrellsville',
    postalCode: '27942',
  },
  'harriet-ar': {
    name: 'Harriet',
    postalCode: '72639',
  },
  'harrietta-mi': {
    name: 'Harrietta',
    postalCode: '49638',
  },
  'harriman-ny': {
    name: 'Harriman',
    postalCode: '10926',
  },
  'harriman-tn': {
    name: 'Harriman',
    postalCode: '37748',
  },
  'harrington-de': {
    name: 'Harrington',
    postalCode: '19952',
  },
  'harrington-me': {
    name: 'Harrington',
    postalCode: '04643',
  },
  'harrington-wa': {
    name: 'Harrington',
    postalCode: '99134',
  },
  'harrington-park-nj': {
    name: 'Harrington Park',
    postalCode: '07640',
  },
  'harris-ia': {
    name: 'Harris',
    postalCode: '51345',
  },
  'harris-mn': {
    name: 'Harris',
    postalCode: '55032',
  },
  'harris-mo': {
    name: 'Harris',
    postalCode: '64645',
  },
  'harris-ny': {
    name: 'Harris',
    postalCode: '12742',
  },
  'harrisburg-ar': {
    name: 'Harrisburg',
    postalCode: '72432',
  },
  'harrisburg-il': {
    name: 'Harrisburg',
    postalCode: '62946',
  },
  'harrisburg-mo': {
    name: 'Harrisburg',
    postalCode: '65256',
  },
  'harrisburg-nc': {
    name: 'Harrisburg',
    postalCode: '28075',
  },
  'harrisburg-ne': {
    name: 'Harrisburg',
    postalCode: '69345',
  },
  'harrisburg-or': {
    name: 'Harrisburg',
    postalCode: '97446',
  },
  'harrisburg-pa': {
    name: 'Harrisburg',
    postalCode: '17101',
  },
  'harrisburg-sd': {
    name: 'Harrisburg',
    postalCode: '57032',
  },
  'harrison-ar': {
    name: 'Harrison',
    postalCode: '72601',
  },
  'harrison-ga': {
    name: 'Harrison',
    postalCode: '31035',
  },
  'harrison-id': {
    name: 'Harrison',
    postalCode: '83833',
  },
  'harrison-me': {
    name: 'Harrison',
    postalCode: '04040',
  },
  'harrison-mi': {
    name: 'Harrison',
    postalCode: '48625',
  },
  'harrison-ne': {
    name: 'Harrison',
    postalCode: '69346',
  },
  'harrison-nj': {
    name: 'Harrison',
    postalCode: '07029',
  },
  'harrison-ny': {
    name: 'Harrison',
    postalCode: '10528',
  },
  'harrison-oh': {
    name: 'Harrison',
    postalCode: '45030',
  },
  'harrison-sd': {
    name: 'Harrison',
    postalCode: '57344',
  },
  'harrison-tn': {
    name: 'Harrison',
    postalCode: '37341',
  },
  'harrison-city-pa': {
    name: 'Harrison City',
    postalCode: '15636',
  },
  'harrison-township-mi': {
    name: 'Harrison Township',
    postalCode: '48045',
  },
  'harrison-valley-pa': {
    name: 'Harrison Valley',
    postalCode: '16927',
  },
  'harrisonburg-la': {
    name: 'Harrisonburg',
    postalCode: '71340',
  },
  'harrisonburg-va': {
    name: 'Harrisonburg',
    postalCode: '22801',
  },
  'harrisonville-mo': {
    name: 'Harrisonville',
    postalCode: '64701',
  },
  'harrisonville-pa': {
    name: 'Harrisonville',
    postalCode: '17228',
  },
  'harrisville-mi': {
    name: 'Harrisville',
    postalCode: '48740',
  },
  'harrisville-ms': {
    name: 'Harrisville',
    postalCode: '39082',
  },
  'harrisville-nh': {
    name: 'Harrisville',
    postalCode: '03450',
  },
  'harrisville-ny': {
    name: 'Harrisville',
    postalCode: '13648',
  },
  'harrisville-pa': {
    name: 'Harrisville',
    postalCode: '16038',
  },
  'harrisville-ri': {
    name: 'Harrisville',
    postalCode: '02830',
  },
  'harrisville-wv': {
    name: 'Harrisville',
    postalCode: '26362',
  },
  'harrod-oh': {
    name: 'Harrod',
    postalCode: '45850',
  },
  'harrodsburg-ky': {
    name: 'Harrodsburg',
    postalCode: '40330',
  },
  'harrogate-tn': {
    name: 'Harrogate',
    postalCode: '37752',
  },
  'harrold-sd': {
    name: 'Harrold',
    postalCode: '57536',
  },
  'harrold-tx': {
    name: 'Harrold',
    postalCode: '76364',
  },
  'harsens-island-mi': {
    name: 'Harsens Island',
    postalCode: '48028',
  },
  'harshaw-wi': {
    name: 'Harshaw',
    postalCode: '54529',
  },
  'hart-mi': {
    name: 'Hart',
    postalCode: '49420',
  },
  'hart-tx': {
    name: 'Hart',
    postalCode: '79043',
  },
  'hartfield-va': {
    name: 'Hartfield',
    postalCode: '23071',
  },
  'hartford-al': {
    name: 'Hartford',
    postalCode: '36344',
  },
  'hartford-ar': {
    name: 'Hartford',
    postalCode: '72938',
  },
  'hartford-ct': {
    name: 'Hartford',
    postalCode: '06101',
  },
  'hartford-ia': {
    name: 'Hartford',
    postalCode: '50118',
  },
  'hartford-il': {
    name: 'Hartford',
    postalCode: '62048',
  },
  'hartford-ks': {
    name: 'Hartford',
    postalCode: '66854',
  },
  'hartford-ky': {
    name: 'Hartford',
    postalCode: '42347',
  },
  'hartford-mi': {
    name: 'Hartford',
    postalCode: '49057',
  },
  'hartford-ny': {
    name: 'Hartford',
    postalCode: '12838',
  },
  'hartford-sd': {
    name: 'Hartford',
    postalCode: '57033',
  },
  'hartford-tn': {
    name: 'Hartford',
    postalCode: '37753',
  },
  'hartford-wi': {
    name: 'Hartford',
    postalCode: '53027',
  },
  'hartford-city-in': {
    name: 'Hartford City',
    postalCode: '47348',
  },
  'hartington-ne': {
    name: 'Hartington',
    postalCode: '68739',
  },
  'hartland-me': {
    name: 'Hartland',
    postalCode: '04943',
  },
  'hartland-mi': {
    name: 'Hartland',
    postalCode: '48353',
  },
  'hartland-mn': {
    name: 'Hartland',
    postalCode: '56042',
  },
  'hartland-vt': {
    name: 'Hartland',
    postalCode: '05048',
  },
  'hartland-wi': {
    name: 'Hartland',
    postalCode: '53029',
  },
  'hartley-ia': {
    name: 'Hartley',
    postalCode: '51346',
  },
  'hartley-tx': {
    name: 'Hartley',
    postalCode: '79044',
  },
  'hartline-wa': {
    name: 'Hartline',
    postalCode: '99135',
  },
  'hartly-de': {
    name: 'Hartly',
    postalCode: '19953',
  },
  'hartman-ar': {
    name: 'Hartman',
    postalCode: '72840',
  },
  'harts-wv': {
    name: 'Harts',
    postalCode: '25524',
  },
  'hartsburg-il': {
    name: 'Hartsburg',
    postalCode: '62643',
  },
  'hartsburg-mo': {
    name: 'Hartsburg',
    postalCode: '65039',
  },
  'hartsdale-ny': {
    name: 'Hartsdale',
    postalCode: '10530',
  },
  'hartsel-co': {
    name: 'Hartsel',
    postalCode: '80449',
  },
  'hartselle-al': {
    name: 'Hartselle',
    postalCode: '35640',
  },
  'hartsfield-ga': {
    name: 'Hartsfield',
    postalCode: '31756',
  },
  'hartshorn-mo': {
    name: 'Hartshorn',
    postalCode: '65479',
  },
  'hartshorne-ok': {
    name: 'Hartshorne',
    postalCode: '74547',
  },
  'hartstown-pa': {
    name: 'Hartstown',
    postalCode: '16131',
  },
  'hartsville-in': {
    name: 'Hartsville',
    postalCode: '47244',
  },
  'hartsville-sc': {
    name: 'Hartsville',
    postalCode: '29550',
  },
  'hartsville-tn': {
    name: 'Hartsville',
    postalCode: '37074',
  },
  'hartville-mo': {
    name: 'Hartville',
    postalCode: '65667',
  },
  'hartville-oh': {
    name: 'Hartville',
    postalCode: '44632',
  },
  'hartville-wy': {
    name: 'Hartville',
    postalCode: '82215',
  },
  'hartwell-ga': {
    name: 'Hartwell',
    postalCode: '30643',
  },
  'hartwick-ia': {
    name: 'Hartwick',
    postalCode: '52232',
  },
  'hartwick-ny': {
    name: 'Hartwick',
    postalCode: '13348',
  },
  'harvard-id': {
    name: 'Harvard',
    postalCode: '83834',
  },
  'harvard-il': {
    name: 'Harvard',
    postalCode: '60033',
  },
  'harvard-ma': {
    name: 'Harvard',
    postalCode: '01451',
  },
  'harvard-ne': {
    name: 'Harvard',
    postalCode: '68944',
  },
  'harvel-il': {
    name: 'Harvel',
    postalCode: '62538',
  },
  'harvest-al': {
    name: 'Harvest',
    postalCode: '35749',
  },
  'harvey-ar': {
    name: 'Harvey',
    postalCode: '72841',
  },
  'harvey-ia': {
    name: 'Harvey',
    postalCode: '50119',
  },
  'harvey-il': {
    name: 'Harvey',
    postalCode: '60426',
  },
  'harvey-la': {
    name: 'Harvey',
    postalCode: '70058',
  },
  'harvey-nd': {
    name: 'Harvey',
    postalCode: '58341',
  },
  'harveys-lake-pa': {
    name: 'Harveys Lake',
    postalCode: '18618',
  },
  'harveyville-ks': {
    name: 'Harveyville',
    postalCode: '66431',
  },
  'harviell-mo': {
    name: 'Harviell',
    postalCode: '63945',
  },
  'harwich-ma': {
    name: 'Harwich',
    postalCode: '02645',
  },
  'harwich-port-ma': {
    name: 'Harwich Port',
    postalCode: '02646',
  },
  'harwick-pa': {
    name: 'Harwick',
    postalCode: '15049',
  },
  'harwinton-ct': {
    name: 'Harwinton',
    postalCode: '06791',
  },
  'harwood-md': {
    name: 'Harwood',
    postalCode: '20776',
  },
  'harwood-mo': {
    name: 'Harwood',
    postalCode: '64750',
  },
  'harwood-nd': {
    name: 'Harwood',
    postalCode: '58042',
  },
  'harwood-tx': {
    name: 'Harwood',
    postalCode: '78632',
  },
  'harwood-heights-il': {
    name: 'Harwood Heights',
    postalCode: '60706',
  },
  'hasbrouck-heights-nj': {
    name: 'Hasbrouck Heights',
    postalCode: '07604',
  },
  'haskell-nj': {
    name: 'Haskell',
    postalCode: '07420',
  },
  'haskell-ok': {
    name: 'Haskell',
    postalCode: '74436',
  },
  'haskell-tx': {
    name: 'Haskell',
    postalCode: '79521',
  },
  'haskins-oh': {
    name: 'Haskins',
    postalCode: '43525',
  },
  'haslet-tx': {
    name: 'Haslet',
    postalCode: '76052',
  },
  'haslett-mi': {
    name: 'Haslett',
    postalCode: '48840',
  },
  'hastings-fl': {
    name: 'Hastings',
    postalCode: '32145',
  },
  'hastings-ia': {
    name: 'Hastings',
    postalCode: '51540',
  },
  'hastings-mi': {
    name: 'Hastings',
    postalCode: '49058',
  },
  'hastings-mn': {
    name: 'Hastings',
    postalCode: '55033',
  },
  'hastings-ne': {
    name: 'Hastings',
    postalCode: '68901',
  },
  'hastings-ny': {
    name: 'Hastings',
    postalCode: '13076',
  },
  'hastings-ok': {
    name: 'Hastings',
    postalCode: '73548',
  },
  'hastings-pa': {
    name: 'Hastings',
    postalCode: '16646',
  },
  'hastings-on-hudson-ny': {
    name: 'Hastings On Hudson',
    postalCode: '10706',
  },
  'hasty-ar': {
    name: 'Hasty',
    postalCode: '72640',
  },
  'hasty-co': {
    name: 'Hasty',
    postalCode: '81044',
  },
  'haswell-co': {
    name: 'Haswell',
    postalCode: '81045',
  },
  'hat-creek-ca': {
    name: 'Hat Creek',
    postalCode: '96040',
  },
  'hatboro-pa': {
    name: 'Hatboro',
    postalCode: '19040',
  },
  'hatch-nm': {
    name: 'Hatch',
    postalCode: '87937',
  },
  'hatchechubbee-al': {
    name: 'Hatchechubbee',
    postalCode: '36858',
  },
  'hatfield-ar': {
    name: 'Hatfield',
    postalCode: '71945',
  },
  'hatfield-ma': {
    name: 'Hatfield',
    postalCode: '01038',
  },
  'hatfield-mo': {
    name: 'Hatfield',
    postalCode: '64458',
  },
  'hatfield-pa': {
    name: 'Hatfield',
    postalCode: '19440',
  },
  'hathaway-mt': {
    name: 'Hathaway',
    postalCode: '59333',
  },
  'hatillo-pr': {
    name: 'Hatillo',
    postalCode: '00659',
  },
  'hatley-wi': {
    name: 'Hatley',
    postalCode: '54440',
  },
  'hattiesburg-ms': {
    name: 'Hattiesburg',
    postalCode: '39401',
  },
  'hattieville-ar': {
    name: 'Hattieville',
    postalCode: '72063',
  },
  'hatton-nd': {
    name: 'Hatton',
    postalCode: '58240',
  },
  'haubstadt-in': {
    name: 'Haubstadt',
    postalCode: '47639',
  },
  'haugen-wi': {
    name: 'Haugen',
    postalCode: '54841',
  },
  'haughton-la': {
    name: 'Haughton',
    postalCode: '71037',
  },
  'hauppauge-ny': {
    name: 'Hauppauge',
    postalCode: '11788',
  },
  'hauula-hi': {
    name: 'Hauula',
    postalCode: '96717',
  },
  'havana-ar': {
    name: 'Havana',
    postalCode: '72842',
  },
  'havana-fl': {
    name: 'Havana',
    postalCode: '32333',
  },
  'havana-il': {
    name: 'Havana',
    postalCode: '62644',
  },
  'havana-ks': {
    name: 'Havana',
    postalCode: '67347',
  },
  'havana-nd': {
    name: 'Havana',
    postalCode: '58043',
  },
  'havelock-ia': {
    name: 'Havelock',
    postalCode: '50546',
  },
  'havelock-nc': {
    name: 'Havelock',
    postalCode: '28532',
  },
  'haven-ks': {
    name: 'Haven',
    postalCode: '67543',
  },
  'havensville-ks': {
    name: 'Havensville',
    postalCode: '66432',
  },
  'haverford-pa': {
    name: 'Haverford',
    postalCode: '19041',
  },
  'haverhill-ia': {
    name: 'Haverhill',
    postalCode: '50120',
  },
  'haverhill-ma': {
    name: 'Haverhill',
    postalCode: '01830',
  },
  'haverhill-nh': {
    name: 'Haverhill',
    postalCode: '03765',
  },
  'haverstraw-ny': {
    name: 'Haverstraw',
    postalCode: '10927',
  },
  'havertown-pa': {
    name: 'Havertown',
    postalCode: '19083',
  },
  'haviland-ks': {
    name: 'Haviland',
    postalCode: '67059',
  },
  'haviland-oh': {
    name: 'Haviland',
    postalCode: '45851',
  },
  'havre-mt': {
    name: 'Havre',
    postalCode: '59501',
  },
  'havre-de-grace-md': {
    name: 'Havre De Grace',
    postalCode: '21078',
  },
  'haw-river-nc': {
    name: 'Haw River',
    postalCode: '27258',
  },
  'hawaiian-gardens-ca': {
    name: 'Hawaiian Gardens',
    postalCode: '90716',
  },
  'hawarden-ia': {
    name: 'Hawarden',
    postalCode: '51023',
  },
  'hawesville-ky': {
    name: 'Hawesville',
    postalCode: '42348',
  },
  'hawk-point-mo': {
    name: 'Hawk Point',
    postalCode: '63349',
  },
  'hawk-run-pa': {
    name: 'Hawk Run',
    postalCode: '16840',
  },
  'hawk-springs-wy': {
    name: 'Hawk Springs',
    postalCode: '82217',
  },
  'hawkeye-ia': {
    name: 'Hawkeye',
    postalCode: '52147',
  },
  'hawkins-tx': {
    name: 'Hawkins',
    postalCode: '75765',
  },
  'hawkins-wi': {
    name: 'Hawkins',
    postalCode: '54530',
  },
  'hawkinsville-ga': {
    name: 'Hawkinsville',
    postalCode: '31036',
  },
  'hawks-mi': {
    name: 'Hawks',
    postalCode: '49743',
  },
  'hawley-mn': {
    name: 'Hawley',
    postalCode: '56549',
  },
  'hawley-pa': {
    name: 'Hawley',
    postalCode: '18428',
  },
  'hawley-tx': {
    name: 'Hawley',
    postalCode: '79525',
  },
  'haworth-nj': {
    name: 'Haworth',
    postalCode: '07641',
  },
  'haworth-ok': {
    name: 'Haworth',
    postalCode: '74740',
  },
  'hawthorne-ca': {
    name: 'Hawthorne',
    postalCode: '90250',
  },
  'hawthorne-fl': {
    name: 'Hawthorne',
    postalCode: '32640',
  },
  'hawthorne-nj': {
    name: 'Hawthorne',
    postalCode: '07506',
  },
  'hawthorne-nv': {
    name: 'Hawthorne',
    postalCode: '89415',
  },
  'hawthorne-ny': {
    name: 'Hawthorne',
    postalCode: '10532',
  },
  'haxtun-co': {
    name: 'Haxtun',
    postalCode: '80731',
  },
  'hay-wa': {
    name: 'Hay',
    postalCode: '99136',
  },
  'hay-springs-ne': {
    name: 'Hay Springs',
    postalCode: '69347',
  },
  'hayden-al': {
    name: 'Hayden',
    postalCode: '35079',
  },
  'hayden-co': {
    name: 'Hayden',
    postalCode: '81639',
  },
  'hayden-id': {
    name: 'Hayden',
    postalCode: '83835',
  },
  'haydenville-ma': {
    name: 'Haydenville',
    postalCode: '01039',
  },
  'hayes-sd': {
    name: 'Hayes',
    postalCode: '57537',
  },
  'hayes-va': {
    name: 'Hayes',
    postalCode: '23072',
  },
  'hayes-center-ne': {
    name: 'Hayes Center',
    postalCode: '69032',
  },
  'hayesville-ia': {
    name: 'Hayesville',
    postalCode: '52562',
  },
  'hayesville-nc': {
    name: 'Hayesville',
    postalCode: '28904',
  },
  'hayfield-mn': {
    name: 'Hayfield',
    postalCode: '55940',
  },
  'hayfork-ca': {
    name: 'Hayfork',
    postalCode: '96041',
  },
  'haymarket-va': {
    name: 'Haymarket',
    postalCode: '20169',
  },
  'haynesville-la': {
    name: 'Haynesville',
    postalCode: '71038',
  },
  'hayneville-al': {
    name: 'Hayneville',
    postalCode: '36040',
  },
  'hays-ks': {
    name: 'Hays',
    postalCode: '67601',
  },
  'hays-nc': {
    name: 'Hays',
    postalCode: '28635',
  },
  'haysi-va': {
    name: 'Haysi',
    postalCode: '24256',
  },
  'haysville-ks': {
    name: 'Haysville',
    postalCode: '67060',
  },
  'hayti-mo': {
    name: 'Hayti',
    postalCode: '63851',
  },
  'hayti-sd': {
    name: 'Hayti',
    postalCode: '57241',
  },
  'hayward-ca': {
    name: 'Hayward',
    postalCode: '94541',
  },
  'hayward-mn': {
    name: 'Hayward',
    postalCode: '56043',
  },
  'hayward-wi': {
    name: 'Hayward',
    postalCode: '54843',
  },
  'haywood-va': {
    name: 'Haywood',
    postalCode: '22722',
  },
  'hazard-ky': {
    name: 'Hazard',
    postalCode: '41701',
  },
  'hazard-ne': {
    name: 'Hazard',
    postalCode: '68844',
  },
  'hazel-ky': {
    name: 'Hazel',
    postalCode: '42049',
  },
  'hazel-sd': {
    name: 'Hazel',
    postalCode: '57242',
  },
  'hazel-crest-il': {
    name: 'Hazel Crest',
    postalCode: '60429',
  },
  'hazel-green-al': {
    name: 'Hazel Green',
    postalCode: '35750',
  },
  'hazel-green-ky': {
    name: 'Hazel Green',
    postalCode: '41332',
  },
  'hazel-green-wi': {
    name: 'Hazel Green',
    postalCode: '53811',
  },
  'hazel-park-mi': {
    name: 'Hazel Park',
    postalCode: '48030',
  },
  'hazelhurst-wi': {
    name: 'Hazelhurst',
    postalCode: '54531',
  },
  'hazelton-id': {
    name: 'Hazelton',
    postalCode: '83335',
  },
  'hazelton-ks': {
    name: 'Hazelton',
    postalCode: '67061',
  },
  'hazelton-nd': {
    name: 'Hazelton',
    postalCode: '58544',
  },
  'hazelwood-mo': {
    name: 'Hazelwood',
    postalCode: '63042',
  },
  'hazen-ar': {
    name: 'Hazen',
    postalCode: '72064',
  },
  'hazen-nd': {
    name: 'Hazen',
    postalCode: '58545',
  },
  'hazlehurst-ga': {
    name: 'Hazlehurst',
    postalCode: '31539',
  },
  'hazlehurst-ms': {
    name: 'Hazlehurst',
    postalCode: '39083',
  },
  'hazlet-nj': {
    name: 'Hazlet',
    postalCode: '07730',
  },
  'hazleton-ia': {
    name: 'Hazleton',
    postalCode: '50641',
  },
  'hazleton-in': {
    name: 'Hazleton',
    postalCode: '47640',
  },
  'hazleton-pa': {
    name: 'Hazleton',
    postalCode: '18201',
  },
  'head-waters-va': {
    name: 'Head Waters',
    postalCode: '24442',
  },
  'headland-al': {
    name: 'Headland',
    postalCode: '36345',
  },
  'headrick-ok': {
    name: 'Headrick',
    postalCode: '73549',
  },
  'healdsburg-ca': {
    name: 'Healdsburg',
    postalCode: '95448',
  },
  'healdton-ok': {
    name: 'Healdton',
    postalCode: '73438',
  },
  'healy-ak': {
    name: 'Healy',
    postalCode: '99743',
  },
  'healy-ks': {
    name: 'Healy',
    postalCode: '67850',
  },
  'hearne-tx': {
    name: 'Hearne',
    postalCode: '77859',
  },
  'heartwell-ne': {
    name: 'Heartwell',
    postalCode: '68945',
  },
  'heaters-wv': {
    name: 'Heaters',
    postalCode: '26627',
  },
  'heath-ma': {
    name: 'Heath',
    postalCode: '01346',
  },
  'heath-oh': {
    name: 'Heath',
    postalCode: '43056',
  },
  'heath-springs-sc': {
    name: 'Heath Springs',
    postalCode: '29058',
  },
  'heathsville-va': {
    name: 'Heathsville',
    postalCode: '22473',
  },
  'heavener-ok': {
    name: 'Heavener',
    postalCode: '74937',
  },
  'hebbronville-tx': {
    name: 'Hebbronville',
    postalCode: '78361',
  },
  'heber-az': {
    name: 'Heber',
    postalCode: '85928',
  },
  'heber-ca': {
    name: 'Heber',
    postalCode: '92249',
  },
  'heber-city-ut': {
    name: 'Heber City',
    postalCode: '84032',
  },
  'heber-springs-ar': {
    name: 'Heber Springs',
    postalCode: '72543',
  },
  'hebo-or': {
    name: 'Hebo',
    postalCode: '97122',
  },
  'hebron-ct': {
    name: 'Hebron',
    postalCode: '06248',
  },
  'hebron-il': {
    name: 'Hebron',
    postalCode: '60034',
  },
  'hebron-in': {
    name: 'Hebron',
    postalCode: '46341',
  },
  'hebron-ky': {
    name: 'Hebron',
    postalCode: '41048',
  },
  'hebron-md': {
    name: 'Hebron',
    postalCode: '21830',
  },
  'hebron-me': {
    name: 'Hebron',
    postalCode: '04238',
  },
  'hebron-nd': {
    name: 'Hebron',
    postalCode: '58638',
  },
  'hebron-ne': {
    name: 'Hebron',
    postalCode: '68370',
  },
  'hebron-nh': {
    name: 'Hebron',
    postalCode: '03241',
  },
  'hebron-oh': {
    name: 'Hebron',
    postalCode: '43025',
  },
  'hecla-sd': {
    name: 'Hecla',
    postalCode: '57446',
  },
  'hector-ar': {
    name: 'Hector',
    postalCode: '72843',
  },
  'hector-mn': {
    name: 'Hector',
    postalCode: '55342',
  },
  'hector-ny': {
    name: 'Hector',
    postalCode: '14841',
  },
  'hedgesville-wv': {
    name: 'Hedgesville',
    postalCode: '25427',
  },
  'hedley-tx': {
    name: 'Hedley',
    postalCode: '79237',
  },
  'hedrick-ia': {
    name: 'Hedrick',
    postalCode: '52563',
  },
  'heflin-al': {
    name: 'Heflin',
    postalCode: '36264',
  },
  'heflin-la': {
    name: 'Heflin',
    postalCode: '71039',
  },
  'hegins-pa': {
    name: 'Hegins',
    postalCode: '17938',
  },
  'heidelberg-ms': {
    name: 'Heidelberg',
    postalCode: '39439',
  },
  'heidrick-ky': {
    name: 'Heidrick',
    postalCode: '40949',
  },
  'heiskell-tn': {
    name: 'Heiskell',
    postalCode: '37754',
  },
  'heislerville-nj': {
    name: 'Heislerville',
    postalCode: '08324',
  },
  'helen-ga': {
    name: 'Helen',
    postalCode: '30545',
  },
  'helena-al': {
    name: 'Helena',
    postalCode: '35080',
  },
  'helena-ar': {
    name: 'Helena',
    postalCode: '72342',
  },
  'helena-mo': {
    name: 'Helena',
    postalCode: '64459',
  },
  'helena-mt': {
    name: 'Helena',
    postalCode: '59601',
  },
  'helena-oh': {
    name: 'Helena',
    postalCode: '43435',
  },
  'helena-ok': {
    name: 'Helena',
    postalCode: '73741',
  },
  'helendale-ca': {
    name: 'Helendale',
    postalCode: '92342',
  },
  'helenville-wi': {
    name: 'Helenville',
    postalCode: '53137',
  },
  'helenwood-tn': {
    name: 'Helenwood',
    postalCode: '37755',
  },
  'helix-or': {
    name: 'Helix',
    postalCode: '97835',
  },
  'hellertown-pa': {
    name: 'Hellertown',
    postalCode: '18055',
  },
  'hellier-ky': {
    name: 'Hellier',
    postalCode: '41534',
  },
  'helmetta-nj': {
    name: 'Helmetta',
    postalCode: '08828',
  },
  'helmville-mt': {
    name: 'Helmville',
    postalCode: '59843',
  },
  'helotes-tx': {
    name: 'Helotes',
    postalCode: '78023',
  },
  'helper-ut': {
    name: 'Helper',
    postalCode: '84526',
  },
  'helton-ky': {
    name: 'Helton',
    postalCode: '40840',
  },
  'heltonville-in': {
    name: 'Heltonville',
    postalCode: '47436',
  },
  'helvetia-wv': {
    name: 'Helvetia',
    postalCode: '26224',
  },
  'hemet-ca': {
    name: 'Hemet',
    postalCode: '92543',
  },
  'hemingford-ne': {
    name: 'Hemingford',
    postalCode: '69348',
  },
  'hemingway-sc': {
    name: 'Hemingway',
    postalCode: '29554',
  },
  'hemlock-mi': {
    name: 'Hemlock',
    postalCode: '48626',
  },
  'hemlock-ny': {
    name: 'Hemlock',
    postalCode: '14466',
  },
  'hemphill-tx': {
    name: 'Hemphill',
    postalCode: '75948',
  },
  'hempstead-ny': {
    name: 'Hempstead',
    postalCode: '11550',
  },
  'hempstead-tx': {
    name: 'Hempstead',
    postalCode: '77445',
  },
  'henagar-al': {
    name: 'Henagar',
    postalCode: '35978',
  },
  'henderson-ar': {
    name: 'Henderson',
    postalCode: '72544',
  },
  'henderson-co': {
    name: 'Henderson',
    postalCode: '80640',
  },
  'henderson-ia': {
    name: 'Henderson',
    postalCode: '51541',
  },
  'henderson-ky': {
    name: 'Henderson',
    postalCode: '42420',
  },
  'henderson-md': {
    name: 'Henderson',
    postalCode: '21640',
  },
  'henderson-mi': {
    name: 'Henderson',
    postalCode: '48841',
  },
  'henderson-mn': {
    name: 'Henderson',
    postalCode: '56044',
  },
  'henderson-nc': {
    name: 'Henderson',
    postalCode: '27536',
  },
  'henderson-ne': {
    name: 'Henderson',
    postalCode: '68371',
  },
  'henderson-nv': {
    name: 'Henderson',
    postalCode: '89002',
  },
  'henderson-ny': {
    name: 'Henderson',
    postalCode: '13650',
  },
  'henderson-tn': {
    name: 'Henderson',
    postalCode: '38340',
  },
  'henderson-tx': {
    name: 'Henderson',
    postalCode: '75652',
  },
  'henderson-wv': {
    name: 'Henderson',
    postalCode: '25106',
  },
  'hendersonville-nc': {
    name: 'Hendersonville',
    postalCode: '28739',
  },
  'hendersonville-tn': {
    name: 'Hendersonville',
    postalCode: '37075',
  },
  'hendley-ne': {
    name: 'Hendley',
    postalCode: '68946',
  },
  'hendricks-mn': {
    name: 'Hendricks',
    postalCode: '56136',
  },
  'hendricks-wv': {
    name: 'Hendricks',
    postalCode: '26271',
  },
  'hendrix-ok': {
    name: 'Hendrix',
    postalCode: '74741',
  },
  'hendrum-mn': {
    name: 'Hendrum',
    postalCode: '56550',
  },
  'henley-mo': {
    name: 'Henley',
    postalCode: '65040',
  },
  'hennepin-il': {
    name: 'Hennepin',
    postalCode: '61327',
  },
  'hennepin-ok': {
    name: 'Hennepin',
    postalCode: '73444',
  },
  'hennessey-ok': {
    name: 'Hennessey',
    postalCode: '73742',
  },
  'henniker-nh': {
    name: 'Henniker',
    postalCode: '03242',
  },
  'henning-mn': {
    name: 'Henning',
    postalCode: '56551',
  },
  'henning-tn': {
    name: 'Henning',
    postalCode: '38041',
  },
  'henrico-nc': {
    name: 'Henrico',
    postalCode: '27842',
  },
  'henrico-va': {
    name: 'Henrico',
    postalCode: '23075',
  },
  'henrietta-mo': {
    name: 'Henrietta',
    postalCode: '64036',
  },
  'henrietta-ny': {
    name: 'Henrietta',
    postalCode: '14467',
  },
  'henrietta-tx': {
    name: 'Henrietta',
    postalCode: '76365',
  },
  'henriette-mn': {
    name: 'Henriette',
    postalCode: '55036',
  },
  'henry-il': {
    name: 'Henry',
    postalCode: '61537',
  },
  'henry-sd': {
    name: 'Henry',
    postalCode: '57243',
  },
  'henry-tn': {
    name: 'Henry',
    postalCode: '38231',
  },
  'henry-va': {
    name: 'Henry',
    postalCode: '24102',
  },
  'henryetta-ok': {
    name: 'Henryetta',
    postalCode: '74437',
  },
  'henryville-in': {
    name: 'Henryville',
    postalCode: '47126',
  },
  'henryville-pa': {
    name: 'Henryville',
    postalCode: '18332',
  },
  'hensel-nd': {
    name: 'Hensel',
    postalCode: '58241',
  },
  'hensley-ar': {
    name: 'Hensley',
    postalCode: '72065',
  },
  'hensonville-ny': {
    name: 'Hensonville',
    postalCode: '12439',
  },
  'hephzibah-ga': {
    name: 'Hephzibah',
    postalCode: '30815',
  },
  'hepler-ks': {
    name: 'Hepler',
    postalCode: '66746',
  },
  'heppner-or': {
    name: 'Heppner',
    postalCode: '97836',
  },
  'herald-ca': {
    name: 'Herald',
    postalCode: '95638',
  },
  'herbster-wi': {
    name: 'Herbster',
    postalCode: '54844',
  },
  'herculaneum-mo': {
    name: 'Herculaneum',
    postalCode: '63048',
  },
  'hercules-ca': {
    name: 'Hercules',
    postalCode: '94547',
  },
  'hereford-az': {
    name: 'Hereford',
    postalCode: '85615',
  },
  'hereford-or': {
    name: 'Hereford',
    postalCode: '97837',
  },
  'hereford-pa': {
    name: 'Hereford',
    postalCode: '18056',
  },
  'hereford-tx': {
    name: 'Hereford',
    postalCode: '79045',
  },
  'herington-ks': {
    name: 'Herington',
    postalCode: '67449',
  },
  'herkimer-ny': {
    name: 'Herkimer',
    postalCode: '13350',
  },
  'herman-mn': {
    name: 'Herman',
    postalCode: '56248',
  },
  'herman-ne': {
    name: 'Herman',
    postalCode: '68029',
  },
  'hermann-mo': {
    name: 'Hermann',
    postalCode: '65041',
  },
  'hermansville-mi': {
    name: 'Hermansville',
    postalCode: '49847',
  },
  'hermanville-ms': {
    name: 'Hermanville',
    postalCode: '39086',
  },
  'herminie-pa': {
    name: 'Herminie',
    postalCode: '15637',
  },
  'hermiston-or': {
    name: 'Hermiston',
    postalCode: '97838',
  },
  'hermitage-ar': {
    name: 'Hermitage',
    postalCode: '71647',
  },
  'hermitage-mo': {
    name: 'Hermitage',
    postalCode: '65668',
  },
  'hermitage-pa': {
    name: 'Hermitage',
    postalCode: '16148',
  },
  'hermitage-tn': {
    name: 'Hermitage',
    postalCode: '37076',
  },
  'hermleigh-tx': {
    name: 'Hermleigh',
    postalCode: '79526',
  },
  'hermon-ny': {
    name: 'Hermon',
    postalCode: '13652',
  },
  'hermosa-sd': {
    name: 'Hermosa',
    postalCode: '57744',
  },
  'hermosa-beach-ca': {
    name: 'Hermosa Beach',
    postalCode: '90254',
  },
  'hernandez-nm': {
    name: 'Hernandez',
    postalCode: '87537',
  },
  'hernando-fl': {
    name: 'Hernando',
    postalCode: '34442',
  },
  'hernando-ms': {
    name: 'Hernando',
    postalCode: '38632',
  },
  'herndon-ks': {
    name: 'Herndon',
    postalCode: '67739',
  },
  'herndon-ky': {
    name: 'Herndon',
    postalCode: '42236',
  },
  'herndon-pa': {
    name: 'Herndon',
    postalCode: '17830',
  },
  'herndon-va': {
    name: 'Herndon',
    postalCode: '20170',
  },
  'herndon-wv': {
    name: 'Herndon',
    postalCode: '24726',
  },
  'hernshaw-wv': {
    name: 'Hernshaw',
    postalCode: '25107',
  },
  'herod-il': {
    name: 'Herod',
    postalCode: '62947',
  },
  'heron-mt': {
    name: 'Heron',
    postalCode: '59844',
  },
  'heron-lake-mn': {
    name: 'Heron Lake',
    postalCode: '56137',
  },
  'herreid-sd': {
    name: 'Herreid',
    postalCode: '57632',
  },
  'herrick-il': {
    name: 'Herrick',
    postalCode: '62431',
  },
  'herrick-sd': {
    name: 'Herrick',
    postalCode: '57538',
  },
  'herrick-center-pa': {
    name: 'Herrick Center',
    postalCode: '18430',
  },
  'herriman-ut': {
    name: 'Herriman',
    postalCode: '84096',
  },
  'herrin-il': {
    name: 'Herrin',
    postalCode: '62948',
  },
  'herron-mi': {
    name: 'Herron',
    postalCode: '49744',
  },
  'herscher-il': {
    name: 'Herscher',
    postalCode: '60941',
  },
  'hersey-mi': {
    name: 'Hersey',
    postalCode: '49639',
  },
  'hershey-ne': {
    name: 'Hershey',
    postalCode: '69143',
  },
  'hershey-pa': {
    name: 'Hershey',
    postalCode: '17033',
  },
  'hertel-wi': {
    name: 'Hertel',
    postalCode: '54845',
  },
  'hertford-nc': {
    name: 'Hertford',
    postalCode: '27944',
  },
  'hesperia-ca': {
    name: 'Hesperia',
    postalCode: '92344',
  },
  'hesperia-mi': {
    name: 'Hesperia',
    postalCode: '49421',
  },
  'hesperus-co': {
    name: 'Hesperus',
    postalCode: '81326',
  },
  'hessel-mi': {
    name: 'Hessel',
    postalCode: '49745',
  },
  'hessmer-la': {
    name: 'Hessmer',
    postalCode: '71341',
  },
  'hesston-ks': {
    name: 'Hesston',
    postalCode: '67062',
  },
  'hesston-pa': {
    name: 'Hesston',
    postalCode: '16647',
  },
  'hestand-ky': {
    name: 'Hestand',
    postalCode: '42151',
  },
  'heth-ar': {
    name: 'Heth',
    postalCode: '72346',
  },
  'hettick-il': {
    name: 'Hettick',
    postalCode: '62649',
  },
  'hettinger-nd': {
    name: 'Hettinger',
    postalCode: '58639',
  },
  'heuvelton-ny': {
    name: 'Heuvelton',
    postalCode: '13654',
  },
  'hewett-wv': {
    name: 'Hewett',
    postalCode: '25108',
  },
  'hewitt-mn': {
    name: 'Hewitt',
    postalCode: '56453',
  },
  'hewitt-nj': {
    name: 'Hewitt',
    postalCode: '07421',
  },
  'hewitt-tx': {
    name: 'Hewitt',
    postalCode: '76643',
  },
  'hewitt-wi': {
    name: 'Hewitt',
    postalCode: '54441',
  },
  'hewlett-ny': {
    name: 'Hewlett',
    postalCode: '11557',
  },
  'hext-tx': {
    name: 'Hext',
    postalCode: '76848',
  },
  'heyburn-id': {
    name: 'Heyburn',
    postalCode: '83336',
  },
  'heyworth-il': {
    name: 'Heyworth',
    postalCode: '61745',
  },
  'hi-hat-ky': {
    name: 'Hi Hat',
    postalCode: '41636',
  },
  'hialeah-fl': {
    name: 'Hialeah',
    postalCode: '33010',
  },
  'hiawassee-ga': {
    name: 'Hiawassee',
    postalCode: '30546',
  },
  'hiawatha-ia': {
    name: 'Hiawatha',
    postalCode: '52233',
  },
  'hiawatha-ks': {
    name: 'Hiawatha',
    postalCode: '66434',
  },
  'hibbing-mn': {
    name: 'Hibbing',
    postalCode: '55746',
  },
  'hickman-ca': {
    name: 'Hickman',
    postalCode: '95323',
  },
  'hickman-ky': {
    name: 'Hickman',
    postalCode: '42050',
  },
  'hickman-ne': {
    name: 'Hickman',
    postalCode: '68372',
  },
  'hickman-tn': {
    name: 'Hickman',
    postalCode: '38567',
  },
  'hickory-ky': {
    name: 'Hickory',
    postalCode: '42051',
  },
  'hickory-ms': {
    name: 'Hickory',
    postalCode: '39332',
  },
  'hickory-nc': {
    name: 'Hickory',
    postalCode: '28601',
  },
  'hickory-pa': {
    name: 'Hickory',
    postalCode: '15340',
  },
  'hickory-corners-mi': {
    name: 'Hickory Corners',
    postalCode: '49060',
  },
  'hickory-flat-ms': {
    name: 'Hickory Flat',
    postalCode: '38633',
  },
  'hickory-grove-sc': {
    name: 'Hickory Grove',
    postalCode: '29717',
  },
  'hickory-hills-il': {
    name: 'Hickory Hills',
    postalCode: '60457',
  },
  'hickory-ridge-ar': {
    name: 'Hickory Ridge',
    postalCode: '72347',
  },
  'hickory-valley-tn': {
    name: 'Hickory Valley',
    postalCode: '38042',
  },
  'hicksville-ny': {
    name: 'Hicksville',
    postalCode: '11801',
  },
  'hicksville-oh': {
    name: 'Hicksville',
    postalCode: '43526',
  },
  'hico-tx': {
    name: 'Hico',
    postalCode: '76457',
  },
  'hico-wv': {
    name: 'Hico',
    postalCode: '25854',
  },
  'hidalgo-il': {
    name: 'Hidalgo',
    postalCode: '62432',
  },
  'hidalgo-tx': {
    name: 'Hidalgo',
    postalCode: '78557',
  },
  'hidden-valley-lake-ca': {
    name: 'Hidden Valley Lake',
    postalCode: '95467',
  },
  'hiddenite-nc': {
    name: 'Hiddenite',
    postalCode: '28636',
  },
  'higbee-mo': {
    name: 'Higbee',
    postalCode: '65257',
  },
  'higden-ar': {
    name: 'Higden',
    postalCode: '72067',
  },
  'higdon-al': {
    name: 'Higdon',
    postalCode: '35979',
  },
  'higganum-ct': {
    name: 'Higganum',
    postalCode: '06441',
  },
  'higgins-tx': {
    name: 'Higgins',
    postalCode: '79046',
  },
  'higginson-ar': {
    name: 'Higginson',
    postalCode: '72068',
  },
  'higginsville-mo': {
    name: 'Higginsville',
    postalCode: '64037',
  },
  'high-bridge-nj': {
    name: 'High Bridge',
    postalCode: '08829',
  },
  'high-bridge-wi': {
    name: 'High Bridge',
    postalCode: '54846',
  },
  'high-falls-ny': {
    name: 'High Falls',
    postalCode: '12440',
  },
  'high-hill-mo': {
    name: 'High Hill',
    postalCode: '63350',
  },
  'high-point-nc': {
    name: 'High Point',
    postalCode: '27260',
  },
  'high-ridge-mo': {
    name: 'High Ridge',
    postalCode: '63049',
  },
  'high-springs-fl': {
    name: 'High Springs',
    postalCode: '32643',
  },
  'high-view-wv': {
    name: 'High View',
    postalCode: '26808',
  },
  'highgate-center-vt': {
    name: 'Highgate Center',
    postalCode: '05459',
  },
  'highland-ca': {
    name: 'Highland',
    postalCode: '92346',
  },
  'highland-il': {
    name: 'Highland',
    postalCode: '62249',
  },
  'highland-in': {
    name: 'Highland',
    postalCode: '46322',
  },
  'highland-ks': {
    name: 'Highland',
    postalCode: '66035',
  },
  'highland-md': {
    name: 'Highland',
    postalCode: '20777',
  },
  'highland-mi': {
    name: 'Highland',
    postalCode: '48356',
  },
  'highland-ny': {
    name: 'Highland',
    postalCode: '12528',
  },
  'highland-wi': {
    name: 'Highland',
    postalCode: '53543',
  },
  'highland-falls-ny': {
    name: 'Highland Falls',
    postalCode: '10928',
  },
  'highland-home-al': {
    name: 'Highland Home',
    postalCode: '36041',
  },
  'highland-lake-ny': {
    name: 'Highland Lake',
    postalCode: '12743',
  },
  'highland-lakes-nj': {
    name: 'Highland Lakes',
    postalCode: '07422',
  },
  'highland-mills-ny': {
    name: 'Highland Mills',
    postalCode: '10930',
  },
  'highland-park-il': {
    name: 'Highland Park',
    postalCode: '60035',
  },
  'highland-park-mi': {
    name: 'Highland Park',
    postalCode: '48203',
  },
  'highland-park-nj': {
    name: 'Highland Park',
    postalCode: '08904',
  },
  'highlands-nc': {
    name: 'Highlands',
    postalCode: '28741',
  },
  'highlands-nj': {
    name: 'Highlands',
    postalCode: '07732',
  },
  'highlands-tx': {
    name: 'Highlands',
    postalCode: '77562',
  },
  'highlandville-mo': {
    name: 'Highlandville',
    postalCode: '65669',
  },
  'highmore-sd': {
    name: 'Highmore',
    postalCode: '57345',
  },
  'highspire-pa': {
    name: 'Highspire',
    postalCode: '17034',
  },
  'hightstown-nj': {
    name: 'Hightstown',
    postalCode: '08520',
  },
  'highwood-il': {
    name: 'Highwood',
    postalCode: '60040',
  },
  'highwood-mt': {
    name: 'Highwood',
    postalCode: '59450',
  },
  'hiko-nv': {
    name: 'Hiko',
    postalCode: '89017',
  },
  'hiland-wy': {
    name: 'Hiland',
    postalCode: '82638',
  },
  'hilbert-wi': {
    name: 'Hilbert',
    postalCode: '54129',
  },
  'hildebran-nc': {
    name: 'Hildebran',
    postalCode: '28637',
  },
  'hildreth-ne': {
    name: 'Hildreth',
    postalCode: '68947',
  },
  'hilger-mt': {
    name: 'Hilger',
    postalCode: '59451',
  },
  'hilham-tn': {
    name: 'Hilham',
    postalCode: '38568',
  },
  'hill-nh': {
    name: 'Hill',
    postalCode: '03243',
  },
  'hill-afb-ut': {
    name: 'Hill AFB',
    postalCode: '84056',
  },
  'hill-city-id': {
    name: 'Hill City',
    postalCode: '83337',
  },
  'hill-city-ks': {
    name: 'Hill City',
    postalCode: '67642',
  },
  'hill-city-mn': {
    name: 'Hill City',
    postalCode: '55748',
  },
  'hill-city-sd': {
    name: 'Hill City',
    postalCode: '57745',
  },
  'hillburn-ny': {
    name: 'Hillburn',
    postalCode: '10931',
  },
  'hiller-pa': {
    name: 'Hiller',
    postalCode: '15444',
  },
  'hilliard-fl': {
    name: 'Hilliard',
    postalCode: '32046',
  },
  'hilliard-oh': {
    name: 'Hilliard',
    postalCode: '43026',
  },
  'hilliards-pa': {
    name: 'Hilliards',
    postalCode: '16040',
  },
  'hillister-tx': {
    name: 'Hillister',
    postalCode: '77624',
  },
  'hillman-mi': {
    name: 'Hillman',
    postalCode: '49746',
  },
  'hillman-mn': {
    name: 'Hillman',
    postalCode: '56338',
  },
  'hillpoint-wi': {
    name: 'Hillpoint',
    postalCode: '53937',
  },
  'hillrose-co': {
    name: 'Hillrose',
    postalCode: '80733',
  },
  'hills-mn': {
    name: 'Hills',
    postalCode: '56138',
  },
  'hillsboro-al': {
    name: 'Hillsboro',
    postalCode: '35643',
  },
  'hillsboro-ga': {
    name: 'Hillsboro',
    postalCode: '31038',
  },
  'hillsboro-ia': {
    name: 'Hillsboro',
    postalCode: '52630',
  },
  'hillsboro-il': {
    name: 'Hillsboro',
    postalCode: '62049',
  },
  'hillsboro-in': {
    name: 'Hillsboro',
    postalCode: '47949',
  },
  'hillsboro-ks': {
    name: 'Hillsboro',
    postalCode: '67063',
  },
  'hillsboro-ky': {
    name: 'Hillsboro',
    postalCode: '41049',
  },
  'hillsboro-mo': {
    name: 'Hillsboro',
    postalCode: '63050',
  },
  'hillsboro-nd': {
    name: 'Hillsboro',
    postalCode: '58045',
  },
  'hillsboro-nm': {
    name: 'Hillsboro',
    postalCode: '88042',
  },
  'hillsboro-oh': {
    name: 'Hillsboro',
    postalCode: '45133',
  },
  'hillsboro-or': {
    name: 'Hillsboro',
    postalCode: '97123',
  },
  'hillsboro-tn': {
    name: 'Hillsboro',
    postalCode: '37342',
  },
  'hillsboro-tx': {
    name: 'Hillsboro',
    postalCode: '76645',
  },
  'hillsboro-wi': {
    name: 'Hillsboro',
    postalCode: '54634',
  },
  'hillsboro-wv': {
    name: 'Hillsboro',
    postalCode: '24946',
  },
  'hillsborough-nc': {
    name: 'Hillsborough',
    postalCode: '27278',
  },
  'hillsborough-nh': {
    name: 'Hillsborough',
    postalCode: '03244',
  },
  'hillsborough-nj': {
    name: 'Hillsborough',
    postalCode: '08844',
  },
  'hillsdale-il': {
    name: 'Hillsdale',
    postalCode: '61257',
  },
  'hillsdale-in': {
    name: 'Hillsdale',
    postalCode: '47854',
  },
  'hillsdale-mi': {
    name: 'Hillsdale',
    postalCode: '49242',
  },
  'hillsdale-nj': {
    name: 'Hillsdale',
    postalCode: '07642',
  },
  'hillsdale-ny': {
    name: 'Hillsdale',
    postalCode: '12529',
  },
  'hillsgrove-pa': {
    name: 'Hillsgrove',
    postalCode: '18619',
  },
  'hillside-il': {
    name: 'Hillside',
    postalCode: '60162',
  },
  'hillside-nj': {
    name: 'Hillside',
    postalCode: '07205',
  },
  'hillsville-va': {
    name: 'Hillsville',
    postalCode: '24343',
  },
  'hilltop-wv': {
    name: 'Hilltop',
    postalCode: '25855',
  },
  'hilltown-pa': {
    name: 'Hilltown',
    postalCode: '18927',
  },
  'hillview-il': {
    name: 'Hillview',
    postalCode: '62050',
  },
  'hilmar-ca': {
    name: 'Hilmar',
    postalCode: '95324',
  },
  'hilo-hi': {
    name: 'Hilo',
    postalCode: '96720',
  },
  'hilton-ny': {
    name: 'Hilton',
    postalCode: '14468',
  },
  'hilton-head-island-sc': {
    name: 'Hilton Head Island',
    postalCode: '29926',
  },
  'hiltons-va': {
    name: 'Hiltons',
    postalCode: '24258',
  },
  'himrod-ny': {
    name: 'Himrod',
    postalCode: '14842',
  },
  'hinckley-il': {
    name: 'Hinckley',
    postalCode: '60520',
  },
  'hinckley-mn': {
    name: 'Hinckley',
    postalCode: '55037',
  },
  'hinckley-oh': {
    name: 'Hinckley',
    postalCode: '44233',
  },
  'hinckley-ut': {
    name: 'Hinckley',
    postalCode: '84635',
  },
  'hindman-ky': {
    name: 'Hindman',
    postalCode: '41822',
  },
  'hindsboro-il': {
    name: 'Hindsboro',
    postalCode: '61930',
  },
  'hindsville-ar': {
    name: 'Hindsville',
    postalCode: '72738',
  },
  'hines-mn': {
    name: 'Hines',
    postalCode: '56647',
  },
  'hines-or': {
    name: 'Hines',
    postalCode: '97738',
  },
  'hinesburg-vt': {
    name: 'Hinesburg',
    postalCode: '05461',
  },
  'hineston-la': {
    name: 'Hineston',
    postalCode: '71438',
  },
  'hinesville-ga': {
    name: 'Hinesville',
    postalCode: '31313',
  },
  'hingham-ma': {
    name: 'Hingham',
    postalCode: '02043',
  },
  'hingham-mt': {
    name: 'Hingham',
    postalCode: '59528',
  },
  'hinkle-ky': {
    name: 'Hinkle',
    postalCode: '40953',
  },
  'hinkley-ca': {
    name: 'Hinkley',
    postalCode: '92347',
  },
  'hinsdale-il': {
    name: 'Hinsdale',
    postalCode: '60521',
  },
  'hinsdale-ma': {
    name: 'Hinsdale',
    postalCode: '01235',
  },
  'hinsdale-mt': {
    name: 'Hinsdale',
    postalCode: '59241',
  },
  'hinsdale-nh': {
    name: 'Hinsdale',
    postalCode: '03451',
  },
  'hinsdale-ny': {
    name: 'Hinsdale',
    postalCode: '14743',
  },
  'hinton-ia': {
    name: 'Hinton',
    postalCode: '51024',
  },
  'hinton-ok': {
    name: 'Hinton',
    postalCode: '73047',
  },
  'hinton-va': {
    name: 'Hinton',
    postalCode: '22831',
  },
  'hinton-wv': {
    name: 'Hinton',
    postalCode: '25951',
  },
  'hiram-ga': {
    name: 'Hiram',
    postalCode: '30141',
  },
  'hiram-me': {
    name: 'Hiram',
    postalCode: '04041',
  },
  'hiram-oh': {
    name: 'Hiram',
    postalCode: '44234',
  },
  'hitchcock-ok': {
    name: 'Hitchcock',
    postalCode: '73744',
  },
  'hitchcock-sd': {
    name: 'Hitchcock',
    postalCode: '57348',
  },
  'hitchcock-tx': {
    name: 'Hitchcock',
    postalCode: '77563',
  },
  'hitchins-ky': {
    name: 'Hitchins',
    postalCode: '41146',
  },
  'hitterdal-mn': {
    name: 'Hitterdal',
    postalCode: '56552',
  },
  'hiwasse-ar': {
    name: 'Hiwasse',
    postalCode: '72739',
  },
  'hiwassee-va': {
    name: 'Hiwassee',
    postalCode: '24347',
  },
  'hixson-tn': {
    name: 'Hixson',
    postalCode: '37343',
  },
  'hixton-wi': {
    name: 'Hixton',
    postalCode: '54635',
  },
  'ho-ho-kus-nj': {
    name: 'Ho Ho Kus',
    postalCode: '07423',
  },
  'hoagland-in': {
    name: 'Hoagland',
    postalCode: '46745',
  },
  'hobart-in': {
    name: 'Hobart',
    postalCode: '46342',
  },
  'hobart-ny': {
    name: 'Hobart',
    postalCode: '13788',
  },
  'hobart-ok': {
    name: 'Hobart',
    postalCode: '73651',
  },
  'hobbs-nm': {
    name: 'Hobbs',
    postalCode: '88240',
  },
  'hobbsville-nc': {
    name: 'Hobbsville',
    postalCode: '27946',
  },
  'hobe-sound-fl': {
    name: 'Hobe Sound',
    postalCode: '33455',
  },
  'hobgood-nc': {
    name: 'Hobgood',
    postalCode: '27843',
  },
  'hoboken-ga': {
    name: 'Hoboken',
    postalCode: '31542',
  },
  'hoboken-nj': {
    name: 'Hoboken',
    postalCode: '07030',
  },
  'hobson-mt': {
    name: 'Hobson',
    postalCode: '59452',
  },
  'hobson-tx': {
    name: 'Hobson',
    postalCode: '78117',
  },
  'hobucken-nc': {
    name: 'Hobucken',
    postalCode: '28537',
  },
  'hockessin-de': {
    name: 'Hockessin',
    postalCode: '19707',
  },
  'hockley-tx': {
    name: 'Hockley',
    postalCode: '77447',
  },
  'hodgen-ok': {
    name: 'Hodgen',
    postalCode: '74939',
  },
  'hodgenville-ky': {
    name: 'Hodgenville',
    postalCode: '42748',
  },
  'hodges-al': {
    name: 'Hodges',
    postalCode: '35571',
  },
  'hodges-sc': {
    name: 'Hodges',
    postalCode: '29653',
  },
  'hoffman-il': {
    name: 'Hoffman',
    postalCode: '62250',
  },
  'hoffman-mn': {
    name: 'Hoffman',
    postalCode: '56339',
  },
  'hoffman-nc': {
    name: 'Hoffman',
    postalCode: '28347',
  },
  'hoffman-estates-il': {
    name: 'Hoffman Estates',
    postalCode: '60169',
  },
  'hogansburg-ny': {
    name: 'Hogansburg',
    postalCode: '13655',
  },
  'hogansville-ga': {
    name: 'Hogansville',
    postalCode: '30230',
  },
  'hogeland-mt': {
    name: 'Hogeland',
    postalCode: '59529',
  },
  'hohenwald-tn': {
    name: 'Hohenwald',
    postalCode: '38462',
  },
  'hoisington-ks': {
    name: 'Hoisington',
    postalCode: '67544',
  },
  'hokah-mn': {
    name: 'Hokah',
    postalCode: '55941',
  },
  'holabird-sd': {
    name: 'Holabird',
    postalCode: '57540',
  },
  'holbrook-az': {
    name: 'Holbrook',
    postalCode: '86025',
  },
  'holbrook-id': {
    name: 'Holbrook',
    postalCode: '83243',
  },
  'holbrook-ma': {
    name: 'Holbrook',
    postalCode: '02343',
  },
  'holbrook-ne': {
    name: 'Holbrook',
    postalCode: '68948',
  },
  'holbrook-ny': {
    name: 'Holbrook',
    postalCode: '11741',
  },
  'holbrook-pa': {
    name: 'Holbrook',
    postalCode: '15341',
  },
  'holcomb-ks': {
    name: 'Holcomb',
    postalCode: '67851',
  },
  'holcomb-mo': {
    name: 'Holcomb',
    postalCode: '63852',
  },
  'holcomb-ms': {
    name: 'Holcomb',
    postalCode: '38940',
  },
  'holcombe-wi': {
    name: 'Holcombe',
    postalCode: '54745',
  },
  'holden-la': {
    name: 'Holden',
    postalCode: '70744',
  },
  'holden-ma': {
    name: 'Holden',
    postalCode: '01520',
  },
  'holden-me': {
    name: 'Holden',
    postalCode: '04429',
  },
  'holden-mo': {
    name: 'Holden',
    postalCode: '64040',
  },
  'holden-wv': {
    name: 'Holden',
    postalCode: '25625',
  },
  'holdenville-ok': {
    name: 'Holdenville',
    postalCode: '74848',
  },
  'holderness-nh': {
    name: 'Holderness',
    postalCode: '03245',
  },
  'holdingford-mn': {
    name: 'Holdingford',
    postalCode: '56340',
  },
  'holdrege-ne': {
    name: 'Holdrege',
    postalCode: '68949',
  },
  'holgate-oh': {
    name: 'Holgate',
    postalCode: '43527',
  },
  'holiday-fl': {
    name: 'Holiday',
    postalCode: '34690',
  },
  'holladay-tn': {
    name: 'Holladay',
    postalCode: '38341',
  },
  'holland-ia': {
    name: 'Holland',
    postalCode: '50642',
  },
  'holland-in': {
    name: 'Holland',
    postalCode: '47541',
  },
  'holland-ky': {
    name: 'Holland',
    postalCode: '42153',
  },
  'holland-ma': {
    name: 'Holland',
    postalCode: '01521',
  },
  'holland-mi': {
    name: 'Holland',
    postalCode: '49423',
  },
  'holland-mn': {
    name: 'Holland',
    postalCode: '56139',
  },
  'holland-ny': {
    name: 'Holland',
    postalCode: '14080',
  },
  'holland-oh': {
    name: 'Holland',
    postalCode: '43528',
  },
  'holland-tx': {
    name: 'Holland',
    postalCode: '76534',
  },
  'holland-patent-ny': {
    name: 'Holland Patent',
    postalCode: '13354',
  },
  'hollandale-mn': {
    name: 'Hollandale',
    postalCode: '56045',
  },
  'hollandale-ms': {
    name: 'Hollandale',
    postalCode: '38748',
  },
  'hollandale-wi': {
    name: 'Hollandale',
    postalCode: '53544',
  },
  'hollansburg-oh': {
    name: 'Hollansburg',
    postalCode: '45332',
  },
  'hollenberg-ks': {
    name: 'Hollenberg',
    postalCode: '66946',
  },
  'holley-ny': {
    name: 'Holley',
    postalCode: '14470',
  },
  'holliday-mo': {
    name: 'Holliday',
    postalCode: '65258',
  },
  'holliday-tx': {
    name: 'Holliday',
    postalCode: '76366',
  },
  'hollidaysburg-pa': {
    name: 'Hollidaysburg',
    postalCode: '16648',
  },
  'hollis-nh': {
    name: 'Hollis',
    postalCode: '03049',
  },
  'hollis-ny': {
    name: 'Hollis',
    postalCode: '11423',
  },
  'hollis-ok': {
    name: 'Hollis',
    postalCode: '73550',
  },
  'hollis-center-me': {
    name: 'Hollis Center',
    postalCode: '04042',
  },
  'hollister-ca': {
    name: 'Hollister',
    postalCode: '95023',
  },
  'hollister-mo': {
    name: 'Hollister',
    postalCode: '65672',
  },
  'hollister-nc': {
    name: 'Hollister',
    postalCode: '27844',
  },
  'hollister-ok': {
    name: 'Hollister',
    postalCode: '73551',
  },
  'holliston-ma': {
    name: 'Holliston',
    postalCode: '01746',
  },
  'holloman-air-force-base-nm': {
    name: 'Holloman Air Force Base',
    postalCode: '88330',
  },
  'hollow-rock-tn': {
    name: 'Hollow Rock',
    postalCode: '38342',
  },
  'holloway-mn': {
    name: 'Holloway',
    postalCode: '56249',
  },
  'hollsopple-pa': {
    name: 'Hollsopple',
    postalCode: '15935',
  },
  'holly-co': {
    name: 'Holly',
    postalCode: '81047',
  },
  'holly-mi': {
    name: 'Holly',
    postalCode: '48442',
  },
  'holly-bluff-ms': {
    name: 'Holly Bluff',
    postalCode: '39088',
  },
  'holly-grove-ar': {
    name: 'Holly Grove',
    postalCode: '72069',
  },
  'holly-hill-sc': {
    name: 'Holly Hill',
    postalCode: '29059',
  },
  'holly-pond-al': {
    name: 'Holly Pond',
    postalCode: '35083',
  },
  'holly-ridge-nc': {
    name: 'Holly Ridge',
    postalCode: '28445',
  },
  'holly-springs-ms': {
    name: 'Holly Springs',
    postalCode: '38635',
  },
  'holly-springs-nc': {
    name: 'Holly Springs',
    postalCode: '27540',
  },
  'hollytree-al': {
    name: 'Hollytree',
    postalCode: '35751',
  },
  'hollywood-al': {
    name: 'Hollywood',
    postalCode: '35752',
  },
  'hollywood-fl': {
    name: 'Hollywood',
    postalCode: '33019',
  },
  'hollywood-md': {
    name: 'Hollywood',
    postalCode: '20636',
  },
  'hollywood-sc': {
    name: 'Hollywood',
    postalCode: '29449',
  },
  'holman-nm': {
    name: 'Holman',
    postalCode: '87723',
  },
  'holmdel-nj': {
    name: 'Holmdel',
    postalCode: '07733',
  },
  'holmen-wi': {
    name: 'Holmen',
    postalCode: '54636',
  },
  'holmes-ny': {
    name: 'Holmes',
    postalCode: '12531',
  },
  'holmes-pa': {
    name: 'Holmes',
    postalCode: '19043',
  },
  'holmes-mill-ky': {
    name: 'Holmes Mill',
    postalCode: '40843',
  },
  'holmesville-oh': {
    name: 'Holmesville',
    postalCode: '44633',
  },
  'holstein-ia': {
    name: 'Holstein',
    postalCode: '51025',
  },
  'holstein-ne': {
    name: 'Holstein',
    postalCode: '68950',
  },
  'holt-fl': {
    name: 'Holt',
    postalCode: '32564',
  },
  'holt-mi': {
    name: 'Holt',
    postalCode: '48842',
  },
  'holt-mo': {
    name: 'Holt',
    postalCode: '64048',
  },
  'holton-in': {
    name: 'Holton',
    postalCode: '47023',
  },
  'holton-ks': {
    name: 'Holton',
    postalCode: '66436',
  },
  'holton-mi': {
    name: 'Holton',
    postalCode: '49425',
  },
  'holts-summit-mo': {
    name: 'Holts Summit',
    postalCode: '65043',
  },
  'holtsville-ny': {
    name: 'Holtsville',
    postalCode: '11742',
  },
  'holtville-ca': {
    name: 'Holtville',
    postalCode: '92250',
  },
  'holtwood-pa': {
    name: 'Holtwood',
    postalCode: '17532',
  },
  'holualoa-hi': {
    name: 'Holualoa',
    postalCode: '96725',
  },
  'holy-cross-ia': {
    name: 'Holy Cross',
    postalCode: '52053',
  },
  'holyoke-co': {
    name: 'Holyoke',
    postalCode: '80734',
  },
  'holyoke-ma': {
    name: 'Holyoke',
    postalCode: '01040',
  },
  'holyoke-mn': {
    name: 'Holyoke',
    postalCode: '55749',
  },
  'holyrood-ks': {
    name: 'Holyrood',
    postalCode: '67450',
  },
  'home-ks': {
    name: 'Home',
    postalCode: '66438',
  },
  'home-pa': {
    name: 'Home',
    postalCode: '15747',
  },
  'homedale-id': {
    name: 'Homedale',
    postalCode: '83628',
  },
  'homeland-ca': {
    name: 'Homeland',
    postalCode: '92548',
  },
  'homer-ak': {
    name: 'Homer',
    postalCode: '99603',
  },
  'homer-ga': {
    name: 'Homer',
    postalCode: '30547',
  },
  'homer-il': {
    name: 'Homer',
    postalCode: '61849',
  },
  'homer-in': {
    name: 'Homer',
    postalCode: '46146',
  },
  'homer-la': {
    name: 'Homer',
    postalCode: '71040',
  },
  'homer-mi': {
    name: 'Homer',
    postalCode: '49245',
  },
  'homer-ne': {
    name: 'Homer',
    postalCode: '68030',
  },
  'homer-ny': {
    name: 'Homer',
    postalCode: '13077',
  },
  'homer-city-pa': {
    name: 'Homer City',
    postalCode: '15748',
  },
  'homer-glen-il': {
    name: 'Homer Glen',
    postalCode: '60491',
  },
  'homerville-ga': {
    name: 'Homerville',
    postalCode: '31634',
  },
  'homerville-oh': {
    name: 'Homerville',
    postalCode: '44235',
  },
  'homestead-fl': {
    name: 'Homestead',
    postalCode: '33030',
  },
  'homestead-ia': {
    name: 'Homestead',
    postalCode: '52236',
  },
  'homestead-mt': {
    name: 'Homestead',
    postalCode: '59242',
  },
  'homestead-pa': {
    name: 'Homestead',
    postalCode: '15120',
  },
  'hometown-il': {
    name: 'Hometown',
    postalCode: '60456',
  },
  'homewood-il': {
    name: 'Homewood',
    postalCode: '60430',
  },
  'homeworth-oh': {
    name: 'Homeworth',
    postalCode: '44634',
  },
  'hominy-ok': {
    name: 'Hominy',
    postalCode: '74035',
  },
  'homosassa-fl': {
    name: 'Homosassa',
    postalCode: '34446',
  },
  'honaker-va': {
    name: 'Honaker',
    postalCode: '24260',
  },
  'hondo-nm': {
    name: 'Hondo',
    postalCode: '88336',
  },
  'hondo-tx': {
    name: 'Hondo',
    postalCode: '78861',
  },
  'honea-path-sc': {
    name: 'Honea Path',
    postalCode: '29654',
  },
  'honeoye-ny': {
    name: 'Honeoye',
    postalCode: '14471',
  },
  'honeoye-falls-ny': {
    name: 'Honeoye Falls',
    postalCode: '14472',
  },
  'honesdale-pa': {
    name: 'Honesdale',
    postalCode: '18431',
  },
  'honey-brook-pa': {
    name: 'Honey Brook',
    postalCode: '19344',
  },
  'honey-creek-ia': {
    name: 'Honey Creek',
    postalCode: '51542',
  },
  'honey-grove-pa': {
    name: 'Honey Grove',
    postalCode: '17035',
  },
  'honey-grove-tx': {
    name: 'Honey Grove',
    postalCode: '75446',
  },
  'honeyville-ut': {
    name: 'Honeyville',
    postalCode: '84314',
  },
  'honobia-ok': {
    name: 'Honobia',
    postalCode: '74549',
  },
  'honokaa-hi': {
    name: 'Honokaa',
    postalCode: '96727',
  },
  'honolulu-hi': {
    name: 'Honolulu',
    postalCode: '96813',
  },
  'honor-mi': {
    name: 'Honor',
    postalCode: '49640',
  },
  'honoraville-al': {
    name: 'Honoraville',
    postalCode: '36042',
  },
  'hood-va': {
    name: 'Hood',
    postalCode: '22723',
  },
  'hood-river-or': {
    name: 'Hood River',
    postalCode: '97031',
  },
  'hoodsport-wa': {
    name: 'Hoodsport',
    postalCode: '98548',
  },
  'hooker-ok': {
    name: 'Hooker',
    postalCode: '73945',
  },
  'hookerton-nc': {
    name: 'Hookerton',
    postalCode: '28538',
  },
  'hooks-tx': {
    name: 'Hooks',
    postalCode: '75561',
  },
  'hooksett-nh': {
    name: 'Hooksett',
    postalCode: '03106',
  },
  'hookstown-pa': {
    name: 'Hookstown',
    postalCode: '15050',
  },
  'hoopa-ca': {
    name: 'Hoopa',
    postalCode: '95546',
  },
  'hooper-co': {
    name: 'Hooper',
    postalCode: '81136',
  },
  'hooper-ne': {
    name: 'Hooper',
    postalCode: '68031',
  },
  'hooper-ut': {
    name: 'Hooper',
    postalCode: '84315',
  },
  'hoopeston-il': {
    name: 'Hoopeston',
    postalCode: '60942',
  },
  'hoople-nd': {
    name: 'Hoople',
    postalCode: '58243',
  },
  'hooppole-il': {
    name: 'Hooppole',
    postalCode: '61258',
  },
  'hoosick-falls-ny': {
    name: 'Hoosick Falls',
    postalCode: '12090',
  },
  'hooversville-pa': {
    name: 'Hooversville',
    postalCode: '15936',
  },
  'hop-bottom-pa': {
    name: 'Hop Bottom',
    postalCode: '18824',
  },
  'hopatcong-nj': {
    name: 'Hopatcong',
    postalCode: '07843',
  },
  'hope-ak': {
    name: 'Hope',
    postalCode: '99605',
  },
  'hope-ar': {
    name: 'Hope',
    postalCode: '71801',
  },
  'hope-id': {
    name: 'Hope',
    postalCode: '83836',
  },
  'hope-in': {
    name: 'Hope',
    postalCode: '47246',
  },
  'hope-ks': {
    name: 'Hope',
    postalCode: '67451',
  },
  'hope-ky': {
    name: 'Hope',
    postalCode: '40334',
  },
  'hope-me': {
    name: 'Hope',
    postalCode: '04847',
  },
  'hope-mi': {
    name: 'Hope',
    postalCode: '48628',
  },
  'hope-nd': {
    name: 'Hope',
    postalCode: '58046',
  },
  'hope-nm': {
    name: 'Hope',
    postalCode: '88250',
  },
  'hope-ri': {
    name: 'Hope',
    postalCode: '02831',
  },
  'hope-hull-al': {
    name: 'Hope Hull',
    postalCode: '36043',
  },
  'hope-mills-nc': {
    name: 'Hope Mills',
    postalCode: '28348',
  },
  'hope-valley-ri': {
    name: 'Hope Valley',
    postalCode: '02832',
  },
  'hopedale-il': {
    name: 'Hopedale',
    postalCode: '61747',
  },
  'hopedale-ma': {
    name: 'Hopedale',
    postalCode: '01747',
  },
  'hopedale-oh': {
    name: 'Hopedale',
    postalCode: '43976',
  },
  'hopewell-nj': {
    name: 'Hopewell',
    postalCode: '08525',
  },
  'hopewell-oh': {
    name: 'Hopewell',
    postalCode: '43746',
  },
  'hopewell-pa': {
    name: 'Hopewell',
    postalCode: '16650',
  },
  'hopewell-va': {
    name: 'Hopewell',
    postalCode: '23860',
  },
  'hopewell-junction-ny': {
    name: 'Hopewell Junction',
    postalCode: '12533',
  },
  'hopkins-mi': {
    name: 'Hopkins',
    postalCode: '49328',
  },
  'hopkins-mn': {
    name: 'Hopkins',
    postalCode: '55305',
  },
  'hopkins-mo': {
    name: 'Hopkins',
    postalCode: '64461',
  },
  'hopkins-sc': {
    name: 'Hopkins',
    postalCode: '29061',
  },
  'hopkinsville-ky': {
    name: 'Hopkinsville',
    postalCode: '42240',
  },
  'hopkinton-ia': {
    name: 'Hopkinton',
    postalCode: '52237',
  },
  'hopkinton-ma': {
    name: 'Hopkinton',
    postalCode: '01748',
  },
  'hopkinton-ri': {
    name: 'Hopkinton',
    postalCode: '02833',
  },
  'hopland-ca': {
    name: 'Hopland',
    postalCode: '95449',
  },
  'hopwood-pa': {
    name: 'Hopwood',
    postalCode: '15445',
  },
  'hoquiam-wa': {
    name: 'Hoquiam',
    postalCode: '98550',
  },
  'horace-nd': {
    name: 'Horace',
    postalCode: '58047',
  },
  'horatio-ar': {
    name: 'Horatio',
    postalCode: '71842',
  },
  'hordville-ne': {
    name: 'Hordville',
    postalCode: '68846',
  },
  'horicon-wi': {
    name: 'Horicon',
    postalCode: '53032',
  },
  'hormigueros-pr': {
    name: 'Hormigueros',
    postalCode: '00660',
  },
  'horn-lake-ms': {
    name: 'Horn Lake',
    postalCode: '38637',
  },
  'hornbeak-tn': {
    name: 'Hornbeak',
    postalCode: '38232',
  },
  'hornbeck-la': {
    name: 'Hornbeck',
    postalCode: '71439',
  },
  'hornbrook-ca': {
    name: 'Hornbrook',
    postalCode: '96044',
  },
  'hornell-ny': {
    name: 'Hornell',
    postalCode: '14843',
  },
  'horner-wv': {
    name: 'Horner',
    postalCode: '26372',
  },
  'hornersville-mo': {
    name: 'Hornersville',
    postalCode: '63855',
  },
  'hornick-ia': {
    name: 'Hornick',
    postalCode: '51026',
  },
  'hornitos-ca': {
    name: 'Hornitos',
    postalCode: '95325',
  },
  'hornsby-tn': {
    name: 'Hornsby',
    postalCode: '38044',
  },
  'horntown-va': {
    name: 'Horntown',
    postalCode: '23395',
  },
  'horse-branch-ky': {
    name: 'Horse Branch',
    postalCode: '42349',
  },
  'horse-cave-ky': {
    name: 'Horse Cave',
    postalCode: '42749',
  },
  'horse-shoe-nc': {
    name: 'Horse Shoe',
    postalCode: '28742',
  },
  'horseheads-ny': {
    name: 'Horseheads',
    postalCode: '14845',
  },
  'horseshoe-bay-tx': {
    name: 'Horseshoe Bay',
    postalCode: '78657',
  },
  'horseshoe-beach-fl': {
    name: 'Horseshoe Beach',
    postalCode: '32648',
  },
  'horseshoe-bend-ar': {
    name: 'Horseshoe Bend',
    postalCode: '72512',
  },
  'horseshoe-bend-id': {
    name: 'Horseshoe Bend',
    postalCode: '83629',
  },
  'horsham-pa': {
    name: 'Horsham',
    postalCode: '19044',
  },
  'hortense-ga': {
    name: 'Hortense',
    postalCode: '31543',
  },
  'horton-al': {
    name: 'Horton',
    postalCode: '35980',
  },
  'horton-ks': {
    name: 'Horton',
    postalCode: '66439',
  },
  'horton-mi': {
    name: 'Horton',
    postalCode: '49246',
  },
  'hortonville-ny': {
    name: 'Hortonville',
    postalCode: '12745',
  },
  'hortonville-wi': {
    name: 'Hortonville',
    postalCode: '54944',
  },
  'hoschton-ga': {
    name: 'Hoschton',
    postalCode: '30548',
  },
  'hosford-fl': {
    name: 'Hosford',
    postalCode: '32334',
  },
  'hoskins-ne': {
    name: 'Hoskins',
    postalCode: '68740',
  },
  'hosmer-sd': {
    name: 'Hosmer',
    postalCode: '57448',
  },
  'hospers-ia': {
    name: 'Hospers',
    postalCode: '51238',
  },
  'hosston-la': {
    name: 'Hosston',
    postalCode: '71043',
  },
  'hot-springs-mt': {
    name: 'Hot Springs',
    postalCode: '59845',
  },
  'hot-springs-nc': {
    name: 'Hot Springs',
    postalCode: '28743',
  },
  'hot-springs-sd': {
    name: 'Hot Springs',
    postalCode: '57747',
  },
  'hot-springs-va': {
    name: 'Hot Springs',
    postalCode: '24445',
  },
  'hot-springs-national-park-ar': {
    name: 'Hot Springs National Park',
    postalCode: '71901',
  },
  'hot-springs-village-ar': {
    name: 'Hot Springs Village',
    postalCode: '71909',
  },
  'hotchkiss-co': {
    name: 'Hotchkiss',
    postalCode: '81419',
  },
  'houghton-mi': {
    name: 'Houghton',
    postalCode: '49931',
  },
  'houghton-ny': {
    name: 'Houghton',
    postalCode: '14744',
  },
  'houghton-sd': {
    name: 'Houghton',
    postalCode: '57449',
  },
  'houghton-lake-mi': {
    name: 'Houghton Lake',
    postalCode: '48629',
  },
  'houlka-ms': {
    name: 'Houlka',
    postalCode: '38850',
  },
  'houlton-me': {
    name: 'Houlton',
    postalCode: '04730',
  },
  'houlton-wi': {
    name: 'Houlton',
    postalCode: '54082',
  },
  'houma-la': {
    name: 'Houma',
    postalCode: '70360',
  },
  'housatonic-ma': {
    name: 'Housatonic',
    postalCode: '01236',
  },
  'house-nm': {
    name: 'House',
    postalCode: '88121',
  },
  'house-springs-mo': {
    name: 'House Springs',
    postalCode: '63051',
  },
  'houston-al': {
    name: 'Houston',
    postalCode: '35572',
  },
  'houston-ar': {
    name: 'Houston',
    postalCode: '72070',
  },
  'houston-de': {
    name: 'Houston',
    postalCode: '19954',
  },
  'houston-mn': {
    name: 'Houston',
    postalCode: '55943',
  },
  'houston-mo': {
    name: 'Houston',
    postalCode: '65483',
  },
  'houston-ms': {
    name: 'Houston',
    postalCode: '38851',
  },
  'houston-oh': {
    name: 'Houston',
    postalCode: '45333',
  },
  'houston-pa': {
    name: 'Houston',
    postalCode: '15342',
  },
  'houston-tx': {
    name: 'Houston',
    postalCode: '77002',
  },
  'houstonia-mo': {
    name: 'Houstonia',
    postalCode: '65333',
  },
  'houtzdale-pa': {
    name: 'Houtzdale',
    postalCode: '16651',
  },
  'hoven-sd': {
    name: 'Hoven',
    postalCode: '57450',
  },
  'hovland-mn': {
    name: 'Hovland',
    postalCode: '55606',
  },
  'howard-co': {
    name: 'Howard',
    postalCode: '81233',
  },
  'howard-ks': {
    name: 'Howard',
    postalCode: '67349',
  },
  'howard-oh': {
    name: 'Howard',
    postalCode: '43028',
  },
  'howard-pa': {
    name: 'Howard',
    postalCode: '16841',
  },
  'howard-sd': {
    name: 'Howard',
    postalCode: '57349',
  },
  'howard-beach-ny': {
    name: 'Howard Beach',
    postalCode: '11414',
  },
  'howard-city-mi': {
    name: 'Howard City',
    postalCode: '49329',
  },
  'howard-lake-mn': {
    name: 'Howard Lake',
    postalCode: '55349',
  },
  'howardsville-va': {
    name: 'Howardsville',
    postalCode: '24562',
  },
  'howe-id': {
    name: 'Howe',
    postalCode: '83244',
  },
  'howe-in': {
    name: 'Howe',
    postalCode: '46746',
  },
  'howe-ok': {
    name: 'Howe',
    postalCode: '74940',
  },
  'howe-tx': {
    name: 'Howe',
    postalCode: '75459',
  },
  'howell-mi': {
    name: 'Howell',
    postalCode: '48843',
  },
  'howell-nj': {
    name: 'Howell',
    postalCode: '07731',
  },
  'howells-ne': {
    name: 'Howells',
    postalCode: '68641',
  },
  'howes-sd': {
    name: 'Howes',
    postalCode: '57748',
  },
  'howes-cave-ny': {
    name: 'Howes Cave',
    postalCode: '12092',
  },
  'howey-in-the-hills-fl': {
    name: 'Howey In The Hills',
    postalCode: '34737',
  },
  'howland-me': {
    name: 'Howland',
    postalCode: '04448',
  },
  'hoxie-ar': {
    name: 'Hoxie',
    postalCode: '72433',
  },
  'hoxie-ks': {
    name: 'Hoxie',
    postalCode: '67740',
  },
  'hoyleton-il': {
    name: 'Hoyleton',
    postalCode: '62803',
  },
  'hoyt-ks': {
    name: 'Hoyt',
    postalCode: '66440',
  },
  'hoyt-lakes-mn': {
    name: 'Hoyt Lakes',
    postalCode: '55750',
  },
  'huachuca-city-az': {
    name: 'Huachuca City',
    postalCode: '85616',
  },
  'hubbard-ia': {
    name: 'Hubbard',
    postalCode: '50122',
  },
  'hubbard-ne': {
    name: 'Hubbard',
    postalCode: '68741',
  },
  'hubbard-oh': {
    name: 'Hubbard',
    postalCode: '44425',
  },
  'hubbard-or': {
    name: 'Hubbard',
    postalCode: '97032',
  },
  'hubbard-tx': {
    name: 'Hubbard',
    postalCode: '76648',
  },
  'hubbard-lake-mi': {
    name: 'Hubbard Lake',
    postalCode: '49747',
  },
  'hubbardston-ma': {
    name: 'Hubbardston',
    postalCode: '01452',
  },
  'hubbardston-mi': {
    name: 'Hubbardston',
    postalCode: '48845',
  },
  'hubbardsville-ny': {
    name: 'Hubbardsville',
    postalCode: '13355',
  },
  'hubbell-ne': {
    name: 'Hubbell',
    postalCode: '68375',
  },
  'hubert-nc': {
    name: 'Hubert',
    postalCode: '28539',
  },
  'hubertus-wi': {
    name: 'Hubertus',
    postalCode: '53033',
  },
  'huddleston-va': {
    name: 'Huddleston',
    postalCode: '24104',
  },
  'huddy-ky': {
    name: 'Huddy',
    postalCode: '41535',
  },
  'hudgins-va': {
    name: 'Hudgins',
    postalCode: '23076',
  },
  'hudson-co': {
    name: 'Hudson',
    postalCode: '80642',
  },
  'hudson-fl': {
    name: 'Hudson',
    postalCode: '34667',
  },
  'hudson-ia': {
    name: 'Hudson',
    postalCode: '50643',
  },
  'hudson-il': {
    name: 'Hudson',
    postalCode: '61748',
  },
  'hudson-in': {
    name: 'Hudson',
    postalCode: '46747',
  },
  'hudson-ks': {
    name: 'Hudson',
    postalCode: '67545',
  },
  'hudson-ky': {
    name: 'Hudson',
    postalCode: '40145',
  },
  'hudson-ma': {
    name: 'Hudson',
    postalCode: '01749',
  },
  'hudson-me': {
    name: 'Hudson',
    postalCode: '04449',
  },
  'hudson-mi': {
    name: 'Hudson',
    postalCode: '49247',
  },
  'hudson-nc': {
    name: 'Hudson',
    postalCode: '28638',
  },
  'hudson-nh': {
    name: 'Hudson',
    postalCode: '03051',
  },
  'hudson-ny': {
    name: 'Hudson',
    postalCode: '12534',
  },
  'hudson-oh': {
    name: 'Hudson',
    postalCode: '44236',
  },
  'hudson-sd': {
    name: 'Hudson',
    postalCode: '57034',
  },
  'hudson-wi': {
    name: 'Hudson',
    postalCode: '54016',
  },
  'hudson-falls-ny': {
    name: 'Hudson Falls',
    postalCode: '12839',
  },
  'hudsonville-mi': {
    name: 'Hudsonville',
    postalCode: '49426',
  },
  'hueysville-ky': {
    name: 'Hueysville',
    postalCode: '41640',
  },
  'huffman-tx': {
    name: 'Huffman',
    postalCode: '77336',
  },
  'huger-sc': {
    name: 'Huger',
    postalCode: '29450',
  },
  'huggins-mo': {
    name: 'Huggins',
    postalCode: '65484',
  },
  'hughes-ar': {
    name: 'Hughes',
    postalCode: '72348',
  },
  'hughes-springs-tx': {
    name: 'Hughes Springs',
    postalCode: '75656',
  },
  'hughesville-md': {
    name: 'Hughesville',
    postalCode: '20637',
  },
  'hughesville-mo': {
    name: 'Hughesville',
    postalCode: '65334',
  },
  'hughesville-pa': {
    name: 'Hughesville',
    postalCode: '17737',
  },
  'hughson-ca': {
    name: 'Hughson',
    postalCode: '95326',
  },
  'hugo-co': {
    name: 'Hugo',
    postalCode: '80821',
  },
  'hugo-mn': {
    name: 'Hugo',
    postalCode: '55038',
  },
  'hugo-ok': {
    name: 'Hugo',
    postalCode: '74743',
  },
  'hugoton-ks': {
    name: 'Hugoton',
    postalCode: '67951',
  },
  'huguenot-ny': {
    name: 'Huguenot',
    postalCode: '12746',
  },
  'hulbert-ok': {
    name: 'Hulbert',
    postalCode: '74441',
  },
  'hulen-ky': {
    name: 'Hulen',
    postalCode: '40845',
  },
  'hulett-wy': {
    name: 'Hulett',
    postalCode: '82720',
  },
  'hull-ga': {
    name: 'Hull',
    postalCode: '30646',
  },
  'hull-ia': {
    name: 'Hull',
    postalCode: '51239',
  },
  'hull-il': {
    name: 'Hull',
    postalCode: '62343',
  },
  'hull-ma': {
    name: 'Hull',
    postalCode: '02045',
  },
  'hull-tx': {
    name: 'Hull',
    postalCode: '77564',
  },
  'humacao-pr': {
    name: 'Humacao',
    postalCode: '00791',
  },
  'humansville-mo': {
    name: 'Humansville',
    postalCode: '65674',
  },
  'humbird-wi': {
    name: 'Humbird',
    postalCode: '54746',
  },
  'humble-tx': {
    name: 'Humble',
    postalCode: '77338',
  },
  'humboldt-ia': {
    name: 'Humboldt',
    postalCode: '50548',
  },
  'humboldt-il': {
    name: 'Humboldt',
    postalCode: '61931',
  },
  'humboldt-ks': {
    name: 'Humboldt',
    postalCode: '66748',
  },
  'humboldt-ne': {
    name: 'Humboldt',
    postalCode: '68376',
  },
  'humboldt-sd': {
    name: 'Humboldt',
    postalCode: '57035',
  },
  'humboldt-tn': {
    name: 'Humboldt',
    postalCode: '38343',
  },
  'hume-ca': {
    name: 'Hume',
    postalCode: '93628',
  },
  'hume-il': {
    name: 'Hume',
    postalCode: '61932',
  },
  'hume-mo': {
    name: 'Hume',
    postalCode: '64752',
  },
  'hume-va': {
    name: 'Hume',
    postalCode: '22639',
  },
  'humeston-ia': {
    name: 'Humeston',
    postalCode: '50123',
  },
  'hummelstown-pa': {
    name: 'Hummelstown',
    postalCode: '17036',
  },
  'humnoke-ar': {
    name: 'Humnoke',
    postalCode: '72072',
  },
  'humphrey-ar': {
    name: 'Humphrey',
    postalCode: '72073',
  },
  'humphrey-ne': {
    name: 'Humphrey',
    postalCode: '68642',
  },
  'humphreys-mo': {
    name: 'Humphreys',
    postalCode: '64646',
  },
  'humptulips-wa': {
    name: 'Humptulips',
    postalCode: '98552',
  },
  'hundred-wv': {
    name: 'Hundred',
    postalCode: '26575',
  },
  'hunker-pa': {
    name: 'Hunker',
    postalCode: '15639',
  },
  'hunlock-creek-pa': {
    name: 'Hunlock Creek',
    postalCode: '18621',
  },
  'hunnewell-mo': {
    name: 'Hunnewell',
    postalCode: '63443',
  },
  'hunt-ny': {
    name: 'Hunt',
    postalCode: '14846',
  },
  'hunt-tx': {
    name: 'Hunt',
    postalCode: '78024',
  },
  'hunt-valley-md': {
    name: 'Hunt Valley',
    postalCode: '21031',
  },
  'hunter-ks': {
    name: 'Hunter',
    postalCode: '67452',
  },
  'hunter-nd': {
    name: 'Hunter',
    postalCode: '58048',
  },
  'hunter-ny': {
    name: 'Hunter',
    postalCode: '12442',
  },
  'hunter-ok': {
    name: 'Hunter',
    postalCode: '74640',
  },
  'hunters-wa': {
    name: 'Hunters',
    postalCode: '99137',
  },
  'huntersville-nc': {
    name: 'Huntersville',
    postalCode: '28078',
  },
  'huntertown-in': {
    name: 'Huntertown',
    postalCode: '46748',
  },
  'huntingburg-in': {
    name: 'Huntingburg',
    postalCode: '47542',
  },
  'huntingdon-pa': {
    name: 'Huntingdon',
    postalCode: '16652',
  },
  'huntingdon-tn': {
    name: 'Huntingdon',
    postalCode: '38344',
  },
  'huntingdon-valley-pa': {
    name: 'Huntingdon Valley',
    postalCode: '19006',
  },
  'huntington-ar': {
    name: 'Huntington',
    postalCode: '72940',
  },
  'huntington-in': {
    name: 'Huntington',
    postalCode: '46750',
  },
  'huntington-ma': {
    name: 'Huntington',
    postalCode: '01050',
  },
  'huntington-ny': {
    name: 'Huntington',
    postalCode: '11743',
  },
  'huntington-or': {
    name: 'Huntington',
    postalCode: '97907',
  },
  'huntington-tx': {
    name: 'Huntington',
    postalCode: '75949',
  },
  'huntington-vt': {
    name: 'Huntington',
    postalCode: '05462',
  },
  'huntington-wv': {
    name: 'Huntington',
    postalCode: '25701',
  },
  'huntington-beach-ca': {
    name: 'Huntington Beach',
    postalCode: '92646',
  },
  'huntington-mills-pa': {
    name: 'Huntington Mills',
    postalCode: '18622',
  },
  'huntington-park-ca': {
    name: 'Huntington Park',
    postalCode: '90255',
  },
  'huntington-station-ny': {
    name: 'Huntington Station',
    postalCode: '11746',
  },
  'huntington-woods-mi': {
    name: 'Huntington Woods',
    postalCode: '48070',
  },
  'huntingtown-md': {
    name: 'Huntingtown',
    postalCode: '20639',
  },
  'huntland-tn': {
    name: 'Huntland',
    postalCode: '37345',
  },
  'huntley-il': {
    name: 'Huntley',
    postalCode: '60142',
  },
  'huntley-mt': {
    name: 'Huntley',
    postalCode: '59037',
  },
  'huntly-va': {
    name: 'Huntly',
    postalCode: '22640',
  },
  'huntsburg-oh': {
    name: 'Huntsburg',
    postalCode: '44046',
  },
  'huntsville-al': {
    name: 'Huntsville',
    postalCode: '35801',
  },
  'huntsville-ar': {
    name: 'Huntsville',
    postalCode: '72740',
  },
  'huntsville-il': {
    name: 'Huntsville',
    postalCode: '62344',
  },
  'huntsville-mo': {
    name: 'Huntsville',
    postalCode: '65259',
  },
  'huntsville-oh': {
    name: 'Huntsville',
    postalCode: '43324',
  },
  'huntsville-tn': {
    name: 'Huntsville',
    postalCode: '37756',
  },
  'huntsville-tx': {
    name: 'Huntsville',
    postalCode: '77320',
  },
  'huntsville-ut': {
    name: 'Huntsville',
    postalCode: '84317',
  },
  'hurdland-mo': {
    name: 'Hurdland',
    postalCode: '63547',
  },
  'hurdle-mills-nc': {
    name: 'Hurdle Mills',
    postalCode: '27541',
  },
  'hurdsfield-nd': {
    name: 'Hurdsfield',
    postalCode: '58451',
  },
  'hurlburt-field-fl': {
    name: 'Hurlburt Field',
    postalCode: '32544',
  },
  'hurley-nm': {
    name: 'Hurley',
    postalCode: '88043',
  },
  'hurley-ny': {
    name: 'Hurley',
    postalCode: '12443',
  },
  'hurley-sd': {
    name: 'Hurley',
    postalCode: '57036',
  },
  'hurley-va': {
    name: 'Hurley',
    postalCode: '24620',
  },
  'hurley-wi': {
    name: 'Hurley',
    postalCode: '54534',
  },
  'hurleyville-ny': {
    name: 'Hurleyville',
    postalCode: '12747',
  },
  'hurlock-md': {
    name: 'Hurlock',
    postalCode: '21643',
  },
  'huron-oh': {
    name: 'Huron',
    postalCode: '44839',
  },
  'huron-sd': {
    name: 'Huron',
    postalCode: '57350',
  },
  'huron-tn': {
    name: 'Huron',
    postalCode: '38345',
  },
  'hurricane-ut': {
    name: 'Hurricane',
    postalCode: '84737',
  },
  'hurricane-wv': {
    name: 'Hurricane',
    postalCode: '25526',
  },
  'hurricane-mills-tn': {
    name: 'Hurricane Mills',
    postalCode: '37078',
  },
  'hurst-tx': {
    name: 'Hurst',
    postalCode: '76053',
  },
  'hurt-va': {
    name: 'Hurt',
    postalCode: '24563',
  },
  'hurtsboro-al': {
    name: 'Hurtsboro',
    postalCode: '36860',
  },
  'huson-mt': {
    name: 'Huson',
    postalCode: '59846',
  },
  'husser-la': {
    name: 'Husser',
    postalCode: '70442',
  },
  'hustisford-wi': {
    name: 'Hustisford',
    postalCode: '53034',
  },
  'hustle-va': {
    name: 'Hustle',
    postalCode: '22476',
  },
  'hustontown-pa': {
    name: 'Hustontown',
    postalCode: '17229',
  },
  'hustonville-ky': {
    name: 'Hustonville',
    postalCode: '40437',
  },
  'hutchins-tx': {
    name: 'Hutchins',
    postalCode: '75141',
  },
  'hutchinson-ks': {
    name: 'Hutchinson',
    postalCode: '67501',
  },
  'hutchinson-mn': {
    name: 'Hutchinson',
    postalCode: '55350',
  },
  'hutsonville-il': {
    name: 'Hutsonville',
    postalCode: '62433',
  },
  'huttig-ar': {
    name: 'Huttig',
    postalCode: '71747',
  },
  'hutto-tx': {
    name: 'Hutto',
    postalCode: '78634',
  },
  'huttonsville-wv': {
    name: 'Huttonsville',
    postalCode: '26273',
  },
  'huxley-ia': {
    name: 'Huxley',
    postalCode: '50124',
  },
  'hyannis-ma': {
    name: 'Hyannis',
    postalCode: '02601',
  },
  'hyannis-ne': {
    name: 'Hyannis',
    postalCode: '69350',
  },
  'hyattsville-md': {
    name: 'Hyattsville',
    postalCode: '20781',
  },
  'hyattville-wy': {
    name: 'Hyattville',
    postalCode: '82428',
  },
  'hyde-park-ma': {
    name: 'Hyde Park',
    postalCode: '02136',
  },
  'hyde-park-ny': {
    name: 'Hyde Park',
    postalCode: '12538',
  },
  'hyde-park-pa': {
    name: 'Hyde Park',
    postalCode: '15641',
  },
  'hyde-park-ut': {
    name: 'Hyde Park',
    postalCode: '84318',
  },
  'hyde-park-vt': {
    name: 'Hyde Park',
    postalCode: '05655',
  },
  'hyden-ky': {
    name: 'Hyden',
    postalCode: '41749',
  },
  'hydes-md': {
    name: 'Hydes',
    postalCode: '21082',
  },
  'hydesville-ca': {
    name: 'Hydesville',
    postalCode: '95547',
  },
  'hydro-ok': {
    name: 'Hydro',
    postalCode: '73048',
  },
  'hye-tx': {
    name: 'Hye',
    postalCode: '78635',
  },
  'hyndman-pa': {
    name: 'Hyndman',
    postalCode: '15545',
  },
  'hyrum-ut': {
    name: 'Hyrum',
    postalCode: '84319',
  },
  'hysham-mt': {
    name: 'Hysham',
    postalCode: '59038',
  },
  'iaeger-wv': {
    name: 'Iaeger',
    postalCode: '24844',
  },
  'ibapah-ut': {
    name: 'Ibapah',
    postalCode: '84034',
  },
  'iberia-mo': {
    name: 'Iberia',
    postalCode: '65486',
  },
  'ickesburg-pa': {
    name: 'Ickesburg',
    postalCode: '17037',
  },
  'ida-ar': {
    name: 'Ida',
    postalCode: '72546',
  },
  'ida-la': {
    name: 'Ida',
    postalCode: '71044',
  },
  'ida-mi': {
    name: 'Ida',
    postalCode: '48140',
  },
  'ida-grove-ia': {
    name: 'Ida Grove',
    postalCode: '51445',
  },
  'idabel-ok': {
    name: 'Idabel',
    postalCode: '74745',
  },
  'idaho-city-id': {
    name: 'Idaho City',
    postalCode: '83631',
  },
  'idaho-falls-id': {
    name: 'Idaho Falls',
    postalCode: '83401',
  },
  'idaho-springs-co': {
    name: 'Idaho Springs',
    postalCode: '80452',
  },
  'idalia-co': {
    name: 'Idalia',
    postalCode: '80735',
  },
  'idalou-tx': {
    name: 'Idalou',
    postalCode: '79329',
  },
  'idanha-or': {
    name: 'Idanha',
    postalCode: '97350',
  },
  'idaville-in': {
    name: 'Idaville',
    postalCode: '47950',
  },
  'ideal-ga': {
    name: 'Ideal',
    postalCode: '31041',
  },
  'ideal-sd': {
    name: 'Ideal',
    postalCode: '57541',
  },
  'ider-al': {
    name: 'Ider',
    postalCode: '35981',
  },
  'idlewild-mi': {
    name: 'Idlewild',
    postalCode: '49642',
  },
  'idleyld-park-or': {
    name: 'Idleyld Park',
    postalCode: '97447',
  },
  'ignacio-co': {
    name: 'Ignacio',
    postalCode: '81137',
  },
  'igo-ca': {
    name: 'Igo',
    postalCode: '96047',
  },
  'ijamsville-md': {
    name: 'Ijamsville',
    postalCode: '21754',
  },
  'iliff-co': {
    name: 'Iliff',
    postalCode: '80736',
  },
  'ilion-ny': {
    name: 'Ilion',
    postalCode: '13357',
  },
  'illinois-city-il': {
    name: 'Illinois City',
    postalCode: '61259',
  },
  'illiopolis-il': {
    name: 'Illiopolis',
    postalCode: '62539',
  },
  'ilwaco-wa': {
    name: 'Ilwaco',
    postalCode: '98624',
  },
  'imbler-or': {
    name: 'Imbler',
    postalCode: '97841',
  },
  'imboden-ar': {
    name: 'Imboden',
    postalCode: '72434',
  },
  'imlay-nv': {
    name: 'Imlay',
    postalCode: '89418',
  },
  'imlay-city-mi': {
    name: 'Imlay City',
    postalCode: '48444',
  },
  'imler-pa': {
    name: 'Imler',
    postalCode: '16655',
  },
  'immokalee-fl': {
    name: 'Immokalee',
    postalCode: '34142',
  },
  'imnaha-or': {
    name: 'Imnaha',
    postalCode: '97842',
  },
  'imogene-ia': {
    name: 'Imogene',
    postalCode: '51645',
  },
  'imperial-ca': {
    name: 'Imperial',
    postalCode: '92251',
  },
  'imperial-mo': {
    name: 'Imperial',
    postalCode: '63052',
  },
  'imperial-ne': {
    name: 'Imperial',
    postalCode: '69033',
  },
  'imperial-pa': {
    name: 'Imperial',
    postalCode: '15126',
  },
  'imperial-beach-ca': {
    name: 'Imperial Beach',
    postalCode: '91932',
  },
  'ina-il': {
    name: 'Ina',
    postalCode: '62846',
  },
  'inavale-ne': {
    name: 'Inavale',
    postalCode: '68952',
  },
  'inchelium-wa': {
    name: 'Inchelium',
    postalCode: '99138',
  },
  'incline-village-nv': {
    name: 'Incline Village',
    postalCode: '89451',
  },
  'independence-ca': {
    name: 'Independence',
    postalCode: '93526',
  },
  'independence-ia': {
    name: 'Independence',
    postalCode: '50644',
  },
  'independence-ks': {
    name: 'Independence',
    postalCode: '67301',
  },
  'independence-ky': {
    name: 'Independence',
    postalCode: '41051',
  },
  'independence-la': {
    name: 'Independence',
    postalCode: '70443',
  },
  'independence-mo': {
    name: 'Independence',
    postalCode: '64050',
  },
  'independence-oh': {
    name: 'Independence',
    postalCode: '44131',
  },
  'independence-or': {
    name: 'Independence',
    postalCode: '97351',
  },
  'independence-va': {
    name: 'Independence',
    postalCode: '24348',
  },
  'independence-wi': {
    name: 'Independence',
    postalCode: '54747',
  },
  'independence-wv': {
    name: 'Independence',
    postalCode: '26374',
  },
  'indiahoma-ok': {
    name: 'Indiahoma',
    postalCode: '73552',
  },
  'indialantic-fl': {
    name: 'Indialantic',
    postalCode: '32903',
  },
  'indian-ak': {
    name: 'Indian',
    postalCode: '99540',
  },
  'indian-head-md': {
    name: 'Indian Head',
    postalCode: '20640',
  },
  'indian-head-pa': {
    name: 'Indian Head',
    postalCode: '15446',
  },
  'indian-lake-ny': {
    name: 'Indian Lake',
    postalCode: '12842',
  },
  'indian-mound-tn': {
    name: 'Indian Mound',
    postalCode: '37079',
  },
  'indian-orchard-ma': {
    name: 'Indian Orchard',
    postalCode: '01151',
  },
  'indian-river-mi': {
    name: 'Indian River',
    postalCode: '49749',
  },
  'indian-rocks-beach-fl': {
    name: 'Indian Rocks Beach',
    postalCode: '33785',
  },
  'indian-trail-nc': {
    name: 'Indian Trail',
    postalCode: '28079',
  },
  'indian-valley-id': {
    name: 'Indian Valley',
    postalCode: '83632',
  },
  'indian-valley-va': {
    name: 'Indian Valley',
    postalCode: '24105',
  },
  'indian-wells-ca': {
    name: 'Indian Wells',
    postalCode: '92210',
  },
  'indiana-pa': {
    name: 'Indiana',
    postalCode: '15701',
  },
  'indianapolis-in': {
    name: 'Indianapolis',
    postalCode: '46201',
  },
  'indianola-ia': {
    name: 'Indianola',
    postalCode: '50125',
  },
  'indianola-il': {
    name: 'Indianola',
    postalCode: '61850',
  },
  'indianola-ms': {
    name: 'Indianola',
    postalCode: '38751',
  },
  'indianola-ne': {
    name: 'Indianola',
    postalCode: '69034',
  },
  'indianola-ok': {
    name: 'Indianola',
    postalCode: '74442',
  },
  'indianola-pa': {
    name: 'Indianola',
    postalCode: '15051',
  },
  'indianola-wa': {
    name: 'Indianola',
    postalCode: '98342',
  },
  'indiantown-fl': {
    name: 'Indiantown',
    postalCode: '34956',
  },
  'indio-ca': {
    name: 'Indio',
    postalCode: '92201',
  },
  'indore-wv': {
    name: 'Indore',
    postalCode: '25111',
  },
  'industry-il': {
    name: 'Industry',
    postalCode: '61440',
  },
  'industry-pa': {
    name: 'Industry',
    postalCode: '15052',
  },
  'industry-tx': {
    name: 'Industry',
    postalCode: '78944',
  },
  'inez-ky': {
    name: 'Inez',
    postalCode: '41224',
  },
  'inez-tx': {
    name: 'Inez',
    postalCode: '77968',
  },
  'ingalls-in': {
    name: 'Ingalls',
    postalCode: '46048',
  },
  'ingalls-ks': {
    name: 'Ingalls',
    postalCode: '67853',
  },
  'ingalls-mi': {
    name: 'Ingalls',
    postalCode: '49848',
  },
  'ingleside-il': {
    name: 'Ingleside',
    postalCode: '60041',
  },
  'ingleside-md': {
    name: 'Ingleside',
    postalCode: '21644',
  },
  'ingleside-tx': {
    name: 'Ingleside',
    postalCode: '78362',
  },
  'inglewood-ca': {
    name: 'Inglewood',
    postalCode: '90301',
  },
  'inglis-fl': {
    name: 'Inglis',
    postalCode: '34449',
  },
  'ingomar-mt': {
    name: 'Ingomar',
    postalCode: '59039',
  },
  'ingraham-il': {
    name: 'Ingraham',
    postalCode: '62434',
  },
  'ingram-tx': {
    name: 'Ingram',
    postalCode: '78025',
  },
  'inkom-id': {
    name: 'Inkom',
    postalCode: '83245',
  },
  'inkster-mi': {
    name: 'Inkster',
    postalCode: '48141',
  },
  'inkster-nd': {
    name: 'Inkster',
    postalCode: '58244',
  },
  'inland-ne': {
    name: 'Inland',
    postalCode: '68954',
  },
  'inlet-ny': {
    name: 'Inlet',
    postalCode: '13360',
  },
  'inlet-beach-fl': {
    name: 'Inlet Beach',
    postalCode: '32461',
  },
  'inman-ks': {
    name: 'Inman',
    postalCode: '67546',
  },
  'inman-ne': {
    name: 'Inman',
    postalCode: '68742',
  },
  'inman-sc': {
    name: 'Inman',
    postalCode: '29349',
  },
  'inola-ok': {
    name: 'Inola',
    postalCode: '74036',
  },
  'interior-sd': {
    name: 'Interior',
    postalCode: '57750',
  },
  'interlachen-fl': {
    name: 'Interlachen',
    postalCode: '32148',
  },
  'interlaken-ny': {
    name: 'Interlaken',
    postalCode: '14847',
  },
  'interlochen-mi': {
    name: 'Interlochen',
    postalCode: '49643',
  },
  'international-falls-mn': {
    name: 'International Falls',
    postalCode: '56649',
  },
  'intervale-nh': {
    name: 'Intervale',
    postalCode: '03845',
  },
  'inver-grove-heights-mn': {
    name: 'Inver Grove Heights',
    postalCode: '55076',
  },
  'inverness-ca': {
    name: 'Inverness',
    postalCode: '94937',
  },
  'inverness-fl': {
    name: 'Inverness',
    postalCode: '34450',
  },
  'inverness-ms': {
    name: 'Inverness',
    postalCode: '38753',
  },
  'inverness-mt': {
    name: 'Inverness',
    postalCode: '59530',
  },
  'inwood-ia': {
    name: 'Inwood',
    postalCode: '51240',
  },
  'inwood-ny': {
    name: 'Inwood',
    postalCode: '11096',
  },
  'inwood-wv': {
    name: 'Inwood',
    postalCode: '25428',
  },
  'inyokern-ca': {
    name: 'Inyokern',
    postalCode: '93527',
  },
  'iola-ks': {
    name: 'Iola',
    postalCode: '66749',
  },
  'iola-tx': {
    name: 'Iola',
    postalCode: '77861',
  },
  'iola-wi': {
    name: 'Iola',
    postalCode: '54945',
  },
  'iona-id': {
    name: 'Iona',
    postalCode: '83427',
  },
  'iona-mn': {
    name: 'Iona',
    postalCode: '56141',
  },
  'ione-ca': {
    name: 'Ione',
    postalCode: '95640',
  },
  'ione-or': {
    name: 'Ione',
    postalCode: '97843',
  },
  'ione-wa': {
    name: 'Ione',
    postalCode: '99139',
  },
  'ionia-ia': {
    name: 'Ionia',
    postalCode: '50645',
  },
  'ionia-mi': {
    name: 'Ionia',
    postalCode: '48846',
  },
  'ionia-mo': {
    name: 'Ionia',
    postalCode: '65335',
  },
  'ionia-ny': {
    name: 'Ionia',
    postalCode: '14475',
  },
  'iota-la': {
    name: 'Iota',
    postalCode: '70543',
  },
  'iowa-la': {
    name: 'Iowa',
    postalCode: '70647',
  },
  'iowa-city-ia': {
    name: 'Iowa City',
    postalCode: '52240',
  },
  'iowa-falls-ia': {
    name: 'Iowa Falls',
    postalCode: '50126',
  },
  'iowa-park-tx': {
    name: 'Iowa Park',
    postalCode: '76367',
  },
  'ipava-il': {
    name: 'Ipava',
    postalCode: '61441',
  },
  'ipswich-ma': {
    name: 'Ipswich',
    postalCode: '01938',
  },
  'ipswich-sd': {
    name: 'Ipswich',
    postalCode: '57451',
  },
  'ira-ia': {
    name: 'Ira',
    postalCode: '50127',
  },
  'ira-tx': {
    name: 'Ira',
    postalCode: '79527',
  },
  'irasburg-vt': {
    name: 'Irasburg',
    postalCode: '05845',
  },
  'iredell-tx': {
    name: 'Iredell',
    postalCode: '76649',
  },
  'ireland-wv': {
    name: 'Ireland',
    postalCode: '26376',
  },
  'irene-sd': {
    name: 'Irene',
    postalCode: '57037',
  },
  'ireton-ia': {
    name: 'Ireton',
    postalCode: '51027',
  },
  'irma-wi': {
    name: 'Irma',
    postalCode: '54442',
  },
  'irmo-sc': {
    name: 'Irmo',
    postalCode: '29063',
  },
  'iron-mn': {
    name: 'Iron',
    postalCode: '55751',
  },
  'iron-city-ga': {
    name: 'Iron City',
    postalCode: '39859',
  },
  'iron-city-tn': {
    name: 'Iron City',
    postalCode: '38463',
  },
  'iron-mountain-mi': {
    name: 'Iron Mountain',
    postalCode: '49801',
  },
  'iron-ridge-wi': {
    name: 'Iron Ridge',
    postalCode: '53035',
  },
  'iron-river-mi': {
    name: 'Iron River',
    postalCode: '49935',
  },
  'iron-river-wi': {
    name: 'Iron River',
    postalCode: '54847',
  },
  'iron-station-nc': {
    name: 'Iron Station',
    postalCode: '28080',
  },
  'irondale-mo': {
    name: 'Irondale',
    postalCode: '63648',
  },
  'irondale-oh': {
    name: 'Irondale',
    postalCode: '43932',
  },
  'irons-mi': {
    name: 'Irons',
    postalCode: '49644',
  },
  'ironside-or': {
    name: 'Ironside',
    postalCode: '97908',
  },
  'ironton-mn': {
    name: 'Ironton',
    postalCode: '56455',
  },
  'ironton-mo': {
    name: 'Ironton',
    postalCode: '63650',
  },
  'ironton-oh': {
    name: 'Ironton',
    postalCode: '45638',
  },
  'ironwood-mi': {
    name: 'Ironwood',
    postalCode: '49938',
  },
  'iroquois-sd': {
    name: 'Iroquois',
    postalCode: '57353',
  },
  'irrigon-or': {
    name: 'Irrigon',
    postalCode: '97844',
  },
  'irvine-ca': {
    name: 'Irvine',
    postalCode: '92602',
  },
  'irvine-ky': {
    name: 'Irvine',
    postalCode: '40336',
  },
  'irvine-pa': {
    name: 'Irvine',
    postalCode: '16329',
  },
  'irving-il': {
    name: 'Irving',
    postalCode: '62051',
  },
  'irving-ny': {
    name: 'Irving',
    postalCode: '14081',
  },
  'irving-tx': {
    name: 'Irving',
    postalCode: '75038',
  },
  'irvington-al': {
    name: 'Irvington',
    postalCode: '36544',
  },
  'irvington-ky': {
    name: 'Irvington',
    postalCode: '40146',
  },
  'irvington-nj': {
    name: 'Irvington',
    postalCode: '07111',
  },
  'irvington-ny': {
    name: 'Irvington',
    postalCode: '10533',
  },
  'irvington-va': {
    name: 'Irvington',
    postalCode: '22480',
  },
  'irvona-pa': {
    name: 'Irvona',
    postalCode: '16656',
  },
  'irwin-ia': {
    name: 'Irwin',
    postalCode: '51446',
  },
  'irwin-id': {
    name: 'Irwin',
    postalCode: '83428',
  },
  'irwin-oh': {
    name: 'Irwin',
    postalCode: '43029',
  },
  'irwin-pa': {
    name: 'Irwin',
    postalCode: '15642',
  },
  'irwinton-ga': {
    name: 'Irwinton',
    postalCode: '31042',
  },
  'isaban-wv': {
    name: 'Isaban',
    postalCode: '24846',
  },
  'isabel-ks': {
    name: 'Isabel',
    postalCode: '67065',
  },
  'isabel-sd': {
    name: 'Isabel',
    postalCode: '57633',
  },
  'isabela-pr': {
    name: 'Isabela',
    postalCode: '00662',
  },
  'isabella-mn': {
    name: 'Isabella',
    postalCode: '55607',
  },
  'isabella-mo': {
    name: 'Isabella',
    postalCode: '65676',
  },
  'isabella-ok': {
    name: 'Isabella',
    postalCode: '73747',
  },
  'isanti-mn': {
    name: 'Isanti',
    postalCode: '55040',
  },
  'iselin-nj': {
    name: 'Iselin',
    postalCode: '08830',
  },
  'ishpeming-mi': {
    name: 'Ishpeming',
    postalCode: '49849',
  },
  'islamorada-fl': {
    name: 'Islamorada',
    postalCode: '33036',
  },
  'island-ky': {
    name: 'Island',
    postalCode: '42350',
  },
  'island-city-ky': {
    name: 'Island City',
    postalCode: '41338',
  },
  'island-falls-me': {
    name: 'Island Falls',
    postalCode: '04747',
  },
  'island-lake-il': {
    name: 'Island Lake',
    postalCode: '60042',
  },
  'island-park-id': {
    name: 'Island Park',
    postalCode: '83429',
  },
  'island-park-ny': {
    name: 'Island Park',
    postalCode: '11558',
  },
  'island-pond-vt': {
    name: 'Island Pond',
    postalCode: '05846',
  },
  'islandia-ny': {
    name: 'Islandia',
    postalCode: '11749',
  },
  'islandton-sc': {
    name: 'Islandton',
    postalCode: '29929',
  },
  'isle-mn': {
    name: 'Isle',
    postalCode: '56342',
  },
  'isle-la-motte-vt': {
    name: 'Isle La Motte',
    postalCode: '05463',
  },
  'isle-of-palms-sc': {
    name: 'Isle Of Palms',
    postalCode: '29451',
  },
  'isle-of-springs-me': {
    name: 'Isle Of Springs',
    postalCode: '04549',
  },
  'isle-saint-george-oh': {
    name: 'Isle Saint George',
    postalCode: '43436',
  },
  'islesboro-me': {
    name: 'Islesboro',
    postalCode: '04848',
  },
  'isleton-ca': {
    name: 'Isleton',
    postalCode: '95641',
  },
  'islip-ny': {
    name: 'Islip',
    postalCode: '11751',
  },
  'islip-terrace-ny': {
    name: 'Islip Terrace',
    postalCode: '11752',
  },
  'ismay-mt': {
    name: 'Ismay',
    postalCode: '59336',
  },
  'isola-ms': {
    name: 'Isola',
    postalCode: '38754',
  },
  'isom-ky': {
    name: 'Isom',
    postalCode: '41824',
  },
  'isonville-ky': {
    name: 'Isonville',
    postalCode: '41149',
  },
  'issaquah-wa': {
    name: 'Issaquah',
    postalCode: '98027',
  },
  'issue-md': {
    name: 'Issue',
    postalCode: '20645',
  },
  'italy-tx': {
    name: 'Italy',
    postalCode: '76651',
  },
  'itasca-il': {
    name: 'Itasca',
    postalCode: '60143',
  },
  'itasca-tx': {
    name: 'Itasca',
    postalCode: '76055',
  },
  'ithaca-mi': {
    name: 'Ithaca',
    postalCode: '48847',
  },
  'ithaca-ne': {
    name: 'Ithaca',
    postalCode: '68033',
  },
  'ithaca-ny': {
    name: 'Ithaca',
    postalCode: '14850',
  },
  'itta-bena-ms': {
    name: 'Itta Bena',
    postalCode: '38941',
  },
  'iuka-il': {
    name: 'Iuka',
    postalCode: '62849',
  },
  'iuka-ks': {
    name: 'Iuka',
    postalCode: '67066',
  },
  'iuka-ms': {
    name: 'Iuka',
    postalCode: '38852',
  },
  'iva-sc': {
    name: 'Iva',
    postalCode: '29655',
  },
  'ivanhoe-ca': {
    name: 'Ivanhoe',
    postalCode: '93235',
  },
  'ivanhoe-mn': {
    name: 'Ivanhoe',
    postalCode: '56142',
  },
  'ivanhoe-nc': {
    name: 'Ivanhoe',
    postalCode: '28447',
  },
  'ivanhoe-tx': {
    name: 'Ivanhoe',
    postalCode: '75447',
  },
  'ivanhoe-va': {
    name: 'Ivanhoe',
    postalCode: '24350',
  },
  'ivel-ky': {
    name: 'Ivel',
    postalCode: '41642',
  },
  'ivesdale-il': {
    name: 'Ivesdale',
    postalCode: '61851',
  },
  'ivins-ut': {
    name: 'Ivins',
    postalCode: '84738',
  },
  'ivor-va': {
    name: 'Ivor',
    postalCode: '23866',
  },
  'ivoryton-ct': {
    name: 'Ivoryton',
    postalCode: '06442',
  },
  'ivydale-wv': {
    name: 'Ivydale',
    postalCode: '25113',
  },
  'ixonia-wi': {
    name: 'Ixonia',
    postalCode: '53036',
  },
  'jachin-al': {
    name: 'Jachin',
    postalCode: '36910',
  },
  'jack-al': {
    name: 'Jack',
    postalCode: '36346',
  },
  'jackhorn-ky': {
    name: 'Jackhorn',
    postalCode: '41825',
  },
  'jackman-me': {
    name: 'Jackman',
    postalCode: '04945',
  },
  'jackpot-nv': {
    name: 'Jackpot',
    postalCode: '89825',
  },
  'jacks-creek-tn': {
    name: 'Jacks Creek',
    postalCode: '38347',
  },
  'jacksboro-tn': {
    name: 'Jacksboro',
    postalCode: '37757',
  },
  'jacksboro-tx': {
    name: 'Jacksboro',
    postalCode: '76458',
  },
  'jackson-al': {
    name: 'Jackson',
    postalCode: '36501',
  },
  'jackson-ca': {
    name: 'Jackson',
    postalCode: '95642',
  },
  'jackson-ga': {
    name: 'Jackson',
    postalCode: '30233',
  },
  'jackson-ky': {
    name: 'Jackson',
    postalCode: '41339',
  },
  'jackson-la': {
    name: 'Jackson',
    postalCode: '70748',
  },
  'jackson-mi': {
    name: 'Jackson',
    postalCode: '49201',
  },
  'jackson-mn': {
    name: 'Jackson',
    postalCode: '56143',
  },
  'jackson-mo': {
    name: 'Jackson',
    postalCode: '63755',
  },
  'jackson-ms': {
    name: 'Jackson',
    postalCode: '39201',
  },
  'jackson-mt': {
    name: 'Jackson',
    postalCode: '59736',
  },
  'jackson-nc': {
    name: 'Jackson',
    postalCode: '27845',
  },
  'jackson-ne': {
    name: 'Jackson',
    postalCode: '68743',
  },
  'jackson-nh': {
    name: 'Jackson',
    postalCode: '03846',
  },
  'jackson-nj': {
    name: 'Jackson',
    postalCode: '08527',
  },
  'jackson-oh': {
    name: 'Jackson',
    postalCode: '45640',
  },
  'jackson-pa': {
    name: 'Jackson',
    postalCode: '18825',
  },
  'jackson-sc': {
    name: 'Jackson',
    postalCode: '29831',
  },
  'jackson-tn': {
    name: 'Jackson',
    postalCode: '38301',
  },
  'jackson-wi': {
    name: 'Jackson',
    postalCode: '53037',
  },
  'jackson-wy': {
    name: 'Jackson',
    postalCode: '83001',
  },
  'jackson-center-oh': {
    name: 'Jackson Center',
    postalCode: '45334',
  },
  'jackson-center-pa': {
    name: 'Jackson Center',
    postalCode: '16133',
  },
  'jackson-heights-ny': {
    name: 'Jackson Heights',
    postalCode: '11372',
  },
  'jackson-springs-nc': {
    name: 'Jackson Springs',
    postalCode: '27281',
  },
  'jacksonburg-wv': {
    name: 'Jacksonburg',
    postalCode: '26377',
  },
  'jacksons-gap-al': {
    name: 'Jacksons Gap',
    postalCode: '36861',
  },
  'jacksonville-al': {
    name: 'Jacksonville',
    postalCode: '36265',
  },
  'jacksonville-ar': {
    name: 'Jacksonville',
    postalCode: '72076',
  },
  'jacksonville-fl': {
    name: 'Jacksonville',
    postalCode: '32099',
  },
  'jacksonville-ga': {
    name: 'Jacksonville',
    postalCode: '31544',
  },
  'jacksonville-il': {
    name: 'Jacksonville',
    postalCode: '62650',
  },
  'jacksonville-mo': {
    name: 'Jacksonville',
    postalCode: '65260',
  },
  'jacksonville-nc': {
    name: 'Jacksonville',
    postalCode: '28540',
  },
  'jacksonville-or': {
    name: 'Jacksonville',
    postalCode: '97530',
  },
  'jacksonville-tx': {
    name: 'Jacksonville',
    postalCode: '75766',
  },
  'jacksonville-vt': {
    name: 'Jacksonville',
    postalCode: '05342',
  },
  'jacksonville-beach-fl': {
    name: 'Jacksonville Beach',
    postalCode: '32250',
  },
  'jacob-il': {
    name: 'Jacob',
    postalCode: '62950',
  },
  'jacobsburg-oh': {
    name: 'Jacobsburg',
    postalCode: '43933',
  },
  'jacobson-mn': {
    name: 'Jacobson',
    postalCode: '55752',
  },
  'jacumba-ca': {
    name: 'Jacumba',
    postalCode: '91934',
  },
  'jadwin-mo': {
    name: 'Jadwin',
    postalCode: '65501',
  },
  'jaffrey-nh': {
    name: 'Jaffrey',
    postalCode: '03452',
  },
  'jakin-ga': {
    name: 'Jakin',
    postalCode: '39861',
  },
  'jal-nm': {
    name: 'Jal',
    postalCode: '88252',
  },
  'jamaica-ia': {
    name: 'Jamaica',
    postalCode: '50128',
  },
  'jamaica-ny': {
    name: 'Jamaica',
    postalCode: '11430',
  },
  'jamaica-va': {
    name: 'Jamaica',
    postalCode: '23079',
  },
  'jamaica-vt': {
    name: 'Jamaica',
    postalCode: '05343',
  },
  'jamaica-plain-ma': {
    name: 'Jamaica Plain',
    postalCode: '02130',
  },
  'james-creek-pa': {
    name: 'James Creek',
    postalCode: '16657',
  },
  'jameson-mo': {
    name: 'Jameson',
    postalCode: '64647',
  },
  'jamesport-mo': {
    name: 'Jamesport',
    postalCode: '64648',
  },
  'jamestown-ca': {
    name: 'Jamestown',
    postalCode: '95327',
  },
  'jamestown-co': {
    name: 'Jamestown',
    postalCode: '80455',
  },
  'jamestown-in': {
    name: 'Jamestown',
    postalCode: '46147',
  },
  'jamestown-ks': {
    name: 'Jamestown',
    postalCode: '66948',
  },
  'jamestown-ky': {
    name: 'Jamestown',
    postalCode: '42629',
  },
  'jamestown-la': {
    name: 'Jamestown',
    postalCode: '71045',
  },
  'jamestown-mo': {
    name: 'Jamestown',
    postalCode: '65046',
  },
  'jamestown-nc': {
    name: 'Jamestown',
    postalCode: '27282',
  },
  'jamestown-nd': {
    name: 'Jamestown',
    postalCode: '58401',
  },
  'jamestown-ny': {
    name: 'Jamestown',
    postalCode: '14701',
  },
  'jamestown-oh': {
    name: 'Jamestown',
    postalCode: '45335',
  },
  'jamestown-pa': {
    name: 'Jamestown',
    postalCode: '16134',
  },
  'jamestown-ri': {
    name: 'Jamestown',
    postalCode: '02835',
  },
  'jamestown-sc': {
    name: 'Jamestown',
    postalCode: '29453',
  },
  'jamestown-tn': {
    name: 'Jamestown',
    postalCode: '38556',
  },
  'jamesville-nc': {
    name: 'Jamesville',
    postalCode: '27846',
  },
  'jamesville-ny': {
    name: 'Jamesville',
    postalCode: '13078',
  },
  'jamieson-or': {
    name: 'Jamieson',
    postalCode: '97909',
  },
  'jamison-pa': {
    name: 'Jamison',
    postalCode: '18929',
  },
  'jamul-ca': {
    name: 'Jamul',
    postalCode: '91935',
  },
  'jane-lew-wv': {
    name: 'Jane Lew',
    postalCode: '26378',
  },
  'janesville-ca': {
    name: 'Janesville',
    postalCode: '96114',
  },
  'janesville-ia': {
    name: 'Janesville',
    postalCode: '50647',
  },
  'janesville-mn': {
    name: 'Janesville',
    postalCode: '56048',
  },
  'janesville-wi': {
    name: 'Janesville',
    postalCode: '53545',
  },
  'jansen-ne': {
    name: 'Jansen',
    postalCode: '68377',
  },
  'jarales-nm': {
    name: 'Jarales',
    postalCode: '87023',
  },
  'jarratt-va': {
    name: 'Jarratt',
    postalCode: '23867',
  },
  'jarreau-la': {
    name: 'Jarreau',
    postalCode: '70749',
  },
  'jarrell-tx': {
    name: 'Jarrell',
    postalCode: '76537',
  },
  'jarrettsville-md': {
    name: 'Jarrettsville',
    postalCode: '21084',
  },
  'jarvisburg-nc': {
    name: 'Jarvisburg',
    postalCode: '27947',
  },
  'jasonville-in': {
    name: 'Jasonville',
    postalCode: '47438',
  },
  'jasper-al': {
    name: 'Jasper',
    postalCode: '35501',
  },
  'jasper-ar': {
    name: 'Jasper',
    postalCode: '72641',
  },
  'jasper-fl': {
    name: 'Jasper',
    postalCode: '32052',
  },
  'jasper-ga': {
    name: 'Jasper',
    postalCode: '30143',
  },
  'jasper-in': {
    name: 'Jasper',
    postalCode: '47546',
  },
  'jasper-mi': {
    name: 'Jasper',
    postalCode: '49248',
  },
  'jasper-mn': {
    name: 'Jasper',
    postalCode: '56144',
  },
  'jasper-mo': {
    name: 'Jasper',
    postalCode: '64755',
  },
  'jasper-ny': {
    name: 'Jasper',
    postalCode: '14855',
  },
  'jasper-tn': {
    name: 'Jasper',
    postalCode: '37347',
  },
  'jasper-tx': {
    name: 'Jasper',
    postalCode: '75951',
  },
  'java-sd': {
    name: 'Java',
    postalCode: '57452',
  },
  'java-va': {
    name: 'Java',
    postalCode: '24565',
  },
  'java-center-ny': {
    name: 'Java Center',
    postalCode: '14082',
  },
  'java-village-ny': {
    name: 'Java Village',
    postalCode: '14083',
  },
  'jay-fl': {
    name: 'Jay',
    postalCode: '32565',
  },
  'jay-me': {
    name: 'Jay',
    postalCode: '04239',
  },
  'jay-ny': {
    name: 'Jay',
    postalCode: '12941',
  },
  'jay-ok': {
    name: 'Jay',
    postalCode: '74346',
  },
  'jay-em-wy': {
    name: 'Jay Em',
    postalCode: '82219',
  },
  'jayess-ms': {
    name: 'Jayess',
    postalCode: '39641',
  },
  'jayton-tx': {
    name: 'Jayton',
    postalCode: '79528',
  },
  'jayuya-pr': {
    name: 'Jayuya',
    postalCode: '00664',
  },
  'jber-ak': {
    name: 'Jber',
    postalCode: '99505',
  },
  'jbphh-hi': {
    name: 'Jbphh',
    postalCode: '96853',
  },
  'jbsa-ft-sam-houston-tx': {
    name: 'Jbsa Ft Sam Houston',
    postalCode: '78234',
  },
  'jbsa-lackland-tx': {
    name: 'Jbsa Lackland',
    postalCode: '78236',
  },
  'jbsa-randolph-tx': {
    name: 'Jbsa Randolph',
    postalCode: '78150',
  },
  'jean-nv': {
    name: 'Jean',
    postalCode: '89019',
  },
  'jeanerette-la': {
    name: 'Jeanerette',
    postalCode: '70544',
  },
  'jeannette-pa': {
    name: 'Jeannette',
    postalCode: '15644',
  },
  'jeddo-mi': {
    name: 'Jeddo',
    postalCode: '48032',
  },
  'jeff-ky': {
    name: 'Jeff',
    postalCode: '41751',
  },
  'jeffers-mn': {
    name: 'Jeffers',
    postalCode: '56145',
  },
  'jefferson-ar': {
    name: 'Jefferson',
    postalCode: '72079',
  },
  'jefferson-co': {
    name: 'Jefferson',
    postalCode: '80456',
  },
  'jefferson-ga': {
    name: 'Jefferson',
    postalCode: '30549',
  },
  'jefferson-ia': {
    name: 'Jefferson',
    postalCode: '50129',
  },
  'jefferson-ma': {
    name: 'Jefferson',
    postalCode: '01522',
  },
  'jefferson-md': {
    name: 'Jefferson',
    postalCode: '21755',
  },
  'jefferson-me': {
    name: 'Jefferson',
    postalCode: '04348',
  },
  'jefferson-nc': {
    name: 'Jefferson',
    postalCode: '28640',
  },
  'jefferson-nh': {
    name: 'Jefferson',
    postalCode: '03583',
  },
  'jefferson-ny': {
    name: 'Jefferson',
    postalCode: '12093',
  },
  'jefferson-oh': {
    name: 'Jefferson',
    postalCode: '44047',
  },
  'jefferson-or': {
    name: 'Jefferson',
    postalCode: '97352',
  },
  'jefferson-pa': {
    name: 'Jefferson',
    postalCode: '15344',
  },
  'jefferson-sc': {
    name: 'Jefferson',
    postalCode: '29718',
  },
  'jefferson-sd': {
    name: 'Jefferson',
    postalCode: '57038',
  },
  'jefferson-tx': {
    name: 'Jefferson',
    postalCode: '75657',
  },
  'jefferson-wi': {
    name: 'Jefferson',
    postalCode: '53549',
  },
  'jefferson-city-mo': {
    name: 'Jefferson City',
    postalCode: '65101',
  },
  'jefferson-city-tn': {
    name: 'Jefferson City',
    postalCode: '37760',
  },
  'jefferson-valley-ny': {
    name: 'Jefferson Valley',
    postalCode: '10535',
  },
  'jeffersonton-va': {
    name: 'Jeffersonton',
    postalCode: '22724',
  },
  'jeffersonville-ga': {
    name: 'Jeffersonville',
    postalCode: '31044',
  },
  'jeffersonville-in': {
    name: 'Jeffersonville',
    postalCode: '47130',
  },
  'jeffersonville-ky': {
    name: 'Jeffersonville',
    postalCode: '40337',
  },
  'jeffersonville-ny': {
    name: 'Jeffersonville',
    postalCode: '12748',
  },
  'jeffersonville-oh': {
    name: 'Jeffersonville',
    postalCode: '43128',
  },
  'jeffersonville-vt': {
    name: 'Jeffersonville',
    postalCode: '05464',
  },
  'jeffrey-wv': {
    name: 'Jeffrey',
    postalCode: '25114',
  },
  'jekyll-island-ga': {
    name: 'Jekyll Island',
    postalCode: '31527',
  },
  'jellico-tn': {
    name: 'Jellico',
    postalCode: '37762',
  },
  'jelm-wy': {
    name: 'Jelm',
    postalCode: '82063',
  },
  'jemez-pueblo-nm': {
    name: 'Jemez Pueblo',
    postalCode: '87024',
  },
  'jemez-springs-nm': {
    name: 'Jemez Springs',
    postalCode: '87025',
  },
  'jemison-al': {
    name: 'Jemison',
    postalCode: '35085',
  },
  'jena-la': {
    name: 'Jena',
    postalCode: '71342',
  },
  'jenera-oh': {
    name: 'Jenera',
    postalCode: '45841',
  },
  'jenison-mi': {
    name: 'Jenison',
    postalCode: '49428',
  },
  'jenkins-ky': {
    name: 'Jenkins',
    postalCode: '41537',
  },
  'jenkins-bridge-va': {
    name: 'Jenkins Bridge',
    postalCode: '23399',
  },
  'jenkinsburg-ga': {
    name: 'Jenkinsburg',
    postalCode: '30234',
  },
  'jenkinsville-sc': {
    name: 'Jenkinsville',
    postalCode: '29065',
  },
  'jenkintown-pa': {
    name: 'Jenkintown',
    postalCode: '19046',
  },
  'jenks-ok': {
    name: 'Jenks',
    postalCode: '74037',
  },
  'jenner-ca': {
    name: 'Jenner',
    postalCode: '95450',
  },
  'jenners-pa': {
    name: 'Jenners',
    postalCode: '15546',
  },
  'jennings-fl': {
    name: 'Jennings',
    postalCode: '32053',
  },
  'jennings-ks': {
    name: 'Jennings',
    postalCode: '67643',
  },
  'jennings-la': {
    name: 'Jennings',
    postalCode: '70546',
  },
  'jennings-ok': {
    name: 'Jennings',
    postalCode: '74038',
  },
  'jensen-ut': {
    name: 'Jensen',
    postalCode: '84035',
  },
  'jensen-beach-fl': {
    name: 'Jensen Beach',
    postalCode: '34957',
  },
  'jeremiah-ky': {
    name: 'Jeremiah',
    postalCode: '41826',
  },
  'jericho-ny': {
    name: 'Jericho',
    postalCode: '11753',
  },
  'jericho-vt': {
    name: 'Jericho',
    postalCode: '05465',
  },
  'jerico-springs-mo': {
    name: 'Jerico Springs',
    postalCode: '64756',
  },
  'jermyn-pa': {
    name: 'Jermyn',
    postalCode: '18433',
  },
  'jermyn-tx': {
    name: 'Jermyn',
    postalCode: '76459',
  },
  'jerome-id': {
    name: 'Jerome',
    postalCode: '83338',
  },
  'jerome-mi': {
    name: 'Jerome',
    postalCode: '49249',
  },
  'jerome-mo': {
    name: 'Jerome',
    postalCode: '65529',
  },
  'jeromesville-oh': {
    name: 'Jeromesville',
    postalCode: '44840',
  },
  'jersey-ar': {
    name: 'Jersey',
    postalCode: '71651',
  },
  'jersey-city-nj': {
    name: 'Jersey City',
    postalCode: '07302',
  },
  'jersey-mills-pa': {
    name: 'Jersey Mills',
    postalCode: '17739',
  },
  'jersey-shore-pa': {
    name: 'Jersey Shore',
    postalCode: '17740',
  },
  'jerseyville-il': {
    name: 'Jerseyville',
    postalCode: '62052',
  },
  'jerusalem-ar': {
    name: 'Jerusalem',
    postalCode: '72080',
  },
  'jerusalem-oh': {
    name: 'Jerusalem',
    postalCode: '43747',
  },
  'jesse-wv': {
    name: 'Jesse',
    postalCode: '24849',
  },
  'jessie-nd': {
    name: 'Jessie',
    postalCode: '58452',
  },
  'jessieville-ar': {
    name: 'Jessieville',
    postalCode: '71949',
  },
  'jessup-md': {
    name: 'Jessup',
    postalCode: '20794',
  },
  'jessup-pa': {
    name: 'Jessup',
    postalCode: '18434',
  },
  'jesup-ga': {
    name: 'Jesup',
    postalCode: '31545',
  },
  'jesup-ia': {
    name: 'Jesup',
    postalCode: '50648',
  },
  'jet-ok': {
    name: 'Jet',
    postalCode: '73749',
  },
  'jetersville-va': {
    name: 'Jetersville',
    postalCode: '23083',
  },
  'jetmore-ks': {
    name: 'Jetmore',
    postalCode: '67854',
  },
  'jetson-ky': {
    name: 'Jetson',
    postalCode: '42252',
  },
  'jewell-ga': {
    name: 'Jewell',
    postalCode: '31045',
  },
  'jewell-ia': {
    name: 'Jewell',
    postalCode: '50130',
  },
  'jewell-ks': {
    name: 'Jewell',
    postalCode: '66949',
  },
  'jewell-ridge-va': {
    name: 'Jewell Ridge',
    postalCode: '24622',
  },
  'jewett-il': {
    name: 'Jewett',
    postalCode: '62436',
  },
  'jewett-ny': {
    name: 'Jewett',
    postalCode: '12444',
  },
  'jewett-oh': {
    name: 'Jewett',
    postalCode: '43986',
  },
  'jewett-tx': {
    name: 'Jewett',
    postalCode: '75846',
  },
  'jewett-city-ct': {
    name: 'Jewett City',
    postalCode: '06351',
  },
  'jim-falls-wi': {
    name: 'Jim Falls',
    postalCode: '54748',
  },
  'jim-thorpe-pa': {
    name: 'Jim Thorpe',
    postalCode: '18229',
  },
  'joanna-sc': {
    name: 'Joanna',
    postalCode: '29351',
  },
  'joaquin-tx': {
    name: 'Joaquin',
    postalCode: '75954',
  },
  'jobstown-nj': {
    name: 'Jobstown',
    postalCode: '08041',
  },
  'joelton-tn': {
    name: 'Joelton',
    postalCode: '37080',
  },
  'joes-co': {
    name: 'Joes',
    postalCode: '80822',
  },
  'joffre-pa': {
    name: 'Joffre',
    postalCode: '15053',
  },
  'johannesburg-mi': {
    name: 'Johannesburg',
    postalCode: '49751',
  },
  'john-day-or': {
    name: 'John Day',
    postalCode: '97845',
  },
  'johns-island-sc': {
    name: 'Johns Island',
    postalCode: '29455',
  },
  'johnsburg-ny': {
    name: 'Johnsburg',
    postalCode: '12843',
  },
  'johnson-ks': {
    name: 'Johnson',
    postalCode: '67855',
  },
  'johnson-ne': {
    name: 'Johnson',
    postalCode: '68378',
  },
  'johnson-vt': {
    name: 'Johnson',
    postalCode: '05656',
  },
  'johnson-city-ny': {
    name: 'Johnson City',
    postalCode: '13790',
  },
  'johnson-city-tn': {
    name: 'Johnson City',
    postalCode: '37601',
  },
  'johnson-city-tx': {
    name: 'Johnson City',
    postalCode: '78636',
  },
  'johnson-creek-wi': {
    name: 'Johnson Creek',
    postalCode: '53038',
  },
  'johnsonburg-pa': {
    name: 'Johnsonburg',
    postalCode: '15845',
  },
  'johnsonville-il': {
    name: 'Johnsonville',
    postalCode: '62850',
  },
  'johnsonville-ny': {
    name: 'Johnsonville',
    postalCode: '12094',
  },
  'johnsonville-sc': {
    name: 'Johnsonville',
    postalCode: '29555',
  },
  'johnston-ia': {
    name: 'Johnston',
    postalCode: '50131',
  },
  'johnston-ri': {
    name: 'Johnston',
    postalCode: '02919',
  },
  'johnston-sc': {
    name: 'Johnston',
    postalCode: '29832',
  },
  'johnston-city-il': {
    name: 'Johnston City',
    postalCode: '62951',
  },
  'johnstown-co': {
    name: 'Johnstown',
    postalCode: '80534',
  },
  'johnstown-ne': {
    name: 'Johnstown',
    postalCode: '69214',
  },
  'johnstown-ny': {
    name: 'Johnstown',
    postalCode: '12095',
  },
  'johnstown-oh': {
    name: 'Johnstown',
    postalCode: '43031',
  },
  'johnstown-pa': {
    name: 'Johnstown',
    postalCode: '15901',
  },
  'joice-ia': {
    name: 'Joice',
    postalCode: '50446',
  },
  'joiner-ar': {
    name: 'Joiner',
    postalCode: '72350',
  },
  'joint-base-mdl-nj': {
    name: 'Joint Base Mdl',
    postalCode: '08640',
  },
  'joliet-il': {
    name: 'Joliet',
    postalCode: '60431',
  },
  'joliet-mt': {
    name: 'Joliet',
    postalCode: '59041',
  },
  'jolley-ia': {
    name: 'Jolley',
    postalCode: '50551',
  },
  'jolo-wv': {
    name: 'Jolo',
    postalCode: '24850',
  },
  'jones-al': {
    name: 'Jones',
    postalCode: '36749',
  },
  'jones-la': {
    name: 'Jones',
    postalCode: '71250',
  },
  'jones-mi': {
    name: 'Jones',
    postalCode: '49061',
  },
  'jones-ok': {
    name: 'Jones',
    postalCode: '73049',
  },
  'jones-mills-pa': {
    name: 'Jones Mills',
    postalCode: '15646',
  },
  'jonesboro-ar': {
    name: 'Jonesboro',
    postalCode: '72401',
  },
  'jonesboro-ga': {
    name: 'Jonesboro',
    postalCode: '30236',
  },
  'jonesboro-il': {
    name: 'Jonesboro',
    postalCode: '62952',
  },
  'jonesboro-in': {
    name: 'Jonesboro',
    postalCode: '46938',
  },
  'jonesboro-la': {
    name: 'Jonesboro',
    postalCode: '71251',
  },
  'jonesboro-me': {
    name: 'Jonesboro',
    postalCode: '04648',
  },
  'jonesboro-tx': {
    name: 'Jonesboro',
    postalCode: '76538',
  },
  'jonesborough-tn': {
    name: 'Jonesborough',
    postalCode: '37659',
  },
  'jonesburg-mo': {
    name: 'Jonesburg',
    postalCode: '63351',
  },
  'jonesport-me': {
    name: 'Jonesport',
    postalCode: '04649',
  },
  'jonestown-pa': {
    name: 'Jonestown',
    postalCode: '17038',
  },
  'jonesville-in': {
    name: 'Jonesville',
    postalCode: '47247',
  },
  'jonesville-ky': {
    name: 'Jonesville',
    postalCode: '41052',
  },
  'jonesville-la': {
    name: 'Jonesville',
    postalCode: '71343',
  },
  'jonesville-mi': {
    name: 'Jonesville',
    postalCode: '49250',
  },
  'jonesville-nc': {
    name: 'Jonesville',
    postalCode: '28642',
  },
  'jonesville-sc': {
    name: 'Jonesville',
    postalCode: '29353',
  },
  'jonesville-va': {
    name: 'Jonesville',
    postalCode: '24263',
  },
  'joplin-mo': {
    name: 'Joplin',
    postalCode: '64801',
  },
  'joplin-mt': {
    name: 'Joplin',
    postalCode: '59531',
  },
  'joppa-al': {
    name: 'Joppa',
    postalCode: '35087',
  },
  'joppa-md': {
    name: 'Joppa',
    postalCode: '21085',
  },
  'jordan-mn': {
    name: 'Jordan',
    postalCode: '55352',
  },
  'jordan-mt': {
    name: 'Jordan',
    postalCode: '59337',
  },
  'jordan-ny': {
    name: 'Jordan',
    postalCode: '13080',
  },
  'jordan-valley-or': {
    name: 'Jordan Valley',
    postalCode: '97910',
  },
  'jordanville-ny': {
    name: 'Jordanville',
    postalCode: '13361',
  },
  'joseph-or': {
    name: 'Joseph',
    postalCode: '97846',
  },
  'joseph-ut': {
    name: 'Joseph',
    postalCode: '84739',
  },
  'josephine-pa': {
    name: 'Josephine',
    postalCode: '15750',
  },
  'josephine-wv': {
    name: 'Josephine',
    postalCode: '25857',
  },
  'joshua-tx': {
    name: 'Joshua',
    postalCode: '76058',
  },
  'joshua-tree-ca': {
    name: 'Joshua Tree',
    postalCode: '92252',
  },
  'jourdanton-tx': {
    name: 'Jourdanton',
    postalCode: '78026',
  },
  'joy-il': {
    name: 'Joy',
    postalCode: '61260',
  },
  'juana-diaz-pr': {
    name: 'Juana Diaz',
    postalCode: '00795',
  },
  'jud-nd': {
    name: 'Jud',
    postalCode: '58454',
  },
  'juda-wi': {
    name: 'Juda',
    postalCode: '53550',
  },
  'judith-gap-mt': {
    name: 'Judith Gap',
    postalCode: '59453',
  },
  'judsonia-ar': {
    name: 'Judsonia',
    postalCode: '72081',
  },
  'julesburg-co': {
    name: 'Julesburg',
    postalCode: '80737',
  },
  'juliaetta-id': {
    name: 'Juliaetta',
    postalCode: '83535',
  },
  'julian-ca': {
    name: 'Julian',
    postalCode: '92036',
  },
  'julian-nc': {
    name: 'Julian',
    postalCode: '27283',
  },
  'julian-ne': {
    name: 'Julian',
    postalCode: '68379',
  },
  'julian-pa': {
    name: 'Julian',
    postalCode: '16844',
  },
  'julian-wv': {
    name: 'Julian',
    postalCode: '25529',
  },
  'juliette-ga': {
    name: 'Juliette',
    postalCode: '31046',
  },
  'jumping-branch-wv': {
    name: 'Jumping Branch',
    postalCode: '25969',
  },
  'juncos-pr': {
    name: 'Juncos',
    postalCode: '00777',
  },
  'junction-il': {
    name: 'Junction',
    postalCode: '62954',
  },
  'junction-tx': {
    name: 'Junction',
    postalCode: '76849',
  },
  'junction-city-ar': {
    name: 'Junction City',
    postalCode: '71749',
  },
  'junction-city-ca': {
    name: 'Junction City',
    postalCode: '96048',
  },
  'junction-city-ga': {
    name: 'Junction City',
    postalCode: '31812',
  },
  'junction-city-ks': {
    name: 'Junction City',
    postalCode: '66441',
  },
  'junction-city-ky': {
    name: 'Junction City',
    postalCode: '40440',
  },
  'junction-city-oh': {
    name: 'Junction City',
    postalCode: '43748',
  },
  'junction-city-or': {
    name: 'Junction City',
    postalCode: '97448',
  },
  'junction-city-wi': {
    name: 'Junction City',
    postalCode: '54443',
  },
  'juneau-ak': {
    name: 'Juneau',
    postalCode: '99801',
  },
  'juneau-wi': {
    name: 'Juneau',
    postalCode: '53039',
  },
  'juniata-ne': {
    name: 'Juniata',
    postalCode: '68955',
  },
  'juntura-or': {
    name: 'Juntura',
    postalCode: '97911',
  },
  'jupiter-fl': {
    name: 'Jupiter',
    postalCode: '33458',
  },
  'jurupa-valley-ca': {
    name: 'Jurupa Valley',
    postalCode: '92509',
  },
  'justice-il': {
    name: 'Justice',
    postalCode: '60458',
  },
  'justiceburg-tx': {
    name: 'Justiceburg',
    postalCode: '79330',
  },
  'justin-tx': {
    name: 'Justin',
    postalCode: '76247',
  },
  'kaaawa-hi': {
    name: 'Kaaawa',
    postalCode: '96730',
  },
  'kabetogama-mn': {
    name: 'Kabetogama',
    postalCode: '56669',
  },
  'kadoka-sd': {
    name: 'Kadoka',
    postalCode: '57543',
  },
  'kahoka-mo': {
    name: 'Kahoka',
    postalCode: '63445',
  },
  'kahuku-hi': {
    name: 'Kahuku',
    postalCode: '96731',
  },
  'kahului-hi': {
    name: 'Kahului',
    postalCode: '96732',
  },
  'kailua-hi': {
    name: 'Kailua',
    postalCode: '96734',
  },
  'kailua-kona-hi': {
    name: 'Kailua Kona',
    postalCode: '96740',
  },
  'kaiser-mo': {
    name: 'Kaiser',
    postalCode: '65047',
  },
  'kalaheo-hi': {
    name: 'Kalaheo',
    postalCode: '96741',
  },
  'kalama-wa': {
    name: 'Kalama',
    postalCode: '98625',
  },
  'kalamazoo-mi': {
    name: 'Kalamazoo',
    postalCode: '49001',
  },
  'kaleva-mi': {
    name: 'Kaleva',
    postalCode: '49645',
  },
  'kalispell-mt': {
    name: 'Kalispell',
    postalCode: '59901',
  },
  'kalkaska-mi': {
    name: 'Kalkaska',
    postalCode: '49646',
  },
  'kalona-ia': {
    name: 'Kalona',
    postalCode: '52247',
  },
  'kamas-ut': {
    name: 'Kamas',
    postalCode: '84036',
  },
  'kamiah-id': {
    name: 'Kamiah',
    postalCode: '83536',
  },
  'kampsville-il': {
    name: 'Kampsville',
    postalCode: '62053',
  },
  'kamrar-ia': {
    name: 'Kamrar',
    postalCode: '50132',
  },
  'kamuela-hi': {
    name: 'Kamuela',
    postalCode: '96743',
  },
  'kanab-ut': {
    name: 'Kanab',
    postalCode: '84741',
  },
  'kanaranzi-mn': {
    name: 'Kanaranzi',
    postalCode: '56146',
  },
  'kanarraville-ut': {
    name: 'Kanarraville',
    postalCode: '84742',
  },
  'kanawha-ia': {
    name: 'Kanawha',
    postalCode: '50447',
  },
  'kanawha-falls-wv': {
    name: 'Kanawha Falls',
    postalCode: '25115',
  },
  'kanawha-head-wv': {
    name: 'Kanawha Head',
    postalCode: '26228',
  },
  'kandiyohi-mn': {
    name: 'Kandiyohi',
    postalCode: '56251',
  },
  'kane-il': {
    name: 'Kane',
    postalCode: '62054',
  },
  'kane-pa': {
    name: 'Kane',
    postalCode: '16735',
  },
  'kaneohe-hi': {
    name: 'Kaneohe',
    postalCode: '96744',
  },
  'kankakee-il': {
    name: 'Kankakee',
    postalCode: '60901',
  },
  'kannapolis-nc': {
    name: 'Kannapolis',
    postalCode: '28081',
  },
  'kanopolis-ks': {
    name: 'Kanopolis',
    postalCode: '67454',
  },
  'kanorado-ks': {
    name: 'Kanorado',
    postalCode: '67741',
  },
  'kansas-il': {
    name: 'Kansas',
    postalCode: '61933',
  },
  'kansas-oh': {
    name: 'Kansas',
    postalCode: '44841',
  },
  'kansas-ok': {
    name: 'Kansas',
    postalCode: '74347',
  },
  'kansas-city-ks': {
    name: 'Kansas City',
    postalCode: '66101',
  },
  'kansas-city-mo': {
    name: 'Kansas City',
    postalCode: '64101',
  },
  'kansasville-wi': {
    name: 'Kansasville',
    postalCode: '53139',
  },
  'kantner-pa': {
    name: 'Kantner',
    postalCode: '15548',
  },
  'kapaa-hi': {
    name: 'Kapaa',
    postalCode: '96746',
  },
  'kaplan-la': {
    name: 'Kaplan',
    postalCode: '70548',
  },
  'kapolei-hi': {
    name: 'Kapolei',
    postalCode: '96707',
  },
  'karlsruhe-nd': {
    name: 'Karlsruhe',
    postalCode: '58744',
  },
  'karlstad-mn': {
    name: 'Karlstad',
    postalCode: '56732',
  },
  'karnack-tx': {
    name: 'Karnack',
    postalCode: '75661',
  },
  'karnak-il': {
    name: 'Karnak',
    postalCode: '62956',
  },
  'karnes-city-tx': {
    name: 'Karnes City',
    postalCode: '78118',
  },
  'karns-city-pa': {
    name: 'Karns City',
    postalCode: '16041',
  },
  'karthaus-pa': {
    name: 'Karthaus',
    postalCode: '16845',
  },
  'karval-co': {
    name: 'Karval',
    postalCode: '80823',
  },
  'kasilof-ak': {
    name: 'Kasilof',
    postalCode: '99610',
  },
  'kasota-mn': {
    name: 'Kasota',
    postalCode: '56050',
  },
  'kasson-mn': {
    name: 'Kasson',
    postalCode: '55944',
  },
  'kathleen-fl': {
    name: 'Kathleen',
    postalCode: '33849',
  },
  'kathleen-ga': {
    name: 'Kathleen',
    postalCode: '31047',
  },
  'kathryn-nd': {
    name: 'Kathryn',
    postalCode: '58049',
  },
  'katonah-ny': {
    name: 'Katonah',
    postalCode: '10536',
  },
  'kattskill-bay-ny': {
    name: 'Kattskill Bay',
    postalCode: '12844',
  },
  'katy-tx': {
    name: 'Katy',
    postalCode: '77449',
  },
  'kaufman-tx': {
    name: 'Kaufman',
    postalCode: '75142',
  },
  'kaukauna-wi': {
    name: 'Kaukauna',
    postalCode: '54130',
  },
  'kaunakakai-hi': {
    name: 'Kaunakakai',
    postalCode: '96748',
  },
  'kaw-city-ok': {
    name: 'Kaw City',
    postalCode: '74641',
  },
  'kawkawlin-mi': {
    name: 'Kawkawlin',
    postalCode: '48631',
  },
  'kaycee-wy': {
    name: 'Kaycee',
    postalCode: '82639',
  },
  'kaysville-ut': {
    name: 'Kaysville',
    postalCode: '84037',
  },
  'keaau-hi': {
    name: 'Keaau',
    postalCode: '96749',
  },
  'kealakekua-hi': {
    name: 'Kealakekua',
    postalCode: '96750',
  },
  'keansburg-nj': {
    name: 'Keansburg',
    postalCode: '07734',
  },
  'kearney-mo': {
    name: 'Kearney',
    postalCode: '64060',
  },
  'kearney-ne': {
    name: 'Kearney',
    postalCode: '68845',
  },
  'kearneysville-wv': {
    name: 'Kearneysville',
    postalCode: '25430',
  },
  'kearny-az': {
    name: 'Kearny',
    postalCode: '85137',
  },
  'kearny-nj': {
    name: 'Kearny',
    postalCode: '07032',
  },
  'keasbey-nj': {
    name: 'Keasbey',
    postalCode: '08832',
  },
  'keatchie-la': {
    name: 'Keatchie',
    postalCode: '71046',
  },
  'keaton-ky': {
    name: 'Keaton',
    postalCode: '41226',
  },
  'keavy-ky': {
    name: 'Keavy',
    postalCode: '40737',
  },
  'kechi-ks': {
    name: 'Kechi',
    postalCode: '67067',
  },
  'keedysville-md': {
    name: 'Keedysville',
    postalCode: '21756',
  },
  'keego-harbor-mi': {
    name: 'Keego Harbor',
    postalCode: '48320',
  },
  'keeling-va': {
    name: 'Keeling',
    postalCode: '24566',
  },
  'keene-ca': {
    name: 'Keene',
    postalCode: '93531',
  },
  'keene-nd': {
    name: 'Keene',
    postalCode: '58847',
  },
  'keene-nh': {
    name: 'Keene',
    postalCode: '03431',
  },
  'keene-ny': {
    name: 'Keene',
    postalCode: '12942',
  },
  'keene-tx': {
    name: 'Keene',
    postalCode: '76059',
  },
  'keene-va': {
    name: 'Keene',
    postalCode: '22946',
  },
  'keene-valley-ny': {
    name: 'Keene Valley',
    postalCode: '12943',
  },
  'keenes-il': {
    name: 'Keenes',
    postalCode: '62851',
  },
  'keenesburg-co': {
    name: 'Keenesburg',
    postalCode: '80643',
  },
  'keeseville-ny': {
    name: 'Keeseville',
    postalCode: '12911',
  },
  'keezletown-va': {
    name: 'Keezletown',
    postalCode: '22832',
  },
  'kegley-wv': {
    name: 'Kegley',
    postalCode: '24731',
  },
  'keithsburg-il': {
    name: 'Keithsburg',
    postalCode: '61442',
  },
  'keithville-la': {
    name: 'Keithville',
    postalCode: '71047',
  },
  'keldron-sd': {
    name: 'Keldron',
    postalCode: '57634',
  },
  'kelford-nc': {
    name: 'Kelford',
    postalCode: '27847',
  },
  'kell-il': {
    name: 'Kell',
    postalCode: '62853',
  },
  'keller-tx': {
    name: 'Keller',
    postalCode: '76244',
  },
  'keller-wa': {
    name: 'Keller',
    postalCode: '99140',
  },
  'kellerton-ia': {
    name: 'Kellerton',
    postalCode: '50133',
  },
  'kelley-ia': {
    name: 'Kelley',
    postalCode: '50134',
  },
  'kelliher-mn': {
    name: 'Kelliher',
    postalCode: '56650',
  },
  'kellogg-ia': {
    name: 'Kellogg',
    postalCode: '50135',
  },
  'kellogg-id': {
    name: 'Kellogg',
    postalCode: '83837',
  },
  'kellogg-mn': {
    name: 'Kellogg',
    postalCode: '55945',
  },
  'kelly-la': {
    name: 'Kelly',
    postalCode: '71441',
  },
  'kelly-nc': {
    name: 'Kelly',
    postalCode: '28448',
  },
  'kelly-wy': {
    name: 'Kelly',
    postalCode: '83011',
  },
  'kellyton-al': {
    name: 'Kellyton',
    postalCode: '35089',
  },
  'kellyville-ok': {
    name: 'Kellyville',
    postalCode: '74039',
  },
  'kelseyville-ca': {
    name: 'Kelseyville',
    postalCode: '95451',
  },
  'kelso-tn': {
    name: 'Kelso',
    postalCode: '37348',
  },
  'kelso-wa': {
    name: 'Kelso',
    postalCode: '98626',
  },
  'kemah-tx': {
    name: 'Kemah',
    postalCode: '77565',
  },
  'kemmerer-wy': {
    name: 'Kemmerer',
    postalCode: '83101',
  },
  'kemp-tx': {
    name: 'Kemp',
    postalCode: '75143',
  },
  'kempner-tx': {
    name: 'Kempner',
    postalCode: '76539',
  },
  'kempton-il': {
    name: 'Kempton',
    postalCode: '60946',
  },
  'kempton-in': {
    name: 'Kempton',
    postalCode: '46049',
  },
  'kempton-pa': {
    name: 'Kempton',
    postalCode: '19529',
  },
  'kenai-ak': {
    name: 'Kenai',
    postalCode: '99611',
  },
  'kenansville-fl': {
    name: 'Kenansville',
    postalCode: '34739',
  },
  'kenansville-nc': {
    name: 'Kenansville',
    postalCode: '28349',
  },
  'kenbridge-va': {
    name: 'Kenbridge',
    postalCode: '23944',
  },
  'kendalia-tx': {
    name: 'Kendalia',
    postalCode: '78027',
  },
  'kendall-ks': {
    name: 'Kendall',
    postalCode: '67857',
  },
  'kendall-ny': {
    name: 'Kendall',
    postalCode: '14476',
  },
  'kendall-wi': {
    name: 'Kendall',
    postalCode: '54638',
  },
  'kendall-park-nj': {
    name: 'Kendall Park',
    postalCode: '08824',
  },
  'kendallville-in': {
    name: 'Kendallville',
    postalCode: '46755',
  },
  'kendrick-id': {
    name: 'Kendrick',
    postalCode: '83537',
  },
  'kenduskeag-me': {
    name: 'Kenduskeag',
    postalCode: '04450',
  },
  'kenedy-tx': {
    name: 'Kenedy',
    postalCode: '78119',
  },
  'kenefic-ok': {
    name: 'Kenefic',
    postalCode: '74748',
  },
  'kenesaw-ne': {
    name: 'Kenesaw',
    postalCode: '68956',
  },
  'kenilworth-il': {
    name: 'Kenilworth',
    postalCode: '60043',
  },
  'kenilworth-nj': {
    name: 'Kenilworth',
    postalCode: '07033',
  },
  'kenilworth-ut': {
    name: 'Kenilworth',
    postalCode: '84529',
  },
  'kenly-nc': {
    name: 'Kenly',
    postalCode: '27542',
  },
  'kenmare-nd': {
    name: 'Kenmare',
    postalCode: '58746',
  },
  'kenmore-wa': {
    name: 'Kenmore',
    postalCode: '98028',
  },
  'kenna-wv': {
    name: 'Kenna',
    postalCode: '25248',
  },
  'kennan-wi': {
    name: 'Kennan',
    postalCode: '54537',
  },
  'kennard-ne': {
    name: 'Kennard',
    postalCode: '68034',
  },
  'kennard-tx': {
    name: 'Kennard',
    postalCode: '75847',
  },
  'kennebec-sd': {
    name: 'Kennebec',
    postalCode: '57544',
  },
  'kennebunk-me': {
    name: 'Kennebunk',
    postalCode: '04043',
  },
  'kennebunkport-me': {
    name: 'Kennebunkport',
    postalCode: '04046',
  },
  'kennedale-tx': {
    name: 'Kennedale',
    postalCode: '76060',
  },
  'kennedy-al': {
    name: 'Kennedy',
    postalCode: '35574',
  },
  'kennedy-mn': {
    name: 'Kennedy',
    postalCode: '56733',
  },
  'kennedy-ny': {
    name: 'Kennedy',
    postalCode: '14747',
  },
  'kennedyville-md': {
    name: 'Kennedyville',
    postalCode: '21645',
  },
  'kenner-la': {
    name: 'Kenner',
    postalCode: '70062',
  },
  'kennerdell-pa': {
    name: 'Kennerdell',
    postalCode: '16374',
  },
  'kennesaw-ga': {
    name: 'Kennesaw',
    postalCode: '30144',
  },
  'kenneth-mn': {
    name: 'Kenneth',
    postalCode: '56147',
  },
  'kennett-mo': {
    name: 'Kennett',
    postalCode: '63857',
  },
  'kennett-square-pa': {
    name: 'Kennett Square',
    postalCode: '19348',
  },
  'kennewick-wa': {
    name: 'Kennewick',
    postalCode: '99336',
  },
  'kenney-il': {
    name: 'Kenney',
    postalCode: '61749',
  },
  'keno-or': {
    name: 'Keno',
    postalCode: '97627',
  },
  'kenosha-wi': {
    name: 'Kenosha',
    postalCode: '53140',
  },
  'kenova-wv': {
    name: 'Kenova',
    postalCode: '25530',
  },
  'kenoza-lake-ny': {
    name: 'Kenoza Lake',
    postalCode: '12750',
  },
  'kensal-nd': {
    name: 'Kensal',
    postalCode: '58455',
  },
  'kensett-ar': {
    name: 'Kensett',
    postalCode: '72082',
  },
  'kensett-ia': {
    name: 'Kensett',
    postalCode: '50448',
  },
  'kensington-ks': {
    name: 'Kensington',
    postalCode: '66951',
  },
  'kensington-md': {
    name: 'Kensington',
    postalCode: '20895',
  },
  'kensington-mn': {
    name: 'Kensington',
    postalCode: '56343',
  },
  'kensington-oh': {
    name: 'Kensington',
    postalCode: '44427',
  },
  'kent-ct': {
    name: 'Kent',
    postalCode: '06757',
  },
  'kent-il': {
    name: 'Kent',
    postalCode: '61044',
  },
  'kent-mn': {
    name: 'Kent',
    postalCode: '56553',
  },
  'kent-ny': {
    name: 'Kent',
    postalCode: '14477',
  },
  'kent-oh': {
    name: 'Kent',
    postalCode: '44240',
  },
  'kent-wa': {
    name: 'Kent',
    postalCode: '98030',
  },
  'kent-city-mi': {
    name: 'Kent City',
    postalCode: '49330',
  },
  'kentland-in': {
    name: 'Kentland',
    postalCode: '47951',
  },
  'kenton-oh': {
    name: 'Kenton',
    postalCode: '43326',
  },
  'kenton-ok': {
    name: 'Kenton',
    postalCode: '73946',
  },
  'kenton-tn': {
    name: 'Kenton',
    postalCode: '38233',
  },
  'kents-hill-me': {
    name: 'Kents Hill',
    postalCode: '04349',
  },
  'kents-store-va': {
    name: 'Kents Store',
    postalCode: '23084',
  },
  'kentwood-la': {
    name: 'Kentwood',
    postalCode: '70444',
  },
  'kenvil-nj': {
    name: 'Kenvil',
    postalCode: '07847',
  },
  'kenwood-ca': {
    name: 'Kenwood',
    postalCode: '95452',
  },
  'kenyon-mn': {
    name: 'Kenyon',
    postalCode: '55946',
  },
  'kenyon-ri': {
    name: 'Kenyon',
    postalCode: '02836',
  },
  'keokee-va': {
    name: 'Keokee',
    postalCode: '24265',
  },
  'keokuk-ia': {
    name: 'Keokuk',
    postalCode: '52632',
  },
  'keosauqua-ia': {
    name: 'Keosauqua',
    postalCode: '52565',
  },
  'keota-ia': {
    name: 'Keota',
    postalCode: '52248',
  },
  'keota-ok': {
    name: 'Keota',
    postalCode: '74941',
  },
  'kerby-or': {
    name: 'Kerby',
    postalCode: '97531',
  },
  'kerens-tx': {
    name: 'Kerens',
    postalCode: '75144',
  },
  'kerens-wv': {
    name: 'Kerens',
    postalCode: '26276',
  },
  'kerhonkson-ny': {
    name: 'Kerhonkson',
    postalCode: '12446',
  },
  'kerkhoven-mn': {
    name: 'Kerkhoven',
    postalCode: '56252',
  },
  'kerman-ca': {
    name: 'Kerman',
    postalCode: '93630',
  },
  'kermit-tx': {
    name: 'Kermit',
    postalCode: '79745',
  },
  'kermit-wv': {
    name: 'Kermit',
    postalCode: '25674',
  },
  'kernersville-nc': {
    name: 'Kernersville',
    postalCode: '27284',
  },
  'kernville-ca': {
    name: 'Kernville',
    postalCode: '93238',
  },
  'kerrick-mn': {
    name: 'Kerrick',
    postalCode: '55756',
  },
  'kerrville-tx': {
    name: 'Kerrville',
    postalCode: '78028',
  },
  'kersey-co': {
    name: 'Kersey',
    postalCode: '80644',
  },
  'kersey-pa': {
    name: 'Kersey',
    postalCode: '15846',
  },
  'kershaw-sc': {
    name: 'Kershaw',
    postalCode: '29067',
  },
  'keshena-wi': {
    name: 'Keshena',
    postalCode: '54135',
  },
  'kesley-ia': {
    name: 'Kesley',
    postalCode: '50649',
  },
  'keswick-ia': {
    name: 'Keswick',
    postalCode: '50136',
  },
  'keswick-va': {
    name: 'Keswick',
    postalCode: '22947',
  },
  'ketchikan-ak': {
    name: 'Ketchikan',
    postalCode: '99901',
  },
  'ketchum-id': {
    name: 'Ketchum',
    postalCode: '83340',
  },
  'kettle-falls-wa': {
    name: 'Kettle Falls',
    postalCode: '99141',
  },
  'kettle-island-ky': {
    name: 'Kettle Island',
    postalCode: '40958',
  },
  'kettle-river-mn': {
    name: 'Kettle River',
    postalCode: '55757',
  },
  'kettleman-city-ca': {
    name: 'Kettleman City',
    postalCode: '93239',
  },
  'keuka-park-ny': {
    name: 'Keuka Park',
    postalCode: '14478',
  },
  'kevil-ky': {
    name: 'Kevil',
    postalCode: '42053',
  },
  'kevin-mt': {
    name: 'Kevin',
    postalCode: '59454',
  },
  'kew-gardens-ny': {
    name: 'Kew Gardens',
    postalCode: '11415',
  },
  'kewadin-mi': {
    name: 'Kewadin',
    postalCode: '49648',
  },
  'kewanee-il': {
    name: 'Kewanee',
    postalCode: '61443',
  },
  'kewanna-in': {
    name: 'Kewanna',
    postalCode: '46939',
  },
  'kewaskum-wi': {
    name: 'Kewaskum',
    postalCode: '53040',
  },
  'kewaunee-wi': {
    name: 'Kewaunee',
    postalCode: '54216',
  },
  'key-biscayne-fl': {
    name: 'Key Biscayne',
    postalCode: '33149',
  },
  'key-largo-fl': {
    name: 'Key Largo',
    postalCode: '33037',
  },
  'key-west-fl': {
    name: 'Key West',
    postalCode: '33040',
  },
  'keyes-ca': {
    name: 'Keyes',
    postalCode: '95328',
  },
  'keyes-ok': {
    name: 'Keyes',
    postalCode: '73947',
  },
  'keyesport-il': {
    name: 'Keyesport',
    postalCode: '62253',
  },
  'keymar-md': {
    name: 'Keymar',
    postalCode: '21757',
  },
  'keyport-nj': {
    name: 'Keyport',
    postalCode: '07735',
  },
  'keyser-wv': {
    name: 'Keyser',
    postalCode: '26726',
  },
  'keystone-ia': {
    name: 'Keystone',
    postalCode: '52249',
  },
  'keystone-in': {
    name: 'Keystone',
    postalCode: '46759',
  },
  'keystone-ne': {
    name: 'Keystone',
    postalCode: '69144',
  },
  'keystone-sd': {
    name: 'Keystone',
    postalCode: '57751',
  },
  'keystone-heights-fl': {
    name: 'Keystone Heights',
    postalCode: '32656',
  },
  'keysville-ga': {
    name: 'Keysville',
    postalCode: '30816',
  },
  'keysville-va': {
    name: 'Keysville',
    postalCode: '23947',
  },
  'keytesville-mo': {
    name: 'Keytesville',
    postalCode: '65261',
  },
  'kiahsville-wv': {
    name: 'Kiahsville',
    postalCode: '25534',
  },
  'kiamesha-lake-ny': {
    name: 'Kiamesha Lake',
    postalCode: '12751',
  },
  'kidder-mo': {
    name: 'Kidder',
    postalCode: '64649',
  },
  'kiefer-ok': {
    name: 'Kiefer',
    postalCode: '74041',
  },
  'kiel-wi': {
    name: 'Kiel',
    postalCode: '53042',
  },
  'kiester-mn': {
    name: 'Kiester',
    postalCode: '56051',
  },
  'kihei-hi': {
    name: 'Kihei',
    postalCode: '96753',
  },
  'kila-mt': {
    name: 'Kila',
    postalCode: '59920',
  },
  'kilauea-hi': {
    name: 'Kilauea',
    postalCode: '96754',
  },
  'kilbourne-il': {
    name: 'Kilbourne',
    postalCode: '62655',
  },
  'kilgore-ne': {
    name: 'Kilgore',
    postalCode: '69216',
  },
  'kilgore-tx': {
    name: 'Kilgore',
    postalCode: '75662',
  },
  'kilkenny-mn': {
    name: 'Kilkenny',
    postalCode: '56052',
  },
  'kill-buck-ny': {
    name: 'Kill Buck',
    postalCode: '14748',
  },
  'kill-devil-hills-nc': {
    name: 'Kill Devil Hills',
    postalCode: '27948',
  },
  'killbuck-oh': {
    name: 'Killbuck',
    postalCode: '44637',
  },
  'killdeer-nd': {
    name: 'Killdeer',
    postalCode: '58640',
  },
  'killduff-ia': {
    name: 'Killduff',
    postalCode: '50137',
  },
  'killeen-tx': {
    name: 'Killeen',
    postalCode: '76541',
  },
  'killen-al': {
    name: 'Killen',
    postalCode: '35645',
  },
  'killington-vt': {
    name: 'Killington',
    postalCode: '05751',
  },
  'killingworth-ct': {
    name: 'Killingworth',
    postalCode: '06419',
  },
  'kilmarnock-va': {
    name: 'Kilmarnock',
    postalCode: '22482',
  },
  'kilmichael-ms': {
    name: 'Kilmichael',
    postalCode: '39747',
  },
  'kiln-ms': {
    name: 'Kiln',
    postalCode: '39556',
  },
  'kim-co': {
    name: 'Kim',
    postalCode: '81049',
  },
  'kimball-mn': {
    name: 'Kimball',
    postalCode: '55353',
  },
  'kimball-ne': {
    name: 'Kimball',
    postalCode: '69145',
  },
  'kimball-sd': {
    name: 'Kimball',
    postalCode: '57355',
  },
  'kimballton-ia': {
    name: 'Kimballton',
    postalCode: '51543',
  },
  'kimberling-city-mo': {
    name: 'Kimberling City',
    postalCode: '65686',
  },
  'kimberly-al': {
    name: 'Kimberly',
    postalCode: '35091',
  },
  'kimberly-id': {
    name: 'Kimberly',
    postalCode: '83341',
  },
  'kimberly-or': {
    name: 'Kimberly',
    postalCode: '97848',
  },
  'kimberly-wi': {
    name: 'Kimberly',
    postalCode: '54136',
  },
  'kimberly-wv': {
    name: 'Kimberly',
    postalCode: '25118',
  },
  'kimbolton-oh': {
    name: 'Kimbolton',
    postalCode: '43749',
  },
  'kimmell-in': {
    name: 'Kimmell',
    postalCode: '46760',
  },
  'kimper-ky': {
    name: 'Kimper',
    postalCode: '41539',
  },
  'kinards-sc': {
    name: 'Kinards',
    postalCode: '29355',
  },
  'kincaid-ks': {
    name: 'Kincaid',
    postalCode: '66039',
  },
  'kincaid-wv': {
    name: 'Kincaid',
    postalCode: '25119',
  },
  'kincheloe-mi': {
    name: 'Kincheloe',
    postalCode: '49788',
  },
  'kinde-mi': {
    name: 'Kinde',
    postalCode: '48445',
  },
  'kinder-la': {
    name: 'Kinder',
    postalCode: '70648',
  },
  'kinderhook-il': {
    name: 'Kinderhook',
    postalCode: '62345',
  },
  'kinderhook-ny': {
    name: 'Kinderhook',
    postalCode: '12106',
  },
  'kindred-nd': {
    name: 'Kindred',
    postalCode: '58051',
  },
  'king-nc': {
    name: 'King',
    postalCode: '27021',
  },
  'king-and-queen-court-house-va': {
    name: 'King And Queen Court House',
    postalCode: '23085',
  },
  'king-city-ca': {
    name: 'King City',
    postalCode: '93930',
  },
  'king-city-mo': {
    name: 'King City',
    postalCode: '64463',
  },
  'king-ferry-ny': {
    name: 'King Ferry',
    postalCode: '13081',
  },
  'king-george-va': {
    name: 'King George',
    postalCode: '22485',
  },
  'king-hill-id': {
    name: 'King Hill',
    postalCode: '83633',
  },
  'king-of-prussia-pa': {
    name: 'King Of Prussia',
    postalCode: '19406',
  },
  'king-william-va': {
    name: 'King William',
    postalCode: '23086',
  },
  'kingdom-city-mo': {
    name: 'Kingdom City',
    postalCode: '65262',
  },
  'kingfield-me': {
    name: 'Kingfield',
    postalCode: '04947',
  },
  'kingfisher-ok': {
    name: 'Kingfisher',
    postalCode: '73750',
  },
  'kingman-az': {
    name: 'Kingman',
    postalCode: '86401',
  },
  'kingman-in': {
    name: 'Kingman',
    postalCode: '47952',
  },
  'kingman-ks': {
    name: 'Kingman',
    postalCode: '67068',
  },
  'kingman-me': {
    name: 'Kingman',
    postalCode: '04451',
  },
  'kings-bay-ga': {
    name: 'Kings Bay',
    postalCode: '31547',
  },
  'kings-mills-oh': {
    name: 'Kings Mills',
    postalCode: '45034',
  },
  'kings-mountain-ky': {
    name: 'Kings Mountain',
    postalCode: '40442',
  },
  'kings-mountain-nc': {
    name: 'Kings Mountain',
    postalCode: '28086',
  },
  'kings-park-ny': {
    name: 'Kings Park',
    postalCode: '11754',
  },
  'kingsburg-ca': {
    name: 'Kingsburg',
    postalCode: '93631',
  },
  'kingsbury-tx': {
    name: 'Kingsbury',
    postalCode: '78638',
  },
  'kingsford-mi': {
    name: 'Kingsford',
    postalCode: '49802',
  },
  'kingshill-vi': {
    name: 'Kingshill',
    postalCode: '00850',
  },
  'kingsland-ar': {
    name: 'Kingsland',
    postalCode: '71652',
  },
  'kingsland-ga': {
    name: 'Kingsland',
    postalCode: '31548',
  },
  'kingsland-tx': {
    name: 'Kingsland',
    postalCode: '78639',
  },
  'kingsley-ia': {
    name: 'Kingsley',
    postalCode: '51028',
  },
  'kingsley-mi': {
    name: 'Kingsley',
    postalCode: '49649',
  },
  'kingsley-pa': {
    name: 'Kingsley',
    postalCode: '18826',
  },
  'kingsport-tn': {
    name: 'Kingsport',
    postalCode: '37660',
  },
  'kingston-ar': {
    name: 'Kingston',
    postalCode: '72742',
  },
  'kingston-ga': {
    name: 'Kingston',
    postalCode: '30145',
  },
  'kingston-id': {
    name: 'Kingston',
    postalCode: '83839',
  },
  'kingston-il': {
    name: 'Kingston',
    postalCode: '60145',
  },
  'kingston-ma': {
    name: 'Kingston',
    postalCode: '02364',
  },
  'kingston-mi': {
    name: 'Kingston',
    postalCode: '48741',
  },
  'kingston-mo': {
    name: 'Kingston',
    postalCode: '64650',
  },
  'kingston-nh': {
    name: 'Kingston',
    postalCode: '03848',
  },
  'kingston-nj': {
    name: 'Kingston',
    postalCode: '08528',
  },
  'kingston-ny': {
    name: 'Kingston',
    postalCode: '12401',
  },
  'kingston-oh': {
    name: 'Kingston',
    postalCode: '45644',
  },
  'kingston-ok': {
    name: 'Kingston',
    postalCode: '73439',
  },
  'kingston-pa': {
    name: 'Kingston',
    postalCode: '18704',
  },
  'kingston-ri': {
    name: 'Kingston',
    postalCode: '02881',
  },
  'kingston-tn': {
    name: 'Kingston',
    postalCode: '37763',
  },
  'kingston-ut': {
    name: 'Kingston',
    postalCode: '84743',
  },
  'kingston-wa': {
    name: 'Kingston',
    postalCode: '98346',
  },
  'kingston-springs-tn': {
    name: 'Kingston Springs',
    postalCode: '37082',
  },
  'kingstree-sc': {
    name: 'Kingstree',
    postalCode: '29556',
  },
  'kingsville-md': {
    name: 'Kingsville',
    postalCode: '21087',
  },
  'kingsville-mo': {
    name: 'Kingsville',
    postalCode: '64061',
  },
  'kingsville-oh': {
    name: 'Kingsville',
    postalCode: '44048',
  },
  'kingsville-tx': {
    name: 'Kingsville',
    postalCode: '78363',
  },
  'kingwood-tx': {
    name: 'Kingwood',
    postalCode: '77339',
  },
  'kingwood-wv': {
    name: 'Kingwood',
    postalCode: '26537',
  },
  'kinmundy-il': {
    name: 'Kinmundy',
    postalCode: '62854',
  },
  'kinnear-wy': {
    name: 'Kinnear',
    postalCode: '82516',
  },
  'kinney-mn': {
    name: 'Kinney',
    postalCode: '55758',
  },
  'kinross-mi': {
    name: 'Kinross',
    postalCode: '49752',
  },
  'kinsale-va': {
    name: 'Kinsale',
    postalCode: '22488',
  },
  'kinsey-mt': {
    name: 'Kinsey',
    postalCode: '59338',
  },
  'kinsley-ks': {
    name: 'Kinsley',
    postalCode: '67547',
  },
  'kinsman-il': {
    name: 'Kinsman',
    postalCode: '60437',
  },
  'kinsman-oh': {
    name: 'Kinsman',
    postalCode: '44428',
  },
  'kinston-al': {
    name: 'Kinston',
    postalCode: '36453',
  },
  'kinston-nc': {
    name: 'Kinston',
    postalCode: '28501',
  },
  'kinta-ok': {
    name: 'Kinta',
    postalCode: '74552',
  },
  'kintnersville-pa': {
    name: 'Kintnersville',
    postalCode: '18930',
  },
  'kintyre-nd': {
    name: 'Kintyre',
    postalCode: '58549',
  },
  'kinzers-pa': {
    name: 'Kinzers',
    postalCode: '17535',
  },
  'kiowa-co': {
    name: 'Kiowa',
    postalCode: '80117',
  },
  'kiowa-ks': {
    name: 'Kiowa',
    postalCode: '67070',
  },
  'kiowa-ok': {
    name: 'Kiowa',
    postalCode: '74553',
  },
  'kirby-ar': {
    name: 'Kirby',
    postalCode: '71950',
  },
  'kirby-wy': {
    name: 'Kirby',
    postalCode: '82430',
  },
  'kirbyville-mo': {
    name: 'Kirbyville',
    postalCode: '65679',
  },
  'kirbyville-tx': {
    name: 'Kirbyville',
    postalCode: '75956',
  },
  'kirk-co': {
    name: 'Kirk',
    postalCode: '80824',
  },
  'kirkland-az': {
    name: 'Kirkland',
    postalCode: '86332',
  },
  'kirkland-il': {
    name: 'Kirkland',
    postalCode: '60146',
  },
  'kirkland-wa': {
    name: 'Kirkland',
    postalCode: '98033',
  },
  'kirklin-in': {
    name: 'Kirklin',
    postalCode: '46050',
  },
  'kirkman-ia': {
    name: 'Kirkman',
    postalCode: '51447',
  },
  'kirksey-ky': {
    name: 'Kirksey',
    postalCode: '42054',
  },
  'kirksville-mo': {
    name: 'Kirksville',
    postalCode: '63501',
  },
  'kirkville-ia': {
    name: 'Kirkville',
    postalCode: '52566',
  },
  'kirkville-ny': {
    name: 'Kirkville',
    postalCode: '13082',
  },
  'kirkwood-il': {
    name: 'Kirkwood',
    postalCode: '61447',
  },
  'kirkwood-ny': {
    name: 'Kirkwood',
    postalCode: '13795',
  },
  'kirkwood-pa': {
    name: 'Kirkwood',
    postalCode: '17536',
  },
  'kiron-ia': {
    name: 'Kiron',
    postalCode: '51448',
  },
  'kirtland-nm': {
    name: 'Kirtland',
    postalCode: '87417',
  },
  'kirwin-ks': {
    name: 'Kirwin',
    postalCode: '67644',
  },
  'kismet-ks': {
    name: 'Kismet',
    postalCode: '67859',
  },
  'kissee-mills-mo': {
    name: 'Kissee Mills',
    postalCode: '65680',
  },
  'kissimmee-fl': {
    name: 'Kissimmee',
    postalCode: '34741',
  },
  'kit-carson-co': {
    name: 'Kit Carson',
    postalCode: '80825',
  },
  'kite-ga': {
    name: 'Kite',
    postalCode: '31049',
  },
  'kite-ky': {
    name: 'Kite',
    postalCode: '41828',
  },
  'kittanning-pa': {
    name: 'Kittanning',
    postalCode: '16201',
  },
  'kittery-me': {
    name: 'Kittery',
    postalCode: '03904',
  },
  'kittery-point-me': {
    name: 'Kittery Point',
    postalCode: '03905',
  },
  'kittrell-nc': {
    name: 'Kittrell',
    postalCode: '27544',
  },
  'kitts-hill-oh': {
    name: 'Kitts Hill',
    postalCode: '45645',
  },
  'kitty-hawk-nc': {
    name: 'Kitty Hawk',
    postalCode: '27949',
  },
  'kitzmiller-md': {
    name: 'Kitzmiller',
    postalCode: '21538',
  },
  'klamath-ca': {
    name: 'Klamath',
    postalCode: '95548',
  },
  'klamath-falls-or': {
    name: 'Klamath Falls',
    postalCode: '97601',
  },
  'klamath-river-ca': {
    name: 'Klamath River',
    postalCode: '96050',
  },
  'klemme-ia': {
    name: 'Klemme',
    postalCode: '50449',
  },
  'klickitat-wa': {
    name: 'Klickitat',
    postalCode: '98628',
  },
  'klingerstown-pa': {
    name: 'Klingerstown',
    postalCode: '17941',
  },
  'klondike-tx': {
    name: 'Klondike',
    postalCode: '75448',
  },
  'knapp-wi': {
    name: 'Knapp',
    postalCode: '54749',
  },
  'kneeland-ca': {
    name: 'Kneeland',
    postalCode: '95549',
  },
  'knifley-ky': {
    name: 'Knifley',
    postalCode: '42753',
  },
  'knightdale-nc': {
    name: 'Knightdale',
    postalCode: '27545',
  },
  'knights-landing-ca': {
    name: 'Knights Landing',
    postalCode: '95645',
  },
  'knightstown-in': {
    name: 'Knightstown',
    postalCode: '46148',
  },
  'knippa-tx': {
    name: 'Knippa',
    postalCode: '78870',
  },
  'knob-lick-ky': {
    name: 'Knob Lick',
    postalCode: '42154',
  },
  'knob-noster-mo': {
    name: 'Knob Noster',
    postalCode: '65336',
  },
  'knobel-ar': {
    name: 'Knobel',
    postalCode: '72435',
  },
  'knott-tx': {
    name: 'Knott',
    postalCode: '79748',
  },
  'knotts-island-nc': {
    name: 'Knotts Island',
    postalCode: '27950',
  },
  'knowlesville-ny': {
    name: 'Knowlesville',
    postalCode: '14479',
  },
  'knox-in': {
    name: 'Knox',
    postalCode: '46534',
  },
  'knox-nd': {
    name: 'Knox',
    postalCode: '58343',
  },
  'knox-pa': {
    name: 'Knox',
    postalCode: '16232',
  },
  'knox-city-mo': {
    name: 'Knox City',
    postalCode: '63446',
  },
  'knox-city-tx': {
    name: 'Knox City',
    postalCode: '79529',
  },
  'knoxville-al': {
    name: 'Knoxville',
    postalCode: '35469',
  },
  'knoxville-ar': {
    name: 'Knoxville',
    postalCode: '72845',
  },
  'knoxville-ga': {
    name: 'Knoxville',
    postalCode: '31050',
  },
  'knoxville-ia': {
    name: 'Knoxville',
    postalCode: '50138',
  },
  'knoxville-il': {
    name: 'Knoxville',
    postalCode: '61448',
  },
  'knoxville-md': {
    name: 'Knoxville',
    postalCode: '21758',
  },
  'knoxville-pa': {
    name: 'Knoxville',
    postalCode: '16928',
  },
  'knoxville-tn': {
    name: 'Knoxville',
    postalCode: '37902',
  },
  'kodak-tn': {
    name: 'Kodak',
    postalCode: '37764',
  },
  'kodiak-ak': {
    name: 'Kodiak',
    postalCode: '99615',
  },
  'koeltztown-mo': {
    name: 'Koeltztown',
    postalCode: '65048',
  },
  'kohler-wi': {
    name: 'Kohler',
    postalCode: '53044',
  },
  'kokomo-in': {
    name: 'Kokomo',
    postalCode: '46901',
  },
  'kokomo-ms': {
    name: 'Kokomo',
    postalCode: '39643',
  },
  'koloa-hi': {
    name: 'Koloa',
    postalCode: '96756',
  },
  'konawa-ok': {
    name: 'Konawa',
    postalCode: '74849',
  },
  'koosharem-ut': {
    name: 'Koosharem',
    postalCode: '84744',
  },
  'kooskia-id': {
    name: 'Kooskia',
    postalCode: '83539',
  },
  'kopperl-tx': {
    name: 'Kopperl',
    postalCode: '76652',
  },
  'korbel-ca': {
    name: 'Korbel',
    postalCode: '95550',
  },
  'kosciusko-ms': {
    name: 'Kosciusko',
    postalCode: '39090',
  },
  'koshkonong-mo': {
    name: 'Koshkonong',
    postalCode: '65692',
  },
  'kosse-tx': {
    name: 'Kosse',
    postalCode: '76653',
  },
  'kossuth-pa': {
    name: 'Kossuth',
    postalCode: '16331',
  },
  'kountze-tx': {
    name: 'Kountze',
    postalCode: '77625',
  },
  'kouts-in': {
    name: 'Kouts',
    postalCode: '46347',
  },
  'krakow-wi': {
    name: 'Krakow',
    postalCode: '54137',
  },
  'kramer-nd': {
    name: 'Kramer',
    postalCode: '58748',
  },
  'kremlin-mt': {
    name: 'Kremlin',
    postalCode: '59532',
  },
  'kremlin-ok': {
    name: 'Kremlin',
    postalCode: '73753',
  },
  'kremmling-co': {
    name: 'Kremmling',
    postalCode: '80459',
  },
  'kresgeville-pa': {
    name: 'Kresgeville',
    postalCode: '18333',
  },
  'kress-tx': {
    name: 'Kress',
    postalCode: '79052',
  },
  'krotz-springs-la': {
    name: 'Krotz Springs',
    postalCode: '70750',
  },
  'krum-tx': {
    name: 'Krum',
    postalCode: '76249',
  },
  'krypton-ky': {
    name: 'Krypton',
    postalCode: '41754',
  },
  'kula-hi': {
    name: 'Kula',
    postalCode: '96790',
  },
  'kulm-nd': {
    name: 'Kulm',
    postalCode: '58456',
  },
  'kulpmont-pa': {
    name: 'Kulpmont',
    postalCode: '17834',
  },
  'kuna-id': {
    name: 'Kuna',
    postalCode: '83634',
  },
  'kunkletown-pa': {
    name: 'Kunkletown',
    postalCode: '18058',
  },
  'kure-beach-nc': {
    name: 'Kure Beach',
    postalCode: '28449',
  },
  'kurtistown-hi': {
    name: 'Kurtistown',
    postalCode: '96760',
  },
  'kuttawa-ky': {
    name: 'Kuttawa',
    postalCode: '42055',
  },
  'kutztown-pa': {
    name: 'Kutztown',
    postalCode: '19530',
  },
  'kyburz-ca': {
    name: 'Kyburz',
    postalCode: '95720',
  },
  'kyle-sd': {
    name: 'Kyle',
    postalCode: '57752',
  },
  'kyle-tx': {
    name: 'Kyle',
    postalCode: '78640',
  },
  'kyle-wv': {
    name: 'Kyle',
    postalCode: '24855',
  },
  'kyles-ford-tn': {
    name: 'Kyles Ford',
    postalCode: '37765',
  },
  'la-barge-wy': {
    name: 'La Barge',
    postalCode: '83123',
  },
  'la-belle-mo': {
    name: 'La Belle',
    postalCode: '63447',
  },
  'la-belle-pa': {
    name: 'La Belle',
    postalCode: '15450',
  },
  'la-canada-flintridge-ca': {
    name: 'La Canada Flintridge',
    postalCode: '91011',
  },
  'la-center-ky': {
    name: 'La Center',
    postalCode: '42056',
  },
  'la-center-wa': {
    name: 'La Center',
    postalCode: '98629',
  },
  'la-conner-wa': {
    name: 'La Conner',
    postalCode: '98257',
  },
  'la-coste-tx': {
    name: 'La Coste',
    postalCode: '78039',
  },
  'la-crescent-mn': {
    name: 'La Crescent',
    postalCode: '55947',
  },
  'la-crescenta-ca': {
    name: 'La Crescenta',
    postalCode: '91214',
  },
  'la-crosse-in': {
    name: 'La Crosse',
    postalCode: '46348',
  },
  'la-crosse-ks': {
    name: 'La Crosse',
    postalCode: '67548',
  },
  'la-crosse-va': {
    name: 'La Crosse',
    postalCode: '23950',
  },
  'la-crosse-wi': {
    name: 'La Crosse',
    postalCode: '54601',
  },
  'la-cygne-ks': {
    name: 'La Cygne',
    postalCode: '66040',
  },
  'la-farge-wi': {
    name: 'La Farge',
    postalCode: '54639',
  },
  'la-fargeville-ny': {
    name: 'La Fargeville',
    postalCode: '13656',
  },
  'la-fayette-ga': {
    name: 'La Fayette',
    postalCode: '30728',
  },
  'la-fayette-il': {
    name: 'La Fayette',
    postalCode: '61449',
  },
  'la-fayette-ky': {
    name: 'La Fayette',
    postalCode: '42254',
  },
  'la-fayette-ny': {
    name: 'La Fayette',
    postalCode: '13084',
  },
  'la-feria-tx': {
    name: 'La Feria',
    postalCode: '78559',
  },
  'la-follette-tn': {
    name: 'La Follette',
    postalCode: '37766',
  },
  'la-fontaine-in': {
    name: 'La Fontaine',
    postalCode: '46940',
  },
  'la-grande-or': {
    name: 'La Grande',
    postalCode: '97850',
  },
  'la-grange-ca': {
    name: 'La Grange',
    postalCode: '95329',
  },
  'la-grange-il': {
    name: 'La Grange',
    postalCode: '60525',
  },
  'la-grange-ky': {
    name: 'La Grange',
    postalCode: '40031',
  },
  'la-grange-mo': {
    name: 'La Grange',
    postalCode: '63448',
  },
  'la-grange-nc': {
    name: 'La Grange',
    postalCode: '28551',
  },
  'la-grange-tx': {
    name: 'La Grange',
    postalCode: '78945',
  },
  'la-grange-park-il': {
    name: 'La Grange Park',
    postalCode: '60526',
  },
  'la-habra-ca': {
    name: 'La Habra',
    postalCode: '90631',
  },
  'la-harpe-il': {
    name: 'La Harpe',
    postalCode: '61450',
  },
  'la-harpe-ks': {
    name: 'La Harpe',
    postalCode: '66751',
  },
  'la-honda-ca': {
    name: 'La Honda',
    postalCode: '94020',
  },
  'la-jara-co': {
    name: 'La Jara',
    postalCode: '81140',
  },
  'la-jara-nm': {
    name: 'La Jara',
    postalCode: '87027',
  },
  'la-jolla-ca': {
    name: 'La Jolla',
    postalCode: '92037',
  },
  'la-jose-pa': {
    name: 'La Jose',
    postalCode: '15753',
  },
  'la-joya-nm': {
    name: 'La Joya',
    postalCode: '87028',
  },
  'la-joya-tx': {
    name: 'La Joya',
    postalCode: '78560',
  },
  'la-junta-co': {
    name: 'La Junta',
    postalCode: '81050',
  },
  'la-loma-nm': {
    name: 'La Loma',
    postalCode: '87724',
  },
  'la-luz-nm': {
    name: 'La Luz',
    postalCode: '88337',
  },
  'la-madera-nm': {
    name: 'La Madera',
    postalCode: '87539',
  },
  'la-marque-tx': {
    name: 'La Marque',
    postalCode: '77568',
  },
  'la-mesa-ca': {
    name: 'La Mesa',
    postalCode: '91941',
  },
  'la-mesa-nm': {
    name: 'La Mesa',
    postalCode: '88044',
  },
  'la-mirada-ca': {
    name: 'La Mirada',
    postalCode: '90638',
  },
  'la-moille-il': {
    name: 'La Moille',
    postalCode: '61330',
  },
  'la-monte-mo': {
    name: 'La Monte',
    postalCode: '65337',
  },
  'la-motte-ia': {
    name: 'La Motte',
    postalCode: '52054',
  },
  'la-palma-ca': {
    name: 'La Palma',
    postalCode: '90623',
  },
  'la-pine-or': {
    name: 'La Pine',
    postalCode: '97739',
  },
  'la-place-la': {
    name: 'La Place',
    postalCode: '70068',
  },
  'la-plata-md': {
    name: 'La Plata',
    postalCode: '20646',
  },
  'la-plata-mo': {
    name: 'La Plata',
    postalCode: '63549',
  },
  'la-plata-nm': {
    name: 'La Plata',
    postalCode: '87418',
  },
  'la-porte-in': {
    name: 'La Porte',
    postalCode: '46350',
  },
  'la-porte-tx': {
    name: 'La Porte',
    postalCode: '77571',
  },
  'la-porte-city-ia': {
    name: 'La Porte City',
    postalCode: '50651',
  },
  'la-prairie-il': {
    name: 'La Prairie',
    postalCode: '62346',
  },
  'la-puente-ca': {
    name: 'La Puente',
    postalCode: '91744',
  },
  'la-quinta-ca': {
    name: 'La Quinta',
    postalCode: '92253',
  },
  'la-rue-oh': {
    name: 'La Rue',
    postalCode: '43332',
  },
  'la-russell-mo': {
    name: 'La Russell',
    postalCode: '64848',
  },
  'la-salle-co': {
    name: 'La Salle',
    postalCode: '80645',
  },
  'la-salle-il': {
    name: 'La Salle',
    postalCode: '61301',
  },
  'la-salle-mi': {
    name: 'La Salle',
    postalCode: '48145',
  },
  'la-valle-wi': {
    name: 'La Valle',
    postalCode: '53941',
  },
  'la-vergne-tn': {
    name: 'La Vergne',
    postalCode: '37086',
  },
  'la-verkin-ut': {
    name: 'La Verkin',
    postalCode: '84745',
  },
  'la-verne-ca': {
    name: 'La Verne',
    postalCode: '91750',
  },
  'la-vernia-tx': {
    name: 'La Vernia',
    postalCode: '78121',
  },
  'la-veta-co': {
    name: 'La Veta',
    postalCode: '81055',
  },
  'la-vista-ne': {
    name: 'La Vista',
    postalCode: '68128',
  },
  'labadie-mo': {
    name: 'Labadie',
    postalCode: '63055',
  },
  'labadieville-la': {
    name: 'Labadieville',
    postalCode: '70372',
  },
  'labelle-fl': {
    name: 'Labelle',
    postalCode: '33935',
  },
  'labolt-sd': {
    name: 'Labolt',
    postalCode: '57246',
  },
  'lac-du-flambeau-wi': {
    name: 'Lac Du Flambeau',
    postalCode: '54538',
  },
  'lacey-wa': {
    name: 'Lacey',
    postalCode: '98503',
  },
  'laceys-spring-al': {
    name: 'Laceys Spring',
    postalCode: '35754',
  },
  'laceyville-pa': {
    name: 'Laceyville',
    postalCode: '18623',
  },
  'lachine-mi': {
    name: 'Lachine',
    postalCode: '49753',
  },
  'lackawaxen-pa': {
    name: 'Lackawaxen',
    postalCode: '18435',
  },
  'lackey-ky': {
    name: 'Lackey',
    postalCode: '41643',
  },
  'laclede-mo': {
    name: 'Laclede',
    postalCode: '64651',
  },
  'lacombe-la': {
    name: 'Lacombe',
    postalCode: '70445',
  },
  'lacon-il': {
    name: 'Lacon',
    postalCode: '61540',
  },
  'lacona-ia': {
    name: 'Lacona',
    postalCode: '50139',
  },
  'lacona-ny': {
    name: 'Lacona',
    postalCode: '13083',
  },
  'laconia-in': {
    name: 'Laconia',
    postalCode: '47135',
  },
  'laconia-nh': {
    name: 'Laconia',
    postalCode: '03246',
  },
  'lacrosse-wa': {
    name: 'Lacrosse',
    postalCode: '99143',
  },
  'ladd-il': {
    name: 'Ladd',
    postalCode: '61329',
  },
  'laddonia-mo': {
    name: 'Laddonia',
    postalCode: '63352',
  },
  'ladera-ranch-ca': {
    name: 'Ladera Ranch',
    postalCode: '92694',
  },
  'ladoga-in': {
    name: 'Ladoga',
    postalCode: '47954',
  },
  'ladonia-tx': {
    name: 'Ladonia',
    postalCode: '75449',
  },
  'ladora-ia': {
    name: 'Ladora',
    postalCode: '52251',
  },
  'ladson-sc': {
    name: 'Ladson',
    postalCode: '29456',
  },
  'lady-lake-fl': {
    name: 'Lady Lake',
    postalCode: '32159',
  },
  'ladysmith-va': {
    name: 'Ladysmith',
    postalCode: '22501',
  },
  'ladysmith-wi': {
    name: 'Ladysmith',
    postalCode: '54848',
  },
  'lafayette-al': {
    name: 'Lafayette',
    postalCode: '36862',
  },
  'lafayette-ca': {
    name: 'Lafayette',
    postalCode: '94549',
  },
  'lafayette-co': {
    name: 'Lafayette',
    postalCode: '80026',
  },
  'lafayette-in': {
    name: 'Lafayette',
    postalCode: '47901',
  },
  'lafayette-la': {
    name: 'Lafayette',
    postalCode: '70500',
  },
  'lafayette-mn': {
    name: 'Lafayette',
    postalCode: '56054',
  },
  'lafayette-nj': {
    name: 'Lafayette',
    postalCode: '07848',
  },
  'lafayette-or': {
    name: 'Lafayette',
    postalCode: '97127',
  },
  'lafayette-tn': {
    name: 'Lafayette',
    postalCode: '37083',
  },
  'lafayette-hill-pa': {
    name: 'Lafayette Hill',
    postalCode: '19444',
  },
  'lafe-ar': {
    name: 'Lafe',
    postalCode: '72436',
  },
  'lafitte-la': {
    name: 'Lafitte',
    postalCode: '70067',
  },
  'lagrange-ga': {
    name: 'Lagrange',
    postalCode: '30240',
  },
  'lagrange-in': {
    name: 'Lagrange',
    postalCode: '46761',
  },
  'lagrange-me': {
    name: 'Lagrange',
    postalCode: '04453',
  },
  'lagrange-oh': {
    name: 'Lagrange',
    postalCode: '44050',
  },
  'lagrange-wy': {
    name: 'Lagrange',
    postalCode: '82221',
  },
  'lagrangeville-ny': {
    name: 'Lagrangeville',
    postalCode: '12540',
  },
  'lagro-in': {
    name: 'Lagro',
    postalCode: '46941',
  },
  'laguna-nm': {
    name: 'Laguna',
    postalCode: '87026',
  },
  'laguna-beach-ca': {
    name: 'Laguna Beach',
    postalCode: '92651',
  },
  'laguna-hills-ca': {
    name: 'Laguna Hills',
    postalCode: '92653',
  },
  'laguna-niguel-ca': {
    name: 'Laguna Niguel',
    postalCode: '92677',
  },
  'laguna-woods-ca': {
    name: 'Laguna Woods',
    postalCode: '92637',
  },
  'lahaina-hi': {
    name: 'Lahaina',
    postalCode: '96761',
  },
  'lahmansville-wv': {
    name: 'Lahmansville',
    postalCode: '26731',
  },
  'lahoma-ok': {
    name: 'Lahoma',
    postalCode: '73754',
  },
  'laie-hi': {
    name: 'Laie',
    postalCode: '96762',
  },
  'laingsburg-mi': {
    name: 'Laingsburg',
    postalCode: '48848',
  },
  'lairdsville-pa': {
    name: 'Lairdsville',
    postalCode: '17742',
  },
  'lajas-pr': {
    name: 'Lajas',
    postalCode: '00667',
  },
  'lake-mi': {
    name: 'Lake',
    postalCode: '48632',
  },
  'lake-ms': {
    name: 'Lake',
    postalCode: '39092',
  },
  'lake-wv': {
    name: 'Lake',
    postalCode: '25121',
  },
  'lake-alfred-fl': {
    name: 'Lake Alfred',
    postalCode: '33850',
  },
  'lake-andes-sd': {
    name: 'Lake Andes',
    postalCode: '57356',
  },
  'lake-ann-mi': {
    name: 'Lake Ann',
    postalCode: '49650',
  },
  'lake-ariel-pa': {
    name: 'Lake Ariel',
    postalCode: '18436',
  },
  'lake-arthur-la': {
    name: 'Lake Arthur',
    postalCode: '70549',
  },
  'lake-arthur-nm': {
    name: 'Lake Arthur',
    postalCode: '88253',
  },
  'lake-benton-mn': {
    name: 'Lake Benton',
    postalCode: '56149',
  },
  'lake-bluff-il': {
    name: 'Lake Bluff',
    postalCode: '60044',
  },
  'lake-bronson-mn': {
    name: 'Lake Bronson',
    postalCode: '56734',
  },
  'lake-butler-fl': {
    name: 'Lake Butler',
    postalCode: '32054',
  },
  'lake-charles-la': {
    name: 'Lake Charles',
    postalCode: '70601',
  },
  'lake-city-ar': {
    name: 'Lake City',
    postalCode: '72437',
  },
  'lake-city-ca': {
    name: 'Lake City',
    postalCode: '96115',
  },
  'lake-city-co': {
    name: 'Lake City',
    postalCode: '81235',
  },
  'lake-city-fl': {
    name: 'Lake City',
    postalCode: '32024',
  },
  'lake-city-ia': {
    name: 'Lake City',
    postalCode: '51449',
  },
  'lake-city-ks': {
    name: 'Lake City',
    postalCode: '67071',
  },
  'lake-city-mi': {
    name: 'Lake City',
    postalCode: '49651',
  },
  'lake-city-mn': {
    name: 'Lake City',
    postalCode: '55041',
  },
  'lake-city-pa': {
    name: 'Lake City',
    postalCode: '16423',
  },
  'lake-city-sc': {
    name: 'Lake City',
    postalCode: '29560',
  },
  'lake-city-sd': {
    name: 'Lake City',
    postalCode: '57247',
  },
  'lake-clear-ny': {
    name: 'Lake Clear',
    postalCode: '12945',
  },
  'lake-como-pa': {
    name: 'Lake Como',
    postalCode: '18437',
  },
  'lake-cormorant-ms': {
    name: 'Lake Cormorant',
    postalCode: '38641',
  },
  'lake-creek-tx': {
    name: 'Lake Creek',
    postalCode: '75450',
  },
  'lake-crystal-mn': {
    name: 'Lake Crystal',
    postalCode: '56055',
  },
  'lake-dallas-tx': {
    name: 'Lake Dallas',
    postalCode: '75065',
  },
  'lake-elmo-mn': {
    name: 'Lake Elmo',
    postalCode: '55042',
  },
  'lake-elsinore-ca': {
    name: 'Lake Elsinore',
    postalCode: '92530',
  },
  'lake-forest-ca': {
    name: 'Lake Forest',
    postalCode: '92630',
  },
  'lake-forest-il': {
    name: 'Lake Forest',
    postalCode: '60045',
  },
  'lake-geneva-wi': {
    name: 'Lake Geneva',
    postalCode: '53147',
  },
  'lake-george-co': {
    name: 'Lake George',
    postalCode: '80827',
  },
  'lake-george-mn': {
    name: 'Lake George',
    postalCode: '56458',
  },
  'lake-george-ny': {
    name: 'Lake George',
    postalCode: '12845',
  },
  'lake-grove-ny': {
    name: 'Lake Grove',
    postalCode: '11755',
  },
  'lake-havasu-city-az': {
    name: 'Lake Havasu City',
    postalCode: '86403',
  },
  'lake-helen-fl': {
    name: 'Lake Helen',
    postalCode: '32744',
  },
  'lake-hiawatha-nj': {
    name: 'Lake Hiawatha',
    postalCode: '07034',
  },
  'lake-hill-ny': {
    name: 'Lake Hill',
    postalCode: '12448',
  },
  'lake-hopatcong-nj': {
    name: 'Lake Hopatcong',
    postalCode: '07849',
  },
  'lake-hughes-ca': {
    name: 'Lake Hughes',
    postalCode: '93532',
  },
  'lake-huntington-ny': {
    name: 'Lake Huntington',
    postalCode: '12752',
  },
  'lake-in-the-hills-il': {
    name: 'Lake In The Hills',
    postalCode: '60156',
  },
  'lake-isabella-ca': {
    name: 'Lake Isabella',
    postalCode: '93240',
  },
  'lake-jackson-tx': {
    name: 'Lake Jackson',
    postalCode: '77566',
  },
  'lake-junaluska-nc': {
    name: 'Lake Junaluska',
    postalCode: '28745',
  },
  'lake-katrine-ny': {
    name: 'Lake Katrine',
    postalCode: '12449',
  },
  'lake-leelanau-mi': {
    name: 'Lake Leelanau',
    postalCode: '49653',
  },
  'lake-lillian-mn': {
    name: 'Lake Lillian',
    postalCode: '56253',
  },
  'lake-linden-mi': {
    name: 'Lake Linden',
    postalCode: '49945',
  },
  'lake-lure-nc': {
    name: 'Lake Lure',
    postalCode: '28746',
  },
  'lake-luzerne-ny': {
    name: 'Lake Luzerne',
    postalCode: '12846',
  },
  'lake-lynn-pa': {
    name: 'Lake Lynn',
    postalCode: '15451',
  },
  'lake-mary-fl': {
    name: 'Lake Mary',
    postalCode: '32746',
  },
  'lake-mills-ia': {
    name: 'Lake Mills',
    postalCode: '50450',
  },
  'lake-mills-wi': {
    name: 'Lake Mills',
    postalCode: '53551',
  },
  'lake-milton-oh': {
    name: 'Lake Milton',
    postalCode: '44429',
  },
  'lake-nebagamon-wi': {
    name: 'Lake Nebagamon',
    postalCode: '54849',
  },
  'lake-norden-sd': {
    name: 'Lake Norden',
    postalCode: '57248',
  },
  'lake-odessa-mi': {
    name: 'Lake Odessa',
    postalCode: '48849',
  },
  'lake-orion-mi': {
    name: 'Lake Orion',
    postalCode: '48359',
  },
  'lake-oswego-or': {
    name: 'Lake Oswego',
    postalCode: '97034',
  },
  'lake-ozark-mo': {
    name: 'Lake Ozark',
    postalCode: '65049',
  },
  'lake-panasoffkee-fl': {
    name: 'Lake Panasoffkee',
    postalCode: '33538',
  },
  'lake-park-ga': {
    name: 'Lake Park',
    postalCode: '31636',
  },
  'lake-park-ia': {
    name: 'Lake Park',
    postalCode: '51347',
  },
  'lake-park-mn': {
    name: 'Lake Park',
    postalCode: '56554',
  },
  'lake-peekskill-ny': {
    name: 'Lake Peekskill',
    postalCode: '10537',
  },
  'lake-placid-fl': {
    name: 'Lake Placid',
    postalCode: '33852',
  },
  'lake-placid-ny': {
    name: 'Lake Placid',
    postalCode: '12946',
  },
  'lake-pleasant-ny': {
    name: 'Lake Pleasant',
    postalCode: '12108',
  },
  'lake-powell-ut': {
    name: 'Lake Powell',
    postalCode: '84533',
  },
  'lake-preston-sd': {
    name: 'Lake Preston',
    postalCode: '57249',
  },
  'lake-providence-la': {
    name: 'Lake Providence',
    postalCode: '71254',
  },
  'lake-saint-louis-mo': {
    name: 'Lake Saint Louis',
    postalCode: '63367',
  },
  'lake-station-in': {
    name: 'Lake Station',
    postalCode: '46405',
  },
  'lake-stevens-wa': {
    name: 'Lake Stevens',
    postalCode: '98258',
  },
  'lake-tomahawk-wi': {
    name: 'Lake Tomahawk',
    postalCode: '54539',
  },
  'lake-toxaway-nc': {
    name: 'Lake Toxaway',
    postalCode: '28747',
  },
  'lake-view-ia': {
    name: 'Lake View',
    postalCode: '51450',
  },
  'lake-view-ny': {
    name: 'Lake View',
    postalCode: '14085',
  },
  'lake-view-sc': {
    name: 'Lake View',
    postalCode: '29563',
  },
  'lake-villa-il': {
    name: 'Lake Villa',
    postalCode: '60046',
  },
  'lake-village-ar': {
    name: 'Lake Village',
    postalCode: '71653',
  },
  'lake-village-in': {
    name: 'Lake Village',
    postalCode: '46349',
  },
  'lake-waccamaw-nc': {
    name: 'Lake Waccamaw',
    postalCode: '28450',
  },
  'lake-wales-fl': {
    name: 'Lake Wales',
    postalCode: '33853',
  },
  'lake-wilson-mn': {
    name: 'Lake Wilson',
    postalCode: '56151',
  },
  'lake-worth-fl': {
    name: 'Lake Worth',
    postalCode: '33449',
  },
  'lake-worth-beach-fl': {
    name: 'Lake Worth Beach',
    postalCode: '33460',
  },
  'lake-zurich-il': {
    name: 'Lake Zurich',
    postalCode: '60047',
  },
  'lakebay-wa': {
    name: 'Lakebay',
    postalCode: '98349',
  },
  'lakefield-mn': {
    name: 'Lakefield',
    postalCode: '56150',
  },
  'lakehead-ca': {
    name: 'Lakehead',
    postalCode: '96051',
  },
  'lakehurst-nj': {
    name: 'Lakehurst',
    postalCode: '08733',
  },
  'lakeland-fl': {
    name: 'Lakeland',
    postalCode: '33801',
  },
  'lakeland-ga': {
    name: 'Lakeland',
    postalCode: '31635',
  },
  'lakeland-la': {
    name: 'Lakeland',
    postalCode: '70752',
  },
  'lakeland-mn': {
    name: 'Lakeland',
    postalCode: '55043',
  },
  'lakemont-ga': {
    name: 'Lakemont',
    postalCode: '30552',
  },
  'lakeport-ca': {
    name: 'Lakeport',
    postalCode: '95453',
  },
  'lakeside-az': {
    name: 'Lakeside',
    postalCode: '85929',
  },
  'lakeside-ca': {
    name: 'Lakeside',
    postalCode: '92040',
  },
  'lakeside-ct': {
    name: 'Lakeside',
    postalCode: '06758',
  },
  'lakeside-mi': {
    name: 'Lakeside',
    postalCode: '49116',
  },
  'lakeside-mt': {
    name: 'Lakeside',
    postalCode: '59922',
  },
  'lakeside-ne': {
    name: 'Lakeside',
    postalCode: '69351',
  },
  'lakeside-or': {
    name: 'Lakeside',
    postalCode: '97449',
  },
  'lakeside-marblehead-oh': {
    name: 'Lakeside Marblehead',
    postalCode: '43440',
  },
  'laketon-in': {
    name: 'Laketon',
    postalCode: '46943',
  },
  'lakeview-ar': {
    name: 'Lakeview',
    postalCode: '72642',
  },
  'lakeview-mi': {
    name: 'Lakeview',
    postalCode: '48850',
  },
  'lakeview-oh': {
    name: 'Lakeview',
    postalCode: '43331',
  },
  'lakeview-or': {
    name: 'Lakeview',
    postalCode: '97630',
  },
  'lakeview-tx': {
    name: 'Lakeview',
    postalCode: '79239',
  },
  'lakeville-ct': {
    name: 'Lakeville',
    postalCode: '06039',
  },
  'lakeville-in': {
    name: 'Lakeville',
    postalCode: '46536',
  },
  'lakeville-ma': {
    name: 'Lakeville',
    postalCode: '02347',
  },
  'lakeville-mn': {
    name: 'Lakeville',
    postalCode: '55044',
  },
  'lakeville-ny': {
    name: 'Lakeville',
    postalCode: '14480',
  },
  'lakeville-oh': {
    name: 'Lakeville',
    postalCode: '44638',
  },
  'lakeville-pa': {
    name: 'Lakeville',
    postalCode: '18438',
  },
  'lakewood-ca': {
    name: 'Lakewood',
    postalCode: '90712',
  },
  'lakewood-il': {
    name: 'Lakewood',
    postalCode: '62438',
  },
  'lakewood-nj': {
    name: 'Lakewood',
    postalCode: '08701',
  },
  'lakewood-ny': {
    name: 'Lakewood',
    postalCode: '14750',
  },
  'lakewood-oh': {
    name: 'Lakewood',
    postalCode: '44107',
  },
  'lakewood-pa': {
    name: 'Lakewood',
    postalCode: '18439',
  },
  'lakewood-wa': {
    name: 'Lakewood',
    postalCode: '98439',
  },
  'lakewood-wi': {
    name: 'Lakewood',
    postalCode: '54138',
  },
  'lakin-ks': {
    name: 'Lakin',
    postalCode: '67860',
  },
  'lakota-ia': {
    name: 'Lakota',
    postalCode: '50451',
  },
  'lakota-nd': {
    name: 'Lakota',
    postalCode: '58344',
  },
  'lamar-ar': {
    name: 'Lamar',
    postalCode: '72846',
  },
  'lamar-co': {
    name: 'Lamar',
    postalCode: '81052',
  },
  'lamar-in': {
    name: 'Lamar',
    postalCode: '47550',
  },
  'lamar-mo': {
    name: 'Lamar',
    postalCode: '64759',
  },
  'lamar-ms': {
    name: 'Lamar',
    postalCode: '38642',
  },
  'lamar-ok': {
    name: 'Lamar',
    postalCode: '74850',
  },
  'lamar-sc': {
    name: 'Lamar',
    postalCode: '29069',
  },
  'lambert-ms': {
    name: 'Lambert',
    postalCode: '38643',
  },
  'lambert-mt': {
    name: 'Lambert',
    postalCode: '59243',
  },
  'lamberton-mn': {
    name: 'Lamberton',
    postalCode: '56152',
  },
  'lambertville-mi': {
    name: 'Lambertville',
    postalCode: '48144',
  },
  'lambertville-nj': {
    name: 'Lambertville',
    postalCode: '08530',
  },
  'lambsburg-va': {
    name: 'Lambsburg',
    postalCode: '24351',
  },
  'lamesa-tx': {
    name: 'Lamesa',
    postalCode: '79331',
  },
  'lamoille-nv': {
    name: 'Lamoille',
    postalCode: '89828',
  },
  'lamona-wa': {
    name: 'Lamona',
    postalCode: '99144',
  },
  'lamoni-ia': {
    name: 'Lamoni',
    postalCode: '50140',
  },
  'lamont-ca': {
    name: 'Lamont',
    postalCode: '93241',
  },
  'lamont-fl': {
    name: 'Lamont',
    postalCode: '32336',
  },
  'lamont-ia': {
    name: 'Lamont',
    postalCode: '50650',
  },
  'lamont-ks': {
    name: 'Lamont',
    postalCode: '66855',
  },
  'lamont-ok': {
    name: 'Lamont',
    postalCode: '74643',
  },
  'lamont-wa': {
    name: 'Lamont',
    postalCode: '99017',
  },
  'lamoure-nd': {
    name: 'Lamoure',
    postalCode: '58458',
  },
  'lampasas-tx': {
    name: 'Lampasas',
    postalCode: '76550',
  },
  'lampe-mo': {
    name: 'Lampe',
    postalCode: '65681',
  },
  'lamy-nm': {
    name: 'Lamy',
    postalCode: '87540',
  },
  'lanagan-mo': {
    name: 'Lanagan',
    postalCode: '64847',
  },
  'lanark-il': {
    name: 'Lanark',
    postalCode: '61046',
  },
  'lancaster-ca': {
    name: 'Lancaster',
    postalCode: '93534',
  },
  'lancaster-ks': {
    name: 'Lancaster',
    postalCode: '66041',
  },
  'lancaster-ky': {
    name: 'Lancaster',
    postalCode: '40444',
  },
  'lancaster-ma': {
    name: 'Lancaster',
    postalCode: '01523',
  },
  'lancaster-mn': {
    name: 'Lancaster',
    postalCode: '56735',
  },
  'lancaster-mo': {
    name: 'Lancaster',
    postalCode: '63548',
  },
  'lancaster-nh': {
    name: 'Lancaster',
    postalCode: '03584',
  },
  'lancaster-ny': {
    name: 'Lancaster',
    postalCode: '14086',
  },
  'lancaster-oh': {
    name: 'Lancaster',
    postalCode: '43130',
  },
  'lancaster-pa': {
    name: 'Lancaster',
    postalCode: '17601',
  },
  'lancaster-sc': {
    name: 'Lancaster',
    postalCode: '29720',
  },
  'lancaster-tn': {
    name: 'Lancaster',
    postalCode: '38569',
  },
  'lancaster-tx': {
    name: 'Lancaster',
    postalCode: '75134',
  },
  'lancaster-va': {
    name: 'Lancaster',
    postalCode: '22503',
  },
  'lancaster-wi': {
    name: 'Lancaster',
    postalCode: '53813',
  },
  'lance-creek-wy': {
    name: 'Lance Creek',
    postalCode: '82222',
  },
  'lancing-tn': {
    name: 'Lancing',
    postalCode: '37770',
  },
  'land-o-lakes-fl': {
    name: 'Land O Lakes',
    postalCode: '34637',
  },
  'land-o-lakes-wi': {
    name: 'Land O Lakes',
    postalCode: '54540',
  },
  'landenberg-pa': {
    name: 'Landenberg',
    postalCode: '19350',
  },
  'lander-wy': {
    name: 'Lander',
    postalCode: '82520',
  },
  'landers-ca': {
    name: 'Landers',
    postalCode: '92285',
  },
  'landing-nj': {
    name: 'Landing',
    postalCode: '07850',
  },
  'landis-nc': {
    name: 'Landis',
    postalCode: '28088',
  },
  'landisburg-pa': {
    name: 'Landisburg',
    postalCode: '17040',
  },
  'landisville-nj': {
    name: 'Landisville',
    postalCode: '08326',
  },
  'landisville-pa': {
    name: 'Landisville',
    postalCode: '17538',
  },
  'landrum-sc': {
    name: 'Landrum',
    postalCode: '29356',
  },
  'lane-ks': {
    name: 'Lane',
    postalCode: '66042',
  },
  'lane-ok': {
    name: 'Lane',
    postalCode: '74555',
  },
  'lane-sc': {
    name: 'Lane',
    postalCode: '29564',
  },
  'lanesboro-mn': {
    name: 'Lanesboro',
    postalCode: '55949',
  },
  'lanesborough-ma': {
    name: 'Lanesborough',
    postalCode: '01237',
  },
  'lanesville-in': {
    name: 'Lanesville',
    postalCode: '47136',
  },
  'lanesville-ny': {
    name: 'Lanesville',
    postalCode: '12450',
  },
  'lanett-al': {
    name: 'Lanett',
    postalCode: '36863',
  },
  'laneview-va': {
    name: 'Laneview',
    postalCode: '22504',
  },
  'laneville-tx': {
    name: 'Laneville',
    postalCode: '75667',
  },
  'lanexa-va': {
    name: 'Lanexa',
    postalCode: '23089',
  },
  'langdon-nd': {
    name: 'Langdon',
    postalCode: '58249',
  },
  'langford-sd': {
    name: 'Langford',
    postalCode: '57454',
  },
  'langhorne-pa': {
    name: 'Langhorne',
    postalCode: '19047',
  },
  'langley-ar': {
    name: 'Langley',
    postalCode: '71952',
  },
  'langley-ky': {
    name: 'Langley',
    postalCode: '41645',
  },
  'langley-wa': {
    name: 'Langley',
    postalCode: '98260',
  },
  'langlois-or': {
    name: 'Langlois',
    postalCode: '97450',
  },
  'langston-al': {
    name: 'Langston',
    postalCode: '35755',
  },
  'langsville-oh': {
    name: 'Langsville',
    postalCode: '45741',
  },
  'langworthy-ia': {
    name: 'Langworthy',
    postalCode: '52252',
  },
  'lanham-md': {
    name: 'Lanham',
    postalCode: '20706',
  },
  'lankin-nd': {
    name: 'Lankin',
    postalCode: '58250',
  },
  'lannon-wi': {
    name: 'Lannon',
    postalCode: '53046',
  },
  'lanoka-harbor-nj': {
    name: 'Lanoka Harbor',
    postalCode: '08734',
  },
  'lansdale-pa': {
    name: 'Lansdale',
    postalCode: '19446',
  },
  'lansdowne-pa': {
    name: 'Lansdowne',
    postalCode: '19050',
  },
  'lanse-mi': {
    name: 'Lanse',
    postalCode: '49946',
  },
  'lansford-nd': {
    name: 'Lansford',
    postalCode: '58750',
  },
  'lansford-pa': {
    name: 'Lansford',
    postalCode: '18232',
  },
  'lansing-ia': {
    name: 'Lansing',
    postalCode: '52151',
  },
  'lansing-il': {
    name: 'Lansing',
    postalCode: '60438',
  },
  'lansing-ks': {
    name: 'Lansing',
    postalCode: '66043',
  },
  'lansing-mi': {
    name: 'Lansing',
    postalCode: '48906',
  },
  'lansing-nc': {
    name: 'Lansing',
    postalCode: '28643',
  },
  'lansing-ny': {
    name: 'Lansing',
    postalCode: '14882',
  },
  'lansing-wv': {
    name: 'Lansing',
    postalCode: '25862',
  },
  'laona-wi': {
    name: 'Laona',
    postalCode: '54541',
  },
  'laotto-in': {
    name: 'Laotto',
    postalCode: '46763',
  },
  'lapeer-mi': {
    name: 'Lapeer',
    postalCode: '48446',
  },
  'lapel-in': {
    name: 'Lapel',
    postalCode: '46051',
  },
  'lapine-al': {
    name: 'Lapine',
    postalCode: '36046',
  },
  'lapoint-ut': {
    name: 'Lapoint',
    postalCode: '84039',
  },
  'laporte-co': {
    name: 'Laporte',
    postalCode: '80535',
  },
  'laporte-mn': {
    name: 'Laporte',
    postalCode: '56461',
  },
  'lapwai-id': {
    name: 'Lapwai',
    postalCode: '83540',
  },
  'laquey-mo': {
    name: 'Laquey',
    postalCode: '65534',
  },
  'laramie-wy': {
    name: 'Laramie',
    postalCode: '82070',
  },
  'larchmont-ny': {
    name: 'Larchmont',
    postalCode: '10538',
  },
  'larchwood-ia': {
    name: 'Larchwood',
    postalCode: '51241',
  },
  'laredo-mo': {
    name: 'Laredo',
    postalCode: '64652',
  },
  'laredo-tx': {
    name: 'Laredo',
    postalCode: '78040',
  },
  'lares-pr': {
    name: 'Lares',
    postalCode: '00669',
  },
  'largo-fl': {
    name: 'Largo',
    postalCode: '33770',
  },
  'larimer-pa': {
    name: 'Larimer',
    postalCode: '15647',
  },
  'larimore-nd': {
    name: 'Larimore',
    postalCode: '58251',
  },
  'larkspur-ca': {
    name: 'Larkspur',
    postalCode: '94939',
  },
  'larkspur-co': {
    name: 'Larkspur',
    postalCode: '80118',
  },
  'larned-ks': {
    name: 'Larned',
    postalCode: '67550',
  },
  'larose-la': {
    name: 'Larose',
    postalCode: '70373',
  },
  'larrabee-ia': {
    name: 'Larrabee',
    postalCode: '51029',
  },
  'larsen-wi': {
    name: 'Larsen',
    postalCode: '54947',
  },
  'larslan-mt': {
    name: 'Larslan',
    postalCode: '59244',
  },
  'larue-tx': {
    name: 'Larue',
    postalCode: '75770',
  },
  'larwill-in': {
    name: 'Larwill',
    postalCode: '46764',
  },
  'las-animas-co': {
    name: 'Las Animas',
    postalCode: '81054',
  },
  'las-cruces-nm': {
    name: 'Las Cruces',
    postalCode: '88001',
  },
  'las-marias-pr': {
    name: 'Las Marias',
    postalCode: '00670',
  },
  'las-piedras-pr': {
    name: 'Las Piedras',
    postalCode: '00771',
  },
  'las-vegas-nm': {
    name: 'Las Vegas',
    postalCode: '87701',
  },
  'las-vegas-nv': {
    name: 'Las Vegas',
    postalCode: '89101',
  },
  'lascassas-tn': {
    name: 'Lascassas',
    postalCode: '37085',
  },
  'lashmeet-wv': {
    name: 'Lashmeet',
    postalCode: '24733',
  },
  'latah-wa': {
    name: 'Latah',
    postalCode: '99018',
  },
  'latham-il': {
    name: 'Latham',
    postalCode: '62543',
  },
  'latham-ks': {
    name: 'Latham',
    postalCode: '67072',
  },
  'latham-mo': {
    name: 'Latham',
    postalCode: '65050',
  },
  'latham-ny': {
    name: 'Latham',
    postalCode: '12110',
  },
  'latham-oh': {
    name: 'Latham',
    postalCode: '45646',
  },
  'lathrop-ca': {
    name: 'Lathrop',
    postalCode: '95330',
  },
  'lathrop-mo': {
    name: 'Lathrop',
    postalCode: '64465',
  },
  'latimer-ia': {
    name: 'Latimer',
    postalCode: '50452',
  },
  'laton-ca': {
    name: 'Laton',
    postalCode: '93242',
  },
  'latonia-ky': {
    name: 'Latonia',
    postalCode: '41015',
  },
  'latrobe-pa': {
    name: 'Latrobe',
    postalCode: '15650',
  },
  'latta-sc': {
    name: 'Latta',
    postalCode: '29565',
  },
  'lauderdale-ms': {
    name: 'Lauderdale',
    postalCode: '39335',
  },
  'laughlin-nv': {
    name: 'Laughlin',
    postalCode: '89029',
  },
  'laughlin-afb-tx': {
    name: 'Laughlin AFB',
    postalCode: '78843',
  },
  'laughlintown-pa': {
    name: 'Laughlintown',
    postalCode: '15655',
  },
  'laura-il': {
    name: 'Laura',
    postalCode: '61451',
  },
  'laura-oh': {
    name: 'Laura',
    postalCode: '45337',
  },
  'laurel-de': {
    name: 'Laurel',
    postalCode: '19956',
  },
  'laurel-ia': {
    name: 'Laurel',
    postalCode: '50141',
  },
  'laurel-in': {
    name: 'Laurel',
    postalCode: '47024',
  },
  'laurel-md': {
    name: 'Laurel',
    postalCode: '20707',
  },
  'laurel-ms': {
    name: 'Laurel',
    postalCode: '39440',
  },
  'laurel-mt': {
    name: 'Laurel',
    postalCode: '59044',
  },
  'laurel-ne': {
    name: 'Laurel',
    postalCode: '68745',
  },
  'laurel-ny': {
    name: 'Laurel',
    postalCode: '11948',
  },
  'laurel-bloomery-tn': {
    name: 'Laurel Bloomery',
    postalCode: '37680',
  },
  'laurel-fork-va': {
    name: 'Laurel Fork',
    postalCode: '24352',
  },
  'laurel-hill-fl': {
    name: 'Laurel Hill',
    postalCode: '32567',
  },
  'laurel-hill-nc': {
    name: 'Laurel Hill',
    postalCode: '28351',
  },
  'laurel-springs-nc': {
    name: 'Laurel Springs',
    postalCode: '28644',
  },
  'laurelville-oh': {
    name: 'Laurelville',
    postalCode: '43135',
  },
  'laurens-ia': {
    name: 'Laurens',
    postalCode: '50554',
  },
  'laurens-ny': {
    name: 'Laurens',
    postalCode: '13796',
  },
  'laurens-sc': {
    name: 'Laurens',
    postalCode: '29360',
  },
  'laurinburg-nc': {
    name: 'Laurinburg',
    postalCode: '28352',
  },
  'laurys-station-pa': {
    name: 'Laurys Station',
    postalCode: '18059',
  },
  'lava-hot-springs-id': {
    name: 'Lava Hot Springs',
    postalCode: '83246',
  },
  'lavaca-ar': {
    name: 'Lavaca',
    postalCode: '72941',
  },
  'lavalette-wv': {
    name: 'Lavalette',
    postalCode: '25535',
  },
  'lavallette-nj': {
    name: 'Lavallette',
    postalCode: '08735',
  },
  'laveen-az': {
    name: 'Laveen',
    postalCode: '85339',
  },
  'laverne-ok': {
    name: 'Laverne',
    postalCode: '73848',
  },
  'lavina-mt': {
    name: 'Lavina',
    postalCode: '59046',
  },
  'lavinia-tn': {
    name: 'Lavinia',
    postalCode: '38348',
  },
  'lavon-tx': {
    name: 'Lavon',
    postalCode: '75166',
  },
  'lavonia-ga': {
    name: 'Lavonia',
    postalCode: '30553',
  },
  'lawler-ia': {
    name: 'Lawler',
    postalCode: '52154',
  },
  'lawley-al': {
    name: 'Lawley',
    postalCode: '36793',
  },
  'lawn-tx': {
    name: 'Lawn',
    postalCode: '79530',
  },
  'lawndale-ca': {
    name: 'Lawndale',
    postalCode: '90260',
  },
  'lawndale-nc': {
    name: 'Lawndale',
    postalCode: '28090',
  },
  'lawnside-nj': {
    name: 'Lawnside',
    postalCode: '08045',
  },
  'lawrence-ks': {
    name: 'Lawrence',
    postalCode: '66044',
  },
  'lawrence-ma': {
    name: 'Lawrence',
    postalCode: '01840',
  },
  'lawrence-mi': {
    name: 'Lawrence',
    postalCode: '49064',
  },
  'lawrence-ms': {
    name: 'Lawrence',
    postalCode: '39336',
  },
  'lawrence-ne': {
    name: 'Lawrence',
    postalCode: '68957',
  },
  'lawrence-ny': {
    name: 'Lawrence',
    postalCode: '11559',
  },
  'lawrence-pa': {
    name: 'Lawrence',
    postalCode: '15055',
  },
  'lawrence-township-nj': {
    name: 'Lawrence Township',
    postalCode: '08648',
  },
  'lawrenceburg-in': {
    name: 'Lawrenceburg',
    postalCode: '47025',
  },
  'lawrenceburg-ky': {
    name: 'Lawrenceburg',
    postalCode: '40342',
  },
  'lawrenceburg-tn': {
    name: 'Lawrenceburg',
    postalCode: '38464',
  },
  'lawrenceville-ga': {
    name: 'Lawrenceville',
    postalCode: '30043',
  },
  'lawrenceville-il': {
    name: 'Lawrenceville',
    postalCode: '62439',
  },
  'lawrenceville-ny': {
    name: 'Lawrenceville',
    postalCode: '12949',
  },
  'lawrenceville-pa': {
    name: 'Lawrenceville',
    postalCode: '16929',
  },
  'lawrenceville-va': {
    name: 'Lawrenceville',
    postalCode: '23868',
  },
  'lawson-mo': {
    name: 'Lawson',
    postalCode: '64062',
  },
  'lawsonville-nc': {
    name: 'Lawsonville',
    postalCode: '27022',
  },
  'lawtey-fl': {
    name: 'Lawtey',
    postalCode: '32058',
  },
  'lawton-ia': {
    name: 'Lawton',
    postalCode: '51030',
  },
  'lawton-mi': {
    name: 'Lawton',
    postalCode: '49065',
  },
  'lawton-nd': {
    name: 'Lawton',
    postalCode: '58345',
  },
  'lawton-ok': {
    name: 'Lawton',
    postalCode: '73501',
  },
  'lawton-pa': {
    name: 'Lawton',
    postalCode: '18828',
  },
  'lawtons-ny': {
    name: 'Lawtons',
    postalCode: '14091',
  },
  'layland-wv': {
    name: 'Layland',
    postalCode: '25864',
  },
  'layton-nj': {
    name: 'Layton',
    postalCode: '07851',
  },
  'layton-ut': {
    name: 'Layton',
    postalCode: '84040',
  },
  'laytonville-ca': {
    name: 'Laytonville',
    postalCode: '95454',
  },
  'le-center-mn': {
    name: 'Le Center',
    postalCode: '56057',
  },
  'le-claire-ia': {
    name: 'Le Claire',
    postalCode: '52753',
  },
  'le-grand-ca': {
    name: 'Le Grand',
    postalCode: '95333',
  },
  'le-mars-ia': {
    name: 'Le Mars',
    postalCode: '51031',
  },
  'le-raysville-pa': {
    name: 'Le Raysville',
    postalCode: '18829',
  },
  'le-roy-il': {
    name: 'Le Roy',
    postalCode: '61752',
  },
  'le-roy-ks': {
    name: 'Le Roy',
    postalCode: '66857',
  },
  'le-roy-mn': {
    name: 'Le Roy',
    postalCode: '55951',
  },
  'le-roy-ny': {
    name: 'Le Roy',
    postalCode: '14482',
  },
  'le-roy-wv': {
    name: 'Le Roy',
    postalCode: '25252',
  },
  'le-sueur-mn': {
    name: 'Le Sueur',
    postalCode: '56058',
  },
  'leachville-ar': {
    name: 'Leachville',
    postalCode: '72438',
  },
  'lead-sd': {
    name: 'Lead',
    postalCode: '57754',
  },
  'lead-hill-ar': {
    name: 'Lead Hill',
    postalCode: '72644',
  },
  'lead-hill-mo': {
    name: 'Lead Hill',
    postalCode: '72643',
  },
  'leadore-id': {
    name: 'Leadore',
    postalCode: '83464',
  },
  'leadville-co': {
    name: 'Leadville',
    postalCode: '80461',
  },
  'leadwood-mo': {
    name: 'Leadwood',
    postalCode: '63653',
  },
  'leaf-river-il': {
    name: 'Leaf River',
    postalCode: '61047',
  },
  'league-city-tx': {
    name: 'League City',
    postalCode: '77573',
  },
  'leakesville-ms': {
    name: 'Leakesville',
    postalCode: '39451',
  },
  'leakey-tx': {
    name: 'Leakey',
    postalCode: '78873',
  },
  'leander-tx': {
    name: 'Leander',
    postalCode: '78641',
  },
  'leary-ga': {
    name: 'Leary',
    postalCode: '39862',
  },
  'leasburg-mo': {
    name: 'Leasburg',
    postalCode: '65535',
  },
  'leasburg-nc': {
    name: 'Leasburg',
    postalCode: '27291',
  },
  'leavenworth-in': {
    name: 'Leavenworth',
    postalCode: '47137',
  },
  'leavenworth-ks': {
    name: 'Leavenworth',
    postalCode: '66048',
  },
  'leavenworth-wa': {
    name: 'Leavenworth',
    postalCode: '98826',
  },
  'leavittsburg-oh': {
    name: 'Leavittsburg',
    postalCode: '44430',
  },
  'leawood-ks': {
    name: 'Leawood',
    postalCode: '66206',
  },
  'lebanon-ct': {
    name: 'Lebanon',
    postalCode: '06249',
  },
  'lebanon-il': {
    name: 'Lebanon',
    postalCode: '62254',
  },
  'lebanon-in': {
    name: 'Lebanon',
    postalCode: '46052',
  },
  'lebanon-ks': {
    name: 'Lebanon',
    postalCode: '66952',
  },
  'lebanon-ky': {
    name: 'Lebanon',
    postalCode: '40033',
  },
  'lebanon-me': {
    name: 'Lebanon',
    postalCode: '04027',
  },
  'lebanon-mo': {
    name: 'Lebanon',
    postalCode: '65536',
  },
  'lebanon-ne': {
    name: 'Lebanon',
    postalCode: '69036',
  },
  'lebanon-nh': {
    name: 'Lebanon',
    postalCode: '03756',
  },
  'lebanon-nj': {
    name: 'Lebanon',
    postalCode: '08833',
  },
  'lebanon-oh': {
    name: 'Lebanon',
    postalCode: '45036',
  },
  'lebanon-ok': {
    name: 'Lebanon',
    postalCode: '73440',
  },
  'lebanon-or': {
    name: 'Lebanon',
    postalCode: '97355',
  },
  'lebanon-pa': {
    name: 'Lebanon',
    postalCode: '17042',
  },
  'lebanon-sd': {
    name: 'Lebanon',
    postalCode: '57455',
  },
  'lebanon-tn': {
    name: 'Lebanon',
    postalCode: '37087',
  },
  'lebanon-va': {
    name: 'Lebanon',
    postalCode: '24266',
  },
  'lebanon-junction-ky': {
    name: 'Lebanon Junction',
    postalCode: '40150',
  },
  'lebec-ca': {
    name: 'Lebec',
    postalCode: '93243',
  },
  'lebo-ks': {
    name: 'Lebo',
    postalCode: '66856',
  },
  'leburn-ky': {
    name: 'Leburn',
    postalCode: '41831',
  },
  'lecanto-fl': {
    name: 'Lecanto',
    postalCode: '34461',
  },
  'leck-kill-pa': {
    name: 'Leck Kill',
    postalCode: '17836',
  },
  'lecompte-la': {
    name: 'Lecompte',
    postalCode: '71346',
  },
  'lecompton-ks': {
    name: 'Lecompton',
    postalCode: '66050',
  },
  'lecontes-mills-pa': {
    name: 'Lecontes Mills',
    postalCode: '16850',
  },
  'ledbetter-ky': {
    name: 'Ledbetter',
    postalCode: '42058',
  },
  'ledbetter-tx': {
    name: 'Ledbetter',
    postalCode: '78946',
  },
  'ledger-mt': {
    name: 'Ledger',
    postalCode: '59456',
  },
  'ledgewood-nj': {
    name: 'Ledgewood',
    postalCode: '07852',
  },
  'ledyard-ct': {
    name: 'Ledyard',
    postalCode: '06339',
  },
  'ledyard-ia': {
    name: 'Ledyard',
    postalCode: '50556',
  },
  'lee-fl': {
    name: 'Lee',
    postalCode: '32059',
  },
  'lee-il': {
    name: 'Lee',
    postalCode: '60530',
  },
  'lee-ma': {
    name: 'Lee',
    postalCode: '01238',
  },
  'lee-me': {
    name: 'Lee',
    postalCode: '04455',
  },
  'lee-nh': {
    name: 'Lee',
    postalCode: '03861',
  },
  'lee-center-ny': {
    name: 'Lee Center',
    postalCode: '13363',
  },
  'leechburg-pa': {
    name: 'Leechburg',
    postalCode: '15656',
  },
  'leedey-ok': {
    name: 'Leedey',
    postalCode: '73654',
  },
  'leeds-al': {
    name: 'Leeds',
    postalCode: '35094',
  },
  'leeds-ma': {
    name: 'Leeds',
    postalCode: '01053',
  },
  'leeds-me': {
    name: 'Leeds',
    postalCode: '04263',
  },
  'leeds-nd': {
    name: 'Leeds',
    postalCode: '58346',
  },
  'leeds-ny': {
    name: 'Leeds',
    postalCode: '12451',
  },
  'leeper-pa': {
    name: 'Leeper',
    postalCode: '16233',
  },
  'lees-summit-mo': {
    name: 'Lees Summit',
    postalCode: '64063',
  },
  'leesburg-al': {
    name: 'Leesburg',
    postalCode: '35983',
  },
  'leesburg-fl': {
    name: 'Leesburg',
    postalCode: '34748',
  },
  'leesburg-ga': {
    name: 'Leesburg',
    postalCode: '31763',
  },
  'leesburg-in': {
    name: 'Leesburg',
    postalCode: '46538',
  },
  'leesburg-nj': {
    name: 'Leesburg',
    postalCode: '08327',
  },
  'leesburg-oh': {
    name: 'Leesburg',
    postalCode: '45135',
  },
  'leesburg-tx': {
    name: 'Leesburg',
    postalCode: '75451',
  },
  'leesburg-va': {
    name: 'Leesburg',
    postalCode: '20175',
  },
  'leesport-pa': {
    name: 'Leesport',
    postalCode: '19533',
  },
  'leesville-la': {
    name: 'Leesville',
    postalCode: '71446',
  },
  'leesville-sc': {
    name: 'Leesville',
    postalCode: '29070',
  },
  'leesville-tx': {
    name: 'Leesville',
    postalCode: '78122',
  },
  'leeton-mo': {
    name: 'Leeton',
    postalCode: '64761',
  },
  'leetonia-oh': {
    name: 'Leetonia',
    postalCode: '44431',
  },
  'leetsdale-pa': {
    name: 'Leetsdale',
    postalCode: '15056',
  },
  'lefor-nd': {
    name: 'Lefor',
    postalCode: '58641',
  },
  'left-hand-wv': {
    name: 'Left Hand',
    postalCode: '25251',
  },
  'leggett-ca': {
    name: 'Leggett',
    postalCode: '95585',
  },
  'lehi-ut': {
    name: 'Lehi',
    postalCode: '84043',
  },
  'lehigh-ia': {
    name: 'Lehigh',
    postalCode: '50557',
  },
  'lehigh-ks': {
    name: 'Lehigh',
    postalCode: '67073',
  },
  'lehigh-acres-fl': {
    name: 'Lehigh Acres',
    postalCode: '33936',
  },
  'lehighton-pa': {
    name: 'Lehighton',
    postalCode: '18235',
  },
  'lehr-nd': {
    name: 'Lehr',
    postalCode: '58460',
  },
  'leicester-ma': {
    name: 'Leicester',
    postalCode: '01524',
  },
  'leicester-nc': {
    name: 'Leicester',
    postalCode: '28748',
  },
  'leicester-ny': {
    name: 'Leicester',
    postalCode: '14481',
  },
  'leigh-ne': {
    name: 'Leigh',
    postalCode: '68643',
  },
  'leighton-al': {
    name: 'Leighton',
    postalCode: '35646',
  },
  'leighton-ia': {
    name: 'Leighton',
    postalCode: '50143',
  },
  'leipsic-oh': {
    name: 'Leipsic',
    postalCode: '45856',
  },
  'leitchfield-ky': {
    name: 'Leitchfield',
    postalCode: '42754',
  },
  'leivasy-wv': {
    name: 'Leivasy',
    postalCode: '26676',
  },
  'leland-ia': {
    name: 'Leland',
    postalCode: '50453',
  },
  'leland-il': {
    name: 'Leland',
    postalCode: '60531',
  },
  'leland-mi': {
    name: 'Leland',
    postalCode: '49654',
  },
  'leland-ms': {
    name: 'Leland',
    postalCode: '38756',
  },
  'leland-nc': {
    name: 'Leland',
    postalCode: '28451',
  },
  'lelia-lake-tx': {
    name: 'Lelia Lake',
    postalCode: '79240',
  },
  'lemitar-nm': {
    name: 'Lemitar',
    postalCode: '87823',
  },
  'lemmon-sd': {
    name: 'Lemmon',
    postalCode: '57638',
  },
  'lemon-cove-ca': {
    name: 'Lemon Cove',
    postalCode: '93244',
  },
  'lemon-grove-ca': {
    name: 'Lemon Grove',
    postalCode: '91945',
  },
  'lemont-il': {
    name: 'Lemont',
    postalCode: '60439',
  },
  'lemont-furnace-pa': {
    name: 'Lemont Furnace',
    postalCode: '15456',
  },
  'lemoore-ca': {
    name: 'Lemoore',
    postalCode: '93245',
  },
  'lemoyne-ne': {
    name: 'Lemoyne',
    postalCode: '69146',
  },
  'lemoyne-pa': {
    name: 'Lemoyne',
    postalCode: '17043',
  },
  'lempster-nh': {
    name: 'Lempster',
    postalCode: '03605',
  },
  'lena-il': {
    name: 'Lena',
    postalCode: '61048',
  },
  'lena-la': {
    name: 'Lena',
    postalCode: '71447',
  },
  'lena-ms': {
    name: 'Lena',
    postalCode: '39094',
  },
  'lena-wi': {
    name: 'Lena',
    postalCode: '54139',
  },
  'lenapah-ok': {
    name: 'Lenapah',
    postalCode: '74042',
  },
  'lenexa-ks': {
    name: 'Lenexa',
    postalCode: '66215',
  },
  'lengby-mn': {
    name: 'Lengby',
    postalCode: '56651',
  },
  'lenhartsville-pa': {
    name: 'Lenhartsville',
    postalCode: '19534',
  },
  'lennon-mi': {
    name: 'Lennon',
    postalCode: '48449',
  },
  'lennox-sd': {
    name: 'Lennox',
    postalCode: '57039',
  },
  'lenoir-nc': {
    name: 'Lenoir',
    postalCode: '28645',
  },
  'lenoir-city-tn': {
    name: 'Lenoir City',
    postalCode: '37771',
  },
  'lenora-ks': {
    name: 'Lenora',
    postalCode: '67645',
  },
  'lenorah-tx': {
    name: 'Lenorah',
    postalCode: '79749',
  },
  'lenore-id': {
    name: 'Lenore',
    postalCode: '83541',
  },
  'lenore-wv': {
    name: 'Lenore',
    postalCode: '25676',
  },
  'lenox-al': {
    name: 'Lenox',
    postalCode: '36454',
  },
  'lenox-ga': {
    name: 'Lenox',
    postalCode: '31637',
  },
  'lenox-ia': {
    name: 'Lenox',
    postalCode: '50851',
  },
  'lenox-ma': {
    name: 'Lenox',
    postalCode: '01240',
  },
  'lenox-mo': {
    name: 'Lenox',
    postalCode: '65541',
  },
  'lenox-tn': {
    name: 'Lenox',
    postalCode: '38047',
  },
  'lenoxville-pa': {
    name: 'Lenoxville',
    postalCode: '18441',
  },
  'lentner-mo': {
    name: 'Lentner',
    postalCode: '63450',
  },
  'lenzburg-il': {
    name: 'Lenzburg',
    postalCode: '62255',
  },
  'leo-in': {
    name: 'Leo',
    postalCode: '46765',
  },
  'leola-ar': {
    name: 'Leola',
    postalCode: '72084',
  },
  'leola-pa': {
    name: 'Leola',
    postalCode: '17540',
  },
  'leola-sd': {
    name: 'Leola',
    postalCode: '57456',
  },
  'leoma-tn': {
    name: 'Leoma',
    postalCode: '38468',
  },
  'leominster-ma': {
    name: 'Leominster',
    postalCode: '01453',
  },
  'leon-ia': {
    name: 'Leon',
    postalCode: '50144',
  },
  'leon-ks': {
    name: 'Leon',
    postalCode: '67074',
  },
  'leon-ok': {
    name: 'Leon',
    postalCode: '73441',
  },
  'leon-va': {
    name: 'Leon',
    postalCode: '22725',
  },
  'leon-wv': {
    name: 'Leon',
    postalCode: '25123',
  },
  'leona-tx': {
    name: 'Leona',
    postalCode: '75850',
  },
  'leonard-mi': {
    name: 'Leonard',
    postalCode: '48367',
  },
  'leonard-mn': {
    name: 'Leonard',
    postalCode: '56652',
  },
  'leonard-mo': {
    name: 'Leonard',
    postalCode: '63451',
  },
  'leonard-nd': {
    name: 'Leonard',
    postalCode: '58052',
  },
  'leonard-tx': {
    name: 'Leonard',
    postalCode: '75452',
  },
  'leonardo-nj': {
    name: 'Leonardo',
    postalCode: '07737',
  },
  'leonardtown-md': {
    name: 'Leonardtown',
    postalCode: '20650',
  },
  'leonardville-ks': {
    name: 'Leonardville',
    postalCode: '66449',
  },
  'leonia-nj': {
    name: 'Leonia',
    postalCode: '07605',
  },
  'leonidas-mi': {
    name: 'Leonidas',
    postalCode: '49066',
  },
  'leopold-in': {
    name: 'Leopold',
    postalCode: '47551',
  },
  'leopold-mo': {
    name: 'Leopold',
    postalCode: '63760',
  },
  'leopolis-wi': {
    name: 'Leopolis',
    postalCode: '54948',
  },
  'leoti-ks': {
    name: 'Leoti',
    postalCode: '67861',
  },
  'lepanto-ar': {
    name: 'Lepanto',
    postalCode: '72354',
  },
  'lerna-il': {
    name: 'Lerna',
    postalCode: '62440',
  },
  'lerona-wv': {
    name: 'Lerona',
    postalCode: '25971',
  },
  'leroy-al': {
    name: 'Leroy',
    postalCode: '36548',
  },
  'leroy-mi': {
    name: 'Leroy',
    postalCode: '49655',
  },
  'lesage-wv': {
    name: 'Lesage',
    postalCode: '25537',
  },
  'leslie-ar': {
    name: 'Leslie',
    postalCode: '72645',
  },
  'leslie-ga': {
    name: 'Leslie',
    postalCode: '31764',
  },
  'leslie-mi': {
    name: 'Leslie',
    postalCode: '49251',
  },
  'leslie-mo': {
    name: 'Leslie',
    postalCode: '63056',
  },
  'lester-al': {
    name: 'Lester',
    postalCode: '35647',
  },
  'lester-wv': {
    name: 'Lester',
    postalCode: '25865',
  },
  'lester-prairie-mn': {
    name: 'Lester Prairie',
    postalCode: '55354',
  },
  'lesterville-mo': {
    name: 'Lesterville',
    postalCode: '63654',
  },
  'lesterville-sd': {
    name: 'Lesterville',
    postalCode: '57040',
  },
  'letart-wv': {
    name: 'Letart',
    postalCode: '25253',
  },
  'letcher-ky': {
    name: 'Letcher',
    postalCode: '41832',
  },
  'letcher-sd': {
    name: 'Letcher',
    postalCode: '57359',
  },
  'letha-id': {
    name: 'Letha',
    postalCode: '83636',
  },
  'letohatchee-al': {
    name: 'Letohatchee',
    postalCode: '36047',
  },
  'letts-ia': {
    name: 'Letts',
    postalCode: '52754',
  },
  'lettsworth-la': {
    name: 'Lettsworth',
    postalCode: '70753',
  },
  'levant-ks': {
    name: 'Levant',
    postalCode: '67743',
  },
  'levant-me': {
    name: 'Levant',
    postalCode: '04456',
  },
  'levelland-tx': {
    name: 'Levelland',
    postalCode: '79336',
  },
  'levels-wv': {
    name: 'Levels',
    postalCode: '25431',
  },
  'leverett-ma': {
    name: 'Leverett',
    postalCode: '01054',
  },
  'levering-mi': {
    name: 'Levering',
    postalCode: '49755',
  },
  'levittown-ny': {
    name: 'Levittown',
    postalCode: '11756',
  },
  'levittown-pa': {
    name: 'Levittown',
    postalCode: '19054',
  },
  'lewellen-ne': {
    name: 'Lewellen',
    postalCode: '69147',
  },
  'lewes-de': {
    name: 'Lewes',
    postalCode: '19958',
  },
  'lewis-co': {
    name: 'Lewis',
    postalCode: '81327',
  },
  'lewis-ia': {
    name: 'Lewis',
    postalCode: '51544',
  },
  'lewis-in': {
    name: 'Lewis',
    postalCode: '47858',
  },
  'lewis-ks': {
    name: 'Lewis',
    postalCode: '67552',
  },
  'lewis-ny': {
    name: 'Lewis',
    postalCode: '12950',
  },
  'lewis-center-oh': {
    name: 'Lewis Center',
    postalCode: '43035',
  },
  'lewis-run-pa': {
    name: 'Lewis Run',
    postalCode: '16738',
  },
  'lewisberry-pa': {
    name: 'Lewisberry',
    postalCode: '17339',
  },
  'lewisburg-ky': {
    name: 'Lewisburg',
    postalCode: '42256',
  },
  'lewisburg-oh': {
    name: 'Lewisburg',
    postalCode: '45338',
  },
  'lewisburg-pa': {
    name: 'Lewisburg',
    postalCode: '17837',
  },
  'lewisburg-tn': {
    name: 'Lewisburg',
    postalCode: '37091',
  },
  'lewisburg-wv': {
    name: 'Lewisburg',
    postalCode: '24901',
  },
  'lewisport-ky': {
    name: 'Lewisport',
    postalCode: '42351',
  },
  'lewiston-ca': {
    name: 'Lewiston',
    postalCode: '96052',
  },
  'lewiston-id': {
    name: 'Lewiston',
    postalCode: '83501',
  },
  'lewiston-me': {
    name: 'Lewiston',
    postalCode: '04240',
  },
  'lewiston-mi': {
    name: 'Lewiston',
    postalCode: '49756',
  },
  'lewiston-mn': {
    name: 'Lewiston',
    postalCode: '55952',
  },
  'lewiston-ne': {
    name: 'Lewiston',
    postalCode: '68380',
  },
  'lewiston-ny': {
    name: 'Lewiston',
    postalCode: '14092',
  },
  'lewiston-ut': {
    name: 'Lewiston',
    postalCode: '84320',
  },
  'lewiston-woodville-nc': {
    name: 'Lewiston Woodville',
    postalCode: '27849',
  },
  'lewistown-il': {
    name: 'Lewistown',
    postalCode: '61542',
  },
  'lewistown-mo': {
    name: 'Lewistown',
    postalCode: '63452',
  },
  'lewistown-mt': {
    name: 'Lewistown',
    postalCode: '59457',
  },
  'lewistown-oh': {
    name: 'Lewistown',
    postalCode: '43333',
  },
  'lewistown-pa': {
    name: 'Lewistown',
    postalCode: '17044',
  },
  'lewisville-ar': {
    name: 'Lewisville',
    postalCode: '71845',
  },
  'lewisville-id': {
    name: 'Lewisville',
    postalCode: '83431',
  },
  'lewisville-in': {
    name: 'Lewisville',
    postalCode: '47352',
  },
  'lewisville-mn': {
    name: 'Lewisville',
    postalCode: '56060',
  },
  'lewisville-nc': {
    name: 'Lewisville',
    postalCode: '27023',
  },
  'lewisville-oh': {
    name: 'Lewisville',
    postalCode: '43754',
  },
  'lewisville-tx': {
    name: 'Lewisville',
    postalCode: '75057',
  },
  'lexa-ar': {
    name: 'Lexa',
    postalCode: '72355',
  },
  'lexington-al': {
    name: 'Lexington',
    postalCode: '35648',
  },
  'lexington-ga': {
    name: 'Lexington',
    postalCode: '30648',
  },
  'lexington-il': {
    name: 'Lexington',
    postalCode: '61753',
  },
  'lexington-in': {
    name: 'Lexington',
    postalCode: '47138',
  },
  'lexington-ky': {
    name: 'Lexington',
    postalCode: '40502',
  },
  'lexington-ma': {
    name: 'Lexington',
    postalCode: '02420',
  },
  'lexington-mi': {
    name: 'Lexington',
    postalCode: '48450',
  },
  'lexington-mo': {
    name: 'Lexington',
    postalCode: '64067',
  },
  'lexington-ms': {
    name: 'Lexington',
    postalCode: '39095',
  },
  'lexington-nc': {
    name: 'Lexington',
    postalCode: '27292',
  },
  'lexington-ne': {
    name: 'Lexington',
    postalCode: '68850',
  },
  'lexington-ok': {
    name: 'Lexington',
    postalCode: '73051',
  },
  'lexington-or': {
    name: 'Lexington',
    postalCode: '97839',
  },
  'lexington-sc': {
    name: 'Lexington',
    postalCode: '29072',
  },
  'lexington-tn': {
    name: 'Lexington',
    postalCode: '38351',
  },
  'lexington-tx': {
    name: 'Lexington',
    postalCode: '78947',
  },
  'lexington-va': {
    name: 'Lexington',
    postalCode: '24450',
  },
  'lexington-park-md': {
    name: 'Lexington Park',
    postalCode: '20653',
  },
  'libby-mt': {
    name: 'Libby',
    postalCode: '59923',
  },
  'liberal-ks': {
    name: 'Liberal',
    postalCode: '67901',
  },
  'liberal-mo': {
    name: 'Liberal',
    postalCode: '64762',
  },
  'liberty-il': {
    name: 'Liberty',
    postalCode: '62347',
  },
  'liberty-in': {
    name: 'Liberty',
    postalCode: '47353',
  },
  'liberty-ks': {
    name: 'Liberty',
    postalCode: '67351',
  },
  'liberty-ky': {
    name: 'Liberty',
    postalCode: '42539',
  },
  'liberty-me': {
    name: 'Liberty',
    postalCode: '04949',
  },
  'liberty-mo': {
    name: 'Liberty',
    postalCode: '64068',
  },
  'liberty-ms': {
    name: 'Liberty',
    postalCode: '39645',
  },
  'liberty-nc': {
    name: 'Liberty',
    postalCode: '27298',
  },
  'liberty-ne': {
    name: 'Liberty',
    postalCode: '68381',
  },
  'liberty-ny': {
    name: 'Liberty',
    postalCode: '12754',
  },
  'liberty-pa': {
    name: 'Liberty',
    postalCode: '16930',
  },
  'liberty-sc': {
    name: 'Liberty',
    postalCode: '29657',
  },
  'liberty-tn': {
    name: 'Liberty',
    postalCode: '37095',
  },
  'liberty-tx': {
    name: 'Liberty',
    postalCode: '77575',
  },
  'liberty-wv': {
    name: 'Liberty',
    postalCode: '25124',
  },
  'liberty-center-in': {
    name: 'Liberty Center',
    postalCode: '46766',
  },
  'liberty-center-oh': {
    name: 'Liberty Center',
    postalCode: '43532',
  },
  'liberty-hill-tx': {
    name: 'Liberty Hill',
    postalCode: '78642',
  },
  'liberty-lake-wa': {
    name: 'Liberty Lake',
    postalCode: '99019',
  },
  'libertyville-ia': {
    name: 'Libertyville',
    postalCode: '52567',
  },
  'libertyville-il': {
    name: 'Libertyville',
    postalCode: '60048',
  },
  'lick-creek-ky': {
    name: 'Lick Creek',
    postalCode: '41540',
  },
  'licking-mo': {
    name: 'Licking',
    postalCode: '65542',
  },
  'lickingville-pa': {
    name: 'Lickingville',
    postalCode: '16332',
  },
  'lidgerwood-nd': {
    name: 'Lidgerwood',
    postalCode: '58053',
  },
  'light-ar': {
    name: 'Light',
    postalCode: '72439',
  },
  'lignite-nd': {
    name: 'Lignite',
    postalCode: '58752',
  },
  'lignum-va': {
    name: 'Lignum',
    postalCode: '22726',
  },
  'ligonier-in': {
    name: 'Ligonier',
    postalCode: '46767',
  },
  'ligonier-pa': {
    name: 'Ligonier',
    postalCode: '15658',
  },
  'lihue-hi': {
    name: 'Lihue',
    postalCode: '96766',
  },
  'lilbourn-mo': {
    name: 'Lilbourn',
    postalCode: '63862',
  },
  'lilburn-ga': {
    name: 'Lilburn',
    postalCode: '30047',
  },
  'lilesville-nc': {
    name: 'Lilesville',
    postalCode: '28091',
  },
  'lillian-al': {
    name: 'Lillian',
    postalCode: '36549',
  },
  'lillie-la': {
    name: 'Lillie',
    postalCode: '71256',
  },
  'lillington-nc': {
    name: 'Lillington',
    postalCode: '27546',
  },
  'lilliwaup-wa': {
    name: 'Lilliwaup',
    postalCode: '98555',
  },
  'lilly-pa': {
    name: 'Lilly',
    postalCode: '15938',
  },
  'lily-ky': {
    name: 'Lily',
    postalCode: '40740',
  },
  'lima-mt': {
    name: 'Lima',
    postalCode: '59739',
  },
  'lima-ny': {
    name: 'Lima',
    postalCode: '14485',
  },
  'lima-oh': {
    name: 'Lima',
    postalCode: '45801',
  },
  'lime-springs-ia': {
    name: 'Lime Springs',
    postalCode: '52155',
  },
  'limerick-me': {
    name: 'Limerick',
    postalCode: '04048',
  },
  'limestone-me': {
    name: 'Limestone',
    postalCode: '04750',
  },
  'limestone-ny': {
    name: 'Limestone',
    postalCode: '14753',
  },
  'limestone-tn': {
    name: 'Limestone',
    postalCode: '37681',
  },
  'limington-me': {
    name: 'Limington',
    postalCode: '04049',
  },
  'limon-co': {
    name: 'Limon',
    postalCode: '80828',
  },
  'lincoln-al': {
    name: 'Lincoln',
    postalCode: '35096',
  },
  'lincoln-ar': {
    name: 'Lincoln',
    postalCode: '72744',
  },
  'lincoln-ca': {
    name: 'Lincoln',
    postalCode: '95648',
  },
  'lincoln-de': {
    name: 'Lincoln',
    postalCode: '19960',
  },
  'lincoln-il': {
    name: 'Lincoln',
    postalCode: '62656',
  },
  'lincoln-ks': {
    name: 'Lincoln',
    postalCode: '67455',
  },
  'lincoln-ma': {
    name: 'Lincoln',
    postalCode: '01773',
  },
  'lincoln-me': {
    name: 'Lincoln',
    postalCode: '04457',
  },
  'lincoln-mi': {
    name: 'Lincoln',
    postalCode: '48742',
  },
  'lincoln-mo': {
    name: 'Lincoln',
    postalCode: '65338',
  },
  'lincoln-mt': {
    name: 'Lincoln',
    postalCode: '59639',
  },
  'lincoln-ne': {
    name: 'Lincoln',
    postalCode: '68502',
  },
  'lincoln-nh': {
    name: 'Lincoln',
    postalCode: '03251',
  },
  'lincoln-ri': {
    name: 'Lincoln',
    postalCode: '02865',
  },
  'lincoln-tx': {
    name: 'Lincoln',
    postalCode: '78948',
  },
  'lincoln-wa': {
    name: 'Lincoln',
    postalCode: '99147',
  },
  'lincoln-city-in': {
    name: 'Lincoln City',
    postalCode: '47552',
  },
  'lincoln-city-or': {
    name: 'Lincoln City',
    postalCode: '97367',
  },
  'lincoln-park-mi': {
    name: 'Lincoln Park',
    postalCode: '48146',
  },
  'lincoln-park-nj': {
    name: 'Lincoln Park',
    postalCode: '07035',
  },
  'lincoln-university-pa': {
    name: 'Lincoln University',
    postalCode: '19352',
  },
  'lincolns-new-salem-il': {
    name: 'Lincolns New Salem',
    postalCode: '62659',
  },
  'lincolnshire-il': {
    name: 'Lincolnshire',
    postalCode: '60069',
  },
  'lincolnton-ga': {
    name: 'Lincolnton',
    postalCode: '30817',
  },
  'lincolnton-nc': {
    name: 'Lincolnton',
    postalCode: '28092',
  },
  'lincolnville-ks': {
    name: 'Lincolnville',
    postalCode: '66858',
  },
  'lincolnville-me': {
    name: 'Lincolnville',
    postalCode: '04849',
  },
  'lincolnwood-il': {
    name: 'Lincolnwood',
    postalCode: '60712',
  },
  'lincroft-nj': {
    name: 'Lincroft',
    postalCode: '07738',
  },
  'lind-wa': {
    name: 'Lind',
    postalCode: '99341',
  },
  'lindale-ga': {
    name: 'Lindale',
    postalCode: '30147',
  },
  'lindale-tx': {
    name: 'Lindale',
    postalCode: '75771',
  },
  'linden-al': {
    name: 'Linden',
    postalCode: '36748',
  },
  'linden-ca': {
    name: 'Linden',
    postalCode: '95236',
  },
  'linden-ia': {
    name: 'Linden',
    postalCode: '50146',
  },
  'linden-in': {
    name: 'Linden',
    postalCode: '47955',
  },
  'linden-mi': {
    name: 'Linden',
    postalCode: '48451',
  },
  'linden-nc': {
    name: 'Linden',
    postalCode: '28356',
  },
  'linden-nj': {
    name: 'Linden',
    postalCode: '07036',
  },
  'linden-pa': {
    name: 'Linden',
    postalCode: '17744',
  },
  'linden-tn': {
    name: 'Linden',
    postalCode: '37096',
  },
  'linden-tx': {
    name: 'Linden',
    postalCode: '75563',
  },
  'linden-va': {
    name: 'Linden',
    postalCode: '22642',
  },
  'linden-wi': {
    name: 'Linden',
    postalCode: '53553',
  },
  'lindenhurst-ny': {
    name: 'Lindenhurst',
    postalCode: '11757',
  },
  'lindenwood-il': {
    name: 'Lindenwood',
    postalCode: '61049',
  },
  'lindley-ny': {
    name: 'Lindley',
    postalCode: '14858',
  },
  'lindon-co': {
    name: 'Lindon',
    postalCode: '80740',
  },
  'lindon-ut': {
    name: 'Lindon',
    postalCode: '84042',
  },
  'lindrith-nm': {
    name: 'Lindrith',
    postalCode: '87029',
  },
  'lindsay-ca': {
    name: 'Lindsay',
    postalCode: '93247',
  },
  'lindsay-mt': {
    name: 'Lindsay',
    postalCode: '59339',
  },
  'lindsay-ne': {
    name: 'Lindsay',
    postalCode: '68644',
  },
  'lindsay-ok': {
    name: 'Lindsay',
    postalCode: '73052',
  },
  'lindsay-tx': {
    name: 'Lindsay',
    postalCode: '76250',
  },
  'lindsborg-ks': {
    name: 'Lindsborg',
    postalCode: '67456',
  },
  'lindsey-oh': {
    name: 'Lindsey',
    postalCode: '43442',
  },
  'lindside-wv': {
    name: 'Lindside',
    postalCode: '24951',
  },
  'lindstrom-mn': {
    name: 'Lindstrom',
    postalCode: '55045',
  },
  'line-lexington-pa': {
    name: 'Line Lexington',
    postalCode: '18932',
  },
  'linefork-ky': {
    name: 'Linefork',
    postalCode: '41833',
  },
  'linesville-pa': {
    name: 'Linesville',
    postalCode: '16424',
  },
  'lineville-al': {
    name: 'Lineville',
    postalCode: '36266',
  },
  'lineville-ia': {
    name: 'Lineville',
    postalCode: '50147',
  },
  'lingle-wy': {
    name: 'Lingle',
    postalCode: '82223',
  },
  'lingo-nm': {
    name: 'Lingo',
    postalCode: '88123',
  },
  'linkwood-md': {
    name: 'Linkwood',
    postalCode: '21835',
  },
  'linn-ks': {
    name: 'Linn',
    postalCode: '66953',
  },
  'linn-mo': {
    name: 'Linn',
    postalCode: '65051',
  },
  'linn-tx': {
    name: 'Linn',
    postalCode: '78563',
  },
  'linn-wv': {
    name: 'Linn',
    postalCode: '26384',
  },
  'linn-creek-mo': {
    name: 'Linn Creek',
    postalCode: '65052',
  },
  'linn-grove-ia': {
    name: 'Linn Grove',
    postalCode: '51033',
  },
  'linneus-mo': {
    name: 'Linneus',
    postalCode: '64653',
  },
  'linthicum-heights-md': {
    name: 'Linthicum Heights',
    postalCode: '21090',
  },
  'linton-in': {
    name: 'Linton',
    postalCode: '47441',
  },
  'linton-nd': {
    name: 'Linton',
    postalCode: '58552',
  },
  'linville-va': {
    name: 'Linville',
    postalCode: '22834',
  },
  'linwood-ks': {
    name: 'Linwood',
    postalCode: '66052',
  },
  'linwood-mi': {
    name: 'Linwood',
    postalCode: '48634',
  },
  'linwood-nc': {
    name: 'Linwood',
    postalCode: '27299',
  },
  'linwood-ne': {
    name: 'Linwood',
    postalCode: '68036',
  },
  'linwood-nj': {
    name: 'Linwood',
    postalCode: '08221',
  },
  'linwood-ny': {
    name: 'Linwood',
    postalCode: '14486',
  },
  'lipan-tx': {
    name: 'Lipan',
    postalCode: '76462',
  },
  'lipscomb-tx': {
    name: 'Lipscomb',
    postalCode: '79056',
  },
  'lisbon-ia': {
    name: 'Lisbon',
    postalCode: '52253',
  },
  'lisbon-la': {
    name: 'Lisbon',
    postalCode: '71048',
  },
  'lisbon-me': {
    name: 'Lisbon',
    postalCode: '04250',
  },
  'lisbon-nd': {
    name: 'Lisbon',
    postalCode: '58054',
  },
  'lisbon-nh': {
    name: 'Lisbon',
    postalCode: '03585',
  },
  'lisbon-ny': {
    name: 'Lisbon',
    postalCode: '13658',
  },
  'lisbon-oh': {
    name: 'Lisbon',
    postalCode: '44432',
  },
  'lisbon-falls-me': {
    name: 'Lisbon Falls',
    postalCode: '04252',
  },
  'lisco-ne': {
    name: 'Lisco',
    postalCode: '69148',
  },
  'liscomb-ia': {
    name: 'Liscomb',
    postalCode: '50148',
  },
  'lisle-il': {
    name: 'Lisle',
    postalCode: '60532',
  },
  'lisle-ny': {
    name: 'Lisle',
    postalCode: '13797',
  },
  'lisman-al': {
    name: 'Lisman',
    postalCode: '36912',
  },
  'lismore-mn': {
    name: 'Lismore',
    postalCode: '56155',
  },
  'litchfield-ca': {
    name: 'Litchfield',
    postalCode: '96117',
  },
  'litchfield-ct': {
    name: 'Litchfield',
    postalCode: '06759',
  },
  'litchfield-il': {
    name: 'Litchfield',
    postalCode: '62056',
  },
  'litchfield-me': {
    name: 'Litchfield',
    postalCode: '04350',
  },
  'litchfield-mi': {
    name: 'Litchfield',
    postalCode: '49252',
  },
  'litchfield-mn': {
    name: 'Litchfield',
    postalCode: '55355',
  },
  'litchfield-ne': {
    name: 'Litchfield',
    postalCode: '68852',
  },
  'litchfield-nh': {
    name: 'Litchfield',
    postalCode: '03052',
  },
  'litchfield-oh': {
    name: 'Litchfield',
    postalCode: '44253',
  },
  'litchfield-park-az': {
    name: 'Litchfield Park',
    postalCode: '85340',
  },
  'litchville-nd': {
    name: 'Litchville',
    postalCode: '58461',
  },
  'lithia-fl': {
    name: 'Lithia',
    postalCode: '33547',
  },
  'lithia-springs-ga': {
    name: 'Lithia Springs',
    postalCode: '30122',
  },
  'lithonia-ga': {
    name: 'Lithonia',
    postalCode: '30038',
  },
  'lithopolis-oh': {
    name: 'Lithopolis',
    postalCode: '43136',
  },
  'lititz-pa': {
    name: 'Lititz',
    postalCode: '17543',
  },
  'littcarr-ky': {
    name: 'Littcarr',
    postalCode: '41834',
  },
  'little-birch-wv': {
    name: 'Little Birch',
    postalCode: '26629',
  },
  'little-cedar-ia': {
    name: 'Little Cedar',
    postalCode: '50454',
  },
  'little-chute-wi': {
    name: 'Little Chute',
    postalCode: '54140',
  },
  'little-compton-ri': {
    name: 'Little Compton',
    postalCode: '02837',
  },
  'little-deer-isle-me': {
    name: 'Little Deer Isle',
    postalCode: '04650',
  },
  'little-elm-tx': {
    name: 'Little Elm',
    postalCode: '75068',
  },
  'little-falls-mn': {
    name: 'Little Falls',
    postalCode: '56345',
  },
  'little-falls-nj': {
    name: 'Little Falls',
    postalCode: '07424',
  },
  'little-falls-ny': {
    name: 'Little Falls',
    postalCode: '13365',
  },
  'little-ferry-nj': {
    name: 'Little Ferry',
    postalCode: '07643',
  },
  'little-genesee-ny': {
    name: 'Little Genesee',
    postalCode: '14754',
  },
  'little-hocking-oh': {
    name: 'Little Hocking',
    postalCode: '45742',
  },
  'little-meadows-pa': {
    name: 'Little Meadows',
    postalCode: '18830',
  },
  'little-mountain-sc': {
    name: 'Little Mountain',
    postalCode: '29075',
  },
  'little-neck-ny': {
    name: 'Little Neck',
    postalCode: '11362',
  },
  'little-orleans-md': {
    name: 'Little Orleans',
    postalCode: '21766',
  },
  'little-plymouth-va': {
    name: 'Little Plymouth',
    postalCode: '23091',
  },
  'little-river-al': {
    name: 'Little River',
    postalCode: '36550',
  },
  'little-river-ca': {
    name: 'Little River',
    postalCode: '95456',
  },
  'little-river-ks': {
    name: 'Little River',
    postalCode: '67457',
  },
  'little-river-sc': {
    name: 'Little River',
    postalCode: '29566',
  },
  'little-river-academy-tx': {
    name: 'Little River Academy',
    postalCode: '76554',
  },
  'little-rock-ar': {
    name: 'Little Rock',
    postalCode: '72201',
  },
  'little-rock-ia': {
    name: 'Little Rock',
    postalCode: '51243',
  },
  'little-rock-ms': {
    name: 'Little Rock',
    postalCode: '39337',
  },
  'little-rock-sc': {
    name: 'Little Rock',
    postalCode: '29567',
  },
  'little-silver-nj': {
    name: 'Little Silver',
    postalCode: '07739',
  },
  'little-sioux-ia': {
    name: 'Little Sioux',
    postalCode: '51545',
  },
  'little-suamico-wi': {
    name: 'Little Suamico',
    postalCode: '54141',
  },
  'little-valley-ny': {
    name: 'Little Valley',
    postalCode: '14755',
  },
  'little-york-il': {
    name: 'Little York',
    postalCode: '61453',
  },
  'littlefield-tx': {
    name: 'Littlefield',
    postalCode: '79339',
  },
  'littlefork-mn': {
    name: 'Littlefork',
    postalCode: '56653',
  },
  'littlerock-ca': {
    name: 'Littlerock',
    postalCode: '93543',
  },
  'littlestown-pa': {
    name: 'Littlestown',
    postalCode: '17340',
  },
  'littleton-co': {
    name: 'Littleton',
    postalCode: '80120',
  },
  'littleton-il': {
    name: 'Littleton',
    postalCode: '61452',
  },
  'littleton-ma': {
    name: 'Littleton',
    postalCode: '01460',
  },
  'littleton-nc': {
    name: 'Littleton',
    postalCode: '27850',
  },
  'littleton-nh': {
    name: 'Littleton',
    postalCode: '03561',
  },
  'littleton-wv': {
    name: 'Littleton',
    postalCode: '26581',
  },
  'live-oak-ca': {
    name: 'Live Oak',
    postalCode: '95953',
  },
  'live-oak-fl': {
    name: 'Live Oak',
    postalCode: '32060',
  },
  'livermore-ca': {
    name: 'Livermore',
    postalCode: '94550',
  },
  'livermore-co': {
    name: 'Livermore',
    postalCode: '80536',
  },
  'livermore-ia': {
    name: 'Livermore',
    postalCode: '50558',
  },
  'livermore-ky': {
    name: 'Livermore',
    postalCode: '42352',
  },
  'livermore-me': {
    name: 'Livermore',
    postalCode: '04253',
  },
  'livermore-falls-me': {
    name: 'Livermore Falls',
    postalCode: '04254',
  },
  'liverpool-ny': {
    name: 'Liverpool',
    postalCode: '13088',
  },
  'liverpool-pa': {
    name: 'Liverpool',
    postalCode: '17045',
  },
  'liverpool-tx': {
    name: 'Liverpool',
    postalCode: '77577',
  },
  'livingston-al': {
    name: 'Livingston',
    postalCode: '35470',
  },
  'livingston-ca': {
    name: 'Livingston',
    postalCode: '95334',
  },
  'livingston-ky': {
    name: 'Livingston',
    postalCode: '40445',
  },
  'livingston-la': {
    name: 'Livingston',
    postalCode: '70754',
  },
  'livingston-mt': {
    name: 'Livingston',
    postalCode: '59047',
  },
  'livingston-nj': {
    name: 'Livingston',
    postalCode: '07039',
  },
  'livingston-tn': {
    name: 'Livingston',
    postalCode: '38570',
  },
  'livingston-tx': {
    name: 'Livingston',
    postalCode: '77351',
  },
  'livingston-wi': {
    name: 'Livingston',
    postalCode: '53554',
  },
  'livingston-manor-ny': {
    name: 'Livingston Manor',
    postalCode: '12758',
  },
  'livonia-la': {
    name: 'Livonia',
    postalCode: '70755',
  },
  'livonia-mi': {
    name: 'Livonia',
    postalCode: '48150',
  },
  'livonia-mo': {
    name: 'Livonia',
    postalCode: '63551',
  },
  'livonia-ny': {
    name: 'Livonia',
    postalCode: '14487',
  },
  'lizella-ga': {
    name: 'Lizella',
    postalCode: '31052',
  },
  'lizemores-wv': {
    name: 'Lizemores',
    postalCode: '25125',
  },
  'lizton-in': {
    name: 'Lizton',
    postalCode: '46149',
  },
  'llano-ca': {
    name: 'Llano',
    postalCode: '93544',
  },
  'llano-tx': {
    name: 'Llano',
    postalCode: '78643',
  },
  'lloyd-mt': {
    name: 'Lloyd',
    postalCode: '59535',
  },
  'loa-ut': {
    name: 'Loa',
    postalCode: '84747',
  },
  'loami-il': {
    name: 'Loami',
    postalCode: '62661',
  },
  'lobelville-tn': {
    name: 'Lobelville',
    postalCode: '37097',
  },
  'loch-sheldrake-ny': {
    name: 'Loch Sheldrake',
    postalCode: '12759',
  },
  'lock-haven-pa': {
    name: 'Lock Haven',
    postalCode: '17745',
  },
  'lockbourne-oh': {
    name: 'Lockbourne',
    postalCode: '43137',
  },
  'locke-ny': {
    name: 'Locke',
    postalCode: '13092',
  },
  'lockeford-ca': {
    name: 'Lockeford',
    postalCode: '95237',
  },
  'lockesburg-ar': {
    name: 'Lockesburg',
    postalCode: '71846',
  },
  'lockhart-tx': {
    name: 'Lockhart',
    postalCode: '78644',
  },
  'lockney-tx': {
    name: 'Lockney',
    postalCode: '79241',
  },
  'lockport-il': {
    name: 'Lockport',
    postalCode: '60441',
  },
  'lockport-ky': {
    name: 'Lockport',
    postalCode: '40036',
  },
  'lockport-la': {
    name: 'Lockport',
    postalCode: '70374',
  },
  'lockport-ny': {
    name: 'Lockport',
    postalCode: '14094',
  },
  'lockridge-ia': {
    name: 'Lockridge',
    postalCode: '52635',
  },
  'lockwood-mo': {
    name: 'Lockwood',
    postalCode: '65682',
  },
  'lockwood-ny': {
    name: 'Lockwood',
    postalCode: '14859',
  },
  'loco-ok': {
    name: 'Loco',
    postalCode: '73442',
  },
  'locust-nc': {
    name: 'Locust',
    postalCode: '28097',
  },
  'locust-dale-va': {
    name: 'Locust Dale',
    postalCode: '22948',
  },
  'locust-fork-al': {
    name: 'Locust Fork',
    postalCode: '35097',
  },
  'locust-gap-pa': {
    name: 'Locust Gap',
    postalCode: '17840',
  },
  'locust-grove-ar': {
    name: 'Locust Grove',
    postalCode: '72550',
  },
  'locust-grove-ga': {
    name: 'Locust Grove',
    postalCode: '30248',
  },
  'locust-grove-ok': {
    name: 'Locust Grove',
    postalCode: '74352',
  },
  'locust-grove-va': {
    name: 'Locust Grove',
    postalCode: '22508',
  },
  'locust-hill-va': {
    name: 'Locust Hill',
    postalCode: '23092',
  },
  'locust-valley-ny': {
    name: 'Locust Valley',
    postalCode: '11560',
  },
  'locustville-va': {
    name: 'Locustville',
    postalCode: '23404',
  },
  'loda-il': {
    name: 'Loda',
    postalCode: '60948',
  },
  'lodge-sc': {
    name: 'Lodge',
    postalCode: '29082',
  },
  'lodge-grass-mt': {
    name: 'Lodge Grass',
    postalCode: '59050',
  },
  'lodgepole-ne': {
    name: 'Lodgepole',
    postalCode: '69149',
  },
  'lodgepole-sd': {
    name: 'Lodgepole',
    postalCode: '57640',
  },
  'lodi-ca': {
    name: 'Lodi',
    postalCode: '95240',
  },
  'lodi-mo': {
    name: 'Lodi',
    postalCode: '63950',
  },
  'lodi-nj': {
    name: 'Lodi',
    postalCode: '07644',
  },
  'lodi-ny': {
    name: 'Lodi',
    postalCode: '14860',
  },
  'lodi-oh': {
    name: 'Lodi',
    postalCode: '44254',
  },
  'lodi-wi': {
    name: 'Lodi',
    postalCode: '53555',
  },
  'log-lane-village-co': {
    name: 'Log Lane Village',
    postalCode: '80705',
  },
  'logan-al': {
    name: 'Logan',
    postalCode: '35098',
  },
  'logan-ia': {
    name: 'Logan',
    postalCode: '51546',
  },
  'logan-ks': {
    name: 'Logan',
    postalCode: '67646',
  },
  'logan-nm': {
    name: 'Logan',
    postalCode: '88426',
  },
  'logan-oh': {
    name: 'Logan',
    postalCode: '43138',
  },
  'logan-ut': {
    name: 'Logan',
    postalCode: '84321',
  },
  'logan-wv': {
    name: 'Logan',
    postalCode: '25601',
  },
  'logansport-in': {
    name: 'Logansport',
    postalCode: '46947',
  },
  'logansport-la': {
    name: 'Logansport',
    postalCode: '71049',
  },
  'loganton-pa': {
    name: 'Loganton',
    postalCode: '17747',
  },
  'loganville-ga': {
    name: 'Loganville',
    postalCode: '30052',
  },
  'loganville-wi': {
    name: 'Loganville',
    postalCode: '53943',
  },
  'logsden-or': {
    name: 'Logsden',
    postalCode: '97357',
  },
  'lohman-mo': {
    name: 'Lohman',
    postalCode: '65053',
  },
  'lohn-tx': {
    name: 'Lohn',
    postalCode: '76852',
  },
  'lohrville-ia': {
    name: 'Lohrville',
    postalCode: '51453',
  },
  'loiza-pr': {
    name: 'Loiza',
    postalCode: '00772',
  },
  'loleta-ca': {
    name: 'Loleta',
    postalCode: '95551',
  },
  'lolita-tx': {
    name: 'Lolita',
    postalCode: '77971',
  },
  'lolo-mt': {
    name: 'Lolo',
    postalCode: '59847',
  },
  'loma-co': {
    name: 'Loma',
    postalCode: '81524',
  },
  'loma-mt': {
    name: 'Loma',
    postalCode: '59460',
  },
  'loma-linda-ca': {
    name: 'Loma Linda',
    postalCode: '92354',
  },
  'loma-mar-ca': {
    name: 'Loma Mar',
    postalCode: '94021',
  },
  'loman-mn': {
    name: 'Loman',
    postalCode: '56654',
  },
  'lomax-il': {
    name: 'Lomax',
    postalCode: '61454',
  },
  'lombard-il': {
    name: 'Lombard',
    postalCode: '60148',
  },
  'lometa-tx': {
    name: 'Lometa',
    postalCode: '76853',
  },
  'lomira-wi': {
    name: 'Lomira',
    postalCode: '53048',
  },
  'lomita-ca': {
    name: 'Lomita',
    postalCode: '90717',
  },
  'lompoc-ca': {
    name: 'Lompoc',
    postalCode: '93436',
  },
  'lonaconing-md': {
    name: 'Lonaconing',
    postalCode: '21539',
  },
  'london-ar': {
    name: 'London',
    postalCode: '72847',
  },
  'london-ky': {
    name: 'London',
    postalCode: '40741',
  },
  'london-oh': {
    name: 'London',
    postalCode: '43140',
  },
  'london-tx': {
    name: 'London',
    postalCode: '76854',
  },
  'london-mills-il': {
    name: 'London Mills',
    postalCode: '61544',
  },
  'londonderry-nh': {
    name: 'Londonderry',
    postalCode: '03053',
  },
  'londonderry-oh': {
    name: 'Londonderry',
    postalCode: '45647',
  },
  'londonderry-vt': {
    name: 'Londonderry',
    postalCode: '05148',
  },
  'lone-grove-ok': {
    name: 'Lone Grove',
    postalCode: '73443',
  },
  'lone-jack-mo': {
    name: 'Lone Jack',
    postalCode: '64070',
  },
  'lone-oak-tx': {
    name: 'Lone Oak',
    postalCode: '75453',
  },
  'lone-pine-ca': {
    name: 'Lone Pine',
    postalCode: '93545',
  },
  'lone-rock-ia': {
    name: 'Lone Rock',
    postalCode: '50559',
  },
  'lone-rock-wi': {
    name: 'Lone Rock',
    postalCode: '53556',
  },
  'lone-star-tx': {
    name: 'Lone Star',
    postalCode: '75668',
  },
  'lone-tree-co': {
    name: 'Lone Tree',
    postalCode: '80124',
  },
  'lone-tree-ia': {
    name: 'Lone Tree',
    postalCode: '52755',
  },
  'lone-wolf-ok': {
    name: 'Lone Wolf',
    postalCode: '73655',
  },
  'lonedell-mo': {
    name: 'Lonedell',
    postalCode: '63060',
  },
  'lonepine-mt': {
    name: 'Lonepine',
    postalCode: '59848',
  },
  'lonetree-wy': {
    name: 'Lonetree',
    postalCode: '82936',
  },
  'long-barn-ca': {
    name: 'Long Barn',
    postalCode: '95335',
  },
  'long-beach-ca': {
    name: 'Long Beach',
    postalCode: '90802',
  },
  'long-beach-ms': {
    name: 'Long Beach',
    postalCode: '39560',
  },
  'long-beach-ny': {
    name: 'Long Beach',
    postalCode: '11561',
  },
  'long-beach-wa': {
    name: 'Long Beach',
    postalCode: '98631',
  },
  'long-bottom-oh': {
    name: 'Long Bottom',
    postalCode: '45743',
  },
  'long-branch-nj': {
    name: 'Long Branch',
    postalCode: '07740',
  },
  'long-branch-tx': {
    name: 'Long Branch',
    postalCode: '75669',
  },
  'long-creek-or': {
    name: 'Long Creek',
    postalCode: '97856',
  },
  'long-creek-sc': {
    name: 'Long Creek',
    postalCode: '29658',
  },
  'long-eddy-ny': {
    name: 'Long Eddy',
    postalCode: '12760',
  },
  'long-grove-ia': {
    name: 'Long Grove',
    postalCode: '52756',
  },
  'long-island-ks': {
    name: 'Long Island',
    postalCode: '67647',
  },
  'long-island-me': {
    name: 'Long Island',
    postalCode: '04050',
  },
  'long-island-va': {
    name: 'Long Island',
    postalCode: '24569',
  },
  'long-island-city-ny': {
    name: 'Long Island City',
    postalCode: '11101',
  },
  'long-lake-mi': {
    name: 'Long Lake',
    postalCode: '48743',
  },
  'long-lake-mn': {
    name: 'Long Lake',
    postalCode: '55356',
  },
  'long-lake-ny': {
    name: 'Long Lake',
    postalCode: '12847',
  },
  'long-lake-sd': {
    name: 'Long Lake',
    postalCode: '57457',
  },
  'long-lake-wi': {
    name: 'Long Lake',
    postalCode: '54542',
  },
  'long-lane-mo': {
    name: 'Long Lane',
    postalCode: '65590',
  },
  'long-pine-ne': {
    name: 'Long Pine',
    postalCode: '69217',
  },
  'long-point-il': {
    name: 'Long Point',
    postalCode: '61333',
  },
  'long-pond-pa': {
    name: 'Long Pond',
    postalCode: '18334',
  },
  'long-prairie-mn': {
    name: 'Long Prairie',
    postalCode: '56347',
  },
  'long-valley-nj': {
    name: 'Long Valley',
    postalCode: '07853',
  },
  'long-valley-sd': {
    name: 'Long Valley',
    postalCode: '57547',
  },
  'longboat-key-fl': {
    name: 'Longboat Key',
    postalCode: '34228',
  },
  'longbranch-wa': {
    name: 'Longbranch',
    postalCode: '98351',
  },
  'longdale-ok': {
    name: 'Longdale',
    postalCode: '73755',
  },
  'longford-ks': {
    name: 'Longford',
    postalCode: '67458',
  },
  'longmeadow-ma': {
    name: 'Longmeadow',
    postalCode: '01106',
  },
  'longmont-co': {
    name: 'Longmont',
    postalCode: '80501',
  },
  'longport-nj': {
    name: 'Longport',
    postalCode: '08403',
  },
  'longs-sc': {
    name: 'Longs',
    postalCode: '29568',
  },
  'longton-ks': {
    name: 'Longton',
    postalCode: '67352',
  },
  'longview-il': {
    name: 'Longview',
    postalCode: '61852',
  },
  'longview-tx': {
    name: 'Longview',
    postalCode: '75601',
  },
  'longview-wa': {
    name: 'Longview',
    postalCode: '98632',
  },
  'longville-la': {
    name: 'Longville',
    postalCode: '70652',
  },
  'longville-mn': {
    name: 'Longville',
    postalCode: '56655',
  },
  'longwood-fl': {
    name: 'Longwood',
    postalCode: '32750',
  },
  'longwood-nc': {
    name: 'Longwood',
    postalCode: '28452',
  },
  'lonoke-ar': {
    name: 'Lonoke',
    postalCode: '72086',
  },
  'lonsdale-ar': {
    name: 'Lonsdale',
    postalCode: '72087',
  },
  'lonsdale-mn': {
    name: 'Lonsdale',
    postalCode: '55046',
  },
  'loogootee-in': {
    name: 'Loogootee',
    postalCode: '47553',
  },
  'lookeba-ok': {
    name: 'Lookeba',
    postalCode: '73053',
  },
  'lookout-wv': {
    name: 'Lookout',
    postalCode: '25868',
  },
  'lookout-mountain-ga': {
    name: 'Lookout Mountain',
    postalCode: '30750',
  },
  'lookout-mountain-tn': {
    name: 'Lookout Mountain',
    postalCode: '37350',
  },
  'loomis-ca': {
    name: 'Loomis',
    postalCode: '95650',
  },
  'loomis-ne': {
    name: 'Loomis',
    postalCode: '68958',
  },
  'loomis-wa': {
    name: 'Loomis',
    postalCode: '98827',
  },
  'loon-lake-wa': {
    name: 'Loon Lake',
    postalCode: '99148',
  },
  'looneyville-wv': {
    name: 'Looneyville',
    postalCode: '25259',
  },
  'loop-tx': {
    name: 'Loop',
    postalCode: '79342',
  },
  'loose-creek-mo': {
    name: 'Loose Creek',
    postalCode: '65054',
  },
  'lopeno-tx': {
    name: 'Lopeno',
    postalCode: '78564',
  },
  'lopez-pa': {
    name: 'Lopez',
    postalCode: '18628',
  },
  'lopez-island-wa': {
    name: 'Lopez Island',
    postalCode: '98261',
  },
  'lorain-oh': {
    name: 'Lorain',
    postalCode: '44052',
  },
  'loraine-il': {
    name: 'Loraine',
    postalCode: '62349',
  },
  'loraine-tx': {
    name: 'Loraine',
    postalCode: '79532',
  },
  'lorane-or': {
    name: 'Lorane',
    postalCode: '97451',
  },
  'loranger-la': {
    name: 'Loranger',
    postalCode: '70446',
  },
  'lordsburg-nm': {
    name: 'Lordsburg',
    postalCode: '88045',
  },
  'lore-city-oh': {
    name: 'Lore City',
    postalCode: '43755',
  },
  'loreauville-la': {
    name: 'Loreauville',
    postalCode: '70552',
  },
  'lorena-tx': {
    name: 'Lorena',
    postalCode: '76655',
  },
  'lorenzo-tx': {
    name: 'Lorenzo',
    postalCode: '79343',
  },
  'loretto-ky': {
    name: 'Loretto',
    postalCode: '40037',
  },
  'loretto-mi': {
    name: 'Loretto',
    postalCode: '49852',
  },
  'loretto-mn': {
    name: 'Loretto',
    postalCode: '55357',
  },
  'loretto-pa': {
    name: 'Loretto',
    postalCode: '15940',
  },
  'loretto-tn': {
    name: 'Loretto',
    postalCode: '38469',
  },
  'loretto-va': {
    name: 'Loretto',
    postalCode: '22509',
  },
  'lorida-fl': {
    name: 'Lorida',
    postalCode: '33857',
  },
  'lorimor-ia': {
    name: 'Lorimor',
    postalCode: '50149',
  },
  'loring-mt': {
    name: 'Loring',
    postalCode: '59537',
  },
  'loris-sc': {
    name: 'Loris',
    postalCode: '29569',
  },
  'lorman-ms': {
    name: 'Lorman',
    postalCode: '39096',
  },
  'lorraine-ks': {
    name: 'Lorraine',
    postalCode: '67459',
  },
  'lorraine-ny': {
    name: 'Lorraine',
    postalCode: '13659',
  },
  'lorton-ne': {
    name: 'Lorton',
    postalCode: '68382',
  },
  'lorton-va': {
    name: 'Lorton',
    postalCode: '22079',
  },
  'los-alamitos-ca': {
    name: 'Los Alamitos',
    postalCode: '90720',
  },
  'los-alamos-nm': {
    name: 'Los Alamos',
    postalCode: '87544',
  },
  'los-altos-ca': {
    name: 'Los Altos',
    postalCode: '94022',
  },
  'los-angeles-ca': {
    name: 'Los Angeles',
    postalCode: '90001',
  },
  'los-banos-ca': {
    name: 'Los Banos',
    postalCode: '93635',
  },
  'los-fresnos-tx': {
    name: 'Los Fresnos',
    postalCode: '78566',
  },
  'los-gatos-ca': {
    name: 'Los Gatos',
    postalCode: '95030',
  },
  'los-lunas-nm': {
    name: 'Los Lunas',
    postalCode: '87031',
  },
  'los-molinos-ca': {
    name: 'Los Molinos',
    postalCode: '96055',
  },
  'los-ojos-nm': {
    name: 'Los Ojos',
    postalCode: '87551',
  },
  'los-osos-ca': {
    name: 'Los Osos',
    postalCode: '93402',
  },
  'losantville-in': {
    name: 'Losantville',
    postalCode: '47354',
  },
  'lost-city-wv': {
    name: 'Lost City',
    postalCode: '26810',
  },
  'lost-creek-ky': {
    name: 'Lost Creek',
    postalCode: '41348',
  },
  'lost-creek-wv': {
    name: 'Lost Creek',
    postalCode: '26385',
  },
  'lost-hills-ca': {
    name: 'Lost Hills',
    postalCode: '93249',
  },
  'lost-nation-ia': {
    name: 'Lost Nation',
    postalCode: '52254',
  },
  'lost-springs-ks': {
    name: 'Lost Springs',
    postalCode: '66859',
  },
  'lost-springs-wy': {
    name: 'Lost Springs',
    postalCode: '82224',
  },
  'lostant-il': {
    name: 'Lostant',
    postalCode: '61334',
  },
  'lostine-or': {
    name: 'Lostine',
    postalCode: '97857',
  },
  'lothair-mt': {
    name: 'Lothair',
    postalCode: '59461',
  },
  'lothian-md': {
    name: 'Lothian',
    postalCode: '20711',
  },
  'lott-tx': {
    name: 'Lott',
    postalCode: '76656',
  },
  'lottie-la': {
    name: 'Lottie',
    postalCode: '70756',
  },
  'lottsburg-va': {
    name: 'Lottsburg',
    postalCode: '22511',
  },
  'lotus-ca': {
    name: 'Lotus',
    postalCode: '95651',
  },
  'louann-ar': {
    name: 'Louann',
    postalCode: '71751',
  },
  'loudon-nh': {
    name: 'Loudon',
    postalCode: '03307',
  },
  'loudon-tn': {
    name: 'Loudon',
    postalCode: '37774',
  },
  'loudonville-oh': {
    name: 'Loudonville',
    postalCode: '44842',
  },
  'louin-ms': {
    name: 'Louin',
    postalCode: '39338',
  },
  'louisa-ky': {
    name: 'Louisa',
    postalCode: '41230',
  },
  'louisa-va': {
    name: 'Louisa',
    postalCode: '23093',
  },
  'louisburg-ks': {
    name: 'Louisburg',
    postalCode: '66053',
  },
  'louisburg-mo': {
    name: 'Louisburg',
    postalCode: '65685',
  },
  'louisburg-nc': {
    name: 'Louisburg',
    postalCode: '27549',
  },
  'louise-ms': {
    name: 'Louise',
    postalCode: '39097',
  },
  'louise-tx': {
    name: 'Louise',
    postalCode: '77455',
  },
  'louisiana-mo': {
    name: 'Louisiana',
    postalCode: '63353',
  },
  'louisville-al': {
    name: 'Louisville',
    postalCode: '36048',
  },
  'louisville-co': {
    name: 'Louisville',
    postalCode: '80027',
  },
  'louisville-ga': {
    name: 'Louisville',
    postalCode: '30434',
  },
  'louisville-il': {
    name: 'Louisville',
    postalCode: '62858',
  },
  'louisville-ky': {
    name: 'Louisville',
    postalCode: '40202',
  },
  'louisville-ms': {
    name: 'Louisville',
    postalCode: '39339',
  },
  'louisville-ne': {
    name: 'Louisville',
    postalCode: '68037',
  },
  'louisville-oh': {
    name: 'Louisville',
    postalCode: '44641',
  },
  'louisville-tn': {
    name: 'Louisville',
    postalCode: '37777',
  },
  'loup-city-ne': {
    name: 'Loup City',
    postalCode: '68853',
  },
  'lovelady-tx': {
    name: 'Lovelady',
    postalCode: '75851',
  },
  'loveland-co': {
    name: 'Loveland',
    postalCode: '80537',
  },
  'loveland-oh': {
    name: 'Loveland',
    postalCode: '45140',
  },
  'loveland-ok': {
    name: 'Loveland',
    postalCode: '73553',
  },
  'lovell-me': {
    name: 'Lovell',
    postalCode: '04051',
  },
  'lovell-wy': {
    name: 'Lovell',
    postalCode: '82431',
  },
  'lovelock-nv': {
    name: 'Lovelock',
    postalCode: '89419',
  },
  'lovely-ky': {
    name: 'Lovely',
    postalCode: '41231',
  },
  'loves-park-il': {
    name: 'Loves Park',
    postalCode: '61111',
  },
  'lovettsville-va': {
    name: 'Lovettsville',
    postalCode: '20180',
  },
  'loveville-md': {
    name: 'Loveville',
    postalCode: '20656',
  },
  'lovilia-ia': {
    name: 'Lovilia',
    postalCode: '50150',
  },
  'loving-nm': {
    name: 'Loving',
    postalCode: '88256',
  },
  'loving-tx': {
    name: 'Loving',
    postalCode: '76460',
  },
  'lovingston-va': {
    name: 'Lovingston',
    postalCode: '22949',
  },
  'lovington-il': {
    name: 'Lovington',
    postalCode: '61937',
  },
  'lovington-nm': {
    name: 'Lovington',
    postalCode: '88260',
  },
  'lowden-ia': {
    name: 'Lowden',
    postalCode: '52255',
  },
  'lowell-ar': {
    name: 'Lowell',
    postalCode: '72745',
  },
  'lowell-in': {
    name: 'Lowell',
    postalCode: '46356',
  },
  'lowell-ma': {
    name: 'Lowell',
    postalCode: '01850',
  },
  'lowell-mi': {
    name: 'Lowell',
    postalCode: '49331',
  },
  'lowell-nc': {
    name: 'Lowell',
    postalCode: '28098',
  },
  'lowell-oh': {
    name: 'Lowell',
    postalCode: '45744',
  },
  'lowell-or': {
    name: 'Lowell',
    postalCode: '97452',
  },
  'lowell-vt': {
    name: 'Lowell',
    postalCode: '05847',
  },
  'lowell-wi': {
    name: 'Lowell',
    postalCode: '53557',
  },
  'lowellville-oh': {
    name: 'Lowellville',
    postalCode: '44436',
  },
  'lower-brule-sd': {
    name: 'Lower Brule',
    postalCode: '57548',
  },
  'lower-lake-ca': {
    name: 'Lower Lake',
    postalCode: '95457',
  },
  'lower-peach-tree-al': {
    name: 'Lower Peach Tree',
    postalCode: '36751',
  },
  'lower-salem-oh': {
    name: 'Lower Salem',
    postalCode: '45745',
  },
  'lowgap-nc': {
    name: 'Lowgap',
    postalCode: '27024',
  },
  'lowland-nc': {
    name: 'Lowland',
    postalCode: '28552',
  },
  'lowman-id': {
    name: 'Lowman',
    postalCode: '83637',
  },
  'lowman-ny': {
    name: 'Lowman',
    postalCode: '14861',
  },
  'lowmansville-ky': {
    name: 'Lowmansville',
    postalCode: '41232',
  },
  'lowndes-mo': {
    name: 'Lowndes',
    postalCode: '63951',
  },
  'lowndesboro-al': {
    name: 'Lowndesboro',
    postalCode: '36752',
  },
  'lowpoint-il': {
    name: 'Lowpoint',
    postalCode: '61545',
  },
  'lowry-mn': {
    name: 'Lowry',
    postalCode: '56349',
  },
  'lowry-va': {
    name: 'Lowry',
    postalCode: '24570',
  },
  'lowry-city-mo': {
    name: 'Lowry City',
    postalCode: '64763',
  },
  'lowville-ny': {
    name: 'Lowville',
    postalCode: '13367',
  },
  'loxahatchee-fl': {
    name: 'Loxahatchee',
    postalCode: '33470',
  },
  'loxley-al': {
    name: 'Loxley',
    postalCode: '36551',
  },
  'loyal-ok': {
    name: 'Loyal',
    postalCode: '73756',
  },
  'loyal-wi': {
    name: 'Loyal',
    postalCode: '54446',
  },
  'loyalhanna-pa': {
    name: 'Loyalhanna',
    postalCode: '15661',
  },
  'loyalton-ca': {
    name: 'Loyalton',
    postalCode: '96118',
  },
  'loysburg-pa': {
    name: 'Loysburg',
    postalCode: '16659',
  },
  'loysville-pa': {
    name: 'Loysville',
    postalCode: '17047',
  },
  'lu-verne-ia': {
    name: 'Lu Verne',
    postalCode: '50560',
  },
  'luana-ia': {
    name: 'Luana',
    postalCode: '52156',
  },
  'lubbock-tx': {
    name: 'Lubbock',
    postalCode: '79401',
  },
  'lubec-me': {
    name: 'Lubec',
    postalCode: '04652',
  },
  'lublin-wi': {
    name: 'Lublin',
    postalCode: '54447',
  },
  'lucama-nc': {
    name: 'Lucama',
    postalCode: '27851',
  },
  'lucan-mn': {
    name: 'Lucan',
    postalCode: '56255',
  },
  'lucas-ia': {
    name: 'Lucas',
    postalCode: '50151',
  },
  'lucas-ks': {
    name: 'Lucas',
    postalCode: '67648',
  },
  'lucas-ky': {
    name: 'Lucas',
    postalCode: '42156',
  },
  'lucas-oh': {
    name: 'Lucas',
    postalCode: '44843',
  },
  'lucasville-oh': {
    name: 'Lucasville',
    postalCode: '45648',
  },
  'lucedale-ms': {
    name: 'Lucedale',
    postalCode: '39452',
  },
  'lucerne-ca': {
    name: 'Lucerne',
    postalCode: '95458',
  },
  'lucerne-in': {
    name: 'Lucerne',
    postalCode: '46950',
  },
  'lucerne-mo': {
    name: 'Lucerne',
    postalCode: '64655',
  },
  'lucerne-valley-ca': {
    name: 'Lucerne Valley',
    postalCode: '92356',
  },
  'lucien-ok': {
    name: 'Lucien',
    postalCode: '73757',
  },
  'lucile-id': {
    name: 'Lucile',
    postalCode: '83542',
  },
  'lucinda-pa': {
    name: 'Lucinda',
    postalCode: '16235',
  },
  'luck-wi': {
    name: 'Luck',
    postalCode: '54853',
  },
  'luckey-oh': {
    name: 'Luckey',
    postalCode: '43443',
  },
  'ludell-ks': {
    name: 'Ludell',
    postalCode: '67744',
  },
  'ludington-mi': {
    name: 'Ludington',
    postalCode: '49431',
  },
  'ludlow-ca': {
    name: 'Ludlow',
    postalCode: '92338',
  },
  'ludlow-il': {
    name: 'Ludlow',
    postalCode: '60949',
  },
  'ludlow-ma': {
    name: 'Ludlow',
    postalCode: '01056',
  },
  'ludlow-mo': {
    name: 'Ludlow',
    postalCode: '64656',
  },
  'ludlow-sd': {
    name: 'Ludlow',
    postalCode: '57755',
  },
  'ludlow-vt': {
    name: 'Ludlow',
    postalCode: '05149',
  },
  'ludlow-falls-oh': {
    name: 'Ludlow Falls',
    postalCode: '45339',
  },
  'ludowici-ga': {
    name: 'Ludowici',
    postalCode: '31316',
  },
  'luebbering-mo': {
    name: 'Luebbering',
    postalCode: '63061',
  },
  'lueders-tx': {
    name: 'Lueders',
    postalCode: '79533',
  },
  'lufkin-tx': {
    name: 'Lufkin',
    postalCode: '75901',
  },
  'lugoff-sc': {
    name: 'Lugoff',
    postalCode: '29078',
  },
  'luke-md': {
    name: 'Luke',
    postalCode: '21540',
  },
  'lula-ga': {
    name: 'Lula',
    postalCode: '30554',
  },
  'luling-la': {
    name: 'Luling',
    postalCode: '70070',
  },
  'luling-tx': {
    name: 'Luling',
    postalCode: '78648',
  },
  'lulu-fl': {
    name: 'Lulu',
    postalCode: '32061',
  },
  'lumber-bridge-nc': {
    name: 'Lumber Bridge',
    postalCode: '28357',
  },
  'lumber-city-ga': {
    name: 'Lumber City',
    postalCode: '31549',
  },
  'lumberport-wv': {
    name: 'Lumberport',
    postalCode: '26386',
  },
  'lumberton-ms': {
    name: 'Lumberton',
    postalCode: '39455',
  },
  'lumberton-nc': {
    name: 'Lumberton',
    postalCode: '28358',
  },
  'lumberton-nj': {
    name: 'Lumberton',
    postalCode: '08048',
  },
  'lumberton-tx': {
    name: 'Lumberton',
    postalCode: '77657',
  },
  'lumberville-pa': {
    name: 'Lumberville',
    postalCode: '18933',
  },
  'lummi-island-wa': {
    name: 'Lummi Island',
    postalCode: '98262',
  },
  'lumpkin-ga': {
    name: 'Lumpkin',
    postalCode: '31815',
  },
  'luna-pier-mi': {
    name: 'Luna Pier',
    postalCode: '48157',
  },
  'lunenburg-ma': {
    name: 'Lunenburg',
    postalCode: '01462',
  },
  'lunenburg-va': {
    name: 'Lunenburg',
    postalCode: '23952',
  },
  'lunenburg-vt': {
    name: 'Lunenburg',
    postalCode: '05906',
  },
  'luning-nv': {
    name: 'Luning',
    postalCode: '89420',
  },
  'lupton-mi': {
    name: 'Lupton',
    postalCode: '48635',
  },
  'lupton-city-tn': {
    name: 'Lupton City',
    postalCode: '37351',
  },
  'luquillo-pr': {
    name: 'Luquillo',
    postalCode: '00773',
  },
  'luray-ks': {
    name: 'Luray',
    postalCode: '67649',
  },
  'luray-mo': {
    name: 'Luray',
    postalCode: '63453',
  },
  'luray-sc': {
    name: 'Luray',
    postalCode: '29932',
  },
  'luray-tn': {
    name: 'Luray',
    postalCode: '38352',
  },
  'luray-va': {
    name: 'Luray',
    postalCode: '22835',
  },
  'lurgan-pa': {
    name: 'Lurgan',
    postalCode: '17232',
  },
  'lusby-md': {
    name: 'Lusby',
    postalCode: '20657',
  },
  'lusk-wy': {
    name: 'Lusk',
    postalCode: '82225',
  },
  'lutcher-la': {
    name: 'Lutcher',
    postalCode: '70071',
  },
  'luther-mi': {
    name: 'Luther',
    postalCode: '49656',
  },
  'luther-ok': {
    name: 'Luther',
    postalCode: '73054',
  },
  'luthersburg-pa': {
    name: 'Luthersburg',
    postalCode: '15848',
  },
  'luthersville-ga': {
    name: 'Luthersville',
    postalCode: '30251',
  },
  'lutherville-timonium-md': {
    name: 'Lutherville Timonium',
    postalCode: '21093',
  },
  'lutsen-mn': {
    name: 'Lutsen',
    postalCode: '55612',
  },
  'luttrell-tn': {
    name: 'Luttrell',
    postalCode: '37779',
  },
  'lutts-tn': {
    name: 'Lutts',
    postalCode: '38471',
  },
  'lutz-fl': {
    name: 'Lutz',
    postalCode: '33548',
  },
  'luverne-al': {
    name: 'Luverne',
    postalCode: '36049',
  },
  'luverne-mn': {
    name: 'Luverne',
    postalCode: '56156',
  },
  'luverne-nd': {
    name: 'Luverne',
    postalCode: '58056',
  },
  'luxemburg-wi': {
    name: 'Luxemburg',
    postalCode: '54217',
  },
  'luxora-ar': {
    name: 'Luxora',
    postalCode: '72358',
  },
  'luzerne-ia': {
    name: 'Luzerne',
    postalCode: '52257',
  },
  'luzerne-mi': {
    name: 'Luzerne',
    postalCode: '48636',
  },
  'luzerne-pa': {
    name: 'Luzerne',
    postalCode: '18709',
  },
  'lyburn-wv': {
    name: 'Lyburn',
    postalCode: '25632',
  },
  'lyerly-ga': {
    name: 'Lyerly',
    postalCode: '30730',
  },
  'lyford-tx': {
    name: 'Lyford',
    postalCode: '78569',
  },
  'lykens-pa': {
    name: 'Lykens',
    postalCode: '17048',
  },
  'lyle-mn': {
    name: 'Lyle',
    postalCode: '55953',
  },
  'lyle-wa': {
    name: 'Lyle',
    postalCode: '98635',
  },
  'lyles-tn': {
    name: 'Lyles',
    postalCode: '37098',
  },
  'lyman-ne': {
    name: 'Lyman',
    postalCode: '69352',
  },
  'lyman-sc': {
    name: 'Lyman',
    postalCode: '29365',
  },
  'lyman-wy': {
    name: 'Lyman',
    postalCode: '82937',
  },
  'lyme-nh': {
    name: 'Lyme',
    postalCode: '03768',
  },
  'lynbrook-ny': {
    name: 'Lynbrook',
    postalCode: '11563',
  },
  'lynch-ne': {
    name: 'Lynch',
    postalCode: '68746',
  },
  'lynch-station-va': {
    name: 'Lynch Station',
    postalCode: '24571',
  },
  'lynchburg-mo': {
    name: 'Lynchburg',
    postalCode: '65543',
  },
  'lynchburg-oh': {
    name: 'Lynchburg',
    postalCode: '45142',
  },
  'lynchburg-sc': {
    name: 'Lynchburg',
    postalCode: '29080',
  },
  'lynchburg-tn': {
    name: 'Lynchburg',
    postalCode: '37352',
  },
  'lynchburg-va': {
    name: 'Lynchburg',
    postalCode: '24501',
  },
  'lynd-mn': {
    name: 'Lynd',
    postalCode: '56157',
  },
  'lyndeborough-nh': {
    name: 'Lyndeborough',
    postalCode: '03082',
  },
  'lynden-wa': {
    name: 'Lynden',
    postalCode: '98264',
  },
  'lyndhurst-nj': {
    name: 'Lyndhurst',
    postalCode: '07071',
  },
  'lyndhurst-va': {
    name: 'Lyndhurst',
    postalCode: '22952',
  },
  'lyndon-il': {
    name: 'Lyndon',
    postalCode: '61261',
  },
  'lyndon-ks': {
    name: 'Lyndon',
    postalCode: '66451',
  },
  'lyndon-center-vt': {
    name: 'Lyndon Center',
    postalCode: '05850',
  },
  'lyndon-station-wi': {
    name: 'Lyndon Station',
    postalCode: '53944',
  },
  'lyndonville-ny': {
    name: 'Lyndonville',
    postalCode: '14098',
  },
  'lyndonville-vt': {
    name: 'Lyndonville',
    postalCode: '05851',
  },
  'lyndora-pa': {
    name: 'Lyndora',
    postalCode: '16045',
  },
  'lynn-al': {
    name: 'Lynn',
    postalCode: '35575',
  },
  'lynn-ar': {
    name: 'Lynn',
    postalCode: '72440',
  },
  'lynn-in': {
    name: 'Lynn',
    postalCode: '47355',
  },
  'lynn-ma': {
    name: 'Lynn',
    postalCode: '01901',
  },
  'lynn-center-il': {
    name: 'Lynn Center',
    postalCode: '61262',
  },
  'lynn-haven-fl': {
    name: 'Lynn Haven',
    postalCode: '32444',
  },
  'lynnfield-ma': {
    name: 'Lynnfield',
    postalCode: '01940',
  },
  'lynnville-ia': {
    name: 'Lynnville',
    postalCode: '50153',
  },
  'lynnville-in': {
    name: 'Lynnville',
    postalCode: '47619',
  },
  'lynnville-tn': {
    name: 'Lynnville',
    postalCode: '38472',
  },
  'lynnwood-wa': {
    name: 'Lynnwood',
    postalCode: '98036',
  },
  'lynwood-ca': {
    name: 'Lynwood',
    postalCode: '90262',
  },
  'lynx-oh': {
    name: 'Lynx',
    postalCode: '45650',
  },
  'lyon-ms': {
    name: 'Lyon',
    postalCode: '38645',
  },
  'lyon-mountain-ny': {
    name: 'Lyon Mountain',
    postalCode: '12952',
  },
  'lyons-co': {
    name: 'Lyons',
    postalCode: '80540',
  },
  'lyons-ga': {
    name: 'Lyons',
    postalCode: '30436',
  },
  'lyons-il': {
    name: 'Lyons',
    postalCode: '60534',
  },
  'lyons-in': {
    name: 'Lyons',
    postalCode: '47443',
  },
  'lyons-ks': {
    name: 'Lyons',
    postalCode: '67554',
  },
  'lyons-mi': {
    name: 'Lyons',
    postalCode: '48851',
  },
  'lyons-ne': {
    name: 'Lyons',
    postalCode: '68038',
  },
  'lyons-nj': {
    name: 'Lyons',
    postalCode: '07939',
  },
  'lyons-ny': {
    name: 'Lyons',
    postalCode: '14489',
  },
  'lyons-oh': {
    name: 'Lyons',
    postalCode: '43533',
  },
  'lyons-or': {
    name: 'Lyons',
    postalCode: '97358',
  },
  'lyons-sd': {
    name: 'Lyons',
    postalCode: '57041',
  },
  'lyons-falls-ny': {
    name: 'Lyons Falls',
    postalCode: '13368',
  },
  'lysite-wy': {
    name: 'Lysite',
    postalCode: '82642',
  },
  'lytle-tx': {
    name: 'Lytle',
    postalCode: '78052',
  },
  'lytle-creek-ca': {
    name: 'Lytle Creek',
    postalCode: '92358',
  },
  'lytton-ia': {
    name: 'Lytton',
    postalCode: '50561',
  },
  'mabank-tx': {
    name: 'Mabank',
    postalCode: '75147',
  },
  'mabel-mn': {
    name: 'Mabel',
    postalCode: '55954',
  },
  'mabelvale-ar': {
    name: 'Mabelvale',
    postalCode: '72103',
  },
  'maben-ms': {
    name: 'Maben',
    postalCode: '39750',
  },
  'maben-wv': {
    name: 'Maben',
    postalCode: '25870',
  },
  'mabie-wv': {
    name: 'Mabie',
    postalCode: '26278',
  },
  'mableton-ga': {
    name: 'Mableton',
    postalCode: '30126',
  },
  'mabton-wa': {
    name: 'Mabton',
    postalCode: '98935',
  },
  'macclenny-fl': {
    name: 'Macclenny',
    postalCode: '32063',
  },
  'macclesfield-nc': {
    name: 'Macclesfield',
    postalCode: '27852',
  },
  'macdoel-ca': {
    name: 'Macdoel',
    postalCode: '96058',
  },
  'macedon-ny': {
    name: 'Macedon',
    postalCode: '14502',
  },
  'macedonia-ia': {
    name: 'Macedonia',
    postalCode: '51549',
  },
  'macedonia-il': {
    name: 'Macedonia',
    postalCode: '62860',
  },
  'macedonia-oh': {
    name: 'Macedonia',
    postalCode: '44056',
  },
  'maceo-ky': {
    name: 'Maceo',
    postalCode: '42355',
  },
  'macfarlan-wv': {
    name: 'Macfarlan',
    postalCode: '26148',
  },
  'machesney-park-il': {
    name: 'Machesney Park',
    postalCode: '61115',
  },
  'machias-me': {
    name: 'Machias',
    postalCode: '04654',
  },
  'machias-ny': {
    name: 'Machias',
    postalCode: '14101',
  },
  'machiasport-me': {
    name: 'Machiasport',
    postalCode: '04655',
  },
  'machipongo-va': {
    name: 'Machipongo',
    postalCode: '23405',
  },
  'mack-co': {
    name: 'Mack',
    postalCode: '81525',
  },
  'mackay-id': {
    name: 'Mackay',
    postalCode: '83251',
  },
  'mackinaw-il': {
    name: 'Mackinaw',
    postalCode: '61755',
  },
  'mackinaw-city-mi': {
    name: 'Mackinaw City',
    postalCode: '49701',
  },
  'macks-creek-mo': {
    name: 'Macks Creek',
    postalCode: '65786',
  },
  'macksburg-ia': {
    name: 'Macksburg',
    postalCode: '50155',
  },
  'macksburg-oh': {
    name: 'Macksburg',
    postalCode: '45746',
  },
  'macksville-ks': {
    name: 'Macksville',
    postalCode: '67557',
  },
  'mackville-ky': {
    name: 'Mackville',
    postalCode: '40040',
  },
  'macomb-il': {
    name: 'Macomb',
    postalCode: '61455',
  },
  'macomb-mi': {
    name: 'Macomb',
    postalCode: '48042',
  },
  'macomb-mo': {
    name: 'Macomb',
    postalCode: '65702',
  },
  'macomb-ok': {
    name: 'Macomb',
    postalCode: '74852',
  },
  'macon-ga': {
    name: 'Macon',
    postalCode: '31201',
  },
  'macon-il': {
    name: 'Macon',
    postalCode: '62544',
  },
  'macon-mo': {
    name: 'Macon',
    postalCode: '63552',
  },
  'macon-ms': {
    name: 'Macon',
    postalCode: '39341',
  },
  'macon-nc': {
    name: 'Macon',
    postalCode: '27551',
  },
  'macungie-pa': {
    name: 'Macungie',
    postalCode: '18062',
  },
  'macy-in': {
    name: 'Macy',
    postalCode: '46951',
  },
  'macy-ne': {
    name: 'Macy',
    postalCode: '68039',
  },
  'mad-river-ca': {
    name: 'Mad River',
    postalCode: '95552',
  },
  'madawaska-me': {
    name: 'Madawaska',
    postalCode: '04756',
  },
  'madbury-nh': {
    name: 'Madbury',
    postalCode: '03823',
  },
  'maddock-nd': {
    name: 'Maddock',
    postalCode: '58348',
  },
  'madelia-mn': {
    name: 'Madelia',
    postalCode: '56062',
  },
  'madera-ca': {
    name: 'Madera',
    postalCode: '93636',
  },
  'madera-pa': {
    name: 'Madera',
    postalCode: '16661',
  },
  'madill-ok': {
    name: 'Madill',
    postalCode: '73446',
  },
  'madison-al': {
    name: 'Madison',
    postalCode: '35756',
  },
  'madison-ct': {
    name: 'Madison',
    postalCode: '06443',
  },
  'madison-fl': {
    name: 'Madison',
    postalCode: '32340',
  },
  'madison-ga': {
    name: 'Madison',
    postalCode: '30650',
  },
  'madison-il': {
    name: 'Madison',
    postalCode: '62060',
  },
  'madison-in': {
    name: 'Madison',
    postalCode: '47250',
  },
  'madison-ks': {
    name: 'Madison',
    postalCode: '66860',
  },
  'madison-md': {
    name: 'Madison',
    postalCode: '21648',
  },
  'madison-me': {
    name: 'Madison',
    postalCode: '04950',
  },
  'madison-mn': {
    name: 'Madison',
    postalCode: '56256',
  },
  'madison-mo': {
    name: 'Madison',
    postalCode: '65263',
  },
  'madison-ms': {
    name: 'Madison',
    postalCode: '39110',
  },
  'madison-nc': {
    name: 'Madison',
    postalCode: '27025',
  },
  'madison-ne': {
    name: 'Madison',
    postalCode: '68748',
  },
  'madison-nh': {
    name: 'Madison',
    postalCode: '03849',
  },
  'madison-nj': {
    name: 'Madison',
    postalCode: '07940',
  },
  'madison-ny': {
    name: 'Madison',
    postalCode: '13402',
  },
  'madison-oh': {
    name: 'Madison',
    postalCode: '44057',
  },
  'madison-sd': {
    name: 'Madison',
    postalCode: '57042',
  },
  'madison-tn': {
    name: 'Madison',
    postalCode: '37115',
  },
  'madison-va': {
    name: 'Madison',
    postalCode: '22727',
  },
  'madison-wi': {
    name: 'Madison',
    postalCode: '53703',
  },
  'madison-wv': {
    name: 'Madison',
    postalCode: '25130',
  },
  'madison-heights-mi': {
    name: 'Madison Heights',
    postalCode: '48071',
  },
  'madison-heights-va': {
    name: 'Madison Heights',
    postalCode: '24572',
  },
  'madison-lake-mn': {
    name: 'Madison Lake',
    postalCode: '56063',
  },
  'madisonburg-pa': {
    name: 'Madisonburg',
    postalCode: '16852',
  },
  'madisonville-ky': {
    name: 'Madisonville',
    postalCode: '42431',
  },
  'madisonville-la': {
    name: 'Madisonville',
    postalCode: '70447',
  },
  'madisonville-tn': {
    name: 'Madisonville',
    postalCode: '37354',
  },
  'madisonville-tx': {
    name: 'Madisonville',
    postalCode: '77864',
  },
  'madras-or': {
    name: 'Madras',
    postalCode: '97741',
  },
  'madrid-ia': {
    name: 'Madrid',
    postalCode: '50156',
  },
  'madrid-ne': {
    name: 'Madrid',
    postalCode: '69150',
  },
  'madrid-ny': {
    name: 'Madrid',
    postalCode: '13660',
  },
  'magalia-ca': {
    name: 'Magalia',
    postalCode: '95954',
  },
  'magazine-ar': {
    name: 'Magazine',
    postalCode: '72943',
  },
  'magdalena-nm': {
    name: 'Magdalena',
    postalCode: '87825',
  },
  'magee-ms': {
    name: 'Magee',
    postalCode: '39111',
  },
  'maggie-valley-nc': {
    name: 'Maggie Valley',
    postalCode: '28751',
  },
  'magna-ut': {
    name: 'Magna',
    postalCode: '84044',
  },
  'magnolia-al': {
    name: 'Magnolia',
    postalCode: '36754',
  },
  'magnolia-ar': {
    name: 'Magnolia',
    postalCode: '71753',
  },
  'magnolia-de': {
    name: 'Magnolia',
    postalCode: '19962',
  },
  'magnolia-ia': {
    name: 'Magnolia',
    postalCode: '51550',
  },
  'magnolia-il': {
    name: 'Magnolia',
    postalCode: '61336',
  },
  'magnolia-ky': {
    name: 'Magnolia',
    postalCode: '42757',
  },
  'magnolia-mn': {
    name: 'Magnolia',
    postalCode: '56158',
  },
  'magnolia-ms': {
    name: 'Magnolia',
    postalCode: '39652',
  },
  'magnolia-nc': {
    name: 'Magnolia',
    postalCode: '28453',
  },
  'magnolia-nj': {
    name: 'Magnolia',
    postalCode: '08049',
  },
  'magnolia-oh': {
    name: 'Magnolia',
    postalCode: '44643',
  },
  'magnolia-tx': {
    name: 'Magnolia',
    postalCode: '77354',
  },
  'magnolia-springs-al': {
    name: 'Magnolia Springs',
    postalCode: '36555',
  },
  'mahaffey-pa': {
    name: 'Mahaffey',
    postalCode: '15757',
  },
  'mahanoy-city-pa': {
    name: 'Mahanoy City',
    postalCode: '17948',
  },
  'mahaska-ks': {
    name: 'Mahaska',
    postalCode: '66955',
  },
  'mahnomen-mn': {
    name: 'Mahnomen',
    postalCode: '56557',
  },
  'mahomet-il': {
    name: 'Mahomet',
    postalCode: '61853',
  },
  'mahopac-ny': {
    name: 'Mahopac',
    postalCode: '10541',
  },
  'mahwah-nj': {
    name: 'Mahwah',
    postalCode: '07430',
  },
  'maida-nd': {
    name: 'Maida',
    postalCode: '58255',
  },
  'maiden-nc': {
    name: 'Maiden',
    postalCode: '28650',
  },
  'maiden-rock-wi': {
    name: 'Maiden Rock',
    postalCode: '54750',
  },
  'maidens-va': {
    name: 'Maidens',
    postalCode: '23102',
  },
  'maidsville-wv': {
    name: 'Maidsville',
    postalCode: '26541',
  },
  'maine-ny': {
    name: 'Maine',
    postalCode: '13802',
  },
  'mainesburg-pa': {
    name: 'Mainesburg',
    postalCode: '16932',
  },
  'maineville-oh': {
    name: 'Maineville',
    postalCode: '45039',
  },
  'maitland-fl': {
    name: 'Maitland',
    postalCode: '32751',
  },
  'maitland-mo': {
    name: 'Maitland',
    postalCode: '64466',
  },
  'maize-ks': {
    name: 'Maize',
    postalCode: '67101',
  },
  'majestic-ky': {
    name: 'Majestic',
    postalCode: '41547',
  },
  'makanda-il': {
    name: 'Makanda',
    postalCode: '62958',
  },
  'makawao-hi': {
    name: 'Makawao',
    postalCode: '96768',
  },
  'makinen-mn': {
    name: 'Makinen',
    postalCode: '55763',
  },
  'makoti-nd': {
    name: 'Makoti',
    postalCode: '58756',
  },
  'malabar-fl': {
    name: 'Malabar',
    postalCode: '32950',
  },
  'malad-city-id': {
    name: 'Malad City',
    postalCode: '83252',
  },
  'malaga-nj': {
    name: 'Malaga',
    postalCode: '08328',
  },
  'malaga-wa': {
    name: 'Malaga',
    postalCode: '98828',
  },
  'malakoff-tx': {
    name: 'Malakoff',
    postalCode: '75148',
  },
  'malcolm-ne': {
    name: 'Malcolm',
    postalCode: '68402',
  },
  'malcom-ia': {
    name: 'Malcom',
    postalCode: '50157',
  },
  'malden-il': {
    name: 'Malden',
    postalCode: '61337',
  },
  'malden-ma': {
    name: 'Malden',
    postalCode: '02148',
  },
  'malden-mo': {
    name: 'Malden',
    postalCode: '63863',
  },
  'malden-bridge-ny': {
    name: 'Malden Bridge',
    postalCode: '12115',
  },
  'malibu-ca': {
    name: 'Malibu',
    postalCode: '90265',
  },
  'malin-or': {
    name: 'Malin',
    postalCode: '97632',
  },
  'malinta-oh': {
    name: 'Malinta',
    postalCode: '43535',
  },
  'maljamar-nm': {
    name: 'Maljamar',
    postalCode: '88264',
  },
  'mallard-ia': {
    name: 'Mallard',
    postalCode: '50562',
  },
  'mallie-ky': {
    name: 'Mallie',
    postalCode: '41836',
  },
  'mallory-ny': {
    name: 'Mallory',
    postalCode: '13103',
  },
  'malmo-ne': {
    name: 'Malmo',
    postalCode: '68040',
  },
  'malmstrom-afb-mt': {
    name: 'Malmstrom AFB',
    postalCode: '59402',
  },
  'malo-wa': {
    name: 'Malo',
    postalCode: '99150',
  },
  'malone-fl': {
    name: 'Malone',
    postalCode: '32445',
  },
  'malone-ny': {
    name: 'Malone',
    postalCode: '12953',
  },
  'malone-tx': {
    name: 'Malone',
    postalCode: '76660',
  },
  'malone-wi': {
    name: 'Malone',
    postalCode: '53049',
  },
  'malta-id': {
    name: 'Malta',
    postalCode: '83342',
  },
  'malta-il': {
    name: 'Malta',
    postalCode: '60150',
  },
  'malta-mt': {
    name: 'Malta',
    postalCode: '59538',
  },
  'malta-oh': {
    name: 'Malta',
    postalCode: '43758',
  },
  'malta-bend-mo': {
    name: 'Malta Bend',
    postalCode: '65339',
  },
  'malvern-ar': {
    name: 'Malvern',
    postalCode: '72104',
  },
  'malvern-ia': {
    name: 'Malvern',
    postalCode: '51551',
  },
  'malvern-oh': {
    name: 'Malvern',
    postalCode: '44644',
  },
  'malvern-pa': {
    name: 'Malvern',
    postalCode: '19355',
  },
  'malverne-ny': {
    name: 'Malverne',
    postalCode: '11565',
  },
  'mamaroneck-ny': {
    name: 'Mamaroneck',
    postalCode: '10543',
  },
  'mammoth-wv': {
    name: 'Mammoth',
    postalCode: '25132',
  },
  'mammoth-cave-ky': {
    name: 'Mammoth Cave',
    postalCode: '42259',
  },
  'mammoth-lakes-ca': {
    name: 'Mammoth Lakes',
    postalCode: '93546',
  },
  'mammoth-spring-ar': {
    name: 'Mammoth Spring',
    postalCode: '72554',
  },
  'mamou-la': {
    name: 'Mamou',
    postalCode: '70554',
  },
  'man-wv': {
    name: 'Man',
    postalCode: '25635',
  },
  'manahawkin-nj': {
    name: 'Manahawkin',
    postalCode: '08050',
  },
  'manakin-sabot-va': {
    name: 'Manakin Sabot',
    postalCode: '23103',
  },
  'manasquan-nj': {
    name: 'Manasquan',
    postalCode: '08736',
  },
  'manassas-va': {
    name: 'Manassas',
    postalCode: '20109',
  },
  'manati-pr': {
    name: 'Manati',
    postalCode: '00674',
  },
  'manawa-wi': {
    name: 'Manawa',
    postalCode: '54949',
  },
  'mancelona-mi': {
    name: 'Mancelona',
    postalCode: '49659',
  },
  'manchaca-tx': {
    name: 'Manchaca',
    postalCode: '78652',
  },
  'manchester-ca': {
    name: 'Manchester',
    postalCode: '95459',
  },
  'manchester-ct': {
    name: 'Manchester',
    postalCode: '06040',
  },
  'manchester-ga': {
    name: 'Manchester',
    postalCode: '31816',
  },
  'manchester-ia': {
    name: 'Manchester',
    postalCode: '52057',
  },
  'manchester-ky': {
    name: 'Manchester',
    postalCode: '40962',
  },
  'manchester-ma': {
    name: 'Manchester',
    postalCode: '01944',
  },
  'manchester-md': {
    name: 'Manchester',
    postalCode: '21102',
  },
  'manchester-me': {
    name: 'Manchester',
    postalCode: '04351',
  },
  'manchester-mi': {
    name: 'Manchester',
    postalCode: '48158',
  },
  'manchester-nh': {
    name: 'Manchester',
    postalCode: '03101',
  },
  'manchester-ny': {
    name: 'Manchester',
    postalCode: '14504',
  },
  'manchester-oh': {
    name: 'Manchester',
    postalCode: '45144',
  },
  'manchester-ok': {
    name: 'Manchester',
    postalCode: '73758',
  },
  'manchester-pa': {
    name: 'Manchester',
    postalCode: '17345',
  },
  'manchester-tn': {
    name: 'Manchester',
    postalCode: '37355',
  },
  'manchester-center-vt': {
    name: 'Manchester Center',
    postalCode: '05255',
  },
  'manchester-township-nj': {
    name: 'Manchester Township',
    postalCode: '08759',
  },
  'mancos-co': {
    name: 'Mancos',
    postalCode: '81328',
  },
  'mandan-nd': {
    name: 'Mandan',
    postalCode: '58554',
  },
  'mandaree-nd': {
    name: 'Mandaree',
    postalCode: '58757',
  },
  'manderson-wy': {
    name: 'Manderson',
    postalCode: '82432',
  },
  'mandeville-la': {
    name: 'Mandeville',
    postalCode: '70448',
  },
  'mangham-la': {
    name: 'Mangham',
    postalCode: '71259',
  },
  'mangum-ok': {
    name: 'Mangum',
    postalCode: '73554',
  },
  'manhasset-ny': {
    name: 'Manhasset',
    postalCode: '11030',
  },
  'manhattan-il': {
    name: 'Manhattan',
    postalCode: '60442',
  },
  'manhattan-ks': {
    name: 'Manhattan',
    postalCode: '66502',
  },
  'manhattan-mt': {
    name: 'Manhattan',
    postalCode: '59741',
  },
  'manhattan-beach-ca': {
    name: 'Manhattan Beach',
    postalCode: '90266',
  },
  'manheim-pa': {
    name: 'Manheim',
    postalCode: '17545',
  },
  'manila-ar': {
    name: 'Manila',
    postalCode: '72442',
  },
  'manila-ut': {
    name: 'Manila',
    postalCode: '84046',
  },
  'manilla-ia': {
    name: 'Manilla',
    postalCode: '51454',
  },
  'manilla-in': {
    name: 'Manilla',
    postalCode: '46150',
  },
  'manistee-mi': {
    name: 'Manistee',
    postalCode: '49660',
  },
  'manistique-mi': {
    name: 'Manistique',
    postalCode: '49854',
  },
  'manito-il': {
    name: 'Manito',
    postalCode: '61546',
  },
  'manitou-ky': {
    name: 'Manitou',
    postalCode: '42436',
  },
  'manitou-beach-mi': {
    name: 'Manitou Beach',
    postalCode: '49253',
  },
  'manitou-springs-co': {
    name: 'Manitou Springs',
    postalCode: '80829',
  },
  'manitowish-waters-wi': {
    name: 'Manitowish Waters',
    postalCode: '54545',
  },
  'manitowoc-wi': {
    name: 'Manitowoc',
    postalCode: '54220',
  },
  'mankato-ks': {
    name: 'Mankato',
    postalCode: '66956',
  },
  'mankato-mn': {
    name: 'Mankato',
    postalCode: '56001',
  },
  'manlius-ny': {
    name: 'Manlius',
    postalCode: '13104',
  },
  'manly-ia': {
    name: 'Manly',
    postalCode: '50456',
  },
  'mannford-ok': {
    name: 'Mannford',
    postalCode: '74044',
  },
  'manning-ia': {
    name: 'Manning',
    postalCode: '51455',
  },
  'manning-nd': {
    name: 'Manning',
    postalCode: '58642',
  },
  'manning-or': {
    name: 'Manning',
    postalCode: '97125',
  },
  'manning-sc': {
    name: 'Manning',
    postalCode: '29102',
  },
  'mannington-wv': {
    name: 'Mannington',
    postalCode: '26582',
  },
  'manns-choice-pa': {
    name: 'Manns Choice',
    postalCode: '15550',
  },
  'manns-harbor-nc': {
    name: 'Manns Harbor',
    postalCode: '27953',
  },
  'mannsville-ny': {
    name: 'Mannsville',
    postalCode: '13661',
  },
  'mannsville-ok': {
    name: 'Mannsville',
    postalCode: '73447',
  },
  'manor-ga': {
    name: 'Manor',
    postalCode: '31550',
  },
  'manor-pa': {
    name: 'Manor',
    postalCode: '15665',
  },
  'manor-tx': {
    name: 'Manor',
    postalCode: '78653',
  },
  'manorville-ny': {
    name: 'Manorville',
    postalCode: '11949',
  },
  'manquin-va': {
    name: 'Manquin',
    postalCode: '23106',
  },
  'mansfield-ar': {
    name: 'Mansfield',
    postalCode: '72944',
  },
  'mansfield-ga': {
    name: 'Mansfield',
    postalCode: '30055',
  },
  'mansfield-il': {
    name: 'Mansfield',
    postalCode: '61854',
  },
  'mansfield-la': {
    name: 'Mansfield',
    postalCode: '71052',
  },
  'mansfield-ma': {
    name: 'Mansfield',
    postalCode: '02048',
  },
  'mansfield-mo': {
    name: 'Mansfield',
    postalCode: '65704',
  },
  'mansfield-oh': {
    name: 'Mansfield',
    postalCode: '44902',
  },
  'mansfield-pa': {
    name: 'Mansfield',
    postalCode: '16933',
  },
  'mansfield-sd': {
    name: 'Mansfield',
    postalCode: '57460',
  },
  'mansfield-tn': {
    name: 'Mansfield',
    postalCode: '38236',
  },
  'mansfield-tx': {
    name: 'Mansfield',
    postalCode: '76063',
  },
  'mansfield-wa': {
    name: 'Mansfield',
    postalCode: '98830',
  },
  'mansfield-center-ct': {
    name: 'Mansfield Center',
    postalCode: '06250',
  },
  'manson-ia': {
    name: 'Manson',
    postalCode: '50563',
  },
  'manson-nc': {
    name: 'Manson',
    postalCode: '27553',
  },
  'manson-wa': {
    name: 'Manson',
    postalCode: '98831',
  },
  'mansura-la': {
    name: 'Mansura',
    postalCode: '71350',
  },
  'mantachie-ms': {
    name: 'Mantachie',
    postalCode: '38855',
  },
  'mantador-nd': {
    name: 'Mantador',
    postalCode: '58058',
  },
  'manteca-ca': {
    name: 'Manteca',
    postalCode: '95336',
  },
  'mantee-ms': {
    name: 'Mantee',
    postalCode: '39751',
  },
  'manteno-il': {
    name: 'Manteno',
    postalCode: '60950',
  },
  'manteo-nc': {
    name: 'Manteo',
    postalCode: '27954',
  },
  'manter-ks': {
    name: 'Manter',
    postalCode: '67862',
  },
  'manti-ut': {
    name: 'Manti',
    postalCode: '84642',
  },
  'mantoloking-nj': {
    name: 'Mantoloking',
    postalCode: '08738',
  },
  'manton-ca': {
    name: 'Manton',
    postalCode: '96059',
  },
  'manton-mi': {
    name: 'Manton',
    postalCode: '49663',
  },
  'mantorville-mn': {
    name: 'Mantorville',
    postalCode: '55955',
  },
  'mantua-nj': {
    name: 'Mantua',
    postalCode: '08051',
  },
  'mantua-oh': {
    name: 'Mantua',
    postalCode: '44255',
  },
  'mantua-ut': {
    name: 'Mantua',
    postalCode: '84324',
  },
  'manvel-nd': {
    name: 'Manvel',
    postalCode: '58256',
  },
  'manvel-tx': {
    name: 'Manvel',
    postalCode: '77578',
  },
  'manville-nj': {
    name: 'Manville',
    postalCode: '08835',
  },
  'manville-ri': {
    name: 'Manville',
    postalCode: '02838',
  },
  'manville-wy': {
    name: 'Manville',
    postalCode: '82227',
  },
  'many-la': {
    name: 'Many',
    postalCode: '71449',
  },
  'manzanola-co': {
    name: 'Manzanola',
    postalCode: '81058',
  },
  'maple-nc': {
    name: 'Maple',
    postalCode: '27956',
  },
  'maple-tx': {
    name: 'Maple',
    postalCode: '79344',
  },
  'maple-wi': {
    name: 'Maple',
    postalCode: '54854',
  },
  'maple-city-ks': {
    name: 'Maple City',
    postalCode: '67102',
  },
  'maple-city-mi': {
    name: 'Maple City',
    postalCode: '49664',
  },
  'maple-falls-wa': {
    name: 'Maple Falls',
    postalCode: '98266',
  },
  'maple-grove-mn': {
    name: 'Maple Grove',
    postalCode: '55311',
  },
  'maple-heights-oh': {
    name: 'Maple Heights',
    postalCode: '44137',
  },
  'maple-hill-ks': {
    name: 'Maple Hill',
    postalCode: '66507',
  },
  'maple-hill-nc': {
    name: 'Maple Hill',
    postalCode: '28454',
  },
  'maple-lake-mn': {
    name: 'Maple Lake',
    postalCode: '55358',
  },
  'maple-park-il': {
    name: 'Maple Park',
    postalCode: '60151',
  },
  'maple-plain-mn': {
    name: 'Maple Plain',
    postalCode: '55359',
  },
  'maple-shade-nj': {
    name: 'Maple Shade',
    postalCode: '08052',
  },
  'maple-valley-wa': {
    name: 'Maple Valley',
    postalCode: '98038',
  },
  'maplecrest-ny': {
    name: 'Maplecrest',
    postalCode: '12454',
  },
  'maplesville-al': {
    name: 'Maplesville',
    postalCode: '36750',
  },
  'mapleton-ia': {
    name: 'Mapleton',
    postalCode: '51034',
  },
  'mapleton-il': {
    name: 'Mapleton',
    postalCode: '61547',
  },
  'mapleton-ks': {
    name: 'Mapleton',
    postalCode: '66754',
  },
  'mapleton-me': {
    name: 'Mapleton',
    postalCode: '04757',
  },
  'mapleton-mn': {
    name: 'Mapleton',
    postalCode: '56065',
  },
  'mapleton-nd': {
    name: 'Mapleton',
    postalCode: '58059',
  },
  'mapleton-or': {
    name: 'Mapleton',
    postalCode: '97453',
  },
  'mapleton-ut': {
    name: 'Mapleton',
    postalCode: '84664',
  },
  'mapleton-depot-pa': {
    name: 'Mapleton Depot',
    postalCode: '17052',
  },
  'mapleville-ri': {
    name: 'Mapleville',
    postalCode: '02839',
  },
  'maplewood-nj': {
    name: 'Maplewood',
    postalCode: '07040',
  },
  'maplewood-oh': {
    name: 'Maplewood',
    postalCode: '45340',
  },
  'maquoketa-ia': {
    name: 'Maquoketa',
    postalCode: '52060',
  },
  'maquon-il': {
    name: 'Maquon',
    postalCode: '61458',
  },
  'maramec-ok': {
    name: 'Maramec',
    postalCode: '74045',
  },
  'marana-az': {
    name: 'Marana',
    postalCode: '85653',
  },
  'marathon-fl': {
    name: 'Marathon',
    postalCode: '33050',
  },
  'marathon-ia': {
    name: 'Marathon',
    postalCode: '50565',
  },
  'marathon-ny': {
    name: 'Marathon',
    postalCode: '13803',
  },
  'marathon-wi': {
    name: 'Marathon',
    postalCode: '54448',
  },
  'marble-nc': {
    name: 'Marble',
    postalCode: '28905',
  },
  'marble-pa': {
    name: 'Marble',
    postalCode: '16334',
  },
  'marble-canyon-az': {
    name: 'Marble Canyon',
    postalCode: '86036',
  },
  'marble-falls-ar': {
    name: 'Marble Falls',
    postalCode: '72648',
  },
  'marble-falls-tx': {
    name: 'Marble Falls',
    postalCode: '78654',
  },
  'marble-hill-ga': {
    name: 'Marble Hill',
    postalCode: '30148',
  },
  'marble-hill-mo': {
    name: 'Marble Hill',
    postalCode: '63764',
  },
  'marble-rock-ia': {
    name: 'Marble Rock',
    postalCode: '50653',
  },
  'marblehead-ma': {
    name: 'Marblehead',
    postalCode: '01945',
  },
  'marblemount-wa': {
    name: 'Marblemount',
    postalCode: '98267',
  },
  'marbury-al': {
    name: 'Marbury',
    postalCode: '36051',
  },
  'marbury-md': {
    name: 'Marbury',
    postalCode: '20658',
  },
  'marceline-mo': {
    name: 'Marceline',
    postalCode: '64658',
  },
  'marcell-mn': {
    name: 'Marcell',
    postalCode: '56657',
  },
  'marcella-ar': {
    name: 'Marcella',
    postalCode: '72555',
  },
  'marcellus-mi': {
    name: 'Marcellus',
    postalCode: '49067',
  },
  'marcellus-ny': {
    name: 'Marcellus',
    postalCode: '13108',
  },
  'march-air-reserve-base-ca': {
    name: 'March Air Reserve Base',
    postalCode: '92518',
  },
  'marchand-pa': {
    name: 'Marchand',
    postalCode: '15758',
  },
  'marco-island-fl': {
    name: 'Marco Island',
    postalCode: '34145',
  },
  'marcola-or': {
    name: 'Marcola',
    postalCode: '97454',
  },
  'marcus-ia': {
    name: 'Marcus',
    postalCode: '51035',
  },
  'marcus-hook-pa': {
    name: 'Marcus Hook',
    postalCode: '19061',
  },
  'marcy-ny': {
    name: 'Marcy',
    postalCode: '13403',
  },
  'mardela-springs-md': {
    name: 'Mardela Springs',
    postalCode: '21837',
  },
  'marengo-ia': {
    name: 'Marengo',
    postalCode: '52301',
  },
  'marengo-il': {
    name: 'Marengo',
    postalCode: '60152',
  },
  'marengo-in': {
    name: 'Marengo',
    postalCode: '47140',
  },
  'marengo-oh': {
    name: 'Marengo',
    postalCode: '43334',
  },
  'marengo-wi': {
    name: 'Marengo',
    postalCode: '54855',
  },
  'marenisco-mi': {
    name: 'Marenisco',
    postalCode: '49947',
  },
  'marfa-tx': {
    name: 'Marfa',
    postalCode: '79843',
  },
  'margarettsville-nc': {
    name: 'Margarettsville',
    postalCode: '27853',
  },
  'margaretville-ny': {
    name: 'Margaretville',
    postalCode: '12455',
  },
  'margate-city-nj': {
    name: 'Margate City',
    postalCode: '08402',
  },
  'margie-mn': {
    name: 'Margie',
    postalCode: '56658',
  },
  'maria-stein-oh': {
    name: 'Maria Stein',
    postalCode: '45860',
  },
  'marianna-ar': {
    name: 'Marianna',
    postalCode: '72360',
  },
  'marianna-fl': {
    name: 'Marianna',
    postalCode: '32446',
  },
  'marianna-pa': {
    name: 'Marianna',
    postalCode: '15345',
  },
  'maribel-wi': {
    name: 'Maribel',
    postalCode: '54227',
  },
  'maricao-pr': {
    name: 'Maricao',
    postalCode: '00606',
  },
  'maricopa-az': {
    name: 'Maricopa',
    postalCode: '85138',
  },
  'maricopa-ca': {
    name: 'Maricopa',
    postalCode: '93252',
  },
  'marienthal-ks': {
    name: 'Marienthal',
    postalCode: '67863',
  },
  'marienville-pa': {
    name: 'Marienville',
    postalCode: '16239',
  },
  'marietta-ga': {
    name: 'Marietta',
    postalCode: '30008',
  },
  'marietta-il': {
    name: 'Marietta',
    postalCode: '61459',
  },
  'marietta-mn': {
    name: 'Marietta',
    postalCode: '56257',
  },
  'marietta-ms': {
    name: 'Marietta',
    postalCode: '38856',
  },
  'marietta-ny': {
    name: 'Marietta',
    postalCode: '13110',
  },
  'marietta-oh': {
    name: 'Marietta',
    postalCode: '45750',
  },
  'marietta-ok': {
    name: 'Marietta',
    postalCode: '73448',
  },
  'marietta-pa': {
    name: 'Marietta',
    postalCode: '17547',
  },
  'marietta-sc': {
    name: 'Marietta',
    postalCode: '29661',
  },
  'marietta-tx': {
    name: 'Marietta',
    postalCode: '75566',
  },
  'marilla-ny': {
    name: 'Marilla',
    postalCode: '14102',
  },
  'marina-ca': {
    name: 'Marina',
    postalCode: '93933',
  },
  'marina-del-rey-ca': {
    name: 'Marina Del Rey',
    postalCode: '90292',
  },
  'marine-il': {
    name: 'Marine',
    postalCode: '62061',
  },
  'marine-city-mi': {
    name: 'Marine City',
    postalCode: '48039',
  },
  'marine-on-saint-croix-mn': {
    name: 'Marine On Saint Croix',
    postalCode: '55047',
  },
  'marinette-wi': {
    name: 'Marinette',
    postalCode: '54143',
  },
  'maringouin-la': {
    name: 'Maringouin',
    postalCode: '70757',
  },
  'marion-al': {
    name: 'Marion',
    postalCode: '36756',
  },
  'marion-ar': {
    name: 'Marion',
    postalCode: '72364',
  },
  'marion-ia': {
    name: 'Marion',
    postalCode: '52302',
  },
  'marion-il': {
    name: 'Marion',
    postalCode: '62959',
  },
  'marion-in': {
    name: 'Marion',
    postalCode: '46952',
  },
  'marion-ks': {
    name: 'Marion',
    postalCode: '66861',
  },
  'marion-ky': {
    name: 'Marion',
    postalCode: '42064',
  },
  'marion-la': {
    name: 'Marion',
    postalCode: '71260',
  },
  'marion-ma': {
    name: 'Marion',
    postalCode: '02738',
  },
  'marion-mi': {
    name: 'Marion',
    postalCode: '49665',
  },
  'marion-ms': {
    name: 'Marion',
    postalCode: '39342',
  },
  'marion-mt': {
    name: 'Marion',
    postalCode: '59925',
  },
  'marion-nc': {
    name: 'Marion',
    postalCode: '28752',
  },
  'marion-nd': {
    name: 'Marion',
    postalCode: '58466',
  },
  'marion-ny': {
    name: 'Marion',
    postalCode: '14505',
  },
  'marion-oh': {
    name: 'Marion',
    postalCode: '43302',
  },
  'marion-sc': {
    name: 'Marion',
    postalCode: '29571',
  },
  'marion-sd': {
    name: 'Marion',
    postalCode: '57043',
  },
  'marion-tx': {
    name: 'Marion',
    postalCode: '78124',
  },
  'marion-va': {
    name: 'Marion',
    postalCode: '24354',
  },
  'marion-wi': {
    name: 'Marion',
    postalCode: '54950',
  },
  'marion-center-pa': {
    name: 'Marion Center',
    postalCode: '15759',
  },
  'marion-junction-al': {
    name: 'Marion Junction',
    postalCode: '36759',
  },
  'marion-station-md': {
    name: 'Marion Station',
    postalCode: '21838',
  },
  'marionville-mo': {
    name: 'Marionville',
    postalCode: '65705',
  },
  'mariposa-ca': {
    name: 'Mariposa',
    postalCode: '95338',
  },
  'marissa-il': {
    name: 'Marissa',
    postalCode: '62257',
  },
  'mark-center-oh': {
    name: 'Mark Center',
    postalCode: '43536',
  },
  'marked-tree-ar': {
    name: 'Marked Tree',
    postalCode: '72365',
  },
  'markesan-wi': {
    name: 'Markesan',
    postalCode: '53946',
  },
  'markham-il': {
    name: 'Markham',
    postalCode: '60428',
  },
  'markham-va': {
    name: 'Markham',
    postalCode: '22643',
  },
  'markle-in': {
    name: 'Markle',
    postalCode: '46770',
  },
  'markleeville-ca': {
    name: 'Markleeville',
    postalCode: '96120',
  },
  'markleton-pa': {
    name: 'Markleton',
    postalCode: '15551',
  },
  'markleville-in': {
    name: 'Markleville',
    postalCode: '46056',
  },
  'markleysburg-pa': {
    name: 'Markleysburg',
    postalCode: '15459',
  },
  'marks-ms': {
    name: 'Marks',
    postalCode: '38609',
  },
  'marksville-la': {
    name: 'Marksville',
    postalCode: '71351',
  },
  'marland-ok': {
    name: 'Marland',
    postalCode: '74644',
  },
  'marlboro-nj': {
    name: 'Marlboro',
    postalCode: '07746',
  },
  'marlboro-ny': {
    name: 'Marlboro',
    postalCode: '12542',
  },
  'marlborough-ct': {
    name: 'Marlborough',
    postalCode: '06447',
  },
  'marlborough-ma': {
    name: 'Marlborough',
    postalCode: '01752',
  },
  'marlborough-nh': {
    name: 'Marlborough',
    postalCode: '03455',
  },
  'marlette-mi': {
    name: 'Marlette',
    postalCode: '48453',
  },
  'marlin-tx': {
    name: 'Marlin',
    postalCode: '76661',
  },
  'marlin-wa': {
    name: 'Marlin',
    postalCode: '98832',
  },
  'marlinton-wv': {
    name: 'Marlinton',
    postalCode: '24954',
  },
  'marlow-nh': {
    name: 'Marlow',
    postalCode: '03456',
  },
  'marlow-ok': {
    name: 'Marlow',
    postalCode: '73055',
  },
  'marlton-nj': {
    name: 'Marlton',
    postalCode: '08053',
  },
  'marmaduke-ar': {
    name: 'Marmaduke',
    postalCode: '72443',
  },
  'marmarth-nd': {
    name: 'Marmarth',
    postalCode: '58643',
  },
  'marmora-nj': {
    name: 'Marmora',
    postalCode: '08223',
  },
  'marne-ia': {
    name: 'Marne',
    postalCode: '51552',
  },
  'marne-mi': {
    name: 'Marne',
    postalCode: '49435',
  },
  'maroa-il': {
    name: 'Maroa',
    postalCode: '61756',
  },
  'marquand-mo': {
    name: 'Marquand',
    postalCode: '63655',
  },
  'marquette-ks': {
    name: 'Marquette',
    postalCode: '67464',
  },
  'marquette-mi': {
    name: 'Marquette',
    postalCode: '49855',
  },
  'marquette-ne': {
    name: 'Marquette',
    postalCode: '68854',
  },
  'marquez-tx': {
    name: 'Marquez',
    postalCode: '77865',
  },
  'marrero-la': {
    name: 'Marrero',
    postalCode: '70072',
  },
  'marriottsville-md': {
    name: 'Marriottsville',
    postalCode: '21104',
  },
  'mars-pa': {
    name: 'Mars',
    postalCode: '16046',
  },
  'mars-hill-me': {
    name: 'Mars Hill',
    postalCode: '04758',
  },
  'mars-hill-nc': {
    name: 'Mars Hill',
    postalCode: '28754',
  },
  'marseilles-il': {
    name: 'Marseilles',
    postalCode: '61341',
  },
  'marshall-ar': {
    name: 'Marshall',
    postalCode: '72650',
  },
  'marshall-ca': {
    name: 'Marshall',
    postalCode: '94940',
  },
  'marshall-il': {
    name: 'Marshall',
    postalCode: '62441',
  },
  'marshall-in': {
    name: 'Marshall',
    postalCode: '47859',
  },
  'marshall-mi': {
    name: 'Marshall',
    postalCode: '49068',
  },
  'marshall-mn': {
    name: 'Marshall',
    postalCode: '56258',
  },
  'marshall-mo': {
    name: 'Marshall',
    postalCode: '65340',
  },
  'marshall-nc': {
    name: 'Marshall',
    postalCode: '28753',
  },
  'marshall-nd': {
    name: 'Marshall',
    postalCode: '58644',
  },
  'marshall-ok': {
    name: 'Marshall',
    postalCode: '73056',
  },
  'marshall-tx': {
    name: 'Marshall',
    postalCode: '75670',
  },
  'marshall-va': {
    name: 'Marshall',
    postalCode: '20115',
  },
  'marshall-wi': {
    name: 'Marshall',
    postalCode: '53559',
  },
  'marshallberg-nc': {
    name: 'Marshallberg',
    postalCode: '28553',
  },
  'marshalltown-ia': {
    name: 'Marshalltown',
    postalCode: '50158',
  },
  'marshallville-ga': {
    name: 'Marshallville',
    postalCode: '31057',
  },
  'marshallville-oh': {
    name: 'Marshallville',
    postalCode: '44645',
  },
  'marshfield-ma': {
    name: 'Marshfield',
    postalCode: '02050',
  },
  'marshfield-mo': {
    name: 'Marshfield',
    postalCode: '65706',
  },
  'marshfield-vt': {
    name: 'Marshfield',
    postalCode: '05658',
  },
  'marshfield-wi': {
    name: 'Marshfield',
    postalCode: '54404',
  },
  'marshville-nc': {
    name: 'Marshville',
    postalCode: '28103',
  },
  'marsing-id': {
    name: 'Marsing',
    postalCode: '83639',
  },
  'marsland-ne': {
    name: 'Marsland',
    postalCode: '69354',
  },
  'marsteller-pa': {
    name: 'Marsteller',
    postalCode: '15760',
  },
  'marston-nc': {
    name: 'Marston',
    postalCode: '28363',
  },
  'marstons-mills-ma': {
    name: 'Marstons Mills',
    postalCode: '02648',
  },
  'mart-tx': {
    name: 'Mart',
    postalCode: '76664',
  },
  'martell-ne': {
    name: 'Martell',
    postalCode: '68404',
  },
  'martelle-ia': {
    name: 'Martelle',
    postalCode: '52305',
  },
  'martensdale-ia': {
    name: 'Martensdale',
    postalCode: '50160',
  },
  'martha-ky': {
    name: 'Martha',
    postalCode: '41159',
  },
  'marthasville-mo': {
    name: 'Marthasville',
    postalCode: '63357',
  },
  'marthaville-la': {
    name: 'Marthaville',
    postalCode: '71450',
  },
  'martin-ga': {
    name: 'Martin',
    postalCode: '30557',
  },
  'martin-ky': {
    name: 'Martin',
    postalCode: '41649',
  },
  'martin-mi': {
    name: 'Martin',
    postalCode: '49070',
  },
  'martin-nd': {
    name: 'Martin',
    postalCode: '58758',
  },
  'martin-oh': {
    name: 'Martin',
    postalCode: '43445',
  },
  'martin-sc': {
    name: 'Martin',
    postalCode: '29836',
  },
  'martin-sd': {
    name: 'Martin',
    postalCode: '57551',
  },
  'martin-tn': {
    name: 'Martin',
    postalCode: '38237',
  },
  'martin-city-mt': {
    name: 'Martin City',
    postalCode: '59926',
  },
  'martindale-tx': {
    name: 'Martindale',
    postalCode: '78655',
  },
  'martinez-ca': {
    name: 'Martinez',
    postalCode: '94553',
  },
  'martins-ferry-oh': {
    name: 'Martins Ferry',
    postalCode: '43935',
  },
  'martinsburg-mo': {
    name: 'Martinsburg',
    postalCode: '65264',
  },
  'martinsburg-oh': {
    name: 'Martinsburg',
    postalCode: '43037',
  },
  'martinsburg-pa': {
    name: 'Martinsburg',
    postalCode: '16662',
  },
  'martinsburg-wv': {
    name: 'Martinsburg',
    postalCode: '25401',
  },
  'martinsdale-mt': {
    name: 'Martinsdale',
    postalCode: '59053',
  },
  'martinsville-il': {
    name: 'Martinsville',
    postalCode: '62442',
  },
  'martinsville-in': {
    name: 'Martinsville',
    postalCode: '46151',
  },
  'martinsville-mo': {
    name: 'Martinsville',
    postalCode: '64467',
  },
  'martinsville-nj': {
    name: 'Martinsville',
    postalCode: '08836',
  },
  'martinsville-oh': {
    name: 'Martinsville',
    postalCode: '45146',
  },
  'martinsville-va': {
    name: 'Martinsville',
    postalCode: '24112',
  },
  'martinton-il': {
    name: 'Martinton',
    postalCode: '60951',
  },
  'martville-ny': {
    name: 'Martville',
    postalCode: '13111',
  },
  'marvell-ar': {
    name: 'Marvell',
    postalCode: '72366',
  },
  'marvin-sd': {
    name: 'Marvin',
    postalCode: '57251',
  },
  'mary-alice-ky': {
    name: 'Mary Alice',
    postalCode: '40964',
  },
  'mary-d-pa': {
    name: 'Mary D',
    postalCode: '17952',
  },
  'mary-esther-fl': {
    name: 'Mary Esther',
    postalCode: '32569',
  },
  'marydel-de': {
    name: 'Marydel',
    postalCode: '19964',
  },
  'marydel-md': {
    name: 'Marydel',
    postalCode: '21649',
  },
  'maryland-ny': {
    name: 'Maryland',
    postalCode: '12116',
  },
  'maryland-heights-mo': {
    name: 'Maryland Heights',
    postalCode: '63043',
  },
  'maryneal-tx': {
    name: 'Maryneal',
    postalCode: '79535',
  },
  'marysville-ca': {
    name: 'Marysville',
    postalCode: '95901',
  },
  'marysville-in': {
    name: 'Marysville',
    postalCode: '47141',
  },
  'marysville-ks': {
    name: 'Marysville',
    postalCode: '66508',
  },
  'marysville-mi': {
    name: 'Marysville',
    postalCode: '48040',
  },
  'marysville-mt': {
    name: 'Marysville',
    postalCode: '59640',
  },
  'marysville-oh': {
    name: 'Marysville',
    postalCode: '43040',
  },
  'marysville-pa': {
    name: 'Marysville',
    postalCode: '17053',
  },
  'marysville-wa': {
    name: 'Marysville',
    postalCode: '98270',
  },
  'maryville-il': {
    name: 'Maryville',
    postalCode: '62062',
  },
  'maryville-mo': {
    name: 'Maryville',
    postalCode: '64468',
  },
  'maryville-tn': {
    name: 'Maryville',
    postalCode: '37801',
  },
  'mascot-tn': {
    name: 'Mascot',
    postalCode: '37806',
  },
  'mascot-va': {
    name: 'Mascot',
    postalCode: '23108',
  },
  'mascotte-fl': {
    name: 'Mascotte',
    postalCode: '34753',
  },
  'mascoutah-il': {
    name: 'Mascoutah',
    postalCode: '62258',
  },
  'mashpee-ma': {
    name: 'Mashpee',
    postalCode: '02649',
  },
  'maskell-ne': {
    name: 'Maskell',
    postalCode: '68751',
  },
  'mason-il': {
    name: 'Mason',
    postalCode: '62443',
  },
  'mason-mi': {
    name: 'Mason',
    postalCode: '48854',
  },
  'mason-oh': {
    name: 'Mason',
    postalCode: '45040',
  },
  'mason-tn': {
    name: 'Mason',
    postalCode: '38049',
  },
  'mason-tx': {
    name: 'Mason',
    postalCode: '76856',
  },
  'mason-wi': {
    name: 'Mason',
    postalCode: '54856',
  },
  'mason-wv': {
    name: 'Mason',
    postalCode: '25260',
  },
  'mason-city-ia': {
    name: 'Mason City',
    postalCode: '50401',
  },
  'mason-city-il': {
    name: 'Mason City',
    postalCode: '62664',
  },
  'mason-city-ne': {
    name: 'Mason City',
    postalCode: '68855',
  },
  'masonic-home-ky': {
    name: 'Masonic Home',
    postalCode: '40041',
  },
  'masontown-pa': {
    name: 'Masontown',
    postalCode: '15461',
  },
  'masontown-wv': {
    name: 'Masontown',
    postalCode: '26542',
  },
  'masonville-ia': {
    name: 'Masonville',
    postalCode: '50654',
  },
  'masonville-ny': {
    name: 'Masonville',
    postalCode: '13804',
  },
  'maspeth-ny': {
    name: 'Maspeth',
    postalCode: '11378',
  },
  'mass-city-mi': {
    name: 'Mass City',
    postalCode: '49948',
  },
  'massapequa-ny': {
    name: 'Massapequa',
    postalCode: '11758',
  },
  'massapequa-park-ny': {
    name: 'Massapequa Park',
    postalCode: '11762',
  },
  'massena-ia': {
    name: 'Massena',
    postalCode: '50853',
  },
  'massena-ny': {
    name: 'Massena',
    postalCode: '13662',
  },
  'massey-md': {
    name: 'Massey',
    postalCode: '21650',
  },
  'massillon-oh': {
    name: 'Massillon',
    postalCode: '44646',
  },
  'masterson-tx': {
    name: 'Masterson',
    postalCode: '79058',
  },
  'mastic-ny': {
    name: 'Mastic',
    postalCode: '11950',
  },
  'mastic-beach-ny': {
    name: 'Mastic Beach',
    postalCode: '11951',
  },
  'masury-oh': {
    name: 'Masury',
    postalCode: '44438',
  },
  'matador-tx': {
    name: 'Matador',
    postalCode: '79244',
  },
  'matamoras-pa': {
    name: 'Matamoras',
    postalCode: '18336',
  },
  'matawan-nj': {
    name: 'Matawan',
    postalCode: '07747',
  },
  'matewan-wv': {
    name: 'Matewan',
    postalCode: '25678',
  },
  'matfield-green-ks': {
    name: 'Matfield Green',
    postalCode: '66862',
  },
  'matheny-wv': {
    name: 'Matheny',
    postalCode: '24860',
  },
  'mather-ca': {
    name: 'Mather',
    postalCode: '95655',
  },
  'matheson-co': {
    name: 'Matheson',
    postalCode: '80830',
  },
  'mathews-al': {
    name: 'Mathews',
    postalCode: '36052',
  },
  'mathews-la': {
    name: 'Mathews',
    postalCode: '70375',
  },
  'mathews-va': {
    name: 'Mathews',
    postalCode: '23109',
  },
  'mathias-wv': {
    name: 'Mathias',
    postalCode: '26812',
  },
  'mathis-tx': {
    name: 'Mathis',
    postalCode: '78368',
  },
  'mathiston-ms': {
    name: 'Mathiston',
    postalCode: '39752',
  },
  'matlock-ia': {
    name: 'Matlock',
    postalCode: '51244',
  },
  'matlock-wa': {
    name: 'Matlock',
    postalCode: '98560',
  },
  'matoaka-wv': {
    name: 'Matoaka',
    postalCode: '24736',
  },
  'mattapan-ma': {
    name: 'Mattapan',
    postalCode: '02126',
  },
  'mattapoisett-ma': {
    name: 'Mattapoisett',
    postalCode: '02739',
  },
  'mattaponi-va': {
    name: 'Mattaponi',
    postalCode: '23110',
  },
  'mattawa-wa': {
    name: 'Mattawa',
    postalCode: '99349',
  },
  'mattawamkeag-me': {
    name: 'Mattawamkeag',
    postalCode: '04459',
  },
  'mattawan-mi': {
    name: 'Mattawan',
    postalCode: '49071',
  },
  'matteson-il': {
    name: 'Matteson',
    postalCode: '60443',
  },
  'matthews-ga': {
    name: 'Matthews',
    postalCode: '30818',
  },
  'matthews-mo': {
    name: 'Matthews',
    postalCode: '63867',
  },
  'matthews-nc': {
    name: 'Matthews',
    postalCode: '28104',
  },
  'mattituck-ny': {
    name: 'Mattituck',
    postalCode: '11952',
  },
  'mattoon-il': {
    name: 'Mattoon',
    postalCode: '61938',
  },
  'mauckport-in': {
    name: 'Mauckport',
    postalCode: '47142',
  },
  'maud-ok': {
    name: 'Maud',
    postalCode: '74854',
  },
  'maud-tx': {
    name: 'Maud',
    postalCode: '75567',
  },
  'maugansville-md': {
    name: 'Maugansville',
    postalCode: '21767',
  },
  'mauk-ga': {
    name: 'Mauk',
    postalCode: '31058',
  },
  'mauldin-sc': {
    name: 'Mauldin',
    postalCode: '29662',
  },
  'maumee-oh': {
    name: 'Maumee',
    postalCode: '43537',
  },
  'maumelle-ar': {
    name: 'Maumelle',
    postalCode: '72113',
  },
  'maunabo-pr': {
    name: 'Maunabo',
    postalCode: '00707',
  },
  'maupin-or': {
    name: 'Maupin',
    postalCode: '97037',
  },
  'maurepas-la': {
    name: 'Maurepas',
    postalCode: '70449',
  },
  'maurertown-va': {
    name: 'Maurertown',
    postalCode: '22644',
  },
  'maurice-ia': {
    name: 'Maurice',
    postalCode: '51036',
  },
  'maurice-la': {
    name: 'Maurice',
    postalCode: '70555',
  },
  'mauston-wi': {
    name: 'Mauston',
    postalCode: '53948',
  },
  'max-mn': {
    name: 'Max',
    postalCode: '56659',
  },
  'max-nd': {
    name: 'Max',
    postalCode: '58759',
  },
  'max-ne': {
    name: 'Max',
    postalCode: '69037',
  },
  'max-meadows-va': {
    name: 'Max Meadows',
    postalCode: '24360',
  },
  'maxbass-nd': {
    name: 'Maxbass',
    postalCode: '58760',
  },
  'maxie-va': {
    name: 'Maxie',
    postalCode: '24628',
  },
  'maxton-nc': {
    name: 'Maxton',
    postalCode: '28364',
  },
  'maxwell-ia': {
    name: 'Maxwell',
    postalCode: '50161',
  },
  'maxwell-ne': {
    name: 'Maxwell',
    postalCode: '69151',
  },
  'maxwell-nm': {
    name: 'Maxwell',
    postalCode: '87728',
  },
  'maxwell-tx': {
    name: 'Maxwell',
    postalCode: '78656',
  },
  'maxwelton-wv': {
    name: 'Maxwelton',
    postalCode: '24957',
  },
  'may-id': {
    name: 'May',
    postalCode: '83253',
  },
  'may-ok': {
    name: 'May',
    postalCode: '73851',
  },
  'may-tx': {
    name: 'May',
    postalCode: '76857',
  },
  'mayaguez-pr': {
    name: 'Mayaguez',
    postalCode: '00680',
  },
  'maybee-mi': {
    name: 'Maybee',
    postalCode: '48159',
  },
  'maybell-co': {
    name: 'Maybell',
    postalCode: '81640',
  },
  'maybrook-ny': {
    name: 'Maybrook',
    postalCode: '12543',
  },
  'mayer-az': {
    name: 'Mayer',
    postalCode: '86333',
  },
  'mayer-mn': {
    name: 'Mayer',
    postalCode: '55360',
  },
  'mayesville-sc': {
    name: 'Mayesville',
    postalCode: '29104',
  },
  'mayetta-ks': {
    name: 'Mayetta',
    postalCode: '66509',
  },
  'mayfield-ks': {
    name: 'Mayfield',
    postalCode: '67103',
  },
  'mayfield-ky': {
    name: 'Mayfield',
    postalCode: '42066',
  },
  'mayfield-ny': {
    name: 'Mayfield',
    postalCode: '12117',
  },
  'mayflower-ar': {
    name: 'Mayflower',
    postalCode: '72106',
  },
  'mayhill-nm': {
    name: 'Mayhill',
    postalCode: '88339',
  },
  'mayking-ky': {
    name: 'Mayking',
    postalCode: '41837',
  },
  'maynard-ar': {
    name: 'Maynard',
    postalCode: '72444',
  },
  'maynard-ia': {
    name: 'Maynard',
    postalCode: '50655',
  },
  'maynard-ma': {
    name: 'Maynard',
    postalCode: '01754',
  },
  'maynard-mn': {
    name: 'Maynard',
    postalCode: '56260',
  },
  'maynardville-tn': {
    name: 'Maynardville',
    postalCode: '37807',
  },
  'mayo-fl': {
    name: 'Mayo',
    postalCode: '32066',
  },
  'mayodan-nc': {
    name: 'Mayodan',
    postalCode: '27027',
  },
  'maypearl-tx': {
    name: 'Maypearl',
    postalCode: '76064',
  },
  'mayport-pa': {
    name: 'Mayport',
    postalCode: '16240',
  },
  'mays-landing-nj': {
    name: 'Mays Landing',
    postalCode: '08330',
  },
  'maysel-wv': {
    name: 'Maysel',
    postalCode: '25133',
  },
  'mayslick-ky': {
    name: 'Mayslick',
    postalCode: '41055',
  },
  'maysville-ar': {
    name: 'Maysville',
    postalCode: '72747',
  },
  'maysville-ga': {
    name: 'Maysville',
    postalCode: '30558',
  },
  'maysville-ky': {
    name: 'Maysville',
    postalCode: '41056',
  },
  'maysville-mo': {
    name: 'Maysville',
    postalCode: '64447',
  },
  'maysville-nc': {
    name: 'Maysville',
    postalCode: '28555',
  },
  'maysville-ok': {
    name: 'Maysville',
    postalCode: '73057',
  },
  'maysville-wv': {
    name: 'Maysville',
    postalCode: '26833',
  },
  'mayview-mo': {
    name: 'Mayview',
    postalCode: '64071',
  },
  'mayville-mi': {
    name: 'Mayville',
    postalCode: '48744',
  },
  'mayville-nd': {
    name: 'Mayville',
    postalCode: '58257',
  },
  'mayville-ny': {
    name: 'Mayville',
    postalCode: '14757',
  },
  'mayville-wi': {
    name: 'Mayville',
    postalCode: '53050',
  },
  'maywood-ca': {
    name: 'Maywood',
    postalCode: '90270',
  },
  'maywood-il': {
    name: 'Maywood',
    postalCode: '60153',
  },
  'maywood-mo': {
    name: 'Maywood',
    postalCode: '63454',
  },
  'maywood-ne': {
    name: 'Maywood',
    postalCode: '69038',
  },
  'maywood-nj': {
    name: 'Maywood',
    postalCode: '07607',
  },
  'mazama-wa': {
    name: 'Mazama',
    postalCode: '98833',
  },
  'mazeppa-mn': {
    name: 'Mazeppa',
    postalCode: '55956',
  },
  'mazie-ky': {
    name: 'Mazie',
    postalCode: '41160',
  },
  'mazomanie-wi': {
    name: 'Mazomanie',
    postalCode: '53560',
  },
  'mazon-il': {
    name: 'Mazon',
    postalCode: '60444',
  },
  'mc-adenville-nc': {
    name: 'Mc Adenville',
    postalCode: '28101',
  },
  'mc-alisterville-pa': {
    name: 'Mc Alisterville',
    postalCode: '17049',
  },
  'mc-alpin-fl': {
    name: 'Mc Alpin',
    postalCode: '32062',
  },
  'mc-andrews-ky': {
    name: 'Mc Andrews',
    postalCode: '41543',
  },
  'mc-arthur-oh': {
    name: 'Mc Arthur',
    postalCode: '45651',
  },
  'mc-bain-mi': {
    name: 'Mc Bain',
    postalCode: '49657',
  },
  'mc-bee-sc': {
    name: 'Mc Bee',
    postalCode: '29101',
  },
  'mc-call-creek-ms': {
    name: 'Mc Call Creek',
    postalCode: '39647',
  },
  'mc-calla-al': {
    name: 'Mc Calla',
    postalCode: '35111',
  },
  'mc-callsburg-ia': {
    name: 'Mc Callsburg',
    postalCode: '50154',
  },
  'mc-camey-tx': {
    name: 'Mc Camey',
    postalCode: '79752',
  },
  'mc-carley-ms': {
    name: 'Mc Carley',
    postalCode: '38943',
  },
  'mc-carr-ky': {
    name: 'Mc Carr',
    postalCode: '41544',
  },
  'mc-caskill-ar': {
    name: 'Mc Caskill',
    postalCode: '71847',
  },
  'mc-caulley-tx': {
    name: 'Mc Caulley',
    postalCode: '79534',
  },
  'mc-caysville-ga': {
    name: 'Mc Caysville',
    postalCode: '30555',
  },
  'mc-clave-co': {
    name: 'Mc Clave',
    postalCode: '81057',
  },
  'mc-clelland-ia': {
    name: 'Mc Clelland',
    postalCode: '51548',
  },
  'mc-clellandtown-pa': {
    name: 'Mc Clellandtown',
    postalCode: '15458',
  },
  'mc-clellanville-sc': {
    name: 'Mc Clellanville',
    postalCode: '29458',
  },
  'mc-clure-il': {
    name: 'Mc Clure',
    postalCode: '62957',
  },
  'mc-clure-oh': {
    name: 'Mc Clure',
    postalCode: '43534',
  },
  'mc-clure-pa': {
    name: 'Mc Clure',
    postalCode: '17841',
  },
  'mc-clure-va': {
    name: 'Mc Clure',
    postalCode: '24269',
  },
  'mc-clurg-mo': {
    name: 'Mc Clurg',
    postalCode: '65701',
  },
  'mc-coll-sc': {
    name: 'Mc Coll',
    postalCode: '29570',
  },
  'mc-comb-oh': {
    name: 'Mc Comb',
    postalCode: '45858',
  },
  'mc-connell-il': {
    name: 'Mc Connell',
    postalCode: '61050',
  },
  'mc-connells-sc': {
    name: 'Mc Connells',
    postalCode: '29726',
  },
  'mc-connellsburg-pa': {
    name: 'Mc Connellsburg',
    postalCode: '17233',
  },
  'mc-cook-ne': {
    name: 'Mc Cook',
    postalCode: '69001',
  },
  'mc-cool-ms': {
    name: 'Mc Cool',
    postalCode: '39108',
  },
  'mc-cool-junction-ne': {
    name: 'Mc Cool Junction',
    postalCode: '68401',
  },
  'mc-cormick-sc': {
    name: 'Mc Cormick',
    postalCode: '29835',
  },
  'mc-coy-co': {
    name: 'Mc Coy',
    postalCode: '80463',
  },
  'mc-cracken-ks': {
    name: 'Mc Cracken',
    postalCode: '67556',
  },
  'mc-crory-ar': {
    name: 'Mc Crory',
    postalCode: '72101',
  },
  'mc-cune-ks': {
    name: 'Mc Cune',
    postalCode: '66753',
  },
  'mc-cutchenville-oh': {
    name: 'Mc Cutchenville',
    postalCode: '44844',
  },
  'mc-dade-tx': {
    name: 'Mc Dade',
    postalCode: '78650',
  },
  'mc-daniels-ky': {
    name: 'Mc Daniels',
    postalCode: '40152',
  },
  'mc-david-fl': {
    name: 'Mc David',
    postalCode: '32568',
  },
  'mc-dermott-oh': {
    name: 'Mc Dermott',
    postalCode: '45652',
  },
  'mc-donald-ks': {
    name: 'Mc Donald',
    postalCode: '67745',
  },
  'mc-donald-oh': {
    name: 'Mc Donald',
    postalCode: '44437',
  },
  'mc-donald-pa': {
    name: 'Mc Donald',
    postalCode: '15057',
  },
  'mc-donald-tn': {
    name: 'Mc Donald',
    postalCode: '37353',
  },
  'mc-donough-ny': {
    name: 'Mc Donough',
    postalCode: '13801',
  },
  'mc-dowell-ky': {
    name: 'Mc Dowell',
    postalCode: '41647',
  },
  'mc-dowell-va': {
    name: 'Mc Dowell',
    postalCode: '24458',
  },
  'mc-ewen-tn': {
    name: 'Mc Ewen',
    postalCode: '37101',
  },
  'mc-fall-mo': {
    name: 'Mc Fall',
    postalCode: '64657',
  },
  'mc-farland-ca': {
    name: 'Mc Farland',
    postalCode: '93250',
  },
  'mc-gaheysville-va': {
    name: 'Mc Gaheysville',
    postalCode: '22840',
  },
  'mc-gee-mo': {
    name: 'Mc Gee',
    postalCode: '63763',
  },
  'mc-gehee-ar': {
    name: 'Mc Gehee',
    postalCode: '71654',
  },
  'mc-grady-nc': {
    name: 'Mc Grady',
    postalCode: '28649',
  },
  'mc-grath-mn': {
    name: 'Mc Grath',
    postalCode: '56350',
  },
  'mc-graw-ny': {
    name: 'Mc Graw',
    postalCode: '13101',
  },
  'mc-gregor-ia': {
    name: 'Mc Gregor',
    postalCode: '52157',
  },
  'mc-gregor-tx': {
    name: 'Mc Gregor',
    postalCode: '76657',
  },
  'mc-henry-ky': {
    name: 'Mc Henry',
    postalCode: '42354',
  },
  'mc-henry-md': {
    name: 'Mc Henry',
    postalCode: '21541',
  },
  'mc-henry-ms': {
    name: 'Mc Henry',
    postalCode: '39561',
  },
  'mc-intire-ia': {
    name: 'Mc Intire',
    postalCode: '50455',
  },
  'mc-intosh-al': {
    name: 'Mc Intosh',
    postalCode: '36553',
  },
  'mc-intosh-sd': {
    name: 'Mc Intosh',
    postalCode: '57641',
  },
  'mc-intyre-ga': {
    name: 'Mc Intyre',
    postalCode: '31054',
  },
  'mc-kean-pa': {
    name: 'Mc Kean',
    postalCode: '16426',
  },
  'mc-kee-ky': {
    name: 'Mc Kee',
    postalCode: '40447',
  },
  'mc-kees-rocks-pa': {
    name: 'Mc Kees Rocks',
    postalCode: '15136',
  },
  'mc-kenney-va': {
    name: 'Mc Kenney',
    postalCode: '23872',
  },
  'mc-kenzie-al': {
    name: 'Mc Kenzie',
    postalCode: '36456',
  },
  'mc-kenzie-tn': {
    name: 'Mc Kenzie',
    postalCode: '38201',
  },
  'mc-kittrick-ca': {
    name: 'Mc Kittrick',
    postalCode: '93251',
  },
  'mc-lain-ms': {
    name: 'Mc Lain',
    postalCode: '39456',
  },
  'mc-laughlin-sd': {
    name: 'Mc Laughlin',
    postalCode: '57642',
  },
  'mc-lean-il': {
    name: 'Mc Lean',
    postalCode: '61754',
  },
  'mc-lean-va': {
    name: 'Mc Lean',
    postalCode: '22101',
  },
  'mc-leansboro-il': {
    name: 'Mc Leansboro',
    postalCode: '62859',
  },
  'mc-leansville-nc': {
    name: 'Mc Leansville',
    postalCode: '27301',
  },
  'mc-leod-mt': {
    name: 'Mc Leod',
    postalCode: '59052',
  },
  'mc-louth-ks': {
    name: 'Mc Louth',
    postalCode: '66054',
  },
  'mc-millan-mi': {
    name: 'Mc Millan',
    postalCode: '49853',
  },
  'mc-nabb-il': {
    name: 'Mc Nabb',
    postalCode: '61335',
  },
  'mc-neal-az': {
    name: 'Mc Neal',
    postalCode: '85617',
  },
  'mc-neil-ar': {
    name: 'Mc Neil',
    postalCode: '71752',
  },
  'mc-queeney-tx': {
    name: 'Mc Queeney',
    postalCode: '78123',
  },
  'mc-rae-ar': {
    name: 'Mc Rae',
    postalCode: '72102',
  },
  'mc-rae-helena-ga': {
    name: 'Mc Rae Helena',
    postalCode: '31037',
  },
  'mc-roberts-ky': {
    name: 'Mc Roberts',
    postalCode: '41835',
  },
  'mc-sherrystown-pa': {
    name: 'Mc Sherrystown',
    postalCode: '17344',
  },
  'mc-veytown-pa': {
    name: 'Mc Veytown',
    postalCode: '17051',
  },
  'mcadoo-pa': {
    name: 'Mcadoo',
    postalCode: '18237',
  },
  'mcadoo-tx': {
    name: 'Mcadoo',
    postalCode: '79243',
  },
  'mcalester-ok': {
    name: 'Mcalester',
    postalCode: '74501',
  },
  'mcalister-nm': {
    name: 'Mcalister',
    postalCode: '88427',
  },
  'mcallen-tx': {
    name: 'Mcallen',
    postalCode: '78501',
  },
  'mcarthur-ca': {
    name: 'Mcarthur',
    postalCode: '96056',
  },
  'mcbh-kaneohe-bay-hi': {
    name: 'Mcbh Kaneohe Bay',
    postalCode: '96863',
  },
  'mccall-id': {
    name: 'Mccall',
    postalCode: '83638',
  },
  'mccammon-id': {
    name: 'Mccammon',
    postalCode: '83250',
  },
  'mccleary-wa': {
    name: 'Mccleary',
    postalCode: '98557',
  },
  'mcclellan-ca': {
    name: 'Mcclellan',
    postalCode: '95652',
  },
  'mccloud-ca': {
    name: 'Mccloud',
    postalCode: '96057',
  },
  'mcclusky-nd': {
    name: 'Mcclusky',
    postalCode: '58463',
  },
  'mccomb-ms': {
    name: 'Mccomb',
    postalCode: '39648',
  },
  'mcconnell-afb-ks': {
    name: 'Mcconnell AFB',
    postalCode: '67221',
  },
  'mcconnelsville-oh': {
    name: 'Mcconnelsville',
    postalCode: '43756',
  },
  'mccordsville-in': {
    name: 'Mccordsville',
    postalCode: '46055',
  },
  'mccurtain-ok': {
    name: 'Mccurtain',
    postalCode: '74944',
  },
  'mccutcheon-field-nc': {
    name: 'Mccutcheon Field',
    postalCode: '28545',
  },
  'mcdaniel-md': {
    name: 'Mcdaniel',
    postalCode: '21647',
  },
  'mcdonough-ga': {
    name: 'Mcdonough',
    postalCode: '30252',
  },
  'mcfarland-wi': {
    name: 'Mcfarland',
    postalCode: '53558',
  },
  'mcgregor-mn': {
    name: 'Mcgregor',
    postalCode: '55760',
  },
  'mcgregor-nd': {
    name: 'Mcgregor',
    postalCode: '58755',
  },
  'mchenry-il': {
    name: 'Mchenry',
    postalCode: '60050',
  },
  'mchenry-nd': {
    name: 'Mchenry',
    postalCode: '58464',
  },
  'mcintosh-mn': {
    name: 'Mcintosh',
    postalCode: '56556',
  },
  'mckeesport-pa': {
    name: 'Mckeesport',
    postalCode: '15131',
  },
  'mckinleyville-ca': {
    name: 'Mckinleyville',
    postalCode: '95519',
  },
  'mckinney-tx': {
    name: 'Mckinney',
    postalCode: '75069',
  },
  'mclean-ne': {
    name: 'Mclean',
    postalCode: '68747',
  },
  'mclean-tx': {
    name: 'Mclean',
    postalCode: '79057',
  },
  'mcleod-nd': {
    name: 'Mcleod',
    postalCode: '58057',
  },
  'mcloud-ok': {
    name: 'Mcloud',
    postalCode: '74851',
  },
  'mcmechen-wv': {
    name: 'Mcmechen',
    postalCode: '26040',
  },
  'mcminnville-or': {
    name: 'Mcminnville',
    postalCode: '97128',
  },
  'mcminnville-tn': {
    name: 'Mcminnville',
    postalCode: '37110',
  },
  'mcpherson-ks': {
    name: 'Mcpherson',
    postalCode: '67460',
  },
  'mcville-nd': {
    name: 'Mcville',
    postalCode: '58254',
  },
  'mead-co': {
    name: 'Mead',
    postalCode: '80542',
  },
  'mead-ne': {
    name: 'Mead',
    postalCode: '68041',
  },
  'mead-ok': {
    name: 'Mead',
    postalCode: '73449',
  },
  'mead-wa': {
    name: 'Mead',
    postalCode: '99021',
  },
  'meade-ks': {
    name: 'Meade',
    postalCode: '67864',
  },
  'meadow-sd': {
    name: 'Meadow',
    postalCode: '57644',
  },
  'meadow-tx': {
    name: 'Meadow',
    postalCode: '79345',
  },
  'meadow-bridge-wv': {
    name: 'Meadow Bridge',
    postalCode: '25976',
  },
  'meadow-creek-wv': {
    name: 'Meadow Creek',
    postalCode: '25977',
  },
  'meadow-grove-ne': {
    name: 'Meadow Grove',
    postalCode: '68752',
  },
  'meadow-vista-ca': {
    name: 'Meadow Vista',
    postalCode: '95722',
  },
  'meadowbrook-wv': {
    name: 'Meadowbrook',
    postalCode: '26404',
  },
  'meadowlands-mn': {
    name: 'Meadowlands',
    postalCode: '55765',
  },
  'meadows-of-dan-va': {
    name: 'Meadows Of Dan',
    postalCode: '24120',
  },
  'meadowview-va': {
    name: 'Meadowview',
    postalCode: '24361',
  },
  'meadview-az': {
    name: 'Meadview',
    postalCode: '86444',
  },
  'meadville-mo': {
    name: 'Meadville',
    postalCode: '64659',
  },
  'meadville-ms': {
    name: 'Meadville',
    postalCode: '39653',
  },
  'meadville-pa': {
    name: 'Meadville',
    postalCode: '16335',
  },
  'meally-ky': {
    name: 'Meally',
    postalCode: '41234',
  },
  'means-ky': {
    name: 'Means',
    postalCode: '40346',
  },
  'meansville-ga': {
    name: 'Meansville',
    postalCode: '30256',
  },
  'mears-mi': {
    name: 'Mears',
    postalCode: '49436',
  },
  'mears-va': {
    name: 'Mears',
    postalCode: '23409',
  },
  'mebane-nc': {
    name: 'Mebane',
    postalCode: '27302',
  },
  'mecca-ca': {
    name: 'Mecca',
    postalCode: '92254',
  },
  'mechanic-falls-me': {
    name: 'Mechanic Falls',
    postalCode: '04256',
  },
  'mechanicsburg-il': {
    name: 'Mechanicsburg',
    postalCode: '62545',
  },
  'mechanicsburg-oh': {
    name: 'Mechanicsburg',
    postalCode: '43044',
  },
  'mechanicsburg-pa': {
    name: 'Mechanicsburg',
    postalCode: '17050',
  },
  'mechanicstown-oh': {
    name: 'Mechanicstown',
    postalCode: '44651',
  },
  'mechanicsville-ia': {
    name: 'Mechanicsville',
    postalCode: '52306',
  },
  'mechanicsville-md': {
    name: 'Mechanicsville',
    postalCode: '20659',
  },
  'mechanicsville-pa': {
    name: 'Mechanicsville',
    postalCode: '18934',
  },
  'mechanicsville-va': {
    name: 'Mechanicsville',
    postalCode: '23111',
  },
  'mechanicville-ny': {
    name: 'Mechanicville',
    postalCode: '12118',
  },
  'mecosta-mi': {
    name: 'Mecosta',
    postalCode: '49332',
  },
  'medaryville-in': {
    name: 'Medaryville',
    postalCode: '47957',
  },
  'meddybemps-me': {
    name: 'Meddybemps',
    postalCode: '04657',
  },
  'medfield-ma': {
    name: 'Medfield',
    postalCode: '02052',
  },
  'medford-ma': {
    name: 'Medford',
    postalCode: '02155',
  },
  'medford-mn': {
    name: 'Medford',
    postalCode: '55049',
  },
  'medford-nj': {
    name: 'Medford',
    postalCode: '08055',
  },
  'medford-ny': {
    name: 'Medford',
    postalCode: '11763',
  },
  'medford-ok': {
    name: 'Medford',
    postalCode: '73759',
  },
  'medford-or': {
    name: 'Medford',
    postalCode: '97501',
  },
  'medford-wi': {
    name: 'Medford',
    postalCode: '54451',
  },
  'media-il': {
    name: 'Media',
    postalCode: '61460',
  },
  'media-pa': {
    name: 'Media',
    postalCode: '19063',
  },
  'mediapolis-ia': {
    name: 'Mediapolis',
    postalCode: '52637',
  },
  'medical-lake-wa': {
    name: 'Medical Lake',
    postalCode: '99022',
  },
  'medicine-bow-wy': {
    name: 'Medicine Bow',
    postalCode: '82329',
  },
  'medicine-lake-mt': {
    name: 'Medicine Lake',
    postalCode: '59247',
  },
  'medicine-lodge-ks': {
    name: 'Medicine Lodge',
    postalCode: '67104',
  },
  'medimont-id': {
    name: 'Medimont',
    postalCode: '83842',
  },
  'medina-nd': {
    name: 'Medina',
    postalCode: '58467',
  },
  'medina-ny': {
    name: 'Medina',
    postalCode: '14103',
  },
  'medina-oh': {
    name: 'Medina',
    postalCode: '44256',
  },
  'medina-tn': {
    name: 'Medina',
    postalCode: '38355',
  },
  'medina-tx': {
    name: 'Medina',
    postalCode: '78055',
  },
  'medina-wa': {
    name: 'Medina',
    postalCode: '98039',
  },
  'medinah-il': {
    name: 'Medinah',
    postalCode: '60157',
  },
  'medon-tn': {
    name: 'Medon',
    postalCode: '38356',
  },
  'medora-il': {
    name: 'Medora',
    postalCode: '62063',
  },
  'medora-in': {
    name: 'Medora',
    postalCode: '47260',
  },
  'medora-nd': {
    name: 'Medora',
    postalCode: '58645',
  },
  'medusa-ny': {
    name: 'Medusa',
    postalCode: '12120',
  },
  'medway-ma': {
    name: 'Medway',
    postalCode: '02053',
  },
  'medway-me': {
    name: 'Medway',
    postalCode: '04460',
  },
  'medway-oh': {
    name: 'Medway',
    postalCode: '45341',
  },
  'meeker-co': {
    name: 'Meeker',
    postalCode: '81641',
  },
  'meeker-ok': {
    name: 'Meeker',
    postalCode: '74855',
  },
  'meeteetse-wy': {
    name: 'Meeteetse',
    postalCode: '82433',
  },
  'meherrin-va': {
    name: 'Meherrin',
    postalCode: '23954',
  },
  'mehoopany-pa': {
    name: 'Mehoopany',
    postalCode: '18629',
  },
  'meigs-ga': {
    name: 'Meigs',
    postalCode: '31765',
  },
  'mekinock-nd': {
    name: 'Mekinock',
    postalCode: '58258',
  },
  'melba-id': {
    name: 'Melba',
    postalCode: '83641',
  },
  'melber-ky': {
    name: 'Melber',
    postalCode: '42069',
  },
  'melbourne-ar': {
    name: 'Melbourne',
    postalCode: '72556',
  },
  'melbourne-fl': {
    name: 'Melbourne',
    postalCode: '32901',
  },
  'melbourne-ia': {
    name: 'Melbourne',
    postalCode: '50162',
  },
  'melbourne-ky': {
    name: 'Melbourne',
    postalCode: '41059',
  },
  'melbourne-beach-fl': {
    name: 'Melbourne Beach',
    postalCode: '32951',
  },
  'melcher-dallas-ia': {
    name: 'Melcher Dallas',
    postalCode: '50062',
  },
  'melcroft-pa': {
    name: 'Melcroft',
    postalCode: '15462',
  },
  'melfa-va': {
    name: 'Melfa',
    postalCode: '23410',
  },
  'melissa-tx': {
    name: 'Melissa',
    postalCode: '75454',
  },
  'mellen-wi': {
    name: 'Mellen',
    postalCode: '54546',
  },
  'mellette-sd': {
    name: 'Mellette',
    postalCode: '57461',
  },
  'melrose-fl': {
    name: 'Melrose',
    postalCode: '32666',
  },
  'melrose-ia': {
    name: 'Melrose',
    postalCode: '52569',
  },
  'melrose-la': {
    name: 'Melrose',
    postalCode: '71452',
  },
  'melrose-ma': {
    name: 'Melrose',
    postalCode: '02176',
  },
  'melrose-mn': {
    name: 'Melrose',
    postalCode: '56352',
  },
  'melrose-nm': {
    name: 'Melrose',
    postalCode: '88124',
  },
  'melrose-ny': {
    name: 'Melrose',
    postalCode: '12121',
  },
  'melrose-wi': {
    name: 'Melrose',
    postalCode: '54642',
  },
  'melrose-park-il': {
    name: 'Melrose Park',
    postalCode: '60160',
  },
  'melvern-ks': {
    name: 'Melvern',
    postalCode: '66510',
  },
  'melville-la': {
    name: 'Melville',
    postalCode: '71353',
  },
  'melville-mt': {
    name: 'Melville',
    postalCode: '59055',
  },
  'melville-ny': {
    name: 'Melville',
    postalCode: '11747',
  },
  'melvin-al': {
    name: 'Melvin',
    postalCode: '36913',
  },
  'melvin-ia': {
    name: 'Melvin',
    postalCode: '51350',
  },
  'melvin-il': {
    name: 'Melvin',
    postalCode: '60952',
  },
  'melvin-ky': {
    name: 'Melvin',
    postalCode: '41650',
  },
  'melvin-mi': {
    name: 'Melvin',
    postalCode: '48454',
  },
  'melvin-tx': {
    name: 'Melvin',
    postalCode: '76858',
  },
  'melvindale-mi': {
    name: 'Melvindale',
    postalCode: '48122',
  },
  'memphis-in': {
    name: 'Memphis',
    postalCode: '47143',
  },
  'memphis-mi': {
    name: 'Memphis',
    postalCode: '48041',
  },
  'memphis-mo': {
    name: 'Memphis',
    postalCode: '63555',
  },
  'memphis-ne': {
    name: 'Memphis',
    postalCode: '68042',
  },
  'memphis-ny': {
    name: 'Memphis',
    postalCode: '13112',
  },
  'memphis-tn': {
    name: 'Memphis',
    postalCode: '38103',
  },
  'memphis-tx': {
    name: 'Memphis',
    postalCode: '79245',
  },
  'memphis-stc-ms': {
    name: 'Memphis Stc',
    postalCode: '38675',
  },
  'mena-ar': {
    name: 'Mena',
    postalCode: '71953',
  },
  'menahga-mn': {
    name: 'Menahga',
    postalCode: '56464',
  },
  'menan-id': {
    name: 'Menan',
    postalCode: '83434',
  },
  'menard-tx': {
    name: 'Menard',
    postalCode: '76859',
  },
  'menasha-wi': {
    name: 'Menasha',
    postalCode: '54952',
  },
  'mendenhall-ms': {
    name: 'Mendenhall',
    postalCode: '39114',
  },
  'mendham-nj': {
    name: 'Mendham',
    postalCode: '07945',
  },
  'mendocino-ca': {
    name: 'Mendocino',
    postalCode: '95460',
  },
  'mendon-il': {
    name: 'Mendon',
    postalCode: '62351',
  },
  'mendon-ma': {
    name: 'Mendon',
    postalCode: '01756',
  },
  'mendon-mi': {
    name: 'Mendon',
    postalCode: '49072',
  },
  'mendon-mo': {
    name: 'Mendon',
    postalCode: '64660',
  },
  'mendon-ny': {
    name: 'Mendon',
    postalCode: '14506',
  },
  'mendon-oh': {
    name: 'Mendon',
    postalCode: '45862',
  },
  'mendon-ut': {
    name: 'Mendon',
    postalCode: '84325',
  },
  'mendota-ca': {
    name: 'Mendota',
    postalCode: '93640',
  },
  'mendota-il': {
    name: 'Mendota',
    postalCode: '61342',
  },
  'mendota-va': {
    name: 'Mendota',
    postalCode: '24270',
  },
  'menifee-ca': {
    name: 'Menifee',
    postalCode: '92584',
  },
  'menlo-ga': {
    name: 'Menlo',
    postalCode: '30731',
  },
  'menlo-ia': {
    name: 'Menlo',
    postalCode: '50164',
  },
  'menlo-park-ca': {
    name: 'Menlo Park',
    postalCode: '94025',
  },
  'menno-sd': {
    name: 'Menno',
    postalCode: '57045',
  },
  'meno-ok': {
    name: 'Meno',
    postalCode: '73760',
  },
  'menoken-nd': {
    name: 'Menoken',
    postalCode: '58558',
  },
  'menominee-mi': {
    name: 'Menominee',
    postalCode: '49858',
  },
  'menomonee-falls-wi': {
    name: 'Menomonee Falls',
    postalCode: '53051',
  },
  'menomonie-wi': {
    name: 'Menomonie',
    postalCode: '54751',
  },
  'mentcle-pa': {
    name: 'Mentcle',
    postalCode: '15761',
  },
  'mentone-al': {
    name: 'Mentone',
    postalCode: '35984',
  },
  'mentone-ca': {
    name: 'Mentone',
    postalCode: '92359',
  },
  'mentone-in': {
    name: 'Mentone',
    postalCode: '46539',
  },
  'mentor-mn': {
    name: 'Mentor',
    postalCode: '56736',
  },
  'mentor-oh': {
    name: 'Mentor',
    postalCode: '44060',
  },
  'mequon-wi': {
    name: 'Mequon',
    postalCode: '53092',
  },
  'mer-rouge-la': {
    name: 'Mer Rouge',
    postalCode: '71261',
  },
  'meraux-la': {
    name: 'Meraux',
    postalCode: '70075',
  },
  'merced-ca': {
    name: 'Merced',
    postalCode: '95340',
  },
  'mercedes-tx': {
    name: 'Mercedes',
    postalCode: '78570',
  },
  'mercedita-pr': {
    name: 'Mercedita',
    postalCode: '00715',
  },
  'mercer-mo': {
    name: 'Mercer',
    postalCode: '64661',
  },
  'mercer-nd': {
    name: 'Mercer',
    postalCode: '58559',
  },
  'mercer-pa': {
    name: 'Mercer',
    postalCode: '16137',
  },
  'mercer-tn': {
    name: 'Mercer',
    postalCode: '38392',
  },
  'mercer-wi': {
    name: 'Mercer',
    postalCode: '54547',
  },
  'mercer-island-wa': {
    name: 'Mercer Island',
    postalCode: '98040',
  },
  'mercersburg-pa': {
    name: 'Mercersburg',
    postalCode: '17236',
  },
  'merchantville-nj': {
    name: 'Merchantville',
    postalCode: '08109',
  },
  'meredith-co': {
    name: 'Meredith',
    postalCode: '81642',
  },
  'meredith-nh': {
    name: 'Meredith',
    postalCode: '03253',
  },
  'meredosia-il': {
    name: 'Meredosia',
    postalCode: '62665',
  },
  'mereta-tx': {
    name: 'Mereta',
    postalCode: '76940',
  },
  'meridale-ny': {
    name: 'Meridale',
    postalCode: '13806',
  },
  'meriden-ct': {
    name: 'Meriden',
    postalCode: '06450',
  },
  'meriden-ia': {
    name: 'Meriden',
    postalCode: '51037',
  },
  'meriden-ks': {
    name: 'Meriden',
    postalCode: '66512',
  },
  'meriden-nh': {
    name: 'Meriden',
    postalCode: '03770',
  },
  'meriden-wy': {
    name: 'Meriden',
    postalCode: '82081',
  },
  'meridian-ca': {
    name: 'Meridian',
    postalCode: '95957',
  },
  'meridian-id': {
    name: 'Meridian',
    postalCode: '83642',
  },
  'meridian-ms': {
    name: 'Meridian',
    postalCode: '39301',
  },
  'meridian-ok': {
    name: 'Meridian',
    postalCode: '73058',
  },
  'meridian-tx': {
    name: 'Meridian',
    postalCode: '76665',
  },
  'meridianville-al': {
    name: 'Meridianville',
    postalCode: '35759',
  },
  'merigold-ms': {
    name: 'Merigold',
    postalCode: '38759',
  },
  'merino-co': {
    name: 'Merino',
    postalCode: '80741',
  },
  'merion-station-pa': {
    name: 'Merion Station',
    postalCode: '19066',
  },
  'merkel-tx': {
    name: 'Merkel',
    postalCode: '79536',
  },
  'merlin-or': {
    name: 'Merlin',
    postalCode: '97532',
  },
  'merna-ne': {
    name: 'Merna',
    postalCode: '68856',
  },
  'merom-in': {
    name: 'Merom',
    postalCode: '47861',
  },
  'merrick-ny': {
    name: 'Merrick',
    postalCode: '11566',
  },
  'merrifield-mn': {
    name: 'Merrifield',
    postalCode: '56465',
  },
  'merrifield-va': {
    name: 'Merrifield',
    postalCode: '22081',
  },
  'merrill-ia': {
    name: 'Merrill',
    postalCode: '51038',
  },
  'merrill-mi': {
    name: 'Merrill',
    postalCode: '48637',
  },
  'merrill-or': {
    name: 'Merrill',
    postalCode: '97633',
  },
  'merrill-wi': {
    name: 'Merrill',
    postalCode: '54452',
  },
  'merrillan-wi': {
    name: 'Merrillan',
    postalCode: '54754',
  },
  'merrillville-in': {
    name: 'Merrillville',
    postalCode: '46410',
  },
  'merrimac-ma': {
    name: 'Merrimac',
    postalCode: '01860',
  },
  'merrimac-wi': {
    name: 'Merrimac',
    postalCode: '53561',
  },
  'merrimack-nh': {
    name: 'Merrimack',
    postalCode: '03054',
  },
  'merriman-ne': {
    name: 'Merriman',
    postalCode: '69218',
  },
  'merritt-mi': {
    name: 'Merritt',
    postalCode: '49667',
  },
  'merritt-nc': {
    name: 'Merritt',
    postalCode: '28556',
  },
  'merritt-island-fl': {
    name: 'Merritt Island',
    postalCode: '32952',
  },
  'merry-hill-nc': {
    name: 'Merry Hill',
    postalCode: '27957',
  },
  'merryville-la': {
    name: 'Merryville',
    postalCode: '70653',
  },
  'mershon-ga': {
    name: 'Mershon',
    postalCode: '31551',
  },
  'mertens-tx': {
    name: 'Mertens',
    postalCode: '76666',
  },
  'mertzon-tx': {
    name: 'Mertzon',
    postalCode: '76941',
  },
  'mertztown-pa': {
    name: 'Mertztown',
    postalCode: '19539',
  },
  'mesa-az': {
    name: 'Mesa',
    postalCode: '85201',
  },
  'mesa-id': {
    name: 'Mesa',
    postalCode: '83643',
  },
  'mesa-wa': {
    name: 'Mesa',
    postalCode: '99343',
  },
  'mescalero-nm': {
    name: 'Mescalero',
    postalCode: '88340',
  },
  'meservey-ia': {
    name: 'Meservey',
    postalCode: '50457',
  },
  'meshoppen-pa': {
    name: 'Meshoppen',
    postalCode: '18630',
  },
  'mesick-mi': {
    name: 'Mesick',
    postalCode: '49668',
  },
  'mesilla-park-nm': {
    name: 'Mesilla Park',
    postalCode: '88047',
  },
  'mesquite-nm': {
    name: 'Mesquite',
    postalCode: '88048',
  },
  'mesquite-nv': {
    name: 'Mesquite',
    postalCode: '89027',
  },
  'mesquite-tx': {
    name: 'Mesquite',
    postalCode: '75149',
  },
  'meta-mo': {
    name: 'Meta',
    postalCode: '65058',
  },
  'metairie-la': {
    name: 'Metairie',
    postalCode: '70001',
  },
  'metaline-falls-wa': {
    name: 'Metaline Falls',
    postalCode: '99153',
  },
  'metamora-il': {
    name: 'Metamora',
    postalCode: '61548',
  },
  'metamora-in': {
    name: 'Metamora',
    postalCode: '47030',
  },
  'metamora-mi': {
    name: 'Metamora',
    postalCode: '48455',
  },
  'metamora-oh': {
    name: 'Metamora',
    postalCode: '43540',
  },
  'metcalf-il': {
    name: 'Metcalf',
    postalCode: '61940',
  },
  'methow-wa': {
    name: 'Methow',
    postalCode: '98834',
  },
  'methuen-ma': {
    name: 'Methuen',
    postalCode: '01844',
  },
  'metropolis-il': {
    name: 'Metropolis',
    postalCode: '62960',
  },
  'metter-ga': {
    name: 'Metter',
    postalCode: '30439',
  },
  'metuchen-nj': {
    name: 'Metuchen',
    postalCode: '08840',
  },
  'metz-wv': {
    name: 'Metz',
    postalCode: '26585',
  },
  'mexia-tx': {
    name: 'Mexia',
    postalCode: '76667',
  },
  'mexico-me': {
    name: 'Mexico',
    postalCode: '04257',
  },
  'mexico-mo': {
    name: 'Mexico',
    postalCode: '65265',
  },
  'mexico-ny': {
    name: 'Mexico',
    postalCode: '13114',
  },
  'meyersdale-pa': {
    name: 'Meyersdale',
    postalCode: '15552',
  },
  'meyersville-tx': {
    name: 'Meyersville',
    postalCode: '77974',
  },
  'mi-wuk-village-ca': {
    name: 'Mi Wuk Village',
    postalCode: '95346',
  },
  'miami-az': {
    name: 'Miami',
    postalCode: '85539',
  },
  'miami-fl': {
    name: 'Miami',
    postalCode: '33122',
  },
  'miami-in': {
    name: 'Miami',
    postalCode: '46959',
  },
  'miami-mo': {
    name: 'Miami',
    postalCode: '65344',
  },
  'miami-nm': {
    name: 'Miami',
    postalCode: '87729',
  },
  'miami-ok': {
    name: 'Miami',
    postalCode: '74354',
  },
  'miami-tx': {
    name: 'Miami',
    postalCode: '79059',
  },
  'miami-beach-fl': {
    name: 'Miami Beach',
    postalCode: '33109',
  },
  'miami-gardens-fl': {
    name: 'Miami Gardens',
    postalCode: '33056',
  },
  'miamisburg-oh': {
    name: 'Miamisburg',
    postalCode: '45342',
  },
  'mica-wa': {
    name: 'Mica',
    postalCode: '99023',
  },
  'micanopy-fl': {
    name: 'Micanopy',
    postalCode: '32667',
  },
  'michael-il': {
    name: 'Michael',
    postalCode: '62065',
  },
  'michie-tn': {
    name: 'Michie',
    postalCode: '38357',
  },
  'michigamme-mi': {
    name: 'Michigamme',
    postalCode: '49861',
  },
  'michigan-nd': {
    name: 'Michigan',
    postalCode: '58259',
  },
  'michigan-center-mi': {
    name: 'Michigan Center',
    postalCode: '49254',
  },
  'michigan-city-in': {
    name: 'Michigan City',
    postalCode: '46360',
  },
  'michigan-city-ms': {
    name: 'Michigan City',
    postalCode: '38647',
  },
  'michigantown-in': {
    name: 'Michigantown',
    postalCode: '46057',
  },
  'mickleton-nj': {
    name: 'Mickleton',
    postalCode: '08056',
  },
  'mico-tx': {
    name: 'Mico',
    postalCode: '78056',
  },
  'middle-brook-mo': {
    name: 'Middle Brook',
    postalCode: '63656',
  },
  'middle-granville-ny': {
    name: 'Middle Granville',
    postalCode: '12849',
  },
  'middle-grove-ny': {
    name: 'Middle Grove',
    postalCode: '12850',
  },
  'middle-island-ny': {
    name: 'Middle Island',
    postalCode: '11953',
  },
  'middle-point-oh': {
    name: 'Middle Point',
    postalCode: '45863',
  },
  'middle-river-md': {
    name: 'Middle River',
    postalCode: '21220',
  },
  'middle-river-mn': {
    name: 'Middle River',
    postalCode: '56737',
  },
  'middle-village-ny': {
    name: 'Middle Village',
    postalCode: '11379',
  },
  'middleboro-ma': {
    name: 'Middleboro',
    postalCode: '02346',
  },
  'middlebourne-wv': {
    name: 'Middlebourne',
    postalCode: '26149',
  },
  'middlebrook-va': {
    name: 'Middlebrook',
    postalCode: '24459',
  },
  'middleburg-fl': {
    name: 'Middleburg',
    postalCode: '32068',
  },
  'middleburg-ky': {
    name: 'Middleburg',
    postalCode: '42541',
  },
  'middleburg-pa': {
    name: 'Middleburg',
    postalCode: '17842',
  },
  'middleburg-va': {
    name: 'Middleburg',
    postalCode: '20117',
  },
  'middleburgh-ny': {
    name: 'Middleburgh',
    postalCode: '12122',
  },
  'middlebury-ct': {
    name: 'Middlebury',
    postalCode: '06762',
  },
  'middlebury-in': {
    name: 'Middlebury',
    postalCode: '46540',
  },
  'middlebury-vt': {
    name: 'Middlebury',
    postalCode: '05753',
  },
  'middlebury-center-pa': {
    name: 'Middlebury Center',
    postalCode: '16935',
  },
  'middlefield-ct': {
    name: 'Middlefield',
    postalCode: '06455',
  },
  'middlefield-oh': {
    name: 'Middlefield',
    postalCode: '44062',
  },
  'middleport-ny': {
    name: 'Middleport',
    postalCode: '14105',
  },
  'middleport-oh': {
    name: 'Middleport',
    postalCode: '45760',
  },
  'middleport-pa': {
    name: 'Middleport',
    postalCode: '17953',
  },
  'middlesboro-ky': {
    name: 'Middlesboro',
    postalCode: '40965',
  },
  'middlesex-nc': {
    name: 'Middlesex',
    postalCode: '27557',
  },
  'middlesex-nj': {
    name: 'Middlesex',
    postalCode: '08846',
  },
  'middlesex-ny': {
    name: 'Middlesex',
    postalCode: '14507',
  },
  'middleton-id': {
    name: 'Middleton',
    postalCode: '83644',
  },
  'middleton-ma': {
    name: 'Middleton',
    postalCode: '01949',
  },
  'middleton-mi': {
    name: 'Middleton',
    postalCode: '48856',
  },
  'middleton-tn': {
    name: 'Middleton',
    postalCode: '38052',
  },
  'middleton-wi': {
    name: 'Middleton',
    postalCode: '53562',
  },
  'middletown-ca': {
    name: 'Middletown',
    postalCode: '95461',
  },
  'middletown-ct': {
    name: 'Middletown',
    postalCode: '06457',
  },
  'middletown-de': {
    name: 'Middletown',
    postalCode: '19709',
  },
  'middletown-ia': {
    name: 'Middletown',
    postalCode: '52638',
  },
  'middletown-il': {
    name: 'Middletown',
    postalCode: '62666',
  },
  'middletown-in': {
    name: 'Middletown',
    postalCode: '47356',
  },
  'middletown-md': {
    name: 'Middletown',
    postalCode: '21769',
  },
  'middletown-mo': {
    name: 'Middletown',
    postalCode: '63359',
  },
  'middletown-nj': {
    name: 'Middletown',
    postalCode: '07748',
  },
  'middletown-ny': {
    name: 'Middletown',
    postalCode: '10940',
  },
  'middletown-oh': {
    name: 'Middletown',
    postalCode: '45042',
  },
  'middletown-pa': {
    name: 'Middletown',
    postalCode: '17057',
  },
  'middletown-ri': {
    name: 'Middletown',
    postalCode: '02842',
  },
  'middletown-va': {
    name: 'Middletown',
    postalCode: '22645',
  },
  'middletown-springs-vt': {
    name: 'Middletown Springs',
    postalCode: '05757',
  },
  'middleville-mi': {
    name: 'Middleville',
    postalCode: '49333',
  },
  'middleville-ny': {
    name: 'Middleville',
    postalCode: '13406',
  },
  'midkiff-tx': {
    name: 'Midkiff',
    postalCode: '79755',
  },
  'midkiff-wv': {
    name: 'Midkiff',
    postalCode: '25540',
  },
  'midland-ga': {
    name: 'Midland',
    postalCode: '31820',
  },
  'midland-mi': {
    name: 'Midland',
    postalCode: '48640',
  },
  'midland-nc': {
    name: 'Midland',
    postalCode: '28107',
  },
  'midland-oh': {
    name: 'Midland',
    postalCode: '45148',
  },
  'midland-pa': {
    name: 'Midland',
    postalCode: '15059',
  },
  'midland-sd': {
    name: 'Midland',
    postalCode: '57552',
  },
  'midland-tx': {
    name: 'Midland',
    postalCode: '79701',
  },
  'midland-va': {
    name: 'Midland',
    postalCode: '22728',
  },
  'midland-city-al': {
    name: 'Midland City',
    postalCode: '36350',
  },
  'midland-park-nj': {
    name: 'Midland Park',
    postalCode: '07432',
  },
  'midlothian-il': {
    name: 'Midlothian',
    postalCode: '60445',
  },
  'midlothian-tx': {
    name: 'Midlothian',
    postalCode: '76065',
  },
  'midlothian-va': {
    name: 'Midlothian',
    postalCode: '23112',
  },
  'midpines-ca': {
    name: 'Midpines',
    postalCode: '95345',
  },
  'midvale-id': {
    name: 'Midvale',
    postalCode: '83645',
  },
  'midvale-ut': {
    name: 'Midvale',
    postalCode: '84047',
  },
  'midville-ga': {
    name: 'Midville',
    postalCode: '30441',
  },
  'midway-al': {
    name: 'Midway',
    postalCode: '36053',
  },
  'midway-ar': {
    name: 'Midway',
    postalCode: '72651',
  },
  'midway-fl': {
    name: 'Midway',
    postalCode: '32343',
  },
  'midway-ga': {
    name: 'Midway',
    postalCode: '31320',
  },
  'midway-ky': {
    name: 'Midway',
    postalCode: '40347',
  },
  'midway-tn': {
    name: 'Midway',
    postalCode: '37809',
  },
  'midway-tx': {
    name: 'Midway',
    postalCode: '75852',
  },
  'midway-ut': {
    name: 'Midway',
    postalCode: '84049',
  },
  'midway-city-ca': {
    name: 'Midway City',
    postalCode: '92655',
  },
  'midway-park-nc': {
    name: 'Midway Park',
    postalCode: '28544',
  },
  'midwest-wy': {
    name: 'Midwest',
    postalCode: '82643',
  },
  'mifflin-pa': {
    name: 'Mifflin',
    postalCode: '17058',
  },
  'mifflinburg-pa': {
    name: 'Mifflinburg',
    postalCode: '17844',
  },
  'mifflintown-pa': {
    name: 'Mifflintown',
    postalCode: '17059',
  },
  'mikado-mi': {
    name: 'Mikado',
    postalCode: '48745',
  },
  'mikana-wi': {
    name: 'Mikana',
    postalCode: '54857',
  },
  'mikkalo-or': {
    name: 'Mikkalo',
    postalCode: '97861',
  },
  'milaca-mn': {
    name: 'Milaca',
    postalCode: '56353',
  },
  'milam-tx': {
    name: 'Milam',
    postalCode: '75959',
  },
  'milam-wv': {
    name: 'Milam',
    postalCode: '26838',
  },
  'milan-ga': {
    name: 'Milan',
    postalCode: '31060',
  },
  'milan-il': {
    name: 'Milan',
    postalCode: '61264',
  },
  'milan-in': {
    name: 'Milan',
    postalCode: '47031',
  },
  'milan-ks': {
    name: 'Milan',
    postalCode: '67105',
  },
  'milan-mi': {
    name: 'Milan',
    postalCode: '48160',
  },
  'milan-mn': {
    name: 'Milan',
    postalCode: '56262',
  },
  'milan-mo': {
    name: 'Milan',
    postalCode: '63556',
  },
  'milan-nh': {
    name: 'Milan',
    postalCode: '03588',
  },
  'milan-oh': {
    name: 'Milan',
    postalCode: '44846',
  },
  'milan-pa': {
    name: 'Milan',
    postalCode: '18831',
  },
  'milan-tn': {
    name: 'Milan',
    postalCode: '38358',
  },
  'milano-tx': {
    name: 'Milano',
    postalCode: '76556',
  },
  'milanville-pa': {
    name: 'Milanville',
    postalCode: '18443',
  },
  'milbank-sd': {
    name: 'Milbank',
    postalCode: '57252',
  },
  'milbridge-me': {
    name: 'Milbridge',
    postalCode: '04658',
  },
  'milburn-ok': {
    name: 'Milburn',
    postalCode: '73450',
  },
  'mildred-mt': {
    name: 'Mildred',
    postalCode: '59341',
  },
  'mildred-pa': {
    name: 'Mildred',
    postalCode: '18632',
  },
  'miles-ia': {
    name: 'Miles',
    postalCode: '52064',
  },
  'miles-tx': {
    name: 'Miles',
    postalCode: '76861',
  },
  'miles-city-mt': {
    name: 'Miles City',
    postalCode: '59301',
  },
  'milesville-sd': {
    name: 'Milesville',
    postalCode: '57553',
  },
  'milford-ca': {
    name: 'Milford',
    postalCode: '96121',
  },
  'milford-ct': {
    name: 'Milford',
    postalCode: '06460',
  },
  'milford-de': {
    name: 'Milford',
    postalCode: '19963',
  },
  'milford-ia': {
    name: 'Milford',
    postalCode: '51351',
  },
  'milford-il': {
    name: 'Milford',
    postalCode: '60953',
  },
  'milford-in': {
    name: 'Milford',
    postalCode: '46542',
  },
  'milford-ks': {
    name: 'Milford',
    postalCode: '66514',
  },
  'milford-ma': {
    name: 'Milford',
    postalCode: '01757',
  },
  'milford-me': {
    name: 'Milford',
    postalCode: '04461',
  },
  'milford-mi': {
    name: 'Milford',
    postalCode: '48380',
  },
  'milford-ne': {
    name: 'Milford',
    postalCode: '68405',
  },
  'milford-nh': {
    name: 'Milford',
    postalCode: '03055',
  },
  'milford-nj': {
    name: 'Milford',
    postalCode: '08848',
  },
  'milford-ny': {
    name: 'Milford',
    postalCode: '13807',
  },
  'milford-oh': {
    name: 'Milford',
    postalCode: '45150',
  },
  'milford-pa': {
    name: 'Milford',
    postalCode: '18337',
  },
  'milford-tx': {
    name: 'Milford',
    postalCode: '76670',
  },
  'milford-ut': {
    name: 'Milford',
    postalCode: '84751',
  },
  'milford-va': {
    name: 'Milford',
    postalCode: '22514',
  },
  'milford-center-oh': {
    name: 'Milford Center',
    postalCode: '43045',
  },
  'mililani-hi': {
    name: 'Mililani',
    postalCode: '96789',
  },
  'mill-city-or': {
    name: 'Mill City',
    postalCode: '97360',
  },
  'mill-creek-ca': {
    name: 'Mill Creek',
    postalCode: '96061',
  },
  'mill-creek-in': {
    name: 'Mill Creek',
    postalCode: '46365',
  },
  'mill-creek-ok': {
    name: 'Mill Creek',
    postalCode: '74856',
  },
  'mill-creek-pa': {
    name: 'Mill Creek',
    postalCode: '17060',
  },
  'mill-creek-wv': {
    name: 'Mill Creek',
    postalCode: '26280',
  },
  'mill-hall-pa': {
    name: 'Mill Hall',
    postalCode: '17751',
  },
  'mill-neck-ny': {
    name: 'Mill Neck',
    postalCode: '11765',
  },
  'mill-run-pa': {
    name: 'Mill Run',
    postalCode: '15464',
  },
  'mill-shoals-il': {
    name: 'Mill Shoals',
    postalCode: '62862',
  },
  'mill-spring-mo': {
    name: 'Mill Spring',
    postalCode: '63952',
  },
  'mill-spring-nc': {
    name: 'Mill Spring',
    postalCode: '28756',
  },
  'mill-valley-ca': {
    name: 'Mill Valley',
    postalCode: '94941',
  },
  'milladore-wi': {
    name: 'Milladore',
    postalCode: '54454',
  },
  'millboro-va': {
    name: 'Millboro',
    postalCode: '24460',
  },
  'millbrae-ca': {
    name: 'Millbrae',
    postalCode: '94030',
  },
  'millbrook-al': {
    name: 'Millbrook',
    postalCode: '36054',
  },
  'millbrook-ny': {
    name: 'Millbrook',
    postalCode: '12545',
  },
  'millburn-nj': {
    name: 'Millburn',
    postalCode: '07041',
  },
  'millbury-ma': {
    name: 'Millbury',
    postalCode: '01527',
  },
  'millbury-oh': {
    name: 'Millbury',
    postalCode: '43447',
  },
  'milledgeville-ga': {
    name: 'Milledgeville',
    postalCode: '31061',
  },
  'milledgeville-il': {
    name: 'Milledgeville',
    postalCode: '61051',
  },
  'milledgeville-tn': {
    name: 'Milledgeville',
    postalCode: '38359',
  },
  'millen-ga': {
    name: 'Millen',
    postalCode: '30442',
  },
  'miller-mo': {
    name: 'Miller',
    postalCode: '65707',
  },
  'miller-ne': {
    name: 'Miller',
    postalCode: '68858',
  },
  'miller-sd': {
    name: 'Miller',
    postalCode: '57362',
  },
  'miller-city-il': {
    name: 'Miller City',
    postalCode: '62962',
  },
  'miller-place-ny': {
    name: 'Miller Place',
    postalCode: '11764',
  },
  'millers-creek-nc': {
    name: 'Millers Creek',
    postalCode: '28651',
  },
  'millers-falls-ma': {
    name: 'Millers Falls',
    postalCode: '01349',
  },
  'millersburg-in': {
    name: 'Millersburg',
    postalCode: '46543',
  },
  'millersburg-mi': {
    name: 'Millersburg',
    postalCode: '49759',
  },
  'millersburg-oh': {
    name: 'Millersburg',
    postalCode: '44654',
  },
  'millersburg-pa': {
    name: 'Millersburg',
    postalCode: '17061',
  },
  'millersport-oh': {
    name: 'Millersport',
    postalCode: '43046',
  },
  'millerstown-pa': {
    name: 'Millerstown',
    postalCode: '17062',
  },
  'millersview-tx': {
    name: 'Millersview',
    postalCode: '76862',
  },
  'millersville-md': {
    name: 'Millersville',
    postalCode: '21108',
  },
  'millersville-mo': {
    name: 'Millersville',
    postalCode: '63766',
  },
  'millersville-pa': {
    name: 'Millersville',
    postalCode: '17551',
  },
  'millerton-ia': {
    name: 'Millerton',
    postalCode: '50165',
  },
  'millerton-ny': {
    name: 'Millerton',
    postalCode: '12546',
  },
  'millerton-pa': {
    name: 'Millerton',
    postalCode: '16936',
  },
  'millfield-oh': {
    name: 'Millfield',
    postalCode: '45761',
  },
  'millheim-pa': {
    name: 'Millheim',
    postalCode: '16854',
  },
  'milligan-ne': {
    name: 'Milligan',
    postalCode: '68406',
  },
  'milliken-co': {
    name: 'Milliken',
    postalCode: '80543',
  },
  'millington-md': {
    name: 'Millington',
    postalCode: '21651',
  },
  'millington-mi': {
    name: 'Millington',
    postalCode: '48746',
  },
  'millington-nj': {
    name: 'Millington',
    postalCode: '07946',
  },
  'millington-tn': {
    name: 'Millington',
    postalCode: '38053',
  },
  'millinocket-me': {
    name: 'Millinocket',
    postalCode: '04462',
  },
  'millis-ma': {
    name: 'Millis',
    postalCode: '02054',
  },
  'millmont-pa': {
    name: 'Millmont',
    postalCode: '17845',
  },
  'millport-al': {
    name: 'Millport',
    postalCode: '35576',
  },
  'millport-ny': {
    name: 'Millport',
    postalCode: '14864',
  },
  'millry-al': {
    name: 'Millry',
    postalCode: '36558',
  },
  'mills-ne': {
    name: 'Mills',
    postalCode: '68753',
  },
  'mills-nm': {
    name: 'Mills',
    postalCode: '87730',
  },
  'mills-pa': {
    name: 'Mills',
    postalCode: '16937',
  },
  'mills-river-nc': {
    name: 'Mills River',
    postalCode: '28759',
  },
  'millsap-tx': {
    name: 'Millsap',
    postalCode: '76066',
  },
  'millsboro-de': {
    name: 'Millsboro',
    postalCode: '19966',
  },
  'millstadt-il': {
    name: 'Millstadt',
    postalCode: '62260',
  },
  'millstone-ky': {
    name: 'Millstone',
    postalCode: '41838',
  },
  'millstone-wv': {
    name: 'Millstone',
    postalCode: '25261',
  },
  'millstone-township-nj': {
    name: 'Millstone Township',
    postalCode: '08510',
  },
  'milltown-in': {
    name: 'Milltown',
    postalCode: '47145',
  },
  'milltown-nj': {
    name: 'Milltown',
    postalCode: '08850',
  },
  'milltown-wi': {
    name: 'Milltown',
    postalCode: '54858',
  },
  'millville-ca': {
    name: 'Millville',
    postalCode: '96062',
  },
  'millville-de': {
    name: 'Millville',
    postalCode: '19967',
  },
  'millville-ma': {
    name: 'Millville',
    postalCode: '01529',
  },
  'millville-mn': {
    name: 'Millville',
    postalCode: '55957',
  },
  'millville-nj': {
    name: 'Millville',
    postalCode: '08332',
  },
  'millville-pa': {
    name: 'Millville',
    postalCode: '17846',
  },
  'millville-wv': {
    name: 'Millville',
    postalCode: '25432',
  },
  'millwood-ga': {
    name: 'Millwood',
    postalCode: '31552',
  },
  'millwood-ky': {
    name: 'Millwood',
    postalCode: '42762',
  },
  'millwood-ny': {
    name: 'Millwood',
    postalCode: '10546',
  },
  'millwood-wv': {
    name: 'Millwood',
    postalCode: '25262',
  },
  'milmay-nj': {
    name: 'Milmay',
    postalCode: '08340',
  },
  'milmine-il': {
    name: 'Milmine',
    postalCode: '61855',
  },
  'milner-ga': {
    name: 'Milner',
    postalCode: '30257',
  },
  'milnesand-nm': {
    name: 'Milnesand',
    postalCode: '88125',
  },
  'milnor-nd': {
    name: 'Milnor',
    postalCode: '58060',
  },
  'milo-ia': {
    name: 'Milo',
    postalCode: '50166',
  },
  'milo-me': {
    name: 'Milo',
    postalCode: '04463',
  },
  'milo-mo': {
    name: 'Milo',
    postalCode: '64767',
  },
  'milpitas-ca': {
    name: 'Milpitas',
    postalCode: '95035',
  },
  'milroy-in': {
    name: 'Milroy',
    postalCode: '46156',
  },
  'milroy-mn': {
    name: 'Milroy',
    postalCode: '56263',
  },
  'milroy-pa': {
    name: 'Milroy',
    postalCode: '17063',
  },
  'milton-de': {
    name: 'Milton',
    postalCode: '19968',
  },
  'milton-fl': {
    name: 'Milton',
    postalCode: '32570',
  },
  'milton-ia': {
    name: 'Milton',
    postalCode: '52570',
  },
  'milton-in': {
    name: 'Milton',
    postalCode: '47357',
  },
  'milton-ks': {
    name: 'Milton',
    postalCode: '67106',
  },
  'milton-ky': {
    name: 'Milton',
    postalCode: '40045',
  },
  'milton-ma': {
    name: 'Milton',
    postalCode: '02186',
  },
  'milton-nc': {
    name: 'Milton',
    postalCode: '27305',
  },
  'milton-nd': {
    name: 'Milton',
    postalCode: '58260',
  },
  'milton-nh': {
    name: 'Milton',
    postalCode: '03851',
  },
  'milton-ny': {
    name: 'Milton',
    postalCode: '12547',
  },
  'milton-pa': {
    name: 'Milton',
    postalCode: '17847',
  },
  'milton-tn': {
    name: 'Milton',
    postalCode: '37118',
  },
  'milton-vt': {
    name: 'Milton',
    postalCode: '05468',
  },
  'milton-wa': {
    name: 'Milton',
    postalCode: '98354',
  },
  'milton-wi': {
    name: 'Milton',
    postalCode: '53563',
  },
  'milton-wv': {
    name: 'Milton',
    postalCode: '25541',
  },
  'milton-center-oh': {
    name: 'Milton Center',
    postalCode: '43541',
  },
  'milton-freewater-or': {
    name: 'Milton Freewater',
    postalCode: '97862',
  },
  'milton-mills-nh': {
    name: 'Milton Mills',
    postalCode: '03852',
  },
  'miltona-mn': {
    name: 'Miltona',
    postalCode: '56354',
  },
  'miltonvale-ks': {
    name: 'Miltonvale',
    postalCode: '67466',
  },
  'milwaukee-nc': {
    name: 'Milwaukee',
    postalCode: '27854',
  },
  'milwaukee-wi': {
    name: 'Milwaukee',
    postalCode: '53202',
  },
  'mimbres-nm': {
    name: 'Mimbres',
    postalCode: '88049',
  },
  'mims-fl': {
    name: 'Mims',
    postalCode: '32754',
  },
  'minatare-ne': {
    name: 'Minatare',
    postalCode: '69356',
  },
  'minburn-ia': {
    name: 'Minburn',
    postalCode: '50167',
  },
  'minco-ok': {
    name: 'Minco',
    postalCode: '73059',
  },
  'minden-ia': {
    name: 'Minden',
    postalCode: '51553',
  },
  'minden-la': {
    name: 'Minden',
    postalCode: '71055',
  },
  'minden-ne': {
    name: 'Minden',
    postalCode: '68959',
  },
  'minden-nv': {
    name: 'Minden',
    postalCode: '89423',
  },
  'minden-city-mi': {
    name: 'Minden City',
    postalCode: '48456',
  },
  'mindenmines-mo': {
    name: 'Mindenmines',
    postalCode: '64769',
  },
  'mindoro-wi': {
    name: 'Mindoro',
    postalCode: '54644',
  },
  'mine-hill-nj': {
    name: 'Mine Hill',
    postalCode: '07803',
  },
  'mineola-ny': {
    name: 'Mineola',
    postalCode: '11501',
  },
  'mineola-tx': {
    name: 'Mineola',
    postalCode: '75773',
  },
  'mineral-il': {
    name: 'Mineral',
    postalCode: '61344',
  },
  'mineral-va': {
    name: 'Mineral',
    postalCode: '23117',
  },
  'mineral-wa': {
    name: 'Mineral',
    postalCode: '98355',
  },
  'mineral-bluff-ga': {
    name: 'Mineral Bluff',
    postalCode: '30559',
  },
  'mineral-city-oh': {
    name: 'Mineral City',
    postalCode: '44656',
  },
  'mineral-point-mo': {
    name: 'Mineral Point',
    postalCode: '63660',
  },
  'mineral-point-pa': {
    name: 'Mineral Point',
    postalCode: '15942',
  },
  'mineral-point-wi': {
    name: 'Mineral Point',
    postalCode: '53565',
  },
  'mineral-ridge-oh': {
    name: 'Mineral Ridge',
    postalCode: '44440',
  },
  'mineral-springs-ar': {
    name: 'Mineral Springs',
    postalCode: '71851',
  },
  'mineral-wells-tx': {
    name: 'Mineral Wells',
    postalCode: '76067',
  },
  'mineral-wells-wv': {
    name: 'Mineral Wells',
    postalCode: '26150',
  },
  'minersville-pa': {
    name: 'Minersville',
    postalCode: '17954',
  },
  'minerva-ny': {
    name: 'Minerva',
    postalCode: '12851',
  },
  'minerva-oh': {
    name: 'Minerva',
    postalCode: '44657',
  },
  'mineville-ny': {
    name: 'Mineville',
    postalCode: '12956',
  },
  'minford-oh': {
    name: 'Minford',
    postalCode: '45653',
  },
  'mingo-ia': {
    name: 'Mingo',
    postalCode: '50168',
  },
  'mingo-junction-oh': {
    name: 'Mingo Junction',
    postalCode: '43938',
  },
  'mingus-tx': {
    name: 'Mingus',
    postalCode: '76463',
  },
  'minier-il': {
    name: 'Minier',
    postalCode: '61759',
  },
  'minneapolis-ks': {
    name: 'Minneapolis',
    postalCode: '67467',
  },
  'minneapolis-mn': {
    name: 'Minneapolis',
    postalCode: '55401',
  },
  'minneola-ks': {
    name: 'Minneola',
    postalCode: '67865',
  },
  'minneota-mn': {
    name: 'Minneota',
    postalCode: '56264',
  },
  'minnesota-city-mn': {
    name: 'Minnesota City',
    postalCode: '55959',
  },
  'minnesota-lake-mn': {
    name: 'Minnesota Lake',
    postalCode: '56068',
  },
  'minnetonka-mn': {
    name: 'Minnetonka',
    postalCode: '55345',
  },
  'minnewaukan-nd': {
    name: 'Minnewaukan',
    postalCode: '58351',
  },
  'minnie-ky': {
    name: 'Minnie',
    postalCode: '41651',
  },
  'minoa-ny': {
    name: 'Minoa',
    postalCode: '13116',
  },
  'minocqua-wi': {
    name: 'Minocqua',
    postalCode: '54548',
  },
  'minong-wi': {
    name: 'Minong',
    postalCode: '54859',
  },
  'minonk-il': {
    name: 'Minonk',
    postalCode: '61760',
  },
  'minooka-il': {
    name: 'Minooka',
    postalCode: '60447',
  },
  'minor-hill-tn': {
    name: 'Minor Hill',
    postalCode: '38473',
  },
  'minot-me': {
    name: 'Minot',
    postalCode: '04258',
  },
  'minot-nd': {
    name: 'Minot',
    postalCode: '58701',
  },
  'minot-afb-nd': {
    name: 'Minot AFB',
    postalCode: '58704',
  },
  'minotola-nj': {
    name: 'Minotola',
    postalCode: '08341',
  },
  'minster-oh': {
    name: 'Minster',
    postalCode: '45865',
  },
  'minter-al': {
    name: 'Minter',
    postalCode: '36761',
  },
  'minter-city-ms': {
    name: 'Minter City',
    postalCode: '38944',
  },
  'minto-nd': {
    name: 'Minto',
    postalCode: '58261',
  },
  'mio-mi': {
    name: 'Mio',
    postalCode: '48647',
  },
  'mira-loma-ca': {
    name: 'Mira Loma',
    postalCode: '91752',
  },
  'miracle-ky': {
    name: 'Miracle',
    postalCode: '40856',
  },
  'miramar-beach-fl': {
    name: 'Miramar Beach',
    postalCode: '32550',
  },
  'miramonte-ca': {
    name: 'Miramonte',
    postalCode: '93641',
  },
  'mirando-city-tx': {
    name: 'Mirando City',
    postalCode: '78369',
  },
  'mirror-lake-nh': {
    name: 'Mirror Lake',
    postalCode: '03853',
  },
  'mishawaka-in': {
    name: 'Mishawaka',
    postalCode: '46544',
  },
  'mishicot-wi': {
    name: 'Mishicot',
    postalCode: '54228',
  },
  'mission-ks': {
    name: 'Mission',
    postalCode: '66202',
  },
  'mission-sd': {
    name: 'Mission',
    postalCode: '57555',
  },
  'mission-tx': {
    name: 'Mission',
    postalCode: '78572',
  },
  'mission-hill-sd': {
    name: 'Mission Hill',
    postalCode: '57046',
  },
  'mission-hills-ca': {
    name: 'Mission Hills',
    postalCode: '91345',
  },
  'mission-viejo-ca': {
    name: 'Mission Viejo',
    postalCode: '92691',
  },
  'missoula-mt': {
    name: 'Missoula',
    postalCode: '59801',
  },
  'missouri-city-tx': {
    name: 'Missouri City',
    postalCode: '77459',
  },
  'missouri-valley-ia': {
    name: 'Missouri Valley',
    postalCode: '51555',
  },
  'mistletoe-ky': {
    name: 'Mistletoe',
    postalCode: '41351',
  },
  'mitchell-ga': {
    name: 'Mitchell',
    postalCode: '30820',
  },
  'mitchell-in': {
    name: 'Mitchell',
    postalCode: '47446',
  },
  'mitchell-ne': {
    name: 'Mitchell',
    postalCode: '69357',
  },
  'mitchell-or': {
    name: 'Mitchell',
    postalCode: '97750',
  },
  'mitchell-sd': {
    name: 'Mitchell',
    postalCode: '57301',
  },
  'mitchells-va': {
    name: 'Mitchells',
    postalCode: '22729',
  },
  'mitchellville-ia': {
    name: 'Mitchellville',
    postalCode: '50169',
  },
  'mittie-la': {
    name: 'Mittie',
    postalCode: '70654',
  },
  'mize-ky': {
    name: 'Mize',
    postalCode: '41352',
  },
  'mize-ms': {
    name: 'Mize',
    postalCode: '39116',
  },
  'mizpah-mn': {
    name: 'Mizpah',
    postalCode: '56660',
  },
  'moab-ut': {
    name: 'Moab',
    postalCode: '84532',
  },
  'moatsville-wv': {
    name: 'Moatsville',
    postalCode: '26405',
  },
  'mobeetie-tx': {
    name: 'Mobeetie',
    postalCode: '79061',
  },
  'moberly-mo': {
    name: 'Moberly',
    postalCode: '65270',
  },
  'mobile-al': {
    name: 'Mobile',
    postalCode: '36602',
  },
  'mobridge-sd': {
    name: 'Mobridge',
    postalCode: '57601',
  },
  'moca-pr': {
    name: 'Moca',
    postalCode: '00676',
  },
  'moccasin-mt': {
    name: 'Moccasin',
    postalCode: '59462',
  },
  'mocksville-nc': {
    name: 'Mocksville',
    postalCode: '27028',
  },
  'modale-ia': {
    name: 'Modale',
    postalCode: '51556',
  },
  'mode-il': {
    name: 'Mode',
    postalCode: '62444',
  },
  'model-co': {
    name: 'Model',
    postalCode: '81059',
  },
  'modena-ny': {
    name: 'Modena',
    postalCode: '12548',
  },
  'modena-ut': {
    name: 'Modena',
    postalCode: '84753',
  },
  'modesto-ca': {
    name: 'Modesto',
    postalCode: '95350',
  },
  'modesto-il': {
    name: 'Modesto',
    postalCode: '62667',
  },
  'modoc-il': {
    name: 'Modoc',
    postalCode: '62261',
  },
  'modoc-in': {
    name: 'Modoc',
    postalCode: '47358',
  },
  'modoc-sc': {
    name: 'Modoc',
    postalCode: '29838',
  },
  'moffat-co': {
    name: 'Moffat',
    postalCode: '81143',
  },
  'moffit-nd': {
    name: 'Moffit',
    postalCode: '58560',
  },
  'mogadore-oh': {
    name: 'Mogadore',
    postalCode: '44260',
  },
  'mohall-nd': {
    name: 'Mohall',
    postalCode: '58761',
  },
  'mohave-valley-az': {
    name: 'Mohave Valley',
    postalCode: '86440',
  },
  'mohawk-mi': {
    name: 'Mohawk',
    postalCode: '49950',
  },
  'mohawk-ny': {
    name: 'Mohawk',
    postalCode: '13407',
  },
  'mohawk-tn': {
    name: 'Mohawk',
    postalCode: '37810',
  },
  'mohawk-wv': {
    name: 'Mohawk',
    postalCode: '24862',
  },
  'mohegan-lake-ny': {
    name: 'Mohegan Lake',
    postalCode: '10547',
  },
  'mohler-wa': {
    name: 'Mohler',
    postalCode: '99154',
  },
  'mohnton-pa': {
    name: 'Mohnton',
    postalCode: '19540',
  },
  'mohrsville-pa': {
    name: 'Mohrsville',
    postalCode: '19541',
  },
  'moira-ny': {
    name: 'Moira',
    postalCode: '12957',
  },
  'mojave-ca': {
    name: 'Mojave',
    postalCode: '93501',
  },
  'mokane-mo': {
    name: 'Mokane',
    postalCode: '65059',
  },
  'mokelumne-hill-ca': {
    name: 'Mokelumne Hill',
    postalCode: '95245',
  },
  'mokena-il': {
    name: 'Mokena',
    postalCode: '60448',
  },
  'molalla-or': {
    name: 'Molalla',
    postalCode: '97038',
  },
  'molena-ga': {
    name: 'Molena',
    postalCode: '30258',
  },
  'moline-il': {
    name: 'Moline',
    postalCode: '61265',
  },
  'moline-ks': {
    name: 'Moline',
    postalCode: '67353',
  },
  'molino-fl': {
    name: 'Molino',
    postalCode: '32577',
  },
  'molt-mt': {
    name: 'Molt',
    postalCode: '59057',
  },
  'momence-il': {
    name: 'Momence',
    postalCode: '60954',
  },
  'mona-ut': {
    name: 'Mona',
    postalCode: '84645',
  },
  'monaca-pa': {
    name: 'Monaca',
    postalCode: '15061',
  },
  'monahans-tx': {
    name: 'Monahans',
    postalCode: '79756',
  },
  'monarch-mt': {
    name: 'Monarch',
    postalCode: '59463',
  },
  'moncks-corner-sc': {
    name: 'Moncks Corner',
    postalCode: '29461',
  },
  'monclova-oh': {
    name: 'Monclova',
    postalCode: '43542',
  },
  'moncure-nc': {
    name: 'Moncure',
    postalCode: '27559',
  },
  'mondamin-ia': {
    name: 'Mondamin',
    postalCode: '51557',
  },
  'mondovi-wi': {
    name: 'Mondovi',
    postalCode: '54755',
  },
  'monee-il': {
    name: 'Monee',
    postalCode: '60449',
  },
  'monessen-pa': {
    name: 'Monessen',
    postalCode: '15062',
  },
  'moneta-va': {
    name: 'Moneta',
    postalCode: '24121',
  },
  'monett-mo': {
    name: 'Monett',
    postalCode: '65708',
  },
  'monetta-sc': {
    name: 'Monetta',
    postalCode: '29105',
  },
  'monette-ar': {
    name: 'Monette',
    postalCode: '72447',
  },
  'mongaup-valley-ny': {
    name: 'Mongaup Valley',
    postalCode: '12762',
  },
  'monkton-md': {
    name: 'Monkton',
    postalCode: '21111',
  },
  'monmouth-ia': {
    name: 'Monmouth',
    postalCode: '52309',
  },
  'monmouth-il': {
    name: 'Monmouth',
    postalCode: '61462',
  },
  'monmouth-me': {
    name: 'Monmouth',
    postalCode: '04259',
  },
  'monmouth-or': {
    name: 'Monmouth',
    postalCode: '97361',
  },
  'monmouth-beach-nj': {
    name: 'Monmouth Beach',
    postalCode: '07750',
  },
  'monmouth-junction-nj': {
    name: 'Monmouth Junction',
    postalCode: '08852',
  },
  'monon-in': {
    name: 'Monon',
    postalCode: '47959',
  },
  'monona-ia': {
    name: 'Monona',
    postalCode: '52159',
  },
  'monongahela-pa': {
    name: 'Monongahela',
    postalCode: '15063',
  },
  'monroe-ct': {
    name: 'Monroe',
    postalCode: '06468',
  },
  'monroe-ga': {
    name: 'Monroe',
    postalCode: '30655',
  },
  'monroe-ia': {
    name: 'Monroe',
    postalCode: '50170',
  },
  'monroe-in': {
    name: 'Monroe',
    postalCode: '46772',
  },
  'monroe-la': {
    name: 'Monroe',
    postalCode: '71201',
  },
  'monroe-me': {
    name: 'Monroe',
    postalCode: '04951',
  },
  'monroe-mi': {
    name: 'Monroe',
    postalCode: '48161',
  },
  'monroe-nc': {
    name: 'Monroe',
    postalCode: '28110',
  },
  'monroe-ne': {
    name: 'Monroe',
    postalCode: '68647',
  },
  'monroe-nh': {
    name: 'Monroe',
    postalCode: '03771',
  },
  'monroe-ny': {
    name: 'Monroe',
    postalCode: '10950',
  },
  'monroe-oh': {
    name: 'Monroe',
    postalCode: '45050',
  },
  'monroe-or': {
    name: 'Monroe',
    postalCode: '97456',
  },
  'monroe-sd': {
    name: 'Monroe',
    postalCode: '57047',
  },
  'monroe-tn': {
    name: 'Monroe',
    postalCode: '38573',
  },
  'monroe-ut': {
    name: 'Monroe',
    postalCode: '84754',
  },
  'monroe-va': {
    name: 'Monroe',
    postalCode: '24574',
  },
  'monroe-wa': {
    name: 'Monroe',
    postalCode: '98272',
  },
  'monroe-wi': {
    name: 'Monroe',
    postalCode: '53566',
  },
  'monroe-center-il': {
    name: 'Monroe Center',
    postalCode: '61052',
  },
  'monroe-city-in': {
    name: 'Monroe City',
    postalCode: '47557',
  },
  'monroe-city-mo': {
    name: 'Monroe City',
    postalCode: '63456',
  },
  'monroe-township-nj': {
    name: 'Monroe Township',
    postalCode: '08831',
  },
  'monroeton-pa': {
    name: 'Monroeton',
    postalCode: '18832',
  },
  'monroeville-al': {
    name: 'Monroeville',
    postalCode: '36460',
  },
  'monroeville-in': {
    name: 'Monroeville',
    postalCode: '46773',
  },
  'monroeville-nj': {
    name: 'Monroeville',
    postalCode: '08343',
  },
  'monroeville-oh': {
    name: 'Monroeville',
    postalCode: '44847',
  },
  'monroeville-pa': {
    name: 'Monroeville',
    postalCode: '15146',
  },
  'monrovia-ca': {
    name: 'Monrovia',
    postalCode: '91016',
  },
  'monrovia-in': {
    name: 'Monrovia',
    postalCode: '46157',
  },
  'monrovia-md': {
    name: 'Monrovia',
    postalCode: '21770',
  },
  'monsey-ny': {
    name: 'Monsey',
    postalCode: '10952',
  },
  'monson-ma': {
    name: 'Monson',
    postalCode: '01057',
  },
  'monson-me': {
    name: 'Monson',
    postalCode: '04464',
  },
  'mont-alto-pa': {
    name: 'Mont Alto',
    postalCode: '17237',
  },
  'mont-clare-pa': {
    name: 'Mont Clare',
    postalCode: '19453',
  },
  'mont-vernon-nh': {
    name: 'Mont Vernon',
    postalCode: '03057',
  },
  'montague-ca': {
    name: 'Montague',
    postalCode: '96064',
  },
  'montague-ma': {
    name: 'Montague',
    postalCode: '01351',
  },
  'montague-mi': {
    name: 'Montague',
    postalCode: '49437',
  },
  'montague-nj': {
    name: 'Montague',
    postalCode: '07827',
  },
  'montague-tx': {
    name: 'Montague',
    postalCode: '76251',
  },
  'montalba-tx': {
    name: 'Montalba',
    postalCode: '75853',
  },
  'montana-mines-wv': {
    name: 'Montana Mines',
    postalCode: '26586',
  },
  'montauk-ny': {
    name: 'Montauk',
    postalCode: '11954',
  },
  'montclair-ca': {
    name: 'Montclair',
    postalCode: '91763',
  },
  'montclair-nj': {
    name: 'Montclair',
    postalCode: '07042',
  },
  'monte-rio-ca': {
    name: 'Monte Rio',
    postalCode: '95462',
  },
  'monte-vista-co': {
    name: 'Monte Vista',
    postalCode: '81144',
  },
  'monteagle-tn': {
    name: 'Monteagle',
    postalCode: '37356',
  },
  'montebello-ca': {
    name: 'Montebello',
    postalCode: '90640',
  },
  'montebello-va': {
    name: 'Montebello',
    postalCode: '24464',
  },
  'montegut-la': {
    name: 'Montegut',
    postalCode: '70377',
  },
  'montello-wi': {
    name: 'Montello',
    postalCode: '53949',
  },
  'monterey-ca': {
    name: 'Monterey',
    postalCode: '93940',
  },
  'monterey-in': {
    name: 'Monterey',
    postalCode: '46960',
  },
  'monterey-la': {
    name: 'Monterey',
    postalCode: '71354',
  },
  'monterey-ma': {
    name: 'Monterey',
    postalCode: '01245',
  },
  'monterey-tn': {
    name: 'Monterey',
    postalCode: '38574',
  },
  'monterey-va': {
    name: 'Monterey',
    postalCode: '24465',
  },
  'monterey-park-ca': {
    name: 'Monterey Park',
    postalCode: '91754',
  },
  'monterville-wv': {
    name: 'Monterville',
    postalCode: '26282',
  },
  'montesano-wa': {
    name: 'Montesano',
    postalCode: '98563',
  },
  'montevallo-al': {
    name: 'Montevallo',
    postalCode: '35115',
  },
  'montevideo-mn': {
    name: 'Montevideo',
    postalCode: '56265',
  },
  'monteview-id': {
    name: 'Monteview',
    postalCode: '83435',
  },
  'montezuma-ga': {
    name: 'Montezuma',
    postalCode: '31063',
  },
  'montezuma-ia': {
    name: 'Montezuma',
    postalCode: '50171',
  },
  'montezuma-in': {
    name: 'Montezuma',
    postalCode: '47862',
  },
  'montezuma-ks': {
    name: 'Montezuma',
    postalCode: '67867',
  },
  'montezuma-nm': {
    name: 'Montezuma',
    postalCode: '87731',
  },
  'montfort-wi': {
    name: 'Montfort',
    postalCode: '53569',
  },
  'montgomery-al': {
    name: 'Montgomery',
    postalCode: '36104',
  },
  'montgomery-il': {
    name: 'Montgomery',
    postalCode: '60538',
  },
  'montgomery-in': {
    name: 'Montgomery',
    postalCode: '47558',
  },
  'montgomery-la': {
    name: 'Montgomery',
    postalCode: '71454',
  },
  'montgomery-mi': {
    name: 'Montgomery',
    postalCode: '49255',
  },
  'montgomery-mn': {
    name: 'Montgomery',
    postalCode: '56069',
  },
  'montgomery-ny': {
    name: 'Montgomery',
    postalCode: '12549',
  },
  'montgomery-pa': {
    name: 'Montgomery',
    postalCode: '17752',
  },
  'montgomery-tx': {
    name: 'Montgomery',
    postalCode: '77316',
  },
  'montgomery-wv': {
    name: 'Montgomery',
    postalCode: '25136',
  },
  'montgomery-center-vt': {
    name: 'Montgomery Center',
    postalCode: '05471',
  },
  'montgomery-city-mo': {
    name: 'Montgomery City',
    postalCode: '63361',
  },
  'montgomery-creek-ca': {
    name: 'Montgomery Creek',
    postalCode: '96065',
  },
  'montgomery-village-md': {
    name: 'Montgomery Village',
    postalCode: '20886',
  },
  'montgomeryville-pa': {
    name: 'Montgomeryville',
    postalCode: '18936',
  },
  'monticello-ar': {
    name: 'Monticello',
    postalCode: '71655',
  },
  'monticello-fl': {
    name: 'Monticello',
    postalCode: '32344',
  },
  'monticello-ga': {
    name: 'Monticello',
    postalCode: '31064',
  },
  'monticello-ia': {
    name: 'Monticello',
    postalCode: '52310',
  },
  'monticello-il': {
    name: 'Monticello',
    postalCode: '61856',
  },
  'monticello-in': {
    name: 'Monticello',
    postalCode: '47960',
  },
  'monticello-ky': {
    name: 'Monticello',
    postalCode: '42633',
  },
  'monticello-me': {
    name: 'Monticello',
    postalCode: '04760',
  },
  'monticello-mn': {
    name: 'Monticello',
    postalCode: '55362',
  },
  'monticello-mo': {
    name: 'Monticello',
    postalCode: '63457',
  },
  'monticello-ms': {
    name: 'Monticello',
    postalCode: '39654',
  },
  'monticello-nm': {
    name: 'Monticello',
    postalCode: '87939',
  },
  'monticello-ny': {
    name: 'Monticello',
    postalCode: '12701',
  },
  'monticello-ut': {
    name: 'Monticello',
    postalCode: '84535',
  },
  'monticello-wi': {
    name: 'Monticello',
    postalCode: '53570',
  },
  'montour-ia': {
    name: 'Montour',
    postalCode: '50173',
  },
  'montour-falls-ny': {
    name: 'Montour Falls',
    postalCode: '14865',
  },
  'montoursville-pa': {
    name: 'Montoursville',
    postalCode: '17754',
  },
  'montpelier-ia': {
    name: 'Montpelier',
    postalCode: '52759',
  },
  'montpelier-id': {
    name: 'Montpelier',
    postalCode: '83254',
  },
  'montpelier-in': {
    name: 'Montpelier',
    postalCode: '47359',
  },
  'montpelier-nd': {
    name: 'Montpelier',
    postalCode: '58472',
  },
  'montpelier-oh': {
    name: 'Montpelier',
    postalCode: '43543',
  },
  'montpelier-va': {
    name: 'Montpelier',
    postalCode: '23192',
  },
  'montpelier-vt': {
    name: 'Montpelier',
    postalCode: '05602',
  },
  'montreal-mo': {
    name: 'Montreal',
    postalCode: '65591',
  },
  'montreal-wi': {
    name: 'Montreal',
    postalCode: '54550',
  },
  'montrose-ar': {
    name: 'Montrose',
    postalCode: '71658',
  },
  'montrose-ca': {
    name: 'Montrose',
    postalCode: '91020',
  },
  'montrose-co': {
    name: 'Montrose',
    postalCode: '81401',
  },
  'montrose-ga': {
    name: 'Montrose',
    postalCode: '31065',
  },
  'montrose-ia': {
    name: 'Montrose',
    postalCode: '52639',
  },
  'montrose-il': {
    name: 'Montrose',
    postalCode: '62445',
  },
  'montrose-mi': {
    name: 'Montrose',
    postalCode: '48457',
  },
  'montrose-mn': {
    name: 'Montrose',
    postalCode: '55363',
  },
  'montrose-mo': {
    name: 'Montrose',
    postalCode: '64770',
  },
  'montrose-ny': {
    name: 'Montrose',
    postalCode: '10548',
  },
  'montrose-pa': {
    name: 'Montrose',
    postalCode: '18801',
  },
  'montrose-sd': {
    name: 'Montrose',
    postalCode: '57048',
  },
  'montrose-wv': {
    name: 'Montrose',
    postalCode: '26283',
  },
  'montross-va': {
    name: 'Montross',
    postalCode: '22520',
  },
  'montvale-nj': {
    name: 'Montvale',
    postalCode: '07645',
  },
  'montvale-va': {
    name: 'Montvale',
    postalCode: '24122',
  },
  'montverde-fl': {
    name: 'Montverde',
    postalCode: '34756',
  },
  'montville-ct': {
    name: 'Montville',
    postalCode: '06353',
  },
  'montville-nj': {
    name: 'Montville',
    postalCode: '07045',
  },
  'montville-oh': {
    name: 'Montville',
    postalCode: '44064',
  },
  'monument-co': {
    name: 'Monument',
    postalCode: '80132',
  },
  'monument-ks': {
    name: 'Monument',
    postalCode: '67747',
  },
  'monument-nm': {
    name: 'Monument',
    postalCode: '88265',
  },
  'monument-or': {
    name: 'Monument',
    postalCode: '97864',
  },
  'moodus-ct': {
    name: 'Moodus',
    postalCode: '06469',
  },
  'moody-al': {
    name: 'Moody',
    postalCode: '35004',
  },
  'moody-mo': {
    name: 'Moody',
    postalCode: '65777',
  },
  'moody-tx': {
    name: 'Moody',
    postalCode: '76557',
  },
  'mooers-ny': {
    name: 'Mooers',
    postalCode: '12958',
  },
  'mooers-forks-ny': {
    name: 'Mooers Forks',
    postalCode: '12959',
  },
  'moon-va': {
    name: 'Moon',
    postalCode: '23119',
  },
  'moonachie-nj': {
    name: 'Moonachie',
    postalCode: '07074',
  },
  'moorcroft-wy': {
    name: 'Moorcroft',
    postalCode: '82721',
  },
  'moore-id': {
    name: 'Moore',
    postalCode: '83255',
  },
  'moore-mt': {
    name: 'Moore',
    postalCode: '59464',
  },
  'moore-sc': {
    name: 'Moore',
    postalCode: '29369',
  },
  'moore-tx': {
    name: 'Moore',
    postalCode: '78057',
  },
  'moore-haven-fl': {
    name: 'Moore Haven',
    postalCode: '33471',
  },
  'moorefield-ky': {
    name: 'Moorefield',
    postalCode: '40350',
  },
  'moorefield-ne': {
    name: 'Moorefield',
    postalCode: '69039',
  },
  'moorefield-wv': {
    name: 'Moorefield',
    postalCode: '26836',
  },
  'mooreland-in': {
    name: 'Mooreland',
    postalCode: '47360',
  },
  'mooreland-ok': {
    name: 'Mooreland',
    postalCode: '73852',
  },
  'moores-hill-in': {
    name: 'Moores Hill',
    postalCode: '47032',
  },
  'mooresboro-nc': {
    name: 'Mooresboro',
    postalCode: '28114',
  },
  'mooresburg-tn': {
    name: 'Mooresburg',
    postalCode: '37811',
  },
  'moorestown-nj': {
    name: 'Moorestown',
    postalCode: '08057',
  },
  'mooresville-in': {
    name: 'Mooresville',
    postalCode: '46158',
  },
  'mooresville-mo': {
    name: 'Mooresville',
    postalCode: '64664',
  },
  'mooresville-nc': {
    name: 'Mooresville',
    postalCode: '28115',
  },
  'mooreton-nd': {
    name: 'Mooreton',
    postalCode: '58061',
  },
  'mooreville-ms': {
    name: 'Mooreville',
    postalCode: '38857',
  },
  'moorhead-ia': {
    name: 'Moorhead',
    postalCode: '51558',
  },
  'moorhead-mn': {
    name: 'Moorhead',
    postalCode: '56560',
  },
  'moorhead-ms': {
    name: 'Moorhead',
    postalCode: '38761',
  },
  'mooringsport-la': {
    name: 'Mooringsport',
    postalCode: '71060',
  },
  'moorland-ia': {
    name: 'Moorland',
    postalCode: '50566',
  },
  'moorpark-ca': {
    name: 'Moorpark',
    postalCode: '93021',
  },
  'moose-wy': {
    name: 'Moose',
    postalCode: '83012',
  },
  'moose-lake-mn': {
    name: 'Moose Lake',
    postalCode: '55767',
  },
  'moose-pass-ak': {
    name: 'Moose Pass',
    postalCode: '99631',
  },
  'mooseheart-il': {
    name: 'Mooseheart',
    postalCode: '60539',
  },
  'moosic-pa': {
    name: 'Moosic',
    postalCode: '18507',
  },
  'moosup-ct': {
    name: 'Moosup',
    postalCode: '06354',
  },
  'mora-la': {
    name: 'Mora',
    postalCode: '71455',
  },
  'mora-mn': {
    name: 'Mora',
    postalCode: '55051',
  },
  'mora-mo': {
    name: 'Mora',
    postalCode: '65345',
  },
  'mora-nm': {
    name: 'Mora',
    postalCode: '87732',
  },
  'moraga-ca': {
    name: 'Moraga',
    postalCode: '94556',
  },
  'moran-ks': {
    name: 'Moran',
    postalCode: '66755',
  },
  'moran-mi': {
    name: 'Moran',
    postalCode: '49760',
  },
  'moran-tx': {
    name: 'Moran',
    postalCode: '76464',
  },
  'moran-wy': {
    name: 'Moran',
    postalCode: '83013',
  },
  'moravia-ia': {
    name: 'Moravia',
    postalCode: '52571',
  },
  'moravia-ny': {
    name: 'Moravia',
    postalCode: '13118',
  },
  'moravian-falls-nc': {
    name: 'Moravian Falls',
    postalCode: '28654',
  },
  'moreauville-la': {
    name: 'Moreauville',
    postalCode: '71355',
  },
  'morehead-ky': {
    name: 'Morehead',
    postalCode: '40351',
  },
  'morehead-city-nc': {
    name: 'Morehead City',
    postalCode: '28557',
  },
  'moreland-ga': {
    name: 'Moreland',
    postalCode: '30259',
  },
  'morenci-az': {
    name: 'Morenci',
    postalCode: '85540',
  },
  'morenci-mi': {
    name: 'Morenci',
    postalCode: '49256',
  },
  'moreno-valley-ca': {
    name: 'Moreno Valley',
    postalCode: '92551',
  },
  'moretown-vt': {
    name: 'Moretown',
    postalCode: '05660',
  },
  'morgan-ga': {
    name: 'Morgan',
    postalCode: '39866',
  },
  'morgan-mn': {
    name: 'Morgan',
    postalCode: '56266',
  },
  'morgan-pa': {
    name: 'Morgan',
    postalCode: '15064',
  },
  'morgan-tx': {
    name: 'Morgan',
    postalCode: '76671',
  },
  'morgan-ut': {
    name: 'Morgan',
    postalCode: '84050',
  },
  'morgan-vt': {
    name: 'Morgan',
    postalCode: '05853',
  },
  'morgan-city-la': {
    name: 'Morgan City',
    postalCode: '70380',
  },
  'morgan-hill-ca': {
    name: 'Morgan Hill',
    postalCode: '95037',
  },
  'morganfield-ky': {
    name: 'Morganfield',
    postalCode: '42437',
  },
  'morganton-ga': {
    name: 'Morganton',
    postalCode: '30560',
  },
  'morganton-nc': {
    name: 'Morganton',
    postalCode: '28655',
  },
  'morgantown-in': {
    name: 'Morgantown',
    postalCode: '46160',
  },
  'morgantown-ky': {
    name: 'Morgantown',
    postalCode: '42261',
  },
  'morgantown-pa': {
    name: 'Morgantown',
    postalCode: '19543',
  },
  'morgantown-wv': {
    name: 'Morgantown',
    postalCode: '26501',
  },
  'morganville-ks': {
    name: 'Morganville',
    postalCode: '67468',
  },
  'morganville-nj': {
    name: 'Morganville',
    postalCode: '07751',
  },
  'morganza-la': {
    name: 'Morganza',
    postalCode: '70759',
  },
  'moriah-ny': {
    name: 'Moriah',
    postalCode: '12960',
  },
  'moriah-center-ny': {
    name: 'Moriah Center',
    postalCode: '12961',
  },
  'moriarty-nm': {
    name: 'Moriarty',
    postalCode: '87035',
  },
  'moriches-ny': {
    name: 'Moriches',
    postalCode: '11955',
  },
  'morland-ks': {
    name: 'Morland',
    postalCode: '67650',
  },
  'morley-ia': {
    name: 'Morley',
    postalCode: '52312',
  },
  'morley-mi': {
    name: 'Morley',
    postalCode: '49336',
  },
  'mormon-lake-az': {
    name: 'Mormon Lake',
    postalCode: '86038',
  },
  'morning-sun-ia': {
    name: 'Morning Sun',
    postalCode: '52640',
  },
  'morning-view-ky': {
    name: 'Morning View',
    postalCode: '41063',
  },
  'moro-ar': {
    name: 'Moro',
    postalCode: '72368',
  },
  'moro-il': {
    name: 'Moro',
    postalCode: '62067',
  },
  'moro-or': {
    name: 'Moro',
    postalCode: '97039',
  },
  'morocco-in': {
    name: 'Morocco',
    postalCode: '47963',
  },
  'morongo-valley-ca': {
    name: 'Morongo Valley',
    postalCode: '92256',
  },
  'morovis-pr': {
    name: 'Morovis',
    postalCode: '00687',
  },
  'morral-oh': {
    name: 'Morral',
    postalCode: '43337',
  },
  'morrice-mi': {
    name: 'Morrice',
    postalCode: '48857',
  },
  'morrill-ks': {
    name: 'Morrill',
    postalCode: '66515',
  },
  'morrill-me': {
    name: 'Morrill',
    postalCode: '04952',
  },
  'morrill-ne': {
    name: 'Morrill',
    postalCode: '69358',
  },
  'morrilton-ar': {
    name: 'Morrilton',
    postalCode: '72110',
  },
  'morris-al': {
    name: 'Morris',
    postalCode: '35116',
  },
  'morris-ct': {
    name: 'Morris',
    postalCode: '06763',
  },
  'morris-ga': {
    name: 'Morris',
    postalCode: '39867',
  },
  'morris-il': {
    name: 'Morris',
    postalCode: '60450',
  },
  'morris-mn': {
    name: 'Morris',
    postalCode: '56267',
  },
  'morris-ny': {
    name: 'Morris',
    postalCode: '13808',
  },
  'morris-ok': {
    name: 'Morris',
    postalCode: '74445',
  },
  'morris-pa': {
    name: 'Morris',
    postalCode: '16938',
  },
  'morris-chapel-tn': {
    name: 'Morris Chapel',
    postalCode: '38361',
  },
  'morris-plains-nj': {
    name: 'Morris Plains',
    postalCode: '07950',
  },
  'morrisdale-pa': {
    name: 'Morrisdale',
    postalCode: '16858',
  },
  'morrison-co': {
    name: 'Morrison',
    postalCode: '80465',
  },
  'morrison-ia': {
    name: 'Morrison',
    postalCode: '50657',
  },
  'morrison-il': {
    name: 'Morrison',
    postalCode: '61270',
  },
  'morrison-mo': {
    name: 'Morrison',
    postalCode: '65061',
  },
  'morrison-ok': {
    name: 'Morrison',
    postalCode: '73061',
  },
  'morrison-tn': {
    name: 'Morrison',
    postalCode: '37357',
  },
  'morrisonville-il': {
    name: 'Morrisonville',
    postalCode: '62546',
  },
  'morrisonville-ny': {
    name: 'Morrisonville',
    postalCode: '12962',
  },
  'morriston-fl': {
    name: 'Morriston',
    postalCode: '32668',
  },
  'morristown-az': {
    name: 'Morristown',
    postalCode: '85342',
  },
  'morristown-in': {
    name: 'Morristown',
    postalCode: '46161',
  },
  'morristown-mn': {
    name: 'Morristown',
    postalCode: '55052',
  },
  'morristown-nj': {
    name: 'Morristown',
    postalCode: '07960',
  },
  'morristown-ny': {
    name: 'Morristown',
    postalCode: '13664',
  },
  'morristown-sd': {
    name: 'Morristown',
    postalCode: '57645',
  },
  'morristown-tn': {
    name: 'Morristown',
    postalCode: '37813',
  },
  'morrisville-mo': {
    name: 'Morrisville',
    postalCode: '65710',
  },
  'morrisville-nc': {
    name: 'Morrisville',
    postalCode: '27560',
  },
  'morrisville-ny': {
    name: 'Morrisville',
    postalCode: '13408',
  },
  'morrisville-pa': {
    name: 'Morrisville',
    postalCode: '19067',
  },
  'morrisville-vt': {
    name: 'Morrisville',
    postalCode: '05661',
  },
  'morro-bay-ca': {
    name: 'Morro Bay',
    postalCode: '93442',
  },
  'morrow-ar': {
    name: 'Morrow',
    postalCode: '72749',
  },
  'morrow-ga': {
    name: 'Morrow',
    postalCode: '30260',
  },
  'morrow-la': {
    name: 'Morrow',
    postalCode: '71356',
  },
  'morrow-oh': {
    name: 'Morrow',
    postalCode: '45152',
  },
  'morrowville-ks': {
    name: 'Morrowville',
    postalCode: '66958',
  },
  'morse-la': {
    name: 'Morse',
    postalCode: '70559',
  },
  'morse-tx': {
    name: 'Morse',
    postalCode: '79062',
  },
  'morse-bluff-ne': {
    name: 'Morse Bluff',
    postalCode: '68648',
  },
  'morton-il': {
    name: 'Morton',
    postalCode: '61550',
  },
  'morton-mn': {
    name: 'Morton',
    postalCode: '56270',
  },
  'morton-ms': {
    name: 'Morton',
    postalCode: '39117',
  },
  'morton-pa': {
    name: 'Morton',
    postalCode: '19070',
  },
  'morton-tx': {
    name: 'Morton',
    postalCode: '79346',
  },
  'morton-wa': {
    name: 'Morton',
    postalCode: '98356',
  },
  'morton-grove-il': {
    name: 'Morton Grove',
    postalCode: '60053',
  },
  'morven-ga': {
    name: 'Morven',
    postalCode: '31638',
  },
  'morven-nc': {
    name: 'Morven',
    postalCode: '28119',
  },
  'morvin-al': {
    name: 'Morvin',
    postalCode: '36762',
  },
  'mosby-mt': {
    name: 'Mosby',
    postalCode: '59058',
  },
  'mosca-co': {
    name: 'Mosca',
    postalCode: '81146',
  },
  'moscow-ia': {
    name: 'Moscow',
    postalCode: '52760',
  },
  'moscow-id': {
    name: 'Moscow',
    postalCode: '83843',
  },
  'moscow-ks': {
    name: 'Moscow',
    postalCode: '67952',
  },
  'moscow-oh': {
    name: 'Moscow',
    postalCode: '45153',
  },
  'moscow-pa': {
    name: 'Moscow',
    postalCode: '18444',
  },
  'moscow-tn': {
    name: 'Moscow',
    postalCode: '38057',
  },
  'moscow-tx': {
    name: 'Moscow',
    postalCode: '75960',
  },
  'moscow-mills-mo': {
    name: 'Moscow Mills',
    postalCode: '63362',
  },
  'moseley-va': {
    name: 'Moseley',
    postalCode: '23120',
  },
  'moselle-ms': {
    name: 'Moselle',
    postalCode: '39459',
  },
  'moses-lake-wa': {
    name: 'Moses Lake',
    postalCode: '98837',
  },
  'moshannon-pa': {
    name: 'Moshannon',
    postalCode: '16859',
  },
  'mosheim-tn': {
    name: 'Mosheim',
    postalCode: '37818',
  },
  'mosier-or': {
    name: 'Mosier',
    postalCode: '97040',
  },
  'mosinee-wi': {
    name: 'Mosinee',
    postalCode: '54455',
  },
  'mosquero-nm': {
    name: 'Mosquero',
    postalCode: '87733',
  },
  'moss-tn': {
    name: 'Moss',
    postalCode: '38575',
  },
  'moss-beach-ca': {
    name: 'Moss Beach',
    postalCode: '94038',
  },
  'moss-landing-ca': {
    name: 'Moss Landing',
    postalCode: '95039',
  },
  'moss-point-ms': {
    name: 'Moss Point',
    postalCode: '39562',
  },
  'mossyrock-wa': {
    name: 'Mossyrock',
    postalCode: '98564',
  },
  'motley-mn': {
    name: 'Motley',
    postalCode: '56466',
  },
  'mott-nd': {
    name: 'Mott',
    postalCode: '58646',
  },
  'moulton-al': {
    name: 'Moulton',
    postalCode: '35650',
  },
  'moulton-ia': {
    name: 'Moulton',
    postalCode: '52572',
  },
  'moulton-tx': {
    name: 'Moulton',
    postalCode: '77975',
  },
  'moultonborough-nh': {
    name: 'Moultonborough',
    postalCode: '03254',
  },
  'moultrie-ga': {
    name: 'Moultrie',
    postalCode: '31768',
  },
  'mound-mn': {
    name: 'Mound',
    postalCode: '55364',
  },
  'mound-bayou-ms': {
    name: 'Mound Bayou',
    postalCode: '38762',
  },
  'mound-city-il': {
    name: 'Mound City',
    postalCode: '62963',
  },
  'mound-city-ks': {
    name: 'Mound City',
    postalCode: '66056',
  },
  'mound-city-mo': {
    name: 'Mound City',
    postalCode: '64470',
  },
  'mound-city-sd': {
    name: 'Mound City',
    postalCode: '57646',
  },
  'mound-valley-ks': {
    name: 'Mound Valley',
    postalCode: '67354',
  },
  'moundridge-ks': {
    name: 'Moundridge',
    postalCode: '67107',
  },
  'mounds-il': {
    name: 'Mounds',
    postalCode: '62964',
  },
  'mounds-ok': {
    name: 'Mounds',
    postalCode: '74047',
  },
  'moundsville-wv': {
    name: 'Moundsville',
    postalCode: '26041',
  },
  'moundville-al': {
    name: 'Moundville',
    postalCode: '35474',
  },
  'moundville-mo': {
    name: 'Moundville',
    postalCode: '64771',
  },
  'mount-airy-ga': {
    name: 'Mount Airy',
    postalCode: '30563',
  },
  'mount-airy-la': {
    name: 'Mount Airy',
    postalCode: '70076',
  },
  'mount-airy-md': {
    name: 'Mount Airy',
    postalCode: '21771',
  },
  'mount-airy-nc': {
    name: 'Mount Airy',
    postalCode: '27030',
  },
  'mount-alto-wv': {
    name: 'Mount Alto',
    postalCode: '25264',
  },
  'mount-angel-or': {
    name: 'Mount Angel',
    postalCode: '97362',
  },
  'mount-arlington-nj': {
    name: 'Mount Arlington',
    postalCode: '07856',
  },
  'mount-auburn-ia': {
    name: 'Mount Auburn',
    postalCode: '52313',
  },
  'mount-auburn-il': {
    name: 'Mount Auburn',
    postalCode: '62547',
  },
  'mount-ayr-ia': {
    name: 'Mount Ayr',
    postalCode: '50854',
  },
  'mount-bethel-pa': {
    name: 'Mount Bethel',
    postalCode: '18343',
  },
  'mount-blanchard-oh': {
    name: 'Mount Blanchard',
    postalCode: '45867',
  },
  'mount-calm-tx': {
    name: 'Mount Calm',
    postalCode: '76673',
  },
  'mount-calvary-wi': {
    name: 'Mount Calvary',
    postalCode: '53057',
  },
  'mount-carbon-wv': {
    name: 'Mount Carbon',
    postalCode: '25139',
  },
  'mount-carmel-il': {
    name: 'Mount Carmel',
    postalCode: '62863',
  },
  'mount-carmel-pa': {
    name: 'Mount Carmel',
    postalCode: '17851',
  },
  'mount-carmel-sc': {
    name: 'Mount Carmel',
    postalCode: '29840',
  },
  'mount-carmel-tn': {
    name: 'Mount Carmel',
    postalCode: '37645',
  },
  'mount-carroll-il': {
    name: 'Mount Carroll',
    postalCode: '61053',
  },
  'mount-clare-wv': {
    name: 'Mount Clare',
    postalCode: '26408',
  },
  'mount-clemens-mi': {
    name: 'Mount Clemens',
    postalCode: '48043',
  },
  'mount-cory-oh': {
    name: 'Mount Cory',
    postalCode: '45868',
  },
  'mount-crawford-va': {
    name: 'Mount Crawford',
    postalCode: '22841',
  },
  'mount-croghan-sc': {
    name: 'Mount Croghan',
    postalCode: '29727',
  },
  'mount-desert-me': {
    name: 'Mount Desert',
    postalCode: '04660',
  },
  'mount-dora-fl': {
    name: 'Mount Dora',
    postalCode: '32757',
  },
  'mount-eden-ky': {
    name: 'Mount Eden',
    postalCode: '40046',
  },
  'mount-enterprise-tx': {
    name: 'Mount Enterprise',
    postalCode: '75681',
  },
  'mount-ephraim-nj': {
    name: 'Mount Ephraim',
    postalCode: '08059',
  },
  'mount-erie-il': {
    name: 'Mount Erie',
    postalCode: '62446',
  },
  'mount-gilead-nc': {
    name: 'Mount Gilead',
    postalCode: '27306',
  },
  'mount-gilead-oh': {
    name: 'Mount Gilead',
    postalCode: '43338',
  },
  'mount-hamilton-ca': {
    name: 'Mount Hamilton',
    postalCode: '95140',
  },
  'mount-hermon-ky': {
    name: 'Mount Hermon',
    postalCode: '42157',
  },
  'mount-hermon-la': {
    name: 'Mount Hermon',
    postalCode: '70450',
  },
  'mount-holly-ar': {
    name: 'Mount Holly',
    postalCode: '71758',
  },
  'mount-holly-nc': {
    name: 'Mount Holly',
    postalCode: '28120',
  },
  'mount-holly-nj': {
    name: 'Mount Holly',
    postalCode: '08060',
  },
  'mount-holly-vt': {
    name: 'Mount Holly',
    postalCode: '05758',
  },
  'mount-holly-springs-pa': {
    name: 'Mount Holly Springs',
    postalCode: '17065',
  },
  'mount-hood-parkdale-or': {
    name: 'Mount Hood Parkdale',
    postalCode: '97041',
  },
  'mount-hope-al': {
    name: 'Mount Hope',
    postalCode: '35651',
  },
  'mount-hope-ks': {
    name: 'Mount Hope',
    postalCode: '67108',
  },
  'mount-hope-wi': {
    name: 'Mount Hope',
    postalCode: '53816',
  },
  'mount-hope-wv': {
    name: 'Mount Hope',
    postalCode: '25880',
  },
  'mount-horeb-wi': {
    name: 'Mount Horeb',
    postalCode: '53572',
  },
  'mount-ida-ar': {
    name: 'Mount Ida',
    postalCode: '71957',
  },
  'mount-jackson-va': {
    name: 'Mount Jackson',
    postalCode: '22842',
  },
  'mount-jewett-pa': {
    name: 'Mount Jewett',
    postalCode: '16740',
  },
  'mount-joy-pa': {
    name: 'Mount Joy',
    postalCode: '17552',
  },
  'mount-judea-ar': {
    name: 'Mount Judea',
    postalCode: '72655',
  },
  'mount-juliet-tn': {
    name: 'Mount Juliet',
    postalCode: '37122',
  },
  'mount-kisco-ny': {
    name: 'Mount Kisco',
    postalCode: '10549',
  },
  'mount-laurel-nj': {
    name: 'Mount Laurel',
    postalCode: '08054',
  },
  'mount-lookout-wv': {
    name: 'Mount Lookout',
    postalCode: '26678',
  },
  'mount-marion-ny': {
    name: 'Mount Marion',
    postalCode: '12456',
  },
  'mount-morris-il': {
    name: 'Mount Morris',
    postalCode: '61054',
  },
  'mount-morris-mi': {
    name: 'Mount Morris',
    postalCode: '48458',
  },
  'mount-morris-ny': {
    name: 'Mount Morris',
    postalCode: '14510',
  },
  'mount-morris-pa': {
    name: 'Mount Morris',
    postalCode: '15349',
  },
  'mount-nebo-wv': {
    name: 'Mount Nebo',
    postalCode: '26679',
  },
  'mount-olive-al': {
    name: 'Mount Olive',
    postalCode: '35117',
  },
  'mount-olive-il': {
    name: 'Mount Olive',
    postalCode: '62069',
  },
  'mount-olive-ms': {
    name: 'Mount Olive',
    postalCode: '39119',
  },
  'mount-olive-nc': {
    name: 'Mount Olive',
    postalCode: '28365',
  },
  'mount-olivet-ky': {
    name: 'Mount Olivet',
    postalCode: '41064',
  },
  'mount-orab-oh': {
    name: 'Mount Orab',
    postalCode: '45154',
  },
  'mount-perry-oh': {
    name: 'Mount Perry',
    postalCode: '43760',
  },
  'mount-pleasant-ar': {
    name: 'Mount Pleasant',
    postalCode: '72561',
  },
  'mount-pleasant-ia': {
    name: 'Mount Pleasant',
    postalCode: '52641',
  },
  'mount-pleasant-mi': {
    name: 'Mount Pleasant',
    postalCode: '48858',
  },
  'mount-pleasant-nc': {
    name: 'Mount Pleasant',
    postalCode: '28124',
  },
  'mount-pleasant-pa': {
    name: 'Mount Pleasant',
    postalCode: '15666',
  },
  'mount-pleasant-sc': {
    name: 'Mount Pleasant',
    postalCode: '29464',
  },
  'mount-pleasant-tn': {
    name: 'Mount Pleasant',
    postalCode: '38474',
  },
  'mount-pleasant-tx': {
    name: 'Mount Pleasant',
    postalCode: '75455',
  },
  'mount-pleasant-ut': {
    name: 'Mount Pleasant',
    postalCode: '84647',
  },
  'mount-pleasant-mills-pa': {
    name: 'Mount Pleasant Mills',
    postalCode: '17853',
  },
  'mount-pocono-pa': {
    name: 'Mount Pocono',
    postalCode: '18344',
  },
  'mount-prospect-il': {
    name: 'Mount Prospect',
    postalCode: '60056',
  },
  'mount-pulaski-il': {
    name: 'Mount Pulaski',
    postalCode: '62548',
  },
  'mount-rainier-md': {
    name: 'Mount Rainier',
    postalCode: '20712',
  },
  'mount-royal-nj': {
    name: 'Mount Royal',
    postalCode: '08061',
  },
  'mount-saint-joseph-oh': {
    name: 'Mount Saint Joseph',
    postalCode: '45051',
  },
  'mount-savage-md': {
    name: 'Mount Savage',
    postalCode: '21545',
  },
  'mount-shasta-ca': {
    name: 'Mount Shasta',
    postalCode: '96067',
  },
  'mount-sherman-ky': {
    name: 'Mount Sherman',
    postalCode: '42764',
  },
  'mount-sidney-va': {
    name: 'Mount Sidney',
    postalCode: '24467',
  },
  'mount-sinai-ny': {
    name: 'Mount Sinai',
    postalCode: '11766',
  },
  'mount-solon-va': {
    name: 'Mount Solon',
    postalCode: '22843',
  },
  'mount-sterling-ia': {
    name: 'Mount Sterling',
    postalCode: '52573',
  },
  'mount-sterling-il': {
    name: 'Mount Sterling',
    postalCode: '62353',
  },
  'mount-sterling-ky': {
    name: 'Mount Sterling',
    postalCode: '40353',
  },
  'mount-sterling-mo': {
    name: 'Mount Sterling',
    postalCode: '65062',
  },
  'mount-sterling-oh': {
    name: 'Mount Sterling',
    postalCode: '43143',
  },
  'mount-storm-wv': {
    name: 'Mount Storm',
    postalCode: '26739',
  },
  'mount-tremper-ny': {
    name: 'Mount Tremper',
    postalCode: '12457',
  },
  'mount-ulla-nc': {
    name: 'Mount Ulla',
    postalCode: '28125',
  },
  'mount-union-ia': {
    name: 'Mount Union',
    postalCode: '52644',
  },
  'mount-union-pa': {
    name: 'Mount Union',
    postalCode: '17066',
  },
  'mount-upton-ny': {
    name: 'Mount Upton',
    postalCode: '13809',
  },
  'mount-vernon-al': {
    name: 'Mount Vernon',
    postalCode: '36560',
  },
  'mount-vernon-ar': {
    name: 'Mount Vernon',
    postalCode: '72111',
  },
  'mount-vernon-ga': {
    name: 'Mount Vernon',
    postalCode: '30445',
  },
  'mount-vernon-ia': {
    name: 'Mount Vernon',
    postalCode: '52314',
  },
  'mount-vernon-il': {
    name: 'Mount Vernon',
    postalCode: '62864',
  },
  'mount-vernon-in': {
    name: 'Mount Vernon',
    postalCode: '47620',
  },
  'mount-vernon-ky': {
    name: 'Mount Vernon',
    postalCode: '40456',
  },
  'mount-vernon-me': {
    name: 'Mount Vernon',
    postalCode: '04352',
  },
  'mount-vernon-mo': {
    name: 'Mount Vernon',
    postalCode: '65712',
  },
  'mount-vernon-ny': {
    name: 'Mount Vernon',
    postalCode: '10550',
  },
  'mount-vernon-oh': {
    name: 'Mount Vernon',
    postalCode: '43050',
  },
  'mount-vernon-or': {
    name: 'Mount Vernon',
    postalCode: '97865',
  },
  'mount-vernon-sd': {
    name: 'Mount Vernon',
    postalCode: '57363',
  },
  'mount-vernon-tx': {
    name: 'Mount Vernon',
    postalCode: '75457',
  },
  'mount-vernon-wa': {
    name: 'Mount Vernon',
    postalCode: '98273',
  },
  'mount-victory-oh': {
    name: 'Mount Victory',
    postalCode: '43340',
  },
  'mount-vision-ny': {
    name: 'Mount Vision',
    postalCode: '13810',
  },
  'mount-washington-ky': {
    name: 'Mount Washington',
    postalCode: '40047',
  },
  'mount-wolf-pa': {
    name: 'Mount Wolf',
    postalCode: '17347',
  },
  'mount-zion-wv': {
    name: 'Mount Zion',
    postalCode: '26151',
  },
  'mountain-nd': {
    name: 'Mountain',
    postalCode: '58262',
  },
  'mountain-wi': {
    name: 'Mountain',
    postalCode: '54149',
  },
  'mountain-center-ca': {
    name: 'Mountain Center',
    postalCode: '92561',
  },
  'mountain-city-nv': {
    name: 'Mountain City',
    postalCode: '89831',
  },
  'mountain-city-tn': {
    name: 'Mountain City',
    postalCode: '37683',
  },
  'mountain-dale-ny': {
    name: 'Mountain Dale',
    postalCode: '12763',
  },
  'mountain-grove-mo': {
    name: 'Mountain Grove',
    postalCode: '65711',
  },
  'mountain-home-ar': {
    name: 'Mountain Home',
    postalCode: '72653',
  },
  'mountain-home-id': {
    name: 'Mountain Home',
    postalCode: '83647',
  },
  'mountain-home-tx': {
    name: 'Mountain Home',
    postalCode: '78058',
  },
  'mountain-home-ut': {
    name: 'Mountain Home',
    postalCode: '84051',
  },
  'mountain-home-afb-id': {
    name: 'Mountain Home AFB',
    postalCode: '83648',
  },
  'mountain-iron-mn': {
    name: 'Mountain Iron',
    postalCode: '55768',
  },
  'mountain-lake-mn': {
    name: 'Mountain Lake',
    postalCode: '56159',
  },
  'mountain-lakes-nj': {
    name: 'Mountain Lakes',
    postalCode: '07046',
  },
  'mountain-park-ok': {
    name: 'Mountain Park',
    postalCode: '73559',
  },
  'mountain-pass-ca': {
    name: 'Mountain Pass',
    postalCode: '92366',
  },
  'mountain-pine-ar': {
    name: 'Mountain Pine',
    postalCode: '71956',
  },
  'mountain-ranch-ca': {
    name: 'Mountain Ranch',
    postalCode: '95246',
  },
  'mountain-rest-sc': {
    name: 'Mountain Rest',
    postalCode: '29664',
  },
  'mountain-top-pa': {
    name: 'Mountain Top',
    postalCode: '18707',
  },
  'mountain-view-ar': {
    name: 'Mountain View',
    postalCode: '72560',
  },
  'mountain-view-ca': {
    name: 'Mountain View',
    postalCode: '94040',
  },
  'mountain-view-hi': {
    name: 'Mountain View',
    postalCode: '96771',
  },
  'mountain-view-mo': {
    name: 'Mountain View',
    postalCode: '65548',
  },
  'mountain-view-ok': {
    name: 'Mountain View',
    postalCode: '73062',
  },
  'mountainair-nm': {
    name: 'Mountainair',
    postalCode: '87036',
  },
  'mountainburg-ar': {
    name: 'Mountainburg',
    postalCode: '72946',
  },
  'mountainside-nj': {
    name: 'Mountainside',
    postalCode: '07092',
  },
  'mountlake-terrace-wa': {
    name: 'Mountlake Terrace',
    postalCode: '98043',
  },
  'mountville-pa': {
    name: 'Mountville',
    postalCode: '17554',
  },
  'mountville-sc': {
    name: 'Mountville',
    postalCode: '29370',
  },
  'mousie-ky': {
    name: 'Mousie',
    postalCode: '41839',
  },
  'mouth-of-wilson-va': {
    name: 'Mouth Of Wilson',
    postalCode: '24363',
  },
  'mouthcard-ky': {
    name: 'Mouthcard',
    postalCode: '41548',
  },
  'moville-ia': {
    name: 'Moville',
    postalCode: '51039',
  },
  'moweaqua-il': {
    name: 'Moweaqua',
    postalCode: '62550',
  },
  'moxee-wa': {
    name: 'Moxee',
    postalCode: '98936',
  },
  'moyers-ok': {
    name: 'Moyers',
    postalCode: '74557',
  },
  'moyie-springs-id': {
    name: 'Moyie Springs',
    postalCode: '83845',
  },
  'moyock-nc': {
    name: 'Moyock',
    postalCode: '27958',
  },
  'mozelle-ky': {
    name: 'Mozelle',
    postalCode: '40858',
  },
  'mozier-il': {
    name: 'Mozier',
    postalCode: '62070',
  },
  'mt-zion-il': {
    name: 'Mt Zion',
    postalCode: '62549',
  },
  'mud-butte-sd': {
    name: 'Mud Butte',
    postalCode: '57758',
  },
  'muenster-tx': {
    name: 'Muenster',
    postalCode: '76252',
  },
  'muir-mi': {
    name: 'Muir',
    postalCode: '48860',
  },
  'muir-pa': {
    name: 'Muir',
    postalCode: '17957',
  },
  'mukilteo-wa': {
    name: 'Mukilteo',
    postalCode: '98275',
  },
  'mukwonago-wi': {
    name: 'Mukwonago',
    postalCode: '53149',
  },
  'mulberry-ar': {
    name: 'Mulberry',
    postalCode: '72947',
  },
  'mulberry-fl': {
    name: 'Mulberry',
    postalCode: '33860',
  },
  'mulberry-in': {
    name: 'Mulberry',
    postalCode: '46058',
  },
  'mulberry-ks': {
    name: 'Mulberry',
    postalCode: '66756',
  },
  'mulberry-tn': {
    name: 'Mulberry',
    postalCode: '37359',
  },
  'mulberry-grove-il': {
    name: 'Mulberry Grove',
    postalCode: '62262',
  },
  'muldoon-tx': {
    name: 'Muldoon',
    postalCode: '78949',
  },
  'muldraugh-ky': {
    name: 'Muldraugh',
    postalCode: '40155',
  },
  'muldrow-ok': {
    name: 'Muldrow',
    postalCode: '74948',
  },
  'muleshoe-tx': {
    name: 'Muleshoe',
    postalCode: '79347',
  },
  'mulga-al': {
    name: 'Mulga',
    postalCode: '35118',
  },
  'mulhall-ok': {
    name: 'Mulhall',
    postalCode: '73063',
  },
  'mulino-or': {
    name: 'Mulino',
    postalCode: '97042',
  },
  'mulkeytown-il': {
    name: 'Mulkeytown',
    postalCode: '62865',
  },
  'mullan-id': {
    name: 'Mullan',
    postalCode: '83846',
  },
  'mullen-ne': {
    name: 'Mullen',
    postalCode: '69152',
  },
  'mullens-wv': {
    name: 'Mullens',
    postalCode: '25882',
  },
  'mullica-hill-nj': {
    name: 'Mullica Hill',
    postalCode: '08062',
  },
  'mulliken-mi': {
    name: 'Mulliken',
    postalCode: '48861',
  },
  'mullin-tx': {
    name: 'Mullin',
    postalCode: '76864',
  },
  'mullins-sc': {
    name: 'Mullins',
    postalCode: '29574',
  },
  'mullinville-ks': {
    name: 'Mullinville',
    postalCode: '67109',
  },
  'mulvane-ks': {
    name: 'Mulvane',
    postalCode: '67110',
  },
  'muncie-in': {
    name: 'Muncie',
    postalCode: '47302',
  },
  'muncy-pa': {
    name: 'Muncy',
    postalCode: '17756',
  },
  'muncy-valley-pa': {
    name: 'Muncy Valley',
    postalCode: '17758',
  },
  'munday-tx': {
    name: 'Munday',
    postalCode: '76371',
  },
  'munday-wv': {
    name: 'Munday',
    postalCode: '26152',
  },
  'mundelein-il': {
    name: 'Mundelein',
    postalCode: '60060',
  },
  'munden-ks': {
    name: 'Munden',
    postalCode: '66959',
  },
  'munford-al': {
    name: 'Munford',
    postalCode: '36268',
  },
  'munford-tn': {
    name: 'Munford',
    postalCode: '38058',
  },
  'munfordville-ky': {
    name: 'Munfordville',
    postalCode: '42765',
  },
  'munger-mi': {
    name: 'Munger',
    postalCode: '48747',
  },
  'munich-nd': {
    name: 'Munich',
    postalCode: '58352',
  },
  'munising-mi': {
    name: 'Munising',
    postalCode: '49862',
  },
  'munith-mi': {
    name: 'Munith',
    postalCode: '49259',
  },
  'munnsville-ny': {
    name: 'Munnsville',
    postalCode: '13409',
  },
  'munroe-falls-oh': {
    name: 'Munroe Falls',
    postalCode: '44262',
  },
  'munson-pa': {
    name: 'Munson',
    postalCode: '16860',
  },
  'munster-in': {
    name: 'Munster',
    postalCode: '46321',
  },
  'murchison-tx': {
    name: 'Murchison',
    postalCode: '75778',
  },
  'murdo-sd': {
    name: 'Murdo',
    postalCode: '57559',
  },
  'murdock-ks': {
    name: 'Murdock',
    postalCode: '67111',
  },
  'murdock-mn': {
    name: 'Murdock',
    postalCode: '56271',
  },
  'murdock-ne': {
    name: 'Murdock',
    postalCode: '68407',
  },
  'murfreesboro-ar': {
    name: 'Murfreesboro',
    postalCode: '71958',
  },
  'murfreesboro-nc': {
    name: 'Murfreesboro',
    postalCode: '27855',
  },
  'murfreesboro-tn': {
    name: 'Murfreesboro',
    postalCode: '37127',
  },
  'murphy-id': {
    name: 'Murphy',
    postalCode: '83650',
  },
  'murphy-nc': {
    name: 'Murphy',
    postalCode: '28906',
  },
  'murphys-ca': {
    name: 'Murphys',
    postalCode: '95247',
  },
  'murphysboro-il': {
    name: 'Murphysboro',
    postalCode: '62966',
  },
  'murray-ia': {
    name: 'Murray',
    postalCode: '50174',
  },
  'murray-id': {
    name: 'Murray',
    postalCode: '83874',
  },
  'murray-ky': {
    name: 'Murray',
    postalCode: '42071',
  },
  'murray-ne': {
    name: 'Murray',
    postalCode: '68409',
  },
  'murrayville-ga': {
    name: 'Murrayville',
    postalCode: '30564',
  },
  'murrayville-il': {
    name: 'Murrayville',
    postalCode: '62668',
  },
  'murrells-inlet-sc': {
    name: 'Murrells Inlet',
    postalCode: '29576',
  },
  'murrieta-ca': {
    name: 'Murrieta',
    postalCode: '92562',
  },
  'murrysville-pa': {
    name: 'Murrysville',
    postalCode: '15668',
  },
  'murtaugh-id': {
    name: 'Murtaugh',
    postalCode: '83344',
  },
  'muscadine-al': {
    name: 'Muscadine',
    postalCode: '36269',
  },
  'muscatine-ia': {
    name: 'Muscatine',
    postalCode: '52761',
  },
  'muscle-shoals-al': {
    name: 'Muscle Shoals',
    postalCode: '35661',
  },
  'muscoda-wi': {
    name: 'Muscoda',
    postalCode: '53573',
  },
  'muscotah-ks': {
    name: 'Muscotah',
    postalCode: '66058',
  },
  'muse-ok': {
    name: 'Muse',
    postalCode: '74949',
  },
  'musella-ga': {
    name: 'Musella',
    postalCode: '31066',
  },
  'muskego-wi': {
    name: 'Muskego',
    postalCode: '53150',
  },
  'muskegon-mi': {
    name: 'Muskegon',
    postalCode: '49440',
  },
  'muskogee-ok': {
    name: 'Muskogee',
    postalCode: '74401',
  },
  'musselshell-mt': {
    name: 'Musselshell',
    postalCode: '59059',
  },
  'mustang-ok': {
    name: 'Mustang',
    postalCode: '73064',
  },
  'mustoe-va': {
    name: 'Mustoe',
    postalCode: '24468',
  },
  'mutual-ok': {
    name: 'Mutual',
    postalCode: '73853',
  },
  'myakka-city-fl': {
    name: 'Myakka City',
    postalCode: '34251',
  },
  'myers-flat-ca': {
    name: 'Myers Flat',
    postalCode: '95554',
  },
  'myerstown-pa': {
    name: 'Myerstown',
    postalCode: '17067',
  },
  'myersville-md': {
    name: 'Myersville',
    postalCode: '21773',
  },
  'mylo-nd': {
    name: 'Mylo',
    postalCode: '58353',
  },
  'myra-wv': {
    name: 'Myra',
    postalCode: '25544',
  },
  'myrtle-mo': {
    name: 'Myrtle',
    postalCode: '65778',
  },
  'myrtle-ms': {
    name: 'Myrtle',
    postalCode: '38650',
  },
  'myrtle-beach-sc': {
    name: 'Myrtle Beach',
    postalCode: '29572',
  },
  'myrtle-creek-or': {
    name: 'Myrtle Creek',
    postalCode: '97457',
  },
  'myrtle-point-or': {
    name: 'Myrtle Point',
    postalCode: '97458',
  },
  'mystic-ct': {
    name: 'Mystic',
    postalCode: '06355',
  },
  'mystic-ia': {
    name: 'Mystic',
    postalCode: '52574',
  },
  'myton-ut': {
    name: 'Myton',
    postalCode: '84052',
  },
  'nabb-in': {
    name: 'Nabb',
    postalCode: '47147',
  },
  'naches-wa': {
    name: 'Naches',
    postalCode: '98929',
  },
  'nacogdoches-tx': {
    name: 'Nacogdoches',
    postalCode: '75961',
  },
  'nags-head-nc': {
    name: 'Nags Head',
    postalCode: '27959',
  },
  'naguabo-pr': {
    name: 'Naguabo',
    postalCode: '00718',
  },
  'nahant-ma': {
    name: 'Nahant',
    postalCode: '01908',
  },
  'nahunta-ga': {
    name: 'Nahunta',
    postalCode: '31553',
  },
  'nakina-nc': {
    name: 'Nakina',
    postalCode: '28455',
  },
  'nallen-wv': {
    name: 'Nallen',
    postalCode: '26680',
  },
  'nampa-id': {
    name: 'Nampa',
    postalCode: '83651',
  },
  'nancy-ky': {
    name: 'Nancy',
    postalCode: '42544',
  },
  'nanjemoy-md': {
    name: 'Nanjemoy',
    postalCode: '20662',
  },
  'nanticoke-md': {
    name: 'Nanticoke',
    postalCode: '21840',
  },
  'nanticoke-pa': {
    name: 'Nanticoke',
    postalCode: '18634',
  },
  'nantucket-ma': {
    name: 'Nantucket',
    postalCode: '02554',
  },
  'nanty-glo-pa': {
    name: 'Nanty Glo',
    postalCode: '15943',
  },
  'nanuet-ny': {
    name: 'Nanuet',
    postalCode: '10954',
  },
  'naoma-wv': {
    name: 'Naoma',
    postalCode: '25140',
  },
  'napa-ca': {
    name: 'Napa',
    postalCode: '94558',
  },
  'napanoch-ny': {
    name: 'Napanoch',
    postalCode: '12458',
  },
  'naper-ne': {
    name: 'Naper',
    postalCode: '68755',
  },
  'naperville-il': {
    name: 'Naperville',
    postalCode: '60540',
  },
  'napier-wv': {
    name: 'Napier',
    postalCode: '26631',
  },
  'naples-fl': {
    name: 'Naples',
    postalCode: '34102',
  },
  'naples-id': {
    name: 'Naples',
    postalCode: '83847',
  },
  'naples-me': {
    name: 'Naples',
    postalCode: '04055',
  },
  'naples-ny': {
    name: 'Naples',
    postalCode: '14512',
  },
  'naples-tx': {
    name: 'Naples',
    postalCode: '75568',
  },
  'napoleon-mo': {
    name: 'Napoleon',
    postalCode: '64074',
  },
  'napoleon-nd': {
    name: 'Napoleon',
    postalCode: '58561',
  },
  'napoleon-oh': {
    name: 'Napoleon',
    postalCode: '43545',
  },
  'napoleonville-la': {
    name: 'Napoleonville',
    postalCode: '70390',
  },
  'naponee-ne': {
    name: 'Naponee',
    postalCode: '68960',
  },
  'nappanee-in': {
    name: 'Nappanee',
    postalCode: '46550',
  },
  'nara-visa-nm': {
    name: 'Nara Visa',
    postalCode: '88430',
  },
  'naranjito-pr': {
    name: 'Naranjito',
    postalCode: '00719',
  },
  'narberth-pa': {
    name: 'Narberth',
    postalCode: '19072',
  },
  'nardin-ok': {
    name: 'Nardin',
    postalCode: '74646',
  },
  'narka-ks': {
    name: 'Narka',
    postalCode: '66960',
  },
  'narragansett-ri': {
    name: 'Narragansett',
    postalCode: '02882',
  },
  'narrows-va': {
    name: 'Narrows',
    postalCode: '24124',
  },
  'narrowsburg-ny': {
    name: 'Narrowsburg',
    postalCode: '12764',
  },
  'narvon-pa': {
    name: 'Narvon',
    postalCode: '17555',
  },
  'naselle-wa': {
    name: 'Naselle',
    postalCode: '98638',
  },
  'nash-ok': {
    name: 'Nash',
    postalCode: '73761',
  },
  'nash-tx': {
    name: 'Nash',
    postalCode: '75569',
  },
  'nashoba-ok': {
    name: 'Nashoba',
    postalCode: '74558',
  },
  'nashotah-wi': {
    name: 'Nashotah',
    postalCode: '53058',
  },
  'nashport-oh': {
    name: 'Nashport',
    postalCode: '43830',
  },
  'nashua-ia': {
    name: 'Nashua',
    postalCode: '50658',
  },
  'nashua-mn': {
    name: 'Nashua',
    postalCode: '56565',
  },
  'nashua-mt': {
    name: 'Nashua',
    postalCode: '59248',
  },
  'nashua-nh': {
    name: 'Nashua',
    postalCode: '03060',
  },
  'nashville-ar': {
    name: 'Nashville',
    postalCode: '71852',
  },
  'nashville-ga': {
    name: 'Nashville',
    postalCode: '31639',
  },
  'nashville-il': {
    name: 'Nashville',
    postalCode: '62263',
  },
  'nashville-in': {
    name: 'Nashville',
    postalCode: '47448',
  },
  'nashville-ks': {
    name: 'Nashville',
    postalCode: '67112',
  },
  'nashville-mi': {
    name: 'Nashville',
    postalCode: '49073',
  },
  'nashville-nc': {
    name: 'Nashville',
    postalCode: '27856',
  },
  'nashville-tn': {
    name: 'Nashville',
    postalCode: '37201',
  },
  'nashwauk-mn': {
    name: 'Nashwauk',
    postalCode: '55769',
  },
  'nassau-ny': {
    name: 'Nassau',
    postalCode: '12123',
  },
  'natalia-tx': {
    name: 'Natalia',
    postalCode: '78059',
  },
  'natchez-la': {
    name: 'Natchez',
    postalCode: '71456',
  },
  'natchez-ms': {
    name: 'Natchez',
    postalCode: '39120',
  },
  'natchitoches-la': {
    name: 'Natchitoches',
    postalCode: '71457',
  },
  'nathalie-va': {
    name: 'Nathalie',
    postalCode: '24577',
  },
  'nathrop-co': {
    name: 'Nathrop',
    postalCode: '81236',
  },
  'natick-ma': {
    name: 'Natick',
    postalCode: '01760',
  },
  'national-city-ca': {
    name: 'National City',
    postalCode: '91950',
  },
  'national-city-mi': {
    name: 'National City',
    postalCode: '48748',
  },
  'national-park-nj': {
    name: 'National Park',
    postalCode: '08063',
  },
  'natoma-ks': {
    name: 'Natoma',
    postalCode: '67651',
  },
  'natrona-wy': {
    name: 'Natrona',
    postalCode: '82646',
  },
  'natrona-heights-pa': {
    name: 'Natrona Heights',
    postalCode: '15065',
  },
  'natural-bridge-ny': {
    name: 'Natural Bridge',
    postalCode: '13665',
  },
  'natural-bridge-va': {
    name: 'Natural Bridge',
    postalCode: '24578',
  },
  'natural-bridge-station-va': {
    name: 'Natural Bridge Station',
    postalCode: '24579',
  },
  'natural-dam-ar': {
    name: 'Natural Dam',
    postalCode: '72948',
  },
  'naturita-co': {
    name: 'Naturita',
    postalCode: '81422',
  },
  'naubinway-mi': {
    name: 'Naubinway',
    postalCode: '49762',
  },
  'naugatuck-ct': {
    name: 'Naugatuck',
    postalCode: '06770',
  },
  'nauvoo-al': {
    name: 'Nauvoo',
    postalCode: '35578',
  },
  'nauvoo-il': {
    name: 'Nauvoo',
    postalCode: '62354',
  },
  'navajo-dam-nm': {
    name: 'Navajo Dam',
    postalCode: '87419',
  },
  'naval-air-station-jrb-tx': {
    name: 'Naval Air Station Jrb',
    postalCode: '76127',
  },
  'navarre-fl': {
    name: 'Navarre',
    postalCode: '32566',
  },
  'navarre-oh': {
    name: 'Navarre',
    postalCode: '44662',
  },
  'navarro-ca': {
    name: 'Navarro',
    postalCode: '95463',
  },
  'navasota-tx': {
    name: 'Navasota',
    postalCode: '77868',
  },
  'naylor-ga': {
    name: 'Naylor',
    postalCode: '31641',
  },
  'naylor-mo': {
    name: 'Naylor',
    postalCode: '63953',
  },
  'naytahwaush-mn': {
    name: 'Naytahwaush',
    postalCode: '56566',
  },
  'nazareth-pa': {
    name: 'Nazareth',
    postalCode: '18064',
  },
  'nazareth-tx': {
    name: 'Nazareth',
    postalCode: '79063',
  },
  'nebo-il': {
    name: 'Nebo',
    postalCode: '62355',
  },
  'nebo-ky': {
    name: 'Nebo',
    postalCode: '42441',
  },
  'nebo-nc': {
    name: 'Nebo',
    postalCode: '28761',
  },
  'nebo-wv': {
    name: 'Nebo',
    postalCode: '25141',
  },
  'nebraska-city-ne': {
    name: 'Nebraska City',
    postalCode: '68410',
  },
  'necedah-wi': {
    name: 'Necedah',
    postalCode: '54646',
  },
  'neche-nd': {
    name: 'Neche',
    postalCode: '58265',
  },
  'nederland-co': {
    name: 'Nederland',
    postalCode: '80466',
  },
  'nederland-tx': {
    name: 'Nederland',
    postalCode: '77627',
  },
  'nedrow-ny': {
    name: 'Nedrow',
    postalCode: '13120',
  },
  'needham-al': {
    name: 'Needham',
    postalCode: '36915',
  },
  'needham-in': {
    name: 'Needham',
    postalCode: '46162',
  },
  'needham-ma': {
    name: 'Needham',
    postalCode: '02492',
  },
  'needham-heights-ma': {
    name: 'Needham Heights',
    postalCode: '02494',
  },
  'needles-ca': {
    name: 'Needles',
    postalCode: '92363',
  },
  'needmore-pa': {
    name: 'Needmore',
    postalCode: '17238',
  },
  'needville-tx': {
    name: 'Needville',
    postalCode: '77461',
  },
  'neely-ms': {
    name: 'Neely',
    postalCode: '39461',
  },
  'neelyton-pa': {
    name: 'Neelyton',
    postalCode: '17239',
  },
  'neelyville-mo': {
    name: 'Neelyville',
    postalCode: '63954',
  },
  'neenah-wi': {
    name: 'Neenah',
    postalCode: '54956',
  },
  'neeses-sc': {
    name: 'Neeses',
    postalCode: '29107',
  },
  'negaunee-mi': {
    name: 'Negaunee',
    postalCode: '49866',
  },
  'negley-oh': {
    name: 'Negley',
    postalCode: '44441',
  },
  'nehalem-or': {
    name: 'Nehalem',
    postalCode: '97131',
  },
  'nehawka-ne': {
    name: 'Nehawka',
    postalCode: '68413',
  },
  'neihart-mt': {
    name: 'Neihart',
    postalCode: '59465',
  },
  'neillsville-wi': {
    name: 'Neillsville',
    postalCode: '54456',
  },
  'nekoma-ks': {
    name: 'Nekoma',
    postalCode: '67559',
  },
  'nekoma-nd': {
    name: 'Nekoma',
    postalCode: '58355',
  },
  'nekoosa-wi': {
    name: 'Nekoosa',
    postalCode: '54457',
  },
  'neligh-ne': {
    name: 'Neligh',
    postalCode: '68756',
  },
  'nellis-wv': {
    name: 'Nellis',
    postalCode: '25142',
  },
  'nellysford-va': {
    name: 'Nellysford',
    postalCode: '22958',
  },
  'nelson-mn': {
    name: 'Nelson',
    postalCode: '56355',
  },
  'nelson-mo': {
    name: 'Nelson',
    postalCode: '65347',
  },
  'nelson-ne': {
    name: 'Nelson',
    postalCode: '68961',
  },
  'nelson-nh': {
    name: 'Nelson',
    postalCode: '03457',
  },
  'nelson-va': {
    name: 'Nelson',
    postalCode: '24580',
  },
  'nelson-wi': {
    name: 'Nelson',
    postalCode: '54756',
  },
  'nelsonville-oh': {
    name: 'Nelsonville',
    postalCode: '45764',
  },
  'nemaha-ia': {
    name: 'Nemaha',
    postalCode: '50567',
  },
  'nemaha-ne': {
    name: 'Nemaha',
    postalCode: '68414',
  },
  'nemo-sd': {
    name: 'Nemo',
    postalCode: '57759',
  },
  'nemo-tx': {
    name: 'Nemo',
    postalCode: '76070',
  },
  'nenana-ak': {
    name: 'Nenana',
    postalCode: '99760',
  },
  'nenzel-ne': {
    name: 'Nenzel',
    postalCode: '69219',
  },
  'neodesha-ks': {
    name: 'Neodesha',
    postalCode: '66757',
  },
  'neoga-il': {
    name: 'Neoga',
    postalCode: '62447',
  },
  'neola-ia': {
    name: 'Neola',
    postalCode: '51559',
  },
  'neola-ut': {
    name: 'Neola',
    postalCode: '84053',
  },
  'neon-ky': {
    name: 'Neon',
    postalCode: '41840',
  },
  'neopit-wi': {
    name: 'Neopit',
    postalCode: '54150',
  },
  'neosho-mo': {
    name: 'Neosho',
    postalCode: '64850',
  },
  'neosho-wi': {
    name: 'Neosho',
    postalCode: '53059',
  },
  'neosho-falls-ks': {
    name: 'Neosho Falls',
    postalCode: '66758',
  },
  'neosho-rapids-ks': {
    name: 'Neosho Rapids',
    postalCode: '66864',
  },
  'neotsu-or': {
    name: 'Neotsu',
    postalCode: '97364',
  },
  'nephi-ut': {
    name: 'Nephi',
    postalCode: '84648',
  },
  'neponset-il': {
    name: 'Neponset',
    postalCode: '61345',
  },
  'neptune-nj': {
    name: 'Neptune',
    postalCode: '07753',
  },
  'neptune-beach-fl': {
    name: 'Neptune Beach',
    postalCode: '32266',
  },
  'nerstrand-mn': {
    name: 'Nerstrand',
    postalCode: '55053',
  },
  'nesbit-ms': {
    name: 'Nesbit',
    postalCode: '38651',
  },
  'nesconset-ny': {
    name: 'Nesconset',
    postalCode: '11767',
  },
  'nescopeck-pa': {
    name: 'Nescopeck',
    postalCode: '18635',
  },
  'neshanic-station-nj': {
    name: 'Neshanic Station',
    postalCode: '08853',
  },
  'neshkoro-wi': {
    name: 'Neshkoro',
    postalCode: '54960',
  },
  'neskowin-or': {
    name: 'Neskowin',
    postalCode: '97149',
  },
  'nesmith-sc': {
    name: 'Nesmith',
    postalCode: '29580',
  },
  'nesquehoning-pa': {
    name: 'Nesquehoning',
    postalCode: '18240',
  },
  'ness-city-ks': {
    name: 'Ness City',
    postalCode: '67560',
  },
  'netawaka-ks': {
    name: 'Netawaka',
    postalCode: '66516',
  },
  'netcong-nj': {
    name: 'Netcong',
    postalCode: '07857',
  },
  'nettie-wv': {
    name: 'Nettie',
    postalCode: '26681',
  },
  'nettleton-ms': {
    name: 'Nettleton',
    postalCode: '38858',
  },
  'nevada-ia': {
    name: 'Nevada',
    postalCode: '50201',
  },
  'nevada-mo': {
    name: 'Nevada',
    postalCode: '64772',
  },
  'nevada-oh': {
    name: 'Nevada',
    postalCode: '44849',
  },
  'nevada-tx': {
    name: 'Nevada',
    postalCode: '75173',
  },
  'nevada-city-ca': {
    name: 'Nevada City',
    postalCode: '95959',
  },
  'neversink-ny': {
    name: 'Neversink',
    postalCode: '12765',
  },
  'nevis-mn': {
    name: 'Nevis',
    postalCode: '56467',
  },
  'nevisdale-ky': {
    name: 'Nevisdale',
    postalCode: '40754',
  },
  'new-albany-in': {
    name: 'New Albany',
    postalCode: '47150',
  },
  'new-albany-ms': {
    name: 'New Albany',
    postalCode: '38652',
  },
  'new-albany-oh': {
    name: 'New Albany',
    postalCode: '43054',
  },
  'new-albany-pa': {
    name: 'New Albany',
    postalCode: '18833',
  },
  'new-albin-ia': {
    name: 'New Albin',
    postalCode: '52160',
  },
  'new-alexandria-pa': {
    name: 'New Alexandria',
    postalCode: '15670',
  },
  'new-athens-il': {
    name: 'New Athens',
    postalCode: '62264',
  },
  'new-auburn-wi': {
    name: 'New Auburn',
    postalCode: '54757',
  },
  'new-augusta-ms': {
    name: 'New Augusta',
    postalCode: '39462',
  },
  'new-baden-il': {
    name: 'New Baden',
    postalCode: '62265',
  },
  'new-baltimore-mi': {
    name: 'New Baltimore',
    postalCode: '48047',
  },
  'new-bavaria-oh': {
    name: 'New Bavaria',
    postalCode: '43548',
  },
  'new-bedford-ma': {
    name: 'New Bedford',
    postalCode: '02740',
  },
  'new-berlin-il': {
    name: 'New Berlin',
    postalCode: '62670',
  },
  'new-berlin-ny': {
    name: 'New Berlin',
    postalCode: '13411',
  },
  'new-berlin-pa': {
    name: 'New Berlin',
    postalCode: '17855',
  },
  'new-berlin-wi': {
    name: 'New Berlin',
    postalCode: '53146',
  },
  'new-bern-nc': {
    name: 'New Bern',
    postalCode: '28560',
  },
  'new-bethlehem-pa': {
    name: 'New Bethlehem',
    postalCode: '16242',
  },
  'new-blaine-ar': {
    name: 'New Blaine',
    postalCode: '72851',
  },
  'new-bloomfield-mo': {
    name: 'New Bloomfield',
    postalCode: '65063',
  },
  'new-bloomfield-pa': {
    name: 'New Bloomfield',
    postalCode: '17068',
  },
  'new-bloomington-oh': {
    name: 'New Bloomington',
    postalCode: '43341',
  },
  'new-boston-il': {
    name: 'New Boston',
    postalCode: '61272',
  },
  'new-boston-mi': {
    name: 'New Boston',
    postalCode: '48164',
  },
  'new-boston-mo': {
    name: 'New Boston',
    postalCode: '63557',
  },
  'new-boston-nh': {
    name: 'New Boston',
    postalCode: '03070',
  },
  'new-boston-tx': {
    name: 'New Boston',
    postalCode: '75570',
  },
  'new-braintree-ma': {
    name: 'New Braintree',
    postalCode: '01531',
  },
  'new-braunfels-tx': {
    name: 'New Braunfels',
    postalCode: '78130',
  },
  'new-bremen-oh': {
    name: 'New Bremen',
    postalCode: '45869',
  },
  'new-brighton-pa': {
    name: 'New Brighton',
    postalCode: '15066',
  },
  'new-britain-ct': {
    name: 'New Britain',
    postalCode: '06051',
  },
  'new-brockton-al': {
    name: 'New Brockton',
    postalCode: '36351',
  },
  'new-brunswick-nj': {
    name: 'New Brunswick',
    postalCode: '08901',
  },
  'new-buffalo-mi': {
    name: 'New Buffalo',
    postalCode: '49117',
  },
  'new-burnside-il': {
    name: 'New Burnside',
    postalCode: '62967',
  },
  'new-cambria-ks': {
    name: 'New Cambria',
    postalCode: '67470',
  },
  'new-cambria-mo': {
    name: 'New Cambria',
    postalCode: '63558',
  },
  'new-canaan-ct': {
    name: 'New Canaan',
    postalCode: '06840',
  },
  'new-caney-tx': {
    name: 'New Caney',
    postalCode: '77357',
  },
  'new-canton-il': {
    name: 'New Canton',
    postalCode: '62356',
  },
  'new-canton-va': {
    name: 'New Canton',
    postalCode: '23123',
  },
  'new-carlisle-in': {
    name: 'New Carlisle',
    postalCode: '46552',
  },
  'new-carlisle-oh': {
    name: 'New Carlisle',
    postalCode: '45344',
  },
  'new-castle-co': {
    name: 'New Castle',
    postalCode: '81647',
  },
  'new-castle-de': {
    name: 'New Castle',
    postalCode: '19720',
  },
  'new-castle-in': {
    name: 'New Castle',
    postalCode: '47362',
  },
  'new-castle-ky': {
    name: 'New Castle',
    postalCode: '40050',
  },
  'new-castle-pa': {
    name: 'New Castle',
    postalCode: '16101',
  },
  'new-castle-va': {
    name: 'New Castle',
    postalCode: '24127',
  },
  'new-century-ks': {
    name: 'New Century',
    postalCode: '66031',
  },
  'new-church-va': {
    name: 'New Church',
    postalCode: '23415',
  },
  'new-city-ny': {
    name: 'New City',
    postalCode: '10956',
  },
  'new-columbia-pa': {
    name: 'New Columbia',
    postalCode: '17856',
  },
  'new-concord-ky': {
    name: 'New Concord',
    postalCode: '42076',
  },
  'new-concord-oh': {
    name: 'New Concord',
    postalCode: '43762',
  },
  'new-creek-wv': {
    name: 'New Creek',
    postalCode: '26743',
  },
  'new-cumberland-pa': {
    name: 'New Cumberland',
    postalCode: '17070',
  },
  'new-cumberland-wv': {
    name: 'New Cumberland',
    postalCode: '26047',
  },
  'new-cuyama-ca': {
    name: 'New Cuyama',
    postalCode: '93254',
  },
  'new-derry-pa': {
    name: 'New Derry',
    postalCode: '15671',
  },
  'new-douglas-il': {
    name: 'New Douglas',
    postalCode: '62074',
  },
  'new-durham-nh': {
    name: 'New Durham',
    postalCode: '03855',
  },
  'new-eagle-pa': {
    name: 'New Eagle',
    postalCode: '15067',
  },
  'new-edinburg-ar': {
    name: 'New Edinburg',
    postalCode: '71660',
  },
  'new-effington-sd': {
    name: 'New Effington',
    postalCode: '57255',
  },
  'new-egypt-nj': {
    name: 'New Egypt',
    postalCode: '08533',
  },
  'new-ellenton-sc': {
    name: 'New Ellenton',
    postalCode: '29809',
  },
  'new-england-nd': {
    name: 'New England',
    postalCode: '58647',
  },
  'new-enterprise-pa': {
    name: 'New Enterprise',
    postalCode: '16664',
  },
  'new-era-mi': {
    name: 'New Era',
    postalCode: '49446',
  },
  'new-fairfield-ct': {
    name: 'New Fairfield',
    postalCode: '06812',
  },
  'new-florence-mo': {
    name: 'New Florence',
    postalCode: '63363',
  },
  'new-florence-pa': {
    name: 'New Florence',
    postalCode: '15944',
  },
  'new-franken-wi': {
    name: 'New Franken',
    postalCode: '54229',
  },
  'new-franklin-mo': {
    name: 'New Franklin',
    postalCode: '65274',
  },
  'new-freedom-pa': {
    name: 'New Freedom',
    postalCode: '17349',
  },
  'new-freeport-pa': {
    name: 'New Freeport',
    postalCode: '15352',
  },
  'new-galilee-pa': {
    name: 'New Galilee',
    postalCode: '16141',
  },
  'new-germany-mn': {
    name: 'New Germany',
    postalCode: '55367',
  },
  'new-glarus-wi': {
    name: 'New Glarus',
    postalCode: '53574',
  },
  'new-gloucester-me': {
    name: 'New Gloucester',
    postalCode: '04260',
  },
  'new-hampton-ia': {
    name: 'New Hampton',
    postalCode: '50659',
  },
  'new-hampton-mo': {
    name: 'New Hampton',
    postalCode: '64471',
  },
  'new-hampton-nh': {
    name: 'New Hampton',
    postalCode: '03256',
  },
  'new-hampton-ny': {
    name: 'New Hampton',
    postalCode: '10958',
  },
  'new-harbor-me': {
    name: 'New Harbor',
    postalCode: '04554',
  },
  'new-harmony-in': {
    name: 'New Harmony',
    postalCode: '47631',
  },
  'new-harmony-ut': {
    name: 'New Harmony',
    postalCode: '84757',
  },
  'new-hartford-ct': {
    name: 'New Hartford',
    postalCode: '06057',
  },
  'new-hartford-ia': {
    name: 'New Hartford',
    postalCode: '50660',
  },
  'new-hartford-ny': {
    name: 'New Hartford',
    postalCode: '13413',
  },
  'new-haven-ct': {
    name: 'New Haven',
    postalCode: '06510',
  },
  'new-haven-il': {
    name: 'New Haven',
    postalCode: '62867',
  },
  'new-haven-in': {
    name: 'New Haven',
    postalCode: '46774',
  },
  'new-haven-ky': {
    name: 'New Haven',
    postalCode: '40051',
  },
  'new-haven-mi': {
    name: 'New Haven',
    postalCode: '48048',
  },
  'new-haven-mo': {
    name: 'New Haven',
    postalCode: '63068',
  },
  'new-haven-vt': {
    name: 'New Haven',
    postalCode: '05472',
  },
  'new-hill-nc': {
    name: 'New Hill',
    postalCode: '27562',
  },
  'new-holland-il': {
    name: 'New Holland',
    postalCode: '62671',
  },
  'new-holland-oh': {
    name: 'New Holland',
    postalCode: '43145',
  },
  'new-holland-pa': {
    name: 'New Holland',
    postalCode: '17557',
  },
  'new-holland-sd': {
    name: 'New Holland',
    postalCode: '57364',
  },
  'new-holstein-wi': {
    name: 'New Holstein',
    postalCode: '53061',
  },
  'new-hope-al': {
    name: 'New Hope',
    postalCode: '35760',
  },
  'new-hope-ky': {
    name: 'New Hope',
    postalCode: '40052',
  },
  'new-hope-pa': {
    name: 'New Hope',
    postalCode: '18938',
  },
  'new-hudson-mi': {
    name: 'New Hudson',
    postalCode: '48165',
  },
  'new-hyde-park-ny': {
    name: 'New Hyde Park',
    postalCode: '11040',
  },
  'new-iberia-la': {
    name: 'New Iberia',
    postalCode: '70560',
  },
  'new-ipswich-nh': {
    name: 'New Ipswich',
    postalCode: '03071',
  },
  'new-johnsonville-tn': {
    name: 'New Johnsonville',
    postalCode: '37134',
  },
  'new-kensington-pa': {
    name: 'New Kensington',
    postalCode: '15068',
  },
  'new-kent-va': {
    name: 'New Kent',
    postalCode: '23124',
  },
  'new-knoxville-oh': {
    name: 'New Knoxville',
    postalCode: '45871',
  },
  'new-lebanon-ny': {
    name: 'New Lebanon',
    postalCode: '12125',
  },
  'new-lebanon-oh': {
    name: 'New Lebanon',
    postalCode: '45345',
  },
  'new-leipzig-nd': {
    name: 'New Leipzig',
    postalCode: '58562',
  },
  'new-lenox-il': {
    name: 'New Lenox',
    postalCode: '60451',
  },
  'new-lexington-oh': {
    name: 'New Lexington',
    postalCode: '43764',
  },
  'new-liberty-ia': {
    name: 'New Liberty',
    postalCode: '52765',
  },
  'new-liberty-ky': {
    name: 'New Liberty',
    postalCode: '40355',
  },
  'new-limerick-me': {
    name: 'New Limerick',
    postalCode: '04761',
  },
  'new-lisbon-ny': {
    name: 'New Lisbon',
    postalCode: '13415',
  },
  'new-lisbon-wi': {
    name: 'New Lisbon',
    postalCode: '53950',
  },
  'new-llano-la': {
    name: 'New Llano',
    postalCode: '71461',
  },
  'new-london-ct': {
    name: 'New London',
    postalCode: '06320',
  },
  'new-london-ia': {
    name: 'New London',
    postalCode: '52645',
  },
  'new-london-mn': {
    name: 'New London',
    postalCode: '56273',
  },
  'new-london-mo': {
    name: 'New London',
    postalCode: '63459',
  },
  'new-london-nc': {
    name: 'New London',
    postalCode: '28127',
  },
  'new-london-nh': {
    name: 'New London',
    postalCode: '03257',
  },
  'new-london-oh': {
    name: 'New London',
    postalCode: '44851',
  },
  'new-london-wi': {
    name: 'New London',
    postalCode: '54961',
  },
  'new-lothrop-mi': {
    name: 'New Lothrop',
    postalCode: '48460',
  },
  'new-madison-oh': {
    name: 'New Madison',
    postalCode: '45346',
  },
  'new-madrid-mo': {
    name: 'New Madrid',
    postalCode: '63869',
  },
  'new-market-al': {
    name: 'New Market',
    postalCode: '35761',
  },
  'new-market-ia': {
    name: 'New Market',
    postalCode: '51646',
  },
  'new-market-md': {
    name: 'New Market',
    postalCode: '21774',
  },
  'new-market-tn': {
    name: 'New Market',
    postalCode: '37820',
  },
  'new-market-va': {
    name: 'New Market',
    postalCode: '22844',
  },
  'new-marshfield-oh': {
    name: 'New Marshfield',
    postalCode: '45766',
  },
  'new-martinsville-wv': {
    name: 'New Martinsville',
    postalCode: '26155',
  },
  'new-matamoras-oh': {
    name: 'New Matamoras',
    postalCode: '45767',
  },
  'new-meadows-id': {
    name: 'New Meadows',
    postalCode: '83654',
  },
  'new-middletown-in': {
    name: 'New Middletown',
    postalCode: '47160',
  },
  'new-middletown-oh': {
    name: 'New Middletown',
    postalCode: '44442',
  },
  'new-milford-ct': {
    name: 'New Milford',
    postalCode: '06776',
  },
  'new-milford-nj': {
    name: 'New Milford',
    postalCode: '07646',
  },
  'new-milford-pa': {
    name: 'New Milford',
    postalCode: '18834',
  },
  'new-millport-pa': {
    name: 'New Millport',
    postalCode: '16861',
  },
  'new-milton-wv': {
    name: 'New Milton',
    postalCode: '26411',
  },
  'new-orleans-la': {
    name: 'New Orleans',
    postalCode: '70112',
  },
  'new-oxford-pa': {
    name: 'New Oxford',
    postalCode: '17350',
  },
  'new-palestine-in': {
    name: 'New Palestine',
    postalCode: '46163',
  },
  'new-paltz-ny': {
    name: 'New Paltz',
    postalCode: '12561',
  },
  'new-paris-in': {
    name: 'New Paris',
    postalCode: '46553',
  },
  'new-paris-oh': {
    name: 'New Paris',
    postalCode: '45347',
  },
  'new-paris-pa': {
    name: 'New Paris',
    postalCode: '15554',
  },
  'new-park-pa': {
    name: 'New Park',
    postalCode: '17352',
  },
  'new-philadelphia-oh': {
    name: 'New Philadelphia',
    postalCode: '44663',
  },
  'new-philadelphia-pa': {
    name: 'New Philadelphia',
    postalCode: '17959',
  },
  'new-pine-creek-or': {
    name: 'New Pine Creek',
    postalCode: '97635',
  },
  'new-plymouth-id': {
    name: 'New Plymouth',
    postalCode: '83655',
  },
  'new-plymouth-oh': {
    name: 'New Plymouth',
    postalCode: '45654',
  },
  'new-point-va': {
    name: 'New Point',
    postalCode: '23125',
  },
  'new-port-richey-fl': {
    name: 'New Port Richey',
    postalCode: '34652',
  },
  'new-portland-me': {
    name: 'New Portland',
    postalCode: '04961',
  },
  'new-prague-mn': {
    name: 'New Prague',
    postalCode: '56071',
  },
  'new-preston-marble-dale-ct': {
    name: 'New Preston Marble Dale',
    postalCode: '06777',
  },
  'new-providence-ia': {
    name: 'New Providence',
    postalCode: '50206',
  },
  'new-providence-nj': {
    name: 'New Providence',
    postalCode: '07974',
  },
  'new-providence-pa': {
    name: 'New Providence',
    postalCode: '17560',
  },
  'new-raymer-co': {
    name: 'New Raymer',
    postalCode: '80742',
  },
  'new-richland-mn': {
    name: 'New Richland',
    postalCode: '56072',
  },
  'new-richmond-in': {
    name: 'New Richmond',
    postalCode: '47967',
  },
  'new-richmond-oh': {
    name: 'New Richmond',
    postalCode: '45157',
  },
  'new-richmond-wi': {
    name: 'New Richmond',
    postalCode: '54017',
  },
  'new-riegel-oh': {
    name: 'New Riegel',
    postalCode: '44853',
  },
  'new-ringgold-pa': {
    name: 'New Ringgold',
    postalCode: '17960',
  },
  'new-river-az': {
    name: 'New River',
    postalCode: '85087',
  },
  'new-roads-la': {
    name: 'New Roads',
    postalCode: '70760',
  },
  'new-rochelle-ny': {
    name: 'New Rochelle',
    postalCode: '10801',
  },
  'new-rockford-nd': {
    name: 'New Rockford',
    postalCode: '58356',
  },
  'new-ross-in': {
    name: 'New Ross',
    postalCode: '47968',
  },
  'new-russia-ny': {
    name: 'New Russia',
    postalCode: '12964',
  },
  'new-salem-il': {
    name: 'New Salem',
    postalCode: '62357',
  },
  'new-salem-ma': {
    name: 'New Salem',
    postalCode: '01355',
  },
  'new-salem-nd': {
    name: 'New Salem',
    postalCode: '58563',
  },
  'new-salem-pa': {
    name: 'New Salem',
    postalCode: '15468',
  },
  'new-salisbury-in': {
    name: 'New Salisbury',
    postalCode: '47161',
  },
  'new-sharon-ia': {
    name: 'New Sharon',
    postalCode: '50207',
  },
  'new-sharon-me': {
    name: 'New Sharon',
    postalCode: '04955',
  },
  'new-site-ms': {
    name: 'New Site',
    postalCode: '38859',
  },
  'new-smyrna-beach-fl': {
    name: 'New Smyrna Beach',
    postalCode: '32168',
  },
  'new-springfield-oh': {
    name: 'New Springfield',
    postalCode: '44443',
  },
  'new-stanton-pa': {
    name: 'New Stanton',
    postalCode: '15672',
  },
  'new-straitsville-oh': {
    name: 'New Straitsville',
    postalCode: '43766',
  },
  'new-sweden-me': {
    name: 'New Sweden',
    postalCode: '04762',
  },
  'new-tazewell-tn': {
    name: 'New Tazewell',
    postalCode: '37825',
  },
  'new-town-nd': {
    name: 'New Town',
    postalCode: '58763',
  },
  'new-tripoli-pa': {
    name: 'New Tripoli',
    postalCode: '18066',
  },
  'new-ulm-mn': {
    name: 'New Ulm',
    postalCode: '56073',
  },
  'new-ulm-tx': {
    name: 'New Ulm',
    postalCode: '78950',
  },
  'new-underwood-sd': {
    name: 'New Underwood',
    postalCode: '57761',
  },
  'new-vernon-nj': {
    name: 'New Vernon',
    postalCode: '07976',
  },
  'new-vienna-ia': {
    name: 'New Vienna',
    postalCode: '52065',
  },
  'new-vienna-oh': {
    name: 'New Vienna',
    postalCode: '45159',
  },
  'new-vineyard-me': {
    name: 'New Vineyard',
    postalCode: '04956',
  },
  'new-virginia-ia': {
    name: 'New Virginia',
    postalCode: '50210',
  },
  'new-washington-in': {
    name: 'New Washington',
    postalCode: '47162',
  },
  'new-washington-oh': {
    name: 'New Washington',
    postalCode: '44854',
  },
  'new-waterford-oh': {
    name: 'New Waterford',
    postalCode: '44445',
  },
  'new-waverly-in': {
    name: 'New Waverly',
    postalCode: '46961',
  },
  'new-waverly-tx': {
    name: 'New Waverly',
    postalCode: '77358',
  },
  'new-weston-oh': {
    name: 'New Weston',
    postalCode: '45348',
  },
  'new-wilmington-pa': {
    name: 'New Wilmington',
    postalCode: '16142',
  },
  'new-windsor-il': {
    name: 'New Windsor',
    postalCode: '61465',
  },
  'new-windsor-md': {
    name: 'New Windsor',
    postalCode: '21776',
  },
  'new-windsor-ny': {
    name: 'New Windsor',
    postalCode: '12553',
  },
  'new-woodstock-ny': {
    name: 'New Woodstock',
    postalCode: '13122',
  },
  'new-york-ny': {
    name: 'New York',
    postalCode: '10001',
  },
  'new-york-mills-mn': {
    name: 'New York Mills',
    postalCode: '56567',
  },
  'new-york-mills-ny': {
    name: 'New York Mills',
    postalCode: '13417',
  },
  'new-zion-sc': {
    name: 'New Zion',
    postalCode: '29111',
  },
  'newalla-ok': {
    name: 'Newalla',
    postalCode: '74857',
  },
  'newark-ar': {
    name: 'Newark',
    postalCode: '72562',
  },
  'newark-ca': {
    name: 'Newark',
    postalCode: '94560',
  },
  'newark-de': {
    name: 'Newark',
    postalCode: '19702',
  },
  'newark-il': {
    name: 'Newark',
    postalCode: '60541',
  },
  'newark-md': {
    name: 'Newark',
    postalCode: '21841',
  },
  'newark-mo': {
    name: 'Newark',
    postalCode: '63458',
  },
  'newark-nj': {
    name: 'Newark',
    postalCode: '07102',
  },
  'newark-ny': {
    name: 'Newark',
    postalCode: '14513',
  },
  'newark-oh': {
    name: 'Newark',
    postalCode: '43055',
  },
  'newark-tx': {
    name: 'Newark',
    postalCode: '76071',
  },
  'newark-valley-ny': {
    name: 'Newark Valley',
    postalCode: '13811',
  },
  'newaygo-mi': {
    name: 'Newaygo',
    postalCode: '49337',
  },
  'newberg-or': {
    name: 'Newberg',
    postalCode: '97132',
  },
  'newbern-al': {
    name: 'Newbern',
    postalCode: '36765',
  },
  'newbern-tn': {
    name: 'Newbern',
    postalCode: '38059',
  },
  'newberry-fl': {
    name: 'Newberry',
    postalCode: '32669',
  },
  'newberry-in': {
    name: 'Newberry',
    postalCode: '47449',
  },
  'newberry-mi': {
    name: 'Newberry',
    postalCode: '49868',
  },
  'newberry-sc': {
    name: 'Newberry',
    postalCode: '29108',
  },
  'newberry-springs-ca': {
    name: 'Newberry Springs',
    postalCode: '92365',
  },
  'newborn-ga': {
    name: 'Newborn',
    postalCode: '30056',
  },
  'newburg-md': {
    name: 'Newburg',
    postalCode: '20664',
  },
  'newburg-mo': {
    name: 'Newburg',
    postalCode: '65550',
  },
  'newburg-nd': {
    name: 'Newburg',
    postalCode: '58762',
  },
  'newburg-pa': {
    name: 'Newburg',
    postalCode: '17240',
  },
  'newburg-wv': {
    name: 'Newburg',
    postalCode: '26410',
  },
  'newburgh-in': {
    name: 'Newburgh',
    postalCode: '47630',
  },
  'newburgh-ny': {
    name: 'Newburgh',
    postalCode: '12550',
  },
  'newbury-ma': {
    name: 'Newbury',
    postalCode: '01951',
  },
  'newbury-nh': {
    name: 'Newbury',
    postalCode: '03255',
  },
  'newbury-oh': {
    name: 'Newbury',
    postalCode: '44065',
  },
  'newbury-vt': {
    name: 'Newbury',
    postalCode: '05051',
  },
  'newbury-park-ca': {
    name: 'Newbury Park',
    postalCode: '91320',
  },
  'newburyport-ma': {
    name: 'Newburyport',
    postalCode: '01950',
  },
  'newcastle-ca': {
    name: 'Newcastle',
    postalCode: '95658',
  },
  'newcastle-me': {
    name: 'Newcastle',
    postalCode: '04553',
  },
  'newcastle-ne': {
    name: 'Newcastle',
    postalCode: '68757',
  },
  'newcastle-ok': {
    name: 'Newcastle',
    postalCode: '73065',
  },
  'newcastle-tx': {
    name: 'Newcastle',
    postalCode: '76372',
  },
  'newcastle-wy': {
    name: 'Newcastle',
    postalCode: '82701',
  },
  'newcomb-ny': {
    name: 'Newcomb',
    postalCode: '12852',
  },
  'newcomb-tn': {
    name: 'Newcomb',
    postalCode: '37819',
  },
  'newcomerstown-oh': {
    name: 'Newcomerstown',
    postalCode: '43832',
  },
  'newdale-id': {
    name: 'Newdale',
    postalCode: '83436',
  },
  'newell-ia': {
    name: 'Newell',
    postalCode: '50568',
  },
  'newell-sd': {
    name: 'Newell',
    postalCode: '57760',
  },
  'newell-wv': {
    name: 'Newell',
    postalCode: '26050',
  },
  'newellton-la': {
    name: 'Newellton',
    postalCode: '71357',
  },
  'newfane-ny': {
    name: 'Newfane',
    postalCode: '14108',
  },
  'newfane-vt': {
    name: 'Newfane',
    postalCode: '05345',
  },
  'newfield-nj': {
    name: 'Newfield',
    postalCode: '08344',
  },
  'newfield-ny': {
    name: 'Newfield',
    postalCode: '14867',
  },
  'newfields-nh': {
    name: 'Newfields',
    postalCode: '03856',
  },
  'newfolden-mn': {
    name: 'Newfolden',
    postalCode: '56738',
  },
  'newfoundland-nj': {
    name: 'Newfoundland',
    postalCode: '07435',
  },
  'newfoundland-pa': {
    name: 'Newfoundland',
    postalCode: '18445',
  },
  'newhall-ca': {
    name: 'Newhall',
    postalCode: '91321',
  },
  'newhall-ia': {
    name: 'Newhall',
    postalCode: '52315',
  },
  'newhebron-ms': {
    name: 'Newhebron',
    postalCode: '39140',
  },
  'newhope-ar': {
    name: 'Newhope',
    postalCode: '71959',
  },
  'newington-ct': {
    name: 'Newington',
    postalCode: '06111',
  },
  'newington-ga': {
    name: 'Newington',
    postalCode: '30446',
  },
  'newington-nh': {
    name: 'Newington',
    postalCode: '03805',
  },
  'newkirk-nm': {
    name: 'Newkirk',
    postalCode: '88431',
  },
  'newkirk-ok': {
    name: 'Newkirk',
    postalCode: '74647',
  },
  'newland-nc': {
    name: 'Newland',
    postalCode: '28657',
  },
  'newman-ca': {
    name: 'Newman',
    postalCode: '95360',
  },
  'newman-il': {
    name: 'Newman',
    postalCode: '61942',
  },
  'newman-grove-ne': {
    name: 'Newman Grove',
    postalCode: '68758',
  },
  'newman-lake-wa': {
    name: 'Newman Lake',
    postalCode: '99025',
  },
  'newmanstown-pa': {
    name: 'Newmanstown',
    postalCode: '17073',
  },
  'newmarket-nh': {
    name: 'Newmarket',
    postalCode: '03857',
  },
  'newnan-ga': {
    name: 'Newnan',
    postalCode: '30263',
  },
  'newport-ar': {
    name: 'Newport',
    postalCode: '72112',
  },
  'newport-ky': {
    name: 'Newport',
    postalCode: '41071',
  },
  'newport-me': {
    name: 'Newport',
    postalCode: '04953',
  },
  'newport-mi': {
    name: 'Newport',
    postalCode: '48166',
  },
  'newport-mn': {
    name: 'Newport',
    postalCode: '55055',
  },
  'newport-nc': {
    name: 'Newport',
    postalCode: '28570',
  },
  'newport-ne': {
    name: 'Newport',
    postalCode: '68759',
  },
  'newport-nh': {
    name: 'Newport',
    postalCode: '03773',
  },
  'newport-nj': {
    name: 'Newport',
    postalCode: '08345',
  },
  'newport-ny': {
    name: 'Newport',
    postalCode: '13416',
  },
  'newport-oh': {
    name: 'Newport',
    postalCode: '45768',
  },
  'newport-or': {
    name: 'Newport',
    postalCode: '97365',
  },
  'newport-pa': {
    name: 'Newport',
    postalCode: '17074',
  },
  'newport-ri': {
    name: 'Newport',
    postalCode: '02840',
  },
  'newport-tn': {
    name: 'Newport',
    postalCode: '37821',
  },
  'newport-va': {
    name: 'Newport',
    postalCode: '24128',
  },
  'newport-vt': {
    name: 'Newport',
    postalCode: '05855',
  },
  'newport-wa': {
    name: 'Newport',
    postalCode: '99156',
  },
  'newport-beach-ca': {
    name: 'Newport Beach',
    postalCode: '92660',
  },
  'newport-center-vt': {
    name: 'Newport Center',
    postalCode: '05857',
  },
  'newport-coast-ca': {
    name: 'Newport Coast',
    postalCode: '92657',
  },
  'newport-news-va': {
    name: 'Newport News',
    postalCode: '23601',
  },
  'newry-me': {
    name: 'Newry',
    postalCode: '04261',
  },
  'newsoms-va': {
    name: 'Newsoms',
    postalCode: '23874',
  },
  'newton-al': {
    name: 'Newton',
    postalCode: '36352',
  },
  'newton-ga': {
    name: 'Newton',
    postalCode: '39870',
  },
  'newton-ia': {
    name: 'Newton',
    postalCode: '50208',
  },
  'newton-il': {
    name: 'Newton',
    postalCode: '62448',
  },
  'newton-ks': {
    name: 'Newton',
    postalCode: '67114',
  },
  'newton-ma': {
    name: 'Newton',
    postalCode: '02458',
  },
  'newton-ms': {
    name: 'Newton',
    postalCode: '39345',
  },
  'newton-nc': {
    name: 'Newton',
    postalCode: '28658',
  },
  'newton-nh': {
    name: 'Newton',
    postalCode: '03858',
  },
  'newton-nj': {
    name: 'Newton',
    postalCode: '07860',
  },
  'newton-tx': {
    name: 'Newton',
    postalCode: '75966',
  },
  'newton-wi': {
    name: 'Newton',
    postalCode: '53063',
  },
  'newton-wv': {
    name: 'Newton',
    postalCode: '25266',
  },
  'newton-center-ma': {
    name: 'Newton Center',
    postalCode: '02459',
  },
  'newton-falls-oh': {
    name: 'Newton Falls',
    postalCode: '44444',
  },
  'newton-grove-nc': {
    name: 'Newton Grove',
    postalCode: '28366',
  },
  'newton-highlands-ma': {
    name: 'Newton Highlands',
    postalCode: '02461',
  },
  'newton-lower-falls-ma': {
    name: 'Newton Lower Falls',
    postalCode: '02462',
  },
  'newton-upper-falls-ma': {
    name: 'Newton Upper Falls',
    postalCode: '02464',
  },
  'newtonville-ma': {
    name: 'Newtonville',
    postalCode: '02460',
  },
  'newtonville-nj': {
    name: 'Newtonville',
    postalCode: '08346',
  },
  'newtown-ct': {
    name: 'Newtown',
    postalCode: '06470',
  },
  'newtown-mo': {
    name: 'Newtown',
    postalCode: '64667',
  },
  'newtown-pa': {
    name: 'Newtown',
    postalCode: '18940',
  },
  'newtown-va': {
    name: 'Newtown',
    postalCode: '23126',
  },
  'newtown-square-pa': {
    name: 'Newtown Square',
    postalCode: '19073',
  },
  'newville-al': {
    name: 'Newville',
    postalCode: '36353',
  },
  'newville-pa': {
    name: 'Newville',
    postalCode: '17241',
  },
  'ney-oh': {
    name: 'Ney',
    postalCode: '43549',
  },
  'nezperce-id': {
    name: 'Nezperce',
    postalCode: '83543',
  },
  'niagara-nd': {
    name: 'Niagara',
    postalCode: '58266',
  },
  'niagara-wi': {
    name: 'Niagara',
    postalCode: '54151',
  },
  'niagara-falls-ny': {
    name: 'Niagara Falls',
    postalCode: '14301',
  },
  'niangua-mo': {
    name: 'Niangua',
    postalCode: '65713',
  },
  'niantic-ct': {
    name: 'Niantic',
    postalCode: '06357',
  },
  'niantic-il': {
    name: 'Niantic',
    postalCode: '62551',
  },
  'nicasio-ca': {
    name: 'Nicasio',
    postalCode: '94946',
  },
  'nice-ca': {
    name: 'Nice',
    postalCode: '95464',
  },
  'niceville-fl': {
    name: 'Niceville',
    postalCode: '32578',
  },
  'nicholasville-ky': {
    name: 'Nicholasville',
    postalCode: '40356',
  },
  'nicholls-ga': {
    name: 'Nicholls',
    postalCode: '31554',
  },
  'nichols-ia': {
    name: 'Nichols',
    postalCode: '52766',
  },
  'nichols-ny': {
    name: 'Nichols',
    postalCode: '13812',
  },
  'nichols-sc': {
    name: 'Nichols',
    postalCode: '29581',
  },
  'nicholson-ga': {
    name: 'Nicholson',
    postalCode: '30565',
  },
  'nicholson-pa': {
    name: 'Nicholson',
    postalCode: '18446',
  },
  'nicholville-ny': {
    name: 'Nicholville',
    postalCode: '12965',
  },
  'nickelsville-va': {
    name: 'Nickelsville',
    postalCode: '24271',
  },
  'nickerson-ks': {
    name: 'Nickerson',
    postalCode: '67561',
  },
  'nickerson-ne': {
    name: 'Nickerson',
    postalCode: '68044',
  },
  'nicktown-pa': {
    name: 'Nicktown',
    postalCode: '15762',
  },
  'nicolaus-ca': {
    name: 'Nicolaus',
    postalCode: '95659',
  },
  'nicollet-mn': {
    name: 'Nicollet',
    postalCode: '56074',
  },
  'nielsville-mn': {
    name: 'Nielsville',
    postalCode: '56568',
  },
  'niland-ca': {
    name: 'Niland',
    postalCode: '92257',
  },
  'niles-il': {
    name: 'Niles',
    postalCode: '60714',
  },
  'niles-mi': {
    name: 'Niles',
    postalCode: '49120',
  },
  'niles-oh': {
    name: 'Niles',
    postalCode: '44446',
  },
  'nimitz-wv': {
    name: 'Nimitz',
    postalCode: '25978',
  },
  'nimrod-mn': {
    name: 'Nimrod',
    postalCode: '56478',
  },
  'nine-mile-falls-wa': {
    name: 'Nine Mile Falls',
    postalCode: '99026',
  },
  'ninety-six-sc': {
    name: 'Ninety Six',
    postalCode: '29666',
  },
  'nineveh-in': {
    name: 'Nineveh',
    postalCode: '46164',
  },
  'nineveh-ny': {
    name: 'Nineveh',
    postalCode: '13813',
  },
  'ninilchik-ak': {
    name: 'Ninilchik',
    postalCode: '99639',
  },
  'ninnekah-ok': {
    name: 'Ninnekah',
    postalCode: '73067',
  },
  'niobrara-ne': {
    name: 'Niobrara',
    postalCode: '68760',
  },
  'niota-il': {
    name: 'Niota',
    postalCode: '62358',
  },
  'niota-tn': {
    name: 'Niota',
    postalCode: '37826',
  },
  'niotaze-ks': {
    name: 'Niotaze',
    postalCode: '67355',
  },
  'nipomo-ca': {
    name: 'Nipomo',
    postalCode: '93444',
  },
  'nipton-ca': {
    name: 'Nipton',
    postalCode: '92364',
  },
  'nisland-sd': {
    name: 'Nisland',
    postalCode: '57762',
  },
  'nisswa-mn': {
    name: 'Nisswa',
    postalCode: '56468',
  },
  'nisula-mi': {
    name: 'Nisula',
    postalCode: '49952',
  },
  'nitro-wv': {
    name: 'Nitro',
    postalCode: '25143',
  },
  'niverville-ny': {
    name: 'Niverville',
    postalCode: '12130',
  },
  'nixa-mo': {
    name: 'Nixa',
    postalCode: '65714',
  },
  'nixon-tx': {
    name: 'Nixon',
    postalCode: '78140',
  },
  'noble-il': {
    name: 'Noble',
    postalCode: '62868',
  },
  'noble-la': {
    name: 'Noble',
    postalCode: '71462',
  },
  'noble-mo': {
    name: 'Noble',
    postalCode: '65715',
  },
  'noble-ok': {
    name: 'Noble',
    postalCode: '73068',
  },
  'nobleboro-me': {
    name: 'Nobleboro',
    postalCode: '04555',
  },
  'noblesville-in': {
    name: 'Noblesville',
    postalCode: '46060',
  },
  'nocona-tx': {
    name: 'Nocona',
    postalCode: '76255',
  },
  'nodaway-ia': {
    name: 'Nodaway',
    postalCode: '50857',
  },
  'noel-mo': {
    name: 'Noel',
    postalCode: '64854',
  },
  'nogal-nm': {
    name: 'Nogal',
    postalCode: '88341',
  },
  'nogales-az': {
    name: 'Nogales',
    postalCode: '85621',
  },
  'nokesville-va': {
    name: 'Nokesville',
    postalCode: '20181',
  },
  'nokomis-fl': {
    name: 'Nokomis',
    postalCode: '34275',
  },
  'nokomis-il': {
    name: 'Nokomis',
    postalCode: '62075',
  },
  'nolan-tx': {
    name: 'Nolan',
    postalCode: '79537',
  },
  'nolanville-tx': {
    name: 'Nolanville',
    postalCode: '76559',
  },
  'nolensville-tn': {
    name: 'Nolensville',
    postalCode: '37135',
  },
  'nome-nd': {
    name: 'Nome',
    postalCode: '58062',
  },
  'nooksack-wa': {
    name: 'Nooksack',
    postalCode: '98276',
  },
  'noonan-nd': {
    name: 'Noonan',
    postalCode: '58765',
  },
  'nora-va': {
    name: 'Nora',
    postalCode: '24272',
  },
  'nora-springs-ia': {
    name: 'Nora Springs',
    postalCode: '50458',
  },
  'norborne-mo': {
    name: 'Norborne',
    postalCode: '64668',
  },
  'norcatur-ks': {
    name: 'Norcatur',
    postalCode: '67653',
  },
  'norco-ca': {
    name: 'Norco',
    postalCode: '92860',
  },
  'norco-la': {
    name: 'Norco',
    postalCode: '70079',
  },
  'norcross-ga': {
    name: 'Norcross',
    postalCode: '30071',
  },
  'norcross-mn': {
    name: 'Norcross',
    postalCode: '56274',
  },
  'nordheim-tx': {
    name: 'Nordheim',
    postalCode: '78141',
  },
  'nordland-wa': {
    name: 'Nordland',
    postalCode: '98358',
  },
  'nordman-id': {
    name: 'Nordman',
    postalCode: '83848',
  },
  'norfolk-ct': {
    name: 'Norfolk',
    postalCode: '06058',
  },
  'norfolk-ma': {
    name: 'Norfolk',
    postalCode: '02056',
  },
  'norfolk-ne': {
    name: 'Norfolk',
    postalCode: '68701',
  },
  'norfolk-ny': {
    name: 'Norfolk',
    postalCode: '13667',
  },
  'norfolk-va': {
    name: 'Norfolk',
    postalCode: '23502',
  },
  'norfork-ar': {
    name: 'Norfork',
    postalCode: '72658',
  },
  'norlina-nc': {
    name: 'Norlina',
    postalCode: '27563',
  },
  'normal-il': {
    name: 'Normal',
    postalCode: '61761',
  },
  'normalville-pa': {
    name: 'Normalville',
    postalCode: '15469',
  },
  'norman-ar': {
    name: 'Norman',
    postalCode: '71960',
  },
  'norman-in': {
    name: 'Norman',
    postalCode: '47264',
  },
  'norman-ok': {
    name: 'Norman',
    postalCode: '73026',
  },
  'norman-park-ga': {
    name: 'Norman Park',
    postalCode: '31771',
  },
  'normandy-tn': {
    name: 'Normandy',
    postalCode: '37360',
  },
  'normangee-tx': {
    name: 'Normangee',
    postalCode: '77871',
  },
  'normantown-wv': {
    name: 'Normantown',
    postalCode: '25267',
  },
  'norridgewock-me': {
    name: 'Norridgewock',
    postalCode: '04957',
  },
  'norris-mt': {
    name: 'Norris',
    postalCode: '59745',
  },
  'norris-sc': {
    name: 'Norris',
    postalCode: '29667',
  },
  'norris-sd': {
    name: 'Norris',
    postalCode: '57560',
  },
  'norris-city-il': {
    name: 'Norris City',
    postalCode: '62869',
  },
  'norristown-pa': {
    name: 'Norristown',
    postalCode: '19401',
  },
  'north-sc': {
    name: 'North',
    postalCode: '29112',
  },
  'north-va': {
    name: 'North',
    postalCode: '23128',
  },
  'north-adams-ma': {
    name: 'North Adams',
    postalCode: '01247',
  },
  'north-adams-mi': {
    name: 'North Adams',
    postalCode: '49262',
  },
  'north-andover-ma': {
    name: 'North Andover',
    postalCode: '01845',
  },
  'north-anson-me': {
    name: 'North Anson',
    postalCode: '04958',
  },
  'north-arlington-nj': {
    name: 'North Arlington',
    postalCode: '07031',
  },
  'north-attleboro-ma': {
    name: 'North Attleboro',
    postalCode: '02760',
  },
  'north-augusta-sc': {
    name: 'North Augusta',
    postalCode: '29841',
  },
  'north-aurora-il': {
    name: 'North Aurora',
    postalCode: '60542',
  },
  'north-babylon-ny': {
    name: 'North Babylon',
    postalCode: '11703',
  },
  'north-baltimore-oh': {
    name: 'North Baltimore',
    postalCode: '45872',
  },
  'north-bangor-ny': {
    name: 'North Bangor',
    postalCode: '12966',
  },
  'north-beach-md': {
    name: 'North Beach',
    postalCode: '20714',
  },
  'north-bend-ne': {
    name: 'North Bend',
    postalCode: '68649',
  },
  'north-bend-oh': {
    name: 'North Bend',
    postalCode: '45052',
  },
  'north-bend-or': {
    name: 'North Bend',
    postalCode: '97459',
  },
  'north-bend-pa': {
    name: 'North Bend',
    postalCode: '17738',
  },
  'north-bend-wa': {
    name: 'North Bend',
    postalCode: '98045',
  },
  'north-bennington-vt': {
    name: 'North Bennington',
    postalCode: '05257',
  },
  'north-benton-oh': {
    name: 'North Benton',
    postalCode: '44449',
  },
  'north-bergen-nj': {
    name: 'North Bergen',
    postalCode: '07047',
  },
  'north-berwick-me': {
    name: 'North Berwick',
    postalCode: '03906',
  },
  'north-billerica-ma': {
    name: 'North Billerica',
    postalCode: '01862',
  },
  'north-blenheim-ny': {
    name: 'North Blenheim',
    postalCode: '12131',
  },
  'north-bloomfield-oh': {
    name: 'North Bloomfield',
    postalCode: '44450',
  },
  'north-branch-mi': {
    name: 'North Branch',
    postalCode: '48461',
  },
  'north-branch-mn': {
    name: 'North Branch',
    postalCode: '55056',
  },
  'north-branch-ny': {
    name: 'North Branch',
    postalCode: '12766',
  },
  'north-branford-ct': {
    name: 'North Branford',
    postalCode: '06471',
  },
  'north-brookfield-ma': {
    name: 'North Brookfield',
    postalCode: '01535',
  },
  'north-brookfield-ny': {
    name: 'North Brookfield',
    postalCode: '13418',
  },
  'north-brunswick-nj': {
    name: 'North Brunswick',
    postalCode: '08902',
  },
  'north-buena-vista-ia': {
    name: 'North Buena Vista',
    postalCode: '52066',
  },
  'north-canton-oh': {
    name: 'North Canton',
    postalCode: '44720',
  },
  'north-charleston-sc': {
    name: 'North Charleston',
    postalCode: '29405',
  },
  'north-chatham-ma': {
    name: 'North Chatham',
    postalCode: '02650',
  },
  'north-chelmsford-ma': {
    name: 'North Chelmsford',
    postalCode: '01863',
  },
  'north-chicago-il': {
    name: 'North Chicago',
    postalCode: '60064',
  },
  'north-chili-ny': {
    name: 'North Chili',
    postalCode: '14514',
  },
  'north-clarendon-vt': {
    name: 'North Clarendon',
    postalCode: '05759',
  },
  'north-collins-ny': {
    name: 'North Collins',
    postalCode: '14111',
  },
  'north-concord-vt': {
    name: 'North Concord',
    postalCode: '05858',
  },
  'north-conway-nh': {
    name: 'North Conway',
    postalCode: '03860',
  },
  'north-creek-ny': {
    name: 'North Creek',
    postalCode: '12853',
  },
  'north-dartmouth-ma': {
    name: 'North Dartmouth',
    postalCode: '02747',
  },
  'north-dighton-ma': {
    name: 'North Dighton',
    postalCode: '02764',
  },
  'north-east-md': {
    name: 'North East',
    postalCode: '21901',
  },
  'north-east-pa': {
    name: 'North East',
    postalCode: '16428',
  },
  'north-easton-ma': {
    name: 'North Easton',
    postalCode: '02356',
  },
  'north-egremont-ma': {
    name: 'North Egremont',
    postalCode: '01252',
  },
  'north-english-ia': {
    name: 'North English',
    postalCode: '52316',
  },
  'north-fairfield-oh': {
    name: 'North Fairfield',
    postalCode: '44855',
  },
  'north-falmouth-ma': {
    name: 'North Falmouth',
    postalCode: '02556',
  },
  'north-ferrisburgh-vt': {
    name: 'North Ferrisburgh',
    postalCode: '05473',
  },
  'north-fork-ca': {
    name: 'North Fork',
    postalCode: '93643',
  },
  'north-fork-id': {
    name: 'North Fork',
    postalCode: '83466',
  },
  'north-fort-myers-fl': {
    name: 'North Fort Myers',
    postalCode: '33903',
  },
  'north-franklin-ct': {
    name: 'North Franklin',
    postalCode: '06254',
  },
  'north-freedom-wi': {
    name: 'North Freedom',
    postalCode: '53951',
  },
  'north-garden-va': {
    name: 'North Garden',
    postalCode: '22959',
  },
  'north-grafton-ma': {
    name: 'North Grafton',
    postalCode: '01536',
  },
  'north-granby-ct': {
    name: 'North Granby',
    postalCode: '06060',
  },
  'north-granville-ny': {
    name: 'North Granville',
    postalCode: '12854',
  },
  'north-grosvenordale-ct': {
    name: 'North Grosvenordale',
    postalCode: '06255',
  },
  'north-hampton-nh': {
    name: 'North Hampton',
    postalCode: '03862',
  },
  'north-hartland-vt': {
    name: 'North Hartland',
    postalCode: '05052',
  },
  'north-haven-ct': {
    name: 'North Haven',
    postalCode: '06473',
  },
  'north-haven-me': {
    name: 'North Haven',
    postalCode: '04853',
  },
  'north-haverhill-nh': {
    name: 'North Haverhill',
    postalCode: '03774',
  },
  'north-henderson-il': {
    name: 'North Henderson',
    postalCode: '61466',
  },
  'north-hero-vt': {
    name: 'North Hero',
    postalCode: '05474',
  },
  'north-highlands-ca': {
    name: 'North Highlands',
    postalCode: '95660',
  },
  'north-hills-ca': {
    name: 'North Hills',
    postalCode: '91343',
  },
  'north-hollywood-ca': {
    name: 'North Hollywood',
    postalCode: '91601',
  },
  'north-houston-tx': {
    name: 'North Houston',
    postalCode: '77315',
  },
  'north-hudson-ny': {
    name: 'North Hudson',
    postalCode: '12855',
  },
  'north-jackson-oh': {
    name: 'North Jackson',
    postalCode: '44451',
  },
  'north-java-ny': {
    name: 'North Java',
    postalCode: '14113',
  },
  'north-judson-in': {
    name: 'North Judson',
    postalCode: '46366',
  },
  'north-kingstown-ri': {
    name: 'North Kingstown',
    postalCode: '02852',
  },
  'north-las-vegas-nv': {
    name: 'North Las Vegas',
    postalCode: '89030',
  },
  'north-lawrence-ny': {
    name: 'North Lawrence',
    postalCode: '12967',
  },
  'north-lawrence-oh': {
    name: 'North Lawrence',
    postalCode: '44666',
  },
  'north-lewisburg-oh': {
    name: 'North Lewisburg',
    postalCode: '43060',
  },
  'north-liberty-ia': {
    name: 'North Liberty',
    postalCode: '52317',
  },
  'north-liberty-in': {
    name: 'North Liberty',
    postalCode: '46554',
  },
  'north-lima-oh': {
    name: 'North Lima',
    postalCode: '44452',
  },
  'north-little-rock-ar': {
    name: 'North Little Rock',
    postalCode: '72114',
  },
  'north-loup-ne': {
    name: 'North Loup',
    postalCode: '68859',
  },
  'north-manchester-in': {
    name: 'North Manchester',
    postalCode: '46962',
  },
  'north-miami-beach-fl': {
    name: 'North Miami Beach',
    postalCode: '33160',
  },
  'north-monmouth-me': {
    name: 'North Monmouth',
    postalCode: '04265',
  },
  'north-montpelier-vt': {
    name: 'North Montpelier',
    postalCode: '05666',
  },
  'north-myrtle-beach-sc': {
    name: 'North Myrtle Beach',
    postalCode: '29582',
  },
  'north-newton-ks': {
    name: 'North Newton',
    postalCode: '67117',
  },
  'north-olmsted-oh': {
    name: 'North Olmsted',
    postalCode: '44070',
  },
  'north-oxford-ma': {
    name: 'North Oxford',
    postalCode: '01537',
  },
  'north-palm-beach-fl': {
    name: 'North Palm Beach',
    postalCode: '33408',
  },
  'north-pitcher-ny': {
    name: 'North Pitcher',
    postalCode: '13124',
  },
  'north-plains-or': {
    name: 'North Plains',
    postalCode: '97133',
  },
  'north-platte-ne': {
    name: 'North Platte',
    postalCode: '69101',
  },
  'north-pole-ak': {
    name: 'North Pole',
    postalCode: '99705',
  },
  'north-pomfret-vt': {
    name: 'North Pomfret',
    postalCode: '05053',
  },
  'north-port-fl': {
    name: 'North Port',
    postalCode: '34286',
  },
  'north-powder-or': {
    name: 'North Powder',
    postalCode: '97867',
  },
  'north-pownal-vt': {
    name: 'North Pownal',
    postalCode: '05260',
  },
  'north-prairie-wi': {
    name: 'North Prairie',
    postalCode: '53153',
  },
  'north-providence-ri': {
    name: 'North Providence',
    postalCode: '02911',
  },
  'north-reading-ma': {
    name: 'North Reading',
    postalCode: '01864',
  },
  'north-richland-hills-tx': {
    name: 'North Richland Hills',
    postalCode: '76180',
  },
  'north-ridgeville-oh': {
    name: 'North Ridgeville',
    postalCode: '44039',
  },
  'north-rose-ny': {
    name: 'North Rose',
    postalCode: '14516',
  },
  'north-royalton-oh': {
    name: 'North Royalton',
    postalCode: '44133',
  },
  'north-salem-in': {
    name: 'North Salem',
    postalCode: '46165',
  },
  'north-salem-ny': {
    name: 'North Salem',
    postalCode: '10560',
  },
  'north-salt-lake-ut': {
    name: 'North Salt Lake',
    postalCode: '84054',
  },
  'north-san-juan-ca': {
    name: 'North San Juan',
    postalCode: '95960',
  },
  'north-sandwich-nh': {
    name: 'North Sandwich',
    postalCode: '03259',
  },
  'north-scituate-ri': {
    name: 'North Scituate',
    postalCode: '02857',
  },
  'north-sioux-city-sd': {
    name: 'North Sioux City',
    postalCode: '57049',
  },
  'north-smithfield-ri': {
    name: 'North Smithfield',
    postalCode: '02896',
  },
  'north-spring-wv': {
    name: 'North Spring',
    postalCode: '24869',
  },
  'north-springfield-pa': {
    name: 'North Springfield',
    postalCode: '16430',
  },
  'north-springfield-vt': {
    name: 'North Springfield',
    postalCode: '05150',
  },
  'north-stonington-ct': {
    name: 'North Stonington',
    postalCode: '06359',
  },
  'north-stratford-nh': {
    name: 'North Stratford',
    postalCode: '03590',
  },
  'north-street-mi': {
    name: 'North Street',
    postalCode: '48049',
  },
  'north-tazewell-va': {
    name: 'North Tazewell',
    postalCode: '24630',
  },
  'north-tonawanda-ny': {
    name: 'North Tonawanda',
    postalCode: '14120',
  },
  'north-troy-vt': {
    name: 'North Troy',
    postalCode: '05859',
  },
  'north-vernon-in': {
    name: 'North Vernon',
    postalCode: '47265',
  },
  'north-versailles-pa': {
    name: 'North Versailles',
    postalCode: '15137',
  },
  'north-wales-pa': {
    name: 'North Wales',
    postalCode: '19454',
  },
  'north-walpole-nh': {
    name: 'North Walpole',
    postalCode: '03609',
  },
  'north-waterboro-me': {
    name: 'North Waterboro',
    postalCode: '04061',
  },
  'north-webster-in': {
    name: 'North Webster',
    postalCode: '46555',
  },
  'north-weymouth-ma': {
    name: 'North Weymouth',
    postalCode: '02191',
  },
  'north-wilkesboro-nc': {
    name: 'North Wilkesboro',
    postalCode: '28659',
  },
  'north-windham-ct': {
    name: 'North Windham',
    postalCode: '06256',
  },
  'north-woodstock-nh': {
    name: 'North Woodstock',
    postalCode: '03262',
  },
  'north-yarmouth-me': {
    name: 'North Yarmouth',
    postalCode: '04097',
  },
  'north-zulch-tx': {
    name: 'North Zulch',
    postalCode: '77872',
  },
  'northampton-ma': {
    name: 'Northampton',
    postalCode: '01060',
  },
  'northampton-pa': {
    name: 'Northampton',
    postalCode: '18067',
  },
  'northboro-ia': {
    name: 'Northboro',
    postalCode: '51647',
  },
  'northborough-ma': {
    name: 'Northborough',
    postalCode: '01532',
  },
  'northbridge-ma': {
    name: 'Northbridge',
    postalCode: '01534',
  },
  'northbrook-il': {
    name: 'Northbrook',
    postalCode: '60062',
  },
  'northern-cambria-pa': {
    name: 'Northern Cambria',
    postalCode: '15714',
  },
  'northfield-ct': {
    name: 'Northfield',
    postalCode: '06778',
  },
  'northfield-ma': {
    name: 'Northfield',
    postalCode: '01360',
  },
  'northfield-mn': {
    name: 'Northfield',
    postalCode: '55057',
  },
  'northfield-nj': {
    name: 'Northfield',
    postalCode: '08225',
  },
  'northfield-oh': {
    name: 'Northfield',
    postalCode: '44067',
  },
  'northfield-vt': {
    name: 'Northfield',
    postalCode: '05663',
  },
  'northford-ct': {
    name: 'Northford',
    postalCode: '06472',
  },
  'northfork-wv': {
    name: 'Northfork',
    postalCode: '24868',
  },
  'northome-mn': {
    name: 'Northome',
    postalCode: '56661',
  },
  'northpoint-pa': {
    name: 'Northpoint',
    postalCode: '15763',
  },
  'northport-al': {
    name: 'Northport',
    postalCode: '35473',
  },
  'northport-mi': {
    name: 'Northport',
    postalCode: '49670',
  },
  'northport-ny': {
    name: 'Northport',
    postalCode: '11768',
  },
  'northport-wa': {
    name: 'Northport',
    postalCode: '99157',
  },
  'northridge-ca': {
    name: 'Northridge',
    postalCode: '91324',
  },
  'northumberland-pa': {
    name: 'Northumberland',
    postalCode: '17857',
  },
  'northvale-nj': {
    name: 'Northvale',
    postalCode: '07647',
  },
  'northville-mi': {
    name: 'Northville',
    postalCode: '48167',
  },
  'northville-ny': {
    name: 'Northville',
    postalCode: '12134',
  },
  'northville-sd': {
    name: 'Northville',
    postalCode: '57465',
  },
  'northwood-ia': {
    name: 'Northwood',
    postalCode: '50459',
  },
  'northwood-nd': {
    name: 'Northwood',
    postalCode: '58267',
  },
  'northwood-nh': {
    name: 'Northwood',
    postalCode: '03261',
  },
  'northwood-oh': {
    name: 'Northwood',
    postalCode: '43619',
  },
  'norton-ks': {
    name: 'Norton',
    postalCode: '67654',
  },
  'norton-ma': {
    name: 'Norton',
    postalCode: '02766',
  },
  'norton-tx': {
    name: 'Norton',
    postalCode: '76865',
  },
  'norton-va': {
    name: 'Norton',
    postalCode: '24273',
  },
  'norton-vt': {
    name: 'Norton',
    postalCode: '05907',
  },
  'norton-wv': {
    name: 'Norton',
    postalCode: '26285',
  },
  'nortonville-ks': {
    name: 'Nortonville',
    postalCode: '66060',
  },
  'nortonville-ky': {
    name: 'Nortonville',
    postalCode: '42442',
  },
  'norwalk-ca': {
    name: 'Norwalk',
    postalCode: '90650',
  },
  'norwalk-ct': {
    name: 'Norwalk',
    postalCode: '06850',
  },
  'norwalk-ia': {
    name: 'Norwalk',
    postalCode: '50211',
  },
  'norwalk-oh': {
    name: 'Norwalk',
    postalCode: '44857',
  },
  'norwalk-wi': {
    name: 'Norwalk',
    postalCode: '54648',
  },
  'norway-ia': {
    name: 'Norway',
    postalCode: '52318',
  },
  'norway-me': {
    name: 'Norway',
    postalCode: '04268',
  },
  'norway-mi': {
    name: 'Norway',
    postalCode: '49870',
  },
  'norway-sc': {
    name: 'Norway',
    postalCode: '29113',
  },
  'norwell-ma': {
    name: 'Norwell',
    postalCode: '02061',
  },
  'norwich-ct': {
    name: 'Norwich',
    postalCode: '06360',
  },
  'norwich-ks': {
    name: 'Norwich',
    postalCode: '67118',
  },
  'norwich-nd': {
    name: 'Norwich',
    postalCode: '58768',
  },
  'norwich-ny': {
    name: 'Norwich',
    postalCode: '13815',
  },
  'norwich-oh': {
    name: 'Norwich',
    postalCode: '43767',
  },
  'norwich-vt': {
    name: 'Norwich',
    postalCode: '05055',
  },
  'norwood-ga': {
    name: 'Norwood',
    postalCode: '30821',
  },
  'norwood-la': {
    name: 'Norwood',
    postalCode: '70761',
  },
  'norwood-ma': {
    name: 'Norwood',
    postalCode: '02062',
  },
  'norwood-mo': {
    name: 'Norwood',
    postalCode: '65717',
  },
  'norwood-nc': {
    name: 'Norwood',
    postalCode: '28128',
  },
  'norwood-nj': {
    name: 'Norwood',
    postalCode: '07648',
  },
  'norwood-ny': {
    name: 'Norwood',
    postalCode: '13668',
  },
  'norwood-pa': {
    name: 'Norwood',
    postalCode: '19074',
  },
  'norwood-va': {
    name: 'Norwood',
    postalCode: '24581',
  },
  'norwood-young-america-mn': {
    name: 'Norwood Young America',
    postalCode: '55368',
  },
  'notasulga-al': {
    name: 'Notasulga',
    postalCode: '36866',
  },
  'noti-or': {
    name: 'Noti',
    postalCode: '97461',
  },
  'nottingham-md': {
    name: 'Nottingham',
    postalCode: '21236',
  },
  'nottingham-nh': {
    name: 'Nottingham',
    postalCode: '03290',
  },
  'nottingham-pa': {
    name: 'Nottingham',
    postalCode: '19362',
  },
  'nova-oh': {
    name: 'Nova',
    postalCode: '44859',
  },
  'novato-ca': {
    name: 'Novato',
    postalCode: '94945',
  },
  'novelty-mo': {
    name: 'Novelty',
    postalCode: '63460',
  },
  'novelty-oh': {
    name: 'Novelty',
    postalCode: '44072',
  },
  'novi-mi': {
    name: 'Novi',
    postalCode: '48374',
  },
  'novice-tx': {
    name: 'Novice',
    postalCode: '79538',
  },
  'novinger-mo': {
    name: 'Novinger',
    postalCode: '63559',
  },
  'nowata-ok': {
    name: 'Nowata',
    postalCode: '74048',
  },
  'noxapater-ms': {
    name: 'Noxapater',
    postalCode: '39346',
  },
  'noxen-pa': {
    name: 'Noxen',
    postalCode: '18636',
  },
  'noxon-mt': {
    name: 'Noxon',
    postalCode: '59853',
  },
  'noyes-mn': {
    name: 'Noyes',
    postalCode: '56740',
  },
  'nucla-co': {
    name: 'Nucla',
    postalCode: '81424',
  },
  'nuevo-ca': {
    name: 'Nuevo',
    postalCode: '92567',
  },
  'nunda-ny': {
    name: 'Nunda',
    postalCode: '14517',
  },
  'nunda-sd': {
    name: 'Nunda',
    postalCode: '57050',
  },
  'nunica-mi': {
    name: 'Nunica',
    postalCode: '49448',
  },
  'nunn-co': {
    name: 'Nunn',
    postalCode: '80648',
  },
  'nunnelly-tn': {
    name: 'Nunnelly',
    postalCode: '37137',
  },
  'nuremberg-pa': {
    name: 'Nuremberg',
    postalCode: '18241',
  },
  'nutley-nj': {
    name: 'Nutley',
    postalCode: '07110',
  },
  'nyack-ny': {
    name: 'Nyack',
    postalCode: '10960',
  },
  'nye-mt': {
    name: 'Nye',
    postalCode: '59061',
  },
  'nyssa-or': {
    name: 'Nyssa',
    postalCode: '97913',
  },
  'o-brien-fl': {
    name: 'O Brien',
    postalCode: '32071',
  },
  'o-brien-or': {
    name: 'O Brien',
    postalCode: '97534',
  },
  'o-brien-tx': {
    name: 'O Brien',
    postalCode: '79539',
  },
  'o-fallon-il': {
    name: 'O Fallon',
    postalCode: '62269',
  },
  'o-fallon-mo': {
    name: 'O Fallon',
    postalCode: '63366',
  },
  'o-neals-ca': {
    name: 'O Neals',
    postalCode: '93645',
  },
  'oacoma-sd': {
    name: 'Oacoma',
    postalCode: '57365',
  },
  'oak-ne': {
    name: 'Oak',
    postalCode: '68964',
  },
  'oak-brook-il': {
    name: 'Oak Brook',
    postalCode: '60523',
  },
  'oak-city-nc': {
    name: 'Oak City',
    postalCode: '27857',
  },
  'oak-creek-co': {
    name: 'Oak Creek',
    postalCode: '80467',
  },
  'oak-creek-wi': {
    name: 'Oak Creek',
    postalCode: '53154',
  },
  'oak-forest-il': {
    name: 'Oak Forest',
    postalCode: '60452',
  },
  'oak-grove-ar': {
    name: 'Oak Grove',
    postalCode: '72660',
  },
  'oak-grove-ky': {
    name: 'Oak Grove',
    postalCode: '42262',
  },
  'oak-grove-la': {
    name: 'Oak Grove',
    postalCode: '71263',
  },
  'oak-grove-mo': {
    name: 'Oak Grove',
    postalCode: '64075',
  },
  'oak-hall-va': {
    name: 'Oak Hall',
    postalCode: '23396',
  },
  'oak-harbor-oh': {
    name: 'Oak Harbor',
    postalCode: '43449',
  },
  'oak-harbor-wa': {
    name: 'Oak Harbor',
    postalCode: '98277',
  },
  'oak-hill-fl': {
    name: 'Oak Hill',
    postalCode: '32759',
  },
  'oak-hill-ny': {
    name: 'Oak Hill',
    postalCode: '12460',
  },
  'oak-hill-oh': {
    name: 'Oak Hill',
    postalCode: '45656',
  },
  'oak-hill-wv': {
    name: 'Oak Hill',
    postalCode: '25901',
  },
  'oak-island-nc': {
    name: 'Oak Island',
    postalCode: '28465',
  },
  'oak-lawn-il': {
    name: 'Oak Lawn',
    postalCode: '60453',
  },
  'oak-park-ca': {
    name: 'Oak Park',
    postalCode: '91377',
  },
  'oak-park-il': {
    name: 'Oak Park',
    postalCode: '60301',
  },
  'oak-park-mi': {
    name: 'Oak Park',
    postalCode: '48237',
  },
  'oak-park-mn': {
    name: 'Oak Park',
    postalCode: '56357',
  },
  'oak-ridge-la': {
    name: 'Oak Ridge',
    postalCode: '71264',
  },
  'oak-ridge-mo': {
    name: 'Oak Ridge',
    postalCode: '63769',
  },
  'oak-ridge-nc': {
    name: 'Oak Ridge',
    postalCode: '27310',
  },
  'oak-ridge-nj': {
    name: 'Oak Ridge',
    postalCode: '07438',
  },
  'oak-ridge-tn': {
    name: 'Oak Ridge',
    postalCode: '37830',
  },
  'oak-run-ca': {
    name: 'Oak Run',
    postalCode: '96069',
  },
  'oak-vale-ms': {
    name: 'Oak Vale',
    postalCode: '39656',
  },
  'oak-view-ca': {
    name: 'Oak View',
    postalCode: '93022',
  },
  'oakboro-nc': {
    name: 'Oakboro',
    postalCode: '28129',
  },
  'oakdale-ca': {
    name: 'Oakdale',
    postalCode: '95361',
  },
  'oakdale-ct': {
    name: 'Oakdale',
    postalCode: '06370',
  },
  'oakdale-il': {
    name: 'Oakdale',
    postalCode: '62268',
  },
  'oakdale-la': {
    name: 'Oakdale',
    postalCode: '71463',
  },
  'oakdale-ne': {
    name: 'Oakdale',
    postalCode: '68761',
  },
  'oakdale-ny': {
    name: 'Oakdale',
    postalCode: '11769',
  },
  'oakdale-pa': {
    name: 'Oakdale',
    postalCode: '15071',
  },
  'oakdale-tn': {
    name: 'Oakdale',
    postalCode: '37829',
  },
  'oakes-nd': {
    name: 'Oakes',
    postalCode: '58474',
  },
  'oakesdale-wa': {
    name: 'Oakesdale',
    postalCode: '99158',
  },
  'oakfield-ga': {
    name: 'Oakfield',
    postalCode: '31772',
  },
  'oakfield-me': {
    name: 'Oakfield',
    postalCode: '04763',
  },
  'oakfield-ny': {
    name: 'Oakfield',
    postalCode: '14125',
  },
  'oakfield-tn': {
    name: 'Oakfield',
    postalCode: '38362',
  },
  'oakfield-wi': {
    name: 'Oakfield',
    postalCode: '53065',
  },
  'oakford-il': {
    name: 'Oakford',
    postalCode: '62673',
  },
  'oakham-ma': {
    name: 'Oakham',
    postalCode: '01068',
  },
  'oakhurst-ca': {
    name: 'Oakhurst',
    postalCode: '93644',
  },
  'oakhurst-nj': {
    name: 'Oakhurst',
    postalCode: '07755',
  },
  'oakhurst-tx': {
    name: 'Oakhurst',
    postalCode: '77359',
  },
  'oakland-ar': {
    name: 'Oakland',
    postalCode: '72661',
  },
  'oakland-ca': {
    name: 'Oakland',
    postalCode: '94601',
  },
  'oakland-ia': {
    name: 'Oakland',
    postalCode: '51560',
  },
  'oakland-il': {
    name: 'Oakland',
    postalCode: '61943',
  },
  'oakland-ky': {
    name: 'Oakland',
    postalCode: '42159',
  },
  'oakland-md': {
    name: 'Oakland',
    postalCode: '21550',
  },
  'oakland-me': {
    name: 'Oakland',
    postalCode: '04963',
  },
  'oakland-mi': {
    name: 'Oakland',
    postalCode: '48363',
  },
  'oakland-ms': {
    name: 'Oakland',
    postalCode: '38948',
  },
  'oakland-ne': {
    name: 'Oakland',
    postalCode: '68045',
  },
  'oakland-nj': {
    name: 'Oakland',
    postalCode: '07436',
  },
  'oakland-or': {
    name: 'Oakland',
    postalCode: '97462',
  },
  'oakland-ri': {
    name: 'Oakland',
    postalCode: '02858',
  },
  'oakland-tn': {
    name: 'Oakland',
    postalCode: '38060',
  },
  'oakland-city-in': {
    name: 'Oakland City',
    postalCode: '47660',
  },
  'oakland-gardens-ny': {
    name: 'Oakland Gardens',
    postalCode: '11364',
  },
  'oakland-mills-pa': {
    name: 'Oakland Mills',
    postalCode: '17076',
  },
  'oakley-ca': {
    name: 'Oakley',
    postalCode: '94561',
  },
  'oakley-id': {
    name: 'Oakley',
    postalCode: '83346',
  },
  'oakley-ks': {
    name: 'Oakley',
    postalCode: '67748',
  },
  'oakley-mi': {
    name: 'Oakley',
    postalCode: '48649',
  },
  'oakley-ut': {
    name: 'Oakley',
    postalCode: '84055',
  },
  'oaklyn-nj': {
    name: 'Oaklyn',
    postalCode: '08107',
  },
  'oakman-al': {
    name: 'Oakman',
    postalCode: '35579',
  },
  'oakmont-pa': {
    name: 'Oakmont',
    postalCode: '15139',
  },
  'oakpark-va': {
    name: 'Oakpark',
    postalCode: '22730',
  },
  'oakridge-or': {
    name: 'Oakridge',
    postalCode: '97463',
  },
  'oaks-ok': {
    name: 'Oaks',
    postalCode: '74359',
  },
  'oakton-va': {
    name: 'Oakton',
    postalCode: '22124',
  },
  'oaktown-in': {
    name: 'Oaktown',
    postalCode: '47561',
  },
  'oakville-ct': {
    name: 'Oakville',
    postalCode: '06779',
  },
  'oakville-ia': {
    name: 'Oakville',
    postalCode: '52646',
  },
  'oakville-tx': {
    name: 'Oakville',
    postalCode: '78060',
  },
  'oakville-wa': {
    name: 'Oakville',
    postalCode: '98568',
  },
  'oakwood-ga': {
    name: 'Oakwood',
    postalCode: '30566',
  },
  'oakwood-il': {
    name: 'Oakwood',
    postalCode: '61858',
  },
  'oakwood-oh': {
    name: 'Oakwood',
    postalCode: '45873',
  },
  'oakwood-ok': {
    name: 'Oakwood',
    postalCode: '73658',
  },
  'oakwood-tx': {
    name: 'Oakwood',
    postalCode: '75855',
  },
  'oakwood-va': {
    name: 'Oakwood',
    postalCode: '24631',
  },
  'oark-ar': {
    name: 'Oark',
    postalCode: '72852',
  },
  'oberlin-ks': {
    name: 'Oberlin',
    postalCode: '67749',
  },
  'oberlin-la': {
    name: 'Oberlin',
    postalCode: '70655',
  },
  'oberlin-oh': {
    name: 'Oberlin',
    postalCode: '44074',
  },
  'oberon-nd': {
    name: 'Oberon',
    postalCode: '58357',
  },
  'obion-tn': {
    name: 'Obion',
    postalCode: '38240',
  },
  'oblong-il': {
    name: 'Oblong',
    postalCode: '62449',
  },
  'ocala-fl': {
    name: 'Ocala',
    postalCode: '34470',
  },
  'ocate-nm': {
    name: 'Ocate',
    postalCode: '87734',
  },
  'occidental-ca': {
    name: 'Occidental',
    postalCode: '95465',
  },
  'ocean-city-md': {
    name: 'Ocean City',
    postalCode: '21842',
  },
  'ocean-city-nj': {
    name: 'Ocean City',
    postalCode: '08226',
  },
  'ocean-grove-nj': {
    name: 'Ocean Grove',
    postalCode: '07756',
  },
  'ocean-isle-beach-nc': {
    name: 'Ocean Isle Beach',
    postalCode: '28469',
  },
  'ocean-park-wa': {
    name: 'Ocean Park',
    postalCode: '98640',
  },
  'ocean-shores-wa': {
    name: 'Ocean Shores',
    postalCode: '98569',
  },
  'ocean-springs-ms': {
    name: 'Ocean Springs',
    postalCode: '39564',
  },
  'ocean-view-de': {
    name: 'Ocean View',
    postalCode: '19970',
  },
  'ocean-view-nj': {
    name: 'Ocean View',
    postalCode: '08230',
  },
  'oceana-wv': {
    name: 'Oceana',
    postalCode: '24870',
  },
  'oceano-ca': {
    name: 'Oceano',
    postalCode: '93445',
  },
  'oceanport-nj': {
    name: 'Oceanport',
    postalCode: '07757',
  },
  'oceanside-ca': {
    name: 'Oceanside',
    postalCode: '92054',
  },
  'oceanside-ny': {
    name: 'Oceanside',
    postalCode: '11572',
  },
  'ochelata-ok': {
    name: 'Ochelata',
    postalCode: '74051',
  },
  'ocheyedan-ia': {
    name: 'Ocheyedan',
    postalCode: '51354',
  },
  'ochlocknee-ga': {
    name: 'Ochlocknee',
    postalCode: '31773',
  },
  'ochopee-fl': {
    name: 'Ochopee',
    postalCode: '34141',
  },
  'ocilla-ga': {
    name: 'Ocilla',
    postalCode: '31774',
  },
  'ocklawaha-fl': {
    name: 'Ocklawaha',
    postalCode: '32179',
  },
  'ocoee-fl': {
    name: 'Ocoee',
    postalCode: '34761',
  },
  'ocoee-tn': {
    name: 'Ocoee',
    postalCode: '37361',
  },
  'oconee-il': {
    name: 'Oconee',
    postalCode: '62553',
  },
  'oconomowoc-wi': {
    name: 'Oconomowoc',
    postalCode: '53066',
  },
  'oconto-ne': {
    name: 'Oconto',
    postalCode: '68860',
  },
  'oconto-wi': {
    name: 'Oconto',
    postalCode: '54153',
  },
  'oconto-falls-wi': {
    name: 'Oconto Falls',
    postalCode: '54154',
  },
  'odd-wv': {
    name: 'Odd',
    postalCode: '25902',
  },
  'odebolt-ia': {
    name: 'Odebolt',
    postalCode: '51458',
  },
  'odell-il': {
    name: 'Odell',
    postalCode: '60460',
  },
  'odell-ne': {
    name: 'Odell',
    postalCode: '68415',
  },
  'odell-tx': {
    name: 'Odell',
    postalCode: '79247',
  },
  'odem-tx': {
    name: 'Odem',
    postalCode: '78370',
  },
  'oden-ar': {
    name: 'Oden',
    postalCode: '71961',
  },
  'odenton-md': {
    name: 'Odenton',
    postalCode: '21113',
  },
  'odenville-al': {
    name: 'Odenville',
    postalCode: '35120',
  },
  'odessa-fl': {
    name: 'Odessa',
    postalCode: '33556',
  },
  'odessa-mn': {
    name: 'Odessa',
    postalCode: '56276',
  },
  'odessa-mo': {
    name: 'Odessa',
    postalCode: '64076',
  },
  'odessa-ne': {
    name: 'Odessa',
    postalCode: '68861',
  },
  'odessa-ny': {
    name: 'Odessa',
    postalCode: '14869',
  },
  'odessa-tx': {
    name: 'Odessa',
    postalCode: '79761',
  },
  'odessa-wa': {
    name: 'Odessa',
    postalCode: '99159',
  },
  'odin-il': {
    name: 'Odin',
    postalCode: '62870',
  },
  'odin-mn': {
    name: 'Odin',
    postalCode: '56160',
  },
  'odon-in': {
    name: 'Odon',
    postalCode: '47562',
  },
  'odonnell-tx': {
    name: 'Odonnell',
    postalCode: '79351',
  },
  'odum-ga': {
    name: 'Odum',
    postalCode: '31555',
  },
  'oelrichs-sd': {
    name: 'Oelrichs',
    postalCode: '57763',
  },
  'oelwein-ia': {
    name: 'Oelwein',
    postalCode: '50662',
  },
  'offerle-ks': {
    name: 'Offerle',
    postalCode: '67563',
  },
  'offutt-afb-ne': {
    name: 'Offutt AFB',
    postalCode: '68113',
  },
  'ogallah-ks': {
    name: 'Ogallah',
    postalCode: '67656',
  },
  'ogallala-ne': {
    name: 'Ogallala',
    postalCode: '69153',
  },
  'ogden-ar': {
    name: 'Ogden',
    postalCode: '71853',
  },
  'ogden-ia': {
    name: 'Ogden',
    postalCode: '50212',
  },
  'ogden-il': {
    name: 'Ogden',
    postalCode: '61859',
  },
  'ogden-ks': {
    name: 'Ogden',
    postalCode: '66517',
  },
  'ogden-ut': {
    name: 'Ogden',
    postalCode: '84401',
  },
  'ogdensburg-nj': {
    name: 'Ogdensburg',
    postalCode: '07439',
  },
  'ogdensburg-ny': {
    name: 'Ogdensburg',
    postalCode: '13669',
  },
  'ogdensburg-wi': {
    name: 'Ogdensburg',
    postalCode: '54962',
  },
  'ogema-mn': {
    name: 'Ogema',
    postalCode: '56569',
  },
  'ogema-wi': {
    name: 'Ogema',
    postalCode: '54459',
  },
  'ogilvie-mn': {
    name: 'Ogilvie',
    postalCode: '56358',
  },
  'oglala-sd': {
    name: 'Oglala',
    postalCode: '57764',
  },
  'oglesby-il': {
    name: 'Oglesby',
    postalCode: '61348',
  },
  'oglesby-tx': {
    name: 'Oglesby',
    postalCode: '76561',
  },
  'oglethorpe-ga': {
    name: 'Oglethorpe',
    postalCode: '31068',
  },
  'ogunquit-me': {
    name: 'Ogunquit',
    postalCode: '03907',
  },
  'ohatchee-al': {
    name: 'Ohatchee',
    postalCode: '36271',
  },
  'ohio-il': {
    name: 'Ohio',
    postalCode: '61349',
  },
  'ohio-city-co': {
    name: 'Ohio City',
    postalCode: '81237',
  },
  'ohio-city-oh': {
    name: 'Ohio City',
    postalCode: '45874',
  },
  'ohiopyle-pa': {
    name: 'Ohiopyle',
    postalCode: '15470',
  },
  'ohiowa-ne': {
    name: 'Ohiowa',
    postalCode: '68416',
  },
  'ohkay-owingeh-nm': {
    name: 'Ohkay Owingeh',
    postalCode: '87566',
  },
  'oil-city-la': {
    name: 'Oil City',
    postalCode: '71061',
  },
  'oil-city-pa': {
    name: 'Oil City',
    postalCode: '16301',
  },
  'oil-springs-ky': {
    name: 'Oil Springs',
    postalCode: '41238',
  },
  'oil-trough-ar': {
    name: 'Oil Trough',
    postalCode: '72564',
  },
  'oilmont-mt': {
    name: 'Oilmont',
    postalCode: '59466',
  },
  'oilville-va': {
    name: 'Oilville',
    postalCode: '23129',
  },
  'ojai-ca': {
    name: 'Ojai',
    postalCode: '93023',
  },
  'ojibwa-wi': {
    name: 'Ojibwa',
    postalCode: '54862',
  },
  'ojo-caliente-nm': {
    name: 'Ojo Caliente',
    postalCode: '87549',
  },
  'ojo-feliz-nm': {
    name: 'Ojo Feliz',
    postalCode: '87735',
  },
  'okabena-mn': {
    name: 'Okabena',
    postalCode: '56161',
  },
  'okahumpka-fl': {
    name: 'Okahumpka',
    postalCode: '34762',
  },
  'okanogan-wa': {
    name: 'Okanogan',
    postalCode: '98840',
  },
  'okarche-ok': {
    name: 'Okarche',
    postalCode: '73762',
  },
  'okatie-sc': {
    name: 'Okatie',
    postalCode: '29909',
  },
  'okaton-sd': {
    name: 'Okaton',
    postalCode: '57562',
  },
  'okauchee-wi': {
    name: 'Okauchee',
    postalCode: '53069',
  },
  'okawville-il': {
    name: 'Okawville',
    postalCode: '62271',
  },
  'okeana-oh': {
    name: 'Okeana',
    postalCode: '45053',
  },
  'okeechobee-fl': {
    name: 'Okeechobee',
    postalCode: '34972',
  },
  'okeene-ok': {
    name: 'Okeene',
    postalCode: '73763',
  },
  'okemah-ok': {
    name: 'Okemah',
    postalCode: '74859',
  },
  'okemos-mi': {
    name: 'Okemos',
    postalCode: '48864',
  },
  'oketo-ks': {
    name: 'Oketo',
    postalCode: '66518',
  },
  'oklahoma-city-ok': {
    name: 'Oklahoma City',
    postalCode: '73102',
  },
  'oklaunion-tx': {
    name: 'Oklaunion',
    postalCode: '76373',
  },
  'oklee-mn': {
    name: 'Oklee',
    postalCode: '56742',
  },
  'okmulgee-ok': {
    name: 'Okmulgee',
    postalCode: '74447',
  },
  'okoboji-ia': {
    name: 'Okoboji',
    postalCode: '51355',
  },
  'okolona-ar': {
    name: 'Okolona',
    postalCode: '71962',
  },
  'okolona-ms': {
    name: 'Okolona',
    postalCode: '38860',
  },
  'oktaha-ok': {
    name: 'Oktaha',
    postalCode: '74450',
  },
  'ola-ar': {
    name: 'Ola',
    postalCode: '72853',
  },
  'ola-id': {
    name: 'Ola',
    postalCode: '83657',
  },
  'olalla-wa': {
    name: 'Olalla',
    postalCode: '98359',
  },
  'olanta-pa': {
    name: 'Olanta',
    postalCode: '16863',
  },
  'olanta-sc': {
    name: 'Olanta',
    postalCode: '29114',
  },
  'olar-sc': {
    name: 'Olar',
    postalCode: '29843',
  },
  'olathe-co': {
    name: 'Olathe',
    postalCode: '81425',
  },
  'olathe-ks': {
    name: 'Olathe',
    postalCode: '66061',
  },
  'olaton-ky': {
    name: 'Olaton',
    postalCode: '42361',
  },
  'old-appleton-mo': {
    name: 'Old Appleton',
    postalCode: '63770',
  },
  'old-bethpage-ny': {
    name: 'Old Bethpage',
    postalCode: '11804',
  },
  'old-bridge-nj': {
    name: 'Old Bridge',
    postalCode: '08857',
  },
  'old-chatham-ny': {
    name: 'Old Chatham',
    postalCode: '12136',
  },
  'old-fields-wv': {
    name: 'Old Fields',
    postalCode: '26845',
  },
  'old-forge-ny': {
    name: 'Old Forge',
    postalCode: '13420',
  },
  'old-forge-pa': {
    name: 'Old Forge',
    postalCode: '18518',
  },
  'old-fort-nc': {
    name: 'Old Fort',
    postalCode: '28762',
  },
  'old-fort-tn': {
    name: 'Old Fort',
    postalCode: '37362',
  },
  'old-glory-tx': {
    name: 'Old Glory',
    postalCode: '79540',
  },
  'old-greenwich-ct': {
    name: 'Old Greenwich',
    postalCode: '06870',
  },
  'old-hickory-tn': {
    name: 'Old Hickory',
    postalCode: '37138',
  },
  'old-lyme-ct': {
    name: 'Old Lyme',
    postalCode: '06371',
  },
  'old-monroe-mo': {
    name: 'Old Monroe',
    postalCode: '63369',
  },
  'old-orchard-beach-me': {
    name: 'Old Orchard Beach',
    postalCode: '04064',
  },
  'old-saybrook-ct': {
    name: 'Old Saybrook',
    postalCode: '06475',
  },
  'old-station-ca': {
    name: 'Old Station',
    postalCode: '96071',
  },
  'old-town-fl': {
    name: 'Old Town',
    postalCode: '32680',
  },
  'old-town-me': {
    name: 'Old Town',
    postalCode: '04468',
  },
  'old-westbury-ny': {
    name: 'Old Westbury',
    postalCode: '11568',
  },
  'oldenburg-in': {
    name: 'Oldenburg',
    postalCode: '47036',
  },
  'oldfield-mo': {
    name: 'Oldfield',
    postalCode: '65720',
  },
  'oldham-sd': {
    name: 'Oldham',
    postalCode: '57051',
  },
  'oldsmar-fl': {
    name: 'Oldsmar',
    postalCode: '34677',
  },
  'oldtown-id': {
    name: 'Oldtown',
    postalCode: '83822',
  },
  'oldtown-md': {
    name: 'Oldtown',
    postalCode: '21555',
  },
  'olean-mo': {
    name: 'Olean',
    postalCode: '65064',
  },
  'olean-ny': {
    name: 'Olean',
    postalCode: '14760',
  },
  'oley-pa': {
    name: 'Oley',
    postalCode: '19547',
  },
  'olga-wa': {
    name: 'Olga',
    postalCode: '98279',
  },
  'olin-ia': {
    name: 'Olin',
    postalCode: '52320',
  },
  'olin-nc': {
    name: 'Olin',
    postalCode: '28660',
  },
  'olive-mt': {
    name: 'Olive',
    postalCode: '59343',
  },
  'olive-branch-il': {
    name: 'Olive Branch',
    postalCode: '62969',
  },
  'olive-branch-ms': {
    name: 'Olive Branch',
    postalCode: '38654',
  },
  'olive-hill-ky': {
    name: 'Olive Hill',
    postalCode: '41164',
  },
  'olivebridge-ny': {
    name: 'Olivebridge',
    postalCode: '12461',
  },
  'oliveburg-pa': {
    name: 'Oliveburg',
    postalCode: '15764',
  },
  'olivehill-tn': {
    name: 'Olivehill',
    postalCode: '38475',
  },
  'olivehurst-ca': {
    name: 'Olivehurst',
    postalCode: '95961',
  },
  'oliver-springs-tn': {
    name: 'Oliver Springs',
    postalCode: '37840',
  },
  'olivet-mi': {
    name: 'Olivet',
    postalCode: '49076',
  },
  'olivet-sd': {
    name: 'Olivet',
    postalCode: '57052',
  },
  'olivia-mn': {
    name: 'Olivia',
    postalCode: '56277',
  },
  'olla-la': {
    name: 'Olla',
    postalCode: '71465',
  },
  'ollie-ia': {
    name: 'Ollie',
    postalCode: '52576',
  },
  'olmito-tx': {
    name: 'Olmito',
    postalCode: '78575',
  },
  'olmitz-ks': {
    name: 'Olmitz',
    postalCode: '67564',
  },
  'olmstead-ky': {
    name: 'Olmstead',
    postalCode: '42265',
  },
  'olmsted-il': {
    name: 'Olmsted',
    postalCode: '62970',
  },
  'olmsted-falls-oh': {
    name: 'Olmsted Falls',
    postalCode: '44138',
  },
  'olmstedville-ny': {
    name: 'Olmstedville',
    postalCode: '12857',
  },
  'olney-il': {
    name: 'Olney',
    postalCode: '62450',
  },
  'olney-md': {
    name: 'Olney',
    postalCode: '20832',
  },
  'olney-tx': {
    name: 'Olney',
    postalCode: '76374',
  },
  'olney-springs-co': {
    name: 'Olney Springs',
    postalCode: '81062',
  },
  'olpe-ks': {
    name: 'Olpe',
    postalCode: '66865',
  },
  'olsburg-ks': {
    name: 'Olsburg',
    postalCode: '66520',
  },
  'olton-tx': {
    name: 'Olton',
    postalCode: '79064',
  },
  'olustee-ok': {
    name: 'Olustee',
    postalCode: '73560',
  },
  'olympia-ky': {
    name: 'Olympia',
    postalCode: '40358',
  },
  'olympia-wa': {
    name: 'Olympia',
    postalCode: '98501',
  },
  'olympia-fields-il': {
    name: 'Olympia Fields',
    postalCode: '60461',
  },
  'olympic-valley-ca': {
    name: 'Olympic Valley',
    postalCode: '96146',
  },
  'olyphant-pa': {
    name: 'Olyphant',
    postalCode: '18447',
  },
  'omaha-ar': {
    name: 'Omaha',
    postalCode: '72662',
  },
  'omaha-ga': {
    name: 'Omaha',
    postalCode: '31821',
  },
  'omaha-il': {
    name: 'Omaha',
    postalCode: '62871',
  },
  'omaha-ne': {
    name: 'Omaha',
    postalCode: '68102',
  },
  'omaha-tx': {
    name: 'Omaha',
    postalCode: '75571',
  },
  'omak-wa': {
    name: 'Omak',
    postalCode: '98841',
  },
  'omar-wv': {
    name: 'Omar',
    postalCode: '25638',
  },
  'omega-ga': {
    name: 'Omega',
    postalCode: '31775',
  },
  'omega-ok': {
    name: 'Omega',
    postalCode: '73764',
  },
  'omer-mi': {
    name: 'Omer',
    postalCode: '48749',
  },
  'omro-wi': {
    name: 'Omro',
    postalCode: '54963',
  },
  'ona-fl': {
    name: 'Ona',
    postalCode: '33865',
  },
  'ona-wv': {
    name: 'Ona',
    postalCode: '25545',
  },
  'onaga-ks': {
    name: 'Onaga',
    postalCode: '66521',
  },
  'onaka-sd': {
    name: 'Onaka',
    postalCode: '57466',
  },
  'onalaska-tx': {
    name: 'Onalaska',
    postalCode: '77360',
  },
  'onalaska-wa': {
    name: 'Onalaska',
    postalCode: '98570',
  },
  'onalaska-wi': {
    name: 'Onalaska',
    postalCode: '54650',
  },
  'onamia-mn': {
    name: 'Onamia',
    postalCode: '56359',
  },
  'onancock-va': {
    name: 'Onancock',
    postalCode: '23417',
  },
  'onarga-il': {
    name: 'Onarga',
    postalCode: '60955',
  },
  'onawa-ia': {
    name: 'Onawa',
    postalCode: '51040',
  },
  'onaway-mi': {
    name: 'Onaway',
    postalCode: '49765',
  },
  'oneida-il': {
    name: 'Oneida',
    postalCode: '61467',
  },
  'oneida-ks': {
    name: 'Oneida',
    postalCode: '66522',
  },
  'oneida-ky': {
    name: 'Oneida',
    postalCode: '40972',
  },
  'oneida-ny': {
    name: 'Oneida',
    postalCode: '13421',
  },
  'oneida-tn': {
    name: 'Oneida',
    postalCode: '37841',
  },
  'oneida-wi': {
    name: 'Oneida',
    postalCode: '54155',
  },
  'oneill-ne': {
    name: 'Oneill',
    postalCode: '68763',
  },
  'onekama-mi': {
    name: 'Onekama',
    postalCode: '49675',
  },
  'onemo-va': {
    name: 'Onemo',
    postalCode: '23130',
  },
  'oneonta-al': {
    name: 'Oneonta',
    postalCode: '35121',
  },
  'oneonta-ny': {
    name: 'Oneonta',
    postalCode: '13820',
  },
  'ong-ne': {
    name: 'Ong',
    postalCode: '68452',
  },
  'onia-ar': {
    name: 'Onia',
    postalCode: '72663',
  },
  'onida-sd': {
    name: 'Onida',
    postalCode: '57564',
  },
  'onley-va': {
    name: 'Onley',
    postalCode: '23418',
  },
  'only-tn': {
    name: 'Only',
    postalCode: '37140',
  },
  'onondaga-mi': {
    name: 'Onondaga',
    postalCode: '49264',
  },
  'onslow-ia': {
    name: 'Onslow',
    postalCode: '52321',
  },
  'onsted-mi': {
    name: 'Onsted',
    postalCode: '49265',
  },
  'ontario-ca': {
    name: 'Ontario',
    postalCode: '91758',
  },
  'ontario-ny': {
    name: 'Ontario',
    postalCode: '14519',
  },
  'ontario-or': {
    name: 'Ontario',
    postalCode: '97914',
  },
  'ontario-wi': {
    name: 'Ontario',
    postalCode: '54651',
  },
  'ontonagon-mi': {
    name: 'Ontonagon',
    postalCode: '49953',
  },
  'onyx-ca': {
    name: 'Onyx',
    postalCode: '93255',
  },
  'oolitic-in': {
    name: 'Oolitic',
    postalCode: '47451',
  },
  'oologah-ok': {
    name: 'Oologah',
    postalCode: '74053',
  },
  'ooltewah-tn': {
    name: 'Ooltewah',
    postalCode: '37363',
  },
  'oostburg-wi': {
    name: 'Oostburg',
    postalCode: '53070',
  },
  'opa-locka-fl': {
    name: 'Opa Locka',
    postalCode: '33054',
  },
  'opdyke-il': {
    name: 'Opdyke',
    postalCode: '62872',
  },
  'opelika-al': {
    name: 'Opelika',
    postalCode: '36801',
  },
  'opelousas-la': {
    name: 'Opelousas',
    postalCode: '70570',
  },
  'opheim-mt': {
    name: 'Opheim',
    postalCode: '59250',
  },
  'ophiem-il': {
    name: 'Ophiem',
    postalCode: '61468',
  },
  'opp-al': {
    name: 'Opp',
    postalCode: '36467',
  },
  'oquawka-il': {
    name: 'Oquawka',
    postalCode: '61469',
  },
  'ora-in': {
    name: 'Ora',
    postalCode: '46968',
  },
  'oracle-az': {
    name: 'Oracle',
    postalCode: '85623',
  },
  'oradell-nj': {
    name: 'Oradell',
    postalCode: '07649',
  },
  'oral-sd': {
    name: 'Oral',
    postalCode: '57766',
  },
  'oran-ia': {
    name: 'Oran',
    postalCode: '50664',
  },
  'oran-mo': {
    name: 'Oran',
    postalCode: '63771',
  },
  'orange-ca': {
    name: 'Orange',
    postalCode: '92862',
  },
  'orange-ct': {
    name: 'Orange',
    postalCode: '06477',
  },
  'orange-ma': {
    name: 'Orange',
    postalCode: '01364',
  },
  'orange-nj': {
    name: 'Orange',
    postalCode: '07050',
  },
  'orange-tx': {
    name: 'Orange',
    postalCode: '77630',
  },
  'orange-va': {
    name: 'Orange',
    postalCode: '22960',
  },
  'orange-beach-al': {
    name: 'Orange Beach',
    postalCode: '36561',
  },
  'orange-city-fl': {
    name: 'Orange City',
    postalCode: '32763',
  },
  'orange-city-ia': {
    name: 'Orange City',
    postalCode: '51041',
  },
  'orange-cove-ca': {
    name: 'Orange Cove',
    postalCode: '93646',
  },
  'orange-grove-tx': {
    name: 'Orange Grove',
    postalCode: '78372',
  },
  'orange-park-fl': {
    name: 'Orange Park',
    postalCode: '32065',
  },
  'orangeburg-ny': {
    name: 'Orangeburg',
    postalCode: '10962',
  },
  'orangeburg-sc': {
    name: 'Orangeburg',
    postalCode: '29115',
  },
  'orangevale-ca': {
    name: 'Orangevale',
    postalCode: '95662',
  },
  'orangeville-il': {
    name: 'Orangeville',
    postalCode: '61060',
  },
  'orangeville-pa': {
    name: 'Orangeville',
    postalCode: '17859',
  },
  'orbisonia-pa': {
    name: 'Orbisonia',
    postalCode: '17243',
  },
  'orchard-co': {
    name: 'Orchard',
    postalCode: '80649',
  },
  'orchard-ia': {
    name: 'Orchard',
    postalCode: '50460',
  },
  'orchard-ne': {
    name: 'Orchard',
    postalCode: '68764',
  },
  'orchard-park-ny': {
    name: 'Orchard Park',
    postalCode: '14127',
  },
  'ord-ne': {
    name: 'Ord',
    postalCode: '68862',
  },
  'ordway-co': {
    name: 'Ordway',
    postalCode: '81063',
  },
  'ore-city-tx': {
    name: 'Ore City',
    postalCode: '75683',
  },
  'oreana-il': {
    name: 'Oreana',
    postalCode: '62554',
  },
  'orefield-pa': {
    name: 'Orefield',
    postalCode: '18069',
  },
  'oregon-il': {
    name: 'Oregon',
    postalCode: '61061',
  },
  'oregon-mo': {
    name: 'Oregon',
    postalCode: '64473',
  },
  'oregon-oh': {
    name: 'Oregon',
    postalCode: '43616',
  },
  'oregon-wi': {
    name: 'Oregon',
    postalCode: '53575',
  },
  'oregon-city-or': {
    name: 'Oregon City',
    postalCode: '97045',
  },
  'oregon-house-ca': {
    name: 'Oregon House',
    postalCode: '95962',
  },
  'oregonia-oh': {
    name: 'Oregonia',
    postalCode: '45054',
  },
  'oreland-pa': {
    name: 'Oreland',
    postalCode: '19075',
  },
  'orem-ut': {
    name: 'Orem',
    postalCode: '84057',
  },
  'orford-nh': {
    name: 'Orford',
    postalCode: '03777',
  },
  'orfordville-wi': {
    name: 'Orfordville',
    postalCode: '53576',
  },
  'orgas-wv': {
    name: 'Orgas',
    postalCode: '25148',
  },
  'orick-ca': {
    name: 'Orick',
    postalCode: '95555',
  },
  'orient-ia': {
    name: 'Orient',
    postalCode: '50858',
  },
  'orient-il': {
    name: 'Orient',
    postalCode: '62874',
  },
  'orient-me': {
    name: 'Orient',
    postalCode: '04471',
  },
  'orient-ny': {
    name: 'Orient',
    postalCode: '11957',
  },
  'orient-oh': {
    name: 'Orient',
    postalCode: '43146',
  },
  'orient-sd': {
    name: 'Orient',
    postalCode: '57467',
  },
  'orient-wa': {
    name: 'Orient',
    postalCode: '99160',
  },
  'oriental-nc': {
    name: 'Oriental',
    postalCode: '28571',
  },
  'orinda-ca': {
    name: 'Orinda',
    postalCode: '94563',
  },
  'orion-il': {
    name: 'Orion',
    postalCode: '61273',
  },
  'oriska-nd': {
    name: 'Oriska',
    postalCode: '58063',
  },
  'oriskany-ny': {
    name: 'Oriskany',
    postalCode: '13424',
  },
  'oriskany-va': {
    name: 'Oriskany',
    postalCode: '24130',
  },
  'oriskany-falls-ny': {
    name: 'Oriskany Falls',
    postalCode: '13425',
  },
  'orkney-springs-va': {
    name: 'Orkney Springs',
    postalCode: '22845',
  },
  'orland-ca': {
    name: 'Orland',
    postalCode: '95963',
  },
  'orland-in': {
    name: 'Orland',
    postalCode: '46776',
  },
  'orland-me': {
    name: 'Orland',
    postalCode: '04472',
  },
  'orland-park-il': {
    name: 'Orland Park',
    postalCode: '60462',
  },
  'orlando-fl': {
    name: 'Orlando',
    postalCode: '32801',
  },
  'orlando-ky': {
    name: 'Orlando',
    postalCode: '40460',
  },
  'orlando-ok': {
    name: 'Orlando',
    postalCode: '73073',
  },
  'orlando-wv': {
    name: 'Orlando',
    postalCode: '26412',
  },
  'orleans-in': {
    name: 'Orleans',
    postalCode: '47452',
  },
  'orleans-ma': {
    name: 'Orleans',
    postalCode: '02653',
  },
  'orleans-mi': {
    name: 'Orleans',
    postalCode: '48865',
  },
  'orleans-ne': {
    name: 'Orleans',
    postalCode: '68966',
  },
  'orleans-vt': {
    name: 'Orleans',
    postalCode: '05860',
  },
  'orlinda-tn': {
    name: 'Orlinda',
    postalCode: '37141',
  },
  'orma-wv': {
    name: 'Orma',
    postalCode: '25268',
  },
  'ormond-beach-fl': {
    name: 'Ormond Beach',
    postalCode: '32174',
  },
  'ormsby-mn': {
    name: 'Ormsby',
    postalCode: '56162',
  },
  'oro-grande-ca': {
    name: 'Oro Grande',
    postalCode: '92368',
  },
  'orocovis-pr': {
    name: 'Orocovis',
    postalCode: '00720',
  },
  'orofino-id': {
    name: 'Orofino',
    postalCode: '83544',
  },
  'orondo-wa': {
    name: 'Orondo',
    postalCode: '98843',
  },
  'orono-me': {
    name: 'Orono',
    postalCode: '04473',
  },
  'oronoco-mn': {
    name: 'Oronoco',
    postalCode: '55960',
  },
  'oronogo-mo': {
    name: 'Oronogo',
    postalCode: '64855',
  },
  'orosi-ca': {
    name: 'Orosi',
    postalCode: '93647',
  },
  'orovada-nv': {
    name: 'Orovada',
    postalCode: '89425',
  },
  'oroville-ca': {
    name: 'Oroville',
    postalCode: '95965',
  },
  'oroville-wa': {
    name: 'Oroville',
    postalCode: '98844',
  },
  'orr-mn': {
    name: 'Orr',
    postalCode: '55771',
  },
  'orrick-mo': {
    name: 'Orrick',
    postalCode: '64077',
  },
  'orrington-me': {
    name: 'Orrington',
    postalCode: '04474',
  },
  'orrs-island-me': {
    name: 'Orrs Island',
    postalCode: '04066',
  },
  'orrstown-pa': {
    name: 'Orrstown',
    postalCode: '17244',
  },
  'orrtanna-pa': {
    name: 'Orrtanna',
    postalCode: '17353',
  },
  'orrum-nc': {
    name: 'Orrum',
    postalCode: '28369',
  },
  'orrville-al': {
    name: 'Orrville',
    postalCode: '36767',
  },
  'orrville-oh': {
    name: 'Orrville',
    postalCode: '44667',
  },
  'orting-wa': {
    name: 'Orting',
    postalCode: '98360',
  },
  'ortley-sd': {
    name: 'Ortley',
    postalCode: '57256',
  },
  'ortonville-mi': {
    name: 'Ortonville',
    postalCode: '48462',
  },
  'ortonville-mn': {
    name: 'Ortonville',
    postalCode: '56278',
  },
  'orviston-pa': {
    name: 'Orviston',
    postalCode: '16864',
  },
  'orwell-oh': {
    name: 'Orwell',
    postalCode: '44076',
  },
  'orwell-vt': {
    name: 'Orwell',
    postalCode: '05760',
  },
  'orwigsburg-pa': {
    name: 'Orwigsburg',
    postalCode: '17961',
  },
  'osage-ia': {
    name: 'Osage',
    postalCode: '50461',
  },
  'osage-mn': {
    name: 'Osage',
    postalCode: '56570',
  },
  'osage-ok': {
    name: 'Osage',
    postalCode: '74054',
  },
  'osage-wv': {
    name: 'Osage',
    postalCode: '26543',
  },
  'osage-wy': {
    name: 'Osage',
    postalCode: '82723',
  },
  'osage-beach-mo': {
    name: 'Osage Beach',
    postalCode: '65065',
  },
  'osage-city-ks': {
    name: 'Osage City',
    postalCode: '66523',
  },
  'osakis-mn': {
    name: 'Osakis',
    postalCode: '56360',
  },
  'osawatomie-ks': {
    name: 'Osawatomie',
    postalCode: '66064',
  },
  'osborn-mo': {
    name: 'Osborn',
    postalCode: '64474',
  },
  'osborne-ks': {
    name: 'Osborne',
    postalCode: '67473',
  },
  'oscar-la': {
    name: 'Oscar',
    postalCode: '70762',
  },
  'oscar-ok': {
    name: 'Oscar',
    postalCode: '73561',
  },
  'osceola-ar': {
    name: 'Osceola',
    postalCode: '72370',
  },
  'osceola-ia': {
    name: 'Osceola',
    postalCode: '50213',
  },
  'osceola-in': {
    name: 'Osceola',
    postalCode: '46561',
  },
  'osceola-mo': {
    name: 'Osceola',
    postalCode: '64776',
  },
  'osceola-ne': {
    name: 'Osceola',
    postalCode: '68651',
  },
  'osceola-pa': {
    name: 'Osceola',
    postalCode: '16942',
  },
  'osceola-wi': {
    name: 'Osceola',
    postalCode: '54020',
  },
  'osceola-mills-pa': {
    name: 'Osceola Mills',
    postalCode: '16666',
  },
  'osco-il': {
    name: 'Osco',
    postalCode: '61274',
  },
  'oscoda-mi': {
    name: 'Oscoda',
    postalCode: '48750',
  },
  'osgood-in': {
    name: 'Osgood',
    postalCode: '47037',
  },
  'oshkosh-ne': {
    name: 'Oshkosh',
    postalCode: '69154',
  },
  'oshkosh-wi': {
    name: 'Oshkosh',
    postalCode: '54901',
  },
  'oskaloosa-ia': {
    name: 'Oskaloosa',
    postalCode: '52577',
  },
  'oskaloosa-ks': {
    name: 'Oskaloosa',
    postalCode: '66066',
  },
  'oslo-mn': {
    name: 'Oslo',
    postalCode: '56744',
  },
  'osmond-ne': {
    name: 'Osmond',
    postalCode: '68765',
  },
  'osnabrock-nd': {
    name: 'Osnabrock',
    postalCode: '58269',
  },
  'osprey-fl': {
    name: 'Osprey',
    postalCode: '34229',
  },
  'osseo-mi': {
    name: 'Osseo',
    postalCode: '49266',
  },
  'osseo-mn': {
    name: 'Osseo',
    postalCode: '55369',
  },
  'osseo-wi': {
    name: 'Osseo',
    postalCode: '54758',
  },
  'ossian-ia': {
    name: 'Ossian',
    postalCode: '52161',
  },
  'ossian-in': {
    name: 'Ossian',
    postalCode: '46777',
  },
  'ossineke-mi': {
    name: 'Ossineke',
    postalCode: '49766',
  },
  'ossining-ny': {
    name: 'Ossining',
    postalCode: '10562',
  },
  'ossipee-nh': {
    name: 'Ossipee',
    postalCode: '03864',
  },
  'osteen-fl': {
    name: 'Osteen',
    postalCode: '32764',
  },
  'osterburg-pa': {
    name: 'Osterburg',
    postalCode: '16667',
  },
  'osterville-ma': {
    name: 'Osterville',
    postalCode: '02655',
  },
  'ostrander-mn': {
    name: 'Ostrander',
    postalCode: '55961',
  },
  'ostrander-oh': {
    name: 'Ostrander',
    postalCode: '43061',
  },
  'oswegatchie-ny': {
    name: 'Oswegatchie',
    postalCode: '13670',
  },
  'oswego-il': {
    name: 'Oswego',
    postalCode: '60543',
  },
  'oswego-ks': {
    name: 'Oswego',
    postalCode: '67356',
  },
  'oswego-ny': {
    name: 'Oswego',
    postalCode: '13126',
  },
  'osyka-ms': {
    name: 'Osyka',
    postalCode: '39657',
  },
  'otego-ny': {
    name: 'Otego',
    postalCode: '13825',
  },
  'othello-wa': {
    name: 'Othello',
    postalCode: '99344',
  },
  'otho-ia': {
    name: 'Otho',
    postalCode: '50569',
  },
  'otis-co': {
    name: 'Otis',
    postalCode: '80743',
  },
  'otis-ks': {
    name: 'Otis',
    postalCode: '67565',
  },
  'otis-la': {
    name: 'Otis',
    postalCode: '71466',
  },
  'otis-ma': {
    name: 'Otis',
    postalCode: '01253',
  },
  'otis-or': {
    name: 'Otis',
    postalCode: '97368',
  },
  'otis-orchards-wa': {
    name: 'Otis Orchards',
    postalCode: '99027',
  },
  'otisco-in': {
    name: 'Otisco',
    postalCode: '47163',
  },
  'otisville-mi': {
    name: 'Otisville',
    postalCode: '48463',
  },
  'otisville-ny': {
    name: 'Otisville',
    postalCode: '10963',
  },
  'otley-ia': {
    name: 'Otley',
    postalCode: '50214',
  },
  'oto-ia': {
    name: 'Oto',
    postalCode: '51044',
  },
  'otoe-ne': {
    name: 'Otoe',
    postalCode: '68417',
  },
  'otsego-mi': {
    name: 'Otsego',
    postalCode: '49078',
  },
  'ottawa-il': {
    name: 'Ottawa',
    postalCode: '61350',
  },
  'ottawa-ks': {
    name: 'Ottawa',
    postalCode: '66067',
  },
  'ottawa-oh': {
    name: 'Ottawa',
    postalCode: '45875',
  },
  'ottawa-lake-mi': {
    name: 'Ottawa Lake',
    postalCode: '49267',
  },
  'otter-mt': {
    name: 'Otter',
    postalCode: '59062',
  },
  'otter-lake-mi': {
    name: 'Otter Lake',
    postalCode: '48464',
  },
  'otter-rock-or': {
    name: 'Otter Rock',
    postalCode: '97369',
  },
  'otterbein-in': {
    name: 'Otterbein',
    postalCode: '47970',
  },
  'ottertail-mn': {
    name: 'Ottertail',
    postalCode: '56571',
  },
  'otterville-mo': {
    name: 'Otterville',
    postalCode: '65348',
  },
  'otto-nc': {
    name: 'Otto',
    postalCode: '28763',
  },
  'otto-wy': {
    name: 'Otto',
    postalCode: '82434',
  },
  'ottosen-ia': {
    name: 'Ottosen',
    postalCode: '50570',
  },
  'ottsville-pa': {
    name: 'Ottsville',
    postalCode: '18942',
  },
  'ottumwa-ia': {
    name: 'Ottumwa',
    postalCode: '52501',
  },
  'otway-oh': {
    name: 'Otway',
    postalCode: '45657',
  },
  'otwell-in': {
    name: 'Otwell',
    postalCode: '47564',
  },
  'ouaquaga-ny': {
    name: 'Ouaquaga',
    postalCode: '13826',
  },
  'outing-mn': {
    name: 'Outing',
    postalCode: '56662',
  },
  'outlook-mt': {
    name: 'Outlook',
    postalCode: '59252',
  },
  'outlook-wa': {
    name: 'Outlook',
    postalCode: '98938',
  },
  'ovalo-tx': {
    name: 'Ovalo',
    postalCode: '79541',
  },
  'ovando-mt': {
    name: 'Ovando',
    postalCode: '59854',
  },
  'overbrook-ks': {
    name: 'Overbrook',
    postalCode: '66524',
  },
  'overbrook-ok': {
    name: 'Overbrook',
    postalCode: '73453',
  },
  'overland-park-ks': {
    name: 'Overland Park',
    postalCode: '66204',
  },
  'overton-ne': {
    name: 'Overton',
    postalCode: '68863',
  },
  'overton-nv': {
    name: 'Overton',
    postalCode: '89040',
  },
  'overton-tx': {
    name: 'Overton',
    postalCode: '75684',
  },
  'ovett-ms': {
    name: 'Ovett',
    postalCode: '39464',
  },
  'ovid-co': {
    name: 'Ovid',
    postalCode: '80744',
  },
  'ovid-mi': {
    name: 'Ovid',
    postalCode: '48866',
  },
  'ovid-ny': {
    name: 'Ovid',
    postalCode: '14521',
  },
  'oviedo-fl': {
    name: 'Oviedo',
    postalCode: '32765',
  },
  'owaneco-il': {
    name: 'Owaneco',
    postalCode: '62555',
  },
  'owanka-sd': {
    name: 'Owanka',
    postalCode: '57767',
  },
  'owasso-ok': {
    name: 'Owasso',
    postalCode: '74055',
  },
  'owatonna-mn': {
    name: 'Owatonna',
    postalCode: '55060',
  },
  'owego-ny': {
    name: 'Owego',
    postalCode: '13827',
  },
  'owen-wi': {
    name: 'Owen',
    postalCode: '54460',
  },
  'owendale-mi': {
    name: 'Owendale',
    postalCode: '48754',
  },
  'owens-cross-roads-al': {
    name: 'Owens Cross Roads',
    postalCode: '35763',
  },
  'owensboro-ky': {
    name: 'Owensboro',
    postalCode: '42301',
  },
  'owensburg-in': {
    name: 'Owensburg',
    postalCode: '47453',
  },
  'owensville-in': {
    name: 'Owensville',
    postalCode: '47665',
  },
  'owensville-mo': {
    name: 'Owensville',
    postalCode: '65066',
  },
  'owensville-oh': {
    name: 'Owensville',
    postalCode: '45160',
  },
  'owenton-ky': {
    name: 'Owenton',
    postalCode: '40359',
  },
  'owings-md': {
    name: 'Owings',
    postalCode: '20736',
  },
  'owings-mills-md': {
    name: 'Owings Mills',
    postalCode: '21117',
  },
  'owingsville-ky': {
    name: 'Owingsville',
    postalCode: '40360',
  },
  'owls-head-me': {
    name: 'Owls Head',
    postalCode: '04854',
  },
  'owls-head-ny': {
    name: 'Owls Head',
    postalCode: '12969',
  },
  'owosso-mi': {
    name: 'Owosso',
    postalCode: '48867',
  },
  'oxbow-me': {
    name: 'Oxbow',
    postalCode: '04764',
  },
  'oxbow-or': {
    name: 'Oxbow',
    postalCode: '97840',
  },
  'oxford-al': {
    name: 'Oxford',
    postalCode: '36203',
  },
  'oxford-ar': {
    name: 'Oxford',
    postalCode: '72565',
  },
  'oxford-ct': {
    name: 'Oxford',
    postalCode: '06478',
  },
  'oxford-fl': {
    name: 'Oxford',
    postalCode: '34484',
  },
  'oxford-ga': {
    name: 'Oxford',
    postalCode: '30054',
  },
  'oxford-ia': {
    name: 'Oxford',
    postalCode: '52322',
  },
  'oxford-in': {
    name: 'Oxford',
    postalCode: '47971',
  },
  'oxford-ks': {
    name: 'Oxford',
    postalCode: '67119',
  },
  'oxford-ma': {
    name: 'Oxford',
    postalCode: '01540',
  },
  'oxford-md': {
    name: 'Oxford',
    postalCode: '21654',
  },
  'oxford-me': {
    name: 'Oxford',
    postalCode: '04270',
  },
  'oxford-mi': {
    name: 'Oxford',
    postalCode: '48370',
  },
  'oxford-ms': {
    name: 'Oxford',
    postalCode: '38655',
  },
  'oxford-nc': {
    name: 'Oxford',
    postalCode: '27565',
  },
  'oxford-ne': {
    name: 'Oxford',
    postalCode: '68967',
  },
  'oxford-nj': {
    name: 'Oxford',
    postalCode: '07863',
  },
  'oxford-ny': {
    name: 'Oxford',
    postalCode: '13830',
  },
  'oxford-oh': {
    name: 'Oxford',
    postalCode: '45056',
  },
  'oxford-pa': {
    name: 'Oxford',
    postalCode: '19363',
  },
  'oxford-wi': {
    name: 'Oxford',
    postalCode: '53952',
  },
  'oxford-junction-ia': {
    name: 'Oxford Junction',
    postalCode: '52323',
  },
  'oxly-mo': {
    name: 'Oxly',
    postalCode: '63955',
  },
  'oxnard-ca': {
    name: 'Oxnard',
    postalCode: '93030',
  },
  'oxon-hill-md': {
    name: 'Oxon Hill',
    postalCode: '20745',
  },
  'oyster-bay-ny': {
    name: 'Oyster Bay',
    postalCode: '11771',
  },
  'ozan-ar': {
    name: 'Ozan',
    postalCode: '71855',
  },
  'ozark-al': {
    name: 'Ozark',
    postalCode: '36360',
  },
  'ozark-ar': {
    name: 'Ozark',
    postalCode: '72949',
  },
  'ozark-il': {
    name: 'Ozark',
    postalCode: '62972',
  },
  'ozark-mo': {
    name: 'Ozark',
    postalCode: '65721',
  },
  'ozawkie-ks': {
    name: 'Ozawkie',
    postalCode: '66070',
  },
  'ozona-tx': {
    name: 'Ozona',
    postalCode: '76943',
  },
  'ozone-ar': {
    name: 'Ozone',
    postalCode: '72854',
  },
  'ozone-park-ny': {
    name: 'Ozone Park',
    postalCode: '11416',
  },
  'pachuta-ms': {
    name: 'Pachuta',
    postalCode: '39347',
  },
  'pacific-mo': {
    name: 'Pacific',
    postalCode: '63069',
  },
  'pacific-wa': {
    name: 'Pacific',
    postalCode: '98047',
  },
  'pacific-grove-ca': {
    name: 'Pacific Grove',
    postalCode: '93950',
  },
  'pacific-junction-ia': {
    name: 'Pacific Junction',
    postalCode: '51561',
  },
  'pacific-palisades-ca': {
    name: 'Pacific Palisades',
    postalCode: '90272',
  },
  'pacifica-ca': {
    name: 'Pacifica',
    postalCode: '94044',
  },
  'packwood-ia': {
    name: 'Packwood',
    postalCode: '52580',
  },
  'packwood-wa': {
    name: 'Packwood',
    postalCode: '98361',
  },
  'pacoima-ca': {
    name: 'Pacoima',
    postalCode: '91331',
  },
  'pacolet-sc': {
    name: 'Pacolet',
    postalCode: '29372',
  },
  'paden-ok': {
    name: 'Paden',
    postalCode: '74860',
  },
  'paden-city-wv': {
    name: 'Paden City',
    postalCode: '26159',
  },
  'padroni-co': {
    name: 'Padroni',
    postalCode: '80745',
  },
  'paducah-ky': {
    name: 'Paducah',
    postalCode: '42001',
  },
  'paducah-tx': {
    name: 'Paducah',
    postalCode: '79248',
  },
  'paeonian-springs-va': {
    name: 'Paeonian Springs',
    postalCode: '20129',
  },
  'page-nd': {
    name: 'Page',
    postalCode: '58064',
  },
  'page-ne': {
    name: 'Page',
    postalCode: '68766',
  },
  'pageland-sc': {
    name: 'Pageland',
    postalCode: '29728',
  },
  'pagosa-springs-co': {
    name: 'Pagosa Springs',
    postalCode: '81147',
  },
  'pahoa-hi': {
    name: 'Pahoa',
    postalCode: '96778',
  },
  'pahokee-fl': {
    name: 'Pahokee',
    postalCode: '33476',
  },
  'pahrump-nv': {
    name: 'Pahrump',
    postalCode: '89048',
  },
  'paia-hi': {
    name: 'Paia',
    postalCode: '96779',
  },
  'paicines-ca': {
    name: 'Paicines',
    postalCode: '95043',
  },
  'paige-tx': {
    name: 'Paige',
    postalCode: '78659',
  },
  'painesville-oh': {
    name: 'Painesville',
    postalCode: '44077',
  },
  'paint-bank-va': {
    name: 'Paint Bank',
    postalCode: '24131',
  },
  'paint-lick-ky': {
    name: 'Paint Lick',
    postalCode: '40461',
  },
  'paint-rock-al': {
    name: 'Paint Rock',
    postalCode: '35764',
  },
  'paint-rock-tx': {
    name: 'Paint Rock',
    postalCode: '76866',
  },
  'painted-post-ny': {
    name: 'Painted Post',
    postalCode: '14870',
  },
  'painter-va': {
    name: 'Painter',
    postalCode: '23420',
  },
  'paintsville-ky': {
    name: 'Paintsville',
    postalCode: '41240',
  },
  'paisley-fl': {
    name: 'Paisley',
    postalCode: '32767',
  },
  'paisley-or': {
    name: 'Paisley',
    postalCode: '97636',
  },
  'pala-ca': {
    name: 'Pala',
    postalCode: '92059',
  },
  'palacios-tx': {
    name: 'Palacios',
    postalCode: '77465',
  },
  'palatine-il': {
    name: 'Palatine',
    postalCode: '60067',
  },
  'palatine-bridge-ny': {
    name: 'Palatine Bridge',
    postalCode: '13428',
  },
  'palatka-fl': {
    name: 'Palatka',
    postalCode: '32177',
  },
  'palco-ks': {
    name: 'Palco',
    postalCode: '67657',
  },
  'palenville-ny': {
    name: 'Palenville',
    postalCode: '12463',
  },
  'palermo-ca': {
    name: 'Palermo',
    postalCode: '95968',
  },
  'palermo-me': {
    name: 'Palermo',
    postalCode: '04354',
  },
  'palermo-nd': {
    name: 'Palermo',
    postalCode: '58769',
  },
  'palestine-ar': {
    name: 'Palestine',
    postalCode: '72372',
  },
  'palestine-il': {
    name: 'Palestine',
    postalCode: '62451',
  },
  'palestine-tx': {
    name: 'Palestine',
    postalCode: '75801',
  },
  'palestine-wv': {
    name: 'Palestine',
    postalCode: '26160',
  },
  'palisade-co': {
    name: 'Palisade',
    postalCode: '81526',
  },
  'palisade-mn': {
    name: 'Palisade',
    postalCode: '56469',
  },
  'palisade-ne': {
    name: 'Palisade',
    postalCode: '69040',
  },
  'palisades-ny': {
    name: 'Palisades',
    postalCode: '10964',
  },
  'palisades-wa': {
    name: 'Palisades',
    postalCode: '98845',
  },
  'palisades-park-nj': {
    name: 'Palisades Park',
    postalCode: '07650',
  },
  'pall-mall-tn': {
    name: 'Pall Mall',
    postalCode: '38577',
  },
  'palm-pa': {
    name: 'Palm',
    postalCode: '18070',
  },
  'palm-bay-fl': {
    name: 'Palm Bay',
    postalCode: '32905',
  },
  'palm-beach-fl': {
    name: 'Palm Beach',
    postalCode: '33480',
  },
  'palm-beach-gardens-fl': {
    name: 'Palm Beach Gardens',
    postalCode: '33410',
  },
  'palm-city-fl': {
    name: 'Palm City',
    postalCode: '34990',
  },
  'palm-coast-fl': {
    name: 'Palm Coast',
    postalCode: '32137',
  },
  'palm-desert-ca': {
    name: 'Palm Desert',
    postalCode: '92211',
  },
  'palm-harbor-fl': {
    name: 'Palm Harbor',
    postalCode: '34683',
  },
  'palm-springs-ca': {
    name: 'Palm Springs',
    postalCode: '92262',
  },
  'palmdale-ca': {
    name: 'Palmdale',
    postalCode: '93550',
  },
  'palmer-ak': {
    name: 'Palmer',
    postalCode: '99645',
  },
  'palmer-ia': {
    name: 'Palmer',
    postalCode: '50571',
  },
  'palmer-il': {
    name: 'Palmer',
    postalCode: '62556',
  },
  'palmer-ks': {
    name: 'Palmer',
    postalCode: '66962',
  },
  'palmer-ma': {
    name: 'Palmer',
    postalCode: '01069',
  },
  'palmer-ne': {
    name: 'Palmer',
    postalCode: '68864',
  },
  'palmer-tn': {
    name: 'Palmer',
    postalCode: '37365',
  },
  'palmer-tx': {
    name: 'Palmer',
    postalCode: '75152',
  },
  'palmer-lake-co': {
    name: 'Palmer Lake',
    postalCode: '80133',
  },
  'palmersville-tn': {
    name: 'Palmersville',
    postalCode: '38241',
  },
  'palmerton-pa': {
    name: 'Palmerton',
    postalCode: '18071',
  },
  'palmetto-fl': {
    name: 'Palmetto',
    postalCode: '34221',
  },
  'palmetto-ga': {
    name: 'Palmetto',
    postalCode: '30268',
  },
  'palmetto-la': {
    name: 'Palmetto',
    postalCode: '71358',
  },
  'palms-mi': {
    name: 'Palms',
    postalCode: '48465',
  },
  'palmyra-il': {
    name: 'Palmyra',
    postalCode: '62674',
  },
  'palmyra-in': {
    name: 'Palmyra',
    postalCode: '47164',
  },
  'palmyra-me': {
    name: 'Palmyra',
    postalCode: '04965',
  },
  'palmyra-mi': {
    name: 'Palmyra',
    postalCode: '49268',
  },
  'palmyra-mo': {
    name: 'Palmyra',
    postalCode: '63461',
  },
  'palmyra-ne': {
    name: 'Palmyra',
    postalCode: '68418',
  },
  'palmyra-nj': {
    name: 'Palmyra',
    postalCode: '08065',
  },
  'palmyra-ny': {
    name: 'Palmyra',
    postalCode: '14522',
  },
  'palmyra-pa': {
    name: 'Palmyra',
    postalCode: '17078',
  },
  'palmyra-tn': {
    name: 'Palmyra',
    postalCode: '37142',
  },
  'palmyra-va': {
    name: 'Palmyra',
    postalCode: '22963',
  },
  'palmyra-wi': {
    name: 'Palmyra',
    postalCode: '53156',
  },
  'palo-ia': {
    name: 'Palo',
    postalCode: '52324',
  },
  'palo-alto-ca': {
    name: 'Palo Alto',
    postalCode: '94301',
  },
  'palo-cedro-ca': {
    name: 'Palo Cedro',
    postalCode: '96073',
  },
  'palo-pinto-tx': {
    name: 'Palo Pinto',
    postalCode: '76484',
  },
  'paloma-il': {
    name: 'Paloma',
    postalCode: '62359',
  },
  'palos-heights-il': {
    name: 'Palos Heights',
    postalCode: '60463',
  },
  'palos-hills-il': {
    name: 'Palos Hills',
    postalCode: '60465',
  },
  'palos-park-il': {
    name: 'Palos Park',
    postalCode: '60464',
  },
  'palos-verdes-peninsula-ca': {
    name: 'Palos Verdes Peninsula',
    postalCode: '90274',
  },
  'palouse-wa': {
    name: 'Palouse',
    postalCode: '99161',
  },
  'pampa-tx': {
    name: 'Pampa',
    postalCode: '79065',
  },
  'pamplico-sc': {
    name: 'Pamplico',
    postalCode: '29583',
  },
  'pamplin-va': {
    name: 'Pamplin',
    postalCode: '23958',
  },
  'pana-il': {
    name: 'Pana',
    postalCode: '62557',
  },
  'panacea-fl': {
    name: 'Panacea',
    postalCode: '32346',
  },
  'panama-ia': {
    name: 'Panama',
    postalCode: '51562',
  },
  'panama-ny': {
    name: 'Panama',
    postalCode: '14767',
  },
  'panama-city-fl': {
    name: 'Panama City',
    postalCode: '32401',
  },
  'panama-city-beach-fl': {
    name: 'Panama City Beach',
    postalCode: '32407',
  },
  'pandora-oh': {
    name: 'Pandora',
    postalCode: '45877',
  },
  'pangburn-ar': {
    name: 'Pangburn',
    postalCode: '72121',
  },
  'panguitch-ut': {
    name: 'Panguitch',
    postalCode: '84759',
  },
  'panhandle-tx': {
    name: 'Panhandle',
    postalCode: '79068',
  },
  'panora-ia': {
    name: 'Panora',
    postalCode: '50216',
  },
  'panorama-city-ca': {
    name: 'Panorama City',
    postalCode: '91402',
  },
  'pansey-al': {
    name: 'Pansey',
    postalCode: '36370',
  },
  'pantego-nc': {
    name: 'Pantego',
    postalCode: '27860',
  },
  'panther-wv': {
    name: 'Panther',
    postalCode: '24872',
  },
  'paola-ks': {
    name: 'Paola',
    postalCode: '66071',
  },
  'paoli-co': {
    name: 'Paoli',
    postalCode: '80746',
  },
  'paoli-in': {
    name: 'Paoli',
    postalCode: '47454',
  },
  'paoli-ok': {
    name: 'Paoli',
    postalCode: '73074',
  },
  'paoli-pa': {
    name: 'Paoli',
    postalCode: '19301',
  },
  'paonia-co': {
    name: 'Paonia',
    postalCode: '81428',
  },
  'papaikou-hi': {
    name: 'Papaikou',
    postalCode: '96781',
  },
  'papillion-ne': {
    name: 'Papillion',
    postalCode: '68046',
  },
  'parachute-co': {
    name: 'Parachute',
    postalCode: '81635',
  },
  'paradis-la': {
    name: 'Paradis',
    postalCode: '70080',
  },
  'paradise-ca': {
    name: 'Paradise',
    postalCode: '95969',
  },
  'paradise-ks': {
    name: 'Paradise',
    postalCode: '67658',
  },
  'paradise-mi': {
    name: 'Paradise',
    postalCode: '49768',
  },
  'paradise-pa': {
    name: 'Paradise',
    postalCode: '17562',
  },
  'paradise-tx': {
    name: 'Paradise',
    postalCode: '76073',
  },
  'paradise-ut': {
    name: 'Paradise',
    postalCode: '84328',
  },
  'paradise-valley-az': {
    name: 'Paradise Valley',
    postalCode: '85253',
  },
  'paradox-ny': {
    name: 'Paradox',
    postalCode: '12858',
  },
  'paragon-in': {
    name: 'Paragon',
    postalCode: '46166',
  },
  'paragould-ar': {
    name: 'Paragould',
    postalCode: '72450',
  },
  'paramount-ca': {
    name: 'Paramount',
    postalCode: '90723',
  },
  'paramus-nj': {
    name: 'Paramus',
    postalCode: '07652',
  },
  'pardeeville-wi': {
    name: 'Pardeeville',
    postalCode: '53954',
  },
  'paris-ar': {
    name: 'Paris',
    postalCode: '72855',
  },
  'paris-il': {
    name: 'Paris',
    postalCode: '61944',
  },
  'paris-ky': {
    name: 'Paris',
    postalCode: '40361',
  },
  'paris-mi': {
    name: 'Paris',
    postalCode: '49338',
  },
  'paris-mo': {
    name: 'Paris',
    postalCode: '65275',
  },
  'paris-ms': {
    name: 'Paris',
    postalCode: '38949',
  },
  'paris-oh': {
    name: 'Paris',
    postalCode: '44669',
  },
  'paris-tn': {
    name: 'Paris',
    postalCode: '38242',
  },
  'paris-tx': {
    name: 'Paris',
    postalCode: '75460',
  },
  'paris-va': {
    name: 'Paris',
    postalCode: '20130',
  },
  'paris-crossing-in': {
    name: 'Paris Crossing',
    postalCode: '47270',
  },
  'parish-ny': {
    name: 'Parish',
    postalCode: '13131',
  },
  'parishville-ny': {
    name: 'Parishville',
    postalCode: '13672',
  },
  'park-ks': {
    name: 'Park',
    postalCode: '67751',
  },
  'park-city-ky': {
    name: 'Park City',
    postalCode: '42160',
  },
  'park-city-mt': {
    name: 'Park City',
    postalCode: '59063',
  },
  'park-city-ut': {
    name: 'Park City',
    postalCode: '84060',
  },
  'park-falls-wi': {
    name: 'Park Falls',
    postalCode: '54552',
  },
  'park-forest-il': {
    name: 'Park Forest',
    postalCode: '60466',
  },
  'park-hall-md': {
    name: 'Park Hall',
    postalCode: '20667',
  },
  'park-hill-ok': {
    name: 'Park Hill',
    postalCode: '74451',
  },
  'park-hills-mo': {
    name: 'Park Hills',
    postalCode: '63601',
  },
  'park-rapids-mn': {
    name: 'Park Rapids',
    postalCode: '56470',
  },
  'park-ridge-il': {
    name: 'Park Ridge',
    postalCode: '60068',
  },
  'park-ridge-nj': {
    name: 'Park Ridge',
    postalCode: '07656',
  },
  'park-river-nd': {
    name: 'Park River',
    postalCode: '58270',
  },
  'park-valley-ut': {
    name: 'Park Valley',
    postalCode: '84329',
  },
  'parkdale-ar': {
    name: 'Parkdale',
    postalCode: '71661',
  },
  'parker-az': {
    name: 'Parker',
    postalCode: '85344',
  },
  'parker-co': {
    name: 'Parker',
    postalCode: '80134',
  },
  'parker-ks': {
    name: 'Parker',
    postalCode: '66072',
  },
  'parker-pa': {
    name: 'Parker',
    postalCode: '16049',
  },
  'parker-sd': {
    name: 'Parker',
    postalCode: '57053',
  },
  'parker-city-in': {
    name: 'Parker City',
    postalCode: '47368',
  },
  'parkers-lake-ky': {
    name: 'Parkers Lake',
    postalCode: '42634',
  },
  'parkers-prairie-mn': {
    name: 'Parkers Prairie',
    postalCode: '56361',
  },
  'parkersburg-ia': {
    name: 'Parkersburg',
    postalCode: '50665',
  },
  'parkersburg-il': {
    name: 'Parkersburg',
    postalCode: '62452',
  },
  'parkersburg-wv': {
    name: 'Parkersburg',
    postalCode: '26101',
  },
  'parkesburg-pa': {
    name: 'Parkesburg',
    postalCode: '19365',
  },
  'parkhill-pa': {
    name: 'Parkhill',
    postalCode: '15945',
  },
  'parkin-ar': {
    name: 'Parkin',
    postalCode: '72373',
  },
  'parkman-wy': {
    name: 'Parkman',
    postalCode: '82838',
  },
  'parks-ar': {
    name: 'Parks',
    postalCode: '72950',
  },
  'parks-ne': {
    name: 'Parks',
    postalCode: '69041',
  },
  'parksley-va': {
    name: 'Parksley',
    postalCode: '23421',
  },
  'parkston-sd': {
    name: 'Parkston',
    postalCode: '57366',
  },
  'parksville-ky': {
    name: 'Parksville',
    postalCode: '40464',
  },
  'parksville-ny': {
    name: 'Parksville',
    postalCode: '12768',
  },
  'parkton-md': {
    name: 'Parkton',
    postalCode: '21120',
  },
  'parkton-nc': {
    name: 'Parkton',
    postalCode: '28371',
  },
  'parkville-md': {
    name: 'Parkville',
    postalCode: '21234',
  },
  'parlier-ca': {
    name: 'Parlier',
    postalCode: '93648',
  },
  'parlin-co': {
    name: 'Parlin',
    postalCode: '81239',
  },
  'parlin-nj': {
    name: 'Parlin',
    postalCode: '08859',
  },
  'parma-id': {
    name: 'Parma',
    postalCode: '83660',
  },
  'parma-mi': {
    name: 'Parma',
    postalCode: '49269',
  },
  'parma-mo': {
    name: 'Parma',
    postalCode: '63870',
  },
  'parmelee-sd': {
    name: 'Parmelee',
    postalCode: '57566',
  },
  'parnell-ia': {
    name: 'Parnell',
    postalCode: '52325',
  },
  'parnell-mo': {
    name: 'Parnell',
    postalCode: '64475',
  },
  'paron-ar': {
    name: 'Paron',
    postalCode: '72122',
  },
  'parrish-al': {
    name: 'Parrish',
    postalCode: '35580',
  },
  'parrish-fl': {
    name: 'Parrish',
    postalCode: '34219',
  },
  'parrott-ga': {
    name: 'Parrott',
    postalCode: '39877',
  },
  'parrottsville-tn': {
    name: 'Parrottsville',
    postalCode: '37843',
  },
  'parshall-co': {
    name: 'Parshall',
    postalCode: '80468',
  },
  'parshall-nd': {
    name: 'Parshall',
    postalCode: '58770',
  },
  'parsippany-nj': {
    name: 'Parsippany',
    postalCode: '07054',
  },
  'parsons-ks': {
    name: 'Parsons',
    postalCode: '67357',
  },
  'parsons-tn': {
    name: 'Parsons',
    postalCode: '38363',
  },
  'parsons-wv': {
    name: 'Parsons',
    postalCode: '26287',
  },
  'parsonsburg-md': {
    name: 'Parsonsburg',
    postalCode: '21849',
  },
  'parsonsfield-me': {
    name: 'Parsonsfield',
    postalCode: '04047',
  },
  'parthenon-ar': {
    name: 'Parthenon',
    postalCode: '72666',
  },
  'partlow-va': {
    name: 'Partlow',
    postalCode: '22534',
  },
  'partridge-ks': {
    name: 'Partridge',
    postalCode: '67566',
  },
  'partridge-ky': {
    name: 'Partridge',
    postalCode: '40862',
  },
  'pasadena-ca': {
    name: 'Pasadena',
    postalCode: '91101',
  },
  'pasadena-md': {
    name: 'Pasadena',
    postalCode: '21122',
  },
  'pasadena-tx': {
    name: 'Pasadena',
    postalCode: '77502',
  },
  'pascagoula-ms': {
    name: 'Pascagoula',
    postalCode: '39567',
  },
  'pasco-wa': {
    name: 'Pasco',
    postalCode: '99301',
  },
  'pascoag-ri': {
    name: 'Pascoag',
    postalCode: '02859',
  },
  'paso-robles-ca': {
    name: 'Paso Robles',
    postalCode: '93446',
  },
  'pass-christian-ms': {
    name: 'Pass Christian',
    postalCode: '39571',
  },
  'passadumkeag-me': {
    name: 'Passadumkeag',
    postalCode: '04475',
  },
  'passaic-nj': {
    name: 'Passaic',
    postalCode: '07055',
  },
  'patagonia-az': {
    name: 'Patagonia',
    postalCode: '85624',
  },
  'pataskala-oh': {
    name: 'Pataskala',
    postalCode: '43062',
  },
  'patchogue-ny': {
    name: 'Patchogue',
    postalCode: '11772',
  },
  'pateros-wa': {
    name: 'Pateros',
    postalCode: '98846',
  },
  'paterson-nj': {
    name: 'Paterson',
    postalCode: '07501',
  },
  'pathfork-ky': {
    name: 'Pathfork',
    postalCode: '40863',
  },
  'patillas-pr': {
    name: 'Patillas',
    postalCode: '00723',
  },
  'patoka-il': {
    name: 'Patoka',
    postalCode: '62875',
  },
  'patoka-in': {
    name: 'Patoka',
    postalCode: '47666',
  },
  'paton-ia': {
    name: 'Paton',
    postalCode: '50217',
  },
  'patrick-sc': {
    name: 'Patrick',
    postalCode: '29584',
  },
  'patrick-afb-fl': {
    name: 'Patrick AFB',
    postalCode: '32925',
  },
  'patrick-springs-va': {
    name: 'Patrick Springs',
    postalCode: '24133',
  },
  'patriot-in': {
    name: 'Patriot',
    postalCode: '47038',
  },
  'patriot-oh': {
    name: 'Patriot',
    postalCode: '45658',
  },
  'patten-me': {
    name: 'Patten',
    postalCode: '04765',
  },
  'patterson-ca': {
    name: 'Patterson',
    postalCode: '95363',
  },
  'patterson-ga': {
    name: 'Patterson',
    postalCode: '31557',
  },
  'patterson-ia': {
    name: 'Patterson',
    postalCode: '50218',
  },
  'patterson-la': {
    name: 'Patterson',
    postalCode: '70392',
  },
  'patterson-mo': {
    name: 'Patterson',
    postalCode: '63956',
  },
  'patterson-ny': {
    name: 'Patterson',
    postalCode: '12563',
  },
  'pattersonville-ny': {
    name: 'Pattersonville',
    postalCode: '12137',
  },
  'pattison-ms': {
    name: 'Pattison',
    postalCode: '39144',
  },
  'patton-mo': {
    name: 'Patton',
    postalCode: '63662',
  },
  'patton-pa': {
    name: 'Patton',
    postalCode: '16668',
  },
  'pattonsburg-mo': {
    name: 'Pattonsburg',
    postalCode: '64670',
  },
  'pattonville-tx': {
    name: 'Pattonville',
    postalCode: '75468',
  },
  'patuxent-river-md': {
    name: 'Patuxent River',
    postalCode: '20670',
  },
  'paul-id': {
    name: 'Paul',
    postalCode: '83347',
  },
  'paul-smiths-ny': {
    name: 'Paul Smiths',
    postalCode: '12970',
  },
  'paulden-az': {
    name: 'Paulden',
    postalCode: '86334',
  },
  'paulding-ms': {
    name: 'Paulding',
    postalCode: '39348',
  },
  'paulding-oh': {
    name: 'Paulding',
    postalCode: '45879',
  },
  'paulina-la': {
    name: 'Paulina',
    postalCode: '70763',
  },
  'paulina-or': {
    name: 'Paulina',
    postalCode: '97751',
  },
  'pauline-sc': {
    name: 'Pauline',
    postalCode: '29374',
  },
  'paullina-ia': {
    name: 'Paullina',
    postalCode: '51046',
  },
  'pauls-valley-ok': {
    name: 'Pauls Valley',
    postalCode: '73075',
  },
  'paulsboro-nj': {
    name: 'Paulsboro',
    postalCode: '08066',
  },
  'pauma-valley-ca': {
    name: 'Pauma Valley',
    postalCode: '92061',
  },
  'paupack-pa': {
    name: 'Paupack',
    postalCode: '18451',
  },
  'pavilion-ny': {
    name: 'Pavilion',
    postalCode: '14525',
  },
  'pavillion-wy': {
    name: 'Pavillion',
    postalCode: '82523',
  },
  'pavo-ga': {
    name: 'Pavo',
    postalCode: '31778',
  },
  'paw-paw-il': {
    name: 'Paw Paw',
    postalCode: '61353',
  },
  'paw-paw-mi': {
    name: 'Paw Paw',
    postalCode: '49079',
  },
  'paw-paw-wv': {
    name: 'Paw Paw',
    postalCode: '25434',
  },
  'pawcatuck-ct': {
    name: 'Pawcatuck',
    postalCode: '06379',
  },
  'pawhuska-ok': {
    name: 'Pawhuska',
    postalCode: '74056',
  },
  'pawlet-vt': {
    name: 'Pawlet',
    postalCode: '05761',
  },
  'pawleys-island-sc': {
    name: 'Pawleys Island',
    postalCode: '29585',
  },
  'pawling-ny': {
    name: 'Pawling',
    postalCode: '12564',
  },
  'pawnee-il': {
    name: 'Pawnee',
    postalCode: '62558',
  },
  'pawnee-ok': {
    name: 'Pawnee',
    postalCode: '74058',
  },
  'pawnee-city-ne': {
    name: 'Pawnee City',
    postalCode: '68420',
  },
  'pawnee-rock-ks': {
    name: 'Pawnee Rock',
    postalCode: '67567',
  },
  'pawtucket-ri': {
    name: 'Pawtucket',
    postalCode: '02860',
  },
  'paxico-ks': {
    name: 'Paxico',
    postalCode: '66526',
  },
  'paxinos-pa': {
    name: 'Paxinos',
    postalCode: '17860',
  },
  'paxton-il': {
    name: 'Paxton',
    postalCode: '60957',
  },
  'paxton-ma': {
    name: 'Paxton',
    postalCode: '01612',
  },
  'paxton-ne': {
    name: 'Paxton',
    postalCode: '69155',
  },
  'payette-id': {
    name: 'Payette',
    postalCode: '83661',
  },
  'payne-oh': {
    name: 'Payne',
    postalCode: '45880',
  },
  'paynes-creek-ca': {
    name: 'Paynes Creek',
    postalCode: '96075',
  },
  'paynesville-mn': {
    name: 'Paynesville',
    postalCode: '56362',
  },
  'paynesville-wv': {
    name: 'Paynesville',
    postalCode: '24873',
  },
  'payneville-ky': {
    name: 'Payneville',
    postalCode: '40157',
  },
  'payson-az': {
    name: 'Payson',
    postalCode: '85541',
  },
  'payson-il': {
    name: 'Payson',
    postalCode: '62360',
  },
  'payson-ut': {
    name: 'Payson',
    postalCode: '84651',
  },
  'pe-ell-wa': {
    name: 'Pe Ell',
    postalCode: '98572',
  },
  'pea-ridge-ar': {
    name: 'Pea Ridge',
    postalCode: '72751',
  },
  'peabody-ks': {
    name: 'Peabody',
    postalCode: '66866',
  },
  'peabody-ma': {
    name: 'Peabody',
    postalCode: '01960',
  },
  'peace-valley-mo': {
    name: 'Peace Valley',
    postalCode: '65788',
  },
  'peach-bottom-pa': {
    name: 'Peach Bottom',
    postalCode: '17563',
  },
  'peach-orchard-ar': {
    name: 'Peach Orchard',
    postalCode: '72453',
  },
  'peach-springs-az': {
    name: 'Peach Springs',
    postalCode: '86434',
  },
  'peacham-vt': {
    name: 'Peacham',
    postalCode: '05862',
  },
  'peachland-nc': {
    name: 'Peachland',
    postalCode: '28133',
  },
  'peachtree-city-ga': {
    name: 'Peachtree City',
    postalCode: '30269',
  },
  'peachtree-corners-ga': {
    name: 'Peachtree Corners',
    postalCode: '30092',
  },
  'peaks-island-me': {
    name: 'Peaks Island',
    postalCode: '04108',
  },
  'pearblossom-ca': {
    name: 'Pearblossom',
    postalCode: '93553',
  },
  'pearce-az': {
    name: 'Pearce',
    postalCode: '85625',
  },
  'pearcy-ar': {
    name: 'Pearcy',
    postalCode: '71964',
  },
  'pearisburg-va': {
    name: 'Pearisburg',
    postalCode: '24134',
  },
  'pearl-il': {
    name: 'Pearl',
    postalCode: '62361',
  },
  'pearl-ms': {
    name: 'Pearl',
    postalCode: '39208',
  },
  'pearl-city-hi': {
    name: 'Pearl City',
    postalCode: '96782',
  },
  'pearl-city-il': {
    name: 'Pearl City',
    postalCode: '61062',
  },
  'pearl-river-la': {
    name: 'Pearl River',
    postalCode: '70452',
  },
  'pearl-river-ny': {
    name: 'Pearl River',
    postalCode: '10965',
  },
  'pearland-tx': {
    name: 'Pearland',
    postalCode: '77581',
  },
  'pearlington-ms': {
    name: 'Pearlington',
    postalCode: '39572',
  },
  'pearsall-tx': {
    name: 'Pearsall',
    postalCode: '78061',
  },
  'pearson-ga': {
    name: 'Pearson',
    postalCode: '31642',
  },
  'pearson-wi': {
    name: 'Pearson',
    postalCode: '54462',
  },
  'pebble-beach-ca': {
    name: 'Pebble Beach',
    postalCode: '93953',
  },
  'pecan-gap-tx': {
    name: 'Pecan Gap',
    postalCode: '75469',
  },
  'pecatonica-il': {
    name: 'Pecatonica',
    postalCode: '61063',
  },
  'peck-id': {
    name: 'Peck',
    postalCode: '83545',
  },
  'peck-ks': {
    name: 'Peck',
    postalCode: '67120',
  },
  'peck-mi': {
    name: 'Peck',
    postalCode: '48466',
  },
  'pecks-mill-wv': {
    name: 'Pecks Mill',
    postalCode: '25547',
  },
  'peckville-pa': {
    name: 'Peckville',
    postalCode: '18452',
  },
  'peconic-ny': {
    name: 'Peconic',
    postalCode: '11958',
  },
  'pecos-nm': {
    name: 'Pecos',
    postalCode: '87552',
  },
  'pecos-tx': {
    name: 'Pecos',
    postalCode: '79772',
  },
  'peculiar-mo': {
    name: 'Peculiar',
    postalCode: '64078',
  },
  'pedricktown-nj': {
    name: 'Pedricktown',
    postalCode: '08067',
  },
  'pedro-oh': {
    name: 'Pedro',
    postalCode: '45659',
  },
  'peebles-oh': {
    name: 'Peebles',
    postalCode: '45660',
  },
  'peekskill-ny': {
    name: 'Peekskill',
    postalCode: '10566',
  },
  'peel-ar': {
    name: 'Peel',
    postalCode: '72668',
  },
  'peerless-mt': {
    name: 'Peerless',
    postalCode: '59253',
  },
  'peetz-co': {
    name: 'Peetz',
    postalCode: '80747',
  },
  'peever-sd': {
    name: 'Peever',
    postalCode: '57257',
  },
  'peggs-ok': {
    name: 'Peggs',
    postalCode: '74452',
  },
  'pegram-tn': {
    name: 'Pegram',
    postalCode: '37143',
  },
  'pekin-il': {
    name: 'Pekin',
    postalCode: '61554',
  },
  'pekin-in': {
    name: 'Pekin',
    postalCode: '47165',
  },
  'pekin-nd': {
    name: 'Pekin',
    postalCode: '58361',
  },
  'pelahatchie-ms': {
    name: 'Pelahatchie',
    postalCode: '39145',
  },
  'pelham-al': {
    name: 'Pelham',
    postalCode: '35124',
  },
  'pelham-ga': {
    name: 'Pelham',
    postalCode: '31779',
  },
  'pelham-nc': {
    name: 'Pelham',
    postalCode: '27311',
  },
  'pelham-nh': {
    name: 'Pelham',
    postalCode: '03076',
  },
  'pelham-ny': {
    name: 'Pelham',
    postalCode: '10803',
  },
  'pelham-tn': {
    name: 'Pelham',
    postalCode: '37366',
  },
  'pelican-la': {
    name: 'Pelican',
    postalCode: '71063',
  },
  'pelican-lake-wi': {
    name: 'Pelican Lake',
    postalCode: '54463',
  },
  'pelican-rapids-mn': {
    name: 'Pelican Rapids',
    postalCode: '56572',
  },
  'pelion-sc': {
    name: 'Pelion',
    postalCode: '29123',
  },
  'pelkie-mi': {
    name: 'Pelkie',
    postalCode: '49958',
  },
  'pell-city-al': {
    name: 'Pell City',
    postalCode: '35125',
  },
  'pella-ia': {
    name: 'Pella',
    postalCode: '50219',
  },
  'pellston-mi': {
    name: 'Pellston',
    postalCode: '49769',
  },
  'pelsor-ar': {
    name: 'Pelsor',
    postalCode: '72856',
  },
  'pelzer-sc': {
    name: 'Pelzer',
    postalCode: '29669',
  },
  'pemaquid-me': {
    name: 'Pemaquid',
    postalCode: '04558',
  },
  'pemberton-mn': {
    name: 'Pemberton',
    postalCode: '56078',
  },
  'pemberton-nj': {
    name: 'Pemberton',
    postalCode: '08068',
  },
  'pemberville-oh': {
    name: 'Pemberville',
    postalCode: '43450',
  },
  'pembina-nd': {
    name: 'Pembina',
    postalCode: '58271',
  },
  'pembine-wi': {
    name: 'Pembine',
    postalCode: '54156',
  },
  'pembroke-ga': {
    name: 'Pembroke',
    postalCode: '31321',
  },
  'pembroke-ky': {
    name: 'Pembroke',
    postalCode: '42266',
  },
  'pembroke-ma': {
    name: 'Pembroke',
    postalCode: '02359',
  },
  'pembroke-me': {
    name: 'Pembroke',
    postalCode: '04666',
  },
  'pembroke-nc': {
    name: 'Pembroke',
    postalCode: '28372',
  },
  'pembroke-va': {
    name: 'Pembroke',
    postalCode: '24136',
  },
  'pembroke-pines-fl': {
    name: 'Pembroke Pines',
    postalCode: '33028',
  },
  'pembroke-township-il': {
    name: 'Pembroke Township',
    postalCode: '60958',
  },
  'pen-argyl-pa': {
    name: 'Pen Argyl',
    postalCode: '18072',
  },
  'pena-blanca-nm': {
    name: 'Pena Blanca',
    postalCode: '87041',
  },
  'penasco-nm': {
    name: 'Penasco',
    postalCode: '87553',
  },
  'pence-springs-wv': {
    name: 'Pence Springs',
    postalCode: '24962',
  },
  'pencil-bluff-ar': {
    name: 'Pencil Bluff',
    postalCode: '71965',
  },
  'pender-ne': {
    name: 'Pender',
    postalCode: '68047',
  },
  'pendergrass-ga': {
    name: 'Pendergrass',
    postalCode: '30567',
  },
  'pendleton-in': {
    name: 'Pendleton',
    postalCode: '46064',
  },
  'pendleton-ky': {
    name: 'Pendleton',
    postalCode: '40055',
  },
  'pendleton-nc': {
    name: 'Pendleton',
    postalCode: '27862',
  },
  'pendleton-or': {
    name: 'Pendleton',
    postalCode: '97801',
  },
  'pendleton-sc': {
    name: 'Pendleton',
    postalCode: '29670',
  },
  'penelope-tx': {
    name: 'Penelope',
    postalCode: '76676',
  },
  'penfield-il': {
    name: 'Penfield',
    postalCode: '61862',
  },
  'penfield-ny': {
    name: 'Penfield',
    postalCode: '14526',
  },
  'penfield-pa': {
    name: 'Penfield',
    postalCode: '15849',
  },
  'pengilly-mn': {
    name: 'Pengilly',
    postalCode: '55775',
  },
  'penhook-va': {
    name: 'Penhook',
    postalCode: '24137',
  },
  'peninsula-oh': {
    name: 'Peninsula',
    postalCode: '44264',
  },
  'penitas-tx': {
    name: 'Penitas',
    postalCode: '78576',
  },
  'penn-nd': {
    name: 'Penn',
    postalCode: '58362',
  },
  'penn-pa': {
    name: 'Penn',
    postalCode: '15675',
  },
  'penn-laird-va': {
    name: 'Penn Laird',
    postalCode: '22846',
  },
  'penn-run-pa': {
    name: 'Penn Run',
    postalCode: '15765',
  },
  'penn-valley-ca': {
    name: 'Penn Valley',
    postalCode: '95946',
  },
  'penn-yan-ny': {
    name: 'Penn Yan',
    postalCode: '14527',
  },
  'pennellville-ny': {
    name: 'Pennellville',
    postalCode: '13132',
  },
  'penngrove-ca': {
    name: 'Penngrove',
    postalCode: '94951',
  },
  'pennington-al': {
    name: 'Pennington',
    postalCode: '36916',
  },
  'pennington-mn': {
    name: 'Pennington',
    postalCode: '56663',
  },
  'pennington-nj': {
    name: 'Pennington',
    postalCode: '08534',
  },
  'pennington-tx': {
    name: 'Pennington',
    postalCode: '75856',
  },
  'pennington-gap-va': {
    name: 'Pennington Gap',
    postalCode: '24277',
  },
  'pennock-mn': {
    name: 'Pennock',
    postalCode: '56279',
  },
  'penns-grove-nj': {
    name: 'Penns Grove',
    postalCode: '08069',
  },
  'pennsauken-nj': {
    name: 'Pennsauken',
    postalCode: '08110',
  },
  'pennsboro-wv': {
    name: 'Pennsboro',
    postalCode: '26415',
  },
  'pennsburg-pa': {
    name: 'Pennsburg',
    postalCode: '18073',
  },
  'pennsville-nj': {
    name: 'Pennsville',
    postalCode: '08070',
  },
  'pennsylvania-furnace-pa': {
    name: 'Pennsylvania Furnace',
    postalCode: '16865',
  },
  'pennville-in': {
    name: 'Pennville',
    postalCode: '47369',
  },
  'penobscot-me': {
    name: 'Penobscot',
    postalCode: '04476',
  },
  'penokee-ks': {
    name: 'Penokee',
    postalCode: '67659',
  },
  'penrose-co': {
    name: 'Penrose',
    postalCode: '81240',
  },
  'penrose-nc': {
    name: 'Penrose',
    postalCode: '28766',
  },
  'penryn-ca': {
    name: 'Penryn',
    postalCode: '95663',
  },
  'pensacola-fl': {
    name: 'Pensacola',
    postalCode: '32501',
  },
  'pentwater-mi': {
    name: 'Pentwater',
    postalCode: '49449',
  },
  'penuelas-pr': {
    name: 'Penuelas',
    postalCode: '00624',
  },
  'peoa-ut': {
    name: 'Peoa',
    postalCode: '84061',
  },
  'peoria-az': {
    name: 'Peoria',
    postalCode: '85345',
  },
  'peoria-il': {
    name: 'Peoria',
    postalCode: '61602',
  },
  'peoria-heights-il': {
    name: 'Peoria Heights',
    postalCode: '61616',
  },
  'peosta-ia': {
    name: 'Peosta',
    postalCode: '52068',
  },
  'peotone-il': {
    name: 'Peotone',
    postalCode: '60468',
  },
  'pep-nm': {
    name: 'Pep',
    postalCode: '88126',
  },
  'pep-tx': {
    name: 'Pep',
    postalCode: '79353',
  },
  'pepeekeo-hi': {
    name: 'Pepeekeo',
    postalCode: '96783',
  },
  'pepperell-ma': {
    name: 'Pepperell',
    postalCode: '01463',
  },
  'pequannock-nj': {
    name: 'Pequannock',
    postalCode: '07440',
  },
  'pequea-pa': {
    name: 'Pequea',
    postalCode: '17565',
  },
  'pequot-lakes-mn': {
    name: 'Pequot Lakes',
    postalCode: '56472',
  },
  'peralta-nm': {
    name: 'Peralta',
    postalCode: '87042',
  },
  'percival-ia': {
    name: 'Percival',
    postalCode: '51648',
  },
  'percy-il': {
    name: 'Percy',
    postalCode: '62272',
  },
  'perdido-al': {
    name: 'Perdido',
    postalCode: '36562',
  },
  'perham-me': {
    name: 'Perham',
    postalCode: '04766',
  },
  'perham-mn': {
    name: 'Perham',
    postalCode: '56573',
  },
  'peridot-az': {
    name: 'Peridot',
    postalCode: '85542',
  },
  'perkasie-pa': {
    name: 'Perkasie',
    postalCode: '18944',
  },
  'perkins-ok': {
    name: 'Perkins',
    postalCode: '74059',
  },
  'perkinston-ms': {
    name: 'Perkinston',
    postalCode: '39573',
  },
  'perkinsville-vt': {
    name: 'Perkinsville',
    postalCode: '05151',
  },
  'perkiomenville-pa': {
    name: 'Perkiomenville',
    postalCode: '18074',
  },
  'perley-mn': {
    name: 'Perley',
    postalCode: '56574',
  },
  'perrin-tx': {
    name: 'Perrin',
    postalCode: '76486',
  },
  'perrinton-mi': {
    name: 'Perrinton',
    postalCode: '48871',
  },
  'perris-ca': {
    name: 'Perris',
    postalCode: '92570',
  },
  'perronville-mi': {
    name: 'Perronville',
    postalCode: '49873',
  },
  'perry-ar': {
    name: 'Perry',
    postalCode: '72125',
  },
  'perry-fl': {
    name: 'Perry',
    postalCode: '32347',
  },
  'perry-ga': {
    name: 'Perry',
    postalCode: '31069',
  },
  'perry-ia': {
    name: 'Perry',
    postalCode: '50220',
  },
  'perry-ks': {
    name: 'Perry',
    postalCode: '66073',
  },
  'perry-me': {
    name: 'Perry',
    postalCode: '04667',
  },
  'perry-mi': {
    name: 'Perry',
    postalCode: '48872',
  },
  'perry-mo': {
    name: 'Perry',
    postalCode: '63462',
  },
  'perry-ny': {
    name: 'Perry',
    postalCode: '14530',
  },
  'perry-oh': {
    name: 'Perry',
    postalCode: '44081',
  },
  'perry-ok': {
    name: 'Perry',
    postalCode: '73077',
  },
  'perry-hall-md': {
    name: 'Perry Hall',
    postalCode: '21128',
  },
  'perryopolis-pa': {
    name: 'Perryopolis',
    postalCode: '15473',
  },
  'perrysburg-ny': {
    name: 'Perrysburg',
    postalCode: '14129',
  },
  'perrysburg-oh': {
    name: 'Perrysburg',
    postalCode: '43551',
  },
  'perrysville-in': {
    name: 'Perrysville',
    postalCode: '47974',
  },
  'perrysville-oh': {
    name: 'Perrysville',
    postalCode: '44864',
  },
  'perryton-tx': {
    name: 'Perryton',
    postalCode: '79070',
  },
  'perryville-ar': {
    name: 'Perryville',
    postalCode: '72126',
  },
  'perryville-ky': {
    name: 'Perryville',
    postalCode: '40468',
  },
  'perryville-md': {
    name: 'Perryville',
    postalCode: '21903',
  },
  'perryville-mo': {
    name: 'Perryville',
    postalCode: '63775',
  },
  'persia-ia': {
    name: 'Persia',
    postalCode: '51563',
  },
  'perth-nd': {
    name: 'Perth',
    postalCode: '58363',
  },
  'perth-amboy-nj': {
    name: 'Perth Amboy',
    postalCode: '08861',
  },
  'peru-ia': {
    name: 'Peru',
    postalCode: '50222',
  },
  'peru-il': {
    name: 'Peru',
    postalCode: '61354',
  },
  'peru-in': {
    name: 'Peru',
    postalCode: '46970',
  },
  'peru-ks': {
    name: 'Peru',
    postalCode: '67360',
  },
  'peru-me': {
    name: 'Peru',
    postalCode: '04290',
  },
  'peru-ne': {
    name: 'Peru',
    postalCode: '68421',
  },
  'peru-ny': {
    name: 'Peru',
    postalCode: '12972',
  },
  'peru-vt': {
    name: 'Peru',
    postalCode: '05152',
  },
  'pescadero-ca': {
    name: 'Pescadero',
    postalCode: '94060',
  },
  'peshastin-wa': {
    name: 'Peshastin',
    postalCode: '98847',
  },
  'peshtigo-wi': {
    name: 'Peshtigo',
    postalCode: '54157',
  },
  'pesotum-il': {
    name: 'Pesotum',
    postalCode: '61863',
  },
  'petal-ms': {
    name: 'Petal',
    postalCode: '39465',
  },
  'petaluma-ca': {
    name: 'Petaluma',
    postalCode: '94952',
  },
  'peterborough-nh': {
    name: 'Peterborough',
    postalCode: '03458',
  },
  'peterman-al': {
    name: 'Peterman',
    postalCode: '36471',
  },
  'petersburg-il': {
    name: 'Petersburg',
    postalCode: '62675',
  },
  'petersburg-in': {
    name: 'Petersburg',
    postalCode: '47567',
  },
  'petersburg-ky': {
    name: 'Petersburg',
    postalCode: '41080',
  },
  'petersburg-mi': {
    name: 'Petersburg',
    postalCode: '49270',
  },
  'petersburg-nd': {
    name: 'Petersburg',
    postalCode: '58272',
  },
  'petersburg-ne': {
    name: 'Petersburg',
    postalCode: '68652',
  },
  'petersburg-ny': {
    name: 'Petersburg',
    postalCode: '12138',
  },
  'petersburg-oh': {
    name: 'Petersburg',
    postalCode: '44454',
  },
  'petersburg-pa': {
    name: 'Petersburg',
    postalCode: '16669',
  },
  'petersburg-tn': {
    name: 'Petersburg',
    postalCode: '37144',
  },
  'petersburg-tx': {
    name: 'Petersburg',
    postalCode: '79250',
  },
  'petersburg-va': {
    name: 'Petersburg',
    postalCode: '23803',
  },
  'petersburg-wv': {
    name: 'Petersburg',
    postalCode: '26847',
  },
  'petersham-ma': {
    name: 'Petersham',
    postalCode: '01366',
  },
  'peterson-ia': {
    name: 'Peterson',
    postalCode: '51047',
  },
  'peterson-mn': {
    name: 'Peterson',
    postalCode: '55962',
  },
  'peterstown-wv': {
    name: 'Peterstown',
    postalCode: '24963',
  },
  'petoskey-mi': {
    name: 'Petoskey',
    postalCode: '49770',
  },
  'petroleum-wv': {
    name: 'Petroleum',
    postalCode: '26161',
  },
  'petrolia-ca': {
    name: 'Petrolia',
    postalCode: '95558',
  },
  'petrolia-pa': {
    name: 'Petrolia',
    postalCode: '16050',
  },
  'petrolia-tx': {
    name: 'Petrolia',
    postalCode: '76377',
  },
  'pettibone-nd': {
    name: 'Pettibone',
    postalCode: '58475',
  },
  'pettigrew-ar': {
    name: 'Pettigrew',
    postalCode: '72752',
  },
  'petty-tx': {
    name: 'Petty',
    postalCode: '75470',
  },
  'pevely-mo': {
    name: 'Pevely',
    postalCode: '63070',
  },
  'pewamo-mi': {
    name: 'Pewamo',
    postalCode: '48873',
  },
  'pewaukee-wi': {
    name: 'Pewaukee',
    postalCode: '53072',
  },
  'pewee-valley-ky': {
    name: 'Pewee Valley',
    postalCode: '40056',
  },
  'peyton-co': {
    name: 'Peyton',
    postalCode: '80831',
  },
  'peytona-wv': {
    name: 'Peytona',
    postalCode: '25154',
  },
  'pfafftown-nc': {
    name: 'Pfafftown',
    postalCode: '27040',
  },
  'pflugerville-tx': {
    name: 'Pflugerville',
    postalCode: '78660',
  },
  'pharr-tx': {
    name: 'Pharr',
    postalCode: '78577',
  },
  'pheba-ms': {
    name: 'Pheba',
    postalCode: '39755',
  },
  'phelan-ca': {
    name: 'Phelan',
    postalCode: '92371',
  },
  'phelps-ky': {
    name: 'Phelps',
    postalCode: '41553',
  },
  'phelps-ny': {
    name: 'Phelps',
    postalCode: '14532',
  },
  'phelps-wi': {
    name: 'Phelps',
    postalCode: '54554',
  },
  'phenix-va': {
    name: 'Phenix',
    postalCode: '23959',
  },
  'phenix-city-al': {
    name: 'Phenix City',
    postalCode: '36867',
  },
  'phil-campbell-al': {
    name: 'Phil Campbell',
    postalCode: '35581',
  },
  'philadelphia-mo': {
    name: 'Philadelphia',
    postalCode: '63463',
  },
  'philadelphia-ms': {
    name: 'Philadelphia',
    postalCode: '39350',
  },
  'philadelphia-ny': {
    name: 'Philadelphia',
    postalCode: '13673',
  },
  'philadelphia-pa': {
    name: 'Philadelphia',
    postalCode: '19102',
  },
  'philadelphia-tn': {
    name: 'Philadelphia',
    postalCode: '37846',
  },
  'philip-sd': {
    name: 'Philip',
    postalCode: '57567',
  },
  'philipp-ms': {
    name: 'Philipp',
    postalCode: '38950',
  },
  'philippi-wv': {
    name: 'Philippi',
    postalCode: '26416',
  },
  'philipsburg-mt': {
    name: 'Philipsburg',
    postalCode: '59858',
  },
  'philipsburg-pa': {
    name: 'Philipsburg',
    postalCode: '16866',
  },
  'phillips-me': {
    name: 'Phillips',
    postalCode: '04966',
  },
  'phillips-ne': {
    name: 'Phillips',
    postalCode: '68865',
  },
  'phillips-wi': {
    name: 'Phillips',
    postalCode: '54555',
  },
  'phillipsburg-ks': {
    name: 'Phillipsburg',
    postalCode: '67661',
  },
  'phillipsburg-mo': {
    name: 'Phillipsburg',
    postalCode: '65722',
  },
  'phillipsburg-nj': {
    name: 'Phillipsburg',
    postalCode: '08865',
  },
  'philmont-ny': {
    name: 'Philmont',
    postalCode: '12565',
  },
  'philo-ca': {
    name: 'Philo',
    postalCode: '95466',
  },
  'philo-il': {
    name: 'Philo',
    postalCode: '61864',
  },
  'philo-oh': {
    name: 'Philo',
    postalCode: '43771',
  },
  'philomath-or': {
    name: 'Philomath',
    postalCode: '97370',
  },
  'philpot-ky': {
    name: 'Philpot',
    postalCode: '42366',
  },
  'phippsburg-me': {
    name: 'Phippsburg',
    postalCode: '04562',
  },
  'phoenicia-ny': {
    name: 'Phoenicia',
    postalCode: '12464',
  },
  'phoenix-az': {
    name: 'Phoenix',
    postalCode: '85003',
  },
  'phoenix-md': {
    name: 'Phoenix',
    postalCode: '21131',
  },
  'phoenix-ny': {
    name: 'Phoenix',
    postalCode: '13135',
  },
  'phoenix-or': {
    name: 'Phoenix',
    postalCode: '97535',
  },
  'phoenixville-pa': {
    name: 'Phoenixville',
    postalCode: '19460',
  },
  'phyllis-ky': {
    name: 'Phyllis',
    postalCode: '41554',
  },
  'piasa-il': {
    name: 'Piasa',
    postalCode: '62079',
  },
  'picabo-id': {
    name: 'Picabo',
    postalCode: '83348',
  },
  'picacho-az': {
    name: 'Picacho',
    postalCode: '85141',
  },
  'picatinny-arsenal-nj': {
    name: 'Picatinny Arsenal',
    postalCode: '07806',
  },
  'picayune-ms': {
    name: 'Picayune',
    postalCode: '39466',
  },
  'picher-ok': {
    name: 'Picher',
    postalCode: '74360',
  },
  'pickens-ar': {
    name: 'Pickens',
    postalCode: '71662',
  },
  'pickens-ms': {
    name: 'Pickens',
    postalCode: '39146',
  },
  'pickens-sc': {
    name: 'Pickens',
    postalCode: '29671',
  },
  'pickens-wv': {
    name: 'Pickens',
    postalCode: '26230',
  },
  'pickerel-wi': {
    name: 'Pickerel',
    postalCode: '54465',
  },
  'pickering-mo': {
    name: 'Pickering',
    postalCode: '64476',
  },
  'pickerington-oh': {
    name: 'Pickerington',
    postalCode: '43147',
  },
  'pickett-wi': {
    name: 'Pickett',
    postalCode: '54964',
  },
  'pickford-mi': {
    name: 'Pickford',
    postalCode: '49774',
  },
  'pickrell-ne': {
    name: 'Pickrell',
    postalCode: '68422',
  },
  'pickton-tx': {
    name: 'Pickton',
    postalCode: '75471',
  },
  'pico-rivera-ca': {
    name: 'Pico Rivera',
    postalCode: '90660',
  },
  'pie-town-nm': {
    name: 'Pie Town',
    postalCode: '87827',
  },
  'piedmont-al': {
    name: 'Piedmont',
    postalCode: '36272',
  },
  'piedmont-ks': {
    name: 'Piedmont',
    postalCode: '67122',
  },
  'piedmont-mo': {
    name: 'Piedmont',
    postalCode: '63957',
  },
  'piedmont-oh': {
    name: 'Piedmont',
    postalCode: '43983',
  },
  'piedmont-ok': {
    name: 'Piedmont',
    postalCode: '73078',
  },
  'piedmont-sc': {
    name: 'Piedmont',
    postalCode: '29673',
  },
  'piedmont-sd': {
    name: 'Piedmont',
    postalCode: '57769',
  },
  'piedmont-wv': {
    name: 'Piedmont',
    postalCode: '26750',
  },
  'pierce-co': {
    name: 'Pierce',
    postalCode: '80650',
  },
  'pierce-id': {
    name: 'Pierce',
    postalCode: '83546',
  },
  'pierce-ne': {
    name: 'Pierce',
    postalCode: '68767',
  },
  'pierce-city-mo': {
    name: 'Pierce City',
    postalCode: '65723',
  },
  'pierceton-in': {
    name: 'Pierceton',
    postalCode: '46562',
  },
  'pierceville-ks': {
    name: 'Pierceville',
    postalCode: '67868',
  },
  'piercy-ca': {
    name: 'Piercy',
    postalCode: '95587',
  },
  'piermont-nh': {
    name: 'Piermont',
    postalCode: '03779',
  },
  'piermont-ny': {
    name: 'Piermont',
    postalCode: '10968',
  },
  'pierpont-oh': {
    name: 'Pierpont',
    postalCode: '44082',
  },
  'pierpont-sd': {
    name: 'Pierpont',
    postalCode: '57468',
  },
  'pierre-sd': {
    name: 'Pierre',
    postalCode: '57501',
  },
  'pierre-part-la': {
    name: 'Pierre Part',
    postalCode: '70339',
  },
  'pierson-fl': {
    name: 'Pierson',
    postalCode: '32180',
  },
  'pierson-ia': {
    name: 'Pierson',
    postalCode: '51048',
  },
  'pierson-mi': {
    name: 'Pierson',
    postalCode: '49339',
  },
  'pierz-mn': {
    name: 'Pierz',
    postalCode: '56364',
  },
  'piffard-ny': {
    name: 'Piffard',
    postalCode: '14533',
  },
  'pigeon-mi': {
    name: 'Pigeon',
    postalCode: '48755',
  },
  'pigeon-forge-tn': {
    name: 'Pigeon Forge',
    postalCode: '37863',
  },
  'piggott-ar': {
    name: 'Piggott',
    postalCode: '72454',
  },
  'pike-nh': {
    name: 'Pike',
    postalCode: '03780',
  },
  'pike-road-al': {
    name: 'Pike Road',
    postalCode: '36064',
  },
  'pikesville-md': {
    name: 'Pikesville',
    postalCode: '21208',
  },
  'piketon-oh': {
    name: 'Piketon',
    postalCode: '45661',
  },
  'pikeville-ky': {
    name: 'Pikeville',
    postalCode: '41501',
  },
  'pikeville-nc': {
    name: 'Pikeville',
    postalCode: '27863',
  },
  'pikeville-tn': {
    name: 'Pikeville',
    postalCode: '37367',
  },
  'pilger-ne': {
    name: 'Pilger',
    postalCode: '68768',
  },
  'pilgrim-ky': {
    name: 'Pilgrim',
    postalCode: '41250',
  },
  'pilgrims-knob-va': {
    name: 'Pilgrims Knob',
    postalCode: '24634',
  },
  'pillager-mn': {
    name: 'Pillager',
    postalCode: '56473',
  },
  'pillsbury-nd': {
    name: 'Pillsbury',
    postalCode: '58065',
  },
  'pilot-va': {
    name: 'Pilot',
    postalCode: '24138',
  },
  'pilot-grove-ia': {
    name: 'Pilot Grove',
    postalCode: '52648',
  },
  'pilot-grove-mo': {
    name: 'Pilot Grove',
    postalCode: '65276',
  },
  'pilot-hill-ca': {
    name: 'Pilot Hill',
    postalCode: '95664',
  },
  'pilot-mound-ia': {
    name: 'Pilot Mound',
    postalCode: '50223',
  },
  'pilot-mountain-nc': {
    name: 'Pilot Mountain',
    postalCode: '27041',
  },
  'pilot-point-tx': {
    name: 'Pilot Point',
    postalCode: '76258',
  },
  'pilot-rock-or': {
    name: 'Pilot Rock',
    postalCode: '97868',
  },
  'pima-az': {
    name: 'Pima',
    postalCode: '85543',
  },
  'pimento-in': {
    name: 'Pimento',
    postalCode: '47866',
  },
  'pinckney-mi': {
    name: 'Pinckney',
    postalCode: '48169',
  },
  'pinckneyville-il': {
    name: 'Pinckneyville',
    postalCode: '62274',
  },
  'pinconning-mi': {
    name: 'Pinconning',
    postalCode: '48650',
  },
  'pine-az': {
    name: 'Pine',
    postalCode: '85544',
  },
  'pine-co': {
    name: 'Pine',
    postalCode: '80470',
  },
  'pine-apple-al': {
    name: 'Pine Apple',
    postalCode: '36768',
  },
  'pine-beach-nj': {
    name: 'Pine Beach',
    postalCode: '08741',
  },
  'pine-bluff-ar': {
    name: 'Pine Bluff',
    postalCode: '71601',
  },
  'pine-bluffs-wy': {
    name: 'Pine Bluffs',
    postalCode: '82082',
  },
  'pine-brook-nj': {
    name: 'Pine Brook',
    postalCode: '07058',
  },
  'pine-bush-ny': {
    name: 'Pine Bush',
    postalCode: '12566',
  },
  'pine-city-mn': {
    name: 'Pine City',
    postalCode: '55063',
  },
  'pine-city-ny': {
    name: 'Pine City',
    postalCode: '14871',
  },
  'pine-grove-ca': {
    name: 'Pine Grove',
    postalCode: '95665',
  },
  'pine-grove-la': {
    name: 'Pine Grove',
    postalCode: '70453',
  },
  'pine-grove-pa': {
    name: 'Pine Grove',
    postalCode: '17963',
  },
  'pine-grove-wv': {
    name: 'Pine Grove',
    postalCode: '26419',
  },
  'pine-hall-nc': {
    name: 'Pine Hall',
    postalCode: '27042',
  },
  'pine-hill-al': {
    name: 'Pine Hill',
    postalCode: '36769',
  },
  'pine-hill-ny': {
    name: 'Pine Hill',
    postalCode: '12465',
  },
  'pine-island-mn': {
    name: 'Pine Island',
    postalCode: '55963',
  },
  'pine-island-ny': {
    name: 'Pine Island',
    postalCode: '10969',
  },
  'pine-knot-ky': {
    name: 'Pine Knot',
    postalCode: '42635',
  },
  'pine-mountain-ga': {
    name: 'Pine Mountain',
    postalCode: '31822',
  },
  'pine-mountain-valley-ga': {
    name: 'Pine Mountain Valley',
    postalCode: '31823',
  },
  'pine-plains-ny': {
    name: 'Pine Plains',
    postalCode: '12567',
  },
  'pine-ridge-ky': {
    name: 'Pine Ridge',
    postalCode: '41360',
  },
  'pine-ridge-sd': {
    name: 'Pine Ridge',
    postalCode: '57770',
  },
  'pine-river-mn': {
    name: 'Pine River',
    postalCode: '56474',
  },
  'pine-river-wi': {
    name: 'Pine River',
    postalCode: '54965',
  },
  'pine-top-ky': {
    name: 'Pine Top',
    postalCode: '41843',
  },
  'pine-valley-ca': {
    name: 'Pine Valley',
    postalCode: '91962',
  },
  'pine-valley-ny': {
    name: 'Pine Valley',
    postalCode: '14872',
  },
  'pine-valley-ut': {
    name: 'Pine Valley',
    postalCode: '84781',
  },
  'pine-village-in': {
    name: 'Pine Village',
    postalCode: '47975',
  },
  'pinebluff-nc': {
    name: 'Pinebluff',
    postalCode: '28373',
  },
  'pinecrest-ca': {
    name: 'Pinecrest',
    postalCode: '95314',
  },
  'pinedale-wy': {
    name: 'Pinedale',
    postalCode: '82941',
  },
  'pinehurst-ga': {
    name: 'Pinehurst',
    postalCode: '31070',
  },
  'pinehurst-id': {
    name: 'Pinehurst',
    postalCode: '83850',
  },
  'pinehurst-nc': {
    name: 'Pinehurst',
    postalCode: '28374',
  },
  'pinehurst-tx': {
    name: 'Pinehurst',
    postalCode: '77362',
  },
  'pineland-sc': {
    name: 'Pineland',
    postalCode: '29934',
  },
  'pineland-tx': {
    name: 'Pineland',
    postalCode: '75968',
  },
  'pinellas-park-fl': {
    name: 'Pinellas Park',
    postalCode: '33781',
  },
  'pinetop-az': {
    name: 'Pinetop',
    postalCode: '85935',
  },
  'pinetops-nc': {
    name: 'Pinetops',
    postalCode: '27864',
  },
  'pinetown-nc': {
    name: 'Pinetown',
    postalCode: '27865',
  },
  'pinetta-fl': {
    name: 'Pinetta',
    postalCode: '32350',
  },
  'pineview-ga': {
    name: 'Pineview',
    postalCode: '31071',
  },
  'pineville-ar': {
    name: 'Pineville',
    postalCode: '72566',
  },
  'pineville-ky': {
    name: 'Pineville',
    postalCode: '40977',
  },
  'pineville-la': {
    name: 'Pineville',
    postalCode: '71360',
  },
  'pineville-mo': {
    name: 'Pineville',
    postalCode: '64856',
  },
  'pineville-nc': {
    name: 'Pineville',
    postalCode: '28134',
  },
  'pineville-sc': {
    name: 'Pineville',
    postalCode: '29468',
  },
  'pineville-wv': {
    name: 'Pineville',
    postalCode: '24874',
  },
  'pinewood-sc': {
    name: 'Pinewood',
    postalCode: '29125',
  },
  'piney-creek-nc': {
    name: 'Piney Creek',
    postalCode: '28663',
  },
  'piney-flats-tn': {
    name: 'Piney Flats',
    postalCode: '37686',
  },
  'piney-point-md': {
    name: 'Piney Point',
    postalCode: '20674',
  },
  'piney-river-va': {
    name: 'Piney River',
    postalCode: '22964',
  },
  'pingree-id': {
    name: 'Pingree',
    postalCode: '83262',
  },
  'pingree-nd': {
    name: 'Pingree',
    postalCode: '58476',
  },
  'pink-hill-nc': {
    name: 'Pink Hill',
    postalCode: '28572',
  },
  'pinnacle-nc': {
    name: 'Pinnacle',
    postalCode: '27043',
  },
  'pinola-ms': {
    name: 'Pinola',
    postalCode: '39149',
  },
  'pinole-ca': {
    name: 'Pinole',
    postalCode: '94564',
  },
  'pinon-nm': {
    name: 'Pinon',
    postalCode: '88344',
  },
  'pinon-hills-ca': {
    name: 'Pinon Hills',
    postalCode: '92372',
  },
  'pinopolis-sc': {
    name: 'Pinopolis',
    postalCode: '29469',
  },
  'pinson-al': {
    name: 'Pinson',
    postalCode: '35126',
  },
  'pinson-tn': {
    name: 'Pinson',
    postalCode: '38366',
  },
  'pinsonfork-ky': {
    name: 'Pinsonfork',
    postalCode: '41555',
  },
  'pioche-nv': {
    name: 'Pioche',
    postalCode: '89043',
  },
  'pioneer-ca': {
    name: 'Pioneer',
    postalCode: '95666',
  },
  'pioneer-la': {
    name: 'Pioneer',
    postalCode: '71266',
  },
  'pioneer-oh': {
    name: 'Pioneer',
    postalCode: '43554',
  },
  'pioneer-tn': {
    name: 'Pioneer',
    postalCode: '37847',
  },
  'pipe-creek-tx': {
    name: 'Pipe Creek',
    postalCode: '78063',
  },
  'piper-city-il': {
    name: 'Piper City',
    postalCode: '60959',
  },
  'pipersville-pa': {
    name: 'Pipersville',
    postalCode: '18947',
  },
  'pipestem-wv': {
    name: 'Pipestem',
    postalCode: '25979',
  },
  'pipestone-mn': {
    name: 'Pipestone',
    postalCode: '56164',
  },
  'pippa-passes-ky': {
    name: 'Pippa Passes',
    postalCode: '41844',
  },
  'piqua-ks': {
    name: 'Piqua',
    postalCode: '66761',
  },
  'piqua-oh': {
    name: 'Piqua',
    postalCode: '45356',
  },
  'piscataway-nj': {
    name: 'Piscataway',
    postalCode: '08854',
  },
  'piseco-ny': {
    name: 'Piseco',
    postalCode: '12139',
  },
  'pisek-nd': {
    name: 'Pisek',
    postalCode: '58273',
  },
  'pisgah-al': {
    name: 'Pisgah',
    postalCode: '35765',
  },
  'pisgah-ia': {
    name: 'Pisgah',
    postalCode: '51564',
  },
  'pisgah-forest-nc': {
    name: 'Pisgah Forest',
    postalCode: '28768',
  },
  'pismo-beach-ca': {
    name: 'Pismo Beach',
    postalCode: '93449',
  },
  'pitcairn-pa': {
    name: 'Pitcairn',
    postalCode: '15140',
  },
  'pitcher-ny': {
    name: 'Pitcher',
    postalCode: '13136',
  },
  'pitkin-la': {
    name: 'Pitkin',
    postalCode: '70656',
  },
  'pitman-nj': {
    name: 'Pitman',
    postalCode: '08071',
  },
  'pitman-pa': {
    name: 'Pitman',
    postalCode: '17964',
  },
  'pitts-ga': {
    name: 'Pitts',
    postalCode: '31072',
  },
  'pittsboro-in': {
    name: 'Pittsboro',
    postalCode: '46167',
  },
  'pittsboro-ms': {
    name: 'Pittsboro',
    postalCode: '38951',
  },
  'pittsboro-nc': {
    name: 'Pittsboro',
    postalCode: '27312',
  },
  'pittsburg-ca': {
    name: 'Pittsburg',
    postalCode: '94565',
  },
  'pittsburg-il': {
    name: 'Pittsburg',
    postalCode: '62974',
  },
  'pittsburg-ks': {
    name: 'Pittsburg',
    postalCode: '66762',
  },
  'pittsburg-mo': {
    name: 'Pittsburg',
    postalCode: '65724',
  },
  'pittsburg-nh': {
    name: 'Pittsburg',
    postalCode: '03592',
  },
  'pittsburg-ok': {
    name: 'Pittsburg',
    postalCode: '74560',
  },
  'pittsburg-tx': {
    name: 'Pittsburg',
    postalCode: '75686',
  },
  'pittsburgh-pa': {
    name: 'Pittsburgh',
    postalCode: '15201',
  },
  'pittsfield-il': {
    name: 'Pittsfield',
    postalCode: '62363',
  },
  'pittsfield-ma': {
    name: 'Pittsfield',
    postalCode: '01201',
  },
  'pittsfield-me': {
    name: 'Pittsfield',
    postalCode: '04967',
  },
  'pittsfield-nh': {
    name: 'Pittsfield',
    postalCode: '03263',
  },
  'pittsfield-pa': {
    name: 'Pittsfield',
    postalCode: '16340',
  },
  'pittsfield-vt': {
    name: 'Pittsfield',
    postalCode: '05762',
  },
  'pittsford-mi': {
    name: 'Pittsford',
    postalCode: '49271',
  },
  'pittsford-ny': {
    name: 'Pittsford',
    postalCode: '14534',
  },
  'pittsford-vt': {
    name: 'Pittsford',
    postalCode: '05763',
  },
  'pittston-pa': {
    name: 'Pittston',
    postalCode: '18640',
  },
  'pittstown-nj': {
    name: 'Pittstown',
    postalCode: '08867',
  },
  'pittsview-al': {
    name: 'Pittsview',
    postalCode: '36871',
  },
  'pittsville-md': {
    name: 'Pittsville',
    postalCode: '21850',
  },
  'pittsville-va': {
    name: 'Pittsville',
    postalCode: '24139',
  },
  'pittsville-wi': {
    name: 'Pittsville',
    postalCode: '54466',
  },
  'pixley-ca': {
    name: 'Pixley',
    postalCode: '93256',
  },
  'placentia-ca': {
    name: 'Placentia',
    postalCode: '92870',
  },
  'placerville-ca': {
    name: 'Placerville',
    postalCode: '95667',
  },
  'placerville-id': {
    name: 'Placerville',
    postalCode: '83666',
  },
  'placida-fl': {
    name: 'Placida',
    postalCode: '33946',
  },
  'placitas-nm': {
    name: 'Placitas',
    postalCode: '87043',
  },
  'plain-wi': {
    name: 'Plain',
    postalCode: '53577',
  },
  'plain-city-oh': {
    name: 'Plain City',
    postalCode: '43064',
  },
  'plain-dealing-la': {
    name: 'Plain Dealing',
    postalCode: '71064',
  },
  'plainfield-ct': {
    name: 'Plainfield',
    postalCode: '06374',
  },
  'plainfield-ia': {
    name: 'Plainfield',
    postalCode: '50666',
  },
  'plainfield-il': {
    name: 'Plainfield',
    postalCode: '60544',
  },
  'plainfield-in': {
    name: 'Plainfield',
    postalCode: '46168',
  },
  'plainfield-ma': {
    name: 'Plainfield',
    postalCode: '01070',
  },
  'plainfield-nh': {
    name: 'Plainfield',
    postalCode: '03781',
  },
  'plainfield-nj': {
    name: 'Plainfield',
    postalCode: '07060',
  },
  'plainfield-vt': {
    name: 'Plainfield',
    postalCode: '05667',
  },
  'plainfield-wi': {
    name: 'Plainfield',
    postalCode: '54966',
  },
  'plains-ga': {
    name: 'Plains',
    postalCode: '31780',
  },
  'plains-ks': {
    name: 'Plains',
    postalCode: '67869',
  },
  'plains-mt': {
    name: 'Plains',
    postalCode: '59859',
  },
  'plains-tx': {
    name: 'Plains',
    postalCode: '79355',
  },
  'plainsboro-nj': {
    name: 'Plainsboro',
    postalCode: '08536',
  },
  'plainview-ar': {
    name: 'Plainview',
    postalCode: '72857',
  },
  'plainview-mn': {
    name: 'Plainview',
    postalCode: '55964',
  },
  'plainview-ne': {
    name: 'Plainview',
    postalCode: '68769',
  },
  'plainview-ny': {
    name: 'Plainview',
    postalCode: '11803',
  },
  'plainview-tx': {
    name: 'Plainview',
    postalCode: '79072',
  },
  'plainville-ct': {
    name: 'Plainville',
    postalCode: '06062',
  },
  'plainville-ga': {
    name: 'Plainville',
    postalCode: '30733',
  },
  'plainville-il': {
    name: 'Plainville',
    postalCode: '62365',
  },
  'plainville-in': {
    name: 'Plainville',
    postalCode: '47568',
  },
  'plainville-ks': {
    name: 'Plainville',
    postalCode: '67663',
  },
  'plainville-ma': {
    name: 'Plainville',
    postalCode: '02762',
  },
  'plainwell-mi': {
    name: 'Plainwell',
    postalCode: '49080',
  },
  'plaistow-nh': {
    name: 'Plaistow',
    postalCode: '03865',
  },
  'planada-ca': {
    name: 'Planada',
    postalCode: '95365',
  },
  'plankinton-sd': {
    name: 'Plankinton',
    postalCode: '57368',
  },
  'plano-ia': {
    name: 'Plano',
    postalCode: '52581',
  },
  'plano-il': {
    name: 'Plano',
    postalCode: '60545',
  },
  'plano-tx': {
    name: 'Plano',
    postalCode: '75023',
  },
  'plant-city-fl': {
    name: 'Plant City',
    postalCode: '33563',
  },
  'plantation-fl': {
    name: 'Plantation',
    postalCode: '33388',
  },
  'plantersville-al': {
    name: 'Plantersville',
    postalCode: '36758',
  },
  'plantersville-ms': {
    name: 'Plantersville',
    postalCode: '38862',
  },
  'plantersville-tx': {
    name: 'Plantersville',
    postalCode: '77363',
  },
  'plantsville-ct': {
    name: 'Plantsville',
    postalCode: '06479',
  },
  'plaquemine-la': {
    name: 'Plaquemine',
    postalCode: '70764',
  },
  'platina-ca': {
    name: 'Platina',
    postalCode: '96076',
  },
  'plato-mn': {
    name: 'Plato',
    postalCode: '55370',
  },
  'plato-mo': {
    name: 'Plato',
    postalCode: '65552',
  },
  'platte-sd': {
    name: 'Platte',
    postalCode: '57369',
  },
  'platte-center-ne': {
    name: 'Platte Center',
    postalCode: '68653',
  },
  'platte-city-mo': {
    name: 'Platte City',
    postalCode: '64079',
  },
  'plattenville-la': {
    name: 'Plattenville',
    postalCode: '70393',
  },
  'platteville-co': {
    name: 'Platteville',
    postalCode: '80651',
  },
  'platteville-wi': {
    name: 'Platteville',
    postalCode: '53818',
  },
  'plattsburg-mo': {
    name: 'Plattsburg',
    postalCode: '64477',
  },
  'plattsburgh-ny': {
    name: 'Plattsburgh',
    postalCode: '12901',
  },
  'plattsmouth-ne': {
    name: 'Plattsmouth',
    postalCode: '68048',
  },
  'plaucheville-la': {
    name: 'Plaucheville',
    postalCode: '71362',
  },
  'playa-del-rey-ca': {
    name: 'Playa Del Rey',
    postalCode: '90293',
  },
  'playa-vista-ca': {
    name: 'Playa Vista',
    postalCode: '90094',
  },
  'plaza-nd': {
    name: 'Plaza',
    postalCode: '58771',
  },
  'pleasant-city-oh': {
    name: 'Pleasant City',
    postalCode: '43772',
  },
  'pleasant-dale-ne': {
    name: 'Pleasant Dale',
    postalCode: '68423',
  },
  'pleasant-garden-nc': {
    name: 'Pleasant Garden',
    postalCode: '27313',
  },
  'pleasant-grove-al': {
    name: 'Pleasant Grove',
    postalCode: '35127',
  },
  'pleasant-grove-ar': {
    name: 'Pleasant Grove',
    postalCode: '72567',
  },
  'pleasant-grove-ca': {
    name: 'Pleasant Grove',
    postalCode: '95668',
  },
  'pleasant-grove-ut': {
    name: 'Pleasant Grove',
    postalCode: '84062',
  },
  'pleasant-hall-pa': {
    name: 'Pleasant Hall',
    postalCode: '17246',
  },
  'pleasant-hill-ca': {
    name: 'Pleasant Hill',
    postalCode: '94523',
  },
  'pleasant-hill-ia': {
    name: 'Pleasant Hill',
    postalCode: '50327',
  },
  'pleasant-hill-il': {
    name: 'Pleasant Hill',
    postalCode: '62366',
  },
  'pleasant-hill-la': {
    name: 'Pleasant Hill',
    postalCode: '71065',
  },
  'pleasant-hill-mo': {
    name: 'Pleasant Hill',
    postalCode: '64080',
  },
  'pleasant-hill-nc': {
    name: 'Pleasant Hill',
    postalCode: '27866',
  },
  'pleasant-hill-oh': {
    name: 'Pleasant Hill',
    postalCode: '45359',
  },
  'pleasant-hill-or': {
    name: 'Pleasant Hill',
    postalCode: '97455',
  },
  'pleasant-hope-mo': {
    name: 'Pleasant Hope',
    postalCode: '65725',
  },
  'pleasant-lake-in': {
    name: 'Pleasant Lake',
    postalCode: '46779',
  },
  'pleasant-lake-mi': {
    name: 'Pleasant Lake',
    postalCode: '49272',
  },
  'pleasant-mount-pa': {
    name: 'Pleasant Mount',
    postalCode: '18453',
  },
  'pleasant-plain-oh': {
    name: 'Pleasant Plain',
    postalCode: '45162',
  },
  'pleasant-plains-ar': {
    name: 'Pleasant Plains',
    postalCode: '72568',
  },
  'pleasant-plains-il': {
    name: 'Pleasant Plains',
    postalCode: '62677',
  },
  'pleasant-prairie-wi': {
    name: 'Pleasant Prairie',
    postalCode: '53158',
  },
  'pleasant-ridge-mi': {
    name: 'Pleasant Ridge',
    postalCode: '48069',
  },
  'pleasant-shade-tn': {
    name: 'Pleasant Shade',
    postalCode: '37145',
  },
  'pleasant-valley-ny': {
    name: 'Pleasant Valley',
    postalCode: '12569',
  },
  'pleasant-view-co': {
    name: 'Pleasant View',
    postalCode: '81331',
  },
  'pleasant-view-tn': {
    name: 'Pleasant View',
    postalCode: '37146',
  },
  'pleasanton-ca': {
    name: 'Pleasanton',
    postalCode: '94566',
  },
  'pleasanton-ks': {
    name: 'Pleasanton',
    postalCode: '66075',
  },
  'pleasanton-ne': {
    name: 'Pleasanton',
    postalCode: '68866',
  },
  'pleasanton-tx': {
    name: 'Pleasanton',
    postalCode: '78064',
  },
  'pleasantville-ia': {
    name: 'Pleasantville',
    postalCode: '50225',
  },
  'pleasantville-nj': {
    name: 'Pleasantville',
    postalCode: '08232',
  },
  'pleasantville-ny': {
    name: 'Pleasantville',
    postalCode: '10570',
  },
  'pleasantville-oh': {
    name: 'Pleasantville',
    postalCode: '43148',
  },
  'pleasantville-pa': {
    name: 'Pleasantville',
    postalCode: '16341',
  },
  'pleasureville-ky': {
    name: 'Pleasureville',
    postalCode: '40057',
  },
  'pledger-tx': {
    name: 'Pledger',
    postalCode: '77468',
  },
  'plentywood-mt': {
    name: 'Plentywood',
    postalCode: '59254',
  },
  'plessis-ny': {
    name: 'Plessis',
    postalCode: '13675',
  },
  'plevna-ks': {
    name: 'Plevna',
    postalCode: '67568',
  },
  'plevna-mo': {
    name: 'Plevna',
    postalCode: '63464',
  },
  'plevna-mt': {
    name: 'Plevna',
    postalCode: '59344',
  },
  'plover-wi': {
    name: 'Plover',
    postalCode: '54467',
  },
  'plum-branch-sc': {
    name: 'Plum Branch',
    postalCode: '29845',
  },
  'plum-city-wi': {
    name: 'Plum City',
    postalCode: '54761',
  },
  'plumerville-ar': {
    name: 'Plumerville',
    postalCode: '72127',
  },
  'plummer-id': {
    name: 'Plummer',
    postalCode: '83851',
  },
  'plummer-mn': {
    name: 'Plummer',
    postalCode: '56748',
  },
  'plush-or': {
    name: 'Plush',
    postalCode: '97637',
  },
  'plymouth-ca': {
    name: 'Plymouth',
    postalCode: '95669',
  },
  'plymouth-ct': {
    name: 'Plymouth',
    postalCode: '06782',
  },
  'plymouth-ia': {
    name: 'Plymouth',
    postalCode: '50464',
  },
  'plymouth-il': {
    name: 'Plymouth',
    postalCode: '62367',
  },
  'plymouth-in': {
    name: 'Plymouth',
    postalCode: '46563',
  },
  'plymouth-ma': {
    name: 'Plymouth',
    postalCode: '02360',
  },
  'plymouth-me': {
    name: 'Plymouth',
    postalCode: '04969',
  },
  'plymouth-mi': {
    name: 'Plymouth',
    postalCode: '48170',
  },
  'plymouth-nc': {
    name: 'Plymouth',
    postalCode: '27962',
  },
  'plymouth-ne': {
    name: 'Plymouth',
    postalCode: '68424',
  },
  'plymouth-nh': {
    name: 'Plymouth',
    postalCode: '03264',
  },
  'plymouth-ny': {
    name: 'Plymouth',
    postalCode: '13832',
  },
  'plymouth-oh': {
    name: 'Plymouth',
    postalCode: '44865',
  },
  'plymouth-pa': {
    name: 'Plymouth',
    postalCode: '18651',
  },
  'plymouth-vt': {
    name: 'Plymouth',
    postalCode: '05056',
  },
  'plymouth-wi': {
    name: 'Plymouth',
    postalCode: '53073',
  },
  'plymouth-meeting-pa': {
    name: 'Plymouth Meeting',
    postalCode: '19462',
  },
  'plympton-ma': {
    name: 'Plympton',
    postalCode: '02367',
  },
  'poca-wv': {
    name: 'Poca',
    postalCode: '25159',
  },
  'pocahontas-ar': {
    name: 'Pocahontas',
    postalCode: '72455',
  },
  'pocahontas-ia': {
    name: 'Pocahontas',
    postalCode: '50574',
  },
  'pocahontas-il': {
    name: 'Pocahontas',
    postalCode: '62275',
  },
  'pocahontas-tn': {
    name: 'Pocahontas',
    postalCode: '38061',
  },
  'pocasset-ma': {
    name: 'Pocasset',
    postalCode: '02559',
  },
  'pocasset-ok': {
    name: 'Pocasset',
    postalCode: '73079',
  },
  'pocatello-id': {
    name: 'Pocatello',
    postalCode: '83201',
  },
  'pocola-ok': {
    name: 'Pocola',
    postalCode: '74902',
  },
  'pocomoke-city-md': {
    name: 'Pocomoke City',
    postalCode: '21851',
  },
  'pocono-lake-pa': {
    name: 'Pocono Lake',
    postalCode: '18347',
  },
  'pocono-pines-pa': {
    name: 'Pocono Pines',
    postalCode: '18350',
  },
  'pocono-summit-pa': {
    name: 'Pocono Summit',
    postalCode: '18346',
  },
  'poestenkill-ny': {
    name: 'Poestenkill',
    postalCode: '12140',
  },
  'point-tx': {
    name: 'Point',
    postalCode: '75472',
  },
  'point-arena-ca': {
    name: 'Point Arena',
    postalCode: '95468',
  },
  'point-harbor-nc': {
    name: 'Point Harbor',
    postalCode: '27964',
  },
  'point-marion-pa': {
    name: 'Point Marion',
    postalCode: '15474',
  },
  'point-of-rocks-md': {
    name: 'Point Of Rocks',
    postalCode: '21777',
  },
  'point-pleasant-wv': {
    name: 'Point Pleasant',
    postalCode: '25550',
  },
  'point-pleasant-beach-nj': {
    name: 'Point Pleasant Beach',
    postalCode: '08742',
  },
  'point-reyes-station-ca': {
    name: 'Point Reyes Station',
    postalCode: '94956',
  },
  'point-roberts-wa': {
    name: 'Point Roberts',
    postalCode: '98281',
  },
  'pointblank-tx': {
    name: 'Pointblank',
    postalCode: '77364',
  },
  'pointe-aux-pins-mi': {
    name: 'Pointe Aux Pins',
    postalCode: '49775',
  },
  'points-wv': {
    name: 'Points',
    postalCode: '25437',
  },
  'poland-in': {
    name: 'Poland',
    postalCode: '47868',
  },
  'poland-me': {
    name: 'Poland',
    postalCode: '04274',
  },
  'poland-ny': {
    name: 'Poland',
    postalCode: '13431',
  },
  'polaris-mt': {
    name: 'Polaris',
    postalCode: '59746',
  },
  'polebridge-mt': {
    name: 'Polebridge',
    postalCode: '59928',
  },
  'polk-mo': {
    name: 'Polk',
    postalCode: '65727',
  },
  'polk-ne': {
    name: 'Polk',
    postalCode: '68654',
  },
  'polk-oh': {
    name: 'Polk',
    postalCode: '44866',
  },
  'polk-pa': {
    name: 'Polk',
    postalCode: '16342',
  },
  'polk-city-fl': {
    name: 'Polk City',
    postalCode: '33868',
  },
  'polk-city-ia': {
    name: 'Polk City',
    postalCode: '50226',
  },
  'polkton-nc': {
    name: 'Polkton',
    postalCode: '28135',
  },
  'pollard-ar': {
    name: 'Pollard',
    postalCode: '72456',
  },
  'pollock-id': {
    name: 'Pollock',
    postalCode: '83547',
  },
  'pollock-la': {
    name: 'Pollock',
    postalCode: '71467',
  },
  'pollock-mo': {
    name: 'Pollock',
    postalCode: '63560',
  },
  'pollock-sd': {
    name: 'Pollock',
    postalCode: '57648',
  },
  'pollock-pines-ca': {
    name: 'Pollock Pines',
    postalCode: '95726',
  },
  'pollocksville-nc': {
    name: 'Pollocksville',
    postalCode: '28573',
  },
  'pollok-tx': {
    name: 'Pollok',
    postalCode: '75969',
  },
  'polo-il': {
    name: 'Polo',
    postalCode: '61064',
  },
  'polo-mo': {
    name: 'Polo',
    postalCode: '64671',
  },
  'polson-mt': {
    name: 'Polson',
    postalCode: '59860',
  },
  'polvadera-nm': {
    name: 'Polvadera',
    postalCode: '87828',
  },
  'pomaria-sc': {
    name: 'Pomaria',
    postalCode: '29126',
  },
  'pomeroy-ia': {
    name: 'Pomeroy',
    postalCode: '50575',
  },
  'pomeroy-oh': {
    name: 'Pomeroy',
    postalCode: '45769',
  },
  'pomeroy-wa': {
    name: 'Pomeroy',
    postalCode: '99347',
  },
  'pomfret-md': {
    name: 'Pomfret',
    postalCode: '20675',
  },
  'pomfret-center-ct': {
    name: 'Pomfret Center',
    postalCode: '06259',
  },
  'pomona-ca': {
    name: 'Pomona',
    postalCode: '91766',
  },
  'pomona-il': {
    name: 'Pomona',
    postalCode: '62975',
  },
  'pomona-ks': {
    name: 'Pomona',
    postalCode: '66076',
  },
  'pomona-mo': {
    name: 'Pomona',
    postalCode: '65789',
  },
  'pomona-ny': {
    name: 'Pomona',
    postalCode: '10970',
  },
  'pomona-park-fl': {
    name: 'Pomona Park',
    postalCode: '32181',
  },
  'pompano-beach-fl': {
    name: 'Pompano Beach',
    postalCode: '33060',
  },
  'pompeys-pillar-mt': {
    name: 'Pompeys Pillar',
    postalCode: '59064',
  },
  'pompton-lakes-nj': {
    name: 'Pompton Lakes',
    postalCode: '07442',
  },
  'pompton-plains-nj': {
    name: 'Pompton Plains',
    postalCode: '07444',
  },
  'ponca-ar': {
    name: 'Ponca',
    postalCode: '72670',
  },
  'ponca-ne': {
    name: 'Ponca',
    postalCode: '68770',
  },
  'ponca-city-ok': {
    name: 'Ponca City',
    postalCode: '74601',
  },
  'ponce-pr': {
    name: 'Ponce',
    postalCode: '00716',
  },
  'ponce-de-leon-fl': {
    name: 'Ponce De Leon',
    postalCode: '32455',
  },
  'ponce-de-leon-mo': {
    name: 'Ponce De Leon',
    postalCode: '65728',
  },
  'ponchatoula-la': {
    name: 'Ponchatoula',
    postalCode: '70454',
  },
  'pond-creek-ok': {
    name: 'Pond Creek',
    postalCode: '73766',
  },
  'pond-eddy-ny': {
    name: 'Pond Eddy',
    postalCode: '12770',
  },
  'pond-gap-wv': {
    name: 'Pond Gap',
    postalCode: '25160',
  },
  'ponder-tx': {
    name: 'Ponder',
    postalCode: '76259',
  },
  'ponderay-id': {
    name: 'Ponderay',
    postalCode: '83852',
  },
  'ponderosa-nm': {
    name: 'Ponderosa',
    postalCode: '87044',
  },
  'poneto-in': {
    name: 'Poneto',
    postalCode: '46781',
  },
  'ponsford-mn': {
    name: 'Ponsford',
    postalCode: '56575',
  },
  'ponte-vedra-fl': {
    name: 'Ponte Vedra',
    postalCode: '32081',
  },
  'ponte-vedra-beach-fl': {
    name: 'Ponte Vedra Beach',
    postalCode: '32082',
  },
  'pontiac-il': {
    name: 'Pontiac',
    postalCode: '61764',
  },
  'pontiac-mi': {
    name: 'Pontiac',
    postalCode: '48340',
  },
  'pontiac-mo': {
    name: 'Pontiac',
    postalCode: '65729',
  },
  'pontotoc-ms': {
    name: 'Pontotoc',
    postalCode: '38863',
  },
  'pontotoc-tx': {
    name: 'Pontotoc',
    postalCode: '76869',
  },
  'pool-wv': {
    name: 'Pool',
    postalCode: '26684',
  },
  'pooler-ga': {
    name: 'Pooler',
    postalCode: '31322',
  },
  'poolesville-md': {
    name: 'Poolesville',
    postalCode: '20837',
  },
  'poolville-tx': {
    name: 'Poolville',
    postalCode: '76487',
  },
  'pope-ms': {
    name: 'Pope',
    postalCode: '38658',
  },
  'pope-army-airfield-nc': {
    name: 'Pope Army Airfield',
    postalCode: '28308',
  },
  'pope-valley-ca': {
    name: 'Pope Valley',
    postalCode: '94567',
  },
  'popejoy-ia': {
    name: 'Popejoy',
    postalCode: '50227',
  },
  'poplar-mt': {
    name: 'Poplar',
    postalCode: '59255',
  },
  'poplar-wi': {
    name: 'Poplar',
    postalCode: '54864',
  },
  'poplar-bluff-mo': {
    name: 'Poplar Bluff',
    postalCode: '63901',
  },
  'poplar-branch-nc': {
    name: 'Poplar Branch',
    postalCode: '27965',
  },
  'poplar-grove-ar': {
    name: 'Poplar Grove',
    postalCode: '72374',
  },
  'poplar-grove-il': {
    name: 'Poplar Grove',
    postalCode: '61065',
  },
  'poplarville-ms': {
    name: 'Poplarville',
    postalCode: '39470',
  },
  'poquoson-va': {
    name: 'Poquoson',
    postalCode: '23662',
  },
  'porcupine-sd': {
    name: 'Porcupine',
    postalCode: '57772',
  },
  'port-allegany-pa': {
    name: 'Port Allegany',
    postalCode: '16743',
  },
  'port-allen-la': {
    name: 'Port Allen',
    postalCode: '70767',
  },
  'port-angeles-wa': {
    name: 'Port Angeles',
    postalCode: '98362',
  },
  'port-aransas-tx': {
    name: 'Port Aransas',
    postalCode: '78373',
  },
  'port-arthur-tx': {
    name: 'Port Arthur',
    postalCode: '77640',
  },
  'port-austin-mi': {
    name: 'Port Austin',
    postalCode: '48467',
  },
  'port-barre-la': {
    name: 'Port Barre',
    postalCode: '70577',
  },
  'port-byron-il': {
    name: 'Port Byron',
    postalCode: '61275',
  },
  'port-byron-ny': {
    name: 'Port Byron',
    postalCode: '13140',
  },
  'port-carbon-pa': {
    name: 'Port Carbon',
    postalCode: '17965',
  },
  'port-charlotte-fl': {
    name: 'Port Charlotte',
    postalCode: '33948',
  },
  'port-chester-ny': {
    name: 'Port Chester',
    postalCode: '10573',
  },
  'port-clinton-oh': {
    name: 'Port Clinton',
    postalCode: '43452',
  },
  'port-crane-ny': {
    name: 'Port Crane',
    postalCode: '13833',
  },
  'port-deposit-md': {
    name: 'Port Deposit',
    postalCode: '21904',
  },
  'port-edwards-wi': {
    name: 'Port Edwards',
    postalCode: '54469',
  },
  'port-elizabeth-nj': {
    name: 'Port Elizabeth',
    postalCode: '08348',
  },
  'port-ewen-ny': {
    name: 'Port Ewen',
    postalCode: '12466',
  },
  'port-gibson-ms': {
    name: 'Port Gibson',
    postalCode: '39150',
  },
  'port-hadlock-wa': {
    name: 'Port Hadlock',
    postalCode: '98339',
  },
  'port-haywood-va': {
    name: 'Port Haywood',
    postalCode: '23138',
  },
  'port-henry-ny': {
    name: 'Port Henry',
    postalCode: '12974',
  },
  'port-hope-mi': {
    name: 'Port Hope',
    postalCode: '48468',
  },
  'port-hueneme-ca': {
    name: 'Port Hueneme',
    postalCode: '93041',
  },
  'port-huron-mi': {
    name: 'Port Huron',
    postalCode: '48060',
  },
  'port-isabel-tx': {
    name: 'Port Isabel',
    postalCode: '78578',
  },
  'port-jefferson-ny': {
    name: 'Port Jefferson',
    postalCode: '11777',
  },
  'port-jefferson-station-ny': {
    name: 'Port Jefferson Station',
    postalCode: '11776',
  },
  'port-jervis-ny': {
    name: 'Port Jervis',
    postalCode: '12771',
  },
  'port-lavaca-tx': {
    name: 'Port Lavaca',
    postalCode: '77979',
  },
  'port-leyden-ny': {
    name: 'Port Leyden',
    postalCode: '13433',
  },
  'port-ludlow-wa': {
    name: 'Port Ludlow',
    postalCode: '98365',
  },
  'port-matilda-pa': {
    name: 'Port Matilda',
    postalCode: '16870',
  },
  'port-monmouth-nj': {
    name: 'Port Monmouth',
    postalCode: '07758',
  },
  'port-murray-nj': {
    name: 'Port Murray',
    postalCode: '07865',
  },
  'port-neches-tx': {
    name: 'Port Neches',
    postalCode: '77651',
  },
  'port-norris-nj': {
    name: 'Port Norris',
    postalCode: '08349',
  },
  'port-orange-fl': {
    name: 'Port Orange',
    postalCode: '32127',
  },
  'port-orchard-wa': {
    name: 'Port Orchard',
    postalCode: '98366',
  },
  'port-orford-or': {
    name: 'Port Orford',
    postalCode: '97465',
  },
  'port-reading-nj': {
    name: 'Port Reading',
    postalCode: '07064',
  },
  'port-republic-md': {
    name: 'Port Republic',
    postalCode: '20676',
  },
  'port-republic-nj': {
    name: 'Port Republic',
    postalCode: '08241',
  },
  'port-republic-va': {
    name: 'Port Republic',
    postalCode: '24471',
  },
  'port-richey-fl': {
    name: 'Port Richey',
    postalCode: '34668',
  },
  'port-royal-pa': {
    name: 'Port Royal',
    postalCode: '17082',
  },
  'port-royal-sc': {
    name: 'Port Royal',
    postalCode: '29935',
  },
  'port-royal-va': {
    name: 'Port Royal',
    postalCode: '22535',
  },
  'port-saint-joe-fl': {
    name: 'Port Saint Joe',
    postalCode: '32456',
  },
  'port-saint-lucie-fl': {
    name: 'Port Saint Lucie',
    postalCode: '34952',
  },
  'port-sanilac-mi': {
    name: 'Port Sanilac',
    postalCode: '48469',
  },
  'port-sulphur-la': {
    name: 'Port Sulphur',
    postalCode: '70083',
  },
  'port-tobacco-md': {
    name: 'Port Tobacco',
    postalCode: '20677',
  },
  'port-townsend-wa': {
    name: 'Port Townsend',
    postalCode: '98368',
  },
  'port-trevorton-pa': {
    name: 'Port Trevorton',
    postalCode: '17864',
  },
  'port-washington-ny': {
    name: 'Port Washington',
    postalCode: '11050',
  },
  'port-washington-oh': {
    name: 'Port Washington',
    postalCode: '43837',
  },
  'port-washington-wi': {
    name: 'Port Washington',
    postalCode: '53074',
  },
  'port-wentworth-ga': {
    name: 'Port Wentworth',
    postalCode: '31407',
  },
  'port-wing-wi': {
    name: 'Port Wing',
    postalCode: '54865',
  },
  'portage-in': {
    name: 'Portage',
    postalCode: '46368',
  },
  'portage-me': {
    name: 'Portage',
    postalCode: '04768',
  },
  'portage-mi': {
    name: 'Portage',
    postalCode: '49002',
  },
  'portage-oh': {
    name: 'Portage',
    postalCode: '43451',
  },
  'portage-pa': {
    name: 'Portage',
    postalCode: '15946',
  },
  'portage-ut': {
    name: 'Portage',
    postalCode: '84331',
  },
  'portage-wi': {
    name: 'Portage',
    postalCode: '53901',
  },
  'portage-des-sioux-mo': {
    name: 'Portage Des Sioux',
    postalCode: '63373',
  },
  'portageville-mo': {
    name: 'Portageville',
    postalCode: '63873',
  },
  'portageville-ny': {
    name: 'Portageville',
    postalCode: '14536',
  },
  'portal-ga': {
    name: 'Portal',
    postalCode: '30450',
  },
  'portal-nd': {
    name: 'Portal',
    postalCode: '58772',
  },
  'portales-nm': {
    name: 'Portales',
    postalCode: '88130',
  },
  'porter-me': {
    name: 'Porter',
    postalCode: '04068',
  },
  'porter-mn': {
    name: 'Porter',
    postalCode: '56280',
  },
  'porter-ok': {
    name: 'Porter',
    postalCode: '74454',
  },
  'porter-tx': {
    name: 'Porter',
    postalCode: '77365',
  },
  'porter-corners-ny': {
    name: 'Porter Corners',
    postalCode: '12859',
  },
  'porter-ranch-ca': {
    name: 'Porter Ranch',
    postalCode: '91326',
  },
  'porterfield-wi': {
    name: 'Porterfield',
    postalCode: '54159',
  },
  'porters-falls-wv': {
    name: 'Porters Falls',
    postalCode: '26162',
  },
  'porters-sideling-pa': {
    name: 'Porters Sideling',
    postalCode: '17354',
  },
  'portersville-pa': {
    name: 'Portersville',
    postalCode: '16051',
  },
  'porterville-ca': {
    name: 'Porterville',
    postalCode: '93257',
  },
  'porterville-ms': {
    name: 'Porterville',
    postalCode: '39352',
  },
  'portis-ks': {
    name: 'Portis',
    postalCode: '67474',
  },
  'portland-ar': {
    name: 'Portland',
    postalCode: '71663',
  },
  'portland-ct': {
    name: 'Portland',
    postalCode: '06480',
  },
  'portland-in': {
    name: 'Portland',
    postalCode: '47371',
  },
  'portland-me': {
    name: 'Portland',
    postalCode: '04101',
  },
  'portland-mi': {
    name: 'Portland',
    postalCode: '48875',
  },
  'portland-mo': {
    name: 'Portland',
    postalCode: '65067',
  },
  'portland-nd': {
    name: 'Portland',
    postalCode: '58274',
  },
  'portland-ny': {
    name: 'Portland',
    postalCode: '14769',
  },
  'portland-oh': {
    name: 'Portland',
    postalCode: '45770',
  },
  'portland-or': {
    name: 'Portland',
    postalCode: '97201',
  },
  'portland-tn': {
    name: 'Portland',
    postalCode: '37148',
  },
  'portland-tx': {
    name: 'Portland',
    postalCode: '78374',
  },
  'portlandville-ny': {
    name: 'Portlandville',
    postalCode: '13834',
  },
  'portola-ca': {
    name: 'Portola',
    postalCode: '96122',
  },
  'portola-valley-ca': {
    name: 'Portola Valley',
    postalCode: '94028',
  },
  'portsmouth-ia': {
    name: 'Portsmouth',
    postalCode: '51565',
  },
  'portsmouth-nh': {
    name: 'Portsmouth',
    postalCode: '03801',
  },
  'portsmouth-oh': {
    name: 'Portsmouth',
    postalCode: '45662',
  },
  'portsmouth-ri': {
    name: 'Portsmouth',
    postalCode: '02871',
  },
  'portsmouth-va': {
    name: 'Portsmouth',
    postalCode: '23701',
  },
  'portville-ny': {
    name: 'Portville',
    postalCode: '14770',
  },
  'porum-ok': {
    name: 'Porum',
    postalCode: '74455',
  },
  'posen-il': {
    name: 'Posen',
    postalCode: '60469',
  },
  'posen-mi': {
    name: 'Posen',
    postalCode: '49776',
  },
  'posey-ca': {
    name: 'Posey',
    postalCode: '93260',
  },
  'poseyville-in': {
    name: 'Poseyville',
    postalCode: '47633',
  },
  'post-or': {
    name: 'Post',
    postalCode: '97752',
  },
  'post-tx': {
    name: 'Post',
    postalCode: '79356',
  },
  'post-falls-id': {
    name: 'Post Falls',
    postalCode: '83854',
  },
  'post-mills-vt': {
    name: 'Post Mills',
    postalCode: '05058',
  },
  'postville-ia': {
    name: 'Postville',
    postalCode: '52162',
  },
  'poteau-ok': {
    name: 'Poteau',
    postalCode: '74953',
  },
  'poteet-tx': {
    name: 'Poteet',
    postalCode: '78065',
  },
  'potlatch-id': {
    name: 'Potlatch',
    postalCode: '83855',
  },
  'potomac-il': {
    name: 'Potomac',
    postalCode: '61865',
  },
  'potomac-md': {
    name: 'Potomac',
    postalCode: '20854',
  },
  'potosi-mo': {
    name: 'Potosi',
    postalCode: '63664',
  },
  'potosi-wi': {
    name: 'Potosi',
    postalCode: '53820',
  },
  'potrero-ca': {
    name: 'Potrero',
    postalCode: '91963',
  },
  'potsdam-ny': {
    name: 'Potsdam',
    postalCode: '13676',
  },
  'potter-ne': {
    name: 'Potter',
    postalCode: '69156',
  },
  'potter-valley-ca': {
    name: 'Potter Valley',
    postalCode: '95469',
  },
  'pottersdale-pa': {
    name: 'Pottersdale',
    postalCode: '16871',
  },
  'pottersville-mo': {
    name: 'Pottersville',
    postalCode: '65790',
  },
  'pottersville-ny': {
    name: 'Pottersville',
    postalCode: '12860',
  },
  'potterville-mi': {
    name: 'Potterville',
    postalCode: '48876',
  },
  'potts-camp-ms': {
    name: 'Potts Camp',
    postalCode: '38659',
  },
  'pottsboro-tx': {
    name: 'Pottsboro',
    postalCode: '75076',
  },
  'pottstown-pa': {
    name: 'Pottstown',
    postalCode: '19464',
  },
  'pottsville-ar': {
    name: 'Pottsville',
    postalCode: '72858',
  },
  'pottsville-pa': {
    name: 'Pottsville',
    postalCode: '17901',
  },
  'pottsville-tx': {
    name: 'Pottsville',
    postalCode: '76565',
  },
  'potwin-ks': {
    name: 'Potwin',
    postalCode: '67123',
  },
  'poughkeepsie-ar': {
    name: 'Poughkeepsie',
    postalCode: '72569',
  },
  'poughkeepsie-ny': {
    name: 'Poughkeepsie',
    postalCode: '12601',
  },
  'poughquag-ny': {
    name: 'Poughquag',
    postalCode: '12570',
  },
  'poulan-ga': {
    name: 'Poulan',
    postalCode: '31781',
  },
  'poulsbo-wa': {
    name: 'Poulsbo',
    postalCode: '98370',
  },
  'poultney-vt': {
    name: 'Poultney',
    postalCode: '05764',
  },
  'pound-va': {
    name: 'Pound',
    postalCode: '24279',
  },
  'pound-wi': {
    name: 'Pound',
    postalCode: '54161',
  },
  'pound-ridge-ny': {
    name: 'Pound Ridge',
    postalCode: '10576',
  },
  'pounding-mill-va': {
    name: 'Pounding Mill',
    postalCode: '24637',
  },
  'poway-ca': {
    name: 'Poway',
    postalCode: '92064',
  },
  'powder-springs-ga': {
    name: 'Powder Springs',
    postalCode: '30127',
  },
  'powder-springs-tn': {
    name: 'Powder Springs',
    postalCode: '37848',
  },
  'powderhorn-co': {
    name: 'Powderhorn',
    postalCode: '81243',
  },
  'powderly-ky': {
    name: 'Powderly',
    postalCode: '42367',
  },
  'powderly-tx': {
    name: 'Powderly',
    postalCode: '75473',
  },
  'powderville-mt': {
    name: 'Powderville',
    postalCode: '59345',
  },
  'powell-mo': {
    name: 'Powell',
    postalCode: '65730',
  },
  'powell-oh': {
    name: 'Powell',
    postalCode: '43065',
  },
  'powell-tn': {
    name: 'Powell',
    postalCode: '37849',
  },
  'powell-tx': {
    name: 'Powell',
    postalCode: '75153',
  },
  'powell-wy': {
    name: 'Powell',
    postalCode: '82435',
  },
  'powell-butte-or': {
    name: 'Powell Butte',
    postalCode: '97753',
  },
  'powells-point-nc': {
    name: 'Powells Point',
    postalCode: '27966',
  },
  'powellton-wv': {
    name: 'Powellton',
    postalCode: '25161',
  },
  'power-mt': {
    name: 'Power',
    postalCode: '59468',
  },
  'powers-mi': {
    name: 'Powers',
    postalCode: '49874',
  },
  'powers-or': {
    name: 'Powers',
    postalCode: '97466',
  },
  'powers-lake-nd': {
    name: 'Powers Lake',
    postalCode: '58773',
  },
  'powersite-mo': {
    name: 'Powersite',
    postalCode: '65731',
  },
  'powersville-mo': {
    name: 'Powersville',
    postalCode: '64672',
  },
  'powhatan-ar': {
    name: 'Powhatan',
    postalCode: '72458',
  },
  'powhatan-va': {
    name: 'Powhatan',
    postalCode: '23139',
  },
  'powhatan-point-oh': {
    name: 'Powhatan Point',
    postalCode: '43942',
  },
  'powhattan-ks': {
    name: 'Powhattan',
    postalCode: '66527',
  },
  'pownal-me': {
    name: 'Pownal',
    postalCode: '04069',
  },
  'pownal-vt': {
    name: 'Pownal',
    postalCode: '05261',
  },
  'poy-sippi-wi': {
    name: 'Poy Sippi',
    postalCode: '54967',
  },
  'poyen-ar': {
    name: 'Poyen',
    postalCode: '72128',
  },
  'poynette-wi': {
    name: 'Poynette',
    postalCode: '53955',
  },
  'prague-ne': {
    name: 'Prague',
    postalCode: '68050',
  },
  'prague-ok': {
    name: 'Prague',
    postalCode: '74864',
  },
  'prairie-ms': {
    name: 'Prairie',
    postalCode: '39756',
  },
  'prairie-city-ia': {
    name: 'Prairie City',
    postalCode: '50228',
  },
  'prairie-city-il': {
    name: 'Prairie City',
    postalCode: '61470',
  },
  'prairie-city-or': {
    name: 'Prairie City',
    postalCode: '97869',
  },
  'prairie-city-sd': {
    name: 'Prairie City',
    postalCode: '57649',
  },
  'prairie-du-chien-wi': {
    name: 'Prairie Du Chien',
    postalCode: '53821',
  },
  'prairie-du-rocher-il': {
    name: 'Prairie Du Rocher',
    postalCode: '62277',
  },
  'prairie-du-sac-wi': {
    name: 'Prairie Du Sac',
    postalCode: '53578',
  },
  'prairie-farm-wi': {
    name: 'Prairie Farm',
    postalCode: '54762',
  },
  'prairie-grove-ar': {
    name: 'Prairie Grove',
    postalCode: '72753',
  },
  'prairie-hill-tx': {
    name: 'Prairie Hill',
    postalCode: '76678',
  },
  'prairie-home-mo': {
    name: 'Prairie Home',
    postalCode: '65068',
  },
  'prairie-view-ks': {
    name: 'Prairie View',
    postalCode: '67664',
  },
  'prairie-village-ks': {
    name: 'Prairie Village',
    postalCode: '66208',
  },
  'prairieburg-ia': {
    name: 'Prairieburg',
    postalCode: '52219',
  },
  'prairieville-la': {
    name: 'Prairieville',
    postalCode: '70769',
  },
  'prather-ca': {
    name: 'Prather',
    postalCode: '93651',
  },
  'pratt-ks': {
    name: 'Pratt',
    postalCode: '67124',
  },
  'pratts-va': {
    name: 'Pratts',
    postalCode: '22731',
  },
  'prattsburgh-ny': {
    name: 'Prattsburgh',
    postalCode: '14873',
  },
  'prattsville-ar': {
    name: 'Prattsville',
    postalCode: '72129',
  },
  'prattsville-ny': {
    name: 'Prattsville',
    postalCode: '12468',
  },
  'prattville-al': {
    name: 'Prattville',
    postalCode: '36066',
  },
  'pray-mt': {
    name: 'Pray',
    postalCode: '59065',
  },
  'preble-ny': {
    name: 'Preble',
    postalCode: '13141',
  },
  'premium-ky': {
    name: 'Premium',
    postalCode: '41845',
  },
  'premont-tx': {
    name: 'Premont',
    postalCode: '78375',
  },
  'prentice-wi': {
    name: 'Prentice',
    postalCode: '54556',
  },
  'prentiss-ms': {
    name: 'Prentiss',
    postalCode: '39474',
  },
  'prescott-ar': {
    name: 'Prescott',
    postalCode: '71857',
  },
  'prescott-az': {
    name: 'Prescott',
    postalCode: '86301',
  },
  'prescott-ia': {
    name: 'Prescott',
    postalCode: '50859',
  },
  'prescott-ks': {
    name: 'Prescott',
    postalCode: '66767',
  },
  'prescott-mi': {
    name: 'Prescott',
    postalCode: '48756',
  },
  'prescott-wa': {
    name: 'Prescott',
    postalCode: '99348',
  },
  'prescott-wi': {
    name: 'Prescott',
    postalCode: '54021',
  },
  'prescott-valley-az': {
    name: 'Prescott Valley',
    postalCode: '86314',
  },
  'presho-sd': {
    name: 'Presho',
    postalCode: '57568',
  },
  'presque-isle-me': {
    name: 'Presque Isle',
    postalCode: '04769',
  },
  'presque-isle-mi': {
    name: 'Presque Isle',
    postalCode: '49777',
  },
  'presque-isle-wi': {
    name: 'Presque Isle',
    postalCode: '54557',
  },
  'presto-pa': {
    name: 'Presto',
    postalCode: '15142',
  },
  'preston-ct': {
    name: 'Preston',
    postalCode: '06365',
  },
  'preston-ga': {
    name: 'Preston',
    postalCode: '31824',
  },
  'preston-ia': {
    name: 'Preston',
    postalCode: '52069',
  },
  'preston-id': {
    name: 'Preston',
    postalCode: '83263',
  },
  'preston-md': {
    name: 'Preston',
    postalCode: '21655',
  },
  'preston-mn': {
    name: 'Preston',
    postalCode: '55965',
  },
  'preston-mo': {
    name: 'Preston',
    postalCode: '65732',
  },
  'preston-ms': {
    name: 'Preston',
    postalCode: '39354',
  },
  'preston-hollow-ny': {
    name: 'Preston Hollow',
    postalCode: '12469',
  },
  'preston-park-pa': {
    name: 'Preston Park',
    postalCode: '18455',
  },
  'prestonsburg-ky': {
    name: 'Prestonsburg',
    postalCode: '41653',
  },
  'pretty-prairie-ks': {
    name: 'Pretty Prairie',
    postalCode: '67570',
  },
  'prewitt-nm': {
    name: 'Prewitt',
    postalCode: '87045',
  },
  'price-ut': {
    name: 'Price',
    postalCode: '84501',
  },
  'prichard-wv': {
    name: 'Prichard',
    postalCode: '25555',
  },
  'priddy-tx': {
    name: 'Priddy',
    postalCode: '76870',
  },
  'pride-la': {
    name: 'Pride',
    postalCode: '70770',
  },
  'priest-river-id': {
    name: 'Priest River',
    postalCode: '83856',
  },
  'prim-ar': {
    name: 'Prim',
    postalCode: '72130',
  },
  'primghar-ia': {
    name: 'Primghar',
    postalCode: '51245',
  },
  'primm-springs-tn': {
    name: 'Primm Springs',
    postalCode: '38476',
  },
  'primrose-ne': {
    name: 'Primrose',
    postalCode: '68655',
  },
  'prince-frederick-md': {
    name: 'Prince Frederick',
    postalCode: '20678',
  },
  'prince-george-va': {
    name: 'Prince George',
    postalCode: '23875',
  },
  'princess-anne-md': {
    name: 'Princess Anne',
    postalCode: '21853',
  },
  'princeton-al': {
    name: 'Princeton',
    postalCode: '35766',
  },
  'princeton-ca': {
    name: 'Princeton',
    postalCode: '95970',
  },
  'princeton-ia': {
    name: 'Princeton',
    postalCode: '52768',
  },
  'princeton-id': {
    name: 'Princeton',
    postalCode: '83857',
  },
  'princeton-il': {
    name: 'Princeton',
    postalCode: '61356',
  },
  'princeton-in': {
    name: 'Princeton',
    postalCode: '47670',
  },
  'princeton-ks': {
    name: 'Princeton',
    postalCode: '66078',
  },
  'princeton-ky': {
    name: 'Princeton',
    postalCode: '42445',
  },
  'princeton-la': {
    name: 'Princeton',
    postalCode: '71067',
  },
  'princeton-ma': {
    name: 'Princeton',
    postalCode: '01541',
  },
  'princeton-me': {
    name: 'Princeton',
    postalCode: '04668',
  },
  'princeton-mn': {
    name: 'Princeton',
    postalCode: '55371',
  },
  'princeton-mo': {
    name: 'Princeton',
    postalCode: '64673',
  },
  'princeton-nc': {
    name: 'Princeton',
    postalCode: '27569',
  },
  'princeton-nj': {
    name: 'Princeton',
    postalCode: '08540',
  },
  'princeton-or': {
    name: 'Princeton',
    postalCode: '97721',
  },
  'princeton-tx': {
    name: 'Princeton',
    postalCode: '75407',
  },
  'princeton-wi': {
    name: 'Princeton',
    postalCode: '54968',
  },
  'princeton-wv': {
    name: 'Princeton',
    postalCode: '24739',
  },
  'princeton-junction-nj': {
    name: 'Princeton Junction',
    postalCode: '08550',
  },
  'princeville-hi': {
    name: 'Princeville',
    postalCode: '96722',
  },
  'princeville-il': {
    name: 'Princeville',
    postalCode: '61559',
  },
  'princewick-wv': {
    name: 'Princewick',
    postalCode: '25908',
  },
  'prineville-or': {
    name: 'Prineville',
    postalCode: '97754',
  },
  'prinsburg-mn': {
    name: 'Prinsburg',
    postalCode: '56281',
  },
  'printer-ky': {
    name: 'Printer',
    postalCode: '41655',
  },
  'prior-lake-mn': {
    name: 'Prior Lake',
    postalCode: '55372',
  },
  'pritchett-co': {
    name: 'Pritchett',
    postalCode: '81064',
  },
  'procious-wv': {
    name: 'Procious',
    postalCode: '25164',
  },
  'proctor-ar': {
    name: 'Proctor',
    postalCode: '72376',
  },
  'proctor-mt': {
    name: 'Proctor',
    postalCode: '59929',
  },
  'proctor-ok': {
    name: 'Proctor',
    postalCode: '74457',
  },
  'proctor-vt': {
    name: 'Proctor',
    postalCode: '05765',
  },
  'proctor-wv': {
    name: 'Proctor',
    postalCode: '26055',
  },
  'proctorsville-vt': {
    name: 'Proctorsville',
    postalCode: '05153',
  },
  'proctorville-oh': {
    name: 'Proctorville',
    postalCode: '45669',
  },
  'prole-ia': {
    name: 'Prole',
    postalCode: '50229',
  },
  'promise-city-ia': {
    name: 'Promise City',
    postalCode: '52583',
  },
  'prompton-pa': {
    name: 'Prompton',
    postalCode: '18456',
  },
  'prophetstown-il': {
    name: 'Prophetstown',
    postalCode: '61277',
  },
  'prospect-ct': {
    name: 'Prospect',
    postalCode: '06712',
  },
  'prospect-ky': {
    name: 'Prospect',
    postalCode: '40059',
  },
  'prospect-oh': {
    name: 'Prospect',
    postalCode: '43342',
  },
  'prospect-or': {
    name: 'Prospect',
    postalCode: '97536',
  },
  'prospect-pa': {
    name: 'Prospect',
    postalCode: '16052',
  },
  'prospect-tn': {
    name: 'Prospect',
    postalCode: '38477',
  },
  'prospect-va': {
    name: 'Prospect',
    postalCode: '23960',
  },
  'prospect-harbor-me': {
    name: 'Prospect Harbor',
    postalCode: '04669',
  },
  'prospect-heights-il': {
    name: 'Prospect Heights',
    postalCode: '60070',
  },
  'prospect-hill-nc': {
    name: 'Prospect Hill',
    postalCode: '27314',
  },
  'prospect-park-pa': {
    name: 'Prospect Park',
    postalCode: '19076',
  },
  'prosper-tx': {
    name: 'Prosper',
    postalCode: '75078',
  },
  'prosperity-pa': {
    name: 'Prosperity',
    postalCode: '15329',
  },
  'prosperity-sc': {
    name: 'Prosperity',
    postalCode: '29127',
  },
  'prosser-wa': {
    name: 'Prosser',
    postalCode: '99350',
  },
  'protection-ks': {
    name: 'Protection',
    postalCode: '67127',
  },
  'protem-mo': {
    name: 'Protem',
    postalCode: '65733',
  },
  'provencal-la': {
    name: 'Provencal',
    postalCode: '71468',
  },
  'providence-ky': {
    name: 'Providence',
    postalCode: '42450',
  },
  'providence-nc': {
    name: 'Providence',
    postalCode: '27315',
  },
  'providence-ri': {
    name: 'Providence',
    postalCode: '02903',
  },
  'providence-ut': {
    name: 'Providence',
    postalCode: '84332',
  },
  'providence-forge-va': {
    name: 'Providence Forge',
    postalCode: '23140',
  },
  'provincetown-ma': {
    name: 'Provincetown',
    postalCode: '02657',
  },
  'provo-ut': {
    name: 'Provo',
    postalCode: '84601',
  },
  'prudenville-mi': {
    name: 'Prudenville',
    postalCode: '48651',
  },
  'prue-ok': {
    name: 'Prue',
    postalCode: '74060',
  },
  'pryor-ok': {
    name: 'Pryor',
    postalCode: '74361',
  },
  'pueblo-co': {
    name: 'Pueblo',
    postalCode: '81001',
  },
  'puerto-real-pr': {
    name: 'Puerto Real',
    postalCode: '00740',
  },
  'pukwana-sd': {
    name: 'Pukwana',
    postalCode: '57370',
  },
  'pulaski-ia': {
    name: 'Pulaski',
    postalCode: '52584',
  },
  'pulaski-il': {
    name: 'Pulaski',
    postalCode: '62976',
  },
  'pulaski-ms': {
    name: 'Pulaski',
    postalCode: '39152',
  },
  'pulaski-ny': {
    name: 'Pulaski',
    postalCode: '13142',
  },
  'pulaski-pa': {
    name: 'Pulaski',
    postalCode: '16143',
  },
  'pulaski-tn': {
    name: 'Pulaski',
    postalCode: '38478',
  },
  'pulaski-va': {
    name: 'Pulaski',
    postalCode: '24301',
  },
  'pulaski-wi': {
    name: 'Pulaski',
    postalCode: '54162',
  },
  'pullman-mi': {
    name: 'Pullman',
    postalCode: '49450',
  },
  'pullman-wa': {
    name: 'Pullman',
    postalCode: '99163',
  },
  'pullman-wv': {
    name: 'Pullman',
    postalCode: '26421',
  },
  'pulteney-ny': {
    name: 'Pulteney',
    postalCode: '14874',
  },
  'punta-gorda-fl': {
    name: 'Punta Gorda',
    postalCode: '33950',
  },
  'punta-santiago-pr': {
    name: 'Punta Santiago',
    postalCode: '00741',
  },
  'punxsutawney-pa': {
    name: 'Punxsutawney',
    postalCode: '15767',
  },
  'puposky-mn': {
    name: 'Puposky',
    postalCode: '56667',
  },
  'purcell-ok': {
    name: 'Purcell',
    postalCode: '73080',
  },
  'purcellville-va': {
    name: 'Purcellville',
    postalCode: '20132',
  },
  'purchase-ny': {
    name: 'Purchase',
    postalCode: '10577',
  },
  'purdin-mo': {
    name: 'Purdin',
    postalCode: '64674',
  },
  'purdon-tx': {
    name: 'Purdon',
    postalCode: '76679',
  },
  'purdum-ne': {
    name: 'Purdum',
    postalCode: '69157',
  },
  'purdy-mo': {
    name: 'Purdy',
    postalCode: '65734',
  },
  'purdys-ny': {
    name: 'Purdys',
    postalCode: '10578',
  },
  'purgitsville-wv': {
    name: 'Purgitsville',
    postalCode: '26852',
  },
  'purlear-nc': {
    name: 'Purlear',
    postalCode: '28665',
  },
  'purling-ny': {
    name: 'Purling',
    postalCode: '12470',
  },
  'purmela-tx': {
    name: 'Purmela',
    postalCode: '76566',
  },
  'pursglove-wv': {
    name: 'Pursglove',
    postalCode: '26546',
  },
  'purvis-ms': {
    name: 'Purvis',
    postalCode: '39475',
  },
  'puryear-tn': {
    name: 'Puryear',
    postalCode: '38251',
  },
  'putnam-ct': {
    name: 'Putnam',
    postalCode: '06260',
  },
  'putnam-il': {
    name: 'Putnam',
    postalCode: '61560',
  },
  'putnam-ok': {
    name: 'Putnam',
    postalCode: '73659',
  },
  'putnam-station-ny': {
    name: 'Putnam Station',
    postalCode: '12861',
  },
  'putnam-valley-ny': {
    name: 'Putnam Valley',
    postalCode: '10579',
  },
  'putnamville-in': {
    name: 'Putnamville',
    postalCode: '46170',
  },
  'putney-ky': {
    name: 'Putney',
    postalCode: '40865',
  },
  'putney-vt': {
    name: 'Putney',
    postalCode: '05346',
  },
  'puxico-mo': {
    name: 'Puxico',
    postalCode: '63960',
  },
  'puyallup-wa': {
    name: 'Puyallup',
    postalCode: '98371',
  },
  'pylesville-md': {
    name: 'Pylesville',
    postalCode: '21132',
  },
  'quail-tx': {
    name: 'Quail',
    postalCode: '79251',
  },
  'quaker-city-oh': {
    name: 'Quaker City',
    postalCode: '43773',
  },
  'quaker-hill-ct': {
    name: 'Quaker Hill',
    postalCode: '06375',
  },
  'quakertown-pa': {
    name: 'Quakertown',
    postalCode: '18951',
  },
  'quanah-tx': {
    name: 'Quanah',
    postalCode: '79252',
  },
  'quantico-md': {
    name: 'Quantico',
    postalCode: '21856',
  },
  'quantico-va': {
    name: 'Quantico',
    postalCode: '22134',
  },
  'quapaw-ok': {
    name: 'Quapaw',
    postalCode: '74363',
  },
  'quarryville-pa': {
    name: 'Quarryville',
    postalCode: '17566',
  },
  'quasqueton-ia': {
    name: 'Quasqueton',
    postalCode: '52326',
  },
  'quay-nm': {
    name: 'Quay',
    postalCode: '88433',
  },
  'quebeck-tn': {
    name: 'Quebeck',
    postalCode: '38579',
  },
  'quebradillas-pr': {
    name: 'Quebradillas',
    postalCode: '00678',
  },
  'queen-pa': {
    name: 'Queen',
    postalCode: '16670',
  },
  'queen-anne-md': {
    name: 'Queen Anne',
    postalCode: '21657',
  },
  'queen-city-mo': {
    name: 'Queen City',
    postalCode: '63561',
  },
  'queen-city-tx': {
    name: 'Queen City',
    postalCode: '75572',
  },
  'queen-creek-az': {
    name: 'Queen Creek',
    postalCode: '85142',
  },
  'queens-village-ny': {
    name: 'Queens Village',
    postalCode: '11427',
  },
  'queensbury-ny': {
    name: 'Queensbury',
    postalCode: '12804',
  },
  'queenstown-md': {
    name: 'Queenstown',
    postalCode: '21658',
  },
  'quemado-nm': {
    name: 'Quemado',
    postalCode: '87829',
  },
  'quemado-tx': {
    name: 'Quemado',
    postalCode: '78877',
  },
  'quenemo-ks': {
    name: 'Quenemo',
    postalCode: '66528',
  },
  'questa-nm': {
    name: 'Questa',
    postalCode: '87556',
  },
  'quicksburg-va': {
    name: 'Quicksburg',
    postalCode: '22847',
  },
  'quilcene-wa': {
    name: 'Quilcene',
    postalCode: '98376',
  },
  'quimby-ia': {
    name: 'Quimby',
    postalCode: '51049',
  },
  'quinault-wa': {
    name: 'Quinault',
    postalCode: '98575',
  },
  'quincy-ca': {
    name: 'Quincy',
    postalCode: '95971',
  },
  'quincy-fl': {
    name: 'Quincy',
    postalCode: '32351',
  },
  'quincy-il': {
    name: 'Quincy',
    postalCode: '62301',
  },
  'quincy-in': {
    name: 'Quincy',
    postalCode: '47456',
  },
  'quincy-ky': {
    name: 'Quincy',
    postalCode: '41166',
  },
  'quincy-ma': {
    name: 'Quincy',
    postalCode: '02169',
  },
  'quincy-mi': {
    name: 'Quincy',
    postalCode: '49082',
  },
  'quincy-mo': {
    name: 'Quincy',
    postalCode: '65735',
  },
  'quincy-oh': {
    name: 'Quincy',
    postalCode: '43343',
  },
  'quincy-wa': {
    name: 'Quincy',
    postalCode: '98848',
  },
  'quinebaug-ct': {
    name: 'Quinebaug',
    postalCode: '06262',
  },
  'quinlan-tx': {
    name: 'Quinlan',
    postalCode: '75474',
  },
  'quinn-sd': {
    name: 'Quinn',
    postalCode: '57775',
  },
  'quinnesec-mi': {
    name: 'Quinnesec',
    postalCode: '49876',
  },
  'quinter-ks': {
    name: 'Quinter',
    postalCode: '67752',
  },
  'quinton-al': {
    name: 'Quinton',
    postalCode: '35130',
  },
  'quinton-ok': {
    name: 'Quinton',
    postalCode: '74561',
  },
  'quinton-va': {
    name: 'Quinton',
    postalCode: '23141',
  },
  'quinwood-wv': {
    name: 'Quinwood',
    postalCode: '25981',
  },
  'quitaque-tx': {
    name: 'Quitaque',
    postalCode: '79255',
  },
  'quitman-ar': {
    name: 'Quitman',
    postalCode: '72131',
  },
  'quitman-ga': {
    name: 'Quitman',
    postalCode: '31643',
  },
  'quitman-la': {
    name: 'Quitman',
    postalCode: '71268',
  },
  'quitman-ms': {
    name: 'Quitman',
    postalCode: '39355',
  },
  'quitman-tx': {
    name: 'Quitman',
    postalCode: '75783',
  },
  'qulin-mo': {
    name: 'Qulin',
    postalCode: '63961',
  },
  'rabun-gap-ga': {
    name: 'Rabun Gap',
    postalCode: '30568',
  },
  'raccoon-ky': {
    name: 'Raccoon',
    postalCode: '41557',
  },
  'raceland-la': {
    name: 'Raceland',
    postalCode: '70394',
  },
  'rachel-wv': {
    name: 'Rachel',
    postalCode: '26587',
  },
  'racine-mn': {
    name: 'Racine',
    postalCode: '55967',
  },
  'racine-oh': {
    name: 'Racine',
    postalCode: '45771',
  },
  'racine-wi': {
    name: 'Racine',
    postalCode: '53402',
  },
  'racine-wv': {
    name: 'Racine',
    postalCode: '25165',
  },
  'rackerby-ca': {
    name: 'Rackerby',
    postalCode: '95972',
  },
  'radcliff-ky': {
    name: 'Radcliff',
    postalCode: '40160',
  },
  'radcliffe-ia': {
    name: 'Radcliffe',
    postalCode: '50230',
  },
  'radersburg-mt': {
    name: 'Radersburg',
    postalCode: '59641',
  },
  'radford-va': {
    name: 'Radford',
    postalCode: '24141',
  },
  'radiant-va': {
    name: 'Radiant',
    postalCode: '22732',
  },
  'radisson-wi': {
    name: 'Radisson',
    postalCode: '54867',
  },
  'radnor-oh': {
    name: 'Radnor',
    postalCode: '43066',
  },
  'raeford-nc': {
    name: 'Raeford',
    postalCode: '28376',
  },
  'ragland-al': {
    name: 'Ragland',
    postalCode: '35131',
  },
  'ragley-la': {
    name: 'Ragley',
    postalCode: '70657',
  },
  'ragsdale-in': {
    name: 'Ragsdale',
    postalCode: '47573',
  },
  'rahway-nj': {
    name: 'Rahway',
    postalCode: '07065',
  },
  'raiford-fl': {
    name: 'Raiford',
    postalCode: '32083',
  },
  'rainbow-tx': {
    name: 'Rainbow',
    postalCode: '76077',
  },
  'rainbow-city-al': {
    name: 'Rainbow City',
    postalCode: '35906',
  },
  'rainelle-wv': {
    name: 'Rainelle',
    postalCode: '25962',
  },
  'rainier-or': {
    name: 'Rainier',
    postalCode: '97048',
  },
  'rainier-wa': {
    name: 'Rainier',
    postalCode: '98576',
  },
  'rainsville-al': {
    name: 'Rainsville',
    postalCode: '35986',
  },
  'raisin-city-ca': {
    name: 'Raisin City',
    postalCode: '93652',
  },
  'raleigh-il': {
    name: 'Raleigh',
    postalCode: '62977',
  },
  'raleigh-ms': {
    name: 'Raleigh',
    postalCode: '39153',
  },
  'raleigh-nc': {
    name: 'Raleigh',
    postalCode: '27601',
  },
  'raleigh-nd': {
    name: 'Raleigh',
    postalCode: '58564',
  },
  'ralls-tx': {
    name: 'Ralls',
    postalCode: '79357',
  },
  'ralph-al': {
    name: 'Ralph',
    postalCode: '35480',
  },
  'ralph-sd': {
    name: 'Ralph',
    postalCode: '57650',
  },
  'ralston-ia': {
    name: 'Ralston',
    postalCode: '51459',
  },
  'ralston-ok': {
    name: 'Ralston',
    postalCode: '74650',
  },
  'ramah-co': {
    name: 'Ramah',
    postalCode: '80832',
  },
  'ramah-nm': {
    name: 'Ramah',
    postalCode: '87321',
  },
  'ramer-al': {
    name: 'Ramer',
    postalCode: '36069',
  },
  'ramer-tn': {
    name: 'Ramer',
    postalCode: '38367',
  },
  'ramona-ca': {
    name: 'Ramona',
    postalCode: '92065',
  },
  'ramona-ks': {
    name: 'Ramona',
    postalCode: '67475',
  },
  'ramona-ok': {
    name: 'Ramona',
    postalCode: '74061',
  },
  'ramona-sd': {
    name: 'Ramona',
    postalCode: '57054',
  },
  'ramseur-nc': {
    name: 'Ramseur',
    postalCode: '27316',
  },
  'ramsey-il': {
    name: 'Ramsey',
    postalCode: '62080',
  },
  'ramsey-in': {
    name: 'Ramsey',
    postalCode: '47166',
  },
  'ramsey-nj': {
    name: 'Ramsey',
    postalCode: '07446',
  },
  'ranburne-al': {
    name: 'Ranburne',
    postalCode: '36273',
  },
  'ranchester-wy': {
    name: 'Ranchester',
    postalCode: '82839',
  },
  'ranchita-ca': {
    name: 'Ranchita',
    postalCode: '92066',
  },
  'rancho-cordova-ca': {
    name: 'Rancho Cordova',
    postalCode: '95670',
  },
  'rancho-cucamonga-ca': {
    name: 'Rancho Cucamonga',
    postalCode: '91701',
  },
  'rancho-mirage-ca': {
    name: 'Rancho Mirage',
    postalCode: '92270',
  },
  'rancho-palos-verdes-ca': {
    name: 'Rancho Palos Verdes',
    postalCode: '90275',
  },
  'rancho-santa-fe-ca': {
    name: 'Rancho Santa Fe',
    postalCode: '92091',
  },
  'rancho-santa-margarita-ca': {
    name: 'Rancho Santa Margarita',
    postalCode: '92688',
  },
  'ranchos-de-taos-nm': {
    name: 'Ranchos De Taos',
    postalCode: '87557',
  },
  'randalia-ia': {
    name: 'Randalia',
    postalCode: '52164',
  },
  'randall-ks': {
    name: 'Randall',
    postalCode: '66963',
  },
  'randall-mn': {
    name: 'Randall',
    postalCode: '56475',
  },
  'randallstown-md': {
    name: 'Randallstown',
    postalCode: '21133',
  },
  'randle-wa': {
    name: 'Randle',
    postalCode: '98377',
  },
  'randleman-nc': {
    name: 'Randleman',
    postalCode: '27317',
  },
  'randlett-ok': {
    name: 'Randlett',
    postalCode: '73562',
  },
  'randlett-ut': {
    name: 'Randlett',
    postalCode: '84063',
  },
  'randolph-al': {
    name: 'Randolph',
    postalCode: '36792',
  },
  'randolph-ia': {
    name: 'Randolph',
    postalCode: '51649',
  },
  'randolph-ks': {
    name: 'Randolph',
    postalCode: '66554',
  },
  'randolph-ma': {
    name: 'Randolph',
    postalCode: '02368',
  },
  'randolph-me': {
    name: 'Randolph',
    postalCode: '04346',
  },
  'randolph-mn': {
    name: 'Randolph',
    postalCode: '55065',
  },
  'randolph-ms': {
    name: 'Randolph',
    postalCode: '38864',
  },
  'randolph-ne': {
    name: 'Randolph',
    postalCode: '68771',
  },
  'randolph-nh': {
    name: 'Randolph',
    postalCode: '03593',
  },
  'randolph-nj': {
    name: 'Randolph',
    postalCode: '07869',
  },
  'randolph-ny': {
    name: 'Randolph',
    postalCode: '14772',
  },
  'randolph-va': {
    name: 'Randolph',
    postalCode: '23962',
  },
  'randolph-vt': {
    name: 'Randolph',
    postalCode: '05060',
  },
  'randolph-wi': {
    name: 'Randolph',
    postalCode: '53956',
  },
  'randolph-center-vt': {
    name: 'Randolph Center',
    postalCode: '05061',
  },
  'random-lake-wi': {
    name: 'Random Lake',
    postalCode: '53075',
  },
  'range-al': {
    name: 'Range',
    postalCode: '36473',
  },
  'rangeley-me': {
    name: 'Rangeley',
    postalCode: '04970',
  },
  'rangely-co': {
    name: 'Rangely',
    postalCode: '81648',
  },
  'ranger-ga': {
    name: 'Ranger',
    postalCode: '30734',
  },
  'ranger-tx': {
    name: 'Ranger',
    postalCode: '76470',
  },
  'ranger-wv': {
    name: 'Ranger',
    postalCode: '25557',
  },
  'rankin-il': {
    name: 'Rankin',
    postalCode: '60960',
  },
  'ransom-il': {
    name: 'Ransom',
    postalCode: '60470',
  },
  'ransom-ks': {
    name: 'Ransom',
    postalCode: '67572',
  },
  'ransom-ky': {
    name: 'Ransom',
    postalCode: '41558',
  },
  'ransom-canyon-tx': {
    name: 'Ransom Canyon',
    postalCode: '79366',
  },
  'ransomville-ny': {
    name: 'Ransomville',
    postalCode: '14131',
  },
  'ranson-wv': {
    name: 'Ranson',
    postalCode: '25438',
  },
  'rantoul-il': {
    name: 'Rantoul',
    postalCode: '61866',
  },
  'rantoul-ks': {
    name: 'Rantoul',
    postalCode: '66079',
  },
  'rapelje-mt': {
    name: 'Rapelje',
    postalCode: '59067',
  },
  'raphine-va': {
    name: 'Raphine',
    postalCode: '24472',
  },
  'rapid-city-mi': {
    name: 'Rapid City',
    postalCode: '49676',
  },
  'rapid-city-sd': {
    name: 'Rapid City',
    postalCode: '57701',
  },
  'rapid-river-mi': {
    name: 'Rapid River',
    postalCode: '49878',
  },
  'rapidan-va': {
    name: 'Rapidan',
    postalCode: '22733',
  },
  'rappahannock-academy-va': {
    name: 'Rappahannock Academy',
    postalCode: '22538',
  },
  'raquette-lake-ny': {
    name: 'Raquette Lake',
    postalCode: '13436',
  },
  'rarden-oh': {
    name: 'Rarden',
    postalCode: '45671',
  },
  'raritan-nj': {
    name: 'Raritan',
    postalCode: '08869',
  },
  'ratcliff-ar': {
    name: 'Ratcliff',
    postalCode: '72951',
  },
  'rathdrum-id': {
    name: 'Rathdrum',
    postalCode: '83858',
  },
  'ratliff-city-ok': {
    name: 'Ratliff City',
    postalCode: '73481',
  },
  'raton-nm': {
    name: 'Raton',
    postalCode: '87740',
  },
  'rattan-ok': {
    name: 'Rattan',
    postalCode: '74562',
  },
  'ravalli-mt': {
    name: 'Ravalli',
    postalCode: '59863',
  },
  'raven-ky': {
    name: 'Raven',
    postalCode: '41861',
  },
  'raven-va': {
    name: 'Raven',
    postalCode: '24639',
  },
  'ravena-ny': {
    name: 'Ravena',
    postalCode: '12143',
  },
  'ravencliff-wv': {
    name: 'Ravencliff',
    postalCode: '25913',
  },
  'ravendale-ca': {
    name: 'Ravendale',
    postalCode: '96123',
  },
  'ravenden-ar': {
    name: 'Ravenden',
    postalCode: '72459',
  },
  'ravenden-springs-ar': {
    name: 'Ravenden Springs',
    postalCode: '72460',
  },
  'ravenel-sc': {
    name: 'Ravenel',
    postalCode: '29470',
  },
  'ravenna-ky': {
    name: 'Ravenna',
    postalCode: '40472',
  },
  'ravenna-mi': {
    name: 'Ravenna',
    postalCode: '49451',
  },
  'ravenna-ne': {
    name: 'Ravenna',
    postalCode: '68869',
  },
  'ravenna-oh': {
    name: 'Ravenna',
    postalCode: '44266',
  },
  'ravenna-tx': {
    name: 'Ravenna',
    postalCode: '75476',
  },
  'ravensdale-wa': {
    name: 'Ravensdale',
    postalCode: '98051',
  },
  'ravenswood-wv': {
    name: 'Ravenswood',
    postalCode: '26164',
  },
  'ravenwood-mo': {
    name: 'Ravenwood',
    postalCode: '64479',
  },
  'rawlings-md': {
    name: 'Rawlings',
    postalCode: '21557',
  },
  'rawlings-va': {
    name: 'Rawlings',
    postalCode: '23876',
  },
  'rawlins-wy': {
    name: 'Rawlins',
    postalCode: '82301',
  },
  'rawson-oh': {
    name: 'Rawson',
    postalCode: '45881',
  },
  'ray-mi': {
    name: 'Ray',
    postalCode: '48096',
  },
  'ray-nd': {
    name: 'Ray',
    postalCode: '58849',
  },
  'ray-oh': {
    name: 'Ray',
    postalCode: '45672',
  },
  'ray-city-ga': {
    name: 'Ray City',
    postalCode: '31645',
  },
  'rayland-oh': {
    name: 'Rayland',
    postalCode: '43943',
  },
  'rayle-ga': {
    name: 'Rayle',
    postalCode: '30660',
  },
  'raymond-ca': {
    name: 'Raymond',
    postalCode: '93653',
  },
  'raymond-ia': {
    name: 'Raymond',
    postalCode: '50667',
  },
  'raymond-il': {
    name: 'Raymond',
    postalCode: '62560',
  },
  'raymond-ks': {
    name: 'Raymond',
    postalCode: '67573',
  },
  'raymond-me': {
    name: 'Raymond',
    postalCode: '04071',
  },
  'raymond-mn': {
    name: 'Raymond',
    postalCode: '56282',
  },
  'raymond-ms': {
    name: 'Raymond',
    postalCode: '39154',
  },
  'raymond-ne': {
    name: 'Raymond',
    postalCode: '68428',
  },
  'raymond-nh': {
    name: 'Raymond',
    postalCode: '03077',
  },
  'raymond-oh': {
    name: 'Raymond',
    postalCode: '43067',
  },
  'raymond-sd': {
    name: 'Raymond',
    postalCode: '57258',
  },
  'raymond-wa': {
    name: 'Raymond',
    postalCode: '98577',
  },
  'raymondville-mo': {
    name: 'Raymondville',
    postalCode: '65555',
  },
  'raymondville-tx': {
    name: 'Raymondville',
    postalCode: '78580',
  },
  'raymore-mo': {
    name: 'Raymore',
    postalCode: '64083',
  },
  'rayne-la': {
    name: 'Rayne',
    postalCode: '70578',
  },
  'raynesford-mt': {
    name: 'Raynesford',
    postalCode: '59469',
  },
  'raynham-ma': {
    name: 'Raynham',
    postalCode: '02767',
  },
  'raysal-wv': {
    name: 'Raysal',
    postalCode: '24879',
  },
  'rayville-la': {
    name: 'Rayville',
    postalCode: '71269',
  },
  'rayville-mo': {
    name: 'Rayville',
    postalCode: '64084',
  },
  'raywick-ky': {
    name: 'Raywick',
    postalCode: '40060',
  },
  'rea-mo': {
    name: 'Rea',
    postalCode: '64480',
  },
  'reader-wv': {
    name: 'Reader',
    postalCode: '26167',
  },
  'readfield-me': {
    name: 'Readfield',
    postalCode: '04355',
  },
  'reading-ks': {
    name: 'Reading',
    postalCode: '66868',
  },
  'reading-ma': {
    name: 'Reading',
    postalCode: '01867',
  },
  'reading-mi': {
    name: 'Reading',
    postalCode: '49274',
  },
  'reading-mn': {
    name: 'Reading',
    postalCode: '56165',
  },
  'reading-pa': {
    name: 'Reading',
    postalCode: '19601',
  },
  'reading-vt': {
    name: 'Reading',
    postalCode: '05062',
  },
  'readlyn-ia': {
    name: 'Readlyn',
    postalCode: '50668',
  },
  'reads-landing-mn': {
    name: 'Reads Landing',
    postalCode: '55968',
  },
  'readsboro-vt': {
    name: 'Readsboro',
    postalCode: '05350',
  },
  'readstown-wi': {
    name: 'Readstown',
    postalCode: '54652',
  },
  'readyville-tn': {
    name: 'Readyville',
    postalCode: '37149',
  },
  'reagan-tn': {
    name: 'Reagan',
    postalCode: '38368',
  },
  'reagan-tx': {
    name: 'Reagan',
    postalCode: '76680',
  },
  'realitos-tx': {
    name: 'Realitos',
    postalCode: '78376',
  },
  'reardan-wa': {
    name: 'Reardan',
    postalCode: '99029',
  },
  'reasnor-ia': {
    name: 'Reasnor',
    postalCode: '50232',
  },
  'rebecca-ga': {
    name: 'Rebecca',
    postalCode: '31783',
  },
  'rebersburg-pa': {
    name: 'Rebersburg',
    postalCode: '16872',
  },
  'rebuck-pa': {
    name: 'Rebuck',
    postalCode: '17867',
  },
  'recluse-wy': {
    name: 'Recluse',
    postalCode: '82725',
  },
  'rector-ar': {
    name: 'Rector',
    postalCode: '72461',
  },
  'rector-pa': {
    name: 'Rector',
    postalCode: '15677',
  },
  'red-bank-nj': {
    name: 'Red Bank',
    postalCode: '07701',
  },
  'red-banks-ms': {
    name: 'Red Banks',
    postalCode: '38661',
  },
  'red-bay-al': {
    name: 'Red Bay',
    postalCode: '35582',
  },
  'red-bluff-ca': {
    name: 'Red Bluff',
    postalCode: '96080',
  },
  'red-boiling-springs-tn': {
    name: 'Red Boiling Springs',
    postalCode: '37150',
  },
  'red-bud-il': {
    name: 'Red Bud',
    postalCode: '62278',
  },
  'red-cloud-ne': {
    name: 'Red Cloud',
    postalCode: '68970',
  },
  'red-creek-ny': {
    name: 'Red Creek',
    postalCode: '13143',
  },
  'red-creek-wv': {
    name: 'Red Creek',
    postalCode: '26289',
  },
  'red-feather-lakes-co': {
    name: 'Red Feather Lakes',
    postalCode: '80545',
  },
  'red-hill-pa': {
    name: 'Red Hill',
    postalCode: '18076',
  },
  'red-hook-ny': {
    name: 'Red Hook',
    postalCode: '12571',
  },
  'red-house-va': {
    name: 'Red House',
    postalCode: '23963',
  },
  'red-house-wv': {
    name: 'Red House',
    postalCode: '25168',
  },
  'red-lake-falls-mn': {
    name: 'Red Lake Falls',
    postalCode: '56750',
  },
  'red-level-al': {
    name: 'Red Level',
    postalCode: '36474',
  },
  'red-lion-pa': {
    name: 'Red Lion',
    postalCode: '17356',
  },
  'red-lodge-mt': {
    name: 'Red Lodge',
    postalCode: '59068',
  },
  'red-oak-ia': {
    name: 'Red Oak',
    postalCode: '51566',
  },
  'red-oak-ok': {
    name: 'Red Oak',
    postalCode: '74563',
  },
  'red-oak-tx': {
    name: 'Red Oak',
    postalCode: '75154',
  },
  'red-oak-va': {
    name: 'Red Oak',
    postalCode: '23964',
  },
  'red-rock-az': {
    name: 'Red Rock',
    postalCode: '85145',
  },
  'red-rock-ok': {
    name: 'Red Rock',
    postalCode: '74651',
  },
  'red-rock-tx': {
    name: 'Red Rock',
    postalCode: '78662',
  },
  'red-springs-nc': {
    name: 'Red Springs',
    postalCode: '28377',
  },
  'red-wing-mn': {
    name: 'Red Wing',
    postalCode: '55066',
  },
  'redbird-ok': {
    name: 'Redbird',
    postalCode: '74458',
  },
  'redcrest-ca': {
    name: 'Redcrest',
    postalCode: '95569',
  },
  'reddick-fl': {
    name: 'Reddick',
    postalCode: '32686',
  },
  'reddick-il': {
    name: 'Reddick',
    postalCode: '60961',
  },
  'redding-ca': {
    name: 'Redding',
    postalCode: '96001',
  },
  'redding-ct': {
    name: 'Redding',
    postalCode: '06896',
  },
  'redding-ia': {
    name: 'Redding',
    postalCode: '50860',
  },
  'redfield-ar': {
    name: 'Redfield',
    postalCode: '72132',
  },
  'redfield-ia': {
    name: 'Redfield',
    postalCode: '50233',
  },
  'redfield-ks': {
    name: 'Redfield',
    postalCode: '66769',
  },
  'redfield-ny': {
    name: 'Redfield',
    postalCode: '13437',
  },
  'redfield-sd': {
    name: 'Redfield',
    postalCode: '57469',
  },
  'redford-mi': {
    name: 'Redford',
    postalCode: '48239',
  },
  'redford-mo': {
    name: 'Redford',
    postalCode: '63665',
  },
  'redford-ny': {
    name: 'Redford',
    postalCode: '12978',
  },
  'redfox-ky': {
    name: 'Redfox',
    postalCode: '41847',
  },
  'redgranite-wi': {
    name: 'Redgranite',
    postalCode: '54970',
  },
  'redkey-in': {
    name: 'Redkey',
    postalCode: '47373',
  },
  'redlands-ca': {
    name: 'Redlands',
    postalCode: '92373',
  },
  'redmond-or': {
    name: 'Redmond',
    postalCode: '97756',
  },
  'redmond-wa': {
    name: 'Redmond',
    postalCode: '98052',
  },
  'redondo-wa': {
    name: 'Redondo',
    postalCode: '98054',
  },
  'redondo-beach-ca': {
    name: 'Redondo Beach',
    postalCode: '90277',
  },
  'redstone-mt': {
    name: 'Redstone',
    postalCode: '59257',
  },
  'redvale-co': {
    name: 'Redvale',
    postalCode: '81431',
  },
  'redway-ca': {
    name: 'Redway',
    postalCode: '95560',
  },
  'redwood-ms': {
    name: 'Redwood',
    postalCode: '39156',
  },
  'redwood-ny': {
    name: 'Redwood',
    postalCode: '13679',
  },
  'redwood-city-ca': {
    name: 'Redwood City',
    postalCode: '94061',
  },
  'redwood-falls-mn': {
    name: 'Redwood Falls',
    postalCode: '56283',
  },
  'redwood-valley-ca': {
    name: 'Redwood Valley',
    postalCode: '95470',
  },
  'ree-heights-sd': {
    name: 'Ree Heights',
    postalCode: '57371',
  },
  'reed-ky': {
    name: 'Reed',
    postalCode: '42451',
  },
  'reed-city-mi': {
    name: 'Reed City',
    postalCode: '49677',
  },
  'reed-point-mt': {
    name: 'Reed Point',
    postalCode: '59069',
  },
  'reeder-nd': {
    name: 'Reeder',
    postalCode: '58649',
  },
  'reeders-pa': {
    name: 'Reeders',
    postalCode: '18352',
  },
  'reedley-ca': {
    name: 'Reedley',
    postalCode: '93654',
  },
  'reeds-mo': {
    name: 'Reeds',
    postalCode: '64859',
  },
  'reeds-spring-mo': {
    name: 'Reeds Spring',
    postalCode: '65737',
  },
  'reedsburg-wi': {
    name: 'Reedsburg',
    postalCode: '53959',
  },
  'reedsport-or': {
    name: 'Reedsport',
    postalCode: '97467',
  },
  'reedsville-oh': {
    name: 'Reedsville',
    postalCode: '45772',
  },
  'reedsville-pa': {
    name: 'Reedsville',
    postalCode: '17084',
  },
  'reedsville-wi': {
    name: 'Reedsville',
    postalCode: '54230',
  },
  'reedsville-wv': {
    name: 'Reedsville',
    postalCode: '26547',
  },
  'reedville-va': {
    name: 'Reedville',
    postalCode: '22539',
  },
  'reedy-wv': {
    name: 'Reedy',
    postalCode: '25270',
  },
  'reelsville-in': {
    name: 'Reelsville',
    postalCode: '46171',
  },
  'reese-mi': {
    name: 'Reese',
    postalCode: '48757',
  },
  'reeseville-wi': {
    name: 'Reeseville',
    postalCode: '53579',
  },
  'reeves-la': {
    name: 'Reeves',
    postalCode: '70658',
  },
  'reevesville-sc': {
    name: 'Reevesville',
    postalCode: '29471',
  },
  'reform-al': {
    name: 'Reform',
    postalCode: '35481',
  },
  'refugio-tx': {
    name: 'Refugio',
    postalCode: '78377',
  },
  'regan-nd': {
    name: 'Regan',
    postalCode: '58477',
  },
  'regent-nd': {
    name: 'Regent',
    postalCode: '58650',
  },
  'regina-ky': {
    name: 'Regina',
    postalCode: '41559',
  },
  'regina-nm': {
    name: 'Regina',
    postalCode: '87046',
  },
  'register-ga': {
    name: 'Register',
    postalCode: '30452',
  },
  'rego-park-ny': {
    name: 'Rego Park',
    postalCode: '11374',
  },
  'rehoboth-ma': {
    name: 'Rehoboth',
    postalCode: '02769',
  },
  'rehoboth-beach-de': {
    name: 'Rehoboth Beach',
    postalCode: '19971',
  },
  'reidsville-ga': {
    name: 'Reidsville',
    postalCode: '30453',
  },
  'reidsville-nc': {
    name: 'Reidsville',
    postalCode: '27320',
  },
  'reinbeck-ia': {
    name: 'Reinbeck',
    postalCode: '50669',
  },
  'reinholds-pa': {
    name: 'Reinholds',
    postalCode: '17569',
  },
  'reisterstown-md': {
    name: 'Reisterstown',
    postalCode: '21136',
  },
  'reklaw-tx': {
    name: 'Reklaw',
    postalCode: '75784',
  },
  'reliance-sd': {
    name: 'Reliance',
    postalCode: '57569',
  },
  'reliance-tn': {
    name: 'Reliance',
    postalCode: '37369',
  },
  'rembert-sc': {
    name: 'Rembert',
    postalCode: '29128',
  },
  'rembrandt-ia': {
    name: 'Rembrandt',
    postalCode: '50576',
  },
  'remer-mn': {
    name: 'Remer',
    postalCode: '56672',
  },
  'remington-in': {
    name: 'Remington',
    postalCode: '47977',
  },
  'remington-va': {
    name: 'Remington',
    postalCode: '22734',
  },
  'remlap-al': {
    name: 'Remlap',
    postalCode: '35133',
  },
  'remsen-ia': {
    name: 'Remsen',
    postalCode: '51050',
  },
  'remsen-ny': {
    name: 'Remsen',
    postalCode: '13438',
  },
  'remus-mi': {
    name: 'Remus',
    postalCode: '49340',
  },
  'renfrew-pa': {
    name: 'Renfrew',
    postalCode: '16053',
  },
  'renick-wv': {
    name: 'Renick',
    postalCode: '24966',
  },
  'renner-sd': {
    name: 'Renner',
    postalCode: '57055',
  },
  'reno-nv': {
    name: 'Reno',
    postalCode: '89501',
  },
  'reno-oh': {
    name: 'Reno',
    postalCode: '45773',
  },
  'renovo-pa': {
    name: 'Renovo',
    postalCode: '17764',
  },
  'rensselaer-in': {
    name: 'Rensselaer',
    postalCode: '47978',
  },
  'rensselaer-ny': {
    name: 'Rensselaer',
    postalCode: '12144',
  },
  'rensselaer-falls-ny': {
    name: 'Rensselaer Falls',
    postalCode: '13680',
  },
  'rensselaerville-ny': {
    name: 'Rensselaerville',
    postalCode: '12147',
  },
  'renton-wa': {
    name: 'Renton',
    postalCode: '98055',
  },
  'rentz-ga': {
    name: 'Rentz',
    postalCode: '31075',
  },
  'renville-mn': {
    name: 'Renville',
    postalCode: '56284',
  },
  'renwick-ia': {
    name: 'Renwick',
    postalCode: '50577',
  },
  'repton-al': {
    name: 'Repton',
    postalCode: '36475',
  },
  'republic-ks': {
    name: 'Republic',
    postalCode: '66964',
  },
  'republic-mi': {
    name: 'Republic',
    postalCode: '49879',
  },
  'republic-mo': {
    name: 'Republic',
    postalCode: '65738',
  },
  'republic-oh': {
    name: 'Republic',
    postalCode: '44867',
  },
  'republic-wa': {
    name: 'Republic',
    postalCode: '99166',
  },
  'republican-city-ne': {
    name: 'Republican City',
    postalCode: '68971',
  },
  'resaca-ga': {
    name: 'Resaca',
    postalCode: '30735',
  },
  'rescue-ca': {
    name: 'Rescue',
    postalCode: '95672',
  },
  'reseda-ca': {
    name: 'Reseda',
    postalCode: '91335',
  },
  'reserve-la': {
    name: 'Reserve',
    postalCode: '70084',
  },
  'reserve-mt': {
    name: 'Reserve',
    postalCode: '59258',
  },
  'reserve-nm': {
    name: 'Reserve',
    postalCode: '87830',
  },
  'reston-va': {
    name: 'Reston',
    postalCode: '20190',
  },
  'reubens-id': {
    name: 'Reubens',
    postalCode: '83548',
  },
  'reva-sd': {
    name: 'Reva',
    postalCode: '57651',
  },
  'reva-va': {
    name: 'Reva',
    postalCode: '22735',
  },
  'revere-ma': {
    name: 'Revere',
    postalCode: '02151',
  },
  'revere-mn': {
    name: 'Revere',
    postalCode: '56166',
  },
  'revere-mo': {
    name: 'Revere',
    postalCode: '63465',
  },
  'revillo-sd': {
    name: 'Revillo',
    postalCode: '57259',
  },
  'rew-pa': {
    name: 'Rew',
    postalCode: '16744',
  },
  'rewey-wi': {
    name: 'Rewey',
    postalCode: '53580',
  },
  'rex-ga': {
    name: 'Rex',
    postalCode: '30273',
  },
  'rexburg-id': {
    name: 'Rexburg',
    postalCode: '83440',
  },
  'rexford-ks': {
    name: 'Rexford',
    postalCode: '67753',
  },
  'rexford-mt': {
    name: 'Rexford',
    postalCode: '59930',
  },
  'rexford-ny': {
    name: 'Rexford',
    postalCode: '12148',
  },
  'rexville-ny': {
    name: 'Rexville',
    postalCode: '14877',
  },
  'reydon-ok': {
    name: 'Reydon',
    postalCode: '73660',
  },
  'reynolds-ga': {
    name: 'Reynolds',
    postalCode: '31076',
  },
  'reynolds-il': {
    name: 'Reynolds',
    postalCode: '61279',
  },
  'reynolds-in': {
    name: 'Reynolds',
    postalCode: '47980',
  },
  'reynolds-nd': {
    name: 'Reynolds',
    postalCode: '58275',
  },
  'reynolds-ne': {
    name: 'Reynolds',
    postalCode: '68429',
  },
  'reynolds-station-ky': {
    name: 'Reynolds Station',
    postalCode: '42368',
  },
  'reynoldsburg-oh': {
    name: 'Reynoldsburg',
    postalCode: '43068',
  },
  'reynoldsville-pa': {
    name: 'Reynoldsville',
    postalCode: '15851',
  },
  'rhame-nd': {
    name: 'Rhame',
    postalCode: '58651',
  },
  'rhine-ga': {
    name: 'Rhine',
    postalCode: '31077',
  },
  'rhinebeck-ny': {
    name: 'Rhinebeck',
    postalCode: '12572',
  },
  'rhineland-mo': {
    name: 'Rhineland',
    postalCode: '65069',
  },
  'rhinelander-wi': {
    name: 'Rhinelander',
    postalCode: '54501',
  },
  'rhoadesville-va': {
    name: 'Rhoadesville',
    postalCode: '22542',
  },
  'rhodelia-ky': {
    name: 'Rhodelia',
    postalCode: '40161',
  },
  'rhodell-wv': {
    name: 'Rhodell',
    postalCode: '25915',
  },
  'rhodes-ia': {
    name: 'Rhodes',
    postalCode: '50234',
  },
  'rhodes-mi': {
    name: 'Rhodes',
    postalCode: '48652',
  },
  'rhodesdale-md': {
    name: 'Rhodesdale',
    postalCode: '21659',
  },
  'rhododendron-or': {
    name: 'Rhododendron',
    postalCode: '97049',
  },
  'rhome-tx': {
    name: 'Rhome',
    postalCode: '76078',
  },
  'rialto-ca': {
    name: 'Rialto',
    postalCode: '92376',
  },
  'rib-lake-wi': {
    name: 'Rib Lake',
    postalCode: '54470',
  },
  'ribera-nm': {
    name: 'Ribera',
    postalCode: '87560',
  },
  'rice-mn': {
    name: 'Rice',
    postalCode: '56367',
  },
  'rice-tx': {
    name: 'Rice',
    postalCode: '75155',
  },
  'rice-va': {
    name: 'Rice',
    postalCode: '23966',
  },
  'rice-wa': {
    name: 'Rice',
    postalCode: '99167',
  },
  'rice-lake-wi': {
    name: 'Rice Lake',
    postalCode: '54868',
  },
  'riceboro-ga': {
    name: 'Riceboro',
    postalCode: '31323',
  },
  'rices-landing-pa': {
    name: 'Rices Landing',
    postalCode: '15357',
  },
  'ricetown-ky': {
    name: 'Ricetown',
    postalCode: '41364',
  },
  'riceville-ia': {
    name: 'Riceville',
    postalCode: '50466',
  },
  'riceville-pa': {
    name: 'Riceville',
    postalCode: '16432',
  },
  'riceville-tn': {
    name: 'Riceville',
    postalCode: '37370',
  },
  'rich-creek-va': {
    name: 'Rich Creek',
    postalCode: '24147',
  },
  'rich-hill-mo': {
    name: 'Rich Hill',
    postalCode: '64779',
  },
  'rich-square-nc': {
    name: 'Rich Square',
    postalCode: '27869',
  },
  'richards-mo': {
    name: 'Richards',
    postalCode: '64778',
  },
  'richards-tx': {
    name: 'Richards',
    postalCode: '77873',
  },
  'richardson-tx': {
    name: 'Richardson',
    postalCode: '75080',
  },
  'richardsville-va': {
    name: 'Richardsville',
    postalCode: '22736',
  },
  'richardton-nd': {
    name: 'Richardton',
    postalCode: '58652',
  },
  'richboro-pa': {
    name: 'Richboro',
    postalCode: '18954',
  },
  'richburg-sc': {
    name: 'Richburg',
    postalCode: '29729',
  },
  'richey-mt': {
    name: 'Richey',
    postalCode: '59259',
  },
  'richfield-id': {
    name: 'Richfield',
    postalCode: '83349',
  },
  'richfield-ks': {
    name: 'Richfield',
    postalCode: '67953',
  },
  'richfield-nc': {
    name: 'Richfield',
    postalCode: '28137',
  },
  'richfield-oh': {
    name: 'Richfield',
    postalCode: '44286',
  },
  'richfield-pa': {
    name: 'Richfield',
    postalCode: '17086',
  },
  'richfield-ut': {
    name: 'Richfield',
    postalCode: '84701',
  },
  'richfield-wi': {
    name: 'Richfield',
    postalCode: '53076',
  },
  'richfield-springs-ny': {
    name: 'Richfield Springs',
    postalCode: '13439',
  },
  'richford-ny': {
    name: 'Richford',
    postalCode: '13835',
  },
  'richford-vt': {
    name: 'Richford',
    postalCode: '05476',
  },
  'richland-ga': {
    name: 'Richland',
    postalCode: '31825',
  },
  'richland-ia': {
    name: 'Richland',
    postalCode: '52585',
  },
  'richland-in': {
    name: 'Richland',
    postalCode: '47634',
  },
  'richland-mi': {
    name: 'Richland',
    postalCode: '49083',
  },
  'richland-mo': {
    name: 'Richland',
    postalCode: '65556',
  },
  'richland-ms': {
    name: 'Richland',
    postalCode: '39218',
  },
  'richland-mt': {
    name: 'Richland',
    postalCode: '59260',
  },
  'richland-nj': {
    name: 'Richland',
    postalCode: '08350',
  },
  'richland-ny': {
    name: 'Richland',
    postalCode: '13144',
  },
  'richland-or': {
    name: 'Richland',
    postalCode: '97870',
  },
  'richland-pa': {
    name: 'Richland',
    postalCode: '17087',
  },
  'richland-tx': {
    name: 'Richland',
    postalCode: '76681',
  },
  'richland-wa': {
    name: 'Richland',
    postalCode: '99352',
  },
  'richland-center-wi': {
    name: 'Richland Center',
    postalCode: '53581',
  },
  'richland-springs-tx': {
    name: 'Richland Springs',
    postalCode: '76871',
  },
  'richlands-nc': {
    name: 'Richlands',
    postalCode: '28574',
  },
  'richlands-va': {
    name: 'Richlands',
    postalCode: '24641',
  },
  'richlandtown-pa': {
    name: 'Richlandtown',
    postalCode: '18955',
  },
  'richmond-ca': {
    name: 'Richmond',
    postalCode: '94801',
  },
  'richmond-il': {
    name: 'Richmond',
    postalCode: '60071',
  },
  'richmond-in': {
    name: 'Richmond',
    postalCode: '47374',
  },
  'richmond-ks': {
    name: 'Richmond',
    postalCode: '66080',
  },
  'richmond-ky': {
    name: 'Richmond',
    postalCode: '40475',
  },
  'richmond-ma': {
    name: 'Richmond',
    postalCode: '01254',
  },
  'richmond-me': {
    name: 'Richmond',
    postalCode: '04357',
  },
  'richmond-mi': {
    name: 'Richmond',
    postalCode: '48062',
  },
  'richmond-mn': {
    name: 'Richmond',
    postalCode: '56368',
  },
  'richmond-mo': {
    name: 'Richmond',
    postalCode: '64085',
  },
  'richmond-oh': {
    name: 'Richmond',
    postalCode: '43944',
  },
  'richmond-tx': {
    name: 'Richmond',
    postalCode: '77406',
  },
  'richmond-ut': {
    name: 'Richmond',
    postalCode: '84333',
  },
  'richmond-va': {
    name: 'Richmond',
    postalCode: '23219',
  },
  'richmond-vt': {
    name: 'Richmond',
    postalCode: '05477',
  },
  'richmond-dale-oh': {
    name: 'Richmond Dale',
    postalCode: '45673',
  },
  'richmond-hill-ga': {
    name: 'Richmond Hill',
    postalCode: '31324',
  },
  'richmond-hill-ny': {
    name: 'Richmond Hill',
    postalCode: '11418',
  },
  'richmondville-ny': {
    name: 'Richmondville',
    postalCode: '12149',
  },
  'richton-ms': {
    name: 'Richton',
    postalCode: '39476',
  },
  'richton-park-il': {
    name: 'Richton Park',
    postalCode: '60471',
  },
  'richview-il': {
    name: 'Richview',
    postalCode: '62877',
  },
  'richville-mn': {
    name: 'Richville',
    postalCode: '56576',
  },
  'richville-ny': {
    name: 'Richville',
    postalCode: '13681',
  },
  'richwood-oh': {
    name: 'Richwood',
    postalCode: '43344',
  },
  'richwood-wv': {
    name: 'Richwood',
    postalCode: '26261',
  },
  'richwoods-mo': {
    name: 'Richwoods',
    postalCode: '63071',
  },
  'rickman-tn': {
    name: 'Rickman',
    postalCode: '38580',
  },
  'rickreall-or': {
    name: 'Rickreall',
    postalCode: '97371',
  },
  'riddle-or': {
    name: 'Riddle',
    postalCode: '97469',
  },
  'riddleton-tn': {
    name: 'Riddleton',
    postalCode: '37151',
  },
  'ridge-md': {
    name: 'Ridge',
    postalCode: '20680',
  },
  'ridge-ny': {
    name: 'Ridge',
    postalCode: '11961',
  },
  'ridge-farm-il': {
    name: 'Ridge Farm',
    postalCode: '61870',
  },
  'ridge-spring-sc': {
    name: 'Ridge Spring',
    postalCode: '29129',
  },
  'ridgecrest-ca': {
    name: 'Ridgecrest',
    postalCode: '93555',
  },
  'ridgedale-mo': {
    name: 'Ridgedale',
    postalCode: '65739',
  },
  'ridgefield-ct': {
    name: 'Ridgefield',
    postalCode: '06877',
  },
  'ridgefield-nj': {
    name: 'Ridgefield',
    postalCode: '07657',
  },
  'ridgefield-wa': {
    name: 'Ridgefield',
    postalCode: '98642',
  },
  'ridgefield-park-nj': {
    name: 'Ridgefield Park',
    postalCode: '07660',
  },
  'ridgeland-ms': {
    name: 'Ridgeland',
    postalCode: '39157',
  },
  'ridgeland-sc': {
    name: 'Ridgeland',
    postalCode: '29936',
  },
  'ridgeland-wi': {
    name: 'Ridgeland',
    postalCode: '54763',
  },
  'ridgeley-wv': {
    name: 'Ridgeley',
    postalCode: '26753',
  },
  'ridgely-md': {
    name: 'Ridgely',
    postalCode: '21660',
  },
  'ridgely-tn': {
    name: 'Ridgely',
    postalCode: '38080',
  },
  'ridgeview-sd': {
    name: 'Ridgeview',
    postalCode: '57652',
  },
  'ridgeview-wv': {
    name: 'Ridgeview',
    postalCode: '25169',
  },
  'ridgeville-in': {
    name: 'Ridgeville',
    postalCode: '47380',
  },
  'ridgeville-sc': {
    name: 'Ridgeville',
    postalCode: '29472',
  },
  'ridgeway-ia': {
    name: 'Ridgeway',
    postalCode: '52165',
  },
  'ridgeway-mo': {
    name: 'Ridgeway',
    postalCode: '64481',
  },
  'ridgeway-oh': {
    name: 'Ridgeway',
    postalCode: '43345',
  },
  'ridgeway-sc': {
    name: 'Ridgeway',
    postalCode: '29130',
  },
  'ridgeway-va': {
    name: 'Ridgeway',
    postalCode: '24148',
  },
  'ridgeway-wi': {
    name: 'Ridgeway',
    postalCode: '53582',
  },
  'ridgewood-nj': {
    name: 'Ridgewood',
    postalCode: '07450',
  },
  'ridgewood-ny': {
    name: 'Ridgewood',
    postalCode: '11385',
  },
  'ridgway-co': {
    name: 'Ridgway',
    postalCode: '81432',
  },
  'ridgway-il': {
    name: 'Ridgway',
    postalCode: '62979',
  },
  'ridgway-pa': {
    name: 'Ridgway',
    postalCode: '15853',
  },
  'ridley-park-pa': {
    name: 'Ridley Park',
    postalCode: '19078',
  },
  'ridott-il': {
    name: 'Ridott',
    postalCode: '61067',
  },
  'riegelsville-pa': {
    name: 'Riegelsville',
    postalCode: '18077',
  },
  'riegelwood-nc': {
    name: 'Riegelwood',
    postalCode: '28456',
  },
  'rienzi-ms': {
    name: 'Rienzi',
    postalCode: '38865',
  },
  'riesel-tx': {
    name: 'Riesel',
    postalCode: '76682',
  },
  'rifle-co': {
    name: 'Rifle',
    postalCode: '81650',
  },
  'riga-mi': {
    name: 'Riga',
    postalCode: '49276',
  },
  'rigby-id': {
    name: 'Rigby',
    postalCode: '83442',
  },
  'riggins-id': {
    name: 'Riggins',
    postalCode: '83549',
  },
  'riley-ks': {
    name: 'Riley',
    postalCode: '66531',
  },
  'riley-or': {
    name: 'Riley',
    postalCode: '97758',
  },
  'rileyville-va': {
    name: 'Rileyville',
    postalCode: '22650',
  },
  'rillton-pa': {
    name: 'Rillton',
    postalCode: '15678',
  },
  'rimersburg-pa': {
    name: 'Rimersburg',
    postalCode: '16248',
  },
  'rimrock-az': {
    name: 'Rimrock',
    postalCode: '86335',
  },
  'rinard-il': {
    name: 'Rinard',
    postalCode: '62878',
  },
  'rincon-ga': {
    name: 'Rincon',
    postalCode: '31326',
  },
  'rincon-nm': {
    name: 'Rincon',
    postalCode: '87940',
  },
  'rincon-pr': {
    name: 'Rincon',
    postalCode: '00677',
  },
  'rindge-nh': {
    name: 'Rindge',
    postalCode: '03461',
  },
  'riner-va': {
    name: 'Riner',
    postalCode: '24149',
  },
  'rineyville-ky': {
    name: 'Rineyville',
    postalCode: '40162',
  },
  'ringgold-ga': {
    name: 'Ringgold',
    postalCode: '30736',
  },
  'ringgold-la': {
    name: 'Ringgold',
    postalCode: '71068',
  },
  'ringgold-pa': {
    name: 'Ringgold',
    postalCode: '15770',
  },
  'ringgold-tx': {
    name: 'Ringgold',
    postalCode: '76261',
  },
  'ringgold-va': {
    name: 'Ringgold',
    postalCode: '24586',
  },
  'ringle-wi': {
    name: 'Ringle',
    postalCode: '54471',
  },
  'ringling-ok': {
    name: 'Ringling',
    postalCode: '73456',
  },
  'ringoes-nj': {
    name: 'Ringoes',
    postalCode: '08551',
  },
  'ringold-ok': {
    name: 'Ringold',
    postalCode: '74754',
  },
  'ringsted-ia': {
    name: 'Ringsted',
    postalCode: '50578',
  },
  'ringtown-pa': {
    name: 'Ringtown',
    postalCode: '17967',
  },
  'ringwood-il': {
    name: 'Ringwood',
    postalCode: '60072',
  },
  'ringwood-nj': {
    name: 'Ringwood',
    postalCode: '07456',
  },
  'ringwood-ok': {
    name: 'Ringwood',
    postalCode: '73768',
  },
  'rio-il': {
    name: 'Rio',
    postalCode: '61472',
  },
  'rio-wi': {
    name: 'Rio',
    postalCode: '53960',
  },
  'rio-wv': {
    name: 'Rio',
    postalCode: '26755',
  },
  'rio-dell-ca': {
    name: 'Rio Dell',
    postalCode: '95562',
  },
  'rio-frio-tx': {
    name: 'Rio Frio',
    postalCode: '78879',
  },
  'rio-grande-nj': {
    name: 'Rio Grande',
    postalCode: '08242',
  },
  'rio-grande-pr': {
    name: 'Rio Grande',
    postalCode: '00745',
  },
  'rio-grande-city-tx': {
    name: 'Rio Grande City',
    postalCode: '78582',
  },
  'rio-hondo-tx': {
    name: 'Rio Hondo',
    postalCode: '78583',
  },
  'rio-linda-ca': {
    name: 'Rio Linda',
    postalCode: '95673',
  },
  'rio-medina-tx': {
    name: 'Rio Medina',
    postalCode: '78066',
  },
  'rio-oso-ca': {
    name: 'Rio Oso',
    postalCode: '95674',
  },
  'rio-rancho-nm': {
    name: 'Rio Rancho',
    postalCode: '87124',
  },
  'rio-rico-az': {
    name: 'Rio Rico',
    postalCode: '85648',
  },
  'rio-verde-az': {
    name: 'Rio Verde',
    postalCode: '85263',
  },
  'rio-vista-ca': {
    name: 'Rio Vista',
    postalCode: '94571',
  },
  'rio-vista-tx': {
    name: 'Rio Vista',
    postalCode: '76093',
  },
  'ripley-ms': {
    name: 'Ripley',
    postalCode: '38663',
  },
  'ripley-ny': {
    name: 'Ripley',
    postalCode: '14775',
  },
  'ripley-oh': {
    name: 'Ripley',
    postalCode: '45167',
  },
  'ripley-ok': {
    name: 'Ripley',
    postalCode: '74062',
  },
  'ripley-tn': {
    name: 'Ripley',
    postalCode: '38063',
  },
  'ripley-wv': {
    name: 'Ripley',
    postalCode: '25271',
  },
  'ripon-ca': {
    name: 'Ripon',
    postalCode: '95366',
  },
  'ripon-wi': {
    name: 'Ripon',
    postalCode: '54971',
  },
  'rippey-ia': {
    name: 'Rippey',
    postalCode: '50235',
  },
  'ripplemead-va': {
    name: 'Ripplemead',
    postalCode: '24150',
  },
  'ripton-vt': {
    name: 'Ripton',
    postalCode: '05766',
  },
  'ririe-id': {
    name: 'Ririe',
    postalCode: '83443',
  },
  'rising-city-ne': {
    name: 'Rising City',
    postalCode: '68658',
  },
  'rising-fawn-ga': {
    name: 'Rising Fawn',
    postalCode: '30738',
  },
  'rising-star-tx': {
    name: 'Rising Star',
    postalCode: '76471',
  },
  'rising-sun-in': {
    name: 'Rising Sun',
    postalCode: '47040',
  },
  'rising-sun-md': {
    name: 'Rising Sun',
    postalCode: '21911',
  },
  'risingsun-oh': {
    name: 'Risingsun',
    postalCode: '43457',
  },
  'rison-ar': {
    name: 'Rison',
    postalCode: '71665',
  },
  'rittman-oh': {
    name: 'Rittman',
    postalCode: '44270',
  },
  'ritzville-wa': {
    name: 'Ritzville',
    postalCode: '99169',
  },
  'riva-md': {
    name: 'Riva',
    postalCode: '21140',
  },
  'river-ky': {
    name: 'River',
    postalCode: '41254',
  },
  'river-edge-nj': {
    name: 'River Edge',
    postalCode: '07661',
  },
  'river-falls-wi': {
    name: 'River Falls',
    postalCode: '54022',
  },
  'river-forest-il': {
    name: 'River Forest',
    postalCode: '60305',
  },
  'river-grove-il': {
    name: 'River Grove',
    postalCode: '60171',
  },
  'river-ranch-fl': {
    name: 'River Ranch',
    postalCode: '33867',
  },
  'river-rouge-mi': {
    name: 'River Rouge',
    postalCode: '48218',
  },
  'riverbank-ca': {
    name: 'Riverbank',
    postalCode: '95367',
  },
  'riverdale-ca': {
    name: 'Riverdale',
    postalCode: '93656',
  },
  'riverdale-ga': {
    name: 'Riverdale',
    postalCode: '30274',
  },
  'riverdale-il': {
    name: 'Riverdale',
    postalCode: '60827',
  },
  'riverdale-md': {
    name: 'Riverdale',
    postalCode: '20737',
  },
  'riverdale-mi': {
    name: 'Riverdale',
    postalCode: '48877',
  },
  'riverdale-nd': {
    name: 'Riverdale',
    postalCode: '58565',
  },
  'riverdale-ne': {
    name: 'Riverdale',
    postalCode: '68870',
  },
  'riverdale-nj': {
    name: 'Riverdale',
    postalCode: '07457',
  },
  'riverhead-ny': {
    name: 'Riverhead',
    postalCode: '11901',
  },
  'riverside-al': {
    name: 'Riverside',
    postalCode: '35135',
  },
  'riverside-ca': {
    name: 'Riverside',
    postalCode: '92501',
  },
  'riverside-ct': {
    name: 'Riverside',
    postalCode: '06878',
  },
  'riverside-ia': {
    name: 'Riverside',
    postalCode: '52327',
  },
  'riverside-il': {
    name: 'Riverside',
    postalCode: '60546',
  },
  'riverside-mo': {
    name: 'Riverside',
    postalCode: '64150',
  },
  'riverside-nj': {
    name: 'Riverside',
    postalCode: '08075',
  },
  'riverside-or': {
    name: 'Riverside',
    postalCode: '97917',
  },
  'riverside-ri': {
    name: 'Riverside',
    postalCode: '02915',
  },
  'riverside-wa': {
    name: 'Riverside',
    postalCode: '98849',
  },
  'riverton-ct': {
    name: 'Riverton',
    postalCode: '06065',
  },
  'riverton-ia': {
    name: 'Riverton',
    postalCode: '51650',
  },
  'riverton-il': {
    name: 'Riverton',
    postalCode: '62561',
  },
  'riverton-ks': {
    name: 'Riverton',
    postalCode: '66770',
  },
  'riverton-ne': {
    name: 'Riverton',
    postalCode: '68972',
  },
  'riverton-nj': {
    name: 'Riverton',
    postalCode: '08077',
  },
  'riverton-ut': {
    name: 'Riverton',
    postalCode: '84065',
  },
  'riverton-wv': {
    name: 'Riverton',
    postalCode: '26814',
  },
  'riverton-wy': {
    name: 'Riverton',
    postalCode: '82501',
  },
  'riverview-fl': {
    name: 'Riverview',
    postalCode: '33569',
  },
  'riverview-mi': {
    name: 'Riverview',
    postalCode: '48193',
  },
  'rives-tn': {
    name: 'Rives',
    postalCode: '38253',
  },
  'rives-junction-mi': {
    name: 'Rives Junction',
    postalCode: '49277',
  },
  'rivesville-wv': {
    name: 'Rivesville',
    postalCode: '26588',
  },
  'riviera-tx': {
    name: 'Riviera',
    postalCode: '78379',
  },
  'rixeyville-va': {
    name: 'Rixeyville',
    postalCode: '22737',
  },
  'rixford-pa': {
    name: 'Rixford',
    postalCode: '16745',
  },
  'roach-mo': {
    name: 'Roach',
    postalCode: '65787',
  },
  'roachdale-in': {
    name: 'Roachdale',
    postalCode: '46172',
  },
  'roan-mountain-tn': {
    name: 'Roan Mountain',
    postalCode: '37687',
  },
  'roann-in': {
    name: 'Roann',
    postalCode: '46974',
  },
  'roanoke-al': {
    name: 'Roanoke',
    postalCode: '36274',
  },
  'roanoke-il': {
    name: 'Roanoke',
    postalCode: '61561',
  },
  'roanoke-in': {
    name: 'Roanoke',
    postalCode: '46783',
  },
  'roanoke-la': {
    name: 'Roanoke',
    postalCode: '70581',
  },
  'roanoke-tx': {
    name: 'Roanoke',
    postalCode: '76262',
  },
  'roanoke-va': {
    name: 'Roanoke',
    postalCode: '24011',
  },
  'roanoke-rapids-nc': {
    name: 'Roanoke Rapids',
    postalCode: '27870',
  },
  'roaring-branch-pa': {
    name: 'Roaring Branch',
    postalCode: '17765',
  },
  'roaring-gap-nc': {
    name: 'Roaring Gap',
    postalCode: '28668',
  },
  'roaring-river-nc': {
    name: 'Roaring River',
    postalCode: '28669',
  },
  'roaring-spring-pa': {
    name: 'Roaring Spring',
    postalCode: '16673',
  },
  'roaring-springs-tx': {
    name: 'Roaring Springs',
    postalCode: '79256',
  },
  'roark-ky': {
    name: 'Roark',
    postalCode: '40979',
  },
  'robards-ky': {
    name: 'Robards',
    postalCode: '42452',
  },
  'robbins-il': {
    name: 'Robbins',
    postalCode: '60472',
  },
  'robbins-nc': {
    name: 'Robbins',
    postalCode: '27325',
  },
  'robbins-tn': {
    name: 'Robbins',
    postalCode: '37852',
  },
  'robbinston-me': {
    name: 'Robbinston',
    postalCode: '04671',
  },
  'robbinsville-nc': {
    name: 'Robbinsville',
    postalCode: '28771',
  },
  'robbinsville-nj': {
    name: 'Robbinsville',
    postalCode: '08691',
  },
  'robeline-la': {
    name: 'Robeline',
    postalCode: '71469',
  },
  'robersonville-nc': {
    name: 'Robersonville',
    postalCode: '27871',
  },
  'robert-la': {
    name: 'Robert',
    postalCode: '70455',
  },
  'robert-lee-tx': {
    name: 'Robert Lee',
    postalCode: '76945',
  },
  'roberta-ga': {
    name: 'Roberta',
    postalCode: '31078',
  },
  'roberts-id': {
    name: 'Roberts',
    postalCode: '83444',
  },
  'roberts-il': {
    name: 'Roberts',
    postalCode: '60962',
  },
  'roberts-mt': {
    name: 'Roberts',
    postalCode: '59070',
  },
  'roberts-wi': {
    name: 'Roberts',
    postalCode: '54023',
  },
  'robertsdale-al': {
    name: 'Robertsdale',
    postalCode: '36567',
  },
  'robertsdale-pa': {
    name: 'Robertsdale',
    postalCode: '16674',
  },
  'robertsville-mo': {
    name: 'Robertsville',
    postalCode: '63072',
  },
  'robesonia-pa': {
    name: 'Robesonia',
    postalCode: '19551',
  },
  'robins-ia': {
    name: 'Robins',
    postalCode: '52328',
  },
  'robinson-il': {
    name: 'Robinson',
    postalCode: '62454',
  },
  'robinson-ks': {
    name: 'Robinson',
    postalCode: '66532',
  },
  'robinson-nd': {
    name: 'Robinson',
    postalCode: '58478',
  },
  'robinson-pa': {
    name: 'Robinson',
    postalCode: '15949',
  },
  'robinson-creek-ky': {
    name: 'Robinson Creek',
    postalCode: '41560',
  },
  'robinsonville-ms': {
    name: 'Robinsonville',
    postalCode: '38664',
  },
  'robson-wv': {
    name: 'Robson',
    postalCode: '25173',
  },
  'robstown-tx': {
    name: 'Robstown',
    postalCode: '78380',
  },
  'roby-mo': {
    name: 'Roby',
    postalCode: '65557',
  },
  'roby-tx': {
    name: 'Roby',
    postalCode: '79543',
  },
  'roca-ne': {
    name: 'Roca',
    postalCode: '68430',
  },
  'rochdale-ma': {
    name: 'Rochdale',
    postalCode: '01542',
  },
  'rochelle-ga': {
    name: 'Rochelle',
    postalCode: '31079',
  },
  'rochelle-il': {
    name: 'Rochelle',
    postalCode: '61068',
  },
  'rochelle-tx': {
    name: 'Rochelle',
    postalCode: '76872',
  },
  'rochelle-va': {
    name: 'Rochelle',
    postalCode: '22738',
  },
  'rochelle-park-nj': {
    name: 'Rochelle Park',
    postalCode: '07662',
  },
  'rocheport-mo': {
    name: 'Rocheport',
    postalCode: '65279',
  },
  'rochert-mn': {
    name: 'Rochert',
    postalCode: '56578',
  },
  'rochester-il': {
    name: 'Rochester',
    postalCode: '62563',
  },
  'rochester-in': {
    name: 'Rochester',
    postalCode: '46975',
  },
  'rochester-ky': {
    name: 'Rochester',
    postalCode: '42273',
  },
  'rochester-ma': {
    name: 'Rochester',
    postalCode: '02770',
  },
  'rochester-mi': {
    name: 'Rochester',
    postalCode: '48306',
  },
  'rochester-mn': {
    name: 'Rochester',
    postalCode: '55901',
  },
  'rochester-nh': {
    name: 'Rochester',
    postalCode: '03839',
  },
  'rochester-ny': {
    name: 'Rochester',
    postalCode: '14604',
  },
  'rochester-pa': {
    name: 'Rochester',
    postalCode: '15074',
  },
  'rochester-tx': {
    name: 'Rochester',
    postalCode: '79544',
  },
  'rochester-vt': {
    name: 'Rochester',
    postalCode: '05767',
  },
  'rochester-wa': {
    name: 'Rochester',
    postalCode: '98579',
  },
  'rochester-mills-pa': {
    name: 'Rochester Mills',
    postalCode: '15771',
  },
  'rociada-nm': {
    name: 'Rociada',
    postalCode: '87742',
  },
  'rock-ks': {
    name: 'Rock',
    postalCode: '67131',
  },
  'rock-mi': {
    name: 'Rock',
    postalCode: '49880',
  },
  'rock-wv': {
    name: 'Rock',
    postalCode: '24747',
  },
  'rock-cave-wv': {
    name: 'Rock Cave',
    postalCode: '26234',
  },
  'rock-city-il': {
    name: 'Rock City',
    postalCode: '61070',
  },
  'rock-city-falls-ny': {
    name: 'Rock City Falls',
    postalCode: '12863',
  },
  'rock-creek-oh': {
    name: 'Rock Creek',
    postalCode: '44084',
  },
  'rock-creek-wv': {
    name: 'Rock Creek',
    postalCode: '25174',
  },
  'rock-falls-ia': {
    name: 'Rock Falls',
    postalCode: '50467',
  },
  'rock-falls-il': {
    name: 'Rock Falls',
    postalCode: '61071',
  },
  'rock-hall-md': {
    name: 'Rock Hall',
    postalCode: '21661',
  },
  'rock-hill-ny': {
    name: 'Rock Hill',
    postalCode: '12775',
  },
  'rock-hill-sc': {
    name: 'Rock Hill',
    postalCode: '29730',
  },
  'rock-island-il': {
    name: 'Rock Island',
    postalCode: '61201',
  },
  'rock-island-tn': {
    name: 'Rock Island',
    postalCode: '38581',
  },
  'rock-island-wa': {
    name: 'Rock Island',
    postalCode: '98850',
  },
  'rock-port-mo': {
    name: 'Rock Port',
    postalCode: '64482',
  },
  'rock-rapids-ia': {
    name: 'Rock Rapids',
    postalCode: '51246',
  },
  'rock-river-wy': {
    name: 'Rock River',
    postalCode: '82083',
  },
  'rock-spring-ga': {
    name: 'Rock Spring',
    postalCode: '30739',
  },
  'rock-springs-wi': {
    name: 'Rock Springs',
    postalCode: '53961',
  },
  'rock-springs-wy': {
    name: 'Rock Springs',
    postalCode: '82901',
  },
  'rock-stream-ny': {
    name: 'Rock Stream',
    postalCode: '14878',
  },
  'rock-tavern-ny': {
    name: 'Rock Tavern',
    postalCode: '12575',
  },
  'rock-valley-ia': {
    name: 'Rock Valley',
    postalCode: '51247',
  },
  'rock-view-wv': {
    name: 'Rock View',
    postalCode: '24880',
  },
  'rockaway-nj': {
    name: 'Rockaway',
    postalCode: '07866',
  },
  'rockaway-beach-mo': {
    name: 'Rockaway Beach',
    postalCode: '65740',
  },
  'rockaway-beach-or': {
    name: 'Rockaway Beach',
    postalCode: '97136',
  },
  'rockaway-park-ny': {
    name: 'Rockaway Park',
    postalCode: '11694',
  },
  'rockbridge-il': {
    name: 'Rockbridge',
    postalCode: '62081',
  },
  'rockbridge-oh': {
    name: 'Rockbridge',
    postalCode: '43149',
  },
  'rockbridge-baths-va': {
    name: 'Rockbridge Baths',
    postalCode: '24473',
  },
  'rockdale-tx': {
    name: 'Rockdale',
    postalCode: '76567',
  },
  'rockfall-ct': {
    name: 'Rockfall',
    postalCode: '06481',
  },
  'rockfield-in': {
    name: 'Rockfield',
    postalCode: '46977',
  },
  'rockfield-ky': {
    name: 'Rockfield',
    postalCode: '42274',
  },
  'rockford-al': {
    name: 'Rockford',
    postalCode: '35136',
  },
  'rockford-ia': {
    name: 'Rockford',
    postalCode: '50468',
  },
  'rockford-il': {
    name: 'Rockford',
    postalCode: '61101',
  },
  'rockford-mi': {
    name: 'Rockford',
    postalCode: '49341',
  },
  'rockford-mn': {
    name: 'Rockford',
    postalCode: '55373',
  },
  'rockford-oh': {
    name: 'Rockford',
    postalCode: '45882',
  },
  'rockford-tn': {
    name: 'Rockford',
    postalCode: '37853',
  },
  'rockford-wa': {
    name: 'Rockford',
    postalCode: '99030',
  },
  'rockham-sd': {
    name: 'Rockham',
    postalCode: '57470',
  },
  'rockholds-ky': {
    name: 'Rockholds',
    postalCode: '40759',
  },
  'rockingham-nc': {
    name: 'Rockingham',
    postalCode: '28379',
  },
  'rocklake-nd': {
    name: 'Rocklake',
    postalCode: '58365',
  },
  'rockland-id': {
    name: 'Rockland',
    postalCode: '83271',
  },
  'rockland-ma': {
    name: 'Rockland',
    postalCode: '02370',
  },
  'rockland-me': {
    name: 'Rockland',
    postalCode: '04841',
  },
  'rockland-wi': {
    name: 'Rockland',
    postalCode: '54653',
  },
  'rockledge-fl': {
    name: 'Rockledge',
    postalCode: '32955',
  },
  'rockledge-ga': {
    name: 'Rockledge',
    postalCode: '30454',
  },
  'rocklin-ca': {
    name: 'Rocklin',
    postalCode: '95677',
  },
  'rockmart-ga': {
    name: 'Rockmart',
    postalCode: '30153',
  },
  'rockport-il': {
    name: 'Rockport',
    postalCode: '62370',
  },
  'rockport-in': {
    name: 'Rockport',
    postalCode: '47635',
  },
  'rockport-ky': {
    name: 'Rockport',
    postalCode: '42369',
  },
  'rockport-ma': {
    name: 'Rockport',
    postalCode: '01966',
  },
  'rockport-me': {
    name: 'Rockport',
    postalCode: '04856',
  },
  'rockport-tx': {
    name: 'Rockport',
    postalCode: '78382',
  },
  'rockport-wa': {
    name: 'Rockport',
    postalCode: '98283',
  },
  'rockport-wv': {
    name: 'Rockport',
    postalCode: '26169',
  },
  'rocksprings-tx': {
    name: 'Rocksprings',
    postalCode: '78880',
  },
  'rockton-il': {
    name: 'Rockton',
    postalCode: '61072',
  },
  'rockton-pa': {
    name: 'Rockton',
    postalCode: '15856',
  },
  'rockvale-tn': {
    name: 'Rockvale',
    postalCode: '37153',
  },
  'rockville-in': {
    name: 'Rockville',
    postalCode: '47872',
  },
  'rockville-md': {
    name: 'Rockville',
    postalCode: '20850',
  },
  'rockville-mo': {
    name: 'Rockville',
    postalCode: '64780',
  },
  'rockville-ne': {
    name: 'Rockville',
    postalCode: '68871',
  },
  'rockville-va': {
    name: 'Rockville',
    postalCode: '23146',
  },
  'rockville-centre-ny': {
    name: 'Rockville Centre',
    postalCode: '11570',
  },
  'rockwall-tx': {
    name: 'Rockwall',
    postalCode: '75032',
  },
  'rockwell-ia': {
    name: 'Rockwell',
    postalCode: '50469',
  },
  'rockwell-nc': {
    name: 'Rockwell',
    postalCode: '28138',
  },
  'rockwell-city-ia': {
    name: 'Rockwell City',
    postalCode: '50579',
  },
  'rockwood-il': {
    name: 'Rockwood',
    postalCode: '62280',
  },
  'rockwood-me': {
    name: 'Rockwood',
    postalCode: '04478',
  },
  'rockwood-mi': {
    name: 'Rockwood',
    postalCode: '48173',
  },
  'rockwood-pa': {
    name: 'Rockwood',
    postalCode: '15557',
  },
  'rockwood-tn': {
    name: 'Rockwood',
    postalCode: '37854',
  },
  'rockwood-tx': {
    name: 'Rockwood',
    postalCode: '76873',
  },
  'rocky-ok': {
    name: 'Rocky',
    postalCode: '73661',
  },
  'rocky-comfort-mo': {
    name: 'Rocky Comfort',
    postalCode: '64861',
  },
  'rocky-face-ga': {
    name: 'Rocky Face',
    postalCode: '30740',
  },
  'rocky-ford-co': {
    name: 'Rocky Ford',
    postalCode: '81067',
  },
  'rocky-ford-ga': {
    name: 'Rocky Ford',
    postalCode: '30455',
  },
  'rocky-gap-va': {
    name: 'Rocky Gap',
    postalCode: '24366',
  },
  'rocky-hill-ct': {
    name: 'Rocky Hill',
    postalCode: '06067',
  },
  'rocky-hill-ky': {
    name: 'Rocky Hill',
    postalCode: '42163',
  },
  'rocky-hill-nj': {
    name: 'Rocky Hill',
    postalCode: '08553',
  },
  'rocky-mount-mo': {
    name: 'Rocky Mount',
    postalCode: '65072',
  },
  'rocky-mount-nc': {
    name: 'Rocky Mount',
    postalCode: '27801',
  },
  'rocky-mount-va': {
    name: 'Rocky Mount',
    postalCode: '24151',
  },
  'rocky-point-nc': {
    name: 'Rocky Point',
    postalCode: '28457',
  },
  'rocky-point-ny': {
    name: 'Rocky Point',
    postalCode: '11778',
  },
  'rocky-ridge-md': {
    name: 'Rocky Ridge',
    postalCode: '21778',
  },
  'rocky-river-oh': {
    name: 'Rocky River',
    postalCode: '44116',
  },
  'rocky-top-tn': {
    name: 'Rocky Top',
    postalCode: '37769',
  },
  'rodeo-ca': {
    name: 'Rodeo',
    postalCode: '94572',
  },
  'rodessa-la': {
    name: 'Rodessa',
    postalCode: '71069',
  },
  'rodman-ny': {
    name: 'Rodman',
    postalCode: '13682',
  },
  'rodney-ia': {
    name: 'Rodney',
    postalCode: '51051',
  },
  'rodney-mi': {
    name: 'Rodney',
    postalCode: '49342',
  },
  'roe-ar': {
    name: 'Roe',
    postalCode: '72134',
  },
  'roebling-nj': {
    name: 'Roebling',
    postalCode: '08554',
  },
  'roebuck-sc': {
    name: 'Roebuck',
    postalCode: '29376',
  },
  'roff-ok': {
    name: 'Roff',
    postalCode: '74865',
  },
  'rogers-ar': {
    name: 'Rogers',
    postalCode: '72756',
  },
  'rogers-ky': {
    name: 'Rogers',
    postalCode: '41365',
  },
  'rogers-mn': {
    name: 'Rogers',
    postalCode: '55374',
  },
  'rogers-nd': {
    name: 'Rogers',
    postalCode: '58479',
  },
  'rogers-ne': {
    name: 'Rogers',
    postalCode: '68659',
  },
  'rogers-nm': {
    name: 'Rogers',
    postalCode: '88132',
  },
  'rogers-oh': {
    name: 'Rogers',
    postalCode: '44455',
  },
  'rogers-tx': {
    name: 'Rogers',
    postalCode: '76569',
  },
  'rogers-city-mi': {
    name: 'Rogers City',
    postalCode: '49779',
  },
  'rogerson-id': {
    name: 'Rogerson',
    postalCode: '83302',
  },
  'rogersville-al': {
    name: 'Rogersville',
    postalCode: '35652',
  },
  'rogersville-mo': {
    name: 'Rogersville',
    postalCode: '65742',
  },
  'rogersville-pa': {
    name: 'Rogersville',
    postalCode: '15359',
  },
  'rogersville-tn': {
    name: 'Rogersville',
    postalCode: '37857',
  },
  'roggen-co': {
    name: 'Roggen',
    postalCode: '80652',
  },
  'rogue-river-or': {
    name: 'Rogue River',
    postalCode: '97537',
  },
  'rohnert-park-ca': {
    name: 'Rohnert Park',
    postalCode: '94928',
  },
  'rohrersville-md': {
    name: 'Rohrersville',
    postalCode: '21779',
  },
  'roland-ar': {
    name: 'Roland',
    postalCode: '72135',
  },
  'roland-ia': {
    name: 'Roland',
    postalCode: '50236',
  },
  'roland-ok': {
    name: 'Roland',
    postalCode: '74954',
  },
  'rolesville-nc': {
    name: 'Rolesville',
    postalCode: '27571',
  },
  'rolette-nd': {
    name: 'Rolette',
    postalCode: '58366',
  },
  'rolfe-ia': {
    name: 'Rolfe',
    postalCode: '50581',
  },
  'roll-az': {
    name: 'Roll',
    postalCode: '85347',
  },
  'rolla-ks': {
    name: 'Rolla',
    postalCode: '67954',
  },
  'rolla-mo': {
    name: 'Rolla',
    postalCode: '65401',
  },
  'rolla-nd': {
    name: 'Rolla',
    postalCode: '58367',
  },
  'rolling-fork-ms': {
    name: 'Rolling Fork',
    postalCode: '39159',
  },
  'rolling-meadows-il': {
    name: 'Rolling Meadows',
    postalCode: '60008',
  },
  'rolling-prairie-in': {
    name: 'Rolling Prairie',
    postalCode: '46371',
  },
  'rollingstone-mn': {
    name: 'Rollingstone',
    postalCode: '55969',
  },
  'rollins-mt': {
    name: 'Rollins',
    postalCode: '59931',
  },
  'rollinsford-nh': {
    name: 'Rollinsford',
    postalCode: '03869',
  },
  'roma-tx': {
    name: 'Roma',
    postalCode: '78584',
  },
  'romance-ar': {
    name: 'Romance',
    postalCode: '72136',
  },
  'rome-ga': {
    name: 'Rome',
    postalCode: '30161',
  },
  'rome-in': {
    name: 'Rome',
    postalCode: '47574',
  },
  'rome-ny': {
    name: 'Rome',
    postalCode: '13440',
  },
  'rome-oh': {
    name: 'Rome',
    postalCode: '44085',
  },
  'rome-pa': {
    name: 'Rome',
    postalCode: '18837',
  },
  'rome-city-in': {
    name: 'Rome City',
    postalCode: '46784',
  },
  'romeo-mi': {
    name: 'Romeo',
    postalCode: '48065',
  },
  'romeoville-il': {
    name: 'Romeoville',
    postalCode: '60446',
  },
  'romney-in': {
    name: 'Romney',
    postalCode: '47981',
  },
  'romney-wv': {
    name: 'Romney',
    postalCode: '26757',
  },
  'romulus-mi': {
    name: 'Romulus',
    postalCode: '48174',
  },
  'romulus-ny': {
    name: 'Romulus',
    postalCode: '14541',
  },
  'ronan-mt': {
    name: 'Ronan',
    postalCode: '59864',
  },
  'ronceverte-wv': {
    name: 'Ronceverte',
    postalCode: '24970',
  },
  'ronda-nc': {
    name: 'Ronda',
    postalCode: '28670',
  },
  'ronkonkoma-ny': {
    name: 'Ronkonkoma',
    postalCode: '11779',
  },
  'ronks-pa': {
    name: 'Ronks',
    postalCode: '17572',
  },
  'roodhouse-il': {
    name: 'Roodhouse',
    postalCode: '62082',
  },
  'roopville-ga': {
    name: 'Roopville',
    postalCode: '30170',
  },
  'roosevelt-az': {
    name: 'Roosevelt',
    postalCode: '85545',
  },
  'roosevelt-mn': {
    name: 'Roosevelt',
    postalCode: '56673',
  },
  'roosevelt-ny': {
    name: 'Roosevelt',
    postalCode: '11575',
  },
  'roosevelt-ok': {
    name: 'Roosevelt',
    postalCode: '73564',
  },
  'roosevelt-tx': {
    name: 'Roosevelt',
    postalCode: '76874',
  },
  'roosevelt-ut': {
    name: 'Roosevelt',
    postalCode: '84066',
  },
  'roosevelt-wa': {
    name: 'Roosevelt',
    postalCode: '99356',
  },
  'roosevelt-roads-pr': {
    name: 'Roosevelt Roads',
    postalCode: '00742',
  },
  'rootstown-oh': {
    name: 'Rootstown',
    postalCode: '44272',
  },
  'roper-nc': {
    name: 'Roper',
    postalCode: '27970',
  },
  'ropesville-tx': {
    name: 'Ropesville',
    postalCode: '79358',
  },
  'rosalia-ks': {
    name: 'Rosalia',
    postalCode: '67132',
  },
  'rosalia-wa': {
    name: 'Rosalia',
    postalCode: '99170',
  },
  'rosalie-ne': {
    name: 'Rosalie',
    postalCode: '68055',
  },
  'rosamond-ca': {
    name: 'Rosamond',
    postalCode: '93560',
  },
  'rosamond-il': {
    name: 'Rosamond',
    postalCode: '62083',
  },
  'rosanky-tx': {
    name: 'Rosanky',
    postalCode: '78953',
  },
  'rosburg-wa': {
    name: 'Rosburg',
    postalCode: '98643',
  },
  'roscoe-il': {
    name: 'Roscoe',
    postalCode: '61073',
  },
  'roscoe-mn': {
    name: 'Roscoe',
    postalCode: '56371',
  },
  'roscoe-mt': {
    name: 'Roscoe',
    postalCode: '59071',
  },
  'roscoe-ny': {
    name: 'Roscoe',
    postalCode: '12776',
  },
  'roscoe-sd': {
    name: 'Roscoe',
    postalCode: '57471',
  },
  'roscoe-tx': {
    name: 'Roscoe',
    postalCode: '79545',
  },
  'roscommon-mi': {
    name: 'Roscommon',
    postalCode: '48653',
  },
  'rose-ok': {
    name: 'Rose',
    postalCode: '74364',
  },
  'rose-bud-ar': {
    name: 'Rose Bud',
    postalCode: '72137',
  },
  'rose-city-mi': {
    name: 'Rose City',
    postalCode: '48654',
  },
  'rose-creek-mn': {
    name: 'Rose Creek',
    postalCode: '55970',
  },
  'rose-hill-ia': {
    name: 'Rose Hill',
    postalCode: '52586',
  },
  'rose-hill-ks': {
    name: 'Rose Hill',
    postalCode: '67133',
  },
  'rose-hill-ms': {
    name: 'Rose Hill',
    postalCode: '39356',
  },
  'rose-hill-nc': {
    name: 'Rose Hill',
    postalCode: '28458',
  },
  'rose-hill-va': {
    name: 'Rose Hill',
    postalCode: '24281',
  },
  'rose-lodge-or': {
    name: 'Rose Lodge',
    postalCode: '97372',
  },
  'roseau-mn': {
    name: 'Roseau',
    postalCode: '56751',
  },
  'roseboom-ny': {
    name: 'Roseboom',
    postalCode: '13450',
  },
  'roseboro-nc': {
    name: 'Roseboro',
    postalCode: '28382',
  },
  'rosebud-mo': {
    name: 'Rosebud',
    postalCode: '63091',
  },
  'rosebud-mt': {
    name: 'Rosebud',
    postalCode: '59347',
  },
  'rosebud-tx': {
    name: 'Rosebud',
    postalCode: '76570',
  },
  'roseburg-or': {
    name: 'Roseburg',
    postalCode: '97470',
  },
  'rosebush-mi': {
    name: 'Rosebush',
    postalCode: '48878',
  },
  'rosedale-in': {
    name: 'Rosedale',
    postalCode: '47874',
  },
  'rosedale-la': {
    name: 'Rosedale',
    postalCode: '70772',
  },
  'rosedale-md': {
    name: 'Rosedale',
    postalCode: '21237',
  },
  'rosedale-ms': {
    name: 'Rosedale',
    postalCode: '38769',
  },
  'rosedale-ny': {
    name: 'Rosedale',
    postalCode: '11422',
  },
  'rosedale-va': {
    name: 'Rosedale',
    postalCode: '24280',
  },
  'rosedale-wv': {
    name: 'Rosedale',
    postalCode: '26636',
  },
  'roseglen-nd': {
    name: 'Roseglen',
    postalCode: '58775',
  },
  'roseland-la': {
    name: 'Roseland',
    postalCode: '70456',
  },
  'roseland-ne': {
    name: 'Roseland',
    postalCode: '68973',
  },
  'roseland-nj': {
    name: 'Roseland',
    postalCode: '07068',
  },
  'roseland-va': {
    name: 'Roseland',
    postalCode: '22967',
  },
  'roselle-il': {
    name: 'Roselle',
    postalCode: '60172',
  },
  'roselle-nj': {
    name: 'Roselle',
    postalCode: '07203',
  },
  'roselle-park-nj': {
    name: 'Roselle Park',
    postalCode: '07204',
  },
  'rosemead-ca': {
    name: 'Rosemead',
    postalCode: '91770',
  },
  'rosemont-nj': {
    name: 'Rosemont',
    postalCode: '08556',
  },
  'rosemount-mn': {
    name: 'Rosemount',
    postalCode: '55068',
  },
  'rosenberg-tx': {
    name: 'Rosenberg',
    postalCode: '77471',
  },
  'rosendale-mo': {
    name: 'Rosendale',
    postalCode: '64483',
  },
  'rosendale-ny': {
    name: 'Rosendale',
    postalCode: '12472',
  },
  'rosendale-wi': {
    name: 'Rosendale',
    postalCode: '54974',
  },
  'roseville-ca': {
    name: 'Roseville',
    postalCode: '95661',
  },
  'roseville-il': {
    name: 'Roseville',
    postalCode: '61473',
  },
  'roseville-mi': {
    name: 'Roseville',
    postalCode: '48066',
  },
  'roseville-oh': {
    name: 'Roseville',
    postalCode: '43777',
  },
  'rosharon-tx': {
    name: 'Rosharon',
    postalCode: '77583',
  },
  'rosholt-sd': {
    name: 'Rosholt',
    postalCode: '57260',
  },
  'rosholt-wi': {
    name: 'Rosholt',
    postalCode: '54473',
  },
  'rosiclare-il': {
    name: 'Rosiclare',
    postalCode: '62982',
  },
  'rosie-ar': {
    name: 'Rosie',
    postalCode: '72571',
  },
  'roslindale-ma': {
    name: 'Roslindale',
    postalCode: '02131',
  },
  'roslyn-ny': {
    name: 'Roslyn',
    postalCode: '11576',
  },
  'roslyn-sd': {
    name: 'Roslyn',
    postalCode: '57261',
  },
  'roslyn-heights-ny': {
    name: 'Roslyn Heights',
    postalCode: '11577',
  },
  'rosman-nc': {
    name: 'Rosman',
    postalCode: '28772',
  },
  'ross-nd': {
    name: 'Ross',
    postalCode: '58776',
  },
  'rossburg-oh': {
    name: 'Rossburg',
    postalCode: '45362',
  },
  'rossford-oh': {
    name: 'Rossford',
    postalCode: '43460',
  },
  'rossiter-pa': {
    name: 'Rossiter',
    postalCode: '15772',
  },
  'rosston-ar': {
    name: 'Rosston',
    postalCode: '71858',
  },
  'rosston-ok': {
    name: 'Rosston',
    postalCode: '73855',
  },
  'rosston-tx': {
    name: 'Rosston',
    postalCode: '76263',
  },
  'rossville-ga': {
    name: 'Rossville',
    postalCode: '30741',
  },
  'rossville-il': {
    name: 'Rossville',
    postalCode: '60963',
  },
  'rossville-in': {
    name: 'Rossville',
    postalCode: '46065',
  },
  'rossville-ks': {
    name: 'Rossville',
    postalCode: '66533',
  },
  'rossville-tn': {
    name: 'Rossville',
    postalCode: '38066',
  },
  'roswell-ga': {
    name: 'Roswell',
    postalCode: '30075',
  },
  'roswell-nm': {
    name: 'Roswell',
    postalCode: '88201',
  },
  'rotan-tx': {
    name: 'Rotan',
    postalCode: '79546',
  },
  'rothbury-mi': {
    name: 'Rothbury',
    postalCode: '49452',
  },
  'rothsay-mn': {
    name: 'Rothsay',
    postalCode: '56579',
  },
  'rothschild-wi': {
    name: 'Rothschild',
    postalCode: '54474',
  },
  'rothville-mo': {
    name: 'Rothville',
    postalCode: '64676',
  },
  'rotonda-west-fl': {
    name: 'Rotonda West',
    postalCode: '33947',
  },
  'rotterdam-junction-ny': {
    name: 'Rotterdam Junction',
    postalCode: '12150',
  },
  'rougemont-nc': {
    name: 'Rougemont',
    postalCode: '27572',
  },
  'rough-and-ready-ca': {
    name: 'Rough And Ready',
    postalCode: '95975',
  },
  'rougon-la': {
    name: 'Rougon',
    postalCode: '70773',
  },
  'roulette-pa': {
    name: 'Roulette',
    postalCode: '16746',
  },
  'round-hill-va': {
    name: 'Round Hill',
    postalCode: '20141',
  },
  'round-lake-il': {
    name: 'Round Lake',
    postalCode: '60073',
  },
  'round-lake-mn': {
    name: 'Round Lake',
    postalCode: '56167',
  },
  'round-lake-ny': {
    name: 'Round Lake',
    postalCode: '12151',
  },
  'round-mountain-nv': {
    name: 'Round Mountain',
    postalCode: '89045',
  },
  'round-mountain-tx': {
    name: 'Round Mountain',
    postalCode: '78663',
  },
  'round-o-sc': {
    name: 'Round O',
    postalCode: '29474',
  },
  'round-pond-me': {
    name: 'Round Pond',
    postalCode: '04564',
  },
  'round-rock-az': {
    name: 'Round Rock',
    postalCode: '86547',
  },
  'round-rock-tx': {
    name: 'Round Rock',
    postalCode: '78664',
  },
  'round-top-ny': {
    name: 'Round Top',
    postalCode: '12473',
  },
  'round-top-tx': {
    name: 'Round Top',
    postalCode: '78954',
  },
  'roundhill-ky': {
    name: 'Roundhill',
    postalCode: '42275',
  },
  'roundup-mt': {
    name: 'Roundup',
    postalCode: '59072',
  },
  'rouses-point-ny': {
    name: 'Rouses Point',
    postalCode: '12979',
  },
  'rousseau-ky': {
    name: 'Rousseau',
    postalCode: '41366',
  },
  'rover-ar': {
    name: 'Rover',
    postalCode: '72860',
  },
  'rowan-ia': {
    name: 'Rowan',
    postalCode: '50470',
  },
  'rowdy-ky': {
    name: 'Rowdy',
    postalCode: '41367',
  },
  'rowe-ma': {
    name: 'Rowe',
    postalCode: '01367',
  },
  'rowe-va': {
    name: 'Rowe',
    postalCode: '24646',
  },
  'rowena-sd': {
    name: 'Rowena',
    postalCode: '57056',
  },
  'rowena-tx': {
    name: 'Rowena',
    postalCode: '76875',
  },
  'rowesville-sc': {
    name: 'Rowesville',
    postalCode: '29133',
  },
  'rowland-nc': {
    name: 'Rowland',
    postalCode: '28383',
  },
  'rowland-heights-ca': {
    name: 'Rowland Heights',
    postalCode: '91748',
  },
  'rowlesburg-wv': {
    name: 'Rowlesburg',
    postalCode: '26425',
  },
  'rowlett-tx': {
    name: 'Rowlett',
    postalCode: '75088',
  },
  'rowley-ia': {
    name: 'Rowley',
    postalCode: '52329',
  },
  'rowley-ma': {
    name: 'Rowley',
    postalCode: '01969',
  },
  'roxana-il': {
    name: 'Roxana',
    postalCode: '62084',
  },
  'roxana-ky': {
    name: 'Roxana',
    postalCode: '41848',
  },
  'roxboro-nc': {
    name: 'Roxboro',
    postalCode: '27573',
  },
  'roxbury-ct': {
    name: 'Roxbury',
    postalCode: '06783',
  },
  'roxbury-ma': {
    name: 'Roxbury',
    postalCode: '02119',
  },
  'roxbury-me': {
    name: 'Roxbury',
    postalCode: '04275',
  },
  'roxbury-ny': {
    name: 'Roxbury',
    postalCode: '12474',
  },
  'roxbury-vt': {
    name: 'Roxbury',
    postalCode: '05669',
  },
  'roxbury-crossing-ma': {
    name: 'Roxbury Crossing',
    postalCode: '02120',
  },
  'roxie-ms': {
    name: 'Roxie',
    postalCode: '39661',
  },
  'roxobel-nc': {
    name: 'Roxobel',
    postalCode: '27872',
  },
  'roxton-tx': {
    name: 'Roxton',
    postalCode: '75477',
  },
  'roy-mt': {
    name: 'Roy',
    postalCode: '59471',
  },
  'roy-nm': {
    name: 'Roy',
    postalCode: '87743',
  },
  'roy-ut': {
    name: 'Roy',
    postalCode: '84067',
  },
  'roy-wa': {
    name: 'Roy',
    postalCode: '98580',
  },
  'royal-ar': {
    name: 'Royal',
    postalCode: '71968',
  },
  'royal-ia': {
    name: 'Royal',
    postalCode: '51357',
  },
  'royal-ne': {
    name: 'Royal',
    postalCode: '68773',
  },
  'royal-center-in': {
    name: 'Royal Center',
    postalCode: '46978',
  },
  'royal-city-wa': {
    name: 'Royal City',
    postalCode: '99357',
  },
  'royal-oak-md': {
    name: 'Royal Oak',
    postalCode: '21662',
  },
  'royal-oak-mi': {
    name: 'Royal Oak',
    postalCode: '48067',
  },
  'royalston-ma': {
    name: 'Royalston',
    postalCode: '01368',
  },
  'royalton-il': {
    name: 'Royalton',
    postalCode: '62983',
  },
  'royalton-ky': {
    name: 'Royalton',
    postalCode: '41464',
  },
  'royalton-mn': {
    name: 'Royalton',
    postalCode: '56373',
  },
  'royersford-pa': {
    name: 'Royersford',
    postalCode: '19468',
  },
  'royse-city-tx': {
    name: 'Royse City',
    postalCode: '75189',
  },
  'royston-ga': {
    name: 'Royston',
    postalCode: '30662',
  },
  'rozel-ks': {
    name: 'Rozel',
    postalCode: '67574',
  },
  'rozet-wy': {
    name: 'Rozet',
    postalCode: '82727',
  },
  'rubicon-wi': {
    name: 'Rubicon',
    postalCode: '53078',
  },
  'ruby-sc': {
    name: 'Ruby',
    postalCode: '29741',
  },
  'ruby-valley-nv': {
    name: 'Ruby Valley',
    postalCode: '89833',
  },
  'ruckersville-va': {
    name: 'Ruckersville',
    postalCode: '22968',
  },
  'rudd-ia': {
    name: 'Rudd',
    postalCode: '50471',
  },
  'rudolph-oh': {
    name: 'Rudolph',
    postalCode: '43462',
  },
  'rudolph-wi': {
    name: 'Rudolph',
    postalCode: '54475',
  },
  'rudy-ar': {
    name: 'Rudy',
    postalCode: '72952',
  },
  'rudyard-mi': {
    name: 'Rudyard',
    postalCode: '49780',
  },
  'rudyard-mt': {
    name: 'Rudyard',
    postalCode: '59540',
  },
  'rueter-mo': {
    name: 'Rueter',
    postalCode: '65744',
  },
  'rufe-ok': {
    name: 'Rufe',
    postalCode: '74755',
  },
  'ruffin-nc': {
    name: 'Ruffin',
    postalCode: '27326',
  },
  'ruffin-sc': {
    name: 'Ruffin',
    postalCode: '29475',
  },
  'ruffs-dale-pa': {
    name: 'Ruffs Dale',
    postalCode: '15679',
  },
  'rugby-nd': {
    name: 'Rugby',
    postalCode: '58368',
  },
  'ruidoso-nm': {
    name: 'Ruidoso',
    postalCode: '88345',
  },
  'ruidoso-downs-nm': {
    name: 'Ruidoso Downs',
    postalCode: '88346',
  },
  'rule-tx': {
    name: 'Rule',
    postalCode: '79547',
  },
  'ruleville-ms': {
    name: 'Ruleville',
    postalCode: '38771',
  },
  'rulo-ne': {
    name: 'Rulo',
    postalCode: '68431',
  },
  'rumford-me': {
    name: 'Rumford',
    postalCode: '04276',
  },
  'rumford-ri': {
    name: 'Rumford',
    postalCode: '02916',
  },
  'rumney-nh': {
    name: 'Rumney',
    postalCode: '03266',
  },
  'rumsey-ky': {
    name: 'Rumsey',
    postalCode: '42371',
  },
  'rumson-nj': {
    name: 'Rumson',
    postalCode: '07760',
  },
  'runge-tx': {
    name: 'Runge',
    postalCode: '78151',
  },
  'runnells-ia': {
    name: 'Runnells',
    postalCode: '50237',
  },
  'runnemede-nj': {
    name: 'Runnemede',
    postalCode: '08078',
  },
  'rupert-ga': {
    name: 'Rupert',
    postalCode: '31081',
  },
  'rupert-id': {
    name: 'Rupert',
    postalCode: '83350',
  },
  'rupert-wv': {
    name: 'Rupert',
    postalCode: '25984',
  },
  'rural-hall-nc': {
    name: 'Rural Hall',
    postalCode: '27045',
  },
  'rural-retreat-va': {
    name: 'Rural Retreat',
    postalCode: '24368',
  },
  'rural-valley-pa': {
    name: 'Rural Valley',
    postalCode: '16249',
  },
  'rush-co': {
    name: 'Rush',
    postalCode: '80833',
  },
  'rush-ky': {
    name: 'Rush',
    postalCode: '41168',
  },
  'rush-ny': {
    name: 'Rush',
    postalCode: '14543',
  },
  'rush-center-ks': {
    name: 'Rush Center',
    postalCode: '67575',
  },
  'rush-city-mn': {
    name: 'Rush City',
    postalCode: '55069',
  },
  'rush-hill-mo': {
    name: 'Rush Hill',
    postalCode: '65280',
  },
  'rush-springs-ok': {
    name: 'Rush Springs',
    postalCode: '73082',
  },
  'rush-valley-ut': {
    name: 'Rush Valley',
    postalCode: '84069',
  },
  'rushford-mn': {
    name: 'Rushford',
    postalCode: '55971',
  },
  'rushford-ny': {
    name: 'Rushford',
    postalCode: '14777',
  },
  'rushmore-mn': {
    name: 'Rushmore',
    postalCode: '56168',
  },
  'rushsylvania-oh': {
    name: 'Rushsylvania',
    postalCode: '43347',
  },
  'rushville-il': {
    name: 'Rushville',
    postalCode: '62681',
  },
  'rushville-in': {
    name: 'Rushville',
    postalCode: '46173',
  },
  'rushville-mo': {
    name: 'Rushville',
    postalCode: '64484',
  },
  'rushville-ne': {
    name: 'Rushville',
    postalCode: '69360',
  },
  'rushville-ny': {
    name: 'Rushville',
    postalCode: '14544',
  },
  'rushville-oh': {
    name: 'Rushville',
    postalCode: '43150',
  },
  'rusk-tx': {
    name: 'Rusk',
    postalCode: '75785',
  },
  'ruskin-fl': {
    name: 'Ruskin',
    postalCode: '33570',
  },
  'ruskin-ne': {
    name: 'Ruskin',
    postalCode: '68974',
  },
  'ruso-nd': {
    name: 'Ruso',
    postalCode: '58778',
  },
  'russell-ia': {
    name: 'Russell',
    postalCode: '50238',
  },
  'russell-ks': {
    name: 'Russell',
    postalCode: '67665',
  },
  'russell-ky': {
    name: 'Russell',
    postalCode: '41169',
  },
  'russell-ma': {
    name: 'Russell',
    postalCode: '01071',
  },
  'russell-mn': {
    name: 'Russell',
    postalCode: '56169',
  },
  'russell-ny': {
    name: 'Russell',
    postalCode: '13684',
  },
  'russell-pa': {
    name: 'Russell',
    postalCode: '16345',
  },
  'russell-springs-ky': {
    name: 'Russell Springs',
    postalCode: '42642',
  },
  'russells-point-oh': {
    name: 'Russells Point',
    postalCode: '43348',
  },
  'russellton-pa': {
    name: 'Russellton',
    postalCode: '15076',
  },
  'russellville-al': {
    name: 'Russellville',
    postalCode: '35653',
  },
  'russellville-ar': {
    name: 'Russellville',
    postalCode: '72801',
  },
  'russellville-in': {
    name: 'Russellville',
    postalCode: '46175',
  },
  'russellville-ky': {
    name: 'Russellville',
    postalCode: '42276',
  },
  'russellville-mo': {
    name: 'Russellville',
    postalCode: '65074',
  },
  'russellville-oh': {
    name: 'Russellville',
    postalCode: '45168',
  },
  'russellville-tn': {
    name: 'Russellville',
    postalCode: '37860',
  },
  'russia-oh': {
    name: 'Russia',
    postalCode: '45363',
  },
  'russiaville-in': {
    name: 'Russiaville',
    postalCode: '46979',
  },
  'rustburg-va': {
    name: 'Rustburg',
    postalCode: '24588',
  },
  'ruston-la': {
    name: 'Ruston',
    postalCode: '71270',
  },
  'ruth-mi': {
    name: 'Ruth',
    postalCode: '48470',
  },
  'ruth-ms': {
    name: 'Ruth',
    postalCode: '39662',
  },
  'ruther-glen-va': {
    name: 'Ruther Glen',
    postalCode: '22546',
  },
  'rutherford-nj': {
    name: 'Rutherford',
    postalCode: '07070',
  },
  'rutherford-tn': {
    name: 'Rutherford',
    postalCode: '38369',
  },
  'rutherfordton-nc': {
    name: 'Rutherfordton',
    postalCode: '28139',
  },
  'ruthton-mn': {
    name: 'Ruthton',
    postalCode: '56170',
  },
  'ruthven-ia': {
    name: 'Ruthven',
    postalCode: '51358',
  },
  'rutland-ia': {
    name: 'Rutland',
    postalCode: '50582',
  },
  'rutland-il': {
    name: 'Rutland',
    postalCode: '61358',
  },
  'rutland-ma': {
    name: 'Rutland',
    postalCode: '01543',
  },
  'rutland-nd': {
    name: 'Rutland',
    postalCode: '58067',
  },
  'rutland-oh': {
    name: 'Rutland',
    postalCode: '45775',
  },
  'rutland-sd': {
    name: 'Rutland',
    postalCode: '57057',
  },
  'rutland-vt': {
    name: 'Rutland',
    postalCode: '05701',
  },
  'rutledge-al': {
    name: 'Rutledge',
    postalCode: '36071',
  },
  'rutledge-ga': {
    name: 'Rutledge',
    postalCode: '30663',
  },
  'rutledge-mo': {
    name: 'Rutledge',
    postalCode: '63563',
  },
  'rutledge-tn': {
    name: 'Rutledge',
    postalCode: '37861',
  },
  'ryan-ia': {
    name: 'Ryan',
    postalCode: '52330',
  },
  'ryan-ok': {
    name: 'Ryan',
    postalCode: '73565',
  },
  'rydal-ga': {
    name: 'Rydal',
    postalCode: '30171',
  },
  'ryder-nd': {
    name: 'Ryder',
    postalCode: '58779',
  },
  'ryderwood-wa': {
    name: 'Ryderwood',
    postalCode: '98581',
  },
  'rye-co': {
    name: 'Rye',
    postalCode: '81069',
  },
  'rye-nh': {
    name: 'Rye',
    postalCode: '03870',
  },
  'rye-ny': {
    name: 'Rye',
    postalCode: '10580',
  },
  'ryegate-mt': {
    name: 'Ryegate',
    postalCode: '59074',
  },
  's-coffeyville-ok': {
    name: 'S Coffeyville',
    postalCode: '74072',
  },
  'sabael-ny': {
    name: 'Sabael',
    postalCode: '12864',
  },
  'sabana-grande-pr': {
    name: 'Sabana Grande',
    postalCode: '00637',
  },
  'sabana-hoyos-pr': {
    name: 'Sabana Hoyos',
    postalCode: '00688',
  },
  'sabana-seca-pr': {
    name: 'Sabana Seca',
    postalCode: '00952',
  },
  'sabattus-me': {
    name: 'Sabattus',
    postalCode: '04280',
  },
  'sabetha-ks': {
    name: 'Sabetha',
    postalCode: '66534',
  },
  'sabillasville-md': {
    name: 'Sabillasville',
    postalCode: '21780',
  },
  'sabin-mn': {
    name: 'Sabin',
    postalCode: '56580',
  },
  'sabina-oh': {
    name: 'Sabina',
    postalCode: '45169',
  },
  'sabinal-tx': {
    name: 'Sabinal',
    postalCode: '78881',
  },
  'sabine-pass-tx': {
    name: 'Sabine Pass',
    postalCode: '77655',
  },
  'sabinsville-pa': {
    name: 'Sabinsville',
    postalCode: '16943',
  },
  'sabula-ia': {
    name: 'Sabula',
    postalCode: '52070',
  },
  'sac-city-ia': {
    name: 'Sac City',
    postalCode: '50583',
  },
  'sachse-tx': {
    name: 'Sachse',
    postalCode: '75048',
  },
  'sackets-harbor-ny': {
    name: 'Sackets Harbor',
    postalCode: '13685',
  },
  'saco-me': {
    name: 'Saco',
    postalCode: '04072',
  },
  'saco-mt': {
    name: 'Saco',
    postalCode: '59261',
  },
  'sacramento-ca': {
    name: 'Sacramento',
    postalCode: '95811',
  },
  'sacramento-ky': {
    name: 'Sacramento',
    postalCode: '42372',
  },
  'sacramento-nm': {
    name: 'Sacramento',
    postalCode: '88347',
  },
  'sacramento-pa': {
    name: 'Sacramento',
    postalCode: '17968',
  },
  'sacred-heart-mn': {
    name: 'Sacred Heart',
    postalCode: '56285',
  },
  'saddle-brook-nj': {
    name: 'Saddle Brook',
    postalCode: '07663',
  },
  'saddle-river-nj': {
    name: 'Saddle River',
    postalCode: '07458',
  },
  'sadieville-ky': {
    name: 'Sadieville',
    postalCode: '40370',
  },
  'sadler-tx': {
    name: 'Sadler',
    postalCode: '76264',
  },
  'sadorus-il': {
    name: 'Sadorus',
    postalCode: '61872',
  },
  'saegertown-pa': {
    name: 'Saegertown',
    postalCode: '16433',
  },
  'safety-harbor-fl': {
    name: 'Safety Harbor',
    postalCode: '34695',
  },
  'saffell-ar': {
    name: 'Saffell',
    postalCode: '72572',
  },
  'safford-al': {
    name: 'Safford',
    postalCode: '36773',
  },
  'safford-az': {
    name: 'Safford',
    postalCode: '85546',
  },
  'sag-harbor-ny': {
    name: 'Sag Harbor',
    postalCode: '11963',
  },
  'sagamore-beach-ma': {
    name: 'Sagamore Beach',
    postalCode: '02562',
  },
  'sage-ar': {
    name: 'Sage',
    postalCode: '72573',
  },
  'saginaw-mi': {
    name: 'Saginaw',
    postalCode: '48601',
  },
  'saginaw-mn': {
    name: 'Saginaw',
    postalCode: '55779',
  },
  'sagle-id': {
    name: 'Sagle',
    postalCode: '83860',
  },
  'sagola-mi': {
    name: 'Sagola',
    postalCode: '49881',
  },
  'saguache-co': {
    name: 'Saguache',
    postalCode: '81149',
  },
  'sahuarita-az': {
    name: 'Sahuarita',
    postalCode: '85629',
  },
  'saint-agatha-me': {
    name: 'Saint Agatha',
    postalCode: '04772',
  },
  'saint-albans-me': {
    name: 'Saint Albans',
    postalCode: '04971',
  },
  'saint-albans-ny': {
    name: 'Saint Albans',
    postalCode: '11412',
  },
  'saint-albans-vt': {
    name: 'Saint Albans',
    postalCode: '05478',
  },
  'saint-albans-wv': {
    name: 'Saint Albans',
    postalCode: '25177',
  },
  'saint-amant-la': {
    name: 'Saint Amant',
    postalCode: '70774',
  },
  'saint-ann-mo': {
    name: 'Saint Ann',
    postalCode: '63074',
  },
  'saint-anne-il': {
    name: 'Saint Anne',
    postalCode: '60964',
  },
  'saint-ansgar-ia': {
    name: 'Saint Ansgar',
    postalCode: '50472',
  },
  'saint-anthony-ia': {
    name: 'Saint Anthony',
    postalCode: '50239',
  },
  'saint-anthony-id': {
    name: 'Saint Anthony',
    postalCode: '83445',
  },
  'saint-anthony-in': {
    name: 'Saint Anthony',
    postalCode: '47575',
  },
  'saint-anthony-nd': {
    name: 'Saint Anthony',
    postalCode: '58566',
  },
  'saint-augustine-fl': {
    name: 'Saint Augustine',
    postalCode: '32080',
  },
  'saint-augustine-il': {
    name: 'Saint Augustine',
    postalCode: '61474',
  },
  'saint-benedict-pa': {
    name: 'Saint Benedict',
    postalCode: '15773',
  },
  'saint-bernard-la': {
    name: 'Saint Bernard',
    postalCode: '70085',
  },
  'saint-bonifacius-mn': {
    name: 'Saint Bonifacius',
    postalCode: '55375',
  },
  'saint-charles-ar': {
    name: 'Saint Charles',
    postalCode: '72140',
  },
  'saint-charles-ia': {
    name: 'Saint Charles',
    postalCode: '50240',
  },
  'saint-charles-id': {
    name: 'Saint Charles',
    postalCode: '83272',
  },
  'saint-charles-il': {
    name: 'Saint Charles',
    postalCode: '60174',
  },
  'saint-charles-ky': {
    name: 'Saint Charles',
    postalCode: '42453',
  },
  'saint-charles-mi': {
    name: 'Saint Charles',
    postalCode: '48655',
  },
  'saint-charles-mn': {
    name: 'Saint Charles',
    postalCode: '55972',
  },
  'saint-charles-mo': {
    name: 'Saint Charles',
    postalCode: '63301',
  },
  'saint-charles-sd': {
    name: 'Saint Charles',
    postalCode: '57571',
  },
  'saint-charles-va': {
    name: 'Saint Charles',
    postalCode: '24282',
  },
  'saint-clair-mi': {
    name: 'Saint Clair',
    postalCode: '48079',
  },
  'saint-clair-mo': {
    name: 'Saint Clair',
    postalCode: '63077',
  },
  'saint-clair-pa': {
    name: 'Saint Clair',
    postalCode: '17970',
  },
  'saint-clair-shores-mi': {
    name: 'Saint Clair Shores',
    postalCode: '48080',
  },
  'saint-clairsville-oh': {
    name: 'Saint Clairsville',
    postalCode: '43950',
  },
  'saint-cloud-fl': {
    name: 'Saint Cloud',
    postalCode: '34769',
  },
  'saint-cloud-mn': {
    name: 'Saint Cloud',
    postalCode: '56301',
  },
  'saint-cloud-wi': {
    name: 'Saint Cloud',
    postalCode: '53079',
  },
  'saint-croix-in': {
    name: 'Saint Croix',
    postalCode: '47576',
  },
  'saint-croix-falls-wi': {
    name: 'Saint Croix Falls',
    postalCode: '54024',
  },
  'saint-david-az': {
    name: 'Saint David',
    postalCode: '85630',
  },
  'saint-david-me': {
    name: 'Saint David',
    postalCode: '04773',
  },
  'saint-edward-ne': {
    name: 'Saint Edward',
    postalCode: '68660',
  },
  'saint-elizabeth-mo': {
    name: 'Saint Elizabeth',
    postalCode: '65075',
  },
  'saint-elmo-il': {
    name: 'Saint Elmo',
    postalCode: '62458',
  },
  'saint-francis-ks': {
    name: 'Saint Francis',
    postalCode: '67756',
  },
  'saint-francis-ky': {
    name: 'Saint Francis',
    postalCode: '40062',
  },
  'saint-francis-me': {
    name: 'Saint Francis',
    postalCode: '04774',
  },
  'saint-francis-mn': {
    name: 'Saint Francis',
    postalCode: '55070',
  },
  'saint-francis-sd': {
    name: 'Saint Francis',
    postalCode: '57572',
  },
  'saint-francis-wi': {
    name: 'Saint Francis',
    postalCode: '53235',
  },
  'saint-francisville-il': {
    name: 'Saint Francisville',
    postalCode: '62460',
  },
  'saint-francisville-la': {
    name: 'Saint Francisville',
    postalCode: '70775',
  },
  'saint-gabriel-la': {
    name: 'Saint Gabriel',
    postalCode: '70776',
  },
  'saint-george-ga': {
    name: 'Saint George',
    postalCode: '31562',
  },
  'saint-george-ks': {
    name: 'Saint George',
    postalCode: '66535',
  },
  'saint-george-sc': {
    name: 'Saint George',
    postalCode: '29477',
  },
  'saint-george-ut': {
    name: 'Saint George',
    postalCode: '84770',
  },
  'saint-germain-wi': {
    name: 'Saint Germain',
    postalCode: '54558',
  },
  'saint-hedwig-tx': {
    name: 'Saint Hedwig',
    postalCode: '78152',
  },
  'saint-helen-mi': {
    name: 'Saint Helen',
    postalCode: '48656',
  },
  'saint-helena-ca': {
    name: 'Saint Helena',
    postalCode: '94574',
  },
  'saint-helena-ne': {
    name: 'Saint Helena',
    postalCode: '68774',
  },
  'saint-helena-island-sc': {
    name: 'Saint Helena Island',
    postalCode: '29920',
  },
  'saint-helens-or': {
    name: 'Saint Helens',
    postalCode: '97051',
  },
  'saint-henry-oh': {
    name: 'Saint Henry',
    postalCode: '45883',
  },
  'saint-hilaire-mn': {
    name: 'Saint Hilaire',
    postalCode: '56754',
  },
  'saint-ignace-mi': {
    name: 'Saint Ignace',
    postalCode: '49781',
  },
  'saint-ignatius-mt': {
    name: 'Saint Ignatius',
    postalCode: '59865',
  },
  'saint-inigoes-md': {
    name: 'Saint Inigoes',
    postalCode: '20684',
  },
  'saint-jacob-il': {
    name: 'Saint Jacob',
    postalCode: '62281',
  },
  'saint-james-la': {
    name: 'Saint James',
    postalCode: '70086',
  },
  'saint-james-mn': {
    name: 'Saint James',
    postalCode: '56081',
  },
  'saint-james-mo': {
    name: 'Saint James',
    postalCode: '65559',
  },
  'saint-james-ny': {
    name: 'Saint James',
    postalCode: '11780',
  },
  'saint-james-city-fl': {
    name: 'Saint James City',
    postalCode: '33956',
  },
  'saint-jo-tx': {
    name: 'Saint Jo',
    postalCode: '76265',
  },
  'saint-joe-ar': {
    name: 'Saint Joe',
    postalCode: '72675',
  },
  'saint-joe-in': {
    name: 'Saint Joe',
    postalCode: '46785',
  },
  'saint-john-in': {
    name: 'Saint John',
    postalCode: '46373',
  },
  'saint-john-nd': {
    name: 'Saint John',
    postalCode: '58369',
  },
  'saint-john-wa': {
    name: 'Saint John',
    postalCode: '99171',
  },
  'saint-johns-az': {
    name: 'Saint Johns',
    postalCode: '85936',
  },
  'saint-johns-fl': {
    name: 'Saint Johns',
    postalCode: '32259',
  },
  'saint-johns-mi': {
    name: 'Saint Johns',
    postalCode: '48879',
  },
  'saint-johnsbury-vt': {
    name: 'Saint Johnsbury',
    postalCode: '05819',
  },
  'saint-johnsville-ny': {
    name: 'Saint Johnsville',
    postalCode: '13452',
  },
  'saint-joseph-il': {
    name: 'Saint Joseph',
    postalCode: '61873',
  },
  'saint-joseph-la': {
    name: 'Saint Joseph',
    postalCode: '71366',
  },
  'saint-joseph-mi': {
    name: 'Saint Joseph',
    postalCode: '49085',
  },
  'saint-joseph-mn': {
    name: 'Saint Joseph',
    postalCode: '56374',
  },
  'saint-joseph-mo': {
    name: 'Saint Joseph',
    postalCode: '64501',
  },
  'saint-joseph-tn': {
    name: 'Saint Joseph',
    postalCode: '38481',
  },
  'saint-landry-la': {
    name: 'Saint Landry',
    postalCode: '71367',
  },
  'saint-lawrence-sd': {
    name: 'Saint Lawrence',
    postalCode: '57373',
  },
  'saint-leonard-md': {
    name: 'Saint Leonard',
    postalCode: '20685',
  },
  'saint-libory-ne': {
    name: 'Saint Libory',
    postalCode: '68872',
  },
  'saint-louis-mi': {
    name: 'Saint Louis',
    postalCode: '48880',
  },
  'saint-louis-mo': {
    name: 'Saint Louis',
    postalCode: '63101',
  },
  'saint-louisville-oh': {
    name: 'Saint Louisville',
    postalCode: '43071',
  },
  'saint-maries-id': {
    name: 'Saint Maries',
    postalCode: '83861',
  },
  'saint-martinville-la': {
    name: 'Saint Martinville',
    postalCode: '70582',
  },
  'saint-mary-mo': {
    name: 'Saint Mary',
    postalCode: '63673',
  },
  'saint-marys-ga': {
    name: 'Saint Marys',
    postalCode: '31558',
  },
  'saint-marys-ks': {
    name: 'Saint Marys',
    postalCode: '66536',
  },
  'saint-marys-oh': {
    name: 'Saint Marys',
    postalCode: '45885',
  },
  'saint-marys-pa': {
    name: 'Saint Marys',
    postalCode: '15857',
  },
  'saint-marys-wv': {
    name: 'Saint Marys',
    postalCode: '26170',
  },
  'saint-matthews-sc': {
    name: 'Saint Matthews',
    postalCode: '29135',
  },
  'saint-meinrad-in': {
    name: 'Saint Meinrad',
    postalCode: '47577',
  },
  'saint-michael-mn': {
    name: 'Saint Michael',
    postalCode: '55376',
  },
  'saint-michael-nd': {
    name: 'Saint Michael',
    postalCode: '58370',
  },
  'saint-michaels-md': {
    name: 'Saint Michaels',
    postalCode: '21663',
  },
  'saint-olaf-ia': {
    name: 'Saint Olaf',
    postalCode: '52072',
  },
  'saint-onge-sd': {
    name: 'Saint Onge',
    postalCode: '57779',
  },
  'saint-paris-oh': {
    name: 'Saint Paris',
    postalCode: '43072',
  },
  'saint-paul-ar': {
    name: 'Saint Paul',
    postalCode: '72760',
  },
  'saint-paul-ia': {
    name: 'Saint Paul',
    postalCode: '52657',
  },
  'saint-paul-in': {
    name: 'Saint Paul',
    postalCode: '47272',
  },
  'saint-paul-ks': {
    name: 'Saint Paul',
    postalCode: '66771',
  },
  'saint-paul-mn': {
    name: 'Saint Paul',
    postalCode: '55101',
  },
  'saint-paul-ne': {
    name: 'Saint Paul',
    postalCode: '68873',
  },
  'saint-paul-or': {
    name: 'Saint Paul',
    postalCode: '97137',
  },
  'saint-paul-va': {
    name: 'Saint Paul',
    postalCode: '24283',
  },
  'saint-paul-park-mn': {
    name: 'Saint Paul Park',
    postalCode: '55071',
  },
  'saint-pauls-nc': {
    name: 'Saint Pauls',
    postalCode: '28384',
  },
  'saint-peter-il': {
    name: 'Saint Peter',
    postalCode: '62880',
  },
  'saint-peter-mn': {
    name: 'Saint Peter',
    postalCode: '56082',
  },
  'saint-peters-mo': {
    name: 'Saint Peters',
    postalCode: '63376',
  },
  'saint-petersburg-fl': {
    name: 'Saint Petersburg',
    postalCode: '33701',
  },
  'saint-regis-mt': {
    name: 'Saint Regis',
    postalCode: '59866',
  },
  'saint-regis-falls-ny': {
    name: 'Saint Regis Falls',
    postalCode: '12980',
  },
  'saint-robert-mo': {
    name: 'Saint Robert',
    postalCode: '65584',
  },
  'saint-rose-la': {
    name: 'Saint Rose',
    postalCode: '70087',
  },
  'saint-simons-island-ga': {
    name: 'Saint Simons Island',
    postalCode: '31522',
  },
  'saint-stephen-mn': {
    name: 'Saint Stephen',
    postalCode: '56375',
  },
  'saint-stephen-sc': {
    name: 'Saint Stephen',
    postalCode: '29479',
  },
  'saint-stephens-al': {
    name: 'Saint Stephens',
    postalCode: '36569',
  },
  'saint-stephens-church-va': {
    name: 'Saint Stephens Church',
    postalCode: '23148',
  },
  'saint-thomas-mo': {
    name: 'Saint Thomas',
    postalCode: '65076',
  },
  'saint-thomas-nd': {
    name: 'Saint Thomas',
    postalCode: '58276',
  },
  'saint-thomas-pa': {
    name: 'Saint Thomas',
    postalCode: '17252',
  },
  'saint-vincent-mn': {
    name: 'Saint Vincent',
    postalCode: '56755',
  },
  'saint-vrain-nm': {
    name: 'Saint Vrain',
    postalCode: '88133',
  },
  'saint-xavier-mt': {
    name: 'Saint Xavier',
    postalCode: '59075',
  },
  'sainte-genevieve-mo': {
    name: 'Sainte Genevieve',
    postalCode: '63670',
  },
  'salado-tx': {
    name: 'Salado',
    postalCode: '76571',
  },
  'salamanca-ny': {
    name: 'Salamanca',
    postalCode: '14779',
  },
  'salamonia-in': {
    name: 'Salamonia',
    postalCode: '47381',
  },
  'salcha-ak': {
    name: 'Salcha',
    postalCode: '99714',
  },
  'sale-city-ga': {
    name: 'Sale City',
    postalCode: '31784',
  },
  'sale-creek-tn': {
    name: 'Sale Creek',
    postalCode: '37373',
  },
  'salem-al': {
    name: 'Salem',
    postalCode: '36874',
  },
  'salem-ar': {
    name: 'Salem',
    postalCode: '72576',
  },
  'salem-ct': {
    name: 'Salem',
    postalCode: '06420',
  },
  'salem-ia': {
    name: 'Salem',
    postalCode: '52649',
  },
  'salem-il': {
    name: 'Salem',
    postalCode: '62881',
  },
  'salem-in': {
    name: 'Salem',
    postalCode: '47167',
  },
  'salem-ky': {
    name: 'Salem',
    postalCode: '42078',
  },
  'salem-ma': {
    name: 'Salem',
    postalCode: '01970',
  },
  'salem-mo': {
    name: 'Salem',
    postalCode: '65560',
  },
  'salem-ne': {
    name: 'Salem',
    postalCode: '68433',
  },
  'salem-nh': {
    name: 'Salem',
    postalCode: '03079',
  },
  'salem-nj': {
    name: 'Salem',
    postalCode: '08079',
  },
  'salem-nm': {
    name: 'Salem',
    postalCode: '87941',
  },
  'salem-ny': {
    name: 'Salem',
    postalCode: '12865',
  },
  'salem-oh': {
    name: 'Salem',
    postalCode: '44460',
  },
  'salem-or': {
    name: 'Salem',
    postalCode: '97301',
  },
  'salem-sc': {
    name: 'Salem',
    postalCode: '29676',
  },
  'salem-sd': {
    name: 'Salem',
    postalCode: '57058',
  },
  'salem-ut': {
    name: 'Salem',
    postalCode: '84653',
  },
  'salem-va': {
    name: 'Salem',
    postalCode: '24153',
  },
  'salem-wi': {
    name: 'Salem',
    postalCode: '53168',
  },
  'salem-wv': {
    name: 'Salem',
    postalCode: '26426',
  },
  'salemburg-nc': {
    name: 'Salemburg',
    postalCode: '28385',
  },
  'salesville-oh': {
    name: 'Salesville',
    postalCode: '43778',
  },
  'salida-ca': {
    name: 'Salida',
    postalCode: '95368',
  },
  'salida-co': {
    name: 'Salida',
    postalCode: '81201',
  },
  'salina-ks': {
    name: 'Salina',
    postalCode: '67401',
  },
  'salina-ok': {
    name: 'Salina',
    postalCode: '74365',
  },
  'salina-ut': {
    name: 'Salina',
    postalCode: '84654',
  },
  'salinas-ca': {
    name: 'Salinas',
    postalCode: '93901',
  },
  'salinas-pr': {
    name: 'Salinas',
    postalCode: '00751',
  },
  'saline-la': {
    name: 'Saline',
    postalCode: '71070',
  },
  'saline-mi': {
    name: 'Saline',
    postalCode: '48176',
  },
  'salineville-oh': {
    name: 'Salineville',
    postalCode: '43945',
  },
  'salisbury-ct': {
    name: 'Salisbury',
    postalCode: '06068',
  },
  'salisbury-ma': {
    name: 'Salisbury',
    postalCode: '01952',
  },
  'salisbury-md': {
    name: 'Salisbury',
    postalCode: '21801',
  },
  'salisbury-mo': {
    name: 'Salisbury',
    postalCode: '65281',
  },
  'salisbury-nc': {
    name: 'Salisbury',
    postalCode: '28144',
  },
  'salisbury-nh': {
    name: 'Salisbury',
    postalCode: '03268',
  },
  'salisbury-pa': {
    name: 'Salisbury',
    postalCode: '15558',
  },
  'salisbury-vt': {
    name: 'Salisbury',
    postalCode: '05769',
  },
  'salisbury-center-ny': {
    name: 'Salisbury Center',
    postalCode: '13454',
  },
  'salisbury-mills-ny': {
    name: 'Salisbury Mills',
    postalCode: '12577',
  },
  'salix-ia': {
    name: 'Salix',
    postalCode: '51052',
  },
  'salix-pa': {
    name: 'Salix',
    postalCode: '15952',
  },
  'salkum-wa': {
    name: 'Salkum',
    postalCode: '98582',
  },
  'salley-sc': {
    name: 'Salley',
    postalCode: '29137',
  },
  'sallis-ms': {
    name: 'Sallis',
    postalCode: '39160',
  },
  'sallisaw-ok': {
    name: 'Sallisaw',
    postalCode: '74955',
  },
  'salmon-id': {
    name: 'Salmon',
    postalCode: '83467',
  },
  'salol-mn': {
    name: 'Salol',
    postalCode: '56756',
  },
  'salome-az': {
    name: 'Salome',
    postalCode: '85348',
  },
  'salt-flat-tx': {
    name: 'Salt Flat',
    postalCode: '79847',
  },
  'salt-lake-city-ut': {
    name: 'Salt Lake City',
    postalCode: '84101',
  },
  'salt-lick-ky': {
    name: 'Salt Lick',
    postalCode: '40371',
  },
  'salt-point-ny': {
    name: 'Salt Point',
    postalCode: '12578',
  },
  'salt-rock-wv': {
    name: 'Salt Rock',
    postalCode: '25559',
  },
  'salters-sc': {
    name: 'Salters',
    postalCode: '29590',
  },
  'saltese-mt': {
    name: 'Saltese',
    postalCode: '59867',
  },
  'saltillo-ms': {
    name: 'Saltillo',
    postalCode: '38866',
  },
  'saltillo-tn': {
    name: 'Saltillo',
    postalCode: '38370',
  },
  'saltillo-tx': {
    name: 'Saltillo',
    postalCode: '75478',
  },
  'saltsburg-pa': {
    name: 'Saltsburg',
    postalCode: '15681',
  },
  'saltville-va': {
    name: 'Saltville',
    postalCode: '24370',
  },
  'saluda-nc': {
    name: 'Saluda',
    postalCode: '28773',
  },
  'saluda-sc': {
    name: 'Saluda',
    postalCode: '29138',
  },
  'saluda-va': {
    name: 'Saluda',
    postalCode: '23149',
  },
  'salvisa-ky': {
    name: 'Salvisa',
    postalCode: '40372',
  },
  'salyersville-ky': {
    name: 'Salyersville',
    postalCode: '41465',
  },
  'sammamish-wa': {
    name: 'Sammamish',
    postalCode: '98074',
  },
  'samoa-ca': {
    name: 'Samoa',
    postalCode: '95564',
  },
  'samson-al': {
    name: 'Samson',
    postalCode: '36477',
  },
  'san-acacia-nm': {
    name: 'San Acacia',
    postalCode: '87831',
  },
  'san-andreas-ca': {
    name: 'San Andreas',
    postalCode: '95249',
  },
  'san-angelo-tx': {
    name: 'San Angelo',
    postalCode: '76901',
  },
  'san-anselmo-ca': {
    name: 'San Anselmo',
    postalCode: '94960',
  },
  'san-antonio-fl': {
    name: 'San Antonio',
    postalCode: '33576',
  },
  'san-antonio-nm': {
    name: 'San Antonio',
    postalCode: '87832',
  },
  'san-antonio-pr': {
    name: 'San Antonio',
    postalCode: '00690',
  },
  'san-antonio-tx': {
    name: 'San Antonio',
    postalCode: '78201',
  },
  'san-ardo-ca': {
    name: 'San Ardo',
    postalCode: '93450',
  },
  'san-augustine-tx': {
    name: 'San Augustine',
    postalCode: '75972',
  },
  'san-benito-tx': {
    name: 'San Benito',
    postalCode: '78586',
  },
  'san-bernardino-ca': {
    name: 'San Bernardino',
    postalCode: '92401',
  },
  'san-bruno-ca': {
    name: 'San Bruno',
    postalCode: '94066',
  },
  'san-carlos-ca': {
    name: 'San Carlos',
    postalCode: '94070',
  },
  'san-clemente-ca': {
    name: 'San Clemente',
    postalCode: '92672',
  },
  'san-diego-ca': {
    name: 'San Diego',
    postalCode: '92101',
  },
  'san-diego-tx': {
    name: 'San Diego',
    postalCode: '78384',
  },
  'san-dimas-ca': {
    name: 'San Dimas',
    postalCode: '91773',
  },
  'san-elizario-tx': {
    name: 'San Elizario',
    postalCode: '79849',
  },
  'san-fernando-ca': {
    name: 'San Fernando',
    postalCode: '91340',
  },
  'san-francisco-ca': {
    name: 'San Francisco',
    postalCode: '94101',
  },
  'san-gabriel-ca': {
    name: 'San Gabriel',
    postalCode: '91775',
  },
  'san-german-pr': {
    name: 'San German',
    postalCode: '00683',
  },
  'san-gregorio-ca': {
    name: 'San Gregorio',
    postalCode: '94074',
  },
  'san-isidro-tx': {
    name: 'San Isidro',
    postalCode: '78588',
  },
  'san-jacinto-ca': {
    name: 'San Jacinto',
    postalCode: '92582',
  },
  'san-joaquin-ca': {
    name: 'San Joaquin',
    postalCode: '93660',
  },
  'san-jon-nm': {
    name: 'San Jon',
    postalCode: '88434',
  },
  'san-jose-ca': {
    name: 'San Jose',
    postalCode: '95101',
  },
  'san-jose-il': {
    name: 'San Jose',
    postalCode: '62682',
  },
  'san-jose-nm': {
    name: 'San Jose',
    postalCode: '87565',
  },
  'san-juan-pr': {
    name: 'San Juan',
    postalCode: '00901',
  },
  'san-juan-tx': {
    name: 'San Juan',
    postalCode: '78589',
  },
  'san-juan-bautista-ca': {
    name: 'San Juan Bautista',
    postalCode: '95045',
  },
  'san-juan-capistrano-ca': {
    name: 'San Juan Capistrano',
    postalCode: '92675',
  },
  'san-leandro-ca': {
    name: 'San Leandro',
    postalCode: '94577',
  },
  'san-lorenzo-ca': {
    name: 'San Lorenzo',
    postalCode: '94580',
  },
  'san-lorenzo-pr': {
    name: 'San Lorenzo',
    postalCode: '00754',
  },
  'san-luis-co': {
    name: 'San Luis',
    postalCode: '81134',
  },
  'san-luis-obispo-ca': {
    name: 'San Luis Obispo',
    postalCode: '93401',
  },
  'san-manuel-az': {
    name: 'San Manuel',
    postalCode: '85631',
  },
  'san-marcos-ca': {
    name: 'San Marcos',
    postalCode: '92069',
  },
  'san-marcos-tx': {
    name: 'San Marcos',
    postalCode: '78666',
  },
  'san-marino-ca': {
    name: 'San Marino',
    postalCode: '91108',
  },
  'san-martin-ca': {
    name: 'San Martin',
    postalCode: '95046',
  },
  'san-mateo-ca': {
    name: 'San Mateo',
    postalCode: '94401',
  },
  'san-mateo-fl': {
    name: 'San Mateo',
    postalCode: '32187',
  },
  'san-miguel-ca': {
    name: 'San Miguel',
    postalCode: '93451',
  },
  'san-pablo-ca': {
    name: 'San Pablo',
    postalCode: '94806',
  },
  'san-patricio-nm': {
    name: 'San Patricio',
    postalCode: '88348',
  },
  'san-pedro-ca': {
    name: 'San Pedro',
    postalCode: '90731',
  },
  'san-pierre-in': {
    name: 'San Pierre',
    postalCode: '46374',
  },
  'san-quentin-ca': {
    name: 'San Quentin',
    postalCode: '94974',
  },
  'san-rafael-ca': {
    name: 'San Rafael',
    postalCode: '94901',
  },
  'san-ramon-ca': {
    name: 'San Ramon',
    postalCode: '94582',
  },
  'san-saba-tx': {
    name: 'San Saba',
    postalCode: '76877',
  },
  'san-sebastian-pr': {
    name: 'San Sebastian',
    postalCode: '00685',
  },
  'san-simeon-ca': {
    name: 'San Simeon',
    postalCode: '93452',
  },
  'san-simon-az': {
    name: 'San Simon',
    postalCode: '85632',
  },
  'san-tan-valley-az': {
    name: 'San Tan Valley',
    postalCode: '85140',
  },
  'san-ygnacio-tx': {
    name: 'San Ygnacio',
    postalCode: '78067',
  },
  'san-ysidro-ca': {
    name: 'San Ysidro',
    postalCode: '92173',
  },
  'san-ysidro-nm': {
    name: 'San Ysidro',
    postalCode: '87053',
  },
  'sanborn-ia': {
    name: 'Sanborn',
    postalCode: '51248',
  },
  'sanborn-mn': {
    name: 'Sanborn',
    postalCode: '56083',
  },
  'sanborn-nd': {
    name: 'Sanborn',
    postalCode: '58480',
  },
  'sanborn-ny': {
    name: 'Sanborn',
    postalCode: '14132',
  },
  'sanbornton-nh': {
    name: 'Sanbornton',
    postalCode: '03269',
  },
  'sanbornville-nh': {
    name: 'Sanbornville',
    postalCode: '03872',
  },
  'sand-coulee-mt': {
    name: 'Sand Coulee',
    postalCode: '59472',
  },
  'sand-creek-mi': {
    name: 'Sand Creek',
    postalCode: '49279',
  },
  'sand-fork-wv': {
    name: 'Sand Fork',
    postalCode: '26430',
  },
  'sand-lake-mi': {
    name: 'Sand Lake',
    postalCode: '49343',
  },
  'sand-lake-ny': {
    name: 'Sand Lake',
    postalCode: '12153',
  },
  'sand-springs-mt': {
    name: 'Sand Springs',
    postalCode: '59077',
  },
  'sand-springs-ok': {
    name: 'Sand Springs',
    postalCode: '74063',
  },
  'sandborn-in': {
    name: 'Sandborn',
    postalCode: '47578',
  },
  'sanders-ky': {
    name: 'Sanders',
    postalCode: '41083',
  },
  'sanders-mt': {
    name: 'Sanders',
    postalCode: '59076',
  },
  'sanderson-fl': {
    name: 'Sanderson',
    postalCode: '32087',
  },
  'sandersville-ga': {
    name: 'Sandersville',
    postalCode: '31082',
  },
  'sandgap-ky': {
    name: 'Sandgap',
    postalCode: '40481',
  },
  'sandia-tx': {
    name: 'Sandia',
    postalCode: '78383',
  },
  'sandia-park-nm': {
    name: 'Sandia Park',
    postalCode: '87047',
  },
  'sandisfield-ma': {
    name: 'Sandisfield',
    postalCode: '01255',
  },
  'sandoval-il': {
    name: 'Sandoval',
    postalCode: '62882',
  },
  'sandown-nh': {
    name: 'Sandown',
    postalCode: '03873',
  },
  'sandpoint-id': {
    name: 'Sandpoint',
    postalCode: '83864',
  },
  'sandston-va': {
    name: 'Sandston',
    postalCode: '23150',
  },
  'sandstone-mn': {
    name: 'Sandstone',
    postalCode: '55072',
  },
  'sandstone-wv': {
    name: 'Sandstone',
    postalCode: '25985',
  },
  'sandusky-mi': {
    name: 'Sandusky',
    postalCode: '48471',
  },
  'sandusky-oh': {
    name: 'Sandusky',
    postalCode: '44870',
  },
  'sandwich-il': {
    name: 'Sandwich',
    postalCode: '60548',
  },
  'sandwich-ma': {
    name: 'Sandwich',
    postalCode: '02563',
  },
  'sandy-or': {
    name: 'Sandy',
    postalCode: '97055',
  },
  'sandy-ut': {
    name: 'Sandy',
    postalCode: '84070',
  },
  'sandy-creek-ny': {
    name: 'Sandy Creek',
    postalCode: '13145',
  },
  'sandy-hook-ct': {
    name: 'Sandy Hook',
    postalCode: '06482',
  },
  'sandy-hook-ky': {
    name: 'Sandy Hook',
    postalCode: '41171',
  },
  'sandy-hook-ms': {
    name: 'Sandy Hook',
    postalCode: '39478',
  },
  'sandy-hook-va': {
    name: 'Sandy Hook',
    postalCode: '23153',
  },
  'sandy-lake-pa': {
    name: 'Sandy Lake',
    postalCode: '16145',
  },
  'sandy-level-va': {
    name: 'Sandy Level',
    postalCode: '24161',
  },
  'sandy-ridge-nc': {
    name: 'Sandy Ridge',
    postalCode: '27046',
  },
  'sandy-spring-md': {
    name: 'Sandy Spring',
    postalCode: '20860',
  },
  'sandyville-wv': {
    name: 'Sandyville',
    postalCode: '25275',
  },
  'sanford-co': {
    name: 'Sanford',
    postalCode: '81151',
  },
  'sanford-fl': {
    name: 'Sanford',
    postalCode: '32771',
  },
  'sanford-me': {
    name: 'Sanford',
    postalCode: '04073',
  },
  'sanford-mi': {
    name: 'Sanford',
    postalCode: '48657',
  },
  'sanford-nc': {
    name: 'Sanford',
    postalCode: '27330',
  },
  'sanford-va': {
    name: 'Sanford',
    postalCode: '23426',
  },
  'sanger-ca': {
    name: 'Sanger',
    postalCode: '93657',
  },
  'sanger-tx': {
    name: 'Sanger',
    postalCode: '76266',
  },
  'sangerville-me': {
    name: 'Sangerville',
    postalCode: '04479',
  },
  'sanibel-fl': {
    name: 'Sanibel',
    postalCode: '33957',
  },
  'santa-ana-ca': {
    name: 'Santa Ana',
    postalCode: '92701',
  },
  'santa-anna-tx': {
    name: 'Santa Anna',
    postalCode: '76878',
  },
  'santa-barbara-ca': {
    name: 'Santa Barbara',
    postalCode: '93101',
  },
  'santa-clara-ca': {
    name: 'Santa Clara',
    postalCode: '95050',
  },
  'santa-clara-ut': {
    name: 'Santa Clara',
    postalCode: '84765',
  },
  'santa-clarita-ca': {
    name: 'Santa Clarita',
    postalCode: '91350',
  },
  'santa-claus-in': {
    name: 'Santa Claus',
    postalCode: '47579',
  },
  'santa-cruz-ca': {
    name: 'Santa Cruz',
    postalCode: '95060',
  },
  'santa-cruz-nm': {
    name: 'Santa Cruz',
    postalCode: '87567',
  },
  'santa-elena-tx': {
    name: 'Santa Elena',
    postalCode: '78591',
  },
  'santa-fe-mo': {
    name: 'Santa Fe',
    postalCode: '65282',
  },
  'santa-fe-nm': {
    name: 'Santa Fe',
    postalCode: '87501',
  },
  'santa-fe-tn': {
    name: 'Santa Fe',
    postalCode: '38482',
  },
  'santa-fe-tx': {
    name: 'Santa Fe',
    postalCode: '77510',
  },
  'santa-fe-springs-ca': {
    name: 'Santa Fe Springs',
    postalCode: '90670',
  },
  'santa-isabel-pr': {
    name: 'Santa Isabel',
    postalCode: '00757',
  },
  'santa-margarita-ca': {
    name: 'Santa Margarita',
    postalCode: '93453',
  },
  'santa-maria-ca': {
    name: 'Santa Maria',
    postalCode: '93454',
  },
  'santa-monica-ca': {
    name: 'Santa Monica',
    postalCode: '90401',
  },
  'santa-paula-ca': {
    name: 'Santa Paula',
    postalCode: '93060',
  },
  'santa-rita-gu': {
    name: 'Santa Rita',
    postalCode: '96915',
  },
  'santa-rosa-ca': {
    name: 'Santa Rosa',
    postalCode: '95401',
  },
  'santa-rosa-nm': {
    name: 'Santa Rosa',
    postalCode: '88435',
  },
  'santa-rosa-tx': {
    name: 'Santa Rosa',
    postalCode: '78593',
  },
  'santa-rosa-beach-fl': {
    name: 'Santa Rosa Beach',
    postalCode: '32459',
  },
  'santa-teresa-nm': {
    name: 'Santa Teresa',
    postalCode: '88008',
  },
  'santa-ynez-ca': {
    name: 'Santa Ynez',
    postalCode: '93460',
  },
  'santa-ysabel-ca': {
    name: 'Santa Ysabel',
    postalCode: '92070',
  },
  'santaquin-ut': {
    name: 'Santaquin',
    postalCode: '84655',
  },
  'santee-ca': {
    name: 'Santee',
    postalCode: '92071',
  },
  'santee-sc': {
    name: 'Santee',
    postalCode: '29142',
  },
  'santo-tx': {
    name: 'Santo',
    postalCode: '76472',
  },
  'santo-domingo-pueblo-nm': {
    name: 'Santo Domingo Pueblo',
    postalCode: '87052',
  },
  'sapello-nm': {
    name: 'Sapello',
    postalCode: '87745',
  },
  'sapphire-nc': {
    name: 'Sapphire',
    postalCode: '28774',
  },
  'sapulpa-ok': {
    name: 'Sapulpa',
    postalCode: '74066',
  },
  'sarah-ms': {
    name: 'Sarah',
    postalCode: '38665',
  },
  'sarahsville-oh': {
    name: 'Sarahsville',
    postalCode: '43779',
  },
  'saraland-al': {
    name: 'Saraland',
    postalCode: '36571',
  },
  'saranac-mi': {
    name: 'Saranac',
    postalCode: '48881',
  },
  'saranac-ny': {
    name: 'Saranac',
    postalCode: '12981',
  },
  'saranac-lake-ny': {
    name: 'Saranac Lake',
    postalCode: '12983',
  },
  'sarasota-fl': {
    name: 'Sarasota',
    postalCode: '34231',
  },
  'saratoga-ar': {
    name: 'Saratoga',
    postalCode: '71859',
  },
  'saratoga-ca': {
    name: 'Saratoga',
    postalCode: '95070',
  },
  'saratoga-tx': {
    name: 'Saratoga',
    postalCode: '77585',
  },
  'saratoga-wy': {
    name: 'Saratoga',
    postalCode: '82331',
  },
  'saratoga-springs-ny': {
    name: 'Saratoga Springs',
    postalCode: '12866',
  },
  'saratoga-springs-ut': {
    name: 'Saratoga Springs',
    postalCode: '84045',
  },
  'sarcoxie-mo': {
    name: 'Sarcoxie',
    postalCode: '64862',
  },
  'sardinia-ny': {
    name: 'Sardinia',
    postalCode: '14134',
  },
  'sardinia-oh': {
    name: 'Sardinia',
    postalCode: '45171',
  },
  'sardis-al': {
    name: 'Sardis',
    postalCode: '36775',
  },
  'sardis-ga': {
    name: 'Sardis',
    postalCode: '30456',
  },
  'sardis-ms': {
    name: 'Sardis',
    postalCode: '38666',
  },
  'sardis-oh': {
    name: 'Sardis',
    postalCode: '43946',
  },
  'sardis-tn': {
    name: 'Sardis',
    postalCode: '38371',
  },
  'sarepta-la': {
    name: 'Sarepta',
    postalCode: '71071',
  },
  'sargeant-mn': {
    name: 'Sargeant',
    postalCode: '55973',
  },
  'sargent-ne': {
    name: 'Sargent',
    postalCode: '68874',
  },
  'sargentville-me': {
    name: 'Sargentville',
    postalCode: '04673',
  },
  'sarles-nd': {
    name: 'Sarles',
    postalCode: '58372',
  },
  'sarona-wi': {
    name: 'Sarona',
    postalCode: '54870',
  },
  'saronville-ne': {
    name: 'Saronville',
    postalCode: '68975',
  },
  'sartell-mn': {
    name: 'Sartell',
    postalCode: '56377',
  },
  'sarver-pa': {
    name: 'Sarver',
    postalCode: '16055',
  },
  'sasakwa-ok': {
    name: 'Sasakwa',
    postalCode: '74867',
  },
  'sassafras-ky': {
    name: 'Sassafras',
    postalCode: '41759',
  },
  'satanta-ks': {
    name: 'Satanta',
    postalCode: '67870',
  },
  'satartia-ms': {
    name: 'Satartia',
    postalCode: '39162',
  },
  'satellite-beach-fl': {
    name: 'Satellite Beach',
    postalCode: '32937',
  },
  'satin-tx': {
    name: 'Satin',
    postalCode: '76685',
  },
  'satsuma-al': {
    name: 'Satsuma',
    postalCode: '36572',
  },
  'satsuma-fl': {
    name: 'Satsuma',
    postalCode: '32189',
  },
  'saucier-ms': {
    name: 'Saucier',
    postalCode: '39574',
  },
  'saugatuck-mi': {
    name: 'Saugatuck',
    postalCode: '49453',
  },
  'saugerties-ny': {
    name: 'Saugerties',
    postalCode: '12477',
  },
  'saugus-ma': {
    name: 'Saugus',
    postalCode: '01906',
  },
  'sauk-centre-mn': {
    name: 'Sauk Centre',
    postalCode: '56378',
  },
  'sauk-city-wi': {
    name: 'Sauk City',
    postalCode: '53583',
  },
  'sauk-rapids-mn': {
    name: 'Sauk Rapids',
    postalCode: '56379',
  },
  'saukville-wi': {
    name: 'Saukville',
    postalCode: '53080',
  },
  'saulsbury-tn': {
    name: 'Saulsbury',
    postalCode: '38067',
  },
  'saulsville-wv': {
    name: 'Saulsville',
    postalCode: '25876',
  },
  'sault-sainte-marie-mi': {
    name: 'Sault Sainte Marie',
    postalCode: '49783',
  },
  'saunderstown-ri': {
    name: 'Saunderstown',
    postalCode: '02874',
  },
  'saunemin-il': {
    name: 'Saunemin',
    postalCode: '61769',
  },
  'sauquoit-ny': {
    name: 'Sauquoit',
    postalCode: '13456',
  },
  'sausalito-ca': {
    name: 'Sausalito',
    postalCode: '94965',
  },
  'sautee-nacoochee-ga': {
    name: 'Sautee Nacoochee',
    postalCode: '30571',
  },
  'savage-md': {
    name: 'Savage',
    postalCode: '20763',
  },
  'savage-mn': {
    name: 'Savage',
    postalCode: '55378',
  },
  'savage-mt': {
    name: 'Savage',
    postalCode: '59262',
  },
  'savanna-il': {
    name: 'Savanna',
    postalCode: '61074',
  },
  'savannah-ga': {
    name: 'Savannah',
    postalCode: '31401',
  },
  'savannah-mo': {
    name: 'Savannah',
    postalCode: '64485',
  },
  'savannah-ny': {
    name: 'Savannah',
    postalCode: '13146',
  },
  'savannah-tn': {
    name: 'Savannah',
    postalCode: '38372',
  },
  'savery-wy': {
    name: 'Savery',
    postalCode: '82332',
  },
  'savona-ny': {
    name: 'Savona',
    postalCode: '14879',
  },
  'savonburg-ks': {
    name: 'Savonburg',
    postalCode: '66772',
  },
  'savoy-il': {
    name: 'Savoy',
    postalCode: '61874',
  },
  'savoy-ma': {
    name: 'Savoy',
    postalCode: '01256',
  },
  'savoy-tx': {
    name: 'Savoy',
    postalCode: '75479',
  },
  'sawyer-ks': {
    name: 'Sawyer',
    postalCode: '67134',
  },
  'sawyer-mi': {
    name: 'Sawyer',
    postalCode: '49125',
  },
  'sawyer-mn': {
    name: 'Sawyer',
    postalCode: '55780',
  },
  'sawyer-nd': {
    name: 'Sawyer',
    postalCode: '58781',
  },
  'sawyer-ok': {
    name: 'Sawyer',
    postalCode: '74756',
  },
  'sawyerville-al': {
    name: 'Sawyerville',
    postalCode: '36776',
  },
  'saxe-va': {
    name: 'Saxe',
    postalCode: '23967',
  },
  'saxon-wi': {
    name: 'Saxon',
    postalCode: '54559',
  },
  'saxon-wv': {
    name: 'Saxon',
    postalCode: '25180',
  },
  'saxonburg-pa': {
    name: 'Saxonburg',
    postalCode: '16056',
  },
  'saxton-pa': {
    name: 'Saxton',
    postalCode: '16678',
  },
  'saxtons-river-vt': {
    name: 'Saxtons River',
    postalCode: '05154',
  },
  'saybrook-il': {
    name: 'Saybrook',
    postalCode: '61770',
  },
  'saylorsburg-pa': {
    name: 'Saylorsburg',
    postalCode: '18353',
  },
  'sayner-wi': {
    name: 'Sayner',
    postalCode: '54560',
  },
  'sayre-ok': {
    name: 'Sayre',
    postalCode: '73662',
  },
  'sayre-pa': {
    name: 'Sayre',
    postalCode: '18840',
  },
  'sayreville-nj': {
    name: 'Sayreville',
    postalCode: '08872',
  },
  'sayville-ny': {
    name: 'Sayville',
    postalCode: '11782',
  },
  'scales-mound-il': {
    name: 'Scales Mound',
    postalCode: '61075',
  },
  'scalf-ky': {
    name: 'Scalf',
    postalCode: '40982',
  },
  'scaly-mountain-nc': {
    name: 'Scaly Mountain',
    postalCode: '28775',
  },
  'scammon-ks': {
    name: 'Scammon',
    postalCode: '66773',
  },
  'scandia-ks': {
    name: 'Scandia',
    postalCode: '66966',
  },
  'scandia-mn': {
    name: 'Scandia',
    postalCode: '55073',
  },
  'scandinavia-wi': {
    name: 'Scandinavia',
    postalCode: '54977',
  },
  'scappoose-or': {
    name: 'Scappoose',
    postalCode: '97056',
  },
  'scarborough-me': {
    name: 'Scarborough',
    postalCode: '04074',
  },
  'scarbro-wv': {
    name: 'Scarbro',
    postalCode: '25917',
  },
  'scarsdale-ny': {
    name: 'Scarsdale',
    postalCode: '10583',
  },
  'scarville-ia': {
    name: 'Scarville',
    postalCode: '50473',
  },
  'scenery-hill-pa': {
    name: 'Scenery Hill',
    postalCode: '15360',
  },
  'scenic-sd': {
    name: 'Scenic',
    postalCode: '57780',
  },
  'schaghticoke-ny': {
    name: 'Schaghticoke',
    postalCode: '12154',
  },
  'schaller-ia': {
    name: 'Schaller',
    postalCode: '51053',
  },
  'schaumburg-il': {
    name: 'Schaumburg',
    postalCode: '60173',
  },
  'schell-city-mo': {
    name: 'Schell City',
    postalCode: '64783',
  },
  'scheller-il': {
    name: 'Scheller',
    postalCode: '62883',
  },
  'schellsburg-pa': {
    name: 'Schellsburg',
    postalCode: '15559',
  },
  'schenectady-ny': {
    name: 'Schenectady',
    postalCode: '12302',
  },
  'schenevus-ny': {
    name: 'Schenevus',
    postalCode: '12155',
  },
  'schererville-in': {
    name: 'Schererville',
    postalCode: '46375',
  },
  'schertz-tx': {
    name: 'Schertz',
    postalCode: '78154',
  },
  'schiller-park-il': {
    name: 'Schiller Park',
    postalCode: '60176',
  },
  'schlater-ms': {
    name: 'Schlater',
    postalCode: '38952',
  },
  'schleswig-ia': {
    name: 'Schleswig',
    postalCode: '51461',
  },
  'schnecksville-pa': {
    name: 'Schnecksville',
    postalCode: '18078',
  },
  'schnellville-in': {
    name: 'Schnellville',
    postalCode: '47580',
  },
  'schodack-landing-ny': {
    name: 'Schodack Landing',
    postalCode: '12156',
  },
  'schofield-wi': {
    name: 'Schofield',
    postalCode: '54476',
  },
  'schofield-barracks-hi': {
    name: 'Schofield Barracks',
    postalCode: '96857',
  },
  'schoharie-ny': {
    name: 'Schoharie',
    postalCode: '12157',
  },
  'schoolcraft-mi': {
    name: 'Schoolcraft',
    postalCode: '49087',
  },
  'schriever-la': {
    name: 'Schriever',
    postalCode: '70395',
  },
  'schroeder-mn': {
    name: 'Schroeder',
    postalCode: '55613',
  },
  'schroon-lake-ny': {
    name: 'Schroon Lake',
    postalCode: '12870',
  },
  'schulenburg-tx': {
    name: 'Schulenburg',
    postalCode: '78956',
  },
  'schuyler-ne': {
    name: 'Schuyler',
    postalCode: '68661',
  },
  'schuyler-va': {
    name: 'Schuyler',
    postalCode: '22969',
  },
  'schuyler-falls-ny': {
    name: 'Schuyler Falls',
    postalCode: '12985',
  },
  'schuylerville-ny': {
    name: 'Schuylerville',
    postalCode: '12871',
  },
  'schuylkill-haven-pa': {
    name: 'Schuylkill Haven',
    postalCode: '17972',
  },
  'schwenksville-pa': {
    name: 'Schwenksville',
    postalCode: '19473',
  },
  'science-hill-ky': {
    name: 'Science Hill',
    postalCode: '42553',
  },
  'scio-ny': {
    name: 'Scio',
    postalCode: '14880',
  },
  'scio-oh': {
    name: 'Scio',
    postalCode: '43988',
  },
  'scio-or': {
    name: 'Scio',
    postalCode: '97374',
  },
  'sciota-il': {
    name: 'Sciota',
    postalCode: '61475',
  },
  'sciota-pa': {
    name: 'Sciota',
    postalCode: '18354',
  },
  'scipio-in': {
    name: 'Scipio',
    postalCode: '47273',
  },
  'scipio-center-ny': {
    name: 'Scipio Center',
    postalCode: '13147',
  },
  'scituate-ma': {
    name: 'Scituate',
    postalCode: '02066',
  },
  'scobey-ms': {
    name: 'Scobey',
    postalCode: '38953',
  },
  'scobey-mt': {
    name: 'Scobey',
    postalCode: '59263',
  },
  'scooba-ms': {
    name: 'Scooba',
    postalCode: '39358',
  },
  'scotch-plains-nj': {
    name: 'Scotch Plains',
    postalCode: '07076',
  },
  'scotia-ca': {
    name: 'Scotia',
    postalCode: '95565',
  },
  'scotia-ne': {
    name: 'Scotia',
    postalCode: '68875',
  },
  'scotland-ar': {
    name: 'Scotland',
    postalCode: '72141',
  },
  'scotland-ct': {
    name: 'Scotland',
    postalCode: '06264',
  },
  'scotland-in': {
    name: 'Scotland',
    postalCode: '47457',
  },
  'scotland-md': {
    name: 'Scotland',
    postalCode: '20687',
  },
  'scotland-sd': {
    name: 'Scotland',
    postalCode: '57059',
  },
  'scotland-tx': {
    name: 'Scotland',
    postalCode: '76379',
  },
  'scotland-neck-nc': {
    name: 'Scotland Neck',
    postalCode: '27874',
  },
  'scotrun-pa': {
    name: 'Scotrun',
    postalCode: '18355',
  },
  'scott-ar': {
    name: 'Scott',
    postalCode: '72142',
  },
  'scott-la': {
    name: 'Scott',
    postalCode: '70583',
  },
  'scott-oh': {
    name: 'Scott',
    postalCode: '45886',
  },
  'scott-air-force-base-il': {
    name: 'Scott Air Force Base',
    postalCode: '62225',
  },
  'scott-bar-ca': {
    name: 'Scott Bar',
    postalCode: '96085',
  },
  'scott-city-ks': {
    name: 'Scott City',
    postalCode: '67871',
  },
  'scott-city-mo': {
    name: 'Scott City',
    postalCode: '63780',
  },
  'scott-depot-wv': {
    name: 'Scott Depot',
    postalCode: '25560',
  },
  'scottdale-ga': {
    name: 'Scottdale',
    postalCode: '30079',
  },
  'scottdale-pa': {
    name: 'Scottdale',
    postalCode: '15683',
  },
  'scottown-oh': {
    name: 'Scottown',
    postalCode: '45678',
  },
  'scotts-mi': {
    name: 'Scotts',
    postalCode: '49088',
  },
  'scotts-hill-tn': {
    name: 'Scotts Hill',
    postalCode: '38374',
  },
  'scotts-mills-or': {
    name: 'Scotts Mills',
    postalCode: '97375',
  },
  'scotts-valley-ca': {
    name: 'Scotts Valley',
    postalCode: '95066',
  },
  'scottsbluff-ne': {
    name: 'Scottsbluff',
    postalCode: '69361',
  },
  'scottsboro-al': {
    name: 'Scottsboro',
    postalCode: '35768',
  },
  'scottsburg-in': {
    name: 'Scottsburg',
    postalCode: '47170',
  },
  'scottsburg-ny': {
    name: 'Scottsburg',
    postalCode: '14545',
  },
  'scottsburg-or': {
    name: 'Scottsburg',
    postalCode: '97473',
  },
  'scottsburg-va': {
    name: 'Scottsburg',
    postalCode: '24589',
  },
  'scottsdale-az': {
    name: 'Scottsdale',
    postalCode: '85250',
  },
  'scottsville-ky': {
    name: 'Scottsville',
    postalCode: '42164',
  },
  'scottsville-ny': {
    name: 'Scottsville',
    postalCode: '14546',
  },
  'scottsville-va': {
    name: 'Scottsville',
    postalCode: '24590',
  },
  'scottville-mi': {
    name: 'Scottville',
    postalCode: '49454',
  },
  'scottville-nc': {
    name: 'Scottville',
    postalCode: '28672',
  },
  'scranton-ar': {
    name: 'Scranton',
    postalCode: '72863',
  },
  'scranton-ia': {
    name: 'Scranton',
    postalCode: '51462',
  },
  'scranton-ks': {
    name: 'Scranton',
    postalCode: '66537',
  },
  'scranton-nc': {
    name: 'Scranton',
    postalCode: '27875',
  },
  'scranton-nd': {
    name: 'Scranton',
    postalCode: '58653',
  },
  'scranton-pa': {
    name: 'Scranton',
    postalCode: '18503',
  },
  'scranton-sc': {
    name: 'Scranton',
    postalCode: '29591',
  },
  'screven-ga': {
    name: 'Screven',
    postalCode: '31560',
  },
  'scribner-ne': {
    name: 'Scribner',
    postalCode: '68057',
  },
  'scroggins-tx': {
    name: 'Scroggins',
    postalCode: '75480',
  },
  'scuddy-ky': {
    name: 'Scuddy',
    postalCode: '41760',
  },
  'scurry-tx': {
    name: 'Scurry',
    postalCode: '75158',
  },
  'sea-cliff-ny': {
    name: 'Sea Cliff',
    postalCode: '11579',
  },
  'sea-girt-nj': {
    name: 'Sea Girt',
    postalCode: '08750',
  },
  'sea-isle-city-nj': {
    name: 'Sea Isle City',
    postalCode: '08243',
  },
  'seabeck-wa': {
    name: 'Seabeck',
    postalCode: '98380',
  },
  'seaboard-nc': {
    name: 'Seaboard',
    postalCode: '27876',
  },
  'seabrook-nh': {
    name: 'Seabrook',
    postalCode: '03874',
  },
  'seabrook-sc': {
    name: 'Seabrook',
    postalCode: '29940',
  },
  'seabrook-tx': {
    name: 'Seabrook',
    postalCode: '77586',
  },
  'seadrift-tx': {
    name: 'Seadrift',
    postalCode: '77983',
  },
  'seaford-de': {
    name: 'Seaford',
    postalCode: '19973',
  },
  'seaford-ny': {
    name: 'Seaford',
    postalCode: '11783',
  },
  'seaford-va': {
    name: 'Seaford',
    postalCode: '23696',
  },
  'seaforth-mn': {
    name: 'Seaforth',
    postalCode: '56287',
  },
  'seagoville-tx': {
    name: 'Seagoville',
    postalCode: '75159',
  },
  'seagraves-tx': {
    name: 'Seagraves',
    postalCode: '79359',
  },
  'seagrove-nc': {
    name: 'Seagrove',
    postalCode: '27341',
  },
  'seal-beach-ca': {
    name: 'Seal Beach',
    postalCode: '90740',
  },
  'seal-cove-me': {
    name: 'Seal Cove',
    postalCode: '04674',
  },
  'seal-rock-or': {
    name: 'Seal Rock',
    postalCode: '97376',
  },
  'seale-al': {
    name: 'Seale',
    postalCode: '36875',
  },
  'sealevel-nc': {
    name: 'Sealevel',
    postalCode: '28577',
  },
  'sealy-tx': {
    name: 'Sealy',
    postalCode: '77474',
  },
  'seaman-oh': {
    name: 'Seaman',
    postalCode: '45679',
  },
  'seanor-pa': {
    name: 'Seanor',
    postalCode: '15953',
  },
  'searchlight-nv': {
    name: 'Searchlight',
    postalCode: '89046',
  },
  'searcy-ar': {
    name: 'Searcy',
    postalCode: '72143',
  },
  'sears-mi': {
    name: 'Sears',
    postalCode: '49679',
  },
  'searsboro-ia': {
    name: 'Searsboro',
    postalCode: '50242',
  },
  'searsmont-me': {
    name: 'Searsmont',
    postalCode: '04973',
  },
  'searsport-me': {
    name: 'Searsport',
    postalCode: '04974',
  },
  'seaside-ca': {
    name: 'Seaside',
    postalCode: '93955',
  },
  'seaside-or': {
    name: 'Seaside',
    postalCode: '97138',
  },
  'seaside-heights-nj': {
    name: 'Seaside Heights',
    postalCode: '08751',
  },
  'seaside-park-nj': {
    name: 'Seaside Park',
    postalCode: '08752',
  },
  'seaton-il': {
    name: 'Seaton',
    postalCode: '61476',
  },
  'seattle-wa': {
    name: 'Seattle',
    postalCode: '98101',
  },
  'sebago-me': {
    name: 'Sebago',
    postalCode: '04029',
  },
  'sebago-lake-me': {
    name: 'Sebago Lake',
    postalCode: '04075',
  },
  'sebastian-fl': {
    name: 'Sebastian',
    postalCode: '32958',
  },
  'sebastopol-ca': {
    name: 'Sebastopol',
    postalCode: '95472',
  },
  'sebec-me': {
    name: 'Sebec',
    postalCode: '04481',
  },
  'sebeka-mn': {
    name: 'Sebeka',
    postalCode: '56477',
  },
  'sebewaing-mi': {
    name: 'Sebewaing',
    postalCode: '48759',
  },
  'sebree-ky': {
    name: 'Sebree',
    postalCode: '42455',
  },
  'sebring-fl': {
    name: 'Sebring',
    postalCode: '33870',
  },
  'sebring-oh': {
    name: 'Sebring',
    postalCode: '44672',
  },
  'secaucus-nj': {
    name: 'Secaucus',
    postalCode: '07094',
  },
  'secondcreek-wv': {
    name: 'Secondcreek',
    postalCode: '24974',
  },
  'secor-il': {
    name: 'Secor',
    postalCode: '61771',
  },
  'section-al': {
    name: 'Section',
    postalCode: '35771',
  },
  'sedalia-co': {
    name: 'Sedalia',
    postalCode: '80135',
  },
  'sedalia-ky': {
    name: 'Sedalia',
    postalCode: '42079',
  },
  'sedalia-mo': {
    name: 'Sedalia',
    postalCode: '65301',
  },
  'sedan-ks': {
    name: 'Sedan',
    postalCode: '67361',
  },
  'sedan-nm': {
    name: 'Sedan',
    postalCode: '88436',
  },
  'sedgewickville-mo': {
    name: 'Sedgewickville',
    postalCode: '63781',
  },
  'sedgwick-co': {
    name: 'Sedgwick',
    postalCode: '80749',
  },
  'sedgwick-ks': {
    name: 'Sedgwick',
    postalCode: '67135',
  },
  'sedgwick-me': {
    name: 'Sedgwick',
    postalCode: '04676',
  },
  'sedley-va': {
    name: 'Sedley',
    postalCode: '23878',
  },
  'sedona-az': {
    name: 'Sedona',
    postalCode: '86336',
  },
  'sedro-woolley-wa': {
    name: 'Sedro Woolley',
    postalCode: '98284',
  },
  'seekonk-ma': {
    name: 'Seekonk',
    postalCode: '02771',
  },
  'seeley-lake-mt': {
    name: 'Seeley Lake',
    postalCode: '59868',
  },
  'seffner-fl': {
    name: 'Seffner',
    postalCode: '33584',
  },
  'seguin-tx': {
    name: 'Seguin',
    postalCode: '78155',
  },
  'seiad-valley-ca': {
    name: 'Seiad Valley',
    postalCode: '96086',
  },
  'seibert-co': {
    name: 'Seibert',
    postalCode: '80834',
  },
  'seiling-ok': {
    name: 'Seiling',
    postalCode: '73663',
  },
  'sekiu-wa': {
    name: 'Sekiu',
    postalCode: '98381',
  },
  'selah-wa': {
    name: 'Selah',
    postalCode: '98942',
  },
  'selby-sd': {
    name: 'Selby',
    postalCode: '57472',
  },
  'selbyville-de': {
    name: 'Selbyville',
    postalCode: '19975',
  },
  'selbyville-wv': {
    name: 'Selbyville',
    postalCode: '26236',
  },
  'selden-ks': {
    name: 'Selden',
    postalCode: '67757',
  },
  'selden-ny': {
    name: 'Selden',
    postalCode: '11784',
  },
  'selfridge-nd': {
    name: 'Selfridge',
    postalCode: '58568',
  },
  'seligman-mo': {
    name: 'Seligman',
    postalCode: '65745',
  },
  'selinsgrove-pa': {
    name: 'Selinsgrove',
    postalCode: '17870',
  },
  'selkirk-ny': {
    name: 'Selkirk',
    postalCode: '12158',
  },
  'sellers-sc': {
    name: 'Sellers',
    postalCode: '29592',
  },
  'sellersburg-in': {
    name: 'Sellersburg',
    postalCode: '47172',
  },
  'sellersville-pa': {
    name: 'Sellersville',
    postalCode: '18960',
  },
  'sells-az': {
    name: 'Sells',
    postalCode: '85634',
  },
  'selma-al': {
    name: 'Selma',
    postalCode: '36701',
  },
  'selma-ca': {
    name: 'Selma',
    postalCode: '93662',
  },
  'selma-ia': {
    name: 'Selma',
    postalCode: '52588',
  },
  'selma-in': {
    name: 'Selma',
    postalCode: '47383',
  },
  'selma-nc': {
    name: 'Selma',
    postalCode: '27576',
  },
  'selma-or': {
    name: 'Selma',
    postalCode: '97538',
  },
  'selmer-tn': {
    name: 'Selmer',
    postalCode: '38375',
  },
  'seminary-ms': {
    name: 'Seminary',
    postalCode: '39479',
  },
  'seminole-al': {
    name: 'Seminole',
    postalCode: '36574',
  },
  'seminole-fl': {
    name: 'Seminole',
    postalCode: '33772',
  },
  'seminole-ok': {
    name: 'Seminole',
    postalCode: '74868',
  },
  'seminole-pa': {
    name: 'Seminole',
    postalCode: '16253',
  },
  'seminole-tx': {
    name: 'Seminole',
    postalCode: '79360',
  },
  'semmes-al': {
    name: 'Semmes',
    postalCode: '36575',
  },
  'semora-nc': {
    name: 'Semora',
    postalCode: '27343',
  },
  'senath-mo': {
    name: 'Senath',
    postalCode: '63876',
  },
  'senatobia-ms': {
    name: 'Senatobia',
    postalCode: '38668',
  },
  'seneca-il': {
    name: 'Seneca',
    postalCode: '61360',
  },
  'seneca-ks': {
    name: 'Seneca',
    postalCode: '66538',
  },
  'seneca-mo': {
    name: 'Seneca',
    postalCode: '64865',
  },
  'seneca-ne': {
    name: 'Seneca',
    postalCode: '69161',
  },
  'seneca-or': {
    name: 'Seneca',
    postalCode: '97873',
  },
  'seneca-pa': {
    name: 'Seneca',
    postalCode: '16346',
  },
  'seneca-sc': {
    name: 'Seneca',
    postalCode: '29672',
  },
  'seneca-sd': {
    name: 'Seneca',
    postalCode: '57473',
  },
  'seneca-falls-ny': {
    name: 'Seneca Falls',
    postalCode: '13148',
  },
  'seneca-rocks-wv': {
    name: 'Seneca Rocks',
    postalCode: '26884',
  },
  'senecaville-oh': {
    name: 'Senecaville',
    postalCode: '43780',
  },
  'seney-mi': {
    name: 'Seney',
    postalCode: '49883',
  },
  'senoia-ga': {
    name: 'Senoia',
    postalCode: '30276',
  },
  'sentinel-ok': {
    name: 'Sentinel',
    postalCode: '73664',
  },
  'sentinel-butte-nd': {
    name: 'Sentinel Butte',
    postalCode: '58654',
  },
  'sequatchie-tn': {
    name: 'Sequatchie',
    postalCode: '37374',
  },
  'sequim-wa': {
    name: 'Sequim',
    postalCode: '98382',
  },
  'sequoia-national-park-ca': {
    name: 'Sequoia National Park',
    postalCode: '93262',
  },
  'serena-il': {
    name: 'Serena',
    postalCode: '60549',
  },
  'sergeant-bluff-ia': {
    name: 'Sergeant Bluff',
    postalCode: '51054',
  },
  'sesser-il': {
    name: 'Sesser',
    postalCode: '62884',
  },
  'seth-wv': {
    name: 'Seth',
    postalCode: '25181',
  },
  'seven-springs-nc': {
    name: 'Seven Springs',
    postalCode: '28578',
  },
  'seven-valleys-pa': {
    name: 'Seven Valleys',
    postalCode: '17360',
  },
  'severn-md': {
    name: 'Severn',
    postalCode: '21144',
  },
  'severna-park-md': {
    name: 'Severna Park',
    postalCode: '21146',
  },
  'severy-ks': {
    name: 'Severy',
    postalCode: '67137',
  },
  'sevier-ut': {
    name: 'Sevier',
    postalCode: '84766',
  },
  'sevierville-tn': {
    name: 'Sevierville',
    postalCode: '37862',
  },
  'seville-fl': {
    name: 'Seville',
    postalCode: '32190',
  },
  'seville-oh': {
    name: 'Seville',
    postalCode: '44273',
  },
  'sewanee-tn': {
    name: 'Sewanee',
    postalCode: '37375',
  },
  'seward-ak': {
    name: 'Seward',
    postalCode: '99664',
  },
  'seward-ne': {
    name: 'Seward',
    postalCode: '68434',
  },
  'seward-pa': {
    name: 'Seward',
    postalCode: '15954',
  },
  'sewaren-nj': {
    name: 'Sewaren',
    postalCode: '07077',
  },
  'sewell-nj': {
    name: 'Sewell',
    postalCode: '08080',
  },
  'sewickley-pa': {
    name: 'Sewickley',
    postalCode: '15143',
  },
  'sextons-creek-ky': {
    name: 'Sextons Creek',
    postalCode: '40983',
  },
  'seymour-ct': {
    name: 'Seymour',
    postalCode: '06483',
  },
  'seymour-ia': {
    name: 'Seymour',
    postalCode: '52590',
  },
  'seymour-il': {
    name: 'Seymour',
    postalCode: '61875',
  },
  'seymour-in': {
    name: 'Seymour',
    postalCode: '47274',
  },
  'seymour-mo': {
    name: 'Seymour',
    postalCode: '65746',
  },
  'seymour-tn': {
    name: 'Seymour',
    postalCode: '37865',
  },
  'seymour-tx': {
    name: 'Seymour',
    postalCode: '76380',
  },
  'seymour-wi': {
    name: 'Seymour',
    postalCode: '54165',
  },
  'shabbona-il': {
    name: 'Shabbona',
    postalCode: '60550',
  },
  'shacklefords-va': {
    name: 'Shacklefords',
    postalCode: '23156',
  },
  'shade-oh': {
    name: 'Shade',
    postalCode: '45776',
  },
  'shade-gap-pa': {
    name: 'Shade Gap',
    postalCode: '17255',
  },
  'shady-cove-or': {
    name: 'Shady Cove',
    postalCode: '97539',
  },
  'shady-dale-ga': {
    name: 'Shady Dale',
    postalCode: '31085',
  },
  'shady-point-ok': {
    name: 'Shady Point',
    postalCode: '74956',
  },
  'shady-side-md': {
    name: 'Shady Side',
    postalCode: '20764',
  },
  'shady-spring-wv': {
    name: 'Shady Spring',
    postalCode: '25918',
  },
  'shady-valley-tn': {
    name: 'Shady Valley',
    postalCode: '37688',
  },
  'shadyside-oh': {
    name: 'Shadyside',
    postalCode: '43947',
  },
  'shafer-mn': {
    name: 'Shafer',
    postalCode: '55074',
  },
  'shafter-ca': {
    name: 'Shafter',
    postalCode: '93263',
  },
  'shaftsbury-vt': {
    name: 'Shaftsbury',
    postalCode: '05262',
  },
  'shakopee-mn': {
    name: 'Shakopee',
    postalCode: '55379',
  },
  'shalimar-fl': {
    name: 'Shalimar',
    postalCode: '32579',
  },
  'shallotte-nc': {
    name: 'Shallotte',
    postalCode: '28470',
  },
  'shallowater-tx': {
    name: 'Shallowater',
    postalCode: '79363',
  },
  'shamokin-pa': {
    name: 'Shamokin',
    postalCode: '17872',
  },
  'shamokin-dam-pa': {
    name: 'Shamokin Dam',
    postalCode: '17876',
  },
  'shamrock-tx': {
    name: 'Shamrock',
    postalCode: '79079',
  },
  'shandaken-ny': {
    name: 'Shandaken',
    postalCode: '12480',
  },
  'shandon-ca': {
    name: 'Shandon',
    postalCode: '93461',
  },
  'shanks-wv': {
    name: 'Shanks',
    postalCode: '26761',
  },
  'shannock-ri': {
    name: 'Shannock',
    postalCode: '02875',
  },
  'shannon-il': {
    name: 'Shannon',
    postalCode: '61078',
  },
  'shannon-ms': {
    name: 'Shannon',
    postalCode: '38868',
  },
  'shannon-nc': {
    name: 'Shannon',
    postalCode: '28386',
  },
  'shannon-city-ia': {
    name: 'Shannon City',
    postalCode: '50861',
  },
  'shapleigh-me': {
    name: 'Shapleigh',
    postalCode: '04076',
  },
  'sharon-ct': {
    name: 'Sharon',
    postalCode: '06069',
  },
  'sharon-ks': {
    name: 'Sharon',
    postalCode: '67138',
  },
  'sharon-ma': {
    name: 'Sharon',
    postalCode: '02067',
  },
  'sharon-nd': {
    name: 'Sharon',
    postalCode: '58277',
  },
  'sharon-ok': {
    name: 'Sharon',
    postalCode: '73857',
  },
  'sharon-pa': {
    name: 'Sharon',
    postalCode: '16146',
  },
  'sharon-sc': {
    name: 'Sharon',
    postalCode: '29742',
  },
  'sharon-tn': {
    name: 'Sharon',
    postalCode: '38255',
  },
  'sharon-vt': {
    name: 'Sharon',
    postalCode: '05065',
  },
  'sharon-wi': {
    name: 'Sharon',
    postalCode: '53585',
  },
  'sharon-grove-ky': {
    name: 'Sharon Grove',
    postalCode: '42280',
  },
  'sharon-hill-pa': {
    name: 'Sharon Hill',
    postalCode: '19079',
  },
  'sharon-springs-ks': {
    name: 'Sharon Springs',
    postalCode: '67758',
  },
  'sharon-springs-ny': {
    name: 'Sharon Springs',
    postalCode: '13459',
  },
  'sharps-chapel-tn': {
    name: 'Sharps Chapel',
    postalCode: '37866',
  },
  'sharpsburg-ga': {
    name: 'Sharpsburg',
    postalCode: '30277',
  },
  'sharpsburg-ia': {
    name: 'Sharpsburg',
    postalCode: '50862',
  },
  'sharpsburg-ky': {
    name: 'Sharpsburg',
    postalCode: '40374',
  },
  'sharpsburg-md': {
    name: 'Sharpsburg',
    postalCode: '21782',
  },
  'sharpsville-in': {
    name: 'Sharpsville',
    postalCode: '46068',
  },
  'sharpsville-pa': {
    name: 'Sharpsville',
    postalCode: '16150',
  },
  'shasta-lake-ca': {
    name: 'Shasta Lake',
    postalCode: '96019',
  },
  'shattuck-ok': {
    name: 'Shattuck',
    postalCode: '73858',
  },
  'shaver-lake-ca': {
    name: 'Shaver Lake',
    postalCode: '93664',
  },
  'shavertown-pa': {
    name: 'Shavertown',
    postalCode: '18708',
  },
  'shaw-ms': {
    name: 'Shaw',
    postalCode: '38773',
  },
  'shaw-afb-sc': {
    name: 'Shaw AFB',
    postalCode: '29152',
  },
  'shawano-wi': {
    name: 'Shawano',
    postalCode: '54166',
  },
  'shawboro-nc': {
    name: 'Shawboro',
    postalCode: '27973',
  },
  'shawmut-mt': {
    name: 'Shawmut',
    postalCode: '59078',
  },
  'shawnee-ks': {
    name: 'Shawnee',
    postalCode: '66203',
  },
  'shawnee-oh': {
    name: 'Shawnee',
    postalCode: '43782',
  },
  'shawnee-ok': {
    name: 'Shawnee',
    postalCode: '74801',
  },
  'shawnee-wy': {
    name: 'Shawnee',
    postalCode: '82229',
  },
  'shawneetown-il': {
    name: 'Shawneetown',
    postalCode: '62984',
  },
  'shawsville-va': {
    name: 'Shawsville',
    postalCode: '24162',
  },
  'sheboygan-wi': {
    name: 'Sheboygan',
    postalCode: '53081',
  },
  'sheboygan-falls-wi': {
    name: 'Sheboygan Falls',
    postalCode: '53085',
  },
  'shedd-or': {
    name: 'Shedd',
    postalCode: '97377',
  },
  'sheffield-al': {
    name: 'Sheffield',
    postalCode: '35660',
  },
  'sheffield-ia': {
    name: 'Sheffield',
    postalCode: '50475',
  },
  'sheffield-il': {
    name: 'Sheffield',
    postalCode: '61361',
  },
  'sheffield-ma': {
    name: 'Sheffield',
    postalCode: '01257',
  },
  'sheffield-pa': {
    name: 'Sheffield',
    postalCode: '16347',
  },
  'sheffield-vt': {
    name: 'Sheffield',
    postalCode: '05866',
  },
  'sheffield-lake-oh': {
    name: 'Sheffield Lake',
    postalCode: '44054',
  },
  'shelbiana-ky': {
    name: 'Shelbiana',
    postalCode: '41562',
  },
  'shelbina-mo': {
    name: 'Shelbina',
    postalCode: '63468',
  },
  'shelburn-in': {
    name: 'Shelburn',
    postalCode: '47879',
  },
  'shelburne-vt': {
    name: 'Shelburne',
    postalCode: '05482',
  },
  'shelburne-falls-ma': {
    name: 'Shelburne Falls',
    postalCode: '01370',
  },
  'shelby-al': {
    name: 'Shelby',
    postalCode: '35143',
  },
  'shelby-ia': {
    name: 'Shelby',
    postalCode: '51570',
  },
  'shelby-mi': {
    name: 'Shelby',
    postalCode: '49455',
  },
  'shelby-ms': {
    name: 'Shelby',
    postalCode: '38774',
  },
  'shelby-mt': {
    name: 'Shelby',
    postalCode: '59474',
  },
  'shelby-nc': {
    name: 'Shelby',
    postalCode: '28150',
  },
  'shelby-ne': {
    name: 'Shelby',
    postalCode: '68662',
  },
  'shelby-oh': {
    name: 'Shelby',
    postalCode: '44875',
  },
  'shelby-gap-ky': {
    name: 'Shelby Gap',
    postalCode: '41563',
  },
  'shelbyville-il': {
    name: 'Shelbyville',
    postalCode: '62565',
  },
  'shelbyville-in': {
    name: 'Shelbyville',
    postalCode: '46176',
  },
  'shelbyville-ky': {
    name: 'Shelbyville',
    postalCode: '40065',
  },
  'shelbyville-mi': {
    name: 'Shelbyville',
    postalCode: '49344',
  },
  'shelbyville-mo': {
    name: 'Shelbyville',
    postalCode: '63469',
  },
  'shelbyville-tn': {
    name: 'Shelbyville',
    postalCode: '37160',
  },
  'shelbyville-tx': {
    name: 'Shelbyville',
    postalCode: '75973',
  },
  'sheldon-ia': {
    name: 'Sheldon',
    postalCode: '51201',
  },
  'sheldon-il': {
    name: 'Sheldon',
    postalCode: '60966',
  },
  'sheldon-mo': {
    name: 'Sheldon',
    postalCode: '64784',
  },
  'sheldon-nd': {
    name: 'Sheldon',
    postalCode: '58068',
  },
  'sheldon-sc': {
    name: 'Sheldon',
    postalCode: '29941',
  },
  'sheldon-vt': {
    name: 'Sheldon',
    postalCode: '05483',
  },
  'sheldon-wi': {
    name: 'Sheldon',
    postalCode: '54766',
  },
  'shell-wy': {
    name: 'Shell',
    postalCode: '82441',
  },
  'shell-knob-mo': {
    name: 'Shell Knob',
    postalCode: '65747',
  },
  'shell-lake-wi': {
    name: 'Shell Lake',
    postalCode: '54871',
  },
  'shell-rock-ia': {
    name: 'Shell Rock',
    postalCode: '50670',
  },
  'shelley-id': {
    name: 'Shelley',
    postalCode: '83274',
  },
  'shellman-ga': {
    name: 'Shellman',
    postalCode: '39886',
  },
  'shellsburg-ia': {
    name: 'Shellsburg',
    postalCode: '52332',
  },
  'shelly-mn': {
    name: 'Shelly',
    postalCode: '56581',
  },
  'shelocta-pa': {
    name: 'Shelocta',
    postalCode: '15774',
  },
  'shelton-ct': {
    name: 'Shelton',
    postalCode: '06484',
  },
  'shelton-ne': {
    name: 'Shelton',
    postalCode: '68876',
  },
  'shelton-wa': {
    name: 'Shelton',
    postalCode: '98584',
  },
  'shenandoah-ia': {
    name: 'Shenandoah',
    postalCode: '51601',
  },
  'shenandoah-pa': {
    name: 'Shenandoah',
    postalCode: '17976',
  },
  'shenandoah-va': {
    name: 'Shenandoah',
    postalCode: '22849',
  },
  'shenandoah-junction-wv': {
    name: 'Shenandoah Junction',
    postalCode: '25442',
  },
  'shepherd-mi': {
    name: 'Shepherd',
    postalCode: '48883',
  },
  'shepherd-mt': {
    name: 'Shepherd',
    postalCode: '59079',
  },
  'shepherd-tx': {
    name: 'Shepherd',
    postalCode: '77371',
  },
  'shepherdstown-wv': {
    name: 'Shepherdstown',
    postalCode: '25443',
  },
  'shepherdsville-ky': {
    name: 'Shepherdsville',
    postalCode: '40165',
  },
  'sheppard-afb-tx': {
    name: 'Sheppard AFB',
    postalCode: '76311',
  },
  'sherborn-ma': {
    name: 'Sherborn',
    postalCode: '01770',
  },
  'sherburn-mn': {
    name: 'Sherburn',
    postalCode: '56171',
  },
  'sherburne-ny': {
    name: 'Sherburne',
    postalCode: '13460',
  },
  'sheridan-ar': {
    name: 'Sheridan',
    postalCode: '72150',
  },
  'sheridan-ca': {
    name: 'Sheridan',
    postalCode: '95681',
  },
  'sheridan-il': {
    name: 'Sheridan',
    postalCode: '60551',
  },
  'sheridan-in': {
    name: 'Sheridan',
    postalCode: '46069',
  },
  'sheridan-mi': {
    name: 'Sheridan',
    postalCode: '48884',
  },
  'sheridan-mo': {
    name: 'Sheridan',
    postalCode: '64486',
  },
  'sheridan-mt': {
    name: 'Sheridan',
    postalCode: '59749',
  },
  'sheridan-or': {
    name: 'Sheridan',
    postalCode: '97378',
  },
  'sheridan-wy': {
    name: 'Sheridan',
    postalCode: '82801',
  },
  'sheridan-lake-co': {
    name: 'Sheridan Lake',
    postalCode: '81071',
  },
  'sherman-ct': {
    name: 'Sherman',
    postalCode: '06784',
  },
  'sherman-il': {
    name: 'Sherman',
    postalCode: '62684',
  },
  'sherman-me': {
    name: 'Sherman',
    postalCode: '04776',
  },
  'sherman-ny': {
    name: 'Sherman',
    postalCode: '14781',
  },
  'sherman-tx': {
    name: 'Sherman',
    postalCode: '75090',
  },
  'sherman-oaks-ca': {
    name: 'Sherman Oaks',
    postalCode: '91403',
  },
  'shermans-dale-pa': {
    name: 'Shermans Dale',
    postalCode: '17090',
  },
  'sherrard-il': {
    name: 'Sherrard',
    postalCode: '61281',
  },
  'sherrill-ar': {
    name: 'Sherrill',
    postalCode: '72152',
  },
  'sherrill-ia': {
    name: 'Sherrill',
    postalCode: '52073',
  },
  'sherrill-ny': {
    name: 'Sherrill',
    postalCode: '13461',
  },
  'sherrills-ford-nc': {
    name: 'Sherrills Ford',
    postalCode: '28673',
  },
  'sherrodsville-oh': {
    name: 'Sherrodsville',
    postalCode: '44675',
  },
  'sherwood-ar': {
    name: 'Sherwood',
    postalCode: '72120',
  },
  'sherwood-md': {
    name: 'Sherwood',
    postalCode: '21665',
  },
  'sherwood-mi': {
    name: 'Sherwood',
    postalCode: '49089',
  },
  'sherwood-nd': {
    name: 'Sherwood',
    postalCode: '58782',
  },
  'sherwood-oh': {
    name: 'Sherwood',
    postalCode: '43556',
  },
  'sherwood-or': {
    name: 'Sherwood',
    postalCode: '97140',
  },
  'sherwood-tn': {
    name: 'Sherwood',
    postalCode: '37376',
  },
  'sherwood-wi': {
    name: 'Sherwood',
    postalCode: '54169',
  },
  'shevlin-mn': {
    name: 'Shevlin',
    postalCode: '56676',
  },
  'sheyenne-nd': {
    name: 'Sheyenne',
    postalCode: '58374',
  },
  'shickley-ne': {
    name: 'Shickley',
    postalCode: '68436',
  },
  'shickshinny-pa': {
    name: 'Shickshinny',
    postalCode: '18655',
  },
  'shidler-ok': {
    name: 'Shidler',
    postalCode: '74652',
  },
  'shields-nd': {
    name: 'Shields',
    postalCode: '58569',
  },
  'shiloh-ga': {
    name: 'Shiloh',
    postalCode: '31826',
  },
  'shiloh-nc': {
    name: 'Shiloh',
    postalCode: '27974',
  },
  'shiloh-nj': {
    name: 'Shiloh',
    postalCode: '08353',
  },
  'shiloh-oh': {
    name: 'Shiloh',
    postalCode: '44878',
  },
  'shiloh-tn': {
    name: 'Shiloh',
    postalCode: '38376',
  },
  'shiner-tx': {
    name: 'Shiner',
    postalCode: '77984',
  },
  'shingle-springs-ca': {
    name: 'Shingle Springs',
    postalCode: '95682',
  },
  'shinglehouse-pa': {
    name: 'Shinglehouse',
    postalCode: '16748',
  },
  'shingleton-mi': {
    name: 'Shingleton',
    postalCode: '49884',
  },
  'shingletown-ca': {
    name: 'Shingletown',
    postalCode: '96088',
  },
  'shinnston-wv': {
    name: 'Shinnston',
    postalCode: '26431',
  },
  'shiocton-wi': {
    name: 'Shiocton',
    postalCode: '54170',
  },
  'shipman-il': {
    name: 'Shipman',
    postalCode: '62685',
  },
  'shipman-va': {
    name: 'Shipman',
    postalCode: '22971',
  },
  'shippensburg-pa': {
    name: 'Shippensburg',
    postalCode: '17257',
  },
  'shippenville-pa': {
    name: 'Shippenville',
    postalCode: '16254',
  },
  'shiprock-nm': {
    name: 'Shiprock',
    postalCode: '87420',
  },
  'shipshewana-in': {
    name: 'Shipshewana',
    postalCode: '46565',
  },
  'shirley-ar': {
    name: 'Shirley',
    postalCode: '72153',
  },
  'shirley-il': {
    name: 'Shirley',
    postalCode: '61772',
  },
  'shirley-in': {
    name: 'Shirley',
    postalCode: '47384',
  },
  'shirley-ma': {
    name: 'Shirley',
    postalCode: '01464',
  },
  'shirley-ny': {
    name: 'Shirley',
    postalCode: '11967',
  },
  'shirley-basin-wy': {
    name: 'Shirley Basin',
    postalCode: '82615',
  },
  'shirleysburg-pa': {
    name: 'Shirleysburg',
    postalCode: '17260',
  },
  'shoals-in': {
    name: 'Shoals',
    postalCode: '47581',
  },
  'shobonier-il': {
    name: 'Shobonier',
    postalCode: '62885',
  },
  'shock-wv': {
    name: 'Shock',
    postalCode: '26638',
  },
  'shoemakersville-pa': {
    name: 'Shoemakersville',
    postalCode: '19555',
  },
  'shohola-pa': {
    name: 'Shohola',
    postalCode: '18458',
  },
  'shokan-ny': {
    name: 'Shokan',
    postalCode: '12481',
  },
  'shongaloo-la': {
    name: 'Shongaloo',
    postalCode: '71072',
  },
  'shook-mo': {
    name: 'Shook',
    postalCode: '63963',
  },
  'shoreham-ny': {
    name: 'Shoreham',
    postalCode: '11786',
  },
  'shoreham-vt': {
    name: 'Shoreham',
    postalCode: '05770',
  },
  'shorewood-il': {
    name: 'Shorewood',
    postalCode: '60404',
  },
  'short-hills-nj': {
    name: 'Short Hills',
    postalCode: '07078',
  },
  'shorter-al': {
    name: 'Shorter',
    postalCode: '36075',
  },
  'shorterville-al': {
    name: 'Shorterville',
    postalCode: '36373',
  },
  'shortsville-ny': {
    name: 'Shortsville',
    postalCode: '14548',
  },
  'shoshone-id': {
    name: 'Shoshone',
    postalCode: '83352',
  },
  'shoshoni-wy': {
    name: 'Shoshoni',
    postalCode: '82649',
  },
  'shoup-id': {
    name: 'Shoup',
    postalCode: '83469',
  },
  'show-low-az': {
    name: 'Show Low',
    postalCode: '85901',
  },
  'showell-md': {
    name: 'Showell',
    postalCode: '21862',
  },
  'shreve-oh': {
    name: 'Shreve',
    postalCode: '44676',
  },
  'shreveport-la': {
    name: 'Shreveport',
    postalCode: '71101',
  },
  'shrewsbury-ma': {
    name: 'Shrewsbury',
    postalCode: '01545',
  },
  'shrewsbury-nj': {
    name: 'Shrewsbury',
    postalCode: '07702',
  },
  'shrewsbury-pa': {
    name: 'Shrewsbury',
    postalCode: '17361',
  },
  'shrub-oak-ny': {
    name: 'Shrub Oak',
    postalCode: '10588',
  },
  'shubert-ne': {
    name: 'Shubert',
    postalCode: '68437',
  },
  'shubuta-ms': {
    name: 'Shubuta',
    postalCode: '39360',
  },
  'shullsburg-wi': {
    name: 'Shullsburg',
    postalCode: '53586',
  },
  'shumway-il': {
    name: 'Shumway',
    postalCode: '62461',
  },
  'shunk-pa': {
    name: 'Shunk',
    postalCode: '17768',
  },
  'shuqualak-ms': {
    name: 'Shuqualak',
    postalCode: '39361',
  },
  'shushan-ny': {
    name: 'Shushan',
    postalCode: '12873',
  },
  'shutesbury-ma': {
    name: 'Shutesbury',
    postalCode: '01072',
  },
  'sibley-ia': {
    name: 'Sibley',
    postalCode: '51249',
  },
  'sibley-il': {
    name: 'Sibley',
    postalCode: '61773',
  },
  'sibley-la': {
    name: 'Sibley',
    postalCode: '71073',
  },
  'sibley-mo': {
    name: 'Sibley',
    postalCode: '64088',
  },
  'sicily-island-la': {
    name: 'Sicily Island',
    postalCode: '71368',
  },
  'sicklerville-nj': {
    name: 'Sicklerville',
    postalCode: '08081',
  },
  'side-lake-mn': {
    name: 'Side Lake',
    postalCode: '55781',
  },
  'sidell-il': {
    name: 'Sidell',
    postalCode: '61876',
  },
  'sidman-pa': {
    name: 'Sidman',
    postalCode: '15955',
  },
  'sidney-ar': {
    name: 'Sidney',
    postalCode: '72577',
  },
  'sidney-ia': {
    name: 'Sidney',
    postalCode: '51652',
  },
  'sidney-il': {
    name: 'Sidney',
    postalCode: '61877',
  },
  'sidney-ky': {
    name: 'Sidney',
    postalCode: '41564',
  },
  'sidney-mi': {
    name: 'Sidney',
    postalCode: '48885',
  },
  'sidney-mt': {
    name: 'Sidney',
    postalCode: '59270',
  },
  'sidney-ne': {
    name: 'Sidney',
    postalCode: '69162',
  },
  'sidney-ny': {
    name: 'Sidney',
    postalCode: '13838',
  },
  'sidney-oh': {
    name: 'Sidney',
    postalCode: '45365',
  },
  'sidney-tx': {
    name: 'Sidney',
    postalCode: '76474',
  },
  'sidney-center-ny': {
    name: 'Sidney Center',
    postalCode: '13839',
  },
  'sidon-ms': {
    name: 'Sidon',
    postalCode: '38954',
  },
  'sieper-la': {
    name: 'Sieper',
    postalCode: '71472',
  },
  'sierra-city-ca': {
    name: 'Sierra City',
    postalCode: '96125',
  },
  'sierra-madre-ca': {
    name: 'Sierra Madre',
    postalCode: '91024',
  },
  'sierra-vista-az': {
    name: 'Sierra Vista',
    postalCode: '85635',
  },
  'sigel-il': {
    name: 'Sigel',
    postalCode: '62462',
  },
  'sigel-pa': {
    name: 'Sigel',
    postalCode: '15860',
  },
  'signal-hill-ca': {
    name: 'Signal Hill',
    postalCode: '90755',
  },
  'signal-mountain-tn': {
    name: 'Signal Mountain',
    postalCode: '37377',
  },
  'sigourney-ia': {
    name: 'Sigourney',
    postalCode: '52591',
  },
  'sikes-la': {
    name: 'Sikes',
    postalCode: '71473',
  },
  'sikeston-mo': {
    name: 'Sikeston',
    postalCode: '63801',
  },
  'silas-al': {
    name: 'Silas',
    postalCode: '36919',
  },
  'siler-ky': {
    name: 'Siler',
    postalCode: '40763',
  },
  'siler-city-nc': {
    name: 'Siler City',
    postalCode: '27344',
  },
  'siletz-or': {
    name: 'Siletz',
    postalCode: '97380',
  },
  'silex-mo': {
    name: 'Silex',
    postalCode: '63377',
  },
  'siloam-nc': {
    name: 'Siloam',
    postalCode: '27047',
  },
  'siloam-springs-ar': {
    name: 'Siloam Springs',
    postalCode: '72761',
  },
  'silsbee-tx': {
    name: 'Silsbee',
    postalCode: '77656',
  },
  'silt-co': {
    name: 'Silt',
    postalCode: '81652',
  },
  'silva-mo': {
    name: 'Silva',
    postalCode: '63964',
  },
  'silver-tx': {
    name: 'Silver',
    postalCode: '76949',
  },
  'silver-bay-mn': {
    name: 'Silver Bay',
    postalCode: '55614',
  },
  'silver-bay-ny': {
    name: 'Silver Bay',
    postalCode: '12874',
  },
  'silver-city-ia': {
    name: 'Silver City',
    postalCode: '51571',
  },
  'silver-city-ms': {
    name: 'Silver City',
    postalCode: '39166',
  },
  'silver-city-nm': {
    name: 'Silver City',
    postalCode: '88061',
  },
  'silver-creek-ga': {
    name: 'Silver Creek',
    postalCode: '30173',
  },
  'silver-creek-ms': {
    name: 'Silver Creek',
    postalCode: '39663',
  },
  'silver-creek-ne': {
    name: 'Silver Creek',
    postalCode: '68663',
  },
  'silver-creek-ny': {
    name: 'Silver Creek',
    postalCode: '14136',
  },
  'silver-creek-wa': {
    name: 'Silver Creek',
    postalCode: '98585',
  },
  'silver-gate-mt': {
    name: 'Silver Gate',
    postalCode: '59081',
  },
  'silver-lake-in': {
    name: 'Silver Lake',
    postalCode: '46982',
  },
  'silver-lake-ks': {
    name: 'Silver Lake',
    postalCode: '66539',
  },
  'silver-lake-mn': {
    name: 'Silver Lake',
    postalCode: '55381',
  },
  'silver-lake-nh': {
    name: 'Silver Lake',
    postalCode: '03875',
  },
  'silver-lake-or': {
    name: 'Silver Lake',
    postalCode: '97638',
  },
  'silver-lake-wi': {
    name: 'Silver Lake',
    postalCode: '53170',
  },
  'silver-point-tn': {
    name: 'Silver Point',
    postalCode: '38582',
  },
  'silver-spring-md': {
    name: 'Silver Spring',
    postalCode: '20901',
  },
  'silver-springs-fl': {
    name: 'Silver Springs',
    postalCode: '34488',
  },
  'silver-springs-nv': {
    name: 'Silver Springs',
    postalCode: '89429',
  },
  'silver-springs-ny': {
    name: 'Silver Springs',
    postalCode: '14550',
  },
  'silver-star-mt': {
    name: 'Silver Star',
    postalCode: '59751',
  },
  'silverado-ca': {
    name: 'Silverado',
    postalCode: '92676',
  },
  'silverdale-wa': {
    name: 'Silverdale',
    postalCode: '98315',
  },
  'silverhill-al': {
    name: 'Silverhill',
    postalCode: '36576',
  },
  'silverlake-wa': {
    name: 'Silverlake',
    postalCode: '98645',
  },
  'silverstreet-sc': {
    name: 'Silverstreet',
    postalCode: '29145',
  },
  'silverthorne-co': {
    name: 'Silverthorne',
    postalCode: '80498',
  },
  'silverton-or': {
    name: 'Silverton',
    postalCode: '97381',
  },
  'silverton-tx': {
    name: 'Silverton',
    postalCode: '79257',
  },
  'silverwood-mi': {
    name: 'Silverwood',
    postalCode: '48760',
  },
  'silvis-il': {
    name: 'Silvis',
    postalCode: '61282',
  },
  'simi-valley-ca': {
    name: 'Simi Valley',
    postalCode: '93063',
  },
  'simla-co': {
    name: 'Simla',
    postalCode: '80835',
  },
  'simmesport-la': {
    name: 'Simmesport',
    postalCode: '71369',
  },
  'simms-tx': {
    name: 'Simms',
    postalCode: '75574',
  },
  'simon-wv': {
    name: 'Simon',
    postalCode: '24882',
  },
  'simpson-il': {
    name: 'Simpson',
    postalCode: '62985',
  },
  'simpsonville-ky': {
    name: 'Simpsonville',
    postalCode: '40067',
  },
  'simpsonville-sc': {
    name: 'Simpsonville',
    postalCode: '29680',
  },
  'sims-ar': {
    name: 'Sims',
    postalCode: '71969',
  },
  'sims-il': {
    name: 'Sims',
    postalCode: '62886',
  },
  'sims-nc': {
    name: 'Sims',
    postalCode: '27880',
  },
  'simsboro-la': {
    name: 'Simsboro',
    postalCode: '71275',
  },
  'simsbury-ct': {
    name: 'Simsbury',
    postalCode: '06070',
  },
  'sinclair-me': {
    name: 'Sinclair',
    postalCode: '04779',
  },
  'sinclair-wy': {
    name: 'Sinclair',
    postalCode: '82334',
  },
  'sinclairville-ny': {
    name: 'Sinclairville',
    postalCode: '14782',
  },
  'singer-la': {
    name: 'Singer',
    postalCode: '70660',
  },
  'singers-glen-va': {
    name: 'Singers Glen',
    postalCode: '22850',
  },
  'sinks-grove-wv': {
    name: 'Sinks Grove',
    postalCode: '24976',
  },
  'sinnamahoning-pa': {
    name: 'Sinnamahoning',
    postalCode: '15861',
  },
  'sinton-tx': {
    name: 'Sinton',
    postalCode: '78387',
  },
  'sioux-center-ia': {
    name: 'Sioux Center',
    postalCode: '51250',
  },
  'sioux-city-ia': {
    name: 'Sioux City',
    postalCode: '51101',
  },
  'sioux-falls-sd': {
    name: 'Sioux Falls',
    postalCode: '57103',
  },
  'sioux-rapids-ia': {
    name: 'Sioux Rapids',
    postalCode: '50585',
  },
  'siren-wi': {
    name: 'Siren',
    postalCode: '54872',
  },
  'sisseton-sd': {
    name: 'Sisseton',
    postalCode: '57262',
  },
  'sister-bay-wi': {
    name: 'Sister Bay',
    postalCode: '54234',
  },
  'sisters-or': {
    name: 'Sisters',
    postalCode: '97759',
  },
  'sistersville-wv': {
    name: 'Sistersville',
    postalCode: '26175',
  },
  'sitka-ak': {
    name: 'Sitka',
    postalCode: '99835',
  },
  'sitka-ky': {
    name: 'Sitka',
    postalCode: '41255',
  },
  'six-lakes-mi': {
    name: 'Six Lakes',
    postalCode: '48886',
  },
  'six-mile-sc': {
    name: 'Six Mile',
    postalCode: '29682',
  },
  'six-mile-run-pa': {
    name: 'Six Mile Run',
    postalCode: '16679',
  },
  'sixes-or': {
    name: 'Sixes',
    postalCode: '97476',
  },
  'sizerock-ky': {
    name: 'Sizerock',
    postalCode: '41762',
  },
  'skamokawa-wa': {
    name: 'Skamokawa',
    postalCode: '98647',
  },
  'skandia-mi': {
    name: 'Skandia',
    postalCode: '49885',
  },
  'skaneateles-ny': {
    name: 'Skaneateles',
    postalCode: '13152',
  },
  'skanee-mi': {
    name: 'Skanee',
    postalCode: '49962',
  },
  'skellytown-tx': {
    name: 'Skellytown',
    postalCode: '79080',
  },
  'skiatook-ok': {
    name: 'Skiatook',
    postalCode: '74070',
  },
  'skidmore-mo': {
    name: 'Skidmore',
    postalCode: '64487',
  },
  'skidmore-tx': {
    name: 'Skidmore',
    postalCode: '78389',
  },
  'skillman-nj': {
    name: 'Skillman',
    postalCode: '08558',
  },
  'skippers-va': {
    name: 'Skippers',
    postalCode: '23879',
  },
  'skipperville-al': {
    name: 'Skipperville',
    postalCode: '36374',
  },
  'skipwith-va': {
    name: 'Skipwith',
    postalCode: '23968',
  },
  'skokie-il': {
    name: 'Skokie',
    postalCode: '60076',
  },
  'skowhegan-me': {
    name: 'Skowhegan',
    postalCode: '04976',
  },
  'slab-fork-wv': {
    name: 'Slab Fork',
    postalCode: '25920',
  },
  'slade-ky': {
    name: 'Slade',
    postalCode: '40376',
  },
  'slanesville-wv': {
    name: 'Slanesville',
    postalCode: '25444',
  },
  'slate-hill-ny': {
    name: 'Slate Hill',
    postalCode: '10973',
  },
  'slate-run-pa': {
    name: 'Slate Run',
    postalCode: '17769',
  },
  'slater-co': {
    name: 'Slater',
    postalCode: '81653',
  },
  'slater-ia': {
    name: 'Slater',
    postalCode: '50244',
  },
  'slater-mo': {
    name: 'Slater',
    postalCode: '65349',
  },
  'slatersville-ri': {
    name: 'Slatersville',
    postalCode: '02876',
  },
  'slaterville-springs-ny': {
    name: 'Slaterville Springs',
    postalCode: '14881',
  },
  'slatington-pa': {
    name: 'Slatington',
    postalCode: '18080',
  },
  'slaton-tx': {
    name: 'Slaton',
    postalCode: '79364',
  },
  'slatyfork-wv': {
    name: 'Slatyfork',
    postalCode: '26291',
  },
  'slaughter-la': {
    name: 'Slaughter',
    postalCode: '70777',
  },
  'slaughters-ky': {
    name: 'Slaughters',
    postalCode: '42456',
  },
  'slayton-mn': {
    name: 'Slayton',
    postalCode: '56172',
  },
  'sledge-ms': {
    name: 'Sledge',
    postalCode: '38628',
  },
  'sleepy-eye-mn': {
    name: 'Sleepy Eye',
    postalCode: '56085',
  },
  'slemp-ky': {
    name: 'Slemp',
    postalCode: '41763',
  },
  'slickville-pa': {
    name: 'Slickville',
    postalCode: '15684',
  },
  'slidell-la': {
    name: 'Slidell',
    postalCode: '70458',
  },
  'sligo-pa': {
    name: 'Sligo',
    postalCode: '16255',
  },
  'slinger-wi': {
    name: 'Slinger',
    postalCode: '53086',
  },
  'slingerlands-ny': {
    name: 'Slingerlands',
    postalCode: '12159',
  },
  'slippery-rock-pa': {
    name: 'Slippery Rock',
    postalCode: '16057',
  },
  'sloan-ia': {
    name: 'Sloan',
    postalCode: '51055',
  },
  'sloan-nv': {
    name: 'Sloan',
    postalCode: '89054',
  },
  'sloansville-ny': {
    name: 'Sloansville',
    postalCode: '12160',
  },
  'sloatsburg-ny': {
    name: 'Sloatsburg',
    postalCode: '10974',
  },
  'slocomb-al': {
    name: 'Slocomb',
    postalCode: '36375',
  },
  'slocum-ri': {
    name: 'Slocum',
    postalCode: '02877',
  },
  'sloughhouse-ca': {
    name: 'Sloughhouse',
    postalCode: '95683',
  },
  'slovan-pa': {
    name: 'Slovan',
    postalCode: '15078',
  },
  'smackover-ar': {
    name: 'Smackover',
    postalCode: '71762',
  },
  'smartsville-ca': {
    name: 'Smartsville',
    postalCode: '95977',
  },
  'smelterville-id': {
    name: 'Smelterville',
    postalCode: '83868',
  },
  'smethport-pa': {
    name: 'Smethport',
    postalCode: '16749',
  },
  'smicksburg-pa': {
    name: 'Smicksburg',
    postalCode: '16256',
  },
  'smilax-ky': {
    name: 'Smilax',
    postalCode: '41764',
  },
  'smiley-tx': {
    name: 'Smiley',
    postalCode: '78159',
  },
  'smith-nv': {
    name: 'Smith',
    postalCode: '89430',
  },
  'smith-center-ks': {
    name: 'Smith Center',
    postalCode: '66967',
  },
  'smith-river-ca': {
    name: 'Smith River',
    postalCode: '95567',
  },
  'smithboro-il': {
    name: 'Smithboro',
    postalCode: '62284',
  },
  'smithdale-ms': {
    name: 'Smithdale',
    postalCode: '39664',
  },
  'smithfield-il': {
    name: 'Smithfield',
    postalCode: '61477',
  },
  'smithfield-ky': {
    name: 'Smithfield',
    postalCode: '40068',
  },
  'smithfield-me': {
    name: 'Smithfield',
    postalCode: '04978',
  },
  'smithfield-nc': {
    name: 'Smithfield',
    postalCode: '27577',
  },
  'smithfield-ne': {
    name: 'Smithfield',
    postalCode: '68976',
  },
  'smithfield-pa': {
    name: 'Smithfield',
    postalCode: '15478',
  },
  'smithfield-ri': {
    name: 'Smithfield',
    postalCode: '02917',
  },
  'smithfield-ut': {
    name: 'Smithfield',
    postalCode: '84335',
  },
  'smithfield-va': {
    name: 'Smithfield',
    postalCode: '23430',
  },
  'smithfield-wv': {
    name: 'Smithfield',
    postalCode: '26437',
  },
  'smithland-ia': {
    name: 'Smithland',
    postalCode: '51056',
  },
  'smithland-ky': {
    name: 'Smithland',
    postalCode: '42081',
  },
  'smithmill-pa': {
    name: 'Smithmill',
    postalCode: '16680',
  },
  'smiths-creek-mi': {
    name: 'Smiths Creek',
    postalCode: '48074',
  },
  'smiths-grove-ky': {
    name: 'Smiths Grove',
    postalCode: '42171',
  },
  'smiths-station-al': {
    name: 'Smiths Station',
    postalCode: '36877',
  },
  'smithsburg-md': {
    name: 'Smithsburg',
    postalCode: '21783',
  },
  'smithshire-il': {
    name: 'Smithshire',
    postalCode: '61478',
  },
  'smithton-il': {
    name: 'Smithton',
    postalCode: '62285',
  },
  'smithton-mo': {
    name: 'Smithton',
    postalCode: '65350',
  },
  'smithton-pa': {
    name: 'Smithton',
    postalCode: '15479',
  },
  'smithtown-ny': {
    name: 'Smithtown',
    postalCode: '11787',
  },
  'smithville-ar': {
    name: 'Smithville',
    postalCode: '72466',
  },
  'smithville-ga': {
    name: 'Smithville',
    postalCode: '31787',
  },
  'smithville-mo': {
    name: 'Smithville',
    postalCode: '64089',
  },
  'smithville-ms': {
    name: 'Smithville',
    postalCode: '38870',
  },
  'smithville-oh': {
    name: 'Smithville',
    postalCode: '44677',
  },
  'smithville-ok': {
    name: 'Smithville',
    postalCode: '74957',
  },
  'smithville-tn': {
    name: 'Smithville',
    postalCode: '37166',
  },
  'smithville-tx': {
    name: 'Smithville',
    postalCode: '78957',
  },
  'smithville-wv': {
    name: 'Smithville',
    postalCode: '26178',
  },
  'smithville-flats-ny': {
    name: 'Smithville Flats',
    postalCode: '13841',
  },
  'smoaks-sc': {
    name: 'Smoaks',
    postalCode: '29481',
  },
  'smock-pa': {
    name: 'Smock',
    postalCode: '15480',
  },
  'smoketown-pa': {
    name: 'Smoketown',
    postalCode: '17576',
  },
  'smoot-wv': {
    name: 'Smoot',
    postalCode: '24977',
  },
  'smoot-wy': {
    name: 'Smoot',
    postalCode: '83126',
  },
  'smyrna-de': {
    name: 'Smyrna',
    postalCode: '19977',
  },
  'smyrna-ga': {
    name: 'Smyrna',
    postalCode: '30080',
  },
  'smyrna-nc': {
    name: 'Smyrna',
    postalCode: '28579',
  },
  'smyrna-ny': {
    name: 'Smyrna',
    postalCode: '13464',
  },
  'smyrna-sc': {
    name: 'Smyrna',
    postalCode: '29743',
  },
  'smyrna-tn': {
    name: 'Smyrna',
    postalCode: '37167',
  },
  'smyrna-mills-me': {
    name: 'Smyrna Mills',
    postalCode: '04780',
  },
  'sneads-fl': {
    name: 'Sneads',
    postalCode: '32460',
  },
  'sneads-ferry-nc': {
    name: 'Sneads Ferry',
    postalCode: '28460',
  },
  'sneedville-tn': {
    name: 'Sneedville',
    postalCode: '37869',
  },
  'snelling-ca': {
    name: 'Snelling',
    postalCode: '95369',
  },
  'snellville-ga': {
    name: 'Snellville',
    postalCode: '30039',
  },
  'snohomish-wa': {
    name: 'Snohomish',
    postalCode: '98290',
  },
  'snoqualmie-wa': {
    name: 'Snoqualmie',
    postalCode: '98065',
  },
  'snover-mi': {
    name: 'Snover',
    postalCode: '48472',
  },
  'snow-ok': {
    name: 'Snow',
    postalCode: '74567',
  },
  'snow-camp-nc': {
    name: 'Snow Camp',
    postalCode: '27349',
  },
  'snow-hill-md': {
    name: 'Snow Hill',
    postalCode: '21863',
  },
  'snow-hill-nc': {
    name: 'Snow Hill',
    postalCode: '28580',
  },
  'snow-shoe-pa': {
    name: 'Snow Shoe',
    postalCode: '16874',
  },
  'snowflake-az': {
    name: 'Snowflake',
    postalCode: '85937',
  },
  'snowmass-co': {
    name: 'Snowmass',
    postalCode: '81654',
  },
  'snowville-ut': {
    name: 'Snowville',
    postalCode: '84336',
  },
  'snyder-co': {
    name: 'Snyder',
    postalCode: '80750',
  },
  'snyder-ok': {
    name: 'Snyder',
    postalCode: '73566',
  },
  'snyder-tx': {
    name: 'Snyder',
    postalCode: '79549',
  },
  'snydersburg-pa': {
    name: 'Snydersburg',
    postalCode: '16257',
  },
  'soap-lake-wa': {
    name: 'Soap Lake',
    postalCode: '98851',
  },
  'sobieski-wi': {
    name: 'Sobieski',
    postalCode: '54171',
  },
  'social-circle-ga': {
    name: 'Social Circle',
    postalCode: '30025',
  },
  'society-hill-sc': {
    name: 'Society Hill',
    postalCode: '29593',
  },
  'socorro-nm': {
    name: 'Socorro',
    postalCode: '87801',
  },
  'sod-wv': {
    name: 'Sod',
    postalCode: '25564',
  },
  'soda-springs-id': {
    name: 'Soda Springs',
    postalCode: '83276',
  },
  'soddy-daisy-tn': {
    name: 'Soddy Daisy',
    postalCode: '37379',
  },
  'sodus-mi': {
    name: 'Sodus',
    postalCode: '49126',
  },
  'sodus-ny': {
    name: 'Sodus',
    postalCode: '14551',
  },
  'sodus-point-ny': {
    name: 'Sodus Point',
    postalCode: '14555',
  },
  'solana-beach-ca': {
    name: 'Solana Beach',
    postalCode: '92075',
  },
  'solano-nm': {
    name: 'Solano',
    postalCode: '87746',
  },
  'soldier-ia': {
    name: 'Soldier',
    postalCode: '51572',
  },
  'soldier-ks': {
    name: 'Soldier',
    postalCode: '66540',
  },
  'soldiers-grove-wi': {
    name: 'Soldiers Grove',
    postalCode: '54655',
  },
  'soldotna-ak': {
    name: 'Soldotna',
    postalCode: '99669',
  },
  'soledad-ca': {
    name: 'Soledad',
    postalCode: '93960',
  },
  'solen-nd': {
    name: 'Solen',
    postalCode: '58570',
  },
  'solgohachia-ar': {
    name: 'Solgohachia',
    postalCode: '72156',
  },
  'solo-mo': {
    name: 'Solo',
    postalCode: '65564',
  },
  'solomon-ks': {
    name: 'Solomon',
    postalCode: '67480',
  },
  'solomons-md': {
    name: 'Solomons',
    postalCode: '20688',
  },
  'solon-ia': {
    name: 'Solon',
    postalCode: '52333',
  },
  'solon-me': {
    name: 'Solon',
    postalCode: '04979',
  },
  'solon-oh': {
    name: 'Solon',
    postalCode: '44139',
  },
  'solon-springs-wi': {
    name: 'Solon Springs',
    postalCode: '54873',
  },
  'solsberry-in': {
    name: 'Solsberry',
    postalCode: '47459',
  },
  'solsville-ny': {
    name: 'Solsville',
    postalCode: '13465',
  },
  'solvang-ca': {
    name: 'Solvang',
    postalCode: '93463',
  },
  'solway-mn': {
    name: 'Solway',
    postalCode: '56678',
  },
  'somerdale-nj': {
    name: 'Somerdale',
    postalCode: '08083',
  },
  'somers-ct': {
    name: 'Somers',
    postalCode: '06071',
  },
  'somers-ia': {
    name: 'Somers',
    postalCode: '50586',
  },
  'somers-mt': {
    name: 'Somers',
    postalCode: '59932',
  },
  'somers-ny': {
    name: 'Somers',
    postalCode: '10589',
  },
  'somers-point-nj': {
    name: 'Somers Point',
    postalCode: '08244',
  },
  'somerset-ca': {
    name: 'Somerset',
    postalCode: '95684',
  },
  'somerset-co': {
    name: 'Somerset',
    postalCode: '81434',
  },
  'somerset-ky': {
    name: 'Somerset',
    postalCode: '42501',
  },
  'somerset-ma': {
    name: 'Somerset',
    postalCode: '02725',
  },
  'somerset-nj': {
    name: 'Somerset',
    postalCode: '08873',
  },
  'somerset-oh': {
    name: 'Somerset',
    postalCode: '43783',
  },
  'somerset-pa': {
    name: 'Somerset',
    postalCode: '15501',
  },
  'somerset-tx': {
    name: 'Somerset',
    postalCode: '78069',
  },
  'somerset-va': {
    name: 'Somerset',
    postalCode: '22972',
  },
  'somerset-wi': {
    name: 'Somerset',
    postalCode: '54025',
  },
  'somerset-center-mi': {
    name: 'Somerset Center',
    postalCode: '49282',
  },
  'somersworth-nh': {
    name: 'Somersworth',
    postalCode: '03878',
  },
  'somerton-az': {
    name: 'Somerton',
    postalCode: '85350',
  },
  'somerville-al': {
    name: 'Somerville',
    postalCode: '35670',
  },
  'somerville-ma': {
    name: 'Somerville',
    postalCode: '02143',
  },
  'somerville-nj': {
    name: 'Somerville',
    postalCode: '08876',
  },
  'somerville-oh': {
    name: 'Somerville',
    postalCode: '45064',
  },
  'somerville-tn': {
    name: 'Somerville',
    postalCode: '38068',
  },
  'somerville-tx': {
    name: 'Somerville',
    postalCode: '77879',
  },
  'somes-bar-ca': {
    name: 'Somes Bar',
    postalCode: '95568',
  },
  'somis-ca': {
    name: 'Somis',
    postalCode: '93066',
  },
  'somonauk-il': {
    name: 'Somonauk',
    postalCode: '60552',
  },
  'sondheimer-la': {
    name: 'Sondheimer',
    postalCode: '71276',
  },
  'sonoita-az': {
    name: 'Sonoita',
    postalCode: '85637',
  },
  'sonoma-ca': {
    name: 'Sonoma',
    postalCode: '95476',
  },
  'sonora-ca': {
    name: 'Sonora',
    postalCode: '95370',
  },
  'sonora-ky': {
    name: 'Sonora',
    postalCode: '42776',
  },
  'sonora-tx': {
    name: 'Sonora',
    postalCode: '76950',
  },
  'sontag-ms': {
    name: 'Sontag',
    postalCode: '39665',
  },
  'sopchoppy-fl': {
    name: 'Sopchoppy',
    postalCode: '32358',
  },
  'soper-ok': {
    name: 'Soper',
    postalCode: '74759',
  },
  'soperton-ga': {
    name: 'Soperton',
    postalCode: '30457',
  },
  'sophia-nc': {
    name: 'Sophia',
    postalCode: '27350',
  },
  'soquel-ca': {
    name: 'Soquel',
    postalCode: '95073',
  },
  'sorento-il': {
    name: 'Sorento',
    postalCode: '62086',
  },
  'sorrento-fl': {
    name: 'Sorrento',
    postalCode: '32776',
  },
  'sorrento-la': {
    name: 'Sorrento',
    postalCode: '70778',
  },
  'sorrento-me': {
    name: 'Sorrento',
    postalCode: '04677',
  },
  'soso-ms': {
    name: 'Soso',
    postalCode: '39480',
  },
  'souderton-pa': {
    name: 'Souderton',
    postalCode: '18964',
  },
  'soulsbyville-ca': {
    name: 'Soulsbyville',
    postalCode: '95372',
  },
  'sound-beach-ny': {
    name: 'Sound Beach',
    postalCode: '11789',
  },
  'sour-lake-tx': {
    name: 'Sour Lake',
    postalCode: '77659',
  },
  'souris-nd': {
    name: 'Souris',
    postalCode: '58783',
  },
  'south-acworth-nh': {
    name: 'South Acworth',
    postalCode: '03607',
  },
  'south-amana-ia': {
    name: 'South Amana',
    postalCode: '52334',
  },
  'south-amboy-nj': {
    name: 'South Amboy',
    postalCode: '08879',
  },
  'south-bay-fl': {
    name: 'South Bay',
    postalCode: '33493',
  },
  'south-beach-or': {
    name: 'South Beach',
    postalCode: '97366',
  },
  'south-beloit-il': {
    name: 'South Beloit',
    postalCode: '61080',
  },
  'south-bend-in': {
    name: 'South Bend',
    postalCode: '46601',
  },
  'south-bend-tx': {
    name: 'South Bend',
    postalCode: '76481',
  },
  'south-bend-wa': {
    name: 'South Bend',
    postalCode: '98586',
  },
  'south-berwick-me': {
    name: 'South Berwick',
    postalCode: '03908',
  },
  'south-bloomingville-oh': {
    name: 'South Bloomingville',
    postalCode: '43152',
  },
  'south-boardman-mi': {
    name: 'South Boardman',
    postalCode: '49680',
  },
  'south-boston-ma': {
    name: 'South Boston',
    postalCode: '02127',
  },
  'south-boston-va': {
    name: 'South Boston',
    postalCode: '24592',
  },
  'south-bound-brook-nj': {
    name: 'South Bound Brook',
    postalCode: '08880',
  },
  'south-branch-mi': {
    name: 'South Branch',
    postalCode: '48761',
  },
  'south-bristol-me': {
    name: 'South Bristol',
    postalCode: '04568',
  },
  'south-burlington-vt': {
    name: 'South Burlington',
    postalCode: '05403',
  },
  'south-cairo-ny': {
    name: 'South Cairo',
    postalCode: '12482',
  },
  'south-charleston-oh': {
    name: 'South Charleston',
    postalCode: '45368',
  },
  'south-charleston-wv': {
    name: 'South Charleston',
    postalCode: '25303',
  },
  'south-chatham-ma': {
    name: 'South Chatham',
    postalCode: '02659',
  },
  'south-china-me': {
    name: 'South China',
    postalCode: '04358',
  },
  'south-colton-ny': {
    name: 'South Colton',
    postalCode: '13687',
  },
  'south-dartmouth-ma': {
    name: 'South Dartmouth',
    postalCode: '02748',
  },
  'south-dayton-ny': {
    name: 'South Dayton',
    postalCode: '14138',
  },
  'south-deerfield-ma': {
    name: 'South Deerfield',
    postalCode: '01373',
  },
  'south-dennis-ma': {
    name: 'South Dennis',
    postalCode: '02660',
  },
  'south-easton-ma': {
    name: 'South Easton',
    postalCode: '02375',
  },
  'south-el-monte-ca': {
    name: 'South El Monte',
    postalCode: '91733',
  },
  'south-elgin-il': {
    name: 'South Elgin',
    postalCode: '60177',
  },
  'south-english-ia': {
    name: 'South English',
    postalCode: '52335',
  },
  'south-fallsburg-ny': {
    name: 'South Fallsburg',
    postalCode: '12779',
  },
  'south-fork-co': {
    name: 'South Fork',
    postalCode: '81154',
  },
  'south-fork-mo': {
    name: 'South Fork',
    postalCode: '65776',
  },
  'south-fork-pa': {
    name: 'South Fork',
    postalCode: '15956',
  },
  'south-fulton-tn': {
    name: 'South Fulton',
    postalCode: '38257',
  },
  'south-gate-ca': {
    name: 'South Gate',
    postalCode: '90280',
  },
  'south-gibson-pa': {
    name: 'South Gibson',
    postalCode: '18842',
  },
  'south-glastonbury-ct': {
    name: 'South Glastonbury',
    postalCode: '06073',
  },
  'south-glens-falls-ny': {
    name: 'South Glens Falls',
    postalCode: '12803',
  },
  'south-grafton-ma': {
    name: 'South Grafton',
    postalCode: '01560',
  },
  'south-greenfield-mo': {
    name: 'South Greenfield',
    postalCode: '65752',
  },
  'south-hackensack-nj': {
    name: 'South Hackensack',
    postalCode: '07606',
  },
  'south-hadley-ma': {
    name: 'South Hadley',
    postalCode: '01075',
  },
  'south-hamilton-ma': {
    name: 'South Hamilton',
    postalCode: '01982',
  },
  'south-haven-ks': {
    name: 'South Haven',
    postalCode: '67140',
  },
  'south-haven-mi': {
    name: 'South Haven',
    postalCode: '49090',
  },
  'south-haven-mn': {
    name: 'South Haven',
    postalCode: '55382',
  },
  'south-heart-nd': {
    name: 'South Heart',
    postalCode: '58655',
  },
  'south-hero-vt': {
    name: 'South Hero',
    postalCode: '05486',
  },
  'south-hill-va': {
    name: 'South Hill',
    postalCode: '23970',
  },
  'south-holland-il': {
    name: 'South Holland',
    postalCode: '60473',
  },
  'south-houston-tx': {
    name: 'South Houston',
    postalCode: '77587',
  },
  'south-hutchinson-ks': {
    name: 'South Hutchinson',
    postalCode: '67505',
  },
  'south-jordan-ut': {
    name: 'South Jordan',
    postalCode: '84009',
  },
  'south-kent-ct': {
    name: 'South Kent',
    postalCode: '06785',
  },
  'south-kortright-ny': {
    name: 'South Kortright',
    postalCode: '13842',
  },
  'south-lake-tahoe-ca': {
    name: 'South Lake Tahoe',
    postalCode: '96150',
  },
  'south-lebanon-oh': {
    name: 'South Lebanon',
    postalCode: '45065',
  },
  'south-londonderry-vt': {
    name: 'South Londonderry',
    postalCode: '05155',
  },
  'south-lyon-mi': {
    name: 'South Lyon',
    postalCode: '48178',
  },
  'south-mills-nc': {
    name: 'South Mills',
    postalCode: '27976',
  },
  'south-milwaukee-wi': {
    name: 'South Milwaukee',
    postalCode: '53172',
  },
  'south-new-berlin-ny': {
    name: 'South New Berlin',
    postalCode: '13843',
  },
  'south-newfane-vt': {
    name: 'South Newfane',
    postalCode: '05351',
  },
  'south-orange-nj': {
    name: 'South Orange',
    postalCode: '07079',
  },
  'south-otselic-ny': {
    name: 'South Otselic',
    postalCode: '13155',
  },
  'south-ozone-park-ny': {
    name: 'South Ozone Park',
    postalCode: '11420',
  },
  'south-padre-island-tx': {
    name: 'South Padre Island',
    postalCode: '78597',
  },
  'south-paris-me': {
    name: 'South Paris',
    postalCode: '04281',
  },
  'south-park-pa': {
    name: 'South Park',
    postalCode: '15129',
  },
  'south-pasadena-ca': {
    name: 'South Pasadena',
    postalCode: '91030',
  },
  'south-pittsburg-tn': {
    name: 'South Pittsburg',
    postalCode: '37380',
  },
  'south-plainfield-nj': {
    name: 'South Plainfield',
    postalCode: '07080',
  },
  'south-plymouth-ny': {
    name: 'South Plymouth',
    postalCode: '13844',
  },
  'south-point-oh': {
    name: 'South Point',
    postalCode: '45680',
  },
  'south-pomfret-vt': {
    name: 'South Pomfret',
    postalCode: '05067',
  },
  'south-portland-me': {
    name: 'South Portland',
    postalCode: '04106',
  },
  'south-portsmouth-ky': {
    name: 'South Portsmouth',
    postalCode: '41174',
  },
  'south-range-wi': {
    name: 'South Range',
    postalCode: '54874',
  },
  'south-richmond-hill-ny': {
    name: 'South Richmond Hill',
    postalCode: '11419',
  },
  'south-river-nj': {
    name: 'South River',
    postalCode: '08882',
  },
  'south-rockwood-mi': {
    name: 'South Rockwood',
    postalCode: '48179',
  },
  'south-roxana-il': {
    name: 'South Roxana',
    postalCode: '62087',
  },
  'south-royalton-vt': {
    name: 'South Royalton',
    postalCode: '05068',
  },
  'south-ryegate-vt': {
    name: 'South Ryegate',
    postalCode: '05069',
  },
  'south-saint-paul-mn': {
    name: 'South Saint Paul',
    postalCode: '55075',
  },
  'south-salem-ny': {
    name: 'South Salem',
    postalCode: '10590',
  },
  'south-salem-oh': {
    name: 'South Salem',
    postalCode: '45681',
  },
  'south-san-francisco-ca': {
    name: 'South San Francisco',
    postalCode: '94080',
  },
  'south-shore-ky': {
    name: 'South Shore',
    postalCode: '41175',
  },
  'south-shore-sd': {
    name: 'South Shore',
    postalCode: '57263',
  },
  'south-sioux-city-ne': {
    name: 'South Sioux City',
    postalCode: '68776',
  },
  'south-solon-oh': {
    name: 'South Solon',
    postalCode: '43153',
  },
  'south-sterling-pa': {
    name: 'South Sterling',
    postalCode: '18460',
  },
  'south-strafford-vt': {
    name: 'South Strafford',
    postalCode: '05070',
  },
  'south-tamworth-nh': {
    name: 'South Tamworth',
    postalCode: '03883',
  },
  'south-thomaston-me': {
    name: 'South Thomaston',
    postalCode: '04858',
  },
  'south-vienna-oh': {
    name: 'South Vienna',
    postalCode: '45369',
  },
  'south-wales-ny': {
    name: 'South Wales',
    postalCode: '14139',
  },
  'south-walpole-ma': {
    name: 'South Walpole',
    postalCode: '02071',
  },
  'south-wayne-wi': {
    name: 'South Wayne',
    postalCode: '53587',
  },
  'south-webster-oh': {
    name: 'South Webster',
    postalCode: '45682',
  },
  'south-west-city-mo': {
    name: 'South West City',
    postalCode: '64863',
  },
  'south-weymouth-ma': {
    name: 'South Weymouth',
    postalCode: '02190',
  },
  'south-whitley-in': {
    name: 'South Whitley',
    postalCode: '46787',
  },
  'south-williamson-ky': {
    name: 'South Williamson',
    postalCode: '41503',
  },
  'south-willington-ct': {
    name: 'South Willington',
    postalCode: '06265',
  },
  'south-windham-ct': {
    name: 'South Windham',
    postalCode: '06266',
  },
  'south-windsor-ct': {
    name: 'South Windsor',
    postalCode: '06074',
  },
  'south-woodstock-vt': {
    name: 'South Woodstock',
    postalCode: '05071',
  },
  'south-yarmouth-ma': {
    name: 'South Yarmouth',
    postalCode: '02664',
  },
  'southampton-ma': {
    name: 'Southampton',
    postalCode: '01073',
  },
  'southampton-ny': {
    name: 'Southampton',
    postalCode: '11968',
  },
  'southampton-pa': {
    name: 'Southampton',
    postalCode: '18966',
  },
  'southard-ok': {
    name: 'Southard',
    postalCode: '73770',
  },
  'southaven-ms': {
    name: 'Southaven',
    postalCode: '38671',
  },
  'southborough-ma': {
    name: 'Southborough',
    postalCode: '01772',
  },
  'southbridge-ma': {
    name: 'Southbridge',
    postalCode: '01550',
  },
  'southbury-ct': {
    name: 'Southbury',
    postalCode: '06488',
  },
  'southern-pines-nc': {
    name: 'Southern Pines',
    postalCode: '28387',
  },
  'southfield-ma': {
    name: 'Southfield',
    postalCode: '01259',
  },
  'southfield-mi': {
    name: 'Southfield',
    postalCode: '48033',
  },
  'southfields-ny': {
    name: 'Southfields',
    postalCode: '10975',
  },
  'southgate-mi': {
    name: 'Southgate',
    postalCode: '48195',
  },
  'southington-ct': {
    name: 'Southington',
    postalCode: '06489',
  },
  'southington-oh': {
    name: 'Southington',
    postalCode: '44470',
  },
  'southlake-tx': {
    name: 'Southlake',
    postalCode: '76092',
  },
  'southold-ny': {
    name: 'Southold',
    postalCode: '11971',
  },
  'southport-ct': {
    name: 'Southport',
    postalCode: '06890',
  },
  'southport-me': {
    name: 'Southport',
    postalCode: '04576',
  },
  'southport-nc': {
    name: 'Southport',
    postalCode: '28461',
  },
  'southside-tn': {
    name: 'Southside',
    postalCode: '37171',
  },
  'southside-wv': {
    name: 'Southside',
    postalCode: '25187',
  },
  'southwest-harbor-me': {
    name: 'Southwest Harbor',
    postalCode: '04679',
  },
  'southwick-ma': {
    name: 'Southwick',
    postalCode: '01077',
  },
  'spalding-mi': {
    name: 'Spalding',
    postalCode: '49886',
  },
  'spalding-ne': {
    name: 'Spalding',
    postalCode: '68665',
  },
  'spanaway-wa': {
    name: 'Spanaway',
    postalCode: '98387',
  },
  'spangle-wa': {
    name: 'Spangle',
    postalCode: '99031',
  },
  'spangler-pa': {
    name: 'Spangler',
    postalCode: '15775',
  },
  'spanish-fork-ut': {
    name: 'Spanish Fork',
    postalCode: '84660',
  },
  'spanishburg-wv': {
    name: 'Spanishburg',
    postalCode: '25922',
  },
  'sparkill-ny': {
    name: 'Sparkill',
    postalCode: '10976',
  },
  'sparkman-ar': {
    name: 'Sparkman',
    postalCode: '71763',
  },
  'sparks-ga': {
    name: 'Sparks',
    postalCode: '31647',
  },
  'sparks-ne': {
    name: 'Sparks',
    postalCode: '69220',
  },
  'sparks-nv': {
    name: 'Sparks',
    postalCode: '89431',
  },
  'sparks-ok': {
    name: 'Sparks',
    postalCode: '74869',
  },
  'sparks-glencoe-md': {
    name: 'Sparks Glencoe',
    postalCode: '21152',
  },
  'sparland-il': {
    name: 'Sparland',
    postalCode: '61565',
  },
  'sparrow-bush-ny': {
    name: 'Sparrow Bush',
    postalCode: '12780',
  },
  'sparrows-point-md': {
    name: 'Sparrows Point',
    postalCode: '21219',
  },
  'sparta-ga': {
    name: 'Sparta',
    postalCode: '31087',
  },
  'sparta-il': {
    name: 'Sparta',
    postalCode: '62286',
  },
  'sparta-ky': {
    name: 'Sparta',
    postalCode: '41086',
  },
  'sparta-mi': {
    name: 'Sparta',
    postalCode: '49345',
  },
  'sparta-mo': {
    name: 'Sparta',
    postalCode: '65753',
  },
  'sparta-nc': {
    name: 'Sparta',
    postalCode: '28675',
  },
  'sparta-nj': {
    name: 'Sparta',
    postalCode: '07871',
  },
  'sparta-tn': {
    name: 'Sparta',
    postalCode: '38583',
  },
  'sparta-wi': {
    name: 'Sparta',
    postalCode: '54656',
  },
  'spartanburg-sc': {
    name: 'Spartanburg',
    postalCode: '29301',
  },
  'spartansburg-pa': {
    name: 'Spartansburg',
    postalCode: '16434',
  },
  'spavinaw-ok': {
    name: 'Spavinaw',
    postalCode: '74366',
  },
  'spearfish-sd': {
    name: 'Spearfish',
    postalCode: '57783',
  },
  'spearman-tx': {
    name: 'Spearman',
    postalCode: '79081',
  },
  'spearsville-la': {
    name: 'Spearsville',
    postalCode: '71277',
  },
  'spearville-ks': {
    name: 'Spearville',
    postalCode: '67876',
  },
  'speculator-ny': {
    name: 'Speculator',
    postalCode: '12164',
  },
  'speedwell-tn': {
    name: 'Speedwell',
    postalCode: '37870',
  },
  'speedwell-va': {
    name: 'Speedwell',
    postalCode: '24374',
  },
  'speer-il': {
    name: 'Speer',
    postalCode: '61479',
  },
  'spencer-ia': {
    name: 'Spencer',
    postalCode: '51301',
  },
  'spencer-id': {
    name: 'Spencer',
    postalCode: '83446',
  },
  'spencer-in': {
    name: 'Spencer',
    postalCode: '47460',
  },
  'spencer-ma': {
    name: 'Spencer',
    postalCode: '01562',
  },
  'spencer-nc': {
    name: 'Spencer',
    postalCode: '28159',
  },
  'spencer-ne': {
    name: 'Spencer',
    postalCode: '68777',
  },
  'spencer-ny': {
    name: 'Spencer',
    postalCode: '14883',
  },
  'spencer-oh': {
    name: 'Spencer',
    postalCode: '44275',
  },
  'spencer-ok': {
    name: 'Spencer',
    postalCode: '73084',
  },
  'spencer-sd': {
    name: 'Spencer',
    postalCode: '57374',
  },
  'spencer-tn': {
    name: 'Spencer',
    postalCode: '38585',
  },
  'spencer-va': {
    name: 'Spencer',
    postalCode: '24165',
  },
  'spencer-wi': {
    name: 'Spencer',
    postalCode: '54479',
  },
  'spencer-wv': {
    name: 'Spencer',
    postalCode: '25276',
  },
  'spencerport-ny': {
    name: 'Spencerport',
    postalCode: '14559',
  },
  'spencertown-ny': {
    name: 'Spencertown',
    postalCode: '12165',
  },
  'spencerville-in': {
    name: 'Spencerville',
    postalCode: '46788',
  },
  'spencerville-md': {
    name: 'Spencerville',
    postalCode: '20868',
  },
  'spencerville-oh': {
    name: 'Spencerville',
    postalCode: '45887',
  },
  'spencerville-ok': {
    name: 'Spencerville',
    postalCode: '74760',
  },
  'sperry-ia': {
    name: 'Sperry',
    postalCode: '52650',
  },
  'sperry-ok': {
    name: 'Sperry',
    postalCode: '74073',
  },
  'sperryville-va': {
    name: 'Sperryville',
    postalCode: '22740',
  },
  'spiceland-in': {
    name: 'Spiceland',
    postalCode: '47385',
  },
  'spicer-mn': {
    name: 'Spicer',
    postalCode: '56288',
  },
  'spicewood-tx': {
    name: 'Spicewood',
    postalCode: '78669',
  },
  'spickard-mo': {
    name: 'Spickard',
    postalCode: '64679',
  },
  'spindale-nc': {
    name: 'Spindale',
    postalCode: '28160',
  },
  'spirit-lake-ia': {
    name: 'Spirit Lake',
    postalCode: '51360',
  },
  'spirit-lake-id': {
    name: 'Spirit Lake',
    postalCode: '83869',
  },
  'spiritwood-nd': {
    name: 'Spiritwood',
    postalCode: '58481',
  },
  'spiro-ok': {
    name: 'Spiro',
    postalCode: '74959',
  },
  'spivey-ks': {
    name: 'Spivey',
    postalCode: '67142',
  },
  'splendora-tx': {
    name: 'Splendora',
    postalCode: '77372',
  },
  'spofford-nh': {
    name: 'Spofford',
    postalCode: '03462',
  },
  'spokane-mo': {
    name: 'Spokane',
    postalCode: '65754',
  },
  'spokane-wa': {
    name: 'Spokane',
    postalCode: '99201',
  },
  'spooner-wi': {
    name: 'Spooner',
    postalCode: '54801',
  },
  'spotswood-nj': {
    name: 'Spotswood',
    postalCode: '08884',
  },
  'spotsylvania-va': {
    name: 'Spotsylvania',
    postalCode: '22551',
  },
  'spottsville-ky': {
    name: 'Spottsville',
    postalCode: '42458',
  },
  'spout-spring-va': {
    name: 'Spout Spring',
    postalCode: '24593',
  },
  'spraggs-pa': {
    name: 'Spraggs',
    postalCode: '15362',
  },
  'sprague-wa': {
    name: 'Sprague',
    postalCode: '99032',
  },
  'sprague-river-or': {
    name: 'Sprague River',
    postalCode: '97639',
  },
  'spragueville-ia': {
    name: 'Spragueville',
    postalCode: '52074',
  },
  'sprakers-ny': {
    name: 'Sprakers',
    postalCode: '12166',
  },
  'sprankle-mills-pa': {
    name: 'Sprankle Mills',
    postalCode: '15776',
  },
  'spray-or': {
    name: 'Spray',
    postalCode: '97874',
  },
  'spring-tx': {
    name: 'Spring',
    postalCode: '77373',
  },
  'spring-arbor-mi': {
    name: 'Spring Arbor',
    postalCode: '49283',
  },
  'spring-branch-tx': {
    name: 'Spring Branch',
    postalCode: '78070',
  },
  'spring-church-pa': {
    name: 'Spring Church',
    postalCode: '15686',
  },
  'spring-city-pa': {
    name: 'Spring City',
    postalCode: '19475',
  },
  'spring-city-tn': {
    name: 'Spring City',
    postalCode: '37381',
  },
  'spring-creek-nv': {
    name: 'Spring Creek',
    postalCode: '89815',
  },
  'spring-creek-pa': {
    name: 'Spring Creek',
    postalCode: '16436',
  },
  'spring-glen-pa': {
    name: 'Spring Glen',
    postalCode: '17978',
  },
  'spring-green-wi': {
    name: 'Spring Green',
    postalCode: '53588',
  },
  'spring-grove-il': {
    name: 'Spring Grove',
    postalCode: '60081',
  },
  'spring-grove-mn': {
    name: 'Spring Grove',
    postalCode: '55974',
  },
  'spring-grove-pa': {
    name: 'Spring Grove',
    postalCode: '17362',
  },
  'spring-grove-va': {
    name: 'Spring Grove',
    postalCode: '23881',
  },
  'spring-hill-fl': {
    name: 'Spring Hill',
    postalCode: '34606',
  },
  'spring-hill-ks': {
    name: 'Spring Hill',
    postalCode: '66083',
  },
  'spring-hill-tn': {
    name: 'Spring Hill',
    postalCode: '37174',
  },
  'spring-hope-nc': {
    name: 'Spring Hope',
    postalCode: '27882',
  },
  'spring-house-pa': {
    name: 'Spring House',
    postalCode: '19477',
  },
  'spring-lake-mi': {
    name: 'Spring Lake',
    postalCode: '49456',
  },
  'spring-lake-mn': {
    name: 'Spring Lake',
    postalCode: '56680',
  },
  'spring-lake-nc': {
    name: 'Spring Lake',
    postalCode: '28390',
  },
  'spring-lake-nj': {
    name: 'Spring Lake',
    postalCode: '07762',
  },
  'spring-mills-pa': {
    name: 'Spring Mills',
    postalCode: '16875',
  },
  'spring-park-mn': {
    name: 'Spring Park',
    postalCode: '55384',
  },
  'spring-run-pa': {
    name: 'Spring Run',
    postalCode: '17262',
  },
  'spring-valley-ca': {
    name: 'Spring Valley',
    postalCode: '91977',
  },
  'spring-valley-il': {
    name: 'Spring Valley',
    postalCode: '61362',
  },
  'spring-valley-mn': {
    name: 'Spring Valley',
    postalCode: '55975',
  },
  'spring-valley-ny': {
    name: 'Spring Valley',
    postalCode: '10977',
  },
  'spring-valley-oh': {
    name: 'Spring Valley',
    postalCode: '45370',
  },
  'spring-valley-wi': {
    name: 'Spring Valley',
    postalCode: '54767',
  },
  'springboro-oh': {
    name: 'Springboro',
    postalCode: '45066',
  },
  'springboro-pa': {
    name: 'Springboro',
    postalCode: '16435',
  },
  'springbrook-wi': {
    name: 'Springbrook',
    postalCode: '54875',
  },
  'springdale-ar': {
    name: 'Springdale',
    postalCode: '72762',
  },
  'springdale-mt': {
    name: 'Springdale',
    postalCode: '59082',
  },
  'springdale-pa': {
    name: 'Springdale',
    postalCode: '15144',
  },
  'springdale-wa': {
    name: 'Springdale',
    postalCode: '99173',
  },
  'springer-nm': {
    name: 'Springer',
    postalCode: '87747',
  },
  'springer-ok': {
    name: 'Springer',
    postalCode: '73458',
  },
  'springerton-il': {
    name: 'Springerton',
    postalCode: '62887',
  },
  'springerville-az': {
    name: 'Springerville',
    postalCode: '85938',
  },
  'springfield-ar': {
    name: 'Springfield',
    postalCode: '72157',
  },
  'springfield-co': {
    name: 'Springfield',
    postalCode: '81073',
  },
  'springfield-ga': {
    name: 'Springfield',
    postalCode: '31329',
  },
  'springfield-id': {
    name: 'Springfield',
    postalCode: '83277',
  },
  'springfield-il': {
    name: 'Springfield',
    postalCode: '62701',
  },
  'springfield-ky': {
    name: 'Springfield',
    postalCode: '40069',
  },
  'springfield-la': {
    name: 'Springfield',
    postalCode: '70462',
  },
  'springfield-ma': {
    name: 'Springfield',
    postalCode: '01103',
  },
  'springfield-me': {
    name: 'Springfield',
    postalCode: '04487',
  },
  'springfield-mn': {
    name: 'Springfield',
    postalCode: '56087',
  },
  'springfield-mo': {
    name: 'Springfield',
    postalCode: '65802',
  },
  'springfield-ne': {
    name: 'Springfield',
    postalCode: '68059',
  },
  'springfield-nh': {
    name: 'Springfield',
    postalCode: '03284',
  },
  'springfield-nj': {
    name: 'Springfield',
    postalCode: '07081',
  },
  'springfield-oh': {
    name: 'Springfield',
    postalCode: '45502',
  },
  'springfield-or': {
    name: 'Springfield',
    postalCode: '97477',
  },
  'springfield-pa': {
    name: 'Springfield',
    postalCode: '19064',
  },
  'springfield-sc': {
    name: 'Springfield',
    postalCode: '29146',
  },
  'springfield-sd': {
    name: 'Springfield',
    postalCode: '57062',
  },
  'springfield-tn': {
    name: 'Springfield',
    postalCode: '37172',
  },
  'springfield-va': {
    name: 'Springfield',
    postalCode: '22150',
  },
  'springfield-vt': {
    name: 'Springfield',
    postalCode: '05156',
  },
  'springfield-wv': {
    name: 'Springfield',
    postalCode: '26763',
  },
  'springfield-center-ny': {
    name: 'Springfield Center',
    postalCode: '13468',
  },
  'springfield-gardens-ny': {
    name: 'Springfield Gardens',
    postalCode: '11413',
  },
  'springhill-la': {
    name: 'Springhill',
    postalCode: '71075',
  },
  'springlake-tx': {
    name: 'Springlake',
    postalCode: '79082',
  },
  'springport-in': {
    name: 'Springport',
    postalCode: '47386',
  },
  'springport-mi': {
    name: 'Springport',
    postalCode: '49284',
  },
  'springs-pa': {
    name: 'Springs',
    postalCode: '15562',
  },
  'springtown-tx': {
    name: 'Springtown',
    postalCode: '76082',
  },
  'springvale-me': {
    name: 'Springvale',
    postalCode: '04083',
  },
  'springview-ne': {
    name: 'Springview',
    postalCode: '68778',
  },
  'springville-al': {
    name: 'Springville',
    postalCode: '35146',
  },
  'springville-ca': {
    name: 'Springville',
    postalCode: '93265',
  },
  'springville-ia': {
    name: 'Springville',
    postalCode: '52336',
  },
  'springville-in': {
    name: 'Springville',
    postalCode: '47462',
  },
  'springville-ny': {
    name: 'Springville',
    postalCode: '14141',
  },
  'springville-pa': {
    name: 'Springville',
    postalCode: '18844',
  },
  'springville-tn': {
    name: 'Springville',
    postalCode: '38256',
  },
  'springville-ut': {
    name: 'Springville',
    postalCode: '84663',
  },
  'springwater-ny': {
    name: 'Springwater',
    postalCode: '14560',
  },
  'sproul-pa': {
    name: 'Sproul',
    postalCode: '16682',
  },
  'spruce-mi': {
    name: 'Spruce',
    postalCode: '48762',
  },
  'spruce-creek-pa': {
    name: 'Spruce Creek',
    postalCode: '16683',
  },
  'spruce-head-me': {
    name: 'Spruce Head',
    postalCode: '04859',
  },
  'spruce-pine-al': {
    name: 'Spruce Pine',
    postalCode: '35585',
  },
  'spruce-pine-nc': {
    name: 'Spruce Pine',
    postalCode: '28777',
  },
  'spur-tx': {
    name: 'Spur',
    postalCode: '79370',
  },
  'spurger-tx': {
    name: 'Spurger',
    postalCode: '77660',
  },
  'spurlockville-wv': {
    name: 'Spurlockville',
    postalCode: '25565',
  },
  'squaw-lake-mn': {
    name: 'Squaw Lake',
    postalCode: '56681',
  },
  'squaw-valley-ca': {
    name: 'Squaw Valley',
    postalCode: '93675',
  },
  'squire-wv': {
    name: 'Squire',
    postalCode: '24884',
  },
  'squires-mo': {
    name: 'Squires',
    postalCode: '65755',
  },
  'st-columbans-ne': {
    name: 'St Columbans',
    postalCode: '68056',
  },
  'st-john-ks': {
    name: 'St John',
    postalCode: '67576',
  },
  'st-john-vi': {
    name: 'St John',
    postalCode: '00830',
  },
  'st-thomas-vi': {
    name: 'St Thomas',
    postalCode: '00802',
  },
  'staatsburg-ny': {
    name: 'Staatsburg',
    postalCode: '12580',
  },
  'stacy-mn': {
    name: 'Stacy',
    postalCode: '55079',
  },
  'stacy-nc': {
    name: 'Stacy',
    postalCode: '28581',
  },
  'stacyville-ia': {
    name: 'Stacyville',
    postalCode: '50476',
  },
  'stacyville-me': {
    name: 'Stacyville',
    postalCode: '04777',
  },
  'stafford-ks': {
    name: 'Stafford',
    postalCode: '67578',
  },
  'stafford-ny': {
    name: 'Stafford',
    postalCode: '14143',
  },
  'stafford-tx': {
    name: 'Stafford',
    postalCode: '77477',
  },
  'stafford-va': {
    name: 'Stafford',
    postalCode: '22554',
  },
  'stafford-springs-ct': {
    name: 'Stafford Springs',
    postalCode: '06076',
  },
  'staffordsville-ky': {
    name: 'Staffordsville',
    postalCode: '41256',
  },
  'staffordsville-va': {
    name: 'Staffordsville',
    postalCode: '24167',
  },
  'stahlstown-pa': {
    name: 'Stahlstown',
    postalCode: '15687',
  },
  'staley-nc': {
    name: 'Staley',
    postalCode: '27355',
  },
  'stambaugh-ky': {
    name: 'Stambaugh',
    postalCode: '41257',
  },
  'stamford-ct': {
    name: 'Stamford',
    postalCode: '06901',
  },
  'stamford-ne': {
    name: 'Stamford',
    postalCode: '68977',
  },
  'stamford-ny': {
    name: 'Stamford',
    postalCode: '12167',
  },
  'stamford-tx': {
    name: 'Stamford',
    postalCode: '79553',
  },
  'stamford-vt': {
    name: 'Stamford',
    postalCode: '05352',
  },
  'stamping-ground-ky': {
    name: 'Stamping Ground',
    postalCode: '40379',
  },
  'stamps-ar': {
    name: 'Stamps',
    postalCode: '71860',
  },
  'stanardsville-va': {
    name: 'Stanardsville',
    postalCode: '22973',
  },
  'stanberry-mo': {
    name: 'Stanberry',
    postalCode: '64489',
  },
  'stanchfield-mn': {
    name: 'Stanchfield',
    postalCode: '55080',
  },
  'standish-ca': {
    name: 'Standish',
    postalCode: '96128',
  },
  'standish-me': {
    name: 'Standish',
    postalCode: '04084',
  },
  'standish-mi': {
    name: 'Standish',
    postalCode: '48658',
  },
  'stanfield-az': {
    name: 'Stanfield',
    postalCode: '85172',
  },
  'stanfield-nc': {
    name: 'Stanfield',
    postalCode: '28163',
  },
  'stanfield-or': {
    name: 'Stanfield',
    postalCode: '97875',
  },
  'stanford-ca': {
    name: 'Stanford',
    postalCode: '94305',
  },
  'stanford-il': {
    name: 'Stanford',
    postalCode: '61774',
  },
  'stanford-ky': {
    name: 'Stanford',
    postalCode: '40484',
  },
  'stanford-mt': {
    name: 'Stanford',
    postalCode: '59479',
  },
  'stanfordville-ny': {
    name: 'Stanfordville',
    postalCode: '12581',
  },
  'stanhope-ia': {
    name: 'Stanhope',
    postalCode: '50246',
  },
  'stanhope-nj': {
    name: 'Stanhope',
    postalCode: '07874',
  },
  'stanley-ia': {
    name: 'Stanley',
    postalCode: '50671',
  },
  'stanley-id': {
    name: 'Stanley',
    postalCode: '83278',
  },
  'stanley-ky': {
    name: 'Stanley',
    postalCode: '42375',
  },
  'stanley-nc': {
    name: 'Stanley',
    postalCode: '28164',
  },
  'stanley-nd': {
    name: 'Stanley',
    postalCode: '58784',
  },
  'stanley-nm': {
    name: 'Stanley',
    postalCode: '87056',
  },
  'stanley-ny': {
    name: 'Stanley',
    postalCode: '14561',
  },
  'stanley-va': {
    name: 'Stanley',
    postalCode: '22851',
  },
  'stanley-wi': {
    name: 'Stanley',
    postalCode: '54768',
  },
  'stanleytown-va': {
    name: 'Stanleytown',
    postalCode: '24168',
  },
  'stanton-al': {
    name: 'Stanton',
    postalCode: '36790',
  },
  'stanton-ca': {
    name: 'Stanton',
    postalCode: '90680',
  },
  'stanton-ia': {
    name: 'Stanton',
    postalCode: '51573',
  },
  'stanton-ky': {
    name: 'Stanton',
    postalCode: '40380',
  },
  'stanton-mi': {
    name: 'Stanton',
    postalCode: '48888',
  },
  'stanton-nd': {
    name: 'Stanton',
    postalCode: '58571',
  },
  'stanton-ne': {
    name: 'Stanton',
    postalCode: '68779',
  },
  'stanton-tn': {
    name: 'Stanton',
    postalCode: '38069',
  },
  'stanton-tx': {
    name: 'Stanton',
    postalCode: '79782',
  },
  'stantonsburg-nc': {
    name: 'Stantonsburg',
    postalCode: '27883',
  },
  'stantonville-tn': {
    name: 'Stantonville',
    postalCode: '38379',
  },
  'stanville-ky': {
    name: 'Stanville',
    postalCode: '41659',
  },
  'stanwood-ia': {
    name: 'Stanwood',
    postalCode: '52337',
  },
  'stanwood-mi': {
    name: 'Stanwood',
    postalCode: '49346',
  },
  'stanwood-wa': {
    name: 'Stanwood',
    postalCode: '98292',
  },
  'staplehurst-ne': {
    name: 'Staplehurst',
    postalCode: '68439',
  },
  'staples-mn': {
    name: 'Staples',
    postalCode: '56479',
  },
  'stapleton-al': {
    name: 'Stapleton',
    postalCode: '36578',
  },
  'stapleton-ga': {
    name: 'Stapleton',
    postalCode: '30823',
  },
  'stapleton-ne': {
    name: 'Stapleton',
    postalCode: '69163',
  },
  'star-id': {
    name: 'Star',
    postalCode: '83669',
  },
  'star-nc': {
    name: 'Star',
    postalCode: '27356',
  },
  'star-city-ar': {
    name: 'Star City',
    postalCode: '71667',
  },
  'star-city-in': {
    name: 'Star City',
    postalCode: '46985',
  },
  'star-lake-ny': {
    name: 'Star Lake',
    postalCode: '13690',
  },
  'star-lake-wi': {
    name: 'Star Lake',
    postalCode: '54561',
  },
  'star-prairie-wi': {
    name: 'Star Prairie',
    postalCode: '54026',
  },
  'star-tannery-va': {
    name: 'Star Tannery',
    postalCode: '22654',
  },
  'starbuck-mn': {
    name: 'Starbuck',
    postalCode: '56381',
  },
  'starford-pa': {
    name: 'Starford',
    postalCode: '15777',
  },
  'stark-ks': {
    name: 'Stark',
    postalCode: '66775',
  },
  'stark-city-mo': {
    name: 'Stark City',
    postalCode: '64866',
  },
  'starke-fl': {
    name: 'Starke',
    postalCode: '32091',
  },
  'starks-la': {
    name: 'Starks',
    postalCode: '70661',
  },
  'starksboro-vt': {
    name: 'Starksboro',
    postalCode: '05487',
  },
  'starkville-ms': {
    name: 'Starkville',
    postalCode: '39759',
  },
  'starkweather-nd': {
    name: 'Starkweather',
    postalCode: '58377',
  },
  'starlight-pa': {
    name: 'Starlight',
    postalCode: '18461',
  },
  'starr-sc': {
    name: 'Starr',
    postalCode: '29684',
  },
  'starrucca-pa': {
    name: 'Starrucca',
    postalCode: '18462',
  },
  'state-center-ia': {
    name: 'State Center',
    postalCode: '50247',
  },
  'state-college-pa': {
    name: 'State College',
    postalCode: '16801',
  },
  'state-farm-va': {
    name: 'State Farm',
    postalCode: '23160',
  },
  'state-line-ms': {
    name: 'State Line',
    postalCode: '39362',
  },
  'state-road-nc': {
    name: 'State Road',
    postalCode: '28676',
  },
  'staten-island-ny': {
    name: 'Staten Island',
    postalCode: '10301',
  },
  'statenville-ga': {
    name: 'Statenville',
    postalCode: '31648',
  },
  'statesboro-ga': {
    name: 'Statesboro',
    postalCode: '30458',
  },
  'statesville-nc': {
    name: 'Statesville',
    postalCode: '28625',
  },
  'statham-ga': {
    name: 'Statham',
    postalCode: '30666',
  },
  'staunton-il': {
    name: 'Staunton',
    postalCode: '62088',
  },
  'staunton-va': {
    name: 'Staunton',
    postalCode: '24401',
  },
  'stayton-or': {
    name: 'Stayton',
    postalCode: '97383',
  },
  'steamboat-rock-ia': {
    name: 'Steamboat Rock',
    postalCode: '50672',
  },
  'steamboat-springs-co': {
    name: 'Steamboat Springs',
    postalCode: '80487',
  },
  'stearns-ky': {
    name: 'Stearns',
    postalCode: '42647',
  },
  'stedman-nc': {
    name: 'Stedman',
    postalCode: '28391',
  },
  'steedman-mo': {
    name: 'Steedman',
    postalCode: '65077',
  },
  'steele-al': {
    name: 'Steele',
    postalCode: '35987',
  },
  'steele-ky': {
    name: 'Steele',
    postalCode: '41566',
  },
  'steele-mo': {
    name: 'Steele',
    postalCode: '63877',
  },
  'steele-nd': {
    name: 'Steele',
    postalCode: '58482',
  },
  'steele-city-ne': {
    name: 'Steele City',
    postalCode: '68440',
  },
  'steeles-tavern-va': {
    name: 'Steeles Tavern',
    postalCode: '24476',
  },
  'steeleville-il': {
    name: 'Steeleville',
    postalCode: '62288',
  },
  'steelville-mo': {
    name: 'Steelville',
    postalCode: '65565',
  },
  'steen-mn': {
    name: 'Steen',
    postalCode: '56173',
  },
  'steens-ms': {
    name: 'Steens',
    postalCode: '39766',
  },
  'steep-falls-me': {
    name: 'Steep Falls',
    postalCode: '04085',
  },
  'steger-il': {
    name: 'Steger',
    postalCode: '60475',
  },
  'steilacoom-wa': {
    name: 'Steilacoom',
    postalCode: '98388',
  },
  'steinauer-ne': {
    name: 'Steinauer',
    postalCode: '68441',
  },
  'steinhatchee-fl': {
    name: 'Steinhatchee',
    postalCode: '32359',
  },
  'stella-mo': {
    name: 'Stella',
    postalCode: '64867',
  },
  'stella-nc': {
    name: 'Stella',
    postalCode: '28582',
  },
  'stella-ne': {
    name: 'Stella',
    postalCode: '68442',
  },
  'stella-niagara-ny': {
    name: 'Stella Niagara',
    postalCode: '14144',
  },
  'stem-nc': {
    name: 'Stem',
    postalCode: '27581',
  },
  'stendal-in': {
    name: 'Stendal',
    postalCode: '47585',
  },
  'stephan-sd': {
    name: 'Stephan',
    postalCode: '57346',
  },
  'stephen-mn': {
    name: 'Stephen',
    postalCode: '56757',
  },
  'stephens-ar': {
    name: 'Stephens',
    postalCode: '71764',
  },
  'stephens-ga': {
    name: 'Stephens',
    postalCode: '30667',
  },
  'stephens-city-va': {
    name: 'Stephens City',
    postalCode: '22655',
  },
  'stephenson-mi': {
    name: 'Stephenson',
    postalCode: '49887',
  },
  'stephenson-va': {
    name: 'Stephenson',
    postalCode: '22656',
  },
  'stephenson-wv': {
    name: 'Stephenson',
    postalCode: '25928',
  },
  'stephensport-ky': {
    name: 'Stephensport',
    postalCode: '40170',
  },
  'stephentown-ny': {
    name: 'Stephentown',
    postalCode: '12168',
  },
  'stephenville-tx': {
    name: 'Stephenville',
    postalCode: '76401',
  },
  'sterling-ak': {
    name: 'Sterling',
    postalCode: '99672',
  },
  'sterling-co': {
    name: 'Sterling',
    postalCode: '80751',
  },
  'sterling-ct': {
    name: 'Sterling',
    postalCode: '06377',
  },
  'sterling-il': {
    name: 'Sterling',
    postalCode: '61081',
  },
  'sterling-ks': {
    name: 'Sterling',
    postalCode: '67579',
  },
  'sterling-ma': {
    name: 'Sterling',
    postalCode: '01564',
  },
  'sterling-mi': {
    name: 'Sterling',
    postalCode: '48659',
  },
  'sterling-nd': {
    name: 'Sterling',
    postalCode: '58572',
  },
  'sterling-ne': {
    name: 'Sterling',
    postalCode: '68443',
  },
  'sterling-ny': {
    name: 'Sterling',
    postalCode: '13156',
  },
  'sterling-oh': {
    name: 'Sterling',
    postalCode: '44276',
  },
  'sterling-pa': {
    name: 'Sterling',
    postalCode: '18463',
  },
  'sterling-va': {
    name: 'Sterling',
    postalCode: '20164',
  },
  'sterling-city-tx': {
    name: 'Sterling City',
    postalCode: '76951',
  },
  'sterling-heights-mi': {
    name: 'Sterling Heights',
    postalCode: '48310',
  },
  'sterlington-la': {
    name: 'Sterlington',
    postalCode: '71280',
  },
  'sterrett-al': {
    name: 'Sterrett',
    postalCode: '35147',
  },
  'stetson-me': {
    name: 'Stetson',
    postalCode: '04488',
  },
  'stetsonville-wi': {
    name: 'Stetsonville',
    postalCode: '54480',
  },
  'steuben-me': {
    name: 'Steuben',
    postalCode: '04680',
  },
  'steuben-wi': {
    name: 'Steuben',
    postalCode: '54657',
  },
  'steubenville-oh': {
    name: 'Steubenville',
    postalCode: '43952',
  },
  'stevens-pa': {
    name: 'Stevens',
    postalCode: '17578',
  },
  'stevens-point-wi': {
    name: 'Stevens Point',
    postalCode: '54481',
  },
  'stevensburg-va': {
    name: 'Stevensburg',
    postalCode: '22741',
  },
  'stevenson-al': {
    name: 'Stevenson',
    postalCode: '35772',
  },
  'stevenson-wa': {
    name: 'Stevenson',
    postalCode: '98648',
  },
  'stevenson-ranch-ca': {
    name: 'Stevenson Ranch',
    postalCode: '91381',
  },
  'stevensville-md': {
    name: 'Stevensville',
    postalCode: '21666',
  },
  'stevensville-mi': {
    name: 'Stevensville',
    postalCode: '49127',
  },
  'stevensville-mt': {
    name: 'Stevensville',
    postalCode: '59870',
  },
  'stevensville-pa': {
    name: 'Stevensville',
    postalCode: '18845',
  },
  'stevensville-va': {
    name: 'Stevensville',
    postalCode: '23161',
  },
  'stevinson-ca': {
    name: 'Stevinson',
    postalCode: '95374',
  },
  'steward-il': {
    name: 'Steward',
    postalCode: '60553',
  },
  'stewardson-il': {
    name: 'Stewardson',
    postalCode: '62463',
  },
  'stewart-mn': {
    name: 'Stewart',
    postalCode: '55385',
  },
  'stewart-ms': {
    name: 'Stewart',
    postalCode: '39767',
  },
  'stewart-oh': {
    name: 'Stewart',
    postalCode: '45778',
  },
  'stewart-tn': {
    name: 'Stewart',
    postalCode: '37175',
  },
  'stewartstown-pa': {
    name: 'Stewartstown',
    postalCode: '17363',
  },
  'stewartsville-mo': {
    name: 'Stewartsville',
    postalCode: '64490',
  },
  'stewartsville-nj': {
    name: 'Stewartsville',
    postalCode: '08886',
  },
  'stewartville-mn': {
    name: 'Stewartville',
    postalCode: '55976',
  },
  'stickney-sd': {
    name: 'Stickney',
    postalCode: '57375',
  },
  'stidham-ok': {
    name: 'Stidham',
    postalCode: '74461',
  },
  'stigler-ok': {
    name: 'Stigler',
    postalCode: '74462',
  },
  'stilesville-in': {
    name: 'Stilesville',
    postalCode: '46180',
  },
  'still-pond-md': {
    name: 'Still Pond',
    postalCode: '21667',
  },
  'stillman-valley-il': {
    name: 'Stillman Valley',
    postalCode: '61084',
  },
  'stillwater-mn': {
    name: 'Stillwater',
    postalCode: '55082',
  },
  'stillwater-ny': {
    name: 'Stillwater',
    postalCode: '12170',
  },
  'stillwater-ok': {
    name: 'Stillwater',
    postalCode: '74074',
  },
  'stillwater-pa': {
    name: 'Stillwater',
    postalCode: '17878',
  },
  'stilwell-ks': {
    name: 'Stilwell',
    postalCode: '66085',
  },
  'stilwell-ok': {
    name: 'Stilwell',
    postalCode: '74960',
  },
  'stinnett-ky': {
    name: 'Stinnett',
    postalCode: '40868',
  },
  'stinnett-tx': {
    name: 'Stinnett',
    postalCode: '79083',
  },
  'stinson-beach-ca': {
    name: 'Stinson Beach',
    postalCode: '94970',
  },
  'stirling-nj': {
    name: 'Stirling',
    postalCode: '07980',
  },
  'stirum-nd': {
    name: 'Stirum',
    postalCode: '58069',
  },
  'stites-id': {
    name: 'Stites',
    postalCode: '83552',
  },
  'stittville-ny': {
    name: 'Stittville',
    postalCode: '13469',
  },
  'stitzer-wi': {
    name: 'Stitzer',
    postalCode: '53825',
  },
  'stockbridge-ga': {
    name: 'Stockbridge',
    postalCode: '30281',
  },
  'stockbridge-mi': {
    name: 'Stockbridge',
    postalCode: '49285',
  },
  'stockbridge-vt': {
    name: 'Stockbridge',
    postalCode: '05772',
  },
  'stockdale-tx': {
    name: 'Stockdale',
    postalCode: '78160',
  },
  'stockett-mt': {
    name: 'Stockett',
    postalCode: '59480',
  },
  'stockholm-me': {
    name: 'Stockholm',
    postalCode: '04783',
  },
  'stockholm-nj': {
    name: 'Stockholm',
    postalCode: '07460',
  },
  'stockholm-sd': {
    name: 'Stockholm',
    postalCode: '57264',
  },
  'stockholm-wi': {
    name: 'Stockholm',
    postalCode: '54769',
  },
  'stockport-ia': {
    name: 'Stockport',
    postalCode: '52651',
  },
  'stockport-oh': {
    name: 'Stockport',
    postalCode: '43787',
  },
  'stockton-al': {
    name: 'Stockton',
    postalCode: '36579',
  },
  'stockton-ca': {
    name: 'Stockton',
    postalCode: '95202',
  },
  'stockton-ga': {
    name: 'Stockton',
    postalCode: '31649',
  },
  'stockton-ia': {
    name: 'Stockton',
    postalCode: '52769',
  },
  'stockton-il': {
    name: 'Stockton',
    postalCode: '61085',
  },
  'stockton-ks': {
    name: 'Stockton',
    postalCode: '67669',
  },
  'stockton-md': {
    name: 'Stockton',
    postalCode: '21864',
  },
  'stockton-mo': {
    name: 'Stockton',
    postalCode: '65785',
  },
  'stockton-nj': {
    name: 'Stockton',
    postalCode: '08559',
  },
  'stockton-ny': {
    name: 'Stockton',
    postalCode: '14784',
  },
  'stockton-ut': {
    name: 'Stockton',
    postalCode: '84071',
  },
  'stockton-springs-me': {
    name: 'Stockton Springs',
    postalCode: '04981',
  },
  'stockville-ne': {
    name: 'Stockville',
    postalCode: '69042',
  },
  'stoddard-nh': {
    name: 'Stoddard',
    postalCode: '03464',
  },
  'stoddard-wi': {
    name: 'Stoddard',
    postalCode: '54658',
  },
  'stokes-nc': {
    name: 'Stokes',
    postalCode: '27884',
  },
  'stokesdale-nc': {
    name: 'Stokesdale',
    postalCode: '27357',
  },
  'stone-ky': {
    name: 'Stone',
    postalCode: '41567',
  },
  'stone-creek-oh': {
    name: 'Stone Creek',
    postalCode: '43840',
  },
  'stone-harbor-nj': {
    name: 'Stone Harbor',
    postalCode: '08247',
  },
  'stone-lake-wi': {
    name: 'Stone Lake',
    postalCode: '54876',
  },
  'stone-mountain-ga': {
    name: 'Stone Mountain',
    postalCode: '30083',
  },
  'stone-park-il': {
    name: 'Stone Park',
    postalCode: '60165',
  },
  'stone-ridge-ny': {
    name: 'Stone Ridge',
    postalCode: '12484',
  },
  'stoneboro-pa': {
    name: 'Stoneboro',
    postalCode: '16153',
  },
  'stonefort-il': {
    name: 'Stonefort',
    postalCode: '62987',
  },
  'stoneham-co': {
    name: 'Stoneham',
    postalCode: '80754',
  },
  'stoneham-ma': {
    name: 'Stoneham',
    postalCode: '02180',
  },
  'stoneham-me': {
    name: 'Stoneham',
    postalCode: '04231',
  },
  'stoneville-nc': {
    name: 'Stoneville',
    postalCode: '27048',
  },
  'stonewall-la': {
    name: 'Stonewall',
    postalCode: '71078',
  },
  'stonewall-ms': {
    name: 'Stonewall',
    postalCode: '39363',
  },
  'stonewall-ok': {
    name: 'Stonewall',
    postalCode: '74871',
  },
  'stonewall-tx': {
    name: 'Stonewall',
    postalCode: '78671',
  },
  'stoney-fork-ky': {
    name: 'Stoney Fork',
    postalCode: '40988',
  },
  'stonington-co': {
    name: 'Stonington',
    postalCode: '81075',
  },
  'stonington-ct': {
    name: 'Stonington',
    postalCode: '06378',
  },
  'stonington-il': {
    name: 'Stonington',
    postalCode: '62567',
  },
  'stonington-me': {
    name: 'Stonington',
    postalCode: '04681',
  },
  'stony-brook-ny': {
    name: 'Stony Brook',
    postalCode: '11790',
  },
  'stony-creek-ny': {
    name: 'Stony Creek',
    postalCode: '12878',
  },
  'stony-creek-va': {
    name: 'Stony Creek',
    postalCode: '23882',
  },
  'stony-point-nc': {
    name: 'Stony Point',
    postalCode: '28678',
  },
  'stony-point-ny': {
    name: 'Stony Point',
    postalCode: '10980',
  },
  'stonyford-ca': {
    name: 'Stonyford',
    postalCode: '95979',
  },
  'stopover-ky': {
    name: 'Stopover',
    postalCode: '41568',
  },
  'storden-mn': {
    name: 'Storden',
    postalCode: '56174',
  },
  'storm-lake-ia': {
    name: 'Storm Lake',
    postalCode: '50588',
  },
  'stormville-ny': {
    name: 'Stormville',
    postalCode: '12582',
  },
  'storrs-mansfield-ct': {
    name: 'Storrs Mansfield',
    postalCode: '06268',
  },
  'story-ar': {
    name: 'Story',
    postalCode: '71970',
  },
  'story-wy': {
    name: 'Story',
    postalCode: '82842',
  },
  'story-city-ia': {
    name: 'Story City',
    postalCode: '50248',
  },
  'stotts-city-mo': {
    name: 'Stotts City',
    postalCode: '65756',
  },
  'stoughton-ma': {
    name: 'Stoughton',
    postalCode: '02072',
  },
  'stoughton-wi': {
    name: 'Stoughton',
    postalCode: '53589',
  },
  'stout-oh': {
    name: 'Stout',
    postalCode: '45684',
  },
  'stoutland-mo': {
    name: 'Stoutland',
    postalCode: '65567',
  },
  'stoutsville-mo': {
    name: 'Stoutsville',
    postalCode: '65283',
  },
  'stoutsville-oh': {
    name: 'Stoutsville',
    postalCode: '43154',
  },
  'stover-mo': {
    name: 'Stover',
    postalCode: '65078',
  },
  'stow-ma': {
    name: 'Stow',
    postalCode: '01775',
  },
  'stow-oh': {
    name: 'Stow',
    postalCode: '44224',
  },
  'stowe-vt': {
    name: 'Stowe',
    postalCode: '05672',
  },
  'stoystown-pa': {
    name: 'Stoystown',
    postalCode: '15563',
  },
  'strabane-pa': {
    name: 'Strabane',
    postalCode: '15363',
  },
  'strafford-mo': {
    name: 'Strafford',
    postalCode: '65757',
  },
  'strafford-nh': {
    name: 'Strafford',
    postalCode: '03884',
  },
  'strafford-vt': {
    name: 'Strafford',
    postalCode: '05072',
  },
  'strandburg-sd': {
    name: 'Strandburg',
    postalCode: '57265',
  },
  'strandquist-mn': {
    name: 'Strandquist',
    postalCode: '56758',
  },
  'strang-ne': {
    name: 'Strang',
    postalCode: '68444',
  },
  'strang-ok': {
    name: 'Strang',
    postalCode: '74367',
  },
  'strasburg-co': {
    name: 'Strasburg',
    postalCode: '80136',
  },
  'strasburg-il': {
    name: 'Strasburg',
    postalCode: '62465',
  },
  'strasburg-nd': {
    name: 'Strasburg',
    postalCode: '58573',
  },
  'strasburg-oh': {
    name: 'Strasburg',
    postalCode: '44680',
  },
  'strasburg-pa': {
    name: 'Strasburg',
    postalCode: '17579',
  },
  'strasburg-va': {
    name: 'Strasburg',
    postalCode: '22641',
  },
  'stratford-ca': {
    name: 'Stratford',
    postalCode: '93266',
  },
  'stratford-ct': {
    name: 'Stratford',
    postalCode: '06497',
  },
  'stratford-ia': {
    name: 'Stratford',
    postalCode: '50249',
  },
  'stratford-nj': {
    name: 'Stratford',
    postalCode: '08084',
  },
  'stratford-ny': {
    name: 'Stratford',
    postalCode: '13470',
  },
  'stratford-ok': {
    name: 'Stratford',
    postalCode: '74872',
  },
  'stratford-sd': {
    name: 'Stratford',
    postalCode: '57474',
  },
  'stratford-tx': {
    name: 'Stratford',
    postalCode: '79084',
  },
  'stratford-wi': {
    name: 'Stratford',
    postalCode: '54484',
  },
  'stratham-nh': {
    name: 'Stratham',
    postalCode: '03885',
  },
  'strathcona-mn': {
    name: 'Strathcona',
    postalCode: '56759',
  },
  'strathmore-ca': {
    name: 'Strathmore',
    postalCode: '93267',
  },
  'strattanville-pa': {
    name: 'Strattanville',
    postalCode: '16258',
  },
  'stratton-co': {
    name: 'Stratton',
    postalCode: '80836',
  },
  'stratton-me': {
    name: 'Stratton',
    postalCode: '04982',
  },
  'stratton-ne': {
    name: 'Stratton',
    postalCode: '69043',
  },
  'straughn-in': {
    name: 'Straughn',
    postalCode: '47387',
  },
  'strawberry-ar': {
    name: 'Strawberry',
    postalCode: '72469',
  },
  'strawberry-plains-tn': {
    name: 'Strawberry Plains',
    postalCode: '37871',
  },
  'strawberry-point-ia': {
    name: 'Strawberry Point',
    postalCode: '52076',
  },
  'strawberry-valley-ca': {
    name: 'Strawberry Valley',
    postalCode: '95981',
  },
  'strawn-il': {
    name: 'Strawn',
    postalCode: '61775',
  },
  'strawn-tx': {
    name: 'Strawn',
    postalCode: '76475',
  },
  'streamwood-il': {
    name: 'Streamwood',
    postalCode: '60107',
  },
  'streator-il': {
    name: 'Streator',
    postalCode: '61364',
  },
  'street-md': {
    name: 'Street',
    postalCode: '21154',
  },
  'streeter-nd': {
    name: 'Streeter',
    postalCode: '58483',
  },
  'streetman-tx': {
    name: 'Streetman',
    postalCode: '75859',
  },
  'streetsboro-oh': {
    name: 'Streetsboro',
    postalCode: '44241',
  },
  'stringer-ms': {
    name: 'Stringer',
    postalCode: '39481',
  },
  'stringtown-ok': {
    name: 'Stringtown',
    postalCode: '74569',
  },
  'stromsburg-ne': {
    name: 'Stromsburg',
    postalCode: '68666',
  },
  'strong-ar': {
    name: 'Strong',
    postalCode: '71765',
  },
  'strong-me': {
    name: 'Strong',
    postalCode: '04983',
  },
  'strong-city-ks': {
    name: 'Strong City',
    postalCode: '66869',
  },
  'stronghurst-il': {
    name: 'Stronghurst',
    postalCode: '61480',
  },
  'strongstown-pa': {
    name: 'Strongstown',
    postalCode: '15957',
  },
  'strongsville-oh': {
    name: 'Strongsville',
    postalCode: '44136',
  },
  'stroud-ok': {
    name: 'Stroud',
    postalCode: '74079',
  },
  'stroudsburg-pa': {
    name: 'Stroudsburg',
    postalCode: '18360',
  },
  'strum-wi': {
    name: 'Strum',
    postalCode: '54770',
  },
  'strunk-ky': {
    name: 'Strunk',
    postalCode: '42649',
  },
  'struthers-oh': {
    name: 'Struthers',
    postalCode: '44471',
  },
  'stryker-oh': {
    name: 'Stryker',
    postalCode: '43557',
  },
  'strykersville-ny': {
    name: 'Strykersville',
    postalCode: '14145',
  },
  'stuart-fl': {
    name: 'Stuart',
    postalCode: '34994',
  },
  'stuart-ia': {
    name: 'Stuart',
    postalCode: '50250',
  },
  'stuart-ne': {
    name: 'Stuart',
    postalCode: '68780',
  },
  'stuart-ok': {
    name: 'Stuart',
    postalCode: '74570',
  },
  'stuart-va': {
    name: 'Stuart',
    postalCode: '24171',
  },
  'stuarts-draft-va': {
    name: 'Stuarts Draft',
    postalCode: '24477',
  },
  'studio-city-ca': {
    name: 'Studio City',
    postalCode: '91604',
  },
  'stumpy-point-nc': {
    name: 'Stumpy Point',
    postalCode: '27978',
  },
  'sturbridge-ma': {
    name: 'Sturbridge',
    postalCode: '01566',
  },
  'sturdivant-mo': {
    name: 'Sturdivant',
    postalCode: '63782',
  },
  'sturgeon-mo': {
    name: 'Sturgeon',
    postalCode: '65284',
  },
  'sturgeon-bay-wi': {
    name: 'Sturgeon Bay',
    postalCode: '54235',
  },
  'sturgeon-lake-mn': {
    name: 'Sturgeon Lake',
    postalCode: '55783',
  },
  'sturgis-ky': {
    name: 'Sturgis',
    postalCode: '42459',
  },
  'sturgis-mi': {
    name: 'Sturgis',
    postalCode: '49091',
  },
  'sturgis-ms': {
    name: 'Sturgis',
    postalCode: '39769',
  },
  'sturgis-sd': {
    name: 'Sturgis',
    postalCode: '57785',
  },
  'sturkie-ar': {
    name: 'Sturkie',
    postalCode: '72578',
  },
  'sturtevant-wi': {
    name: 'Sturtevant',
    postalCode: '53177',
  },
  'stuttgart-ar': {
    name: 'Stuttgart',
    postalCode: '72160',
  },
  'stuyvesant-ny': {
    name: 'Stuyvesant',
    postalCode: '12173',
  },
  'suamico-wi': {
    name: 'Suamico',
    postalCode: '54173',
  },
  'subiaco-ar': {
    name: 'Subiaco',
    postalCode: '72865',
  },
  'sublette-il': {
    name: 'Sublette',
    postalCode: '61367',
  },
  'sublette-ks': {
    name: 'Sublette',
    postalCode: '67877',
  },
  'sublime-tx': {
    name: 'Sublime',
    postalCode: '77986',
  },
  'sublimity-or': {
    name: 'Sublimity',
    postalCode: '97385',
  },
  'succasunna-nj': {
    name: 'Succasunna',
    postalCode: '07876',
  },
  'success-ar': {
    name: 'Success',
    postalCode: '72470',
  },
  'success-mo': {
    name: 'Success',
    postalCode: '65570',
  },
  'suches-ga': {
    name: 'Suches',
    postalCode: '30572',
  },
  'sudan-tx': {
    name: 'Sudan',
    postalCode: '79371',
  },
  'sudbury-ma': {
    name: 'Sudbury',
    postalCode: '01776',
  },
  'sudlersville-md': {
    name: 'Sudlersville',
    postalCode: '21668',
  },
  'suffern-ny': {
    name: 'Suffern',
    postalCode: '10901',
  },
  'suffield-ct': {
    name: 'Suffield',
    postalCode: '06078',
  },
  'suffolk-va': {
    name: 'Suffolk',
    postalCode: '23432',
  },
  'sugar-city-co': {
    name: 'Sugar City',
    postalCode: '81076',
  },
  'sugar-city-id': {
    name: 'Sugar City',
    postalCode: '83448',
  },
  'sugar-grove-il': {
    name: 'Sugar Grove',
    postalCode: '60554',
  },
  'sugar-grove-nc': {
    name: 'Sugar Grove',
    postalCode: '28679',
  },
  'sugar-grove-oh': {
    name: 'Sugar Grove',
    postalCode: '43155',
  },
  'sugar-grove-pa': {
    name: 'Sugar Grove',
    postalCode: '16350',
  },
  'sugar-grove-va': {
    name: 'Sugar Grove',
    postalCode: '24375',
  },
  'sugar-grove-wv': {
    name: 'Sugar Grove',
    postalCode: '26815',
  },
  'sugar-hill-nh': {
    name: 'Sugar Hill',
    postalCode: '03586',
  },
  'sugar-land-tx': {
    name: 'Sugar Land',
    postalCode: '77478',
  },
  'sugar-run-pa': {
    name: 'Sugar Run',
    postalCode: '18846',
  },
  'sugar-tree-tn': {
    name: 'Sugar Tree',
    postalCode: '38380',
  },
  'sugar-valley-ga': {
    name: 'Sugar Valley',
    postalCode: '30746',
  },
  'sugarcreek-oh': {
    name: 'Sugarcreek',
    postalCode: '44681',
  },
  'sugarloaf-pa': {
    name: 'Sugarloaf',
    postalCode: '18249',
  },
  'sugartown-la': {
    name: 'Sugartown',
    postalCode: '70662',
  },
  'suisun-city-ca': {
    name: 'Suisun City',
    postalCode: '94585',
  },
  'suitland-md': {
    name: 'Suitland',
    postalCode: '20746',
  },
  'sula-mt': {
    name: 'Sula',
    postalCode: '59871',
  },
  'sulligent-al': {
    name: 'Sulligent',
    postalCode: '35586',
  },
  'sullivan-il': {
    name: 'Sullivan',
    postalCode: '61951',
  },
  'sullivan-in': {
    name: 'Sullivan',
    postalCode: '47864',
  },
  'sullivan-me': {
    name: 'Sullivan',
    postalCode: '04664',
  },
  'sullivan-mo': {
    name: 'Sullivan',
    postalCode: '63080',
  },
  'sullivan-nh': {
    name: 'Sullivan',
    postalCode: '03445',
  },
  'sullivan-oh': {
    name: 'Sullivan',
    postalCode: '44880',
  },
  'sullivan-wi': {
    name: 'Sullivan',
    postalCode: '53178',
  },
  'sullivan-city-tx': {
    name: 'Sullivan City',
    postalCode: '78595',
  },
  'sullivans-island-sc': {
    name: 'Sullivans Island',
    postalCode: '29482',
  },
  'sully-ia': {
    name: 'Sully',
    postalCode: '50251',
  },
  'sulphur-in': {
    name: 'Sulphur',
    postalCode: '47174',
  },
  'sulphur-ky': {
    name: 'Sulphur',
    postalCode: '40070',
  },
  'sulphur-la': {
    name: 'Sulphur',
    postalCode: '70663',
  },
  'sulphur-ok': {
    name: 'Sulphur',
    postalCode: '73086',
  },
  'sulphur-bluff-tx': {
    name: 'Sulphur Bluff',
    postalCode: '75481',
  },
  'sulphur-rock-ar': {
    name: 'Sulphur Rock',
    postalCode: '72579',
  },
  'sulphur-springs-ar': {
    name: 'Sulphur Springs',
    postalCode: '72768',
  },
  'sulphur-springs-tx': {
    name: 'Sulphur Springs',
    postalCode: '75482',
  },
  'sultan-wa': {
    name: 'Sultan',
    postalCode: '98294',
  },
  'sumas-wa': {
    name: 'Sumas',
    postalCode: '98295',
  },
  'sumerco-wv': {
    name: 'Sumerco',
    postalCode: '25567',
  },
  'sumerduck-va': {
    name: 'Sumerduck',
    postalCode: '22742',
  },
  'sumiton-al': {
    name: 'Sumiton',
    postalCode: '35148',
  },
  'summer-lake-or': {
    name: 'Summer Lake',
    postalCode: '97640',
  },
  'summer-shade-ky': {
    name: 'Summer Shade',
    postalCode: '42166',
  },
  'summerdale-al': {
    name: 'Summerdale',
    postalCode: '36580',
  },
  'summerfield-fl': {
    name: 'Summerfield',
    postalCode: '34491',
  },
  'summerfield-ks': {
    name: 'Summerfield',
    postalCode: '66541',
  },
  'summerfield-la': {
    name: 'Summerfield',
    postalCode: '71079',
  },
  'summerfield-nc': {
    name: 'Summerfield',
    postalCode: '27358',
  },
  'summerfield-oh': {
    name: 'Summerfield',
    postalCode: '43788',
  },
  'summerfield-tx': {
    name: 'Summerfield',
    postalCode: '79085',
  },
  'summerhill-pa': {
    name: 'Summerhill',
    postalCode: '15958',
  },
  'summerland-key-fl': {
    name: 'Summerland Key',
    postalCode: '33042',
  },
  'summers-ar': {
    name: 'Summers',
    postalCode: '72769',
  },
  'summersville-ky': {
    name: 'Summersville',
    postalCode: '42782',
  },
  'summersville-mo': {
    name: 'Summersville',
    postalCode: '65571',
  },
  'summersville-wv': {
    name: 'Summersville',
    postalCode: '26651',
  },
  'summerton-sc': {
    name: 'Summerton',
    postalCode: '29148',
  },
  'summertown-tn': {
    name: 'Summertown',
    postalCode: '38483',
  },
  'summerville-ga': {
    name: 'Summerville',
    postalCode: '30747',
  },
  'summerville-or': {
    name: 'Summerville',
    postalCode: '97876',
  },
  'summerville-pa': {
    name: 'Summerville',
    postalCode: '15864',
  },
  'summerville-sc': {
    name: 'Summerville',
    postalCode: '29483',
  },
  'summit-ms': {
    name: 'Summit',
    postalCode: '39666',
  },
  'summit-nj': {
    name: 'Summit',
    postalCode: '07901',
  },
  'summit-ny': {
    name: 'Summit',
    postalCode: '12175',
  },
  'summit-sd': {
    name: 'Summit',
    postalCode: '57266',
  },
  'summit-argo-il': {
    name: 'Summit Argo',
    postalCode: '60501',
  },
  'summit-hill-pa': {
    name: 'Summit Hill',
    postalCode: '18250',
  },
  'summit-lake-wi': {
    name: 'Summit Lake',
    postalCode: '54485',
  },
  'summit-point-wv': {
    name: 'Summit Point',
    postalCode: '25446',
  },
  'summitville-in': {
    name: 'Summitville',
    postalCode: '46070',
  },
  'sumner-ga': {
    name: 'Sumner',
    postalCode: '31789',
  },
  'sumner-ia': {
    name: 'Sumner',
    postalCode: '50674',
  },
  'sumner-il': {
    name: 'Sumner',
    postalCode: '62466',
  },
  'sumner-me': {
    name: 'Sumner',
    postalCode: '04292',
  },
  'sumner-mi': {
    name: 'Sumner',
    postalCode: '48889',
  },
  'sumner-mo': {
    name: 'Sumner',
    postalCode: '64681',
  },
  'sumner-ne': {
    name: 'Sumner',
    postalCode: '68878',
  },
  'sumner-tx': {
    name: 'Sumner',
    postalCode: '75486',
  },
  'sumner-wa': {
    name: 'Sumner',
    postalCode: '98390',
  },
  'sumpter-or': {
    name: 'Sumpter',
    postalCode: '97877',
  },
  'sumrall-ms': {
    name: 'Sumrall',
    postalCode: '39482',
  },
  'sumter-sc': {
    name: 'Sumter',
    postalCode: '29150',
  },
  'sumterville-fl': {
    name: 'Sumterville',
    postalCode: '33585',
  },
  'sun-city-az': {
    name: 'Sun City',
    postalCode: '85351',
  },
  'sun-city-ks': {
    name: 'Sun City',
    postalCode: '67143',
  },
  'sun-city-center-fl': {
    name: 'Sun City Center',
    postalCode: '33573',
  },
  'sun-city-west-az': {
    name: 'Sun City West',
    postalCode: '85375',
  },
  'sun-prairie-wi': {
    name: 'Sun Prairie',
    postalCode: '53590',
  },
  'sun-river-mt': {
    name: 'Sun River',
    postalCode: '59483',
  },
  'sun-valley-ca': {
    name: 'Sun Valley',
    postalCode: '91352',
  },
  'sun-valley-nv': {
    name: 'Sun Valley',
    postalCode: '89433',
  },
  'sunapee-nh': {
    name: 'Sunapee',
    postalCode: '03782',
  },
  'sunbright-tn': {
    name: 'Sunbright',
    postalCode: '37872',
  },
  'sunburg-mn': {
    name: 'Sunburg',
    postalCode: '56289',
  },
  'sunburst-mt': {
    name: 'Sunburst',
    postalCode: '59482',
  },
  'sunbury-nc': {
    name: 'Sunbury',
    postalCode: '27979',
  },
  'sunbury-oh': {
    name: 'Sunbury',
    postalCode: '43074',
  },
  'sunbury-pa': {
    name: 'Sunbury',
    postalCode: '17801',
  },
  'suncook-nh': {
    name: 'Suncook',
    postalCode: '03275',
  },
  'sundance-wy': {
    name: 'Sundance',
    postalCode: '82729',
  },
  'sunderland-ma': {
    name: 'Sunderland',
    postalCode: '01375',
  },
  'sunderland-md': {
    name: 'Sunderland',
    postalCode: '20689',
  },
  'sunfield-mi': {
    name: 'Sunfield',
    postalCode: '48890',
  },
  'sunflower-al': {
    name: 'Sunflower',
    postalCode: '36581',
  },
  'sunflower-ms': {
    name: 'Sunflower',
    postalCode: '38778',
  },
  'sunland-ca': {
    name: 'Sunland',
    postalCode: '91040',
  },
  'sunland-park-nm': {
    name: 'Sunland Park',
    postalCode: '88063',
  },
  'sunman-in': {
    name: 'Sunman',
    postalCode: '47041',
  },
  'sunnyside-ny': {
    name: 'Sunnyside',
    postalCode: '11104',
  },
  'sunnyside-wa': {
    name: 'Sunnyside',
    postalCode: '98944',
  },
  'sunnyvale-ca': {
    name: 'Sunnyvale',
    postalCode: '94085',
  },
  'sunnyvale-tx': {
    name: 'Sunnyvale',
    postalCode: '75182',
  },
  'sunol-ca': {
    name: 'Sunol',
    postalCode: '94586',
  },
  'sunray-tx': {
    name: 'Sunray',
    postalCode: '79086',
  },
  'sunrise-beach-mo': {
    name: 'Sunrise Beach',
    postalCode: '65079',
  },
  'sunset-la': {
    name: 'Sunset',
    postalCode: '70584',
  },
  'sunset-me': {
    name: 'Sunset',
    postalCode: '04683',
  },
  'sunset-sc': {
    name: 'Sunset',
    postalCode: '29685',
  },
  'sunset-tx': {
    name: 'Sunset',
    postalCode: '76270',
  },
  'sunset-beach-nc': {
    name: 'Sunset Beach',
    postalCode: '28468',
  },
  'sunshine-la': {
    name: 'Sunshine',
    postalCode: '70780',
  },
  'superior-az': {
    name: 'Superior',
    postalCode: '85173',
  },
  'superior-mt': {
    name: 'Superior',
    postalCode: '59872',
  },
  'superior-ne': {
    name: 'Superior',
    postalCode: '68978',
  },
  'superior-wi': {
    name: 'Superior',
    postalCode: '54880',
  },
  'supply-nc': {
    name: 'Supply',
    postalCode: '28462',
  },
  'suquamish-wa': {
    name: 'Suquamish',
    postalCode: '98392',
  },
  'surgoinsville-tn': {
    name: 'Surgoinsville',
    postalCode: '37873',
  },
  'suring-wi': {
    name: 'Suring',
    postalCode: '54174',
  },
  'surprise-az': {
    name: 'Surprise',
    postalCode: '85374',
  },
  'surprise-ne': {
    name: 'Surprise',
    postalCode: '68667',
  },
  'surprise-ny': {
    name: 'Surprise',
    postalCode: '12176',
  },
  'surrency-ga': {
    name: 'Surrency',
    postalCode: '31563',
  },
  'surrey-nd': {
    name: 'Surrey',
    postalCode: '58785',
  },
  'surry-me': {
    name: 'Surry',
    postalCode: '04684',
  },
  'surry-va': {
    name: 'Surry',
    postalCode: '23883',
  },
  'surveyor-wv': {
    name: 'Surveyor',
    postalCode: '25932',
  },
  'susan-va': {
    name: 'Susan',
    postalCode: '23163',
  },
  'susanville-ca': {
    name: 'Susanville',
    postalCode: '96130',
  },
  'susquehanna-pa': {
    name: 'Susquehanna',
    postalCode: '18847',
  },
  'sussex-nj': {
    name: 'Sussex',
    postalCode: '07461',
  },
  'sussex-wi': {
    name: 'Sussex',
    postalCode: '53089',
  },
  'sutersville-pa': {
    name: 'Sutersville',
    postalCode: '15083',
  },
  'sutherland-ia': {
    name: 'Sutherland',
    postalCode: '51058',
  },
  'sutherland-ne': {
    name: 'Sutherland',
    postalCode: '69165',
  },
  'sutherland-va': {
    name: 'Sutherland',
    postalCode: '23885',
  },
  'sutherland-springs-tx': {
    name: 'Sutherland Springs',
    postalCode: '78161',
  },
  'sutherlin-or': {
    name: 'Sutherlin',
    postalCode: '97479',
  },
  'sutherlin-va': {
    name: 'Sutherlin',
    postalCode: '24594',
  },
  'sutter-ca': {
    name: 'Sutter',
    postalCode: '95982',
  },
  'sutter-il': {
    name: 'Sutter',
    postalCode: '62373',
  },
  'sutter-creek-ca': {
    name: 'Sutter Creek',
    postalCode: '95685',
  },
  'sutton-ak': {
    name: 'Sutton',
    postalCode: '99674',
  },
  'sutton-ma': {
    name: 'Sutton',
    postalCode: '01590',
  },
  'sutton-nd': {
    name: 'Sutton',
    postalCode: '58484',
  },
  'sutton-ne': {
    name: 'Sutton',
    postalCode: '68979',
  },
  'sutton-vt': {
    name: 'Sutton',
    postalCode: '05867',
  },
  'sutton-wv': {
    name: 'Sutton',
    postalCode: '26601',
  },
  'suttons-bay-mi': {
    name: 'Suttons Bay',
    postalCode: '49682',
  },
  'suwanee-ga': {
    name: 'Suwanee',
    postalCode: '30024',
  },
  'swain-ny': {
    name: 'Swain',
    postalCode: '14884',
  },
  'swainsboro-ga': {
    name: 'Swainsboro',
    postalCode: '30401',
  },
  'swaledale-ia': {
    name: 'Swaledale',
    postalCode: '50477',
  },
  'swampscott-ma': {
    name: 'Swampscott',
    postalCode: '01907',
  },
  'swan-ia': {
    name: 'Swan',
    postalCode: '50252',
  },
  'swan-lake-ny': {
    name: 'Swan Lake',
    postalCode: '12783',
  },
  'swan-river-mn': {
    name: 'Swan River',
    postalCode: '55784',
  },
  'swan-valley-id': {
    name: 'Swan Valley',
    postalCode: '83449',
  },
  'swannanoa-nc': {
    name: 'Swannanoa',
    postalCode: '28778',
  },
  'swanquarter-nc': {
    name: 'Swanquarter',
    postalCode: '27885',
  },
  'swans-island-me': {
    name: 'Swans Island',
    postalCode: '04685',
  },
  'swansboro-nc': {
    name: 'Swansboro',
    postalCode: '28584',
  },
  'swansea-ma': {
    name: 'Swansea',
    postalCode: '02777',
  },
  'swansea-sc': {
    name: 'Swansea',
    postalCode: '29160',
  },
  'swanton-md': {
    name: 'Swanton',
    postalCode: '21561',
  },
  'swanton-ne': {
    name: 'Swanton',
    postalCode: '68445',
  },
  'swanton-oh': {
    name: 'Swanton',
    postalCode: '43558',
  },
  'swanton-vt': {
    name: 'Swanton',
    postalCode: '05488',
  },
  'swanville-mn': {
    name: 'Swanville',
    postalCode: '56382',
  },
  'swanzey-nh': {
    name: 'Swanzey',
    postalCode: '03446',
  },
  'swarthmore-pa': {
    name: 'Swarthmore',
    postalCode: '19081',
  },
  'swartz-creek-mi': {
    name: 'Swartz Creek',
    postalCode: '48473',
  },
  'swatara-mn': {
    name: 'Swatara',
    postalCode: '55785',
  },
  'swayzee-in': {
    name: 'Swayzee',
    postalCode: '46986',
  },
  'swea-city-ia': {
    name: 'Swea City',
    postalCode: '50590',
  },
  'swedesboro-nj': {
    name: 'Swedesboro',
    postalCode: '08085',
  },
  'sweeden-ky': {
    name: 'Sweeden',
    postalCode: '42285',
  },
  'sweeny-tx': {
    name: 'Sweeny',
    postalCode: '77480',
  },
  'sweet-id': {
    name: 'Sweet',
    postalCode: '83670',
  },
  'sweet-grass-mt': {
    name: 'Sweet Grass',
    postalCode: '59484',
  },
  'sweet-home-or': {
    name: 'Sweet Home',
    postalCode: '97386',
  },
  'sweet-springs-mo': {
    name: 'Sweet Springs',
    postalCode: '65351',
  },
  'sweet-valley-pa': {
    name: 'Sweet Valley',
    postalCode: '18656',
  },
  'sweet-water-al': {
    name: 'Sweet Water',
    postalCode: '36782',
  },
  'sweetwater-ok': {
    name: 'Sweetwater',
    postalCode: '73666',
  },
  'sweetwater-tn': {
    name: 'Sweetwater',
    postalCode: '37874',
  },
  'sweetwater-tx': {
    name: 'Sweetwater',
    postalCode: '79556',
  },
  'swift-mn': {
    name: 'Swift',
    postalCode: '56682',
  },
  'swifton-ar': {
    name: 'Swifton',
    postalCode: '72471',
  },
  'swiftwater-pa': {
    name: 'Swiftwater',
    postalCode: '18370',
  },
  'swisher-ia': {
    name: 'Swisher',
    postalCode: '52338',
  },
  'swiss-wv': {
    name: 'Swiss',
    postalCode: '26690',
  },
  'swisshome-or': {
    name: 'Swisshome',
    postalCode: '97480',
  },
  'switz-city-in': {
    name: 'Switz City',
    postalCode: '47465',
  },
  'swoope-va': {
    name: 'Swoope',
    postalCode: '24479',
  },
  'swords-creek-va': {
    name: 'Swords Creek',
    postalCode: '24649',
  },
  'sycamore-ga': {
    name: 'Sycamore',
    postalCode: '31790',
  },
  'sycamore-il': {
    name: 'Sycamore',
    postalCode: '60178',
  },
  'sycamore-oh': {
    name: 'Sycamore',
    postalCode: '44882',
  },
  'sycamore-pa': {
    name: 'Sycamore',
    postalCode: '15364',
  },
  'sykeston-nd': {
    name: 'Sykeston',
    postalCode: '58486',
  },
  'sykesville-md': {
    name: 'Sykesville',
    postalCode: '21784',
  },
  'sykesville-pa': {
    name: 'Sykesville',
    postalCode: '15865',
  },
  'sylacauga-al': {
    name: 'Sylacauga',
    postalCode: '35150',
  },
  'sylmar-ca': {
    name: 'Sylmar',
    postalCode: '91342',
  },
  'sylva-nc': {
    name: 'Sylva',
    postalCode: '28779',
  },
  'sylvan-grove-ks': {
    name: 'Sylvan Grove',
    postalCode: '67481',
  },
  'sylvania-al': {
    name: 'Sylvania',
    postalCode: '35988',
  },
  'sylvania-ga': {
    name: 'Sylvania',
    postalCode: '30467',
  },
  'sylvania-oh': {
    name: 'Sylvania',
    postalCode: '43560',
  },
  'sylvester-ga': {
    name: 'Sylvester',
    postalCode: '31791',
  },
  'sylvester-tx': {
    name: 'Sylvester',
    postalCode: '79560',
  },
  'sylvester-wv': {
    name: 'Sylvester',
    postalCode: '25193',
  },
  'sylvia-ks': {
    name: 'Sylvia',
    postalCode: '67581',
  },
  'symsonia-ky': {
    name: 'Symsonia',
    postalCode: '42082',
  },
  'syosset-ny': {
    name: 'Syosset',
    postalCode: '11791',
  },
  'syracuse-in': {
    name: 'Syracuse',
    postalCode: '46567',
  },
  'syracuse-ks': {
    name: 'Syracuse',
    postalCode: '67878',
  },
  'syracuse-mo': {
    name: 'Syracuse',
    postalCode: '65354',
  },
  'syracuse-ne': {
    name: 'Syracuse',
    postalCode: '68446',
  },
  'syracuse-ny': {
    name: 'Syracuse',
    postalCode: '13202',
  },
  'syracuse-ut': {
    name: 'Syracuse',
    postalCode: '84075',
  },
  'syria-va': {
    name: 'Syria',
    postalCode: '22743',
  },
  'ty-ty-ga': {
    name: 'TY TY',
    postalCode: '31795',
  },
  'taberg-ny': {
    name: 'Taberg',
    postalCode: '13471',
  },
  'tabiona-ut': {
    name: 'Tabiona',
    postalCode: '84072',
  },
  'table-grove-il': {
    name: 'Table Grove',
    postalCode: '61482',
  },
  'table-rock-ne': {
    name: 'Table Rock',
    postalCode: '68447',
  },
  'tabor-ia': {
    name: 'Tabor',
    postalCode: '51653',
  },
  'tabor-sd': {
    name: 'Tabor',
    postalCode: '57063',
  },
  'tabor-city-nc': {
    name: 'Tabor City',
    postalCode: '28463',
  },
  'tacoma-wa': {
    name: 'Tacoma',
    postalCode: '98402',
  },
  'taft-ca': {
    name: 'Taft',
    postalCode: '93268',
  },
  'taft-tn': {
    name: 'Taft',
    postalCode: '38488',
  },
  'taft-tx': {
    name: 'Taft',
    postalCode: '78390',
  },
  'tafton-pa': {
    name: 'Tafton',
    postalCode: '18464',
  },
  'taftsville-vt': {
    name: 'Taftsville',
    postalCode: '05073',
  },
  'taftville-ct': {
    name: 'Taftville',
    postalCode: '06380',
  },
  'tahlequah-ok': {
    name: 'Tahlequah',
    postalCode: '74464',
  },
  'tahoka-tx': {
    name: 'Tahoka',
    postalCode: '79373',
  },
  'tahuya-wa': {
    name: 'Tahuya',
    postalCode: '98588',
  },
  'taiban-nm': {
    name: 'Taiban',
    postalCode: '88134',
  },
  'takoma-park-md': {
    name: 'Takoma Park',
    postalCode: '20912',
  },
  'talala-ok': {
    name: 'Talala',
    postalCode: '74080',
  },
  'talbott-tn': {
    name: 'Talbott',
    postalCode: '37877',
  },
  'talbotton-ga': {
    name: 'Talbotton',
    postalCode: '31827',
  },
  'talco-tx': {
    name: 'Talco',
    postalCode: '75487',
  },
  'talcott-wv': {
    name: 'Talcott',
    postalCode: '24981',
  },
  'talent-or': {
    name: 'Talent',
    postalCode: '97540',
  },
  'talihina-ok': {
    name: 'Talihina',
    postalCode: '74571',
  },
  'talkeetna-ak': {
    name: 'Talkeetna',
    postalCode: '99676',
  },
  'talking-rock-ga': {
    name: 'Talking Rock',
    postalCode: '30175',
  },
  'tall-timbers-md': {
    name: 'Tall Timbers',
    postalCode: '20690',
  },
  'talladega-al': {
    name: 'Talladega',
    postalCode: '35160',
  },
  'tallahassee-fl': {
    name: 'Tallahassee',
    postalCode: '32301',
  },
  'tallapoosa-ga': {
    name: 'Tallapoosa',
    postalCode: '30176',
  },
  'tallassee-al': {
    name: 'Tallassee',
    postalCode: '36078',
  },
  'tallassee-tn': {
    name: 'Tallassee',
    postalCode: '37878',
  },
  'tallmadge-oh': {
    name: 'Tallmadge',
    postalCode: '44278',
  },
  'tallmansville-wv': {
    name: 'Tallmansville',
    postalCode: '26237',
  },
  'tallula-il': {
    name: 'Tallula',
    postalCode: '62688',
  },
  'tallulah-la': {
    name: 'Tallulah',
    postalCode: '71282',
  },
  'talmage-ne': {
    name: 'Talmage',
    postalCode: '68448',
  },
  'talmage-ut': {
    name: 'Talmage',
    postalCode: '84073',
  },
  'talmo-ga': {
    name: 'Talmo',
    postalCode: '30575',
  },
  'talmoon-mn': {
    name: 'Talmoon',
    postalCode: '56637',
  },
  'taloga-ok': {
    name: 'Taloga',
    postalCode: '73667',
  },
  'talpa-tx': {
    name: 'Talpa',
    postalCode: '76882',
  },
  'tama-ia': {
    name: 'Tama',
    postalCode: '52339',
  },
  'tamaqua-pa': {
    name: 'Tamaqua',
    postalCode: '18252',
  },
  'tamarack-mn': {
    name: 'Tamarack',
    postalCode: '55787',
  },
  'tamaroa-il': {
    name: 'Tamaroa',
    postalCode: '62888',
  },
  'tamassee-sc': {
    name: 'Tamassee',
    postalCode: '29686',
  },
  'tamiment-pa': {
    name: 'Tamiment',
    postalCode: '18371',
  },
  'tamms-il': {
    name: 'Tamms',
    postalCode: '62988',
  },
  'tampa-fl': {
    name: 'Tampa',
    postalCode: '33602',
  },
  'tampa-ks': {
    name: 'Tampa',
    postalCode: '67483',
  },
  'tampico-il': {
    name: 'Tampico',
    postalCode: '61283',
  },
  'tamworth-nh': {
    name: 'Tamworth',
    postalCode: '03886',
  },
  'taneytown-md': {
    name: 'Taneytown',
    postalCode: '21787',
  },
  'taneyville-mo': {
    name: 'Taneyville',
    postalCode: '65759',
  },
  'tangent-or': {
    name: 'Tangent',
    postalCode: '97389',
  },
  'tanner-al': {
    name: 'Tanner',
    postalCode: '35671',
  },
  'tannersville-ny': {
    name: 'Tannersville',
    postalCode: '12485',
  },
  'tannersville-pa': {
    name: 'Tannersville',
    postalCode: '18372',
  },
  'tannersville-va': {
    name: 'Tannersville',
    postalCode: '24377',
  },
  'taopi-mn': {
    name: 'Taopi',
    postalCode: '55977',
  },
  'taos-nm': {
    name: 'Taos',
    postalCode: '87571',
  },
  'tappahannock-va': {
    name: 'Tappahannock',
    postalCode: '22560',
  },
  'tappan-ny': {
    name: 'Tappan',
    postalCode: '10983',
  },
  'tappen-nd': {
    name: 'Tappen',
    postalCode: '58487',
  },
  'tar-heel-nc': {
    name: 'Tar Heel',
    postalCode: '28392',
  },
  'tarawa-terrace-nc': {
    name: 'Tarawa Terrace',
    postalCode: '28543',
  },
  'tarboro-nc': {
    name: 'Tarboro',
    postalCode: '27886',
  },
  'tarentum-pa': {
    name: 'Tarentum',
    postalCode: '15084',
  },
  'tariffville-ct': {
    name: 'Tariffville',
    postalCode: '06081',
  },
  'tarkio-mo': {
    name: 'Tarkio',
    postalCode: '64491',
  },
  'tarpley-tx': {
    name: 'Tarpley',
    postalCode: '78883',
  },
  'tarpon-springs-fl': {
    name: 'Tarpon Springs',
    postalCode: '34688',
  },
  'tarrs-pa': {
    name: 'Tarrs',
    postalCode: '15688',
  },
  'tarrytown-ga': {
    name: 'Tarrytown',
    postalCode: '30470',
  },
  'tarrytown-ny': {
    name: 'Tarrytown',
    postalCode: '10591',
  },
  'tarzan-tx': {
    name: 'Tarzan',
    postalCode: '79783',
  },
  'tarzana-ca': {
    name: 'Tarzana',
    postalCode: '91356',
  },
  'taswell-in': {
    name: 'Taswell',
    postalCode: '47175',
  },
  'tate-ga': {
    name: 'Tate',
    postalCode: '30177',
  },
  'tatum-nm': {
    name: 'Tatum',
    postalCode: '88267',
  },
  'tatum-tx': {
    name: 'Tatum',
    postalCode: '75691',
  },
  'taunton-ma': {
    name: 'Taunton',
    postalCode: '02780',
  },
  'taunton-mn': {
    name: 'Taunton',
    postalCode: '56291',
  },
  'tavares-fl': {
    name: 'Tavares',
    postalCode: '32778',
  },
  'tavernier-fl': {
    name: 'Tavernier',
    postalCode: '33070',
  },
  'tawas-city-mi': {
    name: 'Tawas City',
    postalCode: '48763',
  },
  'taylor-ar': {
    name: 'Taylor',
    postalCode: '71861',
  },
  'taylor-mi': {
    name: 'Taylor',
    postalCode: '48180',
  },
  'taylor-mo': {
    name: 'Taylor',
    postalCode: '63471',
  },
  'taylor-ms': {
    name: 'Taylor',
    postalCode: '38673',
  },
  'taylor-nd': {
    name: 'Taylor',
    postalCode: '58656',
  },
  'taylor-ne': {
    name: 'Taylor',
    postalCode: '68879',
  },
  'taylor-pa': {
    name: 'Taylor',
    postalCode: '18517',
  },
  'taylor-tx': {
    name: 'Taylor',
    postalCode: '76574',
  },
  'taylor-wi': {
    name: 'Taylor',
    postalCode: '54659',
  },
  'taylor-ridge-il': {
    name: 'Taylor Ridge',
    postalCode: '61284',
  },
  'taylors-sc': {
    name: 'Taylors',
    postalCode: '29687',
  },
  'taylors-falls-mn': {
    name: 'Taylors Falls',
    postalCode: '55084',
  },
  'taylorsville-ca': {
    name: 'Taylorsville',
    postalCode: '95983',
  },
  'taylorsville-ga': {
    name: 'Taylorsville',
    postalCode: '30178',
  },
  'taylorsville-ky': {
    name: 'Taylorsville',
    postalCode: '40071',
  },
  'taylorsville-ms': {
    name: 'Taylorsville',
    postalCode: '39168',
  },
  'taylorsville-nc': {
    name: 'Taylorsville',
    postalCode: '28681',
  },
  'taylorville-il': {
    name: 'Taylorville',
    postalCode: '62568',
  },
  'tazewell-tn': {
    name: 'Tazewell',
    postalCode: '37879',
  },
  'tazewell-va': {
    name: 'Tazewell',
    postalCode: '24651',
  },
  'tchula-ms': {
    name: 'Tchula',
    postalCode: '39169',
  },
  'tea-sd': {
    name: 'Tea',
    postalCode: '57064',
  },
  'teaberry-ky': {
    name: 'Teaberry',
    postalCode: '41660',
  },
  'teachey-nc': {
    name: 'Teachey',
    postalCode: '28464',
  },
  'teague-tx': {
    name: 'Teague',
    postalCode: '75860',
  },
  'teaneck-nj': {
    name: 'Teaneck',
    postalCode: '07666',
  },
  'teasdale-ut': {
    name: 'Teasdale',
    postalCode: '84773',
  },
  'tebbetts-mo': {
    name: 'Tebbetts',
    postalCode: '65080',
  },
  'tecate-ca': {
    name: 'Tecate',
    postalCode: '91980',
  },
  'tecumseh-ks': {
    name: 'Tecumseh',
    postalCode: '66542',
  },
  'tecumseh-mi': {
    name: 'Tecumseh',
    postalCode: '49286',
  },
  'tecumseh-mo': {
    name: 'Tecumseh',
    postalCode: '65760',
  },
  'tecumseh-ne': {
    name: 'Tecumseh',
    postalCode: '68450',
  },
  'tecumseh-ok': {
    name: 'Tecumseh',
    postalCode: '74873',
  },
  'teec-nos-pos-az': {
    name: 'Teec Nos Pos',
    postalCode: '86514',
  },
  'teeds-grove-ia': {
    name: 'Teeds Grove',
    postalCode: '52771',
  },
  'tehachapi-ca': {
    name: 'Tehachapi',
    postalCode: '93561',
  },
  'teigen-mt': {
    name: 'Teigen',
    postalCode: '59084',
  },
  'tekamah-ne': {
    name: 'Tekamah',
    postalCode: '68061',
  },
  'tekoa-wa': {
    name: 'Tekoa',
    postalCode: '99033',
  },
  'tekonsha-mi': {
    name: 'Tekonsha',
    postalCode: '49092',
  },
  'telegraph-tx': {
    name: 'Telegraph',
    postalCode: '76883',
  },
  'telephone-tx': {
    name: 'Telephone',
    postalCode: '75488',
  },
  'telford-pa': {
    name: 'Telford',
    postalCode: '18969',
  },
  'telford-tn': {
    name: 'Telford',
    postalCode: '37690',
  },
  'tell-tx': {
    name: 'Tell',
    postalCode: '79259',
  },
  'tell-city-in': {
    name: 'Tell City',
    postalCode: '47586',
  },
  'tellico-plains-tn': {
    name: 'Tellico Plains',
    postalCode: '37385',
  },
  'telluride-co': {
    name: 'Telluride',
    postalCode: '81435',
  },
  'temecula-ca': {
    name: 'Temecula',
    postalCode: '92590',
  },
  'tempe-az': {
    name: 'Tempe',
    postalCode: '85281',
  },
  'temperance-mi': {
    name: 'Temperance',
    postalCode: '48182',
  },
  'temperanceville-va': {
    name: 'Temperanceville',
    postalCode: '23442',
  },
  'temple-ga': {
    name: 'Temple',
    postalCode: '30179',
  },
  'temple-me': {
    name: 'Temple',
    postalCode: '04984',
  },
  'temple-nh': {
    name: 'Temple',
    postalCode: '03084',
  },
  'temple-ok': {
    name: 'Temple',
    postalCode: '73568',
  },
  'temple-pa': {
    name: 'Temple',
    postalCode: '19560',
  },
  'temple-tx': {
    name: 'Temple',
    postalCode: '76501',
  },
  'temple-bar-marina-az': {
    name: 'Temple Bar Marina',
    postalCode: '86443',
  },
  'temple-city-ca': {
    name: 'Temple City',
    postalCode: '91780',
  },
  'temple-hills-md': {
    name: 'Temple Hills',
    postalCode: '20748',
  },
  'templeton-ca': {
    name: 'Templeton',
    postalCode: '93465',
  },
  'templeton-ia': {
    name: 'Templeton',
    postalCode: '51463',
  },
  'templeton-ma': {
    name: 'Templeton',
    postalCode: '01468',
  },
  'templeton-pa': {
    name: 'Templeton',
    postalCode: '16259',
  },
  'ten-mile-tn': {
    name: 'Ten Mile',
    postalCode: '37880',
  },
  'ten-sleep-wy': {
    name: 'Ten Sleep',
    postalCode: '82442',
  },
  'tenafly-nj': {
    name: 'Tenafly',
    postalCode: '07670',
  },
  'tenaha-tx': {
    name: 'Tenaha',
    postalCode: '75974',
  },
  'tenants-harbor-me': {
    name: 'Tenants Harbor',
    postalCode: '04860',
  },
  'tenino-wa': {
    name: 'Tenino',
    postalCode: '98589',
  },
  'tenmile-or': {
    name: 'Tenmile',
    postalCode: '97481',
  },
  'tennessee-il': {
    name: 'Tennessee',
    postalCode: '62374',
  },
  'tennessee-colony-tx': {
    name: 'Tennessee Colony',
    postalCode: '75861',
  },
  'tennessee-ridge-tn': {
    name: 'Tennessee Ridge',
    postalCode: '37178',
  },
  'tennille-ga': {
    name: 'Tennille',
    postalCode: '31089',
  },
  'tennyson-in': {
    name: 'Tennyson',
    postalCode: '47637',
  },
  'tensed-id': {
    name: 'Tensed',
    postalCode: '83870',
  },
  'tenstrike-mn': {
    name: 'Tenstrike',
    postalCode: '56683',
  },
  'tererro-nm': {
    name: 'Tererro',
    postalCode: '87573',
  },
  'terlingua-tx': {
    name: 'Terlingua',
    postalCode: '79852',
  },
  'terlton-ok': {
    name: 'Terlton',
    postalCode: '74081',
  },
  'termo-ca': {
    name: 'Termo',
    postalCode: '96132',
  },
  'terra-alta-wv': {
    name: 'Terra Alta',
    postalCode: '26764',
  },
  'terra-bella-ca': {
    name: 'Terra Bella',
    postalCode: '93270',
  },
  'terrace-park-oh': {
    name: 'Terrace Park',
    postalCode: '45174',
  },
  'terral-ok': {
    name: 'Terral',
    postalCode: '73569',
  },
  'terre-haute-in': {
    name: 'Terre Haute',
    postalCode: '47802',
  },
  'terre-hill-pa': {
    name: 'Terre Hill',
    postalCode: '17581',
  },
  'terrebonne-or': {
    name: 'Terrebonne',
    postalCode: '97760',
  },
  'terrell-nc': {
    name: 'Terrell',
    postalCode: '28682',
  },
  'terrell-tx': {
    name: 'Terrell',
    postalCode: '75160',
  },
  'terreton-id': {
    name: 'Terreton',
    postalCode: '83450',
  },
  'terril-ia': {
    name: 'Terril',
    postalCode: '51364',
  },
  'terry-ms': {
    name: 'Terry',
    postalCode: '39170',
  },
  'terry-mt': {
    name: 'Terry',
    postalCode: '59349',
  },
  'terryville-ct': {
    name: 'Terryville',
    postalCode: '06786',
  },
  'tescott-ks': {
    name: 'Tescott',
    postalCode: '67484',
  },
  'teterboro-nj': {
    name: 'Teterboro',
    postalCode: '07608',
  },
  'teton-id': {
    name: 'Teton',
    postalCode: '83451',
  },
  'tetonia-id': {
    name: 'Tetonia',
    postalCode: '83452',
  },
  'teutopolis-il': {
    name: 'Teutopolis',
    postalCode: '62467',
  },
  'tewksbury-ma': {
    name: 'Tewksbury',
    postalCode: '01876',
  },
  'texarkana-ar': {
    name: 'Texarkana',
    postalCode: '71854',
  },
  'texarkana-tx': {
    name: 'Texarkana',
    postalCode: '75501',
  },
  'texas-city-tx': {
    name: 'Texas City',
    postalCode: '77590',
  },
  'texhoma-ok': {
    name: 'Texhoma',
    postalCode: '73949',
  },
  'texico-il': {
    name: 'Texico',
    postalCode: '62889',
  },
  'texico-nm': {
    name: 'Texico',
    postalCode: '88135',
  },
  'texline-tx': {
    name: 'Texline',
    postalCode: '79087',
  },
  'texola-ok': {
    name: 'Texola',
    postalCode: '73668',
  },
  'thackerville-ok': {
    name: 'Thackerville',
    postalCode: '73459',
  },
  'thatcher-az': {
    name: 'Thatcher',
    postalCode: '85552',
  },
  'thatcher-id': {
    name: 'Thatcher',
    postalCode: '83283',
  },
  'thawville-il': {
    name: 'Thawville',
    postalCode: '60968',
  },
  'thaxton-ms': {
    name: 'Thaxton',
    postalCode: '38871',
  },
  'thaxton-va': {
    name: 'Thaxton',
    postalCode: '24174',
  },
  'thayer-ia': {
    name: 'Thayer',
    postalCode: '50254',
  },
  'thayer-in': {
    name: 'Thayer',
    postalCode: '46381',
  },
  'thayer-ks': {
    name: 'Thayer',
    postalCode: '66776',
  },
  'thayer-mo': {
    name: 'Thayer',
    postalCode: '65791',
  },
  'thayne-wy': {
    name: 'Thayne',
    postalCode: '83127',
  },
  'the-colony-tx': {
    name: 'The Colony',
    postalCode: '75056',
  },
  'the-dalles-or': {
    name: 'The Dalles',
    postalCode: '97058',
  },
  'the-plains-oh': {
    name: 'The Plains',
    postalCode: '45780',
  },
  'the-plains-va': {
    name: 'The Plains',
    postalCode: '20198',
  },
  'the-rock-ga': {
    name: 'The Rock',
    postalCode: '30285',
  },
  'the-villages-fl': {
    name: 'The Villages',
    postalCode: '32162',
  },
  'thebes-il': {
    name: 'Thebes',
    postalCode: '62990',
  },
  'thedford-ne': {
    name: 'Thedford',
    postalCode: '69166',
  },
  'thelma-ky': {
    name: 'Thelma',
    postalCode: '41260',
  },
  'theodore-al': {
    name: 'Theodore',
    postalCode: '36582',
  },
  'theodosia-mo': {
    name: 'Theodosia',
    postalCode: '65761',
  },
  'theresa-ny': {
    name: 'Theresa',
    postalCode: '13691',
  },
  'theresa-wi': {
    name: 'Theresa',
    postalCode: '53091',
  },
  'theriot-la': {
    name: 'Theriot',
    postalCode: '70397',
  },
  'thermal-ca': {
    name: 'Thermal',
    postalCode: '92274',
  },
  'thermopolis-wy': {
    name: 'Thermopolis',
    postalCode: '82443',
  },
  'thetford-center-vt': {
    name: 'Thetford Center',
    postalCode: '05075',
  },
  'thibodaux-la': {
    name: 'Thibodaux',
    postalCode: '70301',
  },
  'thida-ar': {
    name: 'Thida',
    postalCode: '72165',
  },
  'thief-river-falls-mn': {
    name: 'Thief River Falls',
    postalCode: '56701',
  },
  'thiells-ny': {
    name: 'Thiells',
    postalCode: '10984',
  },
  'thomas-ok': {
    name: 'Thomas',
    postalCode: '73669',
  },
  'thomas-wv': {
    name: 'Thomas',
    postalCode: '26292',
  },
  'thomasboro-il': {
    name: 'Thomasboro',
    postalCode: '61878',
  },
  'thomaston-al': {
    name: 'Thomaston',
    postalCode: '36783',
  },
  'thomaston-ct': {
    name: 'Thomaston',
    postalCode: '06787',
  },
  'thomaston-ga': {
    name: 'Thomaston',
    postalCode: '30286',
  },
  'thomaston-me': {
    name: 'Thomaston',
    postalCode: '04861',
  },
  'thomasville-al': {
    name: 'Thomasville',
    postalCode: '36784',
  },
  'thomasville-ga': {
    name: 'Thomasville',
    postalCode: '31757',
  },
  'thomasville-nc': {
    name: 'Thomasville',
    postalCode: '27360',
  },
  'thomasville-pa': {
    name: 'Thomasville',
    postalCode: '17364',
  },
  'thompson-ct': {
    name: 'Thompson',
    postalCode: '06277',
  },
  'thompson-ia': {
    name: 'Thompson',
    postalCode: '50478',
  },
  'thompson-mo': {
    name: 'Thompson',
    postalCode: '65285',
  },
  'thompson-nd': {
    name: 'Thompson',
    postalCode: '58278',
  },
  'thompson-oh': {
    name: 'Thompson',
    postalCode: '44086',
  },
  'thompson-pa': {
    name: 'Thompson',
    postalCode: '18465',
  },
  'thompson-ut': {
    name: 'Thompson',
    postalCode: '84540',
  },
  'thompson-falls-mt': {
    name: 'Thompson Falls',
    postalCode: '59873',
  },
  'thompson-ridge-ny': {
    name: 'Thompson Ridge',
    postalCode: '10985',
  },
  'thompsons-station-tn': {
    name: 'Thompsons Station',
    postalCode: '37179',
  },
  'thompsontown-pa': {
    name: 'Thompsontown',
    postalCode: '17094',
  },
  'thompsonville-il': {
    name: 'Thompsonville',
    postalCode: '62890',
  },
  'thompsonville-mi': {
    name: 'Thompsonville',
    postalCode: '49683',
  },
  'thomson-ga': {
    name: 'Thomson',
    postalCode: '30824',
  },
  'thomson-il': {
    name: 'Thomson',
    postalCode: '61285',
  },
  'thonotosassa-fl': {
    name: 'Thonotosassa',
    postalCode: '33592',
  },
  'thor-ia': {
    name: 'Thor',
    postalCode: '50591',
  },
  'thoreau-nm': {
    name: 'Thoreau',
    postalCode: '87323',
  },
  'thorn-hill-tn': {
    name: 'Thorn Hill',
    postalCode: '37881',
  },
  'thorndale-pa': {
    name: 'Thorndale',
    postalCode: '19372',
  },
  'thorndale-tx': {
    name: 'Thorndale',
    postalCode: '76577',
  },
  'thorndike-me': {
    name: 'Thorndike',
    postalCode: '04986',
  },
  'thornfield-mo': {
    name: 'Thornfield',
    postalCode: '65762',
  },
  'thornton-ar': {
    name: 'Thornton',
    postalCode: '71766',
  },
  'thornton-co': {
    name: 'Thornton',
    postalCode: '80241',
  },
  'thornton-ia': {
    name: 'Thornton',
    postalCode: '50479',
  },
  'thornton-il': {
    name: 'Thornton',
    postalCode: '60476',
  },
  'thornton-ky': {
    name: 'Thornton',
    postalCode: '41855',
  },
  'thornton-nh': {
    name: 'Thornton',
    postalCode: '03285',
  },
  'thornton-pa': {
    name: 'Thornton',
    postalCode: '19373',
  },
  'thornton-tx': {
    name: 'Thornton',
    postalCode: '76687',
  },
  'thornton-wa': {
    name: 'Thornton',
    postalCode: '99176',
  },
  'thornton-wv': {
    name: 'Thornton',
    postalCode: '26440',
  },
  'thorntown-in': {
    name: 'Thorntown',
    postalCode: '46071',
  },
  'thornville-oh': {
    name: 'Thornville',
    postalCode: '43076',
  },
  'thornwood-ny': {
    name: 'Thornwood',
    postalCode: '10594',
  },
  'thorofare-nj': {
    name: 'Thorofare',
    postalCode: '08086',
  },
  'thorp-wa': {
    name: 'Thorp',
    postalCode: '98946',
  },
  'thorp-wi': {
    name: 'Thorp',
    postalCode: '54771',
  },
  'thorsby-al': {
    name: 'Thorsby',
    postalCode: '35171',
  },
  'thousand-oaks-ca': {
    name: 'Thousand Oaks',
    postalCode: '91360',
  },
  'thousand-palms-ca': {
    name: 'Thousand Palms',
    postalCode: '92276',
  },
  'thousandsticks-ky': {
    name: 'Thousandsticks',
    postalCode: '41766',
  },
  'thrall-tx': {
    name: 'Thrall',
    postalCode: '76578',
  },
  'three-bridges-nj': {
    name: 'Three Bridges',
    postalCode: '08887',
  },
  'three-forks-mt': {
    name: 'Three Forks',
    postalCode: '59752',
  },
  'three-lakes-wi': {
    name: 'Three Lakes',
    postalCode: '54562',
  },
  'three-mile-bay-ny': {
    name: 'Three Mile Bay',
    postalCode: '13693',
  },
  'three-oaks-mi': {
    name: 'Three Oaks',
    postalCode: '49128',
  },
  'three-rivers-ca': {
    name: 'Three Rivers',
    postalCode: '93271',
  },
  'three-rivers-ma': {
    name: 'Three Rivers',
    postalCode: '01080',
  },
  'three-rivers-mi': {
    name: 'Three Rivers',
    postalCode: '49093',
  },
  'three-rivers-tx': {
    name: 'Three Rivers',
    postalCode: '78071',
  },
  'three-springs-pa': {
    name: 'Three Springs',
    postalCode: '17264',
  },
  'throckmorton-tx': {
    name: 'Throckmorton',
    postalCode: '76483',
  },
  'thurman-ia': {
    name: 'Thurman',
    postalCode: '51654',
  },
  'thurman-oh': {
    name: 'Thurman',
    postalCode: '45685',
  },
  'thurmond-nc': {
    name: 'Thurmond',
    postalCode: '28683',
  },
  'thurmond-wv': {
    name: 'Thurmond',
    postalCode: '25936',
  },
  'thurmont-md': {
    name: 'Thurmont',
    postalCode: '21788',
  },
  'thurston-ne': {
    name: 'Thurston',
    postalCode: '68062',
  },
  'thurston-or': {
    name: 'Thurston',
    postalCode: '97482',
  },
  'tibbie-al': {
    name: 'Tibbie',
    postalCode: '36583',
  },
  'tichnor-ar': {
    name: 'Tichnor',
    postalCode: '72166',
  },
  'tickfaw-la': {
    name: 'Tickfaw',
    postalCode: '70466',
  },
  'ticonderoga-ny': {
    name: 'Ticonderoga',
    postalCode: '12883',
  },
  'tidewater-or': {
    name: 'Tidewater',
    postalCode: '97390',
  },
  'tidioute-pa': {
    name: 'Tidioute',
    postalCode: '16351',
  },
  'tierra-amarilla-nm': {
    name: 'Tierra Amarilla',
    postalCode: '87575',
  },
  'tieton-wa': {
    name: 'Tieton',
    postalCode: '98947',
  },
  'tiffin-ia': {
    name: 'Tiffin',
    postalCode: '52340',
  },
  'tiffin-oh': {
    name: 'Tiffin',
    postalCode: '44883',
  },
  'tifton-ga': {
    name: 'Tifton',
    postalCode: '31793',
  },
  'tiger-ga': {
    name: 'Tiger',
    postalCode: '30576',
  },
  'tigerton-wi': {
    name: 'Tigerton',
    postalCode: '54486',
  },
  'tignall-ga': {
    name: 'Tignall',
    postalCode: '30668',
  },
  'tijeras-nm': {
    name: 'Tijeras',
    postalCode: '87059',
  },
  'tilden-ne': {
    name: 'Tilden',
    postalCode: '68781',
  },
  'tilden-tx': {
    name: 'Tilden',
    postalCode: '78072',
  },
  'tilghman-md': {
    name: 'Tilghman',
    postalCode: '21671',
  },
  'tiline-ky': {
    name: 'Tiline',
    postalCode: '42083',
  },
  'tillamook-or': {
    name: 'Tillamook',
    postalCode: '97141',
  },
  'tillar-ar': {
    name: 'Tillar',
    postalCode: '71670',
  },
  'tillatoba-ms': {
    name: 'Tillatoba',
    postalCode: '38961',
  },
  'tilleda-wi': {
    name: 'Tilleda',
    postalCode: '54978',
  },
  'tiller-or': {
    name: 'Tiller',
    postalCode: '97484',
  },
  'tillman-sc': {
    name: 'Tillman',
    postalCode: '29943',
  },
  'tillson-ny': {
    name: 'Tillson',
    postalCode: '12486',
  },
  'tilly-ar': {
    name: 'Tilly',
    postalCode: '72679',
  },
  'tilton-il': {
    name: 'Tilton',
    postalCode: '61833',
  },
  'tilton-nh': {
    name: 'Tilton',
    postalCode: '03276',
  },
  'tiltonsville-oh': {
    name: 'Tiltonsville',
    postalCode: '43963',
  },
  'timber-or': {
    name: 'Timber',
    postalCode: '97144',
  },
  'timber-lake-sd': {
    name: 'Timber Lake',
    postalCode: '57656',
  },
  'timberlake-nc': {
    name: 'Timberlake',
    postalCode: '27583',
  },
  'timberville-va': {
    name: 'Timberville',
    postalCode: '22853',
  },
  'timblin-pa': {
    name: 'Timblin',
    postalCode: '15778',
  },
  'timbo-ar': {
    name: 'Timbo',
    postalCode: '72657',
  },
  'timewell-il': {
    name: 'Timewell',
    postalCode: '62375',
  },
  'timmonsville-sc': {
    name: 'Timmonsville',
    postalCode: '29161',
  },
  'timnath-co': {
    name: 'Timnath',
    postalCode: '80547',
  },
  'timpson-tx': {
    name: 'Timpson',
    postalCode: '75975',
  },
  'tina-mo': {
    name: 'Tina',
    postalCode: '64682',
  },
  'tingley-ia': {
    name: 'Tingley',
    postalCode: '50863',
  },
  'tinley-park-il': {
    name: 'Tinley Park',
    postalCode: '60477',
  },
  'tinnie-nm': {
    name: 'Tinnie',
    postalCode: '88351',
  },
  'tinsley-ms': {
    name: 'Tinsley',
    postalCode: '39173',
  },
  'tintah-mn': {
    name: 'Tintah',
    postalCode: '56583',
  },
  'tioga-nd': {
    name: 'Tioga',
    postalCode: '58852',
  },
  'tioga-pa': {
    name: 'Tioga',
    postalCode: '16946',
  },
  'tioga-tx': {
    name: 'Tioga',
    postalCode: '76271',
  },
  'tioga-wv': {
    name: 'Tioga',
    postalCode: '26691',
  },
  'tionesta-pa': {
    name: 'Tionesta',
    postalCode: '16353',
  },
  'tiplersville-ms': {
    name: 'Tiplersville',
    postalCode: '38674',
  },
  'tipp-city-oh': {
    name: 'Tipp City',
    postalCode: '45371',
  },
  'tippecanoe-in': {
    name: 'Tippecanoe',
    postalCode: '46570',
  },
  'tippecanoe-oh': {
    name: 'Tippecanoe',
    postalCode: '44699',
  },
  'tipton-ca': {
    name: 'Tipton',
    postalCode: '93272',
  },
  'tipton-ia': {
    name: 'Tipton',
    postalCode: '52772',
  },
  'tipton-in': {
    name: 'Tipton',
    postalCode: '46072',
  },
  'tipton-ks': {
    name: 'Tipton',
    postalCode: '67485',
  },
  'tipton-mi': {
    name: 'Tipton',
    postalCode: '49287',
  },
  'tipton-mo': {
    name: 'Tipton',
    postalCode: '65081',
  },
  'tipton-ok': {
    name: 'Tipton',
    postalCode: '73570',
  },
  'tiptonville-tn': {
    name: 'Tiptonville',
    postalCode: '38079',
  },
  'tiro-oh': {
    name: 'Tiro',
    postalCode: '44887',
  },
  'tishomingo-ms': {
    name: 'Tishomingo',
    postalCode: '38873',
  },
  'tishomingo-ok': {
    name: 'Tishomingo',
    postalCode: '73460',
  },
  'tiskilwa-il': {
    name: 'Tiskilwa',
    postalCode: '61368',
  },
  'titonka-ia': {
    name: 'Titonka',
    postalCode: '50480',
  },
  'titus-al': {
    name: 'Titus',
    postalCode: '36080',
  },
  'titusville-fl': {
    name: 'Titusville',
    postalCode: '32780',
  },
  'titusville-nj': {
    name: 'Titusville',
    postalCode: '08560',
  },
  'titusville-pa': {
    name: 'Titusville',
    postalCode: '16354',
  },
  'tiverton-ri': {
    name: 'Tiverton',
    postalCode: '02878',
  },
  'tivoli-ny': {
    name: 'Tivoli',
    postalCode: '12583',
  },
  'tivoli-tx': {
    name: 'Tivoli',
    postalCode: '77990',
  },
  'toa-alta-pr': {
    name: 'Toa Alta',
    postalCode: '00953',
  },
  'toa-baja-pr': {
    name: 'Toa Baja',
    postalCode: '00949',
  },
  'toano-va': {
    name: 'Toano',
    postalCode: '23168',
  },
  'tobaccoville-nc': {
    name: 'Tobaccoville',
    postalCode: '27050',
  },
  'tobias-ne': {
    name: 'Tobias',
    postalCode: '68453',
  },
  'tobyhanna-pa': {
    name: 'Tobyhanna',
    postalCode: '18466',
  },
  'toccoa-ga': {
    name: 'Toccoa',
    postalCode: '30577',
  },
  'todd-nc': {
    name: 'Todd',
    postalCode: '28684',
  },
  'todd-pa': {
    name: 'Todd',
    postalCode: '16685',
  },
  'toddville-ia': {
    name: 'Toddville',
    postalCode: '52341',
  },
  'toddville-md': {
    name: 'Toddville',
    postalCode: '21672',
  },
  'toeterville-ia': {
    name: 'Toeterville',
    postalCode: '50481',
  },
  'tofte-mn': {
    name: 'Tofte',
    postalCode: '55615',
  },
  'tohatchi-nm': {
    name: 'Tohatchi',
    postalCode: '87325',
  },
  'toivola-mi': {
    name: 'Toivola',
    postalCode: '49965',
  },
  'tok-ak': {
    name: 'Tok',
    postalCode: '99779',
  },
  'tokeland-wa': {
    name: 'Tokeland',
    postalCode: '98590',
  },
  'tokio-tx': {
    name: 'Tokio',
    postalCode: '79376',
  },
  'tolar-tx': {
    name: 'Tolar',
    postalCode: '76476',
  },
  'toledo-ia': {
    name: 'Toledo',
    postalCode: '52342',
  },
  'toledo-il': {
    name: 'Toledo',
    postalCode: '62468',
  },
  'toledo-oh': {
    name: 'Toledo',
    postalCode: '43601',
  },
  'toledo-or': {
    name: 'Toledo',
    postalCode: '97391',
  },
  'toledo-wa': {
    name: 'Toledo',
    postalCode: '98591',
  },
  'tolland-ct': {
    name: 'Tolland',
    postalCode: '06084',
  },
  'tollesboro-ky': {
    name: 'Tollesboro',
    postalCode: '41189',
  },
  'tolleson-az': {
    name: 'Tolleson',
    postalCode: '85353',
  },
  'tolley-nd': {
    name: 'Tolley',
    postalCode: '58787',
  },
  'tollhouse-ca': {
    name: 'Tollhouse',
    postalCode: '93667',
  },
  'tolna-nd': {
    name: 'Tolna',
    postalCode: '58380',
  },
  'tolono-il': {
    name: 'Tolono',
    postalCode: '61880',
  },
  'tolstoy-sd': {
    name: 'Tolstoy',
    postalCode: '57475',
  },
  'toluca-il': {
    name: 'Toluca',
    postalCode: '61369',
  },
  'tomah-wi': {
    name: 'Tomah',
    postalCode: '54660',
  },
  'tomahawk-ky': {
    name: 'Tomahawk',
    postalCode: '41262',
  },
  'tomahawk-wi': {
    name: 'Tomahawk',
    postalCode: '54487',
  },
  'tomball-tx': {
    name: 'Tomball',
    postalCode: '77375',
  },
  'tombstone-az': {
    name: 'Tombstone',
    postalCode: '85638',
  },
  'tomkins-cove-ny': {
    name: 'Tomkins Cove',
    postalCode: '10986',
  },
  'tompkinsville-ky': {
    name: 'Tompkinsville',
    postalCode: '42167',
  },
  'toms-brook-va': {
    name: 'Toms Brook',
    postalCode: '22660',
  },
  'toms-river-nj': {
    name: 'Toms River',
    postalCode: '08753',
  },
  'tonalea-az': {
    name: 'Tonalea',
    postalCode: '86044',
  },
  'tonasket-wa': {
    name: 'Tonasket',
    postalCode: '98855',
  },
  'tonawanda-ny': {
    name: 'Tonawanda',
    postalCode: '14150',
  },
  'toney-al': {
    name: 'Toney',
    postalCode: '35773',
  },
  'tonganoxie-ks': {
    name: 'Tonganoxie',
    postalCode: '66086',
  },
  'tonica-il': {
    name: 'Tonica',
    postalCode: '61370',
  },
  'tonkawa-ok': {
    name: 'Tonkawa',
    postalCode: '74653',
  },
  'tonopah-az': {
    name: 'Tonopah',
    postalCode: '85354',
  },
  'tonopah-nv': {
    name: 'Tonopah',
    postalCode: '89049',
  },
  'tony-wi': {
    name: 'Tony',
    postalCode: '54563',
  },
  'tooele-ut': {
    name: 'Tooele',
    postalCode: '84074',
  },
  'toomsboro-ga': {
    name: 'Toomsboro',
    postalCode: '31090',
  },
  'toomsuba-ms': {
    name: 'Toomsuba',
    postalCode: '39364',
  },
  'toone-tn': {
    name: 'Toone',
    postalCode: '38381',
  },
  'topanga-ca': {
    name: 'Topanga',
    postalCode: '90290',
  },
  'topaz-ca': {
    name: 'Topaz',
    postalCode: '96133',
  },
  'topeka-il': {
    name: 'Topeka',
    postalCode: '61567',
  },
  'topeka-in': {
    name: 'Topeka',
    postalCode: '46571',
  },
  'topeka-ks': {
    name: 'Topeka',
    postalCode: '66603',
  },
  'topmost-ky': {
    name: 'Topmost',
    postalCode: '41862',
  },
  'topock-az': {
    name: 'Topock',
    postalCode: '86436',
  },
  'toppenish-wa': {
    name: 'Toppenish',
    postalCode: '98948',
  },
  'topping-va': {
    name: 'Topping',
    postalCode: '23169',
  },
  'topsfield-ma': {
    name: 'Topsfield',
    postalCode: '01983',
  },
  'topsfield-me': {
    name: 'Topsfield',
    postalCode: '04490',
  },
  'topsham-me': {
    name: 'Topsham',
    postalCode: '04086',
  },
  'topsham-vt': {
    name: 'Topsham',
    postalCode: '05076',
  },
  'topton-nc': {
    name: 'Topton',
    postalCode: '28781',
  },
  'topton-pa': {
    name: 'Topton',
    postalCode: '19562',
  },
  'toquerville-ut': {
    name: 'Toquerville',
    postalCode: '84774',
  },
  'tornado-wv': {
    name: 'Tornado',
    postalCode: '25202',
  },
  'toronto-ks': {
    name: 'Toronto',
    postalCode: '66777',
  },
  'toronto-oh': {
    name: 'Toronto',
    postalCode: '43964',
  },
  'toronto-sd': {
    name: 'Toronto',
    postalCode: '57268',
  },
  'torrance-ca': {
    name: 'Torrance',
    postalCode: '90501',
  },
  'torrey-ut': {
    name: 'Torrey',
    postalCode: '84775',
  },
  'torrington-ct': {
    name: 'Torrington',
    postalCode: '06790',
  },
  'torrington-wy': {
    name: 'Torrington',
    postalCode: '82240',
  },
  'toston-mt': {
    name: 'Toston',
    postalCode: '59643',
  },
  'totowa-nj': {
    name: 'Totowa',
    postalCode: '07512',
  },
  'totz-ky': {
    name: 'Totz',
    postalCode: '40870',
  },
  'touchet-wa': {
    name: 'Touchet',
    postalCode: '99360',
  },
  'toughkenamon-pa': {
    name: 'Toughkenamon',
    postalCode: '19374',
  },
  'toulon-il': {
    name: 'Toulon',
    postalCode: '61483',
  },
  'toutle-wa': {
    name: 'Toutle',
    postalCode: '98649',
  },
  'tow-tx': {
    name: 'Tow',
    postalCode: '78672',
  },
  'towaco-nj': {
    name: 'Towaco',
    postalCode: '07082',
  },
  'towanda-il': {
    name: 'Towanda',
    postalCode: '61776',
  },
  'towanda-ks': {
    name: 'Towanda',
    postalCode: '67144',
  },
  'towanda-pa': {
    name: 'Towanda',
    postalCode: '18848',
  },
  'tower-mn': {
    name: 'Tower',
    postalCode: '55790',
  },
  'tower-city-nd': {
    name: 'Tower City',
    postalCode: '58071',
  },
  'tower-city-pa': {
    name: 'Tower City',
    postalCode: '17980',
  },
  'tower-hill-il': {
    name: 'Tower Hill',
    postalCode: '62571',
  },
  'town-creek-al': {
    name: 'Town Creek',
    postalCode: '35672',
  },
  'towner-nd': {
    name: 'Towner',
    postalCode: '58788',
  },
  'townley-al': {
    name: 'Townley',
    postalCode: '35587',
  },
  'townsend-de': {
    name: 'Townsend',
    postalCode: '19734',
  },
  'townsend-ga': {
    name: 'Townsend',
    postalCode: '31331',
  },
  'townsend-ma': {
    name: 'Townsend',
    postalCode: '01469',
  },
  'townsend-mt': {
    name: 'Townsend',
    postalCode: '59644',
  },
  'townsend-tn': {
    name: 'Townsend',
    postalCode: '37882',
  },
  'townsend-wi': {
    name: 'Townsend',
    postalCode: '54175',
  },
  'townshend-vt': {
    name: 'Townshend',
    postalCode: '05353',
  },
  'township-of-washington-nj': {
    name: 'Township Of Washington',
    postalCode: '07676',
  },
  'townville-pa': {
    name: 'Townville',
    postalCode: '16360',
  },
  'townville-sc': {
    name: 'Townville',
    postalCode: '29689',
  },
  'towson-md': {
    name: 'Towson',
    postalCode: '21204',
  },
  'toxey-al': {
    name: 'Toxey',
    postalCode: '36921',
  },
  'trabuco-canyon-ca': {
    name: 'Trabuco Canyon',
    postalCode: '92679',
  },
  'tracy-ca': {
    name: 'Tracy',
    postalCode: '95304',
  },
  'tracy-ia': {
    name: 'Tracy',
    postalCode: '50256',
  },
  'tracy-mn': {
    name: 'Tracy',
    postalCode: '56175',
  },
  'tracy-city-tn': {
    name: 'Tracy City',
    postalCode: '37387',
  },
  'tracys-landing-md': {
    name: 'Tracys Landing',
    postalCode: '20779',
  },
  'trade-tn': {
    name: 'Trade',
    postalCode: '37691',
  },
  'traer-ia': {
    name: 'Traer',
    postalCode: '50675',
  },
  'trafalgar-in': {
    name: 'Trafalgar',
    postalCode: '46181',
  },
  'trafford-al': {
    name: 'Trafford',
    postalCode: '35172',
  },
  'trafford-pa': {
    name: 'Trafford',
    postalCode: '15085',
  },
  'trail-mn': {
    name: 'Trail',
    postalCode: '56684',
  },
  'trail-or': {
    name: 'Trail',
    postalCode: '97541',
  },
  'trail-city-sd': {
    name: 'Trail City',
    postalCode: '57657',
  },
  'tram-ky': {
    name: 'Tram',
    postalCode: '41663',
  },
  'trampas-nm': {
    name: 'Trampas',
    postalCode: '87576',
  },
  'tranquillity-ca': {
    name: 'Tranquillity',
    postalCode: '93668',
  },
  'transfer-pa': {
    name: 'Transfer',
    postalCode: '16154',
  },
  'transylvania-la': {
    name: 'Transylvania',
    postalCode: '71286',
  },
  'traphill-nc': {
    name: 'Traphill',
    postalCode: '28685',
  },
  'trappe-md': {
    name: 'Trappe',
    postalCode: '21673',
  },
  'traskwood-ar': {
    name: 'Traskwood',
    postalCode: '72167',
  },
  'travelers-rest-sc': {
    name: 'Travelers Rest',
    postalCode: '29690',
  },
  'traverse-city-mi': {
    name: 'Traverse City',
    postalCode: '49684',
  },
  'travis-afb-ca': {
    name: 'Travis AFB',
    postalCode: '94535',
  },
  'treadwell-ny': {
    name: 'Treadwell',
    postalCode: '13846',
  },
  'treece-ks': {
    name: 'Treece',
    postalCode: '66778',
  },
  'trego-wi': {
    name: 'Trego',
    postalCode: '54888',
  },
  'trementina-nm': {
    name: 'Trementina',
    postalCode: '88439',
  },
  'tremont-il': {
    name: 'Tremont',
    postalCode: '61568',
  },
  'tremont-ms': {
    name: 'Tremont',
    postalCode: '38876',
  },
  'tremont-pa': {
    name: 'Tremont',
    postalCode: '17981',
  },
  'tremonton-ut': {
    name: 'Tremonton',
    postalCode: '84337',
  },
  'trempealeau-wi': {
    name: 'Trempealeau',
    postalCode: '54661',
  },
  'trenary-mi': {
    name: 'Trenary',
    postalCode: '49891',
  },
  'trent-sd': {
    name: 'Trent',
    postalCode: '57065',
  },
  'trent-tx': {
    name: 'Trent',
    postalCode: '79561',
  },
  'trenton-al': {
    name: 'Trenton',
    postalCode: '35774',
  },
  'trenton-fl': {
    name: 'Trenton',
    postalCode: '32693',
  },
  'trenton-ga': {
    name: 'Trenton',
    postalCode: '30752',
  },
  'trenton-il': {
    name: 'Trenton',
    postalCode: '62293',
  },
  'trenton-ky': {
    name: 'Trenton',
    postalCode: '42286',
  },
  'trenton-mi': {
    name: 'Trenton',
    postalCode: '48183',
  },
  'trenton-mo': {
    name: 'Trenton',
    postalCode: '64683',
  },
  'trenton-nc': {
    name: 'Trenton',
    postalCode: '28585',
  },
  'trenton-ne': {
    name: 'Trenton',
    postalCode: '69044',
  },
  'trenton-nj': {
    name: 'Trenton',
    postalCode: '08608',
  },
  'trenton-oh': {
    name: 'Trenton',
    postalCode: '45067',
  },
  'trenton-sc': {
    name: 'Trenton',
    postalCode: '29847',
  },
  'trenton-tn': {
    name: 'Trenton',
    postalCode: '38382',
  },
  'trenton-tx': {
    name: 'Trenton',
    postalCode: '75490',
  },
  'trenton-ut': {
    name: 'Trenton',
    postalCode: '84338',
  },
  'tres-pinos-ca': {
    name: 'Tres Pinos',
    postalCode: '95075',
  },
  'trevett-me': {
    name: 'Trevett',
    postalCode: '04571',
  },
  'trevor-wi': {
    name: 'Trevor',
    postalCode: '53179',
  },
  'trevorton-pa': {
    name: 'Trevorton',
    postalCode: '17881',
  },
  'trexlertown-pa': {
    name: 'Trexlertown',
    postalCode: '18087',
  },
  'treynor-ia': {
    name: 'Treynor',
    postalCode: '51575',
  },
  'trezevant-tn': {
    name: 'Trezevant',
    postalCode: '38258',
  },
  'triadelphia-wv': {
    name: 'Triadelphia',
    postalCode: '26059',
  },
  'triangle-va': {
    name: 'Triangle',
    postalCode: '22172',
  },
  'tribune-ks': {
    name: 'Tribune',
    postalCode: '67879',
  },
  'tridell-ut': {
    name: 'Tridell',
    postalCode: '84076',
  },
  'trilla-il': {
    name: 'Trilla',
    postalCode: '62469',
  },
  'trimble-mo': {
    name: 'Trimble',
    postalCode: '64492',
  },
  'trimble-tn': {
    name: 'Trimble',
    postalCode: '38259',
  },
  'trimont-mn': {
    name: 'Trimont',
    postalCode: '56176',
  },
  'trinchera-co': {
    name: 'Trinchera',
    postalCode: '81081',
  },
  'trinidad-ca': {
    name: 'Trinidad',
    postalCode: '95570',
  },
  'trinidad-co': {
    name: 'Trinidad',
    postalCode: '81082',
  },
  'trinidad-tx': {
    name: 'Trinidad',
    postalCode: '75163',
  },
  'trinity-al': {
    name: 'Trinity',
    postalCode: '35673',
  },
  'trinity-nc': {
    name: 'Trinity',
    postalCode: '27370',
  },
  'trinity-tx': {
    name: 'Trinity',
    postalCode: '75862',
  },
  'trinity-center-ca': {
    name: 'Trinity Center',
    postalCode: '96091',
  },
  'trion-ga': {
    name: 'Trion',
    postalCode: '30753',
  },
  'tripler-army-medical-center-hi': {
    name: 'Tripler Army Medical Center',
    postalCode: '96859',
  },
  'triplett-mo': {
    name: 'Triplett',
    postalCode: '65286',
  },
  'tripoli-ia': {
    name: 'Tripoli',
    postalCode: '50676',
  },
  'tripoli-wi': {
    name: 'Tripoli',
    postalCode: '54564',
  },
  'tripp-sd': {
    name: 'Tripp',
    postalCode: '57376',
  },
  'trivoli-il': {
    name: 'Trivoli',
    postalCode: '61569',
  },
  'trona-ca': {
    name: 'Trona',
    postalCode: '93562',
  },
  'trosper-ky': {
    name: 'Trosper',
    postalCode: '40995',
  },
  'troup-tx': {
    name: 'Troup',
    postalCode: '75789',
  },
  'troupsburg-ny': {
    name: 'Troupsburg',
    postalCode: '14885',
  },
  'trout-la': {
    name: 'Trout',
    postalCode: '71371',
  },
  'trout-creek-mi': {
    name: 'Trout Creek',
    postalCode: '49967',
  },
  'trout-creek-mt': {
    name: 'Trout Creek',
    postalCode: '59874',
  },
  'trout-lake-wa': {
    name: 'Trout Lake',
    postalCode: '98650',
  },
  'trout-run-pa': {
    name: 'Trout Run',
    postalCode: '17771',
  },
  'troutdale-or': {
    name: 'Troutdale',
    postalCode: '97060',
  },
  'troutdale-va': {
    name: 'Troutdale',
    postalCode: '24378',
  },
  'troutman-nc': {
    name: 'Troutman',
    postalCode: '28166',
  },
  'troutville-pa': {
    name: 'Troutville',
    postalCode: '15866',
  },
  'troutville-va': {
    name: 'Troutville',
    postalCode: '24175',
  },
  'troy-al': {
    name: 'Troy',
    postalCode: '36079',
  },
  'troy-id': {
    name: 'Troy',
    postalCode: '83871',
  },
  'troy-il': {
    name: 'Troy',
    postalCode: '62294',
  },
  'troy-in': {
    name: 'Troy',
    postalCode: '47588',
  },
  'troy-ks': {
    name: 'Troy',
    postalCode: '66087',
  },
  'troy-me': {
    name: 'Troy',
    postalCode: '04987',
  },
  'troy-mi': {
    name: 'Troy',
    postalCode: '48083',
  },
  'troy-mo': {
    name: 'Troy',
    postalCode: '63379',
  },
  'troy-mt': {
    name: 'Troy',
    postalCode: '59935',
  },
  'troy-nc': {
    name: 'Troy',
    postalCode: '27371',
  },
  'troy-nh': {
    name: 'Troy',
    postalCode: '03465',
  },
  'troy-ny': {
    name: 'Troy',
    postalCode: '12180',
  },
  'troy-oh': {
    name: 'Troy',
    postalCode: '45373',
  },
  'troy-pa': {
    name: 'Troy',
    postalCode: '16947',
  },
  'troy-sc': {
    name: 'Troy',
    postalCode: '29848',
  },
  'troy-tn': {
    name: 'Troy',
    postalCode: '38260',
  },
  'troy-tx': {
    name: 'Troy',
    postalCode: '76579',
  },
  'troy-va': {
    name: 'Troy',
    postalCode: '22974',
  },
  'troy-vt': {
    name: 'Troy',
    postalCode: '05868',
  },
  'troy-wv': {
    name: 'Troy',
    postalCode: '26443',
  },
  'truckee-ca': {
    name: 'Truckee',
    postalCode: '96161',
  },
  'truesdale-mo': {
    name: 'Truesdale',
    postalCode: '63380',
  },
  'trufant-mi': {
    name: 'Trufant',
    postalCode: '49347',
  },
  'trujillo-alto-pr': {
    name: 'Trujillo Alto',
    postalCode: '00976',
  },
  'truman-mn': {
    name: 'Truman',
    postalCode: '56088',
  },
  'trumann-ar': {
    name: 'Trumann',
    postalCode: '72472',
  },
  'trumansburg-ny': {
    name: 'Trumansburg',
    postalCode: '14886',
  },
  'trumbull-ct': {
    name: 'Trumbull',
    postalCode: '06611',
  },
  'trumbull-ne': {
    name: 'Trumbull',
    postalCode: '68980',
  },
  'truro-ia': {
    name: 'Truro',
    postalCode: '50257',
  },
  'trussville-al': {
    name: 'Trussville',
    postalCode: '35173',
  },
  'truth-or-consequences-nm': {
    name: 'Truth Or Consequences',
    postalCode: '87901',
  },
  'truxton-mo': {
    name: 'Truxton',
    postalCode: '63381',
  },
  'truxton-ny': {
    name: 'Truxton',
    postalCode: '13158',
  },
  'tryon-nc': {
    name: 'Tryon',
    postalCode: '28782',
  },
  'tryon-ne': {
    name: 'Tryon',
    postalCode: '69167',
  },
  'tryon-ok': {
    name: 'Tryon',
    postalCode: '74875',
  },
  'tualatin-or': {
    name: 'Tualatin',
    postalCode: '97062',
  },
  'tuckahoe-ny': {
    name: 'Tuckahoe',
    postalCode: '10707',
  },
  'tuckasegee-nc': {
    name: 'Tuckasegee',
    postalCode: '28783',
  },
  'tucker-ar': {
    name: 'Tucker',
    postalCode: '72168',
  },
  'tucker-ga': {
    name: 'Tucker',
    postalCode: '30084',
  },
  'tuckerman-ar': {
    name: 'Tuckerman',
    postalCode: '72473',
  },
  'tuckerton-nj': {
    name: 'Tuckerton',
    postalCode: '08087',
  },
  'tucson-az': {
    name: 'Tucson',
    postalCode: '85701',
  },
  'tucumcari-nm': {
    name: 'Tucumcari',
    postalCode: '88401',
  },
  'tujunga-ca': {
    name: 'Tujunga',
    postalCode: '91042',
  },
  'tulare-ca': {
    name: 'Tulare',
    postalCode: '93274',
  },
  'tulare-sd': {
    name: 'Tulare',
    postalCode: '57476',
  },
  'tularosa-nm': {
    name: 'Tularosa',
    postalCode: '88352',
  },
  'tulelake-ca': {
    name: 'Tulelake',
    postalCode: '96134',
  },
  'tulia-tx': {
    name: 'Tulia',
    postalCode: '79088',
  },
  'tullahoma-tn': {
    name: 'Tullahoma',
    postalCode: '37388',
  },
  'tullos-la': {
    name: 'Tullos',
    postalCode: '71479',
  },
  'tully-ny': {
    name: 'Tully',
    postalCode: '13159',
  },
  'tulsa-ok': {
    name: 'Tulsa',
    postalCode: '74103',
  },
  'tumacacori-az': {
    name: 'Tumacacori',
    postalCode: '85640',
  },
  'tumbling-shoals-ar': {
    name: 'Tumbling Shoals',
    postalCode: '72581',
  },
  'tumtum-wa': {
    name: 'Tumtum',
    postalCode: '99034',
  },
  'tunas-mo': {
    name: 'Tunas',
    postalCode: '65764',
  },
  'tunbridge-vt': {
    name: 'Tunbridge',
    postalCode: '05077',
  },
  'tunica-ms': {
    name: 'Tunica',
    postalCode: '38676',
  },
  'tunkhannock-pa': {
    name: 'Tunkhannock',
    postalCode: '18657',
  },
  'tunnel-hill-ga': {
    name: 'Tunnel Hill',
    postalCode: '30755',
  },
  'tunnelton-wv': {
    name: 'Tunnelton',
    postalCode: '26444',
  },
  'tuolumne-ca': {
    name: 'Tuolumne',
    postalCode: '95379',
  },
  'tupelo-ms': {
    name: 'Tupelo',
    postalCode: '38801',
  },
  'tupelo-ok': {
    name: 'Tupelo',
    postalCode: '74572',
  },
  'tupper-lake-ny': {
    name: 'Tupper Lake',
    postalCode: '12986',
  },
  'turbeville-sc': {
    name: 'Turbeville',
    postalCode: '29162',
  },
  'turbotville-pa': {
    name: 'Turbotville',
    postalCode: '17772',
  },
  'turin-ny': {
    name: 'Turin',
    postalCode: '13473',
  },
  'turkey-nc': {
    name: 'Turkey',
    postalCode: '28393',
  },
  'turkey-tx': {
    name: 'Turkey',
    postalCode: '79261',
  },
  'turlock-ca': {
    name: 'Turlock',
    postalCode: '95380',
  },
  'turner-me': {
    name: 'Turner',
    postalCode: '04282',
  },
  'turner-mi': {
    name: 'Turner',
    postalCode: '48765',
  },
  'turner-mt': {
    name: 'Turner',
    postalCode: '59542',
  },
  'turner-or': {
    name: 'Turner',
    postalCode: '97392',
  },
  'turners-falls-ma': {
    name: 'Turners Falls',
    postalCode: '01376',
  },
  'turners-station-ky': {
    name: 'Turners Station',
    postalCode: '40075',
  },
  'turney-mo': {
    name: 'Turney',
    postalCode: '64493',
  },
  'turon-ks': {
    name: 'Turon',
    postalCode: '67583',
  },
  'turpin-ok': {
    name: 'Turpin',
    postalCode: '73950',
  },
  'turrell-ar': {
    name: 'Turrell',
    postalCode: '72384',
  },
  'turtle-creek-pa': {
    name: 'Turtle Creek',
    postalCode: '15145',
  },
  'turtle-creek-wv': {
    name: 'Turtle Creek',
    postalCode: '25203',
  },
  'turtle-lake-nd': {
    name: 'Turtle Lake',
    postalCode: '58575',
  },
  'turtle-lake-wi': {
    name: 'Turtle Lake',
    postalCode: '54889',
  },
  'turtlepoint-pa': {
    name: 'Turtlepoint',
    postalCode: '16750',
  },
  'turtletown-tn': {
    name: 'Turtletown',
    postalCode: '37391',
  },
  'turton-sd': {
    name: 'Turton',
    postalCode: '57477',
  },
  'tuscaloosa-al': {
    name: 'Tuscaloosa',
    postalCode: '35401',
  },
  'tuscarora-md': {
    name: 'Tuscarora',
    postalCode: '21790',
  },
  'tuscarora-nv': {
    name: 'Tuscarora',
    postalCode: '89834',
  },
  'tuscarora-pa': {
    name: 'Tuscarora',
    postalCode: '17982',
  },
  'tuscola-il': {
    name: 'Tuscola',
    postalCode: '61953',
  },
  'tuscola-tx': {
    name: 'Tuscola',
    postalCode: '79562',
  },
  'tuscumbia-al': {
    name: 'Tuscumbia',
    postalCode: '35674',
  },
  'tuscumbia-mo': {
    name: 'Tuscumbia',
    postalCode: '65082',
  },
  'tuskahoma-ok': {
    name: 'Tuskahoma',
    postalCode: '74574',
  },
  'tuskegee-al': {
    name: 'Tuskegee',
    postalCode: '36083',
  },
  'tuskegee-institute-al': {
    name: 'Tuskegee Institute',
    postalCode: '36088',
  },
  'tussy-ok': {
    name: 'Tussy',
    postalCode: '73488',
  },
  'tustin-ca': {
    name: 'Tustin',
    postalCode: '92780',
  },
  'tustin-mi': {
    name: 'Tustin',
    postalCode: '49688',
  },
  'tuthill-sd': {
    name: 'Tuthill',
    postalCode: '57574',
  },
  'tutor-key-ky': {
    name: 'Tutor Key',
    postalCode: '41263',
  },
  'tuttle-nd': {
    name: 'Tuttle',
    postalCode: '58488',
  },
  'tuttle-ok': {
    name: 'Tuttle',
    postalCode: '73089',
  },
  'tutwiler-ms': {
    name: 'Tutwiler',
    postalCode: '38963',
  },
  'tuxedo-park-ny': {
    name: 'Tuxedo Park',
    postalCode: '10987',
  },
  'twain-harte-ca': {
    name: 'Twain Harte',
    postalCode: '95383',
  },
  'twelve-mile-in': {
    name: 'Twelve Mile',
    postalCode: '46988',
  },
  'twentynine-palms-ca': {
    name: 'Twentynine Palms',
    postalCode: '92277',
  },
  'twilight-wv': {
    name: 'Twilight',
    postalCode: '25204',
  },
  'twin-bridges-ca': {
    name: 'Twin Bridges',
    postalCode: '95735',
  },
  'twin-bridges-mt': {
    name: 'Twin Bridges',
    postalCode: '59754',
  },
  'twin-brooks-sd': {
    name: 'Twin Brooks',
    postalCode: '57269',
  },
  'twin-city-ga': {
    name: 'Twin City',
    postalCode: '30471',
  },
  'twin-falls-id': {
    name: 'Twin Falls',
    postalCode: '83301',
  },
  'twin-lake-mi': {
    name: 'Twin Lake',
    postalCode: '49457',
  },
  'twin-lakes-co': {
    name: 'Twin Lakes',
    postalCode: '81251',
  },
  'twin-lakes-mn': {
    name: 'Twin Lakes',
    postalCode: '56089',
  },
  'twin-lakes-wi': {
    name: 'Twin Lakes',
    postalCode: '53181',
  },
  'twin-oaks-ok': {
    name: 'Twin Oaks',
    postalCode: '74368',
  },
  'twin-valley-mn': {
    name: 'Twin Valley',
    postalCode: '56584',
  },
  'twining-mi': {
    name: 'Twining',
    postalCode: '48766',
  },
  'twinsburg-oh': {
    name: 'Twinsburg',
    postalCode: '44087',
  },
  'twisp-wa': {
    name: 'Twisp',
    postalCode: '98856',
  },
  'two-buttes-co': {
    name: 'Two Buttes',
    postalCode: '81084',
  },
  'two-dot-mt': {
    name: 'Two Dot',
    postalCode: '59085',
  },
  'two-harbors-mn': {
    name: 'Two Harbors',
    postalCode: '55616',
  },
  'two-rivers-wi': {
    name: 'Two Rivers',
    postalCode: '54241',
  },
  'tyaskin-md': {
    name: 'Tyaskin',
    postalCode: '21865',
  },
  'tybee-island-ga': {
    name: 'Tybee Island',
    postalCode: '31328',
  },
  'tye-tx': {
    name: 'Tye',
    postalCode: '79563',
  },
  'tygh-valley-or': {
    name: 'Tygh Valley',
    postalCode: '97063',
  },
  'tyler-al': {
    name: 'Tyler',
    postalCode: '36785',
  },
  'tyler-mn': {
    name: 'Tyler',
    postalCode: '56178',
  },
  'tyler-tx': {
    name: 'Tyler',
    postalCode: '75701',
  },
  'tyler-hill-pa': {
    name: 'Tyler Hill',
    postalCode: '18469',
  },
  'tylertown-ms': {
    name: 'Tylertown',
    postalCode: '39667',
  },
  'tynan-tx': {
    name: 'Tynan',
    postalCode: '78391',
  },
  'tyndall-sd': {
    name: 'Tyndall',
    postalCode: '57066',
  },
  'tyner-in': {
    name: 'Tyner',
    postalCode: '46572',
  },
  'tyner-ky': {
    name: 'Tyner',
    postalCode: '40486',
  },
  'tyner-nc': {
    name: 'Tyner',
    postalCode: '27980',
  },
  'tyngsboro-ma': {
    name: 'Tyngsboro',
    postalCode: '01879',
  },
  'tyro-va': {
    name: 'Tyro',
    postalCode: '22976',
  },
  'tyrone-ga': {
    name: 'Tyrone',
    postalCode: '30290',
  },
  'tyrone-ok': {
    name: 'Tyrone',
    postalCode: '73951',
  },
  'tyrone-pa': {
    name: 'Tyrone',
    postalCode: '16686',
  },
  'tyronza-ar': {
    name: 'Tyronza',
    postalCode: '72386',
  },
  'ubly-mi': {
    name: 'Ubly',
    postalCode: '48475',
  },
  'udall-ks': {
    name: 'Udall',
    postalCode: '67146',
  },
  'udall-mo': {
    name: 'Udall',
    postalCode: '65766',
  },
  'udell-ia': {
    name: 'Udell',
    postalCode: '52593',
  },
  'uhrichsville-oh': {
    name: 'Uhrichsville',
    postalCode: '44683',
  },
  'ukiah-ca': {
    name: 'Ukiah',
    postalCode: '95482',
  },
  'ulen-mn': {
    name: 'Ulen',
    postalCode: '56585',
  },
  'ullin-il': {
    name: 'Ullin',
    postalCode: '62992',
  },
  'ulman-mo': {
    name: 'Ulman',
    postalCode: '65083',
  },
  'ulmer-sc': {
    name: 'Ulmer',
    postalCode: '29849',
  },
  'ulster-pa': {
    name: 'Ulster',
    postalCode: '18850',
  },
  'ulster-park-ny': {
    name: 'Ulster Park',
    postalCode: '12487',
  },
  'ulysses-ks': {
    name: 'Ulysses',
    postalCode: '67880',
  },
  'ulysses-ky': {
    name: 'Ulysses',
    postalCode: '41264',
  },
  'ulysses-ne': {
    name: 'Ulysses',
    postalCode: '68669',
  },
  'ulysses-pa': {
    name: 'Ulysses',
    postalCode: '16948',
  },
  'umatilla-fl': {
    name: 'Umatilla',
    postalCode: '32784',
  },
  'umatilla-or': {
    name: 'Umatilla',
    postalCode: '97882',
  },
  'umpire-ar': {
    name: 'Umpire',
    postalCode: '71971',
  },
  'umpqua-or': {
    name: 'Umpqua',
    postalCode: '97486',
  },
  'unadilla-ga': {
    name: 'Unadilla',
    postalCode: '31091',
  },
  'unadilla-ne': {
    name: 'Unadilla',
    postalCode: '68454',
  },
  'unadilla-ny': {
    name: 'Unadilla',
    postalCode: '13849',
  },
  'uncasville-ct': {
    name: 'Uncasville',
    postalCode: '06382',
  },
  'underhill-vt': {
    name: 'Underhill',
    postalCode: '05489',
  },
  'underwood-ia': {
    name: 'Underwood',
    postalCode: '51576',
  },
  'underwood-in': {
    name: 'Underwood',
    postalCode: '47177',
  },
  'underwood-mn': {
    name: 'Underwood',
    postalCode: '56586',
  },
  'underwood-nd': {
    name: 'Underwood',
    postalCode: '58576',
  },
  'underwood-wa': {
    name: 'Underwood',
    postalCode: '98651',
  },
  'unicoi-tn': {
    name: 'Unicoi',
    postalCode: '37692',
  },
  'union-ia': {
    name: 'Union',
    postalCode: '50258',
  },
  'union-il': {
    name: 'Union',
    postalCode: '60180',
  },
  'union-ky': {
    name: 'Union',
    postalCode: '41091',
  },
  'union-me': {
    name: 'Union',
    postalCode: '04862',
  },
  'union-mi': {
    name: 'Union',
    postalCode: '49130',
  },
  'union-mo': {
    name: 'Union',
    postalCode: '63084',
  },
  'union-ms': {
    name: 'Union',
    postalCode: '39365',
  },
  'union-ne': {
    name: 'Union',
    postalCode: '68455',
  },
  'union-nh': {
    name: 'Union',
    postalCode: '03887',
  },
  'union-nj': {
    name: 'Union',
    postalCode: '07083',
  },
  'union-or': {
    name: 'Union',
    postalCode: '97883',
  },
  'union-sc': {
    name: 'Union',
    postalCode: '29379',
  },
  'union-wa': {
    name: 'Union',
    postalCode: '98592',
  },
  'union-wv': {
    name: 'Union',
    postalCode: '24983',
  },
  'union-bridge-md': {
    name: 'Union Bridge',
    postalCode: '21791',
  },
  'union-center-sd': {
    name: 'Union Center',
    postalCode: '57787',
  },
  'union-church-ms': {
    name: 'Union Church',
    postalCode: '39668',
  },
  'union-city-ca': {
    name: 'Union City',
    postalCode: '94587',
  },
  'union-city-ga': {
    name: 'Union City',
    postalCode: '30291',
  },
  'union-city-in': {
    name: 'Union City',
    postalCode: '47390',
  },
  'union-city-mi': {
    name: 'Union City',
    postalCode: '49094',
  },
  'union-city-nj': {
    name: 'Union City',
    postalCode: '07087',
  },
  'union-city-oh': {
    name: 'Union City',
    postalCode: '45390',
  },
  'union-city-ok': {
    name: 'Union City',
    postalCode: '73090',
  },
  'union-city-pa': {
    name: 'Union City',
    postalCode: '16438',
  },
  'union-city-tn': {
    name: 'Union City',
    postalCode: '38261',
  },
  'union-dale-pa': {
    name: 'Union Dale',
    postalCode: '18470',
  },
  'union-grove-al': {
    name: 'Union Grove',
    postalCode: '35175',
  },
  'union-grove-nc': {
    name: 'Union Grove',
    postalCode: '28689',
  },
  'union-grove-wi': {
    name: 'Union Grove',
    postalCode: '53182',
  },
  'union-hall-va': {
    name: 'Union Hall',
    postalCode: '24176',
  },
  'union-mills-in': {
    name: 'Union Mills',
    postalCode: '46382',
  },
  'union-mills-nc': {
    name: 'Union Mills',
    postalCode: '28167',
  },
  'union-pier-mi': {
    name: 'Union Pier',
    postalCode: '49129',
  },
  'union-point-ga': {
    name: 'Union Point',
    postalCode: '30669',
  },
  'union-springs-al': {
    name: 'Union Springs',
    postalCode: '36089',
  },
  'union-springs-ny': {
    name: 'Union Springs',
    postalCode: '13160',
  },
  'union-star-ky': {
    name: 'Union Star',
    postalCode: '40171',
  },
  'union-star-mo': {
    name: 'Union Star',
    postalCode: '64494',
  },
  'uniondale-in': {
    name: 'Uniondale',
    postalCode: '46791',
  },
  'uniondale-ny': {
    name: 'Uniondale',
    postalCode: '11553',
  },
  'uniontown-al': {
    name: 'Uniontown',
    postalCode: '36786',
  },
  'uniontown-ar': {
    name: 'Uniontown',
    postalCode: '72955',
  },
  'uniontown-ks': {
    name: 'Uniontown',
    postalCode: '66779',
  },
  'uniontown-ky': {
    name: 'Uniontown',
    postalCode: '42461',
  },
  'uniontown-mo': {
    name: 'Uniontown',
    postalCode: '63783',
  },
  'uniontown-oh': {
    name: 'Uniontown',
    postalCode: '44685',
  },
  'uniontown-pa': {
    name: 'Uniontown',
    postalCode: '15401',
  },
  'uniontown-wa': {
    name: 'Uniontown',
    postalCode: '99179',
  },
  'unionville-ct': {
    name: 'Unionville',
    postalCode: '06085',
  },
  'unionville-ia': {
    name: 'Unionville',
    postalCode: '52594',
  },
  'unionville-in': {
    name: 'Unionville',
    postalCode: '47468',
  },
  'unionville-mi': {
    name: 'Unionville',
    postalCode: '48767',
  },
  'unionville-mo': {
    name: 'Unionville',
    postalCode: '63565',
  },
  'unionville-tn': {
    name: 'Unionville',
    postalCode: '37180',
  },
  'unionville-va': {
    name: 'Unionville',
    postalCode: '22567',
  },
  'unity-me': {
    name: 'Unity',
    postalCode: '04988',
  },
  'unity-or': {
    name: 'Unity',
    postalCode: '97884',
  },
  'unity-wi': {
    name: 'Unity',
    postalCode: '54488',
  },
  'unityville-pa': {
    name: 'Unityville',
    postalCode: '17774',
  },
  'universal-city-ca': {
    name: 'Universal City',
    postalCode: '91608',
  },
  'universal-city-tx': {
    name: 'Universal City',
    postalCode: '78148',
  },
  'university-park-il': {
    name: 'University Park',
    postalCode: '60484',
  },
  'university-park-pa': {
    name: 'University Park',
    postalCode: '16802',
  },
  'university-place-wa': {
    name: 'University Place',
    postalCode: '98467',
  },
  'upatoi-ga': {
    name: 'Upatoi',
    postalCode: '31829',
  },
  'upham-nd': {
    name: 'Upham',
    postalCode: '58789',
  },
  'upland-ca': {
    name: 'Upland',
    postalCode: '91784',
  },
  'upland-in': {
    name: 'Upland',
    postalCode: '46989',
  },
  'upland-ne': {
    name: 'Upland',
    postalCode: '68981',
  },
  'upper-black-eddy-pa': {
    name: 'Upper Black Eddy',
    postalCode: '18972',
  },
  'upper-darby-pa': {
    name: 'Upper Darby',
    postalCode: '19082',
  },
  'upper-falls-md': {
    name: 'Upper Falls',
    postalCode: '21156',
  },
  'upper-jay-ny': {
    name: 'Upper Jay',
    postalCode: '12987',
  },
  'upper-lake-ca': {
    name: 'Upper Lake',
    postalCode: '95485',
  },
  'upper-marlboro-md': {
    name: 'Upper Marlboro',
    postalCode: '20772',
  },
  'upper-sandusky-oh': {
    name: 'Upper Sandusky',
    postalCode: '43351',
  },
  'upper-tract-wv': {
    name: 'Upper Tract',
    postalCode: '26866',
  },
  'upperco-md': {
    name: 'Upperco',
    postalCode: '21155',
  },
  'upperglade-wv': {
    name: 'Upperglade',
    postalCode: '26266',
  },
  'upperstrasburg-pa': {
    name: 'Upperstrasburg',
    postalCode: '17265',
  },
  'upperville-va': {
    name: 'Upperville',
    postalCode: '20184',
  },
  'upsala-mn': {
    name: 'Upsala',
    postalCode: '56384',
  },
  'upson-wi': {
    name: 'Upson',
    postalCode: '54565',
  },
  'upton-ky': {
    name: 'Upton',
    postalCode: '42784',
  },
  'upton-ma': {
    name: 'Upton',
    postalCode: '01568',
  },
  'upton-wy': {
    name: 'Upton',
    postalCode: '82730',
  },
  'urbana-ia': {
    name: 'Urbana',
    postalCode: '52345',
  },
  'urbana-il': {
    name: 'Urbana',
    postalCode: '61801',
  },
  'urbana-in': {
    name: 'Urbana',
    postalCode: '46990',
  },
  'urbana-mo': {
    name: 'Urbana',
    postalCode: '65767',
  },
  'urbana-oh': {
    name: 'Urbana',
    postalCode: '43078',
  },
  'urbandale-ia': {
    name: 'Urbandale',
    postalCode: '50322',
  },
  'urbanna-va': {
    name: 'Urbanna',
    postalCode: '23175',
  },
  'uriah-al': {
    name: 'Uriah',
    postalCode: '36480',
  },
  'urich-mo': {
    name: 'Urich',
    postalCode: '64788',
  },
  'ursa-il': {
    name: 'Ursa',
    postalCode: '62376',
  },
  'usaf-academy-co': {
    name: 'Usaf Academy',
    postalCode: '80840',
  },
  'usk-wa': {
    name: 'Usk',
    postalCode: '99180',
  },
  'ute-ia': {
    name: 'Ute',
    postalCode: '51060',
  },
  'utica-il': {
    name: 'Utica',
    postalCode: '61373',
  },
  'utica-ks': {
    name: 'Utica',
    postalCode: '67584',
  },
  'utica-ky': {
    name: 'Utica',
    postalCode: '42376',
  },
  'utica-mi': {
    name: 'Utica',
    postalCode: '48315',
  },
  'utica-mn': {
    name: 'Utica',
    postalCode: '55979',
  },
  'utica-ms': {
    name: 'Utica',
    postalCode: '39175',
  },
  'utica-ne': {
    name: 'Utica',
    postalCode: '68456',
  },
  'utica-ny': {
    name: 'Utica',
    postalCode: '13501',
  },
  'utica-oh': {
    name: 'Utica',
    postalCode: '43080',
  },
  'utica-pa': {
    name: 'Utica',
    postalCode: '16362',
  },
  'utica-sd': {
    name: 'Utica',
    postalCode: '57067',
  },
  'utopia-tx': {
    name: 'Utopia',
    postalCode: '78884',
  },
  'utuado-pr': {
    name: 'Utuado',
    postalCode: '00641',
  },
  'uvalda-ga': {
    name: 'Uvalda',
    postalCode: '30473',
  },
  'uvalde-tx': {
    name: 'Uvalde',
    postalCode: '78801',
  },
  'uxbridge-ma': {
    name: 'Uxbridge',
    postalCode: '01569',
  },
  'vacaville-ca': {
    name: 'Vacaville',
    postalCode: '95687',
  },
  'vacherie-la': {
    name: 'Vacherie',
    postalCode: '70090',
  },
  'vader-wa': {
    name: 'Vader',
    postalCode: '98593',
  },
  'vadito-nm': {
    name: 'Vadito',
    postalCode: '87579',
  },
  'vado-nm': {
    name: 'Vado',
    postalCode: '88072',
  },
  'vaiden-ms': {
    name: 'Vaiden',
    postalCode: '39176',
  },
  'vail-az': {
    name: 'Vail',
    postalCode: '85641',
  },
  'vail-co': {
    name: 'Vail',
    postalCode: '81657',
  },
  'vail-ia': {
    name: 'Vail',
    postalCode: '51465',
  },
  'valatie-ny': {
    name: 'Valatie',
    postalCode: '12184',
  },
  'valders-wi': {
    name: 'Valders',
    postalCode: '54245',
  },
  'valdese-nc': {
    name: 'Valdese',
    postalCode: '28690',
  },
  'valdez-ak': {
    name: 'Valdez',
    postalCode: '99686',
  },
  'valdez-nm': {
    name: 'Valdez',
    postalCode: '87580',
  },
  'valdosta-ga': {
    name: 'Valdosta',
    postalCode: '31601',
  },
  'vale-nc': {
    name: 'Vale',
    postalCode: '28168',
  },
  'vale-or': {
    name: 'Vale',
    postalCode: '97918',
  },
  'vale-sd': {
    name: 'Vale',
    postalCode: '57788',
  },
  'valencia-ca': {
    name: 'Valencia',
    postalCode: '91354',
  },
  'valencia-pa': {
    name: 'Valencia',
    postalCode: '16059',
  },
  'valentine-az': {
    name: 'Valentine',
    postalCode: '86437',
  },
  'valentine-ne': {
    name: 'Valentine',
    postalCode: '69201',
  },
  'valentine-tx': {
    name: 'Valentine',
    postalCode: '79854',
  },
  'valentines-va': {
    name: 'Valentines',
    postalCode: '23887',
  },
  'valera-tx': {
    name: 'Valera',
    postalCode: '76884',
  },
  'valhalla-ny': {
    name: 'Valhalla',
    postalCode: '10595',
  },
  'valhermoso-springs-al': {
    name: 'Valhermoso Springs',
    postalCode: '35775',
  },
  'valier-mt': {
    name: 'Valier',
    postalCode: '59486',
  },
  'valier-pa': {
    name: 'Valier',
    postalCode: '15780',
  },
  'vallecito-ca': {
    name: 'Vallecito',
    postalCode: '95251',
  },
  'vallecitos-nm': {
    name: 'Vallecitos',
    postalCode: '87581',
  },
  'vallejo-ca': {
    name: 'Vallejo',
    postalCode: '94589',
  },
  'valles-mines-mo': {
    name: 'Valles Mines',
    postalCode: '63087',
  },
  'valley-al': {
    name: 'Valley',
    postalCode: '36854',
  },
  'valley-ne': {
    name: 'Valley',
    postalCode: '68064',
  },
  'valley-wa': {
    name: 'Valley',
    postalCode: '99181',
  },
  'valley-bend-wv': {
    name: 'Valley Bend',
    postalCode: '26293',
  },
  'valley-center-ca': {
    name: 'Valley Center',
    postalCode: '92082',
  },
  'valley-center-ks': {
    name: 'Valley Center',
    postalCode: '67147',
  },
  'valley-city-nd': {
    name: 'Valley City',
    postalCode: '58072',
  },
  'valley-city-oh': {
    name: 'Valley City',
    postalCode: '44280',
  },
  'valley-cottage-ny': {
    name: 'Valley Cottage',
    postalCode: '10989',
  },
  'valley-falls-ks': {
    name: 'Valley Falls',
    postalCode: '66088',
  },
  'valley-falls-ny': {
    name: 'Valley Falls',
    postalCode: '12185',
  },
  'valley-ford-ca': {
    name: 'Valley Ford',
    postalCode: '94972',
  },
  'valley-grove-wv': {
    name: 'Valley Grove',
    postalCode: '26060',
  },
  'valley-head-al': {
    name: 'Valley Head',
    postalCode: '35989',
  },
  'valley-head-wv': {
    name: 'Valley Head',
    postalCode: '26294',
  },
  'valley-lee-md': {
    name: 'Valley Lee',
    postalCode: '20692',
  },
  'valley-mills-tx': {
    name: 'Valley Mills',
    postalCode: '76689',
  },
  'valley-park-mo': {
    name: 'Valley Park',
    postalCode: '63088',
  },
  'valley-spring-tx': {
    name: 'Valley Spring',
    postalCode: '76885',
  },
  'valley-springs-ar': {
    name: 'Valley Springs',
    postalCode: '72682',
  },
  'valley-springs-ca': {
    name: 'Valley Springs',
    postalCode: '95252',
  },
  'valley-springs-sd': {
    name: 'Valley Springs',
    postalCode: '57068',
  },
  'valley-stream-ny': {
    name: 'Valley Stream',
    postalCode: '11580',
  },
  'valley-view-pa': {
    name: 'Valley View',
    postalCode: '17983',
  },
  'valley-view-tx': {
    name: 'Valley View',
    postalCode: '76272',
  },
  'valley-village-ca': {
    name: 'Valley Village',
    postalCode: '91607',
  },
  'valleyford-wa': {
    name: 'Valleyford',
    postalCode: '99036',
  },
  'valliant-ok': {
    name: 'Valliant',
    postalCode: '74764',
  },
  'vallonia-in': {
    name: 'Vallonia',
    postalCode: '47281',
  },
  'valmeyer-il': {
    name: 'Valmeyer',
    postalCode: '62295',
  },
  'valmora-nm': {
    name: 'Valmora',
    postalCode: '87750',
  },
  'valparaiso-fl': {
    name: 'Valparaiso',
    postalCode: '32580',
  },
  'valparaiso-in': {
    name: 'Valparaiso',
    postalCode: '46383',
  },
  'valparaiso-ne': {
    name: 'Valparaiso',
    postalCode: '68065',
  },
  'valrico-fl': {
    name: 'Valrico',
    postalCode: '33594',
  },
  'valyermo-ca': {
    name: 'Valyermo',
    postalCode: '93563',
  },
  'van-tx': {
    name: 'Van',
    postalCode: '75790',
  },
  'van-wv': {
    name: 'Van',
    postalCode: '25206',
  },
  'van-alstyne-tx': {
    name: 'Van Alstyne',
    postalCode: '75495',
  },
  'van-buren-ar': {
    name: 'Van Buren',
    postalCode: '72956',
  },
  'van-buren-in': {
    name: 'Van Buren',
    postalCode: '46991',
  },
  'van-buren-me': {
    name: 'Van Buren',
    postalCode: '04785',
  },
  'van-buren-mo': {
    name: 'Van Buren',
    postalCode: '63965',
  },
  'van-buren-oh': {
    name: 'Van Buren',
    postalCode: '45889',
  },
  'van-dyne-wi': {
    name: 'Van Dyne',
    postalCode: '54979',
  },
  'van-etten-ny': {
    name: 'Van Etten',
    postalCode: '14889',
  },
  'van-horne-ia': {
    name: 'Van Horne',
    postalCode: '52346',
  },
  'van-hornesville-ny': {
    name: 'Van Hornesville',
    postalCode: '13475',
  },
  'van-lear-ky': {
    name: 'Van Lear',
    postalCode: '41265',
  },
  'van-meter-ia': {
    name: 'Van Meter',
    postalCode: '50261',
  },
  'van-nuys-ca': {
    name: 'Van Nuys',
    postalCode: '91401',
  },
  'van-tassell-wy': {
    name: 'Van Tassell',
    postalCode: '82242',
  },
  'van-vleck-tx': {
    name: 'Van Vleck',
    postalCode: '77482',
  },
  'van-wert-ia': {
    name: 'Van Wert',
    postalCode: '50262',
  },
  'van-wert-oh': {
    name: 'Van Wert',
    postalCode: '45891',
  },
  'vance-al': {
    name: 'Vance',
    postalCode: '35490',
  },
  'vance-ms': {
    name: 'Vance',
    postalCode: '38964',
  },
  'vance-sc': {
    name: 'Vance',
    postalCode: '29163',
  },
  'vanceboro-nc': {
    name: 'Vanceboro',
    postalCode: '28586',
  },
  'vanceburg-ky': {
    name: 'Vanceburg',
    postalCode: '41179',
  },
  'vancleave-ms': {
    name: 'Vancleave',
    postalCode: '39565',
  },
  'vancleve-ky': {
    name: 'Vancleve',
    postalCode: '41385',
  },
  'vancourt-tx': {
    name: 'Vancourt',
    postalCode: '76955',
  },
  'vancouver-wa': {
    name: 'Vancouver',
    postalCode: '98660',
  },
  'vandalia-il': {
    name: 'Vandalia',
    postalCode: '62471',
  },
  'vandalia-mi': {
    name: 'Vandalia',
    postalCode: '49095',
  },
  'vandalia-mo': {
    name: 'Vandalia',
    postalCode: '63382',
  },
  'vandalia-mt': {
    name: 'Vandalia',
    postalCode: '59273',
  },
  'vandalia-oh': {
    name: 'Vandalia',
    postalCode: '45377',
  },
  'vandemere-nc': {
    name: 'Vandemere',
    postalCode: '28587',
  },
  'vanderbilt-mi': {
    name: 'Vanderbilt',
    postalCode: '49795',
  },
  'vanderbilt-pa': {
    name: 'Vanderbilt',
    postalCode: '15486',
  },
  'vandergrift-pa': {
    name: 'Vandergrift',
    postalCode: '15690',
  },
  'vanderpool-tx': {
    name: 'Vanderpool',
    postalCode: '78885',
  },
  'vandervoort-ar': {
    name: 'Vandervoort',
    postalCode: '71972',
  },
  'vandiver-al': {
    name: 'Vandiver',
    postalCode: '35176',
  },
  'vanleer-tn': {
    name: 'Vanleer',
    postalCode: '37181',
  },
  'vanlue-oh': {
    name: 'Vanlue',
    postalCode: '45890',
  },
  'vansant-va': {
    name: 'Vansant',
    postalCode: '24656',
  },
  'vanzant-mo': {
    name: 'Vanzant',
    postalCode: '65768',
  },
  'vardaman-ms': {
    name: 'Vardaman',
    postalCode: '38878',
  },
  'varna-il': {
    name: 'Varna',
    postalCode: '61375',
  },
  'varney-ky': {
    name: 'Varney',
    postalCode: '41571',
  },
  'varnville-sc': {
    name: 'Varnville',
    postalCode: '29944',
  },
  'varysburg-ny': {
    name: 'Varysburg',
    postalCode: '14167',
  },
  'vashon-wa': {
    name: 'Vashon',
    postalCode: '98070',
  },
  'vass-nc': {
    name: 'Vass',
    postalCode: '28394',
  },
  'vassalboro-me': {
    name: 'Vassalboro',
    postalCode: '04989',
  },
  'vassar-ks': {
    name: 'Vassar',
    postalCode: '66543',
  },
  'vassar-mi': {
    name: 'Vassar',
    postalCode: '48768',
  },
  'vaughan-ms': {
    name: 'Vaughan',
    postalCode: '39179',
  },
  'vaughn-mt': {
    name: 'Vaughn',
    postalCode: '59487',
  },
  'vaughn-wa': {
    name: 'Vaughn',
    postalCode: '98394',
  },
  'vauxhall-nj': {
    name: 'Vauxhall',
    postalCode: '07088',
  },
  'veblen-sd': {
    name: 'Veblen',
    postalCode: '57270',
  },
  'veedersburg-in': {
    name: 'Veedersburg',
    postalCode: '47987',
  },
  'vega-tx': {
    name: 'Vega',
    postalCode: '79092',
  },
  'vega-alta-pr': {
    name: 'Vega Alta',
    postalCode: '00692',
  },
  'vega-baja-pr': {
    name: 'Vega Baja',
    postalCode: '00693',
  },
  'veguita-nm': {
    name: 'Veguita',
    postalCode: '87062',
  },
  'velpen-in': {
    name: 'Velpen',
    postalCode: '47590',
  },
  'velva-nd': {
    name: 'Velva',
    postalCode: '58790',
  },
  'venango-ne': {
    name: 'Venango',
    postalCode: '69168',
  },
  'venango-pa': {
    name: 'Venango',
    postalCode: '16440',
  },
  'vendor-ar': {
    name: 'Vendor',
    postalCode: '72683',
  },
  'venedocia-oh': {
    name: 'Venedocia',
    postalCode: '45894',
  },
  'veneta-or': {
    name: 'Veneta',
    postalCode: '97487',
  },
  'venetia-pa': {
    name: 'Venetia',
    postalCode: '15367',
  },
  'venice-ca': {
    name: 'Venice',
    postalCode: '90291',
  },
  'venice-fl': {
    name: 'Venice',
    postalCode: '34285',
  },
  'venice-il': {
    name: 'Venice',
    postalCode: '62090',
  },
  'venice-la': {
    name: 'Venice',
    postalCode: '70091',
  },
  'ventnor-city-nj': {
    name: 'Ventnor City',
    postalCode: '08406',
  },
  'ventress-la': {
    name: 'Ventress',
    postalCode: '70783',
  },
  'ventura-ca': {
    name: 'Ventura',
    postalCode: '93001',
  },
  'ventura-ia': {
    name: 'Ventura',
    postalCode: '50482',
  },
  'venus-fl': {
    name: 'Venus',
    postalCode: '33960',
  },
  'venus-pa': {
    name: 'Venus',
    postalCode: '16364',
  },
  'venus-tx': {
    name: 'Venus',
    postalCode: '76084',
  },
  'veradale-wa': {
    name: 'Veradale',
    postalCode: '99037',
  },
  'verbank-ny': {
    name: 'Verbank',
    postalCode: '12585',
  },
  'verbena-al': {
    name: 'Verbena',
    postalCode: '36091',
  },
  'verden-ok': {
    name: 'Verden',
    postalCode: '73092',
  },
  'verdigre-ne': {
    name: 'Verdigre',
    postalCode: '68783',
  },
  'verdon-ne': {
    name: 'Verdon',
    postalCode: '68457',
  },
  'vergas-mn': {
    name: 'Vergas',
    postalCode: '56587',
  },
  'vergennes-il': {
    name: 'Vergennes',
    postalCode: '62994',
  },
  'vergennes-vt': {
    name: 'Vergennes',
    postalCode: '05491',
  },
  'vermilion-oh': {
    name: 'Vermilion',
    postalCode: '44089',
  },
  'vermillion-ks': {
    name: 'Vermillion',
    postalCode: '66544',
  },
  'vermillion-sd': {
    name: 'Vermillion',
    postalCode: '57069',
  },
  'vermont-il': {
    name: 'Vermont',
    postalCode: '61484',
  },
  'vermontville-mi': {
    name: 'Vermontville',
    postalCode: '49096',
  },
  'vermontville-ny': {
    name: 'Vermontville',
    postalCode: '12989',
  },
  'vernal-ut': {
    name: 'Vernal',
    postalCode: '84078',
  },
  'vernalis-ca': {
    name: 'Vernalis',
    postalCode: '95385',
  },
  'verndale-mn': {
    name: 'Verndale',
    postalCode: '56481',
  },
  'verner-wv': {
    name: 'Verner',
    postalCode: '25650',
  },
  'vernon-al': {
    name: 'Vernon',
    postalCode: '35592',
  },
  'vernon-co': {
    name: 'Vernon',
    postalCode: '80755',
  },
  'vernon-fl': {
    name: 'Vernon',
    postalCode: '32462',
  },
  'vernon-il': {
    name: 'Vernon',
    postalCode: '62892',
  },
  'vernon-nj': {
    name: 'Vernon',
    postalCode: '07462',
  },
  'vernon-ny': {
    name: 'Vernon',
    postalCode: '13476',
  },
  'vernon-tx': {
    name: 'Vernon',
    postalCode: '76384',
  },
  'vernon-ut': {
    name: 'Vernon',
    postalCode: '84080',
  },
  'vernon-vt': {
    name: 'Vernon',
    postalCode: '05354',
  },
  'vernon-center-mn': {
    name: 'Vernon Center',
    postalCode: '56090',
  },
  'vernon-center-ny': {
    name: 'Vernon Center',
    postalCode: '13477',
  },
  'vernon-hill-va': {
    name: 'Vernon Hill',
    postalCode: '24597',
  },
  'vernon-hills-il': {
    name: 'Vernon Hills',
    postalCode: '60061',
  },
  'vernon-rockville-ct': {
    name: 'Vernon Rockville',
    postalCode: '06066',
  },
  'vernonia-or': {
    name: 'Vernonia',
    postalCode: '97064',
  },
  'vero-beach-fl': {
    name: 'Vero Beach',
    postalCode: '32960',
  },
  'verona-il': {
    name: 'Verona',
    postalCode: '60479',
  },
  'verona-ky': {
    name: 'Verona',
    postalCode: '41092',
  },
  'verona-mo': {
    name: 'Verona',
    postalCode: '65769',
  },
  'verona-nd': {
    name: 'Verona',
    postalCode: '58490',
  },
  'verona-nj': {
    name: 'Verona',
    postalCode: '07044',
  },
  'verona-ny': {
    name: 'Verona',
    postalCode: '13478',
  },
  'verona-pa': {
    name: 'Verona',
    postalCode: '15147',
  },
  'verona-va': {
    name: 'Verona',
    postalCode: '24482',
  },
  'verona-wi': {
    name: 'Verona',
    postalCode: '53593',
  },
  'versailles-il': {
    name: 'Versailles',
    postalCode: '62378',
  },
  'versailles-in': {
    name: 'Versailles',
    postalCode: '47042',
  },
  'versailles-ky': {
    name: 'Versailles',
    postalCode: '40383',
  },
  'versailles-mo': {
    name: 'Versailles',
    postalCode: '65084',
  },
  'versailles-oh': {
    name: 'Versailles',
    postalCode: '45380',
  },
  'vershire-vt': {
    name: 'Vershire',
    postalCode: '05079',
  },
  'vesper-wi': {
    name: 'Vesper',
    postalCode: '54489',
  },
  'vest-ky': {
    name: 'Vest',
    postalCode: '41772',
  },
  'vesta-mn': {
    name: 'Vesta',
    postalCode: '56292',
  },
  'vestaburg-mi': {
    name: 'Vestaburg',
    postalCode: '48891',
  },
  'vestal-ny': {
    name: 'Vestal',
    postalCode: '13850',
  },
  'vesuvius-va': {
    name: 'Vesuvius',
    postalCode: '24483',
  },
  'veteran-wy': {
    name: 'Veteran',
    postalCode: '82243',
  },
  'vevay-in': {
    name: 'Vevay',
    postalCode: '47043',
  },
  'veyo-ut': {
    name: 'Veyo',
    postalCode: '84782',
  },
  'vian-ok': {
    name: 'Vian',
    postalCode: '74962',
  },
  'viborg-sd': {
    name: 'Viborg',
    postalCode: '57070',
  },
  'viburnum-mo': {
    name: 'Viburnum',
    postalCode: '65566',
  },
  'vicco-ky': {
    name: 'Vicco',
    postalCode: '41773',
  },
  'vichy-mo': {
    name: 'Vichy',
    postalCode: '65580',
  },
  'vici-ok': {
    name: 'Vici',
    postalCode: '73859',
  },
  'vickery-oh': {
    name: 'Vickery',
    postalCode: '43464',
  },
  'vicksburg-mi': {
    name: 'Vicksburg',
    postalCode: '49097',
  },
  'vicksburg-ms': {
    name: 'Vicksburg',
    postalCode: '39180',
  },
  'victor-ia': {
    name: 'Victor',
    postalCode: '52347',
  },
  'victor-id': {
    name: 'Victor',
    postalCode: '83455',
  },
  'victor-mt': {
    name: 'Victor',
    postalCode: '59875',
  },
  'victor-ny': {
    name: 'Victor',
    postalCode: '14564',
  },
  'victor-wv': {
    name: 'Victor',
    postalCode: '25938',
  },
  'victoria-il': {
    name: 'Victoria',
    postalCode: '61485',
  },
  'victoria-ks': {
    name: 'Victoria',
    postalCode: '67671',
  },
  'victoria-mn': {
    name: 'Victoria',
    postalCode: '55386',
  },
  'victoria-tx': {
    name: 'Victoria',
    postalCode: '77901',
  },
  'victoria-va': {
    name: 'Victoria',
    postalCode: '23974',
  },
  'victorville-ca': {
    name: 'Victorville',
    postalCode: '92392',
  },
  'vida-mt': {
    name: 'Vida',
    postalCode: '59274',
  },
  'vida-or': {
    name: 'Vida',
    postalCode: '97488',
  },
  'vidal-ca': {
    name: 'Vidal',
    postalCode: '92280',
  },
  'vidalia-ga': {
    name: 'Vidalia',
    postalCode: '30474',
  },
  'vidalia-la': {
    name: 'Vidalia',
    postalCode: '71373',
  },
  'vidor-tx': {
    name: 'Vidor',
    postalCode: '77662',
  },
  'vienna-ga': {
    name: 'Vienna',
    postalCode: '31092',
  },
  'vienna-il': {
    name: 'Vienna',
    postalCode: '62995',
  },
  'vienna-md': {
    name: 'Vienna',
    postalCode: '21869',
  },
  'vienna-me': {
    name: 'Vienna',
    postalCode: '04360',
  },
  'vienna-mo': {
    name: 'Vienna',
    postalCode: '65582',
  },
  'vienna-oh': {
    name: 'Vienna',
    postalCode: '44473',
  },
  'vienna-sd': {
    name: 'Vienna',
    postalCode: '57271',
  },
  'vienna-va': {
    name: 'Vienna',
    postalCode: '22180',
  },
  'vienna-wv': {
    name: 'Vienna',
    postalCode: '26105',
  },
  'vieques-pr': {
    name: 'Vieques',
    postalCode: '00765',
  },
  'viewtown-va': {
    name: 'Viewtown',
    postalCode: '22746',
  },
  'viking-mn': {
    name: 'Viking',
    postalCode: '56760',
  },
  'vilas-nc': {
    name: 'Vilas',
    postalCode: '28692',
  },
  'villa-grove-co': {
    name: 'Villa Grove',
    postalCode: '81155',
  },
  'villa-grove-il': {
    name: 'Villa Grove',
    postalCode: '61956',
  },
  'villa-park-ca': {
    name: 'Villa Park',
    postalCode: '92861',
  },
  'villa-park-il': {
    name: 'Villa Park',
    postalCode: '60181',
  },
  'villa-rica-ga': {
    name: 'Villa Rica',
    postalCode: '30180',
  },
  'villa-ridge-il': {
    name: 'Villa Ridge',
    postalCode: '62996',
  },
  'villa-ridge-mo': {
    name: 'Villa Ridge',
    postalCode: '63089',
  },
  'villalba-pr': {
    name: 'Villalba',
    postalCode: '00766',
  },
  'villanova-pa': {
    name: 'Villanova',
    postalCode: '19085',
  },
  'villard-mn': {
    name: 'Villard',
    postalCode: '56385',
  },
  'villas-nj': {
    name: 'Villas',
    postalCode: '08251',
  },
  'ville-platte-la': {
    name: 'Ville Platte',
    postalCode: '70586',
  },
  'villisca-ia': {
    name: 'Villisca',
    postalCode: '50864',
  },
  'vilonia-ar': {
    name: 'Vilonia',
    postalCode: '72173',
  },
  'vina-al': {
    name: 'Vina',
    postalCode: '35593',
  },
  'vinalhaven-me': {
    name: 'Vinalhaven',
    postalCode: '04863',
  },
  'vincennes-in': {
    name: 'Vincennes',
    postalCode: '47591',
  },
  'vincent-al': {
    name: 'Vincent',
    postalCode: '35178',
  },
  'vincent-ia': {
    name: 'Vincent',
    postalCode: '50594',
  },
  'vincent-ky': {
    name: 'Vincent',
    postalCode: '41386',
  },
  'vincent-oh': {
    name: 'Vincent',
    postalCode: '45784',
  },
  'vincentown-nj': {
    name: 'Vincentown',
    postalCode: '08088',
  },
  'vine-grove-ky': {
    name: 'Vine Grove',
    postalCode: '40175',
  },
  'vinegar-bend-al': {
    name: 'Vinegar Bend',
    postalCode: '36584',
  },
  'vineland-nj': {
    name: 'Vineland',
    postalCode: '08360',
  },
  'vinemont-al': {
    name: 'Vinemont',
    postalCode: '35179',
  },
  'vineyard-ut': {
    name: 'Vineyard',
    postalCode: '84059',
  },
  'vineyard-haven-ma': {
    name: 'Vineyard Haven',
    postalCode: '02568',
  },
  'vining-mn': {
    name: 'Vining',
    postalCode: '56588',
  },
  'vinita-ok': {
    name: 'Vinita',
    postalCode: '74301',
  },
  'vinson-ok': {
    name: 'Vinson',
    postalCode: '73571',
  },
  'vinton-ia': {
    name: 'Vinton',
    postalCode: '52349',
  },
  'vinton-la': {
    name: 'Vinton',
    postalCode: '70668',
  },
  'vinton-oh': {
    name: 'Vinton',
    postalCode: '45686',
  },
  'vinton-va': {
    name: 'Vinton',
    postalCode: '24179',
  },
  'vintondale-pa': {
    name: 'Vintondale',
    postalCode: '15961',
  },
  'viola-ar': {
    name: 'Viola',
    postalCode: '72583',
  },
  'viola-de': {
    name: 'Viola',
    postalCode: '19979',
  },
  'viola-id': {
    name: 'Viola',
    postalCode: '83872',
  },
  'viola-il': {
    name: 'Viola',
    postalCode: '61486',
  },
  'viola-ks': {
    name: 'Viola',
    postalCode: '67149',
  },
  'viola-wi': {
    name: 'Viola',
    postalCode: '54664',
  },
  'violet-la': {
    name: 'Violet',
    postalCode: '70092',
  },
  'violet-hill-ar': {
    name: 'Violet Hill',
    postalCode: '72584',
  },
  'viper-ky': {
    name: 'Viper',
    postalCode: '41774',
  },
  'virden-il': {
    name: 'Virden',
    postalCode: '62690',
  },
  'virgie-ky': {
    name: 'Virgie',
    postalCode: '41572',
  },
  'virgil-ks': {
    name: 'Virgil',
    postalCode: '66870',
  },
  'virgil-sd': {
    name: 'Virgil',
    postalCode: '57379',
  },
  'virgilina-va': {
    name: 'Virgilina',
    postalCode: '24598',
  },
  'virgin-ut': {
    name: 'Virgin',
    postalCode: '84779',
  },
  'virginia-il': {
    name: 'Virginia',
    postalCode: '62691',
  },
  'virginia-mn': {
    name: 'Virginia',
    postalCode: '55792',
  },
  'virginia-ne': {
    name: 'Virginia',
    postalCode: '68458',
  },
  'virginia-beach-va': {
    name: 'Virginia Beach',
    postalCode: '23451',
  },
  'viroqua-wi': {
    name: 'Viroqua',
    postalCode: '54665',
  },
  'visalia-ca': {
    name: 'Visalia',
    postalCode: '93277',
  },
  'vista-ca': {
    name: 'Vista',
    postalCode: '92081',
  },
  'vivian-la': {
    name: 'Vivian',
    postalCode: '71082',
  },
  'vivian-sd': {
    name: 'Vivian',
    postalCode: '57576',
  },
  'voca-tx': {
    name: 'Voca',
    postalCode: '76887',
  },
  'volant-pa': {
    name: 'Volant',
    postalCode: '16156',
  },
  'volborg-mt': {
    name: 'Volborg',
    postalCode: '59351',
  },
  'volcano-ca': {
    name: 'Volcano',
    postalCode: '95689',
  },
  'volga-ia': {
    name: 'Volga',
    postalCode: '52077',
  },
  'volga-sd': {
    name: 'Volga',
    postalCode: '57071',
  },
  'volga-wv': {
    name: 'Volga',
    postalCode: '26238',
  },
  'volin-sd': {
    name: 'Volin',
    postalCode: '57072',
  },
  'voltaire-nd': {
    name: 'Voltaire',
    postalCode: '58792',
  },
  'voluntown-ct': {
    name: 'Voluntown',
    postalCode: '06384',
  },
  'von-ormy-tx': {
    name: 'Von Ormy',
    postalCode: '78073',
  },
  'vona-co': {
    name: 'Vona',
    postalCode: '80861',
  },
  'vonore-tn': {
    name: 'Vonore',
    postalCode: '37885',
  },
  'voorhees-nj': {
    name: 'Voorhees',
    postalCode: '08043',
  },
  'voorheesville-ny': {
    name: 'Voorheesville',
    postalCode: '12186',
  },
  'voss-tx': {
    name: 'Voss',
    postalCode: '76888',
  },
  'vossburg-ms': {
    name: 'Vossburg',
    postalCode: '39366',
  },
  'vowinckel-pa': {
    name: 'Vowinckel',
    postalCode: '16260',
  },
  'vredenburgh-al': {
    name: 'Vredenburgh',
    postalCode: '36481',
  },
  'vulcan-mi': {
    name: 'Vulcan',
    postalCode: '49892',
  },
  'vulcan-mo': {
    name: 'Vulcan',
    postalCode: '63675',
  },
  'waban-ma': {
    name: 'Waban',
    postalCode: '02468',
  },
  'wabash-in': {
    name: 'Wabash',
    postalCode: '46992',
  },
  'wabasha-mn': {
    name: 'Wabasha',
    postalCode: '55981',
  },
  'wabasso-mn': {
    name: 'Wabasso',
    postalCode: '56293',
  },
  'wabbaseka-ar': {
    name: 'Wabbaseka',
    postalCode: '72175',
  },
  'wabeno-wi': {
    name: 'Wabeno',
    postalCode: '54566',
  },
  'waccabuc-ny': {
    name: 'Waccabuc',
    postalCode: '10597',
  },
  'waco-ga': {
    name: 'Waco',
    postalCode: '30182',
  },
  'waco-ky': {
    name: 'Waco',
    postalCode: '40385',
  },
  'waco-ne': {
    name: 'Waco',
    postalCode: '68460',
  },
  'waco-tx': {
    name: 'Waco',
    postalCode: '76701',
  },
  'waconia-mn': {
    name: 'Waconia',
    postalCode: '55387',
  },
  'waddell-az': {
    name: 'Waddell',
    postalCode: '85355',
  },
  'waddington-ny': {
    name: 'Waddington',
    postalCode: '13694',
  },
  'waddy-ky': {
    name: 'Waddy',
    postalCode: '40076',
  },
  'wade-nc': {
    name: 'Wade',
    postalCode: '28395',
  },
  'wadena-ia': {
    name: 'Wadena',
    postalCode: '52169',
  },
  'wadena-mn': {
    name: 'Wadena',
    postalCode: '56482',
  },
  'wadesboro-nc': {
    name: 'Wadesboro',
    postalCode: '28170',
  },
  'wadesville-in': {
    name: 'Wadesville',
    postalCode: '47638',
  },
  'wading-river-ny': {
    name: 'Wading River',
    postalCode: '11792',
  },
  'wadley-al': {
    name: 'Wadley',
    postalCode: '36276',
  },
  'wadley-ga': {
    name: 'Wadley',
    postalCode: '30477',
  },
  'wadmalaw-island-sc': {
    name: 'Wadmalaw Island',
    postalCode: '29487',
  },
  'wadsworth-il': {
    name: 'Wadsworth',
    postalCode: '60083',
  },
  'wadsworth-oh': {
    name: 'Wadsworth',
    postalCode: '44281',
  },
  'waelder-tx': {
    name: 'Waelder',
    postalCode: '78959',
  },
  'wagarville-al': {
    name: 'Wagarville',
    postalCode: '36585',
  },
  'wagener-sc': {
    name: 'Wagener',
    postalCode: '29164',
  },
  'waggoner-il': {
    name: 'Waggoner',
    postalCode: '62572',
  },
  'wagner-sd': {
    name: 'Wagner',
    postalCode: '57380',
  },
  'wagon-mound-nm': {
    name: 'Wagon Mound',
    postalCode: '87752',
  },
  'wagoner-ok': {
    name: 'Wagoner',
    postalCode: '74467',
  },
  'wagram-nc': {
    name: 'Wagram',
    postalCode: '28396',
  },
  'wahiawa-hi': {
    name: 'Wahiawa',
    postalCode: '96786',
  },
  'wahkiacus-wa': {
    name: 'Wahkiacus',
    postalCode: '98670',
  },
  'wahkon-mn': {
    name: 'Wahkon',
    postalCode: '56386',
  },
  'wahoo-ne': {
    name: 'Wahoo',
    postalCode: '68066',
  },
  'wahpeton-nd': {
    name: 'Wahpeton',
    postalCode: '58075',
  },
  'waialua-hi': {
    name: 'Waialua',
    postalCode: '96791',
  },
  'waianae-hi': {
    name: 'Waianae',
    postalCode: '96792',
  },
  'waikoloa-hi': {
    name: 'Waikoloa',
    postalCode: '96738',
  },
  'wailuku-hi': {
    name: 'Wailuku',
    postalCode: '96793',
  },
  'waimanalo-hi': {
    name: 'Waimanalo',
    postalCode: '96795',
  },
  'waipahu-hi': {
    name: 'Waipahu',
    postalCode: '96797',
  },
  'waite-me': {
    name: 'Waite',
    postalCode: '04492',
  },
  'waite-park-mn': {
    name: 'Waite Park',
    postalCode: '56387',
  },
  'waiteville-wv': {
    name: 'Waiteville',
    postalCode: '24984',
  },
  'waitsburg-wa': {
    name: 'Waitsburg',
    postalCode: '99361',
  },
  'waitsfield-vt': {
    name: 'Waitsfield',
    postalCode: '05673',
  },
  'wakarusa-in': {
    name: 'Wakarusa',
    postalCode: '46573',
  },
  'wakarusa-ks': {
    name: 'Wakarusa',
    postalCode: '66546',
  },
  'wake-va': {
    name: 'Wake',
    postalCode: '23176',
  },
  'wake-forest-nc': {
    name: 'Wake Forest',
    postalCode: '27587',
  },
  'wakeeney-ks': {
    name: 'Wakeeney',
    postalCode: '67672',
  },
  'wakefield-ks': {
    name: 'Wakefield',
    postalCode: '67487',
  },
  'wakefield-ma': {
    name: 'Wakefield',
    postalCode: '01880',
  },
  'wakefield-mi': {
    name: 'Wakefield',
    postalCode: '49968',
  },
  'wakefield-ne': {
    name: 'Wakefield',
    postalCode: '68784',
  },
  'wakefield-ri': {
    name: 'Wakefield',
    postalCode: '02879',
  },
  'wakefield-va': {
    name: 'Wakefield',
    postalCode: '23888',
  },
  'wakeman-oh': {
    name: 'Wakeman',
    postalCode: '44889',
  },
  'wakita-ok': {
    name: 'Wakita',
    postalCode: '73771',
  },
  'wakonda-sd': {
    name: 'Wakonda',
    postalCode: '57073',
  },
  'wakpala-sd': {
    name: 'Wakpala',
    postalCode: '57658',
  },
  'walbridge-oh': {
    name: 'Walbridge',
    postalCode: '43465',
  },
  'walcott-ia': {
    name: 'Walcott',
    postalCode: '52773',
  },
  'walcott-nd': {
    name: 'Walcott',
    postalCode: '58077',
  },
  'walden-co': {
    name: 'Walden',
    postalCode: '80480',
  },
  'walden-ny': {
    name: 'Walden',
    postalCode: '12586',
  },
  'waldo-ar': {
    name: 'Waldo',
    postalCode: '71770',
  },
  'waldo-fl': {
    name: 'Waldo',
    postalCode: '32694',
  },
  'waldo-ks': {
    name: 'Waldo',
    postalCode: '67673',
  },
  'waldo-oh': {
    name: 'Waldo',
    postalCode: '43356',
  },
  'waldo-wi': {
    name: 'Waldo',
    postalCode: '53093',
  },
  'waldoboro-me': {
    name: 'Waldoboro',
    postalCode: '04572',
  },
  'waldorf-md': {
    name: 'Waldorf',
    postalCode: '20601',
  },
  'waldorf-mn': {
    name: 'Waldorf',
    postalCode: '56091',
  },
  'waldport-or': {
    name: 'Waldport',
    postalCode: '97394',
  },
  'waldron-ar': {
    name: 'Waldron',
    postalCode: '72958',
  },
  'waldron-in': {
    name: 'Waldron',
    postalCode: '46182',
  },
  'waldron-ks': {
    name: 'Waldron',
    postalCode: '67150',
  },
  'waldron-mi': {
    name: 'Waldron',
    postalCode: '49288',
  },
  'waldwick-nj': {
    name: 'Waldwick',
    postalCode: '07463',
  },
  'wales-ma': {
    name: 'Wales',
    postalCode: '01081',
  },
  'wales-nd': {
    name: 'Wales',
    postalCode: '58281',
  },
  'wales-ut': {
    name: 'Wales',
    postalCode: '84667',
  },
  'wales-wi': {
    name: 'Wales',
    postalCode: '53183',
  },
  'waleska-ga': {
    name: 'Waleska',
    postalCode: '30183',
  },
  'walford-ia': {
    name: 'Walford',
    postalCode: '52351',
  },
  'walhalla-nd': {
    name: 'Walhalla',
    postalCode: '58282',
  },
  'walhalla-sc': {
    name: 'Walhalla',
    postalCode: '29691',
  },
  'walhonding-oh': {
    name: 'Walhonding',
    postalCode: '43843',
  },
  'walker-ia': {
    name: 'Walker',
    postalCode: '52352',
  },
  'walker-ky': {
    name: 'Walker',
    postalCode: '40997',
  },
  'walker-la': {
    name: 'Walker',
    postalCode: '70785',
  },
  'walker-mn': {
    name: 'Walker',
    postalCode: '56484',
  },
  'walker-mo': {
    name: 'Walker',
    postalCode: '64790',
  },
  'walker-sd': {
    name: 'Walker',
    postalCode: '57659',
  },
  'walker-wv': {
    name: 'Walker',
    postalCode: '26180',
  },
  'walkersville-md': {
    name: 'Walkersville',
    postalCode: '21793',
  },
  'walkersville-wv': {
    name: 'Walkersville',
    postalCode: '26447',
  },
  'walkerton-in': {
    name: 'Walkerton',
    postalCode: '46574',
  },
  'walkerton-va': {
    name: 'Walkerton',
    postalCode: '23177',
  },
  'walkertown-nc': {
    name: 'Walkertown',
    postalCode: '27051',
  },
  'walkerville-mi': {
    name: 'Walkerville',
    postalCode: '49459',
  },
  'wall-sd': {
    name: 'Wall',
    postalCode: '57790',
  },
  'wall-tx': {
    name: 'Wall',
    postalCode: '76957',
  },
  'wall-lake-ia': {
    name: 'Wall Lake',
    postalCode: '51466',
  },
  'walla-walla-wa': {
    name: 'Walla Walla',
    postalCode: '99362',
  },
  'wallace-id': {
    name: 'Wallace',
    postalCode: '83873',
  },
  'wallace-ks': {
    name: 'Wallace',
    postalCode: '67761',
  },
  'wallace-mi': {
    name: 'Wallace',
    postalCode: '49893',
  },
  'wallace-nc': {
    name: 'Wallace',
    postalCode: '28466',
  },
  'wallace-ne': {
    name: 'Wallace',
    postalCode: '69169',
  },
  'wallace-sc': {
    name: 'Wallace',
    postalCode: '29596',
  },
  'wallace-sd': {
    name: 'Wallace',
    postalCode: '57272',
  },
  'wallace-wv': {
    name: 'Wallace',
    postalCode: '26448',
  },
  'wallagrass-me': {
    name: 'Wallagrass',
    postalCode: '04781',
  },
  'walland-tn': {
    name: 'Walland',
    postalCode: '37886',
  },
  'wallback-wv': {
    name: 'Wallback',
    postalCode: '25285',
  },
  'walled-lake-mi': {
    name: 'Walled Lake',
    postalCode: '48390',
  },
  'waller-tx': {
    name: 'Waller',
    postalCode: '77484',
  },
  'walling-tn': {
    name: 'Walling',
    postalCode: '38587',
  },
  'wallingford-ct': {
    name: 'Wallingford',
    postalCode: '06492',
  },
  'wallingford-ia': {
    name: 'Wallingford',
    postalCode: '51365',
  },
  'wallingford-ky': {
    name: 'Wallingford',
    postalCode: '41093',
  },
  'wallingford-pa': {
    name: 'Wallingford',
    postalCode: '19086',
  },
  'wallingford-vt': {
    name: 'Wallingford',
    postalCode: '05773',
  },
  'wallington-nj': {
    name: 'Wallington',
    postalCode: '07057',
  },
  'wallins-creek-ky': {
    name: 'Wallins Creek',
    postalCode: '40873',
  },
  'wallis-tx': {
    name: 'Wallis',
    postalCode: '77485',
  },
  'wallisville-tx': {
    name: 'Wallisville',
    postalCode: '77597',
  },
  'wallkill-ny': {
    name: 'Wallkill',
    postalCode: '12589',
  },
  'wallops-island-va': {
    name: 'Wallops Island',
    postalCode: '23337',
  },
  'wallowa-or': {
    name: 'Wallowa',
    postalCode: '97885',
  },
  'wallpack-center-nj': {
    name: 'Wallpack Center',
    postalCode: '07881',
  },
  'walls-ms': {
    name: 'Walls',
    postalCode: '38680',
  },
  'wallsburg-ut': {
    name: 'Wallsburg',
    postalCode: '84082',
  },
  'walnut-ca': {
    name: 'Walnut',
    postalCode: '91789',
  },
  'walnut-ia': {
    name: 'Walnut',
    postalCode: '51577',
  },
  'walnut-il': {
    name: 'Walnut',
    postalCode: '61376',
  },
  'walnut-ks': {
    name: 'Walnut',
    postalCode: '66780',
  },
  'walnut-ms': {
    name: 'Walnut',
    postalCode: '38683',
  },
  'walnut-bottom-pa': {
    name: 'Walnut Bottom',
    postalCode: '17266',
  },
  'walnut-cove-nc': {
    name: 'Walnut Cove',
    postalCode: '27052',
  },
  'walnut-creek-ca': {
    name: 'Walnut Creek',
    postalCode: '94595',
  },
  'walnut-grove-ca': {
    name: 'Walnut Grove',
    postalCode: '95690',
  },
  'walnut-grove-mn': {
    name: 'Walnut Grove',
    postalCode: '56180',
  },
  'walnut-grove-mo': {
    name: 'Walnut Grove',
    postalCode: '65770',
  },
  'walnut-grove-ms': {
    name: 'Walnut Grove',
    postalCode: '39189',
  },
  'walnut-hill-il': {
    name: 'Walnut Hill',
    postalCode: '62893',
  },
  'walnut-ridge-ar': {
    name: 'Walnut Ridge',
    postalCode: '72476',
  },
  'walnut-shade-mo': {
    name: 'Walnut Shade',
    postalCode: '65771',
  },
  'walnut-springs-tx': {
    name: 'Walnut Springs',
    postalCode: '76690',
  },
  'walnutport-pa': {
    name: 'Walnutport',
    postalCode: '18088',
  },
  'walpole-ma': {
    name: 'Walpole',
    postalCode: '02081',
  },
  'walpole-me': {
    name: 'Walpole',
    postalCode: '04573',
  },
  'walpole-nh': {
    name: 'Walpole',
    postalCode: '03608',
  },
  'walsenburg-co': {
    name: 'Walsenburg',
    postalCode: '81089',
  },
  'walsh-co': {
    name: 'Walsh',
    postalCode: '81090',
  },
  'walsh-il': {
    name: 'Walsh',
    postalCode: '62297',
  },
  'walshville-il': {
    name: 'Walshville',
    postalCode: '62091',
  },
  'walstonburg-nc': {
    name: 'Walstonburg',
    postalCode: '27888',
  },
  'walterboro-sc': {
    name: 'Walterboro',
    postalCode: '29488',
  },
  'walters-ok': {
    name: 'Walters',
    postalCode: '73572',
  },
  'waltersburg-pa': {
    name: 'Waltersburg',
    postalCode: '15488',
  },
  'walterville-or': {
    name: 'Walterville',
    postalCode: '97489',
  },
  'walthall-ms': {
    name: 'Walthall',
    postalCode: '39771',
  },
  'waltham-ma': {
    name: 'Waltham',
    postalCode: '02451',
  },
  'waltham-mn': {
    name: 'Waltham',
    postalCode: '55982',
  },
  'walthill-ne': {
    name: 'Walthill',
    postalCode: '68067',
  },
  'walton-in': {
    name: 'Walton',
    postalCode: '46994',
  },
  'walton-ks': {
    name: 'Walton',
    postalCode: '67151',
  },
  'walton-ky': {
    name: 'Walton',
    postalCode: '41094',
  },
  'walton-ne': {
    name: 'Walton',
    postalCode: '68461',
  },
  'walton-ny': {
    name: 'Walton',
    postalCode: '13856',
  },
  'walton-or': {
    name: 'Walton',
    postalCode: '97490',
  },
  'walton-wv': {
    name: 'Walton',
    postalCode: '25286',
  },
  'waltonville-il': {
    name: 'Waltonville',
    postalCode: '62894',
  },
  'walworth-ny': {
    name: 'Walworth',
    postalCode: '14568',
  },
  'walworth-wi': {
    name: 'Walworth',
    postalCode: '53184',
  },
  'wamego-ks': {
    name: 'Wamego',
    postalCode: '66547',
  },
  'wampum-pa': {
    name: 'Wampum',
    postalCode: '16157',
  },
  'wana-wv': {
    name: 'Wana',
    postalCode: '26590',
  },
  'wanamingo-mn': {
    name: 'Wanamingo',
    postalCode: '55983',
  },
  'wanaque-nj': {
    name: 'Wanaque',
    postalCode: '07465',
  },
  'wanatah-in': {
    name: 'Wanatah',
    postalCode: '46390',
  },
  'wanblee-sd': {
    name: 'Wanblee',
    postalCode: '57577',
  },
  'wanchese-nc': {
    name: 'Wanchese',
    postalCode: '27981',
  },
  'wanette-ok': {
    name: 'Wanette',
    postalCode: '74878',
  },
  'wann-ok': {
    name: 'Wann',
    postalCode: '74083',
  },
  'wannaska-mn': {
    name: 'Wannaska',
    postalCode: '56761',
  },
  'wantagh-ny': {
    name: 'Wantagh',
    postalCode: '11793',
  },
  'wapakoneta-oh': {
    name: 'Wapakoneta',
    postalCode: '45895',
  },
  'wapanucka-ok': {
    name: 'Wapanucka',
    postalCode: '73461',
  },
  'wapato-wa': {
    name: 'Wapato',
    postalCode: '98951',
  },
  'wapella-il': {
    name: 'Wapella',
    postalCode: '61777',
  },
  'wapello-ia': {
    name: 'Wapello',
    postalCode: '52653',
  },
  'wappapello-mo': {
    name: 'Wappapello',
    postalCode: '63966',
  },
  'wappingers-falls-ny': {
    name: 'Wappingers Falls',
    postalCode: '12590',
  },
  'wapwallopen-pa': {
    name: 'Wapwallopen',
    postalCode: '18660',
  },
  'war-wv': {
    name: 'War',
    postalCode: '24892',
  },
  'warba-mn': {
    name: 'Warba',
    postalCode: '55793',
  },
  'warbranch-ky': {
    name: 'Warbranch',
    postalCode: '40874',
  },
  'ward-al': {
    name: 'Ward',
    postalCode: '36922',
  },
  'ward-ar': {
    name: 'Ward',
    postalCode: '72176',
  },
  'ward-co': {
    name: 'Ward',
    postalCode: '80481',
  },
  'ward-sc': {
    name: 'Ward',
    postalCode: '29166',
  },
  'wardell-mo': {
    name: 'Wardell',
    postalCode: '63879',
  },
  'warden-wa': {
    name: 'Warden',
    postalCode: '98857',
  },
  'wardensville-wv': {
    name: 'Wardensville',
    postalCode: '26851',
  },
  'wardsboro-vt': {
    name: 'Wardsboro',
    postalCode: '05355',
  },
  'wardville-ok': {
    name: 'Wardville',
    postalCode: '74576',
  },
  'ware-ma': {
    name: 'Ware',
    postalCode: '01082',
  },
  'ware-shoals-sc': {
    name: 'Ware Shoals',
    postalCode: '29692',
  },
  'wareham-ma': {
    name: 'Wareham',
    postalCode: '02571',
  },
  'waretown-nj': {
    name: 'Waretown',
    postalCode: '08758',
  },
  'warfield-ky': {
    name: 'Warfield',
    postalCode: '41267',
  },
  'warfield-va': {
    name: 'Warfield',
    postalCode: '23889',
  },
  'warfordsburg-pa': {
    name: 'Warfordsburg',
    postalCode: '17267',
  },
  'warm-springs-ar': {
    name: 'Warm Springs',
    postalCode: '72478',
  },
  'warm-springs-ga': {
    name: 'Warm Springs',
    postalCode: '31830',
  },
  'warm-springs-va': {
    name: 'Warm Springs',
    postalCode: '24484',
  },
  'warminster-pa': {
    name: 'Warminster',
    postalCode: '18974',
  },
  'warne-nc': {
    name: 'Warne',
    postalCode: '28909',
  },
  'warner-nh': {
    name: 'Warner',
    postalCode: '03278',
  },
  'warner-ok': {
    name: 'Warner',
    postalCode: '74469',
  },
  'warner-sd': {
    name: 'Warner',
    postalCode: '57479',
  },
  'warner-robins-ga': {
    name: 'Warner Robins',
    postalCode: '31088',
  },
  'warner-springs-ca': {
    name: 'Warner Springs',
    postalCode: '92086',
  },
  'warners-ny': {
    name: 'Warners',
    postalCode: '13164',
  },
  'warnerville-ny': {
    name: 'Warnerville',
    postalCode: '12187',
  },
  'warren-ar': {
    name: 'Warren',
    postalCode: '71671',
  },
  'warren-il': {
    name: 'Warren',
    postalCode: '61087',
  },
  'warren-in': {
    name: 'Warren',
    postalCode: '46792',
  },
  'warren-me': {
    name: 'Warren',
    postalCode: '04864',
  },
  'warren-mi': {
    name: 'Warren',
    postalCode: '48088',
  },
  'warren-mn': {
    name: 'Warren',
    postalCode: '56762',
  },
  'warren-nh': {
    name: 'Warren',
    postalCode: '03279',
  },
  'warren-nj': {
    name: 'Warren',
    postalCode: '07059',
  },
  'warren-oh': {
    name: 'Warren',
    postalCode: '44481',
  },
  'warren-or': {
    name: 'Warren',
    postalCode: '97053',
  },
  'warren-pa': {
    name: 'Warren',
    postalCode: '16365',
  },
  'warren-ri': {
    name: 'Warren',
    postalCode: '02885',
  },
  'warren-tx': {
    name: 'Warren',
    postalCode: '77664',
  },
  'warren-vt': {
    name: 'Warren',
    postalCode: '05674',
  },
  'warren-center-pa': {
    name: 'Warren Center',
    postalCode: '18851',
  },
  'warrendale-pa': {
    name: 'Warrendale',
    postalCode: '15086',
  },
  'warrens-wi': {
    name: 'Warrens',
    postalCode: '54666',
  },
  'warrensburg-il': {
    name: 'Warrensburg',
    postalCode: '62573',
  },
  'warrensburg-mo': {
    name: 'Warrensburg',
    postalCode: '64093',
  },
  'warrensburg-ny': {
    name: 'Warrensburg',
    postalCode: '12885',
  },
  'warrensville-nc': {
    name: 'Warrensville',
    postalCode: '28693',
  },
  'warrenton-ga': {
    name: 'Warrenton',
    postalCode: '30828',
  },
  'warrenton-mo': {
    name: 'Warrenton',
    postalCode: '63383',
  },
  'warrenton-nc': {
    name: 'Warrenton',
    postalCode: '27589',
  },
  'warrenton-or': {
    name: 'Warrenton',
    postalCode: '97146',
  },
  'warrenton-va': {
    name: 'Warrenton',
    postalCode: '20186',
  },
  'warrenville-il': {
    name: 'Warrenville',
    postalCode: '60555',
  },
  'warrenville-sc': {
    name: 'Warrenville',
    postalCode: '29851',
  },
  'warrington-pa': {
    name: 'Warrington',
    postalCode: '18976',
  },
  'warrior-al': {
    name: 'Warrior',
    postalCode: '35180',
  },
  'warriors-mark-pa': {
    name: 'Warriors Mark',
    postalCode: '16877',
  },
  'warroad-mn': {
    name: 'Warroad',
    postalCode: '56763',
  },
  'warsaw-il': {
    name: 'Warsaw',
    postalCode: '62379',
  },
  'warsaw-in': {
    name: 'Warsaw',
    postalCode: '46580',
  },
  'warsaw-ky': {
    name: 'Warsaw',
    postalCode: '41095',
  },
  'warsaw-mn': {
    name: 'Warsaw',
    postalCode: '55087',
  },
  'warsaw-mo': {
    name: 'Warsaw',
    postalCode: '65355',
  },
  'warsaw-nc': {
    name: 'Warsaw',
    postalCode: '28398',
  },
  'warsaw-ny': {
    name: 'Warsaw',
    postalCode: '14569',
  },
  'warsaw-oh': {
    name: 'Warsaw',
    postalCode: '43844',
  },
  'warsaw-va': {
    name: 'Warsaw',
    postalCode: '22572',
  },
  'wartburg-tn': {
    name: 'Wartburg',
    postalCode: '37887',
  },
  'warthen-ga': {
    name: 'Warthen',
    postalCode: '31094',
  },
  'wartrace-tn': {
    name: 'Wartrace',
    postalCode: '37183',
  },
  'warwick-ga': {
    name: 'Warwick',
    postalCode: '31796',
  },
  'warwick-ma': {
    name: 'Warwick',
    postalCode: '01378',
  },
  'warwick-md': {
    name: 'Warwick',
    postalCode: '21912',
  },
  'warwick-nd': {
    name: 'Warwick',
    postalCode: '58381',
  },
  'warwick-ny': {
    name: 'Warwick',
    postalCode: '10990',
  },
  'warwick-ri': {
    name: 'Warwick',
    postalCode: '02886',
  },
  'wasco-ca': {
    name: 'Wasco',
    postalCode: '93280',
  },
  'wasco-or': {
    name: 'Wasco',
    postalCode: '97065',
  },
  'waseca-mn': {
    name: 'Waseca',
    postalCode: '56093',
  },
  'washburn-il': {
    name: 'Washburn',
    postalCode: '61570',
  },
  'washburn-me': {
    name: 'Washburn',
    postalCode: '04786',
  },
  'washburn-mo': {
    name: 'Washburn',
    postalCode: '65772',
  },
  'washburn-nd': {
    name: 'Washburn',
    postalCode: '58577',
  },
  'washburn-tn': {
    name: 'Washburn',
    postalCode: '37888',
  },
  'washburn-wi': {
    name: 'Washburn',
    postalCode: '54891',
  },
  'washington-ar': {
    name: 'Washington',
    postalCode: '71862',
  },
  'washington-ct': {
    name: 'Washington',
    postalCode: '06793',
  },
  'washington-dc': {
    name: 'Washington',
    postalCode: '20001',
  },
  'washington-ga': {
    name: 'Washington',
    postalCode: '30673',
  },
  'washington-ia': {
    name: 'Washington',
    postalCode: '52353',
  },
  'washington-il': {
    name: 'Washington',
    postalCode: '61571',
  },
  'washington-in': {
    name: 'Washington',
    postalCode: '47501',
  },
  'washington-ks': {
    name: 'Washington',
    postalCode: '66968',
  },
  'washington-la': {
    name: 'Washington',
    postalCode: '70589',
  },
  'washington-me': {
    name: 'Washington',
    postalCode: '04574',
  },
  'washington-mi': {
    name: 'Washington',
    postalCode: '48094',
  },
  'washington-mo': {
    name: 'Washington',
    postalCode: '63090',
  },
  'washington-nc': {
    name: 'Washington',
    postalCode: '27889',
  },
  'washington-ne': {
    name: 'Washington',
    postalCode: '68068',
  },
  'washington-nh': {
    name: 'Washington',
    postalCode: '03280',
  },
  'washington-nj': {
    name: 'Washington',
    postalCode: '07882',
  },
  'washington-ok': {
    name: 'Washington',
    postalCode: '73093',
  },
  'washington-pa': {
    name: 'Washington',
    postalCode: '15301',
  },
  'washington-tx': {
    name: 'Washington',
    postalCode: '77880',
  },
  'washington-ut': {
    name: 'Washington',
    postalCode: '84780',
  },
  'washington-va': {
    name: 'Washington',
    postalCode: '22747',
  },
  'washington-vt': {
    name: 'Washington',
    postalCode: '05675',
  },
  'washington-wv': {
    name: 'Washington',
    postalCode: '26181',
  },
  'washington-boro-pa': {
    name: 'Washington Boro',
    postalCode: '17582',
  },
  'washington-court-house-oh': {
    name: 'Washington Court House',
    postalCode: '43160',
  },
  'washington-crossing-pa': {
    name: 'Washington Crossing',
    postalCode: '18977',
  },
  'washington-depot-ct': {
    name: 'Washington Depot',
    postalCode: '06794',
  },
  'washington-island-wi': {
    name: 'Washington Island',
    postalCode: '54246',
  },
  'washington-navy-yard-dc': {
    name: 'Washington Navy Yard',
    postalCode: '20374',
  },
  'washingtonville-ny': {
    name: 'Washingtonville',
    postalCode: '10992',
  },
  'washingtonville-oh': {
    name: 'Washingtonville',
    postalCode: '44490',
  },
  'washita-ok': {
    name: 'Washita',
    postalCode: '73094',
  },
  'washoe-valley-nv': {
    name: 'Washoe Valley',
    postalCode: '89704',
  },
  'washougal-wa': {
    name: 'Washougal',
    postalCode: '98671',
  },
  'washta-ia': {
    name: 'Washta',
    postalCode: '51061',
  },
  'washtucna-wa': {
    name: 'Washtucna',
    postalCode: '99371',
  },
  'wasilla-ak': {
    name: 'Wasilla',
    postalCode: '99623',
  },
  'waskish-mn': {
    name: 'Waskish',
    postalCode: '56685',
  },
  'waskom-tx': {
    name: 'Waskom',
    postalCode: '75692',
  },
  'wasola-mo': {
    name: 'Wasola',
    postalCode: '65773',
  },
  'wassaic-ny': {
    name: 'Wassaic',
    postalCode: '12592',
  },
  'wasta-sd': {
    name: 'Wasta',
    postalCode: '57791',
  },
  'wataga-il': {
    name: 'Wataga',
    postalCode: '61488',
  },
  'watauga-sd': {
    name: 'Watauga',
    postalCode: '57660',
  },
  'watauga-tn': {
    name: 'Watauga',
    postalCode: '37694',
  },
  'watchung-nj': {
    name: 'Watchung',
    postalCode: '07069',
  },
  'water-mill-ny': {
    name: 'Water Mill',
    postalCode: '11976',
  },
  'water-valley-ky': {
    name: 'Water Valley',
    postalCode: '42085',
  },
  'water-valley-ms': {
    name: 'Water Valley',
    postalCode: '38965',
  },
  'water-view-va': {
    name: 'Water View',
    postalCode: '23180',
  },
  'waterboro-me': {
    name: 'Waterboro',
    postalCode: '04087',
  },
  'waterbury-ct': {
    name: 'Waterbury',
    postalCode: '06701',
  },
  'waterbury-ne': {
    name: 'Waterbury',
    postalCode: '68785',
  },
  'waterbury-vt': {
    name: 'Waterbury',
    postalCode: '05676',
  },
  'waterbury-center-vt': {
    name: 'Waterbury Center',
    postalCode: '05677',
  },
  'waterfall-pa': {
    name: 'Waterfall',
    postalCode: '16689',
  },
  'waterflow-nm': {
    name: 'Waterflow',
    postalCode: '87421',
  },
  'waterford-ca': {
    name: 'Waterford',
    postalCode: '95386',
  },
  'waterford-ct': {
    name: 'Waterford',
    postalCode: '06385',
  },
  'waterford-me': {
    name: 'Waterford',
    postalCode: '04088',
  },
  'waterford-mi': {
    name: 'Waterford',
    postalCode: '48327',
  },
  'waterford-ms': {
    name: 'Waterford',
    postalCode: '38685',
  },
  'waterford-ny': {
    name: 'Waterford',
    postalCode: '12188',
  },
  'waterford-oh': {
    name: 'Waterford',
    postalCode: '45786',
  },
  'waterford-pa': {
    name: 'Waterford',
    postalCode: '16441',
  },
  'waterford-va': {
    name: 'Waterford',
    postalCode: '20197',
  },
  'waterford-wi': {
    name: 'Waterford',
    postalCode: '53185',
  },
  'waterford-works-nj': {
    name: 'Waterford Works',
    postalCode: '08089',
  },
  'waterloo-al': {
    name: 'Waterloo',
    postalCode: '35677',
  },
  'waterloo-ia': {
    name: 'Waterloo',
    postalCode: '50701',
  },
  'waterloo-il': {
    name: 'Waterloo',
    postalCode: '62298',
  },
  'waterloo-in': {
    name: 'Waterloo',
    postalCode: '46793',
  },
  'waterloo-ne': {
    name: 'Waterloo',
    postalCode: '68069',
  },
  'waterloo-ny': {
    name: 'Waterloo',
    postalCode: '13165',
  },
  'waterloo-oh': {
    name: 'Waterloo',
    postalCode: '45688',
  },
  'waterloo-sc': {
    name: 'Waterloo',
    postalCode: '29384',
  },
  'waterloo-wi': {
    name: 'Waterloo',
    postalCode: '53594',
  },
  'waterman-il': {
    name: 'Waterman',
    postalCode: '60556',
  },
  'waterport-ny': {
    name: 'Waterport',
    postalCode: '14571',
  },
  'waterproof-la': {
    name: 'Waterproof',
    postalCode: '71375',
  },
  'watersmeet-mi': {
    name: 'Watersmeet',
    postalCode: '49969',
  },
  'watertown-ct': {
    name: 'Watertown',
    postalCode: '06795',
  },
  'watertown-ma': {
    name: 'Watertown',
    postalCode: '02472',
  },
  'watertown-mn': {
    name: 'Watertown',
    postalCode: '55388',
  },
  'watertown-ny': {
    name: 'Watertown',
    postalCode: '13601',
  },
  'watertown-sd': {
    name: 'Watertown',
    postalCode: '57201',
  },
  'watertown-tn': {
    name: 'Watertown',
    postalCode: '37184',
  },
  'watertown-wi': {
    name: 'Watertown',
    postalCode: '53094',
  },
  'waterville-ia': {
    name: 'Waterville',
    postalCode: '52170',
  },
  'waterville-ks': {
    name: 'Waterville',
    postalCode: '66548',
  },
  'waterville-me': {
    name: 'Waterville',
    postalCode: '04901',
  },
  'waterville-mn': {
    name: 'Waterville',
    postalCode: '56096',
  },
  'waterville-ny': {
    name: 'Waterville',
    postalCode: '13480',
  },
  'waterville-oh': {
    name: 'Waterville',
    postalCode: '43566',
  },
  'waterville-pa': {
    name: 'Waterville',
    postalCode: '17776',
  },
  'waterville-vt': {
    name: 'Waterville',
    postalCode: '05492',
  },
  'waterville-wa': {
    name: 'Waterville',
    postalCode: '98858',
  },
  'watervliet-mi': {
    name: 'Watervliet',
    postalCode: '49098',
  },
  'watervliet-ny': {
    name: 'Watervliet',
    postalCode: '12189',
  },
  'watford-city-nd': {
    name: 'Watford City',
    postalCode: '58854',
  },
  'wathena-ks': {
    name: 'Wathena',
    postalCode: '66090',
  },
  'watkins-co': {
    name: 'Watkins',
    postalCode: '80137',
  },
  'watkins-ia': {
    name: 'Watkins',
    postalCode: '52354',
  },
  'watkins-mn': {
    name: 'Watkins',
    postalCode: '55389',
  },
  'watkins-glen-ny': {
    name: 'Watkins Glen',
    postalCode: '14891',
  },
  'watkinsville-ga': {
    name: 'Watkinsville',
    postalCode: '30677',
  },
  'watonga-ok': {
    name: 'Watonga',
    postalCode: '73772',
  },
  'watseka-il': {
    name: 'Watseka',
    postalCode: '60970',
  },
  'watson-ar': {
    name: 'Watson',
    postalCode: '71674',
  },
  'watson-il': {
    name: 'Watson',
    postalCode: '62473',
  },
  'watson-mn': {
    name: 'Watson',
    postalCode: '56295',
  },
  'watson-mo': {
    name: 'Watson',
    postalCode: '64496',
  },
  'watson-ok': {
    name: 'Watson',
    postalCode: '74963',
  },
  'watsontown-pa': {
    name: 'Watsontown',
    postalCode: '17777',
  },
  'watsonville-ca': {
    name: 'Watsonville',
    postalCode: '95076',
  },
  'watton-mi': {
    name: 'Watton',
    postalCode: '49970',
  },
  'watts-ok': {
    name: 'Watts',
    postalCode: '74964',
  },
  'wattsburg-pa': {
    name: 'Wattsburg',
    postalCode: '16442',
  },
  'waubay-sd': {
    name: 'Waubay',
    postalCode: '57273',
  },
  'waubun-mn': {
    name: 'Waubun',
    postalCode: '56589',
  },
  'wauchula-fl': {
    name: 'Wauchula',
    postalCode: '33873',
  },
  'waucoma-ia': {
    name: 'Waucoma',
    postalCode: '52171',
  },
  'wauconda-il': {
    name: 'Wauconda',
    postalCode: '60084',
  },
  'wauconda-wa': {
    name: 'Wauconda',
    postalCode: '98859',
  },
  'waukee-ia': {
    name: 'Waukee',
    postalCode: '50263',
  },
  'waukegan-il': {
    name: 'Waukegan',
    postalCode: '60085',
  },
  'waukesha-wi': {
    name: 'Waukesha',
    postalCode: '53186',
  },
  'waukomis-ok': {
    name: 'Waukomis',
    postalCode: '73773',
  },
  'waukon-ia': {
    name: 'Waukon',
    postalCode: '52172',
  },
  'waunakee-wi': {
    name: 'Waunakee',
    postalCode: '53597',
  },
  'wauneta-ne': {
    name: 'Wauneta',
    postalCode: '69045',
  },
  'waupaca-wi': {
    name: 'Waupaca',
    postalCode: '54981',
  },
  'waupun-wi': {
    name: 'Waupun',
    postalCode: '53963',
  },
  'waurika-ok': {
    name: 'Waurika',
    postalCode: '73573',
  },
  'wausa-ne': {
    name: 'Wausa',
    postalCode: '68786',
  },
  'wausau-wi': {
    name: 'Wausau',
    postalCode: '54401',
  },
  'wausaukee-wi': {
    name: 'Wausaukee',
    postalCode: '54177',
  },
  'wauseon-oh': {
    name: 'Wauseon',
    postalCode: '43567',
  },
  'wautoma-wi': {
    name: 'Wautoma',
    postalCode: '54982',
  },
  'wauzeka-wi': {
    name: 'Wauzeka',
    postalCode: '53826',
  },
  'waveland-in': {
    name: 'Waveland',
    postalCode: '47989',
  },
  'waveland-ms': {
    name: 'Waveland',
    postalCode: '39576',
  },
  'waverly-al': {
    name: 'Waverly',
    postalCode: '36879',
  },
  'waverly-ga': {
    name: 'Waverly',
    postalCode: '31565',
  },
  'waverly-ia': {
    name: 'Waverly',
    postalCode: '50677',
  },
  'waverly-il': {
    name: 'Waverly',
    postalCode: '62692',
  },
  'waverly-ks': {
    name: 'Waverly',
    postalCode: '66871',
  },
  'waverly-ky': {
    name: 'Waverly',
    postalCode: '42462',
  },
  'waverly-mn': {
    name: 'Waverly',
    postalCode: '55390',
  },
  'waverly-mo': {
    name: 'Waverly',
    postalCode: '64096',
  },
  'waverly-ne': {
    name: 'Waverly',
    postalCode: '68462',
  },
  'waverly-ny': {
    name: 'Waverly',
    postalCode: '14892',
  },
  'waverly-oh': {
    name: 'Waverly',
    postalCode: '45690',
  },
  'waverly-tn': {
    name: 'Waverly',
    postalCode: '37185',
  },
  'waverly-va': {
    name: 'Waverly',
    postalCode: '23890',
  },
  'waverly-wv': {
    name: 'Waverly',
    postalCode: '26184',
  },
  'waverly-hall-ga': {
    name: 'Waverly Hall',
    postalCode: '31831',
  },
  'wawaka-in': {
    name: 'Wawaka',
    postalCode: '46794',
  },
  'waxahachie-tx': {
    name: 'Waxahachie',
    postalCode: '75165',
  },
  'waxhaw-nc': {
    name: 'Waxhaw',
    postalCode: '28173',
  },
  'wayan-id': {
    name: 'Wayan',
    postalCode: '83285',
  },
  'waycross-ga': {
    name: 'Waycross',
    postalCode: '31501',
  },
  'wayland-ia': {
    name: 'Wayland',
    postalCode: '52654',
  },
  'wayland-ky': {
    name: 'Wayland',
    postalCode: '41666',
  },
  'wayland-ma': {
    name: 'Wayland',
    postalCode: '01778',
  },
  'wayland-mi': {
    name: 'Wayland',
    postalCode: '49348',
  },
  'wayland-mo': {
    name: 'Wayland',
    postalCode: '63472',
  },
  'wayland-ny': {
    name: 'Wayland',
    postalCode: '14572',
  },
  'waymart-pa': {
    name: 'Waymart',
    postalCode: '18472',
  },
  'wayne-il': {
    name: 'Wayne',
    postalCode: '60184',
  },
  'wayne-me': {
    name: 'Wayne',
    postalCode: '04284',
  },
  'wayne-mi': {
    name: 'Wayne',
    postalCode: '48184',
  },
  'wayne-ne': {
    name: 'Wayne',
    postalCode: '68787',
  },
  'wayne-nj': {
    name: 'Wayne',
    postalCode: '07470',
  },
  'wayne-oh': {
    name: 'Wayne',
    postalCode: '43466',
  },
  'wayne-ok': {
    name: 'Wayne',
    postalCode: '73095',
  },
  'wayne-pa': {
    name: 'Wayne',
    postalCode: '19087',
  },
  'wayne-wv': {
    name: 'Wayne',
    postalCode: '25570',
  },
  'wayne-city-il': {
    name: 'Wayne City',
    postalCode: '62895',
  },
  'waynesboro-ga': {
    name: 'Waynesboro',
    postalCode: '30830',
  },
  'waynesboro-ms': {
    name: 'Waynesboro',
    postalCode: '39367',
  },
  'waynesboro-pa': {
    name: 'Waynesboro',
    postalCode: '17268',
  },
  'waynesboro-tn': {
    name: 'Waynesboro',
    postalCode: '38485',
  },
  'waynesboro-va': {
    name: 'Waynesboro',
    postalCode: '22980',
  },
  'waynesburg-ky': {
    name: 'Waynesburg',
    postalCode: '40489',
  },
  'waynesburg-oh': {
    name: 'Waynesburg',
    postalCode: '44688',
  },
  'waynesburg-pa': {
    name: 'Waynesburg',
    postalCode: '15370',
  },
  'waynesfield-oh': {
    name: 'Waynesfield',
    postalCode: '45896',
  },
  'waynesville-ga': {
    name: 'Waynesville',
    postalCode: '31566',
  },
  'waynesville-il': {
    name: 'Waynesville',
    postalCode: '61778',
  },
  'waynesville-mo': {
    name: 'Waynesville',
    postalCode: '65583',
  },
  'waynesville-nc': {
    name: 'Waynesville',
    postalCode: '28785',
  },
  'waynesville-oh': {
    name: 'Waynesville',
    postalCode: '45068',
  },
  'waynetown-in': {
    name: 'Waynetown',
    postalCode: '47990',
  },
  'waynoka-ok': {
    name: 'Waynoka',
    postalCode: '73860',
  },
  'wayside-tx': {
    name: 'Wayside',
    postalCode: '79094',
  },
  'wayside-wv': {
    name: 'Wayside',
    postalCode: '24985',
  },
  'wayzata-mn': {
    name: 'Wayzata',
    postalCode: '55391',
  },
  'weare-nh': {
    name: 'Weare',
    postalCode: '03281',
  },
  'weatherby-mo': {
    name: 'Weatherby',
    postalCode: '64497',
  },
  'weatherford-ok': {
    name: 'Weatherford',
    postalCode: '73096',
  },
  'weatherford-tx': {
    name: 'Weatherford',
    postalCode: '76085',
  },
  'weatherly-pa': {
    name: 'Weatherly',
    postalCode: '18255',
  },
  'weatogue-ct': {
    name: 'Weatogue',
    postalCode: '06089',
  },
  'weaubleau-mo': {
    name: 'Weaubleau',
    postalCode: '65774',
  },
  'weaver-al': {
    name: 'Weaver',
    postalCode: '36277',
  },
  'weaverville-nc': {
    name: 'Weaverville',
    postalCode: '28787',
  },
  'webb-al': {
    name: 'Webb',
    postalCode: '36376',
  },
  'webb-ia': {
    name: 'Webb',
    postalCode: '51366',
  },
  'webb-city-mo': {
    name: 'Webb City',
    postalCode: '64870',
  },
  'webber-ks': {
    name: 'Webber',
    postalCode: '66970',
  },
  'webbers-falls-ok': {
    name: 'Webbers Falls',
    postalCode: '74470',
  },
  'webberville-mi': {
    name: 'Webberville',
    postalCode: '48892',
  },
  'webbville-ky': {
    name: 'Webbville',
    postalCode: '41180',
  },
  'weber-city-va': {
    name: 'Weber City',
    postalCode: '24290',
  },
  'webster-fl': {
    name: 'Webster',
    postalCode: '33597',
  },
  'webster-ia': {
    name: 'Webster',
    postalCode: '52355',
  },
  'webster-in': {
    name: 'Webster',
    postalCode: '47392',
  },
  'webster-ky': {
    name: 'Webster',
    postalCode: '40176',
  },
  'webster-ma': {
    name: 'Webster',
    postalCode: '01570',
  },
  'webster-mn': {
    name: 'Webster',
    postalCode: '55088',
  },
  'webster-nd': {
    name: 'Webster',
    postalCode: '58382',
  },
  'webster-ny': {
    name: 'Webster',
    postalCode: '14580',
  },
  'webster-sd': {
    name: 'Webster',
    postalCode: '57274',
  },
  'webster-tx': {
    name: 'Webster',
    postalCode: '77598',
  },
  'webster-wi': {
    name: 'Webster',
    postalCode: '54893',
  },
  'webster-city-ia': {
    name: 'Webster City',
    postalCode: '50595',
  },
  'webster-springs-wv': {
    name: 'Webster Springs',
    postalCode: '26288',
  },
  'wedgefield-sc': {
    name: 'Wedgefield',
    postalCode: '29168',
  },
  'wedowee-al': {
    name: 'Wedowee',
    postalCode: '36278',
  },
  'weed-ca': {
    name: 'Weed',
    postalCode: '96094',
  },
  'weed-nm': {
    name: 'Weed',
    postalCode: '88354',
  },
  'weedsport-ny': {
    name: 'Weedsport',
    postalCode: '13166',
  },
  'weedville-pa': {
    name: 'Weedville',
    postalCode: '15868',
  },
  'weehawken-nj': {
    name: 'Weehawken',
    postalCode: '07086',
  },
  'weeksbury-ky': {
    name: 'Weeksbury',
    postalCode: '41667',
  },
  'weems-va': {
    name: 'Weems',
    postalCode: '22576',
  },
  'weeping-water-ne': {
    name: 'Weeping Water',
    postalCode: '68463',
  },
  'weidman-mi': {
    name: 'Weidman',
    postalCode: '48893',
  },
  'weimar-tx': {
    name: 'Weimar',
    postalCode: '78962',
  },
  'weiner-ar': {
    name: 'Weiner',
    postalCode: '72479',
  },
  'weinert-tx': {
    name: 'Weinert',
    postalCode: '76388',
  },
  'weippe-id': {
    name: 'Weippe',
    postalCode: '83553',
  },
  'weir-ks': {
    name: 'Weir',
    postalCode: '66781',
  },
  'weir-ms': {
    name: 'Weir',
    postalCode: '39772',
  },
  'weirsdale-fl': {
    name: 'Weirsdale',
    postalCode: '32195',
  },
  'weirton-wv': {
    name: 'Weirton',
    postalCode: '26062',
  },
  'weiser-id': {
    name: 'Weiser',
    postalCode: '83672',
  },
  'welaka-fl': {
    name: 'Welaka',
    postalCode: '32193',
  },
  'welch-mn': {
    name: 'Welch',
    postalCode: '55089',
  },
  'welch-ok': {
    name: 'Welch',
    postalCode: '74369',
  },
  'welch-tx': {
    name: 'Welch',
    postalCode: '79377',
  },
  'welch-wv': {
    name: 'Welch',
    postalCode: '24801',
  },
  'welches-or': {
    name: 'Welches',
    postalCode: '97067',
  },
  'welchs-creek-ky': {
    name: 'Welchs Creek',
    postalCode: '42287',
  },
  'welcome-md': {
    name: 'Welcome',
    postalCode: '20693',
  },
  'welcome-mn': {
    name: 'Welcome',
    postalCode: '56181',
  },
  'weld-me': {
    name: 'Weld',
    postalCode: '04285',
  },
  'welda-ks': {
    name: 'Welda',
    postalCode: '66091',
  },
  'weldon-ca': {
    name: 'Weldon',
    postalCode: '93283',
  },
  'weldon-ia': {
    name: 'Weldon',
    postalCode: '50264',
  },
  'weldon-il': {
    name: 'Weldon',
    postalCode: '61882',
  },
  'weldon-nc': {
    name: 'Weldon',
    postalCode: '27890',
  },
  'weldona-co': {
    name: 'Weldona',
    postalCode: '80653',
  },
  'weleetka-ok': {
    name: 'Weleetka',
    postalCode: '74880',
  },
  'wellborn-fl': {
    name: 'Wellborn',
    postalCode: '32094',
  },
  'wellersburg-pa': {
    name: 'Wellersburg',
    postalCode: '15564',
  },
  'wellesley-ma': {
    name: 'Wellesley',
    postalCode: '02482',
  },
  'wellesley-hills-ma': {
    name: 'Wellesley Hills',
    postalCode: '02481',
  },
  'wellesley-island-ny': {
    name: 'Wellesley Island',
    postalCode: '13640',
  },
  'wellfleet-ma': {
    name: 'Wellfleet',
    postalCode: '02667',
  },
  'wellfleet-ne': {
    name: 'Wellfleet',
    postalCode: '69170',
  },
  'wellford-sc': {
    name: 'Wellford',
    postalCode: '29385',
  },
  'welling-ok': {
    name: 'Welling',
    postalCode: '74471',
  },
  'wellington-al': {
    name: 'Wellington',
    postalCode: '36279',
  },
  'wellington-co': {
    name: 'Wellington',
    postalCode: '80549',
  },
  'wellington-fl': {
    name: 'Wellington',
    postalCode: '33414',
  },
  'wellington-il': {
    name: 'Wellington',
    postalCode: '60973',
  },
  'wellington-ks': {
    name: 'Wellington',
    postalCode: '67152',
  },
  'wellington-ky': {
    name: 'Wellington',
    postalCode: '40387',
  },
  'wellington-mo': {
    name: 'Wellington',
    postalCode: '64097',
  },
  'wellington-nv': {
    name: 'Wellington',
    postalCode: '89444',
  },
  'wellington-oh': {
    name: 'Wellington',
    postalCode: '44090',
  },
  'wellington-tx': {
    name: 'Wellington',
    postalCode: '79095',
  },
  'wellington-ut': {
    name: 'Wellington',
    postalCode: '84542',
  },
  'wellman-ia': {
    name: 'Wellman',
    postalCode: '52356',
  },
  'wellpinit-wa': {
    name: 'Wellpinit',
    postalCode: '99040',
  },
  'wells-me': {
    name: 'Wells',
    postalCode: '04090',
  },
  'wells-mi': {
    name: 'Wells',
    postalCode: '49894',
  },
  'wells-mn': {
    name: 'Wells',
    postalCode: '56097',
  },
  'wells-nv': {
    name: 'Wells',
    postalCode: '89835',
  },
  'wells-ny': {
    name: 'Wells',
    postalCode: '12190',
  },
  'wells-vt': {
    name: 'Wells',
    postalCode: '05774',
  },
  'wells-bridge-ny': {
    name: 'Wells Bridge',
    postalCode: '13859',
  },
  'wells-river-vt': {
    name: 'Wells River',
    postalCode: '05081',
  },
  'wells-tannery-pa': {
    name: 'Wells Tannery',
    postalCode: '16691',
  },
  'wellsboro-pa': {
    name: 'Wellsboro',
    postalCode: '16901',
  },
  'wellsburg-ia': {
    name: 'Wellsburg',
    postalCode: '50680',
  },
  'wellsburg-ny': {
    name: 'Wellsburg',
    postalCode: '14894',
  },
  'wellsburg-wv': {
    name: 'Wellsburg',
    postalCode: '26070',
  },
  'wellston-mi': {
    name: 'Wellston',
    postalCode: '49689',
  },
  'wellston-oh': {
    name: 'Wellston',
    postalCode: '45692',
  },
  'wellston-ok': {
    name: 'Wellston',
    postalCode: '74881',
  },
  'wellsville-ks': {
    name: 'Wellsville',
    postalCode: '66092',
  },
  'wellsville-mo': {
    name: 'Wellsville',
    postalCode: '63384',
  },
  'wellsville-ny': {
    name: 'Wellsville',
    postalCode: '14895',
  },
  'wellsville-oh': {
    name: 'Wellsville',
    postalCode: '43968',
  },
  'wellsville-pa': {
    name: 'Wellsville',
    postalCode: '17365',
  },
  'wellsville-ut': {
    name: 'Wellsville',
    postalCode: '84339',
  },
  'wellton-az': {
    name: 'Wellton',
    postalCode: '85356',
  },
  'welsh-la': {
    name: 'Welsh',
    postalCode: '70591',
  },
  'wenatchee-wa': {
    name: 'Wenatchee',
    postalCode: '98801',
  },
  'wendel-ca': {
    name: 'Wendel',
    postalCode: '96136',
  },
  'wendell-id': {
    name: 'Wendell',
    postalCode: '83355',
  },
  'wendell-ma': {
    name: 'Wendell',
    postalCode: '01379',
  },
  'wendell-mn': {
    name: 'Wendell',
    postalCode: '56590',
  },
  'wendell-nc': {
    name: 'Wendell',
    postalCode: '27591',
  },
  'wendell-depot-ma': {
    name: 'Wendell Depot',
    postalCode: '01380',
  },
  'wendover-ky': {
    name: 'Wendover',
    postalCode: '41775',
  },
  'wendover-ut': {
    name: 'Wendover',
    postalCode: '84083',
  },
  'wenham-ma': {
    name: 'Wenham',
    postalCode: '01984',
  },
  'wenona-il': {
    name: 'Wenona',
    postalCode: '61377',
  },
  'wenonah-nj': {
    name: 'Wenonah',
    postalCode: '08090',
  },
  'wentworth-mo': {
    name: 'Wentworth',
    postalCode: '64873',
  },
  'wentworth-nh': {
    name: 'Wentworth',
    postalCode: '03282',
  },
  'wentworth-sd': {
    name: 'Wentworth',
    postalCode: '57075',
  },
  'wentzville-mo': {
    name: 'Wentzville',
    postalCode: '63385',
  },
  'weogufka-al': {
    name: 'Weogufka',
    postalCode: '35183',
  },
  'wernersville-pa': {
    name: 'Wernersville',
    postalCode: '19565',
  },
  'weskan-ks': {
    name: 'Weskan',
    postalCode: '67762',
  },
  'weslaco-tx': {
    name: 'Weslaco',
    postalCode: '78596',
  },
  'wesley-ar': {
    name: 'Wesley',
    postalCode: '72773',
  },
  'wesley-ia': {
    name: 'Wesley',
    postalCode: '50483',
  },
  'wesley-me': {
    name: 'Wesley',
    postalCode: '04686',
  },
  'wesley-chapel-fl': {
    name: 'Wesley Chapel',
    postalCode: '33543',
  },
  'wessington-sd': {
    name: 'Wessington',
    postalCode: '57381',
  },
  'wessington-springs-sd': {
    name: 'Wessington Springs',
    postalCode: '57382',
  },
  'wesson-ms': {
    name: 'Wesson',
    postalCode: '39191',
  },
  'west-ms': {
    name: 'West',
    postalCode: '39192',
  },
  'west-tx': {
    name: 'West',
    postalCode: '76691',
  },
  'west-alexander-pa': {
    name: 'West Alexander',
    postalCode: '15376',
  },
  'west-alexandria-oh': {
    name: 'West Alexandria',
    postalCode: '45381',
  },
  'west-alton-mo': {
    name: 'West Alton',
    postalCode: '63386',
  },
  'west-augusta-va': {
    name: 'West Augusta',
    postalCode: '24485',
  },
  'west-babylon-ny': {
    name: 'West Babylon',
    postalCode: '11704',
  },
  'west-baden-springs-in': {
    name: 'West Baden Springs',
    postalCode: '47469',
  },
  'west-baldwin-me': {
    name: 'West Baldwin',
    postalCode: '04091',
  },
  'west-barnstable-ma': {
    name: 'West Barnstable',
    postalCode: '02668',
  },
  'west-bend-ia': {
    name: 'West Bend',
    postalCode: '50597',
  },
  'west-bend-wi': {
    name: 'West Bend',
    postalCode: '53090',
  },
  'west-berlin-nj': {
    name: 'West Berlin',
    postalCode: '08091',
  },
  'west-blocton-al': {
    name: 'West Blocton',
    postalCode: '35184',
  },
  'west-bloomfield-mi': {
    name: 'West Bloomfield',
    postalCode: '48322',
  },
  'west-bloomfield-ny': {
    name: 'West Bloomfield',
    postalCode: '14585',
  },
  'west-boylston-ma': {
    name: 'West Boylston',
    postalCode: '01583',
  },
  'west-branch-ia': {
    name: 'West Branch',
    postalCode: '52358',
  },
  'west-branch-mi': {
    name: 'West Branch',
    postalCode: '48661',
  },
  'west-bridgewater-ma': {
    name: 'West Bridgewater',
    postalCode: '02379',
  },
  'west-brookfield-ma': {
    name: 'West Brookfield',
    postalCode: '01585',
  },
  'west-brooklyn-il': {
    name: 'West Brooklyn',
    postalCode: '61378',
  },
  'west-burke-vt': {
    name: 'West Burke',
    postalCode: '05871',
  },
  'west-burlington-ia': {
    name: 'West Burlington',
    postalCode: '52655',
  },
  'west-burlington-ny': {
    name: 'West Burlington',
    postalCode: '13482',
  },
  'west-charleston-vt': {
    name: 'West Charleston',
    postalCode: '05872',
  },
  'west-chazy-ny': {
    name: 'West Chazy',
    postalCode: '12992',
  },
  'west-chester-ia': {
    name: 'West Chester',
    postalCode: '52359',
  },
  'west-chester-oh': {
    name: 'West Chester',
    postalCode: '45069',
  },
  'west-chester-pa': {
    name: 'West Chester',
    postalCode: '19380',
  },
  'west-chesterfield-ma': {
    name: 'West Chesterfield',
    postalCode: '01084',
  },
  'west-chesterfield-nh': {
    name: 'West Chesterfield',
    postalCode: '03466',
  },
  'west-chicago-il': {
    name: 'West Chicago',
    postalCode: '60185',
  },
  'west-college-corner-in': {
    name: 'West College Corner',
    postalCode: '47003',
  },
  'west-columbia-sc': {
    name: 'West Columbia',
    postalCode: '29169',
  },
  'west-columbia-tx': {
    name: 'West Columbia',
    postalCode: '77486',
  },
  'west-columbia-wv': {
    name: 'West Columbia',
    postalCode: '25287',
  },
  'west-concord-mn': {
    name: 'West Concord',
    postalCode: '55985',
  },
  'west-copake-ny': {
    name: 'West Copake',
    postalCode: '12593',
  },
  'west-cornwall-ct': {
    name: 'West Cornwall',
    postalCode: '06796',
  },
  'west-covina-ca': {
    name: 'West Covina',
    postalCode: '91790',
  },
  'west-coxsackie-ny': {
    name: 'West Coxsackie',
    postalCode: '12192',
  },
  'west-creek-nj': {
    name: 'West Creek',
    postalCode: '08092',
  },
  'west-danville-vt': {
    name: 'West Danville',
    postalCode: '05873',
  },
  'west-decatur-pa': {
    name: 'West Decatur',
    postalCode: '16878',
  },
  'west-dennis-ma': {
    name: 'West Dennis',
    postalCode: '02670',
  },
  'west-des-moines-ia': {
    name: 'West Des Moines',
    postalCode: '50265',
  },
  'west-dover-vt': {
    name: 'West Dover',
    postalCode: '05356',
  },
  'west-edmeston-ny': {
    name: 'West Edmeston',
    postalCode: '13485',
  },
  'west-end-nc': {
    name: 'West End',
    postalCode: '27376',
  },
  'west-enfield-me': {
    name: 'West Enfield',
    postalCode: '04493',
  },
  'west-fairlee-vt': {
    name: 'West Fairlee',
    postalCode: '05083',
  },
  'west-falls-ny': {
    name: 'West Falls',
    postalCode: '14170',
  },
  'west-fargo-nd': {
    name: 'West Fargo',
    postalCode: '58078',
  },
  'west-farmington-oh': {
    name: 'West Farmington',
    postalCode: '44491',
  },
  'west-finley-pa': {
    name: 'West Finley',
    postalCode: '15377',
  },
  'west-fork-ar': {
    name: 'West Fork',
    postalCode: '72774',
  },
  'west-forks-me': {
    name: 'West Forks',
    postalCode: '04985',
  },
  'west-frankfort-il': {
    name: 'West Frankfort',
    postalCode: '62896',
  },
  'west-friendship-md': {
    name: 'West Friendship',
    postalCode: '21794',
  },
  'west-fulton-ny': {
    name: 'West Fulton',
    postalCode: '12194',
  },
  'west-granby-ct': {
    name: 'West Granby',
    postalCode: '06090',
  },
  'west-green-ga': {
    name: 'West Green',
    postalCode: '31567',
  },
  'west-greenwich-ri': {
    name: 'West Greenwich',
    postalCode: '02817',
  },
  'west-grove-ia': {
    name: 'West Grove',
    postalCode: '52538',
  },
  'west-grove-pa': {
    name: 'West Grove',
    postalCode: '19390',
  },
  'west-halifax-vt': {
    name: 'West Halifax',
    postalCode: '05358',
  },
  'west-hamlin-wv': {
    name: 'West Hamlin',
    postalCode: '25571',
  },
  'west-harrison-in': {
    name: 'West Harrison',
    postalCode: '47060',
  },
  'west-harrison-ny': {
    name: 'West Harrison',
    postalCode: '10604',
  },
  'west-hartford-ct': {
    name: 'West Hartford',
    postalCode: '06107',
  },
  'west-hartford-vt': {
    name: 'West Hartford',
    postalCode: '05084',
  },
  'west-hartland-ct': {
    name: 'West Hartland',
    postalCode: '06091',
  },
  'west-harwich-ma': {
    name: 'West Harwich',
    postalCode: '02671',
  },
  'west-hatfield-ma': {
    name: 'West Hatfield',
    postalCode: '01088',
  },
  'west-haven-ct': {
    name: 'West Haven',
    postalCode: '06516',
  },
  'west-haverstraw-ny': {
    name: 'West Haverstraw',
    postalCode: '10993',
  },
  'west-helena-ar': {
    name: 'West Helena',
    postalCode: '72390',
  },
  'west-hempstead-ny': {
    name: 'West Hempstead',
    postalCode: '11552',
  },
  'west-henrietta-ny': {
    name: 'West Henrietta',
    postalCode: '14586',
  },
  'west-hills-ca': {
    name: 'West Hills',
    postalCode: '91307',
  },
  'west-hollywood-ca': {
    name: 'West Hollywood',
    postalCode: '90069',
  },
  'west-hurley-ny': {
    name: 'West Hurley',
    postalCode: '12491',
  },
  'west-islip-ny': {
    name: 'West Islip',
    postalCode: '11795',
  },
  'west-jefferson-nc': {
    name: 'West Jefferson',
    postalCode: '28694',
  },
  'west-jefferson-oh': {
    name: 'West Jefferson',
    postalCode: '43162',
  },
  'west-jordan-ut': {
    name: 'West Jordan',
    postalCode: '84081',
  },
  'west-kill-ny': {
    name: 'West Kill',
    postalCode: '12492',
  },
  'west-kingston-ri': {
    name: 'West Kingston',
    postalCode: '02892',
  },
  'west-lafayette-in': {
    name: 'West Lafayette',
    postalCode: '47906',
  },
  'west-lafayette-oh': {
    name: 'West Lafayette',
    postalCode: '43845',
  },
  'west-lebanon-in': {
    name: 'West Lebanon',
    postalCode: '47991',
  },
  'west-lebanon-nh': {
    name: 'West Lebanon',
    postalCode: '03784',
  },
  'west-leyden-ny': {
    name: 'West Leyden',
    postalCode: '13489',
  },
  'west-liberty-ia': {
    name: 'West Liberty',
    postalCode: '52776',
  },
  'west-liberty-il': {
    name: 'West Liberty',
    postalCode: '62475',
  },
  'west-liberty-ky': {
    name: 'West Liberty',
    postalCode: '41472',
  },
  'west-liberty-oh': {
    name: 'West Liberty',
    postalCode: '43357',
  },
  'west-linn-or': {
    name: 'West Linn',
    postalCode: '97068',
  },
  'west-long-branch-nj': {
    name: 'West Long Branch',
    postalCode: '07764',
  },
  'west-manchester-oh': {
    name: 'West Manchester',
    postalCode: '45382',
  },
  'west-mansfield-oh': {
    name: 'West Mansfield',
    postalCode: '43358',
  },
  'west-memphis-ar': {
    name: 'West Memphis',
    postalCode: '72301',
  },
  'west-middlesex-pa': {
    name: 'West Middlesex',
    postalCode: '16159',
  },
  'west-mifflin-pa': {
    name: 'West Mifflin',
    postalCode: '15122',
  },
  'west-milford-nj': {
    name: 'West Milford',
    postalCode: '07480',
  },
  'west-milford-wv': {
    name: 'West Milford',
    postalCode: '26451',
  },
  'west-milton-oh': {
    name: 'West Milton',
    postalCode: '45383',
  },
  'west-milton-pa': {
    name: 'West Milton',
    postalCode: '17886',
  },
  'west-monroe-la': {
    name: 'West Monroe',
    postalCode: '71291',
  },
  'west-monroe-ny': {
    name: 'West Monroe',
    postalCode: '13167',
  },
  'west-new-york-nj': {
    name: 'West New York',
    postalCode: '07093',
  },
  'west-newbury-ma': {
    name: 'West Newbury',
    postalCode: '01985',
  },
  'west-newfield-me': {
    name: 'West Newfield',
    postalCode: '04095',
  },
  'west-newton-ma': {
    name: 'West Newton',
    postalCode: '02465',
  },
  'west-newton-pa': {
    name: 'West Newton',
    postalCode: '15089',
  },
  'west-nottingham-nh': {
    name: 'West Nottingham',
    postalCode: '03291',
  },
  'west-nyack-ny': {
    name: 'West Nyack',
    postalCode: '10994',
  },
  'west-olive-mi': {
    name: 'West Olive',
    postalCode: '49460',
  },
  'west-oneonta-ny': {
    name: 'West Oneonta',
    postalCode: '13861',
  },
  'west-orange-nj': {
    name: 'West Orange',
    postalCode: '07052',
  },
  'west-ossipee-nh': {
    name: 'West Ossipee',
    postalCode: '03890',
  },
  'west-paducah-ky': {
    name: 'West Paducah',
    postalCode: '42086',
  },
  'west-palm-beach-fl': {
    name: 'West Palm Beach',
    postalCode: '33401',
  },
  'west-paris-me': {
    name: 'West Paris',
    postalCode: '04289',
  },
  'west-pawlet-vt': {
    name: 'West Pawlet',
    postalCode: '05775',
  },
  'west-plains-mo': {
    name: 'West Plains',
    postalCode: '65775',
  },
  'west-point-ca': {
    name: 'West Point',
    postalCode: '95255',
  },
  'west-point-ga': {
    name: 'West Point',
    postalCode: '31833',
  },
  'west-point-ia': {
    name: 'West Point',
    postalCode: '52656',
  },
  'west-point-il': {
    name: 'West Point',
    postalCode: '62380',
  },
  'west-point-ky': {
    name: 'West Point',
    postalCode: '40177',
  },
  'west-point-ms': {
    name: 'West Point',
    postalCode: '39773',
  },
  'west-point-ne': {
    name: 'West Point',
    postalCode: '68788',
  },
  'west-point-ny': {
    name: 'West Point',
    postalCode: '10996',
  },
  'west-point-tx': {
    name: 'West Point',
    postalCode: '78963',
  },
  'west-point-va': {
    name: 'West Point',
    postalCode: '23181',
  },
  'west-portsmouth-oh': {
    name: 'West Portsmouth',
    postalCode: '45663',
  },
  'west-richland-wa': {
    name: 'West Richland',
    postalCode: '99353',
  },
  'west-river-md': {
    name: 'West River',
    postalCode: '20778',
  },
  'west-roxbury-ma': {
    name: 'West Roxbury',
    postalCode: '02132',
  },
  'west-rupert-vt': {
    name: 'West Rupert',
    postalCode: '05776',
  },
  'west-rushville-oh': {
    name: 'West Rushville',
    postalCode: '43163',
  },
  'west-rutland-vt': {
    name: 'West Rutland',
    postalCode: '05777',
  },
  'west-sacramento-ca': {
    name: 'West Sacramento',
    postalCode: '95605',
  },
  'west-salem-il': {
    name: 'West Salem',
    postalCode: '62476',
  },
  'west-salem-oh': {
    name: 'West Salem',
    postalCode: '44287',
  },
  'west-salem-wi': {
    name: 'West Salem',
    postalCode: '54669',
  },
  'west-salisbury-pa': {
    name: 'West Salisbury',
    postalCode: '15565',
  },
  'west-sand-lake-ny': {
    name: 'West Sand Lake',
    postalCode: '12196',
  },
  'west-sayville-ny': {
    name: 'West Sayville',
    postalCode: '11796',
  },
  'west-shokan-ny': {
    name: 'West Shokan',
    postalCode: '12494',
  },
  'west-simsbury-ct': {
    name: 'West Simsbury',
    postalCode: '06092',
  },
  'west-springfield-ma': {
    name: 'West Springfield',
    postalCode: '01089',
  },
  'west-springfield-pa': {
    name: 'West Springfield',
    postalCode: '16443',
  },
  'west-stockbridge-ma': {
    name: 'West Stockbridge',
    postalCode: '01266',
  },
  'west-stockholm-ny': {
    name: 'West Stockholm',
    postalCode: '13696',
  },
  'west-suffield-ct': {
    name: 'West Suffield',
    postalCode: '06093',
  },
  'west-sunbury-pa': {
    name: 'West Sunbury',
    postalCode: '16061',
  },
  'west-terre-haute-in': {
    name: 'West Terre Haute',
    postalCode: '47885',
  },
  'west-topsham-vt': {
    name: 'West Topsham',
    postalCode: '05086',
  },
  'west-townsend-ma': {
    name: 'West Townsend',
    postalCode: '01474',
  },
  'west-townshend-vt': {
    name: 'West Townshend',
    postalCode: '05359',
  },
  'west-union-ia': {
    name: 'West Union',
    postalCode: '52175',
  },
  'west-union-il': {
    name: 'West Union',
    postalCode: '62477',
  },
  'west-union-oh': {
    name: 'West Union',
    postalCode: '45693',
  },
  'west-union-sc': {
    name: 'West Union',
    postalCode: '29696',
  },
  'west-union-wv': {
    name: 'West Union',
    postalCode: '26456',
  },
  'west-unity-oh': {
    name: 'West Unity',
    postalCode: '43570',
  },
  'west-valley-ny': {
    name: 'West Valley',
    postalCode: '14171',
  },
  'west-valley-city-ut': {
    name: 'West Valley City',
    postalCode: '84119',
  },
  'west-van-lear-ky': {
    name: 'West Van Lear',
    postalCode: '41268',
  },
  'west-wardsboro-vt': {
    name: 'West Wardsboro',
    postalCode: '05360',
  },
  'west-wareham-ma': {
    name: 'West Wareham',
    postalCode: '02576',
  },
  'west-warwick-ri': {
    name: 'West Warwick',
    postalCode: '02893',
  },
  'west-winfield-ny': {
    name: 'West Winfield',
    postalCode: '13491',
  },
  'west-yarmouth-ma': {
    name: 'West Yarmouth',
    postalCode: '02673',
  },
  'west-yellowstone-mt': {
    name: 'West Yellowstone',
    postalCode: '59758',
  },
  'west-york-il': {
    name: 'West York',
    postalCode: '62478',
  },
  'westboro-mo': {
    name: 'Westboro',
    postalCode: '64498',
  },
  'westboro-wi': {
    name: 'Westboro',
    postalCode: '54490',
  },
  'westborough-ma': {
    name: 'Westborough',
    postalCode: '01581',
  },
  'westbrook-ct': {
    name: 'Westbrook',
    postalCode: '06498',
  },
  'westbrook-me': {
    name: 'Westbrook',
    postalCode: '04092',
  },
  'westbrook-mn': {
    name: 'Westbrook',
    postalCode: '56183',
  },
  'westbrook-tx': {
    name: 'Westbrook',
    postalCode: '79565',
  },
  'westbury-ny': {
    name: 'Westbury',
    postalCode: '11590',
  },
  'westby-mt': {
    name: 'Westby',
    postalCode: '59275',
  },
  'westby-wi': {
    name: 'Westby',
    postalCode: '54667',
  },
  'westchester-il': {
    name: 'Westchester',
    postalCode: '60154',
  },
  'westcliffe-co': {
    name: 'Westcliffe',
    postalCode: '81252',
  },
  'westdale-ny': {
    name: 'Westdale',
    postalCode: '13483',
  },
  'westerlo-ny': {
    name: 'Westerlo',
    postalCode: '12193',
  },
  'westerly-ri': {
    name: 'Westerly',
    postalCode: '02891',
  },
  'western-ne': {
    name: 'Western',
    postalCode: '68464',
  },
  'western-grove-ar': {
    name: 'Western Grove',
    postalCode: '72685',
  },
  'western-springs-il': {
    name: 'Western Springs',
    postalCode: '60558',
  },
  'westernport-md': {
    name: 'Westernport',
    postalCode: '21562',
  },
  'westernville-ny': {
    name: 'Westernville',
    postalCode: '13486',
  },
  'westerville-ne': {
    name: 'Westerville',
    postalCode: '68881',
  },
  'westerville-oh': {
    name: 'Westerville',
    postalCode: '43081',
  },
  'westfield-ia': {
    name: 'Westfield',
    postalCode: '51062',
  },
  'westfield-il': {
    name: 'Westfield',
    postalCode: '62474',
  },
  'westfield-in': {
    name: 'Westfield',
    postalCode: '46074',
  },
  'westfield-ma': {
    name: 'Westfield',
    postalCode: '01085',
  },
  'westfield-me': {
    name: 'Westfield',
    postalCode: '04787',
  },
  'westfield-nc': {
    name: 'Westfield',
    postalCode: '27053',
  },
  'westfield-nj': {
    name: 'Westfield',
    postalCode: '07090',
  },
  'westfield-ny': {
    name: 'Westfield',
    postalCode: '14787',
  },
  'westfield-pa': {
    name: 'Westfield',
    postalCode: '16950',
  },
  'westfield-vt': {
    name: 'Westfield',
    postalCode: '05874',
  },
  'westfield-wi': {
    name: 'Westfield',
    postalCode: '53964',
  },
  'westfir-or': {
    name: 'Westfir',
    postalCode: '97492',
  },
  'westford-ma': {
    name: 'Westford',
    postalCode: '01886',
  },
  'westford-ny': {
    name: 'Westford',
    postalCode: '13488',
  },
  'westford-vt': {
    name: 'Westford',
    postalCode: '05494',
  },
  'westgate-ia': {
    name: 'Westgate',
    postalCode: '50681',
  },
  'westhampton-ny': {
    name: 'Westhampton',
    postalCode: '11977',
  },
  'westhampton-beach-ny': {
    name: 'Westhampton Beach',
    postalCode: '11978',
  },
  'westhoff-tx': {
    name: 'Westhoff',
    postalCode: '77994',
  },
  'westhope-nd': {
    name: 'Westhope',
    postalCode: '58793',
  },
  'westlake-la': {
    name: 'Westlake',
    postalCode: '70669',
  },
  'westlake-oh': {
    name: 'Westlake',
    postalCode: '44145',
  },
  'westlake-or': {
    name: 'Westlake',
    postalCode: '97493',
  },
  'westlake-village-ca': {
    name: 'Westlake Village',
    postalCode: '91361',
  },
  'westland-mi': {
    name: 'Westland',
    postalCode: '48185',
  },
  'westminster-ca': {
    name: 'Westminster',
    postalCode: '92683',
  },
  'westminster-co': {
    name: 'Westminster',
    postalCode: '80030',
  },
  'westminster-ma': {
    name: 'Westminster',
    postalCode: '01473',
  },
  'westminster-md': {
    name: 'Westminster',
    postalCode: '21157',
  },
  'westminster-sc': {
    name: 'Westminster',
    postalCode: '29693',
  },
  'westminster-vt': {
    name: 'Westminster',
    postalCode: '05158',
  },
  'westmont-il': {
    name: 'Westmont',
    postalCode: '60559',
  },
  'westmoreland-ks': {
    name: 'Westmoreland',
    postalCode: '66549',
  },
  'westmoreland-nh': {
    name: 'Westmoreland',
    postalCode: '03467',
  },
  'westmoreland-ny': {
    name: 'Westmoreland',
    postalCode: '13490',
  },
  'westmoreland-tn': {
    name: 'Westmoreland',
    postalCode: '37186',
  },
  'westmoreland-city-pa': {
    name: 'Westmoreland City',
    postalCode: '15692',
  },
  'weston-co': {
    name: 'Weston',
    postalCode: '81091',
  },
  'weston-ct': {
    name: 'Weston',
    postalCode: '06883',
  },
  'weston-ga': {
    name: 'Weston',
    postalCode: '31832',
  },
  'weston-id': {
    name: 'Weston',
    postalCode: '83286',
  },
  'weston-ma': {
    name: 'Weston',
    postalCode: '02493',
  },
  'weston-mo': {
    name: 'Weston',
    postalCode: '64098',
  },
  'weston-ne': {
    name: 'Weston',
    postalCode: '68070',
  },
  'weston-oh': {
    name: 'Weston',
    postalCode: '43569',
  },
  'weston-or': {
    name: 'Weston',
    postalCode: '97886',
  },
  'weston-vt': {
    name: 'Weston',
    postalCode: '05161',
  },
  'weston-wv': {
    name: 'Weston',
    postalCode: '26452',
  },
  'weston-wy': {
    name: 'Weston',
    postalCode: '82731',
  },
  'westover-md': {
    name: 'Westover',
    postalCode: '21871',
  },
  'westover-pa': {
    name: 'Westover',
    postalCode: '16692',
  },
  'westphalia-ks': {
    name: 'Westphalia',
    postalCode: '66093',
  },
  'westphalia-mi': {
    name: 'Westphalia',
    postalCode: '48894',
  },
  'westphalia-mo': {
    name: 'Westphalia',
    postalCode: '65085',
  },
  'westpoint-in': {
    name: 'Westpoint',
    postalCode: '47992',
  },
  'westpoint-tn': {
    name: 'Westpoint',
    postalCode: '38486',
  },
  'westport-ca': {
    name: 'Westport',
    postalCode: '95488',
  },
  'westport-ct': {
    name: 'Westport',
    postalCode: '06880',
  },
  'westport-in': {
    name: 'Westport',
    postalCode: '47283',
  },
  'westport-ky': {
    name: 'Westport',
    postalCode: '40077',
  },
  'westport-ma': {
    name: 'Westport',
    postalCode: '02790',
  },
  'westport-ny': {
    name: 'Westport',
    postalCode: '12993',
  },
  'westport-pa': {
    name: 'Westport',
    postalCode: '17778',
  },
  'westport-sd': {
    name: 'Westport',
    postalCode: '57481',
  },
  'westport-tn': {
    name: 'Westport',
    postalCode: '38387',
  },
  'westport-wa': {
    name: 'Westport',
    postalCode: '98595',
  },
  'westside-ia': {
    name: 'Westside',
    postalCode: '51467',
  },
  'westtown-ny': {
    name: 'Westtown',
    postalCode: '10998',
  },
  'westview-ky': {
    name: 'Westview',
    postalCode: '40178',
  },
  'westville-fl': {
    name: 'Westville',
    postalCode: '32464',
  },
  'westville-il': {
    name: 'Westville',
    postalCode: '61883',
  },
  'westville-in': {
    name: 'Westville',
    postalCode: '46391',
  },
  'westville-nj': {
    name: 'Westville',
    postalCode: '08093',
  },
  'westville-ok': {
    name: 'Westville',
    postalCode: '74965',
  },
  'westville-sc': {
    name: 'Westville',
    postalCode: '29175',
  },
  'westwego-la': {
    name: 'Westwego',
    postalCode: '70094',
  },
  'westwood-ca': {
    name: 'Westwood',
    postalCode: '96137',
  },
  'westwood-ma': {
    name: 'Westwood',
    postalCode: '02090',
  },
  'westwood-nj': {
    name: 'Westwood',
    postalCode: '07675',
  },
  'wethersfield-ct': {
    name: 'Wethersfield',
    postalCode: '06109',
  },
  'wetmore-co': {
    name: 'Wetmore',
    postalCode: '81253',
  },
  'wetmore-ks': {
    name: 'Wetmore',
    postalCode: '66550',
  },
  'wetmore-mi': {
    name: 'Wetmore',
    postalCode: '49895',
  },
  'wetumka-ok': {
    name: 'Wetumka',
    postalCode: '74883',
  },
  'wetumpka-al': {
    name: 'Wetumpka',
    postalCode: '36092',
  },
  'wever-ia': {
    name: 'Wever',
    postalCode: '52658',
  },
  'wevertown-ny': {
    name: 'Wevertown',
    postalCode: '12886',
  },
  'wewahitchka-fl': {
    name: 'Wewahitchka',
    postalCode: '32449',
  },
  'wewoka-ok': {
    name: 'Wewoka',
    postalCode: '74884',
  },
  'wexford-pa': {
    name: 'Wexford',
    postalCode: '15090',
  },
  'weyauwega-wi': {
    name: 'Weyauwega',
    postalCode: '54983',
  },
  'weyerhaeuser-wi': {
    name: 'Weyerhaeuser',
    postalCode: '54895',
  },
  'weyers-cave-va': {
    name: 'Weyers Cave',
    postalCode: '24486',
  },
  'weymouth-ma': {
    name: 'Weymouth',
    postalCode: '02188',
  },
  'whaleyville-md': {
    name: 'Whaleyville',
    postalCode: '21872',
  },
  'wharncliffe-wv': {
    name: 'Wharncliffe',
    postalCode: '25651',
  },
  'wharton-nj': {
    name: 'Wharton',
    postalCode: '07885',
  },
  'wharton-oh': {
    name: 'Wharton',
    postalCode: '43359',
  },
  'wharton-tx': {
    name: 'Wharton',
    postalCode: '77488',
  },
  'wharton-wv': {
    name: 'Wharton',
    postalCode: '25208',
  },
  'what-cheer-ia': {
    name: 'What Cheer',
    postalCode: '50268',
  },
  'whatley-al': {
    name: 'Whatley',
    postalCode: '36482',
  },
  'wheat-ridge-co': {
    name: 'Wheat Ridge',
    postalCode: '80033',
  },
  'wheatfield-in': {
    name: 'Wheatfield',
    postalCode: '46392',
  },
  'wheatland-ca': {
    name: 'Wheatland',
    postalCode: '95692',
  },
  'wheatland-ia': {
    name: 'Wheatland',
    postalCode: '52777',
  },
  'wheatland-in': {
    name: 'Wheatland',
    postalCode: '47597',
  },
  'wheatland-mo': {
    name: 'Wheatland',
    postalCode: '65779',
  },
  'wheatland-nd': {
    name: 'Wheatland',
    postalCode: '58079',
  },
  'wheatland-wy': {
    name: 'Wheatland',
    postalCode: '82201',
  },
  'wheatley-ar': {
    name: 'Wheatley',
    postalCode: '72392',
  },
  'wheaton-il': {
    name: 'Wheaton',
    postalCode: '60187',
  },
  'wheaton-mn': {
    name: 'Wheaton',
    postalCode: '56296',
  },
  'wheeler-il': {
    name: 'Wheeler',
    postalCode: '62479',
  },
  'wheeler-mi': {
    name: 'Wheeler',
    postalCode: '48662',
  },
  'wheeler-tx': {
    name: 'Wheeler',
    postalCode: '79096',
  },
  'wheeler-wi': {
    name: 'Wheeler',
    postalCode: '54772',
  },
  'wheeler-army-airfield-hi': {
    name: 'Wheeler Army Airfield',
    postalCode: '96854',
  },
  'wheelersburg-oh': {
    name: 'Wheelersburg',
    postalCode: '45694',
  },
  'wheeling-il': {
    name: 'Wheeling',
    postalCode: '60090',
  },
  'wheeling-mo': {
    name: 'Wheeling',
    postalCode: '64688',
  },
  'wheeling-wv': {
    name: 'Wheeling',
    postalCode: '26003',
  },
  'wheelwright-ky': {
    name: 'Wheelwright',
    postalCode: '41669',
  },
  'whick-ky': {
    name: 'Whick',
    postalCode: '41390',
  },
  'whigham-ga': {
    name: 'Whigham',
    postalCode: '39897',
  },
  'whippany-nj': {
    name: 'Whippany',
    postalCode: '07981',
  },
  'whipple-oh': {
    name: 'Whipple',
    postalCode: '45788',
  },
  'whitakers-nc': {
    name: 'Whitakers',
    postalCode: '27891',
  },
  'white-ga': {
    name: 'White',
    postalCode: '30184',
  },
  'white-pa': {
    name: 'White',
    postalCode: '15490',
  },
  'white-sd': {
    name: 'White',
    postalCode: '57276',
  },
  'white-bird-id': {
    name: 'White Bird',
    postalCode: '83554',
  },
  'white-bluff-tn': {
    name: 'White Bluff',
    postalCode: '37187',
  },
  'white-castle-la': {
    name: 'White Castle',
    postalCode: '70788',
  },
  'white-city-ks': {
    name: 'White City',
    postalCode: '66872',
  },
  'white-city-or': {
    name: 'White City',
    postalCode: '97503',
  },
  'white-cloud-ks': {
    name: 'White Cloud',
    postalCode: '66094',
  },
  'white-cloud-mi': {
    name: 'White Cloud',
    postalCode: '49349',
  },
  'white-deer-tx': {
    name: 'White Deer',
    postalCode: '79097',
  },
  'white-earth-nd': {
    name: 'White Earth',
    postalCode: '58794',
  },
  'white-hall-ar': {
    name: 'White Hall',
    postalCode: '71602',
  },
  'white-hall-il': {
    name: 'White Hall',
    postalCode: '62092',
  },
  'white-hall-md': {
    name: 'White Hall',
    postalCode: '21161',
  },
  'white-haven-pa': {
    name: 'White Haven',
    postalCode: '18661',
  },
  'white-heath-il': {
    name: 'White Heath',
    postalCode: '61884',
  },
  'white-house-tn': {
    name: 'White House',
    postalCode: '37188',
  },
  'white-lake-mi': {
    name: 'White Lake',
    postalCode: '48383',
  },
  'white-lake-ny': {
    name: 'White Lake',
    postalCode: '12786',
  },
  'white-lake-sd': {
    name: 'White Lake',
    postalCode: '57383',
  },
  'white-lake-wi': {
    name: 'White Lake',
    postalCode: '54491',
  },
  'white-marsh-md': {
    name: 'White Marsh',
    postalCode: '21162',
  },
  'white-mills-ky': {
    name: 'White Mills',
    postalCode: '42788',
  },
  'white-oak-ga': {
    name: 'White Oak',
    postalCode: '31568',
  },
  'white-oak-nc': {
    name: 'White Oak',
    postalCode: '28399',
  },
  'white-oak-tx': {
    name: 'White Oak',
    postalCode: '75693',
  },
  'white-oak-wv': {
    name: 'White Oak',
    postalCode: '25989',
  },
  'white-owl-sd': {
    name: 'White Owl',
    postalCode: '57792',
  },
  'white-pigeon-mi': {
    name: 'White Pigeon',
    postalCode: '49099',
  },
  'white-pine-tn': {
    name: 'White Pine',
    postalCode: '37890',
  },
  'white-plains-ga': {
    name: 'White Plains',
    postalCode: '30678',
  },
  'white-plains-ky': {
    name: 'White Plains',
    postalCode: '42464',
  },
  'white-plains-md': {
    name: 'White Plains',
    postalCode: '20695',
  },
  'white-plains-ny': {
    name: 'White Plains',
    postalCode: '10601',
  },
  'white-plains-va': {
    name: 'White Plains',
    postalCode: '23893',
  },
  'white-post-va': {
    name: 'White Post',
    postalCode: '22663',
  },
  'white-river-sd': {
    name: 'White River',
    postalCode: '57579',
  },
  'white-river-junction-vt': {
    name: 'White River Junction',
    postalCode: '05001',
  },
  'white-rock-nm': {
    name: 'White Rock',
    postalCode: '87547',
  },
  'white-salmon-wa': {
    name: 'White Salmon',
    postalCode: '98672',
  },
  'white-sands-missile-range-nm': {
    name: 'White Sands Missile Range',
    postalCode: '88002',
  },
  'white-springs-fl': {
    name: 'White Springs',
    postalCode: '32096',
  },
  'white-stone-va': {
    name: 'White Stone',
    postalCode: '22578',
  },
  'white-sulphur-springs-mt': {
    name: 'White Sulphur Springs',
    postalCode: '59645',
  },
  'white-sulphur-springs-ny': {
    name: 'White Sulphur Springs',
    postalCode: '12787',
  },
  'white-sulphur-springs-wv': {
    name: 'White Sulphur Springs',
    postalCode: '24961',
  },
  'white-swan-wa': {
    name: 'White Swan',
    postalCode: '98952',
  },
  'whitefield-me': {
    name: 'Whitefield',
    postalCode: '04353',
  },
  'whitefield-nh': {
    name: 'Whitefield',
    postalCode: '03598',
  },
  'whitefield-ok': {
    name: 'Whitefield',
    postalCode: '74472',
  },
  'whitefish-mt': {
    name: 'Whitefish',
    postalCode: '59937',
  },
  'whiteford-md': {
    name: 'Whiteford',
    postalCode: '21160',
  },
  'whitehall-mi': {
    name: 'Whitehall',
    postalCode: '49461',
  },
  'whitehall-mt': {
    name: 'Whitehall',
    postalCode: '59759',
  },
  'whitehall-ny': {
    name: 'Whitehall',
    postalCode: '12887',
  },
  'whitehall-pa': {
    name: 'Whitehall',
    postalCode: '18052',
  },
  'whitehall-wi': {
    name: 'Whitehall',
    postalCode: '54773',
  },
  'whitehouse-oh': {
    name: 'Whitehouse',
    postalCode: '43571',
  },
  'whitehouse-tx': {
    name: 'Whitehouse',
    postalCode: '75791',
  },
  'whitehouse-station-nj': {
    name: 'Whitehouse Station',
    postalCode: '08889',
  },
  'whiteland-in': {
    name: 'Whiteland',
    postalCode: '46184',
  },
  'whitelaw-wi': {
    name: 'Whitelaw',
    postalCode: '54247',
  },
  'whiteman-air-force-base-mo': {
    name: 'Whiteman Air Force Base',
    postalCode: '65305',
  },
  'whiterocks-ut': {
    name: 'Whiterocks',
    postalCode: '84085',
  },
  'whites-creek-tn': {
    name: 'Whites Creek',
    postalCode: '37189',
  },
  'whitesboro-ny': {
    name: 'Whitesboro',
    postalCode: '13492',
  },
  'whitesboro-ok': {
    name: 'Whitesboro',
    postalCode: '74577',
  },
  'whitesboro-tx': {
    name: 'Whitesboro',
    postalCode: '76273',
  },
  'whitesburg-ga': {
    name: 'Whitesburg',
    postalCode: '30185',
  },
  'whitesburg-ky': {
    name: 'Whitesburg',
    postalCode: '41858',
  },
  'whitesburg-tn': {
    name: 'Whitesburg',
    postalCode: '37891',
  },
  'whiteside-tn': {
    name: 'Whiteside',
    postalCode: '37396',
  },
  'whitestone-ny': {
    name: 'Whitestone',
    postalCode: '11357',
  },
  'whitestown-in': {
    name: 'Whitestown',
    postalCode: '46075',
  },
  'whitesville-ky': {
    name: 'Whitesville',
    postalCode: '42378',
  },
  'whitesville-ny': {
    name: 'Whitesville',
    postalCode: '14897',
  },
  'whitesville-wv': {
    name: 'Whitesville',
    postalCode: '25209',
  },
  'whitetail-mt': {
    name: 'Whitetail',
    postalCode: '59276',
  },
  'whitethorn-ca': {
    name: 'Whitethorn',
    postalCode: '95589',
  },
  'whitetop-va': {
    name: 'Whitetop',
    postalCode: '24292',
  },
  'whiteville-nc': {
    name: 'Whiteville',
    postalCode: '28472',
  },
  'whiteville-tn': {
    name: 'Whiteville',
    postalCode: '38075',
  },
  'whitewater-ca': {
    name: 'Whitewater',
    postalCode: '92282',
  },
  'whitewater-co': {
    name: 'Whitewater',
    postalCode: '81527',
  },
  'whitewater-ks': {
    name: 'Whitewater',
    postalCode: '67154',
  },
  'whitewater-mo': {
    name: 'Whitewater',
    postalCode: '63785',
  },
  'whitewater-mt': {
    name: 'Whitewater',
    postalCode: '59544',
  },
  'whitewater-wi': {
    name: 'Whitewater',
    postalCode: '53190',
  },
  'whitewood-sd': {
    name: 'Whitewood',
    postalCode: '57793',
  },
  'whitewood-va': {
    name: 'Whitewood',
    postalCode: '24657',
  },
  'whitewright-tx': {
    name: 'Whitewright',
    postalCode: '75491',
  },
  'whiting-ia': {
    name: 'Whiting',
    postalCode: '51063',
  },
  'whiting-in': {
    name: 'Whiting',
    postalCode: '46394',
  },
  'whiting-ks': {
    name: 'Whiting',
    postalCode: '66552',
  },
  'whiting-me': {
    name: 'Whiting',
    postalCode: '04691',
  },
  'whiting-vt': {
    name: 'Whiting',
    postalCode: '05778',
  },
  'whitingham-vt': {
    name: 'Whitingham',
    postalCode: '05361',
  },
  'whitinsville-ma': {
    name: 'Whitinsville',
    postalCode: '01588',
  },
  'whitlash-mt': {
    name: 'Whitlash',
    postalCode: '59545',
  },
  'whitley-city-ky': {
    name: 'Whitley City',
    postalCode: '42653',
  },
  'whitleyville-tn': {
    name: 'Whitleyville',
    postalCode: '38588',
  },
  'whitman-ma': {
    name: 'Whitman',
    postalCode: '02382',
  },
  'whitman-ne': {
    name: 'Whitman',
    postalCode: '69366',
  },
  'whitmire-sc': {
    name: 'Whitmire',
    postalCode: '29178',
  },
  'whitmore-ca': {
    name: 'Whitmore',
    postalCode: '96096',
  },
  'whitmore-lake-mi': {
    name: 'Whitmore Lake',
    postalCode: '48189',
  },
  'whitney-ne': {
    name: 'Whitney',
    postalCode: '69367',
  },
  'whitney-tx': {
    name: 'Whitney',
    postalCode: '76692',
  },
  'whitney-point-ny': {
    name: 'Whitney Point',
    postalCode: '13862',
  },
  'whitsett-nc': {
    name: 'Whitsett',
    postalCode: '27377',
  },
  'whitsett-tx': {
    name: 'Whitsett',
    postalCode: '78075',
  },
  'whitt-tx': {
    name: 'Whitt',
    postalCode: '76490',
  },
  'whittemore-ia': {
    name: 'Whittemore',
    postalCode: '50598',
  },
  'whittemore-mi': {
    name: 'Whittemore',
    postalCode: '48770',
  },
  'whittier-ca': {
    name: 'Whittier',
    postalCode: '90601',
  },
  'whittier-nc': {
    name: 'Whittier',
    postalCode: '28789',
  },
  'whittington-il': {
    name: 'Whittington',
    postalCode: '62897',
  },
  'whitwell-tn': {
    name: 'Whitwell',
    postalCode: '37397',
  },
  'wibaux-mt': {
    name: 'Wibaux',
    postalCode: '59353',
  },
  'wichita-ks': {
    name: 'Wichita',
    postalCode: '67202',
  },
  'wichita-falls-tx': {
    name: 'Wichita Falls',
    postalCode: '76301',
  },
  'wickenburg-az': {
    name: 'Wickenburg',
    postalCode: '85390',
  },
  'wickes-ar': {
    name: 'Wickes',
    postalCode: '71973',
  },
  'wickliffe-ky': {
    name: 'Wickliffe',
    postalCode: '42087',
  },
  'wickliffe-oh': {
    name: 'Wickliffe',
    postalCode: '44092',
  },
  'wideman-ar': {
    name: 'Wideman',
    postalCode: '72585',
  },
  'widener-ar': {
    name: 'Widener',
    postalCode: '72394',
  },
  'widnoon-pa': {
    name: 'Widnoon',
    postalCode: '16261',
  },
  'wiergate-tx': {
    name: 'Wiergate',
    postalCode: '75977',
  },
  'wiggins-co': {
    name: 'Wiggins',
    postalCode: '80654',
  },
  'wiggins-ms': {
    name: 'Wiggins',
    postalCode: '39577',
  },
  'wilber-ne': {
    name: 'Wilber',
    postalCode: '68465',
  },
  'wilbraham-ma': {
    name: 'Wilbraham',
    postalCode: '01095',
  },
  'wilbur-wa': {
    name: 'Wilbur',
    postalCode: '99185',
  },
  'wilburn-ar': {
    name: 'Wilburn',
    postalCode: '72179',
  },
  'wilburton-ok': {
    name: 'Wilburton',
    postalCode: '74578',
  },
  'wilburton-pa': {
    name: 'Wilburton',
    postalCode: '17888',
  },
  'wilcox-ne': {
    name: 'Wilcox',
    postalCode: '68982',
  },
  'wilcox-pa': {
    name: 'Wilcox',
    postalCode: '15870',
  },
  'wild-rose-wi': {
    name: 'Wild Rose',
    postalCode: '54984',
  },
  'wilder-id': {
    name: 'Wilder',
    postalCode: '83676',
  },
  'wilder-tn': {
    name: 'Wilder',
    postalCode: '38589',
  },
  'wildersville-tn': {
    name: 'Wildersville',
    postalCode: '38388',
  },
  'wilderville-or': {
    name: 'Wilderville',
    postalCode: '97543',
  },
  'wildomar-ca': {
    name: 'Wildomar',
    postalCode: '92595',
  },
  'wildorado-tx': {
    name: 'Wildorado',
    postalCode: '79098',
  },
  'wildrose-nd': {
    name: 'Wildrose',
    postalCode: '58795',
  },
  'wildwood-fl': {
    name: 'Wildwood',
    postalCode: '34785',
  },
  'wildwood-ga': {
    name: 'Wildwood',
    postalCode: '30757',
  },
  'wildwood-mo': {
    name: 'Wildwood',
    postalCode: '63038',
  },
  'wildwood-nj': {
    name: 'Wildwood',
    postalCode: '08260',
  },
  'wiley-co': {
    name: 'Wiley',
    postalCode: '81092',
  },
  'wiley-ford-wv': {
    name: 'Wiley Ford',
    postalCode: '26767',
  },
  'wilkes-barre-pa': {
    name: 'Wilkes Barre',
    postalCode: '18701',
  },
  'wilkesboro-nc': {
    name: 'Wilkesboro',
    postalCode: '28697',
  },
  'wilkesville-oh': {
    name: 'Wilkesville',
    postalCode: '45695',
  },
  'wilkinson-in': {
    name: 'Wilkinson',
    postalCode: '46186',
  },
  'willacoochee-ga': {
    name: 'Willacoochee',
    postalCode: '31650',
  },
  'willamina-or': {
    name: 'Willamina',
    postalCode: '97396',
  },
  'willard-mo': {
    name: 'Willard',
    postalCode: '65781',
  },
  'willard-nc': {
    name: 'Willard',
    postalCode: '28478',
  },
  'willard-nm': {
    name: 'Willard',
    postalCode: '87063',
  },
  'willard-oh': {
    name: 'Willard',
    postalCode: '44890',
  },
  'willard-ut': {
    name: 'Willard',
    postalCode: '84340',
  },
  'willard-wi': {
    name: 'Willard',
    postalCode: '54493',
  },
  'willards-md': {
    name: 'Willards',
    postalCode: '21874',
  },
  'willcox-az': {
    name: 'Willcox',
    postalCode: '85643',
  },
  'willet-ny': {
    name: 'Willet',
    postalCode: '13863',
  },
  'williams-az': {
    name: 'Williams',
    postalCode: '86046',
  },
  'williams-ca': {
    name: 'Williams',
    postalCode: '95987',
  },
  'williams-ia': {
    name: 'Williams',
    postalCode: '50271',
  },
  'williams-in': {
    name: 'Williams',
    postalCode: '47470',
  },
  'williams-mn': {
    name: 'Williams',
    postalCode: '56686',
  },
  'williams-or': {
    name: 'Williams',
    postalCode: '97544',
  },
  'williams-bay-wi': {
    name: 'Williams Bay',
    postalCode: '53191',
  },
  'williamsburg-ia': {
    name: 'Williamsburg',
    postalCode: '52361',
  },
  'williamsburg-in': {
    name: 'Williamsburg',
    postalCode: '47393',
  },
  'williamsburg-ks': {
    name: 'Williamsburg',
    postalCode: '66095',
  },
  'williamsburg-ky': {
    name: 'Williamsburg',
    postalCode: '40769',
  },
  'williamsburg-ma': {
    name: 'Williamsburg',
    postalCode: '01096',
  },
  'williamsburg-mi': {
    name: 'Williamsburg',
    postalCode: '49690',
  },
  'williamsburg-mo': {
    name: 'Williamsburg',
    postalCode: '63388',
  },
  'williamsburg-nm': {
    name: 'Williamsburg',
    postalCode: '87942',
  },
  'williamsburg-oh': {
    name: 'Williamsburg',
    postalCode: '45176',
  },
  'williamsburg-pa': {
    name: 'Williamsburg',
    postalCode: '16693',
  },
  'williamsburg-va': {
    name: 'Williamsburg',
    postalCode: '23185',
  },
  'williamsburg-wv': {
    name: 'Williamsburg',
    postalCode: '24991',
  },
  'williamsfield-il': {
    name: 'Williamsfield',
    postalCode: '61489',
  },
  'williamsfield-oh': {
    name: 'Williamsfield',
    postalCode: '44093',
  },
  'williamson-ga': {
    name: 'Williamson',
    postalCode: '30292',
  },
  'williamson-ny': {
    name: 'Williamson',
    postalCode: '14589',
  },
  'williamson-wv': {
    name: 'Williamson',
    postalCode: '25661',
  },
  'williamsport-in': {
    name: 'Williamsport',
    postalCode: '47993',
  },
  'williamsport-ky': {
    name: 'Williamsport',
    postalCode: '41271',
  },
  'williamsport-md': {
    name: 'Williamsport',
    postalCode: '21795',
  },
  'williamsport-oh': {
    name: 'Williamsport',
    postalCode: '43164',
  },
  'williamsport-pa': {
    name: 'Williamsport',
    postalCode: '17701',
  },
  'williamsport-tn': {
    name: 'Williamsport',
    postalCode: '38487',
  },
  'williamston-mi': {
    name: 'Williamston',
    postalCode: '48895',
  },
  'williamston-nc': {
    name: 'Williamston',
    postalCode: '27892',
  },
  'williamston-sc': {
    name: 'Williamston',
    postalCode: '29697',
  },
  'williamstown-ky': {
    name: 'Williamstown',
    postalCode: '41097',
  },
  'williamstown-ma': {
    name: 'Williamstown',
    postalCode: '01267',
  },
  'williamstown-mo': {
    name: 'Williamstown',
    postalCode: '63473',
  },
  'williamstown-nj': {
    name: 'Williamstown',
    postalCode: '08094',
  },
  'williamstown-ny': {
    name: 'Williamstown',
    postalCode: '13493',
  },
  'williamstown-oh': {
    name: 'Williamstown',
    postalCode: '45897',
  },
  'williamstown-pa': {
    name: 'Williamstown',
    postalCode: '17098',
  },
  'williamstown-vt': {
    name: 'Williamstown',
    postalCode: '05679',
  },
  'williamstown-wv': {
    name: 'Williamstown',
    postalCode: '26187',
  },
  'williamsville-il': {
    name: 'Williamsville',
    postalCode: '62693',
  },
  'williamsville-mo': {
    name: 'Williamsville',
    postalCode: '63967',
  },
  'williamsville-va': {
    name: 'Williamsville',
    postalCode: '24487',
  },
  'williamsville-vt': {
    name: 'Williamsville',
    postalCode: '05362',
  },
  'williford-ar': {
    name: 'Williford',
    postalCode: '72482',
  },
  'willimantic-ct': {
    name: 'Willimantic',
    postalCode: '06226',
  },
  'willingboro-nj': {
    name: 'Willingboro',
    postalCode: '08046',
  },
  'willington-ct': {
    name: 'Willington',
    postalCode: '06279',
  },
  'willis-mi': {
    name: 'Willis',
    postalCode: '48191',
  },
  'willis-tx': {
    name: 'Willis',
    postalCode: '77318',
  },
  'willis-va': {
    name: 'Willis',
    postalCode: '24380',
  },
  'willisburg-ky': {
    name: 'Willisburg',
    postalCode: '40078',
  },
  'williston-fl': {
    name: 'Williston',
    postalCode: '32696',
  },
  'williston-nd': {
    name: 'Williston',
    postalCode: '58801',
  },
  'williston-sc': {
    name: 'Williston',
    postalCode: '29853',
  },
  'williston-tn': {
    name: 'Williston',
    postalCode: '38076',
  },
  'williston-vt': {
    name: 'Williston',
    postalCode: '05495',
  },
  'williston-park-ny': {
    name: 'Williston Park',
    postalCode: '11596',
  },
  'willits-ca': {
    name: 'Willits',
    postalCode: '95490',
  },
  'willmar-mn': {
    name: 'Willmar',
    postalCode: '56201',
  },
  'willoughby-oh': {
    name: 'Willoughby',
    postalCode: '44094',
  },
  'willow-ak': {
    name: 'Willow',
    postalCode: '99688',
  },
  'willow-ny': {
    name: 'Willow',
    postalCode: '12495',
  },
  'willow-ok': {
    name: 'Willow',
    postalCode: '73673',
  },
  'willow-beach-az': {
    name: 'Willow Beach',
    postalCode: '86445',
  },
  'willow-city-nd': {
    name: 'Willow City',
    postalCode: '58384',
  },
  'willow-city-tx': {
    name: 'Willow City',
    postalCode: '78675',
  },
  'willow-creek-ca': {
    name: 'Willow Creek',
    postalCode: '95573',
  },
  'willow-grove-pa': {
    name: 'Willow Grove',
    postalCode: '19090',
  },
  'willow-hill-il': {
    name: 'Willow Hill',
    postalCode: '62480',
  },
  'willow-hill-pa': {
    name: 'Willow Hill',
    postalCode: '17271',
  },
  'willow-island-ne': {
    name: 'Willow Island',
    postalCode: '69171',
  },
  'willow-lake-sd': {
    name: 'Willow Lake',
    postalCode: '57278',
  },
  'willow-river-mn': {
    name: 'Willow River',
    postalCode: '55795',
  },
  'willow-spring-nc': {
    name: 'Willow Spring',
    postalCode: '27592',
  },
  'willow-springs-il': {
    name: 'Willow Springs',
    postalCode: '60480',
  },
  'willow-springs-mo': {
    name: 'Willow Springs',
    postalCode: '65793',
  },
  'willow-street-pa': {
    name: 'Willow Street',
    postalCode: '17584',
  },
  'willow-wood-oh': {
    name: 'Willow Wood',
    postalCode: '45696',
  },
  'willowbrook-il': {
    name: 'Willowbrook',
    postalCode: '60527',
  },
  'willows-ca': {
    name: 'Willows',
    postalCode: '95988',
  },
  'wills-point-tx': {
    name: 'Wills Point',
    postalCode: '75169',
  },
  'willsboro-ny': {
    name: 'Willsboro',
    postalCode: '12996',
  },
  'willseyville-ny': {
    name: 'Willseyville',
    postalCode: '13864',
  },
  'willshire-oh': {
    name: 'Willshire',
    postalCode: '45898',
  },
  'wilmar-ar': {
    name: 'Wilmar',
    postalCode: '71675',
  },
  'wilmer-al': {
    name: 'Wilmer',
    postalCode: '36587',
  },
  'wilmer-tx': {
    name: 'Wilmer',
    postalCode: '75172',
  },
  'wilmerding-pa': {
    name: 'Wilmerding',
    postalCode: '15148',
  },
  'wilmette-il': {
    name: 'Wilmette',
    postalCode: '60091',
  },
  'wilmington-ca': {
    name: 'Wilmington',
    postalCode: '90744',
  },
  'wilmington-de': {
    name: 'Wilmington',
    postalCode: '19801',
  },
  'wilmington-il': {
    name: 'Wilmington',
    postalCode: '60481',
  },
  'wilmington-ma': {
    name: 'Wilmington',
    postalCode: '01887',
  },
  'wilmington-nc': {
    name: 'Wilmington',
    postalCode: '28401',
  },
  'wilmington-ny': {
    name: 'Wilmington',
    postalCode: '12997',
  },
  'wilmington-oh': {
    name: 'Wilmington',
    postalCode: '45177',
  },
  'wilmington-vt': {
    name: 'Wilmington',
    postalCode: '05363',
  },
  'wilmont-mn': {
    name: 'Wilmont',
    postalCode: '56185',
  },
  'wilmore-ks': {
    name: 'Wilmore',
    postalCode: '67155',
  },
  'wilmore-ky': {
    name: 'Wilmore',
    postalCode: '40390',
  },
  'wilmot-ar': {
    name: 'Wilmot',
    postalCode: '71676',
  },
  'wilmot-nh': {
    name: 'Wilmot',
    postalCode: '03287',
  },
  'wilmot-oh': {
    name: 'Wilmot',
    postalCode: '44689',
  },
  'wilmot-sd': {
    name: 'Wilmot',
    postalCode: '57279',
  },
  'wilsall-mt': {
    name: 'Wilsall',
    postalCode: '59086',
  },
  'wilsey-ks': {
    name: 'Wilsey',
    postalCode: '66873',
  },
  'wilseyville-ca': {
    name: 'Wilseyville',
    postalCode: '95257',
  },
  'wilson-ar': {
    name: 'Wilson',
    postalCode: '72395',
  },
  'wilson-ks': {
    name: 'Wilson',
    postalCode: '67490',
  },
  'wilson-la': {
    name: 'Wilson',
    postalCode: '70789',
  },
  'wilson-mi': {
    name: 'Wilson',
    postalCode: '49896',
  },
  'wilson-nc': {
    name: 'Wilson',
    postalCode: '27893',
  },
  'wilson-ny': {
    name: 'Wilson',
    postalCode: '14172',
  },
  'wilson-ok': {
    name: 'Wilson',
    postalCode: '73463',
  },
  'wilson-tx': {
    name: 'Wilson',
    postalCode: '79381',
  },
  'wilson-wi': {
    name: 'Wilson',
    postalCode: '54027',
  },
  'wilson-wy': {
    name: 'Wilson',
    postalCode: '83014',
  },
  'wilsonburg-wv': {
    name: 'Wilsonburg',
    postalCode: '26461',
  },
  'wilsondale-wv': {
    name: 'Wilsondale',
    postalCode: '25699',
  },
  'wilsons-va': {
    name: 'Wilsons',
    postalCode: '23894',
  },
  'wilsonville-al': {
    name: 'Wilsonville',
    postalCode: '35186',
  },
  'wilsonville-ne': {
    name: 'Wilsonville',
    postalCode: '69046',
  },
  'wilsonville-or': {
    name: 'Wilsonville',
    postalCode: '97070',
  },
  'wilton-ca': {
    name: 'Wilton',
    postalCode: '95693',
  },
  'wilton-ct': {
    name: 'Wilton',
    postalCode: '06897',
  },
  'wilton-ia': {
    name: 'Wilton',
    postalCode: '52778',
  },
  'wilton-me': {
    name: 'Wilton',
    postalCode: '04294',
  },
  'wilton-nd': {
    name: 'Wilton',
    postalCode: '58579',
  },
  'wilton-nh': {
    name: 'Wilton',
    postalCode: '03086',
  },
  'wilton-wi': {
    name: 'Wilton',
    postalCode: '54670',
  },
  'wimauma-fl': {
    name: 'Wimauma',
    postalCode: '33598',
  },
  'wimberley-tx': {
    name: 'Wimberley',
    postalCode: '78676',
  },
  'wimbledon-nd': {
    name: 'Wimbledon',
    postalCode: '58492',
  },
  'winamac-in': {
    name: 'Winamac',
    postalCode: '46996',
  },
  'winburne-pa': {
    name: 'Winburne',
    postalCode: '16879',
  },
  'winchendon-ma': {
    name: 'Winchendon',
    postalCode: '01475',
  },
  'winchester-ca': {
    name: 'Winchester',
    postalCode: '92596',
  },
  'winchester-id': {
    name: 'Winchester',
    postalCode: '83555',
  },
  'winchester-il': {
    name: 'Winchester',
    postalCode: '62694',
  },
  'winchester-in': {
    name: 'Winchester',
    postalCode: '47394',
  },
  'winchester-ks': {
    name: 'Winchester',
    postalCode: '66097',
  },
  'winchester-ky': {
    name: 'Winchester',
    postalCode: '40391',
  },
  'winchester-ma': {
    name: 'Winchester',
    postalCode: '01890',
  },
  'winchester-nh': {
    name: 'Winchester',
    postalCode: '03470',
  },
  'winchester-oh': {
    name: 'Winchester',
    postalCode: '45697',
  },
  'winchester-or': {
    name: 'Winchester',
    postalCode: '97495',
  },
  'winchester-tn': {
    name: 'Winchester',
    postalCode: '37398',
  },
  'winchester-va': {
    name: 'Winchester',
    postalCode: '22601',
  },
  'wind-gap-pa': {
    name: 'Wind Gap',
    postalCode: '18091',
  },
  'wind-ridge-pa': {
    name: 'Wind Ridge',
    postalCode: '15380',
  },
  'windber-pa': {
    name: 'Windber',
    postalCode: '15963',
  },
  'winder-ga': {
    name: 'Winder',
    postalCode: '30680',
  },
  'windermere-fl': {
    name: 'Windermere',
    postalCode: '34786',
  },
  'windfall-in': {
    name: 'Windfall',
    postalCode: '46076',
  },
  'windham-ct': {
    name: 'Windham',
    postalCode: '06280',
  },
  'windham-me': {
    name: 'Windham',
    postalCode: '04062',
  },
  'windham-nh': {
    name: 'Windham',
    postalCode: '03087',
  },
  'windham-ny': {
    name: 'Windham',
    postalCode: '12496',
  },
  'windham-oh': {
    name: 'Windham',
    postalCode: '44288',
  },
  'windom-ks': {
    name: 'Windom',
    postalCode: '67491',
  },
  'windom-mn': {
    name: 'Windom',
    postalCode: '56101',
  },
  'windom-tx': {
    name: 'Windom',
    postalCode: '75492',
  },
  'windsor-ca': {
    name: 'Windsor',
    postalCode: '95492',
  },
  'windsor-co': {
    name: 'Windsor',
    postalCode: '80550',
  },
  'windsor-ct': {
    name: 'Windsor',
    postalCode: '06095',
  },
  'windsor-il': {
    name: 'Windsor',
    postalCode: '61957',
  },
  'windsor-ky': {
    name: 'Windsor',
    postalCode: '42565',
  },
  'windsor-ma': {
    name: 'Windsor',
    postalCode: '01270',
  },
  'windsor-me': {
    name: 'Windsor',
    postalCode: '04363',
  },
  'windsor-mo': {
    name: 'Windsor',
    postalCode: '65360',
  },
  'windsor-nc': {
    name: 'Windsor',
    postalCode: '27983',
  },
  'windsor-ny': {
    name: 'Windsor',
    postalCode: '13865',
  },
  'windsor-oh': {
    name: 'Windsor',
    postalCode: '44099',
  },
  'windsor-pa': {
    name: 'Windsor',
    postalCode: '17366',
  },
  'windsor-sc': {
    name: 'Windsor',
    postalCode: '29856',
  },
  'windsor-va': {
    name: 'Windsor',
    postalCode: '23487',
  },
  'windsor-vt': {
    name: 'Windsor',
    postalCode: '05089',
  },
  'windsor-wi': {
    name: 'Windsor',
    postalCode: '53598',
  },
  'windsor-heights-ia': {
    name: 'Windsor Heights',
    postalCode: '50324',
  },
  'windsor-locks-ct': {
    name: 'Windsor Locks',
    postalCode: '06096',
  },
  'windsor-mill-md': {
    name: 'Windsor Mill',
    postalCode: '21244',
  },
  'windthorst-tx': {
    name: 'Windthorst',
    postalCode: '76389',
  },
  'windyville-mo': {
    name: 'Windyville',
    postalCode: '65783',
  },
  'winfield-al': {
    name: 'Winfield',
    postalCode: '35594',
  },
  'winfield-ia': {
    name: 'Winfield',
    postalCode: '52659',
  },
  'winfield-il': {
    name: 'Winfield',
    postalCode: '60190',
  },
  'winfield-ks': {
    name: 'Winfield',
    postalCode: '67156',
  },
  'winfield-mo': {
    name: 'Winfield',
    postalCode: '63389',
  },
  'winfield-pa': {
    name: 'Winfield',
    postalCode: '17889',
  },
  'winfield-tn': {
    name: 'Winfield',
    postalCode: '37892',
  },
  'winfield-wv': {
    name: 'Winfield',
    postalCode: '25213',
  },
  'winfred-sd': {
    name: 'Winfred',
    postalCode: '57076',
  },
  'wing-al': {
    name: 'Wing',
    postalCode: '36483',
  },
  'wing-nd': {
    name: 'Wing',
    postalCode: '58494',
  },
  'wingate-in': {
    name: 'Wingate',
    postalCode: '47994',
  },
  'wingate-md': {
    name: 'Wingate',
    postalCode: '21675',
  },
  'wingate-nc': {
    name: 'Wingate',
    postalCode: '28174',
  },
  'wingate-tx': {
    name: 'Wingate',
    postalCode: '79566',
  },
  'wingdale-ny': {
    name: 'Wingdale',
    postalCode: '12594',
  },
  'winger-mn': {
    name: 'Winger',
    postalCode: '56592',
  },
  'wingett-run-oh': {
    name: 'Wingett Run',
    postalCode: '45789',
  },
  'wingina-va': {
    name: 'Wingina',
    postalCode: '24599',
  },
  'wingo-ky': {
    name: 'Wingo',
    postalCode: '42088',
  },
  'winifred-mt': {
    name: 'Winifred',
    postalCode: '59489',
  },
  'winifrede-wv': {
    name: 'Winifrede',
    postalCode: '25214',
  },
  'winigan-mo': {
    name: 'Winigan',
    postalCode: '63566',
  },
  'winkelman-az': {
    name: 'Winkelman',
    postalCode: '85192',
  },
  'winlock-wa': {
    name: 'Winlock',
    postalCode: '98596',
  },
  'winn-me': {
    name: 'Winn',
    postalCode: '04495',
  },
  'winnabow-nc': {
    name: 'Winnabow',
    postalCode: '28479',
  },
  'winnebago-il': {
    name: 'Winnebago',
    postalCode: '61088',
  },
  'winnebago-mn': {
    name: 'Winnebago',
    postalCode: '56098',
  },
  'winnebago-ne': {
    name: 'Winnebago',
    postalCode: '68071',
  },
  'winneconne-wi': {
    name: 'Winneconne',
    postalCode: '54986',
  },
  'winnemucca-nv': {
    name: 'Winnemucca',
    postalCode: '89445',
  },
  'winner-sd': {
    name: 'Winner',
    postalCode: '57580',
  },
  'winnetka-ca': {
    name: 'Winnetka',
    postalCode: '91306',
  },
  'winnetka-il': {
    name: 'Winnetka',
    postalCode: '60093',
  },
  'winnetoon-ne': {
    name: 'Winnetoon',
    postalCode: '68789',
  },
  'winnett-mt': {
    name: 'Winnett',
    postalCode: '59087',
  },
  'winnfield-la': {
    name: 'Winnfield',
    postalCode: '71483',
  },
  'winnie-tx': {
    name: 'Winnie',
    postalCode: '77665',
  },
  'winnsboro-la': {
    name: 'Winnsboro',
    postalCode: '71295',
  },
  'winnsboro-sc': {
    name: 'Winnsboro',
    postalCode: '29180',
  },
  'winnsboro-tx': {
    name: 'Winnsboro',
    postalCode: '75494',
  },
  'winona-ks': {
    name: 'Winona',
    postalCode: '67764',
  },
  'winona-mn': {
    name: 'Winona',
    postalCode: '55987',
  },
  'winona-mo': {
    name: 'Winona',
    postalCode: '65588',
  },
  'winona-ms': {
    name: 'Winona',
    postalCode: '38967',
  },
  'winona-tx': {
    name: 'Winona',
    postalCode: '75792',
  },
  'winona-lake-in': {
    name: 'Winona Lake',
    postalCode: '46590',
  },
  'winooski-vt': {
    name: 'Winooski',
    postalCode: '05404',
  },
  'winside-ne': {
    name: 'Winside',
    postalCode: '68790',
  },
  'winslow-ar': {
    name: 'Winslow',
    postalCode: '72959',
  },
  'winslow-az': {
    name: 'Winslow',
    postalCode: '86047',
  },
  'winslow-il': {
    name: 'Winslow',
    postalCode: '61089',
  },
  'winslow-in': {
    name: 'Winslow',
    postalCode: '47598',
  },
  'winsted-ct': {
    name: 'Winsted',
    postalCode: '06098',
  },
  'winsted-mn': {
    name: 'Winsted',
    postalCode: '55395',
  },
  'winston-ga': {
    name: 'Winston',
    postalCode: '30187',
  },
  'winston-mo': {
    name: 'Winston',
    postalCode: '64689',
  },
  'winston-mt': {
    name: 'Winston',
    postalCode: '59647',
  },
  'winston-nm': {
    name: 'Winston',
    postalCode: '87943',
  },
  'winston-or': {
    name: 'Winston',
    postalCode: '97496',
  },
  'winston-salem-nc': {
    name: 'Winston-salem',
    postalCode: '27101',
  },
  'winter-wi': {
    name: 'Winter',
    postalCode: '54896',
  },
  'winter-garden-fl': {
    name: 'Winter Garden',
    postalCode: '34787',
  },
  'winter-harbor-me': {
    name: 'Winter Harbor',
    postalCode: '04693',
  },
  'winter-haven-fl': {
    name: 'Winter Haven',
    postalCode: '33880',
  },
  'winter-park-fl': {
    name: 'Winter Park',
    postalCode: '32789',
  },
  'winter-springs-fl': {
    name: 'Winter Springs',
    postalCode: '32708',
  },
  'winterhaven-ca': {
    name: 'Winterhaven',
    postalCode: '92283',
  },
  'winterport-me': {
    name: 'Winterport',
    postalCode: '04496',
  },
  'winters-ca': {
    name: 'Winters',
    postalCode: '95694',
  },
  'winters-tx': {
    name: 'Winters',
    postalCode: '79567',
  },
  'winterset-ia': {
    name: 'Winterset',
    postalCode: '50273',
  },
  'winterthur-de': {
    name: 'Winterthur',
    postalCode: '19735',
  },
  'winterville-ga': {
    name: 'Winterville',
    postalCode: '30683',
  },
  'winterville-nc': {
    name: 'Winterville',
    postalCode: '28590',
  },
  'winthrop-ar': {
    name: 'Winthrop',
    postalCode: '71866',
  },
  'winthrop-ia': {
    name: 'Winthrop',
    postalCode: '50682',
  },
  'winthrop-ma': {
    name: 'Winthrop',
    postalCode: '02152',
  },
  'winthrop-me': {
    name: 'Winthrop',
    postalCode: '04364',
  },
  'winthrop-mn': {
    name: 'Winthrop',
    postalCode: '55396',
  },
  'winthrop-ny': {
    name: 'Winthrop',
    postalCode: '13697',
  },
  'winthrop-wa': {
    name: 'Winthrop',
    postalCode: '98862',
  },
  'winthrop-harbor-il': {
    name: 'Winthrop Harbor',
    postalCode: '60096',
  },
  'winton-ca': {
    name: 'Winton',
    postalCode: '95388',
  },
  'winton-mn': {
    name: 'Winton',
    postalCode: '55796',
  },
  'winton-nc': {
    name: 'Winton',
    postalCode: '27986',
  },
  'wiota-ia': {
    name: 'Wiota',
    postalCode: '50274',
  },
  'wirt-mn': {
    name: 'Wirt',
    postalCode: '56688',
  },
  'wirtz-va': {
    name: 'Wirtz',
    postalCode: '24184',
  },
  'wiscasset-me': {
    name: 'Wiscasset',
    postalCode: '04578',
  },
  'wisconsin-dells-wi': {
    name: 'Wisconsin Dells',
    postalCode: '53965',
  },
  'wisconsin-rapids-wi': {
    name: 'Wisconsin Rapids',
    postalCode: '54494',
  },
  'wisdom-mt': {
    name: 'Wisdom',
    postalCode: '59761',
  },
  'wise-va': {
    name: 'Wise',
    postalCode: '24293',
  },
  'wise-river-mt': {
    name: 'Wise River',
    postalCode: '59762',
  },
  'wiseman-ar': {
    name: 'Wiseman',
    postalCode: '72587',
  },
  'wishek-nd': {
    name: 'Wishek',
    postalCode: '58495',
  },
  'wishon-ca': {
    name: 'Wishon',
    postalCode: '93669',
  },
  'wisner-la': {
    name: 'Wisner',
    postalCode: '71378',
  },
  'wisner-ne': {
    name: 'Wisner',
    postalCode: '68791',
  },
  'wister-ok': {
    name: 'Wister',
    postalCode: '74966',
  },
  'withams-va': {
    name: 'Withams',
    postalCode: '23488',
  },
  'withee-wi': {
    name: 'Withee',
    postalCode: '54498',
  },
  'witherbee-ny': {
    name: 'Witherbee',
    postalCode: '12998',
  },
  'witt-il': {
    name: 'Witt',
    postalCode: '62094',
  },
  'witten-sd': {
    name: 'Witten',
    postalCode: '57584',
  },
  'wittenberg-wi': {
    name: 'Wittenberg',
    postalCode: '54499',
  },
  'wittensville-ky': {
    name: 'Wittensville',
    postalCode: '41274',
  },
  'witter-ar': {
    name: 'Witter',
    postalCode: '72776',
  },
  'witter-springs-ca': {
    name: 'Witter Springs',
    postalCode: '95493',
  },
  'wittman-md': {
    name: 'Wittman',
    postalCode: '21676',
  },
  'wittmann-az': {
    name: 'Wittmann',
    postalCode: '85361',
  },
  'witts-springs-ar': {
    name: 'Witts Springs',
    postalCode: '72686',
  },
  'wixom-mi': {
    name: 'Wixom',
    postalCode: '48393',
  },
  'woburn-ma': {
    name: 'Woburn',
    postalCode: '01801',
  },
  'woden-ia': {
    name: 'Woden',
    postalCode: '50484',
  },
  'wofford-heights-ca': {
    name: 'Wofford Heights',
    postalCode: '93285',
  },
  'wolbach-ne': {
    name: 'Wolbach',
    postalCode: '68882',
  },
  'wolcott-ct': {
    name: 'Wolcott',
    postalCode: '06716',
  },
  'wolcott-in': {
    name: 'Wolcott',
    postalCode: '47995',
  },
  'wolcott-ny': {
    name: 'Wolcott',
    postalCode: '14590',
  },
  'wolcott-vt': {
    name: 'Wolcott',
    postalCode: '05680',
  },
  'wolcottville-in': {
    name: 'Wolcottville',
    postalCode: '46795',
  },
  'wolf-wy': {
    name: 'Wolf',
    postalCode: '82844',
  },
  'wolf-creek-mt': {
    name: 'Wolf Creek',
    postalCode: '59648',
  },
  'wolf-creek-or': {
    name: 'Wolf Creek',
    postalCode: '97497',
  },
  'wolf-lake-il': {
    name: 'Wolf Lake',
    postalCode: '62998',
  },
  'wolf-point-mt': {
    name: 'Wolf Point',
    postalCode: '59201',
  },
  'wolfcreek-wv': {
    name: 'Wolfcreek',
    postalCode: '24993',
  },
  'wolfe-city-tx': {
    name: 'Wolfe City',
    postalCode: '75496',
  },
  'wolfeboro-nh': {
    name: 'Wolfeboro',
    postalCode: '03894',
  },
  'wolfforth-tx': {
    name: 'Wolfforth',
    postalCode: '79382',
  },
  'wolford-nd': {
    name: 'Wolford',
    postalCode: '58385',
  },
  'wolsey-sd': {
    name: 'Wolsey',
    postalCode: '57384',
  },
  'wolverine-mi': {
    name: 'Wolverine',
    postalCode: '49799',
  },
  'wolverton-mn': {
    name: 'Wolverton',
    postalCode: '56594',
  },
  'womelsdorf-pa': {
    name: 'Womelsdorf',
    postalCode: '19567',
  },
  'wonalancet-nh': {
    name: 'Wonalancet',
    postalCode: '03897',
  },
  'wonder-lake-il': {
    name: 'Wonder Lake',
    postalCode: '60097',
  },
  'wonewoc-wi': {
    name: 'Wonewoc',
    postalCode: '53968',
  },
  'wood-sd': {
    name: 'Wood',
    postalCode: '57585',
  },
  'wood-dale-il': {
    name: 'Wood Dale',
    postalCode: '60191',
  },
  'wood-lake-mn': {
    name: 'Wood Lake',
    postalCode: '56297',
  },
  'wood-lake-ne': {
    name: 'Wood Lake',
    postalCode: '69221',
  },
  'wood-ridge-nj': {
    name: 'Wood Ridge',
    postalCode: '07075',
  },
  'wood-river-il': {
    name: 'Wood River',
    postalCode: '62095',
  },
  'wood-river-ne': {
    name: 'Wood River',
    postalCode: '68883',
  },
  'wood-river-junction-ri': {
    name: 'Wood River Junction',
    postalCode: '02894',
  },
  'woodbine-ga': {
    name: 'Woodbine',
    postalCode: '31569',
  },
  'woodbine-ia': {
    name: 'Woodbine',
    postalCode: '51579',
  },
  'woodbine-ks': {
    name: 'Woodbine',
    postalCode: '67492',
  },
  'woodbine-ky': {
    name: 'Woodbine',
    postalCode: '40771',
  },
  'woodbine-md': {
    name: 'Woodbine',
    postalCode: '21797',
  },
  'woodbine-nj': {
    name: 'Woodbine',
    postalCode: '08270',
  },
  'woodbourne-ny': {
    name: 'Woodbourne',
    postalCode: '12788',
  },
  'woodbridge-ca': {
    name: 'Woodbridge',
    postalCode: '95258',
  },
  'woodbridge-ct': {
    name: 'Woodbridge',
    postalCode: '06525',
  },
  'woodbridge-nj': {
    name: 'Woodbridge',
    postalCode: '07095',
  },
  'woodbridge-va': {
    name: 'Woodbridge',
    postalCode: '22191',
  },
  'woodburn-ia': {
    name: 'Woodburn',
    postalCode: '50275',
  },
  'woodburn-in': {
    name: 'Woodburn',
    postalCode: '46797',
  },
  'woodburn-ky': {
    name: 'Woodburn',
    postalCode: '42170',
  },
  'woodburn-or': {
    name: 'Woodburn',
    postalCode: '97071',
  },
  'woodbury-ct': {
    name: 'Woodbury',
    postalCode: '06798',
  },
  'woodbury-ga': {
    name: 'Woodbury',
    postalCode: '30293',
  },
  'woodbury-nj': {
    name: 'Woodbury',
    postalCode: '08096',
  },
  'woodbury-ny': {
    name: 'Woodbury',
    postalCode: '11797',
  },
  'woodbury-pa': {
    name: 'Woodbury',
    postalCode: '16695',
  },
  'woodbury-tn': {
    name: 'Woodbury',
    postalCode: '37190',
  },
  'woodbury-vt': {
    name: 'Woodbury',
    postalCode: '05681',
  },
  'woodbury-heights-nj': {
    name: 'Woodbury Heights',
    postalCode: '08097',
  },
  'woodcliff-lake-nj': {
    name: 'Woodcliff Lake',
    postalCode: '07677',
  },
  'woodford-va': {
    name: 'Woodford',
    postalCode: '22580',
  },
  'woodgate-ny': {
    name: 'Woodgate',
    postalCode: '13494',
  },
  'woodhaven-ny': {
    name: 'Woodhaven',
    postalCode: '11421',
  },
  'woodhull-il': {
    name: 'Woodhull',
    postalCode: '61490',
  },
  'woodhull-ny': {
    name: 'Woodhull',
    postalCode: '14898',
  },
  'woodinville-wa': {
    name: 'Woodinville',
    postalCode: '98072',
  },
  'woodlake-ca': {
    name: 'Woodlake',
    postalCode: '93286',
  },
  'woodland-al': {
    name: 'Woodland',
    postalCode: '36280',
  },
  'woodland-ca': {
    name: 'Woodland',
    postalCode: '95695',
  },
  'woodland-ga': {
    name: 'Woodland',
    postalCode: '31836',
  },
  'woodland-mi': {
    name: 'Woodland',
    postalCode: '48897',
  },
  'woodland-ms': {
    name: 'Woodland',
    postalCode: '39776',
  },
  'woodland-nc': {
    name: 'Woodland',
    postalCode: '27897',
  },
  'woodland-pa': {
    name: 'Woodland',
    postalCode: '16881',
  },
  'woodland-wa': {
    name: 'Woodland',
    postalCode: '98674',
  },
  'woodland-wi': {
    name: 'Woodland',
    postalCode: '53099',
  },
  'woodland-hills-ca': {
    name: 'Woodland Hills',
    postalCode: '91364',
  },
  'woodland-park-co': {
    name: 'Woodland Park',
    postalCode: '80863',
  },
  'woodlawn-il': {
    name: 'Woodlawn',
    postalCode: '62898',
  },
  'woodlawn-tn': {
    name: 'Woodlawn',
    postalCode: '37191',
  },
  'woodlawn-va': {
    name: 'Woodlawn',
    postalCode: '24381',
  },
  'woodleaf-nc': {
    name: 'Woodleaf',
    postalCode: '27054',
  },
  'woodlyn-pa': {
    name: 'Woodlyn',
    postalCode: '19094',
  },
  'woodman-wi': {
    name: 'Woodman',
    postalCode: '53827',
  },
  'woodmere-ny': {
    name: 'Woodmere',
    postalCode: '11598',
  },
  'woodridge-il': {
    name: 'Woodridge',
    postalCode: '60517',
  },
  'woodridge-ny': {
    name: 'Woodridge',
    postalCode: '12789',
  },
  'woodrow-co': {
    name: 'Woodrow',
    postalCode: '80757',
  },
  'woodruff-sc': {
    name: 'Woodruff',
    postalCode: '29388',
  },
  'woodruff-ut': {
    name: 'Woodruff',
    postalCode: '84086',
  },
  'woodruff-wi': {
    name: 'Woodruff',
    postalCode: '54568',
  },
  'woods-cross-ut': {
    name: 'Woods Cross',
    postalCode: '84087',
  },
  'woods-hole-ma': {
    name: 'Woods Hole',
    postalCode: '02543',
  },
  'woodsboro-md': {
    name: 'Woodsboro',
    postalCode: '21798',
  },
  'woodsboro-tx': {
    name: 'Woodsboro',
    postalCode: '78393',
  },
  'woodsfield-oh': {
    name: 'Woodsfield',
    postalCode: '43793',
  },
  'woodside-ny': {
    name: 'Woodside',
    postalCode: '11377',
  },
  'woodson-tx': {
    name: 'Woodson',
    postalCode: '76491',
  },
  'woodstock-al': {
    name: 'Woodstock',
    postalCode: '35188',
  },
  'woodstock-ct': {
    name: 'Woodstock',
    postalCode: '06281',
  },
  'woodstock-ga': {
    name: 'Woodstock',
    postalCode: '30188',
  },
  'woodstock-il': {
    name: 'Woodstock',
    postalCode: '60098',
  },
  'woodstock-md': {
    name: 'Woodstock',
    postalCode: '21163',
  },
  'woodstock-mn': {
    name: 'Woodstock',
    postalCode: '56186',
  },
  'woodstock-ny': {
    name: 'Woodstock',
    postalCode: '12498',
  },
  'woodstock-oh': {
    name: 'Woodstock',
    postalCode: '43084',
  },
  'woodstock-va': {
    name: 'Woodstock',
    postalCode: '22664',
  },
  'woodstock-vt': {
    name: 'Woodstock',
    postalCode: '05091',
  },
  'woodstock-valley-ct': {
    name: 'Woodstock Valley',
    postalCode: '06282',
  },
  'woodston-ks': {
    name: 'Woodston',
    postalCode: '67675',
  },
  'woodstown-nj': {
    name: 'Woodstown',
    postalCode: '08098',
  },
  'woodsville-nh': {
    name: 'Woodsville',
    postalCode: '03785',
  },
  'woodville-al': {
    name: 'Woodville',
    postalCode: '35776',
  },
  'woodville-ms': {
    name: 'Woodville',
    postalCode: '39669',
  },
  'woodville-oh': {
    name: 'Woodville',
    postalCode: '43469',
  },
  'woodville-tx': {
    name: 'Woodville',
    postalCode: '75979',
  },
  'woodville-va': {
    name: 'Woodville',
    postalCode: '22749',
  },
  'woodville-wi': {
    name: 'Woodville',
    postalCode: '54028',
  },
  'woodville-wv': {
    name: 'Woodville',
    postalCode: '25572',
  },
  'woodward-ia': {
    name: 'Woodward',
    postalCode: '50276',
  },
  'woodward-ok': {
    name: 'Woodward',
    postalCode: '73801',
  },
  'woodward-pa': {
    name: 'Woodward',
    postalCode: '16882',
  },
  'woodway-tx': {
    name: 'Woodway',
    postalCode: '76712',
  },
  'woodworth-la': {
    name: 'Woodworth',
    postalCode: '71485',
  },
  'woodworth-nd': {
    name: 'Woodworth',
    postalCode: '58496',
  },
  'woody-ca': {
    name: 'Woody',
    postalCode: '93287',
  },
  'wooldridge-mo': {
    name: 'Wooldridge',
    postalCode: '65287',
  },
  'woolford-md': {
    name: 'Woolford',
    postalCode: '21677',
  },
  'woollum-ky': {
    name: 'Woollum',
    postalCode: '40999',
  },
  'woolstock-ia': {
    name: 'Woolstock',
    postalCode: '50599',
  },
  'woolwich-me': {
    name: 'Woolwich',
    postalCode: '04579',
  },
  'woolwine-va': {
    name: 'Woolwine',
    postalCode: '24185',
  },
  'woonsocket-ri': {
    name: 'Woonsocket',
    postalCode: '02895',
  },
  'woonsocket-sd': {
    name: 'Woonsocket',
    postalCode: '57385',
  },
  'wooster-ar': {
    name: 'Wooster',
    postalCode: '72181',
  },
  'wooster-oh': {
    name: 'Wooster',
    postalCode: '44691',
  },
  'wooton-ky': {
    name: 'Wooton',
    postalCode: '41776',
  },
  'worcester-ma': {
    name: 'Worcester',
    postalCode: '01602',
  },
  'worcester-ny': {
    name: 'Worcester',
    postalCode: '12197',
  },
  'worcester-vt': {
    name: 'Worcester',
    postalCode: '05682',
  },
  'worden-il': {
    name: 'Worden',
    postalCode: '62097',
  },
  'worden-mt': {
    name: 'Worden',
    postalCode: '59088',
  },
  'worland-wy': {
    name: 'Worland',
    postalCode: '82401',
  },
  'worley-id': {
    name: 'Worley',
    postalCode: '83876',
  },
  'worth-il': {
    name: 'Worth',
    postalCode: '60482',
  },
  'worth-mo': {
    name: 'Worth',
    postalCode: '64499',
  },
  'wortham-tx': {
    name: 'Wortham',
    postalCode: '76693',
  },
  'worthing-sd': {
    name: 'Worthing',
    postalCode: '57077',
  },
  'worthington-ia': {
    name: 'Worthington',
    postalCode: '52078',
  },
  'worthington-in': {
    name: 'Worthington',
    postalCode: '47471',
  },
  'worthington-ky': {
    name: 'Worthington',
    postalCode: '41183',
  },
  'worthington-ma': {
    name: 'Worthington',
    postalCode: '01098',
  },
  'worthington-mn': {
    name: 'Worthington',
    postalCode: '56187',
  },
  'worthington-mo': {
    name: 'Worthington',
    postalCode: '63567',
  },
  'worthington-pa': {
    name: 'Worthington',
    postalCode: '16262',
  },
  'worthington-wv': {
    name: 'Worthington',
    postalCode: '26591',
  },
  'worthville-ky': {
    name: 'Worthville',
    postalCode: '41098',
  },
  'worthville-pa': {
    name: 'Worthville',
    postalCode: '15784',
  },
  'worton-md': {
    name: 'Worton',
    postalCode: '21678',
  },
  'wounded-knee-sd': {
    name: 'Wounded Knee',
    postalCode: '57794',
  },
  'wray-co': {
    name: 'Wray',
    postalCode: '80758',
  },
  'wray-ga': {
    name: 'Wray',
    postalCode: '31798',
  },
  'wrens-ga': {
    name: 'Wrens',
    postalCode: '30833',
  },
  'wrenshall-mn': {
    name: 'Wrenshall',
    postalCode: '55797',
  },
  'wrentham-ma': {
    name: 'Wrentham',
    postalCode: '02093',
  },
  'wright-ks': {
    name: 'Wright',
    postalCode: '67882',
  },
  'wright-mn': {
    name: 'Wright',
    postalCode: '55798',
  },
  'wright-city-mo': {
    name: 'Wright City',
    postalCode: '63390',
  },
  'wright-city-ok': {
    name: 'Wright City',
    postalCode: '74766',
  },
  'wrightsboro-tx': {
    name: 'Wrightsboro',
    postalCode: '78677',
  },
  'wrightstown-nj': {
    name: 'Wrightstown',
    postalCode: '08562',
  },
  'wrightstown-wi': {
    name: 'Wrightstown',
    postalCode: '54180',
  },
  'wrightsville-ga': {
    name: 'Wrightsville',
    postalCode: '31096',
  },
  'wrightsville-pa': {
    name: 'Wrightsville',
    postalCode: '17368',
  },
  'wrightsville-beach-nc': {
    name: 'Wrightsville Beach',
    postalCode: '28480',
  },
  'wrightwood-ca': {
    name: 'Wrightwood',
    postalCode: '92397',
  },
  'wurtsboro-ny': {
    name: 'Wurtsboro',
    postalCode: '12790',
  },
  'wyaconda-mo': {
    name: 'Wyaconda',
    postalCode: '63474',
  },
  'wyalusing-pa': {
    name: 'Wyalusing',
    postalCode: '18853',
  },
  'wyandanch-ny': {
    name: 'Wyandanch',
    postalCode: '11798',
  },
  'wyandotte-mi': {
    name: 'Wyandotte',
    postalCode: '48192',
  },
  'wyandotte-ok': {
    name: 'Wyandotte',
    postalCode: '74370',
  },
  'wyanet-il': {
    name: 'Wyanet',
    postalCode: '61379',
  },
  'wyckoff-nj': {
    name: 'Wyckoff',
    postalCode: '07481',
  },
  'wye-mills-md': {
    name: 'Wye Mills',
    postalCode: '21679',
  },
  'wykoff-mn': {
    name: 'Wykoff',
    postalCode: '55990',
  },
  'wylie-tx': {
    name: 'Wylie',
    postalCode: '75098',
  },
  'wylliesburg-va': {
    name: 'Wylliesburg',
    postalCode: '23976',
  },
  'wymore-ne': {
    name: 'Wymore',
    postalCode: '68466',
  },
  'wynantskill-ny': {
    name: 'Wynantskill',
    postalCode: '12198',
  },
  'wyncote-pa': {
    name: 'Wyncote',
    postalCode: '19095',
  },
  'wyndmere-nd': {
    name: 'Wyndmere',
    postalCode: '58081',
  },
  'wynne-ar': {
    name: 'Wynne',
    postalCode: '72396',
  },
  'wynnewood-ok': {
    name: 'Wynnewood',
    postalCode: '73098',
  },
  'wynnewood-pa': {
    name: 'Wynnewood',
    postalCode: '19096',
  },
  'wynona-ok': {
    name: 'Wynona',
    postalCode: '74084',
  },
  'wynot-ne': {
    name: 'Wynot',
    postalCode: '68792',
  },
  'wyola-mt': {
    name: 'Wyola',
    postalCode: '59089',
  },
  'wyoming-ia': {
    name: 'Wyoming',
    postalCode: '52362',
  },
  'wyoming-il': {
    name: 'Wyoming',
    postalCode: '61491',
  },
  'wyoming-mi': {
    name: 'Wyoming',
    postalCode: '49509',
  },
  'wyoming-mn': {
    name: 'Wyoming',
    postalCode: '55092',
  },
  'wyoming-ny': {
    name: 'Wyoming',
    postalCode: '14591',
  },
  'wyoming-pa': {
    name: 'Wyoming',
    postalCode: '18644',
  },
  'wyoming-ri': {
    name: 'Wyoming',
    postalCode: '02898',
  },
  'wyoming-wv': {
    name: 'Wyoming',
    postalCode: '24898',
  },
  'wysox-pa': {
    name: 'Wysox',
    postalCode: '18854',
  },
  'wytheville-va': {
    name: 'Wytheville',
    postalCode: '24382',
  },
  'wytopitlock-me': {
    name: 'Wytopitlock',
    postalCode: '04497',
  },
  'xenia-il': {
    name: 'Xenia',
    postalCode: '62899',
  },
  'xenia-oh': {
    name: 'Xenia',
    postalCode: '45385',
  },
  'yabucoa-pr': {
    name: 'Yabucoa',
    postalCode: '00767',
  },
  'yachats-or': {
    name: 'Yachats',
    postalCode: '97498',
  },
  'yacolt-wa': {
    name: 'Yacolt',
    postalCode: '98675',
  },
  'yadkinville-nc': {
    name: 'Yadkinville',
    postalCode: '27055',
  },
  'yakima-wa': {
    name: 'Yakima',
    postalCode: '98901',
  },
  'yalaha-fl': {
    name: 'Yalaha',
    postalCode: '34797',
  },
  'yale-ia': {
    name: 'Yale',
    postalCode: '50277',
  },
  'yale-il': {
    name: 'Yale',
    postalCode: '62481',
  },
  'yale-mi': {
    name: 'Yale',
    postalCode: '48097',
  },
  'yale-ok': {
    name: 'Yale',
    postalCode: '74085',
  },
  'yale-sd': {
    name: 'Yale',
    postalCode: '57386',
  },
  'yale-va': {
    name: 'Yale',
    postalCode: '23897',
  },
  'yamhill-or': {
    name: 'Yamhill',
    postalCode: '97148',
  },
  'yancey-tx': {
    name: 'Yancey',
    postalCode: '78886',
  },
  'yanceyville-nc': {
    name: 'Yanceyville',
    postalCode: '27379',
  },
  'yankeetown-fl': {
    name: 'Yankeetown',
    postalCode: '34498',
  },
  'yankton-sd': {
    name: 'Yankton',
    postalCode: '57078',
  },
  'yantic-ct': {
    name: 'Yantic',
    postalCode: '06389',
  },
  'yantis-tx': {
    name: 'Yantis',
    postalCode: '75497',
  },
  'yaphank-ny': {
    name: 'Yaphank',
    postalCode: '11980',
  },
  'yarmouth-ia': {
    name: 'Yarmouth',
    postalCode: '52660',
  },
  'yarmouth-me': {
    name: 'Yarmouth',
    postalCode: '04096',
  },
  'yarmouth-port-ma': {
    name: 'Yarmouth Port',
    postalCode: '02675',
  },
  'yates-center-ks': {
    name: 'Yates Center',
    postalCode: '66783',
  },
  'yates-city-il': {
    name: 'Yates City',
    postalCode: '61572',
  },
  'yatesville-ga': {
    name: 'Yatesville',
    postalCode: '31097',
  },
  'yauco-pr': {
    name: 'Yauco',
    postalCode: '00698',
  },
  'yawkey-wv': {
    name: 'Yawkey',
    postalCode: '25573',
  },
  'yazoo-city-ms': {
    name: 'Yazoo City',
    postalCode: '39194',
  },
  'yeaddiss-ky': {
    name: 'Yeaddiss',
    postalCode: '41777',
  },
  'yeagertown-pa': {
    name: 'Yeagertown',
    postalCode: '17099',
  },
  'yellow-jacket-co': {
    name: 'Yellow Jacket',
    postalCode: '81335',
  },
  'yellow-pine-id': {
    name: 'Yellow Pine',
    postalCode: '83677',
  },
  'yellow-spring-wv': {
    name: 'Yellow Spring',
    postalCode: '26865',
  },
  'yellow-springs-oh': {
    name: 'Yellow Springs',
    postalCode: '45387',
  },
  'yellowstone-national-park-wy': {
    name: 'Yellowstone National Park',
    postalCode: '82190',
  },
  'yellville-ar': {
    name: 'Yellville',
    postalCode: '72687',
  },
  'yelm-wa': {
    name: 'Yelm',
    postalCode: '98597',
  },
  'yemassee-sc': {
    name: 'Yemassee',
    postalCode: '29945',
  },
  'yeoman-in': {
    name: 'Yeoman',
    postalCode: '47997',
  },
  'yerington-nv': {
    name: 'Yerington',
    postalCode: '89447',
  },
  'yeso-nm': {
    name: 'Yeso',
    postalCode: '88136',
  },
  'yigo-gu': {
    name: 'Yigo',
    postalCode: '96929',
  },
  'yoakum-tx': {
    name: 'Yoakum',
    postalCode: '77995',
  },
  'yoder-co': {
    name: 'Yoder',
    postalCode: '80864',
  },
  'yoder-in': {
    name: 'Yoder',
    postalCode: '46798',
  },
  'yoder-wy': {
    name: 'Yoder',
    postalCode: '82244',
  },
  'yolyn-wv': {
    name: 'Yolyn',
    postalCode: '25654',
  },
  'yoncalla-or': {
    name: 'Yoncalla',
    postalCode: '97499',
  },
  'yonkers-ny': {
    name: 'Yonkers',
    postalCode: '10701',
  },
  'yorba-linda-ca': {
    name: 'Yorba Linda',
    postalCode: '92886',
  },
  'york-al': {
    name: 'York',
    postalCode: '36925',
  },
  'york-me': {
    name: 'York',
    postalCode: '03909',
  },
  'york-nd': {
    name: 'York',
    postalCode: '58386',
  },
  'york-ne': {
    name: 'York',
    postalCode: '68467',
  },
  'york-pa': {
    name: 'York',
    postalCode: '17401',
  },
  'york-sc': {
    name: 'York',
    postalCode: '29745',
  },
  'york-haven-pa': {
    name: 'York Haven',
    postalCode: '17370',
  },
  'york-springs-pa': {
    name: 'York Springs',
    postalCode: '17372',
  },
  'yorklyn-de': {
    name: 'Yorklyn',
    postalCode: '19736',
  },
  'yorkshire-oh': {
    name: 'Yorkshire',
    postalCode: '45388',
  },
  'yorktown-in': {
    name: 'Yorktown',
    postalCode: '47396',
  },
  'yorktown-tx': {
    name: 'Yorktown',
    postalCode: '78164',
  },
  'yorktown-va': {
    name: 'Yorktown',
    postalCode: '23690',
  },
  'yorktown-heights-ny': {
    name: 'Yorktown Heights',
    postalCode: '10598',
  },
  'yorkville-ca': {
    name: 'Yorkville',
    postalCode: '95494',
  },
  'yorkville-il': {
    name: 'Yorkville',
    postalCode: '60560',
  },
  'yorkville-ny': {
    name: 'Yorkville',
    postalCode: '13495',
  },
  'yorkville-oh': {
    name: 'Yorkville',
    postalCode: '43971',
  },
  'yosemite-ky': {
    name: 'Yosemite',
    postalCode: '42566',
  },
  'yosemite-national-park-ca': {
    name: 'Yosemite National Park',
    postalCode: '95389',
  },
  'young-america-in': {
    name: 'Young America',
    postalCode: '46998',
  },
  'young-america-mn': {
    name: 'Young America',
    postalCode: '55397',
  },
  'young-harris-ga': {
    name: 'Young Harris',
    postalCode: '30582',
  },
  'youngstown-fl': {
    name: 'Youngstown',
    postalCode: '32466',
  },
  'youngstown-ny': {
    name: 'Youngstown',
    postalCode: '14174',
  },
  'youngstown-oh': {
    name: 'Youngstown',
    postalCode: '44502',
  },
  'youngsville-la': {
    name: 'Youngsville',
    postalCode: '70592',
  },
  'youngsville-nc': {
    name: 'Youngsville',
    postalCode: '27596',
  },
  'youngsville-nm': {
    name: 'Youngsville',
    postalCode: '87064',
  },
  'youngsville-ny': {
    name: 'Youngsville',
    postalCode: '12791',
  },
  'youngsville-pa': {
    name: 'Youngsville',
    postalCode: '16371',
  },
  'youngtown-az': {
    name: 'Youngtown',
    postalCode: '85363',
  },
  'youngwood-pa': {
    name: 'Youngwood',
    postalCode: '15697',
  },
  'yountville-ca': {
    name: 'Yountville',
    postalCode: '94599',
  },
  'ypsilanti-mi': {
    name: 'Ypsilanti',
    postalCode: '48197',
  },
  'ypsilanti-nd': {
    name: 'Ypsilanti',
    postalCode: '58497',
  },
  'yreka-ca': {
    name: 'Yreka',
    postalCode: '96097',
  },
  'yuba-city-ca': {
    name: 'Yuba City',
    postalCode: '95991',
  },
  'yucaipa-ca': {
    name: 'Yucaipa',
    postalCode: '92399',
  },
  'yucca-valley-ca': {
    name: 'Yucca Valley',
    postalCode: '92284',
  },
  'yukon-mo': {
    name: 'Yukon',
    postalCode: '65589',
  },
  'yukon-ok': {
    name: 'Yukon',
    postalCode: '73099',
  },
  'yukon-pa': {
    name: 'Yukon',
    postalCode: '15698',
  },
  'yulan-ny': {
    name: 'Yulan',
    postalCode: '12792',
  },
  'yulee-fl': {
    name: 'Yulee',
    postalCode: '32097',
  },
  'yuma-az': {
    name: 'Yuma',
    postalCode: '85364',
  },
  'yuma-co': {
    name: 'Yuma',
    postalCode: '80759',
  },
  'yuma-tn': {
    name: 'Yuma',
    postalCode: '38390',
  },
  'yutan-ne': {
    name: 'Yutan',
    postalCode: '68073',
  },
  'zachary-la': {
    name: 'Zachary',
    postalCode: '70791',
  },
  'zahl-nd': {
    name: 'Zahl',
    postalCode: '58856',
  },
  'zalma-mo': {
    name: 'Zalma',
    postalCode: '63787',
  },
  'zanesfield-oh': {
    name: 'Zanesfield',
    postalCode: '43360',
  },
  'zanesville-oh': {
    name: 'Zanesville',
    postalCode: '43701',
  },
  'zanoni-mo': {
    name: 'Zanoni',
    postalCode: '65784',
  },
  'zap-nd': {
    name: 'Zap',
    postalCode: '58580',
  },
  'zapata-tx': {
    name: 'Zapata',
    postalCode: '78076',
  },
  'zavalla-tx': {
    name: 'Zavalla',
    postalCode: '75980',
  },
  'zearing-ia': {
    name: 'Zearing',
    postalCode: '50278',
  },
  'zebulon-ga': {
    name: 'Zebulon',
    postalCode: '30295',
  },
  'zebulon-nc': {
    name: 'Zebulon',
    postalCode: '27597',
  },
  'zeeland-mi': {
    name: 'Zeeland',
    postalCode: '49464',
  },
  'zeeland-nd': {
    name: 'Zeeland',
    postalCode: '58581',
  },
  'zeigler-il': {
    name: 'Zeigler',
    postalCode: '62999',
  },
  'zelienople-pa': {
    name: 'Zelienople',
    postalCode: '16063',
  },
  'zellwood-fl': {
    name: 'Zellwood',
    postalCode: '32798',
  },
  'zenda-ks': {
    name: 'Zenda',
    postalCode: '67159',
  },
  'zenia-ca': {
    name: 'Zenia',
    postalCode: '95595',
  },
  'zephyr-tx': {
    name: 'Zephyr',
    postalCode: '76890',
  },
  'zephyrhills-fl': {
    name: 'Zephyrhills',
    postalCode: '33540',
  },
  'zieglerville-pa': {
    name: 'Zieglerville',
    postalCode: '19492',
  },
  'zillah-wa': {
    name: 'Zillah',
    postalCode: '98953',
  },
  'zimmerman-mn': {
    name: 'Zimmerman',
    postalCode: '55398',
  },
  'zion-il': {
    name: 'Zion',
    postalCode: '60099',
  },
  'zion-grove-pa': {
    name: 'Zion Grove',
    postalCode: '17985',
  },
  'zionsville-in': {
    name: 'Zionsville',
    postalCode: '46077',
  },
  'zionsville-pa': {
    name: 'Zionsville',
    postalCode: '18092',
  },
  'zionville-nc': {
    name: 'Zionville',
    postalCode: '28698',
  },
  'zirconia-nc': {
    name: 'Zirconia',
    postalCode: '28790',
  },
  'zoe-ky': {
    name: 'Zoe',
    postalCode: '41397',
  },
  'zolfo-springs-fl': {
    name: 'Zolfo Springs',
    postalCode: '33890',
  },
  'zortman-mt': {
    name: 'Zortman',
    postalCode: '59546',
  },
  'zumbro-falls-mn': {
    name: 'Zumbro Falls',
    postalCode: '55991',
  },
  'zumbrota-mn': {
    name: 'Zumbrota',
    postalCode: '55992',
  },
  'zuni-va': {
    name: 'Zuni',
    postalCode: '23898',
  },
  'zurich-mt': {
    name: 'Zurich',
    postalCode: '59547',
  },
  'zwingle-ia': {
    name: 'Zwingle',
    postalCode: '52079',
  },
  'zwolle-la': {
    name: 'Zwolle',
    postalCode: '71486',
  },
};
